import React, { useState } from "react";
import MessageModal from "molecules/modals/message-modal";
import { useLocation, useHistory } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SalesforceConfirmationModalProps {
    heading?: string;
    label?: string;
    message?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SalesforceConfirmationModal: React.FC<SalesforceConfirmationModalProps> =
    (props: SalesforceConfirmationModalProps) => {
        const { t } = useLocalization();
        const location = useLocation();
        const history = useHistory();
        const [isOpen, setIsOpen] = useState<boolean>(
            location.search.indexOf("fromSalesforce") > 0
        );
        const heading =
            props.heading ?? t("enterpriseConfirmationModal-heading");
        const label = props.label ?? t("enterpriseConfirmationModal-heading");
        const message = props.message ?? t("supportPage-messageConfirmation");

        const handleClose = () => {
            // Stripping query string so confirmation modal doesn't reappear on refresh/navigation
            history.replace(RouteUtils.removeQueryString(location.pathname));
            setIsOpen(false);
        };

        return (
            <MessageModal
                closeDialog={handleClose}
                heading={heading}
                isVisible={isOpen}
                label={label}>
                {message}
            </MessageModal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SalesforceConfirmationModal;

// #endregion Exports
