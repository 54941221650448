import { RouteUtils } from "utilities/route-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import Anchor from "atoms/anchors/anchor";
import Loader from "atoms/loaders/loader";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import SituationalNavigationResultsView from "organisms/situational-navigation/situational-navigation-results-view";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { CollectionUtils } from "utilities/collection-utils";
import SolutionsPageContext, {
    useSolutionsPageContext,
} from "utilities/contexts/use-solutions-page-context";
import useSituations from "utilities/hooks/domain/situational-navigation/situations/use-situations";
import useSolutions from "utilities/hooks/domain/situational-navigation/solutions/use-solutions";
import useLoading from "utilities/hooks/use-loading";
import CultureResources from "utilities/interfaces/culture-resources";
import CategoryService from "utilities/services/situational-navigation/categories/category-service";
import SituationSolutionQueryParams from "utilities/services/situational-navigation/situation-solution-query-params";
import { ToastManager } from "utilities/toast/toast-manager";
import { SkipNavContent } from "@reach/skip-nav";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SituationalNavigationResultsPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const LOADING_CATEGORIES_ERROR = "There was an issue loading categories.";

// #endregion Constants

const SituationalNavigationResultsPage: React.FC<SituationalNavigationResultsPageProps> =
    () => {
        // -----------------------------------------------------------------------------------------
        // #region Constants
        // -----------------------------------------------------------------------------------------

        const cssBaseClassName = "c-situational-navigation-page";
        const cssBaseToolbarClassName =
            "c-situational-navigation-page__toolbar";
        const location = useLocation();

        // #endregion Constants

        // -----------------------------------------------------------------------------------------
        // #region Localization
        // -----------------------------------------------------------------------------------------

        const { t } = useLocalization<CultureResources>();

        // #endregion Localization

        // -----------------------------------------------------------------------------------------
        // #region State
        // -----------------------------------------------------------------------------------------

        const { solutionsPageContext, setSolutionsPageContext } =
            useSolutionsPageContext();

        const serviceParams =
            RouteUtils.queryStringToObject<SituationSolutionQueryParams>(
                location.search
            );

        const { list: listCategoriesApi } = CategoryService.useList();
        const { get: getCategoryApi } = CategoryService.useGet();

        const [loadingCategories, setLoadingCategories] = useState(false);

        const { solutions, loadingSolutions } = useSolutions(
            solutionsPageContext.categories,
            true
        );
        const { situations, loadingSituations } = useSituations(
            solutionsPageContext.categories,
            true
        );
        const [total, setTotal] = useState<number>(0);

        const loading = useLoading(
            loadingSituations,
            loadingSolutions,
            loadingCategories
        );

        // #endregion State

        // -----------------------------------------------------------------------------------------
        // #region Side Effects
        // -----------------------------------------------------------------------------------------

        useEffect(() => {
            const loadCategories = async () => {
                setLoadingCategories(true);

                try {
                    const categoryListResult = await listCategoriesApi({
                        categoryIds: serviceParams.relatedCategoryIds,
                    });

                    setSolutionsPageContext(
                        solutionsPageContext.with({
                            categories: CategoryCollectionRecord.fromArray(
                                categoryListResult.resultObjects
                            ),
                        })
                    );
                } catch (e) {
                    ToastManager.error(LOADING_CATEGORIES_ERROR);
                }

                setLoadingCategories(false);
            };

            if (!CollectionUtils.hasValues(serviceParams.relatedCategoryIds)) {
                return;
            }
            if (solutionsPageContext.categories.isEmpty()) {
                loadCategories();
            }
        }, [
            listCategoriesApi,
            serviceParams,
            getCategoryApi,
            solutionsPageContext,
            setSolutionsPageContext,
        ]);

        useEffect(() => {
            setTotal(situations.length + solutions.length);
        }, [situations, solutions]);

        if (loading) {
            return (
                <Loader
                    accessibleText={t("loadingItem", {
                        item: t("category_plural"),
                    })}
                />
            );
        }

        if (
            solutionsPageContext.categories.isEmpty() &&
            serviceParams == null
        ) {
            return <Redirect to={siteMap.situationalNavigation.dashboard} />;
        }

        // #endregion Side Effects

        // -----------------------------------------------------------------------------------------
        // #region Component
        // -----------------------------------------------------------------------------------------

        const resultCountString = t("result", { count: total });
        const editFiltersLabel = t("editItem", { item: t("filter_plural") });

        return (
            <SolutionsPageContext.Provider
                value={[solutionsPageContext, setSolutionsPageContext]}>
                <SkipNavContent>
                    <div
                        className={`${cssBaseClassName} -results`}
                        tabIndex={-1}>
                        <div className={`${cssBaseClassName}__container`}>
                            <div
                                className={`${cssBaseToolbarClassName} -top -filled`}>
                                <div
                                    className={`${cssBaseToolbarClassName}__left`}>
                                    {resultCountString}
                                </div>
                                <div
                                    className={`${cssBaseToolbarClassName}__right`}>
                                    <Anchor
                                        to={
                                            siteMap.situationalNavigation
                                                .dashboard
                                        }>
                                        {editFiltersLabel}
                                    </Anchor>
                                </div>
                            </div>
                            {loading ? (
                                <Loader />
                            ) : (
                                <SituationalNavigationResultsView
                                    categoriesFromContext={
                                        solutionsPageContext.categories
                                    }
                                    situations={situations}
                                    solutions={solutions}
                                />
                            )}
                        </div>
                    </div>
                </SkipNavContent>
            </SolutionsPageContext.Provider>
        );
    };
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SituationalNavigationResultsPage;

// #endregion Exports
