import React, { PropsWithChildren } from "react";
import StringUtils from "utilities/string-utils";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface NoResultsBannerProps {
    /**
     * Additional class name to be applied
     */
    className?: string;

    id?: string;

    /**
     * Title/main text to display (only renders if provided)
     */
    title?: string;

    /**
     * Size of the Paragraph to render for the title (if provided)
     *
     * @default ParagraphSizes.Large
     */
    titleSize?: ParagraphSizes;

    /**
     * Subtitle/secondary text to display in label under title (only renders if provided)
     */
    subtitle?: string;

    /**
     * Size of the Paragraph to render for the subtitle (if provided)
     *
     * @default ParagraphSizes.Small
     */
    subtitleSize?: ParagraphSizes;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-no-results-banner";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const NoResultsBanner: React.FunctionComponent<
    PropsWithChildren<NoResultsBannerProps>
> = (props: PropsWithChildren<NoResultsBannerProps>) => {
    const { title, subtitle, className, id } = props;
    const titleSize = props.titleSize ?? ParagraphSizes.Large;
    const subtitleSize = props.subtitleSize ?? ParagraphSizes.Small;

    let fullClassName = CLASS_NAME;
    if (StringUtils.hasValue(className)) {
        fullClassName = `${fullClassName} ${className}`;
    }

    return (
        <div className={fullClassName} id={id}>
            {StringUtils.hasValue(title) && (
                <Paragraph size={titleSize}>{title}</Paragraph>
            )}
            {StringUtils.hasValue(subtitle) && (
                <Paragraph
                    cssClassName={`${CLASS_NAME}__subtitle`}
                    size={subtitleSize}>
                    {subtitle}
                </Paragraph>
            )}
            {props.children}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default NoResultsBanner;

// #endregion Export
