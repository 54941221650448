import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import React, { ReactNode } from "react";
import { t } from "utilities/localization-utils";
import logo from "assets/images/nfpa-link-color.png";
import JoinGroupOnDemandFooter from "./join-group-on-demand-footer";

const BASE_CLASS = "c-onboarding-layout";
const MENU_CLASS = "c-onboarding-layout__menu";
const CONTENT_CLASS = "c-onboarding-layout__content";

interface JoinGroupOnDemandLayoutProps {
    children: ReactNode;
}

const JoinGroupOnDemandLayout = ({
    children,
}: JoinGroupOnDemandLayoutProps) => {
    return (
        <>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <div className={BASE_CLASS}>
                <div className={MENU_CLASS}>
                    <div className={`${MENU_CLASS}__logo`}>
                        <Anchor to={siteMap.home} ariaLabel="Home">
                            <img src={logo} alt="Home" />
                        </Anchor>
                    </div>
                </div>
                <div className={CONTENT_CLASS}>
                    <SkipNavContent tabIndex={-1}>{children}</SkipNavContent>
                </div>
                <JoinGroupOnDemandFooter />
            </div>
        </>
    );
};

export default JoinGroupOnDemandLayout;
