import { RecordUtils } from "andculturecode-javascript-core";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { Record } from "immutable";
import Chapter from "models/interfaces/chapter";
import ArticleRecord from "models/view-models/article-record";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import { ReactNode } from "react";
import CaapsRecordUtils from "utilities/caaps-record-utils";
import { CollectionUtils } from "utilities/collection-utils";
import { tForCulture } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import XmlUtils from "utilities/xml-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultValues: Chapter =
    RecordUtils.auditableDefaultValuesFactory<Chapter>({
        articles: [],
        displaySequence: undefined,
        externalId: "",
        label: undefined,
        nfpaLabel: undefined,
        number: undefined,
        parentId: undefined,
        publication: undefined,
        publicationId: 0,
        sections: [],
        title: undefined,
        titleAsPlainText: "",
        changes: undefined,
        hasTables: false,
    });

// #endregion Constants

export default class ChapterRecord
    extends Record(defaultValues)
    implements Chapter
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Chapter) {
        if (params == null) {
            params = Object.assign(defaultValues, params);
        }

        if (CollectionUtils.hasValues(params.articles)) {
            params.articles = params.articles!.map((article) =>
                RecordUtils.ensureRecord(article, ArticleRecord)
            );
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (CollectionUtils.hasValues(params.sections)) {
            params.sections = params.sections!.map((section) =>
                RecordUtils.ensureRecord(section, SectionRecord)
            );
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getDisplayTitle(): string {
        return this.parseTitle(UnicodeCharacterConstants.EmDash);
    }

    public getDisplayLabel(): string {
        return this.label || "";
    }

    public getPageTitle(): string {
        return this.parseTitle();
    }

    /**
     * Get the application route to view the current section in Book View
     */
    public getRoute(
        isAdminPreview: boolean = false,
        includeHash: boolean = true
    ): string {
        const anchor = PublicationAnchorRecord.fromChapterRecord(
            this,
            isAdminPreview
        );
        return anchor.getRoute(includeHash) ?? "#";
    }

    /**
     * Returns the title as React components (Usually only text, but <inline> elements can exist for changes).
     */
    public getTitle(debug?: boolean): ReactNode {
        // If we have no title, just return an empty string.
        if (StringUtils.isEmpty(this.title)) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        return XmlUtils.convert(this.title!, xmlConverters, debug);
    }

    /**
     * @returns Whether or not the record title or body XML has changes.
     * Uses XmlUtils.hasAttributeDeep to recursively check every element in the XML tree.
     */
    public doesTitleOrBodyHaveChanges(): boolean {
        const hasChanges = CaapsRecordUtils().doesTitleOrBodyHaveChanges(
            this.title,
            undefined,
            this.changes
        );

        return hasChanges;
    }

    public isPersisted(): boolean {
        return this.id != null && this.id > 0;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Chapter>} values
     */
    public with(values: Partial<Chapter>): ChapterRecord {
        return new ChapterRecord(Object.assign(this.toJS(), values));
    }
    /**
     * Adds PublicationRecord relationship based on ExternalId from a list of PublicationRecords.
     *
     * @param {PublicationRecord[]} [publications]
     */
    public withPublication(publications?: PublicationRecord[]): ChapterRecord {
        if (CollectionUtils.isEmpty(publications)) {
            return this;
        }

        return this.with({
            publication: publications.find(
                (publication: PublicationRecord) =>
                    publication?.id === this.publicationId
            ),
        });
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private parseTitle(separator?: string): string {
        let title = this.titleAsPlainText ?? "";

        const culture = this.publication?.locale;
        if (this.number != null) {
            const titleWithNumber = tForCulture(culture, "chapterNumber", {
                number: this.number,
            });

            separator = StringUtils.hasValue(separator)
                ? ` ${separator} `
                : " ";

            title = `${titleWithNumber}${separator}${title.replace(
                titleWithNumber,
                ""
            )}`;
        }

        // If this changes, please change `ChapterSearchUploadConductor#Upload`.
        // Placeholder, <title> for introduction is currently empty
        if (this.number == null && StringUtils.isEmpty(title)) {
            title = tForCulture(culture, "introduction");
        }

        return title;
    }

    // #endregion Private Methods
}
