import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import {
    EnhancedContentRoutes,
    EnhancedContentQueryParams,
    EnhancedContentRouteParams,
    getEndpoints,
} from "utilities/services/enhanced-content-service-routes";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const typeEndpointMap = EnhancedContentRoutes;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service Factory
// -------------------------------------------------------------------------------------------------

const EnhancedContentServiceFactory = {
    get: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceFactory.get<
            EnhancedContentRecord,
            EnhancedContentRouteParams,
            EnhancedContentQueryParams
        >(EnhancedContentRecord, endpoints.resourceEndpoint);
    },
    useGet: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceHookFactory.useGet<
            EnhancedContentRecord,
            EnhancedContentRouteParams,
            EnhancedContentQueryParams
        >(EnhancedContentRecord, endpoints.resourceEndpoint)();
    },
    list: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceFactory.nestedList<
            EnhancedContentRecord,
            EnhancedContentRouteParams,
            EnhancedContentQueryParams
        >(EnhancedContentRecord, endpoints.baseEndpoint);
    },
    useList: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceHookFactory.useNestedList<
            EnhancedContentRecord,
            EnhancedContentRouteParams,
            EnhancedContentQueryParams
        >(EnhancedContentRecord, endpoints.baseEndpoint)();
    },
    create: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceFactory.nestedCreate<
            EnhancedContentRecord,
            EnhancedContentRouteParams
        >(EnhancedContentRecord, endpoints.baseEndpoint);
    },
    useCreate: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceHookFactory.useNestedCreate<
            EnhancedContentRecord,
            EnhancedContentRouteParams
        >(EnhancedContentRecord, endpoints.baseEndpoint)();
    },
    update: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceFactory.update<
            EnhancedContentRecord,
            EnhancedContentRouteParams
        >(EnhancedContentRecord, endpoints.baseEndpoint);
    },
    useUpdate: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceHookFactory.useUpdate<
            EnhancedContentRecord,
            EnhancedContentRouteParams
        >(EnhancedContentRecord, endpoints.resourceEndpoint)();
    },
    delete: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceFactory.delete<EnhancedContentRouteParams>(
            endpoints.resourceEndpoint
        );
    },
    useDelete: (type: PublicationComponentType) => {
        const endpoints = getEndpoints(type, typeEndpointMap);
        return ServiceHookFactory.useDelete<EnhancedContentRouteParams>(
            endpoints.resourceEndpoint
        )();
    },
};

// #endregion Service Factory

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EnhancedContentServiceFactory;

// #endregion Exports
