import { useLocalization } from "andculturecode-javascript-react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React, { useState } from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import { v4 } from "uuid";

const BASE_CLASS_NAME = "c-change-summary";
const accordionContentId = v4();
const buttonId = v4();

interface TiaChangeSummaryProps {
    changeSummary: string;
}

const TiaChangeSummary = ({ changeSummary }: TiaChangeSummaryProps) => {
    const { t } = useLocalization<CultureResources>();
    const [isCollapsed, setCollapsed] = useState<boolean>(true);

    const handleToggle = () => {
        setCollapsed(!isCollapsed);
    };

    const accessibleText = t("changeSummaryAction", {
        action: isCollapsed ? t("expand") : t("collapse"),
    });
    const expandOrCollapse = isCollapsed ? t("expand") : t("collapse");
    const changeSummaryHeader = t("changeSummaryHeader");
    const expandedClassName = !isCollapsed ? "-expanded" : "";

    const iconType = isCollapsed ? Icons.Expand : Icons.Collapse;

    return (
        <div className={BASE_CLASS_NAME}>
            <Button
                cssClassName={`${BASE_CLASS_NAME}__heading`}
                ariaControls={accordionContentId}
                accessibleText={accessibleText}
                ariaExpanded={!isCollapsed}
                onClick={handleToggle}
                id={buttonId}
                style={ButtonStyles.None}>
                <div>
                    <Icon type={Icons.Information} />
                    {changeSummaryHeader}
                </div>
                <div>
                    {expandOrCollapse}
                    <Icon cssClassName="-icon" type={iconType} />
                    <span className={"sr-only"}>{expandOrCollapse}</span>
                </div>
            </Button>
            <div
                aria-hidden={isCollapsed}
                aria-labelledby={buttonId}
                className={`${BASE_CLASS_NAME}__content ${expandedClassName}`}
                id={accordionContentId}
                role="region">
                <div
                    className={`${BASE_CLASS_NAME}__accordion ${expandedClassName}`}>
                    <p>{changeSummary}</p>
                </div>
            </div>
        </div>
    );
};

export default TiaChangeSummary;
