import { RecordUtils } from "andculturecode-javascript-core";
import PdfTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import { Record as ImmutableRecord } from "immutable";

const defaultValues: PdfTableOfContentsEntry =
    RecordUtils.defaultValuesFactory<PdfTableOfContentsEntry>({
        id: undefined,
        heading: undefined,
        description: undefined,
        displayOrder: undefined,
        pageNumber: undefined,
        sections: [],
    });

export default class FreeAccessPublicationTableOfContentsRecord
    extends ImmutableRecord(defaultValues)
    implements PdfTableOfContentsEntry
{
    constructor(params?: PdfTableOfContentsEntry) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.sections != null) {
            params.sections = RecordUtils.ensureRecords(
                params.sections,
                FreeAccessPublicationTableOfContentsRecord
            );
        }

        super(params);
    }
}
