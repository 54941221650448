import PublicationTypes from "constants/publication-types";
import { Record } from "immutable";
import type PublicationAnchor from "models/interfaces/publication-anchor";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import HitRecord from "models/view-models/search/hit-record";
import SectionRecord from "models/view-models/section-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import { RecordUtils } from "andculturecode-javascript-core";
import TableRecord from "./table-record";

const defaultValues: PublicationAnchor =
    RecordUtils.defaultValuesFactory<PublicationAnchor>({
        annexId: undefined,
        annexNfpaLabel: undefined,
        annexNumber: undefined,
        articleId: undefined,
        articleNfpaLabel: undefined,
        chapterId: undefined,
        chapterNfpaLabel: undefined,
        chapterNumber: undefined,
        externalId: undefined,
        groupBy: undefined,
        isAdminPreview: false,
        parentExternalId: undefined,
        parentType: undefined,
        partId: undefined,
        publicationCode: undefined,
        publicationEdition: undefined,
        publicationId: undefined,
        publicationType: undefined,
        rootSectionId: undefined,
        sectionId: undefined,
        type: undefined,
    });

export default class PublicationAnchorRecord
    extends Record(defaultValues)
    implements PublicationAnchor
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: PublicationAnchor) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Static methods
    // ----------------------------------------------------------------------------------------

    public static fromArticleRecord(
        article: ArticleRecord,
        isAdminPreview: boolean
    ) {
        return new PublicationAnchorRecord({
            ...article.toJS(),
            articleNfpaLabel: article.nfpaLabel,
            chapterNfpaLabel: article.chapter?.nfpaLabel,
            publicationCode: article.publication?.code,
            publicationEdition: article.publication?.edition,
            publicationType: article.publication?.type,
            articleId: article.id,
            isAdminPreview,
        });
    }

    public static fromAnnexRecord(annex: AnnexRecord, isAdminPreview: boolean) {
        return new PublicationAnchorRecord({
            ...annex.toJS(),
            publicationEdition: annex.publication?.edition,
            publicationCode: annex.publication?.code,
            publicationType: annex.publication?.type,
            annexNfpaLabel: annex.nfpaLabel,
            annexId: annex.id,
            annexNumber: annex.number,
            isAdminPreview,
        });
    }
    public static fromAnnexGroup(
        annex: AnnexRecord,
        groupBy: string | number,
        isAdminPreview: boolean
    ) {
        return new PublicationAnchorRecord({
            ...annex.toJS(),
            publicationEdition: annex.publication?.edition,
            publicationCode: annex.publication?.code,
            publicationType: annex.publication?.type,
            annexNfpaLabel: annex.nfpaLabel,
            annexId: annex.id,
            annexNumber: annex.number,
            groupBy: groupBy.toString(),
            isAdminPreview,
        });
    }

    public static fromChapterRecord(
        chapter: ChapterRecord,
        isAdminPreview: boolean
    ) {
        return new PublicationAnchorRecord({
            ...chapter.toJS(),
            chapterId: chapter.id,
            chapterNfpaLabel: chapter.nfpaLabel,
            publicationCode: chapter.publication?.code,
            publicationEdition: chapter.publication?.edition,
            publicationType: chapter.publication?.type,
            isAdminPreview,
        });
    }

    /**
     * Map properties from a HitRecord to a new instance of a PublicationAnchorRecord
     * @param  {HitRecord} hit
     */
    public static fromHitRecord(hit: HitRecord) {
        return new PublicationAnchorRecord({
            ...hit.toJS(),
            annexId: hit.annex?.id,
            annexNfpaLabel: hit.annex?.nfpaLabel,
            articleId: hit.article?.id,
            articleNfpaLabel: hit.article?.nfpaLabel,
            chapterId: hit.chapter?.id,
            chapterNfpaLabel: hit.chapter?.nfpaLabel,
            externalId: hit.getExternalId(),
            groupBy: hit.section?.groupBy,
            publicationCode: hit.publication?.code,
            publicationEdition: hit.publication?.edition,
            publicationType: hit.publication?.type,
            sectionId: hit.section?.id,
        });
    }

    /**
     * Map properties from a SectionRecord to a new instance of a PublicationAnchorRecord
     * @param  {SectionRecord} section
     * @param  {boolean} isAdminPreview
     * @param  {PublicationTypes} publicationType?
     * @returns PublicationAnchorRecord
     */
    public static fromSectionRecord(
        section: SectionRecord,
        isAdminPreview: boolean,
        publicationType?: PublicationTypes
    ): PublicationAnchorRecord {
        return new PublicationAnchorRecord({
            ...section.toJS(),
            annexNfpaLabel: section.annex?.nfpaLabel,
            articleNfpaLabel: section.article?.nfpaLabel,
            chapterNfpaLabel: section.chapter?.nfpaLabel,
            isAdminPreview,
            publicationCode: section.publication?.code,
            publicationEdition: section.publication?.edition,
            publicationType,
            sectionId: section.id,
        });
    }

    public static fromTableRecord(
        table: TableRecord,
        isAdminPreview: boolean,
        publicationType?: PublicationTypes
    ): PublicationAnchorRecord {
        return new PublicationAnchorRecord({
            ...table.toJS(),
            annexNfpaLabel: table.annex?.nfpaLabel,
            articleNfpaLabel: table.article?.nfpaLabel,
            groupBy: table.section?.groupBy,
            isAdminPreview,
            publicationCode: table.publication?.code,
            publicationEdition: table.publication?.edition,
            publicationType,
        });
    }

    /**
     * Map Properties from a UserBookmarkRecord to a new instance of a PublicationAnchorRecord
     * @param userBookmark
     */
    public static fromUserBookmarkRecord(
        userBookmark: UserBookmarkRecord
    ): PublicationAnchorRecord {
        return new PublicationAnchorRecord({
            ...userBookmark.toJS(),
            externalId: userBookmark.externalId,
            publicationType: userBookmark.createdOnPublication?.type,
        });
    }

    // #endregion Static methods

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Build a route for an annex or annex group page
     * @returns string
     */
    getAnnexPageRoute(): string | undefined {
        if (StringUtils.hasValue(this.groupBy)) {
            return this.getAnnexGroupPageRoute();
        }
        if (this.isAdminPreview) {
            return RouteUtils.getUrl(siteMap.publications.adminPreview.annex, {
                publicationId: this.publicationId,
                id: this.annexId,
            });
        }
        if (!this.isAdminPreview && this.hasAnnexPermalinkParams()) {
            return this.getAnnexPagePermalinkRoute();
        }

        return RouteUtils.getUrl(siteMap.publications.deprecated.annex, {
            publicationId: this.publicationId,
            id: this.annexId,
        });
    }
    /**
     * Build a route for an article page
     * @returns string
     */
    public getArticlePageRoute(): string | undefined {
        if (!this.isAdminPreview && this.hasArticlePermalinkParams()) {
            return this.getArticlePermalinkRoute();
        }

        if (this.isAdminPreview) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.article,
                {
                    publicationId: this.publicationId,
                    chapterId: this.chapterId,
                    id: this.articleId,
                }
            );
        }

        return RouteUtils.getUrl(siteMap.publications.deprecated.article, {
            publicationId: this.publicationId,
            chapterId: this.chapterId,
            id: this.articleId,
        });
    }

    /**
     * Build a route for the chapter page
     * @returns string
     */
    public getChapterPageRoute(): string | undefined {
        if (!this.isAdminPreview && this.hasChapterPermalinkParams()) {
            return this.getChapterPermalinkRoute();
        }
        if (this.isAdminPreview) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.chapter,
                {
                    publicationId: this.publicationId,
                    id: this.chapterId,
                }
            );
        }
        return RouteUtils.getUrl(siteMap.publications.deprecated.chapter, {
            publicationId: this.publicationId,
            id: this.chapterId,
        });
    }
    /**
     * For any model, return the valid url to be used as the href attribute on an anchor
     * @returns string
     */
    public getRoute(includeHash = true): string | undefined {
        if (
            this.chapterId != null &&
            this.publicationType !== PublicationTypes.NFC &&
            this.articleId == null &&
            (this.sectionId != null || this.rootSectionId != null)
        ) {
            return this.buildRoute(
                this.getSectionPageRoute(this.rootSectionId ?? this.sectionId),
                this.isTable()
            );
        }

        if (this.articleId != null) {
            return this.buildRoute(this.getArticlePageRoute(), includeHash);
        }

        if (this.chapterId != null) {
            return this.buildRoute(this.getChapterPageRoute(), includeHash);
        }

        if (this.annexId != null) {
            const hasGroupBy = StringUtils.hasValue(this.groupBy);
            return hasGroupBy
                ? this.buildRoute(
                      this.getAnnexPageRoute(),
                      includeHash && this.sectionId != null
                  )
                : this.buildRoute(this.getAnnexPageRoute(), includeHash);
        }

        return undefined;
    }
    /**
     * Build a route for the section page
     * @param  {number|undefined} sectionId
     * @returns string
     */
    public getSectionPageRoute(
        sectionId: number | undefined
    ): string | undefined {
        if (this.isAdminPreview === true) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.section,
                {
                    publicationId: this.publicationId,
                    chapterId: this.chapterId,
                    id: sectionId,
                }
            );
        }

        if (this.hasSectionPermalinkParams()) {
            const externalId = this.hasParentParams()
                ? this.parentExternalId
                : this.externalId;

            return RouteUtils.getUrl(siteMap.publications.permalinks.section, {
                chapterNfpaLabel: this.chapterNfpaLabel,
                code: this.publicationCode,
                edition: this.publicationEdition,
                externalId,
            });
        }

        return RouteUtils.getUrl(siteMap.publications.deprecated.section, {
            publicationId: this.publicationId,
            chapterId: this.chapterId,
            id: sectionId,
        });
    }

    public with(values: Partial<PublicationAnchor>): PublicationAnchorRecord {
        return new PublicationAnchorRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private buildRoute(route: string | undefined, includeHash: boolean = true) {
        if (includeHash) {
            route = route?.concat(this.getExternalIdHash());
        }

        return route;
    }

    private getAnnexGroupPageRoute(): string | undefined {
        if (this.isAdminPreview) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.annexGroup,
                {
                    publicationId: this.publicationId,
                    annexId: this.annexId,
                    id: this.groupBy,
                }
            );
        }

        if (this.hasAnnexPermalinkParams()) {
            return RouteUtils.getUrl(
                siteMap.publications.permalinks.annexGroup,
                {
                    code: this.publicationCode,
                    edition: this.publicationEdition,
                    nfpaLabel: this.annexNfpaLabel,
                    id: this.groupBy,
                }
            );
        }

        return RouteUtils.getUrl(siteMap.publications.deprecated.annexGroup, {
            publicationId: this.publicationId,
            annexId: this.annexId,
            id: this.groupBy,
        });
    }

    private getAnnexPagePermalinkRoute(): string | undefined {
        return RouteUtils.getUrl(siteMap.publications.permalinks.annex, {
            code: this.publicationCode,
            edition: this.publicationEdition,
            nfpaLabel: this.annexNfpaLabel,
        });
    }

    private getArticlePermalinkRoute(): string | undefined {
        return RouteUtils.getUrl(siteMap.publications.permalinks.article, {
            code: this.publicationCode,
            edition: this.publicationEdition,
            chapterNfpaLabel: this.chapterNfpaLabel,
            nfpaLabel: this.articleNfpaLabel,
        });
    }

    private getChapterPermalinkRoute(): string | undefined {
        return RouteUtils.getUrl(siteMap.publications.permalinks.chapter, {
            code: this.publicationCode,
            edition: this.publicationEdition,
            nfpaLabel: this.chapterNfpaLabel,
        });
    }

    private getExternalIdHash(): string {
        if (
            this.hasParentParams() ||
            this.sectionId != null ||
            this.partId != null
        ) {
            return `#${this.externalId}`;
        }

        return "";
    }

    private hasAnnexPermalinkParams(): boolean {
        return (
            this.hasPublicationPermalinkParams() && this.annexNfpaLabel != null
        );
    }

    private hasArticlePermalinkParams(): boolean {
        return (
            this.hasChapterPermalinkParams() && this.articleNfpaLabel != null
        );
    }

    private hasChapterPermalinkParams(): boolean {
        return (
            this.hasPublicationPermalinkParams() &&
            this.chapterNfpaLabel != null
        );
    }

    private hasPublicationPermalinkParams(): boolean {
        return this.publicationCode != null && this.publicationEdition != null;
    }

    private hasSectionPermalinkParams(): boolean {
        return this.hasChapterPermalinkParams() && this.externalId != null;
    }

    private hasParentParams(): boolean {
        return this.parentExternalId != null && this.parentType != null;
    }

    private isTable(): boolean {
        return this.type === "table";
    }

    // #endregion Private Methods
}
