import LocalizationUtils from "utilities/localization-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

export default function useConfigurableAlertMessages() {
    const { globalState } = useGlobalState();
    const currentCultureCode =
        LocalizationUtils.currentCultureCode() ?? "en-US";

    const subscriptionFreezeActive =
        globalState.systemSettings?.configurableAlertMessages
            ?.subscriptionsOffline.isActive;

    const subscriptionsOfflineConfigurableAlert =
        globalState.systemSettings?.configurableAlertMessages
            ?.subscriptionsOffline;

    const alertMessageContents =
        currentCultureCode === "en-US"
            ? subscriptionsOfflineConfigurableAlert?.messageContents.enUs
            : subscriptionsOfflineConfigurableAlert?.messageContents.esEs;

    return {
        subscriptionFreezeActive,
        subscriptionsOfflineConfigurableAlert,
        alertMessageContents,
    };
}
