import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { ErrorFallbackClassName } from "molecules/errors/error-fallback";
import { useLocalization } from "utilities/hooks/use-localization";
import OfflineAlertBanner from "organisms/banners/offline-alert-banner";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineFallbackProps extends FallbackProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineFallback: React.FunctionComponent<OfflineFallbackProps> = (
    props: OfflineFallbackProps
) => {
    const { resetErrorBoundary } = props;
    const { t } = useLocalization();
    const history = useHistory();
    const onClick = () => {
        history.push(siteMap.account.offline);
        resetErrorBoundary();
    };

    const buttonText = t("viewItem", { item: t("offline-settings-header") });
    return (
        <div className={ErrorFallbackClassName}>
            <OfflineAlertBanner buttonText={buttonText} onClick={onClick} />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default OfflineFallback;

// #endregion Export
