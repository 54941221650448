import { AnchorTargetTypes } from "andculturecode-javascript-core";
import { useLocalization } from "utilities/hooks/use-localization";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import FeatureFlag from "molecules/feature-flags/feature-flag";
import LanguagePreferencesModal from "organisms/modals/language-preferences-modal/language-preferences-modal";
import UserTopicsList from "organisms/user-topics-list/user-topics-list";
import React, { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useDebounce from "utilities/hooks/use-debounce";
import usePageErrors from "utilities/hooks/use-page-errors";
import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";
import ExternalTopicService from "utilities/services/topics/external-topic-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import UserWorkGroupsList from "organisms/publication-content/user-workgroups/user-workgroups-list";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { NfpaUrlPathsV2 } from "constants/nfpa-urls/nfpa-url-paths-v2";
import useTopics from "../../../../utilities/hooks/domain/topics/use-topics";
import Topic from "../../../../models/interfaces/topic";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountInformationTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__information";
const UPDATE_TOPICS_ERROR = "There was an issue updating topics.";
const UPDATE_TOPICS_SUCCESSFUL = "Topics successfully updated.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountInformationTabPanel: React.FC<AccountInformationTabPanelProps> =
    () => {
        const { globalState } = useGlobalState();
        const { t } = useLocalization<CultureResources>();
        const { isOnline } = useNetworkInformation();
        const { useAzureB2CForSSO, useWorkGroups, bypassHermes } =
            useFeatureFlags();
        const { loading, loaded, resultObject: topics } = useTopics();

        const accountInfoLabel = t("accountInformationTab-header");
        const editButtonLabel = t("edit");
        const emailLabel = t("email");
        const emailNotAvailable = t("field-notAvailable", { item: emailLabel });
        const languageLabel = t("language");
        const linkText = useAzureB2CForSSO
            ? t("accountDashboard-manageAccount")
            : t("accountDashboard-manageProfile", {
                  address: "www.nfpa.org",
              });
        const nameLabel = t("field-fullName");
        const nameNotAvailable = t("field-notAvailable", { item: nameLabel });
        const passwordLabel = t("password");
        const preferencesLabel = t("preference_plural");
        const topicsDescription = t("topicsOfInterest-description");
        const topicsLabel = t("topic-of-interest_plural");

        const isEnterprise = globalState.currentIdentity
            ?.getCurrentUserRole()
            ?.isEnterpriseRole();
        const showWorkGroups = isEnterprise && useWorkGroups;

        const email = globalState.currentIdentity?.user?.email;

        const fullName =
            globalState.currentIdentity?.user?.getFirstAndLastName();
        const password = UnicodeCharacterConstants.Bullet.repeat(12);
        const preferredLanguageSetting =
            globalState.getUserSetting("PreferredLanguage");
        const [externalTopicsLoaded, setExternalTopicsLoaded] =
            useState<boolean>(false);
        const [refreshingTopics, setRefreshingTopics] = useState(false);
        const [selectedTopicIds, setSelectedTopicIds] = useState<number[]>([]);
        const [shouldOpenLanguageModal, setShouldOpenLanguageModal] =
            useState(false);
        const { handlePageLoadError, pageErrors } = usePageErrors();
        const getExternalTopicsApi = ExternalTopicService.list;

        const debouncedRefreshFlag = useDebounce(refreshingTopics, 1000);

        const updateUserTopics = useCallback(async () => {
            const selectedTopics: Topic[] = topics.filter(
                (x) => x.id && selectedTopicIds.includes(x.id)
            );

            try {
                await ExternalTopicService.updateSelected(selectedTopics);

                ToastManager.success(UPDATE_TOPICS_SUCCESSFUL);
            } catch (result) {
                handlePageLoadError(result);
                ToastManager.error(UPDATE_TOPICS_ERROR);
            }
            setRefreshingTopics(false);
        }, [handlePageLoadError, selectedTopicIds, topics]);

        useEffect(() => {
            if (!debouncedRefreshFlag) {
                return;
            }

            updateUserTopics();
        }, [debouncedRefreshFlag, updateUserTopics]);

        useEffect(() => {
            if (externalTopicsLoaded || bypassHermes) {
                return;
            }

            async function getUserTopics() {
                const response = await getExternalTopicsApi();

                const externalTopics = response.resultObjects ?? [];
                setSelectedTopicIds(externalTopics.map((u) => u.id));
                setExternalTopicsLoaded(true);
            }

            try {
                getUserTopics();
            } catch (result) {
                handlePageLoadError(result);
            }
        }, [
            bypassHermes,
            externalTopicsLoaded,
            getExternalTopicsApi,
            handlePageLoadError,
            pageErrors,
        ]);

        const handleCheck = (
            e: React.ChangeEvent<HTMLInputElement>,
            identifier?: number
        ) => {
            let topics = selectedTopicIds.some((s) => s === identifier!)
                ? selectedTopicIds.filter((t) => t !== identifier)
                : selectedTopicIds.concat(identifier!);

            setSelectedTopicIds(topics);
            setRefreshingTopics(true);
        };

        const linkUrl = useAzureB2CForSSO
            ? globalState
                  .getSystemSettings()
                  .getNfpaUrlV2(NfpaUrlPathsV2.MyProfile)
            : globalState
                  .getSystemSettings()
                  .getNfpaUrl(NfpaUrlPaths.MyProfile);

        const togglePrefferedLanguageModal = () =>
            setShouldOpenLanguageModal((previous: boolean) => !previous);

        return (
            <div className={BASE_CLASS}>
                <div className={`${BASE_CLASS}__personal`}>
                    <Heading priority={HeadingPriority.Five}>
                        {accountInfoLabel}
                    </Heading>
                    {displayInformation(
                        nameLabel,
                        StringUtils.isEmpty(fullName)
                            ? nameNotAvailable
                            : fullName!
                    )}
                    {displayInformation(
                        emailLabel,
                        StringUtils.isEmpty(email) ? emailNotAvailable : email!
                    )}
                    {displayInformation(passwordLabel, password)}
                    <Anchor
                        ariaLabel={linkText}
                        disabled={!isOnline}
                        external={true}
                        target={AnchorTargetTypes.Blank}
                        to={linkUrl}>
                        {linkText}
                    </Anchor>
                </div>
                <FeatureFlag feature={"i18N"}>
                    <FeatureFlag.Boolean value={true}>
                        <div className={`${BASE_CLASS}__personal`}>
                            <Heading priority={HeadingPriority.Five}>
                                {preferencesLabel}
                            </Heading>
                            {displayInformation(
                                languageLabel,
                                <span
                                    className={`${BASE_CLASS}__personal__language`}>
                                    {LocalizationUtils.getLanguageCodeFullLabel(
                                        preferredLanguageSetting?.value
                                    )}
                                    <Button
                                        disabled={!isOnline}
                                        onClick={togglePrefferedLanguageModal}
                                        style={ButtonStyles.Anchor}>
                                        {editButtonLabel}
                                    </Button>
                                </span>
                            )}
                        </div>
                    </FeatureFlag.Boolean>
                </FeatureFlag>
                {showWorkGroups && (
                    <div className={`${BASE_CLASS}__workgroups`}>
                        <UserWorkGroupsList />
                    </div>
                )}

                {!bypassHermes && (
                    <div className={`${BASE_CLASS}__topics`}>
                        <Heading priority={HeadingPriority.Five}>
                            {topicsLabel}
                        </Heading>
                        <Paragraph
                            cssClassName="-interest"
                            size={ParagraphSizes.Small}>
                            {topicsDescription}
                        </Paragraph>
                        <UserTopicsList
                            disabled={debouncedRefreshFlag || !isOnline}
                            isSelectionRequired={true}
                            onCheck={handleCheck}
                            selectedTopicIds={selectedTopicIds}
                            loaded={loaded}
                            loading={loading}
                            topics={topics}
                        />
                        {debouncedRefreshFlag && (
                            <Loader
                                accessibleText={`${t(
                                    "saving"
                                )} ${topicsLabel.toLocaleLowerCase(
                                    globalState.getPreferredOrCurrentCulture()
                                )}`}
                            />
                        )}
                    </div>
                )}

                <FeatureFlag feature={"i18N"}>
                    <FeatureFlag.Boolean value={true}>
                        <LanguagePreferencesModal
                            isVisible={shouldOpenLanguageModal}
                            onHeaderClose={togglePrefferedLanguageModal}
                            onCancel={togglePrefferedLanguageModal}
                            onSave={togglePrefferedLanguageModal}
                        />
                    </FeatureFlag.Boolean>
                </FeatureFlag>
            </div>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const displayInformation = (
    heading: string,
    information: string | JSX.Element
): React.ReactNode => {
    return (
        <div className={`${BASE_CLASS}-display`}>
            <Heading priority={HeadingPriority.Six}>{heading}</Heading>
            <Paragraph>{information}</Paragraph>
        </div>
    );
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountInformationTabPanel;

// #endregion Exports
