import { AppNameTm } from "constants/app-name-tm";
import React, { ReactNode, useState } from "react";
import AlertLevels from "constants/alert-levels";
import { siteMap } from "internal-sitemap";
import AlertBanner from "molecules/alerts/alert-banner";
import Anchor from "atoms/anchors/anchor";
import StringUtils from "utilities/string-utils";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import Button from "atoms/buttons/button";
import EcommerceRedirectionModal from "organisms/subscription-card/ecommerce-redirection-modal";
import { t } from "utilities/localization-utils";
import RoleType from "utilities/enumerations/role-type";
import RoleRecord from "models/view-models/role-record";
import useModalActions from "utilities/hooks/use-modal-actions";
import ContactModal from "pages/support/contact-modal";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountExpirationAlertBannerProps {
    currentRole?: RoleRecord;
    cssClassName?: string;
    daysUntilExpiration: number;
    errorWindowInDays: number;
    isAutoRenewing: boolean;
    isExpired: boolean;
    isGroupAdmin: boolean;
    pin: string;
    warningWindowInDays: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-account-expiration-alerts";
const DAY_TEXT = "Day";
const EXPIRED_TEXT = [
    {
        text: `Your ${AppNameTm} subscription has expired.`,
    },
    {
        text: "To avoid an interruption in access, please",
    },
    {
        text: "contact customer service",
        link: `${siteMap.support.faq}#payment-info`,
    },
    {
        text: "to resolve your payment issue.",
    },
];

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountExpirationAlertBanner: React.FC<AccountExpirationAlertBannerProps> =
    (props: AccountExpirationAlertBannerProps) => {
        const {
            currentRole,
            cssClassName,
            daysUntilExpiration,
            errorWindowInDays,
            isAutoRenewing,
            isExpired,
            isGroupAdmin,
            pin,
            warningWindowInDays,
        } = props;

        const baseClass = StringUtils.hasValue(cssClassName)
            ? `${BASE_CLASS_NAME} ${cssClassName}`
            : BASE_CLASS_NAME;

        const [redirecting, setRedirecting] = useState(false);
        const {
            isOpen: isContactModalOpen,
            handleOpen: handleOpenContactModal,
            handleClose: handleCloseContactModal,
        } = useModalActions();
        const isMobile = useBreakpoint(
            Breakpoints.Phone,
            BreakpointComparer.MaxWidth
        );

        const isInWarningWindow = daysUntilExpiration <= warningWindowInDays;

        const isInErrorWindow = daysUntilExpiration <= errorWindowInDays;

        const currentRoleIsTeam = currentRole?.is(RoleType.TEAM) ?? false;
        const userIsIndividual = currentRole?.is(RoleType.INDIVIDUAL) ?? false;
        const userIsTeamAdmin = currentRoleIsTeam && isGroupAdmin;
        const userIsEnterpriseAdmin =
            currentRole?.is(RoleType.ENTERPRISE) && isGroupAdmin;
        const userIsTeamMember = currentRoleIsTeam && !isGroupAdmin;

        const nonAutoRenewingAlertLevel = isInErrorWindow
            ? AlertLevels.Error
            : AlertLevels.Warning;

        if (isAutoRenewing) {
            if (isExpired && !userIsTeamMember) {
                return (
                    <div className={baseClass}>
                        <AlertBanner alertLevel={AlertLevels.Error}>
                            {EXPIRED_TEXT.map((e, index) => {
                                if (StringUtils.isEmpty(e.link)) {
                                    return (
                                        <span key={index}>
                                            &nbsp;{e.text}&nbsp;
                                        </span>
                                    );
                                }

                                return (
                                    <Anchor key={index} to={e.link!}>
                                        {e.text}
                                    </Anchor>
                                );
                            })}
                        </AlertBanner>
                    </div>
                );
            }

            return null;
        }

        if (!isInWarningWindow) {
            return null;
        }

        if (currentRole?.is(RoleType.FREE_TRIAL) === true) {
            return (
                <div className={`${baseClass} -cta`}>
                    <AlertBanner alertLevel={nonAutoRenewingAlertLevel}>
                        {nonAutoRenewingText(
                            daysUntilExpiration,
                            isMobile,
                            true
                        )}{" "}
                        <Anchor
                            cssClassName="c-button -primary"
                            to={siteMap.account.subscription}>
                            {t("select-plan")}
                        </Anchor>
                    </AlertBanner>
                </div>
            );
        }

        if (userIsIndividual || userIsTeamAdmin || userIsEnterpriseAdmin) {
            const buttonText = userIsEnterpriseAdmin
                ? t("contactSalesToRenew")
                : `${StringUtils.capitalize(t("renew"))} ${t(
                      "my-current-plan"
                  )}`;
            const handleClick = userIsEnterpriseAdmin
                ? handleOpenContactModal
                : () => setRedirecting(true);
            return (
                <div className={`${baseClass} -cta`}>
                    <AlertBanner alertLevel={nonAutoRenewingAlertLevel}>
                        {nonAutoRenewingText(daysUntilExpiration, isMobile)}
                        <Button onClick={handleClick}>
                            <div>{buttonText}</div>
                        </Button>
                        {redirecting && (
                            <EcommerceRedirectionModal
                                pin={pin}
                                withPurchaseText={false}
                            />
                        )}
                        {isContactModalOpen && (
                            <ContactModal
                                closeDialog={handleCloseContactModal}
                                isVisible={true}
                                returnURL={siteMap.dashboards.user}
                                selectedReasonOption={t(
                                    "contactReasons-renewEnterpriseSubscription"
                                )}
                            />
                        )}
                    </AlertBanner>
                </div>
            );
        }

        return null;
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const nonAutoRenewingText = (
    daysUntilExpiration: number,
    isMobile: boolean,
    isFreeTrial: boolean = false
): ReactNode => {
    const day = StringUtils.pluralize(daysUntilExpiration, DAY_TEXT);

    const renewText = isFreeTrial
        ? t("upgrade")
        : StringUtils.capitalize(t("renew"));
    const planText = isFreeTrial ? t("your-free-trial") : t("your-plan");
    return (
        <span>
            {planText}
            <span className={`${BASE_CLASS_NAME}__days-remaining`}>
                &nbsp;
                {`expires in ${daysUntilExpiration} ${day}.`}
                &nbsp;
            </span>
            {!isMobile && `${renewText} ${StringUtils.capitalize(t("now"))}!`}
        </span>
    );
};

//#endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AccountExpirationAlertBanner;

// #endregion Export
