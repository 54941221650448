import * as React from "react";

export interface CommitteesMemberClassificationProps {
    children?: JSX.Element[];
}

const CommitteesMemberClassification = (
    props: CommitteesMemberClassificationProps
) => (
    <span className="c-committee-member__classification">{props.children}</span>
);

export default CommitteesMemberClassification;
