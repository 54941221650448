import React from "react";
import Heading from "atoms/typography/heading";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import { HeadingPriority } from "atoms/constants/heading-priority";
import PublicationBatchBadge from "./publication-batch-badge";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { Redirect } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import PublicationBatchBulkActions from "./publication-batch-bulk-actions";
import PublicationBatchReprocessStatuses from "./batchStatuses/publication-batch-reprocess-statuses";

const CSS_CLASS_NAME = "c-publication-batch";

const PublicationBatch: React.FC = () => {
    const { allowBatchPublications } = useFeatureFlags();

    const {
        context: { publicationBatch },
    } = useAdminEditorPageContext();

    const batchHeading = `${publicationBatch.length} Publication${
        publicationBatch.length > 1 ? "s" : ""
    } Selected`;

    if (!allowBatchPublications) {
        return (
            <Redirect
                to={RouteUtils.getUrl(siteMap.admin.publications.dashboard)}
            />
        );
    }

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__batch-heading`}
                    priority={HeadingPriority.Five}>
                    {batchHeading}
                </Heading>
                <PublicationBatchBadge />
                <PublicationBatchBulkActions />
            </div>
            <PublicationBatchReprocessStatuses />
        </React.Fragment>
    );
};

export default PublicationBatch;
