import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import SituationRelationshipCard from "organisms/situational-navigation/situations/situation-relationship-card";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationRelationshipsSectionProps {
    hotSpotImageFileId?: number;
    situationRelationships: Array<SituationRelationshipRecord>;
    solutions: Array<SolutionRecord>;
    situations: Array<SituationRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationRelationshipsSection: React.FC<SituationRelationshipsSectionProps> =
    (props: SituationRelationshipsSectionProps) => {
        const CSS_BASE_CLASS = "c-situation-relationships-section";

        const getSolution = (id?: number) =>
            props.solutions.find((s: SolutionRecord) => s.id === id);

        const getSituation = (id?: number) =>
            props.situations.find((s: SituationRecord) => s.id === id);

        const sortedSituationRelationships = () =>
            CollectionUtils.collapseDisplaySequencesAndSort(
                props.situationRelationships,
                (s: SituationRelationshipRecord, displaySequence: number) =>
                    s.with({ displaySequence })
            );

        return (
            <div className={CSS_BASE_CLASS}>
                {sortedSituationRelationships()
                    .map((s: SituationRelationshipRecord) => {
                        const solution = getSolution(s.relatedSolutionId);
                        const situation = getSituation(s.relatedSituationId);

                        if (solution == null && situation == null) {
                            return null;
                        }

                        const record: SituationRecord | SolutionRecord =
                            (solution ?? situation)!;

                        const recordLabel =
                            solution == null ? "situation" : "solution";

                        return (
                            <SituationRelationshipCard
                                accessibleText={`View ${recordLabel}, ${record.title}`}
                                key={`${recordLabel}-${record.id}`}
                                value={record}
                                situationRelationship={s}
                            />
                        );
                    })
                    .filter((solution) => solution != null)}
            </div>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationRelationshipsSection;

// #endregion Exports
