import Anchor from "atoms/anchors/anchor";
import Image from "atoms/images/image";
import { AppNameWithHtml } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import SubscriptionType from "organisms/enums/subscription-type";
import React, { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import freeAccessLogo from "assets/images/free-access-logo.svg";
import freeAccessLogoMobile from "assets/images/logo-color.svg";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import SubscriptionTypeUtils from "utilities/subscription-type-utils";
import { TrackingCode } from "constants/nfpa-urls/tracking-codes";

interface BannerNavigationProps {}

const BASE_CSS_CLASSNAME: string = "c-banner-navigation";
const BUTTON_CLASSNAME = "c-button -secondary";

const BannerNavigation: React.FunctionComponent<BannerNavigationProps> = (
    props: BannerNavigationProps
) => {
    const { currentIdentity } = useGlobalState().globalState;

    const isFreeAccessUser =
        !currentIdentity?.hasActiveSubscription() &&
        !currentIdentity?.hasHadFreeTrial();
    const hasHadFreeTrial =
        currentIdentity?.hasHadFreeTrial() &&
        !currentIdentity.hasActiveSubscription();
    const isSubscriber = currentIdentity?.hasActiveSubscription();

    const plansRoute = RouteUtils.getUrl(siteMap.signup.plans, null, {
        icid: TrackingCode.FreeAccessSubscribe,
    });
    const freeTrialRoute = RouteUtils.getUrl(
        siteMap.signup.registerPlanSelected,
        {
            subscriptionType: SubscriptionTypeUtils.toRouteParam(
                SubscriptionType.FreeTrial
            ),
        },
        { icid: TrackingCode.FreeAccessSubscribe }
    );
    const dashboardRoute = RouteUtils.getUrl(siteMap.dashboards.user);

    const FREE_TRIAL_TEXT = t("tryLinkFree", {
        appNameTm: AppNameWithHtml,
    });
    const SUBSCRIBE_TEXT = t("subscribeLink", { appNameTm: AppNameWithHtml });
    const DASHBOARD_TEXT = t("accessLink", { appNameTm: AppNameWithHtml });

    const { route, text } = useMemo(() => {
        if (isFreeAccessUser) {
            return { route: freeTrialRoute, text: FREE_TRIAL_TEXT };
        }

        if (hasHadFreeTrial) {
            return { route: plansRoute, text: SUBSCRIBE_TEXT };
        }

        if (isSubscriber) {
            return { route: dashboardRoute, text: DASHBOARD_TEXT };
        }

        return { route: "#", text: "" };
    }, [
        DASHBOARD_TEXT,
        FREE_TRIAL_TEXT,
        SUBSCRIBE_TEXT,
        dashboardRoute,
        freeTrialRoute,
        hasHadFreeTrial,
        isFreeAccessUser,
        isSubscriber,
        plansRoute,
    ]);

    return (
        <header className={BASE_CSS_CLASSNAME}>
            <div className={`${BASE_CSS_CLASSNAME}__logoWrapper`}>
                <picture>
                    <source
                        srcSet={freeAccessLogo}
                        media={`(min-width: ${Breakpoints.LargeTablet}px)`}
                    />
                    <Image
                        cssClassName={`${BASE_CSS_CLASSNAME}__logo`}
                        altText={"free access logo"}
                        src={freeAccessLogoMobile}
                    />
                </picture>
            </div>
            <div className={`${BASE_CSS_CLASSNAME}__ctas`}>
                <Anchor cssClassName={BUTTON_CLASSNAME} to={route}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </Anchor>
            </div>
        </header>
    );
};

export default BannerNavigation;
