import { QueryFunction, QueryKey, QueryFunctionContext } from "react-query";
import { ServiceResponse } from "andculturecode-javascript-core";
import {
    ListService,
    NestedListService,
    GetService,
} from "andculturecode-javascript-react";
import {
    NestedBatchedListService,
    BatchedListService,
} from "utilities/services/service-factory";

const QueryFunctionFactory = {
    get: <TRecord, TRouteParams, TQueryParams>(
        fn: GetService<TRecord, TRouteParams, TQueryParams>
    ): QueryFunction<ServiceResponse<TRecord>, QueryKey> => {
        return (context: QueryFunctionContext<QueryKey, any>) => {
            const { routeParams, queryParams } = context.queryKey[1] as any;
            return fn(routeParams, queryParams) as Promise<
                ServiceResponse<TRecord>
            >;
        };
    },
    list: <TRecord, TQueryParams>(
        fn: ListService<TRecord, TQueryParams>
    ): QueryFunction<ServiceResponse<TRecord>, QueryKey> => {
        return (context: QueryFunctionContext<QueryKey, any>) => {
            const queryParams = context.queryKey[1] as any;
            return fn(queryParams) as Promise<ServiceResponse<TRecord>>;
        };
    },
    nestedList: <TRecord, TRouteParams, TQueryParams>(
        fn: NestedListService<TRecord, TRouteParams, TQueryParams>
    ): QueryFunction<ServiceResponse<TRecord>, QueryKey> => {
        return (context: QueryFunctionContext<QueryKey, any>) => {
            const { routeParams, queryParams } = context.queryKey[1] as any;
            return fn(routeParams, queryParams) as Promise<
                ServiceResponse<TRecord>
            >;
        };
    },
    batchedList: <TRecord, TQueryParams>(
        fn: BatchedListService<TRecord, TQueryParams>
    ): QueryFunction<ServiceResponse<TRecord>, QueryKey> => {
        return (context: QueryFunctionContext<QueryKey, any>) => {
            const { queryParams, propertyName, size } = context
                .queryKey[1] as any;
            return fn(queryParams, propertyName, size) as Promise<
                ServiceResponse<TRecord>
            >;
        };
    },
    nestedBatchedList: <TRecord, TRouteParams, TQueryParams>(
        fn: NestedBatchedListService<TRecord, TRouteParams, TQueryParams>
    ): QueryFunction<ServiceResponse<TRecord>, QueryKey> => {
        return (context: QueryFunctionContext<QueryKey, any>) => {
            const { routeParams, queryParams, propertyName, size } = context
                .queryKey[1] as any;
            return fn(routeParams, queryParams, propertyName, size) as Promise<
                ServiceResponse<TRecord>
            >;
        };
    },
};
export default QueryFunctionFactory;
