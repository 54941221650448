import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { ModalTypes } from "molecules/constants/modal-types";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import Modal from "molecules/modals/modal";
import RichTextEditor from "molecules/rich-text/rich-text-editor";
import React, { useEffect, useState } from "react";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import useChangeSummaries from "utilities/hooks/domain/change-summaries/use-change-summaries";
import { ToastManager } from "utilities/toast/toast-manager";

export interface ChangeSummariesModalProps {
    externalId: string;
    title: string;
    code: string;
    edition: string;
    publicationComponentType: PublicationComponentType;
    onClose: () => void;
}

const CSS_CLASS_NAME = "c-enhanced-content-modal";
const AddEditChangeSummariesModal = React.memo(
    (props: ChangeSummariesModalProps) => {
        const {
            externalId,
            title,
            code,
            edition,
            publicationComponentType: publicationType,
            onClose,
        } = props;
        const [bodyDraft, setBodyDraft] = useState("");
        const [showConfirmationModal, setShowConfirmationModal] =
            useState(false);
        const { existingChangeSummary, addOrUpdateChangeSummary } =
            useChangeSummaries({
                externalId,
                code,
                edition,
                title,
                publicationType,
            });

        const handleChange = (bodyDraft: string) => {
            setBodyDraft(bodyDraft);
        };
        const handleSave = async () => {
            const success = await addOrUpdateChangeSummary(bodyDraft);
            if (!success) {
                ToastManager.error("There was an issue saving change summary.");
                return;
            }
            ToastManager.success("Change Summary Saved! 🎉");
            onClose();
        };
        const confirmBeforeClose = () => {
            setShowConfirmationModal(true);
        };

        useEffect(() => {
            if (
                existingChangeSummary != null &&
                externalId === existingChangeSummary.externalId
            ) {
                setBodyDraft(existingChangeSummary.bodyDraft ?? "");
            }
        }, [existingChangeSummary, externalId]);

        const addEditChangeSummaryLabel = "Add/Edit Change Summary";
        return (
            <>
                <Modal
                    closeDialog={confirmBeforeClose}
                    isVisible={true}
                    label={addEditChangeSummaryLabel}
                    type={ModalTypes.Base}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <Heading priority={HeadingPriority.Two}>
                            {addEditChangeSummaryLabel}
                        </Heading>
                        <ModalCloseButton onClick={confirmBeforeClose} />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__body`}>
                        <div className={`${CSS_CLASS_NAME}__content`}>
                            <Paragraph
                                cssClassName={`${CSS_CLASS_NAME}__content__title`}>
                                {title}
                            </Paragraph>
                            <RichTextEditor
                                allowImages={true}
                                onChange={handleChange}
                                placeholder={"Changed Summary body..."}
                                value={bodyDraft}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__footer`}>
                            <Button
                                onClick={confirmBeforeClose}
                                style={ButtonStyles.Secondary}>
                                Cancel
                            </Button>
                            <Button
                                disabled={false}
                                onClick={handleSave}
                                style={ButtonStyles.Primary}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal>
                {showConfirmationModal && (
                    <ConfirmationModal
                        onCancel={() => setShowConfirmationModal(false)}
                        onConfirm={onClose}
                        isVisible={showConfirmationModal}
                        label="Are you sure?"
                        message="If you close this editor without saving, any changes you made will be lost."
                    />
                )}
            </>
        );
    }
);

export default AddEditChangeSummariesModal;
