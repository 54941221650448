import * as React from "react";

import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface HomeBreadcrumbProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const HomeBreadcrumb: React.FunctionComponent<HomeBreadcrumbProps> = () => {
    const { t } = useLocalization();
    return (
        <React.Fragment>
            <Icon type={Icons.Home} />
            <span className="c-breadcrumb__label">{t("home")}</span>
            <span className="sr-only">{t("home")}</span>
        </React.Fragment>
    );
};

// #endregion Component

export default HomeBreadcrumb;
