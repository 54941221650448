import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { match } from "react-router-dom";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { Translator } from "utilities/types/translator-type";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const translatePageTitle = (t: Translator<CultureResources>) =>
    t("breadcrumbs-myLink-myBookmarks");

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyBookmarksBreadcrumbProps {
    match: match;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyBookmarksBreadcrumb: React.FC<MyBookmarksBreadcrumbProps> = (
    props: MyBookmarksBreadcrumbProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const { match } = props;

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();

    useHeaderData({ match, title: translatePageTitle(t) });

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{translatePageTitle(t)}</span>
        </Breadcrumb>
    );

    // #endregion Returns
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MyBookmarksBreadcrumb;

// #endregion Exports
