import Anchor from "atoms/anchors/anchor";
import Publishable from "models/interfaces/publishable";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React, { useCallback, useEffect, useState } from "react";
import { siteMap } from "internal-sitemap";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EditorSidebarListItemsProps {
    baseClassName: string;
    items: Array<SituationRecord | SolutionRecord>;
    type: typeof SituationRecord | typeof SolutionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditorSidebarListItems: React.FC<EditorSidebarListItemsProps> = (
    props: EditorSidebarListItemsProps
) => {
    const { context } = useAdminEditorPageContext();

    const isSelected = useCallback(
        (record: Publishable) =>
            !RouteUtils.assertCurrentUrl(
                siteMap.admin.situationalNavigation.situations.create
            ) &&
            !RouteUtils.assertCurrentUrl(
                siteMap.admin.situationalNavigation.solutions.create
            ) &&
            record.id === context.currentValue?.id,
        [context.currentValue]
    );

    const getListItemClassName = (record: Publishable): string => {
        const classNames = [
            `${props.baseClassName}__content__left__list__item`,
            "c-button",
            "-tertiary-alt",
        ];

        if (isSelected(record)) {
            classNames.push("-selected");
        }

        return classNames.join(" ");
    };

    const getEditLink = useCallback(
        (record: Publishable) =>
            props.type === SituationRecord
                ? RouteUtils.getUrl(
                      siteMap.admin.situationalNavigation.situations.edit,
                      { id: record.id }
                  )
                : RouteUtils.getUrl(
                      siteMap.admin.situationalNavigation.solutions.edit,
                      { id: record.id }
                  ),
        [props.type]
    );

    const [previousItemListSize, setPreviousItemListSize] = useState(0);

    useEffect(() => {
        if (props.items.length > previousItemListSize) {
            // scroll to newly created item in the sidebar
            const elementId = `editor-sidebar-item-${
                props.items[props.items.length - 1].id
            }`;
            ScrollUtils.scrollToElementBySelector(`#${elementId}.-selected`);
        }

        setPreviousItemListSize(props.items.length);
    }, [props.items, previousItemListSize]);

    return (
        <React.Fragment>
            {props.items.map((record: SituationRecord | SolutionRecord) => (
                <Anchor
                    cssClassName={getListItemClassName(record)}
                    id={`editor-sidebar-item-${record.id}`}
                    to={getEditLink(record)}
                    key={record.id}>
                    <div
                        className={`${props.baseClassName}__content__left__list__item__title`}>
                        <div className="-text">{record.titleDraft}</div>
                        <PublishStatusBadge
                            hasUnpublishedChanges={record.hasUnpublishedChanges()}
                            small={true}
                            status={record.getPublishStatus()}
                        />
                    </div>
                    <div
                        className={`${props.baseClassName}__content__left__list__item__subtitle`}>
                        {record.subtitleDraft}
                    </div>
                </Anchor>
            ))}
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EditorSidebarListItems;

// #endregion Exports
