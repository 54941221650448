import React from "react";
import Breadcrumb from "atoms/breadcrumbs/breadcrumb";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SolutionsBreadcrumbProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SolutionsBreadcrumb: React.FC<SolutionsBreadcrumbProps> = () => {
    return (
        <React.Fragment>
            <Breadcrumb>
                <span className="c-breadcrumb__label">Solutions</span>
            </Breadcrumb>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionsBreadcrumb;

// #endregion Exports
