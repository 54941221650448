import debounce from "lodash/debounce";
import WindowContextRecord from "models/view-models/window-context-record";
import React, {
    createContext,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import { WindowEvents } from "utilities/enumerations/window-events";

export interface WindowContextProviderProps {}

const defaultState: WindowContextRecord = new WindowContextRecord();

export const WindowContext = createContext<WindowContextRecord>(defaultState);

const WindowContextProvider = (
    props: PropsWithChildren<WindowContextProviderProps>
) => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    const [windowContext, setWindowContext] = useState(
        new WindowContextRecord({ height, width })
    );

    const delay = 250;

    const getDimensions = () => {
        setWindowContext(
            new WindowContextRecord({
                height: window.innerHeight,
                width: window.innerWidth,
            })
        );
    };

    const handleWindowResize = debounce(getDimensions, delay);

    useEffect(() => {
        window.addEventListener(WindowEvents.Resize, handleWindowResize);

        return () =>
            window.removeEventListener(WindowEvents.Resize, handleWindowResize);
    }, [handleWindowResize]);

    return (
        <WindowContext.Provider value={windowContext}>
            {props.children}
        </WindowContext.Provider>
    );
};

export default WindowContextProvider;
