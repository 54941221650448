import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React, { CSSProperties } from "react";
import StringUtils from "utilities/string-utils";
import StringIndexedObject from "utilities/types/string-indexed-object";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ALLOWED_STYLE_PROPERTIES = ["width", "height"];

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface HtmlConverterProps extends XmlConvertedComponentProps {
    /**
     * HTML specific attribute for styling
     */
    style?: string;
    src?: string;
    "data-src"?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

/**
 * An XML converter which returns the HTML as-is.
 * @param props {XmlConvertedComponentProps}
 */
const HtmlConverter: React.FC<HtmlConverterProps> = (
    props: HtmlConverterProps
) => {
    const { tagName, style, children, ...restProps } = props;

    // Clone props to be able to manipulate on a case-by-case basis
    let convertedProps = { ...restProps };

    // Lazyload images from richtext editor images. See lazysizes package (https://www.npmjs.com/package/lazysizes)
    if (tagName === "img" && restProps.hasOwnProperty("src")) {
        convertedProps = {
            ...restProps,
            src: "",
            "data-src": restProps.src,
            className: `${restProps.className} lazyload`,
        };
    }

    return React.createElement(
        tagName,
        {
            ...convertedProps,
            style: convertToCSSProperties(style),
        },
        children
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default HtmlConverter;

// #endregion Exports

// -----------------------------------------------------------------------------------------
// #region Pure Functions
// -----------------------------------------------------------------------------------------

/**
 * Converts an html style attribute into a css properties object that can be consumed by react components
 *
 * @param {string} [htmlStyle]
 * @return {*}  {CSSProperties}
 */
function convertToCSSProperties(htmlStyle?: string): CSSProperties {
    if (htmlStyle == null) {
        return {};
    }

    const style: StringIndexedObject<string> = {};
    htmlStyle.split(";").forEach((el) => {
        const [property, value] = el.split(":");

        if (!property) return;

        const formattedProperty = StringUtils.camelCase(property.trim());

        const stylePropertyIsAllowed =
            ALLOWED_STYLE_PROPERTIES.find((p) => p === formattedProperty) !=
            null;

        if (stylePropertyIsAllowed) {
            style[formattedProperty] = value.trim();
        }
    });

    return style;
}

// #endregion Pure Functions
