import * as React from "react";

export interface CommitteesMemberGroupProps {
    children?: React.ReactElement;
}

const CommitteesMemberGroup = (props: CommitteesMemberGroupProps) => {
    return <div className="c-committee-member-group">{props.children}</div>;
};

export default CommitteesMemberGroup;
