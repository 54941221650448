import * as React from "react";

export interface CommitteesMemberProps {
    children?: JSX.Element[];
}

const CommitteesMember = (props: CommitteesMemberProps) => (
    <div className="c-committee-member">{props.children}</div>
);

export default CommitteesMember;
