import { siteMap } from "internal-sitemap";
import { WebApplication, WithContext } from "schema-dts";
import useOffersStructuredData from "./use-offers-structured-data";

type PlansStructuredData = WithContext<WebApplication>;

export default function usePlansStructuredData(): string {
    const plansStructuredData: PlansStructuredData = {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "@id": "https://link.nfpa.org/#organization",
        applicationCategory: "BusinessApplication",
        name: "NFPA LiNK",
        operatingSystem: "all",
        browserRequirements:
            "Specifies browser requirements in human-readable text",
        url: `${window.location.origin}${siteMap.signup.plans}`,
        screenshot: "Dashboard image URL",
        offers: useOffersStructuredData(),
        creator: {
            "@type": "Organization",
            "@id": "https://www.nfpa.org/#organization",
            url: "https://www.nfpa.org/",
            name: "National Fire Protection Association",
            logo: {
                "@type": "ImageObject",
                url: "https://static.nfpa.org/UniversalNav/nfpa-logo-text-color.png",
                width: "46px",
                height: "49px",
            },
        },
    };

    return JSON.stringify(plansStructuredData);
}
