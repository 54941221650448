import CreateUserDto from "models/interfaces/create-user-dto";
import { useCallback, useState } from "react";
import { useSignupContext } from "utilities/contexts/signup/use-signup-context";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import useEmailAddressValidation from "../validation/use-email-validation";
import { t } from "utilities/localization-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import SignupContextRecord from "models/view-models/signup-context-record";
import StringUtils from "utilities/string-utils";
import CreateUserDtoRecord from "models/view-models/create-user-dto-record";
import { CreateUserDtoValidator } from "models/validation/create-user-dto-validator";
import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";
import AuthenticationProviderErrorKeys from "constants/authentication-provider-error-keys";
import UserService from "utilities/services/users/user-service";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import UserLoginRecord from "models/view-models/user-login-record";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { ScrollUtils } from "utilities/scroll-utils";
import useIdentity from "utilities/hooks/use-identity";
import GlobalStateRecord from "models/view-models/global-state-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

export default function useTrainingContractSignup() {
    const { ERROR_CREATE_USER_EMAIL_IN_USE } = AuthenticationProviderErrorKeys;

    const [emailValidationError, setEmailValidationError] = useState<string>();
    const [errorResult, setErrorResult] = useState<
        ObjectValidationResult<CreateUserDto>
    >({});
    const [creatingUser, setCreatingUser] = useState<boolean>(false);

    const { signupContext, setSignupContext } = useSignupContext();
    const { validateEmail } = useEmailAddressValidation();
    const { validateEmailAddresses } = useFeatureFlags();
    const { create: userCreateApi } = UserService.useCreate();
    const { create: userLoginCreateApi } = UserLoginService.useCreate();
    const { buildCurrentIdentity } = useIdentity();
    const { setGlobalState } = useGlobalState();
    const errorCreatingAccount = t("errors-actionResource_possessive", {
        action: t("creating"),
        resource: t("account"),
    });

    const handleFormFieldChange = (key: keyof CreateUserDto, value: any) => {
        setSignupContext((previousValue: SignupContextRecord) =>
            previousValue.with({
                newUser: previousValue.newUser?.set(key, value),
            })
        );
    };

    const handleEmailFormFieldBlur = async () => {
        if (!validateEmailAddresses) {
            return;
        }

        const emailAddress = signupContext.newUser.email;

        var validationResult = await validateEmail(emailAddress);

        if (validationResult.isValid) {
            setEmailValidationError(undefined);
            setErrorResult((previous) => {
                return { ...previous, email: [] };
            });

            return;
        }

        setEmailValidationError(validationResult.errorMessage ?? "");
    };

    const getEmailFormFieldErrors = (): string => {
        if (StringUtils.hasValue(emailValidationError)) {
            return emailValidationError;
        }

        return ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
            "email",
            errorResult
        );
    };

    const validate = (
        user: CreateUserDtoRecord,
        setErrorResult: React.Dispatch<
            React.SetStateAction<ObjectValidationResult<CreateUserDto>>
        >
    ) => {
        const validateResult = new CreateUserDtoValidator().validate(user);
        setErrorResult(validateResult);
        return ObjectValidator.hasErrors(validateResult);
    };

    const createUser = useCallback(async () => {
        const getFormFieldErrors = (
            result: any
        ): ObjectValidationResult<CreateUserDto> | undefined => {
            if (!RecordUtils.isRecord(result, ResultRecord)) {
                return;
            }

            if (
                result.doesNotHaveErrors() ||
                !result.hasErrorFor(ERROR_CREATE_USER_EMAIL_IN_USE)
            ) {
                return;
            }

            const emailError = result.getErrorMessageFor(
                ERROR_CREATE_USER_EMAIL_IN_USE
            )!;

            const validationResult: ObjectValidationResult<CreateUserDto> = {
                email: [emailError!],
            };
            return validationResult;
        };

        const handleAccountCreationError = () => {
            ToastManager.error(errorCreatingAccount);
            setCreatingUser(false);
        };

        try {
            setCreatingUser(true);
            if (
                validate(signupContext.newUser, setErrorResult) ||
                StringUtils.hasValue(emailValidationError)
            ) {
                ScrollUtils.scrollToTop();
                setCreatingUser(false);
                return;
            }

            const dto = signupContext.newUser;
            const userCreateResponse = await userCreateApi(dto);
            if (userCreateResponse.result?.hasErrors()) {
                handleAccountCreationError();
                return;
            }

            const userLoginCreateResponse = await userLoginCreateApi(
                new UserLoginRecord({
                    password: signupContext.newUser.password,
                    userName: signupContext.newUser.email,
                })
            );
            if (userLoginCreateResponse.result?.hasErrors()) {
                handleAccountCreationError();
                return;
            }
            const identity = await buildCurrentIdentity(
                userLoginCreateResponse.resultObject
            );

            if (identity == null) {
                handleAccountCreationError();
                return;
            }

            setGlobalState((globalState: GlobalStateRecord) =>
                globalState.setIdentity(identity)
            );

            setCreatingUser(false);
        } catch (result) {
            setCreatingUser(false);
            const validationErrors = getFormFieldErrors(result);
            if (validationErrors != null) {
                setErrorResult(validationErrors);
                return;
            }
            ToastManager.error(errorCreatingAccount);
        }
    }, [
        signupContext.newUser,
        emailValidationError,
        errorCreatingAccount,
        ERROR_CREATE_USER_EMAIL_IN_USE,
        userCreateApi,
        userLoginCreateApi,
        buildCurrentIdentity,
        setGlobalState,
    ]);

    return {
        handleFormFieldChange,
        handleEmailFormFieldBlur,
        getEmailFormFieldErrors,
        createUser,
        signupContext,
        errorResult,
        creatingUser,
    };
}
