import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import ShareLinkCallToActionBanner from "organisms/share-links/share-link-call-to-action-banner";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface InvalidLinkViewProps {
    text: string;
    subtext: string;
    /**
     * Show or hide the "sign in/sign up" banner.
     * Defaults to the value of
     * !globalState.isAuthenticated() ||
     * CollectionUtils.isEmpty(globalState.currentIdentity?.getRoles())
     *
     * However you can still explicitly set it to true or false to force
     * it to show/hide.
     */
    showBanner?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InvalidLinkView: React.FC<InvalidLinkViewProps> = (
    props: InvalidLinkViewProps
) => {
    const CSS_CLASS_NAME = "c-invalid-share-link-view";

    const { globalState } = useGlobalState();

    const showBanner =
        props.showBanner ??
        (!globalState.isAuthenticated() ||
            CollectionUtils.isEmpty(globalState.currentIdentity?.getRoles()));

    return (
        <React.Fragment>
            {showBanner && <ShareLinkCallToActionBanner />}
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <div className={`${CSS_CLASS_NAME}__container__icon`}>
                        <Icon type={Icons.Stopwatch} />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__container__header`}>
                        <Heading priority={HeadingPriority.Six}>
                            {props.text}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__container__body`}>
                        <Paragraph>{props.subtext}</Paragraph>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default InvalidLinkView;

// #endregion Exports
