import { ResultRecord } from "andculturecode-javascript-core";
import { useAtom } from "jotai";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import { useCallback, useEffect } from "react";
import ChangesSummaryAtom from "utilities/atoms/change-indicator-diff-panel/change-summary-atom";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import AdminChangeSummaryService from "utilities/services/admin/change-summaries/change-summary-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseChangeSummariesModalOptions {
    externalId: string;
    code: string;
    edition: string;
    title: string;
    publicationType: PublicationComponentType;
}

const useChangeSummaries = ({
    externalId,
    code,
    edition,
    title,
    publicationType,
}: UseChangeSummariesModalOptions) => {
    const [changeSummaries, setChangeSummaries] = useAtom(ChangesSummaryAtom);
    const existingChangeSummary =
        changeSummaries.length > 0 ? changeSummaries[0] : null;
    const { create } = AdminChangeSummaryService.useCreate();
    const { update } = AdminChangeSummaryService.useUpdate();

    const addOrUpdateChangeSummary = async (bodyDraft: string) => {
        if (existingChangeSummary == null) {
            return await addChangeSummary(bodyDraft);
        } else {
            return await updateChangeSummary(bodyDraft);
        }
    };

    const addChangeSummary = async (newBodyDraft: string) => {
        try {
            const dto = new ChangeSummaryRecord({
                externalId: externalId,
                code: code,
                edition: edition,
                bodyDraft: newBodyDraft,
                title: title,
                type: publicationType,
            });
            const result = await create(dto);
            if (result?.resultObject == null) return false;

            setChangeSummaries((prev) => [...prev, result.resultObject!]);
            return true;
        } catch {
            return false;
        }
    };

    const updateChangeSummary = async (bodyDraft: string) => {
        try {
            const updatedChangeSummary = existingChangeSummary!.with({
                bodyDraft,
            });
            const result = await update(updatedChangeSummary, {
                id: updatedChangeSummary.id,
            });
            if (result?.resultObject == null) return false;
            setChangeSummaries((prev) =>
                prev.map((changes) => {
                    if (changes.id === result.resultObject?.id) {
                        return result.resultObject!;
                    }
                    return changes;
                })
            );
            return true;
        } catch {
            return false;
        }
    };
    const getAdminChangeSummaries = useCallback(
        async (code: string, edition: string, externalId: string) => {
            try {
                const prevChangeSummary = await AdminChangeSummaryService.list({
                    code,
                    edition,
                    externalId,
                });
                setChangeSummaries(prevChangeSummary.resultObjects);
            } catch (error) {
                if (error instanceof ResultRecord) {
                    ToastManager.error(error.listErrorMessages());
                }
            }
        },
        [setChangeSummaries]
    );

    useEffect(() => {
        getAdminChangeSummaries(code, edition, externalId);
    }, [code, edition, externalId, getAdminChangeSummaries]);

    useEffect(() => {
        return () => setChangeSummaries([]);
    }, [setChangeSummaries]);

    return {
        existingChangeSummary,
        addOrUpdateChangeSummary,
    };
};

export default useChangeSummaries;
