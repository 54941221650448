import PublishStatus from "models/enumerations/publish-status";
import PublicationRecord from "models/view-models/publication-record";
import { useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";
import useLoadResourceStatus from "utilities/hooks/use-load-resource-status";
import usePageErrors from "utilities/hooks/use-page-errors";
import useRefreshToken from "utilities/hooks/use-refresh-token";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import FileService from "utilities/services/file-service";
import { ToastManager } from "utilities/toast/toast-manager";

export default function usePublications(
    groupByPrinting: boolean,
    publishStatus?: PublishStatus,
    publicationContentFormatType?: PublicationContentFormatType,
    searchText?: string
): ServiceHookResult<Array<PublicationRecord>> {
    const LOADING_PUBLICATIONS_ERROR =
        "There was an issue loading publications.";
    const { list: listPublicationsApi } = AdminPublicationService.useList();
    const { list: listFilesApi } = FileService.useBatchedList();

    const [publications, setPublications] = useState<Array<PublicationRecord>>(
        []
    );
    const { loading, loaded, setLoading, setLoaded } = useLoadResourceStatus();
    const { refresh, refreshToken } = useRefreshToken();
    const { pageErrors, handlePageLoadError, resetPageErrors } =
        usePageErrors();

    useEffect(() => {
        const loadPublications = async () => {
            resetPageErrors();
            setLoading(true);
            setLoaded(false);

            const BATCH_SIZE = 20;

            try {
                const listResult = await listPublicationsApi({
                    groupByPrinting,
                    publishStatus,
                    publicationContentFormatType,
                    searchText,
                });

                setPublications(listResult.resultObjects);

                const uniqueFileIds = CollectionUtils.uniqueValuesByProperty(
                    listResult.resultObjects,
                    "coverImageFileId"
                );

                listFilesApi({ ids: uniqueFileIds }, "ids", BATCH_SIZE).then(
                    (filesResult) => {
                        const publicationsWithCoverImages =
                            listResult.resultObjects.map((publication) => {
                                const coverImageFile =
                                    filesResult.resultObjects.find(
                                        (file) =>
                                            file.id ===
                                            publication.coverImageFileId
                                    );

                                return publication.with({ coverImageFile });
                            });
                        setPublications(publicationsWithCoverImages);
                    }
                );

                setLoaded(true);
            } catch (e) {
                ToastManager.error(LOADING_PUBLICATIONS_ERROR);
                handlePageLoadError(e);
                setLoaded(false);
            }

            setLoading(false);
        };

        loadPublications();
    }, [
        publicationContentFormatType,
        groupByPrinting,
        handlePageLoadError,
        listFilesApi,
        listPublicationsApi,
        publishStatus,
        refreshToken,
        resetPageErrors,
        searchText,
        setLoaded,
        setLoading,
    ]);

    return {
        resultObject: publications,
        loading,
        loaded,
        errors: pageErrors,
        refresh,
    };
}
