import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import CallToActionToggle from "molecules/call-to-action/call-to-action-toggle";
import React from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

interface FeatureFlagsPageProps {}

const FeatureFlagsPage: React.FunctionComponent<FeatureFlagsPageProps> = (
    props: FeatureFlagsPageProps
) => {
    const featureFlags = useFeatureFlags();

    return (
        <div className="c-feature-flag-page">
            <div className="c-feature-flag-page__list">
                <Heading priority={HeadingPriority.Two}>Feature Flags</Heading>
                {Object.keys(featureFlags).map((key, index) => {
                    const value: boolean =
                        Object.values(featureFlags)[index] === true;
                    return (
                        <CallToActionToggle
                            checked={value}
                            key={key}
                            label={key}
                            description={""}
                            disabled={true}
                            onChange={() => null}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FeatureFlagsPage;
