import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import * as React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SubscriptionCardFeatureProps {
    featureText: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionCardFeature: React.FunctionComponent<SubscriptionCardFeatureProps> =
    (props) => {
        const baseClass = "c-subscription-card-feature";
        const { featureText } = props;

        return (
            <div className={baseClass}>
                <Icon
                    type={Icons.CheckmarkOvalOutline}
                    size={IconSizes.Large}
                />
                <div className={baseClass + "__content"}>
                    <Paragraph
                        size={ParagraphSizes.Small}
                        dangerouslySetInnerHTML={{ __html: featureText }}
                    />
                </div>
            </div>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubscriptionCardFeature;

// #endregion Exports
