import { SetStateAction } from "jotai";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import React, { Dispatch } from "react";
import { Content } from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import ChangeIndicatorDiffPanelFooter from "./change-indicator-diff-panel-footer";
import ChangeIndicatorDiffPanelHeader from "./change-indicator-diff-panel-header";
interface ChangeIndicatorDiffContentProps {
    content: Content;
    showBody: boolean;
    subHeader: string;
    setChangeSummaries: Dispatch<SetStateAction<ChangeSummaryRecord[]>>;
}

const cssBaseClass: string = "c-change-indicator-diff-panel";
export const DiffPanelContext = React.createContext(false);

const ChangeIndicatorDiffContent = (props: ChangeIndicatorDiffContentProps) => {
    const { content, showBody, subHeader, setChangeSummaries } = props;
    return (
        <>
            <ChangeIndicatorDiffPanelHeader changes={content.changes ?? ""} />
            <div className={`${cssBaseClass}__contents`}>
                <div className={`${cssBaseClass}__subheader`}>{subHeader}</div>
                {showBody && (
                    <div className={`${cssBaseClass}__body`}>
                        <span className={content.titleClassName}>
                            {content.title}
                        </span>
                        <DiffPanelContext.Provider value={true}>
                            {content.body}
                        </DiffPanelContext.Provider>
                    </div>
                )}
            </div>
            <ChangeIndicatorDiffPanelFooter
                setChangeSummaries={setChangeSummaries}
            />
        </>
    );
};

export default ChangeIndicatorDiffContent;
