import * as React from "react";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";

interface TableNotesProps extends XmlConvertedComponentProps {
    cols: number;
}

const TableNotes: React.FunctionComponent<TableNotesProps> = (props) => {
    if (props.diffchanged === XmlChangeNotationConstants.DELETION) {
        return null;
    }
    return (
        <tfoot>
            <tr>
                <td colSpan={100}>{props.children}</td>
            </tr>
        </tfoot>
    );
};

export default TableNotes;
