import { RouteTab } from "molecules/route-tabs/route-tabs";
import { siteMap } from "internal-sitemap";

const getTabs = (): RouteTab[] => {
    return [
        {
            route: siteMap.admin.freeAccessPublications.dashboard,
            label: "Publications",
        },
        {
            route: siteMap.admin.freeAccessPublications.jobs,
            label: "Jobs",
        },
    ];
};

export const FreeAccessPublicationsAdminUtils = {
    getTabs,
};
