import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import PublicationRecord from "models/view-models/publication-record";
import BookRecord from "models/view-models/book-record";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import useModalActions from "utilities/hooks/use-modal-actions";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { RemoveUrlsCommand } from "utilities/service-worker/interfaces/commands/remove-urls-command";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import DateUtils from "utilities/date-utils";
import moment from "moment";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-offline-book-list-item";
const DATA_TEST_ID = "offline-book-list-item";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interface
// -----------------------------------------------------------------------------------------

interface OfflineBookListItemProps {
    book: BookRecord;
    isDownloading: boolean;
    isEnabled: boolean;
    offlineBook: OfflineBookRecord;
    onDownloadBook: (book: BookRecord) => void;
    onRemoveOfflineBook: (offlineBook: OfflineBookRecord) => void;
    publication: PublicationRecord;
}

// #endregion Interface

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineBookListItem: React.FC<OfflineBookListItemProps> = (props) => {
    const {
        book,
        isDownloading,
        isEnabled,
        offlineBook,
        onDownloadBook,
        onRemoveOfflineBook,
        publication,
    } = props;
    const { sendCommand } = useServiceWorker();
    const { t } = useLocalization<CultureResources>();

    const {
        handleClose: handleCloseDeleteModal,
        handleOpen: handleOpenDeleteModal,
        isOpen: isDeleteModalOpen,
    } = useModalActions();

    const { code, edition } = publication;

    const downloadingText = isDownloading
        ? `${t("downloading")}...`
        : t("download");

    const handleDownloadBook = () => {
        handleCloseDeleteModal();
        onDownloadBook(book);
    };

    const handleRemoveBook = () => {
        sendCommand<RemoveUrlsCommand>({
            bookId: book.id!,
            code: book.code,
            edition: book.edition,
            type: ServiceWorkerCommandTypes.RemoveUrls,
        });
        onRemoveOfflineBook(offlineBook);
        handleCloseDeleteModal();
    };

    const canDelete = isEnabled && !isDownloading;

    const showSyncStatus = moment(offlineBook.syncedOn).isAfter(
        offlineBook.book?.createdOn
    );

    return (
        <div
            className={BASE_CLASS}
            data-test-code={publication.code}
            data-test-edition={publication.edition}
            data-test-id={DATA_TEST_ID}
            key={publication.id}>
            <div className={`${BASE_CLASS}__title`}>
                {`${code} (${edition})`}
            </div>
            <div className={`${BASE_CLASS}__sync-status`}>
                {showSyncStatus && (
                    <>
                        {offlineBook.isUpdateAvailable() && (
                            <div>
                                {t("offlineBookDownloadStatus-updateAvailable")}
                            </div>
                        )}
                        {DateUtils.formatDate(offlineBook.syncedOn)}
                    </>
                )}
            </div>
            <Button
                cssClassName={`${BASE_CLASS}__download`}
                disabled={!isEnabled}
                onClick={handleDownloadBook}
                size={ButtonSizes.XSmall}
                style={ButtonStyles.Tertiary}>
                {downloadingText}
            </Button>
            <Button
                cssClassName={`${BASE_CLASS}__delete`}
                disabled={!canDelete}
                onClick={handleOpenDeleteModal}
                style={ButtonStyles.Destructive}
                size={ButtonSizes.XSmall}>
                <Icon type={Icons.Trashcan} />
            </Button>
            {isDeleteModalOpen && (
                <ConfirmationModal
                    isVisible={isDeleteModalOpen}
                    message={t("confirmationMessage", {
                        action: t("remove").toLowerCase(),
                        item: t("stored-publication"),
                    })}
                    onCancel={handleCloseDeleteModal}
                    onConfirm={handleRemoveBook}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookListItem;

// #endregion Exports
