import UnorderedList from "molecules/lists/unordered-list";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ComponentProps {
    cssClassName?: string;
    key?: React.Key;
}

export interface PageErrorsListProps
    extends Pick<ComponentProps, "cssClassName"> {
    errors: string[];
    hide: boolean;
    renderComponent?:
        | React.RefForwardingComponent<any, ComponentProps>
        | React.FunctionComponent<ComponentProps>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PageErrorsList: React.FC<PageErrorsListProps> = (
    props: PageErrorsListProps
) => {
    const {
        cssClassName,
        errors,
        hide,
        renderComponent: RenderComponent,
    } = props;

    if (CollectionUtils.isEmpty(errors) || hide) {
        return null;
    }

    if (RenderComponent != null) {
        return (
            <React.Fragment>
                {errors.map((error: string, key: number) => (
                    <RenderComponent cssClassName={cssClassName} key={key}>
                        {error}
                    </RenderComponent>
                ))}
            </React.Fragment>
        );
    }

    return <UnorderedList cssClassName={cssClassName} listItems={errors} />;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PageErrorsList;

// #endregion Exports
