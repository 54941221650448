import ArticleRecord from "models/view-models/article-record";
import { useCallback, useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import ArticleService from "utilities/services/articles/article-service";
import ChapterArticleService from "utilities/services/publications/chapters/articles/article-service";
import ChapterService from "utilities/services/publications/chapters/chapter-service";
import PublicationService from "utilities/services/publications/publication-service";

export interface ArticleHookOptions {
    articleId?: string | number;
    chapterId?: string | number;
    code?: string;
    edition?: string;
    includeChapter?: boolean;
    includePublication?: boolean;
    nfpaLabel?: string;
    publicationId?: string | number;
}
/**
 * Custom hook for getting Article data based on a Chapter for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param  {string | number} publicationId
 * @param  {string | number} chapterId
 * @param  {string | number} articleId
 * @param  {string | number} nfpaLabel
 */
export default function useArticle(
    options: ArticleHookOptions
): ServiceHookResult<ArticleRecord> {
    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const {
        articleId,
        publicationId,
        chapterId,
        code,
        edition,
        includeChapter,
        includePublication,
        nfpaLabel,
    } = options;

    const [article, setArticle] = useState<ArticleRecord>(new ArticleRecord());
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    // #endregion Public Properties
    // -----------------------------------------------------------------------------------------
    // #region Hook Constructor
    // -----------------------------------------------------------------------------------------

    const { get } = ChapterArticleService.useGet();
    const { list } = ArticleService.useList();

    const { get: getPublication } = PublicationService.useGet();
    const { get: getChapter } = ChapterService.useGet();

    const loadPublication = useCallback(
        async (article: ArticleRecord, id?: number): Promise<ArticleRecord> => {
            if (NumberUtils.isDefault(id)) {
                return article;
            }

            try {
                const response = await getPublication({ id });
                return article.with({ publication: response.resultObject });
            } catch (error) {
                handlePageLoadError(error);
            }
            return article;
        },
        [getPublication, handlePageLoadError]
    );

    const loadChapter = useCallback(
        async (
            article: ArticleRecord,
            id?: number,
            publicationId?: number
        ): Promise<ArticleRecord> => {
            if (
                NumberUtils.isDefault(id) ||
                NumberUtils.isDefault(publicationId)
            ) {
                return article;
            }
            try {
                const response = await getChapter({ publicationId, id });
                return article.with({ chapter: response.resultObject });
            } catch (error) {
                handlePageLoadError(error);
            }
            return article;
        },
        [getChapter, handlePageLoadError]
    );

    /**
     * Primary function to load the article and any included navigation properties
     */
    const load = useCallback(
        async (
            publicationId?: number,
            chapterId?: number,
            articleId?: number,
            code?: string,
            edition?: string,
            nfpaLabel?: string,
            includePublication: boolean = false,
            includeChapter: boolean = false
        ) => {
            if (
                NumberUtils.isDefault(publicationId) ||
                NumberUtils.isDefault(chapterId)
            ) {
                return;
            }
            setLoading(true);

            try {
                let article: ArticleRecord = new ArticleRecord();
                if (nfpaLabel != null) {
                    const response = await list({
                        code,
                        edition,
                        nfpaLabel,
                    });
                    article = response.resultObjects[0];
                }
                if (articleId != null) {
                    const response = await get({
                        publicationId: publicationId,
                        chapterId: chapterId,
                        id: articleId,
                    });
                    if (response.resultObject == null) {
                        setArticle(new ArticleRecord());
                        return;
                    }

                    article = response.resultObject;
                }
                if (includePublication) {
                    article = await loadPublication(article, publicationId);
                }
                if (includeChapter) {
                    article = await loadChapter(
                        article,
                        publicationId,
                        chapterId
                    );
                }

                setArticle(article);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        },
        [
            get,
            handlePageLoadError,
            list,
            loadChapter,
            loadPublication,
            resetPageErrors,
        ]
    );

    useEffect(() => {
        load(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(chapterId),
            NumberUtils.parseInt(articleId),
            code,
            edition,
            nfpaLabel,
            includePublication,
            includeChapter
        );
    }, [
        articleId,
        chapterId,
        code,
        edition,
        includeChapter,
        includePublication,
        load,
        nfpaLabel,
        publicationId,
    ]);
    // #endregion Hook Constructor

    return {
        errors,
        loaded,
        loading,
        resultObject: article,
    };
}
