import React, { PropsWithChildren, useState } from "react";
import BookviewLayoutContextRecord from "./bookview-layout-context-record";
import BookviewLayoutContext from "./use-bookview-layout-context";

interface BookviewLayoutContextProps {}

const BookviewLayoutContextProvider = (
    props: PropsWithChildren<BookviewLayoutContextProps>
) => {
    const [context, setContext] = useState(new BookviewLayoutContextRecord());

    return (
        <BookviewLayoutContext.Provider value={[context, setContext]}>
            {props.children}
        </BookviewLayoutContext.Provider>
    );
};

export default BookviewLayoutContextProvider;
