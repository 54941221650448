import React, { useCallback, useState } from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "andculturecode-javascript-react";
import CultureResources from "utilities/interfaces/culture-resources";
import PublicationRecord from "models/view-models/publication-record";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import ReadonlyPublicationInfoModal from "../readonly-publication-info-modal";
import PublicationOptionsMenu from "./publication-options-menu";
import Checkbox from "atoms/forms/checkbox";
import { KeyboardConstants } from "constants/keyboard-constants";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";

interface PublicationListItemActionsProps {
    publication: PublicationRecord;
    favorites?: Array<UserPublicationFavoriteRecord>;
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
}

const BASE_CLASS = "c-publication-selection-list-group-item";

const PublicationListItemActions = (props: PublicationListItemActionsProps) => {
    const { publication, favorites = [], onChangeFavorites } = props;

    const { useOfflineFeatures } = useFeatureFlags();
    const { addFavorite, unfavorite, isFavorited } =
        useUpdatePublicationFavorite(publication, favorites, onChangeFavorites);

    const handleFavoriteAction = useCallback(
        async (isToBeFavorited: boolean) => {
            if (isToBeFavorited) {
                await addFavorite();
                return;
            }

            await unfavorite();
        },
        [addFavorite, unfavorite]
    );
    const handleKeyDown = useCallback(
        async (event: React.KeyboardEvent<HTMLSpanElement>) => {
            if (event.key !== KeyboardConstants.Enter) {
                return;
            }

            await handleFavoriteAction(!isFavorited);
        },
        [handleFavoriteAction, isFavorited]
    );

    return (
        <>
            <div className={`${BASE_CLASS}__actions`}>
                {useOfflineFeatures ? (
                    <>
                        {publication.getIsLegacy() && <ReadOnlyModalButton />}
                        <PublicationOptionsMenu
                            favorites={favorites}
                            onChangeFavorites={onChangeFavorites}
                            publication={publication}
                        />
                    </>
                ) : (
                    <Checkbox
                        ariaLabel={"Favorite"}
                        checked={isFavorited}
                        checkedIcon={Icons.StarFilled}
                        uncheckedIcon={Icons.StarOutline}
                        onChange={handleFavoriteAction}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </div>
        </>
    );
};

const ReadOnlyModalButton = () => {
    const [showReadOnlyModal, setShowReadOnlyModal] = useState(false);

    const { t } = useLocalization<CultureResources>();

    const handleReadOnlyClick = () => {
        setShowReadOnlyModal(true);
    };
    const handleReadOnlyModalClose = () => {
        setShowReadOnlyModal(false);
    };

    return (
        <Button
            accessibleText={t("readonlyPublicationAboutTitle")}
            onClick={handleReadOnlyClick}
            cssClassName={"c-action-button"}
            size={ButtonSizes.Small}>
            <Icon
                cssClassName="c-legacy-icon"
                type={Icons.Scroll}
                size={IconSizes.Base}
            />
            <ReadonlyPublicationInfoModal
                isVisible={showReadOnlyModal}
                closeDialog={handleReadOnlyModalClose}
            />
        </Button>
    );
};

export default PublicationListItemActions;
