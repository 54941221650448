import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import SupportUserSubscriptionRecord from "models/view-models/support-user-subscription-record";
import React, { useState } from "react";
import RoleType from "utilities/enumerations/role-type";
import UserDetails, { UserDetail } from "./user-details";

interface SubscriptionTabsProps {
    subscriptions?: SupportUserSubscriptionRecord[];
}

const BASE_CLASS = "c-support-user-detail-page__subscriptions";
const HEADING_CLASS = `${BASE_CLASS}__heading`;
const TAB_ROW_CLASS = `${BASE_CLASS}__tabs-row`;
const TAB_CLASS = `${TAB_ROW_CLASS}__tab`;
const SUBSCRIPTION_DETAILS_CLASS = `${BASE_CLASS}__details`;

const SubscriptionTabs = (props: SubscriptionTabsProps) => {
    const { subscriptions } = props;

    const [activeIndex, setActiveIndex] = useState(0);
    const activeSubscription =
        subscriptions != null ? subscriptions[activeIndex] : null;

    if (subscriptions == null || activeSubscription == null) return null;

    return (
        <div className={BASE_CLASS}>
            <Heading
                cssClassName={HEADING_CLASS}
                priority={HeadingPriority.Six}>
                Subscriptions
            </Heading>
            <div className={TAB_ROW_CLASS}>
                {subscriptions.map((s, i) => (
                    <SubscriptionTab
                        key={s.id}
                        heading={getHeading(s)}
                        isActive={i === activeIndex}
                        icon={getIcon(s.roleType)}
                        setActiveTab={() => setActiveIndex(i)}
                    />
                ))}
            </div>
            {activeSubscription && (
                <SubscriptionDetail subscription={activeSubscription} />
            )}
        </div>
    );
};

export default SubscriptionTabs;

const SubscriptionDetail = (props: {
    subscription: SupportUserSubscriptionRecord;
}) => {
    const { subscription } = props;

    const userDetails: UserDetail[] = [
        { heading: "pin", data: subscription.externalProductId },
        { heading: "sku", data: subscription.sku },
        {
            heading: "expiration date",
            data: subscription.formatExpirationDate(),
        },
        {
            heading: "auto-renew",
            data: subscription.isAutoRenewing ? "Yes" : "No",
        },
    ];

    if (subscription.isTeamOrEnterprise()) {
        userDetails.unshift(
            { heading: "type", data: subscription.description },
            { heading: "nfpa team id", data: subscription.teamId ?? "" }
        );
    }

    return (
        <UserDetails
            cssClassName={SUBSCRIPTION_DETAILS_CLASS}
            details={userDetails}
        />
    );
};

const SubscriptionTab = (props: {
    isActive: boolean;
    setActiveTab: () => void;
    heading: string;
    icon: keyof typeof Icons;
}) => {
    const { heading, icon, isActive, setActiveTab } = props;

    const className = isActive ? `${TAB_CLASS} -active` : TAB_CLASS;

    return (
        <span className={className} onClick={() => setActiveTab()}>
            <Icon type={icon} />
            {heading}
        </span>
    );
};

const getHeading = (subscription: SupportUserSubscriptionRecord) => {
    const teamName = getTeamName(subscription.roleType, subscription.teamName);

    const isExpired = subscription.isExpired();

    const heading = isExpired
        ? `${teamName} (Expired ${subscription.formatExpirationDate()})`
        : teamName;

    return heading;
};

const getTeamName = (roleType: RoleType, teamName: string | undefined) => {
    if (teamName) return teamName;

    switch (roleType) {
        case RoleType.FREE_TRIAL:
            return "Free Trial";
        case RoleType.INDIVIDUAL:
            return "Individual";
        case RoleType.TEAM:
            return "Team";
        case RoleType.ENTERPRISE:
            return "Enterprise";
        case RoleType.TRAINING_CONTRACT:
            return "Training";
        default:
            return RoleType[roleType];
    }
};

const getIcon = (roleType: RoleType) => {
    switch (roleType) {
        case RoleType.FREE_TRIAL:
            return Icons.Individual;
        case RoleType.INDIVIDUAL:
            return Icons.Individual;
        case RoleType.TEAM:
            return Icons.Team;
        case RoleType.ENTERPRISE:
            return Icons.Enterprise;
        case RoleType.TRAINING_CONTRACT:
            return Icons.Individual;
        default:
            return Icons.Individual;
    }
};
