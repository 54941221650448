import type PublicationRecord from "models/view-models/publication-record";
import React from "react";
import StringUtils from "utilities/string-utils";

interface PublicationListItemProps {
    publication: PublicationRecord;
}

const BASE_CLASS = "c-publication-selection-list-group-item";

const PublicationListItem: React.FunctionComponent<PublicationListItemProps> = (
    props: PublicationListItemProps
) => {
    const { publication } = props;

    const src = publication.coverImageFile?.presignedUrl;
    const coverStyle: React.CSSProperties = StringUtils.hasValue(src)
        ? {
              backgroundImage: `url(${src})`,
              backgroundSize: "cover",
          }
        : {};
    const cssClassNames = [BASE_CLASS, "-display-only"].join(" ");
    return (
        <div className={cssClassNames}>
            <div className={`${BASE_CLASS}__item`}>
                <div className={`${BASE_CLASS}__cover`} style={coverStyle} />
                <div>
                    <div className={`${BASE_CLASS}__title`}>
                        {publication.getDisplayTitle(false)}
                    </div>
                    <div className={`${BASE_CLASS}__edition`}>
                        {publication.edition}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicationListItem;
