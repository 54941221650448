import type SectionRecord from "models/view-models/section-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import SectionService from "utilities/services/publications/chapters/articles/sections/section-service";

export interface SectionsHookOptions {
    publicationId?: string | number;
    chapterId?: string | number;
    articleId?: string | number;
    pending?: boolean;
}
/**
 * Custom hook for getting Section data based on a Chapter and Article for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * @param {string | number} publicationId
 * @param {string | number} chapterId
 * @param {string | number} articleId
 */
export default function useSections(
    options: SectionsHookOptions
): ServiceHookResult<SectionRecord[]> {
    const [sections, setSections] = useState<Array<SectionRecord>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { list: getSectionsApi } = SectionService.useList();
    const { publicationId, chapterId, articleId } = options;
    const pending = options.pending ?? false;

    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const getSections = async (
            publicationId?: number,
            chapterId?: number,
            articleId?: number
        ) => {
            if (
                NumberUtils.isDefault(publicationId) ||
                NumberUtils.isDefault(chapterId) ||
                NumberUtils.isDefault(articleId)
            ) {
                return;
            }

            setLoading(true);

            try {
                const response = await getSectionsApi({
                    articleId: articleId,
                    chapterId: chapterId,
                    publicationId: publicationId,
                });
                const sections =
                    response.resultObjects || ([] as Array<SectionRecord>);
                setSections([...sections]);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        if (pending) {
            setSections([]);
            return;
        }

        getSections(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(chapterId),
            NumberUtils.parseInt(articleId)
        );
    }, [
        articleId,
        chapterId,
        getSectionsApi,
        handlePageLoadError,
        pending,
        publicationId,
        resetPageErrors,
    ]);

    return {
        errors,
        loaded,
        loading,
        resultObject: sections,
    };
}
