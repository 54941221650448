import AlertLevels from "constants/alert-levels";
import AlertBanner from "molecules/alerts/alert-banner";
import React from "react";

interface TrainingContractPageErrorProps {
    message: string | JSX.Element;
}
const TrainingContractPageError = (props: TrainingContractPageErrorProps) => {
    const { message } = props;
    return (
        <AlertBanner alertLevel={AlertLevels.Warning}>{message}</AlertBanner>
    );
};

export default TrainingContractPageError;
