import React from "react";
import Loader from "atoms/loaders/loader";
import Modal, { ModalProps } from "molecules/modals/modal";
import Paragraph from "atoms/typography/paragraph";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface RedirectionModalProps extends ModalProps {
    /**
     * Delay in ms to show the modal before redirecting.
     *
     * @default 3000
     */
    delay?: number;

    /**
     * Description to display in the body of the modal.
     *
     * @default "Hang tight while you are redirected."
     */
    description?: string;

    /**
     * Location to redirect the user to
     */
    to: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-redirection-modal";
const DEFAULT_DELAY = 3000;
const DEFAULT_DESCRIPTION = "Hang tight while you are redirected.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RedirectionModal: React.FunctionComponent<RedirectionModalProps> = (
    props: RedirectionModalProps
) => {
    const {
        closeDialog,
        description,
        delay,
        isVisible,
        label,
        to,
        transition,
        type,
    } = props;

    // Set a timeout before redirecting the user
    setTimeout(() => {
        window.location.href = to;
    }, delay ?? DEFAULT_DELAY);

    return (
        <Modal
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={label}
            transition={transition}
            type={type}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>Just a moment...</h2>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <Paragraph>{description || DEFAULT_DESCRIPTION}</Paragraph>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <Loader accessibleText={`Redirecting to ${to}`} />
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default RedirectionModal;

// #endregion Export
