import { atom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { useEffect, useRef } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useSectionScrollSpyHandlers from "./use-section-scroll-spy-handlers";

export const ActiveIdAtom = atom<number | null>(null);
export const IsClickedAtom = atom<boolean>(false);
export const ActiveSectionAtom = atom<HTMLElement | null>(null);

/**
 * the breadcrumb menu's height is 41px
 *
 * the extra 4px is just to give some room depending on when the callback is triggered
 */
const BREADCRUMB_HEIGHT = 45;

export default function useSectionScrollSpy() {
    const setActiveId = useUpdateAtom(ActiveIdAtom);
    const setIsClicked = useUpdateAtom(IsClickedAtom);

    const { addScrollListener } = useSectionScrollSpyHandlers();

    const visibleSections = useRef<IntersectionObserverEntry[] | null>(null);

    const callback = (
        entries: IntersectionObserverEntry[],
        observer?: IntersectionObserver
    ) => {
        let isScrollable = false;
        let isScrolledToTop = true;
        let isScrolledToBottom = false;

        const root = observer?.root;

        if (root != null && root instanceof Element) {
            const { scrollHeight, scrollTop, offsetHeight, clientHeight } =
                root as HTMLElement;

            const styles = window.getComputedStyle(root);
            const paddingBottom = parseInt(styles.paddingBottom);
            const difference = offsetHeight + scrollTop - scrollHeight;

            // since this is calculated during the observer callback, the scroll bar might
            // not be completely at the bottom
            isScrolledToBottom = Math.abs(difference) < paddingBottom;

            isScrollable = scrollHeight > clientHeight;

            // 10px gives some leeway if the callback isn't triggered when it's at the absolut top
            isScrolledToTop = scrollTop < 10;
        }

        if (visibleSections.current) {
            entries.forEach((entry) => {
                const isIncluded = checkIfIncluded(
                    visibleSections.current,
                    entry
                );

                const isEntering = entry.isIntersecting && !isIncluded;

                const isExiting = !entry.isIntersecting && isIncluded;

                const isUpdating = entry.isIntersecting && isIncluded;

                if (isEntering) visibleSections.current?.push(entry);

                if (isExiting)
                    visibleSections.current = remove(
                        visibleSections.current,
                        entry
                    );

                if (isUpdating)
                    visibleSections.current = replace(
                        visibleSections.current,
                        entry
                    );
            });

            visibleSections.current.sort(
                (a, b) =>
                    // using getBoundingClientRect instead of the entry's boundingClientRect because
                    // the entry may not be up to date
                    a.target.getBoundingClientRect().top -
                    b.target.getBoundingClientRect().top
            );
        }

        // put this down here to skip the above steps for the initialization of the observer
        if (visibleSections.current == null)
            visibleSections.current = getIntersecting(entries);

        setActiveId((id) => {
            /**
             * we can't use the value of either isClicked or activeId inside the callback because
             * they will not be updated in the Intersection Observer
             * ¯\_(ツ)_/¯
             */
            let isClicked = false;
            setIsClicked((prev) => {
                isClicked = prev;
                return prev;
            });

            if (isClicked) {
                if (id == null) return id;
                const clickedSection = document.getElementById(id.toString());

                if (clickedSection == null) return id;

                const top = clickedSection.getBoundingClientRect().top;

                const shouldAddScrollListener =
                    top > BREADCRUMB_HEIGHT ||
                    (isScrollable && isScrolledToBottom);

                if (shouldAddScrollListener) addScrollListener();
            }

            if (!CollectionUtils.hasValues(visibleSections.current)) return id;

            // for page load and when scrolling back to top
            if (isScrolledToTop) {
                const section = visibleSections.current[0]
                    .target as HTMLElement;
                const newId = Number(
                    section.dataset.rootSectionId ?? section.id
                );
                return newId;
            }

            // for scrolling to end of page
            if (isScrolledToBottom && isScrollable) {
                const lastSection = visibleSections.current[
                    visibleSections.current.length - 1
                ].target as HTMLElement;
                const newId = Number(
                    lastSection.dataset.rootSectionId ?? lastSection.id
                );
                return newId;
            }

            // general scrolling through the page
            const activeSection = getActiveSection(visibleSections.current);
            if (activeSection == null) return id;

            const newId = Number(
                activeSection.dataset.rootSectionId ?? activeSection.id
            );
            return newId;
        });
    };

    useEffect(() => {
        return () => {
            visibleSections.current = null;

            const container = document.getElementsByClassName(
                "c-publication-page-layout__content__main"
            )[0] as HTMLElement;

            if (container == null) return;

            container.removeAttribute("hasHandler");
        };
    }, []);

    return { callback };
}

const getIntersecting = (entries: IntersectionObserverEntry[]) =>
    entries.filter((entry) => entry.isIntersecting);

const checkIfIncluded = (
    sections: IntersectionObserverEntry[] | null,
    entry: IntersectionObserverEntry
) => sections?.find((section) => section.target === entry.target) !== undefined;

const remove = (
    sections: IntersectionObserverEntry[] | null,
    entry: IntersectionObserverEntry
) => sections?.filter((section) => section.target !== entry.target) ?? sections;

const replace = (
    sections: IntersectionObserverEntry[] | null,
    entry: IntersectionObserverEntry
) =>
    sections?.map((section) =>
        section.target === entry.target ? entry : section
    ) ?? sections;

const getActiveSection = (sections: IntersectionObserverEntry[] | null) => {
    if (sections == null || sections.length < 1) return null;

    const topSection = sections[0];

    // if there is only one visible section
    if (sections.length === 1) return topSection.target as HTMLElement;

    // if the section is very long it might not fit inside the root
    // or may not switch to being active soon enough
    // 300 and 0.5 are just a guess but seem to be working
    const isLongSection = topSection.boundingClientRect.height > 300;
    if (isLongSection && topSection.intersectionRatio >= 0.5)
        return topSection.target as HTMLElement;

    // find the first section that is 100% visible
    // there's a chance that when the callback is triggered it might be something like .9965213543
    const firstFullyVisibleSection = sections.find(
        (section) => section.intersectionRatio > 0.99
    );

    if (firstFullyVisibleSection !== undefined)
        return firstFullyVisibleSection.target as HTMLElement;

    return null;
};
