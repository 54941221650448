import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import WindowContext from "models/interfaces/window-context";

const defaultValues: WindowContext =
    RecordUtils.defaultValuesFactory<WindowContext>({
        height: 0,
        width: 0,
    });

export default class WindowContextRecord
    extends Record(defaultValues)
    implements WindowContext
{
    constructor(params?: WindowContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
}
