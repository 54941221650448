import Anchor from "atoms/anchors/anchor";
import React from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import LinkResourceRecord from "./link-resource-record";
import { LinkResourceStyle } from "./link-resource-styles";

interface ILinkResourcesRequiredProps {
    linkResourceRecords: LinkResourceRecord[];
}

interface ILinkResourcesOptionalProps {
    linkResourceStyles?: LinkResourceStyle;
    onNavItemClick?: () => void;
    isPublic?: boolean;
}

interface LinkResourcesProps
    extends ILinkResourcesRequiredProps,
        ILinkResourcesOptionalProps {}

const defaultProps: ILinkResourcesOptionalProps = {
    linkResourceStyles: LinkResourceStyle.Default,
    onNavItemClick: () => null,
    isPublic: false,
};

const LinkResources: React.FunctionComponent<LinkResourcesProps> = (
    props: LinkResourcesProps
) => {
    const {
        linkResourceRecords,
        linkResourceStyles,
        onNavItemClick,
        isPublic = false,
    } = props;
    const cssBase = "c-link-resources";
    const cssClass = `${cssBase}${linkResourceStyles}`;
    const { extractTablesFromPublicationImport } = useFeatureFlags();

    if (!extractTablesFromPublicationImport) {
        return null;
    }
    return (
        <nav className={cssClass}>
            <h3 className={`${cssClass}__header`}>LiNK Resources</h3>
            {linkResourceRecords.map(
                (linkResourceRecord: LinkResourceRecord) => {
                    return (
                        <Anchor
                            cssClassName={`${cssClass}__link`}
                            key={linkResourceRecord.title}
                            onClick={onNavItemClick}
                            disabled={isPublic}
                            to={linkResourceRecord.link}>
                            <div className={`${cssClass}__item`}>
                                {linkResourceRecord.title}
                            </div>
                        </Anchor>
                    );
                }
            )}
        </nav>
    );
};
LinkResources.defaultProps = defaultProps;
export default LinkResources;
