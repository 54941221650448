import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import PublishStatus from "models/enumerations/publish-status";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import EnhancedContentPanel from "organisms/enhanced-content/enhanced-content-panel";
import React from "react";
import useEnhancedContentResources from "utilities/hooks/domain/resources/use-enhanced-content-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentPreviewModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog" | "label"> {
    enhancedContent: EnhancedContentRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-enhanced-content-preview-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentPreviewModal: React.FunctionComponent<EnhancedContentPreviewModalProps> =
    (props: EnhancedContentPreviewModalProps) => {
        const { closeDialog, enhancedContent, isVisible, label } = props;

        const { loading: resourcesLoading, resources } =
            useEnhancedContentResources(
                false,
                enhancedContent?.id!,
                enhancedContent.getPublishStatus() === PublishStatus.Unpublished
            );

        return (
            <Modal
                closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
                closeDialog={closeDialog}
                cssClassName={CSS_BASE_CLASS_NAME}
                isVisible={isVisible}
                label={label}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <Heading
                        priority={
                            2
                        }>{`Preview - ${enhancedContent.title}`}</Heading>
                    <Button
                        accessibleText="Close Dialog"
                        cssClassName="-modal-close -icon"
                        onClick={closeDialog}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.TertiaryAlt}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    <EnhancedContentPanel
                        enhancedContent={enhancedContent}
                        isPreview={true}
                        resources={resources}
                        resourcesLoading={resourcesLoading}
                    />
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                    <Anchor
                        cssClassName={`c-button ${ButtonStyles.Secondary} ${ButtonSizes.Medium}`}
                        to={enhancedContent.getRoute(true)}>
                        Edit in Bookview
                    </Anchor>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EnhancedContentPreviewModal;

// #endregion Export
