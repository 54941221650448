import React, { PropsWithChildren } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SliderProps {
    index: number;
    cssWrapperClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Slider: React.FC<PropsWithChildren<SliderProps>> = (
    props: PropsWithChildren<SliderProps>
) => {
    const CSS_CLASS_NAME = "c-slider";
    const { children, index, cssWrapperClassName } = props;

    const currentChild = React.Children.toArray(children)[index];

    return (
        <div className={`${CSS_CLASS_NAME} ${cssWrapperClassName}`}>
            {React.isValidElement(currentChild)
                ? React.cloneElement(currentChild, { key: index })
                : currentChild}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default Slider;

// #endregion Exports
