import RadioButton from "atoms/forms/radio-button-input";
import HitRecord from "models/view-models/search/hit-record";
import RadioList, {
    RadioListStyles,
    RadioMarkerPosition,
} from "molecules/radio-lists/radio-list";
import RichTextReferenceSearch from "molecules/rich-text/rich-text-reference-search";
import React, { useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export enum RichTextReferenceLinkType {
    Publication = 0,
    Other = 1,
}

export interface RichTextReferenceSelectProps {
    baseClassName: string;
    contentItemError?: string;
    onChange: (hit?: HitRecord) => void;
    onReferenceTypeChanged: (type: RichTextReferenceLinkType) => void;
    publicationId: number;
    value?: HitRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const RADIO_BUTTON_NAME = "rich-text-reference-select";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextReferenceSelect: React.FC<RichTextReferenceSelectProps> = (
    props: RichTextReferenceSelectProps
) => {
    const {
        baseClassName,
        contentItemError,
        onChange,
        onReferenceTypeChanged,
        publicationId,
        value,
    } = props;

    const [referencePublication, setReferencePublication] = useState(false);

    const createCheckHandler = (type: RichTextReferenceLinkType) => () => {
        setReferencePublication(type === RichTextReferenceLinkType.Publication);
        onReferenceTypeChanged(type);
    };

    const handleReferenceContentItemChecked = createCheckHandler(
        RichTextReferenceLinkType.Other
    );
    const handleReferencePublicationChecked = createCheckHandler(
        RichTextReferenceLinkType.Publication
    );

    return (
        <RadioList
            items={[
                <RadioButton
                    checked={referencePublication}
                    label={"Reference Publication"}
                    name={RADIO_BUTTON_NAME}
                    onCheck={handleReferencePublicationChecked}
                />,
                <RichTextReferenceSearch
                    baseClassName={baseClassName}
                    checked={!referencePublication}
                    error={contentItemError}
                    onChange={onChange}
                    onCheck={handleReferenceContentItemChecked}
                    publicationId={publicationId}
                    value={value}
                />,
            ]}
            markerPosition={RadioMarkerPosition.Left}
            style={RadioListStyles.None}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextReferenceSelect;

// #endregion Exports
