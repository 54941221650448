import * as React from "react";
import { SectionSpanTypes } from "organisms/enums/section-span-types";
import SectionLabel from "./section-label";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";

export interface SpanConverterProps extends XmlConvertedComponentProps {}

const SpanConverter = (props: SpanConverterProps) => {
    const { className, children } = props;
    switch (className) {
        case SectionSpanTypes.Label:
            return <SectionLabel children={children} />;
        default:
            if (children === undefined) {
                return null;
            }
            return children;
    }
};

export default SpanConverter;
