import { useCallback, useEffect, useMemo, useReducer } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import {
    CodesEntityTypes as DefaultCodesEntityTypes,
    DirectEntityTypes as DefaultDirectEntityTypes,
    PublicationEntityTypeConstants,
} from "constants/publication-entity-type-constants";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import useSearch from "utilities/hooks/aspect/search/use-search-v2";
import { UseSearchHookProvider } from "utilities/hooks/use-search";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import useGroupByFavoritePublications from "utilities/hooks/domain/user-publication-favorites/use-group-by-favorite-publications";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import { CollectionUtils } from "utilities/collection-utils";
import HitRecord from "models/view-models/search/hit-record";
import PublicationRecord from "models/view-models/publication-record";
import StringUtils from "utilities/string-utils";
import CurrentPublicationEntityRecord from "models/view-models/atoms/current-publication-entity-record";
import { intersectionBy } from "lodash";
import OfflinePublicationsAtom from "utilities/atoms/offline-publications-atom";
import { useAtomValue } from "jotai/utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum ActionType {
    ExecuteFilterSearch,
    SetSelectedRowCount,
    SetSearchStatus,
    SetState,
}

export enum SearchStatus {
    ExecuteFilter,
    Filtering,
    Initial,
    Loaded,
}

export enum SearchModalResultType {
    Codes,
    Direct,
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const INITIAL_STATE = {
    codesEntityTypes: [],
    directEntityTypes: [],
    enhancedContentTypes: [PublicationEntityTypeConstants.EnhancedContent],
    searchText: "",
    selectedPublicationIds: [],
    selectedResultType: SearchModalResultType.Codes,
    selectedRowCount: 0,
    status: SearchStatus.Initial,
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type SearchModalV2Action =
    | {
          type: ActionType.ExecuteFilterSearch;
          search: VoidFunction;
      }
    | {
          type: ActionType.SetSelectedRowCount;
          rowCount: number;
      }
    | {
          type: ActionType.SetSearchStatus;
          status: SearchStatus;
      }
    | {
          type: ActionType.SetState;
          value: Partial<SearchModalV2State>;
      };

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalV2State {
    codesEntityTypes: Array<string>;
    directEntityTypes: Array<string>;
    enhancedContentTypes: Array<string>;
    searchText: string;
    selectedPublicationIds: Array<number>;
    selectedResultType: SearchModalResultType;
    selectedRowCount: number;
    status: SearchStatus;
}

export interface SearchModalReducerResult extends SearchModalV2State {
    codesRowCount: number | undefined;
    codesSearchResults: HitRecord[];
    directRowCount: number | undefined;
    directSearching: boolean;
    directSearchResults: HitRecord[];
    favoritePublications: PublicationRecord[];
    publications: PublicationRecord[];
    publicationSearching: boolean;
    handleSubmit: VoidFunction;
    handleSearchClear: VoidFunction;
    handleSetReducerState: (state: Partial<SearchModalV2State>) => void;
}

export interface SearchModalReducerOptions {
    currentPublicationEntity: CurrentPublicationEntityRecord | undefined;
    currentPublication: PublicationRecord | undefined;
    initialState?: Partial<SearchModalV2State>;
    resetPagination: VoidFunction;
    setPagingRowCount: (count: number) => void;
    skip: number;
    take: number;
}

// #endregion Interfaces

export default function useSearchModalReducer(
    options: SearchModalReducerOptions
): SearchModalReducerResult {
    const { isOnline } = useNetworkInformation();
    const { includeEnhancedContentInSearch } = useFeatureFlags();
    const [
        {
            codesEntityTypes,
            directEntityTypes,
            enhancedContentTypes,
            searchText,
            selectedPublicationIds,
            selectedResultType,
            selectedRowCount,
            status,
        },
        dispatch,
    ] = useReducer(searchModalV2Reducer, undefined, () =>
        Object.assign(initializeSearchModalV2State(), options?.initialState)
    );

    const {
        currentPublicationEntity,
        currentPublication,
        skip,
        take,
        setPagingRowCount,
        resetPagination,
    } = options;

    const setReducerState = useCallback(
        (value: Partial<SearchModalV2State>) => {
            dispatch({ type: ActionType.SetState, value });
        },
        []
    );

    const { resultObject: onlinePublications } = usePublications();
    const offlinePublicationsData = useAtomValue(OfflinePublicationsAtom);
    const offlinePublications = useMemo(
        () => offlinePublicationsData ?? [],
        [offlinePublicationsData]
    );
    const { favorites = [] } = usePublicationFavorites();
    const { favoritePublications: allFavoritePublications = [] } =
        useGroupByFavoritePublications(onlinePublications, favorites);

    const baseSearchParams: Partial<SearchListQueryParams> = useMemo(
        () => ({
            exact: /^["].*["]$/.test(searchText),
            search: searchText,
            start: skip,
            take: take,
        }),
        [skip, searchText, take]
    );

    const publications = useMemo(() => {
        if (isOnline) {
            return onlinePublications;
        }

        return offlinePublications;
    }, [isOnline, offlinePublications, onlinePublications]);

    const favoritePublications = useMemo(() => {
        if (isOnline) {
            return allFavoritePublications;
        }

        return intersectionBy(
            allFavoritePublications,
            offlinePublications,
            "id"
        );
    }, [allFavoritePublications, isOnline, offlinePublications]);

    const searchProvider = useMemo(
        () =>
            isOnline
                ? UseSearchHookProvider.Cloud
                : UseSearchHookProvider.Offline,
        [isOnline]
    );

    const selectedEntityParams = () => {
        const codeEntityParams = CollectionUtils.hasValues(codesEntityTypes)
            ? codesEntityTypes
            : DefaultCodesEntityTypes;

        const entityTypes = includeEnhancedContentInSearch
            ? [...codeEntityParams, ...enhancedContentTypes]
            : codeEntityParams;

        const defaults = {
            publicationIds: selectedPublicationIds ?? [],
            entityTypes,
        };

        const entityParams = currentPublicationEntity?.getEntityIdParams();
        if (
            entityParams === null ||
            (currentPublicationEntity !== null &&
                !currentPublicationEntity?.selected)
        ) {
            return defaults;
        }

        return {
            ...entityParams,
            entityTypes: enhancedContentTypes,
            publicationIds: [currentPublication?.id!],
        };
    };

    const {
        reset: codeReset,
        resultObject: codesSearchResults,
        rowCount: codesRowCount,
        search: codeSearch,
        loading: publicationSearching,
    } = useSearch(searchProvider, {
        ...baseSearchParams,
        ...selectedEntityParams(),
    });

    const {
        reset: directReset,
        resultObject: directSearchResults,
        loading: directSearching,
        rowCount: directRowCount,
        search: directSearch,
    } = useSearch(searchProvider, {
        ...baseSearchParams,
        entityTypes: CollectionUtils.hasValues(directEntityTypes)
            ? directEntityTypes
            : DefaultDirectEntityTypes,
    });

    const selectedSearch =
        selectedResultType === SearchModalResultType.Codes
            ? codeSearch
            : directSearch;

    const handleSearchClear = () => {
        codeReset();
        directReset();
        setReducerState({
            searchText: "",
            status: SearchStatus.Initial,
        });
    };

    const handleSubmit = useCallback(() => {
        resetPagination();
        codeSearch();
        directSearch();
    }, [codeSearch, directSearch, resetPagination]);

    useEffect(
        function filtersChanged() {
            dispatch({
                type: ActionType.SetSearchStatus,
                status: SearchStatus.ExecuteFilter,
            });
        },
        [
            skip,
            take,
            codesEntityTypes,
            directEntityTypes,
            enhancedContentTypes,
            selectedPublicationIds,
            currentPublicationEntity,
        ]
    );

    useEffect(
        function executeFilter() {
            if (status !== SearchStatus.ExecuteFilter) {
                return;
            }

            dispatch({
                type: ActionType.ExecuteFilterSearch,
                search: selectedSearch,
            });
        },
        [status, selectedSearch]
    );

    useEffect(() => {
        const rowCount =
            (selectedResultType === SearchModalResultType.Codes
                ? codesRowCount
                : directRowCount) ?? 0;
        dispatch({
            type: ActionType.SetSelectedRowCount,
            rowCount: rowCount,
        });
        setPagingRowCount(rowCount);
    }, [codesRowCount, directRowCount, selectedResultType, setPagingRowCount]);

    return {
        codesEntityTypes,
        codesRowCount,
        codesSearchResults,
        directEntityTypes,
        directRowCount,
        directSearching,
        directSearchResults,
        enhancedContentTypes,
        favoritePublications,
        publications,
        publicationSearching,
        searchText,
        selectedPublicationIds: selectedEntityParams().publicationIds,
        selectedResultType,
        selectedRowCount,
        status,
        handleSearchClear,
        handleSubmit,
        handleSetReducerState: setReducerState,
    };
}

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function executeFilterSearch(
    state: SearchModalV2State,
    action: SearchModalV2Action
): SearchModalV2State {
    if (action.type !== ActionType.ExecuteFilterSearch) {
        return state;
    }

    action.search();
    return {
        ...state,
        status: SearchStatus.Filtering,
    };
}

function initializeSearchModalV2State(): SearchModalV2State {
    return INITIAL_STATE;
}

function searchModalV2Reducer(
    state: SearchModalV2State,
    action: SearchModalV2Action
): SearchModalV2State {
    const reducerFunctions = {
        [ActionType.ExecuteFilterSearch]: executeFilterSearch,
        [ActionType.SetSearchStatus]: setSearchStatus,
        [ActionType.SetSelectedRowCount]: setSelectedRowCount,
        [ActionType.SetState]: setState,
    };

    if (action.type in reducerFunctions) {
        return reducerFunctions[action.type](state, action);
    }

    return state;
}

function setSearchStatus(
    state: SearchModalV2State,
    action: SearchModalV2Action
): SearchModalV2State {
    if (action.type !== ActionType.SetSearchStatus) {
        return state;
    }

    if (StringUtils.isEmpty(state.searchText)) {
        return state;
    }

    return {
        ...state,
        status: action.status,
    };
}

function setSelectedRowCount(
    state: SearchModalV2State,
    action: SearchModalV2Action
): SearchModalV2State {
    if (action.type !== ActionType.SetSelectedRowCount) {
        return state;
    }

    const newStatus = StringUtils.isEmpty(state.searchText)
        ? SearchStatus.Initial
        : SearchStatus.Loaded;

    return {
        ...state,
        status: newStatus,
        selectedRowCount: action.rowCount,
    };
}

function setState(
    state: SearchModalV2State,
    action: SearchModalV2Action
): SearchModalV2State {
    if (action.type !== ActionType.SetState) {
        return state;
    }

    return {
        ...state,
        ...action.value,
    };
}

// #endregion Functions
