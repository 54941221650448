import { CancelSubscriptionFormSubmissionValidator } from "models/validation/cancel-subscription-form-submission-validator";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import React, { useState, useRef } from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Form from "molecules/forms/form";
import SelectFormField from "molecules/form-fields/select-form-field";
import { SelectOption } from "atoms/forms/select";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import CancelSubscriptionFormSubmissonRecord from "models/view-models/cancel-subscription-form-submission.record";
import { CancelSubscriptionFormSubmission } from "models/interfaces/cancel-subscription-form-submission";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import SubscriptionRecord from "models/view-models/subscription-record";
import HiddenField from "atoms/forms/hidden-field";
import HiddenFieldValues from "models/interfaces/hidden-field-values";
import { SalesForceFormConstants as SFC } from "constants/salesforce-form-constants";
import StringUtils from "utilities/string-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SubscriptionCancellationModalProps {
    closeDialog: () => void;
    isVisible: boolean;
    subscription: SubscriptionRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-subscription-cancellation-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionCancellationModal: React.FunctionComponent<SubscriptionCancellationModalProps> =
    (props: SubscriptionCancellationModalProps) => {
        const [selectIsDefault, setSelectIsDefault] = useState<boolean>(true);
        const [formRecord, setFormRecord] =
            useState<CancelSubscriptionFormSubmissonRecord>(
                new CancelSubscriptionFormSubmissonRecord()
            );
        const [errorResult, setErrorResult] = useState<
            ObjectValidationResult<CancelSubscriptionFormSubmission>
        >({});
        const formRef = useRef<HTMLFormElement>(null);
        const { globalState } = useGlobalState();
        const systemSettings = globalState?.getSystemSettings();
        const { t } = useLocalization<CultureResources>();

        const cancellationTerms = [
            t("cancellationTerm1"),
            t("cancellationTerm2"),
            t("cancellationTerm3"),
            t("cancellationTerm4"),
            t("cancellationTerm5"),
        ];

        const CancellationReasons = {
            NotUseful: t("notUseful"),
            TooExpensive: t("tooExpensive"),
            TechnicalProblems: t("techicalProblems"),
            AutoRenew: t("autoRenew"),
            AltService: t("altService"),
            UnHappy: t("unHappy"),
            CustomerService: t("customerService"),
            LimitedTime: t("limitedTime"),
            Other: t("other"),
        };
        const cancellationReasons: SelectOption[] = [
            {
                data: t("selectAnOption"),
                disabled: true,
                isPlaceholder: true,
                label: t("selectAnOption"),
                value: "0",
            },
            ...Object.values(CancellationReasons).map((r) => ({
                data: r,
                label: r,
                value: r,
            })),
        ];

        // Hidden fields setup

        const identity = globalState.currentIdentity;
        const user = identity?.user;
        const orgId = systemSettings?.reCaptchaOrgId;
        const retUrl = systemSettings?.getSalesforceCancellationReturnUrl();
        const teamName = identity?.getCurrentRoleDisplayName();

        const hiddenFields: HiddenFieldValues[] = [
            { name: SFC.Description.name, value: SFC.Description.value },
            { name: SFC.Email.name, value: user?.email },
            { name: SFC.Name.name, value: user?.getFirstAndLastName() },
            { name: SFC.OrgId.name, value: orgId },
            { name: SFC.Phone.name, value: user?.phoneNumber },
            { name: SFC.RecordType.name, value: SFC.RecordType.value },
            { name: SFC.ReturnUrl.name, value: retUrl },
            { name: SFC.Subject.name, value: SFC.Subject.value },
            { name: SFC.SubscriptionPin.name, value: props.subscription.sku },
            { name: SFC.TeamName.name, value: teamName },
            { name: SFC.UseWebToCase.name, value: SFC.UseWebToCase.value },
            { name: SFC.WebId.name, value: `${user?.webId}` },
        ];

        const selectChangeHandler = (option: SelectOption) => {
            setSelectIsDefault(false);
            setFormRecord(
                formRecord.with({
                    reason: option.value,
                })
            );
        };

        const formSubmissionHandler = (
            e:
                | React.FormEvent<HTMLFormElement>
                | React.MouseEvent<HTMLButtonElement>
        ) => {
            e.preventDefault();

            const formHasErrors = validate(formRecord, setErrorResult);
            if (formHasErrors) {
                return;
            }

            formRef.current?.submit();
        };

        const closeDialogHandler = () => {
            setSelectIsDefault(true);
            setFormRecord(new CancelSubscriptionFormSubmissonRecord());
            setErrorResult({});

            props.closeDialog();
        };

        return (
            <Modal
                closeDialog={closeDialogHandler}
                cssClassName={`${CSS_BASE_CLASS_NAME}`}
                isVisible={props.isVisible}
                label={t("cancelMySubscription")}
                transition={ModalTransitions.Fade}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <h2>{t("cancelMySubscription")}</h2>
                    <Button
                        style={ButtonStyles.Tertiary}
                        size={ButtonSizes.Medium}
                        onClick={closeDialogHandler}
                        cssClassName={"-modal-close -icon"}
                        accessibleText={t("closeItem", { item: t("dialog") })}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    <div
                        className={`${CSS_BASE_CLASS_NAME}__container -condensed-bottom`}>
                        <Paragraph
                            cssClassName={`${CSS_BASE_CLASS_NAME}__terms`}
                            size={ParagraphSizes.Small}>
                            {t("cancellationTermNotes")}
                        </Paragraph>
                        <ul>
                            {cancellationTerms.map((term, index) => (
                                <li key={index}>
                                    <Icon
                                        type={Icons.Dot}
                                        size={IconSizes.Base}
                                    />
                                    <Paragraph size={ParagraphSizes.Small}>
                                        {term}
                                    </Paragraph>
                                </li>
                            ))}
                        </ul>

                        {/* Salesforce debugging information in salesforce.md */}
                        <Form
                            action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                            onSubmit={() => {}}
                            ref={formRef}>
                            {hiddenFields.map((field, index) => (
                                <HiddenField
                                    key={index}
                                    name={field.name!}
                                    value={field.value!}
                                />
                            ))}

                            <SelectFormField
                                cssClassName={
                                    selectIsDefault ? "-on-default" : ""
                                }
                                errorMessage={getErrors(errorResult, "reason")}
                                id={SFC.CancellationReason.id}
                                isValid={
                                    getErrors(errorResult, "reason") == null
                                }
                                fieldId={SFC.CancellationReason.id}
                                label={t("reasonForCancellation")}
                                name={SFC.CancellationReason.name}
                                onChange={(option) =>
                                    selectChangeHandler(option!)
                                }
                                options={cancellationReasons}
                                value={
                                    StringUtils.hasValue(formRecord.reason)
                                        ? formRecord.reason
                                        : cancellationReasons[0].value
                                }
                            />
                            {formRecord.reason ===
                                CancellationReasons.Other && (
                                <TextAreaFormField
                                    inputTestId={SFC.Other.id}
                                    isValid={true}
                                    label={t("field-description")}
                                    maxLength={255}
                                    fieldId={SFC.Other.id}
                                    placeholder={t("enterADescription")}
                                    name={SFC.Other.name}
                                    onChange={(e) => {
                                        setFormRecord(
                                            formRecord.with({
                                                description: e.target.value,
                                            })
                                        );
                                    }}
                                    required={false}
                                    rows={6}
                                    value={formRecord.description}
                                />
                            )}
                        </Form>
                    </div>
                    <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                        <div className={`${CSS_BASE_CLASS_NAME}__container`}>
                            <Button onClick={closeDialogHandler}>
                                {t("nevermindKepSubscription")}
                            </Button>
                            <Button
                                size={ButtonSizes.Medium}
                                style={ButtonStyles.Destructive}
                                onClick={formSubmissionHandler}>
                                {t("cancelMySubscription")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Validation
// -----------------------------------------------------------------------------------------

const getErrors = (
    errorResult: ObjectValidationResult<CancelSubscriptionFormSubmission>,
    key: keyof CancelSubscriptionFormSubmission
): string | undefined => {
    const hasErrors = ObjectValidator.hasErrorsFor(key, errorResult);
    return hasErrors ? errorResult[key]?.join(",") : undefined;
};

const validate = (
    form: CancelSubscriptionFormSubmissonRecord,
    setErrorResult: React.Dispatch<
        React.SetStateAction<
            ObjectValidationResult<CancelSubscriptionFormSubmission>
        >
    >
) => {
    const validateResult =
        new CancelSubscriptionFormSubmissionValidator().validate(form);
    setErrorResult(validateResult);
    return ObjectValidator.hasErrors(validateResult);
};

// #endregion Validation

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SubscriptionCancellationModal;

// #endregion Export
