import FileLink from "atoms/anchors/file-link";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { LoaderSizes } from "atoms/constants/loader-sizes";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import FileRecord from "models/view-models/file-record";
import ListBox, { ListBoxItemClassName } from "molecules/lists/list-box";
import React, { PropsWithChildren } from "react";
import PathUtils from "utilities/path-utils";
import StringUtils from "utilities/string-utils";

interface UploadedFileFieldProps {
    cssClassName?: string;
    deleteLoading?: boolean;
    disableDelete?: boolean;
    file?: FileRecord;
    includeDelete?: boolean;
    label?: string;
    loading: boolean;
    loadingMessage?: string;
    onDeleteClick?: () => void;
}

const UploadedFileField: React.FunctionComponent<
    PropsWithChildren<UploadedFileFieldProps>
> = (props: PropsWithChildren<UploadedFileFieldProps>) => {
    const {
        children,
        cssClassName,
        deleteLoading,
        disableDelete,
        file,
        includeDelete,
        label,
        loading,
        loadingMessage,
        onDeleteClick,
    } = props;
    const CSS_BASE_CLASS = "c-file-upload-field__file-container__files";

    return (
        <div className={cssClassName}>
            <label>
                {StringUtils.hasValue(label) ? label : "Attached File"}
            </label>
            <ListBox>
                {children}
                {
                    // if
                    file != null && (
                        <div
                            className={`${ListBoxItemClassName} ${CSS_BASE_CLASS}`}>
                            {
                                // if
                                deleteLoading && (
                                    <Loader accessibleText="Deleting file..." />
                                )
                            }
                            {
                                // if
                                !deleteLoading && (
                                    <FileLink
                                        accessibleText="Download"
                                        cssClassName={`${CSS_BASE_CLASS}__filename`}
                                        file={file}>
                                        {PathUtils.relativePathToFilename(
                                            file.relativeProviderPath
                                        )}
                                    </FileLink>
                                )
                            }
                            {
                                // if
                                loading && (
                                    <div
                                        className={`${CSS_BASE_CLASS}__loading-message`}>
                                        <span
                                            className={`${CSS_BASE_CLASS}__loading-message__label`}>
                                            {loadingMessage}
                                        </span>
                                        <Loader
                                            type={LoaderStyles.LinkGlyphGray}
                                            size={LoaderSizes.Small}
                                        />
                                    </div>
                                )
                            }
                            {
                                // if
                                includeDelete === true && (
                                    <Button
                                        accessibleText={"Remove File"}
                                        cssClassName={`${CSS_BASE_CLASS}__remove-btn`}
                                        disabled={
                                            disableDelete || deleteLoading
                                        }
                                        onClick={onDeleteClick}
                                        size={ButtonSizes.Small}
                                        style={ButtonStyles.TertiaryAlt}>
                                        <Icon type={Icons.Trashcan} />
                                    </Button>
                                )
                            }
                        </div>
                    )
                }
            </ListBox>
        </div>
    );
};

export default UploadedFileField;
