import { EnvironmentUtils } from "andculturecode-javascript-core";
import FeatureFlags from "models/interfaces/feature-flags";
import React, { PropsWithChildren } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import PathUtils from "utilities/path-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FeatureFlagProps {
    feature: keyof FeatureFlags;
}

export interface FeatureStateProps<T> {
    value: T;
}

interface FeatureFlagNamespacedComponent<T> extends React.FC<T> {
    Boolean: typeof BooleanComponent;
    Id: typeof IdComponent;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

// This will be added as sub-components on the FeatureFlag namespace
// used like:
// <FeatureFlag.Boolean value={true}>{ feature content here }</FeatureFlag.Boolean>
// <FeatureFlag.Boolean value={false}>{ fallback content here }</FeatureFlag.Boolean>
//
// We could eventually extend this to types other than boolean, such as enums,
// but we would need to update the backend feature flags API to support that,
// specifically NfpaFeatureDefinitionProvider and FeatureFlagDtoConverter
const StateComponent = <T extends any>(
    props: PropsWithChildren<FeatureStateProps<T>>
) => <React.Fragment>{props.children}</React.Fragment>;
const BooleanComponent = (
    props: PropsWithChildren<FeatureStateProps<boolean>>
) => StateComponent<boolean>(props);
const IdComponent = (
    props: PropsWithChildren<FeatureStateProps<number | null | undefined>>
) => StateComponent<number | null | undefined>(props);

const flagComponentTypes = [BooleanComponent, IdComponent];

const formatComponentTypeNames = (components: Array<any>) =>
    components
        .map((c) => `<FeatureFlag.${c?.name?.replace("Component", "")}>`)
        .join(", ");

const formatComponentNamesWithValues = (components: Array<any>) =>
    components
        .map(
            (c) =>
                `<FeatureFlag.${c?.type?.name?.replace(
                    "Component",
                    ""
                )} value={${c?.props?.value}}>`
        )
        .join(", ");

const formattedComponentTypeNames =
    formatComponentTypeNames(flagComponentTypes);

const WARNING_INVALID_CHILDREN_MESSAGE =
    "feature-flag.tsx: WARNING: only FeatureFlag components are rendered as" +
    " children. All children of <FeatureFlag> must be one of:" +
    ` ${formattedComponentTypeNames}`;
const WARNING_DUPLICATE_CHILDREN_MESSAGE =
    "feature-flag.tsx: WARNING: only one state component should exist for" +
    " each feature state.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FeatureFlagComponent: React.FC<PropsWithChildren<FeatureFlagProps>> = (
    props: PropsWithChildren<FeatureFlagProps>
) => {
    const featureFlags = useFeatureFlags();
    if (featureFlags == null) {
        return null;
    }

    const allChildren = React.Children.toArray(props.children);

    // children which are not one of flagComponentTypes
    const invalidChildren = allChildren
        .filter(
            (el: any) => !flagComponentTypes.some((type) => el?.type === type)
        )
        .map((el) => el as React.Component);

    // valid children, one of flagComponentTypes
    const stateChildren = allChildren
        .filter((el: any) =>
            flagComponentTypes.some((type) => el?.type === type)
        )
        .map((el) => el as React.Component);

    // duplicate children by value
    const duplicateChildren = CollectionUtils.findDuplicates(
        stateChildren,
        (el?: any) => el?.props?.value
    );

    EnvironmentUtils.runIfDevelopment(() => {
        if (CollectionUtils.hasValues(invalidChildren)) {
            let invalidChildrenLineNumbers = "";
            invalidChildren.forEach((c: any) => {
                if (c._source != null) {
                    invalidChildrenLineNumbers += `\n\tat ${PathUtils.relativePathToFilename(
                        c._source.fileName
                    )}:${c._source.lineNumber}`;
                }
            });

            if (StringUtils.hasValue(invalidChildrenLineNumbers)) {
                invalidChildrenLineNumbers = `${invalidChildrenLineNumbers}\n`;
            }

            console.warn(
                WARNING_INVALID_CHILDREN_MESSAGE,
                invalidChildrenLineNumbers
            );
        }

        if (CollectionUtils.hasValues(duplicateChildren)) {
            console.warn(
                WARNING_DUPLICATE_CHILDREN_MESSAGE,
                `Found duplicate components: ${formatComponentNamesWithValues(
                    duplicateChildren
                )}`
            );
        }
    });

    const featureState = featureFlags[props.feature];
    if (Array.isArray(featureState)) {
        const currentStateChild = stateChildren.find((el?: React.Component) =>
            featureState.some((state) => state === (el?.props as any)?.value)
        );

        return <React.Fragment>{currentStateChild ?? null}</React.Fragment>;
    }

    const currentStateChild = stateChildren.find(
        (el?: React.Component) => (el?.props as any)?.value === featureState
    );

    return <React.Fragment>{currentStateChild ?? null}</React.Fragment>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const FeatureFlag: FeatureFlagNamespacedComponent<FeatureFlagProps> =
    Object.assign(FeatureFlagComponent, {
        Boolean: BooleanComponent,
        Id: IdComponent,
    });
export default FeatureFlag;

// #endregion Exports
