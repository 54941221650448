import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ChangeSummariesAdminBreadcrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">Change Summaries</span>
    </Breadcrumb>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ChangeSummariesAdminBreadcrumb;

// #endregion Exports
