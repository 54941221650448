import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React from "react";
import { siteMap } from "internal-sitemap";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { SkipNavContent } from "@reach/skip-nav";
import Button from "atoms/buttons/button";
import useModalActions from "utilities/hooks/use-modal-actions";
import DashboardVideoUpdateModal from "organisms/modals/dashboard-video-update-modal/dashboard-video-update-modal";
import AllowRoles from "../../molecules/allow-roles/allow-roles";
import RoleType, {
    ContentAdminRoleTypes,
} from "../../utilities/enumerations/role-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AdminDashboardPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const TITLE = "Admin Console";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-admin-dashboard";
const AdminDashboardPage: React.FC<AdminDashboardPageProps> = () => {
    useHeaderData({ title: TITLE });

    const {
        isOpen: isDashboardVideoModalOpen,
        handleOpen: handleOpenDashboardVideoModal,
        handleClose: handleCloseDashboardVideoModal,
    } = useModalActions();

    return (
        <React.Fragment>
            <BreadcrumbsMenu fullWidth={true} />
            <div className="c-dashboard -breadcrumb">
                <SkipNavContent>
                    <div
                        className={`c-dashboard__panel ${CSS_CLASS_NAME}`}
                        tabIndex={-1}>
                        <Heading priority={HeadingPriority.Three}>
                            {TITLE}
                        </Heading>
                        <AllowRoles allowedRoles={ContentAdminRoleTypes}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    DiRECT Management
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .situations.dashboard
                                        }>
                                        Situations
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .solutions.dashboard
                                        }>
                                        Solutions
                                    </Anchor>
                                </div>
                            </div>
                        </AllowRoles>
                        <AllowRoles allowedRoles={ContentAdminRoleTypes}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    DiRECT Category Management
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .categories.occupancies
                                                .dashboard
                                        }>
                                        Occupancies
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .categories.systems.dashboard
                                        }>
                                        Systems
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .categories.spaces.dashboard
                                        }>
                                        Spaces
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.situationalNavigation
                                                .categories.equipment.dashboard
                                        }>
                                        Equipment
                                    </Anchor>
                                </div>
                            </div>
                        </AllowRoles>
                        <AllowRoles allowedRoles={ContentAdminRoleTypes}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    Publication Management
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.publications.dashboard
                                        }>
                                        Publications
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.enhancedContent
                                                .dashboard
                                        }>
                                        Enhanced Content
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.changeSummaries
                                                .dashboard
                                        }>
                                        Change Summaries
                                    </Anchor>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={
                                            siteMap.admin.freeAccessPublications
                                                .dashboard
                                        }>
                                        Free Access Publications
                                    </Anchor>
                                </div>
                            </div>
                        </AllowRoles>
                        <AllowRoles
                            allowedRoles={[
                                RoleType.SYS_ADMIN,
                                RoleType.SUPPORT,
                            ]}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    User Management
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <AllowRoles
                                        allowedRoles={[RoleType.SYS_ADMIN]}>
                                        <Anchor
                                            cssClassName="c-button"
                                            to={
                                                siteMap.admin.userManagement
                                                    .dashboard
                                            }>
                                            Admin Users
                                        </Anchor>
                                    </AllowRoles>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={siteMap.admin.support.users}>
                                        LiNK Subscribers
                                    </Anchor>
                                </div>
                            </div>
                        </AllowRoles>
                        <AllowRoles allowedRoles={ContentAdminRoleTypes}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    Marketing
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <Anchor
                                        cssClassName="c-button"
                                        to={siteMap.admin.announcements}>
                                        Announcements
                                    </Anchor>
                                </div>
                            </div>
                        </AllowRoles>
                        <AllowRoles allowedRoles={[RoleType.SYS_ADMIN]}>
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    Application Information
                                </Paragraph>
                                <div
                                    className={`${CSS_CLASS_NAME}__section__links`}>
                                    <Anchor
                                        to={siteMap.admin.featureFlags}
                                        cssClassName="c-button">
                                        View Feature Flags
                                    </Anchor>
                                    <Button
                                        cssClassName="c-button"
                                        onClick={handleOpenDashboardVideoModal}>
                                        Dashboard Video
                                    </Button>
                                    {isDashboardVideoModalOpen && (
                                        <DashboardVideoUpdateModal
                                            isVisible={
                                                isDashboardVideoModalOpen
                                            }
                                            closeDialog={
                                                handleCloseDashboardVideoModal
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </AllowRoles>
                    </div>
                </SkipNavContent>
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AdminDashboardPage;

// #endregion Exports
