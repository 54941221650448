import { useLocalization } from "andculturecode-javascript-react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import useWorkGroupReadService from "utilities/hooks/domain/work-groups/use-work-group-read-service";
import CultureResources from "utilities/interfaces/culture-resources";

const UserWorkGroupsList = () => {
    const CSS_NAME = "c-account-dashboard__information__workgroups";
    const { t } = useLocalization<CultureResources>();
    const { workGroupRecords: workgroups } = useWorkGroupReadService();
    const workgroupsLabel = t("workgroups-information-label");
    const workgroupsInterestParagraph = t("workgroups-information-interest");
    const notAdded = t("notAddedWorkgroups");
    const isMember = workgroups.length > 0;

    return (
        <div>
            <Heading priority={HeadingPriority.Five}>{workgroupsLabel}</Heading>
            {!isMember && (
                <Paragraph cssClassName="-interest" size={ParagraphSizes.Small}>
                    {notAdded}
                </Paragraph>
            )}
            {isMember && (
                <Paragraph cssClassName="-interest" size={ParagraphSizes.Small}>
                    {workgroupsInterestParagraph}
                </Paragraph>
            )}
            {workgroups.map((workgroup) => (
                <div key={workgroup.id} className={`${CSS_NAME} -pill`}>
                    {workgroup.title}
                </div>
            ))}
        </div>
    );
};

export default UserWorkGroupsList;
