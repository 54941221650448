import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import FreeAccessPublicationTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import React from "react";

interface TableOfContentsImageViewerCollapsePanelItemProps {
    item: FreeAccessPublicationTableOfContentsEntry;
    onEntryClick: (entry: FreeAccessPublicationTableOfContentsEntry) => void;
}

const BASS_CSS_CLASSNAME = "c-image-viewer__nav__list__item";

const TableOfContentsImageViewerCollapsePanelItem = (
    props: TableOfContentsImageViewerCollapsePanelItemProps
) => {
    const { item, onEntryClick } = props;

    const displayText = `${item.heading} ${item.description}`;

    return (
        <li>
            <Button
                accessibleText={displayText}
                cssClassName={BASS_CSS_CLASSNAME}
                onClick={() => onEntryClick(item)}
                style={ButtonStyles.None}>
                {displayText}
            </Button>
        </li>
    );
};

export default TableOfContentsImageViewerCollapsePanelItem;
