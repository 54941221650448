import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";

interface BigButtonProps {
    icon: keyof typeof Icons;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const BASE_CLASS = "c-big-button";

const BigButton: React.FC<React.PropsWithChildren<BigButtonProps>> = (
    props: React.PropsWithChildren<BigButtonProps>
) => {
    const { children, icon, onClick } = props;
    return (
        <button className={BASE_CLASS} onClick={onClick}>
            <div className={`${BASE_CLASS}__icon`}>
                <Icon size={IconSizes.Large} type={icon} />
            </div>
            {children}
        </button>
    );
};

export default BigButton;
