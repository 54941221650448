import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import DisclaimerText from "molecules/situational-navigation/disclaimer-text/disclaimer-text";
import HotSpotImage from "molecules/situational-navigation/hotspot-image";
import SituationRelationshipsSection from "organisms/situational-navigation/situations/situation-relationships-section";
import NotFoundPage from "pages/errors/not-found";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useSituation from "utilities/hooks/domain/situational-navigation/situations/use-situation";
import useLoading from "utilities/hooks/use-loading";
import NumberUtils from "utilities/number-utils";
import RichTextUtils from "utilities/rich-text-utils";
import SituationRelationshipService from "utilities/services/situational-navigation/situations/situation-relationship-service";
import SituationService from "utilities/services/situational-navigation/situations/situation-service";
import SolutionService from "utilities/services/situational-navigation/solutions/solution-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { SkipNavContent } from "@reach/skip-nav";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import DirectBackAnchor from "atoms/anchors/direct-back-anchor";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-situation-page";
const LOADING_SITUATION_SOLUTIONS_ERROR =
    "There was an issue loading situation solutions.";

// #endregion Constants

const SituationPage: React.FC = () => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { id: situationId } = useParams();
    const [loadingSituationRelationships, setLoadingSituationRelationships] =
        useState(false);
    const [situationRelationships, setSituationRelationships] = useState<
        Array<SituationRelationshipRecord>
    >([]);
    const [solutions, setSolutions] = useState<Array<SolutionRecord>>([]);
    const [situations, setSituations] = useState<Array<SituationRecord>>([]);

    // #endregion State

    const { list: listSituationApi } = SituationService.useList();
    const { list: listSolutionApi } = SolutionService.useList();
    const { resultObject: situation, loading: loadingSituation } =
        useSituation(situationId);
    const { list: listSituationRelationshipsApi } =
        SituationRelationshipService.useList();

    const loading = useLoading(loadingSituation, loadingSituationRelationships);
    const { setPageTitle } = useHeaderData();

    const hasInfoClass = useMemo(
        () =>
            RichTextUtils.hasValue(situation?.body ?? "") ? "-has-info" : "",
        [situation]
    );

    const loadingText = t("loadingItem", {
        item: t("situation"),
    });

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => setPageTitle(situation?.title), [setPageTitle, situation]);

    useEffect(() => {
        // Load situation solutions
        const loadSituationRelationships = async (id: number) => {
            setLoadingSituationRelationships(true);

            try {
                // Get the situation solutions records.
                const [
                    situationRelationshipsResult,
                    solutionsResult,
                    situationsResult,
                ] = await Promise.all([
                    listSituationRelationshipsApi({
                        situationId: id,
                    }),
                    listSolutionApi({
                        situationId: id,
                    }),
                    listSituationApi({
                        relatedSituationId: id,
                    }),
                ]);

                setSituationRelationships(
                    situationRelationshipsResult.resultObjects ?? []
                );
                setSolutions(solutionsResult.resultObjects ?? []);
                setSituations(situationsResult.resultObjects ?? []);
            } catch (e) {
                ToastManager.error(LOADING_SITUATION_SOLUTIONS_ERROR);
            }
            setLoadingSituationRelationships(false);
        };

        const id = NumberUtils.parseInt(situationId);
        if (id == null) {
            return;
        }
        loadSituationRelationships(id);
    }, [
        situationId,
        listSolutionApi,
        listSituationRelationshipsApi,
        listSituationApi,
    ]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    if (loading) {
        return <Loader accessibleText={loadingText} />;
    }

    if (situation == null) {
        return <NotFoundPage />;
    }

    return (
        <div className={CSS_BASE_CLASS}>
            <section>
                <div className={`${CSS_BASE_CLASS}__top`}>
                    <DirectBackAnchor />
                    <div>
                        <Icon
                            cssClassName={`${CSS_BASE_CLASS}__situation-icon`}
                            type={Icons.Sitnav}
                        />
                        <span className="c-label -small">{t("situation")}</span>
                    </div>
                </div>
            </section>
            <SkipNavContent tabIndex={-1}>
                <section className={`${CSS_BASE_CLASS}__container`}>
                    <article className={`${CSS_BASE_CLASS}__content`}>
                        <Heading
                            cssClassName={`${CSS_BASE_CLASS}__header`}
                            priority={HeadingPriority.Four}>
                            {situation.title}
                        </Heading>
                        {RichTextUtils.hasValue(situation.body) && (
                            <a
                                className={`${CSS_BASE_CLASS}__additional-info-button c-button -small`}
                                href={"#additional-info"}>
                                {t("situationPage-additionalInfo")}
                            </a>
                        )}
                        <Paragraph size={ParagraphSizes.Small}>
                            {`${t("situationPage-covers")}:`}
                        </Paragraph>
                        {situation.hotSpotImageFileId != null && (
                            <div
                                className={`${CSS_BASE_CLASS}__content__image-container`}>
                                <div
                                    className={`${CSS_BASE_CLASS}__content__image`}>
                                    <HotSpotImage
                                        hotSpotImageFile={
                                            situation.hotSpotImageFile
                                        }
                                    />
                                </div>
                                <div
                                    className={`${CSS_BASE_CLASS}__content__map`}>
                                    {situationRelationships
                                        .filter(
                                            (s: SituationRelationshipRecord) =>
                                                s.hotSpotX != null &&
                                                s.hotSpotY != null
                                        )
                                        .map(
                                            (
                                                hotspot: SituationRelationshipRecord,
                                                index: number
                                            ) => {
                                                const hotspotModifier =
                                                    hotspot.relatedSituationId
                                                        ? "-situation"
                                                        : "-solution";
                                                return (
                                                    <span
                                                        style={hotspot.toDraftInlineStyles()}
                                                        key={index}
                                                        className={`${CSS_BASE_CLASS}__content__map__hotspot ${hotspotModifier}`}>
                                                        {hotspot.displaySequence +
                                                            1}
                                                    </span>
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                        )}
                        {
                            // if
                            CollectionUtils.hasValues(
                                situationRelationships
                            ) && (
                                <SituationRelationshipsSection
                                    hotSpotImageFileId={
                                        situation.hotSpotImageFileId
                                    }
                                    situationRelationships={
                                        situationRelationships
                                    }
                                    situations={situations}
                                    solutions={solutions}
                                />
                            )
                        }
                    </article>
                </section>
                <section
                    className={`${CSS_BASE_CLASS}__additional-info-container ${hasInfoClass}`}
                    id={"additional-info"}>
                    {
                        // if
                        RichTextUtils.hasValue(situation.body) && (
                            <React.Fragment>
                                <Paragraph
                                    cssClassName={`${CSS_BASE_CLASS}__additional-info-container__header`}
                                    size={ParagraphSizes.Base}>
                                    {t("situationPage-additionalInfo")}
                                </Paragraph>
                                <RichTextArea content={situation.body} />
                            </React.Fragment>
                        )
                    }
                    <DisclaimerText />
                </section>
            </SkipNavContent>
        </div>
    );

    // #endregion Component
};

export default SituationPage;
