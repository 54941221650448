import InputCharacterCount from "atoms/forms/input-character-count";
import { InputFormFieldProps } from "molecules/form-fields/input-form-field";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormFieldErrorsProps
    extends Pick<
        InputFormFieldProps,
        | "errorMessage"
        | "errorMessages"
        | "showCharacterCount"
        | "value"
        | "maxLength"
    > {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormFieldErrors: React.FC<FormFieldErrorsProps> = (
    props: FormFieldErrorsProps
) => {
    const {
        errorMessage,
        errorMessages,
        maxLength,
        showCharacterCount,
        value,
    } = props;

    if (
        StringUtils.isEmpty(errorMessage) &&
        CollectionUtils.isEmpty(errorMessages)
    ) {
        return null;
    }

    return (
        <div className="c-form-field__bottom">
            <div className="c-form-field__bottom__errors">
                {
                    // if
                    StringUtils.hasValue(errorMessage) && (
                        <label>{errorMessage}</label>
                    )
                }
                {
                    // if
                    CollectionUtils.hasValues(errorMessages) &&
                        errorMessages?.map((s: string) => (
                            <label key={s}>{s}</label>
                        ))
                }
            </div>
            {
                // if
                showCharacterCount && (
                    <InputCharacterCount
                        currentLength={(value ?? "").length}
                        maxLength={maxLength ?? 20}
                    />
                )
            }
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FormFieldErrors;

// #endregion Exports
