import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";
import useRemoveWhitespaceChildren from "utilities/hooks/use-remove-whitespace-children";
import XmlUtils from "utilities/xml-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const NON_NFPA_REF_CONVERTER_ID = "non-nfpa-ref";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ParagraphConverterProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ParagraphConverter: React.FunctionComponent<ParagraphConverterProps> = (
    props: ParagraphConverterProps
) => {
    const { children } = props;
    const filteredChildren = useRemoveWhitespaceChildren(children);

    return (
        <Paragraph
            cssClassName={buildCssClassName(props)}
            size={ParagraphSizes.Base}>
            {filteredChildren}
        </Paragraph>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

function buildCssClassName(props: ParagraphConverterProps): string {
    const cssClassnames: string[] = [];
    const { diffchanged } = props;

    if (diffchanged != null) {
        cssClassnames.push(`c-code-change`);
        cssClassnames.push(`-${diffchanged}`);
    }

    if (XmlUtils.childrenHaveConverter(props, NON_NFPA_REF_CONVERTER_ID)) {
        cssClassnames.push(`-${NON_NFPA_REF_CONVERTER_ID}`);
    }

    return cssClassnames.join(" ");
}

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ParagraphConverter;

// #endregion Exports
