import SectionRecord from "models/view-models/section-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SectionBasePathParams {
    annexId: number;
    publicationId: number;
}

export interface SectionResourcePathParams extends SectionBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Sections by Annex.
 * These get translated to QueryString parameters
 */
export interface SectionListQueryParams {
    groupBy?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/annexes/:annexId/sections";
const resourceType = SectionRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SectionService = {
    key: "publication-annexes-sections",
    /**
     * Retrieve a specific Section resource in an Annex
     */
    get: ServiceFactory.get<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Sections based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionService;

// #endregion Exports
