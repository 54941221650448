import React, { ReactNode } from "react";
import TiaChangesHeader from "./tia-changes-panel-header";
import TiaRecord from "models/view-models/tia-record";
import TiaEffectiveDateLine from "./tia-effective-date-line";
import { CaapsTypes } from "constants/caaps-types";
import TiaChangeSummary from "./tia-change-summary";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import {
    LevelModifiers,
    SectionLevelClassNames,
} from "organisms/section-detail/section-detail-wrapper";
import { SectionType } from "models/enumerations/section-type";

interface TiaListContentProps {
    handleClose: () => void;
    index: number;
    titleTias: TiaRecord[];
    headingTia: TiaRecord;
    currentTias: TiaRecord[];
    sectionLevels: Record<string, number | undefined>;
}

const CSS_BASE_CLASS = "c-tia-changes-panel";

const TiaListContent = ({
    currentTias,
    handleClose,
    headingTia,
    index,
    sectionLevels,
    titleTias,
}: TiaListContentProps) => (
    <>
        <TiaChangesHeader handleClose={handleClose} />

        {/* TODO: for multiple dates
                {tias.length > 1 && (
                    <TiaChangesPanelNavigation
                        activeTia={activeTiaIndex}
                        numberOfTias={tias.length}
                        onSetActiveTia={setActiveTiaIndex}
                    />
                )} */}

        <div className={`${CSS_BASE_CLASS}__contents`}>
            <TiaEffectiveDateLine currentTiaIndex={index} tias={titleTias} />

            <div className={`${CSS_BASE_CLASS}__body`}>
                <Title title={headingTia.getTitle()} />

                {headingTia.caapsType !== CaapsTypes.Chapter && (
                    <Body body={headingTia.getBody()} />
                )}

                {currentTias.map((t) => (
                    <TiaSection
                        key={t.externalId}
                        tia={t}
                        sectionType={sectionLevels[t.externalId ?? ""]}
                    />
                ))}
            </div>
        </div>

        <div style={{ padding: "1rem" }}>
            {headingTia.changeSummary && (
                <TiaChangeSummary changeSummary={headingTia.changeSummary} />
            )}
        </div>
    </>
);

export default TiaListContent;

const Title = ({ title }: { title: ReactNode }) => (
    <section className={`${CSS_BASE_CLASS}__heading -title`} tabIndex={-1}>
        <Heading
            cssClassName={`${CSS_BASE_CLASS}__title`}
            priority={HeadingPriority.Two}>
            {title}
        </Heading>
    </section>
);

const Body = ({ body }: { body: ReactNode }) => (
    <div className={`${CSS_BASE_CLASS}__body`}>{body}</div>
);

const TiaSection = ({
    tia,
    sectionType,
}: {
    tia: TiaRecord;
    sectionType: number | undefined;
}) => {
    const classNames = getClassNames(sectionType);

    return (
        <div
            className={classNames.wrapperClass}
            onClick={() => scrollToSection(tia.externalId ?? "")}>
            <div className={classNames.sectionClass}>
                <span className={`${classNames.sectionClass}__title`}>
                    {tia.getTitle()}
                </span>
                {tia.getBody()}
            </div>
        </div>
    );
};

const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    section?.scrollIntoView({ behavior: "smooth", block: "center" });
    section?.focus({ preventScroll: true });
};

const sectionWrapper = "c-section-wrapper";

const getClassNames = (type: number | undefined) => {
    const defaultClasses = {
        wrapperClass: `${sectionWrapper} ${LevelModifiers.two}`,
        sectionClass: SectionLevelClassNames.two,
    };

    let sectionClass: string, levelModifier: string;

    switch (type) {
        case SectionType.Section:
            sectionClass = SectionLevelClassNames.zero;
            levelModifier = LevelModifiers.zero;
            break;

        case SectionType.SubSection:
            sectionClass = SectionLevelClassNames.one;
            levelModifier = LevelModifiers.one;
            break;

        case SectionType.SubSubSection:
        default:
            return defaultClasses;
    }

    return {
        wrapperClass: `${sectionWrapper} ${levelModifier}`,
        sectionClass,
    };
};
