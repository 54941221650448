import { MotionProps, Transition } from "framer-motion";

const smoothTransition: Transition = { type: "tween", duration: 0.2 };

const FADE_IN: MotionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

const SLIDE_UP: MotionProps = {
    initial: {
        bottom: 0,
        height: "100%",
        maxHeight: "100%",
        position: "absolute",
        display: "flex",
        width: "100%",
        y: "100%",
    },
    animate: { y: 0, transition: smoothTransition },
    exit: { y: "100%", transition: smoothTransition },
    transition: smoothTransition,
};

const SLIDE_RIGHT: MotionProps = {
    initial: { x: "-100%" },
    animate: { x: 0 },
    exit: { x: "-100%" },
    transition: smoothTransition,
};

/*
    Usage:
        <motion.div {...FramerMotionTransitions.SLIDE_RIGHT> Your animationed component here </motion.div>}
*/

export const FramerMotionTransitions = {
    SLIDE_RIGHT,
    SLIDE_UP,
    FADE_IN,
};
