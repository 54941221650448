import * as React from "react";
import { PropsWithChildren } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface StickyNotificationProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-sticky-notification";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const StickyNotification: React.FunctionComponent<
    PropsWithChildren<StickyNotificationProps>
> = (props: PropsWithChildren<StickyNotificationProps>) => {
    const { children } = props;
    return <div className={BASE_CLASS_NAME}>{children}</div>;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default StickyNotification;

// #endregion Exports
