import UserLoginRecord from "models/view-models/user-login-record";
import { useCallback, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import GlobalStateRecord from "models/view-models/global-state-record";
import useIdentity from "utilities/hooks/use-identity";

/**
 * Custom hook providing utility functions for the current identity of the user
 */
export default function useCurrentIdentity() {
    const [loading, setLoading] = useState(false);
    const { buildCurrentIdentity } = useIdentity();
    const { globalState, setGlobalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const isLoggedIn = globalState.isAuthenticated();

    const build = useCallback(
        (userLogin?: UserLoginRecord) => {
            if (loading) {
                return;
            }

            setLoading(true);

            buildCurrentIdentity(userLogin).then((identity) => {
                setGlobalState((globalState: GlobalStateRecord) =>
                    globalState.setIdentity(identity)
                );

                setLoading(false);
            });
        },
        [buildCurrentIdentity, loading, setGlobalState]
    );

    const refresh = useCallback(
        () => build(currentIdentity?.userLogin),
        [build, currentIdentity?.userLogin]
    );

    return {
        build,
        currentIdentity,
        isLoggedIn,
        loading,
        refresh,
    };
}
