import { List } from "immutable";
import type EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useLoadResourceStatus from "utilities/hooks/use-load-resource-status";
import usePageErrors from "utilities/hooks/use-page-errors";
import useRefreshToken from "utilities/hooks/use-refresh-token";
import AdminEnhancedContentResourceService from "utilities/services/admin/enhanced-content/resources/enhanced-content-resource-service";
import EnhancedContentResourceService from "utilities/services/enhanced-content/resources/enhanced-content-resource-service";
import FileService from "utilities/services/file-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_LOADING_RESOURCES = "There was an issue loading related resources.";
const FILE_BATCH_SIZE = 25;

// #endregion Constants

/**
 * Custom hook to gather EnhancedContentResources from the api
 */
export default function useEnhancedContentResources(
    disabled: boolean,
    enhancedContentId?: number,
    draft?: boolean
) {
    const [resources, setResources] = useState(
        List<EnhancedContentResourceRecord>()
    );
    const { loading, loaded, setLoading, setLoaded } = useLoadResourceStatus();

    const { list: adminListResourcesApi } =
        AdminEnhancedContentResourceService.useList();
    const { list: nonAdminListResourcesApi } =
        EnhancedContentResourceService.useList();
    const listResourcesApi = draft
        ? adminListResourcesApi
        : nonAdminListResourcesApi;

    const { list: listFiles } = FileService.useBatchedList();

    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    const { refreshToken, refresh } = useRefreshToken();

    const listResources = useCallback(async () => {
        setLoading(true);
        if (disabled || enhancedContentId == null) {
            setResources(List());
            setLoading(false);
            return;
        }

        resetPageErrors();
        setLoaded(false);
        try {
            let { resultObjects: enhancedContentResources } =
                await listResourcesApi({
                    enhancedContentId: enhancedContentId,
                });

            const fileIds = CollectionUtils.uniqueValuesByProperty(
                enhancedContentResources,
                draft ? "fileDraftId" : "fileId"
            );

            const { resultObjects: files } = await listFiles(
                { ids: fileIds },
                "ids",
                FILE_BATCH_SIZE
            );

            enhancedContentResources = enhancedContentResources.map(
                (e: EnhancedContentResourceRecord) => e.withFile(files)
            );

            setResources(List(enhancedContentResources));
            setLoading(false);
            setLoaded(true);
        } catch (e) {
            ToastManager.error(ERROR_LOADING_RESOURCES);
            handlePageLoadError(e);
            setLoading(false);
        }
    }, [
        disabled,
        draft,
        enhancedContentId,
        handlePageLoadError,
        listFiles,
        listResourcesApi,
        resetPageErrors,
        setLoaded,
        setLoading,
    ]);

    useEffect(() => {
        if (disabled) {
            return;
        }
        listResources();
    }, [disabled, listResources, refreshToken]);

    return {
        errors,
        listResources,
        loading,
        loaded,
        resources,
        refresh,
    };
}
