import { Resource } from "models/interfaces/resource";
import ExternalResourceUtils from "utilities/external-resource-utils";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";

export default class ResourceValidator extends ObjectValidator<Resource> {
    validate(
        resource: Resource,
        isExternal: boolean
    ): ObjectValidationResult<Resource> {
        const result: ObjectValidationResult<Resource> = {};

        if (StringUtils.isEmpty(resource.titleDraft)) {
            result.titleDraft = ["Title is required."];
        }

        if (isExternal && StringUtils.isEmpty(resource.absoluteUrlDraft)) {
            result.absoluteUrlDraft = ["URL is required."];
        }

        if (
            isExternal &&
            StringUtils.hasValue(resource.absoluteUrlDraft) &&
            !ExternalResourceUtils.isYoutubeLink(resource.absoluteUrlDraft) &&
            !ExternalResourceUtils.isBrightcoveLink(resource.absoluteUrlDraft)
        ) {
            result.absoluteUrlDraft = [
                "URL must be a YouTube or Brightcove video URL.",
            ];
        }

        if (!isExternal && resource.fileDraftId == null) {
            result.fileDraftId = ["File upload is required."];
        }

        return result;
    }
}
