import { ButtonProps } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import Paragraph from "atoms/typography/paragraph";
import { ModalTypes } from "molecules/constants/modal-types";
import BaseModalFooter from "molecules/modals/footers/base-modal-footer";
import BaseModalHeader from "molecules/modals/headers/base-modal-header";
import Modal from "molecules/modals/modal";
import React from "react";
import useModalActions from "utilities/hooks/use-modal-actions";

interface Props {
    setCookie: () => void;
    publication: string;
}

const BASE_CLASSNAME = "c-free-access-page__modal";
const BODY = `${BASE_CLASSNAME}__body`;
const PARA = `${BODY}__paragraph`;
const LIST = `${BODY}__list`;

const FreeAccessTermsModal = (props: Props) => {
    const { publication, setCookie } = props;

    const { handleClose } = useModalActions();

    const header = `Accept Terms for: ${publication}`;

    const onAccept = () => {
        setCookie();
        handleClose();
    };

    const primaryButtonProps: ButtonProps = {
        accessibleText: "I AGREE",
        onClick: onAccept,
        style: ButtonStyles.Primary,
    };

    const decline = () => (window.location.href = "https://www.nfpa.org/");

    const closeButtonProps: ButtonProps = {
        accessibleText: "Return to Homepage",
        onClick: decline,
        style: ButtonStyles.Secondary,
    };

    return (
        <Modal
            cssClassName={BASE_CLASSNAME + " -no-padding"}
            closeDialog={handleClose}
            isVisible={true}
            label={header}
            type={ModalTypes.Fullscreen}>
            <BaseModalHeader closeDialog={() => {}} heading={header} />
            <Body />
            <BaseModalFooter
                closeButtonProps={closeButtonProps}
                closeButtonText={"Return to Homepage"}
                closeDialog={handleClose}
                primaryButtonProps={primaryButtonProps}
                primaryText={"I AGREE"}
            />
        </Modal>
    );
};

export default FreeAccessTermsModal;

const Body = () => (
    <div className={BODY}>
        <Paragraph cssClassName={PARA}>{terms.one}</Paragraph>
        <Paragraph cssClassName={PARA}>{terms.two}</Paragraph>
        <ol className={LIST}>
            {terms.list.map((t) => (
                <li>
                    <Paragraph>{t}</Paragraph>
                </li>
            ))}
        </ol>
        <Paragraph cssClassName={PARA}>{terms.three}</Paragraph>
        <Paragraph cssClassName={PARA}>{terms.four}</Paragraph>
    </div>
);

const terms = {
    one: 'Please indicate your acceptance of the following terms for accessing the NFPA online document you have selected (“the Online Document”) by scrolling down the page and clicking "I AGREE" to connect. By clicking on "I AGREE," you accept the terms of this agreement',

    two: `This is a legal agreement between you (the NFPA visitor) and the NFPA for access to and use of the Online Document. By clicking on “I AGREE” below and by using the Online Document, you agree to be bound by the terms of this agreement both as to this and each subsequent use you make of the Online Document, and you are responsible for ensuring that the terms of this agreement are met. If you do not agree to the terms of this agreement, click on the "Return to Homepage" button below.`,

    list: [
        "GRANT OF LICENSE. NFPA grants you, the NFPA visitor, a nonexclusive and nontransferable license to view online the content of the Online Document. The Online Document is designed to be viewed online only - there are no “print,” “save,” or “cut and paste” options - and the license granted to you by this agreement does not include the right to download, reproduce, store in a retrieval system, modify, make available on a network, use to create derivative works, or transmit the content of the Online Document in any form or by any means, electronic, mechanical, photocopying, recording, scanning, or otherwise.",

        "COPYRIGHT. You acknowledge that the content of the Online Document is copyrighted and owned by NFPA and is protected by U.S. copyright law and international treaty provisions. You acquire no proprietary interest in the Online Document or any of the information displayed therein. Nothing herein is intended to prohibit you from making limited, non-commercial use of the content of any NFPA codes, standards, guides, and recommended practices to the extent that such use is a “fair use” under the copyright laws of the United States. However, such fair use does not include the disabling, circumventing, or otherwise evading the read-only or other technological measures that limit copying of the content of the Online Document. No copying beyond that permitted by “fair use” shall be permitted without the express written permission of the NFPA. Permission will be considered based on a written request to the Associate General Counsel, NFPA, 1 Batterymarch Park, P.O. Box 9101, Quincy, MA 02269-9101. Print and full-featured electronic versions of NFPA codes, standards, guides, and recommended practices, including the Online Document, are available for purchase through this web site or by contacting NFPA at 1-800-344-3555.",

        "WARRANTY LIMITATION AND DISCLAIMER OF LIABILITY. THE ONLINE DOCUMENT IS PROVIDED TO YOU “AS” IS” AND WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATIONS THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NFPA SHALL NOT BE LIABLE FOR ANY DAMAGE OR LOSS OF ANY KIND ARISING OUT OF, RESULTING FROM, OR IN ANY WAY RELATED TO THE ONLINE DOCUMENT, INCLUDING WITHOUT LIMITATION, (A) ANY ERRORS IN OR OMISSIONS IN THE CONTENT OF THE ONLINE DOCUMENT; (B) THE UNAVAILABILITY OR INTERRUPTION OF ACCESS TO THE ONLINE DOCUMENT; (C) YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH USING THE ONLINE DOCUMENT; AND (D) YOUR USE OF THE CONTENT AND INFORMATION OR OPINIONS CONTAINED IN THE ONLINE DOCUMENT. OTHER IMPORTANT NOTICES AND DISCLAIMERS OF LIABILITY ARE CONTAINED IN THE INTRODUCTORY MATERIALS INCLUDED AT THE BEGINNING OF ALL PRINT AND ELECTRONIC VERSIONS OF NFPA CODES, STANDARDS, GUIDES, AND RECOMMENDED PRACTICES, INCLUDING THE ONLINE DOCUMENT, AND ARE INCORPORATED HEREIN BY REFERENCE. SOME STATES RESTRICT WARRANTY AND REMEDY EXCLUSIONS AND LIMITATIONS, AND, TO THE EXTENT OF SUCH RESTRICTIONS, THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH STATES, NFPA'S LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.",

        "MISCELLANEOUS. The terms of this agreement may be changed from time to time. NFPA may suspend or discontinue providing the Online Document to you with or without cause and without notice. NFPA may pursue any remedy legally available to it if you fail to comply with any of your obligations hereunder. The failure of NFPA to enforce any provision hereof shall not constitute or be construed as a waiver of such provision or of the right to enforce it at a later time.",
    ],

    three: `You may not assign or transfer your rights or obligations under this agreement.
    This agreement shall be construed and interpreted pursuant to the laws of the State of Massachusetts applicable to agreements wholly entered into and performed in the State of Massachusetts, excluding that body of law dealing with conflict of laws. Any legal action, suit, or proceeding arising out of or relating to this agreement or the breach thereof shall be instituted in a court of competent jurisdiction in the State of Massachusetts, Norfolk County, and each party hereby consents and submits to the personal jurisdiction of such court, waives any objection to venue in such court and consents to the service of process by registered or certified mail, return receipt requested, at the last known address of such party.`,

    four: "The terms of this agreement constitute the entire agreement between the parties with respect to the subject matter hereof. If any provision hereof is adjudged to be invalid, void, or unenforceable, the parties agree that the remaining provisions hereof will not be affected thereby, that the provision in question may be replaced by the lawful provision that most nearly embodies the original intention of the parties, and that the terms of this agreement will in any event remain valid and enforceable.",
};
