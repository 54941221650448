import * as React from "react";
import { forwardRef, useState } from "react";
import uuid from "uuid";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import { TextInputProps } from "atoms/forms/text-input";

export interface RadioButtonProps<TValue extends string | string[] | number>
    extends Pick<DataTestAttributes, "dataTestId">,
        Pick<TextInputProps, "disabled"> {
    autofocus?: boolean;
    checked: boolean;
    children?: React.ReactNode;
    cssClassName?: string;
    id?: string;
    label: string;
    name: string;
    onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    ref?: React.RefObject<HTMLInputElement> | null;
    value?: TValue;
}

const RadioButton: React.RefForwardingComponent<
    HTMLInputElement,
    RadioButtonProps<string | string[] | number>
> = forwardRef(
    <TValue extends string | string[] | number>(
        props: RadioButtonProps<TValue>,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const {
            autofocus,
            checked,
            children,
            cssClassName,
            dataTestId,
            disabled = false,
            label,
            name,
            onCheck,
            onClick,
            value,
        } = props;

        const handleChecked = (e: React.ChangeEvent<HTMLInputElement>): void =>
            onCheck?.(e);
        const handleClick = (): void => onClick?.();

        // stateful so it doesn't recompute on every render
        const [id] = useState(props.id ?? `radio-${uuid.v4()}`);

        return (
            <div
                className={`c-radio ${
                    checked ? "-selected" : ""
                } ${cssClassName}`}>
                <input
                    autoFocus={autofocus}
                    checked={checked}
                    data-test-id={dataTestId}
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={handleChecked}
                    onClick={handleClick}
                    ref={ref}
                    type="radio"
                    value={value}
                />
                <label htmlFor={id}>
                    {label}
                    {children}
                </label>
            </div>
        );
    }
);

export default RadioButton;
