import PublicationTypes from "constants/publication-types";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import PublicationRecord from "models/view-models/publication-record";
import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import TableOfContentsAnnexGroups from "organisms/table-of-contents/table-of-contents-annex-groups";
import React, { Fragment, useCallback } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import TableOfContentsCollapsePanelHeader from "organisms/table-of-contents/table-of-contents-collapse-panel-header";
import TableOfContentsCollapsePanelLink from "organisms/table-of-contents/table-of-contents-collapse-panel-link";
import StringUtils from "utilities/string-utils";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import { useParams } from "react-router-dom";
import { AnnexParams } from "interfaces/routing/route-params";

interface TableOfContentsAnnexProps
    extends Pick<DataTestAttributes, "dataTestId"> {
    allCollapsed: boolean;
    annexes: AnnexTableOfContentsRecord[];
    isPublic?: boolean;
    publication: PublicationRecord;
}

const TableOfContentsAnnex: React.FunctionComponent<TableOfContentsAnnexProps> =
    (props: TableOfContentsAnnexProps) => {
        const {
            allCollapsed,
            annexes,
            dataTestId,
            isPublic = false,
            publication,
        } = props;

        const cssBaseClass = "c-table-of-contents-chapter";
        const cssPublicModifier = "-public";
        const cssClassName = isPublic
            ? StringUtils.joinClassNames([cssBaseClass, cssPublicModifier])
            : cssBaseClass;

        const { isAdminPreview } = useAdminPreview();
        const params = useParams<AnnexParams>();

        const sortedAnnexes = annexes.sort(
            (a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0)
        );

        const panelTop = useCallback(
            (annex: AnnexTableOfContentsRecord) => {
                const annexPageRoute = annex.getRoute(params, isAdminPreview);

                const annexTitle =
                    publication?.type === PublicationTypes.NFC
                        ? annex.getDisplayTitle(publication.type)
                        : publication?.getAnnexesDisplayLabel();

                return (
                    <TableOfContentsCollapsePanelHeader
                        dataTestId={dataTestId}
                        displayTitle={annexTitle}
                        isPublic={isPublic}
                        to={annexPageRoute}
                    />
                );
            },
            [dataTestId, isAdminPreview, isPublic, params, publication]
        );

        if (publication.type !== PublicationTypes.NFC) {
            const firstAnnex = CollectionUtils.hasValues(sortedAnnexes)
                ? sortedAnnexes![0]
                : null;

            if (firstAnnex == null) {
                return null;
            }

            return (
                <div className={cssClassName}>
                    <CollapsePanel
                        collapse={allCollapsed}
                        buttonAriaText={publication.getAnnexesDisplayLabel()}
                        panelTop={panelTop(firstAnnex)}>
                        <nav className="c-table-of-contents-section">
                            <ul>
                                {sortedAnnexes.map((annex) => (
                                    <TableOfContentsCollapsePanelLink
                                        key={annex.id}
                                        index={annex.id}
                                        isPublic={isPublic}
                                        to={annex.getRoute(
                                            params,
                                            isAdminPreview
                                        )}>
                                        {annex.getDisplayTitle()}
                                    </TableOfContentsCollapsePanelLink>
                                ))}
                            </ul>
                        </nav>
                    </CollapsePanel>
                </div>
            );
        }

        return (
            <Fragment>
                {sortedAnnexes.map((annex) => {
                    const hasGroup = CollectionUtils.hasValues(
                        annex.annexGroups
                    );

                    const isEmptyAndPublicModifier =
                        !hasGroup && isPublic ? "-empty" : "";
                    const className = StringUtils.joinClassNames([
                        cssClassName,
                        isEmptyAndPublicModifier,
                    ]);

                    return (
                        <div className={className} key={annex.id}>
                            <CollapsePanel
                                buttonAriaText={annex.getDisplayTitle(
                                    publication.type
                                )}
                                collapse={props.allCollapsed}
                                data-test-id="chapter-collapse-panel"
                                panelTop={panelTop(annex)}>
                                {hasGroup && (
                                    <TableOfContentsAnnexGroups
                                        annexGroups={annex.annexGroups}
                                        annexNfpaLabel={annex.nfpaLabel ?? ""}
                                        isPublic={isPublic}
                                    />
                                )}
                            </CollapsePanel>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

export default TableOfContentsAnnex;
