import React, { forwardRef, RefObject, useMemo } from "react";
import HtmlToXmlUtils from "utilities/html-to-xml-utils";
import XmlUtils from "utilities/xml-utils";
import { LinkPreviewContextProvider } from "utilities/contexts/link-preview/link-preview-context-provider";

interface RichTextAreaProps {
    content: string;
    cssClassName?: string;
    ref?: RefObject<HTMLDivElement>;
}

const RichTextArea: React.RefForwardingComponent<
    HTMLDivElement,
    RichTextAreaProps
> = forwardRef((props: RichTextAreaProps, ref: React.Ref<HTMLDivElement>) => {
    const { content: propsContent } = props;

    const CSS_BASE_CLASS = "c-rich-text-area";
    const cssClassNames = [CSS_BASE_CLASS];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    // Only run the XML serializer when the content actually changes
    const content = useMemo(
        // wrap content in a div so that all children are visited by the converter
        () => HtmlToXmlUtils.convertToXml(`<div>${propsContent}</div>`),
        [propsContent]
    );

    return (
        <LinkPreviewContextProvider>
            <div ref={ref} className={cssClassNames.join(" ")}>
                {XmlUtils.convertRichText(content)}
            </div>
        </LinkPreviewContextProvider>
    );
});

export default RichTextArea;
