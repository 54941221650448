import RoleType from "../../utilities/enumerations/role-type";
import { useGlobalState } from "../../utilities/contexts/use-global-state-context";

interface AllowRolesProps {
    children: JSX.Element;
    allowedRoles: RoleType[];
}

const AllowRoles = ({ children, allowedRoles }: AllowRolesProps) => {
    const { globalState } = useGlobalState();

    return globalState.currentIdentity?.hasCurrentRole(...allowedRoles)
        ? children
        : null;
};

export default AllowRoles;
