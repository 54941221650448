import BrightcoveMetadataRecord from "models/view-models/external/brightcove/brightcove-metadata-record";
import { useEffect, useState } from "react";
import ExternalResourceUtils from "utilities/external-resource-utils";
import { t } from "utilities/localization-utils";
import BrightcoveMetadataService from "utilities/services/external/brightcove/brightcove-metadata-service";

export default function useBrightcoveMetadata(absoluteUrl: string | undefined) {
    const [brightcoveMetadata, setBrightcoveMetadata] = useState<
        BrightcoveMetadataRecord | undefined
    >(undefined);
    const { get: getBrightcoveMetadataApi } = BrightcoveMetadataService;
    const loadBrightcoveDurationAndThumbnail = async () => {
        try {
            const result = await getBrightcoveMetadataApi({
                videoId: ExternalResourceUtils.getBrightcoveVideoId(
                    absoluteUrl!
                )!,
            });
            setBrightcoveMetadata(
                new BrightcoveMetadataRecord().with({
                    thumbnailUrl: result.resultObject?.thumbnailUrl,
                    duration:
                        ExternalResourceUtils.roundVideoDuration(
                            result.resultObject!.duration,
                            t
                        ) ?? "",
                })
            );
        } catch (error) {
            console.error(`Error in use-brightcove-metadata hook: ${error}`);
        }
    };
    useEffect(() => {
        if (
            ExternalResourceUtils.isBrightcoveLink(absoluteUrl) &&
            brightcoveMetadata === undefined
        ) {
            loadBrightcoveDurationAndThumbnail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [absoluteUrl]);

    return {
        brightcoveMetadata,
    };
}
