import TiaRecord from "models/view-models/tia-record";
import React, { ReactNode, useEffect } from "react";
import useTias from "utilities/hooks/domain/publications/use-tias";
import TiaChangeSummary from "./tia-change-summary";
import TiaChangesHeader from "./tia-changes-panel-header";
import TiaChangesPanelNavigation from "./tia-changes-panel-navigation";
import TiaEffectiveDateLine from "./tia-effective-date-line";
import { t } from "utilities/localization-utils";
import TiaType from "models/enumerations/tia-type";

const cssBaseClass: string = "c-tia-changes-panel";

interface TiaChangesPanelContentProps {
    tias: TiaRecord[];
    handleClose: () => void;
    title: ReactNode;
    body: ReactNode;
}
const TiaChangesPanelContent = (props: TiaChangesPanelContentProps) => {
    const { handleClose, tias, title, body } = props;

    const { activeTiaIndex, currentTia, setActiveTiaIndex } = useTias();

    useEffect(() => {
        return () => setActiveTiaIndex(0);
    }, [setActiveTiaIndex]);

    return (
        <>
            <TiaChangesHeader handleClose={handleClose} />
            {tias.length > 1 && (
                <TiaChangesPanelNavigation
                    activeTia={activeTiaIndex}
                    numberOfTias={tias.length}
                    onSetActiveTia={setActiveTiaIndex}
                />
            )}
            <div className={`${cssBaseClass}__contents`}>
                <TiaEffectiveDateLine
                    currentTiaIndex={activeTiaIndex}
                    tias={tias}
                />
                <div className={`${cssBaseClass}__body`}>
                    {currentTia.type === TiaType.Addition ? (
                        t("tiaPanelNewAddition")
                    ) : (
                        <>
                            {title}
                            {body}
                        </>
                    )}
                </div>
                {currentTia.changeSummary && (
                    <TiaChangeSummary
                        changeSummary={currentTia.changeSummary}
                    />
                )}
            </div>
        </>
    );
};

export default TiaChangesPanelContent;

