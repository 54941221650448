import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

/**
 * HoC to generate a breadcrumb for each {CategoryType}
 * @param type
 */
const CategoryTypeBreadcrumbFactory = (type: CategoryType) => {
    return () => (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{CategoryType[type]}</span>
        </Breadcrumb>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoryTypeBreadcrumbFactory;

// #endregion Exports
