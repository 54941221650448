import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import EntityLabelsRecord from "models/view-models/entity-labels-record";
import { siteMap } from "internal-sitemap";
import CategoryCollection from "utilities/interfaces/categories/category-collection";
import { nameof } from "ts-simple-nameof";

/**
 * Get the label for a CategoryType
 * @param type the CategoryType
 * @param lowerCase
 * @param asOptionLabel only affects CategoryType.Equipment, if true, normalizes the plural
 *                      version to "equipment types," rather than just "equipment," since
 *                      "equipment" is a weird plural word.
 */
const toLabel = (
    type: CategoryType,
    lowerCase: boolean = false,
    asOptionLabel: boolean = false
): EntityLabelsRecord => {
    let article = "a";
    let singular = "";
    let plural = "";
    switch (type) {
        case CategoryType.Equipment:
            article = "an";
            singular = "Equipment";
            plural = asOptionLabel ? "Equipment Types" : "Equipment";
            break;
        case CategoryType.Systems:
            article = "a";
            singular = "System";
            plural = "Systems";
            break;
        case CategoryType.Occupancies:
            article = "an";
            singular = "Occupancy";
            plural = "Occupancies";
            break;
        case CategoryType.Spaces:
            article = "a";
            singular = "Space";
            plural = "Spaces";
            break;
    }

    if (lowerCase) {
        article = article.toLowerCase();
        singular = singular.toLowerCase();
        plural = plural.toLowerCase();
    }

    return new EntityLabelsRecord({
        article,
        singular,
        plural,
    });
};

const toCategoryCollectionProperty = (
    type: CategoryType
): keyof CategoryCollection => {
    switch (type) {
        case CategoryType.Equipment:
            return nameof<CategoryCollection>(
                (e) => e.equipment
            ) as keyof CategoryCollection;
        case CategoryType.Occupancies:
            return nameof<CategoryCollection>(
                (e) => e.occupancies
            ) as keyof CategoryCollection;
        case CategoryType.Spaces:
            return nameof<CategoryCollection>(
                (e) => e.spaces
            ) as keyof CategoryCollection;
        case CategoryType.Systems:
            return nameof<CategoryCollection>(
                (e) => e.systems
            ) as keyof CategoryCollection;
    }
};

const getTypeForRoute = (route: string) => {
    if (
        route.includes(
            siteMap.admin.situationalNavigation.categories.spaces.dashboard
        )
    ) {
        return CategoryType.Spaces;
    }

    if (
        route.includes(
            siteMap.admin.situationalNavigation.categories.equipment.dashboard
        )
    ) {
        return CategoryType.Equipment;
    }

    if (
        route.includes(
            siteMap.admin.situationalNavigation.categories.systems.dashboard
        )
    ) {
        return CategoryType.Systems;
    }

    return CategoryType.Occupancies;
};

const getDashboardRouteForType = (type: CategoryType) => {
    switch (type) {
        case CategoryType.Equipment:
            return siteMap.admin.situationalNavigation.categories.equipment
                .dashboard;
        case CategoryType.Occupancies:
            return siteMap.admin.situationalNavigation.categories.occupancies
                .dashboard;
        case CategoryType.Systems:
            return siteMap.admin.situationalNavigation.categories.systems
                .dashboard;
        case CategoryType.Spaces:
        default:
            return siteMap.admin.situationalNavigation.categories.spaces
                .dashboard;
    }
};

const getCreateRouteForType = (type: CategoryType) => {
    switch (type) {
        case CategoryType.Equipment:
            return siteMap.admin.situationalNavigation.categories.equipment
                .create;
        case CategoryType.Occupancies:
            return siteMap.admin.situationalNavigation.categories.occupancies
                .create;
        case CategoryType.Systems:
            return siteMap.admin.situationalNavigation.categories.systems
                .create;
        case CategoryType.Spaces:
        default:
            return siteMap.admin.situationalNavigation.categories.spaces.create;
    }
};

export const CategoryUtils = {
    getCreateRouteForType,
    getDashboardRouteForType,
    getTypeForRoute,
    toLabel,
    toCategoryCollectionProperty,
};
