import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import FreeAccessSidebarNavigationTooltip from "./free-access-sidebar-navigation-tooltip";
import { siteMap } from "internal-sitemap";
import { TrackingCode } from "constants/nfpa-urls/tracking-codes";

interface FreeAccessSidebarProps {}

const CSS_BASE_CLASSNAME = "c-sidebar-navigation";
const CSS_BASE_MODIFIER = "-free-access";
const CSS_TOOLTIP_CLASSNAME = "c-tooltip-nav-item";

const FreeAccessSidebar = (props: FreeAccessSidebarProps) => {
    const { t } = useLocalization<CultureResources>();
    const signUpPlanWithIcid =
        siteMap.signup.plans + `?icid=${TrackingCode.FreeAccessSubscribe}`;
    return (
        <aside className={[CSS_BASE_CLASSNAME, CSS_BASE_MODIFIER].join(" ")}>
            <FreeAccessSidebarNavigationTooltip
                description={t("free-access-nav-tooltip-direct-description")}
                title={t("free-access-nav-tooltip-direct-title")}
                buttonText={t("subscribe-now")}
                buttonUrl={signUpPlanWithIcid}>
                <div className={CSS_TOOLTIP_CLASSNAME}>
                    <Icon type={Icons.Sitnav} size={IconSizes.Large} />
                    <span className="sr-only">{t("nav-aria-direct-link")}</span>
                </div>
            </FreeAccessSidebarNavigationTooltip>
            <FreeAccessSidebarNavigationTooltip
                description={t("free-access-nav-tooltip-mylink-description")}
                title={t("nav-tooltip-mylink-title")}
                buttonText={t("subscribe-now")}
                buttonUrl={signUpPlanWithIcid}>
                <div className={CSS_TOOLTIP_CLASSNAME}>
                    <Icon type={Icons.BookmarkOutline} size={IconSizes.Large} />
                    <span className="sr-only">{t("nav-aria-mylink-link")}</span>
                </div>
            </FreeAccessSidebarNavigationTooltip>
            <FreeAccessSidebarNavigationTooltip
                description={t("free-access-nav-tooltip-search-description")}
                title={t("free-access-nav-tooltip-search-title")}
                buttonText={t("subscribe-now")}
                buttonUrl={signUpPlanWithIcid}>
                <div className={CSS_TOOLTIP_CLASSNAME}>
                    <Icon type={Icons.Search} size={IconSizes.Large} />
                </div>
            </FreeAccessSidebarNavigationTooltip>
        </aside>
    );
};

export default FreeAccessSidebar;
