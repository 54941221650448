export enum ParagraphSizes {
    XXXXLarge = "-xxxxlarge",
    XXXLarge = "-xxxlarge",
    XXLarge = "-xxlarge",
    XLarge = "-xlarge",
    Large = "-large",
    Base = "-base",
    Small = "-small",
    XSmall = "-xsmall",
    XXSmall = "-xxsmall",
}
