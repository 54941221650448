import ArticleRecord from "models/view-models/article-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface ArticleBasePathParams {
    chapterId: number;
    publicationId: number;
}

export interface ArticleResourcePathParams extends ArticleBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Chapter resources.
 * These get translated to QueryString parameters
 */
export interface ArticleListQueryParams {
    nfpaLabel?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/chapters/:chapterId/articles";
const resourceType = ArticleRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "publication-chapter-article";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const ArticleService = {
    /**
     * Retrieve a specific Article resource
     */
    get: ServiceFactory.get<ArticleRecord, ArticleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Article resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        ArticleRecord,
        ArticleBasePathParams,
        ArticleListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<ArticleRecord, ArticleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<
        ArticleRecord,
        ArticleResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        ArticleRecord,
        ArticleBasePathParams,
        ArticleListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        ArticleRecord,
        ArticleBasePathParams,
        ArticleListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ArticleService;

// #endregion Exports
