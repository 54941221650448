import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSituationPublishPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const resourceType = SituationRecord;
const resourceEndpoint = "admin/situational-navigation/situations/:id/publish";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSituationPublishService = {
    publish: ServiceFactory.update<
        SituationRecord,
        AdminSituationPublishPathParams
    >(resourceType, resourceEndpoint),
    usePublish: ServiceHookFactory.useUpdate<
        SituationRecord,
        AdminSituationPublishPathParams
    >(resourceType, resourceEndpoint),
    unpublish:
        ServiceFactory.delete<AdminSituationPublishPathParams>(
            resourceEndpoint
        ),
    useUnpublish:
        ServiceHookFactory.useDelete<AdminSituationPublishPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSituationPublishService;

// #endregion Exports
