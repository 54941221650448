import Button from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface NoCategoriesAvailableModalProps {
    isVisible: boolean;
    onClose: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NoCategoriesAvailableModal: React.FC<NoCategoriesAvailableModalProps> = (
    props: NoCategoriesAvailableModalProps
) => {
    const baseClassName = "c-no-categories-available-modal";
    const { isVisible, onClose } = props;

    return (
        <Modal
            cssClassName={baseClassName}
            closeDialog={onClose}
            isVisible={isVisible}
            label="No Selections Available"
            transition={ModalTransitions.SlideUp}
            type={ModalTypes.Base}>
            <Heading priority={HeadingPriority.Five}>
                No Selections Available
            </Heading>
            <Paragraph>
                Try removing one or more of your existing category filters to
                select from this category.
            </Paragraph>
            <div className={`${baseClassName}__action-container`}>
                <Button onClick={onClose}>Got It. Thanks!</Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default NoCategoriesAvailableModal;

// #endregion Exports
