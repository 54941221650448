import { EnvironmentUtils } from "andculturecode-javascript-core";
import FileLink from "atoms/anchors/file-link";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import FileRecord from "models/view-models/file-record";
import ListBox, { ListBoxItemClassName } from "molecules/lists/list-box";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import FileUploadProgressBar from "molecules/progress-bars/file-upload-progress-bar";
import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { S3Response } from "react-s3-uploader";
import { CollectionUtils } from "utilities/collection-utils";
import { CoreUtils } from "utilities/core-utils";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import FileUtils from "utilities/file-utils";
import PathUtils from "utilities/path-utils";
import RemoteAccessDetailsService from "utilities/services/remote-access-details-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import uuid from "uuid";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FileUploadFieldProps {
    accept?: string | Array<string>;
    /**
     * Return false to abort the upload to S3.
     * This callback gives you an opportunity to perform
     * validation on the file before uploading it.
     * @param file
     */
    beforeUpload?: (file: File) => Promise<boolean>;
    /**
     * Customize the confirm button text in the delete confirmation modal
     * @default "Yes, Remove"
     */
    confirmButtonText?: string;
    /**
     * Customize the text in the delete confirmation modal
     * @default "Are you sure you want to remove this file?"
     */
    confirmModalText?: string;
    /**
     * Show a confirmation dialog before calling onFileChanged(undefined)
     * @default false
     */
    confirmRemove?: boolean;
    /**
     * If true, will console.log updates about the upload progress,
     * only if in the development environment.
     */
    debugS3UploadProgress?: boolean;
    /**
     * Allows you to show loading file deletion state
     * when deleted from outside this component, for example
     * when closing the solution resource modal with the
     * cancel button.
     */
    deleteLoading?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    errorMessages?: Array<string>;
    fileUploadDestination?: FileUploadDestination;
    /**
     * Text to render under the "attach file" label.
     * You can use this to display instructions such as
     * a maximum file size or maximum image dimensions.
     */
    helpText?: string;
    isErrored?: boolean;
    /**
     * Field label, defaults to "Attach File"
     */
    label?: string;
    /**
     * Max file size to accept, in Bytes.
     * You can use FileUtils#convertMbToBytes
     * if you want to specify size in MB
     */
    maxSize?: number;
    onFileChanged?: (file?: FileRecord) => void;
    onRetryUpload?: () => void;
    onUploadError?: (message: string) => void;
    onUploadStart?: () => void;
    required?: boolean;
    /**
     * Used, for example, to put the publication ID in the relative path.
     */
    uploadPathPrefix?: string;
    value?: FileRecord;
}

// #endregion Interfaces

const DefaultProps: Partial<FileUploadFieldProps> = {
    confirmButtonText: "Yes, Remove",
    confirmModalText: "Are you sure you want to remove this file?",
    confirmRemove: false,
};

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FileUploadField: React.FC<FileUploadFieldProps> = (
    props: FileUploadFieldProps
) => {
    const {
        beforeUpload,
        debugS3UploadProgress,
        fileUploadDestination,
        isErrored,
        maxSize,
        onFileChanged,
        onRetryUpload,
        onUploadError,
        onUploadStart,
        uploadPathPrefix,
    } = props;

    const CSS_BASE_CLASS = "c-file-upload-field";
    const cssClassNames = [CSS_BASE_CLASS, "c-form-field"];
    if (isErrored) {
        cssClassNames.push("-error");
    }

    const onFinishUploading = useCallback(
        async (uploadResult: S3Response, file?: FileRecord) => {
            if (!isErrored) {
                // show the success state before transitioning
                await CoreUtils.sleep(500);
            }

            setInputFile(undefined);
            setProgress(0);

            if (file == null) {
                onFileChanged?.(file);
                return;
            }

            // Presign the url with the GET verb for caching
            const remoteAccessDetailsResult =
                await RemoteAccessDetailsService.get(undefined, {
                    storageContainer: file.storageContainer,
                    relativeProviderPath: file.relativeProviderPath,
                });
            const uploadedFile = file.with({
                presignedUrl: remoteAccessDetailsResult.resultObject?.url,
                suggestedCode: file.suggestedCode,
                suggestedEdition: file.suggestedEdition,
                suggestedLocale: file.suggestedLocale,
            });

            onFileChanged?.(uploadedFile);
        },
        [onFileChanged, isErrored]
    );

    const handleUploadError = useCallback(
        (message: string) => {
            ToastManager.error(message);
            onUploadError?.(message);
        },
        [onUploadError]
    );

    const handleProgress = useCallback(
        (percent: number, status: string, file: File) => {
            if (debugS3UploadProgress === true) {
                EnvironmentUtils.runIfDevelopment(() =>
                    console.log(
                        `S3-UPLOAD:: ${file.name}: ${percent}% - ${status}`
                    )
                );
            }
            setProgress(percent);
        },
        [debugS3UploadProgress]
    );

    const handleRetry = () => inputFile != null && uploadFile(inputFile);

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null && e.target.files.length > 0) {
            uploadFile(e.target.files[0]);
        }
    };

    const handleFileRemoved = async () => {
        if (props.confirmRemove === true && !showConfirmModal) {
            setShowConfirmModal(true);
            return;
        }

        setShowConfirmModal(false);

        props.onFileChanged?.(undefined);
    };

    const [inputFile, setInputFile] = useState<File | undefined>();
    const [progress, setProgress] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleUploadStart = useCallback(
        async (file: File, next?: (file: File) => any): Promise<boolean> => {
            setInputFile(file);
            setProgress(0);
            next?.(file);

            if (beforeUpload != null) {
                const propValidateResult = await beforeUpload(file);
                if (!propValidateResult) {
                    return false;
                }
            }

            if (maxSize != null && file.size > maxSize) {
                ToastManager.error(
                    `File is too large. Max file size is ${FileUtils.formatBytes(
                        maxSize
                    )}.`
                );
                return false;
            }

            onUploadStart?.();
            return true;
        },
        [beforeUpload, maxSize, onUploadStart]
    );

    const handleUploadAbort = useCallback(() => {
        setProgress(0);
        setInputFile(undefined);
        onFileChanged?.(undefined);
    }, [onFileChanged]);

    const uploadFile = useCallback(
        (file: File) => {
            onRetryUpload?.();
            FileUtils.uploadFile(file, {
                beforeUpload: handleUploadStart,
                fileUploadDestination,
                onAbort: handleUploadAbort,
                onError: handleUploadError,
                onFinish: onFinishUploading,
                onProgress: handleProgress,
                uploadPathPrefix,
            });
        },
        [
            fileUploadDestination,
            handleProgress,
            handleUploadAbort,
            handleUploadError,
            handleUploadStart,
            onFinishUploading,
            onRetryUpload,
            uploadPathPrefix,
        ]
    );

    const handleDrop = useCallback(
        (acceptedFiles: Array<File>, rejectedFiles: Array<FileRejection>) => {
            if (CollectionUtils.hasValues(acceptedFiles)) {
                uploadFile(acceptedFiles[0]);
            }

            if (CollectionUtils.hasValues(rejectedFiles)) {
                if (rejectedFiles.length > 1) {
                    ToastManager.error(
                        "Only 1 file can be attached to a resource."
                    );
                    return;
                }

                ToastManager.error(
                    `${rejectedFiles.length} file(s) failed to upload.`
                );
            }
        },
        [uploadFile]
    );

    const getFileContainerClassNames = (): string => {
        const classNames = [
            ListBoxItemClassName,
            `${CSS_BASE_CLASS}__file-container__progress`,
        ];
        if (progress >= 100 && !props.isErrored) {
            classNames.push("-success");
        }

        if (props.isErrored) {
            classNames.push("-error");
        }

        return classNames.join(" ");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: props.accept,
        disabled: props.disabled,
        multiple: false,
        onDrop: handleDrop,
    });

    return (
        <div className={cssClassNames.join(" ")}>
            {
                // if
                props.value == null && inputFile == null && (
                    <React.Fragment>
                        <label>
                            {StringUtils.hasValue(props.label)
                                ? props.label
                                : "Attach File"}
                            {props.required === true && (
                                <span className="c-form-field__required">
                                    {" *"}
                                </span>
                            )}
                        </label>
                        {StringUtils.hasValue(props.helpText) && (
                            <label className="-help-text">
                                {props.helpText}
                            </label>
                        )}
                        <div
                            className={`${CSS_BASE_CLASS}__file-container`}
                            {...getRootProps()}>
                            <input
                                onChange={handleFileInputChange}
                                {...getInputProps()}
                            />
                            {
                                // if
                                isDragActive && <p>Drop the files here</p>
                            }
                            {
                                // if
                                !isDragActive && (
                                    <p
                                        className={`${CSS_BASE_CLASS}__file-container__help-text`}>
                                        Drag file here or{" "}
                                        <span
                                            className={`${CSS_BASE_CLASS}__browse-link`}>
                                            Browse
                                        </span>
                                    </p>
                                )
                            }
                        </div>
                    </React.Fragment>
                )
            }
            {
                // if
                (props.value != null || inputFile != null) && (
                    <React.Fragment>
                        <label>
                            {StringUtils.hasValue(props.label)
                                ? props.label
                                : "Attached File"}
                        </label>
                        <ListBox>
                            {
                                // if
                                props.value == null && inputFile != null && (
                                    <div
                                        className={getFileContainerClassNames()}>
                                        <FileUploadProgressBar
                                            cssClassName={`${CSS_BASE_CLASS}__file-container__progress__progress-bar`}
                                            isErrored={props.isErrored}
                                            onRetryClick={handleRetry}
                                            value={progress}
                                        />
                                    </div>
                                )
                            }
                            {
                                // if
                                props.value != null && inputFile == null && (
                                    <div
                                        className={`${ListBoxItemClassName} ${CSS_BASE_CLASS}__file-container__files`}>
                                        {
                                            // if
                                            props.deleteLoading && (
                                                <Loader accessibleText="Deleting file..." />
                                            )
                                        }
                                        {
                                            // if
                                            !props.deleteLoading && (
                                                <FileLink
                                                    accessibleText="Download"
                                                    cssClassName={`${CSS_BASE_CLASS}__file-container__files__filename`}
                                                    file={props.value}>
                                                    {PathUtils.relativePathToFilename(
                                                        props.value
                                                            .relativeProviderPath
                                                    )}
                                                </FileLink>
                                            )
                                        }
                                        <Button
                                            accessibleText={"Remove File"}
                                            cssClassName={`${CSS_BASE_CLASS}__file-container__files__remove-btn`}
                                            disabled={
                                                props.disabled ||
                                                props.deleteLoading
                                            }
                                            onClick={handleFileRemoved}
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.TertiaryAlt}
                                            type={ButtonTypes.Button}>
                                            <Icon type={Icons.Trashcan} />
                                        </Button>
                                    </div>
                                )
                            }
                        </ListBox>
                    </React.Fragment>
                )
            }
            {
                // if
                (CollectionUtils.hasValues(props.errorMessages) ||
                    StringUtils.hasValue(props.errorMessage)) && (
                    <div
                        className={`${CSS_BASE_CLASS}__errors-container c-form-field__bottom__errors`}>
                        {props.errorMessages?.map((s: string) => (
                            <label key={uuid.v4()}>{s}</label>
                        ))}
                        {StringUtils.hasValue(props.errorMessage) && (
                            <label>{props.errorMessage}</label>
                        )}
                    </div>
                )
            }
            <ConfirmationModal
                isVisible={showConfirmModal}
                message={props.confirmModalText}
                onCancel={() => setShowConfirmModal(false)}
                onConfirm={handleFileRemoved}
                confirmButtonText={props.confirmButtonText}
                confirmButtonStyle={ButtonStyles.Destructive}
            />
        </div>
    );
};

FileUploadField.defaultProps = DefaultProps;

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FileUploadField;

// #endregion Exports
