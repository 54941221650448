import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

interface TableHeaderProps extends XmlConvertedComponentProps {
    contents: Array<JSX.Element | undefined>;
}

const TableHeader: React.FunctionComponent<TableHeaderProps> = (props) => {
    const { contents } = props;

    return <thead>{contents}</thead>;
};

export default TableHeader;
