import { useLocation } from "react-router-dom";

/**
 * Utility hook for retrieving the current location's hash id, stripped of the octothorpe
 */
export const useLocationHash = (): string => {
    const { hash } = useLocation();

    return hash?.replace("#", "").trim() ?? "";
};
