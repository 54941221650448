import TopicRecord from "models/view-models/topic-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TopicBasePathParams {}

export interface TopicResourcePathParams extends TopicBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Topic resources
 * These get translated to QueryString parameters
 */
export interface TopicListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "topics";
const resourceType = TopicRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const TopicService = {
    /**
     * Retrieve a Topic by id
     */
    get: ServiceFactory.get<TopicRecord, TopicResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieve a a list of all Topics matching given parameters
     */
    list: ServiceFactory.list<TopicRecord, TopicListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<TopicRecord, TopicResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<TopicRecord, TopicListQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TopicService;

// #endregion Exports
