import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React, { Dispatch, SetStateAction } from "react";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import PublishStatus from "models/enumerations/publish-status";
import ChangeSummariesAdminActions from "./change-summaries-admin-actions";
import Anchor from "atoms/anchors/anchor";

interface ChangeSummariesAdminTableRowProps {
    changeSummary: ChangeSummaryRecord;
    setChangeSummaries: Dispatch<SetStateAction<ChangeSummaryRecord[]>>;
    refresh: () => void;
}

const ChangeSummariesAdminTableRow = (
    props: ChangeSummariesAdminTableRowProps
) => {
    const { changeSummary, setChangeSummaries, refresh } = props;
    const { title, code, edition, adminNote, updatedByName } = changeSummary;
    const updateChangeSummary = (changeSummary: ChangeSummaryRecord) => {
        setChangeSummaries((prev) => {
            return prev.map((x) => {
                if (x.id !== changeSummary.id) return x;
                return changeSummary;
            });
        });
    };
    const hasUnpublishedChanges =
        changeSummary.publishStatus ===
        PublishStatus.PublishedWithUnpublishedChanges;
    const publishStatus =
        changeSummary.publishStatus ?? PublishStatus.Unpublished;
    const bookviewRoute = changeSummary.getBookviewRoute();

    return (
        <tr>
            <td>
                <Anchor to={bookviewRoute}>{title}</Anchor>
            </td>
            <td>
                {code} ({edition})
            </td>
            <td>{adminNote}</td>
            <td className={`-right-col`}>
                <div>{changeSummary.getDisplayStatusAndDate()}</div>
                {updatedByName != null && <div>by {updatedByName}</div>}
            </td>
            <td className={`-status -right-col`}>
                <PublishStatusBadge
                    hasUnpublishedChanges={hasUnpublishedChanges}
                    status={publishStatus}
                />
            </td>
            <td className={"-collapse"}>
                <ChangeSummariesAdminActions
                    // changeSummaryId={id!}
                    // publishStatus={publishStatus!}
                    adminNote={changeSummary.adminNote ?? ""}
                    bookviewRoute={bookviewRoute}
                    removeChangeSummary={refresh}
                    updateChangeSummary={updateChangeSummary}
                    changeSummary={changeSummary}
                />
            </td>
        </tr>
    );
};

export default ChangeSummariesAdminTableRow;
