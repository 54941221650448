import TrainingContractRecord from "models/view-models/training-contract-record";
import ServiceFactory from "../service-factory";

interface ResourcePathParams {
    contractId: number;
}

const baseEndpoint = "training-contract";
const resourceEndpoint = `${baseEndpoint}/:contractId`;
const resourceType = TrainingContractRecord;

const TrainingContractsService = {
    get: ServiceFactory.get<TrainingContractRecord, ResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
};

export default TrainingContractsService;
