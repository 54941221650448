import { atom } from "jotai";
import VisitorIdentityMetadata from "models/interfaces/pendo/visitor";
import AccountIdentityMetadata from "models/interfaces/pendo/account";

interface PendoState {
    visitor?: VisitorIdentityMetadata;
    account?: AccountIdentityMetadata;
}

const PendoStateAtom = atom<PendoState | undefined>(undefined);

export default PendoStateAtom;
