import Anchor from "atoms/anchors/anchor";
import { useAtomValue } from "jotai/utils";
import TableOfContentsBookmarkIndicator from "organisms/table-of-contents/table-of-contents-bookmark-indicator";
import React, { useMemo } from "react";
import { useLocationHash } from "utilities/hooks/use-location-hash";
import {
    ActiveIdAtom,
    IsClickedAtom,
} from "utilities/hooks/scroll-spy/use-section-scroll-spy";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";

interface NavItemProps {
    hasBookmark: boolean;
    externalId: string;
    id: number | undefined;
    isActive?: boolean;
    label: string;
    replace?: boolean;
    route: string;
    shouldScroll: boolean;
    title: string;
    cssModifierClassName?: string;
}

const classNames = ["c-publication-sidebar-nav-link", "-single-line"];
const labelClassName = "c-publication-sidebar-nav-link__label";

const NavItem = (props: NavItemProps) => {
    const {
        externalId,
        id,
        label,
        replace,
        route,
        hasBookmark,
        title,
        cssModifierClassName,
    } = props;

    const activeExternalId = useLocationHash();
    const isClicked = useAtomValue(IsClickedAtom);
    const useExternalId = isClicked && StringUtils.hasValue(activeExternalId);

    // TODO: look into to read-only ref-style atoms in jotai
    const activeId = useAtomValue(ActiveIdAtom);

    const isActive = props.isActive
        ? props.isActive
        : useExternalId
        ? externalId === activeExternalId
        : activeId === id;

    const anchorId = id == null ? uuid.v4() : `nav-link-${id.toString()}`;

    const memoizedChildren = useMemo(
        () => (
            <>
                {StringUtils.hasValue(label) && (
                    <span className={labelClassName}>{label}</span>
                )}

                {StringUtils.hasValue(title) && (
                    <span className="c-publication-sidebar-nav-link__title">
                        {` ${title}`}
                    </span>
                )}
                <TableOfContentsBookmarkIndicator hasBookmark={hasBookmark} />
            </>
        ),
        [hasBookmark, label, title]
    );

    return (
        <Anchor
            cssClassName={`${[...classNames, cssModifierClassName].join(" ")} ${
                isActive ? "-active" : ""
            }`}
            id={anchorId}
            replace={replace}
            to={route}
            shouldScroll={false}>
            {memoizedChildren}
        </Anchor>
    );
};

export default NavItem;
