import SectionRecord from "models/view-models/section-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserReferenceBasePathParams {
    userId: number;
}

export interface UserReferenceQueryParams {
    userReferenceIds: number[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/references/sections";
const resourceType = SectionRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserReferenceSectionService = {
    key: "userreference-sections",
    useNestedBatchedList: ServiceHookFactory.useNestedBatchedList<
        SectionRecord,
        UserReferenceBasePathParams,
        UserReferenceQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserReferenceSectionService;

// #endregion Exports
