import { createContext, useContext } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface BreadcrumbsContextProps {
    inModal: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Context
// -----------------------------------------------------------------------------------------

const defaultState: BreadcrumbsContextProps = { inModal: false };
export const BreadcrumbsContext =
    createContext<BreadcrumbsContextProps>(defaultState);

// #endregion Context

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Hook to build html header data such as title, and meta tags
 */
export const useBreadcrumbsContext = () => {
    const { inModal } = useContext(BreadcrumbsContext);

    return {
        inModal,
    };
};

// #endregion Hook
