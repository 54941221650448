import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import React, { forwardRef, PropsWithChildren, Ref } from "react";
import StringUtils from "utilities/string-utils";
import { AriaRole } from "utilities/enumerations/aria-role";

export enum ButtonTypes {
    Button = "button",
    Submit = "submit",
}

export interface ButtonProps {
    accessibleText?: string;
    ariaChecked?: boolean;
    ariaControls?: string;
    ariaDisabled?: boolean;
    ariaExpanded?: boolean;
    ariaRole?: AriaRole;
    children?: any;
    cssClassName?: string;
    dataTestId?: string;
    disabled?: boolean;
    /**
     * Id of the form element this button is linked to. This is commonly used to associate a submit
     * button that is outside of the form.
     */
    form?: string;
    hidden?: boolean;
    id?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseOut?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    size?: ButtonSizes;
    style?: ButtonStyles;
    title?: string;
    /**
     * useful when you need a non-submit button inside a form
     */
    type?: ButtonTypes;
    value?: string | number | string[] | undefined;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (props: PropsWithChildren<ButtonProps>, ref: Ref<HTMLButtonElement>) => {
        const {
            accessibleText,
            ariaChecked,
            ariaControls,
            ariaDisabled,
            ariaExpanded,
            ariaRole,
            children,
            cssClassName,
            disabled,
            form,
            hidden,
            id,
            onClick,
            onMouseEnter,
            onMouseOut,
            size,
            style,
            title,
            type,
            value,
        } = props;

        const classNames = ["c-button"];

        if (style === ButtonStyles.None) {
            classNames[0] = "";
        }

        if (StringUtils.hasValue(size)) {
            classNames.push(size);
        }

        if (StringUtils.hasValue(style)) {
            classNames.push(style);
        }

        if (StringUtils.hasValue(cssClassName)) {
            classNames.push(cssClassName);
        }

        return (
            <button
                aria-checked={ariaChecked}
                aria-controls={ariaControls}
                aria-disabled={ariaDisabled}
                aria-expanded={ariaExpanded}
                aria-label={accessibleText}
                className={classNames.join(" ")}
                data-test-id={props.dataTestId}
                disabled={disabled}
                form={form}
                hidden={hidden}
                id={id}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseOut={onMouseOut}
                ref={ref}
                role={ariaRole}
                title={title}
                type={type}
                value={value}
                onKeyDown={props.onKeyDown}>
                {children}
                {accessibleText != null && (
                    // if
                    <span className="sr-only">{accessibleText}</span>
                )}
            </button>
        );
    }
);

export default Button;
