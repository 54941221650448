import CreateUserDto from "models/interfaces/create-user-dto";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";

class CreateUserDtoValidator extends ObjectValidator<CreateUserDto> {
    public validate(
        newUser: CreateUserDto
    ): ObjectValidationResult<CreateUserDto> {
        const validationResult: ObjectValidationResult<CreateUserDto> = {};

        const getPropertyIsRequiredMessage = (fieldName: string) =>
            t("propertyIsRequired", { name: fieldName });

        if (!StringUtils.hasValue(newUser.firstName)) {
            validationResult.firstName = [
                getPropertyIsRequiredMessage(t("field-firstName")),
            ];
        }

        if (!StringUtils.hasValue(newUser.lastName)) {
            validationResult.lastName = [
                getPropertyIsRequiredMessage(t("field-lastName")),
            ];
        }

        if (!StringUtils.hasValue(newUser.email)) {
            validationResult.email = [
                getPropertyIsRequiredMessage(t("field-email")),
            ];
        }

        if (
            StringUtils.hasValue(newUser.email) &&
            !StringUtils.isValidEmail(newUser.email)
        ) {
            validationResult.email = [
                t("field-invalid", { name: t("field-email") }),
            ];
        }

        if (!StringUtils.hasValue(newUser.password)) {
            validationResult.password = [
                getPropertyIsRequiredMessage(t("password")),
            ];
        }

        if (
            StringUtils.hasValue(newUser.password) &&
            !StringUtils.isValidPassword(newUser.password)
        ) {
            validationResult.password = [
                t("field-invalid", { name: t("password") }),
            ];
        }

        if (!StringUtils.hasValue(newUser.confirmPassword)) {
            validationResult.confirmPassword = [
                getPropertyIsRequiredMessage(t("password")),
            ];
        }

        if (
            StringUtils.hasValue(newUser.confirmPassword) &&
            newUser.password !== newUser.confirmPassword
        ) {
            validationResult.confirmPassword = [t("passwordMismatch")];
        }

        if (newUser.captchaComplete === false) {
            validationResult.captchaComplete = [
                t("propertyIsRequired", {
                    name: "reCAPTCHA",
                }),
            ];
        }

        return validationResult;
    }
}

export { CreateUserDtoValidator };
