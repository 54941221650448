import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import React from "react";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { PageNavigationMenuProps } from "molecules/page-navigation/page-navigation-menu";

export interface PageNavigationProps
    extends Pick<PageNavigationMenuProps, "disableAll"> {
    displayNumbers?: boolean;
    disableNext?: boolean;
    disablePrevious?: boolean;
    numberOfPages: number;
    onSelectPage?: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    currentPage: number;
    onNavigateForward: () => void;
    onNavigateBack: () => void;
}

const PageNavigation: React.FunctionComponent<PageNavigationProps> = (
    props
) => {
    const {
        disableAll = false,
        displayNumbers = true,
        disableNext,
        disablePrevious,
        numberOfPages,
        onSelectPage,
        currentPage,
        onNavigateBack,
        onNavigateForward,
    } = props;
    const buttons: JSX.Element[] = [];
    const cssBaseClassName = "c-page-navigation";

    const showPageButtons = useBreakpoint(Breakpoints.Laptop) && displayNumbers;
    const { t } = useLocalization<CultureResources>();

    let startPage = 1;
    let endPage = 10;

    const setButtonIndexes = () => {
        if (numberOfPages <= 10) {
            startPage = 1;
            endPage = numberOfPages;
            return;
        }

        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
            return;
        }

        if (currentPage + 4 >= numberOfPages) {
            startPage = numberOfPages - 9;
            endPage = numberOfPages;
            return;
        }

        startPage = currentPage - 5;
        endPage = currentPage + 4;
    };

    setButtonIndexes();
    for (let i = startPage; i <= endPage; i++) {
        buttons.push(
            <Button
                accessibleText={t("gotoResultPage", { pageNumber: i })}
                disabled={disableAll}
                style={
                    i === currentPage
                        ? ButtonStyles.Primary
                        : ButtonStyles.Tertiary
                }
                size={ButtonSizes.Small}
                onClick={onSelectPage}
                value={i}
                key={i}>
                {i}
            </Button>
        );
    }

    return (
        <div className={cssBaseClassName}>
            <Button
                accessibleText={t("previousResultsPage")}
                disabled={disablePrevious || disableAll}
                cssClassName={`${cssBaseClassName}__prev`}
                style={ButtonStyles.Tertiary}
                onClick={onNavigateBack}>
                <Icon type={Icons.ChevronLeft} size={IconSizes.Base} />
            </Button>
            {showPageButtons && (
                <div className={`${cssBaseClassName}__main`}>{buttons}</div>
            )}
            <Button
                accessibleText={t("nextResultsPage")}
                disabled={disableNext || disableAll}
                cssClassName={`${cssBaseClassName}__next`}
                style={ButtonStyles.Tertiary}
                onClick={onNavigateForward}>
                <Icon type={Icons.ChevronRight} size={IconSizes.Base} />
            </Button>
        </div>
    );
};

export default PageNavigation;
