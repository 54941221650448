import RadioButton from "atoms/forms/radio-button-input";
import { useAtom } from "jotai";
import SearchModalFilterSection from "organisms/modals/search-modal/filters/search-modal-filter-section";
import { SearchModalFiltersContainerProps } from "organisms/modals/search-modal/filters/search-modal-filters-container";
import { SearchModalResultType } from "organisms/modals/search-modal/utils/search-modal-reducer";
import React, { useEffect } from "react";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import { useLocalization } from "utilities/hooks/use-localization";
import useCurrentPublication from "utilities/contexts/use-current-publication";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalScopeFilterProps
    extends Pick<
        SearchModalFiltersContainerProps,
        | "selectedPublications"
        | "onSelectedPublicationsUpdated"
        | "searchResultType"
        | "onCodesEntityTypesUpdated"
    > {
    searchScope: SearchScope;
    onSearchScopeUpdate: (scope: SearchScope) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const RADIO_BUTTON_NAME = "search-modal-scope-filter";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum SearchScope {
    CurrentLocation,
    CurrentPublication,
    SelectPublications,
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalScopeFilter: React.FC<SearchModalScopeFilterProps> = (
    props: SearchModalScopeFilterProps
) => {
    const {
        onCodesEntityTypesUpdated,
        onSelectedPublicationsUpdated,
        searchResultType,
        selectedPublications = [],
        searchScope,
        onSearchScopeUpdate,
    } = props;
    const { publication } = useCurrentPublication();
    const [currentPublicationEntity, setCurrentPublicationEntity] = useAtom(
        CurrentPublicationEntityAtom
    );

    const skipSetCurrentPublication =
        !publication.isPersisted() ||
        searchScope !== SearchScope.CurrentPublication ||
        selectedPublications.includes(publication.id!);

    const handleCurrentLocationClick = () => {
        onSearchScopeUpdate(SearchScope.CurrentLocation);
        setCurrentPublicationEntity((value) => value?.updateSelection(true));
        onCodesEntityTypesUpdated([]);
    };

    const handleCurrentPublicationClick = () => {
        onSearchScopeUpdate(SearchScope.CurrentPublication);
        onSelectedPublicationsUpdated([publication?.id!]);
        setCurrentPublicationEntity((value) => value?.updateSelection(false));
    };

    const handleSelectPublicationsClick = () => {
        onSearchScopeUpdate(SearchScope.SelectPublications);
        onSelectedPublicationsUpdated([]);
        setCurrentPublicationEntity((value) => value?.updateSelection(false));
    };

    const isChecked = (searchScopeOption: SearchScope) =>
        searchScope === searchScopeOption;

    useEffect(() => {
        if (skipSetCurrentPublication) {
            return;
        }

        onSelectedPublicationsUpdated([publication?.id!]);
    }, [publication, onSelectedPublicationsUpdated, skipSetCurrentPublication]);

    const { t } = useLocalization();

    const currentPublicationEntityLabel = `${publication?.getDisplayCodeAndEdition()} ${
        currentPublicationEntity?.entityTitle
    }`;
    const currentPublicationLabel = t("allOfItem", {
        item: publication?.getDisplayCodeAndEdition(),
    });
    const searchScopeText = t("searchItem", { item: t("scope") });
    const selectPublicationsLabel = t("selectItem", {
        item: t("publication_plural"),
    });

    const isSelectPublicationsChecked =
        isChecked(SearchScope.SelectPublications) ||
        (publication.isPersisted() && currentPublicationEntity === null);

    return (
        <SearchModalFilterSection
            isVisible={searchResultType === SearchModalResultType.Codes}
            panelText={searchScopeText}
            panelTriggerAriaText={searchScopeText}>
            <fieldset>
                {currentPublicationEntity != null && (
                    <RadioButton
                        checked={
                            isChecked(SearchScope.CurrentLocation) &&
                            currentPublicationEntity.selected
                        }
                        label={currentPublicationEntityLabel}
                        name={RADIO_BUTTON_NAME}
                        onClick={handleCurrentLocationClick}
                    />
                )}
                {publication.isPersisted() && (
                    <RadioButton
                        checked={isChecked(SearchScope.CurrentPublication)}
                        label={currentPublicationLabel}
                        name={RADIO_BUTTON_NAME}
                        onClick={handleCurrentPublicationClick}
                    />
                )}
                <RadioButton
                    checked={isSelectPublicationsChecked}
                    label={selectPublicationsLabel}
                    name={RADIO_BUTTON_NAME}
                    onClick={handleSelectPublicationsClick}
                />
            </fieldset>
        </SearchModalFilterSection>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalScopeFilter;

// #endregion Exports
