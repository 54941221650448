import type UserRecord from "models/view-models/user-record";
import { useCallback, useEffect, useMemo, useState } from "react";
import UserService from "utilities/services/users/user-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultErrorHandler = () =>
    ToastManager.error("There was an issue loading the user.");

/**
 * Custom hook to get a user by ID.
 * Example use: showing name of user who created a team collection.
 * @param userId
 * @param onError
 * @returns {{UserRecord, boolean}}
 */
export default function useUser(userId?: number, onError?: () => void) {
    const { get: getUserApi } = UserService.useGet();

    const [user, setUser] = useState<UserRecord>();
    const [loadingUser, setLoadingUser] = useState(true);
    const [previousUserId, setPreviousUserId] = useState(0);

    const id = useMemo(() => userId, [userId]);

    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler();
    }, [onError]);

    useEffect(() => {
        if (id == null || id === previousUserId) {
            setLoadingUser(false);
            return;
        }

        setPreviousUserId(id);

        const loadUser = async () => {
            setLoadingUser(true);

            try {
                const result = await getUserApi({ id });
                setUser(result.resultObject);
            } catch (e) {
                handleError();
            }

            setLoadingUser(false);
        };

        loadUser();
    }, [id, previousUserId, handleError, getUserApi]);

    return { user, loadingUser };
}
