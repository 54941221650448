import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import PublicationBatchReprocessStatusesTableRow, {
    PublicationBatchReprocessStatusesTableRowProps,
} from "./publication-batch-reprocess-statuses-table-row";

const CSS_CLASS_NAME = "c-publication-batch-reprocess-statuses__table";
const CSS_CLASS_NAME_HEADER = `${CSS_CLASS_NAME}__header`;

export interface PublicationBatchReprocessStatusesTableProps {
    rows: PublicationBatchReprocessStatusesTableRowProps[];
}

const PublicationBatchReprocessStatusesTable: React.FC<PublicationBatchReprocessStatusesTableProps> =
    (props: PublicationBatchReprocessStatusesTableProps) => {
        const { rows } = props;

        return (
            <DataTable cssClassName={`${CSS_CLASS_NAME}`}>
                <thead className={`${CSS_CLASS_NAME_HEADER}`}>
                    <tr>
                        <th className={`${CSS_CLASS_NAME_HEADER}__publication`}>
                            Publication
                        </th>
                        <th className={`${CSS_CLASS_NAME_HEADER}__worker-name`}>
                            Worker Name
                        </th>
                        <th className={`${CSS_CLASS_NAME_HEADER}__status`}>
                            Status
                        </th>
                        <th
                            className={`${CSS_CLASS_NAME_HEADER}__created-on -right-col`}>
                            Created On
                        </th>
                        <th className={"-collapse"}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map(
                        (
                            row: PublicationBatchReprocessStatusesTableRowProps,
                            index
                        ) => (
                            <PublicationBatchReprocessStatusesTableRow
                                key={index}
                                {...row}
                            />
                        )
                    )}

                    {CollectionUtils.isEmpty(rows) && (
                        <tr>
                            <td colSpan={6} className="-no-results-row">
                                No Results
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        );
    };

export default PublicationBatchReprocessStatusesTable;
