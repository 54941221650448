import { useEffect, useState } from "react";
import PublicationRecord from "models/view-models/publication-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import PublicationService from "utilities/services/publications/publication-service";

const useFeaturedBookPublications = () => {
    const [publications, setPublications] = useState<PublicationRecord[]>([]);

    const { list } = PublicationService.useGetFeaturedPublications();

    const errorMessage = t("errors-actionResource_plural", {
        action: t("loading"),
        resource: t("featuredPublications"),
    });

    useEffect(() => {
        const loadPublications = async () => {
            try {
                const response = await list();
                if (response.resultObjects == null) {
                    throw new Error();
                }
                setPublications(response.resultObjects);
            } catch (error) {
                ToastManager.error(errorMessage);
            }
        };
        loadPublications();
    }, [list, errorMessage]);
    return { publications };
};

export default useFeaturedBookPublications;
