import { useCallback, useEffect, useState } from "react";
import { CookieUtils, SetCookieOptions } from "utilities/cookie-utils";

const cookieKey = "freeAccessTermsAndConditions";
const cookieOptions: SetCookieOptions = {
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
    path: "/",
};

export default function useTermsAndConditions(publication: string) {
    const [hasCookie, setHasCookie] = useState(false);

    const validateCookie = useCallback(() => {
        const cookie = CookieUtils.get<string[]>(cookieKey);
        const isValid = cookie?.includes(publication) ?? false;

        setHasCookie(isValid);
    }, [publication]);

    useEffect(() => {
        validateCookie();
    }, [publication, validateCookie]);

    const setCookie = () => {
        const cookie = CookieUtils.get<string[]>(cookieKey);

        if (cookie === undefined) {
            CookieUtils.set(cookieKey, [publication], cookieOptions);
        } else {
            CookieUtils.set(cookieKey, [...cookie, publication], cookieOptions);
        }

        validateCookie();
    };

    return { hasCookie, setCookie };
}
