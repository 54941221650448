import { UnicodeCharacterConstants } from "constants/unicode-character-constants";

const AppNameTm = `NFPA LiNK${UnicodeCharacterConstants.Registered}`;

const AppNameWithHtml = `NFPA LiNK<sup>${UnicodeCharacterConstants.Registered}</sup>`;

const AppName = `NFPA LiNK`;

const NfpaWithHtml = `NFPA<sup>${UnicodeCharacterConstants.Registered}</sup>`;

export { AppNameTm, AppNameWithHtml, AppName, NfpaWithHtml };
