import type FileRecord from "models/view-models/file-record";
import PublicationRecord from "models/view-models/publication-record";
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import { CollectionUtils } from "utilities/collection-utils";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { tErrorLoading } from "utilities/localization-utils";
import NumberUtils from "utilities/number-utils";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import FileService from "utilities/services/file-service";
import PublicationService from "utilities/services/publications/publication-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationServiceHookResult
    extends ServiceHookResult<PublicationRecord> {
    refresh: () => void;
    setPublication: Dispatch<SetStateAction<PublicationRecord>>;
}

export interface UsePublicationOptions {
    /**
     * Search by Primary key of publication record
     */
    publicationId?: number | string;
    /**
     * Search by Publication Code instead of ID; if ID is null, code and edition are required
     */
    code?: string;
    /**
     * Search by Publication Edition instead of ID; if ID is null, code and edition are required
     */
    edition?: string;
    /**
     * Function (wrapped in useCallback) to execute when publication fails to load
     */
    onPublicationLoadError?: () => void;
    /**
     * if true, uses AdminPublicationService to get the publication, so that we can get an
     * unpublished publication
     */
    useAdminService?: boolean;
}

// #endregion Interfaces

/**
 * Custom hook to load a publication with it's cover image
 *
 * @param options
 */
export default function usePublication(
    options: UsePublicationOptions
): PublicationServiceHookResult {
    const {
        publicationId,
        code,
        edition,
        onPublicationLoadError,
        useAdminService,
    } = options;

    const { get } = PublicationService.useGet();
    const { list } = PublicationService.useList();
    const { get: getAdmin } = AdminPublicationService.useGet();
    const { list: listAdmin } = AdminPublicationService.useList();
    const { get: getFileApi } = FileService.useGet();

    const [publication, setPublication] = useState<PublicationRecord>(
        new PublicationRecord()
    );
    const [loadingPublication, setLoadingPublication] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [refreshToken, setRefreshToken] = useState(false);
    const { pageErrors, setPageErrors, resetPageErrors, handlePageLoadError } =
        usePageErrors();

    const handlePublicationLoadedError = useCallback(() => {
        if (onPublicationLoadError != null) {
            onPublicationLoadError();
            return;
        }

        ToastManager.error(tErrorLoading("publication"));
    }, [onPublicationLoadError]);

    const refresh = useCallback(() => {
        setRefreshToken((refreshToken: boolean) => !refreshToken);
    }, [setRefreshToken]);

    useEffect(() => {
        setLoaded(false);
        resetPageErrors();

        // we need either the ID, or BOTH the code+edition;
        // if all are null/empty, we can't load the publication
        if (
            publicationId == null &&
            StringUtils.isEmpty(code) &&
            StringUtils.isEmpty(edition)
        ) {
            setLoadingPublication(false);
            return;
        }

        const loadFile = async (
            id?: string | number
        ): Promise<FileRecord | undefined> => {
            const fileId = NumberUtils.parseInt(id);
            if (fileId == null) {
                return;
            }
            try {
                const fileResult = await getFileApi({ id: fileId });
                return fileResult.resultObject;
            } catch (result) {
                ToastManager.error(tErrorLoading("resourceType-File"));
                return undefined;
            }
        };

        const loadPublication = async (
            id?: number | string,
            code?: string,
            edition?: string
        ) => {
            const publicationId = NumberUtils.parseInt(id);
            if (!NumberUtils.isDefault(publicationId)) {
                return await loadById(publicationId);
            }

            // if we didn't have the ID, we need BOTH the code and edition
            if (StringUtils.isEmpty(code) || StringUtils.isEmpty(edition)) {
                setLoadingPublication(false);
                return;
            }

            return await loadByCodeAndEdition(code!, edition!);
        };

        const loadByCodeAndEdition = async (code: string, edition: string) => {
            setLoadingPublication(true);

            try {
                const result = await (useAdminService
                    ? listAdmin({ code, edition })
                    : list({ code, edition }));
                if (CollectionUtils.isEmpty(result.resultObjects)) {
                    setLoadingPublication(false);
                    setLoaded(true);
                    return;
                }
                let publication = result.resultObjects[0];
                const coverImageFile = await loadFile(
                    publication.coverImageFileId
                );

                publication = publication.with({
                    color: publication.getColorOrDefault(),
                    coverImageFile: coverImageFile,
                });

                setPublication(publication);
            } catch (result) {
                handlePublicationLoadedError();
                setPageErrors(result);
                setLoaded(false);
            }

            setLoadingPublication(false);
            setLoaded(true);
        };

        const loadById = async (id: number) => {
            if (id == null) {
                return;
            }
            setLoadingPublication(true);

            try {
                const result = await (useAdminService
                    ? getAdmin({ id })
                    : get({ id }));
                if (result.resultObject == null) {
                    return;
                }
                let publication = result.resultObject;
                const coverImageFile = await loadFile(
                    publication.coverImageFileId
                );
                const contentSourceFile = await loadFile(
                    publication.contentSourceFileId
                );

                publication = publication.with({
                    color: publication.getColorOrDefault(),
                    coverImageFile: coverImageFile,
                    contentSourceFile: contentSourceFile,
                });

                setPublication(publication);
            } catch (result) {
                handlePublicationLoadedError();
                setPageErrors(result);
                setLoaded(false);
            }

            setLoadingPublication(false);
            setLoaded(true);
        };

        loadPublication(publicationId, code, edition);
    }, [
        code,
        edition,
        listAdmin,
        list,
        getAdmin,
        getFileApi,
        get,
        handlePageLoadError,
        handlePublicationLoadedError,
        publicationId,
        refreshToken,
        resetPageErrors,
        setPageErrors,
        useAdminService,
    ]);

    return {
        errors: pageErrors,
        loaded,
        loading: loadingPublication,
        refresh,
        resultObject: publication,
        setPublication,
    };
}
