import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import XmlUtils from "utilities/xml-utils";
import PublicationComponentType from "./enumerations/publication-component-type";
import CultureResources from "./interfaces/culture-resources";

const CaapsRecordUtils = () => {
    const doesTitleOrBodyHaveChanges = (
        title: string | undefined,
        body: string | undefined,
        xmlChangeNotationConstant: string | undefined
    ): boolean => {
        const hasChanges =
            doesTitleHaveChanges(title) ||
            doesBodyHaveChanges(body) ||
            isNew(xmlChangeNotationConstant);
        return hasChanges;
    };

    const isNew = (xmlChangeNotationConstant: string | undefined): boolean => {
        return (
            xmlChangeNotationConstant ===
            XmlChangeNotationConstants.NEW_MATERIAL
        );
    };
    const doesTitleHaveChanges = (title: string | undefined): boolean => {
        if (!title) return false;

        const titleEl = XmlUtils.toElement(title);
        const titleHasChanges =
            titleEl != null &&
            XmlUtils.hasAttributeDeep(
                titleEl,
                XmlChangeNotationConstants.CHANGED
            );
        return titleHasChanges;
    };

    const doesBodyHaveChanges = (body: string | undefined): boolean => {
        if (!body) return false;

        // TODO: Filter table elements from the body before checking for changes

        const bodyEl = XmlUtils.toElement(body);
        const bodyHasChanges =
            bodyEl != null &&
            XmlUtils.hasAttributeDeep(
                bodyEl,
                XmlChangeNotationConstants.CHANGED
            );
        return bodyHasChanges;
    };

    const getCaapsTypeString = (
        publicationComponentType: PublicationComponentType
    ): keyof CultureResources => {
        switch (publicationComponentType) {
            case PublicationComponentType.Chapter:
                return "chapter";
            case PublicationComponentType.Annex:
                return "annex";
            case PublicationComponentType.Article:
                return "article";
            case PublicationComponentType.Part:
                return "part";
            default:
                return "section";
        }
    };

    return { doesTitleOrBodyHaveChanges, getCaapsTypeString };
};

export default CaapsRecordUtils;
