import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface OriginsSectionConverterProps
    extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OriginsSectionConverter: React.FC<OriginsSectionConverterProps> = (
    props: OriginsSectionConverterProps
) => {
    const CSS_CLASS_NAME = "c-origins-section";

    // There are times where props.children is undefined
    if (props.children == null) {
        return null;
    }

    return <div className={CSS_CLASS_NAME}>{props.children}</div>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default OriginsSectionConverter;

// #endregion Exports
