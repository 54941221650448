import { ResultRecord } from "andculturecode-javascript-core";
import { CollectionUtils } from "utilities/collection-utils";
import { useCallback, useState } from "react";

/**
 * NOTE: This implementation was previously ported to `andculturecode-javascript-react` but has since
 * diverged with extra safe-guarding.
 *
 * @see https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/67
 *
 * Hook to bundle common page error handling functionality
 */
export default function usePageErrors() {
    const [pageErrors, setPageErrors] = useState<Array<string>>([]);

    const handlePageLoadError = useCallback((result: any) => {
        if (result == null) {
            return;
        }

        if (result instanceof ResultRecord) {
            setPageErrors((errors: string[]) => [
                ...errors,
                ...result.listErrorMessages(),
            ]);
            return;
        }

        if (typeof result === "string") {
            setPageErrors((errors: string[]) => [...errors, result]);
            return;
        }

        setPageErrors((errors: string[]) => [...errors, result.toString()]);
    }, []);

    const resetPageErrors = useCallback(() => {
        setPageErrors((prevState) =>
            CollectionUtils.hasValues(prevState) ? [] : prevState
        );
    }, []);

    return {
        handlePageLoadError,
        pageErrors,
        resetPageErrors,
        setPageErrors,
    };
}
