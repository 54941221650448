import BookRecord from "models/view-models/book-record";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import OfflineDeviceRecord from "models/view-models/offline/offline-device-record";
import { useEffect, useState } from "react";
import useCheckbox from "utilities/hooks/use-checkbox";
import NumberUtils from "utilities/number-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineSettingsHookResult {
    resultObject: OfflineBookRecord;
    handleToggleEnhancedContent: () => void;
    handleToggleBookmarks: () => void;
    handleToggleStoreForOffline: () => void;
}

export interface OfflineSettingsHookOptions {
    currentBook?: BookRecord;
    currentDevice?: OfflineDeviceRecord;
    offlineBook?: OfflineBookRecord;
}

// #endregion Interfaces

export default function useOfflineSettings(
    options: OfflineSettingsHookOptions
) {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { currentBook, offlineBook, currentDevice } = options;

    const [settings, setSettings] = useState<OfflineBookRecord>(
        new OfflineBookRecord({
            bookId: currentBook?.id!,
            includeEnhancedContent: false,
            includeBookmarks: false,
            includeExternalReferences: false,
            offlineDeviceId: currentDevice?.id ?? 0,
        })
    );

    const { checked: storeOfflineChecked, handleToggle } = useCheckbox(
        !NumberUtils.isDefault(settings.id)
    );

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(
        function initialize() {
            if (offlineBook != null) {
                setSettings(offlineBook);
                return;
            }

            setSettings((prevState) =>
                prevState.with({
                    offlineDevice: currentDevice,
                    offlineDeviceId: currentDevice?.id,
                    book: currentBook,
                    bookId: currentBook?.id,
                })
            );
        },
        [currentDevice, offlineBook, currentBook]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleToggleEnhancedContent = () =>
        setSettings((prevState) =>
            prevState.with({
                includeEnhancedContent: !prevState.includeEnhancedContent,
            })
        );
    const handleToggleBookmarks = () =>
        setSettings((prevState) =>
            prevState.with({
                includeBookmarks: !prevState.includeBookmarks,
            })
        );

    const handleToggleStoreForOffline = () => {
        handleToggle();
        if (storeOfflineChecked) {
            return;
        }
        setSettings((prevState) =>
            prevState.with({
                includeEnhancedContent: false,
                includeBookmarks: false,
            })
        );
    };

    // #endregion Event Handlers

    return {
        handleToggleStoreForOffline,
        handleToggleBookmarks,
        handleToggleEnhancedContent,
        storeOfflineChecked,
        resultObject: settings,
    };
}
