import React from "react";
import * as Reach from "@reach/tabs";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TabProps extends Reach.TabProps {
    cssClassName?: string;
    isSelected?: boolean;
}

// #endregion Interfaces

/**
 * Extension of the ReachUI Tab component
 *
 * https://reacttraining.com/reach-ui/tabs
 *
 * @param props
 */
const Tab: React.FunctionComponent<TabProps> = (props: TabProps) => {
    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------

    const { isSelected, cssClassName } = props;
    const CSS_BASE_CLASS = "c-tabs__tab";
    const classNames = [CSS_BASE_CLASS];

    // #endregion Constants

    if (cssClassName != null) {
        classNames.push(cssClassName);
    }
    if (isSelected) {
        classNames.push("-active");
    }

    return (
        <Reach.Tab className={classNames.join(" ")} {...props}>
            {props.children}
            <div className={"-active-border"} />
        </Reach.Tab>
    );
};

export default Tab;
