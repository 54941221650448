import { Do } from "andculturecode-javascript-core";
import MultiSelect, { MultiSelectProps } from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import { InputFormFieldProps } from "molecules/form-fields/input-form-field";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ActionMeta } from "react-select";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TypeaheadProps<TResourceType>
    extends Pick<MultiSelectProps, "menuPosition">,
        Pick<
            InputFormFieldProps,
            "errorMessage" | "errorMessages" | "isValid" | "label" | "disabled"
        > {
    getOptionText: (resource: TResourceType) => string;
    onChange: (value?: TResourceType) => void;
    placeholder?: string;
    required?: boolean;
    search: (searchText: string) => Promise<Array<TResourceType>>;
    value?: TResourceType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-typeahead";
const DEBOUNCE_DELAY = 1250;
const MIN_SEARCH_CHARS = 2;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Typeahead = <TResourceType extends any>(
    props: TypeaheadProps<TResourceType>
) => {
    const { search: searchCallback, getOptionText, value } = props;
    const [options, setOptions] = useState<Array<SelectOption<TResourceType>>>(
        []
    );
    const [searchText, setSearchText] = useState("");
    // manually debouncing so we can wait for keystrokes on the input to stop
    const [debouncedSearchText, setDebouncedSearchText] = useState("");
    const timeoutRef = useRef<number>();
    const [loading, setLoading] = useState(false);

    const multiSelectValue = useMemo(() => {
        if (value == null) {
            return [];
        }

        return [getOptionText(value)];
    }, [value, getOptionText]);

    const search = useCallback(
        async (searchText: string) => searchCallback(searchText),
        [searchCallback]
    );

    /**
     * Whenever searchText changes, reset the
     * timer which will set debouncedSearchText.
     * This has the effect of waiting until
     * searchText stops changing for DEBOUNCE_DELAY
     * milliseconds before ever updating debouncedSearchText.
     * Essentially, wait until the user stops typing to trigger
     * the search.
     */
    useEffect(
        function resetTimeoutOnSearchTextChange() {
            if (timeoutRef.current != null) {
                window.clearTimeout(timeoutRef.current);
            }

            if (
                StringUtils.isEmpty(searchText) ||
                (searchText ?? "").length < MIN_SEARCH_CHARS
            ) {
                setDebouncedSearchText("");
                return;
            }

            timeoutRef.current = window.setTimeout(
                () => setDebouncedSearchText(searchText),
                DEBOUNCE_DELAY
            );

            return () => {
                if (timeoutRef.current != null) {
                    window.clearTimeout(timeoutRef.current);
                }
            };
        },
        [searchText]
    );

    useEffect(() => {
        if (debouncedSearchText.length < MIN_SEARCH_CHARS) {
            setOptions([]);
            setLoading(false);
            return;
        }

        Do.try(async () => {
            setLoading(true);
            const result = await search(debouncedSearchText);
            setOptions(
                result?.map((resource: TResourceType) => ({
                    label: getOptionText(resource),
                    value: getOptionText(resource),
                    data: resource,
                })) ?? []
            );
        })
            .catch(() => {
                ToastManager.error("There was an issue loading options.");
                setOptions([]);
            })
            .finally(() => setLoading(false));
    }, [debouncedSearchText, getOptionText, search]);

    const handleSelected = (
        newValue: SelectOption<TResourceType>,
        actionMeta?: ActionMeta
    ) => {
        if (
            actionMeta?.action === "remove-value" ||
            actionMeta?.action === "deselect-option" ||
            actionMeta?.action === "clear"
        ) {
            props.onChange();
            return;
        }

        props.onChange(newValue.data!);
    };

    const buildOptionFromValue = (resource: TResourceType) => [
        {
            label: getOptionText(resource),
            value: getOptionText(resource),
            data: resource,
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <label>
                {props.label}
                {props.required === true && (
                    <span className={"c-form-field__required"}>{" *"}</span>
                )}
            </label>
            <MultiSelect<TResourceType>
                errorMessage={props.errorMessage}
                errorMessages={props.errorMessages}
                disabled={props.disabled}
                hideNoOptionsMessage={
                    loading || debouncedSearchText.length < MIN_SEARCH_CHARS
                }
                isMulti={false}
                isValid={props.isValid}
                loading={loading}
                loadingMessage={"Searching..."}
                menuPosition={props.menuPosition}
                onChange={handleSelected}
                onSearchChange={setSearchText}
                options={value == null ? options : buildOptionFromValue(value)}
                optionFilter={() => true}
                placeholder={props.placeholder}
                value={multiSelectValue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default Typeahead;

// #endregion Exports
