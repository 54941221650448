import React, { useMemo } from "react";

/**
 * Remove react children that consist of only whitespace or line terminator characters
 * @param children
 */
export default function useRemoveWhitespaceChildren(children: React.ReactNode) {
    return useMemo(
        () =>
            React.Children.toArray(children ?? []).filter(
                (e: React.ReactNode) => e?.toString()?.trim() !== ""
            ),
        [children]
    );
}
