import { Redirect, useParams } from "react-router";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useLoading from "utilities/hooks/use-loading";
import React from "react";
import useErrors from "utilities/hooks/use-errors";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { siteMap } from "internal-sitemap";
import useChapterTables from "utilities/hooks/domain/publications/chapters/use-chapter-tables";
import TablesList from "./tables-list";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useCurrentPublication from "utilities/contexts/use-current-publication";

interface ChapterTablesContentParams {
    chapterId: string;
    chapterNfpaLabel: string;
}

interface ChapterTablesContentProps {}

const CSS_CLASS_NAME = "c-chapter-tables-content";

const ChapterTablesContent: React.FunctionComponent<ChapterTablesContentProps> =
    (props: ChapterTablesContentProps) => {
        const { extractTablesFromPublicationImport } = useFeatureFlags();
        const { publication } = useCurrentPublication();
        const { chapterId, chapterNfpaLabel } =
            useParams<ChapterTablesContentParams>();
        const { isAdminPreview } = useAdminPreview();
        const { showDiffPanel } = useChangeIndicatorDiffPanel();
        const { isTiaPanelOpen } = useTias();
        const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
        const {
            errors: chapterErrors,
            loading: chapterLoading,
            loaded: chapterLoaded,
            resultObject: chapter,
        } = useChapter({
            chapterId,
            nfpaLabel: chapterNfpaLabel,
            publicationId: publication?.id,
        });
        const {
            errors: tablesErrors,
            loaded: tablesLoaded,
            loading: tablesLoading,
            resultObject: tables,
        } = useChapterTables(isAdminPreview, chapter.id);
        const errors = useErrors(chapterErrors, tablesErrors);
        const loading = useLoading(chapterLoading, tablesLoading);
        useErrorBoundary(errors);

        const loaded = chapterLoaded && tablesLoaded;
        const title = chapter.getDisplayTitle();
        const showChanges = (showDiffPanel || isTiaPanelOpen) && isDesktop;

        if (!extractTablesFromPublicationImport) {
            return <Redirect to={siteMap.dashboards.user} />;
        }
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}>
                <div
                    className={`${CSS_CLASS_NAME}`}
                    style={{
                        width: showChanges ? "50%" : "100%",
                        display: "flex",
                        justifyContent: showChanges ? "end" : "center",
                    }}>
                    <div
                        className={`${CSS_CLASS_NAME}__container`}
                        style={
                            showChanges
                                ? {
                                      maxWidth: "730px",
                                      width: "100%",
                                  }
                                : {}
                        }>
                        <TablesList
                            errors={errors}
                            loaded={loaded}
                            loading={loading}
                            publicationType={publication?.type!}
                            tables={tables}
                            title={title}
                        />
                    </div>
                </div>
                {showChanges && (
                    <div
                        style={{
                            width: "50%",
                            height: "100%",
                        }}></div>
                )}
            </div>
        );
    };

export default ChapterTablesContent;
