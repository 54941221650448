import { Record } from "immutable";
import VisitorIdentityMetadata from "models/interfaces/pendo/visitor";

const defaultVisitorIdentityMetadata: VisitorIdentityMetadata = {
    id: "",
    email: "",
    full_name: "",
    role: "",
    language: "",
    environment: "",
    teamName: "",
    subscriptionPin: "",
    userCreatedOnDate: "",
    isAdminOfCurrentTeam: "",
};

class VisitorRecord
    extends Record(defaultVisitorIdentityMetadata)
    implements VisitorIdentityMetadata
{
    constructor(params?: VisitorIdentityMetadata) {
        if (params == null) {
            params = Object.assign({}, defaultVisitorIdentityMetadata);
        }
        super(params);
    }
}

export default VisitorRecord;
