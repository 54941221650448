import { Record } from "immutable";
import type Role from "models/interfaces/role";
import RoleType from "utilities/enumerations/role-type";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: Role = RecordUtils.auditableDefaultValuesFactory<Role>({
    description: undefined,
    name: undefined,
    rolePriority: undefined,
    roleType: undefined,
});

export default class RoleRecord extends Record(defaultValues) implements Role {
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Role) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns the role name if it has a value.
     *
     * @default ""
     */
    public getName(): string {
        if (!this.hasName()) {
            return "";
        }

        return this.name!;
    }

    public getSubscriptionLabel(): string {
        if (this.is(RoleType.FREE_TRIAL)) {
            return t("subscriptionType-Free Trial");
        }

        if (this.is(RoleType.INDIVIDUAL)) {
            return t("subscriptionType-Individual");
        }

        if (this.is(RoleType.TEAM) || this.is(RoleType.ENTERPRISE)) {
            return t("subscriptionType-Team");
        }
        return this.getName();
    }

    /**
     * Returns whether or not this RoleRecord has a non-null, non-whitespace name
     */
    public hasName(): boolean {
        return StringUtils.hasValue(this.name);
    }

    /**
     * Returns whether or not this RoleRecord has a non-null type
     */
    public hasType(): boolean {
        return this.roleType != null;
    }

    /**
     * Returns whether or not this RoleRecord corresponds to the given RoleType
     *
     * @param {RoleType} type
     */
    public is(type: RoleType): boolean {
        return this.hasType() && this.roleType === type;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Role>} values
     */
    public with(values: Partial<Role>): RoleRecord {
        return new RoleRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
