import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Loader from "atoms/loaders/loader";
import PublicationRecord from "models/view-models/publication-record";
import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import ButtonGroup from "organisms/button-groups/button-group";
import TableOfContentsAnnex from "organisms/table-of-contents/table-of-contents-annex";
import React, { useState } from "react";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import CultureResources from "utilities/interfaces/culture-resources";
import TableOfContentsChapter from "./table-of-contents-chapter";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import TableOfContentsCollapsePanelHeader from "organisms/table-of-contents/table-of-contents-collapse-panel-header";
import StringUtils from "utilities/string-utils";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TableOfContentsProps {
    disableExpandOrCollapseAll?: boolean;
    isPublic?: boolean;
    publication: PublicationRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableOfContents: React.FunctionComponent<TableOfContentsProps> = (
    props: TableOfContentsProps
) => {
    const {
        disableExpandOrCollapseAll = false,
        isPublic = false,
        publication,
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------

    const cssBaseClass = "c-table-of-contents";
    const cssChapterBaseClass = `${cssBaseClass}-chapter`;
    const cssPublicModifier = "-public";
    const cssChapterClassName = isPublic
        ? StringUtils.joinClassNames([cssChapterBaseClass, cssPublicModifier])
        : cssChapterBaseClass;

    // #endregion Constants

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { pageErrors } = usePageErrors();
    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization<CultureResources>();

    const { loading, tableOfContents } = useBookviewTableOfContents(isPublic);
    const { annexes, chapters, publicationMetadata } = tableOfContents;

    const metadataWithPublication = publicationMetadata?.map((p) =>
        p.with({ publication })
    );

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [allCollapsed, setAllCollapsed] = useState(true);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useErrorBoundary(pageErrors);

    // #endregion Side-Effects

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------
    if (publication == null) return null;

    if (loading) {
        return (
            <div className={cssBaseClass}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={cssBaseClass}>
            {!disableExpandOrCollapseAll && (
                <div className={`${cssBaseClass}__collapseButtons`}>
                    <ButtonGroup>
                        <Button
                            onClick={() => setAllCollapsed(false)}
                            size={ButtonSizes.Small}
                            style={ButtonStyles.Secondary}>
                            {t("expandAll")}
                        </Button>
                        <Button
                            onClick={() => setAllCollapsed(true)}
                            size={ButtonSizes.Small}
                            style={ButtonStyles.Secondary}>
                            {t("collapseAll")}
                        </Button>
                    </ButtonGroup>
                </div>
            )}

            {metadataWithPublication?.map((metadata) => (
                <div
                    className={`${cssChapterClassName} -empty`}
                    key={metadata.id}>
                    <CollapsePanel
                        collapse={allCollapsed}
                        buttonAriaText={metadata.getTypeDisplayName()}
                        panelTop={
                            <TableOfContentsCollapsePanelHeader
                                dataTestId={`${metadata.type}-link`}
                                displayTitle={metadata.getTypeDisplayName()}
                                isPublic={isPublic}
                                to={metadata.getRoute(isAdminPreview)}
                            />
                        }
                    />
                </div>
            ))}

            {chapters?.map((chapter) => (
                <TableOfContentsChapter
                    allCollapsed={allCollapsed}
                    chapter={chapter}
                    dataTestId="chapter-link"
                    isPublic={isPublic}
                    key={chapter.id}
                    publicationId={publication.id ?? 0}
                />
            ))}

            <TableOfContentsAnnex
                allCollapsed={allCollapsed}
                annexes={annexes ?? []}
                dataTestId="annex-link"
                isPublic={isPublic}
                publication={publication}
            />
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TableOfContents;

// #endregion Exports
