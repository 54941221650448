import { Record } from "immutable";
import SolutionRelationship from "models/interfaces/situational-navigation/solutions/solution-relationship";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SolutionRelationship =
    RecordUtils.auditableDefaultValuesFactory<SolutionRelationship>({
        deletedDraftOn: undefined,
        deletedDraftById: undefined,
        publishedOn: undefined,
        publishedById: undefined,
        solution1Id: 0,
        solution2Id: 0,
    });

export default class SolutionRelationshipRecord
    extends Record(defaultValues)
    implements SolutionRelationship
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SolutionRelationship) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SolutionRelationship>} values
     */
    public with(
        values: Partial<SolutionRelationship>
    ): SolutionRelationshipRecord {
        return new SolutionRelationshipRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
