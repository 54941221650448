import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import BookRecord from "models/view-models/book-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BookQueryParams {
    bookIds?: number[];
    code?: string;
    edition?: string;
    isFeatured?: boolean;
}

export interface BookPathParams {
    id?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "books";
const key = "books";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = BookRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const BookService = {
    key,

    useGet: ServiceHookFactory.useGet<
        BookRecord,
        BookPathParams,
        BookQueryParams
    >(resourceType, resourceEndpoint),

    list: ServiceFactory.list<BookRecord, BookQueryParams>(
        resourceType,
        baseEndpoint
    ),
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useList<BookRecord, BookQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useUpdate: ServiceHookFactory.useUpdate<BookRecord, BookPathParams>(
        resourceType,
        resourceEndpoint
    ),

    useListQuery: QueryHookFactory.useListQuery<BookRecord, BookQueryParams>(
        key,
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookService;

// #endregion Exports
