import * as React from "react";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";

export interface CommitteesMemberInfoProps {
    children?: JSX.Element[];
}

const CommitteesMemberInfo = (props: CommitteesMemberInfoProps) => (
    <Paragraph
        size={ParagraphSizes.Small}
        cssClassName="c-committee-member__additionalInfo">
        {props.children}
    </Paragraph>
);

export default CommitteesMemberInfo;
