import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import OfflineBookSyncStatusRecord from "models/view-models/offline/offline-book-sync-status-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineBookSyncStatusBasePathParams {
    offlineBookId: number;
    offlineDeviceId: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint =
    "offlinedevices/:offlineDeviceId/books/:offlineBookId/syncstatus";
const resourceType = OfflineBookSyncStatusRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const OfflineBookSyncStatusService = {
    useGet: ServiceHookFactory.useGet<
        OfflineBookSyncStatusRecord,
        OfflineBookSyncStatusBasePathParams
    >(resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        OfflineBookSyncStatusRecord,
        OfflineBookSyncStatusBasePathParams
    >(baseEndpoint, resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookSyncStatusService;

// #endregion Exports
