import * as React from "react";
import InputFormField from "molecules/form-fields/input-form-field";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import CreateUserDto from "models/interfaces/create-user-dto";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import SignupContextRecord from "models/view-models/signup-context-record";
import PasswordFormField from "molecules/form-fields/password-form-field";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";

export interface TrainingContractNewUserFormProps {
    signupContext: SignupContextRecord;
    handleFormFieldChange: (key: keyof CreateUserDto, value: any) => void;
    handleEmailFormFieldBlur: () => Promise<void>;
    getEmailFormFieldErrors: () => string;
    errorResult: ObjectValidationResult<CreateUserDto>;
}

const BASE_CLASS = "c-new-user-form";

const TrainingContractNewUserForm: React.FunctionComponent<TrainingContractNewUserFormProps> =
    (props: TrainingContractNewUserFormProps) => {
        const { t } = useLocalization<CultureResources>();

        const firstNameLabel = t("field-firstName");
        const lastNameLabel = t("field-lastName");
        const emailLabel = t("yourItem", { item: t("field-email") });
        const passwordLabel = t("password");
        const firstNamePlaceholder = `${StringUtils.capitalize(
            t("enterAField_possessive", { fieldName: firstNameLabel })
        )}...`;
        const lastNamePlaceholder = `${StringUtils.capitalize(
            t("enterAField_possessive", { fieldName: lastNameLabel })
        )}...`;
        const emailAddressPlaceholder = `${StringUtils.capitalize(
            t("enterAField_possessive", { fieldName: t("field-email") })
        )}...`;
        const passwordPlaceholder = `${StringUtils.capitalize(
            t("enterAField", { fieldName: passwordLabel })
        )}...`;
        const confirmPasswordLabel = StringUtils.capitalize(
            t("confirmItem", { item: passwordLabel })
        );
        const confirmPasswordPlaceholder = `${StringUtils.capitalize(
            t("confirmItem", { item: passwordLabel })
        )}...`;

        return (
            <div>
                <InputFormField
                    errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                        "firstName",
                        props.errorResult
                    )}
                    inputTestId="firstName"
                    isValid={
                        !ObjectValidator.hasErrorsFor<CreateUserDto>(
                            "firstName",
                            props.errorResult
                        )
                    }
                    label={firstNameLabel}
                    maxLength={100}
                    placeholder={firstNamePlaceholder}
                    onChange={(e) =>
                        props.handleFormFieldChange("firstName", e.target.value)
                    }
                    required={true}
                    showCharacterCount={false}
                    value={props.signupContext.newUser?.firstName}
                />
                <InputFormField
                    errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                        "lastName",
                        props.errorResult
                    )}
                    inputTestId="lastName"
                    isValid={
                        !ObjectValidator.hasErrorsFor<CreateUserDto>(
                            "lastName",
                            props.errorResult
                        )
                    }
                    label={lastNameLabel}
                    maxLength={100}
                    onChange={(e) =>
                        props.handleFormFieldChange("lastName", e.target.value)
                    }
                    placeholder={lastNamePlaceholder}
                    required={true}
                    showCharacterCount={false}
                    value={props.signupContext.newUser?.lastName}
                />
                <InputFormField
                    errorMessage={props.getEmailFormFieldErrors()}
                    inputTestId="email"
                    isValid={
                        !ObjectValidator.hasErrorsFor<CreateUserDto>(
                            "email",
                            props.errorResult
                        )
                    }
                    label={emailLabel}
                    maxLength={100}
                    onBlur={props.handleEmailFormFieldBlur}
                    onChange={(e) =>
                        props.handleFormFieldChange("email", e.target.value)
                    }
                    placeholder={emailAddressPlaceholder}
                    required={true}
                    showCharacterCount={false}
                    value={props.signupContext.newUser?.email}
                />
                <div className={`${BASE_CLASS}__password`}>
                    <PasswordFormField
                        errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                            "password",
                            props.errorResult
                        )}
                        inputTestId="password"
                        isValid={
                            !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                "password",
                                props.errorResult
                            )
                        }
                        label={passwordLabel}
                        onChange={(e) =>
                            props.handleFormFieldChange(
                                "password",
                                e.target.value
                            )
                        }
                        placeholder={passwordPlaceholder}
                        required={true}
                        value={props.signupContext.newUser?.password}
                    />
                    <Paragraph size={ParagraphSizes.XSmall}>
                        {t("passwordRequirements")}
                    </Paragraph>
                </div>
                <PasswordFormField
                    errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                        "confirmPassword",
                        props.errorResult
                    )}
                    inputTestId="confirmPassword"
                    isValid={
                        !ObjectValidator.hasErrorsFor<CreateUserDto>(
                            "confirmPassword",
                            props.errorResult
                        )
                    }
                    label={confirmPasswordLabel}
                    onChange={(e) =>
                        props.handleFormFieldChange(
                            "confirmPassword",
                            e.target.value
                        )
                    }
                    placeholder={confirmPasswordPlaceholder}
                    required={true}
                    value={props.signupContext.newUser?.confirmPassword}
                />
            </div>
        );
    };

export default TrainingContractNewUserForm;
