import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import LocalizationUtils from "utilities/localization-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useGlobalState } from "../../utilities/contexts/use-global-state-context";
import useAzure from "../../pages/azure-login/use-azure";
import { useLocalization } from "utilities/hooks/use-localization";

const FrozenSubscriptionsAlert: React.FC<any> = (props: any) => {
    const history = useHistory();
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { isAuthenticated, globalState } = useGlobalState();
    const { login } = useAzure();
    const { t } = useLocalization();

    const currentCultureCode =
        LocalizationUtils.currentCultureCode() ?? "en-US";

    const subscriptionsOfflineConfigurableAlert =
        globalState.systemSettings?.configurableAlertMessages
            ?.subscriptionsOffline;

    const alertMessageContents =
        currentCultureCode === "en-US"
            ? subscriptionsOfflineConfigurableAlert?.messageContents.enUs
            : subscriptionsOfflineConfigurableAlert?.messageContents.esEs;

    const buttonText = useMemo(() => {
        return isAuthenticated
            ? t("backToDashboard")
            : useAzureB2CForSSO
            ? t("logIn")
            : t("signIn");
    }, [t, isAuthenticated, useAzureB2CForSSO]);

    const onClick = useCallback(() => {
        isAuthenticated
            ? history.push(siteMap.dashboards.user)
            : useAzureB2CForSSO
            ? login()
            : history.push(siteMap.userlogins.new);
    }, [history, isAuthenticated, login, useAzureB2CForSSO]);

    return (
        <div className="c-frozen-subscriptions-page">
            <AlertNotice
                buttonText={buttonText}
                description={alertMessageContents?.message}
                level={AlertLevels.Warning}
                onClick={onClick}
                title={alertMessageContents?.title}
            />
        </div>
    );
};

export default FrozenSubscriptionsAlert;
