import TrainingContractUserRoleRecord from "models/view-models/training-contract-user-role-record";
import ServiceFactory from "../service-factory";

interface UserPathParams {
    id: number;
    contractId: number;
}

interface UserPathQueryParams {
    ignoreQueryFilter?: boolean;
}

const baseEndpoint = "training-contract-user-roles";
const userEndpoint = `${baseEndpoint}/users/:id/:contractId`;
const resourceType = TrainingContractUserRoleRecord;

const TrainingContractUserRolesService = {
    get: ServiceFactory.get<
        TrainingContractUserRoleRecord,
        UserPathParams,
        UserPathQueryParams
    >(resourceType, userEndpoint),
    create: ServiceFactory.create<TrainingContractUserRoleRecord>(
        resourceType,
        baseEndpoint
    ),
};

export default TrainingContractUserRolesService;
