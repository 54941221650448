import { Record } from "immutable";
import AccountIdentityMetadata from "models/interfaces/pendo/account";

const defaultAccountIdentityMetadata: AccountIdentityMetadata = {
    id: "",
};

class AccountRecord
    extends Record(defaultAccountIdentityMetadata)
    implements AccountIdentityMetadata
{
    constructor(params?: AccountIdentityMetadata) {
        if (params == null) {
            params = Object.assign({}, defaultAccountIdentityMetadata);
        }
        super(params);
    }
}

export default AccountRecord;
