import { List } from "immutable";
/* eslint-disable-next-line no-restricted-imports */
import { CollectionUtils as AndcultureCodeCollectionUtils } from "andculturecode-javascript-core";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Loop through objects with a displaySequence property
 * and ensure there are no gaps, i.e. each item is consecutive,
 * and sorts the array by increasing displaySequence.
 * setDisplaySequence should be something like
 * (r: MyRecord, displaySequence: number) => r.with({ displaySequence })
 * @param array
 * @param setDisplaySequence
 * @param getDisplaySequence typically this isn't needed. it's here to support DisplaySequenceDraft.
 * @private
 */
const _collapseDisplaySequencesAndSort = <
    T extends { displaySequence: number }
>(
    array: Array<T>,
    setDisplaySequence: (t: T, displaySequence: number) => T,
    getDisplaySequence: (t: T) => number = (t: T) => t.displaySequence
): Array<T> => {
    const sorted = array.sort(
        (a: T, b: T) => getDisplaySequence(a) - getDisplaySequence(b)
    );

    return _collapseDisplaySequences(sorted, setDisplaySequence);
};

/**
 * Loop through objects with a displaySequence property
 * and ensure there are no gaps, i.e. each item is consecutive.
 * setDisplaySequence should be something like
 * (r: MyRecord, displaySequence: number) => r.with({ displaySequence })
 * @param array
 * @param setDisplaySequence
 */
const _collapseDisplaySequences = <T extends { displaySequence: number }>(
    array: Array<T>,
    setDisplaySequence: (t: T, displaySequence: number) => T
): Array<T> => {
    const newArray = [...array];
    for (let i = 0; i < newArray.length; i++) {
        newArray[i] = setDisplaySequence(newArray[i], i);
    }
    return newArray;
};

/**
 * Similar to CollectionUtils.distinctBy, but returns an Array containing ONLY the duplicate values,
 * rather than returning the source array with duplicates removed.
 * @param source
 * @param selector
 */
const _findDuplicates = <T, V>(source: Array<T>, selector: (value: T) => V) => {
    const duplicates: Array<T> = [];

    for (let i = 0; i < source.length; i++) {
        const t = source[i];
        const value = selector(t);
        if (
            source.some(
                (other: T, index: number) =>
                    index !== i && selector(other) === value
            )
        ) {
            duplicates.push(t);
        }
    }

    return duplicates;
};

/**
 * Return the array of elements that are present in arrayA that do not appear in arrayB according
 * to the given property. If property is not supplied, it will filter on the individual values.
 */
const _difference = <T, TProperty extends keyof T>(
    arrayA: Array<T>,
    arrayB: Array<T>,
    property?: TProperty
) => {
    if (property == null) {
        return arrayA.filter((item) => !arrayB.includes(item));
    }

    return arrayA.filter(
        (item) => !arrayB.some((e) => e[property] === item[property])
    );
};

/**
 * Returns an array of the source elements with duplicates filtered out,
 * checking duplicates by the property returned from {selector}
 * @param source
 * @param selector
 */
const _distinctBy = <T, V>(source: Array<T>, selector: (value: T) => V) => {
    const newArr: Array<T> = [];
    for (const t of source) {
        if (newArr.some((value: T) => selector(value) === selector(t))) {
            continue;
        }

        newArr.push(t);
    }

    return newArr;
};

/**
 * Compare two collections by a property of each value,
 * specified by selector, including considering the order of
 * elements, as long as all elements of one exist in the
 * other.
 * @param selector a function taking the item of the array and returning a property.
 * @param array1 first array to compare.
 * @param array2 second array to compare.
 * @returns true if both arrays contain all the same elements of the other,
 *          not considering order, false otherwise.
 */
const _equalsByOrdered = <T, V>(
    selector: (element: T) => V,
    array1: Array<T> | List<any> | undefined,
    array2: Array<T> | List<any> | undefined
): boolean => {
    if (array1 == null) {
        return array2 == null;
    }

    if (array2 == null) {
        return false;
    }

    if (
        AndcultureCodeCollectionUtils.length(array1) !==
        AndcultureCodeCollectionUtils.length(array2)
    ) {
        return false;
    }

    for (let i = 0; i < AndcultureCodeCollectionUtils.length(array1); i++) {
        if (_get(array1, i) !== _get(array2, i)) {
            return false;
        }
    }

    return true;
};

/**
 * Utility method to get an element out of something that could be either an array or a list.
 * @param arr
 * @param index
 * @private
 */
const _get = <T>(arr: Array<T> | List<T>, index: number): T | undefined => {
    if (arr instanceof List) {
        return (arr as List<T>).get(index);
    }

    return (arr as Array<T>)[index];
};

/**
 * Given a List of elements and a predicate to find the index of one of those elements,
 * returns the previous element in the list.
 *
 * This function is useful when deleting an item from a List and then finding the previous item
 * to set as a default.
 *
 * Note: If the predicate finds the first item in the List, then the second item will be returned.
 *
 * @param elements
 * @param predicate
 */
const _getPreviousElement = <T>(
    elements: List<T>,
    predicate: (value: T) => boolean
): T | undefined => {
    const currentIndex = elements.findIndex(predicate);
    if (currentIndex === -1) {
        return;
    }

    const prevIndex = currentIndex === 0 ? 1 : currentIndex - 1;
    const prev = elements.get(prevIndex);

    return prev;
};

const _handleDragAndDropReorder = <T extends any>(
    items: Array<T>,
    startIndex: number,
    endIndex: number
): Array<T> => {
    const result = [...items];
    const [removedItem] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removedItem);

    return result;
};

/**
 * Join items to a formatted list string,
 * i.e. "item1, item2, and item3"
 * @param items
 * @param selector
 * @param includeAnd include the word "and" before the last element
 */
const _joinToCommaSeparatedList = <T, V>(
    items: Array<T> | List<T>,
    selector: (value: T) => V | T = (value: T) => value,
    includeAnd: boolean = true
) => {
    // force typing as List to make typing easier to work with
    const itemsList = List(items);

    if (itemsList.isEmpty()) {
        return "";
    }
    if (itemsList.size === 1) {
        return selector(itemsList.get(0)!);
    }
    const allProperties = itemsList.map(selector);
    const propertiesToDelineate = allProperties.pop();
    const lastPropertyInList = allProperties.get(allProperties.size - 1);

    return `${propertiesToDelineate.join(", ")}${
        includeAnd ? " and" : ","
    } ${lastPropertyInList}`;
};

/**
 * Returns a NEW array with the element at the specified index
 * replaced with the specified value. Since it returns a new array,
 * this can be safely used as the value for a React.SetStateAction
 * i.e. setMyArray(CollectionUtils.replaceElementAt(myArray, index, newValue));
 * @param source
 * @param index
 * @param value
 */
const _replaceElementAt = <T>(
    source: Array<T>,
    index: number,
    value: T
): Array<T> => {
    // Note: This implementation does slightly differ from the current implementation in
    // `AndcultureCodeCollectionUtils`, so I am refraining from swapping it right now.
    if (index < 0) {
        return [...source];
    }

    if (source.length === 0) {
        return source;
    }
    if (source.length === 1) {
        return [value];
    }

    if (index === source.length - 1) {
        return [...source.slice(0, index), value];
    }

    return [...source.slice(0, index), value, ...source.slice(index + 1)];
};

/**
 * Finds all unique values for a property across a list of records.
 *
 * @template TRecord
 * @template TProperty
 * @template TReturn
 * @param {TProperty} property  Name of property on record
 * @param {(Array<TRecord> | List<TRecord>)} records  Records to pull values from
 */
const _uniqueValuesByProperty = <
    TRecord,
    TProperty extends keyof TRecord,
    TReturn extends NonNullable<TRecord[TProperty]>
>(
    records: Array<TRecord> | List<TRecord>,
    property: TProperty
): Array<TReturn> =>
    List(records)
        .map((e) => e[property])
        .filter((e): e is TReturn => e != null)
        .toSet() // Unique property values.
        .toArray();

// #endregion Functions
// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const CollectionUtils = {
    ...AndcultureCodeCollectionUtils,
    collapseDisplaySequencesAndSort: _collapseDisplaySequencesAndSort,
    collapseDisplaySequences: _collapseDisplaySequences,
    difference: _difference,
    distinctBy: _distinctBy,
    equalsByOrdered: _equalsByOrdered,
    findDuplicates: _findDuplicates,
    getPreviousElement: _getPreviousElement,
    handleDragAndDropReorder: _handleDragAndDropReorder,
    joinToCommaSeparatedList: _joinToCommaSeparatedList,
    replaceElementAt: _replaceElementAt,
    uniqueValuesByProperty: _uniqueValuesByProperty,
};

// #endregion Exports
