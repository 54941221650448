import { useState, useCallback } from "react";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import usePageErrors from "utilities/hooks/use-page-errors";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface LoadRecordsResult<T> extends ServiceHookResult<Array<T>> {
    loadRecords: (
        fetchRecordCallback: () => Promise<Array<T> | undefined>
    ) => Promise<Array<T> | undefined>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

function useLoadRecords<TRecord>(): LoadRecordsResult<TRecord> {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [resultObject, setResultObject] = useState<Array<TRecord>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    // #endregion Hooks

    const loadRecords = useCallback(
        async (
            fetchRecordCallback: () => Promise<Array<TRecord> | undefined>
        ) => {
            setLoading(true);
            setLoaded(false);
            resetPageErrors();

            try {
                const records = await fetchRecordCallback();

                if (records == null) {
                    handlePageLoadError("There was an issue loading records.");
                    setLoading(false);
                    return;
                }

                setResultObject(records);
                setLoading(false);
                setLoaded(true);
                return records;
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        },
        [handlePageLoadError, resetPageErrors]
    );

    return {
        resultObject,
        loading,
        loaded,
        loadRecords,
        errors,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useLoadRecords;

// #endregion Exports
