import { siteMap } from "internal-sitemap";
import AnnexContent from "organisms/publication-content/annex-content";
import AnnexGroupContent from "organisms/publication-content/annex-group-content";
import AnnexTablesContent from "organisms/publication-content/annex-tables-content";
import ArticleContent from "organisms/publication-content/article-content";
import ChapterContent from "organisms/publication-content/chapter-content";
import ChapterTablesContent from "organisms/publication-content/chapter-tables-content";
import AnnexGroupRedirect from "organisms/publication-content/legacy-redirects/annex-group-redirect";
import AnnexRedirect from "organisms/publication-content/legacy-redirects/annex-redirect";
import ArticleRedirect from "organisms/publication-content/legacy-redirects/article-redirect";
import ChapterRedirect from "organisms/publication-content/legacy-redirects/chapter-redirect";
import MetadataRedirect from "organisms/publication-content/legacy-redirects/metadata-redirect";
import PublicationRedirect from "organisms/publication-content/legacy-redirects/publication-redirect";
import SectionRedirect from "organisms/publication-content/legacy-redirects/section-redirect";
import MetadataContent from "organisms/publication-content/metadata-content";
import SectionContent from "organisms/publication-content/section-content";
import BookViewLayout from "templates/bookview-layout";
import PublicationLayout from "templates/publication-layout";
import PublicationWrapper from "templates/publication-wrapper";
import { RouteMap } from "utilities/interfaces/route-map";

export const deprecatedPublicationRoutes: RouteMap = {
    deprecatedPublication: {
        authRequired: true,
        component: PublicationRedirect,
        exact: true,
        path: siteMap.publications.deprecated.publication,
        routes: {},
    },
    deprecatedChapter: {
        authRequired: true,
        component: ChapterRedirect,
        exact: true,
        path: siteMap.publications.deprecated.chapter,
        routes: {},
    },
    deprecatedPublicationMetadata: {
        authRequired: true,
        component: MetadataRedirect,
        exact: true,
        path: siteMap.publications.deprecated.publicationMetadata,
        routes: {},
    },
    deprecatedAnnex: {
        authRequired: true,
        component: AnnexRedirect,
        exact: true,
        path: siteMap.publications.deprecated.annex,
        routes: {},
    },
    deprecatedAnnexGroup: {
        authRequired: true,
        component: AnnexGroupRedirect,
        exact: true,
        path: siteMap.publications.deprecated.annexGroup,
        routes: {},
    },
    deprecatedArticle: {
        authRequired: true,
        component: ArticleRedirect,
        exact: true,
        path: siteMap.publications.deprecated.article,
        routes: {},
    },
    deprecatedSection: {
        authRequired: true,
        component: SectionRedirect,
        exact: true,
        path: siteMap.publications.deprecated.section,
        routes: {},
    },
};
export const publicationRoutes: RouteMap = {
    ...deprecatedPublicationRoutes,
    base: {
        authRequired: true,
        component: PublicationWrapper,
        exact: false,
        path: siteMap.publications.permalinks.publication,
        routes: {
            publication: {
                authRequired: true,
                component: PublicationLayout,
                exact: true,
                path: siteMap.publications.permalinks.publication,
                routes: {},
            },
            bookView: {
                authRequired: true,
                component: BookViewLayout,
                exact: false,
                path: siteMap.publications.permalinks.publication,
                routes: {
                    chapter: {
                        path: siteMap.publications.permalinks.chapter,
                        exact: true,
                        authRequired: true,
                        component: ChapterContent,
                        routes: {},
                    },
                    article: {
                        path: siteMap.publications.permalinks.article,
                        exact: true,
                        authRequired: true,
                        component: ArticleContent,
                        routes: {},
                    },
                    annex: {
                        path: siteMap.publications.permalinks.annex,
                        exact: true,
                        authRequired: true,
                        component: AnnexContent,
                        routes: {},
                    },
                    annexGroup: {
                        path: siteMap.publications.permalinks.annexGroup,
                        exact: true,
                        authRequired: true,
                        component: AnnexGroupContent,
                        routes: {},
                    },
                    section: {
                        path: siteMap.publications.permalinks.section,
                        exact: true,
                        authRequired: true,
                        component: SectionContent,
                        routes: {},
                    },
                    chapterTables: {
                        path: siteMap.publications.permalinks.chapterTables,
                        exact: true,
                        authRequired: true,
                        component: ChapterTablesContent,
                        routes: {},
                    },
                    annexTables: {
                        path: siteMap.publications.permalinks.annexTables,
                        exact: true,
                        authRequired: true,
                        component: AnnexTablesContent,
                        routes: {},
                    },
                    metadata: {
                        authRequired: true,
                        component: MetadataContent,
                        exact: true,
                        path: siteMap.publications.permalinks
                            .publicationMetadata,
                        routes: {},
                    },
                },
            },
        },
    },
    previewBase: {
        authRequired: true,
        component: PublicationWrapper,
        exact: false,
        path: siteMap.publications.adminPreview.publication,
        routes: {
            previewPublication: {
                authRequired: true,
                component: PublicationLayout,
                exact: true,
                path: siteMap.publications.adminPreview.publication,
                routes: {},
            },
            preview: {
                authRequired: true,
                component: BookViewLayout,
                exact: false,
                path: siteMap.publications.adminPreview.publication,
                routes: {
                    chapter: {
                        path: siteMap.publications.adminPreview.chapter,
                        exact: true,
                        authRequired: true,
                        component: ChapterContent,
                        routes: {},
                    },
                    article: {
                        path: siteMap.publications.adminPreview.article,
                        exact: true,
                        authRequired: true,
                        component: ArticleContent,
                        routes: {},
                    },
                    annex: {
                        path: siteMap.publications.adminPreview.annex,
                        exact: true,
                        authRequired: true,
                        component: AnnexContent,
                        routes: {},
                    },
                    annexGroup: {
                        path: siteMap.publications.adminPreview.annexGroup,
                        exact: true,
                        authRequired: true,
                        component: AnnexGroupContent,
                        routes: {},
                    },
                    section: {
                        path: siteMap.publications.adminPreview.section,
                        exact: true,
                        authRequired: true,
                        component: SectionContent,
                        routes: {},
                    },
                    chapterTables: {
                        path: siteMap.publications.adminPreview.chapterTables,
                        exact: true,
                        authRequired: true,
                        component: ChapterTablesContent,
                        routes: {},
                    },
                    annexTables: {
                        path: siteMap.publications.adminPreview.annexTables,
                        exact: true,
                        authRequired: true,
                        component: AnnexTablesContent,
                        routes: {},
                    },
                    metadata: {
                        authRequired: true,
                        component: MetadataContent,
                        exact: true,
                        path: siteMap.publications.adminPreview
                            .publicationMetadata,
                        routes: {},
                    },
                },
            },
        },
    },
};
