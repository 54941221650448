import StringIndexedObject from "utilities/types/string-indexed-object";
import StringUtils from "utilities/string-utils";
import { KeyValuePair } from "andculturecode-javascript-core";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const COOKIE_CLEARED_MAX_AGE = "max-age=-99999999";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ICookieUtils {
    /**
     * Return all cookies for the current page
     *
     * @return {*}  {StringIndexedObject<any>}
     */
    all(): StringIndexedObject<any>;

    /**
     * Clear the stored value for a cookie by the provided cookie key
     *
     * @param {string} key
     */
    clear(key: string): void;

    /**
     * Get the stored value for the provided cookie based on key.
     * If none exists, it returns undefined
     *
     * @template T
     * @param {string} key
     * @return {*}  {(T | undefined)}
     */
    get<T = string>(key: string): T | undefined;

    /**
     * Store the provided value in the cookie storage based on the key and options provided
     *
     * @template T
     * @param {string} key
     * @param {T} value
     * @param {SetCookieOptions} [options]
     */
    set<T = string>(key: string, value: T, options?: SetCookieOptions): void;
}

export interface SetCookieOptions {
    domain?: string;
    expires?: Date;
    maxAge?: number;
    path?: string;
    sameSite?: SameSiteCookie;
    secure?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum SameSiteCookie {
    None = "none",
    Lax = "lax",
    Strict = "strict",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function clearCookie(key: string) {
    document.cookie = `${key}=; ${COOKIE_CLEARED_MAX_AGE};`;
}

function cookies(): StringIndexedObject<any> {
    const cookies = _getCookieKeyValuePairs();
    return _convertToObject(cookies);
}

function getCookie<T = string>(key: string): T | undefined {
    return cookies()[key];
}

function setCookie<T = string>(
    key: string,
    value: T,
    options?: SetCookieOptions
): void {
    const { domain, expires, maxAge, path, sameSite, secure } = options ?? {};

    const cookieValues: string[] = [
        encodeURIComponent(JSON.stringify({ value })),
    ];

    if (path != null) {
        cookieValues.push(`path=${path}`);
    }

    if (domain != null) {
        cookieValues.push(`domain='${domain}'`);
    }

    if (maxAge != null) {
        cookieValues.push(`max-age=${maxAge}`);
    }

    if (expires != null) {
        cookieValues.push(`expires=${expires.toUTCString()}`);
    }

    if (secure === true) {
        cookieValues.push(`secure`);
    }

    if (sameSite != null) {
        cookieValues.push(`samesite=${sameSite}`);
    }

    document.cookie = `${key}=${cookieValues.join("; ")};`;
}

// #endregion  Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function _convertToObject<T = any>(
    cookies: Array<Partial<KeyValuePair<string, T>>>
): StringIndexedObject<T> {
    return cookies.reduce<StringIndexedObject<T>>(
        (previous, { key, value }) => {
            if (key == null || value == null) {
                return previous;
            }

            return {
                ...previous,
                [key]: value,
            };
        },
        {}
    );
}

function _getCookieKeyValuePairs(): Array<Partial<KeyValuePair<string, any>>> {
    return document.cookie
        .split(";")
        .map<Partial<KeyValuePair<string, any>>>((s) => {
            const [key, value] = s?.trim()?.split("=");

            if (StringUtils.isEmpty(key) || StringUtils.isEmpty(value)) {
                return {};
            }

            return {
                key: key,
                value: _tryParse(value),
            };
        })
        .filter(({ key }) => key != null);
}

function _tryParse(maybeJson: string): any {
    const maybeJsonValue = decodeURIComponent(maybeJson);

    try {
        const { value, ...rest } = JSON.parse(maybeJsonValue);
        const restHasNoProperties = Object.values(rest).length === 0;

        if (value != null && restHasNoProperties) {
            return value;
        }

        return { ...rest, value };
    } catch {
        return maybeJsonValue;
    }
}

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const CookieUtils: ICookieUtils = {
    all: cookies,
    clear: clearCookie,
    get: getCookie,
    set: setCookie,
};

// #endregion Exports
