import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import FileRecord from "models/view-models/file-record";
import { ModalTypes } from "molecules/constants/modal-types";
import FileUploadField from "molecules/form-fields/file-upload-field";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import React, { useState } from "react";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import MimeTypes from "utilities/enumerations/mime-type";
import FileUtils from "utilities/file-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminHotSpotUploadModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog"> {
    baseClassName: string;
    onCancel: () => void;
    onConfirm: () => void;
    onFileChanged: (newFile?: FileRecord) => void;
    value?: FileRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const MAX_HOT_SPOT_IMAGE_WIDTH = 1920;
const MAX_HOT_SPOT_IMAGE_HEIGHT = 1080;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminHotSpotUploadModal: React.FC<AdminHotSpotUploadModalProps> = (
    props: AdminHotSpotUploadModalProps
) => {
    const [imageSizeError, setImageSizeError] = useState("");

    const validateImageSize = async (file: File): Promise<boolean> => {
        setImageSizeError("");
        const { width, height } = await FileUtils.getImageResolution(file);
        if (
            width > MAX_HOT_SPOT_IMAGE_WIDTH ||
            height > MAX_HOT_SPOT_IMAGE_HEIGHT
        ) {
            setImageSizeError("Image dimensions exceed maximum size.");
            return false;
        }

        return true;
    };

    return (
        <Modal
            cssClassName={`${props.baseClassName}__upload-modal`}
            closeDialog={props.closeDialog}
            isVisible={props.isVisible}
            label="Upload Hot Spot Image"
            type={ModalTypes.Base}>
            <Form onSubmit={() => false}>
                <FileUploadField
                    accept={MimeTypes.Image}
                    beforeUpload={validateImageSize}
                    errorMessages={[imageSizeError]}
                    fileUploadDestination={
                        FileUploadDestination.SituationHotSpotImage
                    }
                    helpText={`Maximum size: ${MAX_HOT_SPOT_IMAGE_WIDTH}x${MAX_HOT_SPOT_IMAGE_HEIGHT}`}
                    label="Attach Image"
                    onFileChanged={props.onFileChanged}
                    required={true}
                    value={props.value}
                />
                <div className={`${props.baseClassName}__upload-modal__footer`}>
                    <Button
                        type={ButtonTypes.Button}
                        style={ButtonStyles.Secondary}
                        onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button
                        disabled={props.value == null}
                        type={ButtonTypes.Button}
                        onClick={props.onConfirm}>
                        Add
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminHotSpotUploadModal;

// #endregion Exports
