import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import RichTextEditor from "molecules/rich-text/rich-text-editor";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import FileRecord from "models/view-models/file-record";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import UnsavedChangesPrompt from "molecules/unsaved-changes-prompt/unsaved-changes-prompt";
import AdminCategoriesSection from "organisms/admin/situational-navigation/admin-categories-section";
import PublishStatusMenu from "organisms/admin/situational-navigation/publish-status-menu";
import AdminSituationRelationshipsSection from "organisms/admin/situational-navigation/situations/admin-situation-relationships-section";
import { SituationsAdminPageContainerId } from "pages/admin/situational-navigation/situations-page";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { CollectionUtils } from "utilities/collection-utils";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import FileUtils from "utilities/file-utils";
import useAdminSituation from "utilities/hooks/domain/admin/situational-navigation/situations/use-admin-situation";
import useAdminSituationCategories from "utilities/hooks/domain/admin/situational-navigation/situations/use-admin-situation-categories";
import useAdminSituationRelationships from "utilities/hooks/domain/admin/situational-navigation/situations/use-admin-situation-relationships";
import useLoading from "utilities/hooks/use-loading";
import usePageErrors from "utilities/hooks/use-page-errors";
import NumberUtils from "utilities/number-utils";
import AdminSituationPublishService from "utilities/services/admin/situational-navigation/situations/admin-situation-publish-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { RouteUtils } from "utilities/route-utils";
import { RecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export const SituationEditorContentAreaId =
    "situation-editor-content-area-container";

const SITUATION_PUBLISH_ERROR = "There was an issue publishing this situation.";
const SITUATION_PUBLISH_SUCCESSFUL = "Situation successfully published.";
const SITUATION_UNPUBLISH_ERROR =
    "There was an issue unpublishing this situation.";
const SITUATION_UNPUBLISH_SUCCESSFUL =
    "Situation has been successfully unpublished.";

// #endregion Constants

const SituationEditor: React.FC = () => {
    // -----------------------------------------------------------------------------------------
    // #region String Constants
    // -----------------------------------------------------------------------------------------

    const CSS_CLASS_NAME = "c-admin-editor-layout";

    // #endregion String Constants

    // -----------------------------------------------------------------------------------------
    // #region Context
    // -----------------------------------------------------------------------------------------

    const { id } = useParams();
    const { context } = useAdminEditorPageContext();

    // #endregion Context

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------
    const { delete: unpublishSituationApi } =
        AdminSituationPublishService.useUnpublish();
    const { update: publishSituationApi } =
        AdminSituationPublishService.usePublish();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State and Custom Hooks
    // -----------------------------------------------------------------------------------------

    const {
        pageErrors: titleErrors,
        resetPageErrors: resetTitleErrors,
        setPageErrors: setTitleErrors,
    } = usePageErrors();

    const {
        createSituation,
        deleteSituation,
        hotSpotImage,
        initializeSituation,
        initialSituation,
        loadingHotSpotImage,
        loadingSituation,
        redirectIgnoreIsDirty,
        setHotSpotImage,
        setLoadingSituation,
        setRedirectIgnoreIsDirty,
        setSituation,
        situation,
        updateSituation,
    } = useAdminSituation(id);

    const {
        categories,
        initialCategories,
        initializeCategories,
        loadingCategories,
        setCategories,
        updateCategories,
    } = useAdminSituationCategories(id);

    const {
        initializeRelatedResults,
        initialSituationRelationships,
        initialSituations,
        initialSolutions,
        loadingRelatedResults,
        setSituationRelationships,
        setSituations,
        setSolutions,
        situationRelationships,
        situations,
        solutions,
        updateRelationships,
    } = useAdminSituationRelationships(id);

    const loading = useLoading(
        loadingCategories,
        loadingHotSpotImage,
        loadingRelatedResults,
        loadingSituation
    );

    const getDropdownPortalElement = (): HTMLElement =>
        document.getElementById(SituationsAdminPageContainerId)!;

    /**
     * This is needed because, for example, on successful creation, the setIsDirty(false)
     * doesn't go through before the page attempts to redirect, so we need to ignore the isDirty
     * flag in order to prevent the confirm prompt from showing when it shouldn't
     */

    const [isDirty, setIsDirty] = useState(false);

    const reset = useCallback(() => {
        initializeRelatedResults();
        initializeCategories();
    }, [initializeCategories, initializeRelatedResults]);

    // #endregion State and Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    /**
     * Reset all data when id changes
     */
    useEffect(() => reset(), [id, reset]);

    /**
     * Update isDirty flag when data changes
     */
    useEffect(() => {
        if (situation == null || loading) {
            setIsDirty(false);
            return;
        }

        if (situation.id == null) {
            setIsDirty(
                StringUtils.hasValue(situation.titleDraft) ||
                    StringUtils.hasValue(situation.subtitleDraft) ||
                    StringUtils.hasValue(situation.bodyDraft) ||
                    CollectionUtils.hasValues(situationRelationships) ||
                    categories.hasValues() ||
                    situation.hotSpotImageFileDraftId != null
            );
            return;
        }

        setIsDirty(
            initialSituation.titleDraft !== situation.titleDraft ||
                initialSituation.subtitleDraft !== situation.subtitleDraft ||
                initialSituation.bodyDraft !== situation.bodyDraft ||
                initialSituation.hotSpotImageFileDraftId !==
                    situation.hotSpotImageFileDraftId ||
                !CollectionUtils.equalsBy(
                    (c: CategoryRecord) => c.id,
                    initialCategories.toList().toJS(),
                    categories.toList().toJS()
                ) ||
                !CollectionUtils.equalsBy(
                    (s: SolutionRecord) => s.id,
                    initialSolutions,
                    solutions
                ) ||
                !CollectionUtils.equalsBy(
                    (s: SituationRecord) => s.id,
                    initialSituations,
                    situations
                ) ||
                !CollectionUtils.equalsByOrdered(
                    (s: SituationRelationshipRecord) => s.id,
                    initialSituationRelationships,
                    situationRelationships
                )
        );
    }, [
        categories,
        hotSpotImage,
        id,
        initialCategories,
        initialSituation,
        initialSituationRelationships,
        initialSituations,
        initialSolutions,
        situation,
        situationRelationships,
        situations,
        solutions,
        loading,
    ]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSituation((prevState: SituationRecord) =>
            prevState.with({ titleDraft: e.target.value })
        );
    const handleSubtitleChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSituation((prevState: SituationRecord) =>
            prevState.with({ subtitleDraft: e.target.value })
        );
    const handleBodyChanged = (newValue: string) =>
        setSituation((prevState: SituationRecord) =>
            prevState.with({ bodyDraft: newValue })
        );
    const handleCategoriesChanged = (newValues: CategoryCollectionRecord) =>
        setCategories(newValues);
    const handleImageFileSelected = (file?: FileRecord) => {
        setSituation((prevState: SituationRecord) =>
            prevState.with({
                hotSpotImageFileDraftId: file?.id,
                hotSpotImageFileDraft: file,
            })
        );
        setHotSpotImage(file);
    };
    const handleRelatedResultSelected = (
        newValue: SolutionRecord | SituationRecord
    ) => {
        const isSolution = RecordUtils.isRecord(newValue, SolutionRecord);

        const newSituationRelationships = [
            ...situationRelationships,
            new SituationRelationshipRecord().with({
                displaySequenceDraft: situationRelationships.length - 1,
                situationId: NumberUtils.parseInt(id) ?? -1,
                relatedSolutionId: isSolution ? newValue.id : undefined,
                relatedSituationId: isSolution ? undefined : newValue.id,
            }),
        ];
        setSituationRelationships(
            CollectionUtils.collapseDisplaySequencesAndSort(
                newSituationRelationships,
                (
                    s: SituationRelationshipRecord,
                    displaySequenceDraft: number
                ) => s.with({ displaySequenceDraft }),
                (s: SituationRelationshipRecord) => s.displaySequenceDraft
            )
        );

        if (isSolution) {
            setSolutions([...solutions, newValue as SolutionRecord]);
            return;
        }

        setSituations([...situations, newValue as SituationRecord]);
    };
    const handleHotSpotUpdate = (
        newValues: Array<SituationRelationshipRecord>
    ) =>
        setSituationRelationships(
            CollectionUtils.collapseDisplaySequencesAndSort(
                newValues,
                (
                    r: SituationRelationshipRecord,
                    displaySequenceDraft: number
                ) => r.with({ displaySequenceDraft }),
                (r: SituationRelationshipRecord) => r.displaySequenceDraft
            )
        );
    const handleRelatedResultRemoved = (
        removedValue: SolutionRecord | SituationRecord
    ) => {
        const isSolution = RecordUtils.isRecord(removedValue, SolutionRecord);

        setSituationRelationships(
            CollectionUtils.collapseDisplaySequencesAndSort(
                situationRelationships.filter(
                    (s: SituationRelationshipRecord) =>
                        isSolution
                            ? s.relatedSolutionId !== removedValue.id
                            : s.relatedSituationId !== removedValue.id
                ),
                (
                    s: SituationRelationshipRecord,
                    displaySequenceDraft: number
                ) => s.with({ displaySequenceDraft }),
                (s: SituationRelationshipRecord) => s.displaySequenceDraft
            )
        );

        if (isSolution) {
            setSolutions(
                solutions.filter(
                    (s: SolutionRecord) => s.id !== removedValue.id
                )
            );
            return;
        }

        setSituations(
            situations.filter((s: SituationRecord) => s.id !== removedValue.id)
        );
    };

    const handleSituationRelationshipsReordered = (
        newItems: Array<SituationRelationshipRecord>
    ) =>
        setSituationRelationships(
            CollectionUtils.collapseDisplaySequencesAndSort(
                newItems,
                (
                    s: SituationRelationshipRecord,
                    displaySequenceDraft: number
                ) => s.with({ displaySequenceDraft }),
                (s: SituationRelationshipRecord) => s.displaySequenceDraft
            )
        );

    const validate = (): boolean => {
        if (StringUtils.isEmpty(situation.titleDraft)) {
            setTitleErrors(["Title is required."]);
            return false;
        }

        resetTitleErrors();
        return true;
    };

    const handleDeleteClick = () => {
        const situationId = NumberUtils.parseInt(id);
        if (situationId == null) {
            return;
        }

        deleteSituation(situationId);
    };

    const handlePublishClick = async () => {
        setLoadingSituation(true);

        if (isDirty) {
            await updateAllData();
        }

        const oldFileId = situation.hotSpotImageFileId;

        try {
            const result = await publishSituationApi(situation, {
                id: situation.id!,
            });
            const published = result.resultObject!;
            initializeSituation(published, hotSpotImage);
            if (
                oldFileId != null &&
                published.hotSpotImageFileId !== oldFileId
            ) {
                const fileDeleteResult = await FileUtils.deleteFileById(
                    oldFileId
                );
                if (!fileDeleteResult) {
                    ToastManager.error(
                        "There was an issue deleting the old hotspot image file."
                    );
                }
            }
            ToastManager.success(SITUATION_PUBLISH_SUCCESSFUL);
        } catch (e) {
            ToastManager.error(SITUATION_PUBLISH_ERROR);
        }

        setLoadingSituation(false);
    };

    const handleUnpublishClick = async () => {
        setLoadingSituation(true);

        try {
            const unpublished = situation.with({
                publishedById: undefined,
                publishedOn: undefined,
            });
            await unpublishSituationApi(unpublished.id!);
            initializeSituation(
                situation.with({
                    publishedOn: undefined,
                    publishedById: undefined,
                }),
                hotSpotImage
            );
            ToastManager.success(SITUATION_UNPUBLISH_SUCCESSFUL);
        } catch (e) {
            ToastManager.error(SITUATION_UNPUBLISH_ERROR);
        }

        setLoadingSituation(false);
    };

    const updateAllData = async () => {
        return await Promise.all([
            updateSituation(),
            updateCategories(situation.id),
            updateRelationships(situation.id),
        ]);
    };

    const handleFormSubmit = async () => {
        if (!validate()) {
            return;
        }

        if (situation.id == null) {
            const newSituation = await createSituation();
            if (newSituation != null) {
                await Promise.all([
                    updateCategories(newSituation.id),
                    updateRelationships(newSituation.id),
                ]);
                setRedirectIgnoreIsDirty(
                    RouteUtils.getUrl(
                        siteMap.admin.situationalNavigation.situations.edit,
                        { id: newSituation.id }
                    )
                );
            }
            return;
        }

        await Promise.all([
            updateSituation(),
            updateCategories(situation.id),
            updateRelationships(situation.id),
        ]);
    };

    // #endregion Event Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Component
    // -------------------------------------------------------------------------------------------------

    if (StringUtils.hasValue(redirectIgnoreIsDirty)) {
        return <Redirect to={redirectIgnoreIsDirty!} />;
    }

    return (
        <div className={`${CSS_CLASS_NAME} c-situation-editor`}>
            <div
                className={`${CSS_CLASS_NAME}__content`}
                id={SituationEditorContentAreaId}>
                <Paragraph
                    size={ParagraphSizes.Large}
                    cssClassName={`${CSS_CLASS_NAME}__content__heading`}>
                    {situation.id == null ? "New " : "Edit "} Situation
                </Paragraph>
                <Form onSubmit={handleFormSubmit}>
                    <InputFormField
                        disabled={loading}
                        errorMessages={titleErrors}
                        isValid={CollectionUtils.isEmpty(titleErrors)}
                        label="Title"
                        maxLength={150}
                        onChange={handleTitleChanged}
                        placeholder="Situation title..."
                        required={true}
                        value={situation.titleDraft}
                    />
                    <InputFormField
                        disabled={loading}
                        isValid={true}
                        label="Subtitle"
                        maxLength={150}
                        onChange={handleSubtitleChanged}
                        placeholder="Situation subtitle..."
                        value={situation.subtitleDraft}
                    />
                    <RichTextEditor
                        allowImages={true}
                        disabled={loading}
                        label="Body"
                        onChange={handleBodyChanged}
                        placeholder="Situation body..."
                        value={situation.bodyDraft}
                    />
                    <AdminCategoriesSection
                        baseClassName={CSS_CLASS_NAME}
                        dropdownPortal={getDropdownPortalElement()}
                        loading={loading}
                        onChange={handleCategoriesChanged}
                        options={context.categories}
                        showSituationHelpText={true}
                        value={categories}
                    />
                    <AdminSituationRelationshipsSection
                        dropdownPortal={getDropdownPortalElement()}
                        hotSpotImageFile={hotSpotImage}
                        loadingFile={loadingHotSpotImage}
                        onImageSelected={handleImageFileSelected}
                        onRemoved={handleRelatedResultRemoved}
                        onReordered={handleSituationRelationshipsReordered}
                        onSelected={handleRelatedResultSelected}
                        onHotSpotUpdate={handleHotSpotUpdate}
                        situationId={NumberUtils.parseInt(id)}
                        selectedSolutions={solutions}
                        selectedSituations={situations}
                        value={situationRelationships}
                    />
                </Form>
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                {loading && (
                    <Loader
                        accessibleText={
                            situation.id == null ? "Creating" : "Saving"
                        }
                    />
                )}
                {!loading && (
                    <React.Fragment>
                        <div className={`${CSS_CLASS_NAME}__footer__left`}>
                            {situation.id == null && (
                                <Anchor
                                    to={
                                        siteMap.admin.situationalNavigation
                                            .situations.dashboard
                                    }
                                    cssClassName="c-button -secondary">
                                    Cancel
                                </Anchor>
                            )}
                            <Button onClick={handleFormSubmit}>
                                {situation.id == null ? "Create " : "Save "}
                                Situation
                            </Button>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__footer__right`}>
                            <PublishStatusMenu
                                isValueDirty={isDirty}
                                onDeleteClick={handleDeleteClick}
                                onPublishClick={handlePublishClick}
                                onUnpublishClick={handleUnpublishClick}
                                recordLabel="situation"
                                value={situation}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
            <UnsavedChangesPrompt
                isDirty={isDirty}
                message="Any unsaved changes will be lost."
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationEditor;

// #endregion Exports
