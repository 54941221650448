import { useLocalization } from "utilities/hooks/use-localization";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { AppNameWithHtml } from "constants/app-name-tm";
import CreateUserDto from "models/interfaces/create-user-dto";
import NewUserLoginForm, {
    NewUserLoginFormProps,
} from "organisms/userlogins/userlogins-new-form/userlogins-new-form";
import React, { PropsWithChildren, useState } from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import { v4 } from "uuid";
import NewUserForm, { NewUserFormProps } from "./new-user-form";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface RegisterOrLoginFormProps
    extends Omit<NewUserFormProps, "onSuccess" | "onSubmit">,
        Omit<NewUserLoginFormProps, "onSuccess" | "onSubmit"> {
    newUserLoginFormSubmitButtonText?: string;
    onError?: (message?: string) => void;
    onNewUserFormSuccess?: () => void;
    onSubmit?: (() => boolean) | ((user?: CreateUserDto) => boolean);
    onUserLoginFormSuccess?: () => void;
    showRegisterForm: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-register-or-login-form";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RegisterOrLoginForm: React.FunctionComponent<
    PropsWithChildren<RegisterOrLoginFormProps>
> = (props: PropsWithChildren<RegisterOrLoginFormProps>) => {
    // -----------------------------------------------------------------------------------------
    // #region Setup
    // -----------------------------------------------------------------------------------------

    const {
        defaultEmail,
        freeTrialAgreementError,
        freeTrialTermsAccepted,
        newUserLoginFormSubmitButtonText,
        newUserLoginFormType,
        onError,
        onFreeTrialTermsAccepted,
        onNewUserFormSuccess,
        onSubmit,
        onUserLoginFormSuccess,
        selectedSubscriptionType,
        showLogo,
        showRegisterForm: showRegisterFormInitialValue,
        showSignInTitle,
        submitButtonText,
    } = props;

    const { useAzureB2CForSSO } = useFeatureFlags();
    const { t } = useLocalization<CultureResources>();
    const createAccountHeader = t("registerOrLogin-signUpHeader", {
        appNameWithHtml: AppNameWithHtml,
    });
    const signInHeader = useAzureB2CForSSO
        ? t("registerOrLogin-loginHeader")
        : t("registerOrLogin-signinHeader");
    const registerQuestion = t("registerOrLogin-signUpQuestion");
    const signInQuestion = t("registerOrLogin-loginQuestion");
    const signInText = useAzureB2CForSSO ? t("logIn") : t("signIn");
    const signUpLinkText = t("signUp");

    // #endregion Setup

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [showRegisterForm, setShowRegisterForm] = useState<boolean>(
        showRegisterFormInitialValue
    );

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Return
    // -----------------------------------------------------------------------------------------

    return (
        <div className={`${BASE_CLASS}`}>
            {useAzureB2CForSSO ? (
                <>
                    <Heading priority={HeadingPriority.Two}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: createAccountHeader,
                            }}
                        />
                    </Heading>
                    <div>
                        {registerQuestion}
                        &nbsp;
                        <LogInAnchor />
                    </div>
                    {props.children}
                </>
            ) : (
                <>
                    <Heading priority={HeadingPriority.Two}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: showRegisterForm
                                    ? createAccountHeader
                                    : signInHeader,
                            }}
                        />
                    </Heading>
                    <div>
                        {showRegisterForm ? registerQuestion : signInQuestion}
                        &nbsp;
                        <button
                            className={`${BASE_CLASS}__switch-form-trigger`}
                            onClick={() =>
                                setShowRegisterForm((previous) => !previous)
                            }>
                            {showRegisterForm ? signInText : signUpLinkText}
                        </button>
                    </div>
                </>
            )}

            {showRegisterForm ? (
                <React.Fragment>
                    <NewUserForm
                        defaultEmail={defaultEmail}
                        formId={`register-form-${v4()}`}
                        freeTrialAgreementError={freeTrialAgreementError}
                        freeTrialTermsAccepted={freeTrialTermsAccepted}
                        onError={onError}
                        onFreeTrialTermsAccepted={onFreeTrialTermsAccepted}
                        onSubmit={onSubmit}
                        onSuccess={onNewUserFormSuccess}
                        selectedSubscriptionType={selectedSubscriptionType}
                        submitButtonText={submitButtonText}
                    />
                </React.Fragment>
            ) : (
                <NewUserLoginForm
                    defaultEmail={defaultEmail}
                    freeTrialAgreementError={freeTrialAgreementError}
                    freeTrialTermsAccepted={freeTrialTermsAccepted}
                    newUserLoginFormType={newUserLoginFormType}
                    onError={onError}
                    onFreeTrialTermsAccepted={onFreeTrialTermsAccepted}
                    onSubmit={onSubmit}
                    onSuccess={onUserLoginFormSuccess}
                    selectedSubscriptionType={selectedSubscriptionType}
                    showLogo={showLogo}
                    showSignInTitle={showSignInTitle}
                    submitButtonText={
                        newUserLoginFormSubmitButtonText ?? submitButtonText
                    }
                />
            )}
        </div>
    );

    // #endregion Return
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default RegisterOrLoginForm;

// #endregion Exports
