import CreateFileRequestRecord from "models/view-models/create-file-request-record";
import FileRecord from "models/view-models/file-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FilePathParams {
    id: number;
}
export interface FileQueryParams {
    ids?: Array<number>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "files";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = FileRecord;
const key = "files";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const FileService = {
    key,
    create: ServiceFactory.create<CreateFileRequestRecord>(
        CreateFileRequestRecord,
        baseEndpoint
    ),
    useCreate: ServiceHookFactory.useCreate<CreateFileRequestRecord>(
        CreateFileRequestRecord,
        baseEndpoint
    ),
    delete: ServiceFactory.delete(resourceEndpoint),
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
    get: ServiceFactory.get<FileRecord, FilePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGet: ServiceHookFactory.useGet<FileRecord, FilePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<FileRecord, FilePathParams>(
        key,
        resourceType,
        resourceEndpoint
    ),
    useBatchedList: ServiceHookFactory.useBatchedList<
        FileRecord,
        FileQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FileService;

// #endregion Exports
