import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import type CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import { useEffect, useState } from "react";
import CategoryService from "utilities/services/situational-navigation/categories/category-service";
import { ToastManager } from "utilities/toast/toast-manager";

export default function useCategories(
    categoriesFromContext: CategoryCollectionRecord,
    refreshCategories: boolean
) {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------
    const [categories, setCategories] = useState(
        new CategoryCollectionRecord()
    );

    const [initialCategories, setInitialCategories] = useState<
        CategoryCollectionRecord | undefined
    >();
    const [loadingCategories, setLoadingCategories] = useState(false);
    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listCategoriesApi } = CategoryService.useList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        const loadCategories = async () => {
            setLoadingCategories(true);

            try {
                const result = await listCategoriesApi({
                    relatedCategoryIds: categoriesFromContext.mapToArray(
                        (r: CategoryRecord) => r.id!
                    ),
                });
                setCategories(
                    CategoryCollectionRecord.fromArray(result.resultObjects)
                );

                setInitialCategories(
                    (
                        initialCategories: CategoryCollectionRecord | undefined
                    ) => {
                        if (initialCategories == null) {
                            return CategoryCollectionRecord.fromArray(
                                result.resultObjects
                            );
                        }
                        return initialCategories;
                    }
                );
            } catch (e) {
                ToastManager.error("There was an issue loading categories.");
            }

            setLoadingCategories(false);
        };

        if (refreshCategories) {
            loadCategories();
        }
    }, [
        categoriesFromContext,
        listCategoriesApi,
        refreshCategories,
        setInitialCategories,
    ]);
    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Exports
    // -----------------------------------------------------------------------------------------

    return {
        initialCategories,
        categories,
        loadingCategories,
    };
    // #endregion Exports
}
