import SectionRecord from "models/view-models/section-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SectionBasePathParams {
    publicationId: number;
}

export interface SectionResourcePathParams extends SectionBasePathParams {
    id: number;
}

export interface SectionExternalIdPathParams extends SectionBasePathParams {
    externalId: string;
}

/**
 * Search parameters when listing Chapter resources.
 * These get translated to QueryString parameters
 */
export interface SectionListQueryParams {
    /**
     * An array of externalIds to filter by.
     */
    externalIds?: string[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/sections";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SectionRecord;
const externalIdEndpoint = `${baseEndpoint}/externalId/:externalId`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SectionService = {
    /**
     * Retrieves a specific Section resource
     */
    get: ServiceFactory.get<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Section resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for retrieving a specific Section resource in a react component
     */
    useGet: ServiceHookFactory.useGet<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGetByExternalId: ServiceHookFactory.useGet<
        SectionRecord,
        SectionExternalIdPathParams
    >(resourceType, externalIdEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),

    useNestedBatchList: ServiceHookFactory.useNestedBatchedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionService;

// #endregion Exports
