import ServiceFactory from "utilities/services/service-factory";
import { PartRecord } from "internal";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PartBasePathParams {
    publicationId: number;
    chapterId: number;
    articleId: number;
}

export interface PartResourcePathParams extends PartBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Part resources.
 * These get translated to QueryString parameters
 */
export interface PartListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint =
    "publications/:publicationId/chapters/:chapterId/articles/:articleId/parts";
const resourceType = PartRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "publications-chapters-articles-parts";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const PartService = {
    /**
     * Retrieve a specific Part resource
     */
    get: ServiceFactory.get<PartRecord, PartResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Part resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        PartRecord,
        PartListQueryParams,
        PartBasePathParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging PartService get calls in react components
     */
    useGet: ServiceHookFactory.useGet<PartRecord, PartResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<
        PartRecord,
        PartResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging PartService list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        PartRecord,
        PartBasePathParams,
        PartListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        PartRecord,
        PartBasePathParams,
        PartListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PartService;

// #endregion Exports
