import ImageStyle from "interfaces/organisms/image-style";
import { RefObject, useMemo, useState } from "react";

const MAX_ZOOM_LEVEL = 10;
const ZOOM_INCREMENT = 30;

const useZoom = (imageRef: RefObject<HTMLDivElement>) => {
    const [zoomLevel, setZoomLevel] = useState(0);

    const onZoomIn = () => {
        if (zoomLevel < MAX_ZOOM_LEVEL) {
            setZoomLevel(zoomLevel + 1);
        }
    };
    const onZoomOut = () => {
        if (zoomLevel > 0) {
            setZoomLevel(zoomLevel - 1);
        }
    };

    const zoomPercentage = `${100 + zoomLevel * ZOOM_INCREMENT}%`;
    const isImageRefValid = imageRef !== null && imageRef.current !== null;
    const shouldZoom = isImageRefValid && zoomLevel > 0;
    const display = zoomLevel === 0 ? "block" : "inline";

    const imageStyle: ImageStyle = useMemo(
        () =>
            shouldZoom
                ? imageRef.current.clientHeight < imageRef.current.clientWidth
                    ? {
                          height: zoomPercentage,
                          width: "auto",
                          display: display,
                      }
                    : {
                          height: "auto",
                          width: zoomPercentage,
                          display: display,
                      }
                : {},
        [display, imageRef, shouldZoom, zoomPercentage]
    );

    return {
        onZoomIn,
        onZoomOut,
        imageStyle,
    };
};

export default useZoom;
