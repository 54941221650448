import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useFeatureFlags from "./use-feature-flags";
import { NfpaUrlPathsV2 } from "constants/nfpa-urls/nfpa-url-paths-v2";
import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";

export default function useNfpaUrlPaths() {
    const { globalState } = useGlobalState();
    const { useAzureB2CForSSO } = useFeatureFlags();
    const systemSettings = globalState.getSystemSettings();
    interface nfpaUrls {
        CustomerSupport: string;
        ForgottenPassword: string;
        LicenseAgreement: string;
        LiNK: string;
        MyProfile: string;
        PrivacyPolicy: string;
        TermsOfUse: string;
        Login: string;
    }

    const getUrl = (path: keyof nfpaUrls) => {
        return useAzureB2CForSSO
            ? systemSettings.getNfpaUrlV2(NfpaUrlPathsV2[path])
            : systemSettings.getNfpaUrl(NfpaUrlPaths[path]);
    };

    const customerSupportUrl = getUrl("CustomerSupport");
    const forgotPasswordUrl = getUrl("ForgottenPassword");
    const licenseUrl = getUrl("LicenseAgreement");
    const linkUrl = getUrl("LiNK");
    const nfpaMyProfileUrl = getUrl("MyProfile");
    const privacyUrl = getUrl("PrivacyPolicy");
    const termsUrl = getUrl("TermsOfUse");
    const loginUrl = getUrl("Login");

    return {
        privacyUrl,
        termsUrl,
        forgotPasswordUrl,
        nfpaMyProfileUrl,
        customerSupportUrl,
        licenseUrl,
        linkUrl,
        loginUrl,
    };
}
