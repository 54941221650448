export enum WorkerNames {
    PublicationReferenceCreate = "PublicationReference Create",
    PublicationSearchDocumentClean = "Publication Search Document Clean",
    PublicationSearchUpload = "Publication Search Upload",
    PublicationReprocessPublication = "Reprocess Publication",
    TableExtractWorker = "Table Extract Worker",
    LegacyPublicationCreateTableOfContents = "Legacy Publication Create Table Of Contents",
    FreeAccessPublicationExtractPages = "Free Access Publication Extract Pages",
    FreeAccessPublicationExtractTableOfContents = "Free Access Publication Extract Table Of Contents",
    EnhancedContentSearchIndexMigration = "Enhanced Content Search Index Migration",
}
