import React, { forwardRef } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Button from "atoms/buttons/button";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import PublishStatus from "models/enumerations/publish-status";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentPublishActionProps
    extends Pick<EnhancedContentPanelProps, "enhancedContent"> {
    onClick: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentPublishAction = forwardRef<
    HTMLButtonElement,
    EnhancedContentPublishActionProps
>((props, ref) => {
    const { enhancedContent, onClick } = props;

    const { globalState } = useGlobalState();

    const canPublish =
        ((globalState.currentIdentity?.isAdmin() ||
            globalState.currentIdentity?.isPublisher()) &&
            (enhancedContent.getPublishStatus() === PublishStatus.Unpublished ||
                enhancedContent.hasUnpublishedChanges())) ??
        false;

    const buttonText = enhancedContent.hasUnpublishedChanges()
        ? "Publish Changes"
        : "Publish";

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    if (!canPublish) {
        return null;
    }

    return (
        <Button
            accessibleText={`${buttonText} Enhanced Content`}
            ref={ref}
            onClick={onClick}>
            {buttonText}
        </Button>
    );

    // #endregion Render
});

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentPublishAction;

// #endregion Exports
