import Anchor from "atoms/anchors/anchor";
import TableOfContentsBookmarkIndicator from "organisms/table-of-contents/table-of-contents-bookmark-indicator";
import React from "react";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import StringUtils from "utilities/string-utils";
import { DataTestAttributes } from "interfaces/data-test-attributes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationSidebarNavLinkProps {
    item: PublicationSidebarNavItem;
}

export interface PublicationSidebarNavItem
    extends Pick<DataTestAttributes, "dataTestDisplaySequence"> {
    /**
     * Determines whether this sidebar item has the 'active' state.
     *
     * @default false
     */
    active?: boolean;

    /**
     * Optional group by attribute for NFC annexes
     */
    groupBy?: string;

    /**
     * Id of the related element to be used as a key when mapping over items.
     */
    id: number;

    /**
     * Reflects whether the associated item has a diffchanged value of "deletion" (if applicable)
     */
    isDeleted?: boolean;

    /**
     * Bold label to display next to the sidebar item's title
     */
    label: string;

    /**
     * Route for the sidebar item to link to
     */
    route: string;

    /**
     * Determines whether the sidebar item label/title is styled on a single line together.
     *
     * @default false
     */
    singleLine?: boolean;

    /**
     * Title or name of the entity this sidebar item links to.
     */
    title: string;

    useRelativePath?: boolean;

    /**
     * Type of resource reflected in the navigation item
     */
    type?: PublicationComponentType;

    searchForLinkOnPage: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationSidebarNavLink: React.FC<PublicationSidebarNavLinkProps> = (
    props: PublicationSidebarNavLinkProps
) => {
    const {
        active,
        dataTestDisplaySequence,
        id,
        isDeleted,
        label,
        route,
        singleLine,
        title,
        useRelativePath,
        searchForLinkOnPage = false,
    } = props.item;
    const cssClassNames: Array<string> = ["c-publication-sidebar-nav-link"];
    const labelCssClassNames: Array<string> = [
        "c-publication-sidebar-nav-link__label",
    ];

    if (active) {
        cssClassNames.push("-active");
    }

    if (singleLine) {
        cssClassNames.push("-single-line");
    }

    const hasBookmark = false;

    if (isDeleted ?? false) {
        return null;
    }

    return (
        <Anchor
            cssClassName={StringUtils.joinClassNames(cssClassNames)}
            dataTestDisplaySequence={dataTestDisplaySequence}
            id={id.toString()}
            to={route}
            shouldScroll={searchForLinkOnPage}
            useRelativePath={useRelativePath}>
            {
                //if
                StringUtils.hasValue(label) && (
                    <span
                        className={StringUtils.joinClassNames(
                            labelCssClassNames
                        )}>
                        {label}
                    </span>
                )
            }

            {
                //if
                StringUtils.hasValue(title) && (
                    <span className="c-publication-sidebar-nav-link__title">
                        {title}
                    </span>
                )
            }

            <TableOfContentsBookmarkIndicator hasBookmark={hasBookmark} />
        </Anchor>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PublicationSidebarNavLink;

// #endregion Export
