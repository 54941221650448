import { DataTestAttributes } from "interfaces/data-test-attributes";
import React, { PropsWithChildren } from "react";
import Anchor from "atoms/anchors/anchor";
import TableOfContentsBookmarkIndicator from "organisms/table-of-contents/table-of-contents-bookmark-indicator";
import StringUtils from "utilities/string-utils";

interface TableOfContentsCollapsePanelLinkProps
    extends Pick<
        DataTestAttributes,
        "dataTestDisplaySequence" | "dataTestExternalId" | "dataTestId"
    > {
    hasBookmark?: boolean;
    index?: number | string;
    isPublic?: boolean;
    onClick?: () => void;
    to: string;
    useRelativePath?: boolean;
}

const TableOfContentsCollapsePanelLink: React.FunctionComponent<
    PropsWithChildren<TableOfContentsCollapsePanelLinkProps>
> = (props) => {
    const {
        children,
        dataTestDisplaySequence,
        dataTestExternalId,
        dataTestId,
        hasBookmark = false,
        onClick,
        index,
        isPublic = false,
        to,
        useRelativePath = false,
    } = props;

    const cssBaseClass = "c-table-of-contents-section__item";
    const cssPublicModifier = "-public";
    const cssClassName = isPublic
        ? StringUtils.joinClassNames([cssBaseClass, cssPublicModifier])
        : cssBaseClass;

    const dataTestProps = {
        "data-test-display-sequence": dataTestDisplaySequence,
        "data-test-external-id": dataTestExternalId,
        "data-test-id": dataTestId,
    };

    if (isPublic) {
        return (
            <li key={index}>
                <div className={cssClassName} {...dataTestProps}>
                    {children}
                    <TableOfContentsBookmarkIndicator
                        hasBookmark={hasBookmark}
                    />
                </div>
            </li>
        );
    }

    return (
        <li key={index} onClick={onClick}>
            <Anchor
                cssClassName={cssClassName}
                dataTestDisplaySequence={dataTestDisplaySequence}
                dataTestExternalId={dataTestExternalId}
                dataTestId={dataTestId}
                to={to}
                useRelativePath={useRelativePath}>
                {children}
                <TableOfContentsBookmarkIndicator hasBookmark={hasBookmark} />
            </Anchor>
        </li>
    );
};

export default TableOfContentsCollapsePanelLink;
