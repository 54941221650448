import logo from "assets/images/nfpa-link-glyph-reversed.png";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import ProfileMenu from "organisms/profile/profile-menu";
import * as React from "react";
import { useRef, useState } from "react";
import { siteMap } from "internal-sitemap";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import SidebarNavigationTooltip from "organisms/sidebar-navigation/sidebar-navigation-tooltip";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { ClassNameConstants } from "constants/classname-constants";
import { CloseDialogEvent } from "molecules/modals/modal";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useModalActions from "utilities/hooks/use-modal-actions";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import SearchModal from "organisms/modals/search-modal/search-modal";
import AllowRoles from "../../molecules/allow-roles/allow-roles";
import { AdminRoleTypes } from "../../utilities/enumerations/role-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SidebarNavigationProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASSNAME = "c-sidebar-navigation";
const { Hover } = ClassNameConstants;
const TOOLTIP_DISTANCE = 20;
const TOOLTIP_SKIDDING = 0;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SidebarNavigation: React.FunctionComponent<SidebarNavigationProps> = (
    props
) => {
    const { routeIsAdmin, routeIsSitNav, routeIsMyLink } = useIsNestedRoute();
    const { t } = useLocalization<CultureResources>();
    const { isOnline } = useNetworkInformation();
    const offlineDisabledClass = !isOnline ? "-disabled" : "";

    const {
        isOpen: isOfflineAlertModalOpen,
        handleClose: handleOfflineAlertModalClose,
        handleOpen: handleFeatureUnavailableModalOpen,
    } = useModalActions();
    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );
    // Used to temporarily turn off tooltips for the search button so that it does not steal focus
    // when navigating via mouse or link
    const [searchTooltipDisabled, setSearchTooltipDisabled] =
        useState<boolean>(false);

    const [showSearch, setShowSearch] = useState<boolean>(false);

    const searchRef = useRef<HTMLButtonElement>(null);
    const sideNavigationRef = useRef<HTMLElement>(null);

    const applyHoverIfActive = (className: string, routeIsActive: boolean) => {
        if (!routeIsActive || showSearch) {
            return className;
        }

        return `${className} ${Hover}`;
    };

    const shouldCloseSearch = (target: EventTarget) =>
        showSearch &&
        target instanceof Element &&
        sideNavigationRef?.current?.contains(target);

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleClickSidebarNavigation = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (shouldCloseSearch(event.target)) {
            setSearchTooltipDisabled(true);
            handleCloseSearch();
        }
    };

    const handleCloseSearch = (event?: CloseDialogEvent) => {
        if (isMouseClickEvent(event) || isClickedLink(event)) {
            setSearchTooltipDisabled(true);
        }

        setShowSearch(false);
    };

    const handleCloseSearchComplete = () => {
        setSearchTooltipDisabled(false);
    };

    const handleShowSearch = () => {
        if (!showSearch) {
            setShowSearch(true);
        }
    };

    const handleLinkClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        if (isOnline) {
            return true;
        }

        e.preventDefault();

        if (isMobile) {
            handleFeatureUnavailableModalOpen();
        }
        return;
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <aside
            className={CSS_BASE_CLASSNAME}
            onClickCapture={handleClickSidebarNavigation}
            ref={sideNavigationRef}>
            <div className={`${CSS_BASE_CLASSNAME}__logo`}>
                <SidebarNavigationTooltip
                    description={t("nav-tooltip-home-description")}
                    offsetInPx={{
                        distance: TOOLTIP_DISTANCE,
                        skidding: TOOLTIP_SKIDDING,
                    }}
                    title={t("nav-tooltip-home-title")}>
                    <Anchor
                        to={siteMap.dashboards.user}
                        ariaLabel={t("nav-tooltip-home-title")}>
                        <img src={logo} alt={t("nav-tooltip-home-title")} />
                    </Anchor>
                </SidebarNavigationTooltip>
            </div>
            <SidebarNavigationTooltip
                description={
                    !isOnline
                        ? t("offline-fallback-description")
                        : t("nav-tooltip-direct-description")
                }
                title={t("nav-tooltip-direct-title")}>
                <Anchor
                    ariaLabel={t("nav-aria-direct-link")}
                    cssClassName={applyHoverIfActive(
                        `${offlineDisabledClass} c-button -icon`,
                        routeIsSitNav
                    )}
                    dataTestId="sitnav-link"
                    to={siteMap.situationalNavigation.dashboard}
                    onClick={handleLinkClick}>
                    <Icon type={Icons.Sitnav} size={IconSizes.Large} />
                    <span className="sr-only">{t("nav-aria-direct-link")}</span>
                </Anchor>
            </SidebarNavigationTooltip>
            <SidebarNavigationTooltip
                description={
                    !isOnline
                        ? t("offline-fallback-description")
                        : t("nav-tooltip-mylink-description")
                }
                title={t("nav-tooltip-mylink-title")}>
                <Anchor
                    ariaLabel={t("nav-aria-mylink-link")}
                    cssClassName={applyHoverIfActive(
                        `${offlineDisabledClass} c-button -icon`,
                        routeIsMyLink
                    )}
                    to={siteMap.myLink.myBookmarks}
                    onClick={handleLinkClick}>
                    <Icon type={Icons.BookmarkOutline} size={IconSizes.Large} />
                    <span className="sr-only">{t("nav-aria-mylink-link")}</span>
                </Anchor>
            </SidebarNavigationTooltip>
            <SidebarNavigationTooltip
                description={t("nav-tooltip-search-description")}
                disabled={searchTooltipDisabled}
                title={t("nav-tooltip-search-title")}>
                <Button
                    accessibleText={t("nav-aria-search-link")}
                    cssClassName={`-icon ${showSearch ? Hover : ""}`}
                    onClick={handleShowSearch}
                    ref={searchRef}
                    style={ButtonStyles.Primary}>
                    <Icon type={Icons.Search} size={IconSizes.Large} />
                </Button>
            </SidebarNavigationTooltip>
            <AllowRoles allowedRoles={AdminRoleTypes}>
                <Anchor
                    ariaLabel="Admin Dashboard"
                    cssClassName={applyHoverIfActive(
                        "c-button -icon",
                        routeIsAdmin
                    )}
                    to={siteMap.admin.dashboard}>
                    <Icon type={Icons.Settings} size={IconSizes.Large} />
                    <span className="sr-only">Go to Admin Dashboard</span>
                </Anchor>
            </AllowRoles>
            <ProfileMenu />
            <SearchModal
                closeDialog={handleCloseSearch}
                isVisible={showSearch}
                onCloseComplete={handleCloseSearchComplete}
            />
            {isOfflineAlertModalOpen && (
                <OfflineAlertModal
                    isOpen={isOfflineAlertModalOpen}
                    handleClose={handleOfflineAlertModalClose}
                />
            )}
        </aside>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const isMouseClickEvent = (event?: any) =>
    event != null &&
    event.clientX !== 0 &&
    event.clientY !== 0 &&
    event.nativeEvent instanceof MouseEvent;

const isClickedLink = (event?: CloseDialogEvent) =>
    event?.currentTarget?.tagName === "A";

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SidebarNavigation;

// #endregion Exports
