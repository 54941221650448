import UserCollectionRecord from "models/view-models/user-collection-record";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import NumberUtils from "utilities/number-utils";
import ServiceHookResult from "utilities/interfaces/service-hook-result";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserCollectionHookResult
    extends ServiceHookResult<UserCollectionRecord | undefined> {
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

export default function useUserCollection(
    userId?: number,
    id?: number
): UserCollectionHookResult {
    const { get } = UserCollectionService.useGetQuery();

    const enabled =
        !NumberUtils.isDefault(userId) && !NumberUtils.isDefault(id);
    const result = get({ userId: userId!, id: id! }, undefined, { enabled });

    return {
        errors: result.error?.listErrorMessages() ?? [],
        loaded: result.isFetched,
        loading: result.isLoading,
        refresh: result.refetch,
        resultObject: result.data?.resultObject,
    };
}

// #endregion Public Functions
