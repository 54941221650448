import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import React from "react";
import { AppNameWithHtml } from "constants/app-name-tm";

interface OnboardingLayoutBannerModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog"> {}

const BASE_CLASS = "c-onboarding-layout-banner-modal";
const HEADER_CLASS = `${BASE_CLASS}__header`;
const CONTENT_CLASS = `${BASE_CLASS}__content`;

const OnboardingLayoutBannerModal: React.FunctionComponent<OnboardingLayoutBannerModalProps> =
    (props: OnboardingLayoutBannerModalProps) => {
        return (
            <Modal
                cssClassName={BASE_CLASS}
                isVisible={props.isVisible}
                closeButtonStyle={ModalCloseButtonStyle.OutsideDialog}
                closeDialog={props.closeDialog}
                label={"NFPA is upgrading to single-sign-on"}
                type={ModalTypes.Base}>
                <div className={HEADER_CLASS}>
                    <Button
                        accessibleText="Close"
                        cssClassName={HEADER_CLASS}
                        onClick={props.closeDialog}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.TertiaryAlt}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>

                <div className={CONTENT_CLASS}>
                    <Paragraph>
                        You will be prompted to reset your password the first
                        time you log in to your subscription due to these
                        changes. Then you can continue using{" "}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: AppNameWithHtml,
                            }}
                        />{" "}
                        exactly as you do today!
                    </Paragraph>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default OnboardingLayoutBannerModal;

// #endregion Export
