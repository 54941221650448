import SectionRecord from "models/view-models/section-record";
import { useCallback, useEffect } from "react";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import SectionService from "utilities/services/sections/section-service";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import NumberUtils from "utilities/number-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseOfflineSectionsOptions {
    enabled?: boolean;
    sectionId?: number;
}

interface UseOfflineSectionsHook extends ServiceHookResult<SectionRecord> {
    refresh: () => void;
}

// #endregion Interfaces

/**
 * Custom hook for lazy-loading a section and all subSections while offline.
 */
export default function useOfflineSections(
    options: UseOfflineSectionsOptions
): UseOfflineSectionsHook {
    const { enabled = true } = options;

    const sectionId = NumberUtils.parseInt(options.sectionId);

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listSectionsApi } = SectionService.useList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { result, setInitial, setError, setLoading, setSuccess } =
        useServiceHookResultState<SectionRecord>(new SectionRecord());

    const { publication } = useCurrentPublication();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Callbacks
    // -----------------------------------------------------------------------------------------

    const load = useCallback(async () => {
        if (sectionId == null || publication?.id == null || !enabled) {
            setInitial();
            return;
        }

        setLoading();

        try {
            const { resultObjects: publicationSections } =
                await listSectionsApi({
                    publicationId: publication.id,
                });

            const section = publicationSections.find(
                (section) => section.id === sectionId
            );

            const subSectionsWithPublications = publicationSections
                .filter((section) => section.parentId === sectionId)
                .map((section: SectionRecord) =>
                    section.with({
                        publication: publication,
                    })
                );

            const sectionWithSubSectionsAndPublication = section?.with({
                subSections: subSectionsWithPublications,
                publication: publication,
            });

            if (
                sectionWithSubSectionsAndPublication == null ||
                sectionWithSubSectionsAndPublication.publication == null
            ) {
                setError(t("sections-include-subsections-not-found"));

                return;
            }

            setSuccess(sectionWithSubSectionsAndPublication);
        } catch (error) {
            ToastManager.error(t("sections-include-subsection-loading-error"));

            setError(error);
        }
    }, [
        enabled,
        listSectionsApi,
        publication,
        sectionId,
        setError,
        setInitial,
        setLoading,
        setSuccess,
    ]);

    const refresh = useCallback(() => load(), [load]);

    // #endregion Callbacks

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        load();
    }, [load]);

    // #endregion Side-Effects

    return {
        ...result,
        refresh,
    };
}
