import TrainingContractUserRoleRecord from "models/view-models/training-contract-user-role-record";
import { useCallback, useState } from "react";
import { t } from "utilities/localization-utils";
import NumberUtils from "utilities/number-utils";
import TrainingContractUserRolesService from "utilities/services/training-contract-user-roles/training-contract-user-roles-service";
import { ToastManager } from "utilities/toast/toast-manager";

const errorMessage = t("errors-actionResource", {
    action: t("loading"),
    resource: t("userRole"),
});

export default function useGetTrianingContractUserRole() {
    const [isLoading, setIsLoading] = useState(false);
    const [trainingContractUserRoleRecord, setTrainingContractUserRoleRecord] =
        useState<TrainingContractUserRoleRecord | null>(null);

    const getTrainingContractUserRole = useCallback(
        async (userId: number | undefined, contractId: number | undefined) => {
            if (
                userId == null ||
                NumberUtils.isDefault(userId) ||
                contractId == null
            ) {
                return;
            }

            setIsLoading(true);

            try {
                const result = await TrainingContractUserRolesService.get(
                    {
                        id: userId,
                        contractId,
                    },
                    // ignore query filter to get the record even if it's epxired and soft deleted
                    { ignoreQueryFilter: true }
                );

                if (result.resultObject === undefined) return;

                setTrainingContractUserRoleRecord(result.resultObject);
            } catch (error) {
                ToastManager.error(errorMessage);
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const hasPreexistingTrainingAccount =
        trainingContractUserRoleRecord != null;

    return {
        getTrainingContractUserRole,
        hasPreexistingTrainingAccount,
        isLoading,
        trainingContractUserRoleRecord,
    };
}
