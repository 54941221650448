import NoResultsBanner from "molecules/banners/no-results-banner";
import PublicationCardStatic from "molecules/cards/publication-card-static";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import NumberUtils from "utilities/number-utils";
import UserPublicationFavoriteService from "utilities/services/user-publication-favorites/user-publication-favorite-service";
import { t } from "utilities/localization-utils";
import useOfflinePublications from "utilities/hooks/domain/publications/use-offline-publications";
import Loader from "atoms/loaders/loader";
import { LoaderStyles } from "atoms/constants/loader-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface DownloadedPublicationsProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-favorite-publications";

// #endregion Constants

const DownloadedPublications: React.FunctionComponent<DownloadedPublicationsProps> =
    (props: DownloadedPublicationsProps) => {
        const { globalState } = useGlobalState();
        const { currentIdentity } = globalState;
        const {
            loaded: loadedOfflinePublications,
            loading: loadingOfflinePublications,
            searchResults: offlinePublications,
        } = useOfflinePublications();
        const userId = currentIdentity?.userId();
        const userRoleId = currentIdentity?.getCurrentUserRole()?.id;
        const listFavoritesEnabled =
            !NumberUtils.isDefault(userId) &&
            !NumberUtils.isDefault(userRoleId);

        const { list: listFavorites } =
            UserPublicationFavoriteService.useListQuery();

        const favoritesResult = listFavorites({ userId: userId! }, undefined, {
            enabled: listFavoritesEnabled,
        });

        const favorites = favoritesResult.data?.resultObjects.filter(
            (e) => e.userRoleId === userRoleId
        );

        const cssClassName = [
            BASE_CLASS_NAME,
            "-offline",
            CollectionUtils.isEmpty(offlinePublications) ? "-empty" : "",
        ].join(" ");

        if (loadingOfflinePublications && !loadedOfflinePublications) {
            return (
                <div className={`${cssClassName}`}>
                    <Loader
                        accessibleText={t("loading")}
                        type={LoaderStyles.LinkGlyphGray}
                    />
                </div>
            );
        }

        if (
            !loadingOfflinePublications &&
            loadedOfflinePublications &&
            CollectionUtils.isEmpty(offlinePublications)
        ) {
            return (
                <div className={cssClassName}>
                    <NoResultsBanner
                        className={"-secondary"}
                        subtitle={t("offline-dashboard-empty-banner-subtitle")}
                        title={t("offline-books-empty-banner-title")}
                    />
                </div>
            );
        }

        return (
            <div className={cssClassName}>
                <div className={`${BASE_CLASS_NAME}__cards`}>
                    {offlinePublications.map((op) => (
                        <PublicationCardStatic
                            publication={op}
                            favorites={favorites}
                        />
                    ))}
                </div>
            </div>
        );
    };

export default DownloadedPublications;
