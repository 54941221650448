import { InputTypes } from "atoms/constants/input-types";
import { InputProperties } from "interfaces/forms/input-properties";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface HiddenFieldProps extends Pick<InputProperties, "value"> {
    /**
     * Id value for the id attribute.
     */
    id?: string;

    /**
     * Name value for the name attribute.
     */
    name: string;

    /**
     * Unique identifier used select the underlying <input> for functional/e2e testing
     */
    testId?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const HiddenField: React.FunctionComponent<HiddenFieldProps> = (
    props: HiddenFieldProps
) => {
    const { id, name, testId, value } = props;

    return (
        <input
            data-test-id={testId}
            id={id}
            name={name}
            type={InputTypes.Hidden}
            value={value}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default HiddenField;

// #endregion Export
