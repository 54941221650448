import { Map, Record } from "immutable";

export interface OfflineBookViewAnalyticsState {
    paths: Map<string, number>;
}

const defaultValues: OfflineBookViewAnalyticsState = {
    paths: Map(),
};

export default class OfflineBookViewAnalyticsRecord
    extends Record(defaultValues)
    implements OfflineBookViewAnalyticsState
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: OfflineBookViewAnalyticsState) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (typeof params.paths !== "function") {
            params.paths = Map(params.paths);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Adds the specified number of seconds to the total time spent for the path provided.
     */
    addSecondsViewedForPath(
        path: string,
        secondsViewed: number
    ): OfflineBookViewAnalyticsRecord {
        if (!this.paths.has(path)) {
            return this.with({
                paths: this.paths.set(path, secondsViewed),
            });
        }

        return this.with({
            paths: this.paths.update(path, (seconds: number) => {
                return seconds + secondsViewed;
            }),
        });
    }

    /**
     * Helper method to determine if any path has been viewed for more than 20 seconds
     */
    hasViewedAnOfflineBookForAtLeast20Seconds(): boolean {
        return this.paths.some((v) => v >= 20);
    }

    /**
     * Merges new values into the record and returns a new instance.
     */
    public with(
        values: Partial<OfflineBookViewAnalyticsState>
    ): OfflineBookViewAnalyticsRecord {
        return new OfflineBookViewAnalyticsRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
