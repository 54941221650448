import Button from "atoms/buttons/button";
import Loader from "atoms/loaders/loader";
import UserRecord from "models/view-models/user-record";
import UserRoleRecord from "models/view-models/user-role-record";
import MenuButton from "molecules/menu-button/menu-button";
import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserManagementTableProps {
    loading: boolean;
    onEditUserClick: (user: UserRecord) => void;
    onRemoveUserClick: (user: UserRecord) => void;
    users: Array<UserRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-user-management-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserManagementTable: React.FC<UserManagementTableProps> = (
    props: UserManagementTableProps
) => {
    const { loading, users } = props;

    if (loading) {
        return <Loader accessibleText={"Loading users..."} />;
    }

    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th className="-roles-col">Roles</th>
                    <th className="-collapse">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user: UserRecord) => (
                    <tr key={user.id}>
                        <td>{user.getFirstAndLastName()}</td>
                        <td>{user.email}</td>
                        <td className="-roles-col">
                            {user.userRoles!.map((ur: UserRoleRecord) => (
                                <div
                                    className={`${CSS_CLASS_NAME}__role-chip`}
                                    key={ur.id}>
                                    {ur.role!.getName()}
                                </div>
                            ))}
                        </td>
                        <td className="-collapse">
                            <MenuButton buttonAccessibleText={"Options"}>
                                <Button
                                    accessibleText={"Edit Roles"}
                                    onClick={() => props.onEditUserClick(user)}>
                                    Edit Roles
                                </Button>
                                <Button
                                    accessibleText={"Remove Admin"}
                                    onClick={() =>
                                        props.onRemoveUserClick(user)
                                    }>
                                    Remove Admin
                                </Button>
                            </MenuButton>
                        </td>
                    </tr>
                ))}
                {CollectionUtils.isEmpty(users) && (
                    <tr>
                        <td colSpan={4} className="-no-results-row">
                            No Results
                        </td>
                    </tr>
                )}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserManagementTable;

// #endregion Exports
