import SolutionRelationshipRecord from "models/view-models/situational-navigation/solutions/solution-relationship-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SolutionRelationshipPathParams {
    solutionId: number;
}

export interface SolutionRelationshipQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "situational-navigation/solutions/:solutionId/relationships";
const key = "situational-navigation-solutions-relationships";
const resourceType = SolutionRelationshipRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SolutionRelationshipService = {
    list: ServiceFactory.nestedList<
        SolutionRelationshipRecord,
        SolutionRelationshipPathParams,
        SolutionRelationshipQueryParams
    >(resourceType, baseEndpoint),

    useList: ServiceHookFactory.useNestedList<
        SolutionRelationshipRecord,
        SolutionRelationshipPathParams,
        SolutionRelationshipQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        SolutionRelationshipRecord,
        SolutionRelationshipPathParams,
        SolutionRelationshipQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionRelationshipService;

// #endregion Exports
