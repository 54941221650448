import PdfTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import ImageViewerFooter from "molecules/footer/image-viewer-footer";
import TableOfContentsImageViewer from "organisms/table-of-contents/table-of-contents-image-viewer";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useZoom from "utilities/hooks/use-zoom";
import ImagePage from "models/interfaces/image-page";
import blankImage from "assets/images/publication-page.jpg";

const CSS_BASE_CLASS = "c-image-viewer";
const CSS_BASE_MODIFIER = "-free-access";
const CSS_CONTENT_CLASS = `${CSS_BASE_CLASS}__content`;
const CSS_IMAGE_WRAPPER_CLASS = `${CSS_BASE_CLASS}__image-wrapper`;
const CSS_IMAGE_CLASS = `${CSS_BASE_CLASS}__image`;

interface ImageViewerProps {
    tableOfContents: PdfTableOfContentsEntry[];
    pages: ImagePage[];
    currentPageNumber: number;
    activeId: number | undefined;
    setCurrentPageNumber: (value: number) => void;
    setActiveId: (value: number | undefined) => void;
    onFooterPageChange: (value: number) => void;
    banner?: JSX.Element;
}

const ImageViewer = ({
    tableOfContents,
    pages,
    currentPageNumber,
    setCurrentPageNumber,
    activeId,
    setActiveId,
    onFooterPageChange,
    banner,
}: ImageViewerProps) => {
    const imageWrapperRef = useRef<HTMLDivElement>(null);
    const isTabletOrSmaller = useBreakpoint(
        Breakpoints.LargeTablet,
        BreakpointComparer.MaxWidth
    );
    const currentPage = pages.find((e) => e.pageNumber === currentPageNumber);
    const { imageStyle, onZoomIn, onZoomOut } = useZoom(imageWrapperRef);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        // Build an image to determine width/height attributes for empty <img> element
        const image = new Image();
        image.src = currentPage?.imageUri || "";
        image.onload = () => {
            setDimensions({ width: image.width, height: image.height });
        };

        // Set background image property to be used in image-viewer.scss
        document.documentElement.style.setProperty(
            "--nfpa-free-access-uri",
            `url("${currentPage?.imageUri}")`
        );
    }, [currentPage]);

    return (
        <div className={[CSS_BASE_CLASS, CSS_BASE_MODIFIER].join(" ")}>
            {tableOfContents.length > 0 && (
                <TableOfContentsImageViewer
                    currentPageNumber={currentPageNumber}
                    activeId={activeId}
                    tableOfContentsEntries={tableOfContents ?? []}
                    setCurrentPageNumber={setCurrentPageNumber}
                    setActiveId={setActiveId}
                    pageEntries={pages}
                />
            )}
            <div className={CSS_CONTENT_CLASS}>
                <div className={CSS_IMAGE_WRAPPER_CLASS} ref={imageWrapperRef}>
                    <img
                        className={`${CSS_IMAGE_CLASS} -overlay ${
                            isTabletOrSmaller ? "hide-menu" : ""
                        }`}
                        src={blankImage}
                        alt=""
                        draggable={false}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    {currentPage?.imageUri != null && (
                        <img
                            className={`${CSS_IMAGE_CLASS} -background ${
                                isTabletOrSmaller ? "hide-menu" : ""
                            }`}
                            height={dimensions.height}
                            width={dimensions.width}
                            alt=""
                            draggable={false}
                            onContextMenu={(e) => e.preventDefault()}
                            style={imageStyle}
                        />
                    )}
                </div>
                {banner}
                <ImageViewerFooter
                    currentPageNumber={currentPageNumber}
                    setCurrentPageNumber={setCurrentPageNumber}
                    totalPages={pages.length}
                    onZoomIn={onZoomIn}
                    onZoomOut={onZoomOut}
                    onFooterPageChange={onFooterPageChange}
                />
            </div>
        </div>
    );
};

export default ImageViewer;
