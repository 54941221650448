import SolutionSectionRecord from "models/view-models/situational-navigation/solutions/solution-section-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSolutionSectionPathParams {
    solutionId: number;
}

export interface AdminSolutionSectionQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "admin/situational-navigation/solutions/:solutionId/sections";
const resourceType = SolutionSectionRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSolutionSectionService = {
    list: ServiceFactory.nestedList<
        SolutionSectionRecord,
        AdminSolutionSectionPathParams,
        AdminSolutionSectionQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SolutionSectionRecord,
        AdminSolutionSectionPathParams,
        AdminSolutionSectionQueryParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.bulkUpdate<
        SolutionSectionRecord,
        AdminSolutionSectionPathParams
    >(resourceType, baseEndpoint),
    useUpdate: ServiceHookFactory.useBulkUpdate<
        SolutionSectionRecord,
        AdminSolutionSectionPathParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSolutionSectionService;

// #endregion Exports
