import RichTextEditorFooter from "molecules/rich-text/rich-text-editor-footer";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import Editor, { EditorValue, ToolbarConfig } from "react-rte";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import DefaultEditorToolbarConfig from "utilities/types/default-rich-text-toolbar-config";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextEditorDeprecatedProps {
    disabled?: boolean;

    /**
     * Error to display underneath the input field.
     */
    errors?: Array<string>;
    label?: string | React.ReactNode;

    /**
     * Max length of (raw) characters the user can input in the field. Adds a character counter
     * before the RTE so the user can see what the current count and limit are.
     */
    maxLength?: number;

    /**
     * Callback to handle a changed input value. The formatted value will be the first param of the
     * callback, while the plain text or raw value will be the second. This is useful if the parent
     * component needs to do length checking on the raw character count for validation.
     */
    onChange: (newValue: string, rawValue: string) => void;
    placeholder?: string;
    /**
     * Configure the react-rte toolbar. This gets merged with the default config.
     * @default the default config from react-rte package
     * @see {ToolbarConfig}
     * @link https://github.com/sstur/react-rte#toolbar-customization
     */
    toolbarConfig?: Partial<ToolbarConfig>;
    value: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-rich-text-editor";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextEditorDeprecated: React.FC<RichTextEditorDeprecatedProps> = (
    props: RichTextEditorDeprecatedProps
) => {
    const { disabled, errors, label, maxLength, onChange, placeholder, value } =
        props;

    const [internalValue, setInternalValue] = useState(
        Editor.createValueFromString(value, "html")
    );

    const synchronizeValue = useCallback(() => {
        setInternalValue((prevState: EditorValue) =>
            prevState.toString("html") !== props.value
                ? Editor.createValueFromString(props.value, "html")
                : prevState
        );
    }, [props.value]);

    useEffect(() => synchronizeValue(), [synchronizeValue]);

    const toolbarConfig: ToolbarConfig = Object.assign(
        {},
        DefaultEditorToolbarConfig,
        props.toolbarConfig ?? {}
    );

    const formFieldClassNames = [BASE_CLASS_NAME, "c-form-field"];
    const applyErrorState =
        CollectionUtils.hasValues(errors) ||
        (maxLength != null && getRawCharacterCount(internalValue) > maxLength);

    if (applyErrorState) {
        formFieldClassNames.push("-error");
    }

    const rootClassNames = [BASE_CLASS_NAME];
    if (CollectionUtils.hasValues(errors) || maxLength != null) {
        rootClassNames.push("-with-footer");
    }

    return (
        <div className={rootClassNames.join(" ")}>
            <div className={StringUtils.joinClassNames(formFieldClassNames)}>
                {label != null && (
                    <label className={`${BASE_CLASS_NAME}__label`}>
                        {label}
                    </label>
                )}
                <Editor
                    disabled={disabled}
                    value={internalValue}
                    onChange={(value: EditorValue) =>
                        handleChange(onChange, setInternalValue, value)
                    }
                    placeholder={placeholder}
                    toolbarConfig={toolbarConfig}
                />
            </div>
            <RichTextEditorFooter
                baseClassName={BASE_CLASS_NAME}
                currentLength={getRawCharacterCount(internalValue)}
                errors={errors}
                maxLength={maxLength}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getPlainText = (value?: EditorValue): string => {
    if (value == null) {
        return "";
    }

    return value.getEditorState().getCurrentContent().getPlainText();
};

const getRawCharacterCount = (value?: EditorValue): number =>
    getPlainText(value).length;

const handleChange = (
    onChange: (newValue: string, rawValue: string) => void,
    setInternalValue: Dispatch<SetStateAction<EditorValue>>,
    value: EditorValue
) => {
    setInternalValue(value);
    onChange(value.toString("html"), getPlainText(value));
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextEditorDeprecated;

// #endregion Exports
