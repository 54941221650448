import UserCollection from "models/interfaces/user-collection";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import { t } from "utilities/localization-utils";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";

export default class UserCollectionValidator extends ObjectValidator<UserCollection> {
    validate(
        userCollection: UserCollection
    ): ObjectValidationResult<UserCollection> {
        const result: ObjectValidationResult<UserCollection> = {};

        if (StringUtils.isEmpty(userCollection.name)) {
            result.name = [
                t("propertyIsRequired", { name: t("collectionTitle") }),
            ];
        }

        if (
            userCollection.shareType === UserCollectionShareType.WorkGroup &&
            userCollection.workGroups.length === 0
        ) {
            result.workGroups = [
                t("propertyIsRequired", { name: t("workgroup") }),
            ];
        }

        return result;
    }
}
