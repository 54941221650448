import type { SelectOption } from "atoms/forms/select";
import { List, Record } from "immutable";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import type Category from "models/interfaces/situational-navigation/categories/category";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SolutionCategoryRecord from "models/view-models/situational-navigation/solutions/solution-category-record";
import { CollectionUtils } from "utilities/collection-utils";
import CategoryCollection from "utilities/interfaces/categories/category-collection";
import NumberUtils from "utilities/number-utils";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: CategoryCollection =
    RecordUtils.defaultValuesFactory<CategoryCollection>({
        equipment: List<CategoryRecord>(),
        occupancies: List<CategoryRecord>(),
        spaces: List<CategoryRecord>(),
        systems: List<CategoryRecord>(),
    });

const categoryToOption = (cat: CategoryRecord): SelectOption => ({
    label: cat.title,
    value: cat.id!.toString(),
});

export default class CategoryCollectionRecord
    extends Record(defaultValues)
    implements CategoryCollection
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: CategoryCollection) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params instanceof CategoryCollectionRecord) {
            params = params.toJS();
        }

        // ensure members are the Immutable Record classes
        params.equipment = List(
            params.equipment.map((e: Category) => new CategoryRecord(e))
        );
        params.occupancies = List(
            params.occupancies.map((e: Category) => new CategoryRecord(e))
        );
        params.spaces = List(
            params.spaces.map((e: Category) => new CategoryRecord(e))
        );
        params.systems = List(
            params.systems.map((e: Category) => new CategoryRecord(e))
        );

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Adds/updates the category to the appropriate list based on type.
     * If category.id == null, adds to the list, else replaces the existing item in the list.
     * @param category
     */
    public addOrUpdate(category: CategoryRecord): CategoryCollectionRecord {
        const listProperty = CategoryUtils.toCategoryCollectionProperty(
            category.type
        );
        const list = this[listProperty];

        if (category.id == null) {
            return this.with({
                [listProperty]: list.push(category),
            });
        }

        const index = this[listProperty].findIndex(
            (c: CategoryRecord) => c.id === category.id
        );

        if (index < 0) {
            return this.with({
                [listProperty]: list.push(category),
            });
        }

        return this.with({
            [listProperty]: List(
                CollectionUtils.replaceElementAt(
                    list.toArray(),
                    index,
                    category
                )
            ),
        });
    }

    /**
     * Returns true if at least one Category causes matcherFn to return true.
     * Returns false otherwise.
     * @param matcherFn function to indicate whether a category is a match
     */
    public contains(matcherFn: (c: CategoryRecord) => boolean): boolean {
        return this.toList().some((c: CategoryRecord) => matcherFn(c));
    }

    /**
     * Returns a new CategoryCollectionRecord containing all the same elements
     * but with all elements of specified type removed.
     * @param type exclude all elements of this type
     */
    public excludingType(type: CategoryType): CategoryCollectionRecord {
        return this.filter((c: CategoryRecord) => c.type !== type);
    }

    /**
     * Utility function to filter the entire collection at once.
     * @param filterFn return true if element should be included, false otherwise
     */
    public filter(
        filterFn: (c: CategoryRecord) => boolean
    ): CategoryCollectionRecord {
        return CategoryCollectionRecord.fromList(
            this.toList().filter(filterFn)
        );
    }

    /**
     * Utility method to quickly filter by type
     * @param type
     */
    public filterByType(type: CategoryType): CategoryCollectionRecord {
        switch (type) {
            case CategoryType.Equipment:
                return CategoryCollectionRecord.fromList(this.equipment);
            case CategoryType.Occupancies:
                return CategoryCollectionRecord.fromList(this.occupancies);
            case CategoryType.Spaces:
                return CategoryCollectionRecord.fromList(this.spaces);
            case CategoryType.Systems:
                return CategoryCollectionRecord.fromList(this.systems);
        }
    }

    /**
     * Construct a new CategoryCollectionRecord from a flat Array<CategoryRecord>
     * @param categories {Array<CategoryRecord>}
     */
    public static fromArray(
        categories?: Array<CategoryRecord>
    ): CategoryCollectionRecord {
        return CategoryCollectionRecord.fromList(
            List.of(...(categories ?? []))
        );
    }

    /**
     * Construct a new CategoryCollectionRecord from a flat List<CategoryRecord>
     * @param categories {List<CategoryRecord>}
     */
    public static fromList(
        categories?: List<CategoryRecord>
    ): CategoryCollectionRecord {
        return new CategoryCollectionRecord({
            equipment: (categories ?? List<CategoryRecord>()).filter(
                (c: CategoryRecord) => c.type === CategoryType.Equipment
            ),
            occupancies: (categories ?? List<CategoryRecord>()).filter(
                (c: CategoryRecord) => c.type === CategoryType.Occupancies
            ),
            spaces: (categories ?? List<CategoryRecord>()).filter(
                (c: CategoryRecord) => c.type === CategoryType.Spaces
            ),
            systems: (categories ?? List<CategoryRecord>()).filter(
                (c: CategoryRecord) => c.type === CategoryType.Systems
            ),
        });
    }

    public findById(
        id: number | string | undefined
    ): CategoryRecord | undefined {
        id = NumberUtils.parseInt(id);
        if (id == null) {
            return undefined;
        }

        return this.toList().find((c) => c.id === id);
    }

    public hasValues(): boolean {
        return !this.isEmpty();
    }

    public isEmpty(): boolean {
        return this.size === 0;
    }

    /**
     * Map this instance to a new CategoryCollectionRecord
     * containing only categories that have a corresponding
     * option in the given options List or Array
     * @param options { Array<SelectOption> | List<SelectOption> }
     */
    public mapFromSelectOptions(
        options: Array<SelectOption> | List<SelectOption>
    ): CategoryCollectionRecord {
        return CategoryCollectionRecord.fromList(
            List(
                this.toList().filter((c: CategoryRecord) =>
                    options.some(
                        (o: SelectOption) => (c.id || -1).toString() === o.value
                    )
                )
            )
        );
    }

    /**
     * Map this instance to a new CategoryCollectionRecord
     * containing only categories with a corresponding
     * SolutionCategory in the given
     * solutionCategories Array or List
     * @param solutionCategories { Array<SolutionCategoryRecord> | List<SolutionCategoryRecord> }
     */
    public mapFromSolutionCategories(
        solutionCategories:
            | Array<SolutionCategoryRecord>
            | List<SolutionCategoryRecord>
    ): CategoryCollectionRecord {
        const categories = this.toList().filter(
            (c: CategoryRecord) =>
                solutionCategories.find(
                    (s: SolutionCategoryRecord) => c.id === s.categoryId
                ) != null
        );
        return CategoryCollectionRecord.fromList(categories);
    }

    /**
     * Map to a flat Array<T> using mapperFn
     * @param mapperFn take each CategoryRecord and return desired property
     * @param type filter by type
     */
    public mapToArray<T>(
        mapperFn: (record: CategoryRecord) => T,
        type?: CategoryType
    ): Array<T> {
        return this.toArray(type).map(mapperFn);
    }

    /**
     * Map to a flat List<T> using mapperFn
     * @param mapperFn take each CategoryRecord and return desired property
     * @param type filter by type
     */
    public mapToList<T>(
        mapperFn: (record: CategoryRecord) => T,
        type?: CategoryType
    ): List<T> {
        return List(this.toList(type).map(mapperFn));
    }

    /**
     * Convert this CategoryCollectionRecord into a flat Array<CategoryRecord>
     * @param type filter by type
     */
    public toArray(type?: CategoryType): Array<CategoryRecord> {
        return this.toList(type).toArray();
    }

    /**
     * Convert this CategoryCollectionRecord into a flat List<CategoryRecord>
     * @param type filter by type
     */
    public toList(type?: CategoryType): List<CategoryRecord> {
        if (type != null) {
            switch (type) {
                case CategoryType.Equipment:
                    return this.equipment;
                case CategoryType.Occupancies:
                    return this.occupancies;
                case CategoryType.Spaces:
                    return this.spaces;
                case CategoryType.Systems:
                    return this.systems;
            }
        }
        return List(
            this.equipment
                .concat(this.occupancies)
                .concat(this.spaces)
                .concat(this.systems)
        );
    }

    /**
     * Convert to a List<SelectOption>, optionally for a single CategoryType
     * @param categoryType {CategoryType | undefined}
     */
    public toSelectOptions(categoryType?: CategoryType): List<SelectOption> {
        switch (categoryType) {
            case CategoryType.Equipment:
                return List(this.equipment.map(categoryToOption));
            case CategoryType.Occupancies:
                return List(this.occupancies.map(categoryToOption));
            case CategoryType.Spaces:
                return List(this.spaces.map(categoryToOption));
            case CategoryType.Systems:
                return List(this.systems.map(categoryToOption));
            default:
                return List(this.toList().map(categoryToOption));
        }
    }

    /**
     * Convert to a List<string> representing only the values of the SelectOptions,
     * optionally for a single CategoryType
     * @param categoryType {CategoryType | undefined}
     */
    public toSelectOptionValues(categoryType?: CategoryType): List<string> {
        return List(
            this.toSelectOptions(categoryType).map((s: SelectOption) => s.value)
        );
    }

    public get size(): number {
        return (
            this.equipment.size +
            this.occupancies.size +
            this.spaces.size +
            this.systems.size
        );
    }

    /**
     * Return the intersection of the two collections.
     * Intersection is the set of all elements contained by both collections.
     * @param other
     */
    public intersect(
        other: CategoryCollectionRecord
    ): CategoryCollectionRecord {
        return this.filter((c: CategoryRecord) =>
            other.contains((cOther: CategoryRecord) => c.id === cOther.id)
        );
    }

    /**
     * Create a new CategoryCollectionRecord by updating values specified
     * by a Partial<CategoryCollection>
     * @param values {Partial<CategoryCollection>}
     */
    public with(values: Partial<CategoryCollection>): CategoryCollectionRecord {
        return new CategoryCollectionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
