import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface NotificationDotIconProps {
    pulse?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CSS_CLASS = "c-notification-dot-icon";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const NotificationDotIcon: React.FC<NotificationDotIconProps> = (
    props: NotificationDotIconProps
) => {
    const cssClassNames = [BASE_CSS_CLASS];
    if (props.pulse) {
        cssClassNames.push("-pulse");
    }

    return <div className={cssClassNames.join(" ")} />;
};

// #endregion Component
export default NotificationDotIcon;
