import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import { ToastContent, TypeOptions } from "react-toastify";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DATA_TEST_ID = "toast";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type ToastTypes = TypeOptions;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Toast = (
    icon: Icons,
    content: string | ToastContent,
    toastType: ToastTypes
): ToastContent => (
    <div
        className="c-toast-content"
        data-test-id={DATA_TEST_ID}
        data-toast-type={toastType}>
        <div className="c-toast-content__icon-container">
            <Icon
                cssClassName="c-toast-content__icon-container__icon"
                size={IconSizes.Large}
                type={icon}
            />
        </div>
        <div className="c-toast-content__body">{content}</div>
    </div>
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export class ToastTemplates {
    static error(content: string | ToastContent): ToastContent {
        return Toast(Icons.Warning, content, "error");
    }

    static info(content: string | ToastContent): ToastContent {
        return Toast(Icons.Information, content, "info");
    }

    static progress(content: string | ToastContent, icon: Icons): ToastContent {
        return Toast(icon, content, "info");
    }

    static success(content: string | ToastContent): ToastContent {
        return Toast(Icons.CheckmarkOvalFilled, content, "success");
    }

    static warning(content: string | ToastContent): ToastContent {
        return Toast(Icons.Warning, content, "warning");
    }
}

// #endregion Exports
