import * as React from "react";

export interface CommitteesMemberNameProps {
    children?: JSX.Element[];
}

const CommitteesMemberName = (props: CommitteesMemberNameProps) => (
    <span className="c-committee-member__name">{props.children}</span>
);

export default CommitteesMemberName;
