import * as React from "react";
import OnboardingLayout from "./onboarding-layout";
import { NestedRoutes } from "utilities/routing/nested-route";

const PublicPublicationsLayout: React.FC<any> = (props: any) => {
    return (
        <OnboardingLayout>
            <NestedRoutes routes={props.routes} />
        </OnboardingLayout>
    );
};

export default PublicPublicationsLayout;
