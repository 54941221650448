import FreeAccessPublication from "models/interfaces/free-access-publication";
import { Record as ImmutableRecord } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import FreeAccessPublicationPageRecord from "./free-access-publication-page-record";
import FreeAccessPublicationTableOfContentsRecord from "./free-access-publication-table-of-contents-entry-record";
import { PdfPublication } from "organisms/admin/publications/pdf-publication-import-form";

const defaultValues: FreeAccessPublication = {
    id: undefined,
    isPublished: undefined,
    title: undefined,
    code: undefined,
    edition: undefined,
    totalPages: undefined,
    freeAccessPublicationPages: undefined,
    freeAccessPublicationTableOfContents: undefined,
    contentSourceFileId: undefined,
    usePdfBookmarkTitles: undefined,
};

export default class FreeAccessPublicationRecord
    extends ImmutableRecord(defaultValues)
    implements FreeAccessPublication
{
    constructor(params?: FreeAccessPublication) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.freeAccessPublicationPages != null) {
            params.freeAccessPublicationPages = RecordUtils.ensureRecords(
                params.freeAccessPublicationPages,
                FreeAccessPublicationPageRecord
            );
        }

        if (params.freeAccessPublicationTableOfContents != null) {
            params.freeAccessPublicationTableOfContents =
                RecordUtils.ensureRecords(
                    params.freeAccessPublicationTableOfContents,
                    FreeAccessPublicationTableOfContentsRecord
                );
        }

        super(params);
    }

    public static fromPdfPublication(publication: PdfPublication) {
        return new FreeAccessPublicationRecord({
            ...publication,
        });
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Publication>} values
     */
    public with(
        values: Partial<FreeAccessPublication>
    ): FreeAccessPublicationRecord {
        return new FreeAccessPublicationRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
