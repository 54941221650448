import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useState } from "react";

const useRecaptcha = () => {
    const { globalState } = useGlobalState();
    const [timeStamp, setTimestamp] = useState<string>("");
    const [captchaComplete, setCaptchaComplete] = useState<boolean>(false);
    const [showCaptchaErrors, setShowCaptchaErrors] = useState(false);
    const keyName = globalState?.getSystemSettings()?.reCaptchaKeyName;
    const orgId = globalState?.getSystemSettings()?.reCaptchaOrgId;
    const siteKey = globalState?.getSystemSettings()?.reCaptchaSiteKey ?? "";
    const culture = globalState.getPreferredOrCurrentCulture();
    const captchaSettings = {
        keyname: keyName,
        fallback: "true",
        orgId: orgId,
        ts: timeStamp,
    };
    const handleRecaptchaChange = () => {
        setShowCaptchaErrors(false);
        setTimestamp(new Date().getTime().toString());
        setCaptchaComplete(true);
    };

    return {
        siteKey,
        culture,
        captchaSettings,
        captchaComplete,
        handleRecaptchaChange,
        showCaptchaErrors,
        setShowCaptchaErrors,
    };
};

export default useRecaptcha;
