import React from "react";
import { FallbackProps } from "react-error-boundary";
import AlertNotice from "molecules/banners/alert-notice";
import AlertLevels from "constants/alert-levels";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import ErrorUtils from "utilities/error-utils";
import OfflineFallback from "molecules/errors/offline-fallback";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ErrorFallbackProps extends FallbackProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DATA_TEST_ID = "error-fallback";
export const ErrorFallbackClassName = "c-error-fallback";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ErrorFallback: React.FunctionComponent<ErrorFallbackProps> = (
    props: ErrorFallbackProps
) => {
    const { error, resetErrorBoundary } = props;
    const { isOnline } = useNetworkInformation();
    const { t } = useLocalization();
    if (ErrorUtils.isNetworkError(error) && !isOnline) {
        return <OfflineFallback {...props} />;
    }

    const title = t("oopsErrorOccurred");
    const { message: description } = error;
    const buttonText = t("tryAgain");
    return (
        <div className={ErrorFallbackClassName}>
            <AlertNotice
                buttonText={buttonText}
                dataTestId={DATA_TEST_ID}
                description={description}
                level={AlertLevels.Error}
                onClick={resetErrorBoundary}
                title={title}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default ErrorFallback;

// #endregion Export
