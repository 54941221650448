import { useCallback, useState } from "react";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { Constructor } from "utilities/types/constructor";
import usePageErrors from "utilities/hooks/use-page-errors";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

type LoadRecordCallback<T> = (
    fetchRecordCallback: () => Promise<T | undefined>
) => Promise<void>;

export interface LoadRecordResult<T> extends ServiceHookResult<T> {
    loadRecord: LoadRecordCallback<T>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

function useLoadRecord<TRecord>(
    record: Constructor<TRecord>
): LoadRecordResult<TRecord> {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [resultObject, setResultObject] = useState<TRecord>(new record());
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    // #endregion Hooks

    const loadRecord = useCallback(
        async (fetchRecordCallback: () => Promise<TRecord | undefined>) => {
            setLoading(true);
            setLoaded(false);
            resetPageErrors();

            try {
                const record = await fetchRecordCallback();

                if (record == null) {
                    handlePageLoadError("Record not found");
                    setLoading(false);
                    return;
                }

                setResultObject(record);
                setLoading(false);
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        },
        [handlePageLoadError, resetPageErrors]
    );

    return {
        resultObject: resultObject,
        loading,
        loaded,
        loadRecord,
        errors,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useLoadRecord;

// #endregion Exports
