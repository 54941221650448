import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { SupportConstants } from "constants/phone-number-constants";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import { siteMap } from "internal-sitemap";
import { MetaTagTypes } from "models/interfaces/header-data";
import CheckboxFormField from "molecules/form-fields/checkbox-form-field";
import React, { useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Redirect, useHistory } from "react-router-dom";
import OnboardingLayout from "templates/onboarding-layout";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { useUpdateUser } from "utilities/hooks/domain/users/use-update-user";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useUnauthorizedResult from "utilities/hooks/use-unauthorized-result";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";

const BASE_CLASS_NAME = "c-terms-and-conditions-page";
const contentClassName = `${BASE_CLASS_NAME}__content`;

const TermsAndConditionsPageV2 = () => {
    const { globalState } = useGlobalState();
    const { currentIdentity, systemSettings, systemSettingsLoaded } =
        globalState;

    const { useEulaAcceptedVersion, useMarkdownForTermsAndConditions } =
        useFeatureFlags();
    const history = useHistory();

    const loaderText = t("loadingItem", {
        item: t("termsAndConditions-documentTitle"),
    });

    // Clean up the unauthorizedResult from GlobalState if found/relevant to this page
    useUnauthorizedResult(UserConfigurationErrorKeys.ERROR_EULA_NOT_ACCEPTED);

    const headerData = useMemo(
        () => ({
            title: t("termsAndConditions-pageTitle"),
            metaTags: [
                {
                    name: MetaTagTypes.Title,
                    content: t("termsAndConditions-meta-title"),
                },
            ],
        }),
        []
    );
    useHeaderData(headerData);

    // Flag to determine whether the user has checked the 'I accept ...' box.
    const [hasAccepted, setHasAccepted] = useState<boolean>(false);

    // should but null?
    // Error message to display underneath the checkbox for validation.
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    );

    const { loading, updateUser } = useUpdateUser();

    const handleAcceptClick = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        // Swallow the event until this logic to update the UserRole runs
        event.preventDefault();
        event.nativeEvent.returnValue = false; // Something weird with IE

        if (!hasAccepted) {
            setErrorMessage(t("termsAndConditions-mustAgreeToContinue"));
            return;
        }

        const user = currentIdentity?.user;

        const updatedUser = user?.with({
            eulaAccepted: true,
            eulaVersionAccepted: termsAndConditionsVersion,
        });

        updateUser(updatedUser).then((user) => {
            if (user?.eulaAccepted) {
                history.push(siteMap.dashboards.user);
            }

            // TODO: what if !eulaAccepted
        });
    };

    const handleCheckboxChange = () => {
        setHasAccepted(!hasAccepted);
        setErrorMessage(undefined);
    };

    if (currentIdentity?.user == null) return null;

    const errorClass = StringUtils.hasValue(errorMessage) ? "errors" : "";

    const renderTermsAsHtml =
        systemSettingsLoaded &&
        !useMarkdownForTermsAndConditions &&
        systemSettings?.hasTermsAndConditions();
    const renderTermsAsMarkdown =
        systemSettingsLoaded &&
        useMarkdownForTermsAndConditions &&
        systemSettings?.hasTermsAndConditions();

    const termsAndConditions =
        systemSettings?.getTermsAndConditionsContent() ?? "";

    const termsAndConditionsVersion =
        systemSettings?.termsAndConditions?.version ?? "";

    if (
        (useEulaAcceptedVersion &&
            currentIdentity.user.eulaVersionAccepted ===
                termsAndConditionsVersion) ||
        (!useEulaAcceptedVersion && currentIdentity.user.eulaAccepted)
    ) {
        return <Redirect to={siteMap.dashboards.user} />;
    }
    // TODO: use onboarding layout v2?
    return (
        <OnboardingLayout>
            <div className={BASE_CLASS_NAME}>
                <div className={`${BASE_CLASS_NAME}_container`}>
                    <div className={`${BASE_CLASS_NAME}__inner-container`}>
                        <div className={contentClassName}>
                            <Heading
                                cssClassName={`${contentClassName}__heading`}
                                priority={HeadingPriority.Four}>
                                {t("termsAndConditions-documentTitle")}
                            </Heading>

                            <div className={`${contentClassName}__terms`}>
                                {loading && (
                                    <Loader
                                        accessibleText={loaderText}
                                        type={LoaderStyles.LinkGlyphGray}
                                    />
                                )}
                                {renderTermsAsHtml && (
                                    <Paragraph
                                        dangerouslySetInnerHTML={{
                                            __html: termsAndConditions,
                                        }}
                                        size={ParagraphSizes.Small}
                                    />
                                )}
                                {renderTermsAsMarkdown && (
                                    <ReactMarkdown>
                                        {termsAndConditions}
                                    </ReactMarkdown>
                                )}
                            </div>
                            <div
                                className={`${contentClassName}__action ${errorClass}`}>
                                <CheckboxFormField
                                    checked={hasAccepted}
                                    errorMessage={errorMessage}
                                    label={t("termsAndConditions-IAgreeText")}
                                    onChange={handleCheckboxChange}
                                />
                                <Anchor
                                    onClick={handleAcceptClick}
                                    to={siteMap.dashboards.user}>
                                    {t("termsAndConditions-acceptButtonText")}
                                </Anchor>
                            </div>
                        </div>
                        <Paragraph
                            size={ParagraphSizes.XSmall}
                            cssClassName={`${BASE_CLASS_NAME}__help-info`}>
                            {t(
                                "termsAndConditions-contactCustomerServiceText",
                                {
                                    usPhoneNumber:
                                        SupportConstants.UsPhoneNumber,
                                    itnlPhoneNumber:
                                        SupportConstants.InternationalPhoneNumber,
                                    businessHours:
                                        SupportConstants.BusinessHours,
                                }
                            )}
                        </Paragraph>
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    );
};

export default TermsAndConditionsPageV2;
