import Loader from "atoms/loaders/loader";
import DataTable from "organisms/data-tables/data-table";
import React from "react";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import FreeAccessPublicationsAdminTableRow from "./free-access-publications-admin-table-row";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FreeAccessPublicationsAdminTableProps {
    freeAccessPublicationRecords: FreeAccessPublicationRecord[];
    loading: boolean;
    onEditPublication: (args0: FreeAccessPublicationRecord) => void;
    toggleIsPublished: (
        publication: FreeAccessPublicationRecord
    ) => Promise<void>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-admin-table";
const CSS_CLASS_NAME_HEADER = `${CSS_CLASS_NAME}__header`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FreeAccessPublicationsAdminTable: React.FC<FreeAccessPublicationsAdminTableProps> =
    (props: FreeAccessPublicationsAdminTableProps) => {
        const {
            freeAccessPublicationRecords,
            loading,
            onEditPublication,
            toggleIsPublished,
        } = props;

        if (loading) {
            return (
                <Loader
                    accessibleText={"Loading Free Access Publications..."}
                />
            );
        }

        return (
            <DataTable cssClassName={`${CSS_CLASS_NAME} -middle`}>
                <thead className={`${CSS_CLASS_NAME_HEADER}`}>
                    <tr>
                        <th className={`${CSS_CLASS_NAME_HEADER}-publication`}>
                            Publication
                        </th>
                        <th className={`${CSS_CLASS_NAME_HEADER}-content`}>
                            Title
                        </th>
                        <th
                            className={`${CSS_CLASS_NAME_HEADER}-status -right-col`}>
                            Status
                        </th>
                        <th className={"-collapse"}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {freeAccessPublicationRecords &&
                    freeAccessPublicationRecords.length > 0 ? (
                        freeAccessPublicationRecords.map(
                            (publication: FreeAccessPublicationRecord) => (
                                <FreeAccessPublicationsAdminTableRow
                                    freeAccessPublication={publication}
                                    key={publication.id}
                                    onEditPublication={onEditPublication}
                                    toggleIsPublished={toggleIsPublished}
                                />
                            )
                        )
                    ) : (
                        <tr>
                            <td colSpan={4} className="-no-results-row">
                                No Results
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FreeAccessPublicationsAdminTable;

// #endregion Exports
