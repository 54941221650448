import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { ChapterNfpaLabelParams } from "interfaces/routing/chapter-nfpa-label-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import React from "react";
import { useMemo } from "react";
import { match } from "react-router-dom";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import StringUtils from "utilities/string-utils";

interface TableBreadcrumbProps {
    match: match<
        NestedPublicationParams & ChapterNfpaLabelParams & NfpaLabelParams
    >;
}

const TablesBreadcrumb: React.FunctionComponent<TableBreadcrumbProps> = (
    props: TableBreadcrumbProps
) => {
    const { match } = props;
    const { code, edition } = match.params;

    const { loading, resultObject: publication } = usePublication({
        code,
        edition,
    });

    const breadcrumbLabel = useMemo(() => {
        if (loading) {
            return "...";
        }

        return StringUtils.translateDomainTerms("Tables", publication?.locale);
    }, [loading, publication?.locale]);

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{breadcrumbLabel}</span>
        </Breadcrumb>
    );
};

export default TablesBreadcrumb;
