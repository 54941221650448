import Icon from "atoms/icons/icon";
import React from "react";
import { Icons } from "atoms/constants/icons";
import { CollectionUtils } from "utilities/collection-utils";

/*
-----------------------------------------------------------------------------------------
Interfaces
-----------------------------------------------------------------------------------------
*/

export interface UnorderedListProps {
    cssClassName?: string;
    id?: string;
    listItems: Array<any>;
    listIcon?: Icons;
}

/*
---------------------------------------------------------------------------------------------
Component
---------------------------------------------------------------------------------------------
*/

const UnorderedList: React.FC<UnorderedListProps> = (
    props: UnorderedListProps
) => {
    let cssClassNames: Array<any> = [];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.listIcon) {
        cssClassNames.push("-has-icon");
        cssClassNames.push(`${props.listIcon}`);
    }

    return (
        <ul className={cssClassNames.join(" ")} id={props.id}>
            {CollectionUtils.hasValues(props.listItems) &&
                props.listItems?.map((listItem, index) => {
                    return (
                        <li key={index}>
                            {props.listIcon != null && (
                                <Icon type={props.listIcon} />
                            )}
                            {listItem}
                        </li>
                    );
                })}
        </ul>
    );
};

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default UnorderedList;
