import * as React from "react";

export interface CommitteesOfficersProps {
    children?: JSX.Element[];
}

const CommitteesOfficers = (props: CommitteesOfficersProps) => {
    return (
        <div className="c-committee-member-group -officers">
            {props.children}
        </div>
    );
};

export default CommitteesOfficers;
