import { Action, AlignAction, ImageSpec } from "quill-blot-formatter";
import CustomImageDeleteAction from "utilities/quill/blot-formatters/actions/custom-image-delete-action";
import CustomImageResizeAction from "utilities/quill/blot-formatters/actions/custom-image-resize-action";
import SetAltTextAction from "utilities/quill/blot-formatters/actions/set-alt-text-action";

/**
 * Custom quill-blot-formatter spec to handle
 * repositioning the image resize handles
 * when the editor scrolls.
 */
export default class CustomImageSpec extends ImageSpec {
    // Override
    getActions(): Array<typeof Action> {
        return [
            AlignAction,
            CustomImageDeleteAction,
            CustomImageResizeAction,
            SetAltTextAction,
        ];
    }

    // Override
    init() {
        this.formatter.quill.root.addEventListener("click", this.onClick);

        // handling scroll event
        this.formatter.quill.root.addEventListener("scroll", () => {
            this.formatter.repositionOverlay();
        });

        // handling align
        this.formatter.quill.on(
            "editor-change",
            (eventName: string, ...args: any) => {
                if (eventName === "selection-change" && args[2] === "api") {
                    setTimeout(() => {
                        this.formatter.repositionOverlay();
                    }, 10);
                }
            }
        );
    }
}
