/**
 * Enum representing the various 'components' of a Publication's data structure (not actual React components)
 *
 * @enum {number}
 */
enum PublicationComponentType {
    Annex = 0,
    AnnexGroup = 1,
    Article = 2,
    Chapter = 3,
    Publication = 4,
    Section = 5,
    Part = 6,
    Table = 7,
}

export default PublicationComponentType;
