import DataTable from "organisms/data-tables/data-table";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import FreeAccessPublicationAdminJobStatusesRow, {
    FreeAccessPublicationAdminJobStatusesTableRowProps,
} from "./free-access-publications-admin-job-statuses-row";

const CSS_CLASS_NAME = "c-publication-batch-reprocess-statuses__table";
const CSS_CLASS_NAME_HEADER = `${CSS_CLASS_NAME}__header`;

export interface FreeAccessPublicationAdminJobStatusesTableProps {
    rows: FreeAccessPublicationAdminJobStatusesTableRowProps[];
}

const FreeAccessPublicationAdminJobStatusesTable: React.FC<FreeAccessPublicationAdminJobStatusesTableProps> =
    (props: FreeAccessPublicationAdminJobStatusesTableProps) => {
        const { rows } = props;

        return (
            <DataTable cssClassName={`${CSS_CLASS_NAME}`}>
                <thead className={`${CSS_CLASS_NAME_HEADER}`}>
                    <tr>
                        <th>FileName</th>
                        <th>Worker Name</th>
                        <th>Status</th>
                        <th>Publish Status</th>
                        <th>Total Pages</th>
                        <th>Created On</th>
                        <th className={"-collapse"}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map(
                        (
                            row: FreeAccessPublicationAdminJobStatusesTableRowProps,
                            index
                        ) => (
                            <FreeAccessPublicationAdminJobStatusesRow
                                key={index}
                                {...row}
                            />
                        )
                    )}

                    {CollectionUtils.isEmpty(rows) && (
                        <tr>
                            <td colSpan={6} className="-no-results-row">
                                No Results
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        );
    };

export default FreeAccessPublicationAdminJobStatusesTable;
