import CodesModal from "molecules/modals/codes-modal";
import React from "react";
import { useLinkPreviewContext } from "utilities/contexts/link-preview/use-link-preview-context";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useLoading from "utilities/hooks/use-loading";
import useSectionIncludeSubSections from "utilities/hooks/domain/publications/chapters/sections/use-section-include-sub-sections";
import useOfflineSections from "utilities/hooks/domain/publications/chapters/sections/use-offline-sections";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useCurrentPublication from "utilities/contexts/use-current-publication";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface LinkPreviewModalProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const LinkPreviewModal: React.FunctionComponent<LinkPreviewModalProps> = () => {
    const { isModalOpen, onGoToBookView, onModalClose, route, sectionId } =
        useLinkPreviewContext();

    const { isOnline } = useNetworkInformation();

    const { loading: loadingOnlineSection, resultObject: onlineSection } =
        useSectionIncludeSubSections({ enabled: isOnline, sectionId });

    const { loading: loadingOfflineSection, resultObject: offlineSection } =
        useOfflineSections({
            enabled: !isOnline,
            sectionId,
        });

    const {
        loading: loadingOnlinePublication,
        resultObject: onlinePublication,
    } = usePublication({ publicationId: onlineSection?.publicationId });

    const { publication: currentPublication } = useCurrentPublication();

    const loadingPublication = isOnline && loadingOnlinePublication;

    const loadingSection = isOnline
        ? loadingOnlineSection
        : loadingOfflineSection;

    const publication = currentPublication ?? onlinePublication;

    const section = isOnline ? onlineSection : offlineSection;

    const loading = useLoading(loadingPublication, loadingSection);

    return (
        <CodesModal
            closeDialog={onModalClose}
            isLoading={loading}
            isVisible={isModalOpen}
            onClickOpenInBookView={onGoToBookView}
            route={route}
            content={section}
            subContent={section.subSections}
            publicationDisplayTitle={publication.getDisplayTitle()}
            publicationType={publication.type}
            enableReferencePanel={true}
            publication={publication}
            code={publication.code}
            edition={publication.edition}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default LinkPreviewModal;

// #endregion Export
