import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Private Functions
// -------------------------------------------------------------------------------------------------

const parse = (html?: string): DocumentFragment | undefined => {
    html = html?.trim();

    if (StringUtils.hasValue(html)) {
        return getTemplateContent(html);
    }

    return undefined;
};

const getTemplateContent = (html: string): DocumentFragment => {
    const fragment = document.createDocumentFragment();
    const temp = document.createElement("div");
    temp.innerHTML = html;

    while (temp.firstChild) {
        fragment.appendChild(temp.firstChild);
    }

    return fragment;
};

// #endregion Private Functions

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const isEmpty = (value?: string) => {
    const container = parse(value);
    if (container == null) {
        return true;
    }

    // check for image content, regardless of text content.
    const images = Array.from(container.querySelectorAll("img") ?? []);
    if (
        CollectionUtils.hasValues(images) &&
        images.some((img: HTMLImageElement) => StringUtils.hasValue(img.src))
    ) {
        return false;
    }

    // if no images, check for text content
    const textContent = container.textContent;
    return StringUtils.isEmpty(textContent ?? "");
};

const hasValue = (value?: string): value is string => !isEmpty(value);

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const RichTextUtils = {
    isEmpty,
    hasValue,
    parse,
};

export default RichTextUtils;

// #endregion Exports
