import { ResultRecord } from "andculturecode-javascript-core";
import { useLocalization } from "utilities/hooks/use-localization";
import logo from "assets/images/nfpa-link-color.png";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { InputTypes } from "atoms/constants/input-types";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import AuthenticationProviderErrorKeys from "constants/authentication-provider-error-keys";
import { AutocompleteAttributeValues } from "constants/autocomplete-attribute-values";
import GlobalStateRecord from "models/view-models/global-state-record";
import UserLoginRecord from "models/view-models/user-login-record";
import InputFormField from "molecules/form-fields/input-form-field";
import PasswordFormField from "molecules/form-fields/password-form-field";
import Form from "molecules/forms/form";
import FreeTrialTermsBox from "molecules/free-trial-terms-box/free-trial-terms-box";
import moment from "moment";
import AccountLoginIssuesModal from "organisms/modals/account-login-issues-modal/account-login-issues-modal";
import GroupInvitationAccountLoginIssuesModal from "organisms/modals/account-login-issues-modal/group-invitation-account-login-issues-modal";
import SubscriptionType from "organisms/enums/subscription-type";
import * as React from "react";
import { useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useIdentity from "utilities/hooks/use-identity";
import usePageErrors from "utilities/hooks/use-page-errors";
import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import AlertLevels from "constants/alert-levels";
import AlertBanner from "molecules/alerts/alert-banner";
import UserLoginSubmitButton from "organisms/userlogins/userlogins-new-form/userlogin-submit-button";
import PageErrorsList from "molecules/lists/page-errors-list";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import ForgotPasswordButton from "molecules/forgot-password-button/forgot-password-button";
import CreateAccountAnchor from "molecules/create-account-anchor/create-account-anchor";

export enum NewUserLoginFormType {
    GroupInvitation,
}

export interface NewUserLoginFormProps {
    defaultEmail?: string;
    displayLoginToasts?: boolean;
    freeTrialAgreementError?: string;
    freeTrialTermsAccepted?: boolean;
    isSigningIn?: boolean;
    newUserLoginFormType?: NewUserLoginFormType;
    onError?: (message?: string) => void;
    onFreeTrialTermsAccepted?: (accepted: boolean) => void;
    onSubmit?: () => boolean;
    submitButtonText?: string;

    /**
     * Optional callback that will be fired after successfully logging in the user.
     */
    onSuccess?: () => void;
    selectedSubscriptionType?: SubscriptionType;
    showLogo?: boolean;
    showSignInTitle?: boolean;
}

const NewUserLoginForm: React.FunctionComponent<NewUserLoginFormProps> = (
    props: NewUserLoginFormProps
) => {
    const { setGlobalState } = useGlobalState();
    const { buildCurrentIdentity } = useIdentity();
    const { isOnline } = useNetworkInformation();

    const [accountLoginIssuesModalIsOpen, setAccountLoginIssuesModalIsOpen] =
        useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [signingIn, setSigningIn] = useState(props.isSigningIn);
    const [username, setUserName] = useState("");
    const [usernameError, setUserNameError] = useState("");

    const { useAzureB2CForSSO } = useFeatureFlags();
    const displayLoginToasts = props.displayLoginToasts ?? false;
    const hasDefaultEmail = StringUtils.hasValue(props.defaultEmail);

    const { create: createUserLoginApi } = UserLoginService.useCreate();
    const { setPageErrors, pageErrors, resetPageErrors } = usePageErrors();
    const { t } = useLocalization<CultureResources>();

    useEffect(() => {
        if (StringUtils.hasValue(props.defaultEmail)) {
            setUserName(props.defaultEmail!);
        }
    }, [props.defaultEmail]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formHasErrors = await validate();

        if (formHasErrors) {
            return;
        }

        setSigningIn(true);
        resetPageErrors();
        try {
            const response = await createUserLoginApi(
                new UserLoginRecord({
                    password: password,
                    userName: username,
                })
            );

            const identity = await buildCurrentIdentity(response.resultObject);
            if (identity == null) {
                return;
            }

            setGlobalState((globalState: GlobalStateRecord) => {
                const updatedGlobalState = globalState.setIdentity(identity);

                LocalizationUtils.changeCultureCode(
                    updatedGlobalState.getPreferredOrCurrentCulture()
                );

                return updatedGlobalState;
            });

            const currentUserRole = identity?.getCurrentUserRole();

            // If we aren't supposed to display the login toasts, or we don't have a role to display
            // information for, don't continue.
            if (!displayLoginToasts || currentUserRole == null) {
                props.onSuccess?.();
                return;
            }

            const toastMessage =
                currentUserRole?.userRoleGroup != null
                    ? t("newUserLoginForm-loggedInAs-memberOf", {
                          teamName: currentUserRole.userRoleGroup.group?.name,
                      })
                    : t("newUserLoginForm-loggedInAs-user", {
                          username: identity.getCurrentRoleDisplayName(),
                      });
            ToastManager.success(toastMessage);

            if (moment(currentUserRole?.expiresOn).isBefore(moment())) {
                ToastManager.error(
                    t("newUserLoginForm-errors-currentRoleExpired")
                );
                setSigningIn(false);
                props.onError?.();
            }

            props.onSuccess?.();
        } catch (result) {
            props.onError?.();

            // stop loading indicator
            setSigningIn(false);

            // Handle the specific case for a failed login (wrong username/password)
            if (
                result instanceof ResultRecord &&
                result.hasErrorFor(
                    AuthenticationProviderErrorKeys.ERROR_LOGIN_RESOURCE_NOT_FOUND
                )
            ) {
                setPageErrors([t("newUserLoginForm-errors-loginFailed")]);
                return;
            }

            setPageErrors([t("newUserLoginForm-errors-loginGeneral")]);
        }
    };

    const validate = async () => {
        resetErrors();
        let hasErrors = false;

        if (username.length === 0) {
            setUserNameError(
                t("propertyIsRequired", { name: t("field-email") })
            );
            hasErrors = true;
        }
        if (password.length === 0) {
            setPasswordError(t("propertyIsRequired", { name: t("password") }));
            hasErrors = true;
        }

        return hasErrors;
    };

    const resetErrors = () => {
        setUserNameError("");
        setPasswordError("");
    };

    const getLoginIssuesModal = () => {
        switch (props.newUserLoginFormType) {
            case NewUserLoginFormType.GroupInvitation:
                return (
                    <GroupInvitationAccountLoginIssuesModal
                        closeDialog={() =>
                            setAccountLoginIssuesModalIsOpen(false)
                        }
                        isVisible={accountLoginIssuesModalIsOpen}
                    />
                );
            default:
                return (
                    <AccountLoginIssuesModal
                        closeDialog={() =>
                            setAccountLoginIssuesModalIsOpen(false)
                        }
                        isVisible={accountLoginIssuesModalIsOpen}
                    />
                );
        }
    };

    return (
        <div className="c-userlogin-new-form">
            {(props.showLogo ?? true) && (
                <img crossOrigin="anonymous" src={logo} alt={t("nfpaLogo")} />
            )}
            {(props.showSignInTitle ?? true) && (
                <Heading priority={HeadingPriority.One}>{t("signIn")}</Heading>
            )}
            {useAzureB2CForSSO ? (
                <CreateAccountAnchor cssClassName="c-button" />
            ) : (
                <>
                    <Form onSubmit={handleSubmit} buttonText={t("signIn")}>
                        {!isOnline && (
                            <AlertBanner alertLevel={AlertLevels.Error}>
                                {t("login-offline-warning")}
                            </AlertBanner>
                        )}
                        <InputFormField
                            autocomplete={AutocompleteAttributeValues.USERNAME}
                            disabled={hasDefaultEmail || signingIn || !isOnline}
                            errorMessage={usernameError}
                            inputTestId="userName"
                            isValid={usernameError.length === 0}
                            label={t("field-email")}
                            maxLength={100}
                            onChange={(e) => setUserName(e.target.value)}
                            required={!hasDefaultEmail}
                            showCharacterCount={false}
                            type={InputTypes.Email}
                            value={username}
                        />
                        <PasswordFormField
                            autocomplete={AutocompleteAttributeValues.PASSWORD}
                            disabled={signingIn || !isOnline}
                            errorMessage={passwordError}
                            inputTestId="password"
                            isValid={passwordError.length === 0}
                            label={t("password")}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                            value={password}
                        />
                        {props.selectedSubscriptionType ===
                            SubscriptionType.FreeTrial && (
                            <FreeTrialTermsBox
                                accepted={props.freeTrialTermsAccepted ?? false}
                                error={props.freeTrialAgreementError}
                                onAcceptChange={props.onFreeTrialTermsAccepted}
                            />
                        )}

                        <UserLoginSubmitButton
                            buttonText={props.submitButtonText ?? t("signIn")}
                            disabled={!isOnline}
                            loading={signingIn ?? false}
                        />
                        <PageErrorsList
                            cssClassName={"c-userlogin-new-form__errors"}
                            errors={pageErrors}
                            hide={signingIn ?? true}
                            renderComponent={Paragraph}
                        />
                    </Form>
                    {!signingIn && (
                        <div className="c-userlogin-new-form__help-links">
                            <ForgotPasswordButton cssClassName={"-link"} />
                            <Button
                                cssClassName={"-link"}
                                onClick={() =>
                                    setAccountLoginIssuesModalIsOpen(true)
                                }
                                style={ButtonStyles.Anchor}>
                                {t("needHelpSigningIn")}
                            </Button>
                        </div>
                    )}
                </>
            )}
            {accountLoginIssuesModalIsOpen && getLoginIssuesModal()}
        </div>
    );
};

export default NewUserLoginForm;
