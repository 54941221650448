import React, { useState, SetStateAction, useEffect, useMemo } from "react";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import CultureResources from "utilities/interfaces/culture-resources";
import SystemSettingsService, {
    SystemSettingsBasePathParams,
} from "utilities/services/system-settings/system-settings-service";
import usePageErrors from "utilities/hooks/use-page-errors";
import SystemSettingsRecord from "models/view-models/system-settings-record";
import Loader from "atoms/loaders/loader";
import {
    useGlobalState,
    GlobalStateUpdater,
} from "utilities/contexts/use-global-state-context";
import GlobalStateRecord from "models/view-models/global-state-record";
import UnorderedList from "molecules/lists/unordered-list";
import CheckboxFormField from "molecules/form-fields/checkbox-form-field";
import UserRoleRecord from "models/view-models/user-role-record";
import { CollectionUtils } from "utilities/collection-utils";
import UserRoleService, {
    UserRoleBasePathParams,
    UserRoleListQueryParams,
    UserRoleResourcePathParams,
} from "utilities/services/users/user-roles/user-role-service";
import {
    NestedListService,
    UpdateService,
} from "utilities/services/service-factory";
import OnboardingLayout from "templates/onboarding-layout";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import { useHistory } from "react-router-dom";
import { History } from "history";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import useUnauthorizedResult from "utilities/hooks/use-unauthorized-result";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { MetaTagTypes } from "models/interfaces/header-data";
import { SupportConstants } from "constants/phone-number-constants";
import { GetService } from "andculturecode-javascript-react";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import { Translator } from "utilities/types/translator-type";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import ReactMarkdown from "react-markdown";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-terms-and-conditions-page";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TermsAndConditionsPage: React.FunctionComponent = () => {
    const contentClassName = `${BASE_CLASS_NAME}__content`;

    const { useMarkdownForTermsAndConditions } = useFeatureFlags();
    const { t } = useLocalization<CultureResources>();
    const loaderText = t("loadingItem", {
        item: t("termsAndConditions-documentTitle"),
    });

    // Clean up the unauthorizedResult from GlobalState if found/relevant to this page
    useUnauthorizedResult(UserConfigurationErrorKeys.ERROR_EULA_NOT_ACCEPTED);

    // Pull out global state, system settings, and the current user identity for reading
    // and updating UserRoles
    const { globalState, setGlobalState } = useGlobalState();

    const headerData = useMemo(
        () => ({
            title: t("termsAndConditions-pageTitle"),
            metaTags: [
                {
                    name: MetaTagTypes.Title,
                    content: t("termsAndConditions-meta-title"),
                },
            ],
        }),
        [t]
    );
    useHeaderData(headerData);

    // Flag to determine whether the SystemSettings have been refreshed on initial page load.
    const [systemSettingsLoaded, setSystemSettingsLoaded] =
        useState<boolean>(false);

    const [systemSettings, setSystemSettings] = useState<SystemSettingsRecord>(
        globalState.getSystemSettings()
    );

    // We need history to redirect the user upon successful acceptance of the T&C
    const history = useHistory();

    // Flag to determine whether or not the page is loading data
    const [isLoading, setIsLoading] = useState<boolean>(
        !globalState.hasSystemSettings()
    );

    // Flag to determine whether the list of UserRoles has been refreshed on initial page load.
    const [userRolesLoaded, setUserRolesLoaded] = useState<boolean>(false);

    const [userRoles, setUserRoles] = useState<UserRoleRecord[]>([]);

    // Flag to determine whether the controls (checkbox, button) should be disabled, based on
    // whether all of their current subscription-based UserRoles have the EulaAccepted flag set to true.
    const [isDisabled, setIsDisabled] = useState<boolean>(
        !hasUnacceptedSubscriptionRoles(userRoles)
    );

    // Flag to determine whether the user has checked the 'I accept ...' box. Initialize it from
    // whether or not they've already accepted the EULA for all of their UserRoles (isDisabled)
    const [hasAccepted, setHasAccepted] = useState<boolean>(isDisabled);

    // Error message to display underneath the checkbox for validation.
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    );

    // Page errors + API service hooks
    const { handlePageLoadError, pageErrors, resetPageErrors } =
        usePageErrors();
    const { get: getSystemSettingsApi } = SystemSettingsService.useGet();
    const { update: updateUserRoleApi } = UserRoleService.useUpdate();
    const { list: listUserRolesApi } = UserRoleService.useList();

    useEffect(() => {
        // If we've already loaded the SystemSettings on this page, don't get them again.
        if (systemSettingsLoaded) {
            return;
        }

        getSystemSettings(
            getSystemSettingsApi,
            handlePageLoadError,
            resetPageErrors,
            setGlobalState,
            setIsLoading,
            setSystemSettings,
            setSystemSettingsLoaded
        );
    }, [
        getSystemSettingsApi,
        globalState,
        handlePageLoadError,
        resetPageErrors,
        setGlobalState,
        setIsLoading,
        setSystemSettings,
        setSystemSettingsLoaded,
        systemSettingsLoaded,
    ]);

    useEffect(() => {
        // If we've already loaded the UserRoles on this page, don't get them again.
        if (userRolesLoaded) {
            return;
        }

        listUserRoles(
            globalState,
            handlePageLoadError,
            listUserRolesApi,
            resetPageErrors,
            setIsLoading,
            setUserRoles,
            setUserRolesLoaded
        );
    }, [
        globalState,
        handlePageLoadError,
        listUserRolesApi,
        resetPageErrors,
        setGlobalState,
        setIsLoading,
        setUserRolesLoaded,
        userRolesLoaded,
    ]);

    useEffect(() => {
        if (hasUnacceptedSubscriptionRoles(userRoles)) {
            setIsDisabled(false);
            setHasAccepted(false);
            return;
        }

        setIsDisabled(true);
        setHasAccepted(true);
    }, [userRoles]);

    const errorClass = StringUtils.hasValue(errorMessage) ? "errors" : "";

    const renderTermsAsHtml =
        !isLoading &&
        !useMarkdownForTermsAndConditions &&
        systemSettings.hasTermsAndConditions();
    const renderTermsAsMarkdown =
        !isLoading &&
        useMarkdownForTermsAndConditions &&
        systemSettings.hasTermsAndConditions();
    return (
        <OnboardingLayout>
            <div className={BASE_CLASS_NAME}>
                <div className={`${BASE_CLASS_NAME}__container`}>
                    <div className={`${BASE_CLASS_NAME}__inner-container`}>
                        <div className={contentClassName}>
                            <Heading
                                cssClassName={`${contentClassName}__heading`}
                                priority={HeadingPriority.Four}>
                                {t("termsAndConditions-documentTitle")}
                            </Heading>
                            {pageErrors.length > 0 && (
                                <UnorderedList listItems={pageErrors} />
                            )}
                            <div className={`${contentClassName}__terms`}>
                                {isLoading && (
                                    <Loader
                                        accessibleText={loaderText}
                                        type={LoaderStyles.LinkGlyphGray}
                                    />
                                )}
                                {renderTermsAsHtml && (
                                    <Paragraph
                                        dangerouslySetInnerHTML={{
                                            __html: systemSettings.getTermsAndConditionsContent(),
                                        }}
                                        size={ParagraphSizes.Small}
                                    />
                                )}
                                {renderTermsAsMarkdown && (
                                    <ReactMarkdown>
                                        {systemSettings.getTermsAndConditionsContent()}
                                    </ReactMarkdown>
                                )}
                            </div>
                            <div
                                className={`${contentClassName}__action ${errorClass}`}>
                                <CheckboxFormField
                                    checked={hasAccepted}
                                    disabled={isDisabled}
                                    errorMessage={errorMessage}
                                    label={t("termsAndConditions-IAgreeText")}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            hasAccepted,
                                            setErrorMessage,
                                            setHasAccepted
                                        )
                                    }
                                />
                                <Anchor
                                    onClick={(
                                        event: React.MouseEvent<
                                            HTMLElement,
                                            MouseEvent
                                        >
                                    ) =>
                                        handleAcceptClick(
                                            event,
                                            handlePageLoadError,
                                            hasAccepted,
                                            history,
                                            listUserRolesApi,
                                            resetPageErrors,
                                            setErrorMessage,
                                            setGlobalState,
                                            setIsLoading,
                                            updateUserRoleApi,
                                            userRoles,
                                            t
                                        )
                                    }
                                    to={siteMap.dashboards.user}>
                                    {t("termsAndConditions-acceptButtonText")}
                                </Anchor>
                            </div>
                        </div>
                        <Paragraph
                            size={ParagraphSizes.XSmall}
                            cssClassName={`${BASE_CLASS_NAME}__help-info`}>
                            {t(
                                "termsAndConditions-contactCustomerServiceText",
                                {
                                    usPhoneNumber:
                                        SupportConstants.UsPhoneNumber,
                                    intlPhoneNumber:
                                        SupportConstants.InternationalPhoneNumber,
                                    businessHours:
                                        SupportConstants.BusinessHours,
                                }
                            )}
                        </Paragraph>
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getSystemSettings = async (
    getSystemSettingsApi: GetService<
        SystemSettingsRecord,
        SystemSettingsBasePathParams
    >,
    handlePageLoadError: (result: any) => void,
    resetPageErrors: () => void,
    setGlobalState: GlobalStateUpdater,
    setIsLoading: React.Dispatch<SetStateAction<boolean>>,
    setSystemSettings: React.Dispatch<SetStateAction<SystemSettingsRecord>>,
    setSystemSettingsLoaded: React.Dispatch<SetStateAction<boolean>>
): Promise<void> => {
    const getSystemSettings = async () => {
        try {
            setSystemSettingsLoaded(true);

            const response = await getSystemSettingsApi({});

            const systemSettings = response.resultObject;

            // Update GlobalState with the fresh record incase anything has changed
            setGlobalState((globalState: GlobalStateRecord) =>
                globalState.setSystemSettings(systemSettings)
            );

            // Set our local state copy to re-render
            setSystemSettings(systemSettings!);

            resetPageErrors();
            setIsLoading(false);
        } catch (error) {
            handlePageLoadError(error);
            setIsLoading(false);
            setSystemSettingsLoaded(false);
        }
    };

    getSystemSettings();
};

const getUnacceptedUserRoles = (
    userRoles: UserRoleRecord[]
): UserRoleRecord[] => {
    if (CollectionUtils.isEmpty(userRoles)) {
        return [];
    }

    return userRoles.filter(
        (e: UserRoleRecord) => e.subscriptionId != null && !e.eulaAccepted
    );
};

const handleAcceptClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    handlePageLoadError: (result: any) => void,
    hasAccepted: boolean,
    history: History,
    listUserRolesApi: NestedListService<
        UserRoleRecord,
        UserRoleBasePathParams,
        UserRoleListQueryParams
    >,
    resetPageErrors: () => void,
    setErrorMessage: React.Dispatch<SetStateAction<string | undefined>>,
    setGlobalState: GlobalStateUpdater,
    setIsLoading: React.Dispatch<SetStateAction<boolean>>,
    updateUserRoleApi: UpdateService<
        UserRoleRecord,
        UserRoleResourcePathParams
    >,
    userRoles: UserRoleRecord[],
    t: Translator<CultureResources>
): void => {
    // Swallow the event until this logic to update the UserRole runs
    event.preventDefault();
    event.nativeEvent.returnValue = false; // Something weird with IE

    if (!hasAccepted) {
        setErrorMessage(t("termsAndConditions-mustAgreeToContinue"));
        return;
    }

    if (!hasUnacceptedSubscriptionRoles(userRoles)) {
        // If we have no unaccepted subscription roles, just continue to the dashboard.
        history.push(siteMap.dashboards.user);
        return;
    }

    // Find the first UserRole which has eulaAccepted set to false.
    // It doesn't matter as much which UserRole we pick, calling update on any of subscription-based
    // UserRoles will trigger the Hermes UpdateEulaStatus call and re-sync the roles on our side.

    const userRole = getUnacceptedUserRoles(userRoles).pop();

    const updateUserRole = async (userRole: UserRoleRecord) => {
        const { userId } = userRole;
        try {
            setIsLoading(true);

            await updateUserRoleApi(userRole.with({ eulaAccepted: true }), {
                userId: userId,
                id: userRole.id!,
            });

            const listResponse = await listUserRolesApi({
                userId,
            });

            setGlobalState((globalState: GlobalStateRecord) => {
                if (globalState.currentIdentity == null) {
                    return globalState;
                }

                return globalState.setIdentity(
                    globalState.currentIdentity.with({
                        userRoles: listResponse.resultObjects,
                    })
                );
            });

            resetPageErrors();
            setIsLoading(false);

            // Finally, redirect the user to the dashboard.
            history.push(siteMap.dashboards.user);
        } catch (error) {
            handlePageLoadError(error);
            setIsLoading(false);
        }
    };

    updateUserRole(userRole!);
};

const handleCheckboxChange = (
    hasAccepted: boolean,
    setErrorMessage: React.Dispatch<SetStateAction<string | undefined>>,
    setHasAccepted: React.Dispatch<SetStateAction<boolean>>
): void => {
    setHasAccepted(!hasAccepted);
    setErrorMessage(undefined);
};

const hasUnacceptedSubscriptionRoles = (
    userRoles: UserRoleRecord[]
): boolean => {
    if (CollectionUtils.isEmpty(userRoles)) {
        return false;
    }

    return CollectionUtils.hasValues(getUnacceptedUserRoles(userRoles));
};

const listUserRoles = async (
    globalState: GlobalStateRecord,
    handlePageLoadError: (result: any) => void,
    listUserRolesApi: NestedListService<
        UserRoleRecord,
        UserRoleBasePathParams,
        UserRoleListQueryParams
    >,
    resetPageErrors: () => void,
    setIsLoading: React.Dispatch<SetStateAction<boolean>>,
    setUserRoles: React.Dispatch<SetStateAction<UserRoleRecord[]>>,
    setUserRolesLoaded: React.Dispatch<SetStateAction<boolean>>
): Promise<void> => {
    if (globalState.currentIdentity == null) {
        return;
    }

    const { currentIdentity } = globalState;

    const userId = currentIdentity.userId();

    const listUserRoles = async (userId: number) => {
        try {
            setUserRolesLoaded(true);

            const listResponse = await listUserRolesApi({
                userId,
            });

            setUserRoles(listResponse.resultObjects!);
            resetPageErrors();
            setIsLoading(false);
        } catch (error) {
            handlePageLoadError(error);
            setIsLoading(false);
            setUserRolesLoaded(false);
        }
    };

    listUserRoles(userId);
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TermsAndConditionsPage;

// #endregion Export
