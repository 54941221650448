import React, { forwardRef, Ref } from "react";
import { CheckboxButtonProperties } from "atoms/forms/checkbox-button";
import { InputTypes } from "atoms/constants/input-types";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ToggleButtonProps extends CheckboxButtonProperties {
    tabIndex?: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-toggle-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ToggleButton = forwardRef(
    (props: ToggleButtonProps, ref: Ref<HTMLInputElement>) => {
        const { checked, disabled, onChange, tabIndex } = props;
        const labelClassName = `${BASE_CLASS}__switch ${
            disabled ? "-disabled" : ""
        }`;
        const sliderClassName = `${BASE_CLASS}__slider ${BASE_CLASS}__slider__button`;
        return (
            <div className={BASE_CLASS}>
                <label className={labelClassName}>
                    <input
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                        tabIndex={tabIndex}
                        type={InputTypes.Checkbox}
                        value={checked.toString()}
                    />
                    <span className={sliderClassName} />
                </label>
            </div>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ToggleButton;

// #endregion Exports
