import React, { forwardRef } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Button from "atoms/buttons/button";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import PublishStatus from "models/enumerations/publish-status";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentUnpublishActionProps
    extends Pick<EnhancedContentPanelProps, "enhancedContent"> {
    onClick: () => void;
    ref?: React.RefObject<HTMLButtonElement>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentUnpublishAction: React.RefForwardingComponent<
    HTMLButtonElement,
    EnhancedContentUnpublishActionProps
> = forwardRef(
    (
        props: EnhancedContentUnpublishActionProps,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        const { enhancedContent, onClick, ref: buttonRef } = props;

        const { globalState } = useGlobalState();

        const canUnpublish =
            ((globalState.currentIdentity?.isPublisher() ||
                globalState.currentIdentity?.isAdmin()) &&
                enhancedContent.getPublishStatus() === PublishStatus.Published &&
                !enhancedContent.hasUnpublishedChanges()) ??
            false;

        if (!canUnpublish) {
            return null;
        }

        return (
            <Button
                accessibleText={`Unpublish Enhanced Content`}
                onClick={onClick}
                ref={buttonRef}>
                Unpublish
            </Button>
        );
    }
);

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentUnpublishAction;

// #endregion Exports
