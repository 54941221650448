import React, { useEffect } from "react";
import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import TableOfContentsImageViewerCollapsePanelHeader from "./table-of-contents-image-viewer-collapse-panel-header";
import SlidingDrawer from "molecules/sliding-drawer/sliding-drawer";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useTableOfContents from "utilities/hooks/domain/publications/use-table-of-contents";
import { CollectionUtils } from "utilities/collection-utils";
import TableOfContentsImageViewerCollapsePanelItem from "./table-of-contents-image-viewer-collapse-panel-item";
import { t } from "utilities/localization-utils";
import PdfTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import ImagePage from "models/interfaces/image-page";
import useTableOfContentsAtom from "pages/free-access/use-table-of-contents-atom";

interface TableOfContentsImageViewerProps {
    currentPageNumber: number;
    activeId: number | undefined;
    tableOfContentsEntries: PdfTableOfContentsEntry[]; // May need an additional type and/or props to cover Legacy Publications' data structure
    setCurrentPageNumber: (value: number) => void;
    setActiveId: (value: number | undefined) => void;
    className?: string | null;
    pageEntries: ImagePage[] | undefined;
}

const TableOfContentsImageViewer: React.FunctionComponent<TableOfContentsImageViewerProps> =
    (props: TableOfContentsImageViewerProps) => {
        const {
            currentPageNumber,
            activeId,
            tableOfContentsEntries,
            setCurrentPageNumber,
            setActiveId,
            className,
            pageEntries,
        } = props;

        const isTablet = useBreakpoint(
            Breakpoints.Tablet,
            BreakpointComparer.MaxWidth
        );
        const { drawerIsOpen, setDrawerIsOpen } = useTableOfContentsAtom();

        const { getActiveParentId } = useTableOfContents({
            tableOfContentsEntries,
            currentPageNumber,
            pageEntries,
        });

        useEffect(() => {
            if (isTablet && drawerIsOpen) {
                setDrawerIsOpen(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentPageNumber]);

        const onEntryClick = (entry: PdfTableOfContentsEntry) => {
            setCurrentPageNumber(entry.pageNumber);
            setActiveId(getActiveParentId(entry.id));
        };

        const CSS_TOC_CLASS = className || "c-image-viewer__left-sidebar";
        const CSS_NAV_CLASS = "c-image-viewer__nav";

        return (
            <SlidingDrawer
                cssClassName={CSS_TOC_CLASS}
                buttonAriaText={t("tableOfContents")}
                isOpen={drawerIsOpen}
                onDrawerOpen={() => setDrawerIsOpen(true)}
                onDrawerClose={() => setDrawerIsOpen(false)}>
                <nav className={CSS_NAV_CLASS}>
                    {tableOfContentsEntries?.map((entry) => {
                        const hasSections = CollectionUtils.hasValues(
                            entry.sections
                        );
                        return (
                            <CollapsePanel
                                key={entry.id}
                                data-test-id="chapter-collapse-panel"
                                panelTop={
                                    <TableOfContentsImageViewerCollapsePanelHeader
                                        tableOfContentsEntry={entry}
                                        onEntryClick={() => onEntryClick(entry)}
                                        isActive={entry.id === activeId}
                                    />
                                }
                                collapse={entry.id !== activeId}
                                scrollToWhenActive={true}
                                modifierCSSClassName={`-compact ${
                                    entry.id === activeId ? "-active" : ""
                                }`}
                                buttonAriaText={entry.heading}>
                                {hasSections && (
                                    <ul className={`${CSS_NAV_CLASS}__list`}>
                                        {entry.sections.map((s) => {
                                            const hasSubsections =
                                                CollectionUtils.hasValues(
                                                    s.sections
                                                );
                                            if (!hasSubsections) {
                                                return (
                                                    <TableOfContentsImageViewerCollapsePanelItem
                                                        item={s}
                                                        key={s.id}
                                                        onEntryClick={
                                                            onEntryClick
                                                        }
                                                    />
                                                );
                                            }

                                            return (
                                                <CollapsePanel
                                                    key={s.id}
                                                    panelTop={
                                                        <TableOfContentsImageViewerCollapsePanelHeader
                                                            cssClassName={
                                                                "-article"
                                                            }
                                                            tableOfContentsEntry={
                                                                s
                                                            }
                                                            onEntryClick={() =>
                                                                onEntryClick(s)
                                                            }
                                                            isActive={
                                                                s.id ===
                                                                activeId
                                                            }
                                                        />
                                                    }
                                                    collapse={s.id !== activeId}
                                                    scrollToWhenActive={true}
                                                    modifierCSSClassName={`-compact -article ${
                                                        s.id === activeId
                                                            ? "-active"
                                                            : ""
                                                    }`}
                                                    buttonAriaText={s.heading}>
                                                    <ul
                                                        className={`${CSS_NAV_CLASS}__list`}>
                                                        {s.sections.map(
                                                            (ss) => (
                                                                <TableOfContentsImageViewerCollapsePanelItem
                                                                    item={ss}
                                                                    key={ss.id}
                                                                    onEntryClick={
                                                                        onEntryClick
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </ul>
                                                </CollapsePanel>
                                            );
                                        })}
                                    </ul>
                                )}
                            </CollapsePanel>
                        );
                    })}
                </nav>
            </SlidingDrawer>
        );
    };

export default TableOfContentsImageViewer;
