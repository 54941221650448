import { ResultRecord, ServiceResponse } from "andculturecode-javascript-core";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";

import React, {
    ChangeEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    useMemo,
    useState,
} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { InviteOnDemandErrors } from "utilities/hooks/domain/groups/use-invite-on-demand";
import useRecaptcha from "utilities/hooks/domain/user-settings/use-recaptcha";
import { t } from "utilities/localization-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { JoinGroupOnDemandState } from "./join-group-on-demand-content";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import CaptchaErrors from "molecules/forms/captcha-errors";
interface JoinGroupOnDemandFormProps {
    disabled: boolean;
    setState: Dispatch<SetStateAction<JoinGroupOnDemandState>>;
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    emailErrors: string[];
    setEmailErrors: Dispatch<SetStateAction<string[]>>;
    createInviteOnDemand: (email: string) => Promise<{
        validationErrors?: string[];
        result?:
            | ServiceResponse<GroupInvitationRecord>
            | ResultRecord<GroupInvitationRecord>;
    }>;
    domains: string[];
}
const JoinGroupOnDemandForm = ({
    disabled,
    setState,
    email,
    setEmail,
    emailErrors,
    setEmailErrors,
    createInviteOnDemand,
    domains,
}: JoinGroupOnDemandFormProps) => {
    const [showCaptchaErrors, setShowCaptchaErrors] = useState(false);
    const {
        siteKey,
        culture,
        captchaSettings,
        captchaComplete,
        handleRecaptchaChange: recaptchaChanged,
    } = useRecaptcha();

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailErrors([]);
        setEmail(e.currentTarget.value);
    };

    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    const handleSubmit = async (
        e: FormEvent<HTMLFormElement | HTMLButtonElement>
    ) => {
        e.preventDefault();

        if (!captchaComplete) {
            setShowCaptchaErrors(true);
            return;
        }
        const { validationErrors, result } = await createInviteOnDemand(email);

        if (validationErrors) {
            setEmailErrors(validationErrors);
            return;
        }

        const hasErrors = result instanceof ResultRecord;
        if (hasErrors) {
            handleErrors(result);
            return;
        }

        setState(
            result?.resultObject?.isUserAlreadyCreated
                ? JoinGroupOnDemandState.THANK_YOU_SIGN_IN
                : JoinGroupOnDemandState.THANK_YOU_SIGN_UP
        );
        return;
    };

    const handleErrors = (result: ResultRecord<GroupInvitationRecord>) => {
        if (result.hasErrorFor(InviteOnDemandErrors.ERROR_DOMAIN_NOT_FOUND)) {
            setState(JoinGroupOnDemandState.UNRECOGNIZED_EMAIL);
            return;
        }
        if (
            result.hasErrorFor(InviteOnDemandErrors.ERROR_INVITE_ALREADY_EXISTS)
        ) {
            ToastManager.error("You were already invited"); // TODO: https://app.clickup.com/t/2219993/NFPA-5960
            return;
        }
        if (result.hasErrorFor(InviteOnDemandErrors.ERROR_NO_INVITES_LEFT)) {
            setState(JoinGroupOnDemandState.NO_INVITES_AVAILABLE);
            return;
        }
        if (
            result.hasErrorFor(InviteOnDemandErrors.ERROR_USER_ALREADY_IN_GROUP)
        ) {
            setState(JoinGroupOnDemandState.ALREADY_ON_TEAM);
            return;
        }

        result.errors?.forEach((error) => {
            ToastManager.error(error);
        });
    };

    const handleRecaptchaChange = () => {
        setShowCaptchaErrors(false);
        recaptchaChanged();
    };

    const domainsText = useMemo(() => {
        return `(${domains.join(", ")})`;
    }, [domains]);

    const placeholderText = `example@${domains[0]}`;

    return (
        <>
            <div>
                <Heading priority={HeadingPriority.Two}>
                    {t("on-demand-enter-email")}
                </Heading>
            </div>
            <Form onSubmit={handleSubmit}>
                <InputFormField
                    label={`${t("field-email")} ${
                        domains.length > 1 ? domainsText : ""
                    }`}
                    placeholder={placeholderText}
                    required
                    onChange={handleEmailInputChange}
                    errorMessages={emailErrors}
                    maxLength={320}
                    isValid={emailErrors.length <= 0}
                    value={email}
                />
                <input
                    type="hidden"
                    name="captcha_settings"
                    value={JSON.stringify(captchaSettings)}
                />
                <input
                    type="hidden"
                    name="orgid"
                    value={captchaSettings.orgId}
                />
                <ReCAPTCHA
                    hl={culture}
                    sitekey={siteKey}
                    onChange={handleRecaptchaChange}
                />
                <CaptchaErrors showCaptchaErrors={showCaptchaErrors} />
                <Button
                    accessibleText={t("submit")}
                    ariaDisabled={disabled || subscriptionFreezeActive}
                    disabled={disabled || subscriptionFreezeActive}
                    onClick={handleSubmit}
                    type={ButtonTypes.Submit}>
                    {t("submit")}
                </Button>
            </Form>
        </>
    );
};

export default JoinGroupOnDemandForm;
