import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { useEffect, useCallback } from "react";
import OfflineBooksService from "utilities/services/offline/offline-books-service";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import useServiceHookResultAtom from "utilities/hooks/use-service-hook-result-atom";
import { OfflineBooksServiceHookResultAtom } from "utilities/atoms/offline-books-service-hook-result-atom";
import useOfflineBookNavigationProperties from "utilities/hooks/domain/offline/use-offline-book-navigation-properties";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseOfflineBooksOptions {
    offlineDeviceId?: number;
}

interface UseOfflineBooksHook
    extends ServiceHookResult<Array<OfflineBookRecord>> {
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useOfflineBooks(
    options: UseOfflineBooksOptions
): UseOfflineBooksHook {
    const { offlineDeviceId } = options;

    const { result, setError, setLoading, setSuccess } =
        useServiceHookResultAtom(OfflineBooksServiceHookResultAtom);

    const { list: listOfflineBooks } = OfflineBooksService.useList();

    const {
        addBook: addBookToOfflineBookRecord,
        addSyncStatus: addSyncStatusToOfflineBookRecord,
    } = useOfflineBookNavigationProperties();

    const loadOfflineBooks = useCallback(async () => {
        if (offlineDeviceId == null) {
            return;
        }

        try {
            setLoading();

            const { resultObjects: offlineBooks } = await listOfflineBooks({
                offlineDeviceId,
            });

            const offlineBooksWithSyncStatus = await Promise.all(
                offlineBooks.map(addSyncStatusToOfflineBookRecord)
            );

            const offlineBooksWithBook = await Promise.all(
                offlineBooksWithSyncStatus.map(addBookToOfflineBookRecord)
            );

            setSuccess(offlineBooksWithBook, offlineBooksWithBook.length);
        } catch (error) {
            setError(error);
        }
    }, [
        addBookToOfflineBookRecord,
        addSyncStatusToOfflineBookRecord,
        listOfflineBooks,
        offlineDeviceId,
        setError,
        setLoading,
        setSuccess,
    ]);

    const refresh = useCallback(() => {
        loadOfflineBooks();
    }, [loadOfflineBooks]);

    useEffect(() => {
        loadOfflineBooks();
    }, [loadOfflineBooks]);

    return {
        ...result,
        refresh,
    };
}

// #endregion Hook
