import { ResultRecord } from "andculturecode-javascript-core";
import Trans from "atoms/i18n/trans";
import { AppNameTm } from "constants/app-name-tm";
import TrainingContractUserRolesErrorKeys from "constants/training-contract-user-roles-error-keys";
import { siteMap } from "internal-sitemap";
import TrainingContractRecord from "models/view-models/training-contract-record";
import TrainingContractUserRoleRecord from "models/view-models/training-contract-user-role-record";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useQueryString from "utilities/hooks/routing/use-query-string";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import { t } from "utilities/localization-utils";
import NumberUtils from "utilities/number-utils";
import TrainingContractUserRolesService from "utilities/services/training-contract-user-roles/training-contract-user-roles-service";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface TrainingContractQueryParams {
    trainingContractAccessCode: string;
}

export const useTrainingContractAccessCode = (
    hasPreexistingTrainingAccount: boolean
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string | JSX.Element>(
        ""
    );
    const [trainingContractUserRoleRecord, setTrainingContractUserRoleRecord] =
        useState<TrainingContractUserRoleRecord | null>(null);

    const { push } = useHistory();
    const { refresh: refreshIdentity } = useRefreshIdentity();
    const { get: getUserConfigApi } = UserConfigurationService.useGet();
    const { setGlobalState } = useGlobalState();
    const { useAzureB2CForSSO } = useFeatureFlags();

    const { queryParams } = useQueryString<TrainingContractQueryParams>();

    const verifiedAccessCode = queryParams.trainingContractAccessCode;

    const handleSuccessfulAccessCode = useCallback(
        async ({ id, accessCode }: Partial<TrainingContractRecord>) => {
            if (NumberUtils.isDefault(id) || NumberUtils.isDefault(accessCode))
                return;

            setIsLoading(true);

            const trainingUserRole = new TrainingContractUserRoleRecord({
                trainingContract: new TrainingContractRecord({
                    id,
                    accessCode,
                }),
            });

            let success = false;

            try {
                const { resultObject: traingUserRole } =
                    await TrainingContractUserRolesService.create(
                        trainingUserRole
                    );

                if (traingUserRole == null) throw new Error();

                setGlobalState((previous) =>
                    previous.clearUnauthorizedResult()
                );

                await getUserConfigApi(
                    {},
                    { returnForbiddenIfErrored: false, setNewestUserRole: true }
                );

                await refreshIdentity();

                success = true;
                setTrainingContractUserRoleRecord(traingUserRole);
            } catch (error) {
                if (error instanceof ResultRecord) {
                    handleError(error);
                } else {
                    ToastManager.error(t("oopsErrorOccurred"));
                }
            } finally {
                setIsLoading(false);
                if (success) push(siteMap.dashboards.user);
            }
        },
        [getUserConfigApi, push, refreshIdentity, setGlobalState]
    );

    useEffect(() => {
        if (hasPreexistingTrainingAccount) {
            setErrorMessages(
                <Trans i18nKey="training-contract-role-already-exists">
                    Your training access has expired. To continue utilizing{" "}
                    {{ appName: AppNameTm }}, please
                    <PlansAnchor>enroll in a new subscription</PlansAnchor>
                </Trans>
            );
        }
    }, [hasPreexistingTrainingAccount, useAzureB2CForSSO]);

    return {
        handleSuccessfulAccessCode,
        verifiedAccessCode: Number(verifiedAccessCode),
        isLoading,
        errorMessages,
        trainingContractUserRoleRecord,
    };
};

const handleError = (error: ResultRecord<TrainingContractUserRoleRecord>) => {
    if (
        error.hasErrorFor(
            TrainingContractUserRolesErrorKeys.NO_TRAINING_CONTRACT_FOUND_ERROR
        )
    ) {
        ToastManager.error(
            t("not-found-resource", {
                resource: t("subscriptionType-Training"),
            })
        );
        return;
    }

    if (
        error.hasErrorFor(
            TrainingContractUserRolesErrorKeys.INVALID_ACCESS_CODE_ERROR
        )
    ) {
        ToastManager.error(t("trainingContractAccessCodeError"));
        return;
    }

    if (
        error.hasErrorFor(
            TrainingContractUserRolesErrorKeys.TRAINING_CONTRACT_USER_ROLE_EXISTS_ERROR
        )
    ) {
        ToastManager.error(
            <Trans i18nKey="training-contract-role-already-exists">
                This email address has already been enrolled. Please{" "}
                <LogInAnchor lowerCase={true} />
                to gain access.
            </Trans>
        );
        return;
    }

    if (
        error.hasErrorFor(
            TrainingContractUserRolesErrorKeys.TRAINING_CONTRACT_USER_ROLE_CREATE_ERROR,
            TrainingContractUserRolesErrorKeys.USER_ROLE_CREATE_ERROR
        )
    ) {
        ToastManager.error(
            t("errors-actionResource", {
                action: t("creating"),
                resource: t("userRole"),
            })
        );
        return;
    }

    ToastManager.error(t("oopsErrorOccurred"));
};
