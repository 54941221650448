import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import * as React from "react";
import StringUtils from "utilities/string-utils";
import { tForCulture } from "utilities/localization-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";

export const TitleConverterId = "TitleConverter";

export interface TitleProps extends XmlConvertedComponentProps {
    description?: React.ReactNode | React.ReactNodeArray | string;
    nfpaLabel?: string;
    parentNode?: string;
}

const Title: React.FunctionComponent<TitleProps> = (props) => {
    const { children, description, parentNode } = props;
    const { publication } = useCurrentPublication();

    const hasDescription =
        description != null &&
        (typeof description === "string" || React.isValidElement(description));

    if (parentNode != null && parentNode.includes("table")) {
        return (
            <caption>
                {StringUtils.hasValue(props.nfpaLabel)
                    ? `${tForCulture(publication?.locale, "table")} ${
                          props.nfpaLabel
                      } `
                    : null}
                {children}
                {hasDescription && (
                    <React.Fragment>
                        <br />
                        <span className="-description">{description}</span>
                    </React.Fragment>
                )}
            </caption>
        );
    }

    return <span>{props.children}</span>;
};

export default Title;
