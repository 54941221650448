const TrainingContractUserRolesErrorKeys = {
    NO_TRAINING_CONTRACT_FOUND_ERROR: "NoTrainingContractFoundException",
    INVALID_ACCESS_CODE_ERROR: "InvalidAccessCodeException",
    TRAINING_CONTRACT_USER_ROLE_EXISTS_ERROR:
        "TrainingContractUserRoleAlreadyExistsException",
    USER_ROLE_CREATE_ERROR: "UserRoleCannotBeCreatedException",
    TRAINING_CONTRACT_USER_ROLE_CREATE_ERROR:
        "TrainingContractUserRoleCannotBeCreatedException",
};

export default TrainingContractUserRolesErrorKeys;
