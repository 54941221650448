import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React, { useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useUserCollection from "utilities/hooks/domain/users/user-collections/use-user-collection";
import StringUtils from "utilities/string-utils";
import { useHeaderData } from "utilities/contexts/use-header-data-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CollectionPageBreadcrumbProps {
    match: any;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CollectionPageBreadcrumb: React.FC<CollectionPageBreadcrumbProps> = (
    props: CollectionPageBreadcrumbProps
) => {
    const { match } = props;
    const { setPageTitle } = useHeaderData({ match });
    const { globalState } = useGlobalState();
    const { resultObject: userCollection } = useUserCollection(
        globalState.currentIdentity?.userId(),
        match?.params?.id
    );

    useEffect(
        () => setPageTitle(userCollection?.name),
        [setPageTitle, userCollection]
    );

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">
                {StringUtils.hasValue(userCollection?.name)
                    ? userCollection?.name
                    : "..."}
            </span>
        </Breadcrumb>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CollectionPageBreadcrumb;

// #endregion Exports
