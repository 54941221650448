import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import * as React from "react";

export interface ListProps extends XmlConvertedComponentProps {}

const List: React.FunctionComponent<ListProps> = (props: ListProps) => {
    const changedModifier =
        props.diffchanged != null ? ` c-code-change -${props.diffchanged}` : "";
    const cssClassName = "c-publication-list" + changedModifier;

    return <ul className={cssClassName}>{props.children}</ul>;
};

export default List;
