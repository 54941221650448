import { Record } from "immutable";
import type GroupInvitation from "models/interfaces/group-invitations/group-invitation";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: GroupInvitation =
    RecordUtils.auditableDefaultValuesFactory<GroupInvitation>({
        acceptedOn: undefined,
        captchaComplete: false,
        email: "",
        expiresOn: undefined,
        groupId: undefined,
        magicLinkUrlTemplate: undefined,
        token: "",
        isUserAlreadyCreated: undefined,
    });

export default class GroupInvitationRecord
    extends Record(defaultValues)
    implements GroupInvitation
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<GroupInvitation>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<GroupInvitation>} values
     */
    public with(values: Partial<GroupInvitation>): GroupInvitationRecord {
        return new GroupInvitationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
