import { Record } from "immutable";
import type YoutubeMetadata from "models/interfaces/external/youtube/youtube-metadata";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: YoutubeMetadata =
    RecordUtils.defaultValuesFactory<YoutubeMetadata>({
        id: "",
        duration: "",
        description: "",
        thumbnailUrl: "",
        title: "",
    });

export default class YoutubeMetadataRecord
    extends Record(defaultValues)
    implements YoutubeMetadata
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: YoutubeMetadata) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<YoutubeMetadata>): YoutubeMetadataRecord {
        return new YoutubeMetadataRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
