import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import CreateGroupInvitationDtoRecord from "models/view-models/group-invitations/create-group-invitation-dto-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface GroupInvitationBasePathParams {}

export interface GroupInvitationResourcePathParams
    extends GroupInvitationBasePathParams {
    groupId: number;
}

/**
 * Search parameters when listing GroupInvitation resources.
 * These get translated to QueryString parameters
 */
export interface GroupInvitationListQueryParams {
    isPending?: boolean;
    token?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "groups/:groupId/invitations";
const resourceType = GroupInvitationRecord;
const createResourceType = CreateGroupInvitationDtoRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const acceptEndpoint = `${resourceEndpoint}/accept`;
const key = "group-invitations";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const GroupInvitationService = {
    /**
     * Attempts to accept the GroupInvitation and add the user to the Group.
     */
    accept: ServiceFactory.update(resourceType, acceptEndpoint),

    /**
     * Create a new GroupInvitation
     */
    create: ServiceFactory.create(createResourceType, baseEndpoint),

    /**
     * Deletes a GroupInvitation
     */
    delete: ServiceFactory.delete(resourceEndpoint),

    /**
     * Retrieve a specific GroupInvitation resource
     */

    get: ServiceFactory.get<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Retrieves a list of GroupInvitation resources based upon supplied search criteria
     */
    list: ServiceFactory.list<
        GroupInvitationRecord,
        GroupInvitationListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Update a GroupInvitation
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for accepting the GroupInvitation in a react component.
     */
    useAccept: ServiceHookFactory.useUpdate(resourceType, acceptEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useNestedCreate<
        CreateGroupInvitationDtoRecord,
        GroupInvitationResourcePathParams
    >(createResourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete:
        ServiceHookFactory.useDelete<GroupInvitationResourcePathParams>(
            resourceEndpoint
        ),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams
    >(resourceType, resourceEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams,
        GroupInvitationListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams,
        GroupInvitationListQueryParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        GroupInvitationRecord,
        GroupInvitationResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default GroupInvitationService;

// #region Exports
