import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import { siteMap } from "internal-sitemap";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import useInviteOnDemand from "utilities/hooks/domain/groups/use-invite-on-demand";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import useTeams from "utilities/hooks/use-teams";
import JoinGroupOnDemandContent from "./join-group-on-demand-content";
import JoinGroupOnDemandLayout from "./join-group-on-demand-layout";
import FrozenSubscriptionsAlert from "molecules/frozen-subscriptions-alert/frozen-subscriptions-alert";
import OnboardingLayout from "templates/onboarding-layout";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";

const BASE_CLASS = "c-join-group-on-demand-page";

interface JoinGroupOnDemandParams {
    groupId: string;
}

const JoinGroupOnDemandPage = () => {
    const { groupId } = useParams<JoinGroupOnDemandParams>();

    const { isLoading, group, createInviteOnDemand } = useInviteOnDemand({
        groupId: parseInt(groupId),
    });

    const { currentIdentity } = useCurrentIdentity();

    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    const { isMemberOfTeam, switchToDifferentTeam } = useTeams();

    if (
        isMemberOfTeam(groupId) &&
        currentIdentity?.getCurrentTeamId.toString() !== groupId
    ) {
        switchToDifferentTeam(groupId);
    }

    const isUserMemberOfCurrentTeam =
        currentIdentity && isMemberOfTeam(groupId);

    if (isUserMemberOfCurrentTeam) {
        return <Redirect to={siteMap.dashboards.user} />;
    }

    return (
        <>
            {subscriptionFreezeActive ? (
                <OnboardingLayout>
                    <FrozenSubscriptionsAlert />
                </OnboardingLayout>
            ) : (
                <JoinGroupOnDemandLayout>
                    <div className={BASE_CLASS}>
                        <div className={`${BASE_CLASS}__content`}>
                            {group == null ? (
                                !isLoading ? (
                                    <Redirect to={siteMap.errors.notFound} />
                                ) : (
                                    <Loader type={LoaderStyles.LinkGlyphGray} />
                                )
                            ) : (
                                <JoinGroupOnDemandContent
                                    isLoading={isLoading}
                                    group={group}
                                    createInviteOnDemand={createInviteOnDemand}
                                />
                            )}
                        </div>
                    </div>
                </JoinGroupOnDemandLayout>
            )}
        </>
    );
};
export default JoinGroupOnDemandPage;
