import { Do, ResultRecord } from "andculturecode-javascript-core";
import JobRecord from "models/view-models/job-record";
import PublicationRecord from "models/view-models/publication-record";
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from "react";
import useUpdatePublication from "utilities/hooks/domain/admin/publications/use-update-publication";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useLoading from "utilities/hooks/use-loading";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import AdminPublicationPublishService from "utilities/services/admin/publications/admin-publication-publish-service";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import JobService from "utilities/services/jobs/job-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { CollectionUtils } from "utilities/collection-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const UPDATE_PUBLICATION_MESSAGE = "Publication has been successfully updated.";
const PUBLISH_PUBLICATION_SUCCESS_MESSAGE =
    "Publication successfully published.";
const PUBLISH_PUBLICATION_ERROR_MESSAGE =
    "There was an issue publishing the publication.";
const UNPUBLISH_SUCCESS_MESSAGE = "Publication successfully unpublished.";
const UNPUBLISH_ERROR_MESSAGE =
    "There was an issue unpublishing the publication.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UsePublicationAdminOptions {
    onPublicationLoadError?: () => void;
    onPublishError?: () => void;
    onPublishSuccess?: (publication: PublicationRecord) => void;
    onUnpublishError?: () => void;
    onUnpublishSuccess?: (publication: PublicationRecord) => void;
    onUpdateError?: () => void;
    onUpdateSuccess?: (publication: PublicationRecord) => void;
    publicationId: number;
}

interface AdminPublicationServiceHook
    extends ServiceHookResult<PublicationRecord> {
    jobs?: Array<JobRecord>;
    publish: (shouldSaveFirst?: boolean) => Promise<void>;
    refresh: () => void;
    saving: boolean;
    setPublication: Dispatch<SetStateAction<PublicationRecord>>;
    unpublish: () => Promise<void>;
    update: (pub?: PublicationRecord | undefined) => Promise<void>;
}
// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function usePublicationAdmin(
    options: UsePublicationAdminOptions
): AdminPublicationServiceHook {
    const useAdminGetService = useMemo(() => true, []); // momeoized so it doesn't cause a re-render loop
    const { update: publishApi } = AdminPublicationPublishService.usePublish();
    const { delete: unpublishApi } = AdminPublicationPublishService.useDelete();
    const { update: updateApi } = AdminPublicationService.useUpdate();
    const { list: listJobsApi } = JobService.useListQuery();
    const { pageErrors, handlePageLoadError, resetPageErrors, setPageErrors } =
        usePageErrors();

    const {
        errors,
        loaded,
        loading,
        refresh: refreshPublication,
        resultObject: publication,
        setPublication,
    } = usePublication({
        publicationId: options.publicationId,
        onPublicationLoadError: options.onPublicationLoadError,
        useAdminService: useAdminGetService,
    });

    const { data: jobsResult, refetch: refreshJobs } = listJobsApi(
        { publicationId: publication.id },
        { enabled: !NumberUtils.isDefault(publication.id) }
    );

    if (CollectionUtils.hasValues(errors)) {
        setPageErrors(errors);
    }

    const handleUpdateSuccess = useCallback(
        (publication: PublicationRecord) => {
            if (options.onUpdateSuccess != null) {
                options.onUpdateSuccess(publication);
                return;
            }
            ToastManager.success(UPDATE_PUBLICATION_MESSAGE);
        },
        [options]
    );

    const { saving: updating, updatePublication } = useUpdatePublication(
        publication,
        handleUpdateSuccess,
        options.onUpdateError
    );

    const [publishing, setPublishing] = useState(false);
    const saving = useLoading(updating, publishing);

    const handlePublishSuccess = useCallback(
        (publication: PublicationRecord) => {
            if (options.onPublishSuccess != null) {
                options.onPublishSuccess(publication);
                return;
            }
            ToastManager.success(PUBLISH_PUBLICATION_SUCCESS_MESSAGE);
        },
        [options]
    );

    const handlePublishError = useCallback(() => {
        if (options.onPublishError != null) {
            options.onPublishError();
            return;
        }

        ToastManager.error(PUBLISH_PUBLICATION_ERROR_MESSAGE);
    }, [options]);

    const handleUnpublishSuccess = useCallback(
        (publication: PublicationRecord) => {
            if (options.onUnpublishSuccess != null) {
                options.onUnpublishSuccess(publication);
                return;
            }

            ToastManager.success(UNPUBLISH_SUCCESS_MESSAGE);
        },
        [options]
    );

    const handleUnpublishError = useCallback(() => {
        if (options.onUnpublishError != null) {
            options.onUnpublishError();
            return;
        }

        ToastManager.error(UNPUBLISH_ERROR_MESSAGE);
    }, [options]);

    const publishPublication = useCallback(
        async (shouldSaveFirst: boolean = false) =>
            Do.try(async () => {
                resetPageErrors();
                setPublishing(true);

                let localPublication = publication;
                if (shouldSaveFirst) {
                    const publicationId = NumberUtils.parseInt(
                        localPublication.id
                    );
                    if (publicationId == null) {
                        return;
                    }
                    const saveResult = await updateApi(localPublication, {
                        id: publicationId,
                    });
                    if (saveResult.resultObject == null) {
                        handlePublishError();
                        handlePageLoadError(saveResult);
                        setPublishing(false);
                        return;
                    }

                    localPublication = saveResult.resultObject;
                }

                const result = await publishApi(localPublication, {
                    id: localPublication.id ?? 0,
                });
                if (result.resultObject == null) {
                    handlePublishError();
                    handlePageLoadError(result);
                    setPublishing(false);
                    return;
                }

                handlePublishSuccess(result.resultObject);
            })
                .catch(
                    (result?: ResultRecord<PublicationRecord>, err?: any) => {
                        handlePublishError();
                        handlePageLoadError(result ?? err);
                    }
                )
                .finally(() => setPublishing(false))
                .getAwaiter(),
        [
            handlePageLoadError,
            handlePublishError,
            handlePublishSuccess,
            publication,
            publishApi,
            resetPageErrors,
            updateApi,
        ]
    );

    const unpublishPublication = useCallback(
        async () =>
            Do.try(async () => {
                setPublishing(true);
                await unpublishApi(publication.id!, { id: publication.id! });
                handleUnpublishSuccess(publication);
            })
                .catch((result?: ResultRecord<Boolean>, err?: any) => {
                    handleUnpublishError();
                    handlePageLoadError(result ?? err);
                })
                .finally(() => setPublishing(false))
                .getAwaiter(),
        [
            unpublishApi,
            handleUnpublishError,
            handleUnpublishSuccess,
            handlePageLoadError,
            publication,
        ]
    );

    const refresh = useCallback(() => {
        refreshPublication();
        refreshJobs();
    }, [refreshPublication, refreshJobs]);

    return {
        errors: pageErrors,
        loaded,
        loading,
        publish: publishPublication,
        refresh,
        resultObject: publication,
        saving,
        jobs: jobsResult?.resultObjects,
        setPublication,
        unpublish: unpublishPublication,
        update: updatePublication,
    };
}
// #endregion Hook
