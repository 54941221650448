import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import React, { PropsWithChildren } from "react";

export interface PublicationSidebarNavProps {
    title?: string;
}

const PublicationSidebarNav: React.FC<
    PropsWithChildren<PublicationSidebarNavProps>
> = (props: PropsWithChildren<PublicationSidebarNavProps>) => {
    const { title } = props;

    return (
        <nav className="c-publication-sidebar-nav">
            <Heading
                cssClassName="c-publication-sidebar-nav__chapter-title"
                priority={HeadingPriority.Four}>
                {title}
            </Heading>
            {props.children}
        </nav>
    );
};

/*
---------------------------------------------------------------------------------------------
#region Exports
---------------------------------------------------------------------------------------------
*/

export default PublicationSidebarNav;

// #endregion Exports
