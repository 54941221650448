import { UseSearchHookProvider } from "utilities/hooks/use-search";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import HitRecord from "models/view-models/search/hit-record";
import useOfflineSearch from "utilities/hooks/aspect/search/offline/use-offline-search-v2";
import useCloudSearch from "utilities/hooks/aspect/search/cloud/use-cloud-search-v2";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";

export interface SearchHitServiceHookResult
    extends ServiceHookResult<HitRecord[]> {
    reset(): void;
    search: () => void;
}

export default function useSearch(
    provider: UseSearchHookProvider,
    searchParams?: SearchListQueryParams
): SearchHitServiceHookResult {
    const memoizedSearchParams = useDeepCompareMemo(
        () => searchParams,
        [searchParams]
    );

    const offline = useOfflineSearch(memoizedSearchParams);
    const cloud = useCloudSearch(memoizedSearchParams);

    const results = provider === UseSearchHookProvider.Cloud ? cloud : offline;

    return results;
}
