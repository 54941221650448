import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import { t } from "utilities/localization-utils";

const NoInvitesAvailable = () => {
    return (
        <div>
            <Heading priority={HeadingPriority.Two}>
                {t("no-invites-available")}
            </Heading>
            <Paragraph>{t("on-demand-no-invites-1")}</Paragraph>
        </div>
    );
};

export default NoInvitesAvailable;
