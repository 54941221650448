import PublicationRecord from "models/view-models/publication-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface PublicationBasePathParams {}

export interface PublicationResourcePathParams
    extends PublicationBasePathParams {
    id?: number;
}

/**
 * Search parameters when listing Publication resources.
 * These get translated to QueryString parameters
 */
export interface PublicationListQueryParams {
    /**
     * (Optional) Code for a Publication to filter results by.
     */
    code?: string;

    /**
     * (Optional) Edition for a Publication to filter results by.
     */
    edition?: string;

    /**
     * (Optional) Array of PublicationIds to filter results by.
     */
    publicationIds?: number[];

    /**
     * (Optional) Array of TopicIds to filter results by.
     */
    topicIds?: number[];

    /**
     * (Option) Search text to search on code, edition and title.
     */
    searchText?: string;

    /**
     * (Optional) If true, only returns the latest printing of each publication matched by the other filters.
     * @default true
     */
    groupByPrinting?: boolean;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "public/publications";
const key = "public-publications";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = PublicationRecord;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const PublicationService = {
    /**
     * Retrieve a specific Publication resource
     */
    get: ServiceFactory.get<PublicationRecord, PublicationResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Publication resources based upon supplied search criteria
     */
    list: ServiceFactory.list<PublicationRecord, PublicationListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useBatchList: ServiceHookFactory.useBatchedList<
        PublicationRecord,
        PublicationListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        PublicationRecord,
        PublicationResourcePathParams
    >(resourceType, resourceEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        PublicationRecord,
        PublicationResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        PublicationRecord,
        PublicationListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useListQuery<
        PublicationRecord,
        PublicationListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Services

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default PublicationService;

// #endregion Exports
