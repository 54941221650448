import PublicationCodeGroup from "models/interfaces/publication-code-group";
import PublicationCodeGroupRecord from "models/view-models/publication-code-group-record";
import PublicationRecord from "models/view-models/publication-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import { ToastManager } from "utilities/toast/toast-manager";
import useFiles from "../files/use-files";

const baseEndpoint = "publications/codes";
const DEFAULT_PUBLICATION_GROUP_TAKE = 10;

interface QueryParams {
    searchText?: string;
    skip?: number;
    take?: number;
}

const usePublicationCodeGroups = () => {
    const [skip, setSkip] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");

    const { list } = ServiceHookFactory.useList<
        PublicationCodeGroupRecord,
        QueryParams
    >(PublicationCodeGroupRecord, baseEndpoint)();

    const [loading, setLoading] = useState(false);
    const [publicationGroups, setPublicationGroups] = useState<
        PublicationCodeGroupRecord[]
    >([]);
    const publications = publicationGroups.reduce(
        (a: PublicationRecord[], b: PublicationCodeGroup) => {
            return a.concat(b.publications);
        },
        []
    );
    const fileIds =
        CollectionUtils.uniqueValuesByProperty(
            publications,
            "coverImageFileId"
        ) ?? [];
    const { resultObject: coverImageFiles } = useFiles({ ids: fileIds });
    const { t } = useLocalization<CultureResources>();
    const errorMessage = t("errors-actionResource_plural", {
        action: t("loading"),
        resource: t("publication_plural"),
    });

    const handleSearch = (newSearchText: string) => {
        if (searchText !== newSearchText) {
            setSkip(0);
            setPublicationGroups([]);
            setSearchText(newSearchText);
        }
    };

    const getPublicationGroups = useCallback(async () => {
        setLoading(true);
        try {
            const { resultObjects: publicationGroups } = await list({
                searchText,
                skip: skip * DEFAULT_PUBLICATION_GROUP_TAKE,
                take: DEFAULT_PUBLICATION_GROUP_TAKE,
            });
            const sortedpublicationGroups =
                sortPublicationCodeGroups(publicationGroups);
            setPublicationGroups((prev) => [
                ...prev,
                ...sortedpublicationGroups,
            ]);
        } catch (error) {
            ToastManager.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }, [skip, errorMessage, list, searchText]);

    useEffect(() => {
        getPublicationGroups();
    }, [getPublicationGroups]);

    useEffect(() => {
        setPublicationGroups((prev) => {
            return prev.map((group) => {
                const publications = group.publications.map((publication) => {
                    const coverImageFile = coverImageFiles.find(
                        (coverImageFile) =>
                            publication.coverImageFileId === coverImageFile.id
                    );
                    return publication.with({ coverImageFile });
                });
                return group.with({ publications });
            });
        });
    }, [coverImageFiles]);

    return {
        setSkip,
        loading,
        publicationGroups,
        setPublicationGroups,
        handleSearch,
    };
};

export default usePublicationCodeGroups;

const sortPublicationCodeGroups = (
    publicationCodeGroups: PublicationCodeGroupRecord[]
) =>
    publicationCodeGroups.sort((a, b) =>
        a.code.localeCompare(b.code, "en", { numeric: true })
    );
