import UserRoleRecord from "models/view-models/user-role-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserRoleBasePathParams {
    /**
     * Id of the User for which the UserRoles relate to
     */
    userId: number;
}

export interface UserRoleResourcePathParams extends UserRoleBasePathParams {
    /**
     * Id of the UserRole resource
     */
    id: number;
}

/**
 * Search parameters when listing UserRole resources
 * These get translated to QueryString parameters
 */
export interface UserRoleListQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/roles";
const resourceType = UserRoleRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserRoleService = {
    /**
     * Retrieve a single UserRole by id
     */
    get: ServiceFactory.get<UserRoleRecord, UserRoleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of UserRoles by userId
     */
    list: ServiceFactory.nestedList<
        UserRoleRecord,
        UserRoleBasePathParams,
        UserRoleListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Updates a single UserRole (For now, the only field to update is eulaAccepted. Must be set to true.)
     */
    update: ServiceFactory.update<UserRoleRecord, UserRoleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGet: ServiceHookFactory.useGet<
        UserRoleRecord,
        UserRoleResourcePathParams
    >(resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useNestedList<
        UserRoleRecord,
        UserRoleBasePathParams,
        UserRoleListQueryParams
    >(resourceType, baseEndpoint),

    useUpdate: ServiceHookFactory.useUpdate<
        UserRoleRecord,
        UserRoleResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserRoleService;

// #endregion Exports
