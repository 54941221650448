import PublicationComponentType from "utilities/enumerations/publication-component-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ServiceEndpoints {
    baseEndpoint: string;
    resourceEndpoint: string;
}

export interface EnhancedContentQueryParams {
    id?: number;
    code?: string;
    edition?: string;
}

export interface EnhancedContentRouteParams {
    annexId?: number;
    articleId?: number;
    chapterId?: number;
    id?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const EnhancedContentRoutes = new Map<
    PublicationComponentType,
    ServiceEndpoints
>([
    [
        PublicationComponentType.Section,
        {
            baseEndpoint: "sections/enhancedcontent",
            resourceEndpoint: "sections/enhancedcontent/:id",
        },
    ],
    [
        PublicationComponentType.Chapter,
        {
            baseEndpoint: "chapters/:chapterId/enhancedcontent",
            resourceEndpoint: "chapters/:chapterId/enhancedcontent/:id",
        },
    ],
    [
        PublicationComponentType.Annex,
        {
            baseEndpoint: "annexes/:annexId/enhancedcontent",
            resourceEndpoint: "annexes/:annexId/enhancedcontent/:id",
        },
    ],
    [
        PublicationComponentType.Article,
        {
            baseEndpoint: "articles/:articleId/enhancedcontent",
            resourceEndpoint: "articles/:articleId/enhancedcontent/:id",
        },
    ],
]);

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Utils
// -------------------------------------------------------------------------------------------------

const getEndpoints = (
    type: PublicationComponentType,
    map: Map<PublicationComponentType, ServiceEndpoints>
) => {
    const endpoints = map.get(type);
    if (endpoints == null) {
        throw TypeError(`Unhandled type: ${type}`);
    }

    return endpoints;
};

// #endregion Utils

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnhancedContentRoutes, getEndpoints };

// #endregion Exports
