import { OfflineSessionConstants } from "constants/offline-session-constants";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
    NetworkInformation,
    NetworkInformationContext,
    NetworkState,
} from "utilities/contexts/network-information/network-information-context";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { CookieUtils } from "utilities/cookie-utils";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import { useOfflineProgressToasts } from "utilities/hooks/domain/offline/use-offline-progress-toasts";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { ToastManager } from "utilities/toast/toast-manager";

export interface NetworkInformationProviderProps {
    networkState?: NetworkState;
}

const NetworkInformationProvider: React.FC<NetworkInformationProviderProps> = (
    props
) => {
    const { children, networkState: initialState } = props;
    const [networkState, setNetworkState] = useState<NetworkState>(
        initializeNetworkState(initialState)
    );
    const { sendCommand } = useServiceWorker();

    useOfflineProgressToasts();

    const loadNetworkInformation = useCallback(() => {
        const connection = getNavigatorConnection();
        const { onLine } = window.navigator;

        const { downlink, downlinkMax, effectiveType, saveData, type } =
            connection ?? {};

        const offlineAt = onLine ? undefined : moment().toISOString();

        if (onLine) {
            CookieUtils.clear(
                OfflineSessionConstants.sessionExpirationCookieName
            );
        }

        const baseState: Pick<NetworkState, "isOnline" | "offlineAt"> = {
            isOnline: onLine,
            offlineAt: offlineAt,
        };

        // skip for non supported browsers.
        const updatedState: NetworkState =
            connection == null
                ? baseState
                : {
                      ...baseState,
                      downlink,
                      downlinkMax,
                      effectiveType,
                      saveData,
                      type,
                  };
        setNetworkState(updatedState);

        LocalStorageUtils.set<NetworkState>(
            LocalStorageKey.NetworkState,
            updatedState
        );

        ToastManager.info(
            `You are now ${updatedState.isOnline ? "online" : "offline"}`
        );

        if (!updatedState.isOnline) {
            sendCommand({
                type: ServiceWorkerCommandTypes.BuildSearchIndex,
            });
        }
    }, [sendCommand]);

    useEffect(() => {
        const handleChange = () => {
            loadNetworkInformation();
        };

        window.addEventListener("online", handleChange);
        window.addEventListener("offline", handleChange);

        return function cancel() {
            window.removeEventListener("online", handleChange);
            window.removeEventListener("offline", handleChange);
        };
    }, [loadNetworkInformation]);

    return (
        <NetworkInformationContext.Provider value={networkState}>
            {children}
        </NetworkInformationContext.Provider>
    );
};

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getNavigatorConnection = ():
    | (NetworkInformation & EventTarget)
    | undefined => {
    if ("connection" in window.navigator) {
        return (window.navigator as any).connection;
    }

    if ("mozConnection" in window.navigator) {
        return (window.navigator as any).mozConnection;
    }

    if ("webkitConnection" in window.navigator) {
        return (window.navigator as any).webkitConnection;
    }

    return undefined;
};

const initializeNetworkState = (
    localNetworkState?: NetworkState
): NetworkState => {
    const { onLine: isOnline } = window.navigator;
    const offlineAt = isOnline ? undefined : localNetworkState?.offlineAt;

    return Object.assign(localNetworkState ?? {}, { isOnline, offlineAt });
};

// #endregion Functions

export default NetworkInformationProvider;
