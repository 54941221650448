import FeatureFlags from "models/interfaces/feature-flags";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocation } from "react-router-dom";
import FeatureFlagUtils from "utilities/feature-flag-utils";
import { EnvironmentUtils } from "andculturecode-javascript-core";

export default function useFeatureFlags(): Required<FeatureFlags> {
    const { globalState } = useGlobalState();
    const { search } = useLocation();

    const defaultFeatureFlags = globalState.getFeatureFlagsWithDefaults();

    const getUrlFeatureFlags = () => {
        if (!EnvironmentUtils.isDevelopment()) {
            return {};
        }

        return FeatureFlagUtils.fromUrl(
            search,
            Object.keys(defaultFeatureFlags)
        );
    };

    return {
        ...defaultFeatureFlags,
        ...getUrlFeatureFlags(),
    };
}
