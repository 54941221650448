import React, { useCallback } from "react";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import EnhancedContentPublishService from "utilities/services/admin/enhanced-content/enhanced-content-publish-service";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentUnpublishActionModalProps
    extends Pick<EnhancedContentPanelProps, "enhancedContent" | "onSuccess"> {
    onClose: () => void;
    updatePublicationPageContext?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_UNPUBLISH_ENHANCED_CONTENT =
    "There was an issue unpublishing this enhanced content";
const SUCCESS_UNPUBLISH_ENHANCED_CONTENT = "Enhanced content unpublished.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentUnpublishActionModal = (
    props: EnhancedContentUnpublishActionModalProps
) => {
    const {
        enhancedContent,
        onClose,
        onSuccess,
        updatePublicationPageContext = false,
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { delete: enhancedContentUnpublishApi } =
        EnhancedContentPublishService.useUnpublish();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Context Hooks
    // -----------------------------------------------------------------------------------------

    const { globalState } = useGlobalState();

    const { updateEnhancedContentOnEdit, enhancedContent: newEnhancedContent } =
        useEnhancedContent({});

    // #endregion Context Hooks

    // -----------------------------------------------------------------------------------------
    // #region Callbacks
    // -----------------------------------------------------------------------------------------

    const unpublishEnhancedContentRecord = useCallback(async () => {
        if (enhancedContent.id != null) {
            const unpublishResponse = await enhancedContentUnpublishApi(
                enhancedContent.id
            );

            if (unpublishResponse.result?.hasErrors()) {
                return false;
            }

            const unpublishedRecord = enhancedContent.with({
                publishedOn: undefined,
                updatedBy: globalState.currentIdentity?.user,
            });

            if (
                updatePublicationPageContext &&
                CollectionUtils.hasValues(newEnhancedContent)
            ) {
                updateEnhancedContentOnEdit(unpublishedRecord);
            }

            onSuccess?.(unpublishedRecord);
            return true;
        }

        return false;
    }, [
        enhancedContent,
        enhancedContentUnpublishApi,
        globalState.currentIdentity,
        onSuccess,
        updateEnhancedContentOnEdit,
        updatePublicationPageContext,
        newEnhancedContent,
    ]);

    // #endregion Callbacks

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleUnpublish = useCallback(async () => {
        try {
            if (await unpublishEnhancedContentRecord()) {
                ToastManager.success(SUCCESS_UNPUBLISH_ENHANCED_CONTENT);
            } else {
                ToastManager.error(ERROR_UNPUBLISH_ENHANCED_CONTENT);
            }
        } catch (error) {
            ToastManager.error(ERROR_UNPUBLISH_ENHANCED_CONTENT);
        }
        onClose();
    }, [unpublishEnhancedContentRecord, onClose]);

    return (
        <ConfirmationModal
            confirmButtonText="Yes, Unpublish"
            isVisible={true}
            label="Confirm Unpublish"
            message="Are you sure you want to unpublish this enhanced content?"
            onCancel={onClose}
            onConfirm={handleUnpublish}
        />
    );
};

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentUnpublishActionModal;

// #endregion Exports
