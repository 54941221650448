import SubscriptionRecord from "models/view-models/subscription-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import SubscriptionService from "utilities/services/subscriptions/subscription-service";

/**
 * Custom hook for getting a Subscription
 * @param id
 */
export default function useSubscription(
    id: number | undefined
): ServiceHookResult<SubscriptionRecord> {
    const [subscription, setSubscription] = useState<SubscriptionRecord>(
        new SubscriptionRecord()
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { get: getSubscriptionApi } = SubscriptionService.useGet();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const getSubscription = async (id: number | undefined) => {
            if (id == null) {
                return;
            }

            setLoading(true);

            try {
                const response = await getSubscriptionApi({
                    id: id,
                });

                const subscription = response.resultObject;

                setSubscription(subscription!);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        getSubscription(id);
    }, [id, getSubscriptionApi, handlePageLoadError, resetPageErrors]);
    return {
        errors,
        loaded,
        loading,
        resultObject: subscription,
    };
}
