import TiaRecord from "models/view-models/tia-record";
import React from "react";
import DateUtils from "utilities/date-utils";

interface TiaBookviewHeadingProps {
    tias: TiaRecord[];
}

const cssBaseClass: string = "c-tia-changes-panel";

const TiaBookviewHeading = (props: TiaBookviewHeadingProps) => {
    const { tias } = props;
    const tiaName = DateUtils.sortByDate(tias, "effectiveDate").at(0)?.name;

    if (tiaName == null) return null;

    return <h3 className={`${cssBaseClass}__bookviewHeading`}>{tiaName}</h3>;
};

export default TiaBookviewHeading;
