import UserLoginRecord from "models/view-models/user-login-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface UserLoginBasePathParams {}

export interface UserLoginResourcePathParams extends UserLoginBasePathParams {
    id: number;
}

/**
 * Search parameters when listing UserLogin resources.
 * These get translated to QueryString parameters
 */
export interface UserLoginListQueryParams {
    userId?: number;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "userlogins";
const key = "userlogins";
const resourceType = UserLoginRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const UserLoginService = {
    key,

    /**
     * Attempts to log in a user. When successful a new UserLogin is created and returned.
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Attempts to log in a user from a cookie set by another NFPA property.
     * When successful a new UserLogin is created and returned.
     */
    // createFromCookie: ServiceFactory.create(
    //     resourceType,
    //     baseEndpointFromCookie
    // ),

    /**
     * Logs out the requested session and soft-deletes the associated UserLogin
     */
    delete: ServiceFactory.delete(resourceEndpoint),

    /**
     * Retrieve a specific UserLogin resource
     */
    // get: ServiceFactory.get<UserLoginRecord, UserLoginResourcePathParams>(
    //     resourceType,
    //     resourceEndpoint
    // ),

    /**
     * Retrieves a list of UserLogin resources based upon supplied search criteria
     */
    list: ServiceFactory.list<UserLoginRecord, UserLoginListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Update details around the current session (ie. keep alive)
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Attempts to log in a user from a cookie set by another NFPA property.
     * When successful a new UserLogin is created and returned.
     */
    // useCreateFromCookie: ServiceHookFactory.useCreate(
    //     resourceType,
    //     baseEndpointFromCookie
    // ),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    // useGet: ServiceHookFactory.useGet<
    //     UserLoginRecord,
    //     UserLoginResourcePathParams
    // >(resourceType, resourceEndpoint),

    // useGetQuery: QueryHookFactory.useGetQuery<
    //     UserLoginRecord,
    //     UserLoginResourcePathParams
    // >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        UserLoginRecord,
        UserLoginListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        UserLoginRecord,
        UserLoginResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default UserLoginService;

// #region Exports
