import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationQueryParams extends PagedQuery {
    relatedCategoryIds?: Array<number>;
    relatedSituationId?: number;
    searchText?: string;

    /**
     * (Optional) Array of SituationIds to filter results by.
     */
    situationIds?: number[];
}

export interface SituationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const key = "situational-navigation-situations";
const countKey = "situational-navigation-situations-count";
const baseEndpoint = "situational-navigation/situations";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SituationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SituationService = {
    count: ServiceFactory.get<Number, undefined, SituationQueryParams>(
        Number,
        `${baseEndpoint}/count`
    ),
    useCount: ServiceHookFactory.useGet<
        Number,
        undefined,
        SituationQueryParams
    >(Number, `${baseEndpoint}/count`),
    useCountQuery: QueryHookFactory.useGetQuery<
        Number,
        undefined,
        SituationQueryParams
    >(countKey, Number, `${baseEndpoint}/count`),

    get: ServiceFactory.get<
        SituationRecord,
        SituationPathParams,
        SituationQueryParams
    >(resourceType, resourceEndpoint),

    useGet: ServiceHookFactory.useGet<
        SituationRecord,
        SituationPathParams,
        SituationQueryParams
    >(resourceType, resourceEndpoint),
    useGetQuery: QueryHookFactory.useGetQuery<
        SituationRecord,
        SituationPathParams,
        SituationQueryParams
    >(key, resourceType, resourceEndpoint),

    list: ServiceFactory.list<SituationRecord, SituationQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useList: ServiceHookFactory.useList<SituationRecord, SituationQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useListQuery: QueryHookFactory.useListQuery<
        SituationRecord,
        SituationQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationService;

// #endregion Exports
