import { useCallback, useState } from "react";
import { SearchHitServiceHookResult } from "utilities/hooks/aspect/search/use-search-v2";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import StringUtils from "utilities/string-utils";
import HitRecord from "models/view-models/search/hit-record";
import useOfflineSearchService from "utilities/hooks/aspect/search/offline/use-offline-search-service";
import usePublications from "utilities/hooks/domain/publications/use-publications";

/**
 * Hook for offline search
 */
export default function useOfflineSearch(
    searchParams?: SearchListQueryParams
): SearchHitServiceHookResult {
    const { get } = useOfflineSearchService();
    const { resultObject: publications } = usePublications();

    const [result, setResult] = useState<ServiceHookResult<HitRecord[]>>(
        ServiceHookResultFactory.initialResult([])
    );

    const searchAsync = useCallback(async (): Promise<void> => {
        if (shouldNotSearch(searchParams?.search)) {
            setResult(ServiceHookResultFactory.initialResult([]));
            return;
        }

        setResult(ServiceHookResultFactory.loadingResult([], result.rowCount));

        try {
            const result = await get(searchParams);
            const { rowCount, resultObjects: hits = [] } = result ?? {};

            const offlineResults = ServiceHookResultFactory.successResult(
                hits.map((h) => h.withPublication(publications)),
                rowCount
            );

            setResult(offlineResults);
        } catch (error) {
            setResult(ServiceHookResultFactory.errorResult([], error));
        }
    }, [get, publications, result.rowCount, searchParams]);

    const search = useCallback(() => {
        searchAsync();
    }, [searchAsync]);

    const reset = useCallback(() => {
        setResult(ServiceHookResultFactory.initialResult([]));
    }, []);

    return {
        ...result,
        reset,
        search,
    };
}

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const shouldNotSearch = (searchTerm?: string) =>
    StringUtils.isEmpty(searchTerm);

// #endregion Functions
