import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import SituationSolutionQueryParams from "utilities/services/situational-navigation/situation-solution-query-params";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SolutionBasePathParams {}
export interface SolutionResourcePathParams extends SolutionBasePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "situational-navigation/solutions";
const resourceType = SolutionRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "situational-navigation-solutions";
const countKey = "situational-navigation-solutions-count";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SolutionService = {
    /**
     * Service implementation of client facing Solutions Get method
     * @param  {SolutionRecord} resourceType
     * @param  {string} resourceEndpoint
     */
    get: ServiceFactory.get<SolutionRecord, SolutionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Service implementation of client facing Solutions Index method
     * @param  {SolutionRecord} resourceType
     * @param  {string} baseEndpoint
     */
    list: ServiceFactory.list<SolutionRecord, SituationSolutionQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Hook wrapper for client facing Solutions Get method.
     * @param  {SolutionRecord} resourceType
     * @param  {string} resourceEndpoint
     */
    useGet: ServiceHookFactory.useGet<
        SolutionRecord,
        SolutionResourcePathParams
    >(resourceType, resourceEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        SolutionRecord,
        SolutionResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Hook wrapper for client facing Solutions Index method
     * @param  {SolutionRecord} resourceType
     * @param  {string} baseEndpoint
     */
    useList: ServiceHookFactory.useList<
        SolutionRecord,
        SituationSolutionQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useListQuery<
        SolutionRecord,
        SituationSolutionQueryParams
    >(key, resourceType, baseEndpoint),

    count: ServiceFactory.get<Number, undefined, SituationSolutionQueryParams>(
        Number,
        `${baseEndpoint}/count`
    ),

    useCount: ServiceHookFactory.useGet<
        Number,
        undefined,
        SituationSolutionQueryParams
    >(Number, `${baseEndpoint}/count`),

    useCountQuery: QueryHookFactory.useGetQuery<
        Number,
        undefined,
        SituationSolutionQueryParams
    >(countKey, Number, `${baseEndpoint}/count`),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionService;

// #endregion Exports
