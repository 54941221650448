import TrainingContractErrorKey from "constants/training-contract-error-keys";
import TrainingContractRecord from "models/view-models/training-contract-record";
import { useCallback, useState } from "react";
import TrainingContractsService from "utilities/services/training-contracts/training-contracts-service";

export default function useGetTrainingContract() {
    const [isLoading, setIsLoading] = useState(false);
    const [trainingContractRecord, setTrainingContractRecord] =
        useState<TrainingContractRecord | null>(null);
    const [isNotFound, setIsNotFound] = useState(false);

    const getTrainingContract = useCallback(
        async (contractId: number | undefined) => {
            if (contractId == null) return;

            setIsLoading(true);

            try {
                const result = await TrainingContractsService.get({
                    contractId,
                });

                if (result.resultObject === undefined)
                    throw new Error(
                        TrainingContractErrorKey.NO_TRAINING_CONTRACT_FOUND_ERROR
                    );

                if (!result.resultObject.hasRegistrationPage)
                    throw new Error(
                        TrainingContractErrorKey.TRAINING_CONTRACT_REGISTRATION_PAGE_NOT_ALLOWED
                    );

                setTrainingContractRecord(result.resultObject);
            } catch (error) {
                setIsNotFound(true);
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    return {
        getTrainingContract,
        isLoading,
        isNotFound,
        trainingContractRecord,
    };
}
