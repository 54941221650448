import { useCallback, useState } from "react";

export interface RefreshTokenHookResponse {
    refresh: () => void;
    refreshToken: boolean;
}

export default function useRefreshToken() {
    const [refreshToken, setRefreshToken] = useState(false);
    const refresh = useCallback(() => {
        setRefreshToken((refreshToken: boolean) => !refreshToken);
    }, [setRefreshToken]);

    return {
        refresh,
        refreshToken,
    };
}
