import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminEnhancedContentPublishPathParams {
    id: number;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const resourceType = EnhancedContentRecord;
const resourceEndpoint = "admin/enhanced-content/:id/publish";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const EnhancedContentPublishService = {
    publish: ServiceFactory.update<
        EnhancedContentRecord,
        AdminEnhancedContentPublishPathParams
    >(resourceType, resourceEndpoint),

    usePublish: ServiceHookFactory.useUpdate<
        EnhancedContentRecord,
        AdminEnhancedContentPublishPathParams
    >(resourceType, resourceEndpoint),

    unpublish:
        ServiceFactory.delete<AdminEnhancedContentPublishPathParams>(
            resourceEndpoint
        ),

    useUnpublish:
        ServiceHookFactory.useDelete<AdminEnhancedContentPublishPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentPublishService;

// #region Exports
