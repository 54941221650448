import React, { ReactNode } from "react";
import AlertLevels from "constants/alert-levels";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Paragraph from "atoms/typography/paragraph";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { DataTestAttributes } from "interfaces/data-test-attributes";

export interface AlertNoticeProps
    extends Pick<DataTestAttributes, "dataTestId"> {
    buttonText?: string | null;
    description?: string | undefined;
    icon?: Icons;
    level: AlertLevels;
    onClick?: () => void;
    title: string | undefined;
    children?: ReactNode;
}

const CSS_BASE_CLASS = "c-alert-notice";

const AlertNotice: React.FunctionComponent<AlertNoticeProps> = (
    props: AlertNoticeProps
) => {
    const {
        level,
        title,
        dataTestId,
        description,
        onClick,
        buttonText = null,
        children,
    } = props;
    let modifier = "";
    let icon = Icons.Information;

    switch (level) {
        case AlertLevels.Warning:
            icon = props.icon ?? Icons.Information;
            modifier = "-warning";
            break;
        case AlertLevels.Error:
            icon = props.icon ?? Icons.Warning;
            modifier = "-error";
            break;
        default:
            icon = props.icon ?? Icons.Information;
            break;
    }

    return (
        <div
            className={`${CSS_BASE_CLASS} ${modifier}`}
            data-test-id={dataTestId}>
            <Icon type={icon} size={IconSizes.XLarge} />
            <Heading priority={HeadingPriority.One}>{title}</Heading>
            <Paragraph>{description}</Paragraph>
            {children}
            {buttonText && (
                <Button style={ButtonStyles.TertiaryAlt} onClick={onClick}>
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default AlertNotice;
