import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TableOfContentsBookmarkIndicatorProps {
    hasBookmark: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TableOfContentsBookmarkIndicator: React.FC<TableOfContentsBookmarkIndicatorProps> =
    (props: TableOfContentsBookmarkIndicatorProps) => {
        if (!props.hasBookmark) {
            return null;
        }

        return <Icon type={Icons.Bookmark} size={IconSizes.Base} />;
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default TableOfContentsBookmarkIndicator;

// #endregion Exports
