import Anchor from "atoms/anchors/anchor";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import React, { useCallback, useEffect, useState } from "react";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import { ScrollUtils } from "utilities/scroll-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CategorySidebarListItemsProps {
    baseClassName: string;
    items: Array<CategoryRecord>;
    type: CategoryType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategorySidebarListItems: React.FC<CategorySidebarListItemsProps> = (
    props: CategorySidebarListItemsProps
) => {
    const { context } = useAdminEditorPageContext();
    const [previousItemCount, setPreviousItemCount] = useState(0);

    const isSelected = useCallback(
        (category: CategoryRecord) => context.currentValue?.id === category.id,
        [context.currentValue]
    );

    const getListItemClassName = (category: CategoryRecord): string => {
        const classNames = [
            `${props.baseClassName}__content__left__list__item`,
            "c-button",
            "-tertiary-alt",
        ];

        if (isSelected(category)) {
            classNames.push("-selected");
        }

        return classNames.join(" ");
    };

    useEffect(() => {
        if (props.items.length > previousItemCount) {
            // scroll to newly created item in the sidebar
            const elementId = `editor-sidebar-item-${
                props.items[props.items.length - 1].id
            }`;
            ScrollUtils.scrollToElementBySelector(`#${elementId}.-selected`);
        }

        setPreviousItemCount(props.items.length);
    }, [props.items, previousItemCount]);

    return (
        <React.Fragment>
            {props.items.map((category: CategoryRecord) => (
                <Anchor
                    cssClassName={getListItemClassName(category)}
                    id={`editor-sidebar-item-${category.id}`}
                    key={category.id}
                    to={category.getEditPageRoute()!}>
                    <div
                        className={`${props.baseClassName}__content__left__list__item__title`}>
                        <div className="-text">{category.title}</div>
                    </div>
                    <div
                        className={`${props.baseClassName}__content__left__list__item__subtitle`}>
                        {category.description}
                    </div>
                </Anchor>
            ))}
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategorySidebarListItems;

// #endregion Exports
