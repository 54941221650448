import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import NumberUtils from "utilities/number-utils";
import AdminSituationRelationshipService from "utilities/services/admin/situational-navigation/situations/admin-situation-relationship-service";
import AdminSituationService from "utilities/services/admin/situational-navigation/situations/admin-situation-service";
import AdminSolutionService from "utilities/services/admin/situational-navigation/solutions/admin-solution-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook wrapper to handle state and api interactions for SituationRelationships
 * in the administrative editors
 *
 * @param  {string} situationId?
 */
export default function useAdminSituationRelationships(situationId?: string) {
    // -----------------------------------------------------------------------------------------
    // #region State Hooks
    // -----------------------------------------------------------------------------------------

    const [initialSituationRelationships, setInitialSituationRelationships] =
        useState<Array<SituationRelationshipRecord>>([]);
    const [initialSolutions, setInitialSolutions] = useState<
        Array<SolutionRecord>
    >([]);
    const [loadingRelatedResults, setLoadingRelatedResults] = useState(false);
    const [situationRelationships, setSituationRelationships] = useState<
        Array<SituationRelationshipRecord>
    >([]);
    const [solutions, setSolutions] = useState<Array<SolutionRecord>>([]);
    const [initialSituations, setInitialSituations] = useState<
        Array<SituationRecord>
    >([]);
    const [situations, setSituations] = useState<Array<SituationRecord>>([]);

    // #endregion State Hooks

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listSolutionsApi } = AdminSolutionService.useList();
    const { create: createSituationRelationshipApi } =
        AdminSituationRelationshipService.useCreate();
    const { delete: deleteSituationRelationshipApi } =
        AdminSituationRelationshipService.useDelete();
    const { list: listSituationRelationshipsApi } =
        AdminSituationRelationshipService.useList();
    const { update: updateSituationRelationshipApi } =
        AdminSituationRelationshipService.useUpdate();
    const { list: listSituationsApi } = AdminSituationService.useList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    const initializeRelatedResults = useCallback(
        (
            solutions?: Array<SolutionRecord>,
            situations?: Array<SituationRecord>,
            situationRelationships?: Array<SituationRelationshipRecord>
        ) => {
            const collapsedAndSorted =
                CollectionUtils.collapseDisplaySequencesAndSort(
                    situationRelationships ?? [],
                    (
                        s: SituationRelationshipRecord,
                        displaySequenceDraft: number
                    ) => s.with({ displaySequenceDraft }),
                    (s: SituationRelationshipRecord) => s.displaySequenceDraft
                );
            setInitialSituationRelationships(collapsedAndSorted);
            setSituationRelationships(collapsedAndSorted);
            setInitialSolutions(solutions ?? []);
            setSolutions(solutions ?? []);
            setInitialSituations(situations ?? []);
            setSituations(situations ?? []);
        },
        []
    );

    const updateRelationships = useCallback(
        async (situationId?: number) => {
            if (situationId == null) {
                return;
            }

            setLoadingRelatedResults(true);

            try {
                const relationshipsToDelete =
                    initialSituationRelationships.filter(
                        (r: SituationRelationshipRecord) =>
                            !situationRelationships.some(
                                (s: SituationRelationshipRecord) =>
                                    s.id === r.id
                            )
                    );
                const relationshipsToCreate = situationRelationships.filter(
                    (r: SituationRelationshipRecord) => r.id == null
                );
                const relationshipsToUpdate = situationRelationships.filter(
                    (current: SituationRelationshipRecord) => {
                        if (current.id == null) {
                            return false;
                        }

                        const initial = initialSituationRelationships.find(
                            (initial: SituationRelationshipRecord) =>
                                initial.id === current.id
                        );
                        if (initial == null) {
                            return false;
                        }

                        return (
                            initial.displaySequenceDraft !==
                                current.displaySequenceDraft ||
                            initial.hotSpotXDraft !== current.hotSpotXDraft ||
                            initial.hotSpotYDraft !== current.hotSpotYDraft
                        );
                    }
                );

                await Promise.all<any>([
                    ...relationshipsToDelete.map(
                        (r: SituationRelationshipRecord) =>
                            deleteSituationRelationshipApi(r.id!, {
                                situationId: situationId!,
                            })
                    ),
                    ...relationshipsToCreate.map(
                        (r: SituationRelationshipRecord) =>
                            createSituationRelationshipApi(
                                r.with({ situationId }),
                                {
                                    situationId: situationId!,
                                }
                            )
                    ),
                    ...relationshipsToUpdate.map(
                        (r: SituationRelationshipRecord) =>
                            updateSituationRelationshipApi(
                                r.with({ situationId }),
                                {
                                    situationId: situationId!,
                                }
                            )
                    ),
                ]);

                const [
                    refreshSolutionsResult,
                    refreshSituationsResult,
                    refreshRelationshipsResult,
                ] = await Promise.all([
                    listSolutionsApi({ situationId: situationId! }),
                    listSituationsApi({ relatedSituationId: situationId }),
                    listSituationRelationshipsApi({
                        situationId: situationId!,
                    }),
                ]);

                initializeRelatedResults(
                    refreshSolutionsResult.resultObjects,
                    refreshSituationsResult.resultObjects,
                    refreshRelationshipsResult.resultObjects
                );
            } catch (e) {
                ToastManager.error(
                    "There was an issue updating related results."
                );
            }

            setLoadingRelatedResults(false);
        },
        [
            createSituationRelationshipApi,
            deleteSituationRelationshipApi,
            initializeRelatedResults,
            initialSituationRelationships,
            listSituationRelationshipsApi,
            listSituationsApi,
            listSolutionsApi,
            situationRelationships,
            updateSituationRelationshipApi,
        ]
    );

    useEffect(() => {
        const loadRelatedResults = async (id?: string) => {
            const situationId = NumberUtils.parseInt(id);
            if (situationId == null) {
                return;
            }
            setLoadingRelatedResults(true);

            try {
                const [relationshipsResult, slnResult, situationsResult] =
                    await Promise.all([
                        listSituationRelationshipsApi({
                            situationId,
                        }),
                        listSolutionsApi({ situationId }),
                        listSituationsApi({ relatedSituationId: situationId }),
                    ]);

                if (
                    CollectionUtils.isEmpty(relationshipsResult.resultObjects)
                ) {
                    initializeRelatedResults();
                    setLoadingRelatedResults(false);
                    return;
                }

                initializeRelatedResults(
                    slnResult.resultObjects,
                    situationsResult.resultObjects,
                    relationshipsResult.resultObjects
                );
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading related solutions."
                );
            }

            setLoadingRelatedResults(false);
        };

        loadRelatedResults(situationId);
    }, [
        initializeRelatedResults,
        listSituationRelationshipsApi,
        listSituationsApi,
        listSolutionsApi,
        situationId,
    ]);

    // #endregion Public Methods

    return {
        initializeRelatedResults,
        initialSituationRelationships,
        initialSituations,
        initialSolutions,
        loadingRelatedResults,
        setSituationRelationships,
        setSituations,
        setSolutions,
        situationRelationships,
        situations,
        solutions,
        updateRelationships,
    };
}
