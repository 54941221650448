import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import PublicationRecord from "models/view-models/publication-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { useEffect, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import { CollectionUtils } from "utilities/collection-utils";
import { ButtonStyles } from "atoms/constants/button-styles";
import Paragraph from "atoms/typography/paragraph";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import SearchModalScopeFilter, {
    SearchScope,
} from "organisms/modals/search-modal/filters/search-modal-scope-filter";
import { useAtomValue } from "jotai/utils";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import SearchModalPublicationFilter from "organisms/modals/search-modal/filters/search-modal-publication-filter";
import SearchModalContentFilter from "organisms/modals/search-modal/filters/search-modal-content-filter";
import { SearchModalResultType } from "organisms/modals/search-modal/utils/search-modal-reducer";
import { AriaRole } from "utilities/enumerations/aria-role";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import SearchModalEnhancedContentFilter from "./search-modal-enhanced-content-filter";
import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalFiltersContainerProps {
    codesEntityTypes?: Array<string>;
    directEntityTypes?: Array<string>;
    enhancedContentTypes?: Array<string>;
    favoritePublications?: Array<PublicationRecord>;
    isModalOpen: boolean;
    onClearFilters: () => void;
    onCodesEntityTypesUpdated: (codesEntityTypes: Array<string>) => void;
    onDirectEntityTypesUpdated: (directEntityTypes: Array<string>) => void;
    onEnhancedContentTypesUpdated: (
        enhancedContentTypes: Array<string>
    ) => void;
    onModalClose: () => void;
    onSelectedPublicationsUpdated: (
        selectedPublications: Array<number>
    ) => void;
    publications?: Array<PublicationRecord>;
    resetPagination: () => void;
    searchResultType: SearchModalResultType;
    selectedPublications?: Array<number>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-search-modal-filters";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalFiltersContainer: React.FC<
    SearchModalFiltersContainerProps
> = (props: SearchModalFiltersContainerProps) => {
    const {
        codesEntityTypes = [],
        directEntityTypes = [],
        enhancedContentTypes = [],
        isModalOpen,
        onClearFilters,
        onModalClose,
        resetPagination,
        searchResultType,
        selectedPublications = [],
    } = props;

    const { publication } = useCurrentPublication();
    const currentPublicationEntity = useAtomValue(CurrentPublicationEntityAtom);

    const initializeSearchScope = (): SearchScope => {
        if (currentPublicationEntity != null) {
            return SearchScope.CurrentLocation;
        }

        if (publication.isPersisted()) {
            return SearchScope.CurrentPublication;
        }

        return SearchScope.SelectPublications;
    };

    const [searchScope, setSearchScope] = useState<SearchScope>(
        initializeSearchScope
    );

    const { t } = useLocalization();

    useEffect(() => {
        resetPagination();
    }, [searchScope, resetPagination]);

    const selectedEntityTypes =
        searchResultType === SearchModalResultType.Codes
            ? codesEntityTypes
            : directEntityTypes;

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const hasFilters = () => {
        if (searchScope === SearchScope.SelectPublications) {
            return (
                CollectionUtils.hasValues(selectedEntityTypes) ||
                CollectionUtils.hasValues(selectedPublications) ||
                // enhanced content is selected by default
                !enhancedContentTypes.includes(
                    PublicationEntityTypeConstants.EnhancedContent
                )
            );
        }

        return CollectionUtils.hasValues(selectedEntityTypes);
    };

    const filterSections = (
        <React.Fragment>
            <SearchModalScopeFilter
                {...props}
                searchScope={searchScope}
                onSearchScopeUpdate={setSearchScope}
            />
            <SearchModalEnhancedContentFilter {...props} />
            <SearchModalPublicationFilter
                {...props}
                searchScope={searchScope}
            />
            <SearchModalContentFilter {...props} searchScope={searchScope} />
        </React.Fragment>
    );

    if (isMobile) {
        return (
            <Modal
                closeButtonStyle={ModalCloseButtonStyle.Hidden}
                closeDialog={onModalClose}
                cssClassName={`${BASE_CLASS}__mobile -modal`}
                isVisible={isModalOpen}
                label={t("filter_plural")}
                transition={ModalTransitions.SlideUp}
                type={ModalTypes.Bottom}>
                <div className={`${BASE_CLASS}__header`}>
                    <Paragraph>{t("filter")}</Paragraph>
                    <Button
                        onClick={onModalClose}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Secondary}>
                        {t("close")}
                    </Button>
                </div>
                <div className={`${BASE_CLASS}__mobile__content`}>
                    {filterSections}
                </div>
                <div className={`${BASE_CLASS}__mobile__footer`}>
                    <Button
                        onClick={onClearFilters}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Tertiary}>
                        {t("reset")}
                    </Button>
                    <Button onClick={onModalClose} size={ButtonSizes.Small}>
                        {t("actionItem", {
                            action: t("show"),
                            item: t("result_plural"),
                        })}
                    </Button>
                </div>
            </Modal>
        );
    }
    return (
        <div
            aria-label={t("searchModal-ResultFilters")}
            className={BASE_CLASS}
            role={AriaRole.Search}>
            <div className={`${BASE_CLASS}__header`}>
                <Heading priority={HeadingPriority.Three}>
                    {t("filter_plural")}
                </Heading>
                {hasFilters() && (
                    <div className={`${BASE_CLASS}__filter-action`}>
                        <Button
                            cssClassName="-link"
                            onClick={onClearFilters}
                            size={ButtonSizes.Small}>
                            {`${t("reset")} ${t("all")}`}
                        </Button>
                    </div>
                )}
            </div>
            {filterSections}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalFiltersContainer;

// #endregion Exports
