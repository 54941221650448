/**
 * Constants for maintaining name, value and id's for Salesforce forms.
 */
export const SalesForceFormConstants = {
    CancellationReason: {
        id: "00N1T00000AK3gh",
        name: "00N1T00000AK3gh",
    },
    Description: {
        name: "description",
        value: "Cancel my COD Subscription",
    },
    Email: {
        name: "email",
    },
    Phone: {
        name: "phone",
    },
    Name: {
        name: "name",
    },
    OrgId: {
        name: "orgid",
    },
    Other: {
        id: "00N1T00000AK3gm",
        name: "00N1T00000AK3gm",
    },
    RecordType: {
        name: "recordType",
        value: "01250000000QxNB",
    },
    ReturnUrl: {
        name: "retURL",
    },
    Subject: {
        name: "subject",
        value: "COD Cancel",
    },
    SubscriptionPin: {
        name: "00N1T00000AKA6b",
    },
    TeamName: {
        name: "00N1T00000AKA6l",
    },
    UseWebToCase: {
        name: "00N50000002y48J",
        value: "1",
    },
    WebId: {
        name: "00N50000002ib6M",
    },
};
