import { atom, useAtom } from "jotai";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useCallback, useState } from "react";
import { t } from "utilities/localization-utils";
import UserBookmarkService, {
    ListBookmarksQueryParams,
} from "utilities/services/user-bookmarks/user-bookmark-service";
import { ToastManager } from "utilities/toast/toast-manager";

export const BookmarksAtom = atom<UserBookmarkRecord[]>([]);

const ERROR_LOADING_USER_BOOKMARKS = t("error-loading-bookmarks");

export default function useBookmarks() {
    const [bookmarks, setBookmarks] = useAtom(BookmarksAtom);
    const [loading, setLoading] = useState(false);

    const addBookmark = (bookmark: UserBookmarkRecord) =>
        setBookmarks([...bookmarks, bookmark]);

    const getUserBookmarks = (externalId: string, userId?: number) => {
        // copied this from the publication context record
        // if the external id doesn't exist the .find() will return undefined
        // it looks like we loop through the array twice for no reason
        if (!bookmarks.some((b) => b.externalId === externalId)) return;

        const filter =
            userId == null
                ? (e: UserBookmarkRecord) => e.externalId === externalId
                : (e: UserBookmarkRecord) =>
                      e.externalId === externalId && e.userId === userId;

        return bookmarks.find(filter);
    };

    const removeUserBookmark = (bookmark: UserBookmarkRecord) =>
        setBookmarks(bookmarks.filter((b) => b.id !== bookmark.id));

    const updateBookmark = (bookmark: UserBookmarkRecord) => {
        if (!bookmark.isPersisted()) return;

        setBookmarks((prev) =>
            prev.map((b) => (b.id === bookmark.id ? bookmark : b))
        );
    };

    const loadBookmarks = useCallback(
        async (params: ListBookmarksQueryParams) => {
            setLoading(true);

            try {
                const result = await UserBookmarkService.listV2(params);

                setBookmarks(result.resultObjects);
            } catch (error) {
                ToastManager.error(ERROR_LOADING_USER_BOOKMARKS);
            } finally {
                setLoading(false);
            }
        },
        [setBookmarks]
    );

    return {
        loading,
        bookmarks,
        addBookmark,
        loadBookmarks,
        getUserBookmarks,
        removeUserBookmark,
        setBookmarks,
        updateBookmark,
    };
}
