import IdentityRecord from "models/view-models/identity-record";
import UserLoginRecord from "models/view-models/user-login-record";
import { useCallback } from "react";
import RoleService from "utilities/services/roles/role-service";
import UserRoleService from "utilities/services/users/user-roles/user-role-service";
import UserService from "utilities/services/users/user-service";
import UserRoleGroupService from "utilities/services/user-role-groups/user-role-group-service";
import GroupService from "utilities/services/groups/group-service";
import NumberUtils from "utilities/number-utils";
import UserSettingService from "utilities/services/user-settings/user-setting-service";
import AuthService from "utilities/services/auth/auth-service";

/**
 * Custom hook providing utility functions for the current identity of the user
 */
export default function useIdentity() {
    const { get: getUser } = UserService.useGet();
    const { get: getAuth } = AuthService.useGet();
    const { list: listUserRoles } = UserRoleService.useList();
    const { list: listRoles } = RoleService.useList();
    const { list: listUserRoleGroups } = UserRoleGroupService.useList();
    const { list: listGroups } = GroupService.useList();
    const { list: listUserSettings } = UserSettingService.useList();

    /**
     * Build the identity record including the current User, UserLogin and related navigation properties
     */
    const buildCurrentIdentity = async (
        userLogin: UserLoginRecord | undefined
    ): Promise<IdentityRecord | undefined> => {
        if (
            userLogin == null ||
            NumberUtils.isDefault(userLogin.id) ||
            NumberUtils.isDefault(userLogin.userId)
        ) {
            return;
        }

        const userLoginId = userLogin.id!;
        const userId = userLogin.userId!;

        const getUserPromise = getUser({
            id: userId,
        });
        const listGroupsPromise = listGroups({ userId });
        const listRolesPromise = listRoles();
        const getUserLoginPromise = getAuth({
            id: userLoginId,
        });
        const listUserRoleGroupsPromise = listUserRoleGroups({
            userId,
        });
        const listUserRolesPromise = listUserRoles({ userId });
        const listUserSettingsPromise = listUserSettings({
            userId,
        });

        const [
            getUserResponse,
            listGroupsResponse,
            listRolesResponse,
            getUserLoginResponse,
            listUserRoleGroupsResponse,
            listUserRolesResponse,
            listUserSettingsResponse,
        ] = await Promise.all([
            getUserPromise,
            listGroupsPromise,
            listRolesPromise,
            getUserLoginPromise,
            listUserRoleGroupsPromise,
            listUserRolesPromise,
            listUserSettingsPromise,
        ]);

        const { resultObject: user, result: userResult } = getUserResponse;

        if (userResult?.hasErrors()) {
            return undefined;
        }

        const { resultObjects: groups } = listGroupsResponse;
        const { resultObjects: roles } = listRolesResponse;
        const { resultObject: updatedUserLogin } = getUserLoginResponse;
        const { resultObjects: userRoleGroups } = listUserRoleGroupsResponse;
        const { resultObjects: userRoles } = listUserRolesResponse;
        const { resultObjects: userSettings } = listUserSettingsResponse;

        return new IdentityRecord({
            groups,
            roles,
            user,
            userLogin: updatedUserLogin,
            userRoleGroups,
            userRoles,
            userSettings,
        });
    };

    return {
        buildCurrentIdentity: useCallback(buildCurrentIdentity, [
            getUser,
            getAuth,
            listGroups,
            listRoles,
            listUserRoleGroups,
            listUserRoles,
            listUserSettings,
        ]),
    };
}
