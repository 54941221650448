import { Record } from "immutable";

export interface LinkResourceModel {
    title: string;
    link: string;
}

const defaultLinkResourceRecord: LinkResourceModel = {
    title: "",
    link: "",
};

class LinkResourceRecord
    extends Record(defaultLinkResourceRecord)
    implements LinkResourceModel
{
    constructor(params?: LinkResourceModel) {
        if (params == null) {
            params = Object.assign({}, defaultLinkResourceRecord);
        }
        super(params);
    }
}

export default LinkResourceRecord;
