import { useLocalization } from "utilities/hooks/use-localization";
import React, { useState } from "react";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Modal, { ModalProps } from "molecules/modals/modal";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHistory } from "react-router-dom";
import BillingCycleRadioButtons from "organisms/subscription-card/billing-cycle-radio-buttons";
import EcommerceRedirectionModal from "organisms/subscription-card/ecommerce-redirection-modal";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import { BillingCycleOption } from "constants/billing-cycle-options";
import SubscriptionType from "organisms/enums/subscription-type";
import TeamSizeSelect from "organisms/subscription-card/team-size-select";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SelectBillingCycleModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    onCheck: (option?: BillingCycleOption) => void;
    selectedBillingCycle?: BillingCycleOption;
    selectedSubscriptionType?: SubscriptionType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-select-billing-cycle-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectBillingCycleModal: React.FunctionComponent<SelectBillingCycleModalProps> =
    (props) => {
        const {
            closeDialog,
            isVisible,
            onCheck: handleCheck,
            selectedBillingCycle,
            selectedSubscriptionType,
        } = props;

        const { t } = useLocalization<CultureResources>();

        const [teamSize, setTeamSize] = useState<SubscriptionTeamSize>();
        const [hasBillingCycleError, setHasBillingCycleError] =
            useState<boolean>(false);
        const [hasTeamSizeError, setHasTeamSizeError] =
            useState<boolean>(false);
        const [redirecting, setRedirecting] = useState<boolean>(false);
        const { globalState } = useGlobalState();
        const history = useHistory();

        const { useAzureB2CForSSO } = useFeatureFlags();

        const [confirmButtonText] = useState<string>(
            globalState.isAuthenticated()
                ? t("billingCycle-goToNfpaToPurchase")
                : t("continue")
        );

        const onClose = () => {
            if (closeDialog != null) {
                closeDialog();
            }

            // Wipe out their selected plan if they are closing the modal.
            handleCheck(undefined);
        };

        const handleBillingCycleCheck = (option: BillingCycleOption) => {
            setHasBillingCycleError(false);
            handleCheck(option);
        };

        const handleTeamSizeChange = (teamSize: SubscriptionTeamSize) => {
            setHasTeamSizeError(false);
            setHasBillingCycleError(false);
            setTeamSize(teamSize);
            handleCheck(undefined);
        };

        const handleConfirm = () => {
            if (
                selectedSubscriptionType === SubscriptionType.Team &&
                teamSize == null
            ) {
                setHasTeamSizeError(true);
                return;
            }

            if (
                selectedBillingCycle == null ||
                selectedSubscriptionType == null
            ) {
                setHasBillingCycleError(true);
                return;
            }

            if (useAzureB2CForSSO) {
                // Change on path, all options are known
                const route =
                    globalState?.systemSettings?.getEcommerceCheckoutUrl(
                        "",
                        {},
                        {
                            subscriptionType: selectedSubscriptionType,
                            billingCycle: selectedBillingCycle.cycle,
                            teamSize: teamSize,
                            autorenew: selectedBillingCycle.autoRenew,
                        },
                        true
                    );
                window.location.href = route!;
            } else {
                if (globalState.isAuthenticated()) {
                    setRedirecting(true);
                    return;
                }

                // If we are unauthenticated, we need to redirect to the registration page. No need for the
                // redirection timeout.
                history.push(
                    RouteUtils.getRegistrationPageRoute(
                        selectedSubscriptionType,
                        selectedBillingCycle?.pin
                    )
                );
                return;
            }
        };

        if (redirecting) {
            return (
                <EcommerceRedirectionModal
                    pin={selectedBillingCycle!.pin}
                    transition={ModalTransitions.None}
                />
            );
        }

        const subscriptionType =
            selectedSubscriptionType != null
                ? t(
                      `subscriptionType-${selectedSubscriptionType.toString()}` as keyof CultureResources
                  )
                : "";

        return (
            <Modal
                closeDialog={onClose}
                cssClassName={CSS_BASE_CLASS_NAME}
                isVisible={isVisible}
                label={t("billingCycle-selectABillingCycle", {
                    subscriptionType,
                })}
                transition={ModalTransitions.Fade}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <h2>
                        {t("billingCycle-planDetails", { subscriptionType })}
                    </h2>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    {selectedSubscriptionType === SubscriptionType.Team && (
                        <TeamSizeSelect
                            errored={hasTeamSizeError}
                            onChange={handleTeamSizeChange}
                            value={teamSize}
                        />
                    )}
                    {(selectedSubscriptionType !== SubscriptionType.Team ||
                        teamSize != null) && (
                        <BillingCycleRadioButtons
                            errored={hasBillingCycleError}
                            onCheck={handleBillingCycleCheck}
                            teamSize={teamSize}
                            title={`${t(
                                "billingCycle-selectABillingCycleToContinue"
                            )} *`}
                            type={selectedSubscriptionType}
                            value={selectedBillingCycle}
                        />
                    )}
                    <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                        <Button
                            dataTestId={"cancel"}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Secondary}
                            onClick={onClose}>
                            {t("cancel")}
                        </Button>
                        <Button
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Primary}
                            onClick={handleConfirm}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SelectBillingCycleModal;

// #endregion Export
