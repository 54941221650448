import { useLocalization } from "utilities/hooks/use-localization";
import React from "react";
import SubscriptionCard from "organisms/subscription-card/subscription-card";
import SubscriptionType from "organisms/enums/subscription-type";
import { Icons } from "atoms/constants/icons";
import { SubscriptionFeaturesType } from "utilities/hooks/domain/subscriptions/use-subscription-features";
import SubscriptionFeatureList from "organisms/subscription-card/subscription-feature-list";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EnterpriseSubscriptionCardProps {
    onSelect: () => void;
    cssClassNameModifier?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnterpriseSubscriptionCard: React.FunctionComponent<EnterpriseSubscriptionCardProps> =
    (props: EnterpriseSubscriptionCardProps) => {
        const { onSelect, cssClassNameModifier = "" } = props;
        const { t } = useLocalization<CultureResources>();
        return (
            <SubscriptionCard
                billingText={t("subscriptionCard-enterprise-billingText")}
                buttonText={t("subscriptionCard-enterprise-buttonText")}
                headingIcon={Icons.Enterprise}
                onSelect={onSelect}
                price={t("subscriptionCard-enterprise-pricingVaries")}
                type={SubscriptionType.Enterprise}
                cssClassNameModifier={cssClassNameModifier}>
                <SubscriptionFeatureList
                    type={SubscriptionFeaturesType.Enterprise}
                />
            </SubscriptionCard>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EnterpriseSubscriptionCard;

// #endregion Export
