import { Record } from "immutable";
import BookTopic from "models/interfaces/book-topic";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: BookTopic =
    RecordUtils.auditableDefaultValuesFactory<BookTopic>({
        bookId: 0,
        isPrimary: false,
        topicId: 0,
    });

export default class BookTopicRecord
    extends Record(defaultValues)
    implements BookTopic
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: BookTopic) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<BookTopic>} values
     */
    public with(values: Partial<BookTopic>): BookTopicRecord {
        return new BookTopicRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
