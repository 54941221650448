import React from "react";
import * as Reach from "@reach/tabs";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TabPanelsProps extends Reach.TabPanelsProps {}

// #endregion Interfaces

/**
 * Extension of the ReachUI TabPanels component
 * @see /documentation/code-conventions-react.md#ReachUI Components##Tabs
 * for a note about usage
 * @param props
 */
const TabPanels: React.FunctionComponent<TabPanelsProps> = (
    props: TabPanelsProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------
    const CSS_BASE_CLASS = "c-tabs__panels";

    // #endregion Constants

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    return (
        <Reach.TabPanels className={CSS_BASE_CLASS}>
            {props.children}
        </Reach.TabPanels>
    );

    // #endregion Component
};

export default TabPanels;
