import { ChapterParams } from "interfaces/routing/chapter-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { PublicationParams } from "interfaces/routing/publication-params";
import PublicationContentRedirect from "organisms/publication-content/legacy-redirects/publication-content-redirect";
import React from "react";
import { useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import useArticle from "utilities/hooks/domain/publications/chapters/articles/use-article";
import usePublicationQuery from "utilities/hooks/domain/publications/use-publication-query";
import useChapterQuery from "utilities/hooks/queries/publications/chapters/use-chapter-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useLoading from "utilities/hooks/use-loading";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ArticleRedirectProps {}

interface RouteParams
    extends Pick<NestedPublicationParams, "publicationId">,
        Pick<PublicationParams, "id">,
        Pick<ChapterParams, "chapterId"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ArticleRedirect: React.FC<ArticleRedirectProps> = (
    props: ArticleRedirectProps
) => {
    const {
        publicationId,
        chapterId,
        id: articleId,
    } = useParams<RouteParams>();

    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: chapter,
        loaded: chapterLoaded,
        loading: chapterLoading,
    } = useChapterQuery({
        id: chapterId,
    });

    const {
        resultObject: publication,
        loaded: publicationLoaded,
        loading: publicationLoading,
    } = usePublicationQuery({
        id: NumberUtils.parseInt(publicationId),
        isAdmin: isAdminPreview,
    });

    const {
        resultObject: article,
        loaded: articleLoaded,
        loading: articleLoading,
    } = useArticle({
        articleId: articleId,
        chapterId: chapter?.id,
        code: publication?.code,
        edition: publication?.edition,
        publicationId: publicationId,
    });

    const loading = useLoading(
        articleLoading,
        chapterLoading,
        publicationLoading
    );
    const loaded = articleLoaded && chapterLoaded && publicationLoaded;
    const route = RouteUtils.getUrl(siteMap.publications.permalinks.article, {
        code: publication.code,
        edition: publication.edition,
        chapterNfpaLabel: chapter.nfpaLabel,
        nfpaLabel: article.nfpaLabel,
    });

    return (
        <PublicationContentRedirect
            entities={[article, chapter, publication]}
            loading={loading}
            loaded={loaded}
            route={route}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ArticleRedirect;

// #endregion Exports
