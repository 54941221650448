import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { match } from "react-router-dom";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const PAGE_TITLE = "MyLiNK";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyLinkBreadcrumbProps {
    match: match;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyLinkBreadcrumb: React.FC<MyLinkBreadcrumbProps> = (
    props: MyLinkBreadcrumbProps
) => {
    const { match } = props;
    useHeaderData({ match, title: PAGE_TITLE });

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{PAGE_TITLE}</span>
        </Breadcrumb>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MyLinkBreadcrumb;

// #endregion Exports
