import * as React from "react";
import CommitteesMemberGroup from "./committees-member-group";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";

export interface CommitteesNonVotersMemberGroupProps {
    children?: JSX.Element[];
}

const CommitteesNonVotersMemberGroup = (
    props: CommitteesNonVotersMemberGroupProps
) => {
    return (
        <CommitteesMemberGroup>
            <React.Fragment>
                <Heading
                    priority={HeadingPriority.Three}
                    cssClassName="c-committee-member-group__title">
                    Nonvoting
                </Heading>
                {props.children}
            </React.Fragment>
        </CommitteesMemberGroup>
    );
};

export default CommitteesNonVotersMemberGroup;
