import TableRecord from "models/view-models/table-record";
import React from "react";
import TableOfContentsCollapsePanelLink from "./table-of-contents-collapse-panel-link";

interface TableOfContentsTablesProps {
    tables: Array<TableRecord>;
}

const TableOfContentsTables: React.FunctionComponent<TableOfContentsTablesProps> =
    (props: TableOfContentsTablesProps) => {
        const className = "c-table-of-contents-section";
        const { tables } = props;

        return (
            <nav className={className}>
                <ul>
                    {tables
                        .filter((table) => !table.isDeleted())
                        .map((table) => {
                            const link = table.getHashLink();

                            return (
                                <TableOfContentsCollapsePanelLink
                                    dataTestDisplaySequence={
                                        table.displaySequence
                                    }
                                    dataTestExternalId={table.externalId}
                                    dataTestId={"table-link"}
                                    index={table.id}
                                    to={link}>
                                    <span>{table.getDisplayTitle()}</span>
                                </TableOfContentsCollapsePanelLink>
                            );
                        })}
                </ul>
            </nav>
        );
    };

export default TableOfContentsTables;
