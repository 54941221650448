import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region interfaces
// -----------------------------------------------------------------------------------------

interface LabelConverterProps extends XmlConvertedComponentProps {}

// #endregion interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-xml-label";
const LabelConverterId = "LabelConverter";

// #endregion Constants

const LabelConverter: React.FunctionComponent<LabelConverterProps> = (
    props: LabelConverterProps
) => {
    const changedModifier =
        props.diffchanged != null ? `c-code-change -${props.diffchanged}` : "";

    if (props.parentNode?.includes("li")) {
        return (
            <span className={`${BASE_CLASS} ${changedModifier}`}>
                {props.children}
            </span>
        );
    }
    return (
        <label className={`${BASE_CLASS} ${changedModifier}`}>
            {props.children}
        </label>
    );
};

// -----------------------------------------------------------------------------------------
// #region exports
// -----------------------------------------------------------------------------------------

export default LabelConverter;

export { LabelConverterId };

// #endregion exports
