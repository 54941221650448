import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import { useState } from "react";
import AdminFreeAccessService from "utilities/services/admin/free-access/admin-free-access-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useAdminFreeAccessContext from "./use-admin-free-access-context";

const useAdminEditFreeAccessPublications = () => {
    const { setIsLoading, publications, setPublications } =
        useAdminFreeAccessContext();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [publicationToEdit, setPublicationToEdit] =
        useState<FreeAccessPublicationRecord>();

    const onEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const onEditPublication = (publication: FreeAccessPublicationRecord) => {
        setPublicationToEdit(publication);
        setIsEditModalOpen(true);
    };

    const { update: updateFreeAccessPublication } =
        AdminFreeAccessService.useUpdate();

    const onUpdateFreeAccessPublication = async (
        publicationToUpdate: FreeAccessPublicationRecord
    ) => {
        setIsLoading(true);
        try {
            if (!publicationToUpdate) {
                throw new Error();
            }
            const results = await updateFreeAccessPublication(
                publicationToUpdate,
                { id: publicationToUpdate.id! }
            );
            const updatedPublication = results.resultObject;

            if (!updatedPublication) {
                throw new Error();
            }

            const newPublications = replacePublication(
                publications,
                updatedPublication
            );
            setPublications(newPublications);
            setIsEditModalOpen(false);
            ToastManager.success("Publication successfully updated!");
        } catch (error) {
            ToastManager.error("Error updating publication.");
        } finally {
            setIsLoading(false);
        }
    };
    return {
        onUpdateFreeAccessPublication,
        isEditModalOpen,
        onEditModalClose,
        onEditPublication,
        publicationToEdit,
    };
};

const replacePublication = (
    publications: FreeAccessPublicationRecord[],
    updatedPublication: FreeAccessPublicationRecord
) =>
    publications.map((publication) =>
        publication.id === updatedPublication.id
            ? updatedPublication
            : publication
    );

export default useAdminEditFreeAccessPublications;
