import DeviceDetector from "device-detector-js";
import _, { chain } from "lodash";
import OfflineBook from "models/interfaces/offline/offline-book";
import OfflineDevice from "models/interfaces/offline/offline-device";
import NumberUtils from "utilities/number-utils";
import StorageProviderFactory from "utilities/service-worker/storage/storage-provider-factory";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineStorageProvider {
    getCurrentOfflineDevice: (userId: number) => Promise<OfflineDevice>;
    getOfflineBooks: () => Promise<Array<OfflineBook>>;
    getOfflineDevices: () => Promise<Array<OfflineDevice>>;
    isOfflineDevicePersisted: (userId: number) => Promise<boolean>;
    saveOfflineDevice: (offlineDevice: OfflineDevice) => Promise<OfflineDevice>;
    setOfflineBooks: (
        offlineBooks: Array<OfflineBook>
    ) => Promise<Array<OfflineBook>>;
    setOfflineDevices: (
        offlineDevices: Array<OfflineDevice>
    ) => Promise<Array<OfflineDevice>>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const deviceDetector = new DeviceDetector({ versionTruncation: 0 });
const offlineBooksProvider = StorageProviderFactory.offlineBooks();
const offlineDevicesProvider = StorageProviderFactory.offlineDevices();

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Factory
// -----------------------------------------------------------------------------------------

const OfflineStorageProviderFactory = {
    create: (): OfflineStorageProvider => {
        return {
            getCurrentOfflineDevice: getCurrentOfflineDeviceOrDefault,
            getOfflineBooks,
            getOfflineDevices,
            isOfflineDevicePersisted,
            saveOfflineDevice,
            setOfflineBooks,
            setOfflineDevices,
        };
    },
};

// #endregion Factory

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

const buildOfflineDevice = (userId: number): OfflineDevice => {
    const { userAgent } = navigator;
    const { device, os, client } = deviceDetector.parse(userAgent);
    const deviceId = uuid.v4();
    const detectedDeviceName = _.compact([
        os?.name,
        os?.version,
        device?.brand,
        device?.model,
        device?.type,
        client?.name,
    ])
        .map(StringUtils.capitalize)
        .join(" ");

    const newOfflineDevice: OfflineDevice = {
        createdById: userId,
        deviceId,
        name: StringUtils.hasValue(detectedDeviceName)
            ? detectedDeviceName
            : `Unknown Device (${deviceId})`,
    };

    return newOfflineDevice;
};

const buildOfflineDevices = (
    offlineDevices: OfflineDevice[],
    offlineDevice: OfflineDevice
): OfflineDevice[] => {
    const isDevice = filterDeviceByOfflineDeviceUserId(
        offlineDevice.createdById
    );

    const currentOfflineDevice = chain(offlineDevices)
        .filter(isDevice)
        .first()
        .value();

    if (currentOfflineDevice == null) {
        return [...offlineDevices, offlineDevice];
    }

    return offlineDevices.map((device) => {
        if (isDevice(device)) {
            return offlineDevice;
        }

        return device;
    });
};

const filterDeviceByOfflineDeviceUserId =
    (userId?: number) => (device: OfflineDevice) =>
        userId != null && device.createdById === userId;

const getCurrentOfflineDeviceByUserId = async (
    userId: number
): Promise<OfflineDevice | undefined> => {
    const offlineDevices = await offlineDevicesProvider.read();

    return chain(offlineDevices ?? [])
        .filter(filterDeviceByOfflineDeviceUserId(userId))
        .first()
        .value();
};

const getCurrentOfflineDeviceOrDefault = async (
    userId: number
): Promise<OfflineDevice> => {
    const currentOfflineDevice = await getCurrentOfflineDeviceByUserId(userId);

    if (currentOfflineDevice != null) {
        return currentOfflineDevice;
    }

    return await saveOfflineDevice(buildOfflineDevice(userId));
};

const getOfflineBooks = async (): Promise<Array<OfflineBook>> =>
    (await offlineBooksProvider.read()) ?? [];

const getOfflineDevices = async (): Promise<Array<OfflineDevice>> =>
    (await offlineDevicesProvider.read()) ?? [];

const isOfflineDevicePersisted = async (userId: number): Promise<boolean> =>
    !NumberUtils.isDefault(
        (await getCurrentOfflineDeviceOrDefault(userId))?.id
    );

const saveOfflineDevice = async (
    offlineDevice: OfflineDevice
): Promise<OfflineDevice> => {
    const offlineDevices = await offlineDevicesProvider.read();

    const updatedOfflineDevices = buildOfflineDevices(
        offlineDevices ?? [],
        offlineDevice
    );

    await offlineDevicesProvider.write(updatedOfflineDevices);

    return offlineDevice;
};

const setOfflineBooks = async (
    offlineBooks: Array<OfflineBook>
): Promise<Array<OfflineBook>> => offlineBooksProvider.write(offlineBooks);

const setOfflineDevices = (
    offlineDevices: Array<OfflineDevice>
): Promise<Array<OfflineDevice>> =>
    offlineDevicesProvider.write(offlineDevices);

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineStorageProviderFactory;

// #endregion Exports
