import ModalCloseButton from "atoms/buttons/modal-close-button";
import { AnimatePresence, motion } from "framer-motion";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { FramerMotionTransitions } from "constants/framer-motion-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import IEFriendlyDialogWithOverlay from "molecules/modals/ie-friendly-dialog-with-overlay";
import React, {
    PropsWithChildren,
    Ref,
    RefObject,
    forwardRef,
    useEffect,
} from "react";
import { BrowserUtils } from "utilities/browser-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import StringUtils from "utilities/string-utils";
import Dialog, { DialogContent, DialogOverlay } from "@reach/dialog";
import { DataTestAttributes } from "interfaces/data-test-attributes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export type CloseDialogEvent = React.SyntheticEvent<Element, Event> &
    Pick<Partial<KeyboardEvent>, "key">;

export interface ModalProps extends Pick<DataTestAttributes, "dataTestId"> {
    closeButtonStyle?: ModalCloseButtonStyle;
    closeDialog: (event?: CloseDialogEvent) => void;
    cssClassName?: string;
    isVisible: boolean;
    label: string;
    onCloseComplete?: () => void;
    ref?: RefObject<HTMLDivElement>;
    showAppSidebar?: boolean;
    style?: React.CSSProperties;
    transition?: ModalTransitions;
    type: ModalTypes;
    useDialogOverlay?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultProps: Partial<ModalProps> = {
    useDialogOverlay: true,
};

// #endregion Constants

/**
 * Forwards the div ref for the .c-modal__content div
 */
const Modal: React.RefForwardingComponent<
    HTMLDivElement,
    PropsWithChildren<ModalProps>
> = forwardRef(
    (props: PropsWithChildren<ModalProps>, ref: Ref<HTMLDivElement>) => {
        const {
            closeButtonStyle,
            closeDialog,
            cssClassName,
            dataTestId,
            isVisible,
            label,
            onCloseComplete,
            showAppSidebar,
            transition,
            type,
        } = props;
        const classNames = ["c-modal"];
        const overlayClass = `c-modal-overlay ${
            showAppSidebar ? "-show-sidebar" : ""
        }`;

        // -----------------------------------------------------------------------------------------
        // #region Side Effects
        // -----------------------------------------------------------------------------------------

        useEffect(() => {
            if (
                isVisible &&
                window.innerWidth < Breakpoints.Phone &&
                type !== ModalTypes.Bottom
            ) {
                // Counteract Android Chrome's auto page scrolling that throws off the modal position
                window.setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 5);
            }
        }, [isVisible, type]);

        // #endregion Side Effects

        if (StringUtils.hasValue(cssClassName)) {
            classNames.push(cssClassName!);
        }

        if (type != null) {
            classNames.push(type);
        }

        let modalTransition;

        switch (transition) {
            case ModalTransitions.Fade:
                modalTransition = FramerMotionTransitions.FADE_IN;
                break;
            case ModalTransitions.SlideRight:
                modalTransition = FramerMotionTransitions.SLIDE_RIGHT;
                break;
            case ModalTransitions.SlideUp:
                modalTransition = FramerMotionTransitions.SLIDE_UP;
                break;
            case ModalTransitions.None:
            default:
                modalTransition = {};
        }

        const handleNonAnimationDismissal = (event?: CloseDialogEvent) => {
            closeDialog(event);
            onCloseComplete?.();
        };

        // -----------------------------------------------------------------------------------------
        // #region Component
        // -----------------------------------------------------------------------------------------

        // Not yet supporting animations
        if (!props.useDialogOverlay) {
            return (
                <Dialog
                    allowPinchZoom={true}
                    aria-label={label}
                    className={StringUtils.joinClassNames(classNames)}
                    isOpen={isVisible}
                    onDismiss={handleNonAnimationDismissal}>
                    <div className="c-modal__content" ref={ref}>
                        {props.children}
                    </div>

                    {
                        // if
                        closeButtonStyle != null &&
                            closeButtonStyle ===
                                ModalCloseButtonStyle.InsideDialog && (
                                <ModalCloseButton
                                    modalType={type}
                                    onClick={handleNonAnimationDismissal}
                                />
                            )
                    }
                    {
                        // else
                        closeButtonStyle == null && (
                            <ModalCloseButton
                                modalType={type}
                                onClick={handleNonAnimationDismissal}
                            />
                        )
                    }
                </Dialog>
            );
        }

        // This is not a long term solution, browser detection should be a last resort.
        if (BrowserUtils.isIE()) {
            return (
                <IEFriendlyDialogWithOverlay
                    {...props}
                    closeDialog={handleNonAnimationDismissal}
                    classNames={classNames}
                    closeButtonStyle={closeButtonStyle}
                    type={type}
                />
            );
        }

        const isFullscreen =
            type === ModalTypes.Fullscreen || type === ModalTypes.Search;

        return (
            <AnimatePresence onExitComplete={onCloseComplete}>
                {isVisible && (
                    <DialogOverlay
                        allowPinchZoom={true}
                        isOpen={isVisible}
                        onDismiss={closeDialog}>
                        <motion.div
                            key="modal"
                            {...FramerMotionTransitions.FADE_IN}
                            className={overlayClass}>
                            {
                                // if
                                closeButtonStyle != null &&
                                    closeButtonStyle ===
                                        ModalCloseButtonStyle.OutsideDialog && (
                                        <ModalCloseButton
                                            modalType={type}
                                            onClick={closeDialog}
                                        />
                                    )
                            }
                            <motion.div {...modalTransition}>
                                <DialogContent
                                    aria-label={label}
                                    className={StringUtils.joinClassNames(
                                        classNames
                                    )}
                                    data-test-id={dataTestId}>
                                    {closeButtonStyle == null &&
                                        isFullscreen && (
                                            <ModalCloseButton
                                                modalType={type}
                                                onClick={closeDialog}
                                            />
                                        )}
                                    <div className="c-modal__content" ref={ref}>
                                        {props.children}
                                    </div>
                                    {closeButtonStyle == null &&
                                        !isFullscreen && (
                                            <ModalCloseButton
                                                modalType={type}
                                                onClick={closeDialog}
                                            />
                                        )}
                                    {closeButtonStyle != null &&
                                        closeButtonStyle ===
                                            ModalCloseButtonStyle.InsideDialog && (
                                            <ModalCloseButton
                                                modalType={type}
                                                onClick={closeDialog}
                                            />
                                        )}
                                </DialogContent>
                            </motion.div>
                        </motion.div>
                    </DialogOverlay>
                )}
            </AnimatePresence>
        );
        // #endregion Component
    }
);

Modal.defaultProps = defaultProps;

export default Modal;
