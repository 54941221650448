import EnhancedContentMigrationRecord from "models/view-models/enhanced-content-migration-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EnhancedContentMigrationQueryParams {
    code?: string;
    destinationEdition?: string;

    /**
     * Optionally return only the most recently created record
     */
    mostRecent?: boolean;
}

export interface EnhancedContentMigrationPathParams {}

export interface EnhancedContentMigrationResourcePathParams
    extends EnhancedContentMigrationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/enhancedcontentmigration";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = EnhancedContentMigrationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EnhancedContentMigrationService = {
    /**
     * Retrieve a specific Group resource
     */
    get: ServiceFactory.get<
        EnhancedContentMigrationRecord,
        EnhancedContentMigrationResourcePathParams
    >(resourceType, resourceEndpoint),
    list: ServiceFactory.list<
        EnhancedContentMigrationRecord,
        EnhancedContentMigrationQueryParams
    >(resourceType, baseEndpoint),
    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        EnhancedContentMigrationRecord,
        EnhancedContentMigrationResourcePathParams
    >(resourceType, resourceEndpoint),
    useList: ServiceHookFactory.useList<
        EnhancedContentMigrationRecord,
        EnhancedContentMigrationQueryParams
    >(resourceType, baseEndpoint),
    create: ServiceFactory.create<EnhancedContentMigrationRecord>(
        resourceType,
        baseEndpoint
    ),
    useCreate: ServiceHookFactory.useCreate<EnhancedContentMigrationRecord>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EnhancedContentMigrationService;

// #endregion Exports
