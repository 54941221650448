import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { OfflineSessionConstants } from "constants/offline-session-constants";
import moment from "moment";
import OfflineSettingsHeader from "organisms/account-offline-settings/offline-tab-header";
import ButtonGroup from "organisms/button-groups/button-group";
import OfflineBookList from "organisms/offline-book-list/offline-book-list";
import React from "react";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { NetworkState } from "utilities/contexts/network-information/network-information-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { CookieUtils } from "utilities/cookie-utils";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import RoleType from "utilities/enumerations/role-type";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import OfflineUnsupportedBanner from "organisms/banners/offline-unsupported-banner";
import ServiceWorkerStatus from "molecules/service-worker-status/service-worker-status";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountOfflineTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__offline";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountOfflineTabPanel: React.FC<AccountOfflineTabPanelProps> = () => {
    const { useOfflineFeatures } = useFeatureFlags();
    const { t } = useLocalization<CultureResources>();

    const { globalState } = useGlobalState();
    const isAdmin = globalState.currentIdentity?.isCurrentRole(
        RoleType.SYS_ADMIN
    );

    if (!useOfflineFeatures) {
        return <Redirect to={siteMap.account.information} />;
    }

    const handleExpireOffline = () => {
        const networkState = LocalStorageUtils.get<NetworkState>(
            LocalStorageKey.NetworkState
        );

        LocalStorageUtils.set(LocalStorageKey.NetworkState, {
            ...networkState,
            offlineAt: moment().subtract(21, "days").toISOString(),
        });
    };

    const handleExpireCookie = () =>
        CookieUtils.set(
            OfflineSessionConstants.sessionExpirationCookieName,
            moment().subtract(1, "hours").toISOString()
        );

    return (
        <div className={BASE_CLASS}>
            <OfflineUnsupportedBanner />
            <ServiceWorkerStatus />
            <OfflineSettingsHeader />

            <div className={`${BASE_CLASS}__publications`}>
                <Paragraph size={ParagraphSizes.Large}>
                    {t("publication_plural")}
                </Paragraph>
                <OfflineBookList />
            </div>

            {isAdmin && (
                <div className={`${BASE_CLASS}__search-index`}>
                    <ButtonGroup>
                        <Button
                            onClick={handleExpireOffline}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Primary}>
                            Test Stale Offline Session Alert
                        </Button>
                        <Button
                            onClick={handleExpireCookie}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Primary}>
                            Test Expired Offline Session Alert
                        </Button>
                    </ButtonGroup>
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountOfflineTabPanel;

// #endregion Exports
