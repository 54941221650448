import metanavRoutes, { MetaNavRoute } from "metanav-routes";
import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface MetaNavSwitchProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Switch
// -----------------------------------------------------------------------------------------

const MetaNavSwitch: React.FunctionComponent<MetaNavSwitchProps> = (
    props: MetaNavSwitchProps
) => {
    return (
        <Switch>
            {metanavRoutes.map((route: MetaNavRoute, i: any) => {
                // TODO: https://app.clickup.com/t/qz6rq9 Extend NestedRoutes to allow for props to be passed
                // <NestedRoute key={i} {...route} />
                const MetaNav = route.component as React.ComponentType<any>;

                return (
                    <Route
                        exact
                        key={i}
                        path={route.path}
                        render={(props: RouteProps) => <MetaNav {...props} />}
                    />
                );
            })}
        </Switch>
    );
};

// #endregion Switch

export default MetaNavSwitch;
