import type Subscription from "models/interfaces/subscription";
import { Record } from "immutable";
import RoleType from "utilities/enumerations/role-type";
import type CultureResources from "utilities/interfaces/culture-resources";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: Subscription =
    RecordUtils.auditableDefaultValuesFactory<Subscription>({
        description: "",
        externalProductId: "",
        isAutoRenewing: false,
        name: "",
        roleType: undefined,
        roleId: 0,
        sku: "",
    });

export default class SubscriptionRecord
    extends Record(defaultValues)
    implements Subscription
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Subscription) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to return the associated i18n Culture Key for the roleType
     */
    public getRoleNameCultureKey(): keyof CultureResources | undefined {
        if (this.roleType == null) {
            return undefined;
        }

        switch (this.roleType) {
            case RoleType.ENTERPRISE:
                return "subscriptionType-Enterprise";
            case RoleType.FREE_TRIAL:
                return "subscriptionType-Free Trial";
            case RoleType.TEAM:
                return "subscriptionType-Team";
            case RoleType.TRAINING_CONTRACT:
                return "subscriptionType-Training";
            default:
                return "subscriptionType-Individual";
        }
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Subscription>} values
     */
    public with(values: Partial<Subscription>): SubscriptionRecord {
        return new SubscriptionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
