import * as React from "react";
import Paragraph from "atoms/typography/paragraph";

export interface CommitteesChaptersProps {
    children?: JSX.Element[];
}

const CommitteesChapters = (props: CommitteesChaptersProps) => (
    <Paragraph cssClassName="c-committee-list__articles">
        {props.children}
    </Paragraph>
);

export default CommitteesChapters;
