import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "../service-factory";

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint =
    "publications/:code/:edition/chapters/:chapterNfpaLabel/enhanced-content";

const baseAdminEndpoint = "admin/chapters/:chapterId/enhancedcontents";

interface EnhancedContentBasePathParams {
    code: string;
    edition: string;
    chapterNfpaLabel: string;
}

interface EnhancedContentAdminBasePathParams {
    chapterId: string;
}

interface EnhancedContentQueryParams {}

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const EnhancedContentByChapterService = {
    list: ServiceFactory.nestedList<
        EnhancedContentRecord,
        EnhancedContentBasePathParams,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseEndpoint),
    adminList: ServiceFactory.nestedList<
        EnhancedContentRecord,
        EnhancedContentAdminBasePathParams,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseAdminEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentByChapterService;

// #endregion Exports
