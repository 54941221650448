import { ServiceResponse } from "andculturecode-javascript-core";

// TODO: Move to open source.
// See https://github.com/AndcultureCode/AndcultureCode.JavaScript.React/issues/55 for more information.

// ---------------------------------------------------------------------------------------------
// #region Functions
// ---------------------------------------------------------------------------------------------

const ServiceResponseFactory = {
    /**
     * Default successful list response
     */
    successfulList<T>(
        resultObjects?: Array<T>,
        rowCount?: number
    ): ServiceResponse<T> {
        return {
            resultObjects: resultObjects ?? [],
            rowCount: rowCount ?? 0,
            status: 200,
        };
    },

    /**
     * Default successful get response
     */
    successfulGet<T>(resultObject?: T): ServiceResponse<T> {
        return {
            resultObject,
            resultObjects: [],
            rowCount: 0,
            status: 200,
        };
    },
};

// #endregion Functions

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ServiceResponseFactory;

// #endregion Exports
