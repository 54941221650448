import { LoaderStyles } from "atoms/constants/loader-styles";
import SubmitButton from "atoms/forms/submit-button";
import Loader from "atoms/loaders/loader";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserLoginSubmitButtonProps {
    buttonText: string;
    disabled: boolean;
    loading: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserLoginSubmitButton: React.FC<UserLoginSubmitButtonProps> = (
    props: UserLoginSubmitButtonProps
) => {
    const { buttonText, disabled, loading } = props;

    const { t } = useLocalization<CultureResources>();
    if (loading) {
        return (
            <Loader
                accessibleText={t("newUserLoginForm-loader-accessibleText")}
                type={LoaderStyles.LinkGlyphGray}
            />
        );
    }

    return (
        <SubmitButton
            buttonText={buttonText}
            cssClassName="c-button"
            disabled={disabled}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default UserLoginSubmitButton;

// #endregion Exports
