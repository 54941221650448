import UserRecord from "models/view-models/user-record";
import { useCallback, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";
import UserService from "utilities/services/users/user-service";
import { ToastManager } from "utilities/toast/toast-manager";

export function useUpdateUser() {
    const { setGlobalState } = useGlobalState();
    const { update: updateUserApi } = UserService.useUpdate();
    const [loading, setLoading] = useState(false);

    const updateUser = useCallback(
        async (user: UserRecord | undefined) => {
            if (user == null || user.id == null) return;

            setLoading(true);

            try {
                const result = await updateUserApi(user, { id: user.id });

                setGlobalState((globalState) =>
                    globalState.with({
                        currentIdentity: globalState.currentIdentity?.with({
                            user: result.resultObject,
                        }),
                    })
                );

                return result.resultObject;
            } catch (error) {
                ToastManager.error(
                    t("errors-actionResource_plural", {
                        action: t("updating"),
                        resource: t("user"),
                    })
                );
                return null;
            } finally {
                setLoading(false);
            }
        },
        [setGlobalState, updateUserApi]
    );

    return { loading, updateUser };
}
