import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useCallback,
    useMemo,
    useEffect,
} from "react";
import { useLocation, useRouteMatch, match } from "react-router-dom";
import { MetaTag } from "models/interfaces/header-data";
import HeaderDataRecord from "models/view-models/header-data-record";
import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export type HeaderDataUpdater = Dispatch<SetStateAction<HeaderDataRecord>>;

export type HeaderDataOptions = {
    title?: string;
    match?: match;
    metaTags?: Array<MetaTag>;
};

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Context
// -----------------------------------------------------------------------------------------

const defaultState = new HeaderDataRecord();
const defaultUpdater: HeaderDataUpdater = () => {};
export const HeaderDataContext = createContext<
    [HeaderDataRecord, HeaderDataUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Hook to build html header data such as title, and meta tags
 */
export const useHeaderData = (options?: HeaderDataOptions) => {
    const location = useLocation();
    const match = useRouteMatch();
    const [headerData, setHeaderDataState] = useContext(HeaderDataContext);

    const path = useMemo(
        () => options?.match?.path ?? match.path,
        [options, match.path]
    );

    const url = useMemo(
        () => options?.match?.url ?? match.url,
        [options, match.url]
    );

    const title = useMemo(() => options?.title, [options]);

    const fullPageTitle = useMemo(
        () => headerData.getFullPageTitle(location.pathname),
        [location.pathname, headerData]
    );

    const metaTags: MetaTag[] = useMemo(
        () => options?.metaTags ?? [],
        [options]
    );

    const headerDataMetaTags: MetaTag[] = useMemo(
        () => headerData.getMetaTags(location.pathname),
        [location.pathname, headerData]
    );

    const setPageTitle = useCallback(
        (title?: string) => {
            setHeaderDataState((p) => {
                const headerDataState = p.with({
                    titles:
                        title == null
                            ? p.titles.remove(path)
                            : p.titles.set(path, { url, title }),
                });

                return headerDataState;
            });
        },
        [setHeaderDataState, url, path]
    );

    const setMetaTags = useCallback(
        (metaTags: MetaTag[]) => {
            setHeaderDataState((p) => {
                // Update any current matching MetaTags
                const currentMetaTags =
                    p.metaTags.get(path)?.map((currentMetaTag) => {
                        const foundMetaTag = metaTags.find(
                            (m) => m.name === currentMetaTag.name
                        );

                        if (foundMetaTag == null) {
                            return currentMetaTag;
                        }

                        return {
                            ...currentMetaTag,
                            content: foundMetaTag.content,
                        };
                    }) ?? [];

                // MetaTags that are not in the current array of MetaTags
                const newMetaTags = metaTags.filter(
                    (m) => !currentMetaTags.some((c) => c.name === m.name)
                );

                // Update url for all MetaTags in the current array
                const updatedMetaTags = [
                    ...currentMetaTags,
                    ...newMetaTags,
                ].map((m) => ({
                    ...m,
                    url,
                }));

                return p.with({
                    metaTags: p.metaTags.set(path, updatedMetaTags),
                });
            });
        },
        [setHeaderDataState, path, url]
    );

    useEffect(() => {
        // Avoid triggering any other consumers to rerender if there is nothing to update
        if (CollectionUtils.hasValues(metaTags)) {
            setMetaTags(metaTags);
        }
    }, [setMetaTags, metaTags]);

    useEffect(() => {
        if (StringUtils.hasValue(title)) {
            setPageTitle(title);
        }
    }, [setPageTitle, title]);

    return {
        fullPageTitle,
        metaTags: headerDataMetaTags,
        setPageTitle,
        setMetaTags,
    };
};

// #endregion Hook
