import React from "react";
import RedirectionModal from "molecules/modals/redirection-modal";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StringUtils from "utilities/string-utils";
import useMarketingQueryParams from "utilities/hooks/use-marketing-query-params";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EcommerceRedirectionModalProps {
    /**
     * Billing cycle option to be used for building the route to redirect to.
     */
    pin: string;

    /**
     * Transition for the modal to show up with.
     *
     * @default {ModalTransitions.Fade}
     */
    transition?: ModalTransitions;
    withPurchaseText?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EcommerceRedirectionModal: React.FunctionComponent<EcommerceRedirectionModalProps> =
    (props: EcommerceRedirectionModalProps) => {
        const { getMarketingQueryParams } = useMarketingQueryParams();
        const { pin, withPurchaseText = true, transition } = props;
        const { globalState } = useGlobalState();
        const redirectionRoute =
            globalState?.systemSettings?.getEcommerceCheckoutUrl(
                pin,
                getMarketingQueryParams
            );

        if (StringUtils.isEmpty(redirectionRoute)) {
            return null;
        }

        const description = withPurchaseText
            ? t("ecommerceRedirectionModal-defaultDescription")
            : t("ecommerceRedirectionModal-shortenedDescription");

        return (
            <RedirectionModal
                closeDialog={() => null}
                description={description}
                isVisible={true}
                label="Redirecting to NFPA.org"
                to={redirectionRoute!}
                transition={transition ?? ModalTransitions.Fade}
                type={ModalTypes.Base}
            />
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EcommerceRedirectionModal;

// #endregion Export
