import AlertNotice from "molecules/banners/alert-notice";
import React from "react";
import AlertLevels from "constants/alert-levels";
import useMaintenanceModeMessage from "utilities/hooks/use-maintenance-mode-message";
import ProgressBar, { ProgressBarStyles } from "atoms/progress/progress-bar";
import useAutoPageRefresh from "utilities/hooks/use-auto-page-refresh";
import { t } from "utilities/localization-utils";
import Paragraph from "atoms/typography/paragraph";

const MaintenancePage: React.FC<any> = (props: any) => {
    const { maintenanceModeMessage, hasAutoRefresh, timeBetweenRefreshes } =
        useMaintenanceModeMessage();
    const { timeUntilNextRefreshAsPercent, timeUntilNextRefresh } =
        useAutoPageRefresh({
            isActive: hasAutoRefresh,
            timeBetweenRefreshesInSeconds: timeBetweenRefreshes,
        });

    const alert = (
        <div className="c-not-found-page">
            <AlertNotice
                description={maintenanceModeMessage?.content}
                level={AlertLevels.Error}
                title={maintenanceModeMessage?.title}>
                {hasAutoRefresh && (
                    <>
                        {timeUntilNextRefresh ? (
                            <Paragraph>
                                {" "}
                                {t("refreshingInNSeconds", {
                                    timeInSeconds: timeUntilNextRefresh,
                                })}
                            </Paragraph>
                        ) : (
                            <Paragraph>{t("refreshing")}</Paragraph>
                        )}
                        <ProgressBar
                            style={ProgressBarStyles.Thick}
                            cssClassName="-error"
                            value={timeUntilNextRefreshAsPercent}
                        />
                    </>
                )}
            </AlertNotice>
        </div>
    );

    return (
        <>
            <div className="c-application-layout">
                <div className="c-application-layout__panel">{alert}</div>
            </div>
        </>
    );
};

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default MaintenancePage;
