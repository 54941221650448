import { PagedQuery } from "andculturecode-javascript-core";
import PublishStatus from "models/enumerations/publish-status";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

const baseEndpoint = "admin/change-summary";
const resourceType = ChangeSummaryRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const publishEndpoint = `${resourceEndpoint}/publish-status`;

export interface AdminChangeSummaryQueryParams
    extends PagedQuery,
        PublishableFilters {
    code?: string;
    edition?: string;
    externalId?: string;
    type?: PublicationComponentType;
    hasUnpublishedChanges?: boolean;
    publishStatus?: PublishStatus;
    searchText?: string;
}

interface ChangeSummaryPathParams {
    id: number;
}

const AdminChangeSummaryService = {
    create: ServiceFactory.create(resourceType, baseEndpoint),
    list: ServiceFactory.list<
        ChangeSummaryRecord,
        AdminChangeSummaryQueryParams
    >(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),
    update: ServiceFactory.update(resourceType, resourceEndpoint),
    useUpdate: ServiceHookFactory.useUpdate(resourceType, resourceEndpoint),
    useDelete:
        ServiceHookFactory.useDelete<ChangeSummaryPathParams>(resourceEndpoint),
    publish: ServiceFactory.update(resourceType, publishEndpoint),
};

export default AdminChangeSummaryService;
