import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import SectionRecord from "models/view-models/section-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import React, { useState } from "react";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useBookmarkActions from "utilities/hooks/domain/user-bookmarks/use-bookmark-actions";
import useRenderBookmarks from "utilities/hooks/domain/user-bookmarks/use-render-bookmarks";
import { t } from "utilities/localization-utils";
import BookmarkSettings from "./bookmark-settings";
import BookmarkSlider from "./bookmark-slider";

interface DeletedSectionContentsProps {
    bookmarks: UserBookmarkRecord[];
    section: SectionRecord;
}

const CSS_BASE_CLASS = "c-section-detail-actions";
const TEXT_CLASS = "-deleted-section-msg";

const DeletedSectionContents = (props: DeletedSectionContentsProps) => {
    const { bookmarks, section } = props;

    const [currentBookmark, setCurrentBookmark] = useState(
        new UserBookmarkRecord()
    );

    const { currentIdentity } = useGlobalState().globalState;

    const firstBookmark = bookmarks[0];
    const { createdOnPublicationId, color } = firstBookmark;

    const { handleEditBookmark } = useBookmarkActions({
        userBookmarkType: UserBookmarkType.Section,
        createdOnPublicationId: createdOnPublicationId,
        color: color,
        externalId: section.externalId,
        userId: currentIdentity?.userId() ?? 0,
        groupId: currentIdentity?.getCurrentTeamId(),
        setCurrentBookmark: setCurrentBookmark,
        bookmarks: bookmarks,
    });

    const { renderPrimaryBookmark } = useRenderBookmarks({
        baseClass: CSS_BASE_CLASS,
    });

    const {
        bookviewLayoutContext,
        handleBookmarksReadOnlyDialogOpen,
        handleBookmarksReadOnlyDialogClose,
        handleBookmarkSettingsClose,
    } = useBookviewLayoutContext();

    const bookmarkSettingsIsOpen =
        bookviewLayoutContext.bookmarkSettingsOpenForExternalId ===
        section.externalId;

    const bookmarkSliderIsOpen =
        bookviewLayoutContext.bookmarkReadonlyOpenForExternalId ===
        section.externalId;

    const bookmarkButtonAccessibleText = t("openItem", { item: t("bookmark") });
    const message = t("deletedSectionBookmark");

    return (
        <>
            <div className={CSS_BASE_CLASS}>
                <span className={TEXT_CLASS}>{message}</span>
                <div className={`${CSS_BASE_CLASS}__notes`}>
                    <Button
                        accessibleText={bookmarkButtonAccessibleText}
                        dataTestId="section-bookmark"
                        style={ButtonStyles.TertiaryAlt}
                        onClick={() =>
                            handleBookmarksReadOnlyDialogOpen(
                                section.externalId
                            )
                        }>
                        {renderPrimaryBookmark(bookmarks[0], bookmarks.length)}
                    </Button>
                </div>
            </div>
            {bookmarkSliderIsOpen && (
                <BookmarkSlider
                    bookmarks={bookmarks}
                    onClose={handleBookmarksReadOnlyDialogClose}
                    onDeleted={handleBookmarksReadOnlyDialogClose}
                    onEdit={handleEditBookmark}
                    section={section}
                    title={section.getFullyQualifiedDisplayTitle()}
                    handleAddBookmark={() => {}}
                />
            )}
            {bookmarkSettingsIsOpen && (
                <BookmarkSettings
                    onCancel={handleBookmarkSettingsClose}
                    onDelete={handleBookmarkSettingsClose}
                    bookmark={currentBookmark}
                    externalId={section.externalId}
                    section={section}
                    title={section.getFullyQualifiedDisplayTitle()}
                    userBookmarkType={UserBookmarkType.Section}
                />
            )}
        </>
    );
};

export default DeletedSectionContents;
