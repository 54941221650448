import React, { PropsWithChildren } from "react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import StringUtils from "utilities/string-utils";
import { DataTestAttributes } from "interfaces/data-test-attributes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface HeadingProps
    extends Pick<DataTestAttributes, "dataTestId" | "dataTestDisplaySequence"> {
    cssClassName?: string;
    priority?: HeadingPriority;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Heading: React.FC<PropsWithChildren<HeadingProps>> = (
    props: PropsWithChildren<HeadingProps>
) => {
    const cssClassNames: Array<string> = [];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const componentProps = {
        className: cssClassNames.join(" "),
        "data-test-id": props.dataTestId,
    };

    return React.createElement(
        `h${props.priority ?? HeadingPriority.Two}`,
        componentProps,
        props.children
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default Heading;

// #endregion Exports
