import ServiceHookResult from "utilities/interfaces/service-hook-result";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import { useCallback } from "react";
import { ToastManager } from "utilities/toast/toast-manager";
import { tErrorLoading } from "utilities/localization-utils";
import JobService from "utilities/services/jobs/job-service";
import JobRecord from "models/view-models/job-record";
import { WorkerNames } from "utilities/enumerations/worker-names";
import { PromiseUtils } from "utilities/promise-utils";
import { CollectionUtils } from "utilities/collection-utils";
import useDeepCompareEffect from "use-deep-compare-effect";

export interface UseJobsByNameOptions {
    /**
     * Limit results to jobs from the last 7 days.
     */
    reduceScope?: boolean;

    /**
     * List of names for jobs get.
     */
    workerNames: WorkerNames[];
}

interface UseJobsByNameHook extends ServiceHookResult<Array<JobRecord>> {
    refresh: () => void;
}

const useJobsByName = (options: UseJobsByNameOptions): UseJobsByNameHook => {
    const { list: listJobs } = JobService.useListByJob();

    const { reduceScope, workerNames } = options;

    const { result, setError, setLoading, setSuccess } =
        useServiceHookResultState<Array<JobRecord>>([]);

    const loadJobs = useCallback(
        async (workerNames: Array<WorkerNames>, reduceScope = false) => {
            if (CollectionUtils.isEmpty(workerNames)) {
                return;
            }

            try {
                setLoading();

                const requests = workerNames.map((name) =>
                    listJobs({ jobName: name, reduceScope })
                );

                const createdJobs = await PromiseUtils.toArray(requests);

                setSuccess(createdJobs);
            } catch (error) {
                ToastManager.error(tErrorLoading("job_plural"));
                setError(error);
            }
        },
        [listJobs, setError, setLoading, setSuccess]
    );

    const refresh = useCallback(() => {
        loadJobs(workerNames, reduceScope);
    }, [loadJobs, reduceScope, workerNames]);

    useDeepCompareEffect(() => {
        loadJobs(workerNames, reduceScope);
    }, [loadJobs, reduceScope, workerNames]);

    return {
        ...result,
        refresh,
    };
};

export default useJobsByName;
