import { SkipNavContent } from "@reach/skip-nav";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { HeadingPriority } from "atoms/constants/heading-priority";
import SelectSizes from "atoms/constants/select-sizes";
import Select, { SelectOption } from "atoms/forms/select";
import Heading from "atoms/typography/heading";
import UserRecord from "models/view-models/user-record";
import UserRoleRecord from "models/view-models/user-role-record";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import AddAdminRoleModal from "organisms/admin/users/add-admin-role-modal";
import UserManagementTable from "organisms/admin/users/user-management-table";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React, { useState } from "react";
import RoleNames from "utilities/enumerations/role-names";
import RoleType from "utilities/enumerations/role-type";
import useUserManagement from "utilities/hooks/domain/admin/users/use-user-management";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-user-management-dashboard";

const SEARCH_FORM_PLACEHOLDER = "Filter by name or email address...";
const REMOVE_ADMIN_CONFIRM_TEXT = "Are you sure you want to remove this admin?";
const REMOVE_ADMIN_CONFIRM_BUTTON_TEXT = "Yes, Remove";

const ROLE_FILTER_OPTIONS: Array<SelectOption<RoleType>> = [
    {
        label: "All Admin Roles",
        value: "all",
        data: undefined,
    },
    {
        label: "Authors",
        value: RoleNames.AUTHOR,
        data: RoleType.AUTHOR,
    },
    {
        label: "Publishers",
        value: RoleNames.PUBLISHER,
        data: RoleType.PUBLISHER,
    },
    {
        label: "Customer Support",
        value: RoleNames.SUPPORT,
        data: RoleType.SUPPORT,
    },
    {
        label: "System Admins",
        value: RoleNames.SYS_ADMIN,
        data: RoleType.SYS_ADMIN,
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserManagementPage: React.FC<any> = () => {
    // -------------------------------------------------------------------------------------------------
    // #region State
    // -------------------------------------------------------------------------------------------------

    const [searchText, setSearchText] = useState("");
    const [filterByRole, setFilterByRole] = useState<SelectOption<RoleType>>();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [editingUser, setEditingUser] = useState<UserRecord>();

    // #endregion State

    // -------------------------------------------------------------------------------------------------
    // #region Hooks
    // -------------------------------------------------------------------------------------------------

    const { updateUserRoles, loadingUsers, searchUsers, roles, users } =
        useUserManagement();

    // #endregion Hooks

    // -------------------------------------------------------------------------------------------------
    // #region Functions
    // -------------------------------------------------------------------------------------------------

    const openModal = (user: UserRecord | undefined = undefined) => {
        setEditingUser(user);
        setShowEditModal(true);
    };

    const closeModal = () => {
        setShowEditModal(false);
        setEditingUser(undefined);
    };

    // #endregion Functions

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const resetInputs = () => {
        setFilterByRole(ROLE_FILTER_OPTIONS[0]);
        setSearchText("");
    };

    const handleRemoveUserClicked = (user: UserRecord) => {
        setEditingUser(user);
        setShowDeleteConfirmModal(true);
    };

    // removes ALL admin roles
    const handleRemoveUserConfirmed = () => {
        updateUserRoles([], editingUser);
        resetInputs();
        setShowDeleteConfirmModal(false);
    };

    const handleAdminModalConfirm = (userRoles: Array<UserRoleRecord>) => {
        updateUserRoles(userRoles, editingUser);
        resetInputs();
    };

    const handleSearchTextClear = () => {
        setSearchText("");
        searchUsers("", filterByRole?.data);
    };

    const handleSearchFormSubmit = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();

        searchUsers(searchText, filterByRole?.data);

        // prevent form from actually submitting and refreshing the page
        return false;
    };

    const handleSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchText(e.target.value);

    const handleRoleFilterSelected = (
        selectedOption?: SelectOption<RoleType>
    ) => {
        setFilterByRole(selectedOption);
        searchUsers(searchText, selectedOption?.data);
    };

    // #endregion Event Handlers

    return (
        <React.Fragment>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={`c-dashboard__panel ${CSS_CLASS_NAME}`}>
                    <Heading priority={HeadingPriority.Five}>
                        Admin Users
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div className={`${CSS_CLASS_NAME}__content__controls`}>
                            <SearchForm
                                onClear={handleSearchTextClear}
                                onSearchClick={handleSearchFormSubmit}
                                onSearchTextChange={handleSearchTextChanged}
                                onSubmit={handleSearchFormSubmit}
                                placeholder={SEARCH_FORM_PLACEHOLDER}
                                searchText={searchText}
                                size={SearchFormSizes.Small}
                                style={SearchFormStyles.Tertiary}
                            />
                            <div
                                className={`${CSS_CLASS_NAME}__content__controls__right`}>
                                <Select
                                    onChange={handleRoleFilterSelected}
                                    options={ROLE_FILTER_OPTIONS}
                                    value={filterByRole?.value}
                                    size={SelectSizes.Small}
                                />
                                <Button
                                    accessibleText={"Add Admin"}
                                    onClick={() => openModal()}
                                    size={ButtonSizes.Small}
                                    type={ButtonTypes.Button}>
                                    Add Admin
                                </Button>
                            </div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__content__data`}>
                            <UserManagementTable
                                loading={loadingUsers}
                                onEditUserClick={openModal}
                                onRemoveUserClick={handleRemoveUserClicked}
                                users={users}
                            />
                        </div>
                    </div>
                </div>
            </SkipNavContent>
            {showEditModal && (
                <AddAdminRoleModal
                    closeDialog={closeModal}
                    initialUser={editingUser}
                    loading={loadingUsers}
                    onConfirm={handleAdminModalConfirm}
                    roles={roles}
                />
            )}
            <ConfirmationModal
                isVisible={showDeleteConfirmModal}
                message={REMOVE_ADMIN_CONFIRM_TEXT}
                onCancel={() => setShowDeleteConfirmModal(false)}
                onConfirm={handleRemoveUserConfirmed}
                confirmButtonText={REMOVE_ADMIN_CONFIRM_BUTTON_TEXT}
            />
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserManagementPage;

// #endregion Exports
