import React, { useCallback } from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import JobRecord from "models/view-models/job-record";
import { WorkerNames } from "utilities/enumerations/worker-names";
import JobService from "utilities/services/jobs/job-service";
import { ToastManager } from "utilities/toast/toast-manager";
import PublicationAdminBulkActionsModal from "organisms/modals/publication-admin-bulk-actions-modal/publication-admin-bulk-actions-modal";
import useModalActions from "utilities/hooks/use-modal-actions";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";

const CSS_CLASS_NAME = "c-publication-batch-bulk-actions";

const PublicationBatchBulkActions: React.FC = () => {
    const {
        context: { publicationBatch },
    } = useAdminEditorPageContext();
    const allPublicationsArePdf = publicationBatch.every(
        (e) =>
            e.publicationContentFormatType ===
            PublicationContentFormatType.Image
    );
    const allPublicationsAreXml = publicationBatch.every(
        (e) =>
            e.publicationContentFormatType === PublicationContentFormatType.Xml
    );

    const { create: bulkCreateJobs } = JobService.useBulkCreate();
    const { extractTablesFromPublicationImport } = useFeatureFlags();
    const {
        handleClose: handleReprocessModalClose,
        handleOpen: handleReprocessModalOpen,
        isOpen: isReprocessModalOpen,
    } = useModalActions();

    const {
        handleClose: handleExtractTablesModalClose,
        handleOpen: handleExtractTablesModalOpen,
        isOpen: isExtractTablesModalOpen,
    } = useModalActions();

    const extractTablesFromXml = useCallback(
        async (startNow: boolean) => {
            const jobs = publicationBatch.map(
                (publication) =>
                    new JobRecord({
                        workerName: WorkerNames.TableExtractWorker,
                        workerArgs: JSON.stringify([publication.id]),
                        startNow: startNow,
                    })
            );

            try {
                await bulkCreateJobs(jobs);
                ToastManager.success(
                    "Table extraction for publications started successfully"
                );
            } catch (error) {
                ToastManager.error(
                    "Unable to start extracting tables for publications"
                );
            }
        },
        [bulkCreateJobs, publicationBatch]
    );

    const reprocessPublicationData = useCallback(
        async (startNow: boolean) => {
            const jobs = publicationBatch.map(
                (publication) =>
                    new JobRecord({
                        workerName: WorkerNames.PublicationReprocessPublication,
                        workerArgs: JSON.stringify([publication.id, startNow]),
                    })
            );

            try {
                await bulkCreateJobs(jobs);
                ToastManager.success(
                    "Reprocess publications started successfully"
                );
            } catch (error) {
                ToastManager.error("Unable to start reprocessing publications");
            }
        },
        [bulkCreateJobs, publicationBatch]
    );

    const handleLegacyExtractTableOfContentsClick = useCallback(async () => {
        const jobs = publicationBatch.map((publication) => {
            return new JobRecord({
                workerName: WorkerNames.LegacyPublicationCreateTableOfContents,
                workerArgs: JSON.stringify([publication.id]),
            });
        });
        try {
            await bulkCreateJobs(jobs);
            ToastManager.success(
                `Extracting table of contents for ${jobs.length} legacy publications`
            );
        } catch {
            ToastManager.error("Unable to extract table of contents");
        }
    }, [bulkCreateJobs, publicationBatch]);

    return (
        <div className={`${CSS_CLASS_NAME}__button-group`}>
            {allPublicationsAreXml && (
                <>
                    {
                        <Button
                            style={ButtonStyles.Tertiary}
                            onClick={handleReprocessModalOpen}
                            cssClassName={`${CSS_CLASS_NAME}__bulk-function-button`}>
                            Reprocess Publication Data
                        </Button>
                    }
                    {extractTablesFromPublicationImport && (
                        <Button
                            style={ButtonStyles.Tertiary}
                            onClick={handleExtractTablesModalOpen}
                            cssClassName={`${CSS_CLASS_NAME}__bulk-function-button`}>
                            Extract Table Data
                        </Button>
                    )}

                    {isReprocessModalOpen && (
                        <PublicationAdminBulkActionsModal
                            closeDialog={handleReprocessModalClose}
                            isVisible={isReprocessModalOpen}
                            processLabel={"Reprocess Publication Data"}
                            publications={publicationBatch}
                            handleJob={reprocessPublicationData}
                        />
                    )}

                    {isExtractTablesModalOpen && (
                        <PublicationAdminBulkActionsModal
                            closeDialog={handleExtractTablesModalClose}
                            isVisible={isExtractTablesModalOpen}
                            processLabel={"Extract Table Data"}
                            publications={publicationBatch}
                            handleJob={extractTablesFromXml}
                        />
                    )}
                </>
            )}
            {allPublicationsArePdf && (
                <Button
                    style={ButtonStyles.Tertiary}
                    onClick={handleLegacyExtractTableOfContentsClick}
                    cssClassName={`${CSS_CLASS_NAME}__bulk-function-button`}>
                    Extract Table Of Contents
                </Button>
            )}
        </div>
    );
};

export default PublicationBatchBulkActions;
