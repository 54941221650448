import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";
import { UserSettingsKeys } from "constants/user-settings-keys";
import { Record } from "immutable";
import FeatureFlags, {
    DEFAULT_FEATURE_FLAGS,
} from "models/interfaces/feature-flags";
import type GlobalState from "models/interfaces/global-state";
import type SearchState from "models/interfaces/search-state";
import IdentityRecord from "models/view-models/identity-record";
import SearchStateRecord from "models/view-models/search-state-record";
import SystemSettingsRecord from "models/view-models/system-settings-record";
import UserSettingRecord from "models/view-models/user-setting-record";
import { CoreUtils } from "utilities/core-utils";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import StringUtils from "utilities/string-utils";
import { UserSettingTypes } from "utilities/types/user-setting-types";
import LocalizationUtils from "utilities/localization-utils";

const defaultValues: GlobalState =
    RecordUtils.defaultValuesFactory<GlobalState>({
        callbackLink: undefined,
        currentIdentity: undefined,
        currentSearch: undefined,
        hasLoadedUserConfigurationsFromNfpa: false,
        loadingGroupInvitation: false,
        marketingQueryParams: undefined,
        systemSettings: undefined,
        systemSettingsLoaded: false,
        unauthorizedResult: undefined,
        isAppInMaintenanceMode: false,
    });

export default class GlobalStateRecord
    extends Record(defaultValues)
    implements GlobalState
{
    // ---------------------------------------------------------------------------------------------
    // #region Properties
    // ---------------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // ---------------------------------------------------------------------------------------------
    // #region Constructor
    // ---------------------------------------------------------------------------------------------

    constructor(params?: GlobalState) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.systemSettings != null) {
            params.systemSettings = RecordUtils.ensureRecord(
                params.systemSettings,
                SystemSettingsRecord
            );
        }

        if (params.currentIdentity != null) {
            params.currentIdentity = RecordUtils.ensureRecord(
                params.currentIdentity,
                IdentityRecord
            );
        }

        if (params.currentSearch != null) {
            params.currentSearch = RecordUtils.ensureRecord(
                params.currentSearch,
                SearchStateRecord
            );
        }

        if (params.unauthorizedResult != null) {
            params.unauthorizedResult = RecordUtils.ensureRecord(
                params.unauthorizedResult,
                ResultRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Utility method for clearing out the ResultRecord set from an unauthorized (403) API response
     */
    public clearUnauthorizedResult(): GlobalStateRecord {
        return this.with({ unauthorizedResult: undefined });
    }

    /**
     * Retrieves the current direct search page from the underlying `SearchStateRecord`
     *
     * @default 1
     */
    public getCurrentDirectPage(): number {
        return this.currentSearch?.currentDirectPage ?? 1;
    }

    /**
     * Retrieves the current publication search page from the underlying `SearchStateRecord`
     *
     * @default 1
     */
    public getCurrentPublicationPage(): number {
        return this.currentSearch?.currentPublicationPage ?? 1;
    }

    /**
     * Retrieves the current value of the searchText from the underlying `SearchStateRecord`
     *
     * If the record has not been instantiated, or no search text has been entered by the user,
     * it returns an empty string.
     *
     * @default ""
     */
    public getCurrentSearchText(): string {
        return this.currentSearch?.searchText ?? "";
    }

    /**
     * Returns an instance of Required<FeatureFlags>.
     * Required<T> is a Typescript utility interface which makes all
     * properties of T required (non-nullable).
     */
    public getFeatureFlagsWithDefaults(): Required<FeatureFlags> {
        if (!this.systemSettingsLoaded) {
            return { ...DEFAULT_FEATURE_FLAGS };
        }

        const featureFlags = this.systemSettings?.featureFlags ?? {};
        return CoreUtils.merge(
            { ...DEFAULT_FEATURE_FLAGS },
            { ...featureFlags }
        );
    }

    /**
     * Returns the current culture code, preferring a user's setting if available.
     */
    public getPreferredOrCurrentCulture(): string {
        const preferredLanguage =
            this.getUserSetting("PreferredLanguage")?.getValue<string>();

        if (StringUtils.hasValue(preferredLanguage)) {
            return preferredLanguage!;
        }

        return LocalizationUtils.currentCultureCode();
    }

    /**
     * Utility method for returning the SystemSettingsRecord. If the object has not been set yet,
     * it will return a new, default instance of one.
     */
    public getSystemSettings(): SystemSettingsRecord {
        if (!this.hasSystemSettings()) {
            return new SystemSettingsRecord();
        }

        return this.systemSettings!;
    }

    /**
     * Utility method for returning the underlying UserLoginId (if currently authenticated)
     *
     */
    public getUserLoginId(): number | undefined {
        if (!this.isAuthenticated()) {
            return undefined;
        }

        return this.currentIdentity?.userLogin?.id;
    }

    /**
     * Convenience method for retrieving a specific `UserSettingRecord` by key
     *
     * @param key Strongly typed `UserSettingsKey` to retrieve
     */
    public getUserSetting(
        key: keyof typeof UserSettingsKeys
    ): UserSettingRecord | undefined {
        return this.currentIdentity?.getUserSetting(key);
    }

    /**
     * Convenience method for accessing `UserSettingRecord.getValue<T>()` for a specified key
     *
     * @param key Strongly typed `UserSettingsKey` to retrieve
     */
    public getUserSettingValue<T extends UserSettingTypes>(
        key: keyof typeof UserSettingsKeys
    ): T | undefined {
        return this.getUserSetting(key)?.getValue<T>();
    }

    public static getInitialGlobalState() {
        return new GlobalStateRecord().setFromLocalStorage();
    }

    /**
     * Returns whether or not the systemSettings field is non-null
     */
    public hasSystemSettings(): boolean {
        return this.systemSettings != null;
    }

    /**
     * Is the current user authenticated?
     */
    public isAuthenticated(): boolean {
        if (this.currentIdentity == null) {
            return false;
        }
        return this.currentIdentity.isValid();
    }

    /**
     * Returns a new instance of the global state record
     * with the updated identity
     */
    public setCurrentSearch(
        searchState?: Partial<SearchState>
    ): GlobalStateRecord {
        if (this.currentSearch != null) {
            return this.with({
                currentSearch: this.currentSearch.with(searchState ?? {}),
            });
        }

        return this.with({ currentSearch: new SearchStateRecord(searchState) });
    }

    /**
     * Initialize the global state record from values in local storage.
     * @returns GlobalStateRecord
     */
    public setFromLocalStorage(): GlobalStateRecord {
        return this.with({
            currentIdentity: LocalStorageUtils.get<IdentityRecord>(
                LocalStorageKey.Identity,
                IdentityRecord
            ),
            systemSettings: LocalStorageUtils.get<SystemSettingsRecord>(
                LocalStorageKey.SystemSettings,
                SystemSettingsRecord
            ),
        });
    }

    /**
     * Returns a new instance of the global state record
     * with the updated identity
     */
    public setIdentity(identity?: IdentityRecord): GlobalStateRecord {
        if (identity == null || !identity.isValid()) {
            return this.setUnauthenticated();
        }
        identity.refreshLocalStorage();

        return this.with({ currentIdentity: identity });
    }

    public setSystemSettings(
        systemSettingsRecord: SystemSettingsRecord | undefined
    ): GlobalStateRecord {
        if (systemSettingsRecord == null) {
            return new GlobalStateRecord(this.toJS());
        }

        LocalStorageUtils.set(
            LocalStorageKey.SystemSettings,
            systemSettingsRecord
        );
        return this.with({ systemSettings: systemSettingsRecord });
    }

    /**
     * Returns a new instance of the global state record
     * with the user unauthenticated
     */
    public setUnauthenticated(): GlobalStateRecord {
        localStorage.removeItem(LocalStorageKey.Identity);
        return this.with({ currentIdentity: undefined });
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<GlobalState>} values
     */
    public with(values: Partial<GlobalState>): GlobalStateRecord {
        return new GlobalStateRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Utility function to allow passing a Partial<FeatureFlags>
     *     which will be merged with the defaults.
     * @param values
     */
    public withFeatureFlags(values: Partial<FeatureFlags>): GlobalStateRecord {
        const newFeatureFlags: Required<FeatureFlags> = CoreUtils.merge(
            { ...this.getFeatureFlagsWithDefaults() },
            { values }
        );

        return this.with({
            systemSettings: this.getSystemSettings().with({
                featureFlags: newFeatureFlags,
            }),
        });
    }

    /**
     * Update and existing group name and return a new GlobalStateRecord
     * @param {number} groupId
     * @param {string} teamName
     * @return {*}  {GlobalStateRecord}
     */
    public updateTeamName(
        groupId: number,
        teamName: string
    ): GlobalStateRecord {
        const userRoles = this.currentIdentity?.userRoles.map((userRole) => {
            if (
                userRole.userRoleGroup?.group?.id === groupId &&
                userRole.userRoleGroup?.group?.name !== teamName
            ) {
                const updatedUserRole = userRole.with({
                    userRoleGroup: userRole.userRoleGroup?.with({
                        group: userRole.userRoleGroup.group?.with({
                            name: teamName,
                        }),
                    }),
                });

                return updatedUserRole;
            }

            return userRole;
        });

        return this.with({
            currentIdentity: this.currentIdentity?.with({
                userRoles,
            }),
        });
    }

    /**
     * Update an existing UserSetting record in the list of UserSettings
     */
    public updateUserSetting(
        userSetting: UserSettingRecord
    ): GlobalStateRecord {
        if (this.currentIdentity == null) {
            return this;
        }

        return this.with({
            currentIdentity:
                this.currentIdentity.updateUserSetting(userSetting),
        });
    }

    // #endregion Public Methods
}
