import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface DisclaimerTextProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-disclaimer-text";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DisclaimerText: React.FC<DisclaimerTextProps> = (
    props: DisclaimerTextProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const cssClassNames = [CSS_BASE_CLASS];
    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <div className={cssClassNames.join(" ")}>
            <p>
                <span className={`${CSS_BASE_CLASS}__disclaimer-label`}>
                    {t("disclaimerText-disclaimer")}:
                </span>
                {t("disclaimerText-disclaimerBodySection1")}
            </p>
            <p> {t("disclaimerText-disclaimerBodySection2")}</p>
        </div>
    );

    // #endregion Returns
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default DisclaimerText;

// #endregion Exports
