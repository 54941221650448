import React from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AdminSituationHotspotModalProps {
    closeDialog: () => void;
    currentHotspot?: SituationRelationshipRecord;
    handleDelete: () => void;
    hotspots?: SituationRelationshipRecord[];
    isVisible: boolean;
    onConfirm: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-admin-situation-hotspot-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AdminSituationHotspotModal: React.FunctionComponent<AdminSituationHotspotModalProps> =
    (props) => {
        const { closeDialog, isVisible, onConfirm } = props;

        return (
            <Modal
                closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
                closeDialog={closeDialog}
                cssClassName={CSS_BASE_CLASS_NAME}
                isVisible={isVisible}
                label={`Edit Hotspot`}
                type={ModalTypes.Fullscreen}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <h2>Edit Hotspot</h2>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    {props.children}
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                    <div className={`${CSS_BASE_CLASS_NAME}__footer__left`}>
                        <Button
                            style={ButtonStyles.Destructive}
                            onClick={props.handleDelete}>
                            Delete Hotspot
                        </Button>
                    </div>
                    <div className={`${CSS_BASE_CLASS_NAME}__footer__right`}>
                        <Button
                            onClick={closeDialog}
                            style={ButtonStyles.Secondary}>
                            Cancel
                        </Button>
                        <Button
                            onClick={onConfirm}
                            style={ButtonStyles.Primary}>
                            Done
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AdminSituationHotspotModal;

// #endregion Export
