import ServiceFactory from "utilities/services/service-factory";
import PublicationMetadataRecord from "models/view-models/publication-metadata-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationMetadataResourceBasePathParams {
    publicationId: number;
    type?: string;
}

export interface PublicationMetadataResourcePathParams
    extends PublicationMetadataResourceBasePathParams {
    id: number;
}

/**
 * Search parameters when listing PublicationMetadata resources.
 * These get translated to QueryString parameters
 */
export interface PublicationMetadataListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/metadata";
const resourceType = PublicationMetadataRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const PublicationMetadataService = {
    /**
     * Retrieve a specific PublicationMetadata resource
     */
    get: ServiceFactory.get<
        PublicationMetadataRecord,
        PublicationMetadataResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Retrieves a list of PublicationMetadata resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        PublicationMetadataRecord,
        PublicationMetadataListQueryParams,
        PublicationMetadataResourceBasePathParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        PublicationMetadataRecord,
        PublicationMetadataResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        PublicationMetadataRecord,
        PublicationMetadataResourceBasePathParams,
        PublicationMetadataListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default PublicationMetadataService;
