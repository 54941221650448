import PublicationService from "utilities/services/publications/publication-service";
import SectionService from "utilities/services/sections/section-service";
import SectionRecord from "models/view-models/section-record";
import { useCallback } from "react";

/**
 * Custom hook to get a section with sub sections.
 *
 * @export
 */
export default function useSectionIncludeSubSectionsApi() {
    const { get: getPublicationApi } = PublicationService.useGet();
    const { get: getSectionApi } = SectionService.useGet();
    const { list: listSectionsApi } = SectionService.useList();

    const getSectionIncludeSubSections = useCallback(
        async (sectionId?: number): Promise<SectionRecord | undefined> => {
            if (sectionId == null) {
                return;
            }

            const sectionResponse = await getSectionApi({ id: sectionId });
            const subSectionResponse = await listSectionsApi({
                parentId: sectionId,
            });
            const publicationResponse = await getPublicationApi({
                id: sectionResponse.resultObject?.publicationId,
            });

            const section = sectionResponse.resultObject;
            const publication = publicationResponse.resultObject;
            let subSections = subSectionResponse.resultObjects;

            if (section == null || publication == null) {
                return;
            }

            const subSectionsWithPublications = subSections.map(
                (section: SectionRecord) =>
                    section.with({
                        publication: publication,
                    })
            );

            const sectionWithSubSectionsAndPublication = section.with({
                subSections: subSectionsWithPublications,
                publication: publication,
            });

            return sectionWithSubSectionsAndPublication;
        },
        [getPublicationApi, getSectionApi, listSectionsApi]
    );

    return {
        getSectionIncludeSubSections,
    };
}
