import { Map } from "immutable";

export enum MetaTagTypes {
    Description = "description",
    Keywords = "keywords",
    Title = "title",
}

export interface MetaTag {
    content: string;
    name: MetaTagTypes;
    url?: string;
}

export interface HeaderData {
    /**
     *  - The key value of the map is the match path string pattern such as "/publication/:publicationId"
     *  - The value is the actual url and page title
     */
    titles: Map<string, { url: string; title: string }>;

    /**
     * HTML meta tags to insert into the document head of the HTML source
     *  - The key value of the map is the match path string pattern such as "/mylink/bookmarks"
     *  - The value is an array of MetaTags
     */
    metaTags: Map<string, Array<MetaTag>>;
}
