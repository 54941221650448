import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import JobRecord from "models/view-models/job-record";
import React from "react";
import { WorkerNames } from "utilities/enumerations/worker-names";
import PublicationBatchReprocessStatusesTable from "./publication-batch-reprocess-statuses-table";
import { PublicationBatchReprocessStatusesTableRowProps } from "./publication-batch-reprocess-statuses-table-row";
import useJobsByName from "utilities/hooks/domain/jobs/use-jobs-by-names";
import usePublicationsByIds from "utilities/hooks/domain/admin/publications/use-publications-by-ids";
import _ from "lodash";
import PublicationRecord from "models/view-models/publication-record";

const CSS_CLASS_NAME = "c-publication-batch-reprocess-statuses";

const PublicationBatchReprocessStatuses: React.FC = () => {
    const workerNames = [
        WorkerNames.PublicationReprocessPublication,
        WorkerNames.TableExtractWorker,
        WorkerNames.LegacyPublicationCreateTableOfContents,
    ];

    const { refresh: refreshJobs, resultObject: jobs } = useJobsByName({
        workerNames,
        reduceScope: true,
    });

    const publicationIds = _(jobs)
        .map((job) => getPublicationId(job))
        .uniq()
        .value();

    const { resultObject: publications } = usePublicationsByIds({
        publicationIds,
    });

    const jobRows = buildRows(jobs, publications);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <h2>Batch Actions From The Past 7 Days</h2>
                <Button
                    style={ButtonStyles.Secondary}
                    onClick={refreshJobs}
                    cssClassName={`${CSS_CLASS_NAME}__bulk-function-button`}>
                    Refresh
                </Button>
            </div>
            <PublicationBatchReprocessStatusesTable rows={jobRows} />
        </div>
    );
};

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

const buildRow = (
    job: JobRecord,
    publications: PublicationRecord[]
): PublicationBatchReprocessStatusesTableRowProps => {
    const publicationId = getPublicationId(job);

    const publication = publications.find(
        (publication) => publication.id === publicationId
    );

    return {
        workerName: job?.workerName ?? "",
        publication: publication?.getDisplayCodeEditionAndTitle() ?? "",
        status: job.status,
        createdOn: job.createdOn,
    };
};

const buildRows = (
    jobs: JobRecord[],
    publications: PublicationRecord[]
): PublicationBatchReprocessStatusesTableRowProps[] => {
    const jobRows = jobs.map((job) => buildRow(job, publications));
    return sortJobRows(jobRows);
};

const getPublicationId = (jobRecord: JobRecord): number => {
    let workerArgs = jobRecord?.workerArgs ?? "";

    return JSON.parse(workerArgs)[0].Data;
};

const sortJobRows = (
    jobRows: PublicationBatchReprocessStatusesTableRowProps[]
) => {
    return [...jobRows].sort((a, b) => {
        const aDate = new Date(a?.createdOn || "").valueOf();
        const bDate = new Date(b?.createdOn || "").valueOf();

        return bDate - aDate;
    });
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationBatchReprocessStatuses;

// #endregion Exports
