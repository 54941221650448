import { CaapsTypes } from "constants/caaps-types";
import { atom, useAtom } from "jotai";
import TiaType from "models/enumerations/tia-type";
import SectionRecord from "models/view-models/section-record";
import TiaRecord from "models/view-models/tia-record";
import { GroupedTias } from "organisms/panels/tia-changes/tia-list-panel";
import { useCallback, useMemo } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import DateUtils from "utilities/date-utils";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import TiaService from "utilities/services/publications/tias/tia-service";
import { ToastManager } from "utilities/toast/toast-manager";

const TiasAtom = atom<TiaRecord[]>([]);
const LoadingAtom = atom<boolean>(false);
const CurrentExternalIdAtom = atom<string | null>(null);
const ActiveTIAIndex = atom<number>(0);
const GroupedTiasAtom = atom<GroupedTias[]>([]);

const useTias = (externalId?: string) => {
    const [tias, setTias] = useAtom(TiasAtom);
    const [loading, setLoading] = useAtom(LoadingAtom);
    const [currentExternalId, setCurrentExternalId] = useAtom(
        CurrentExternalIdAtom
    );
    const [activeTiaIndex, setActiveTiaIndex] = useAtom(ActiveTIAIndex);

    const [groupedTias, setGroupedTias] = useAtom(GroupedTiasAtom);

    // -------------------------------------------------------------------------------------------------
    // TIA Panel
    // -------------------------------------------------------------------------------------------------

    const sortedTias: TiaRecord[] = useMemo(() => {
        const tiasFilteredByExternalId =
            externalId != null
                ? tias.filter((x) => x.externalId === externalId)
                : [];

        return DateUtils.sortByDate(tiasFilteredByExternalId, "effectiveDate");
    }, [externalId, tias]);

    const loadTias = useCallback(
        async (publicationId: number) => {
            setLoading(true);
            try {
                const result = await TiaService.list({
                    publicationId,
                });
                setTias(result.resultObjects);
            } catch (error) {
                setTias([]);
                ToastManager.error(`Issue retrieving TIAs: ${error}`);
            } finally {
                setLoading(false);
            }
        },
        [setLoading, setTias]
    );

    const handleClose = () => {
        const section = document.getElementById(currentExternalId!);
        setCurrentExternalId(null);
        setTimeout(() => section?.scrollIntoView({ block: "start" }));
    };

    const handleTiaClick = (tiaExternalId: string) => {
        handleTiaListPanelClose();
        setCurrentExternalId(tiaExternalId);
    };

    // scroll the current section to the top of the viewport
    // ensures the section and panel are in view after opening the panel
    if (currentExternalId != null) {
        const section = document.getElementById(currentExternalId);
        setTimeout(() => section?.scrollIntoView({ block: "start" }));
    }

    // -------------------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------------------
    // TIA List Panel
    // -------------------------------------------------------------------------------------------------

    const getTiasForCaapsItem = (id: number, key: keyof TiaRecord) =>
        tias.filter((t) => t[key] === id && t.caapsType === CaapsTypes.Section);

    const getDates = () =>
        Array.from(new Set(sortedTias.map((t) => t.effectiveDate!)));

    const groupCaapsTiasByDate = (
        id: number | undefined,
        type: PublicationComponentType
    ) => {
        if (id == null) return;

        const key = caapsIdMap[type];

        const effectiveDates = getDates();
        const caapsTias = getTiasForCaapsItem(id, key);

        setGroupedTias(
            effectiveDates.map((date) => ({
                date,
                tias: caapsTias.filter((t) => t.effectiveDate === date),
            }))
        );
    };

    const handleTiaListPanelOpen = (
        id: number | undefined,
        externalId: string,
        type: PublicationComponentType
    ) => {
        setCurrentExternalId(null);
        groupCaapsTiasByDate(id, type);
    };

    const handleTiaListPanelClose = () => setGroupedTias([]);

    // -------------------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------------------
    // Hidden Content Banner
    // -------------------------------------------------------------------------------------------------

    const hasDeletedTiasForSection = (
        id: number | undefined,
        externalId: string | undefined
    ) => {
        if (id == null || externalId == null) return false;

        return tias.some(
            (t) =>
                t.type === TiaType.Deletion &&
                (t.externalId === externalId || t.rootSectionId === id)
        );
    };

    const hasDeletedTiasForAnnexGroup = (
        id: number | undefined,
        sections: SectionRecord[] | undefined
    ) => {
        if (id == null || sections == null) return false;

        return tias
            .filter(
                (tia) => tia.type === TiaType.Deletion && tia.annexId === id
            )
            .some((tia) =>
                sections.some(
                    (section) => section.externalId === tia.externalId
                )
            );
    };

    const hasDeletedTiasForCaapsType = ({
        id,
        type,
        externalId,
        sections,
    }: DeletedTiaOptions) => {
        if (id == null) return false;

        if (type === PublicationComponentType.Section)
            return hasDeletedTiasForSection(id, externalId);

        if (type === PublicationComponentType.AnnexGroup)
            return hasDeletedTiasForAnnexGroup(id, sections);

        const key = caapsIdMap[type];

        return tias.some((t) => t.type === TiaType.Deletion && t[key] === id);
    };

    // -------------------------------------------------------------------------------------------------

    const closePanels = () => {
        setCurrentExternalId(null);
        handleTiaListPanelClose();
    };

    return {
        activeTiaIndex,
        currentTia:
            DateUtils.sortByDate(
                tias.filter((tia) => currentExternalId === tia.externalId),
                "effectiveDate"
            )[activeTiaIndex] ?? new TiaRecord(),
        tias: sortedTias,
        loading,
        currentExternalId,
        isTiaPanelOpen:
            currentExternalId != null || CollectionUtils.hasValues(groupedTias),
        isTiaListPanelOpen: CollectionUtils.hasValues(groupedTias),
        groupedTias,
        closePanels,
        setActiveTiaIndex,
        setTias,
        setLoading,
        setCurrentExternalId,
        loadTias,
        handleTiaClick,
        handleClose,
        hasDeletedTiasForCaapsType,
        handleTiaListPanelClose,
        handleTiaListPanelOpen,
    };
};

export default useTias;

const caapsIdMap: Record<number, keyof TiaRecord> = {
    [PublicationComponentType.Annex]: "annexId",
    [PublicationComponentType.Article]: "articleId",
    [PublicationComponentType.Chapter]: "chapterId",
    [PublicationComponentType.Part]: "partId",
    [PublicationComponentType.Section]: "rootSectionId",
};

interface DeletedTiaOptions {
    id: number | undefined;
    type: PublicationComponentType;
    externalId?: string;
    sections?: SectionRecord[];
}
