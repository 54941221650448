import YoutubeMetadataRecord from "models/view-models/external/youtube/youtube-metadata-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface YoutubeMetadataPathParams {
    videoId: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "external/youtube/metadata/:videoId";
const resourceType = YoutubeMetadataRecord;
const key = "external-youtube-metadata";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const YoutubeMetadataService = {
    get: ServiceFactory.get<YoutubeMetadataRecord, YoutubeMetadataPathParams>(
        resourceType,
        baseEndpoint
    ),
    useGet: ServiceHookFactory.useGet<
        YoutubeMetadataRecord,
        YoutubeMetadataPathParams
    >(resourceType, baseEndpoint),
    useGetQuery: QueryHookFactory.useGetQuery<
        YoutubeMetadataRecord,
        YoutubeMetadataPathParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default YoutubeMetadataService;

// #endregion Exports
