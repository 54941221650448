import OrganizationErrorKey from "constants/organization-error-keys";
import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import OrganizationManifestsService from "utilities/services/organizations/organization-manifests-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { useCallback, useState } from "react";

const defaultErrorHandler = () =>
    ToastManager.error(
        "There was an issue loading your organization login details. Please try again."
    );

export default function useOrganizationManifests(onError?: () => void) {
    const [isLoading, setIsLoading] = useState(false);
    const [organizationManifests, setOrganizationManifests] = useState<
        OrganizationManifestRecord[] | null
    >(null);
    const { list: listApi } = OrganizationManifestsService.useList();

    // Handlers
    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler();
    }, [onError]);

    const list = useCallback(async () => {
        setIsLoading(true);

        try {
            const result = await listApi();

            if (result.resultObjects === undefined) {
                throw new Error(
                    OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR
                );
            }

            setOrganizationManifests(result.resultObjects);
        } catch (error) {
            handleError();
        } finally {
            setIsLoading(false);
        }
    }, [handleError, listApi]);

    return {
        list,
        isLoading,
        organizationManifests,
    };
}
