import { ErrorLike } from "utilities/types/error-like";
import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";
import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";
import _ from "lodash";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const NETWORK_ERROR = "Network Error";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

const ErrorUtils = {
    NETWORK_ERROR,
    errorLikeToError(errors?: ErrorLike): Error | undefined {
        if (errors == null) {
            return undefined;
        }

        if (_.isError(errors)) {
            return errors;
        }

        if (_.isString(errors) && StringUtils.hasValue(errors)) {
            return new Error(errors);
        }

        if (isStringArray(errors) && CollectionUtils.hasValues(errors)) {
            return new Error(errors.join("\n"));
        }

        if (RecordUtils.isRecord(errors, ResultRecord) && errors.hasErrors()) {
            return new Error(errors.listErrorMessages().join("\n"));
        }
    },
    isNetworkError(error: any): boolean {
        const isNetworkError =
            _.isError(error) && error.message.includes(NETWORK_ERROR);

        const isStringNetworkError =
            _.isString(error) && error.includes(NETWORK_ERROR);

        const isStringArrayNetworkError =
            isStringArray(error) &&
            error.some((value: string) => value.includes(NETWORK_ERROR));
        return (
            isNetworkError || isStringNetworkError || isStringArrayNetworkError
        );
    },
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const isStringArray = (maybeArray: any): maybeArray is string[] =>
    Array.isArray(maybeArray) && maybeArray.every(_.isString);

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ErrorUtils;

// #endregion Exports
