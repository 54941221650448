import { useEffect } from "react";

export default function usePreventRightClick() {
    const preventRightClick = (e: KeyboardEvent) => {
        if (e.shiftKey && e.key === "F10") {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", preventRightClick);

        return () => {
            document.body.removeEventListener("keydown", preventRightClick);
        };
    }, []);
}
