import { Record } from "immutable";
import type CreateFileRequest from "models/interfaces/create-file-request";
import FileRecord from "models/view-models/file-record";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: CreateFileRequest =
    RecordUtils.auditableDefaultValuesFactory({
        fileUploadDestination: undefined,
        presignedUrl: undefined,
        relativeProviderPath: "",
        storageContainer: undefined,
        suggestedCode: undefined,
        suggestedEdition: undefined,
        suggestedLocale: undefined,
    });

export default class CreateFileRequestRecord
    extends Record(defaultValues)
    implements CreateFileRequest
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: CreateFileRequest) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public static fromFileRecord(
        file: FileRecord,
        fileUploadDestination?: FileUploadDestination
    ): CreateFileRequestRecord {
        const request = new CreateFileRequestRecord(file);
        return request.with({ fileUploadDestination });
    }

    public toFileRecord(): FileRecord {
        return new FileRecord(this.toJS());
    }

    public with(values: Partial<CreateFileRequest>): CreateFileRequestRecord {
        return new CreateFileRequestRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
