import UserCollectionRecord from "models/view-models/user-collection-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionBasePathParams {
    /**
     * Id of the User for which the UserCollections relate to
     */
    userId: number;
}

export interface UserCollectionResourcePathParams
    extends UserCollectionBasePathParams {
    /**
     * Id of the UserCollection resource
     */
    id: number;
}

/**
 * Search parameters when listing UserCollection resources
 * These get translated to QueryString parameters
 */
export interface UserCollectionListQueryParams {
    bookmarkId?: number;
    searchText?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/collections";
const resourceType = UserCollectionRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "usercollections";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserCollectionService = {
    create: ServiceFactory.nestedCreate<
        UserCollectionRecord,
        UserCollectionBasePathParams
    >(UserCollectionRecord, baseEndpoint),
    delete: ServiceFactory.delete<UserCollectionResourcePathParams>(
        resourceEndpoint
    ),
    get: ServiceFactory.get<
        UserCollectionRecord,
        UserCollectionResourcePathParams
    >(resourceType, resourceEndpoint),
    list: ServiceFactory.nestedList<
        UserCollectionRecord,
        UserCollectionBasePathParams,
        UserCollectionListQueryParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.update<
        UserCollectionRecord,
        UserCollectionResourcePathParams
    >(resourceType, resourceEndpoint),
    useCreate: ServiceHookFactory.useNestedCreate<
        UserCollectionRecord,
        UserCollectionBasePathParams
    >(UserCollectionRecord, baseEndpoint),
    useDelete:
        ServiceHookFactory.useDelete<UserCollectionResourcePathParams>(
            resourceEndpoint
        ),
    useGet: ServiceHookFactory.useGet<
        UserCollectionRecord,
        UserCollectionResourcePathParams
    >(resourceType, resourceEndpoint),
    useGetQuery: QueryHookFactory.useGetQuery<
        UserCollectionRecord,
        UserCollectionResourcePathParams
    >(key, resourceType, resourceEndpoint),
    useList: ServiceHookFactory.useNestedList<
        UserCollectionRecord,
        UserCollectionBasePathParams,
        UserCollectionListQueryParams
    >(resourceType, baseEndpoint),
    useUpdate: ServiceHookFactory.useUpdate<
        UserCollectionRecord,
        UserCollectionResourcePathParams
    >(resourceType, resourceEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        UserCollectionRecord,
        UserCollectionBasePathParams,
        UserCollectionListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionService;

// #endregion Exports
