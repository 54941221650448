import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import React, { useEffect } from "react";
import OnboardingLayout from "templates/onboarding-layout";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { ToastManager } from "utilities/toast/toast-manager";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";

interface UnauthenticatedOverlayProps {
    onDismiss?: () => void;
}

const UnauthenticatedOverlay: React.FunctionComponent<UnauthenticatedOverlayProps> =
    (props: UnauthenticatedOverlayProps) => {
        const title = t("expired-offline-session-alert-title");
        const description = t("expired-offline-session-alert-description");
        const { globalState, setGlobalState } = useGlobalState();
        const networkState = useNetworkInformation();
        const history = useHistory();

        useEffect(() => {
            if (!globalState.isAuthenticated()) {
                return;
            }
            setGlobalState((prev) => prev.setUnauthenticated());
        });

        const handleDismiss = () => {
            if (!networkState.isOnline) {
                ToastManager.error(description);
                return;
            }
            history.push(siteMap.userlogins.new);
        };

        return (
            <OnboardingLayout>
                <div className="c-overlay -unauthenticated">
                    <AlertNotice
                        level={AlertLevels.Error}
                        title={title}
                        description={description}
                        onClick={props.onDismiss ?? handleDismiss}
                        buttonText={t("signIn")}
                    />
                </div>
            </OnboardingLayout>
        );
    };

export default UnauthenticatedOverlay;
