import { Record } from "immutable";
import type SolutionsPageContext from "models/interfaces/situational-navigation/solutions/solutions-page-context";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SolutionsPageContext =
    RecordUtils.defaultValuesFactory<SolutionsPageContext>({
        categories: new CategoryCollectionRecord(),
    });

export default class SolutionsPageContextRecord
    extends Record(defaultValues)
    implements SolutionsPageContext
{
    // ---------------------------------------------------------------------------------------------
    // #region Properties
    // ---------------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SolutionsPageContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        params.categories = new CategoryCollectionRecord(params.categories);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SolutionsPageContext>} values
     */
    public with(
        values: Partial<SolutionsPageContext>
    ): SolutionsPageContextRecord {
        return new SolutionsPageContextRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
