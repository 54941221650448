import { useState, useEffect } from "react";
import PublicationRecord from "models/view-models/publication-record";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import StringUtils from "utilities/string-utils";
import ServiceHookResult from "utilities/interfaces/service-hook-result";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UsePreviewPublicationsOptions {
    topicIds: Array<number>;
    skip: number;
    searchText?: string;
    take: number;
}

interface UsePreviewPublicationsResult
    extends ServiceHookResult<PublicationRecord[]> {
    filtering: boolean;
    searching: boolean;
}

// #endregion Interfaces

export default function usePreviewPublications(
    options: UsePreviewPublicationsOptions
): UsePreviewPublicationsResult {
    const { topicIds, skip, searchText, take } = options;

    const [isSearching, setIsSearching] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [publications, setPublications] = useState<Array<PublicationRecord>>(
        []
    );

    const { loading, resultObject, ...publicationsHookResult } =
        usePublications({
            isPublic: true,
            searchText: searchText,
            skip: StringUtils.hasValue(searchText) ? skip : undefined,
            take: StringUtils.hasValue(searchText) ? take : undefined,
            topicIds: topicIds,
        });

    useEffect(() => {
        setIsFiltering(true);
    }, [topicIds]);

    useEffect(() => {
        setIsSearching(true);
    }, [searchText]);

    useEffect(() => {
        if (loading) {
            return;
        }

        setIsFiltering(false);
        setIsSearching(false);
        setPublications(resultObject);
    }, [loading, resultObject]);

    return {
        ...publicationsHookResult,
        filtering: isFiltering,
        loading,
        resultObject: publications,
        searching: isSearching,
    };
}
