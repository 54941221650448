import React from "react";
import CheckboxButton from "atoms/forms/checkbox-button";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import TopicRecord from "models/view-models/topic-record";
import Loader from "atoms/loaders/loader";
import { CollectionUtils } from "utilities/collection-utils";
import Tooltip from "molecules/tooltips/tooltip";
import { LoaderStyles } from "atoms/constants/loader-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UserTopicsListProps {
    disabled?: boolean;
    /**
     * Optional parameter to prevent user from deselecting all options. Developer note, this is enforced
     * on the backend regardless.
     */
    isSelectionRequired?: boolean;
    loaderType?: LoaderStyles;
    onCheck: (
        e: React.ChangeEvent<HTMLInputElement>,
        identifier?: number | undefined
    ) => void;
    topics: TopicRecord[];
    loading: boolean;
    loaded: boolean;
    selectedTopicIds: number[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-user-topics-list";
const TOOLTIP_DELAY_IN_MS = 500;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserTopicsList: React.FC<UserTopicsListProps> = (
    props: UserTopicsListProps
) => {
    const {
        disabled: disableAll,
        isSelectionRequired,
        onCheck: handleCheck,
        topics,
        loading,
        loaded,
        selectedTopicIds,
    } = props;
    const showLoader = loading && !loaded;

    return (
        <div className={CLASS_NAME}>
            {showLoader && <Loader type={props.loaderType} />}

            {!showLoader && CollectionUtils.hasValues(topics) && (
                <React.Fragment>
                    <fieldset>
                        <legend>
                            <span className="sr-only">I am interested in</span>
                        </legend>
                        {topics.map((topic: TopicRecord) => {
                            if (topic.id == null) {
                                return null;
                            }
                            const selected = isSelected(
                                topic.externalIdentifier!,
                                selectedTopicIds
                            );

                            const isLastSelected =
                                selectedTopicIds.length === 1 &&
                                selected &&
                                isSelectionRequired;

                            const button = (
                                <TopicCheckbox
                                    key={topic.id}
                                    topic={topic}
                                    disabled={
                                        (isLastSelected || disableAll) ?? false
                                    }
                                    selected={selected}
                                    handleCheck={handleCheck}
                                />
                            );

                            return !(isSelectionRequired && isLastSelected) ? (
                                button
                            ) : (
                                <Tooltip
                                    key={topic.id}
                                    content={
                                        "At least one topic selected is required"
                                    }
                                    delayInMs={TOOLTIP_DELAY_IN_MS}
                                    interactive={true}>
                                    <span>{button}</span>
                                </Tooltip>
                            );
                        })}
                    </fieldset>
                </React.Fragment>
            )}
        </div>
    );
};

const TopicCheckbox = ({
    topic,
    disabled,
    selected,
    handleCheck,
}: {
    topic: TopicRecord;
    disabled: boolean;
    selected: boolean;
    handleCheck: (
        e: React.ChangeEvent<HTMLInputElement>,
        identifier?: number | undefined
    ) => void;
}) => {
    return (
        <CheckboxButton
            checked={selected}
            disabled={disabled}
            identifier={topic.externalIdentifier}
            key={topic.id!}
            label={topic.name}
            onChange={handleCheck}>
            <Paragraph size={ParagraphSizes.XSmall}>{topic.name}</Paragraph>
        </CheckboxButton>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const isSelected = (
    identifier: number,
    selectedIdentifiers: number[]
): boolean => {
    return selectedIdentifiers.some((s: number) => s === identifier);
};
// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default UserTopicsList;

// #endregion Exports
