import { ContactFormSubmission } from "models/interfaces/contact-form-submission";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";

class ContactFormSubmissionValidator extends ObjectValidator<ContactFormSubmission> {
    public validate(
        form: ContactFormSubmission
    ): ObjectValidationResult<ContactFormSubmission> {
        const validationResult: ObjectValidationResult<ContactFormSubmission> =
            {};
        const nameLabel = t("field-fullName");
        const emailAddressLabel = t("field-email");
        const phoneLabel = t("phone");

        if (StringUtils.isEmpty(form.name)) {
            validationResult.name = [
                t("propertyIsRequired", { name: nameLabel }),
            ];
        }

        if (StringUtils.isEmpty(form.email)) {
            validationResult.email = [
                t("propertyIsRequired", { name: emailAddressLabel }),
            ];
        }

        if (
            StringUtils.hasValue(form.email) &&
            !StringUtils.isValidEmail(form.email)
        ) {
            validationResult.email = [
                t("field-invalid", { name: emailAddressLabel }),
            ];
        }

        if (StringUtils.isEmpty(form.phone)) {
            validationResult.phone = [
                t("propertyIsRequired", { name: phoneLabel }),
            ];
        }

        if (
            StringUtils.hasValue(form.phone) &&
            !StringUtils.isValidPhoneNumber(form.phone)
        ) {
            validationResult.phone = [t("field-invalid", { name: phoneLabel })];
        }

        if (!form.captchaComplete) {
            validationResult.captchaComplete = [
                t("field-invalid", { name: "reCAPTCHA" }),
            ];
        }

        return validationResult;
    }
}

export { ContactFormSubmissionValidator };
