import { PublicationParams } from "interfaces/routing/publication-params";
import PublicationContentRedirect from "organisms/publication-content/legacy-redirects/publication-content-redirect";
import React from "react";
import { useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import usePublicationQuery from "utilities/hooks/domain/publications/use-publication-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationRedirectProps {}

interface RouteParams extends Pick<PublicationParams, "id"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationRedirect: React.FC<PublicationRedirectProps> = (
    props: PublicationRedirectProps
) => {
    const { id: publicationId } = useParams<RouteParams>();
    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: publication,
        loaded,
        loading,
    } = usePublicationQuery({
        id: NumberUtils.parseInt(publicationId),
        isAdmin: isAdminPreview,
    });

    const route = RouteUtils.getUrl(
        siteMap.publications.permalinks.publication,
        {
            code: publication.code,
            edition: publication.edition,
        }
    );

    return (
        <PublicationContentRedirect
            entities={[publication]}
            loaded={loaded}
            loading={loading}
            route={route}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationRedirect;

// #endregion Exports
