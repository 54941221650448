import type FileRecord from "models/view-models/file-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import { useEffect, useState } from "react";
import useLoading from "utilities/hooks/use-loading";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import FileService from "utilities/services/file-service";
import SituationService from "utilities/services/situational-navigation/situations/situation-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook wrapper to handle state and api interactions for Situations in the administrative editors
 *
 * @param  {string} id?
 */
export default function useSituation(
    id?: string
): ServiceHookResult<SituationRecord> {
    // -----------------------------------------------------------------------------------------
    // #region State Hooks
    // -----------------------------------------------------------------------------------------

    const [loadingSituation, setLoadingSituation] = useState(false);
    const [loadingHotSpotImage, setLoadingHotSpotImage] = useState(false);
    const [situation, setSituation] = useState(new SituationRecord());

    const { pageErrors, handlePageLoadError, resetPageErrors } =
        usePageErrors();
    const loading = useLoading(loadingSituation, loadingHotSpotImage);
    const [loaded, setLoaded] = useState(false);

    // #endregion State Hooks

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { get: getSituationApi } = SituationService.useGet();
    const { get: getFileApi } = FileService.useGet();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        const situationId = NumberUtils.parseInt(id);
        resetPageErrors();
        if (situationId == null) {
            return;
        }

        const loadFile = async (id: number) => {
            setLoadingHotSpotImage(true);
            try {
                const fileResult = await getFileApi({ id });
                setLoadingHotSpotImage(false);
                return fileResult.resultObject;
            } catch (e) {
                handlePageLoadError(e);
                ToastManager.error(
                    "There was an issue loading hot spot image."
                );
                setLoadingHotSpotImage(false);
            }
        };

        const loadSituation = async (situationId: number) => {
            setLoaded(false);
            setLoadingSituation(true);

            try {
                const result = await getSituationApi({ id: situationId });
                let hotSpotImageFile: FileRecord | undefined = undefined;
                let hotSpotImageFileDraft: FileRecord | undefined = undefined;
                if (result.resultObject?.hotSpotImageFileId != null) {
                    hotSpotImageFile = await loadFile(
                        result.resultObject.hotSpotImageFileId
                    );
                }
                if (result.resultObject?.hotSpotImageFileDraftId != null) {
                    hotSpotImageFileDraft = await loadFile(
                        result.resultObject.hotSpotImageFileDraftId
                    );
                }
                const situationResult = result.resultObject?.with({
                    hotSpotImageFile,
                    hotSpotImageFileDraft,
                });
                if (situationResult == null) {
                    return;
                }

                setSituation(situationResult);
                setLoaded(true);
            } catch (e) {
                handlePageLoadError(e);
                ToastManager.error("There was an issue loading the situation.");
                setLoaded(false);
            }

            setLoadingSituation(false);
        };

        loadSituation(situationId);
    }, [id, getSituationApi, getFileApi, resetPageErrors, handlePageLoadError]);

    // #endregion Public Methods

    return {
        resultObject: situation,
        errors: pageErrors,
        loading: loading,
        loaded: loaded,
    };
}
