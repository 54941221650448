import JobStatuses from "models/enumerations/jobs/job-statuses";
import PublishStatus from "models/enumerations/publish-status";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import React from "react";
import { DateTime } from "schema-dts";
import DateUtils from "utilities/date-utils";

export interface FreeAccessPublicationAdminJobStatusesTableRowProps {
    fileName: string;
    workerName: string;
    status: JobStatuses | undefined;
    createdOn: DateTime | undefined;
    totalPages?: number;
    isPublished?: boolean;
}

const FreeAccessPublicationAdminJobStatusesRow: React.FC<FreeAccessPublicationAdminJobStatusesTableRowProps> =
    (props: FreeAccessPublicationAdminJobStatusesTableRowProps) => {
        const {
            fileName,
            workerName,
            status,
            createdOn,
            totalPages,
            isPublished,
        } = props;

        const getStatus = (): string => {
            switch (status) {
                case JobStatuses.Completed:
                    return "Completed";
                case JobStatuses.Errored:
                    return "Errored";
                case JobStatuses.InProgress:
                default:
                    return "In Progress";
            }
        };

        const formatDate = (): string => DateUtils.formatDate(createdOn);

        return (
            <tr>
                <td>{fileName}</td>
                <td>{workerName}</td>
                <td>{getStatus()}</td>
                <td>
                    <Badge isPublished={isPublished} />
                </td>
                <td>{totalPages}</td>
                <td>{formatDate()}</td>
            </tr>
        );
    };

export default FreeAccessPublicationAdminJobStatusesRow;

const Badge = ({ isPublished }: { isPublished: boolean | undefined }) => {
    if (isPublished == null) return null;

    const status = isPublished
        ? PublishStatus.Published
        : PublishStatus.Unpublished;

    return (
        <PublishStatusBadge
            status={status}
            hasUnpublishedChanges={false}
            small={true}
        />
    );
};
