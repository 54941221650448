import * as React from "react";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";

export interface CommitteesIntroductionProps {
    children?: JSX.Element[];
}

const CommitteesIntroduction = (props: CommitteesIntroductionProps) => {
    return (
        <Heading
            cssClassName="c-committee-list__name"
            priority={HeadingPriority.Two}>
            {props.children}
        </Heading>
    );
};

export default CommitteesIntroduction;
