import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EquationProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-equation";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Equation: React.FunctionComponent<EquationProps> = (
    props: EquationProps
) => {
    const expandEquation = t("expandItem", { item: t("equation") });
    const equationView = t("itemView", { item: t("equation") });

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    // #endregion Hooks

    const closeButtonStyle = isMobile
        ? ModalCloseButtonStyle.InsideDialog
        : ModalCloseButtonStyle.OutsideDialog;

    const isDeleted = props.diffchanged === XmlChangeNotationConstants.DELETION;

    return (
        <React.Fragment>
            <Button
                accessibleText={expandEquation}
                cssClassName={`${BASE_CLASS_NAME} ${
                    isDeleted && "c-code-change -deletion"
                }`}
                onClick={() => setShowFullScreen(true)}
                style={ButtonStyles.None}>
                {props.children}
            </Button>

            {showFullScreen && (
                <Modal
                    closeButtonStyle={closeButtonStyle}
                    closeDialog={() => setShowFullScreen(false)}
                    cssClassName={`${BASE_CLASS_NAME}__modal`}
                    isVisible={showFullScreen}
                    label={equationView}
                    type={ModalTypes.Fullscreen}>
                    {props.children}
                </Modal>
            )}
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default Equation;

// #endregion Export
