import type CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import type CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import { useCallback, useEffect, useState } from "react";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";

/**
 * Handle state of the back button for situations and solutions based on the context
 * Decides whether to return the user to the mobile results, desktop results page, or empty dashboard
 *
 * @param  {CategoryCollectionRecord} categories
 * @param  {boolean} isDesktop
 */
export default function useReturnRoute(
    categories: CategoryCollectionRecord,
    isDesktop: boolean
) {
    const [returnRoute, setReturnRoute] = useState<string>("");

    const getReturnRoute = useCallback(() => {
        if (!categories.hasValues() || isDesktop) {
            return siteMap.situationalNavigation.dashboard;
        }
        const relatedIdArray = categories.mapToArray(
            (c: CategoryRecord) => c.id
        );
        return RouteUtils.appendQueryParams(
            siteMap.situationalNavigation.results,
            { relatedCategoryIds: relatedIdArray }
        );
    }, [categories, isDesktop]);

    useEffect(() => {
        setReturnRoute(getReturnRoute());
    }, [getReturnRoute]);

    return returnRoute;
}
