import * as React from "react";
import UnorderedList from "molecules/lists/unordered-list";

interface RadioListProps {
    items: JSX.Element[];
    markerPosition?: RadioMarkerPosition;
    style?: RadioListStyles;
}

export enum RadioListStyles {
    Default = "-default",
    Button = "-button-style",
    None = "-none-style",
}

export enum RadioMarkerPosition {
    Left = "-markers-left",
    Right = "-markers-right",
}

const RadioList: React.FunctionComponent<RadioListProps> = (props) => {
    const { items, markerPosition, style } = props;
    const cssBaseClassName = "c-radio-list";

    if (items.length === 0) {
        return null;
    }

    const classNames = [cssBaseClassName];
    if (style != null) {
        classNames.push(style);
    }

    classNames.push(markerPosition ?? RadioMarkerPosition.Right);

    return (
        <fieldset className={classNames.join(" ")}>
            <UnorderedList listItems={items} />
        </fieldset>
    );
};

export default RadioList;
