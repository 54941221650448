import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { PublicationParams } from "interfaces/routing/publication-params";
import PublicationContentRedirect from "organisms/publication-content/legacy-redirects/publication-content-redirect";
import React from "react";
import { useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";
import usePublicationQuery from "utilities/hooks/domain/publications/use-publication-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useLoading from "utilities/hooks/use-loading";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface AnnexRedirectProps {}

interface RouteParams
    extends Pick<NestedPublicationParams, "publicationId">,
        Pick<PublicationParams, "id"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AnnexRedirect: React.FC<AnnexRedirectProps> = (
    props: AnnexRedirectProps
) => {
    const { publicationId, id: annexId } = useParams<RouteParams>();
    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: annex,
        loaded: annexLoaded,
        loading: annexLoading,
    } = useAnnex(publicationId, annexId);
    const {
        resultObject: publication,
        loaded: publicationLoaded,
        loading: publicationLoading,
    } = usePublicationQuery({
        id: NumberUtils.parseInt(publicationId),
        isAdmin: isAdminPreview,
    });

    const loading = useLoading(annexLoading, publicationLoading);
    const loaded = publicationLoaded && annexLoaded;

    const route = RouteUtils.getUrl(siteMap.publications.permalinks.annex, {
        code: publication.code,
        edition: publication.edition,
        nfpaLabel: annex.nfpaLabel,
    });

    return (
        <PublicationContentRedirect
            entities={[annex, publication]}
            loaded={loaded}
            loading={loading}
            route={route}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AnnexRedirect;

// #endregion Exports
