import { Icons } from "atoms/constants/icons";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import React, { ChangeEvent } from "react";
import { t } from "utilities/localization-utils";

interface WorkGroupManageSearchProps {
    searchText: string;
    setSearchText: (value: React.SetStateAction<string>) => void;
}
const BASE_CLASS = "c-account-dashboard__workgroups__search";

const WorkGroupManageSearch: React.FC<WorkGroupManageSearchProps> = (props) => {
    const { searchText, setSearchText } = props;

    const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchClear = () => {
        setSearchText("");
    };

    const handleWithSearch = (e: React.FormEvent | React.MouseEvent) => {
        e.preventDefault();
    };

    return (
        <div className={BASE_CLASS}>
            <SearchForm
                accessibleText={t("manageWorkGroups-searchForm-accessibleText")}
                buttonIcon={Icons.Search}
                id="workgroups-search-input"
                onClear={handleSearchClear}
                onSearchClick={handleWithSearch}
                onSearchTextChange={handleSearchTextChange}
                onSubmit={handleWithSearch}
                placeholder={t("manageWorkGroups-searchForm-placeholder")}
                searchText={searchText}
                size={SearchFormSizes.Small}
                style={SearchFormStyles.Tertiary}
            />
        </div>
    );
};

export default WorkGroupManageSearch;
