import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationsBreadcrumb: React.FC = () => {
    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">Situations</span>
        </Breadcrumb>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationsBreadcrumb;

// #endregion Exports
