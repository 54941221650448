import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import AdminEnhancedContentService from "utilities/services/admin/enhanced-content/enhanced-content-service";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_UPDATING_ENHANCED_CONTENT_NOTE =
    "There was an issue updating the enhanced content note.";

// #endregion Constants

/**
 * Custom hook to update enhanced content on api.
 */
export default function useUpdateEnhancedContentAdminNoteResources() {
    const { update: updateApi } = AdminEnhancedContentService.useUpdate();

    /**
     * Hook to update enhanced content.
     *
     * @param {EnhancedContentRecord} record
     */
    const update = async (
        record: EnhancedContentRecord
    ): Promise<ServiceHookResult<EnhancedContentRecord | undefined>> => {
        try {
            const response = await updateApi(record, { id: record.id! });

            if (response.result?.hasErrors()) {
                return ServiceHookResultFactory.errorResult(
                    response.resultObject,
                    response.result.errors
                );
            }

            return ServiceHookResultFactory.successResult(
                response.resultObject
            );
        } catch (result) {
            return ServiceHookResultFactory.errorResult(
                record,
                ERROR_UPDATING_ENHANCED_CONTENT_NOTE
            );
        }
    };

    return {
        update,
    };
}
