import { useLocalization } from "utilities/hooks/use-localization";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import { SelectOption } from "atoms/forms/select";
import Icon from "atoms/icons/icon";
import { KeyboardConstants } from "constants/keyboard-constants";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import FormFieldErrors from "molecules/form-fields/form-field-errors";
import React, { useEffect, useRef, useState } from "react";
import Select, { OptionTypeBase, ValueType } from "react-select";
import makeAnimated from "react-select/animated";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useUserRoleGroups from "utilities/hooks/domain/user-role-groups/use-user-role-groups";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";
import useOutsideClick from "utilities/hooks/use-outside-click";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EmailMultiInputProps {
    cssClassName?: string;
    disabled?: boolean;
    emails: Array<string>;
    errorMessage?: string;
    errorMessages?: Array<string>;
    /**
     * Used to handle the useOnClickOutside properly
     * for collapsing the menu in mobile layouts.
     */
    inModal?: boolean;
    isValid?: boolean;
    label?: string;
    onEmailsChanged: (newValues: Array<string>) => void;
    placeholder?: string;
    required?: boolean;
    /**
     * If true, the input will load the current user's team members
     * (if part of a team) and add them as clickable options
     * @default false
     */
    suggestTeamMembers?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const animatedComponents = makeAnimated();

const EmailMultiInput: React.FC<EmailMultiInputProps> = (
    props: EmailMultiInputProps
) => {
    const CSS_CLASS_NAME = "c-email-multi-input";
    const classNames = [CSS_CLASS_NAME, "c-form-field", "c-multi-select"];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    if (!props.isValid) {
        classNames.push("-invalid");
    }

    const isMobileOrTablet = useBreakpoint(
        Breakpoints.Laptop,
        BreakpointComparer.MaxWidth
    );

    const [currentInputValue, setCurrentInputValue] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputId] = useState(`email-multi-input-${uuid.v4()}`);

    const { globalState } = useGlobalState();

    const { t } = useLocalization<CultureResources>();
    const emailAddressesLabel = t("field-email_plural");
    const teamMembersLabel = t("yourItem", { item: t("team-member_plural") });
    const defaultPlaceholder = t("email-multi-input-placeholder");

    const containerRef = useRef(null);

    useOutsideClick(containerRef, () => setIsMenuOpen(false));

    useEffect(() => {
        if (!isMobileOrTablet) {
            return;
        }

        const input = document.getElementById(inputId) as HTMLInputElement;
        if (input == null) {
            return;
        }
        input.type = InputTypes.Email; // important for mobile keyboards

        // setTimeout because Internet Explorer exists...
        const handler = () => setTimeout(() => setIsMenuOpen(false), 0);
        input.addEventListener("focus", handler);

        return () => input.removeEventListener("focus", handler);
    }, [isMobileOrTablet, inputId]);

    const currentGroupId = globalState.currentIdentity?.getCurrentTeam()?.id;

    const { resultObject: userRoleGroups } = useUserRoleGroups({
        groupId: props.suggestTeamMembers === true ? currentGroupId : undefined,
        includeUserRoleAndUser: true,
    });

    const handleChange = (values: ValueType<OptionTypeBase>) => {
        props.onEmailsChanged(
            (values ?? []).map((o: OptionTypeBase) => o.value)
        );
    };

    const handleBlur = () => {
        if (StringUtils.isValidEmail(currentInputValue)) {
            props.onEmailsChanged([...props.emails, currentInputValue]);
        }

        setCurrentInputValue("");
    };

    const handleInputChange = (value: string) => {
        setCurrentInputValue(value);
        setIsMenuOpen(false);
    };

    const handleInputKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
        setIsMenuOpen(false);
        // value is coming through react-select as the literal character instead of the name
        if (
            (e.key === KeyboardConstants.Comma ||
                e.key === "," ||
                e.key === KeyboardConstants.Space ||
                e.key === " " ||
                e.key === KeyboardConstants.Tab ||
                e.key === KeyboardConstants.Enter) &&
            StringUtils.hasValue(currentInputValue)
        ) {
            e.preventDefault();
            e.stopPropagation();
            const emailWithCommaStrippedOff = currentInputValue
                .replace(",", "")
                .trim();
            if (!StringUtils.isValidEmail(emailWithCommaStrippedOff)) {
                return false;
            }
            props.onEmailsChanged([...props.emails, emailWithCommaStrippedOff]);
            setCurrentInputValue("");
            return false;
        }
    };

    const toSelectOptions = (emails: Array<string>) =>
        emails.map((s: string) => ({ value: s, label: s }));

    const teamMemberSelectOptions = (
        users: Array<UserRoleGroupRecord>
    ): Array<SelectOption> =>
        users
            .filter((e: UserRoleGroupRecord) =>
                StringUtils.hasValue(e.userRole?.user?.email)
            )
            .map((e: UserRoleGroupRecord) => ({
                label: `${e.userRole!.user!.getFirstAndLastName()} - ${e.userRole!
                    .user!.email!}`,
                value: e.userRole!.user!.email!,
            }));

    const nonSelectedTeamMemberOptions = teamMemberSelectOptions(
        (userRoleGroups ?? []).filter(
            (e: UserRoleGroupRecord) =>
                !props.emails.some(
                    (s: string) =>
                        e.userRole?.user?.email?.toLowerCase() ===
                        s.toLowerCase()
                ) &&
                e.userRole?.user?.email?.toLowerCase() !==
                    globalState.currentIdentity?.user?.email?.toLowerCase()
        )
    );

    // make it an option group so we can add a header label

    const teamMembersOptionGroup = {
        label: teamMembersLabel,
        options: nonSelectedTeamMemberOptions,
    };

    const shouldMenuBeOpen = () => {
        if (isMobileOrTablet) {
            // in mobile/tablet layout, we are manually controlling menu state
            return isMenuOpen;
        }

        if (
            props.suggestTeamMembers !== true ||
            CollectionUtils.isEmpty(teamMembersOptionGroup.options)
        ) {
            // force closed if there are no options,
            // or if team member suggestions are disabled
            return false;
        }

        // otherwise, let react-select manage the menu state
        return undefined;
    };

    return (
        <div className={classNames.join(" ")}>
            <label>
                {props.label != null ? props.label : emailAddressesLabel}
                {props.required === true && (
                    <span className="c-form-field__required"> *</span>
                )}
            </label>
            <div
                className={`${CSS_CLASS_NAME}__select-container`}
                ref={containerRef}>
                <Select
                    aria-label={props.label}
                    className={`c-multi-select ${
                        CollectionUtils.hasValues(userRoleGroups)
                            ? "-has-options"
                            : ""
                    }`}
                    classNamePrefix="c-multi-select__input__selector"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    escapeClearsValue={false}
                    filterOption={() => true}
                    inputId={inputId}
                    inputValue={currentInputValue}
                    isClearable={false}
                    isDisabled={props.disabled}
                    isMulti={true}
                    menuIsOpen={shouldMenuBeOpen()}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleInputKeydown}
                    options={[teamMembersOptionGroup]}
                    placeholder={props.placeholder ?? defaultPlaceholder}
                    value={toSelectOptions(props.emails)}
                />
                {isMobileOrTablet && CollectionUtils.hasValues(userRoleGroups) && (
                    <Button
                        type={ButtonTypes.Button}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        style={ButtonStyles.TertiaryAlt}>
                        <span className="-separator" />
                        <Icon type={Icons.ChevronDown} />
                    </Button>
                )}
            </div>
            <FormFieldErrors
                errorMessage={props.errorMessage}
                errorMessages={props.errorMessages}
                showCharacterCount={false}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EmailMultiInput;

// #endregion Exports
