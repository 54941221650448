import { useCallback, useState } from "react";

/**
 * Convenince hook to setup necessary state values for using searchText
 */
export default function useSearchText() {
    const [searchText, setSearchText] = useState("");
    const [submittedSearchText, setSubmittedSearchText] = useState("");

    const handleSearchTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.currentTarget.value);
        },
        [setSearchText]
    );

    const handleSearchClear = useCallback(() => {
        setSubmittedSearchText("");
        setSearchText("");
    }, [setSearchText, setSubmittedSearchText]);

    return {
        handleSearchClear,
        handleSearchTextChange,
        searchText,
        setSearchText,
        setSubmittedSearchText,
        submittedSearchText,
    };
}
