import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import type UserReference from "models/interfaces/user-reference";
import BookRecord from "models/view-models/book-record";
import UserRoleRecord from "models/view-models/user-role-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import { CollectionUtils } from "utilities/collection-utils";

const defaultValues: UserReference =
    RecordUtils.auditableDefaultValuesFactory<UserReference>({
        book: undefined,
        bookId: undefined,
        externalId: undefined,
        publication: undefined,
        section: undefined,
        sectionId: undefined,
        userRole: undefined,
        userRoleId: undefined,
    });

export default class UserReferenceRecord
    extends Record(defaultValues)
    implements UserReference
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserReference) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.book != null) {
            params.book = RecordUtils.ensureRecord(params.book, BookRecord);
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (params.section != null) {
            params.section = RecordUtils.ensureRecord(
                params.section,
                SectionRecord
            );
        }

        if (params.userRole != null) {
            params.userRole = RecordUtils.ensureRecord(
                params.userRole,
                UserRoleRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserReferenceRecord>} values
     */
    public with(values: Partial<UserReference>): UserReferenceRecord {
        return new UserReferenceRecord(Object.assign(this.toJS(), values));
    }

    public withBook(books?: BookRecord[]): UserReferenceRecord {
        if (CollectionUtils.isEmpty(books)) {
            return this;
        }
        const book = books.find((e) => e.id === this.bookId);

        return this.with({ book: book });
    }

    public withPublication(
        publications?: PublicationRecord[]
    ): UserReferenceRecord {
        if (CollectionUtils.isEmpty(publications)) {
            return this;
        }
        const publication = publications.find(
            (e) =>
                e.code === this.book?.code && e.edition === this.book?.edition
        );

        return this.with({ publication: publication });
    }

    public withSection(sections?: SectionRecord[]): UserReferenceRecord {
        if (CollectionUtils.isEmpty(sections)) {
            return this;
        }
        const section = sections.find(
            (e) =>
                e.publicationId === this.publication?.id &&
                e.externalId === this.externalId
        );

        return this.with({ section: section });
    }

    // #endregion Public Methods
}
