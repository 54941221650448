import LegacyPublicationRecord from "models/view-models/legacy-publication-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

export interface LegacyPublicationBasePathParams {}

export interface LegacyPublicationResourcePathParams {
    id: number;
}

const baseEndpoint = "publications/legacy";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = LegacyPublicationRecord;

const LegacyService = {
    get: ServiceFactory.get<
        LegacyPublicationRecord,
        LegacyPublicationResourcePathParams
    >(resourceType, resourceEndpoint),
    useGet: ServiceHookFactory.useGet<
        LegacyPublicationRecord,
        LegacyPublicationResourcePathParams
    >(resourceType, resourceEndpoint),
};

export default LegacyService;
