import { useCallback, useState } from "react";
import { SearchHitServiceHookResult } from "utilities/hooks/aspect/search/use-search-v2";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import SearchService, {
    SearchListQueryParams,
} from "utilities/services/search/search-service";
import HitRecord from "models/view-models/search/hit-record";
import StringUtils from "utilities/string-utils";
import useRelatedRecords from "utilities/hooks/aspect/search/use-related-records";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

/**
 * Hook to use cloud search
 */
export default function useCloudSearch(
    searchParams?: SearchListQueryParams
): SearchHitServiceHookResult {
    const { get } = SearchService.useGet();
    const { getRelatedRecords } = useRelatedRecords();

    const [result, setResult] = useState<ServiceHookResult<HitRecord[]>>(
        ServiceHookResultFactory.initialResult([])
    );

    const searchAsync = useCallback(async (): Promise<void> => {
        if (shouldNotSearch(searchParams?.search)) {
            setResult(ServiceHookResultFactory.initialResult([]));
            return;
        }

        setResult(ServiceHookResultFactory.loadingResult([], result.rowCount));

        try {
            const result = await get({}, searchParams);
            const { found: rowCount, hits = [] } = result.resultObject ?? {};

            const hitsWithRelatedRecords = await getRelatedRecords(hits);

            setResult(
                ServiceHookResultFactory.successResult(
                    hitsWithRelatedRecords,
                    rowCount
                )
            );
        } catch (error) {
            setResult(ServiceHookResultFactory.errorResult([], error));
        }
    }, [get, getRelatedRecords, result.rowCount, searchParams]);

    const search = useCallback(() => {
        searchAsync();
    }, [searchAsync]);

    const reset = useCallback(() => {
        setResult(ServiceHookResultFactory.initialResult([]));
    }, []);

    return {
        ...result,
        reset,
        search,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const shouldNotSearch = (searchTerm?: string) =>
    StringUtils.isEmpty(searchTerm);

// #endregion Functions
