import { Do } from "andculturecode-javascript-core";
import { siteMap } from "internal-sitemap";
import GlobalStateRecord from "models/view-models/global-state-record";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";

export default function useStartFreeTrial() {
    const history = useHistory();
    const { get: getUserConfigApi } = UserConfigurationService.useGet();
    const { setGlobalState } = useGlobalState();

    const startFreeTrialForSignedInUser = useCallback(async () => {
        Do.try(async () => {
            await getUserConfigApi(
                {},
                // set returnForbiddendIfErrored to false because a new user
                // will have errors for eula and topics
                { returnForbiddenIfErrored: false, startFreeTrial: true }
            );
            history.push(siteMap.dashboards.user);
        }).catch(() => {
            // on error, treat it like a logout
            setGlobalState((prevState: GlobalStateRecord) =>
                prevState.setUnauthenticated()
            );
        });
    }, [getUserConfigApi, history, setGlobalState]);

    const setCallbackLink = () =>
        `${window.location.pathname}?freeTrialAgreementAccepted=true`;

    return { startFreeTrialForSignedInUser, setCallbackLink };
}
