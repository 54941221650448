import React from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";

const END_CLASS = "c-emulation-mode-banner__end";

interface EndEmulationSessionButtonProps {
    disabled?: boolean;
}

const EndEmulationSessionButton = ({
    disabled = false,
}: EndEmulationSessionButtonProps) => {
    const { token, deleteToken } = useEmulationMode();

    const endSessionText = "End Session";

    const handleEndSession = () => {
        if (token?.id != null && token.guestId != null) {
            deleteToken(token.id, token.guestId);
        }
    };

    return (
        <Button
            disabled={disabled}
            cssClassName={END_CLASS}
            onClick={handleEndSession}
            style={ButtonStyles.Destructive}>
            {endSessionText}
        </Button>
    );
};

export default EndEmulationSessionButton;
