import React from "react";
import { MutableRefObject, PropsWithChildren, useEffect } from "react";

interface Props {
    callback: (
        entries: IntersectionObserverEntry[],
        observer?: IntersectionObserver
    ) => void;
    options?: IntersectionObserverInit;
    sectionRefs: MutableRefObject<(HTMLElement | null)[]>;
}

const ScrollSpy = (props: PropsWithChildren<Props>) => {
    const { callback, options, sectionRefs } = props;

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);

        sectionRefs.current.forEach((section) => {
            if (section === null) return;
            observer.observe(section);
        });
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{props.children}</>;
};

export default React.memo(ScrollSpy);
