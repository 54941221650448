import { useLocalization } from "utilities/hooks/use-localization";
import logo from "assets/images/logo-blue.png";
import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import moment from "moment";
import CultureDropdown from "organisms/culture-dropdown/culture-dropdown";
import React from "react";
import { siteMap } from "internal-sitemap";
import CultureResources from "utilities/interfaces/culture-resources";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";

const BASE_CLASS = "c-unauthenticated-footer";

const JoinGroupOnDemandFooter = () => {
    const { i18N } = useFeatureFlags();
    const { privacyUrl, termsUrl } = useNfpaUrlPaths();
    const { t } = useLocalization<CultureResources>();

    const termsLinkText = t("termsOfUse");
    const privacyLinkText = t("privacyPolicy");
    const supportPageLinkText = t("support");

    return (
        <div className={BASE_CLASS}>
            <img src={logo} alt="Home" />
            {i18N && <CultureDropdown />}
            <div className={`${BASE_CLASS}__links`}>
                <Anchor to={termsUrl} external={true}>
                    {termsLinkText}
                </Anchor>
                <Anchor to={privacyUrl} external={true}>
                    {privacyLinkText}
                </Anchor>
                <Anchor to={siteMap.support.faq}>{supportPageLinkText}</Anchor>
            </div>
            <Paragraph size={ParagraphSizes.XSmall}>
                {UnicodeCharacterConstants.Copyright} National Fire Protection
                Association (NFPA) {moment().year()}
            </Paragraph>
        </div>
    );
};

export default JoinGroupOnDemandFooter;
