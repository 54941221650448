import { useState, useEffect, useCallback } from "react";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { CheckStorageUsageMessage } from "utilities/service-worker/interfaces/messages/check-storage-usage-message";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { CheckStorageUsageCommand } from "utilities/service-worker/interfaces/commands/check-storage-usage-command";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseOfflineStorageOptions {
    /**
     * (Optional) Id of the book to return estimated size for
     */
    bookId?: number;
}

interface UseOfflineStorageUsageResult {
    refresh: () => void;
    sizeInBytes: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

const useOfflineStorageUsage = (
    options?: UseOfflineStorageOptions
): UseOfflineStorageUsageResult => {
    const { bookId } = options ?? {};
    const { sendCommand } = useServiceWorker();
    const [bytes, setBytes] = useState<number>(0);
    const handleStorageUsageMessage = (
        event: TypedMessageEvent<CheckStorageUsageMessage>
    ) => {
        const { bookId: eventBookId, bytes: eventBytes } = event.data;
        if (
            (bookId == null && eventBookId != null) ||
            (bookId != null && eventBookId == null)
        ) {
            return;
        }

        if (bookId != null && eventBookId != null && eventBookId !== bookId) {
            return;
        }

        setBytes(eventBytes);
    };

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.StorageUsage,
        handleStorageUsageMessage
    );

    const checkStorageUsage = useCallback(
        () =>
            sendCommand<CheckStorageUsageCommand>({
                bookId,
                type: ServiceWorkerCommandTypes.CheckStorageUsage,
            }),
        [bookId, sendCommand]
    );

    useEffect(() => {
        checkStorageUsage();
    }, [checkStorageUsage]);

    return {
        refresh: checkStorageUsage,
        sizeInBytes: bytes,
    };
};

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { useOfflineStorageUsage };

// #endregion Exports
