import { useAsyncEffect } from "andculturecode-javascript-react";
import type EnhancedContentRecord from "models/view-models/enhanced-content-record";
import type PublicationRecord from "models/view-models/publication-record";
import { EnhancedContentMigrationAction } from "organisms/admin/publications/enhanced-content/enhanced-content-migration-modal";
import { useEffect, useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import AdminEnhancedContentExternalIdsService from "utilities/services/admin/enhanced-content/enhanced-content-external-ids-service";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_LOADING_ENHANCED_CONTENTS =
    "There was an issue loading enhanced contents.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseEnhancedContentPreMigrationOptions {
    destination: PublicationRecord;
    migrationAction: EnhancedContentMigrationAction;
    source: PublicationRecord;
}

// #endregion Interfaces

/**
 * Utility helper hook for enhanced content migrations
 *
 * @param {UseEnhancedContentMigrationsOptions} options
 * @return {*}
 */
export default function useEnhancedContentPreMigration(
    options: Partial<UseEnhancedContentPreMigrationOptions>
) {
    const { destination, migrationAction, source } = options;

    const { code: destinationCode, edition: destinationEdition } =
        destination ?? {};

    const { edition: sourceEdition } = source ?? {};

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: getEnhancedContentExternalIds } =
        AdminEnhancedContentExternalIdsService.useList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [destinationEnhancedContent, setDestinationEnhancedContent] =
        useState<EnhancedContentRecord[]>();
    const [sourceEnhancedContent, setSourceEnhancedContent] =
        useState<EnhancedContentRecord[]>();

    const [errors, setErrors] = useState<Array<string>>([]);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const addErrors = (result?: { listErrorMessages: () => Array<string> }) => {
        setErrors((prev) => [...prev, ...(result?.listErrorMessages() ?? [])]);
    };

    const overwriteCount = useMemo(() => {
        if (
            migrationAction ===
            EnhancedContentMigrationAction.MigrateAndKeepExisting
        ) {
            return 0;
        }

        const destinationExternalIds =
            destinationEnhancedContent?.map((ec) => ec.externalId) ?? [];

        const sourceExternalIds =
            sourceEnhancedContent?.map((ec) => ec.externalId) ?? [];

        const overwriteIds = CollectionUtils.intersection(
            destinationExternalIds,
            sourceExternalIds
        );

        return overwriteIds.length;
    }, [destinationEnhancedContent, migrationAction, sourceEnhancedContent]);

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useAsyncEffect(
        async function destinationEnhancedContentRecords() {
            if (
                StringUtils.hasValue(destinationCode) &&
                StringUtils.hasValue(destinationEdition)
            ) {
                try {
                    const { result, resultObjects } =
                        await getEnhancedContentExternalIds(
                            {
                                code: destinationCode,
                                edition: destinationEdition,
                            },
                            { isPublished: true }
                        );

                    if (result?.hasErrors()) {
                        addErrors(result);
                        return;
                    }

                    if (CollectionUtils.hasValues(resultObjects)) {
                        setDestinationEnhancedContent(resultObjects);
                    }
                } catch (error) {
                    setErrors([ERROR_LOADING_ENHANCED_CONTENTS]);
                }
            }
        },
        [destinationCode, destinationEdition]
    );

    useAsyncEffect(
        async function sourceEnhancedContentRecords() {
            if (
                StringUtils.hasValue(destinationCode) &&
                StringUtils.hasValue(sourceEdition)
            ) {
                try {
                    const { result, resultObjects } =
                        await getEnhancedContentExternalIds(
                            {
                                code: destinationCode,
                                edition: sourceEdition,
                            },
                            { isPublished: true }
                        );

                    if (result?.hasErrors()) {
                        addErrors(result);
                        return;
                    }

                    if (CollectionUtils.hasValues(resultObjects)) {
                        setSourceEnhancedContent(resultObjects);
                    }
                } catch (error) {
                    setErrors([ERROR_LOADING_ENHANCED_CONTENTS]);
                }
            }
        },
        [destinationCode, sourceEdition]
    );

    useEffect(() => {
        setErrors([]);
    }, [destination, migrationAction, source]);

    // #endregion Side-Effects

    return {
        errors,
        overwriteCount,
    };
}
