import SelectSizes from "atoms/constants/select-sizes";
import FormFieldErrors from "molecules/form-fields/form-field-errors";
import { InputFormFieldProps } from "molecules/form-fields/input-form-field";
import React, { useEffect, useMemo, useState } from "react";
import Select, {
    ActionMeta,
    components,
    InputActionMeta,
    OptionProps,
    OptionTypeBase,
    SelectComponentsConfig,
    StylesConfig,
    ValueType,
} from "react-select";
import makeAnimated from "react-select/animated";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";
import { SelectOption } from "./select";

// Documentation: https://react-select.com/home

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MultiSelectProps<
    TData = any,
    TValue extends string | number = string
> extends Pick<
        InputFormFieldProps,
        "errorMessage" | "errorMessages" | "required" | "isValid"
    > {
    animated?: boolean;
    children?: React.ReactNode | React.ReactNodeArray;
    /**
     * Useful when used in conjunction with the
     * dropdownPortal prop, in order to avoid
     * graphical issues when scrolling the page.
     */
    closeMenuOnScroll?: boolean | ((e: Event) => boolean);
    closeMenuOnSelect?: boolean;

    cssClassName?: string;

    /**
     * Optionally customize rendered components for the internal react-select.
     */
    customComponents?: SelectComponentsConfig<SelectOption<TData, TValue>>;

    /**
     * Optionally pass in custom styles to the Select component
     * @see https://react-select.com/styles#styles
     */
    customStyles?: Partial<StylesConfig>;

    disabled?: boolean;
    /**
     * HTMLElement where the menu should be attached.
     * This is useful if you are having issues with
     * the dropdown menu being cut off by a container.
     */
    dropdownPortal?: HTMLElement;
    /**
     * Useful in conjunction with dropdownPortal
     * prop if the select is near the bottom of
     * the dropdownPortal. Makes the menu open
     * up instead of down.
     */
    dropUp?: boolean;
    /**
     * Clear ALL values when escape key is pressed.
     */
    escapeClearsValue?: boolean;
    /**
     * Don't show the menu if there are no options.
     * False by default.
     */
    hideNoOptionsMessage?: boolean;
    /**
     * Controls whether selected options should continue
     * to be visible in the dropdown.
     * @default false
     */
    hideSelectedOptions?: boolean;
    id?: string;
    isClearable?: boolean;
    /**
     * When you need advanced behavior (like async option loading)
     * on a single select, you can set isMulti=false
     */
    isMulti?: boolean;
    label?: string;
    /**
     * Shown next to label in light gray text.
     */
    labelHelpText?: string;
    loading?: boolean;
    loadingMessage?: string;
    /**
     * Useful for debugging styles. Set to true
     * to have the menu remain open.
     */
    menuIsOpen?: boolean;
    /**
     * If you're having trouble with the dropdown being cut off
     * by the bottom of the container (for example, a modal),
     * try setting menuPosition="fixed"
     * @default "absolute"
     */
    menuPosition?: "absolute" | "fixed";
    /**
     * Optionally customize the message when there are no options.
     * @default undefined defaults to "No options"
     */
    noOptionsMessage?: string;
    onChange:
        | ((
              values: Array<SelectOption<TData, TValue>>,
              actionMetaData?: ActionMeta
          ) => void)
        | ((
              value: SelectOption<TData, TValue>,
              actionMetaData?: ActionMeta
          ) => void);
    onSearchChange?: (
        newValue: string,
        actionMetaData?: InputActionMeta
    ) => void;
    options: Array<SelectOption<TData, TValue>>;
    /**
     * Optionally, provide a custom filtering algorithm
     * for filtering options. Only applies if searchable === true
     */
    optionFilter?: (option: SelectOption<TData, TValue>) => boolean;
    placeholder?: string;
    /**
     * Custom renderer for options.
     */
    renderOption?: (
        option: SelectOption<TData, TValue>,
        isSelected: boolean
    ) => React.ReactElement;

    /**
     * Optionally, you can disable the search functionality.
     * @default true
     */
    searchable?: boolean;
    size?: SelectSizes;
    value?: Array<TValue>;
    showSelection?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const defaultProps: Partial<MultiSelectProps> = {
    searchable: true,
    isValid: true, // for backwards compatibility; it's false by default otherwise
    showSelection: true,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MultiSelect = <
    TData extends any = any,
    TValue extends string | number = string
>(
    props: MultiSelectProps<TData, TValue>
) => {
    const id = props.id || `multi-select-${uuid.v4()}`;

    const { optionFilter } = props;
    const onChange = (
        values: ValueType<OptionTypeBase>,
        actionMetaData: ActionMeta
    ) => {
        if (props.isMulti ?? true) {
            props.onChange(
                (values || []).map((o: OptionTypeBase) => ({
                    label: o.label,
                    value: o.value,
                    data: o.data,
                })),
                actionMetaData
            );
            return;
        }

        const singleOptionOnChange = props.onChange as (
            value: SelectOption<TData, TValue>,
            actionMetaData?: ActionMeta
        ) => void;
        singleOptionOnChange(
            values as SelectOption<TData, TValue>,
            actionMetaData
        );
    };

    const [menuIsOpen, setMenuIsOpen] = useState<boolean | undefined>();

    useEffect(() => {
        if (props.menuIsOpen != null) {
            setMenuIsOpen(props.menuIsOpen);
            return;
        }

        if (props.hideNoOptionsMessage && props.options.length === 0) {
            setMenuIsOpen(false);
            return;
        }

        setMenuIsOpen(undefined);
    }, [props.menuIsOpen, props.options, props.hideNoOptionsMessage]);

    const isClearable = () => {
        if (props.isClearable != null) {
            return props.isClearable;
        }

        return true;
    };

    const animatedComponents = makeAnimated(props.customComponents);
    if (props.renderOption != null) {
        animatedComponents.Option = (
            optionProps: OptionProps<SelectOption<TData, TValue>>
        ) => (
            <components.Option {...optionProps}>
                <div {...optionProps.innerProps} ref={optionProps.innerRef}>
                    {props.renderOption!(
                        optionProps.data,
                        optionProps.isSelected
                    )}
                </div>
            </components.Option>
        );
    } else {
        animatedComponents.Option = components.Option;
    }

    const noOptionsMessageFunc = StringUtils.hasValue(props.noOptionsMessage)
        ? () => props.noOptionsMessage!
        : undefined;

    const customOptionFilter = useMemo(() => {
        if (optionFilter == null) {
            return undefined;
        }

        return (option: { label: string; value: string; data: TData }) =>
            optionFilter!(option as SelectOption<TData, TValue>);
    }, [optionFilter]);

    const invalidClass = props.isValid ? "" : "-invalid";

    const getValue = () => {
        if (props.showSelection) {
            return props.value == null
                ? undefined
                : props.options.filter((o: SelectOption<TData, TValue>) =>
                      props.value!.some((v: TValue) => v === o.value)
                  );
        }
        return [];
    };

    return (
        <div
            className={`c-multi-select ${props.size ?? SelectSizes.Base} ${
                props.cssClassName
            }`}>
            <div className={`c-form-field ${invalidClass}`}>
                <div className="c-multi-select__input">
                    {props.label && (
                        <label htmlFor={id}>
                            {props.label}
                            {props.labelHelpText && (
                                <label className="-help-text">
                                    {" "}
                                    {props.labelHelpText}
                                </label>
                            )}
                            {props.required && (
                                <span className="c-form-field__required">
                                    {" *"}
                                </span>
                            )}
                        </label>
                    )}
                    <Select
                        aria-label={props.label}
                        classNamePrefix="c-multi-select__input__selector"
                        closeMenuOnScroll={props.closeMenuOnScroll}
                        closeMenuOnSelect={props.closeMenuOnSelect}
                        components={
                            props.animated === false
                                ? undefined
                                : animatedComponents
                        }
                        escapeClearsValue={props.escapeClearsValue ?? false}
                        filterOption={customOptionFilter}
                        hideSelectedOptions={props.hideSelectedOptions}
                        id={id}
                        isClearable={isClearable()}
                        isDisabled={props.disabled}
                        isLoading={props.loading}
                        isMulti={props.isMulti ?? true}
                        isSearchable={props.searchable}
                        loadingMessage={() =>
                            props.loadingMessage || "Loading options..."
                        }
                        menuIsOpen={menuIsOpen}
                        menuPlacement={props.dropUp ? "top" : "auto"}
                        menuPortalTarget={props.dropdownPortal}
                        menuPosition={props.menuPosition}
                        noOptionsMessage={noOptionsMessageFunc}
                        onChange={onChange}
                        onInputChange={props.onSearchChange}
                        options={props.options}
                        placeholder={props.placeholder}
                        styles={props.customStyles}
                        tabSelectsValue={false}
                        value={getValue()}
                    />
                </div>
                <FormFieldErrors
                    errorMessage={props.errorMessage}
                    errorMessages={props.errorMessages}
                    showCharacterCount={false}
                />
                {/* render children so we can add things inside the c-form-field */}
                {props.children}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

MultiSelect.defaultProps = defaultProps;
export default MultiSelect;

// #endregion Exports
