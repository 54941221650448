import ServiceFactory from "utilities/services/service-factory";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import WorkGroupsAtom from "utilities/atoms/work-group/work-groups-atom";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";

export interface WorkGroupListQueryParams {}

const baseEndpoint = "workgroups";
const resourceType = WorkGroupRecord;

const useWorkGroupReadService = () => {
    const [workGroupRecords, setWorkGroupRecords] = useAtom(WorkGroupsAtom);
    const [filteredWorkGroups, setFilteredWorkGroups] = useState<
        WorkGroupRecord[]
    >([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        const getWorkGroups = async () => {
            const workGroupService = ServiceFactory.list<
                WorkGroupRecord,
                WorkGroupListQueryParams
            >(resourceType, baseEndpoint);
            const workGroups = await workGroupService({});
            setWorkGroupRecords(workGroups.resultObjects);
            setFilteredWorkGroups(workGroups.resultObjects);
        };
        getWorkGroups();
    }, [setWorkGroupRecords]);

    useEffect(() => {
        const search = (): void => {
            let workGroups: WorkGroupRecord[] = workGroupRecords;
            if (searchText) {
                workGroups = workGroupRecords.filter((workGroup) =>
                    workGroup.title
                        ?.toLocaleLowerCase()
                        .includes(searchText.toLowerCase())
                );
            }
            setFilteredWorkGroups(workGroups);
        };
        search();
    }, [workGroupRecords, searchText]);

    return {
        searchText,
        setSearchText,
        filteredWorkGroups,
        workGroupRecords,
    } as const;
};

export default useWorkGroupReadService;
