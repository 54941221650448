import React, { useState, useEffect } from "react";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { EnhancedContentMigrationModal } from "organisms/admin/publications/enhanced-content/enhanced-content-migration-modal";
import PublicationRecord from "models/view-models/publication-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { useAsyncEffect } from "andculturecode-javascript-react";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import { CollectionUtils } from "utilities/collection-utils";
import ListBox from "molecules/lists/list-box";
import Loader from "atoms/loaders/loader";
import { LoaderSizes } from "atoms/constants/loader-sizes";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { LoaderStyles } from "atoms/constants/loader-styles";
import EnhancedContentMigrationReportModal from "organisms/admin/publications/enhanced-content/enhanced-content-migration-report-modal";
import useEnhancedContentMigrations from "utilities/hooks/domain/enhanced-content/use-enhanced-content-migrations";
import EnhancedContentMigrationRecord from "models/view-models/enhanced-content-migration-record";
import useModalActions from "utilities/hooks/use-modal-actions";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CSS_CLASSNAME = "c-enhanced-content-migration-field";
const fieldLabel = "Enhanced Content Data";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentMigrationFieldProps {
    cssClassName?: string;
    publication: PublicationRecord;
}

// #endregion Interfaces

export default function EnhancedContentMigrationField(
    props: EnhancedContentMigrationFieldProps
) {
    const { cssClassName, publication } = props;
    const { code: destinationCode, edition: destinationEdition } = publication;

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listPublications } = AdminPublicationService.useList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [migrationsSources, setMigrationSources] = useState<
        PublicationRecord[]
    >([]);

    const {
        handleClose: handleCloseEnhancedContentMigrationModal,
        handleOpen: handleOpenEnhancedContentMigrationModal,
        isOpen: isEnhancedContentMigrationModalOpen,
    } = useModalActions();

    const {
        handleClose: handleCloseEnhancedContentMigrationReportModal,
        handleOpen: handleOpenEnhancedContentMigrationReportModal,
        isOpen: isEnhancedContentMigrationReportModalOpen,
    } = useModalActions();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        errors: migrationErrors,
        startMigration,
        isLoading: lastMigrationIsLoading,
        lastMigration,
    } = useEnhancedContentMigrations({
        destination: publication,
    });

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const cannotMigrate = migrationsSources.length === 0;

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useAsyncEffect(
        async function migrationSources() {
            if (destinationCode != null) {
                const { results, resultObjects } = await listPublications({
                    code: destinationCode,
                    hasPublishedEnhancedContent: true,
                });

                if (results?.hasErrors()) {
                    ToastManager.error(results?.listErrorMessages()[0]);
                }

                setMigrationSources(
                    uniqueMigrationSources(
                        resultObjects.filter(
                            (p) => p.edition !== destinationEdition
                        )
                    )
                );
            }
        },
        [destinationCode, destinationEdition]
    );

    useEffect(() => {
        if (CollectionUtils.hasValues(migrationErrors)) {
            migrationErrors.forEach((err) => {
                ToastManager.error(err);
            });
        }
    }, [migrationErrors]);

    // #endregion Side-Effects

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleMigrateEnhancedContentAction = async (
        migration?: EnhancedContentMigrationRecord
    ) => {
        handleCloseEnhancedContentMigrationModal();

        if (migration != null) {
            startMigration(migration);
        }
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const fieldContents = () => {
        if (lastMigration != null) {
            return (
                <ListBox>
                    <div className={`${BASE_CSS_CLASSNAME}__container`}>
                        <div className={`${BASE_CSS_CLASSNAME}__info`}>
                            <div>
                                <b>Source:</b>
                                <span
                                    className={`${BASE_CSS_CLASSNAME}__source-text`}>
                                    {lastMigration.getMigratedSourceText()}
                                </span>
                            </div>

                            <div className={`${BASE_CSS_CLASSNAME}__migration`}>
                                {lastMigration.isMigrating() && (
                                    <Paragraph size={ParagraphSizes.XSmall}>
                                        <Loader
                                            size={LoaderSizes.XSmall}
                                            type={LoaderStyles.LinkGlyphGray}
                                        />
                                        <i
                                            className={`${BASE_CSS_CLASSNAME}__migrating`}>
                                            Migration in progress...
                                        </i>
                                    </Paragraph>
                                )}

                                {!lastMigration.isMigrating() && (
                                    <div>
                                        <b>Migrated:</b>
                                        <Button
                                            cssClassName={`${BASE_CSS_CLASSNAME}__migrated-text`}
                                            onClick={
                                                handleOpenEnhancedContentMigrationReportModal
                                            }
                                            size={ButtonSizes.Small}
                                            style={ButtonStyles.Anchor}>
                                            {lastMigration.getMigratedStatusText()}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`${BASE_CSS_CLASSNAME}__action`}>
                            <Button
                                accessibleText={"New Migration"}
                                disabled={cannotMigrate}
                                onClick={
                                    handleOpenEnhancedContentMigrationModal
                                }
                                size={ButtonSizes.Small}
                                style={ButtonStyles.Anchor}>
                                New Migration
                            </Button>
                        </div>
                    </div>
                </ListBox>
            );
        }

        return (
            <Button
                disabled={cannotMigrate}
                onClick={handleOpenEnhancedContentMigrationModal}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}>
                Migrate Enhanced Content
            </Button>
        );
    };

    if (lastMigrationIsLoading) {
        return null;
    }

    return (
        <div
            className={`c-form-field c-enhanced-content-migration-field ${cssClassName}`}>
            <label>{fieldLabel}</label>
            {fieldContents()}
            <EnhancedContentMigrationModal
                closeDialog={handleMigrateEnhancedContentAction}
                destination={publication}
                isVisible={isEnhancedContentMigrationModalOpen}
                sources={migrationsSources}
            />
            <EnhancedContentMigrationReportModal
                closeDialog={handleCloseEnhancedContentMigrationReportModal}
                isVisible={isEnhancedContentMigrationReportModalOpen}
                migration={lastMigration}
            />
        </div>
    );

    // #endregion Render
}

// -----------------------------------------------------------------------------------------
// #region Pure Functions
// -----------------------------------------------------------------------------------------

/**
 * Filter down an array of publications to a unique set of editions
 *
 * @param {PublicationRecord[]} migrationSources
 * @return {*}
 */
function uniqueMigrationSources(migrationSources: PublicationRecord[]) {
    return CollectionUtils.uniqueValuesByProperty(
        migrationSources,
        "edition"
    ).map((edition) => {
        const filteredSources =
            migrationSources.filter((source) => source.edition === edition) ??
            [];

        if (CollectionUtils.isEmpty(filteredSources)) {
            return filteredSources;
        }

        return filteredSources.sort(({ id: a = 0 }, { id: b = 0 }) => b - a)[0];
    });
}

// #endregion Pure Functions
