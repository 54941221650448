import React from "react";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import EnhancedContentService from "utilities/services/sections/enhanced-content/enhanced-content-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { EnhancedContentPanelProps } from "organisms/enhanced-content/enhanced-content-panel";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentDeleteActionModalProps
    extends Pick<
        EnhancedContentPanelProps,
        "enhancedContent" | "onDeleteConfirm" | "onSuccess"
    > {
    onClose: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_DELETE_ENHANCED_CONTENT =
    "There was an issue deleting this enhanced content";
const SUCCESS_DELETE_ENHANCED_CONTENT = "Enhanced content deleted.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentDeleteActionModal = (
    props: EnhancedContentDeleteActionModalProps
) => {
    const { enhancedContent, onClose, onDeleteConfirm, onSuccess } = props;

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { updateEnhancedContentOnDelete } = useEnhancedContent({});

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { delete: enhancedContentDeleteApi } =
        EnhancedContentService.useDelete();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleDelete = async () => {
        try {
            const response =
                onDeleteConfirm != null
                    ? await onDeleteConfirm(enhancedContent)
                    : await enhancedContentDeleteApi(enhancedContent?.id!);

            if (response.result?.hasErrors()) {
                ToastManager.error(ERROR_DELETE_ENHANCED_CONTENT);
                return;
            }

            updateEnhancedContentOnDelete(enhancedContent.id);

            ToastManager.success(SUCCESS_DELETE_ENHANCED_CONTENT);

            onSuccess?.();
        } catch (error) {
            ToastManager.error(ERROR_DELETE_ENHANCED_CONTENT);
        }

        onClose();
    };

    return (
        <ConfirmationModal
            confirmButtonText="Yes, Delete"
            isVisible={true}
            label="Confirm Delete"
            message="Are you sure you want to delete this enhanced content?"
            onCancel={onClose}
            onConfirm={handleDelete}
        />
    );
};

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentDeleteActionModal;

// #endregion Exports
