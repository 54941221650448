import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import type UserBookmark from "models/interfaces/user-bookmark";
import GroupRecord from "models/view-models/group-record";
import PublicationRecord from "models/view-models/publication-record";
import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import UserRecord from "models/view-models/user-record";
import moment from "moment";
import { ColorSelectType } from "organisms/my-link/my-bookmarks/color-select";
import { CollectionUtils } from "utilities/collection-utils";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import StringUtils from "utilities/string-utils";
import { t } from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import PublicationTypes from "constants/publication-types";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultValues: UserBookmark =
    RecordUtils.auditableDefaultValuesFactory<UserBookmark>({
        annexNfpaLabel: undefined,
        articleNfpaLabel: undefined,
        chapterNfpaLabel: undefined,
        color: 0,
        createdOnPublication: undefined,
        createdOnPublicationId: undefined,
        description: "",
        descriptionAsPlainText: "",
        externalId: "",
        group: undefined,
        groupBy: undefined,
        groupId: undefined,
        parentExternalId: "",
        publicationCode: undefined,
        publicationEdition: undefined,
        updatedOnPublication: undefined,
        updatedOnPublicationId: undefined,
        user: undefined,
        userBookmarkType: undefined,
        userCollectionBookmarks: undefined,
        userId: 0,
        title: undefined,
        subtitle: undefined,
        publicationType: undefined,
    });

// #endregion Constants

export default class UserBookmarkRecord
    extends Record(defaultValues)
    implements UserBookmark
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserBookmark) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.createdOnPublication != null) {
            params.createdOnPublication = RecordUtils.ensureRecord(
                params.createdOnPublication,
                PublicationRecord
            );
        }

        if (params.group != null) {
            params.group = RecordUtils.ensureRecord(params.group, GroupRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.updatedOnPublication != null) {
            params.updatedOnPublication = RecordUtils.ensureRecord(
                params.updatedOnPublication,
                PublicationRecord
            );
        }

        if (CollectionUtils.hasValues(params.userCollectionBookmarks)) {
            params.userCollectionBookmarks =
                params.userCollectionBookmarks!.map(
                    (ucb: UserCollectionBookmarkRecord) =>
                        RecordUtils.ensureRecord(
                            ucb,
                            UserCollectionBookmarkRecord
                        )
                );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns the CSS classname modifier for applying icon color
     *
     * @example
     * new UserBookmarkRecord().with({ color: UserBookmarkColors.1 }).getColorClassModifier();
     * // returns " -bookmark-color-yellow"
     */
    public getColorClassModifier(): string {
        return CustomColorUtils.getClassModifier(
            UserBookmarkColors,
            this.color,
            ColorSelectType.Bookmark
        );
    }

    /**
     * Get a human-readable timestamp indicating "how long ago"
     * the bookmark was created, i.e. "just now" or "5 minutes ago" or "a month ago"
     */
    public getModifiedDateFromNowText(): string {
        if (StringUtils.isEmpty(this.createdOn)) {
            return t("addedJustNow");
        }

        return `${t("added")} ${moment().to(moment(this.createdOn))}`;
    }

    /**
     * Returns the fully qualified title of the underlying Section, if it is available.
     *
     * @default ""
     */
    public getFullyQualifiedDisplayTitle(): string {
        return this.title;
    }

    public getFullyQualifiedDisplaySubtitle(): string {
        return this.subtitle;
    }

    public getTruncatedTitle(): string {
        if (this.title?.length > 82) {
            return this.title?.slice(0, 80).trim() + "...";
        }

        return this.getFullyQualifiedDisplayTitle();
    }

    /**
     * Returns the route to the underlying Section in book view, if it is available.
     *
     * @default ""
     */
    public getSectionRoute(): string {
        if (
            this.chapterNfpaLabel != null &&
            this.publicationType === PublicationTypes.NEC &&
            this.articleNfpaLabel == null
        ) {
            return this.getSectionPagePermalinkRoute() ?? "#";
        }

        if (this.hasArticlePermalinkParams()) {
            return this.getArticlePermalinkRoute() ?? "#";
        }

        if (this.hasChapterPermalinkParams()) {
            return this.getChapterPermalinkRoute() ?? "#";
        }

        if (this.hasAnnexGroupPermalinkParams()) {
            return this.getAnnexGroupPageRoute() ?? "#";
        }

        if (this.hasAnnexPermalinkParams()) {
            return this.getAnnexPagePermalinkRoute() ?? "#";
        }

        return "#";
    }

    /**
     * Returns whether or not this record has been persisted to the underlying database.
     */
    public isPersisted(): boolean {
        return this.id != null && this.id > 0;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserBookmark>} values
     */
    public with(values: Partial<UserBookmark>): UserBookmarkRecord {
        return new UserBookmarkRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds `createdOnSection` relationship based on id from a list of SectionRecords
     *
     * @param {Array<SectionRecord>} [sections]
     */
    public withTitle(title: string, subtitle: string): UserBookmarkRecord {
        return this.with({ title: title, subtitle: subtitle });
    }

    /**
     * Adds `userCollectionBookmarks` relationship with nested `userCollection` relationship
     * @param userCollectionBookmarks
     * @param collections
     */
    public withUserCollectionBookmarks(
        userCollectionBookmarks: Array<UserCollectionBookmarkRecord>,
        collections: Array<UserCollectionRecord>
    ): UserBookmarkRecord {
        userCollectionBookmarks = userCollectionBookmarks
            .filter(
                (ucb: UserCollectionBookmarkRecord) =>
                    ucb.bookmarkId === this.id
            )
            .map((ucb: UserCollectionBookmarkRecord) =>
                ucb.withCollection(
                    collections.find(
                        (c: UserCollectionRecord) => c.id === ucb.collectionId
                    )
                )
            );

        return this.with({ userCollectionBookmarks });
    }

    public withUser(users: Array<UserRecord>): UserBookmarkRecord {
        return this.with({ user: users.find((u) => u.id === this.userId) });
    }

    // #endregion Public Methods

    private getAnnexGroupPageRoute(): string | undefined {
        const route = RouteUtils.getUrl(
            siteMap.publications.permalinks.annexGroup,
            {
                code: this.publicationCode,
                edition: this.publicationEdition,
                nfpaLabel: this.annexNfpaLabel,
                id: this.groupBy,
            }
        );

        return `${route}${this.getExternalIdHash()}`;
    }

    private getSectionPagePermalinkRoute(): string | undefined {
        return RouteUtils.getUrl(siteMap.publications.permalinks.section, {
            chapterNfpaLabel: this.chapterNfpaLabel,
            code: this.publicationCode,
            edition: this.publicationEdition,
            externalId:
                this.parentExternalId != null
                    ? `${this.parentExternalId}#${this.externalId}`
                    : this.externalId,
        });
    }

    private getAnnexPagePermalinkRoute(): string | undefined {
        const route = RouteUtils.getUrl(siteMap.publications.permalinks.annex, {
            code: this.publicationCode,
            edition: this.publicationEdition,
            nfpaLabel: this.annexNfpaLabel,
        });

        return `${route}${this.getExternalIdHash()}`;
    }

    private getArticlePermalinkRoute(): string | undefined {
        const route = RouteUtils.getUrl(
            siteMap.publications.permalinks.article,
            {
                code: this.publicationCode,
                edition: this.publicationEdition,
                chapterNfpaLabel: this.chapterNfpaLabel,
                nfpaLabel: this.articleNfpaLabel,
            }
        );

        return `${route}${this.getExternalIdHash()}`;
    }

    private getChapterPermalinkRoute(): string | undefined {
        const route = RouteUtils.getUrl(
            siteMap.publications.permalinks.chapter,
            {
                code: this.publicationCode,
                edition: this.publicationEdition,
                nfpaLabel: this.chapterNfpaLabel,
            }
        );

        return `${route}${this.getExternalIdHash()}`;
    }

    private getExternalIdHash(): string {
        return `#${this.externalId}`;
    }

    private hasAnnexGroupPermalinkParams() {
        return (
            this.hasPublicationPermalinkParams() &&
            this.hasAnnexPermalinkParams() &&
            this.groupBy != null
        );
    }

    private hasAnnexPermalinkParams(): boolean {
        return (
            this.hasPublicationPermalinkParams() && this.annexNfpaLabel != null
        );
    }

    private hasArticlePermalinkParams(): boolean {
        return (
            this.hasChapterPermalinkParams() && this.articleNfpaLabel != null
        );
    }

    private hasChapterPermalinkParams(): boolean {
        return (
            this.hasPublicationPermalinkParams() &&
            this.chapterNfpaLabel != null
        );
    }

    private hasPublicationPermalinkParams(): boolean {
        return this.publicationCode != null && this.publicationEdition != null;
    }

    private hasSectionPermalinkParams(): boolean {
        return this.hasChapterPermalinkParams() && this.externalId != null;
    }
}
