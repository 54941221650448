import { SearchListQueryParams } from "utilities/services/search/search-service";
import { ServiceResponse } from "andculturecode-javascript-core";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import HitRecord from "models/view-models/search/hit-record";
import { useCallback, useRef } from "react";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { DirectEntityTypes } from "constants/publication-entity-type-constants";
import ServiceResponseFactory from "utilities/services/service-response-factory";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";
import { GetSearchResultsMessage } from "utilities/service-worker/interfaces/messages/get-search-results-message";
import { chain, isEqual, omit } from "lodash";
import { SearchIndexStatusMessage } from "utilities/service-worker/interfaces/messages/search-index-status-message";
import { OfflineSearchIndexStatus } from "models/enumerations/offline/offline-search-index-status";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DEFAULT_TAKE_SIZE = 10;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useOfflineSearchService() {
    const mostRecentSearch = useRef<SearchListQueryParams>();
    const hits = useRef<HitRecord[]>([]);
    const isLoading = useRef(false);
    const indexStatus = useRef(OfflineSearchIndexStatus.NOT_INDEXED);

    const { sendCommand } = useServiceWorker();

    const handleSearchResults = useCallback(
        (event: TypedMessageEvent<GetSearchResultsMessage>) => {
            hits.current = event.data.hits.map((h) => new HitRecord(h));
            isLoading.current = false;
        },
        []
    );

    const handleIndexResults = useCallback(
        (event: TypedMessageEvent<SearchIndexStatusMessage>) => {
            if (event.data.status === OfflineSearchIndexStatus.INDEXED) {
                indexStatus.current = event.data.status;
            }
        },
        []
    );

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.SearchIndexStatus,
        handleIndexResults
    );

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.SearchResults,
        handleSearchResults
    );

    const get = useCallback(
        async (
            searchParams?: SearchListQueryParams
        ): Promise<ServiceResponse<HitRecord>> => {
            if (
                StringUtils.isEmpty(searchParams?.search) ||
                isDirectSearch(searchParams?.entityTypes)
            ) {
                return ServiceResponseFactory.successfulList<HitRecord>([], 0);
            }

            if (shouldSearch(searchParams, mostRecentSearch.current)) {
                isLoading.current = true;
                indexStatus.current = OfflineSearchIndexStatus.NOT_INDEXED;
                hits.current = [];

                sendCommand({
                    type: ServiceWorkerCommandTypes.CheckSearchIndexStatus,
                });

                await waitUntil(
                    () =>
                        indexStatus.current === OfflineSearchIndexStatus.INDEXED
                );

                const { search: searchTerm, ...remainingSearchParams } =
                    searchParams ?? {};

                sendCommand({
                    ...remainingSearchParams,
                    searchTerm,
                    type: ServiceWorkerCommandTypes.Search,
                });

                mostRecentSearch.current = searchParams;

                await waitUntil(() => !isLoading.current);
            }

            return ServiceResponseFactory.successfulList(
                chain(hits.current)
                    .drop(searchParams?.start ?? 0)
                    .take(searchParams?.take ?? DEFAULT_TAKE_SIZE)
                    .value(),
                hits.current.length
            );
        },
        [sendCommand]
    );

    return { get };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const isDirectSearch = (entityTypes: string[] = []): boolean => {
    return DirectEntityTypes.some((et) => entityTypes.includes(et));
};

const shouldSearch = (
    currentSearch?: SearchListQueryParams,
    mostRecentSearch?: SearchListQueryParams
) =>
    StringUtils.hasValue(currentSearch?.search) &&
    searchParamsHaveChanged(currentSearch, mostRecentSearch);

const searchParamsHaveChanged = (
    currentSearch?: SearchListQueryParams,
    mostRecentSearch?: SearchListQueryParams
) => {
    const currentSearchParams = omit(currentSearch, "start", "take");
    const mostRecentSearchParams = omit(mostRecentSearch, "start", "take");

    return !isEqual(currentSearchParams, mostRecentSearchParams);
};

const waitUntil = (
    waitOnValue: () => boolean,
    timeoutMs: number = 15000
): Promise<void> => {
    let intervalId: NodeJS.Timeout;
    let timeoutId: NodeJS.Timeout;

    const pollForChanges = (callback: () => void) => {
        intervalId = setInterval(() => {
            if (waitOnValue()) {
                clearTimeout(timeoutId);
                callback();
                clearInterval(intervalId);
            }
        }, 10);
    };

    const onTimeout = (callback: () => void) => {
        timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            callback();
            clearTimeout(timeoutId);
        }, timeoutMs);
    };

    return new Promise((resolve, reject) => {
        pollForChanges(() => resolve());
        onTimeout(() => reject("Timeout reached"));
    });
};

// #endregion Functions
