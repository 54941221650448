import type UserCollectionRecord from "models/view-models/user-collection-record";
import { useCallback, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultSuccessHandler = () => ToastManager.success("Collection updated!");

export default function useUpdateUserCollection(
    userCollection: UserCollectionRecord,
    onSuccess?: () => void
) {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { update: UpdateUserCollectionApi } =
        UserCollectionService.useUpdate();

    const [loading, setLoading] = useState(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    const handleSuccess = useCallback(() => {
        if (onSuccess != null) {
            onSuccess();
            return;
        }

        defaultSuccessHandler();
    }, [onSuccess]);

    const { pageErrors, handlePageLoadError } = usePageErrors();

    // #endregion Handlers

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const updateUserCollection = async (
        userCollection: UserCollectionRecord
    ) => {
        setLoading(true);
        let resultObject: UserCollectionRecord | undefined = undefined;
        try {
            const userCollectionUpdateResult = await UpdateUserCollectionApi(
                userCollection,
                { userId: userCollection.userId, id: userCollection.id! }
            );
            if (userCollectionUpdateResult.result?.hasErrors) {
                setLoading(false);
                handlePageLoadError(userCollectionUpdateResult);
            }
            resultObject = userCollectionUpdateResult.resultObject;
        } catch (result) {
            handlePageLoadError(result);
            setLoading(false);
            return;
        }
        setLoading(false);
        handleSuccess();
        return resultObject;
    };

    // #endregion Hooks

    return { updateUserCollection, loading, pageErrors };
}
