import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import MenuButton from "molecules/menu-button/menu-button";
import EnhancedContentPreviewModal from "organisms/admin/publications/enhanced-content/enhanced-content-preview-modal";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import EnhancedContentAdminNoteActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-admin-note-action-modal";
import EnhancedContentDeleteActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-delete-action-modal";
import EnhancedContentPublishActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-publish-action-modal";
import EnhancedContentUnpublishActionModal from "organisms/enhanced-content/enhanced-content-action-modals/enhanced-content-unpublish-action-modal";
import EnhancedContentDeleteAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-delete-action";
import EnhancedContentPublishAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-publish-action";
import EnhancedContentUnpublishAction from "organisms/enhanced-content/enhanced-content-actions/enhanced-content-unpublish-action";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useModalActions from "utilities/hooks/use-modal-actions";
import AdminEnhancedContentService from "utilities/services/admin/enhanced-content/enhanced-content-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentAdminTableRowProps {
    enhancedContent: EnhancedContentRecord;
    onRefresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentAdminTableRow = (
    props: EnhancedContentAdminTableRowProps
) => {
    const { enhancedContent, onRefresh } = props;
    const { delete: deleteApi } = AdminEnhancedContentService.useDelete();

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showPublishConfirmation, setShowPublishConfirmation] =
        useState(false);
    const [showUnpublishConfirmation, setShowUnpublishConfirmation] =
        useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const updatedByText = enhancedContent.getUpdatedByText();

    const bookviewRoute = enhancedContent.getRoute(true);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const {
        handleClose: handleAdminNoteModalClose,
        handleOpen: handleAdminNoteModalOpen,
        isOpen: isAdminNoteModalOpen,
    } = useModalActions();

    const handleDeleteConfirm = (enhancedContent: EnhancedContentRecord) =>
        deleteApi(enhancedContent.id!);
    const handleDeleteClick = () => setShowDeleteConfirmation(true);
    const handleDeleteConfirmationClose = () =>
        setShowDeleteConfirmation(false);

    const handlePublishClick = () => setShowPublishConfirmation(true);
    const handlePublishConfirmationClose = () =>
        setShowPublishConfirmation(false);

    const handleUnpublishClick = () => setShowUnpublishConfirmation(true);
    const handleUnpublishConfirmationClose = () =>
        setShowUnpublishConfirmation(false);

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <tr>
            <td>
                <Link to={bookviewRoute}>{enhancedContent.title}</Link>
            </td>
            <td>{enhancedContent.getDisplayCodeAndEdition()}</td>
            <td>{enhancedContent.adminNote}</td>
            <td className={`-right-col`}>
                <div>{`${enhancedContent.getDisplayStatusAndDate()}`}</div>
                {updatedByText != null && <div>{`${updatedByText}`}</div>}
            </td>
            <td className={`-status -right-col`}>
                <PublishStatusBadge
                    hasUnpublishedChanges={enhancedContent.hasUnpublishedChanges()}
                    status={enhancedContent.getPublishStatus()}
                />
            </td>
            <td className={"-collapse"}>
                <MenuButton buttonAccessibleText={"Options"}>
                    <EnhancedContentPublishAction
                        enhancedContent={enhancedContent}
                        onClick={handlePublishClick}
                    />
                    <Anchor cssClassName="c-button" to={bookviewRoute}>
                        Go to Bookview
                    </Anchor>
                    <Button
                        accessibleText="Preview Enhanced Content"
                        onClick={() => setShowPreviewModal(true)}>
                        Preview
                    </Button>
                    <EnhancedContentUnpublishAction
                        enhancedContent={enhancedContent}
                        onClick={handleUnpublishClick}
                    />
                    <EnhancedContentDeleteAction
                        enhancedContent={enhancedContent}
                        onClick={handleDeleteClick}
                    />
                    {/* 'Edit Note' Button should always be last. */}
                    <Button
                        accessibleText="Edit Enhanced Content Note"
                        onClick={handleAdminNoteModalOpen}>
                        Edit Note
                    </Button>
                </MenuButton>

                {showPublishConfirmation && (
                    <EnhancedContentPublishActionModal
                        enhancedContent={enhancedContent}
                        onClose={handlePublishConfirmationClose}
                        onSuccess={onRefresh}
                    />
                )}

                {showUnpublishConfirmation && (
                    <EnhancedContentUnpublishActionModal
                        enhancedContent={enhancedContent}
                        onClose={handleUnpublishConfirmationClose}
                        onSuccess={onRefresh}
                    />
                )}

                {showDeleteConfirmation && (
                    <EnhancedContentDeleteActionModal
                        enhancedContent={enhancedContent}
                        onClose={handleDeleteConfirmationClose}
                        onDeleteConfirm={handleDeleteConfirm}
                        onSuccess={onRefresh}
                    />
                )}

                {isAdminNoteModalOpen && (
                    <EnhancedContentAdminNoteActionModal
                        onClose={handleAdminNoteModalClose}
                        onRefresh={onRefresh}
                        record={enhancedContent}
                    />
                )}

                {showPreviewModal && (
                    <EnhancedContentPreviewModal
                        isVisible={showPreviewModal}
                        closeDialog={() => setShowPreviewModal(false)}
                        label="Enhanced Content Preview"
                        enhancedContent={enhancedContent}
                    />
                )}
            </td>
        </tr>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EnhancedContentAdminTableRow;

// #endregion Exports
