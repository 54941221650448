import type FileRecord from "models/view-models/file-record";
import { useCallback, useEffect, useState } from "react";
import FileService from "utilities/services/file-service";

/**
 * Custom hook to get a FileRecord from the API.
 * NOTE wrap callbacks in useCallback
 * @param fileId
 * @param onFileLoadSuccess
 * @param onFileLoadError
 */
export default function useFile(
    fileId: number | undefined,
    onFileLoadSuccess?: (file?: FileRecord) => void,
    onFileLoadError?: () => void
) {
    const { get: getFileApi } = FileService.useGet();
    const [file, setFile] = useState<FileRecord>();
    const [loadingFile, setLoadingFile] = useState(fileId != null);

    const handleFileSuccess = useCallback(
        (file?: FileRecord) => onFileLoadSuccess?.(file),
        [onFileLoadSuccess]
    );
    const handleFileLoadError = useCallback(
        () => onFileLoadError?.(),
        [onFileLoadError]
    );

    useEffect(() => {
        if (fileId == null) {
            setFile(undefined);
            setLoadingFile(false);
            handleFileSuccess();
            return;
        }

        const loadFile = async () => {
            setLoadingFile(true);

            try {
                const result = await getFileApi({ id: fileId! });
                setFile(result.resultObject);
                handleFileSuccess(result.resultObject);
            } catch (e) {
                handleFileLoadError();
                setFile(undefined);
            }

            setLoadingFile(false);
        };

        loadFile();
    }, [fileId, getFileApi, handleFileSuccess, handleFileLoadError]);

    return {
        file,
        setFile,
        loadingFile,
    };
}
