import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { EmulationModeContext } from "./emulation-mode-context-provider";
import EmulationService from "utilities/services/support/emulation-service";
import { ToastManager } from "utilities/toast/toast-manager";
import EmulationTokenRecord from "models/view-models/emulation-token-record";
import { useHistory } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import useAuthentication from "use-authentication";

const UNABLE_TO_CREATE_TOKEN = "Unable to create emulation token.";

export default function useEmulationMode() {
    const {
        setLoadingEmulationSession,
        setEndingEmulationSession,
        token,
        setToken,
        userEmail,
    } = useContext(EmulationModeContext);
    const { attemptLogin } = useAuthentication();
    const history = useHistory();
    const timeoutId = useRef<number | null>(null);

    const deleteToken = useCallback(
        async (tokenId: number | undefined, guestId: number | undefined) => {
            try {
                if (tokenId !== undefined) {
                    setEndingEmulationSession(true);
                    await EmulationService.delete(tokenId);
                }
            } catch (error) {
                ToastManager.error(
                    "There was an error deleting emulation session token"
                );
            } finally {
                setToken(null);
                await attemptLogin(true);
                setEndingEmulationSession(false);
                history.push(
                    RouteUtils.getUrl(siteMap.admin.support.userDetail, {
                        id: guestId,
                    })
                );
                if (timeoutId.current != null) {
                    clearTimeout(timeoutId.current);
                }
            }
        },
        [attemptLogin, history, setEndingEmulationSession, setToken]
    );

    const countDownToExpiration = (expiresOn: string | undefined) => {
        if (expiresOn !== undefined) {
            const expiresOnTime = Date.parse(expiresOn);
            const countdownMilliseconds = expiresOnTime - Date.now();
            return countdownMilliseconds;
        }
    };

    useEffect(() => {
        if (token != null) {
            timeoutId.current = setTimeout(async () => {
                await deleteToken(token?.id, token?.guestId);
            }, countDownToExpiration(token?.expiresOn));
        }
        return () => {
            if (timeoutId.current != null) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [deleteToken, token]);

    const login = async () => {
        try {
            await attemptLogin(true);
            return true;
        } catch (error) {
            ToastManager.error("Unable to login as subscriber");
            return false;
        }
    };

    const createToken = async (id: number | undefined) => {
        if (id == null) return ToastManager.error(UNABLE_TO_CREATE_TOKEN);

        try {
            setLoadingEmulationSession(true);
            const request = await EmulationService.create(
                new EmulationTokenRecord({ guestId: id })
            );
            const newToken = request.resultObject;

            const success = request.status === 200;
            if (
                success &&
                newToken != null &&
                newToken.id != null &&
                newToken.expiresOn != null &&
                newToken.guestId != null
            ) {
                setToken(newToken);
                const isLoggedIn = await login();

                if (!isLoggedIn) {
                    return deleteToken(newToken.id, id);
                }

                history.push(RouteUtils.getUrl(siteMap.dashboards.user));
            } else {
                ToastManager.error(UNABLE_TO_CREATE_TOKEN);
            }
        } catch (error) {
            ToastManager.error(UNABLE_TO_CREATE_TOKEN);
        } finally {
            setLoadingEmulationSession(false);
        }
    };

    const isInEmulationMode = useMemo(() => token != null, [token]);

    return {
        isInEmulationMode,
        token,
        setToken,
        userEmail,
        createToken,
        deleteToken,
    };
}
