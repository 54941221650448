import React, { useState, useEffect } from "react";
import useSupportUsers from "utilities/hooks/domain/admin/support/use-support-users";
import SearchForm from "molecules/forms/search-form";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import UserSupportTable from "organisms/admin/support/user-support-table";
import { t } from "utilities/localization-utils";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import StringUtils from "utilities/string-utils";

const CSS_BASE_CLASS = "c-support-users-page";

const SupportUsersPage: React.FC<any> = () => {
    const {
        loadingSupportUsers,
        supportUsers,
        loadSupportUsers,
        rowCount,
        take,
        skip,
        currentPage,
        numberOfPages,
        onPageNext,
        onPageLast,
        onPageSizeChange,
        onSelectPage,
        resetPage,
    } = useSupportUsers();

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (StringUtils.hasValue(searchText))
            loadSupportUsers({ searchText, skip, take });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSupportUsers, skip, take]);

    const handleSearchFormSubmit = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        resetPage();
        loadSupportUsers({ searchText, take });
        return false;
    };

    const handleSearchFormCleared = () => {
        resetPage();
        setSearchText("");
    };

    const handleSearchTextChanged = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchText(e.target.value);
    };

    return (
        <div className={CSS_BASE_CLASS}>
            <div className={`${CSS_BASE_CLASS}__content`}>
                <div className={`${CSS_BASE_CLASS}__content__controls`}>
                    <SearchForm
                        onClear={handleSearchFormCleared}
                        onSearchClick={handleSearchFormSubmit}
                        onSearchTextChange={handleSearchTextChanged}
                        onSubmit={handleSearchFormSubmit}
                        placeholder={t("userSupportPage-searchFormPlaceholder")}
                        searchText={searchText}
                        size={SearchFormSizes.Small}
                        style={SearchFormStyles.Tertiary}
                    />
                </div>
            </div>

            <div className={`${CSS_BASE_CLASS}__table`}>
                <UserSupportTable
                    users={supportUsers}
                    loading={loadingSupportUsers}
                />
            </div>

            <div className={`${CSS_BASE_CLASS}__footer`}>
                <PageNavigationMenu
                    resultTotal={rowCount}
                    pageSize={take}
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    onPageSizeChange={onPageSizeChange}
                    onNavigateBack={onPageLast}
                    onNavigateForward={onPageNext}
                    onSelectPage={onSelectPage}
                />
            </div>
        </div>
    );
};

export default SupportUsersPage;
