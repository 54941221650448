import { siteMap } from "internal-sitemap";
import LegacyPublicationPage from "pages/publications/legacy-publication-page";
import PublicationPage from "pages/publications/publication-page";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ReferencePanelContextProvider } from "utilities/contexts/reference-panel/reference-panel-context-provider";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useHeaderData } from "utilities/contexts/use-header-data-context";

interface PublicationLayoutProps {}

const PublicationLayout: React.FC<PublicationLayoutProps> = (
    props: PublicationLayoutProps
) => {
    const { setPageTitle } = useHeaderData();
    const { publication, loaded, errors } = useCurrentPublication();

    useEffect(() => {
        setPageTitle(publication?.getPageTitle());
    }, [publication, setPageTitle]);

    if (loaded && !publication.isPersisted()) {
        return <Redirect to={siteMap.errors.notFound} />;
    }

    return (
        <React.Fragment>
            {publication.getIsLegacy() ? (
                <ReferencePanelContextProvider>
                    <LegacyPublicationPage publication={publication} />
                </ReferencePanelContextProvider>
            ) : (
                <PublicationPage
                    publication={publication}
                    publicationLoaded={loaded}
                    pageErrors={errors}
                />
            )}
        </React.Fragment>
    );
};

export default PublicationLayout;
