export enum InputTypes {
    Button = "button",
    Checkbox = "checkbox",
    Email = "email",
    Hidden = "hidden",
    Password = "password",
    Phone = "tel",
    Text = "text",
    Number = "number",
}
