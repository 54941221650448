import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import * as React from "react";
import StringUtils from "utilities/string-utils";
import useCurrentPublication from "utilities/contexts/use-current-publication";

export interface ExceptionSectionProps extends XmlConvertedComponentProps {
    label?: string;
}

const ExceptionSection: React.FunctionComponent<ExceptionSectionProps> = (
    props: ExceptionSectionProps
) => {
    const { publication } = useCurrentPublication();
    const { diffchanged } = props;
    let label = props.label;
    const hasLabel = StringUtils.hasValue(label);

    if (hasLabel) {
        label = StringUtils.translateDomainTerms(label, publication?.locale!);
    }
    const changedModifier =
        diffchanged != null ? ` c-code-change -${diffchanged}` : "";
    const cssClassName = "c-exception-section" + changedModifier;

    return (
        <div className={cssClassName + (!hasLabel ? " -no-label" : "")}>
            {hasLabel && <span>{label + ": "}</span>}
            {props.children}
        </div>
    );
};

export default ExceptionSection;
