import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Paragraph from "atoms/typography/paragraph";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import React, { useEffect, useState } from "react";
import NumberUtils from "utilities/number-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { SelectOption } from "atoms/forms/select";
import TopicRecord from "models/view-models/topic-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TopicsFilterModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    onConfirm: (selectedTopicIds: Array<number>) => void;
    onReset: () => void;
    options: Array<SelectOption<TopicRecord, number>>;
    selectedTopicIds: Array<number>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-topics-filter-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TopicsFilterModal: React.FC<TopicsFilterModalProps> = (
    props: TopicsFilterModalProps
) => {
    const [selectedTopicIds, setSelectedTopicIds] = useState(
        props.selectedTopicIds
    );
    const { t } = useLocalization<CultureResources>();

    useEffect(() => {
        // when modal is closed without confirming, reset selection
        if (!props.isVisible) {
            setSelectedTopicIds(props.selectedTopicIds);
        }
    }, [props.isVisible, props.selectedTopicIds]);

    const handleTopicsSelected = (
        selectedValues: Array<SelectOption<TopicRecord, number>>
    ) => {
        const newSelectedTopicIds = selectedValues
            .map((option: SelectOption<TopicRecord, number>) =>
                NumberUtils.parseInt(option.value)
            )
            .filter((topicId?: number): topicId is number => topicId != null);

        setSelectedTopicIds(newSelectedTopicIds);
    };

    const handleResetClick = () => {
        props.onReset();
        props.closeDialog();
    };

    const handleConfirmClick = () => {
        props.onConfirm(selectedTopicIds);
        props.closeDialog();
    };

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.Hidden}
            cssClassName={CSS_CLASS_NAME}
            label={t("filter")}
            type={ModalTypes.Bottom}
            transition={ModalTransitions.SlideUp}
            {...props}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Paragraph>{t("filter")}</Paragraph>
                <Button
                    onClick={handleConfirmClick}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.Secondary}>
                    Close
                </Button>
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__body__filter-group`}>
                    <label>{t("filterBy", { item: t("topic") })}</label>
                    <CheckboxList<TopicRecord, number>
                        onChange={handleTopicsSelected}
                        options={props.options}
                        // renderOption={TopicOptionRenderer}
                        values={selectedTopicIds}
                    />
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <Button
                    onClick={handleResetClick}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.Tertiary}>
                    {t("reset")}
                </Button>
                <Button onClick={handleConfirmClick} size={ButtonSizes.Small}>
                    {t("actionItem", {
                        action: t("show"),
                        item: t("result_plural"),
                    })}
                </Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default TopicsFilterModal;

// #endregion Exports
