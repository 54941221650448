import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import AnnouncementRecord from "models/view-models/announcement-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface AnnouncementBasePathParams {}

export interface AnnouncementResourcePathParams
    extends AnnouncementBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Announcement resources.
 * These get translated to QueryString parameters
 */
export interface AnnouncementListQueryParams {}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "announcements";
const cachedEndpoint = `${baseEndpoint}/cache`;
const resourceType = AnnouncementRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "announcements";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AnnouncementService = {
    key,
    /**
     * Create a new Announcement
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Retrieve a specific Announcement resource
     */
    get: ServiceFactory.get<AnnouncementRecord, AnnouncementResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Announcement resources based upon supplied search criteria
     */
    list: ServiceFactory.list<AnnouncementRecord, AnnouncementListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Update an Announcement
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        AnnouncementRecord,
        AnnouncementResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        AnnouncementRecord,
        AnnouncementListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useListQuery<
        AnnouncementRecord,
        AnnouncementListQueryParams
    >(key, resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useCachedList: ServiceHookFactory.useList<
        AnnouncementRecord,
        AnnouncementListQueryParams
    >(resourceType, cachedEndpoint),

    useCachedListQuery: QueryHookFactory.useListQuery<
        AnnouncementRecord,
        AnnouncementListQueryParams
    >(key, resourceType, cachedEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        AnnouncementRecord,
        AnnouncementResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AnnouncementService;

export const AnnouncementServiceErrorMessages = {
    Create: "There was an issue creating this announcement",
    Get: "There was an issue retrieving an announcement",
    List: "There was an issue retrieving an announcement",
    Update: "There was an issue updating an announcement",
};

// #region Exports
