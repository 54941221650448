import { Rfc4646LanguageCodes } from "andculturecode-javascript-core";

const defaultFormatter = new Intl.NumberFormat(Rfc4646LanguageCodes.EN_US);

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const _format = (
    num: number | Number,
    formatter: Intl.NumberFormat = defaultFormatter
): string => formatter.format(num as number);

/**
 * Attempts to parses an integer from the given input. If the input is already a number,
 * it returns that value. If the parsed value returns `NaN`, it returns `undefined`
 *
 * @param {(string | number)} [num]
 */
const _parseInt = (num?: string | number | null): number | undefined => {
    if (num == null) {
        return undefined;
    }

    if (typeof num === "number") {
        if (isNaN(num)) {
            return undefined;
        }

        return num;
    }

    const parsed = Number.parseInt(num);
    if (isNaN(parsed)) {
        return undefined;
    }

    return parsed;
};

/**
 * Parse the integer from the given input and return true if the number is null or less than or equal to 0
 *
 * @param num
 */
const _isDefault = (num?: string | number): num is undefined | number => {
    const value = _parseInt(num);
    return value == null || value <= 0;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const NumberUtils = {
    format: _format,
    parseInt: _parseInt,
    isDefault: _isDefault,
};

export default NumberUtils;

// #endregion Exports
