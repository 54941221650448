import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationsAdminBreadcrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">Publications</span>
    </Breadcrumb>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationsAdminBreadcrumb;

// #endregion Exports
