import RadioButton from "atoms/forms/radio-button-input";
import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import HitRecord from "models/view-models/search/hit-record";
import Typeahead from "molecules/typeahead/typeahead";
import React, { useCallback, useEffect, useMemo } from "react";
import useCloudSearch from "utilities/hooks/use-cloud-search";
import useToastError from "utilities/hooks/use-toast-error";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextReferenceSearchProps {
    baseClassName: string;
    checked: boolean;
    error?: string;
    onChange: (hit?: HitRecord) => void;
    onCheck: () => void;
    publicationId: number;
    value?: HitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const RADIO_BUTTON_NAME = "rich-text-reference-search";

const EntityTypes = [
    PublicationEntityTypeConstants.Chapter,
    PublicationEntityTypeConstants.Annex,
    PublicationEntityTypeConstants.Article,
    PublicationEntityTypeConstants.Part,
    PublicationEntityTypeConstants.Section,
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextReferenceSearch: React.FC<RichTextReferenceSearchProps> = (
    props: RichTextReferenceSearchProps
) => {
    const { checked, error, onChange, onCheck, publicationId, value } = props;

    const onSearchError = useToastError(
        "There was an issue searching for references."
    );
    const cloudSearchOptions = useMemo(
        () => ({ onError: onSearchError }),
        [onSearchError]
    );
    const { search } = useCloudSearch(cloudSearchOptions);

    const handleSearch = useCallback(
        async (searchText: string) => {
            const searchParams = buildSearchParams(searchText, publicationId);
            const result = await search(searchParams);
            if (result == null) {
                return [];
            }

            return result.hits ?? [];
        },
        [publicationId, search]
    );

    const handleContentItemSelected = (hit?: HitRecord) => onChange(hit);

    useEffect(() => {
        if (!checked) {
            onChange();
        }
    }, [checked, onChange]);

    const baseClassName = `${props.baseClassName}__content-item`;

    return (
        <React.Fragment>
            <RadioButton
                cssClassName={`${baseClassName}-radio`}
                checked={checked}
                label={"Reference Content Item"}
                name={RADIO_BUTTON_NAME}
                onCheck={onCheck}
            />
            {checked && (
                <div className={`${baseClassName}-search`}>
                    <Typeahead<HitRecord>
                        errorMessage={error}
                        getOptionText={getOptionText}
                        isValid={StringUtils.isEmpty(error)}
                        label={"Content Item"}
                        menuPosition={"fixed"}
                        onChange={handleContentItemSelected}
                        placeholder={"Search for reference..."}
                        required={true}
                        search={handleSearch}
                        value={value}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const buildSearchParams = (
    search: string,
    publicationId: number
): SearchListQueryParams => ({
    search,
    entityTypes: EntityTypes,
    publicationIds: [publicationId],
    start: 0,
    take: 10,
});

const getOptionText = (hit: HitRecord) => hit.getOptionDisplayText();

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextReferenceSearch;

// #endregion Exports
