import React from "react";
import { routes } from "routes";
import { siteMap } from "internal-sitemap";
import { Redirect, useLocation } from "react-router-dom";
import { CoreUtils } from "utilities/core-utils";
import { NestedRoutes } from "utilities/routing/nested-route";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

export const SituationalNavigationDashboard: React.FC = () => {
    const location = useLocation();
    const { generateCultureCodedRoute } = useCultureCodeRoute();

    // for now, just redirect to the admin dashboard,
    // since there isn't a general sitnav landing page
    if (
        location.pathname ===
        generateCultureCodedRoute(siteMap.admin.situationalNavigation.dashboard)
    ) {
        return (
            <Redirect to={generateCultureCodedRoute(siteMap.admin.dashboard)} />
        );
    }

    return (
        <NestedRoutes
            routes={CoreUtils.objectToArray(
                routes.home.routes.situationalNavigationAdmin.routes
            ).map((route: RouteDefinition) =>
                RouteUtils.withCulture<RouteDefinition>(route)
            )}
        />
    );
};

// #endregion Component
