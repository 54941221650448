import { SkipNavContent } from "@reach/skip-nav";
import { NestedRoutes } from "andculturecode-javascript-react";
import StickyNotification from "atoms/sticky-notification/sticky-notification";
import { AnimateSharedLayout, motion, MotionProps } from "framer-motion";
import ErrorBoundary from "molecules/errors/error-boundary";
import BookViewMetaNav from "molecules/metanavs/bookview-metanav";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import ReferencePanel from "organisms/panels/reference-panel";
import { BrowserUtils } from "utilities/browser-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import OfflineBookviewAnalytics from "molecules/offline-bookview-analytics/offline-bookview-analytics";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import useTias from "utilities/hooks/domain/publications/use-tias";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import {
    getAnnexNfpaLabelFromRoute,
    getChapterNfpaLabelFromRoute,
    getAnnexIdFromRoute,
    getChapterIdFromRoute,
} from "utilities/bookview-utils";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import useSectionAdjustment from "utilities/hooks/use-section-adjustment";
import useEnhancedContentSearchHit from "utilities/hooks/domain/enhanced-content/use-enhanced-content-search-hit";

const CSS_BASE_CLASS = "c-publication-page-layout";
const SLIDE_LEFT: MotionProps = {
    initial: { x: "100%" },
    animate: { x: 0 },
    exit: { x: "90%" },
    transition: { type: "tween", duration: 0.2 },
};

const BookViewLayoutContent: React.FunctionComponent<any> = React.memo(
    (props: React.PropsWithChildren<any>) => {
        const { isOpen: isReferencePanelOpen } = useReferencePanelContext();
        const { isOnline } = useNetworkInformation();
        const { t } = useLocalization<CultureResources>();
        const { globalState } = useGlobalState();
        const { currentIdentity } = globalState;
        const { publication, loaded } = useCurrentPublication();
        const { useTIAs } = useFeatureFlags();
        const { loadTias } = useTias();
        const { disableApiCall } = useEnhancedContentSearchHit();

        useEffect(() => {
            if (publication.id != null && useTIAs) loadTias(publication.id);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [publication.id, useTIAs]);

        // START: Enhanced Content
        const { pathname } = useLocation();
        const showEnhancedContent = globalState.getUserSettingValue<boolean>(
            "EnhancedContentShow"
        );
        const canAddOrEditEnhancedContent = useMemo(
            () =>
                currentIdentity?.isAdmin() ||
                currentIdentity?.isAuthorOrPublisher(),
            [currentIdentity]
        );

        const { routeIsPreview } = useIsNestedRoute();

        const chapterId = getChapterIdFromRoute(pathname);
        const chapterNfpaLabel = getChapterNfpaLabelFromRoute(pathname);
        const annexId = getAnnexIdFromRoute(pathname);
        const annexNfpaLabel = getAnnexNfpaLabelFromRoute(pathname);

        const { updateEnhancedContentOnChapterChange } = useEnhancedContent({
            annexId,
            chapterId,
            annexNfpaLabel,
            chapterNfpaLabel,
            isAdmin: canAddOrEditEnhancedContent && routeIsPreview,
            code: publication.code ?? "",
            edition: publication.edition ?? "",
            disabled: !showEnhancedContent && disableApiCall,
        });

        useEffect(() => {
            if (loaded) {
                updateEnhancedContentOnChapterChange();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loaded, chapterId, chapterNfpaLabel, annexId, annexNfpaLabel]);
        // END: Enhanced Content

        const renderStaticReferencePanel =
            isReferencePanelOpen && BrowserUtils.isIE() && isOnline;
        const renderAnimatedReferencePanel =
            isReferencePanelOpen && !BrowserUtils.isIE() && isOnline;
        const renderUnpublishedSticky =
            currentIdentity?.isAdmin() &&
            loaded &&
            StringUtils.isEmpty(publication?.publishedOn);
        const classNames = `${CSS_BASE_CLASS} ${
            isReferencePanelOpen && isOnline ? "-reference-panel-open" : ""
        }`;

        const memoRoutes = useMemo(
            () => (
                <NestedRoutes
                    routes={props.routes}
                    isAuthenticated={globalState.isAuthenticated()}
                />
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [globalState.currentIdentity?.userLogin?.id, props.routes]
        );

        // Ensures that section scrolls to top
        useSectionAdjustment();

        return (
            <div className={classNames}>
                <BookViewMetaNav />
                <ErrorBoundary>
                    <AnimateSharedLayout>
                        <div className="c-publication-page-layout__content">
                            <BreadcrumbsMenu />
                            <SkipNavContent>
                                <OfflineBookviewAnalytics />
                                {BrowserUtils.isIE() ? (
                                    <div
                                        className={`${CSS_BASE_CLASS}__content__main`}>
                                        <NestedRoutes
                                            routes={props.routes}
                                            isAuthenticated={globalState.isAuthenticated()}
                                        />
                                    </div>
                                ) : (
                                    <motion.div
                                        className={`${CSS_BASE_CLASS}__content__main`}
                                        layout>
                                        {/* <NestedRoutes
                                            routes={props.routes}
                                            isAuthenticated={globalState.isAuthenticated()}
                                        /> */}
                                        {memoRoutes}
                                    </motion.div>
                                )}
                            </SkipNavContent>
                        </div>
                        {renderStaticReferencePanel && (
                            <div>
                                <ReferencePanel />
                            </div>
                        )}
                        {renderAnimatedReferencePanel && (
                            <motion.div
                                {...SLIDE_LEFT}
                                layoutId={"reference-panel"}>
                                <ReferencePanel />
                            </motion.div>
                        )}
                    </AnimateSharedLayout>
                </ErrorBoundary>
                {renderUnpublishedSticky && (
                    <StickyNotification>{t("unpublished")}</StickyNotification>
                )}
            </div>
        );
    }
);

export default BookViewLayoutContent;
