import MenuButton from "molecules/menu-button/menu-button";
import React, { PropsWithChildren } from "react";
import Button from "atoms/buttons/button";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import { ScrollUtils } from "utilities/scroll-utils";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import { t, tActionForContainer } from "utilities/localization-utils";
import useModalActions from "utilities/hooks/use-modal-actions";
import OfflineSettingsModal from "organisms/modals/offline-settings-modal/offline-settings-modal";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import StringUtils from "utilities/string-utils";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import useOfflineSync from "utilities/hooks/domain/offline/use-offline-sync";

export interface PublicationOptionMenuProps {
    favorites: Array<UserPublicationFavoriteRecord>;
    onChangeFavorites: (
        favorites: Array<UserPublicationFavoriteRecord>
    ) => void;
    publication: PublicationRecord;
}

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const DATA_TEST_ID_OFFLINE_SETTINGS =
    "publication-options-menu-offline-settings";

// #endregion Constants

const PublicationOptionsMenu: React.FC<PublicationOptionMenuProps> = (
    props: PropsWithChildren<PublicationOptionMenuProps>
) => {
    const CSS_CLASS_NAME = "c-publication-options-menu";

    const { favorites, onChangeFavorites, publication } = props;

    const isDownloaded = false; // NFPA-4594: Wire this up.

    const { isOnline } = useNetworkInformation();

    const anchorId = `publication-card-${publication.id!}`;

    const {
        addFavorite,
        unfavorite,
        isFavorited,
        loading: loadingFavorites,
    } = useUpdatePublicationFavorite(publication, favorites, onChangeFavorites);

    const {
        handleClose: handleUnfavoriteConfirmationClose,
        handleOpen: handleUnfavoriteConfirmationOpen,
        isOpen: isUnfavoriteConfirmationOpen,
    } = useModalActions();

    const {
        handleClose: handleOfflineSettingsClose,
        handleOpen: handleOfflineSettingOpen,
        isOpen: isOfflineSettingsOpen,
    } = useModalActions();

    const menuButtonText = t("publicationOptionsMenu");

    const favoriteContainer = tActionForContainer("favorite_plural");
    const favoriteMenuItemText = isFavorited
        ? favoriteContainer("removeFrom")
        : favoriteContainer("addTo");

    const offlineSettingsMenuItemText = t("offline-settings-header");

    const favoriteButtonIcon = isFavorited
        ? Icons.StarFilled
        : Icons.StarOutline;

    const offlineButtonIcon = isDownloaded
        ? Icons.DownloadComplete
        : Icons.Download;

    const { syncOfflineBook } = useOfflineSync();

    const handleOfflineSettingsModalClose = (
        offlineBook?: OfflineBookRecord,
        sync?: boolean,
        includeOptionsChanged?: boolean
    ) => {
        if (sync === true && offlineBook != null) {
            syncOfflineBook(offlineBook, includeOptionsChanged);
        }

        handleOfflineSettingsClose();
    };

    const handleFavoriteClick = async () => {
        if (isFavorited) {
            handleUnfavoriteConfirmationOpen();
            return;
        }

        await addFavorite();
        setTimeout(() => {
            const card = document.getElementById(anchorId);
            if (card == null) {
                return;
            }

            ScrollUtils.scrollToElement(card, { behavior: "smooth" });
            card.focus();
        }, 0);
    };

    const handleUnfavoritePublication = async () => {
        handleUnfavoriteConfirmationClose();
        await unfavorite();
    };

    const isFavoriteButtonDisabled = loadingFavorites || !isOnline;

    const isHighlighted = (isActive: boolean) =>
        isActive ? "-highlighted" : "";

    const favoriteButtonModifier = isHighlighted(isFavorited);
    const offlineButtonModifier = isHighlighted(isDownloaded);

    const imageModifier =
        publication.coverImageFile != null ? "-has-cover-image" : "";

    return (
        <div className={CSS_CLASS_NAME}>
            <MenuButton
                buttonAccessibleText={menuButtonText}
                cssClassName={imageModifier}
                modalClassName={`${CSS_CLASS_NAME}__mobile-modal`}>
                <Button
                    accessibleText={StringUtils.capitalize(
                        favoriteMenuItemText
                    )}
                    cssClassName={favoriteButtonModifier}
                    disabled={isFavoriteButtonDisabled}
                    onClick={handleFavoriteClick}>
                    <Icon type={favoriteButtonIcon} />
                    {StringUtils.capitalize(favoriteMenuItemText)}
                </Button>
                {!publication.getIsLegacy() && (
                    <Button
                        accessibleText={StringUtils.capitalize(
                            offlineSettingsMenuItemText
                        )}
                        cssClassName={offlineButtonModifier}
                        dataTestId={DATA_TEST_ID_OFFLINE_SETTINGS}
                        onClick={handleOfflineSettingOpen}>
                        <Icon type={offlineButtonIcon} />
                        {StringUtils.capitalize(offlineSettingsMenuItemText)}
                    </Button>
                )}
            </MenuButton>

            {isOfflineSettingsOpen && (
                <OfflineSettingsModal
                    closeDialog={handleOfflineSettingsModalClose}
                    isVisible={isOfflineSettingsOpen}
                    label={"Offline Settings"}
                    publication={publication}
                />
            )}

            <ConfirmationModal
                isVisible={isUnfavoriteConfirmationOpen}
                message={t("confirmationMessage", {
                    action: t("unfavorite").toLowerCase(),
                    item: t("publication").toLowerCase(),
                })}
                onCancel={handleUnfavoriteConfirmationClose}
                onConfirm={handleUnfavoritePublication}
            />
        </div>
    );
};

export default PublicationOptionsMenu;
