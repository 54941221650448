import { SkipNavContent } from "@reach/skip-nav";
import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import EditorPageSidebar from "organisms/admin/situational-navigation/editor-page-sidebar";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import AdminEditorPageContext from "utilities/contexts/admin/use-admin-editor-page-context";
import useLoading from "utilities/hooks/use-loading";
import {
    NestedRoutes,
    NestedRoutesProps,
} from "utilities/routing/nested-route";
import AdminCategoryService from "utilities/services/admin/situational-navigation/categories/admin-category-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationsPageProps extends NestedRoutesProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export const SituationsAdminPageContainerId = "situations-admin-page-container";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationsPage: React.FC<any> = (props: SituationsPageProps) => {
    const CSS_CLASS_NAME = "c-admin-editor-page-layout";
    const location = useLocation();
    const { generateCultureCodedRoute } = useCultureCodeRoute();

    const { list: listCategoriesApi } = AdminCategoryService.useList();

    const [context, setContext] = useState(new AdminEditorPageContextRecord());

    const [loadingCategories, setLoadingCategories] = useState(false);
    const loading = useLoading(loadingCategories);

    const currentLocationIsSituationAdmin =
        location.pathname ===
        generateCultureCodedRoute(
            siteMap.admin.situationalNavigation.situations.dashboard
        );

    useEffect(() => {
        const loadCategories = async () => {
            setLoadingCategories(true);
            try {
                const result = await listCategoriesApi();
                setContext((context: AdminEditorPageContextRecord) =>
                    context.with({
                        categories: CategoryCollectionRecord.fromArray(
                            result.resultObjects
                        ),
                    })
                );
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading category options. Please reload the page to try again."
                );
            }
            setLoadingCategories(false);
        };

        loadCategories();
    }, [listCategoriesApi]);

    return (
        <React.Fragment>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <AdminEditorPageContext.Provider value={[context, setContext]}>
                    <div
                        className={`c-dashboard__panel ${CSS_CLASS_NAME} c-situations-admin`}
                        id={SituationsAdminPageContainerId}>
                        <Heading priority={HeadingPriority.Five}>
                            Situational Navigation Management
                        </Heading>
                        <section className={`${CSS_CLASS_NAME}__content`}>
                            {loading && (
                                <Loader accessibleText="Loading situations" />
                            )}
                            {!loading && (
                                <React.Fragment>
                                    <EditorPageSidebar type={SituationRecord} />
                                    <div
                                        className={`${CSS_CLASS_NAME}__content__right`}>
                                        <NestedRoutes routes={props.routes} />
                                        {currentLocationIsSituationAdmin && (
                                            <div
                                                className={`${CSS_CLASS_NAME}__content__right__none-selected`}>
                                                <Paragraph
                                                    size={ParagraphSizes.Large}>
                                                    Select a situation on the
                                                    left to edit or&nbsp;
                                                    <Anchor
                                                        to={
                                                            siteMap.admin
                                                                .situationalNavigation
                                                                .situations
                                                                .create
                                                        }>
                                                        create a new situation.
                                                    </Anchor>
                                                </Paragraph>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </section>
                    </div>
                </AdminEditorPageContext.Provider>
            </SkipNavContent>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationsPage;

// #endregion Exports
