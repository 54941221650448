import { HeadingPriority } from "atoms/constants/heading-priority";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import ArticleTableOfContentsRecord from "models/view-models/table-of-contents/article-table-of-contents-record";
import React from "react";
import { useLocation } from "react-router-dom";
import {
    getArticleIdFromRoute,
    getArticleNfpaLabelFromRoute,
} from "utilities/bookview-utils";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import ExpandableMetaNavCollapsePanel, {
    ExpandableMetaNavLevels,
} from "./expandable-meta-nav-collapse-panel";
import ExpandableMetaNavSections from "./expandable-meta-nav-sections";

interface ExpandableMetaNavArticleProps {
    article: ArticleTableOfContentsRecord;
    publicationId: number;
    isCurrentActivePanel: boolean;
    isAdminPreview: boolean;
    routeParams: any;
}

const ExpandableMetaNavArticle = (props: ExpandableMetaNavArticleProps) => {
    const {
        article,
        publicationId,
        isCurrentActivePanel,
        isAdminPreview,
        routeParams,
    } = props;

    const { articlesLoading, getTableOfContentsArticleSection } =
        useBookviewTableOfContents();

    const { pathname } = useLocation();
    const isCurrentActiveArticlePanel =
        isCurrentActivePanel && isAdminPreview
            ? getArticleIdFromRoute(pathname) === article.id
            : getArticleNfpaLabelFromRoute(pathname) === article.nfpaLabel;

    const articleHasSections = article.sections && article.sections.length > 0;

    const handlePanelOpen = () => {
        if (!articleHasSections) {
            getTableOfContentsArticleSection(
                publicationId,
                article.id,
                article.chapterId
            );
        }
    };

    const { sections, parts } = article;

    const sectionsWithoutParts = sections?.filter(
        (section) =>
            (section.partId === undefined || section.partId === null) &&
            !section.isDeleted
    );
    const partsWithSections =
        parts?.map((part) => ({
            partTitle: `${part.label} ${part.titleAsPlainText}`,
            sections: sections?.filter(
                (section) => section.partId === part.id && !section.isDeleted
            ),
            partId: part.id,
        })) ?? [];

    const sectionRouteParams = {
        ...routeParams,
        nfpaLabel: article.nfpaLabel,
        id: article.id,
        chapterNfpaLabel: routeParams.nfpaLabel,
        chapterId: routeParams.id,
    };

    const articleRouteParams = {
        ...routeParams,
        nfpaLabel: article.nfpaLabel,
        id: article.id,
        chapterNfpaLabel: routeParams.nfpaLabel,
        chapterId: routeParams.id,
    };

    const headingRoute = isAdminPreview
        ? article.getAdminRoute(articleRouteParams)
        : article.getRoute(articleRouteParams);

    return (
        <ExpandableMetaNavCollapsePanel
            isCurrentActivePanel={isCurrentActiveArticlePanel}
            level={ExpandableMetaNavLevels.Child}
            headingText={article.getDisplayTitle() ?? ""}
            headingRoute={headingRoute}
            onPanelOpen={handlePanelOpen}>
            {articlesLoading ? (
                <Loader type={LoaderStyles.LinkGlyph} />
            ) : (
                <>
                    <ExpandableMetaNavSections
                        sections={sectionsWithoutParts ?? []}
                        parentType={SectionParentType.Article}
                        isAdminPreview={isAdminPreview}
                        isCurrentActivePanel={isCurrentActiveArticlePanel}
                        routeParams={sectionRouteParams}
                    />
                    {partsWithSections.map((partWithSections) => (
                        <React.Fragment key={partWithSections.partId}>
                            <Heading
                                priority={HeadingPriority.Four}
                                cssClassName="c-expandable-meta-nav-collapse-panel__part">
                                {partWithSections.partTitle}
                            </Heading>
                            <ExpandableMetaNavSections
                                sections={partWithSections.sections ?? []}
                                parentType={SectionParentType.Article}
                                isAdminPreview={isAdminPreview}
                                isCurrentActivePanel={
                                    isCurrentActiveArticlePanel
                                }
                                routeParams={sectionRouteParams}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}
        </ExpandableMetaNavCollapsePanel>
    );
};

export default ExpandableMetaNavArticle;
