import { Record } from "immutable";
import PublishableUtils, {
    PublishableRecord,
} from "utilities/publishable-utils";
import PublishStatus from "models/enumerations/publish-status";
import { RecordUtils } from "andculturecode-javascript-core";
import type { EnhancedContentResource } from "models/interfaces/enhanced-content-resource";
import FileRecord from "models/view-models/file-record";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import BaseResourceRecord from "models/view-models/base-resource-record";
import { CollectionUtils } from "utilities/collection-utils";

const defaultValues: EnhancedContentResource =
    RecordUtils.auditableDefaultValuesFactory({
        absoluteUrl: undefined,
        absoluteUrlDraft: undefined,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        description: "",
        descriptionDraft: "",
        displaySequence: 0,
        displaySequenceDraft: 0,
        enhancedContent: undefined,
        enhancedContentId: 0,
        file: undefined,
        fileDraft: undefined,
        fileDraftId: undefined,
        fileId: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        title: "",
        titleDraft: "",
    });

export default class EnhancedContentResourceRecord
    extends BaseResourceRecord(Record(defaultValues))
    implements EnhancedContentResource, PublishableRecord
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: EnhancedContentResource) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.enhancedContent != null) {
            params.enhancedContent = RecordUtils.ensureRecord(
                params.enhancedContent,
                EnhancedContentRecord
            );
        }

        if (params.file != null) {
            params.file = RecordUtils.ensureRecord(params.file, FileRecord);
        }

        if (params.fileDraft != null) {
            params.fileDraft = RecordUtils.ensureRecord(
                params.fileDraft,
                FileRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region PublishableRecord Implementation
    // -----------------------------------------------------------------------------------------

    public hasUnpublishedChanges(): boolean {
        // this can be updated if we extend the Publication publishing process to include
        // fields, rather than just the entire record wholesale.
        return PublishableUtils.hasUnpublishedChanges(this);
    }

    public getPublishStatus(): PublishStatus {
        return PublishableUtils.getPublishStatus(this);
    }

    // #endregion PublishableRecord Implementation

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to check if this record is new or a persisted entity
     */
    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<EnhancedContentResource>} values
     */
    public with(
        values: Partial<EnhancedContentResource>
    ): EnhancedContentResourceRecord {
        return new EnhancedContentResourceRecord(
            Object.assign(this.toJS(), values)
        );
    }

    public withFile(files: Array<FileRecord>): EnhancedContentResourceRecord {
        if (CollectionUtils.isEmpty(files)) {
            return this;
        }

        const file = files.find((f: FileRecord) => f.id === this.fileId);
        const fileDraft = files.find(
            (f: FileRecord) => f.id === this.fileDraftId
        );

        return this.with({ file, fileDraft });
    }

    // #endregion Public Methods
}
