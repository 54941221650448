import ModalCloseButton from "atoms/buttons/modal-close-button";
import { KeyboardConstants } from "constants/keyboard-constants";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import { ModalProps } from "molecules/modals/modal";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import useOnClickOutside from "utilities/hooks/use-onclick-outside";
import StringUtils from "utilities/string-utils";
import { DialogContent } from "@reach/dialog";

interface IEFriendlyDialogWithOverlayProps extends ModalProps {
    classNames: string[];
    closeButtonStyle?: ModalCloseButtonStyle;
    type: ModalTypes;
}

const IEFriendlyDialogWithOverlay: React.FunctionComponent<
    PropsWithChildren<IEFriendlyDialogWithOverlayProps>
> = (props: PropsWithChildren<IEFriendlyDialogWithOverlayProps>) => {
    const {
        classNames,
        closeButtonStyle,
        closeDialog,
        dataTestId,
        isVisible,
        label,
        showAppSidebar,
        type,
    } = props;

    const overlayClass = `c-modal-overlay ${
        showAppSidebar ? "-show-sidebar" : ""
    }`;

    const contentsContainerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(contentsContainerRef, closeDialog, undefined, true);

    useEffect(() => {
        if (isVisible) {
            contentsContainerRef.current?.focus();
        }
    }, [isVisible]);

    const handleKeydown = (e: React.KeyboardEvent) => {
        if (e.key === KeyboardConstants.Escape) {
            closeDialog();
        }
    };

    if (!isVisible) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={overlayClass}>
            {
                // if
                closeButtonStyle != null &&
                    closeButtonStyle ===
                        ModalCloseButtonStyle.OutsideDialog && (
                        <ModalCloseButton
                            modalType={type}
                            onClick={closeDialog}
                        />
                    )
            }
            <div>
                <DialogContent
                    aria-label={label}
                    className={StringUtils.joinClassNames(classNames)}
                    data-test-id={dataTestId}>
                    <div
                        ref={contentsContainerRef}
                        className="-ie-content-container"
                        tabIndex={0}
                        onKeyDown={handleKeydown}>
                        <div className="c-modal__content">{props.children}</div>
                        {
                            // if
                            closeButtonStyle != null &&
                                closeButtonStyle ===
                                    ModalCloseButtonStyle.InsideDialog && (
                                    <ModalCloseButton
                                        modalType={type}
                                        onClick={closeDialog}
                                    />
                                )
                        }
                        {
                            // else
                            closeButtonStyle == null && (
                                <ModalCloseButton
                                    modalType={type}
                                    onClick={closeDialog}
                                />
                            )
                        }
                    </div>
                </DialogContent>
            </div>
        </div>,
        document.getElementById("modal-root")!
    );
};

export default IEFriendlyDialogWithOverlay;
