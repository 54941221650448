import useCloudSearch from "utilities/hooks/use-cloud-search";
import useOfflineSearch from "utilities/hooks/aspect/search/offline/use-offline-search";
import SearchResultRecord from "models/view-models/search/search-result-record";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import { CatchResultHandler } from "andculturecode-javascript-core";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useMemo } from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum UseSearchHookProvider {
    Cloud = "Cloud",
    Offline = "Offline",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UseSearchHookResult {
    loading: boolean;
    searchResults?: SearchResultRecord;
}

export interface UseSearchHookOptions {
    /**
     * Callback to handle API errors
     */
    onError?: CatchResultHandler<Array<SearchResultRecord>>;

    /**
     * Params to search with, if not directly using the callback
     */
    params?: SearchListQueryParams;

    /**
     * Type of search to perform (ie. Cloud vs Offline)
     */
    provider?: UseSearchHookProvider;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useSearch(
    options: UseSearchHookOptions
): UseSearchHookResult {
    const { isOnline } = useNetworkInformation();
    const { useOfflineFeatures } = useFeatureFlags();
    const { onError, params } = options;

    const provider = (() => {
        if (options.provider != null) {
            return options.provider;
        }

        if (!isOnline && useOfflineFeatures) {
            return UseSearchHookProvider.Offline;
        }

        return UseSearchHookProvider.Cloud;
    })();

    const searchOptions = useMemo(
        () => ({
            onError,
            params,
            provider,
        }),
        [onError, params, provider]
    );

    const { searchResults: cloudSearchResults, loading: cloudSearchLoading } =
        useCloudSearch(searchOptions);

    const {
        searchResults: offlineSearchResults,
        loading: offlineSearchLoading,
    } = useOfflineSearch(searchOptions);

    const searchResults =
        searchOptions.provider === UseSearchHookProvider.Cloud
            ? cloudSearchResults
            : offlineSearchResults;

    const loading =
        searchOptions.provider === UseSearchHookProvider.Cloud
            ? cloudSearchLoading
            : offlineSearchLoading;

    return { loading, searchResults };
}

// #endregion Hook
