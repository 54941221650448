import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";
import { CollectionUtils } from "utilities/collection-utils";
import ServiceHookResult from "utilities/interfaces/service-hook-result";

// ---------------------------------------------------------------------------------------------
// #region Functions
// ---------------------------------------------------------------------------------------------

const ServiceHookResultFactory = {
    /**
     * Default error service hook response
     *
     * @template T
     * @param {T} resultObject
     * @param {*} error
     */
    errorResult<T>(resultObject: T, error: any): ServiceHookResult<T> {
        const errors: Array<string> = [];

        if (typeof error === "string") {
            errors.push(error);
        }

        if (RecordUtils.isRecord(error, ResultRecord)) {
            errors.concat(error.listErrorMessages());
        }

        if (error.message != null) {
            errors.push(error.message);
        }

        return {
            errors: errors,
            loading: false,
            loaded: CollectionUtils.isNotEmpty(errors),
            resultObject: resultObject,
            rowCount: 0,
        };
    },

    /**
     * Default initial service hook response with nothing loaded or loading
     *
     * @template T
     * @param {T} resultObject
     * @param {number} [rowCount]
     */
    initialResult<T>(resultObject: T, rowCount?: number): ServiceHookResult<T> {
        return {
            errors: [],
            loading: false,
            loaded: false,
            resultObject: resultObject,
            rowCount: rowCount ?? 0,
        };
    },

    /**
     * Default loading service hook response
     *
     * @template T
     * @param {T} resultObject
     * @param {number} [rowCount]
     */
    loadingResult<T>(resultObject: T, rowCount?: number): ServiceHookResult<T> {
        return {
            errors: [],
            loading: true,
            loaded: false,
            resultObject: resultObject,
            rowCount: rowCount ?? 0,
        };
    },

    /**
     * Default success service hook response
     *
     * @template T
     * @param {T} resultObject
     * @param {number} [rowCount]
     */
    successResult<T>(resultObject: T, rowCount?: number): ServiceHookResult<T> {
        return {
            errors: [],
            loading: false,
            loaded: true,
            resultObject: resultObject,
            rowCount: rowCount ?? 0,
        };
    },
};

// #endregion Functions

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ServiceHookResultFactory;

// #endregion Exports
