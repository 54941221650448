import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import useGroupByFavoritePublications from "utilities/hooks/domain/user-publication-favorites/use-group-by-favorite-publications";
import SearchResultRecord from "models/view-models/search/search-result-record";
import {
    UseSearchHookOptions,
    UseSearchHookProvider,
} from "utilities/hooks/use-search";
import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import { CollectionUtils } from "utilities/collection-utils";
import HitRecord from "models/view-models/search/hit-record";
import PublicationRecord from "models/view-models/publication-record";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { OfflineSearchIndexStatus } from "models/enumerations/offline/offline-search-index-status";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import useOfflineSearchIndexStatus from "utilities/hooks/aspect/search/offline/use-offline-search-index";
import { chunk } from "lodash";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

enum UseOfflineSearchStatus {
    Initialized,
    SearchTermsChanged,
    Searching,
    Loaded,
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseOfflineSearchState {
    enabled: boolean;
    filteredPublications: PublicationRecord[];
    pagedSearchResults: SearchResultRecord[];
    publicationIds: Array<number>;
    searchResults: SearchResultRecord;
    searchTerm: string;
    status: UseOfflineSearchStatus;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type UseOfflineSearchAction =
    | {
          type: "changeSearchCriteria";
          publicationIds: Array<number>;
          publications: PublicationRecord[];
          searchTerm: string;
      }
    | {
          type: "setIsEnabled";
          enabled: boolean;
      }
    | {
          type: "startSearch";
          sendSearchCommand: (searchTerm: string) => void;
      }
    | {
          type: "setSearchResults";
          hitRecords: HitRecord[];
          take: number | undefined;
      };

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useOfflineSearch(options: UseSearchHookOptions) {
    const { params } = options;
    const { entityTypes = [], publicationIds = [], start, take } = params ?? {};

    const { resultObject: publications } = usePublications();
    const { favorites } = usePublicationFavorites();
    const { favoritePublications } = useGroupByFavoritePublications(
        publications,
        favorites
    );

    const { sendCommand } = useServiceWorker();
    const sendCommandRef = useRef(sendCommand);

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.SearchResults,
        (event) => {
            const { hits } = event.data ?? {};
            const hitRecords = hits.map((h) =>
                new HitRecord(h).withPublication(filteredPublications)
            );

            dispatch({ type: "setSearchResults", take: take, hitRecords });
        }
    );

    const [
        { filteredPublications, searchResults, status, pagedSearchResults },
        dispatch,
    ] = useReducer(useOfflineSearchReducer, undefined, () =>
        initializeReducer(options)
    );

    const pagedResults = useMemo(() => {
        const { found = 0 } = searchResults;
        if (found < 1 || start == null || take == null) {
            return searchResults;
        }

        return pagedSearchResults[start / take];
    }, [searchResults, pagedSearchResults, start, take]);

    const indexStatus = useOfflineSearchIndexStatus();
    useEffect(
        function checkOfflineSearchIndexStatus() {
            if (
                [
                    PublicationEntityTypeConstants.Situation,
                    PublicationEntityTypeConstants.Solution,
                ].some((et) => entityTypes.includes(et))
            ) {
                return;
            }

            dispatch({
                type: "setIsEnabled",
                enabled: indexStatus === OfflineSearchIndexStatus.INDEXED,
            });
        },
        [entityTypes, indexStatus]
    );

    useEffect(
        function whenSearchCriteriaChanges() {
            if (status === UseOfflineSearchStatus.Searching) {
                return;
            }

            dispatch({
                type: "changeSearchCriteria",
                publicationIds,
                publications: favoritePublications,
                searchTerm: options?.params?.search ?? "",
            });
        },
        [favoritePublications, options?.params?.search, publicationIds, status]
    );

    const loadSearchResults = useCallback(async () => {
        if (status !== UseOfflineSearchStatus.SearchTermsChanged) {
            return;
        }

        const sendSearchCommand = (searchTerm: string) => {
            sendCommandRef.current({
                type: ServiceWorkerCommandTypes.Search,
                searchTerm,
                publicationIds,
            });
        };

        dispatch({ type: "startSearch", sendSearchCommand });
    }, [status, publicationIds]);

    useEffect(() => {
        loadSearchResults();
    }, [loadSearchResults]);

    return {
        loading:
            status === UseOfflineSearchStatus.Searching ||
            indexStatus === OfflineSearchIndexStatus.INDEXING,
        searchResults: pagedResults,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Reducer Functions
// -----------------------------------------------------------------------------------------

function changeSearchCriteria(
    state: UseOfflineSearchState,
    action: UseOfflineSearchAction
): UseOfflineSearchState {
    if (action.type !== "changeSearchCriteria" || !state.enabled) {
        return state;
    }

    const { publications, publicationIds, searchTerm } = action;
    if (state.status === UseOfflineSearchStatus.SearchTermsChanged) {
        return state;
    }

    const filteredPublications = publications.filter(
        (publication) =>
            CollectionUtils.isEmpty(publicationIds) ||
            publicationIds.some((id) => id === publication.id)
    );

    if (
        searchTerm === state.searchTerm &&
        CollectionUtils.equalsBy(
            (x) => x.id,
            state.filteredPublications,
            filteredPublications
        )
    ) {
        return state;
    }

    return {
        ...state,
        filteredPublications,
        searchTerm,
        status: UseOfflineSearchStatus.SearchTermsChanged,
    };
}

function setIsEnabled(
    state: UseOfflineSearchState,
    action: UseOfflineSearchAction
): UseOfflineSearchState {
    if (action.type !== "setIsEnabled") {
        return state;
    }

    return {
        ...state,
        enabled: action.enabled,
    };
}

function startSearch(
    state: UseOfflineSearchState,
    action: UseOfflineSearchAction
): UseOfflineSearchState {
    if (
        action.type !== "startSearch" ||
        state.status !== UseOfflineSearchStatus.SearchTermsChanged ||
        !state.enabled
    ) {
        return state;
    }

    action?.sendSearchCommand(state.searchTerm);

    return { ...state, status: UseOfflineSearchStatus.Searching };
}

function setSearchResults(
    state: UseOfflineSearchState,
    action: UseOfflineSearchAction
): UseOfflineSearchState {
    if (action.type !== "setSearchResults") {
        return state;
    }

    if (state.status !== UseOfflineSearchStatus.Searching) {
        return state;
    }

    const { hitRecords } = action;

    const total = hitRecords.length ?? 0;
    const results = state.searchResults.with({
        hits: hitRecords,
        found: total,
    });
    let pagedSearchResults = [results];
    if (action.take != null) {
        const pages = chunk(hitRecords, action.take);
        pagedSearchResults = pages.map(
            (p) => new SearchResultRecord({ hits: p, found: total })
        );
    }
    return {
        ...state,
        pagedSearchResults: pagedSearchResults,
        status: UseOfflineSearchStatus.Loaded,
        searchResults: results,
    };
}

function initializeReducer(
    options: UseSearchHookOptions
): UseOfflineSearchState {
    const { params, provider = UseSearchHookProvider.Offline } = options;
    const { entityTypes, publicationIds = [] } = params ?? {};

    const enabled =
        provider === UseSearchHookProvider.Offline &&
        ![
            PublicationEntityTypeConstants.Situation,
            PublicationEntityTypeConstants.Solution,
        ].some((et) => entityTypes?.includes(et));

    return {
        enabled: enabled,
        filteredPublications: [],
        pagedSearchResults: [],
        publicationIds,
        status: UseOfflineSearchStatus.Initialized,
        searchResults: new SearchResultRecord({
            hits: [],
            found: 0,
        }),
        searchTerm: params?.search ?? "",
    };
}

const reducerFunctions = {
    changeSearchCriteria: changeSearchCriteria,
    setIsEnabled: setIsEnabled,
    startSearch: startSearch,
    setSearchResults: setSearchResults,
};

function useOfflineSearchReducer(
    state: UseOfflineSearchState,
    action: UseOfflineSearchAction
): UseOfflineSearchState {
    if (action.type in reducerFunctions) {
        return reducerFunctions[action.type](state, action);
    }

    return state;
}

// #endregion Reducer Functions
