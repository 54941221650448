import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import GroupRecord from "models/view-models/group-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface GroupBasePathParams {}

export interface GroupResourcePathParams extends GroupBasePathParams {
    id: number;
}

export interface GroupSyncQueryParams {
    forceSync?: boolean;
}

/**
 * Search parameters when listing Group resources.
 * These get translated to QueryString parameters
 */
export interface GroupListQueryParams {
    userId?: number;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "groups";
const resourceType = GroupRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const syncEndpoint = `${resourceEndpoint}/sync`;
const key = "groups";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const GroupService = {
    key,
    /**
     * Create a new Group
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Retrieve a specific Group resource
     */
    get: ServiceFactory.get<GroupRecord, GroupResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Group resources based upon supplied search criteria
     */
    list: ServiceFactory.list<GroupRecord, GroupListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Sync a Group
     */
    sync: ServiceFactory.update<
        GroupRecord,
        GroupBasePathParams,
        GroupSyncQueryParams
    >(resourceType, syncEndpoint),

    /**
     * Update a Group
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<GroupRecord, GroupResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<
        GroupRecord,
        GroupResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<GroupRecord, GroupListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useListQuery: QueryHookFactory.useListQuery<
        GroupRecord,
        GroupListQueryParams
    >(key, resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useSync: ServiceHookFactory.useUpdate<
        GroupRecord,
        GroupResourcePathParams,
        GroupSyncQueryParams
    >(resourceType, syncEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        GroupRecord,
        GroupResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default GroupService;

// #region Exports
