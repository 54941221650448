import TableRecord from "models/view-models/table-record";
import { useEffect, useCallback } from "react";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import TableService from "utilities/services/publications/annexes/tables/table-service";

/**
 * Custom hook for getting Tables based on an Annex
 *
 * @param {string | number} annexId
 * @returns
 */
export default function useAnnexTables(
    annexId?: string | number,
    isAdmin: boolean = false
): ServiceHookResult<Array<TableRecord>> {
    annexId = NumberUtils.parseInt(annexId);

    const { list: listTablesApi } = TableService.useList();
    const { list: adminPreviewList } = TableService.usePreviewList();
    const getTables = isAdmin ? adminPreviewList : listTablesApi;
    const { result, setInitial, setError, setLoading, setSuccess } =
        useServiceHookResultState<Array<TableRecord>>(new Array<TableRecord>());

    const loadTables = useCallback(
        async (annexId) => {
            if (NumberUtils.isDefault(annexId)) {
                setInitial();
                return;
            }

            setLoading();

            try {
                const { resultObjects: tablesList } = await getTables({
                    annexId,
                });

                setSuccess(tablesList);
            } catch (error) {
                setError(error);
            }
        },
        [getTables, setError, setInitial, setLoading, setSuccess]
    );

    const refresh = useCallback(
        () => loadTables(annexId),
        [annexId, loadTables]
    );

    useEffect(() => {
        loadTables(annexId);
    }, [annexId, loadTables]);

    return { ...result, refresh };
}
