import Tooltip, { FollowCursorPlacement } from "molecules/tooltips/tooltip";
import React, { useRef } from "react";
import useTextOverflow from "utilities/hooks/use-text-overflow";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RelatedSolutionDescriptionProps {
    children: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const TOOLTIP_DELAY_IN_MS = 500;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RelatedSolutionDescription: React.FC<RelatedSolutionDescriptionProps> = (
    props: RelatedSolutionDescriptionProps
) => {
    const { children } = props;
    const labelRef = useRef<HTMLLabelElement>(null);
    const isOverflowed = useTextOverflow(labelRef);

    return (
        <Tooltip
            content={children}
            delayInMs={TOOLTIP_DELAY_IN_MS}
            disabled={!isOverflowed}
            followCursor={FollowCursorPlacement.Initial}>
            <label ref={labelRef}>{children}</label>
        </Tooltip>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RelatedSolutionDescription;

// #endregion Exports
