import { Dispatch, Reducer, useReducer } from "react";
import { CollectionUtils } from "utilities/collection-utils";

/**
 * Utility hook to create a stateful array value
 * that is always sorted alphabetically by one of
 * the item's properties, retrieved by {selector}
 * @param initialValue the initial value of the array
 * @param selector a function returning a string value for each item to sort by
 */
export default function useSortAlphabeticallyReducer<T>(
    initialValue: Array<T>,
    selector: (value: T) => string
): [Array<T>, Dispatch<Array<T>>] {
    const [values, setValues] = useReducer<Reducer<Array<T>, Array<T>>>(
        (currentValues: Array<T>, newValues: Array<T>) =>
            CollectionUtils.sortByString(newValues, selector),
        initialValue
    );

    return [values, setValues];
}
