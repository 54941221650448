import { PartRecord } from "internal";
import PartsList from "organisms/parts-list/parts-list";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import StringUtils from "utilities/string-utils";
import TableOfContentsCollapsePanelLink from "organisms/table-of-contents/table-of-contents-collapse-panel-link";
import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import { SectionParams } from "interfaces/routing/route-params";
import { useParams } from "react-router-dom";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useSectionScrollSpyHandlers from "utilities/hooks/scroll-spy/use-section-scroll-spy-handlers";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TableOfContentsSectionProps {
    isPublic?: boolean;
    chapterNfpaLabel?: string;
    parts?: Array<PartRecord>;
    sections: SectionTableOfContentsRecord[];
    /**
     * Flag to drive whether the component uses 'hash' links (such as #ID000700000036) to link to
     * the SectionDetail element on the page, or a direct link to the SectionPage route
     *
     * @default true
     */
    useHashLinks?: boolean;
    parentType: SectionParentType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableOfContentsSection: React.FunctionComponent<TableOfContentsSectionProps> =
    (props: TableOfContentsSectionProps) => {
        const className = "c-table-of-contents-section";
        const {
            isPublic = false,
            parts,
            sections,
            chapterNfpaLabel,
            parentType,
        } = props;
        const { useHashLinks } = props ?? true;
        const { isAdminPreview } = useAdminPreview();
        const sectionParams = useParams<SectionParams>();

        // Allows for expandable ToC and non-expandable ToC views to function
        const params = {
            ...sectionParams,
            nfpaLabel: chapterNfpaLabel ?? sectionParams.chapterNfpaLabel,
        };

        const { handleClick } = useSectionScrollSpyHandlers();

        // If we have no Parts to group the Sections by, return the 'simple' or ungrouped table of contents
        if (CollectionUtils.isEmpty(parts)) {
            return (
                <nav className={className}>
                    <ul>
                        {renderSectionList(
                            sections,
                            params,
                            parentType,
                            handleClick,
                            useHashLinks,
                            isAdminPreview,
                            isPublic
                        )}
                    </ul>
                </nav>
            );
        }

        const sectionsWithoutPartId = sections.filter((s) => s.partId == null);
        const renderSections = (partId?: number) =>
            renderSectionListWithHashLinks(
                sections,
                params,
                parentType,
                handleClick,
                partId,
                isAdminPreview
            );

        return (
            <nav className={className}>
                {CollectionUtils.hasValues(sectionsWithoutPartId) && (
                    <ul>
                        {renderSectionList(
                            sectionsWithoutPartId,
                            params,
                            parentType,
                            handleClick,
                            useHashLinks,
                            isAdminPreview,
                            isPublic
                        )}
                    </ul>
                )}
                <PartsList
                    parts={parts}
                    renderSections={renderSections}
                    sections={sections}
                    ulClassName={`${className}__part`}
                />
            </nav>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const renderSectionListWithHashLinks = (
    sections: Array<SectionTableOfContentsRecord>,
    params: SectionParams,
    parentType: SectionParentType,
    handleClick: (id: number) => void,
    partId?: number,
    isAdminPreview: boolean = false
): JSX.Element[] => {
    const filteredSections = sections.filter((s) => s.partId === partId);

    return renderSectionList(
        filteredSections,
        params,
        parentType,
        handleClick,
        true,
        isAdminPreview
    );
};
const renderSectionList = (
    sections: Array<SectionTableOfContentsRecord>,
    params: SectionParams,
    parentType: SectionParentType,
    handleClick: (id: number) => void,
    useHashLinks: boolean = true,
    isAdminPreview: boolean = false,
    isPublic: boolean = false
): JSX.Element[] => {
    return sections
        .filter(
            (x) => !x.isDeleted && StringUtils.hasValue(x.getDisplayTitle())
        )
        .sort((a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0))
        .map((section: SectionTableOfContentsRecord) => {
            const link = useHashLinks
                ? section.getHashLink()
                : section.getRoute(params, isAdminPreview, parentType);

            return (
                <TableOfContentsCollapsePanelLink
                    key={section.id}
                    dataTestDisplaySequence={section.displaySequence}
                    dataTestExternalId={section.externalId}
                    dataTestId={"section-link"}
                    hasBookmark={section.hasBookmark}
                    index={section.id}
                    isPublic={isPublic}
                    onClick={() => handleClick(section.id ?? 0)}
                    to={link}
                    useRelativePath={true}>
                    <span>{section.getDisplayTitle()}</span>
                </TableOfContentsCollapsePanelLink>
            );
        });
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TableOfContentsSection;

// #endregion Export
