import ChangeSummaryRecord from "models/view-models/change-summary-record";
import ServiceFactory from "utilities/services/service-factory";

const baseEndpoint = "change-summary";
const resourceType = ChangeSummaryRecord;

interface ChangeSummaryQueryParams {
    externalId: string;
    code: string;
    edition: string;
}

const ChangeSummaryService = {
    list: ServiceFactory.list<ChangeSummaryRecord, ChangeSummaryQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

export default ChangeSummaryService;
