import * as React from "react";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";

export interface CommitteesSpanConverterProps {
    children?: JSX.Element[];
    className?: string;
    datalabel?: string;
}

const CommitteesSpanConverter = (props: CommitteesSpanConverterProps) => {
    switch (props.className) {
        case "nfpa-fm-html_name":
            return (
                <span className="c-committee-member__name">
                    {props.children}
                </span>
            );
        case "nfpa-fm-html_organization":
            return (
                <span className="c-committee-member__organization">
                    {props.children}
                </span>
            );
        case "nfpa-fm-html_classification":
            return (
                <span className="c-committee-member__classification">
                    {props.children}
                </span>
            );
        case "nfpa-fm-html_additionalinfo":
        case "nfpa-fm-html_votingmembername":
            return (
                <Paragraph
                    size={ParagraphSizes.Small}
                    cssClassName="c-committee-member__additionalInfo">
                    {props.children}
                </Paragraph>
            );
        case "nfpa-fm-html_recordingsecretary":
            return <div className="c-committee-member">{props.children}</div>;

        default:
            // There are times where props.children is undefined in paragraph elements.
            if (props.children === undefined) {
                return null;
            }

            return props.children;
    }
};

export default CommitteesSpanConverter;
