import ServiceFactory from "utilities/services/service-factory";
import ChapterRecord from "models/view-models/chapter-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface ChapterResourcePathParams {
    id: number;
}

export interface ChapterListQueryParams {
    /**
     * (Optional) Array of ChapterIds to filter results by.
     */
    chapterIds?: number[];

    /**
     * (Optional) Code of the related Publication to filter results by. Must be sent with edition.
     */
    code?: string;

    /**
     * (Optional) Edition of the related Publication to filter results by. Must be sent with code.
     */
    edition?: string;

    /**
     * (Optional) Filter Chapters by NfpaLabel.
     */
    nfpaLabel?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "chapters";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = ChapterRecord;
const key = "chapters";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const ChapterService = {
    key,
    get: ServiceFactory.get<ChapterRecord, ChapterResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    list: ServiceFactory.list<ChapterRecord, ChapterListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useBatchList: ServiceHookFactory.useBatchedList<
        ChapterRecord,
        ChapterListQueryParams
    >(resourceType, baseEndpoint),

    useGet: ServiceHookFactory.useGet<ChapterRecord, ChapterResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useList: ServiceHookFactory.useList<ChapterRecord, ChapterListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useListQuery: QueryHookFactory.useListQuery<
        ChapterRecord,
        ChapterListQueryParams
    >(key, resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        ChapterRecord,
        ChapterResourcePathParams
    >(key, resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ChapterService;

// #endregion Exports
