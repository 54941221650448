import PublishStatus from "models/enumerations/publish-status";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import { PagedQuery } from "andculturecode-javascript-core";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface AdminEnhancedContentBasePathParams {}

export interface AdminEnhancedContentResourcePathParams
    extends AdminEnhancedContentBasePathParams {
    id: number;
}

/**
 * Search parameters when listing EnhancedContent resources.
 * These get translated to QueryString parameters
 */
export interface AdminEnhancedContentListQueryParams
    extends PagedQuery,
        PublishableFilters {
    code?: string;
    edition?: string;
    hasUnpublishedChanges?: boolean;
    publishStatus?: PublishStatus;
    searchText?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const resourceType = EnhancedContentRecord;
const baseEndpoint = "admin/enhancedcontent";
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AdminEnhancedContentService = {
    /**
     * Retrieves a list of EnhancedContent resources based upon supplied search criteria
     */
    list: ServiceFactory.list<
        EnhancedContentRecord,
        AdminEnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        EnhancedContentRecord,
        AdminEnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        EnhancedContentRecord,
        AdminEnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AdminEnhancedContentService;

// #region Exports
