import { useProgressToast } from "utilities/hooks/use-progress-toast";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";
import { RemoveUrlsProgressMessage } from "utilities/service-worker/interfaces/messages/remove-urls-progress-message";
import StringUtils from "utilities/string-utils";
import { Icons } from "atoms/constants/icons";
import { PreloadUrlsProgressMessage } from "utilities/service-worker/interfaces/messages/preload-urls-progress-message";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import useOfflinePublications from "utilities/hooks/domain/publications/use-offline-publications";

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

const useOfflineProgressToasts = () => {
    const { refresh: refreshOfflinePublicationData } = useOfflinePublications();
    const { start: startRemovalToast, update: updateRemovalToast } =
        useProgressToast({
            icon: Icons.DownloadRemove,
        });

    const {
        done: closePreloadToast,
        start: startPreloadToast,
        update: updatePreloadToast,
    } = useProgressToast({
        icon: Icons.Download,
    });

    const handleProgressMessage = (
        event: TypedMessageEvent<
            PreloadUrlsProgressMessage | RemoveUrlsProgressMessage
        >
    ) => {
        const { code, current, edition, storageExceeded, total, type } =
            event.data;

        const action =
            type === ServiceWorkerMessageTypes.PreloadProgress
                ? t("saving")
                : t("removing");

        const warning =
            type === ServiceWorkerMessageTypes.PreloadProgress
                ? ` - ${t("offline-download-browser-warning")}`
                : "";

        const isPreload = type === ServiceWorkerMessageTypes.PreloadProgress;
        const startToast = isPreload ? startPreloadToast : startRemovalToast;

        const updateToast = isPreload ? updatePreloadToast : updateRemovalToast;

        const codeAndEdition = `NFPA ${code}-${edition}`;

        const message = StringUtils.join(
            [StringUtils.capitalize(action), codeAndEdition, warning],
            " "
        );

        // For the purposes of displaying a progress toast for the preload/removal of this book,
        // the message itself can act as a unique identifier for updates
        const toastId = message;
        if (current === 0) {
            startToast(message, total, toastId);
            refreshOfflinePublicationData?.();
            return;
        }

        if (storageExceeded && isPreload) {
            closePreloadToast(toastId);
            ToastManager.error(
                t("offline-storage-limit", { publication: codeAndEdition }),
                {
                    autoClose: false,
                }
            );
            return;
        }

        updateToast(toastId, current);
    };

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.RemoveUrlsProgress,
        handleProgressMessage
    );

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.PreloadProgress,
        handleProgressMessage
    );
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { useOfflineProgressToasts };

// #endregion Exports
