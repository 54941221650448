import TableRecord from "models/view-models/table-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useEffect, useState } from "react";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import { t } from "utilities/localization-utils";
import TableService from "utilities/services/publications/table-service";
import { ToastManager } from "utilities/toast/toast-manager";

export default function useSelectedTableBookmark() {
    // Services
    const { get: getTableApi } = TableService.useGetByExternalId();

    const [selectedBookmark, setSelectedBookmark] =
        useState<UserBookmarkRecord>();
    const [selectedTable, setSelectedTable] = useState<TableRecord>();
    const [loading, setLoading] = useState<boolean>(false);

    const errorMessage = t("error-loading-bookmarks");

    useEffect(() => {
        setLoading(true);
        setSelectedTable(undefined);
    }, [selectedBookmark]);

    useEffect(() => {
        if (
            selectedBookmark == null ||
            selectedBookmark.userBookmarkType !== UserBookmarkType.Table ||
            selectedTable != null
        ) {
            setLoading(false);
            return;
        }

        async function getTable(
            publicationId: number | undefined,
            externalId: string | undefined,
            code: string | undefined,
            edition: string | undefined
        ) {
            if (
                publicationId == null ||
                externalId == null ||
                code == null ||
                edition == null
            )
                return ToastManager.error(errorMessage);

            setLoading(true);

            try {
                const { resultObject: table } = await getTableApi(
                    {
                        publicationId,
                        externalId,
                    },
                    { code, edition }
                );

                if (table != null) {
                    setSelectedTable(table);
                }
            } catch (error) {
                ToastManager.error(errorMessage);
            }

            setLoading(false);
        }

        getTable(
            selectedBookmark.createdOnPublicationId!,
            selectedBookmark.externalId,
            selectedBookmark.publicationCode,
            selectedBookmark.publicationEdition
        );
    }, [errorMessage, getTableApi, selectedBookmark, selectedTable]);

    return {
        loading,
        selectedBookmark,
        selectedTable,
        setSelectedBookmark,
    };
}
