import { Offer, AggregateOffer } from "schema-dts";
import { EcommercePins } from "constants/ecommerce-pins";
import SubscriptionType from "organisms/enums/subscription-type";
import { RouteUtils } from "utilities/route-utils";
import { useEcommercePrices } from "../use-ecommerce-prices";

const description = {
    FreeTrial:
        "NFPA LiNK® is a customizable digital platform that helps you work faster and more effectively by providing direct access to codes, standards, related content, expert commentary, and visual aids when and where you need it. The introductory free trial allows you to experience digital access to NFPA® codes and standards with dynamic search, notes, sharing, and more. Try all the features today with a hassle-free two-week trial.",
    Individual:
        "With NFPA LiNK® you will have online and offline access to a range of content—from NFPA® codes and standards to expert commentary and visual aids. Now it’s easier to locate, organize, and share knowledge anytime and anywhere.\n\nNFPA LiNK® provides a competitive advantage by allowing you to access the information from anywhere, on any device. Tailor the content to meet your needs by creating favorites, bookmarks, personal notes, and custom collections. Share and navigate code content based on the situation you are facing to quickly find the information you need to keep your project moving forward with speed and accuracy.",
    TeamFive:
        "Give your team the most advanced tool for accessing code-related information fast and efficiently. NFPA LiNK® is a digital platform that unlocks the knowledge of NFPA® codes and standards when, where, and how you need them.\n\nNFPA LiNK® puts everyone on your small team into a single platform where you can curate the content you require, share across projects and groups, and better prepare for your workday. This digital tool helps teams save time and be more effective and competitive by delivering reliable information on demand.\n\nNow it’s easier to locate, organize, and share knowledge anytime and anywhere. Take sharing, collaboration, and productivity to the next level. Put your team on the cutting edge by signing up for NFPA LiNK® today.",
    TeamTen:
        "NFPA LiNK® is a tool that you can bring wherever your work takes you. Log in from a variety of devices and intuitively search for information by real-life scenarios. Whether you are on the job site, in the office, or anywhere in between, your team will have access to the right answers they need at their fingertips.\n\nUser management features let you add up to 10 members to your team and keep individuals connected on an organized digital platform. Create custom collections for you and your team based on project specifications, bookmark frequently referenced sections, make notes for yourself and/or for your team, save, and share—what your content and information look like is up to you. With NFPA LiNK®, you can navigate directly to your and your team's bookmarked content or quickly search for answers based on your current situation.\n\nWork faster and more economically with the ability to cite, reference, and share content on the spot. NFPA LiNK® helps minimize work delays and reduces the costs of noncompliance.",
    Enterprise:
        "The NFPA LiNK® Enterprise Plan puts the power of NFPA knowledge to work for you, with scalable access for companies with more than 11 users. NFPA LiNK® offers flexible pricing and billing options based on your team and your budget. With prices starting at $94.75 per user, that cost decreases the larger your team grows. Personal account management and dedicated support provides you with expert assistance and onboarding.\n\nWith an enterprise plan to NFPA LiNK® your users will have access to NFPA® codes and standards, expert commentary, and visual aids, as well as a tailored digital experience for improved sharing and collaboration.\n\nWith NFPA LiNK®, you can increase organization-wide productivity by bookmarking frequently referenced sections, assigning colors, sharing personal notes, and curating collections of information customized to project specifications.\n\nNFPA LiNK® sets your organization apart from the competition with tools and technology you can depend on. Ensure you are operating on the cutting edge with the game-changing platform that is revolutionizing information delivery and collaboration in the digital age. Inquire about an NFPA LiNK® Enterprise Plan today.",
};

export default function useOffersStructuredData(): Offer[] | AggregateOffer[] {
    const { ecommercePrices } = useEcommercePrices();

    return [
        {
            "@type": "Offer",
            url: `${
                window.location.origin
            }${RouteUtils.getRegistrationPageRoute(
                SubscriptionType.FreeTrial
            )}`,
            description: description.FreeTrial,
            name: "Free Trial",
            price: ecommercePrices?.FreeTrial,
            priceCurrency: "USD",
            validThrough: "P2W",
        },
        {
            "@type": "AggregateOffer",
            url: `${
                window.location.origin
            }${RouteUtils.getRegistrationPageRoute(
                SubscriptionType.Individual,
                EcommercePins.IndividualAnnualAutoRenew
            )}`,
            description: description.Individual,
            disambiguatingDescription: "Individual Subscription",
            highPrice: ecommercePrices?.IndividualAnnual,
            lowPrice: ecommercePrices?.IndividualMonthlyAutoRenew,
            priceCurrency: "USD",
            priceSpecification: [
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.IndividualMonthlyAutoRenew,
                    priceCurrency: "USD",
                    name: "Monthly Auto-Renewal",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "MON",
                    },
                },
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.IndividualAnnualAutoRenew,
                    priceCurrency: "USD",
                    name: "Annual - Auto-renewal",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.IndividualAnnual,
                    priceCurrency: "USD",
                    name: "Annual",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
            ],
        },
        {
            "@type": "AggregateOffer",
            url: `${
                window.location.origin
            }${RouteUtils.getRegistrationPageRoute(
                SubscriptionType.Team,
                EcommercePins.TeamFiveAnnualAutoRenew
            )}`,
            description: description.TeamFive,
            disambiguatingDescription: "Team up to 5 Subscription",
            highPrice: ecommercePrices?.TeamFiveAnnual,
            lowPrice: ecommercePrices?.TeamFiveAnnualAutoRenew,
            priceCurrency: "USD",
            priceSpecification: [
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.TeamFiveAnnualAutoRenew,
                    priceCurrency: "USD",
                    name: "Team with up to 5 members - Auto-renewal",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.TeamFiveAnnual,
                    priceCurrency: "USD",
                    name: "Team with up to 5 members - Annual",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
            ],
        },
        {
            "@type": "AggregateOffer",
            url: `${
                window.location.origin
            }${RouteUtils.getRegistrationPageRoute(
                SubscriptionType.Team,
                EcommercePins.TeamTenAnnualAutoRenew
            )}`,
            description: description.TeamTen,
            disambiguatingDescription: "Team up to 10 Subscription",
            highPrice: ecommercePrices?.TeamTenAnnual,
            lowPrice: ecommercePrices?.TeamTenAnnualAutoRenew,
            priceCurrency: "USD",
            priceSpecification: [
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.TeamTenAnnualAutoRenew,
                    priceCurrency: "USD",
                    name: "Team with up to 10 members - Auto-renewal",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
                {
                    "@type": "UnitPriceSpecification",
                    price: ecommercePrices?.TeamTenAnnual,
                    priceCurrency: "USD",
                    name: "Team with up to 10 members - Annual",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: "1",
                        unitCode: "ANN",
                    },
                },
            ],
        },
        {
            "@type": "Offer",
            url: `${
                window.location.origin
            }${RouteUtils.getRegistrationPageRoute(
                SubscriptionType.Enterprise,
                EcommercePins.EnterpriseAnnualAutoRenew
            )}`,
            description: description.Enterprise,
            name: "Enterprise Subscription",
        },
    ];
}
