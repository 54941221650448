import JobStatuses from "models/enumerations/jobs/job-statuses";
import React from "react";
import { DateTime } from "schema-dts";
import DateUtils from "utilities/date-utils";

export interface PublicationBatchReprocessStatusesTableRowProps {
    workerName: string;
    publication: string;
    status: JobStatuses | undefined;
    createdOn: DateTime | undefined;
}

const PublicationBatchReprocessStatusesTableRow: React.FC<PublicationBatchReprocessStatusesTableRowProps> =
    (props: PublicationBatchReprocessStatusesTableRowProps) => {
        const { workerName, publication, status, createdOn } = props;

        const getStatus = (): string => {
            switch (status) {
                case JobStatuses.Completed:
                    return "Completed";
                case JobStatuses.Errored:
                    return "Errored";
                case JobStatuses.InProgress:
                default:
                    return "In Progress";
            }
        };

        const formatDate = (): string => DateUtils.formatDate(createdOn);

        return (
            <tr>
                <td>{publication}</td>
                <td>{workerName}</td>
                <td>{getStatus()}</td>
                <td>{formatDate()}</td>
            </tr>
        );
    };

export default PublicationBatchReprocessStatusesTableRow;
