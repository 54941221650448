import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SubscriptProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------
const className = "c-code-change -deletion";

const Subscript: React.FunctionComponent<SubscriptProps> = (
    props: SubscriptProps
) => {
    const isDeleted = props.diffchanged === XmlChangeNotationConstants.DELETION;

    return (
        <span className={isDeleted ? className : ""}>
            <sub>{props.children}</sub>
        </span>
    );
};

// #endregion Component

export default Subscript;
