import type SectionRecord from "models/view-models/section-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import SectionService from "utilities/services/publications/annexes/sections/section-service";

/**
 * Custom hook for getting grouped sections within an Annex
 *
 * @export
 * @param {(string | number)} [publicationId]
 * @param {(string | number)} [annexId]
 * @param {string} [groupBy]
 * @returns {ServiceHookResult<SectionRecord[]>}
 */
export default function useAnnexGroupedSections(
    publicationId?: string | number,
    annexId?: string | number,
    groupBy?: string
): ServiceHookResult<SectionRecord[]> {
    const [sections, setSections] = useState<SectionRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { list: listSectionsApi } = SectionService.useList();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const listSections = async (
            publicationId?: number,
            annexId?: number,
            groupBy?: string
        ) => {
            if (publicationId == null || annexId == null) {
                return;
            }

            setLoading(true);

            try {
                const response = await listSectionsApi(
                    {
                        publicationId,
                        annexId,
                    },
                    { groupBy }
                );

                setSections(response.resultObjects ?? []);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        listSections(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(annexId),
            groupBy
        );
    }, [
        annexId,
        groupBy,
        handlePageLoadError,
        listSectionsApi,
        publicationId,
        resetPageErrors,
    ]);

    return {
        errors,
        loaded,
        loading,
        resultObject: sections,
    };
}
