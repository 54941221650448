import Anchor from "atoms/anchors/anchor";
import PublicationRecord from "models/view-models/publication-record";
import React from "react";
import { siteMap } from "internal-sitemap";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import RemoteImage from "atoms/images/remote-image";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { CollectionUtils } from "utilities/collection-utils";
import { useHistory } from "react-router-dom";
import CheckboxFormField from "molecules/form-fields/checkbox-form-field";
import PublishStatusBadgeWithIcon from "organisms/admin/publications/publish-status-badge-with-icon";
import DateUtils from "utilities/date-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublicationAdminSidebarItemProps {
    publication: PublicationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BASE_CLASS_NAME =
    "c-admin-editor-page-layout-v2__content__left__list__item";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationAdminSidebarItem: React.FC<
    PublicationAdminSidebarItemProps
> = (props: PublicationAdminSidebarItemProps) => {
    const classNames = [`${BASE_CLASS_NAME}`, "c-button", "-tertiary-alt"];

    const { publication } = props;

    const history = useHistory();

    const {
        context,
        context: { publicationBatch },
        setContext,
    } = useAdminEditorPageContext();

    if (
        context.currentValue?.id === publication.id ||
        publicationBatch.includes(publication)
    ) {
        classNames.push("-selected");
    }

    const editLink = RouteUtils.getUrl(siteMap.admin.publications.edit, {
        id: publication.id!,
    });

    const coverImage = publication.coverImageFile;

    const displayCodeAndEdition = publication.getDisplayCodeAndEdition();

    const codeAndEdition = StringUtils.hasValue(displayCodeAndEdition)
        ? displayCodeAndEdition
        : "New publication importing...";

    const title = StringUtils.hasValue(publication.title)
        ? publication.title
        : "";

    const batchRoute = RouteUtils.getUrl(siteMap.admin.publications.batch);

    const isBatched = ((): boolean => {
        if (
            CollectionUtils.isEmpty(publicationBatch) ||
            publication?.id == null
        ) {
            return false;
        }

        return publicationBatch.find((e) => e.id === publication.id) != null;
    })();

    const handlePublicationBatch = (): void => {
        if (publication.id == null) {
            return;
        }

        if (isBatched) {
            setContext((prevContext) => {
                const { publicationBatch } = prevContext;

                const batch = publicationBatch?.filter(
                    (e) => e.id !== publication.id
                );

                return prevContext.with({ publicationBatch: batch });
            });

            return;
        }

        setContext((prevContext) => {
            const { publicationBatch } = prevContext;

            if (publication?.id == null) {
                return prevContext.with({ publicationBatch: [] });
            }

            return prevContext.with({
                currentValue: undefined,
                publicationBatch: [...publicationBatch, publication],
            });
        });

        history.push(batchRoute);
    };

    return (
        <div className={`${BASE_CLASS_NAME}`}>
            <div className={`${BASE_CLASS_NAME}__checkbox`}>
                <CheckboxFormField
                    checked={isBatched}
                    label={""}
                    onChange={handlePublicationBatch}
                />
            </div>
            <Anchor
                cssClassName={classNames.join(" ")}
                id={`editor-sidebar-item-${publication.id}`}
                to={editLink}
                key={publication.id}>
                {coverImage && (
                    <div className={`${BASE_CLASS_NAME}__cover`}>
                        <RemoteImage
                            file={coverImage}
                            loaderType={LoaderStyles.LinkGlyphGray}
                        />
                    </div>
                )}
                {!coverImage && (
                    <div className={`${BASE_CLASS_NAME}__no-cover`}></div>
                )}
                <div className={`${BASE_CLASS_NAME}__display`}>
                    <div className={`${BASE_CLASS_NAME}__code`}>
                        <div className="-text">{codeAndEdition}</div>
                        <PublishStatusBadgeWithIcon
                            hasUnpublishedChanges={publication.hasUnpublishedChanges()}
                            status={publication.getPublishStatus()}
                        />
                    </div>
                    <div className={`${BASE_CLASS_NAME}__title`}>{title}</div>
                    <div className={`${BASE_CLASS_NAME}__subtitle`}>
                        {`Created ${DateUtils.formatDate(
                            publication.createdOn
                        )}`}
                    </div>
                </div>
            </Anchor>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationAdminSidebarItem;

// #endregion Exports
