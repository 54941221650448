import { useLocation } from "react-router-dom";
import React, { PropsWithChildren, useState } from "react";
import { t } from "utilities/localization-utils";
import { Icons } from "atoms/constants/icons";
import { v4 } from "uuid";
import Button from "atoms/buttons/button";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SupportFaqPanelProps {
    buttonText: string;
    id?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-faq-panel";
const PANEL_TOP_CLASS = `${BASE_CLASS}__panel-top`;
const ICON_CLASS = `${PANEL_TOP_CLASS}__icon`;
const CONTENT_CLASS = `${BASE_CLASS}__content`;
const CONTAINER_CLASS = `${CONTENT_CLASS}__container`;
const QUESTION_CLASS = `${PANEL_TOP_CLASS}__question`;

const SupportFaqPanel = (props: PropsWithChildren<SupportFaqPanelProps>) => {
    const { buttonText, children, id } = props;
    const location = useLocation();

    const [isCollapsed, setIsCollapsed] = useState(location.hash !== `#${id}`);

    const accessibleText = isCollapsed ? t("expand") : t("collapse");

    const accordionContentId = v4();
    const buttonId = v4();

    const handleToggle = () => setIsCollapsed((prev) => !prev);

    return (
        <div className={BASE_CLASS}>
            <Button
                accessibleText={accessibleText}
                ariaControls={accordionContentId}
                ariaExpanded={!isCollapsed}
                cssClassName={PANEL_TOP_CLASS}
                id={buttonId}
                onClick={handleToggle}
                size={ButtonSizes.XSmall}
                style={ButtonStyles.None}>
                <span className={QUESTION_CLASS}>{buttonText}</span>
                <Icon
                    cssClassName={ICON_CLASS}
                    type={Icons.ChevronDown}
                    size={IconSizes.Base}
                />
                <span
                    className={
                        "sr-only"
                    }>{`${accessibleText} ${buttonText}`}</span>
            </Button>
            <div
                aria-hidden={isCollapsed}
                aria-labelledby={buttonId}
                className={CONTENT_CLASS}
                id={accordionContentId}
                role="region">
                <div className={CONTAINER_CLASS}>{children}</div>
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SupportFaqPanel;

// #endregion Export
