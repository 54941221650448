import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import Heading from "atoms/typography/heading";
import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import React, { PropsWithChildren, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

export enum ExpandableMetaNavLevels {
    Parent,
    Child,
    Grandchild,
}

interface ExpandableMetaNavCollapsePanelProps {
    isCurrentActivePanel: boolean;
    level: ExpandableMetaNavLevels;
    headingText: string;
    headingRoute: string;
    onPanelOpen?: () => void; // Use for articles to hit API on panel open
}
const BASE_CSS_CLASSNAME = "c-expandable-meta-nav-collapse-panel";

const ExpandableMetaNavCollapsePanel: React.FC<
    PropsWithChildren<ExpandableMetaNavCollapsePanelProps>
> = (props: PropsWithChildren<ExpandableMetaNavCollapsePanelProps>) => {
    const {
        isCurrentActivePanel,
        children,
        level,
        headingText,
        headingRoute,
        onPanelOpen,
    } = props;

    const isPanelOpen = useRef(false);

    let headingPriority, levelModifier;
    let iconSize = IconSizes.Medium;

    switch (level) {
        case ExpandableMetaNavLevels.Grandchild:
            levelModifier = "-grandchild";
            headingPriority = HeadingPriority.Four;
            break;
        case ExpandableMetaNavLevels.Child:
            levelModifier = "-child";
            headingPriority = HeadingPriority.Five;
            break;
        case ExpandableMetaNavLevels.Parent:
        default:
            levelModifier = "";
            headingPriority = HeadingPriority.Three;
    }

    const cssClassName = `${BASE_CSS_CLASSNAME} ${levelModifier}`;

    // useEffect and useRef combination so that panels don't unexpected close on route change,
    // but will still open on initial mount of the metanav
    useEffect(() => {
        isPanelOpen.current = isCurrentActivePanel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPanelCollapsed = () => {
        if (isCurrentActivePanel) {
            return false;
        } else {
            return !isPanelOpen.current;
        }
    }

    return (
        <CollapsePanel
            iconSize={iconSize}
            modifierCSSClassName={cssClassName}
            buttonAriaText={headingText}
            collapse={isPanelCollapsed()}
            isCollapseDisabled={isCurrentActivePanel}
            onOpen={onPanelOpen}
            panelTop={
                <Link
                    to={headingRoute}
                    className={`${BASE_CSS_CLASSNAME}__headingLink`}>
                    <Heading
                        cssClassName={`${BASE_CSS_CLASSNAME}__heading`}
                        priority={headingPriority}>
                        {headingText}
                    </Heading>
                </Link>
            }>
            {children}
        </CollapsePanel>
    );
};

export default ExpandableMetaNavCollapsePanel;
