import { useLocalization } from "utilities/hooks/use-localization";
import Anchor from "atoms/anchors/anchor";
import Paragraph from "atoms/typography/paragraph";
import UserCollectionRecord from "models/view-models/user-collection-record";
import React from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import UserService from "utilities/services/users/user-service";
import NumberUtils from "utilities/number-utils";
import UserCollectionListItemHeader from "./user-collection-list-item-header";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionListItemProps {
    userCollectionRecord: UserCollectionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserCollectionListItem: React.FC<UserCollectionListItemProps> = (
    props: UserCollectionListItemProps
) => {
    const BASE_CLASS_NAME = "c-user-collection-list-item";
    const { t } = useLocalization<CultureResources>();
    const { get: getUser } = UserService.useGetQuery();

    const userGetEnabled = !NumberUtils.isDefault(
        props.userCollectionRecord.createdById
    );

    const userGetResult = getUser(
        { id: props.userCollectionRecord.createdById! },
        undefined,
        { enabled: userGetEnabled }
    );
    const user = userGetResult.data?.resultObject;
    const loadingUser = userGetResult.isLoading;

    const createdByText =
        user == null ? "" : t("byUser", { user: user.getFirstAndLastName() });

    return (
        <Anchor
            to={props.userCollectionRecord.getRoute()}
            cssClassName={BASE_CLASS_NAME}>
            <UserCollectionListItemHeader
                userCollection={props.userCollectionRecord}
            />
            <div className={`${BASE_CLASS_NAME}__content`}>
                <Paragraph>{props.userCollectionRecord.name}</Paragraph>
                {(props.userCollectionRecord.isGroupCollection() ||
                    props.userCollectionRecord.isWorkGroupCollection()) && (
                    <label>
                        {loadingUser && "..."}
                        {!loadingUser && createdByText}
                    </label>
                )}
            </div>
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionListItem;

// #endregion Exports
