import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import RadioButton from "atoms/forms/radio-button-input";
import Paragraph from "atoms/typography/paragraph";
import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import RadioList, {
    RadioMarkerPosition,
} from "molecules/radio-lists/radio-list";
import { CountedColorSelectOption } from "organisms/my-link/bookmark-color-filter-select";
import React, { useEffect, useState } from "react";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import NumberUtils from "utilities/number-utils";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { ColorSelectType } from "organisms/my-link/my-bookmarks/color-select";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyBookmarksSortAndFilterModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    onConfirm: (
        selectedColors: Array<UserBookmarkColors>,
        sortBy: UserBookmarkSortOption
    ) => void;
    onReset: () => void;
    options: Array<CountedColorSelectOption>;
    selectedColors: Array<UserBookmarkColors>;
    sortBy: UserBookmarkSortOption;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-my-bookmarks-sort-and-filter-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyBookmarksSortAndFilterModal: React.FC<MyBookmarksSortAndFilterModalProps> =
    (props: MyBookmarksSortAndFilterModalProps) => {
        const [selectedColors, setSelectedColors] = useState(
            props.selectedColors
        );
        const [sortBy, setSortBy] = useState(props.sortBy);
        const { t } = useLocalization<CultureResources>();

        useEffect(() => {
            // when modal is closed without confirming, reset selection
            if (!props.isVisible) {
                setSortBy(props.sortBy);
                setSelectedColors(props.selectedColors);
            }
        }, [props.isVisible, props.sortBy, props.selectedColors]);

        const handleColorsSelected = (
            selectedValues: Array<CountedColorSelectOption>
        ) => {
            const newColors: Array<UserBookmarkColors> = [];
            selectedValues.forEach((option: CountedColorSelectOption) => {
                const colorId = NumberUtils.parseInt(option.value);
                if (colorId != null) {
                    newColors.push(colorId);
                }
            });
            setSelectedColors(newColors);
        };

        const handleResetClick = () => {
            props.onReset();
            props.closeDialog();
        };

        const handleConfirmClick = () => {
            props.onConfirm(selectedColors, sortBy);
            props.closeDialog();
        };

        const sortAndFilter = `${t("sort")} ${t("and")} ${t("filter")}`;

        return (
            <Modal
                closeButtonStyle={ModalCloseButtonStyle.Hidden}
                cssClassName={CSS_CLASS_NAME}
                label={"Sort and Filter"}
                type={ModalTypes.Bottom}
                transition={ModalTransitions.SlideUp}
                {...props}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Paragraph>{sortAndFilter}</Paragraph>
                    <Button
                        onClick={handleConfirmClick}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Secondary}>
                        Close
                    </Button>
                </div>
                <div className={`${CSS_CLASS_NAME}__body`}>
                    <div className={`${CSS_CLASS_NAME}__body__filter-group`}>
                        <label>{t("sortBy")}</label>
                        <RadioList
                            items={[
                                <RadioButton
                                    checked={
                                        sortBy ===
                                        UserBookmarkSortOption.CreatedOnDescending
                                    }
                                    id={`radio-sort-${UserBookmarkSortOption.CreatedOnDescending}`}
                                    key={
                                        UserBookmarkSortOption.CreatedOnDescending
                                    }
                                    label={t("sortOptionNewest")}
                                    name={"created-on-descending"}
                                    onClick={() =>
                                        setSortBy(
                                            UserBookmarkSortOption.CreatedOnDescending
                                        )
                                    }
                                />,
                                <RadioButton
                                    checked={
                                        sortBy ===
                                        UserBookmarkSortOption.CreatedOnAscending
                                    }
                                    id={`radio-sort-${UserBookmarkSortOption.CreatedOnAscending}`}
                                    key={
                                        UserBookmarkSortOption.CreatedOnAscending
                                    }
                                    label={t("sortOptionOldest")}
                                    name={"created-on-ascending"}
                                    onClick={() =>
                                        setSortBy(
                                            UserBookmarkSortOption.CreatedOnAscending
                                        )
                                    }
                                />,
                            ]}
                            markerPosition={RadioMarkerPosition.Left}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__body__filter-group`}>
                        <label>{t("filterBy", { item: t("color") })}</label>
                        <CheckboxList<number, number>
                            onChange={handleColorsSelected}
                            options={props.options}
                            renderOption={ColorOptionRenderer}
                            values={selectedColors}
                        />
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    <Button
                        onClick={handleResetClick}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Tertiary}>
                        {t("reset")}
                    </Button>
                    <Button
                        onClick={handleConfirmClick}
                        size={ButtonSizes.Small}>
                        {t("actionItem", {
                            action: t("show"),
                            item: t("result_plural"),
                        })}
                    </Button>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const ColorOptionRenderer = (option: CountedColorSelectOption) => (
    <div className={`${CSS_CLASS_NAME}__body__filter-group__colors`}>
        <div
            className={`${CSS_CLASS_NAME}__body__filter-group__colors__color ${CustomColorUtils.getClassModifier(
                UserBookmarkColors,
                option.value,
                ColorSelectType.Bookmark
            )}`}
        />
        <span
            className={`${CSS_CLASS_NAME}__body__filter-group__colors__label`}>
            {option.label}
        </span>
    </div>
);

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MyBookmarksSortAndFilterModal;

// #endregion Exports
