import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FreeAccessPublicationAdminBreadcrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">Free Access Publications</span>
    </Breadcrumb>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FreeAccessPublicationAdminBreadcrumb;

// #endregion Exports
