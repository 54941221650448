import { createContext } from "react";
import { NetworkEffectiveType } from "utilities/enumerations/network-effective-type";
import { NetworkType } from "utilities/enumerations/network-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface NetworkInformation {
    downlink?: number;
    downlinkMax?: number;
    effectiveType?: NetworkEffectiveType;
    saveData?: boolean;
    type?: NetworkType;
}

export interface NetworkState extends NetworkInformation {
    isOnline: boolean;
    offlineAt?: string;
}

// #endregion Interfaces

export const NetworkInformationContext = createContext<NetworkState>({
    isOnline: true,
});
