export const PublicationEntityTypeConstants = {
    Annex: "Annex",
    Article: "Article",
    Chapter: "Chapter",
    Part: "Part",
    Section: "Section",
    Situation: "Situation",
    Solution: "Solution",
    EnhancedContent: "EnhancedContent",
};

export const CodesEntityTypes = [
    PublicationEntityTypeConstants.Chapter,
    PublicationEntityTypeConstants.Annex,
    PublicationEntityTypeConstants.Article,
    PublicationEntityTypeConstants.Section,
];

export const DirectEntityTypes = [
    PublicationEntityTypeConstants.Situation,
    PublicationEntityTypeConstants.Solution,
];

export const EnhancedContentEntityTypes = [
    PublicationEntityTypeConstants.EnhancedContent,
];
