import * as React from "react";

import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";

export interface OriginsH1ConverterProps {
    children?: JSX.Element[];
    className?: string;
    datalabel?: string;
}

const OriginsH1Converter = (props: OriginsH1ConverterProps) => {
    // There are times where props.children is undefined
    if (props.children === undefined) {
        return null;
    }

    return <Heading priority={HeadingPriority.Two}>{props.children}</Heading>;
};

export default OriginsH1Converter;
