import { useLocalization } from "utilities/hooks/use-localization";
import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import UserBookmarkConstants from "constants/user-bookmark-constants";
import UserBookmark from "models/interfaces/user-bookmark";
import { UserBookmarkValidator } from "models/validation/user-bookmark-validator";
import PublicationRecord from "models/view-models/publication-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import BookmarkCollectionMultiSelect from "organisms/my-link/my-bookmarks/bookmark-collection-multi-select";
import ColorSelect, {
    ColorSelectType,
} from "organisms/my-link/my-bookmarks/color-select";
import React, { useEffect, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import useBookmarkCollections from "utilities/hooks/domain/my-link/use-bookmark-collections";
import useUpdateBookmark from "utilities/hooks/domain/my-link/use-update-bookmark";
import useUpdateBookmarkCollections from "utilities/hooks/domain/my-link/use-update-bookmark-collections";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useLoading from "utilities/hooks/use-loading";
import useModalTransition from "utilities/hooks/use-modal-transition";
import CultureResources from "utilities/interfaces/culture-resources";
import { ToastManager } from "utilities/toast/toast-manager";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import RichTextEditorDeprecated from "molecules/rich-text/rich-text-editor-deprecated";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UpdateBookmarkModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    bookmark: UserBookmarkRecord;
    onBookmarkUpdated: (bookmark: UserBookmarkRecord) => void;
    publication?: PublicationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-update-bookmark-modal";
const UPDATE_BOOKMARK_ERROR = "There was an issue updating this bookmark.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UpdateBookmarkModal: React.FC<UpdateBookmarkModalProps> = (
    props: UpdateBookmarkModalProps
) => {
    const { bookmark: initialBookmark, onBookmarkUpdated, closeDialog } = props;
    const { t } = useLocalization<CultureResources>();

    const [validationMessages, setValidationMessages] = useState<
        ObjectValidationResult<UserBookmark>
    >({});

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const isValid = (bookmark: UserBookmarkRecord): boolean => {
        const validationResult = new UserBookmarkValidator().validate(
            bookmark,
            UserBookmarkConstants.MAX_LENGTH
        );
        setValidationMessages(validationResult);

        return !ObjectValidator.hasErrors(validationResult);
    };

    const [bookmark, setBookmark] = useState(
        new UserBookmarkRecord(initialBookmark.toJS())
    );
    useEffect(() => setBookmark(initialBookmark), [initialBookmark]);

    const {
        collectionBookmarks,
        collections,
        setCollections,
        allCollections,
        loading: loadingCollections,
    } = useBookmarkCollections(bookmark.id);

    const { updateUserCollectionBookmarks, saving: savingCollections } =
        useUpdateBookmarkCollections(collectionBookmarks);

    const { updateBookmark, loading: savingBookmark } = useUpdateBookmark();

    const loading = useLoading(
        savingBookmark,
        loadingCollections,
        savingCollections
    );

    const updateBookmarkAndCollections = async () => {
        if (!isValid(bookmark)) {
            return;
        }

        const [updateBookmarkSuccess, updateCollectionsSuccess] =
            await Promise.all([
                updateBookmark(bookmark),
                updateUserCollectionBookmarks(
                    bookmark.id!,
                    collections.map((c: UserCollectionRecord) => c.id!)
                ),
            ]);
        if (!updateBookmarkSuccess || !updateCollectionsSuccess) {
            ToastManager.error(UPDATE_BOOKMARK_ERROR);
            return;
        }

        ToastManager.success("Changes have been successfully saved.");
        onBookmarkUpdated(bookmark);
        closeDialog();
    };

    const handleDescriptionChanged = (
        newValue: string,
        rawValue: string
    ): void => {
        setBookmark((prevState: UserBookmarkRecord) =>
            prevState.with({
                description: newValue,
                descriptionAsPlainText: rawValue,
            })
        );
    };

    const handleUserBookmarkColorChanged = (newValue: number): void => {
        setBookmark((userBookmark: UserBookmarkRecord) =>
            userBookmark.with({
                color: newValue,
            })
        );
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        await updateBookmarkAndCollections();
    };

    const transition = useModalTransition();

    const bookmarkText = t("bookmark");
    const modalHeading = t("editItem", { item: bookmarkText });
    const loaderText = t("saving", { item: bookmarkText });
    const notesFieldLabel = t("field-notes");
    const optionalLabel = t("field-optional");
    const notesFieldPlaceholder = t("addNoteHere");
    const cancel = t("cancel");
    const save = t("save");

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.Hidden}
            cssClassName={CSS_CLASS_NAME}
            label={modalHeading}
            transition={transition}
            type={ModalTypes.Base}
            {...props}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Icon type={Icons.Bookmark} />
                <Heading priority={HeadingPriority.Two}>{modalHeading}</Heading>
                <ModalCloseButton onClick={closeDialog} />
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__body__form-area`}>
                    {loading && <Loader accessibleText={loaderText} />}
                    {!loading && (
                        <React.Fragment>
                            <div
                                className={`${CSS_CLASS_NAME}__body__form-area__section-info`}>
                                {initialBookmark != null && (
                                    <Paragraph>
                                        {initialBookmark.getFullyQualifiedDisplayTitle()}
                                    </Paragraph>
                                )}
                                {props.publication != null && (
                                    <label>
                                        {props.publication.getDisplayCodeEditionAndTitle()}
                                    </label>
                                )}
                            </div>
                            <Form onSubmit={handleFormSubmit}>
                                <ColorSelect
                                    enumObject={UserBookmarkColors}
                                    onChange={(newValue: number) =>
                                        handleUserBookmarkColorChanged(newValue)
                                    }
                                    type={ColorSelectType.Bookmark}
                                    value={bookmark.color}
                                />
                                <RichTextEditorDeprecated
                                    errors={validationMessages.description}
                                    label={
                                        <React.Fragment>
                                            {notesFieldLabel}
                                            <span
                                                className={`${CSS_CLASS_NAME}__body__form-area__description-optional-label`}>
                                                {optionalLabel}
                                            </span>
                                        </React.Fragment>
                                    }
                                    onChange={handleDescriptionChanged}
                                    value={bookmark.description}
                                    maxLength={UserBookmarkConstants.MAX_LENGTH}
                                    placeholder={notesFieldPlaceholder}
                                    toolbarConfig={
                                        UserBookmarkConstants.RICH_TEXT_TOOLBAR_CONFIG
                                    }
                                />
                                <BookmarkCollectionMultiSelect
                                    allCollections={allCollections}
                                    onChange={setCollections}
                                    value={collections}
                                    menuPosition={
                                        isMobile ? "absolute" : "fixed"
                                    }
                                />
                            </Form>
                        </React.Fragment>
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__body__footer`}>
                    <Button
                        disabled={loading}
                        style={ButtonStyles.Secondary}
                        onClick={closeDialog}>
                        {cancel}
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={updateBookmarkAndCollections}>
                        {save}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UpdateBookmarkModal;

// #endregion Exports
