import * as React from "react";

export interface SectionLabelProps {
    children?: any;
}

const SectionLabel: React.FunctionComponent<SectionLabelProps> = (
    props: SectionLabelProps
) => {
    const cssClassName = "c-section-label";

    return <span className={cssClassName}>{props.children}</span>;
};

export default SectionLabel;
