import SelectSizes from "atoms/constants/select-sizes";
import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import { KeyboardConstants } from "constants/keyboard-constants";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {
    SelectComponentsConfig,
    ValueContainerProps,
    components,
    ValueType,
    OptionsType,
} from "react-select";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import uuid from "uuid";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization-utils";
import CheckboxInput from "atoms/forms/checkbox-input";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import _ from "lodash";
import useOutsideClick from "utilities/hooks/use-outside-click";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MultiCheckboxSelectProps<
    TData = any,
    TValue extends string | number = string
> {
    cssClassName?: string;
    customOptionRenderer?: (
        option: SelectOption<TData, TValue>,
        isSelected: boolean
    ) => React.ReactElement;
    customValueContainer?: (
        props: ValueContainerProps<SelectOption<TData, TValue>>
    ) => React.ReactElement;
    filterType?: keyof CultureResources;
    onChange: (newValues: Array<SelectOption<TData, TValue>>) => void;
    onMenuClosed?: () => void;
    options: Array<SelectOption<TData, TValue>>;
    placeholder?: string;
    size?: SelectSizes;
    values: Array<TValue>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BASE_CSS_CLASS_NAME = "c-multi-checkbox-select";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MultiCheckboxSelect = <
    TData extends unknown = any,
    TValue extends string | number = string
>(
    props: MultiCheckboxSelectProps<TData, TValue>
) => {
    const {
        cssClassName,
        customOptionRenderer,
        customValueContainer,
        filterType = "option",
        onChange,
        onMenuClosed,
        options,
        size = SelectSizes.Small,
        values,
    } = props;

    /**
     * We need to manually control the menu open state because
     * of a bug with react-select when using a custom component
     * for react-select's ValueContainer
     * @see https://stackoverflow.com/questions/61467417/how-to-change-valuecontainer-of-react-select-so-that-minifying-does-not-introduc
     * @see https://github.com/JedWatson/react-select/issues/2597
     */
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const menuContainerRef = useRef<HTMLDivElement>(null);
    const [multiSelectId] = useState(`multi-checkbox-select-${uuid.v4()}`);

    // since we're manually controlling menu state, sometimes the --is-focused class
    // from react-select doesn't quite sync properly.
    const [forceUnfocused, setForceUnfocused] = useState(false);

    const customComponents = useMemo<
        SelectComponentsConfig<SelectOption<TData, TValue>>
    >(
        () => ({
            ValueContainer:
                customValueContainer ?? defaultValueContainer(filterType),
        }),
        [customValueContainer, filterType]
    );

    const dropdownPortal = menuContainerRef.current ?? undefined;

    const placeholder = useMemo(() => {
        if (StringUtils.hasValue(props.placeholder)) {
            return props.placeholder;
        }

        return _.startCase(
            t("filterBy", {
                item: t(filterType, { count: 2 }),
            })
        );
    }, [filterType, props.placeholder]);

    const renderOption = customOptionRenderer ?? defaultOptionRenderer;

    useEffect(() => {
        if (menuIsOpen) {
            setForceUnfocused(false);
            return;
        }

        onMenuClosed?.();
    }, [menuIsOpen, onMenuClosed]);

    const isTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    useOutsideClick(containerRef, () => {
        setMenuIsOpen(false);
        setForceUnfocused(true);
    });

    const handleContainerClick = (
        e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
        setForceUnfocused(false);

        const selectElement = document.getElementById(multiSelectId);
        const literalClickedElement = e.target;

        if (selectElement == null || literalClickedElement == null) {
            return;
        }

        if (selectElement.contains(literalClickedElement as Node)) {
            setMenuIsOpen(!menuIsOpen);
        }

        e.stopPropagation();
        e.preventDefault();
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KeyboardConstants.Escape) {
            setMenuIsOpen(false);
        }

        if (
            (e.key === KeyboardConstants.Enter ||
                e.key === KeyboardConstants.Space) &&
            !menuIsOpen
        ) {
            setMenuIsOpen(true);
        }
    };

    const handleBlur = () => {
        if (isTablet) {
            // this event bubbles when it shouldn't on iPad
            return;
        }

        setForceUnfocused(true);
        setMenuIsOpen(false);
    };

    return (
        <div
            className={`${BASE_CSS_CLASS_NAME} ${
                forceUnfocused ? "-unfocused" : ""
            } ${cssClassName}`}
            onBlur={handleBlur}
            onClick={handleContainerClick}
            onTouchStart={handleContainerClick} // iOS/iPadOS sometimes prevents click events from bubbling on certain elements
            onKeyDown={handleKeydown}
            ref={containerRef}>
            <MultiSelect
                closeMenuOnSelect={false}
                cssClassName={`${BASE_CSS_CLASS_NAME}__multi-select`}
                customComponents={customComponents}
                dropdownPortal={dropdownPortal}
                hideSelectedOptions={false}
                id={multiSelectId}
                menuIsOpen={menuIsOpen}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                renderOption={renderOption}
                searchable={false}
                size={size}
                value={values}
            />
            <div
                className={`${BASE_CSS_CLASS_NAME}__menu-container`}
                ref={menuContainerRef}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const defaultOptionRenderer = <
    TData extends unknown = any,
    TValue extends string | number = string
>(
    option: SelectOption<TData, TValue>,
    isSelected: boolean
) => (
    <CheckboxInput
        checked={isSelected}
        label={renderLabel(option)}
        onChange={() => {}}
        tabindex={-1}
    />
);

const defaultValueContainer =
    (filterType: keyof CultureResources) =>
    <TData extends unknown = any, TValue extends string | number = string>(
        props: ValueContainerProps<SelectOption<TData, TValue>>
    ) => {
        const selectedOptions = props.selectProps.value;

        if (!isOptionsType(selectedOptions)) {
            return (
                <components.ValueContainer {...props}>
                    {props.children}
                </components.ValueContainer>
            );
        }

        return (
            <components.ValueContainer {...props}>
                <span
                    className={`${BASE_CSS_CLASS_NAME}__menu-container__value__multiple`}>
                    {t("filteredByWithCount", {
                        count: selectedOptions.length,
                        filter: t(filterType, {
                            count: selectedOptions.length,
                        }),
                    })}
                </span>
            </components.ValueContainer>
        );
    };

const isOptionsType = <TData, TValue extends string | number>(
    selectedOptions: ValueType<SelectOption<TData, TValue>>
): selectedOptions is OptionsType<SelectOption<TData, TValue>> => {
    return (
        selectedOptions != null &&
        Array.isArray(selectedOptions) &&
        CollectionUtils.hasValues(selectedOptions)
    );
};

const renderLabel = <
    TData extends unknown = any,
    TValue extends string | number = string
>(
    option: SelectOption<TData, TValue>
) => (
    <div className={`${BASE_CSS_CLASS_NAME}__menu-container__option-label`}>
        {option.label}
    </div>
);

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MultiCheckboxSelect;

// #endregion Exports
