import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-section-body";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionBody: React.FunctionComponent<XmlConvertedComponentProps> = (
    props: XmlConvertedComponentProps
) => {
    const { diffchanged } = props;
    let classNames = [CSS_BASE_CLASS];

    const changedModifier = StringUtils.hasValue(diffchanged)
        ? `c-code-change -${diffchanged}`
        : "";

    classNames.push(changedModifier);

    return (
        <div className={StringUtils.joinClassNames(classNames)}>
            {props.children}
        </div>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionBody;

// #endregion Exports
