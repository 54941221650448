import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import { PartRecord } from "internal";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PartBasePathParams {}

export interface PartResourcePathParams extends PartBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Part resources.
 * These get translated to QueryString parameters
 */
export interface PartListQueryParams {
    partIds?: number[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "parts";
const key = "parts";
const resourceType = PartRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

export const PartService = {
    /**
     * Retrieves a list of Part resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        PartRecord,
        PartListQueryParams,
        PartBasePathParams
    >(resourceType, baseEndpoint),

    useBatchList: ServiceHookFactory.useBatchedList<
        PartRecord,
        PartListQueryParams
    >(PartRecord, baseEndpoint),

    /**
     * Custom hook for leveraging PartService list calls in react components
     */
    useList: ServiceHookFactory.useList<PartRecord, PartListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useListQuery: QueryHookFactory.useListQuery<
        PartRecord,
        PartListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service
