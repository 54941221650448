import EmulationTokenRecord from "models/view-models/emulation-token-record";
import React, {
    PropsWithChildren,
    createContext,
    useMemo,
    useState,
} from "react";
import useLocalStorage from "utilities/hooks/use-local-storage";
import { useGlobalState } from "../use-global-state-context";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";

interface EmulationModeContextState {
    token: EmulationTokenRecord | null;
    setToken: (value: EmulationTokenRecord | null) => void;
    userEmail: string;
    loadingEmulationSession: boolean;
    setLoadingEmulationSession: React.Dispatch<React.SetStateAction<boolean>>;
    endingEmulationSession: boolean;
    setEndingEmulationSession: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: EmulationModeContextState = {
    token: null,
    setToken: () => {},
    userEmail: "",
    loadingEmulationSession: false,
    setLoadingEmulationSession: () => {},
    endingEmulationSession: false,
    setEndingEmulationSession: () => {},
};

export const EmulationModeContext = createContext(defaultState);

const EmulationModeContextProvider = (props: PropsWithChildren<{}>) => {
    const [token, setToken] = useLocalStorage<EmulationTokenRecord | null>(
        "token",
        null
    );

    const [loadingEmulationSession, setLoadingEmulationSession] =
        useState(false);
    const [endingEmulationSession, setEndingEmulationSession] = useState(false);

    const { globalState } = useGlobalState();
    const userEmail = globalState.currentIdentity?.user?.email ?? "";

    const value: EmulationModeContextState = {
        token,
        setToken,
        userEmail,
        loadingEmulationSession,
        setLoadingEmulationSession,
        endingEmulationSession,
        setEndingEmulationSession,
    };

    const emulationTransitionText = useMemo(() => {
        return loadingEmulationSession
            ? "Loading emulation session"
            : endingEmulationSession
            ? "Ending emulation session"
            : "";
    }, [endingEmulationSession, loadingEmulationSession]);

    return loadingEmulationSession || endingEmulationSession ? (
        <FullScreenTransition transitionText={emulationTransitionText} />
    ) : (
        <EmulationModeContext.Provider value={value}>
            {props.children}
        </EmulationModeContext.Provider>
    );
};

export default EmulationModeContextProvider;
