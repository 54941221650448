import breadcrumbRoutes from "breadcrumb-routes";
import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs, {
    BreadcrumbData,
    Options,
} from "use-react-router-breadcrumbs";
import { BreadcrumbsContext } from "utilities/contexts/use-breadcrumbs-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BreadcrumbsModalProps {
    onBreadcrumbClick?: () => void;
    options: Options;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BreadcrumbsModal: React.FC<BreadcrumbsModalProps> = (
    props: BreadcrumbsModalProps
) => {
    const { onBreadcrumbClick: onClick, options } = props;
    const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, options);

    const breadcrumbLinks = breadcrumbs
        .slice(0)
        .reverse()
        .map((breadcrumbData: BreadcrumbData, index: number) => {
            const { match, breadcrumb } = breadcrumbData;
            let className = "c-breadcrumb";
            if (index === 0) {
                className = `${className} -disabled`;
            }

            return (
                <li key={match.url}>
                    <Link
                        className={className}
                        onClick={onClick}
                        to={match.url}>
                        {breadcrumb}
                    </Link>
                </li>
            );
        });

    return (
        <BreadcrumbsContext.Provider value={{ inModal: true }}>
            <nav className="c-breadcrumb-modal">
                <ol>{breadcrumbLinks}</ol>
            </nav>
        </BreadcrumbsContext.Provider>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BreadcrumbsModal;

// #endregion Exports
