import { useLocation } from "react-router-dom";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useUpdateAtom } from "jotai/utils";
import { OfflineBookViewAnalyticsAtom } from "utilities/atoms/offline-book-view-analytics-atom";
import { useEffect } from "react";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const OFFLINE_CHECK_INTERVAL_SECONDS = 1;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineBookviewAnalytics: React.FC = () => {
    const { pathname: path } = useLocation();
    const { isOnline } = useNetworkInformation();
    const setOfflineBookViewAnalytics = useUpdateAtom(
        OfflineBookViewAnalyticsAtom
    );

    useEffect(() => {
        if (isOnline) {
            return;
        }

        const interval = setInterval(() => {
            setOfflineBookViewAnalytics((prev) =>
                prev.addSecondsViewedForPath(
                    path,
                    OFFLINE_CHECK_INTERVAL_SECONDS
                )
            );
        }, 1000 * OFFLINE_CHECK_INTERVAL_SECONDS);

        return function cleanup() {
            clearInterval(interval);
        };
    }, [isOnline, path, setOfflineBookViewAnalytics]);

    return null;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookviewAnalytics;

// #endregion Exports
