import { atom, useAtom } from "jotai";

const metaNavIsVisible = atom<boolean>(false);

const useMetaNav = () => {
    const [isMetaNavVisible, setIsMetaNavVisible] = useAtom(metaNavIsVisible);

    const toggle = () => setIsMetaNavVisible((prev) => !prev);

    const closeMetaNav = () => setIsMetaNavVisible(false);

    return { isMetaNavVisible, toggle, closeMetaNav };
};

export default useMetaNav;
