import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "../service-factory";

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint =
    "publications/:code/:edition/annexes/:annexNfpaLabel/enhanced-content";
const baseAdminEndpoint = "admin/annexes/:annexId/enhancedcontents";

interface EnhancedContentBasePathParams {
    code: string;
    edition: string;
    annexNfpaLabel: string;
}

interface EnhancedContentAdminBasePathParams {
    annexId: string;
}

interface EnhancedContentQueryParams {}

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const EnhancedContentByAnnexService = {
    list: ServiceFactory.nestedList<
        EnhancedContentRecord,
        EnhancedContentBasePathParams,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseEndpoint),
    adminList: ServiceFactory.nestedList<
        EnhancedContentRecord,
        EnhancedContentAdminBasePathParams,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseAdminEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentByAnnexService;

// #endregion Exports
