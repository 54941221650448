import React from "react";
import AlertNotice from "molecules/banners/alert-notice";
import { t } from "utilities/localization-utils";
import AlertLevels from "constants/alert-levels";

interface AuthenticatedOverlayProps {
    onDismiss: () => void;
    visible: boolean;
}

const AuthenticatedOverlay: React.FunctionComponent<AuthenticatedOverlayProps> =
    (props: AuthenticatedOverlayProps) => {
        const title = t("expired-offline-session-warning-title");
        const description = t("expired-offline-session-warning-description");
        if (!props.visible) {
            return null;
        }

        return (
            <div className="c-overlay -authenticated">
                <AlertNotice
                    buttonText={t("continue")}
                    description={description}
                    level={AlertLevels.Warning}
                    onClick={props.onDismiss}
                    title={title}
                />
            </div>
        );
    };

export default AuthenticatedOverlay;
