import { useCallback } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import PublicationService from "utilities/services/publications/publication-service";

interface Options {
    code: string;
    edition: string;
}

export default function useCheckPublication() {
    const { list } = PublicationService.useList();

    const doesPublicationExist = useCallback(
        async (options: Options) => {
            const { code, edition } = options;

            if (code == null || edition == null) return false;

            try {
                const result = await list({ code, edition });

                if (CollectionUtils.hasValues(result.resultObjects))
                    return true;
            } catch {
                return false;
            }

            return false;
        },
        [list]
    );

    return {
        doesPublicationExist,
    };
}
