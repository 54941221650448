import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { siteMap } from "internal-sitemap";
import FileUploadField from "molecules/form-fields/file-upload-field";
import React from "react";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import MimeTypes from "utilities/enumerations/mime-type";
import { ToastManager } from "utilities/toast/toast-manager";
import useAdminFreeAccessPublicationCreate from "./use-free-access-publication-create";
import PdfPublicationImportForm from "organisms/admin/publications/pdf-publication-import-form";

const CSS_CLASS_NAME = "c-publication-create";
export interface FreeAccessPublicationCreateProps {
    closeDialog: () => void;
}
const FreeAccessPublicationCreate = (
    props: FreeAccessPublicationCreateProps
) => {
    const { closeDialog } = props;

    const {
        contentSourceFile,
        handlePublicationCreate,
        pdfPublication,
        setPdfPublication,
        handlePublicationFileChange,
    } = useAdminFreeAccessPublicationCreate();

    const jobsTableRoute = siteMap.admin.freeAccessPublications.jobs;

    const handleCreate = async () => {
        const isSuccessful = await handlePublicationCreate();
        if (isSuccessful) {
            ToastManager.success(
                <p>
                    Successfully uploaded.
                    <br />
                    Import process in progress.
                    <br />
                    For details see:
                    <br />
                    <Anchor to={jobsTableRoute}>Free Access Jobs Table</Anchor>
                </p>
            );
            closeDialog();
        } else {
            ToastManager.error("Error creating publication");
        }
    };

    const isImportDisabled =
        contentSourceFile?.id == null ||
        pdfPublication.title == null ||
        pdfPublication.title === "";

    const showPublicationForm = contentSourceFile?.id != null;

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__form`}>
                <FileUploadField
                    accept={MimeTypes.Pdf}
                    confirmRemove={true}
                    disabled={false}
                    fileUploadDestination={FileUploadDestination.Publication}
                    helpText="Free Access file must be a .pdf. Once an import is completed, it is automatically published."
                    label="Add Free Access Publication Data"
                    onFileChanged={handlePublicationFileChange}
                    uploadPathPrefix={"publications"}
                    value={contentSourceFile}
                />
                {showPublicationForm && (
                    <PdfPublicationImportForm
                        publication={pdfPublication}
                        setPublication={setPdfPublication}
                    />
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button disabled={isImportDisabled} onClick={handleCreate}>
                    Import Publication
                </Button>
            </div>
        </div>
    );
};

export default FreeAccessPublicationCreate;
