import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface AdminEnhancedContentExternalIdsQueryParams {
    isPublished?: boolean;
}

export interface AdminEnhancedContentExternalIdsPathParams {
    code: string;
    edition: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "admin/enhancedcontent/:code/:edition/externalids";
const key = "admin-enhancedcontent-externalids";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AdminEnhancedContentExternalIdsService = {
    /**
     * Retrieves a list of external ids based upon publication code and edtion
     */
    list: ServiceFactory.nestedList<
        EnhancedContentRecord,
        AdminEnhancedContentExternalIdsPathParams,
        AdminEnhancedContentExternalIdsQueryParams
    >(EnhancedContentRecord, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        EnhancedContentRecord,
        AdminEnhancedContentExternalIdsPathParams,
        AdminEnhancedContentExternalIdsQueryParams
    >(EnhancedContentRecord, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        EnhancedContentRecord,
        AdminEnhancedContentExternalIdsPathParams,
        AdminEnhancedContentExternalIdsQueryParams
    >(key, EnhancedContentRecord, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AdminEnhancedContentExternalIdsService;

// #region Exports
