import { Icons } from "atoms/constants/icons";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import RoleType from "utilities/enumerations/role-type";

const useUserCollectionInfo = (userCollection: UserCollectionRecord) => {
    if (userCollection == null) userCollection = new UserCollectionRecord();
    const { globalState } = useGlobalState();

    const isEnterprise = useMemo(
        () => globalState.currentIdentity?.isCurrentRole(RoleType.ENTERPRISE),
        [globalState]
    );

    const collectionShareType = useMemo(() => {
        return userCollection?.getCollectionShareType();
    }, [userCollection]);

    const setUserPageLabel = useCallback(() => {
        if (collectionShareType !== UserCollectionShareType.User) {
            setPageLabel("-team");
        } else {
            setPageLabel("");
        }
    }, [collectionShareType]);

    const setUserCollectionIconType = useCallback(() => {
        if (collectionShareType === UserCollectionShareType.Team) {
            setCollectionIconType(isEnterprise ? Icons.Enterprise : Icons.Team);
        }
        if (collectionShareType === UserCollectionShareType.WorkGroup) {
            setCollectionIconType(Icons.Team);
        }
        if (collectionShareType === UserCollectionShareType.User) {
            setCollectionIconType(Icons.Folder);
        }
    }, [collectionShareType, isEnterprise]);

    const [pageLabel, setPageLabel] = useState("");
    const [collectionIconType, setCollectionIconType] = useState<Icons>(
        Icons.Folder
    );

    useEffect(() => {
        setUserPageLabel();
        setUserCollectionIconType();
    }, [setUserCollectionIconType, setUserPageLabel, userCollection]);

    return { pageLabel, collectionIconType };
};

export default useUserCollectionInfo;
