import { Record } from "immutable";
import type UserCollection from "models/interfaces/user-Collection";
import GroupRecord from "models/view-models/group-record";
import UserRecord from "models/view-models/user-record";
import { siteMap } from "internal-sitemap";
import { RecordUtils } from "andculturecode-javascript-core";
import { RouteUtils } from "utilities/route-utils";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";

const defaultValues: UserCollection =
    RecordUtils.auditableDefaultValuesFactory<UserCollection>({
        group: undefined,
        groupId: undefined,
        shareType: UserCollectionShareType.User,
        name: "",
        user: undefined,
        userBookmarkCount: undefined,
        userId: 0,
        workGroups: [],
    });

export default class UserCollectionRecord
    extends Record(defaultValues)
    implements UserCollection
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserCollection) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.group != null) {
            params.group = RecordUtils.ensureRecord(params.group, GroupRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Return the location of the current user collection
     *
     * @returns string
     */
    public getRoute(): string {
        return RouteUtils.getUrl(siteMap.myLink.collection, { id: this.id });
    }

    /**
     * Calculate whether a User Collection is shared among Group members
     *
     * @returns boolean
     */
    public isGroupCollection(): boolean {
        return (
            this.groupId != null &&
            this.shareType === UserCollectionShareType.Team
        );
    }

    public isWorkGroupCollection(): boolean {
        return (
            this.groupId != null &&
            this.shareType === UserCollectionShareType.WorkGroup
        );
    }

    public getCollectionShareType(): UserCollectionShareType {
        if (this.isGroupCollection()) {
            return UserCollectionShareType.Team;
        }
        if (this.isWorkGroupCollection()) {
            return UserCollectionShareType.WorkGroup;
        }

        return UserCollectionShareType.User;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserCollection>} values
     */
    public with(values: Partial<UserCollection>): UserCollectionRecord {
        return new UserCollectionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
