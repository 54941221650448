import { useEffect, useRef } from "react";

/**
 * Utility hook to determine whether or not a component is currently mounted in the DOM.
 */
export default function useMounted() {
    const isMounted = useRef<boolean>(false);
    useEffect(function onMount() {
        isMounted.current = true;

        return function unMount() {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
}
