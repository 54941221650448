import React, { useRef, useCallback, useLayoutEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-profile-menu__menu__user-info";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ProfileMenuTeamNameProps {
    teamName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export default function ProfileMenuTeamName(props: ProfileMenuTeamNameProps) {
    const roleDisplayDiv = useRef<HTMLDivElement>(null);

    const { globalState } = useGlobalState();

    const teamName =
        props.teamName ??
        globalState.currentIdentity?.getCurrentRoleDisplayName();

    const isRoleDisplayNameDivOverflowed = useCallback((): boolean => {
        const displayDiv = roleDisplayDiv.current;

        if (displayDiv == null) {
            return false;
        }

        return (
            displayDiv.offsetWidth < displayDiv.scrollWidth ||
            displayDiv.offsetHeight < displayDiv.scrollHeight
        );
    }, [roleDisplayDiv]);

    useLayoutEffect(
        function truncateMultiLineTeamName() {
            const displayDiv = roleDisplayDiv.current;

            if (
                displayDiv == null ||
                StringUtils.isEmpty(displayDiv.innerText)
            ) {
                return;
            }

            while (isRoleDisplayNameDivOverflowed()) {
                const prev = displayDiv.innerText;

                displayDiv.innerText =
                    prev.slice(0, prev.length - 4).trimEnd() + "...";
            }
        },
        [isRoleDisplayNameDivOverflowed, teamName]
    );

    return (
        <div
            aria-label={teamName}
            className={`${BASE_CLASS_NAME}__role-name`}
            ref={roleDisplayDiv}>
            {teamName}
        </div>
    );
}

// #endregion Component
