import * as React from "react";
import PublicationRecord from "models/view-models/publication-record";
import PublicationSelectionListGroupItem from "organisms/modals/publication-selection-modal/publication-selection-list-group-item";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import FileRecord from "models/view-models/file-record";
import { PublicationSelectionListProps } from "organisms/modals/publication-selection-modal/publication-selection-list";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface PublicationSelectionListGroupProps
    extends Pick<PublicationSelectionListProps, "displayMenuActions"> {
    coverImageFiles: FileRecord[];
    cssClassName: string;
    favorites: UserPublicationFavoriteRecord[];
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
    publications: PublicationRecord[];
    title: string;
    onResultClick?: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationSelectionListGroup = (
    props: PublicationSelectionListGroupProps
) => {
    const {
        coverImageFiles,
        displayMenuActions = true,
        cssClassName,
        favorites,
        onChangeFavorites,
        publications,
        title,
        onResultClick = () => {},
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div className={cssClassName}>
            <span>{title}</span>
            <div className={`${cssClassName}__editions`}>
                {publications.map((publication) => {
                    const coverImage = coverImageFiles.find(
                        (f) => f.id === publication.coverImageFileId
                    )!;

                    return (
                        <PublicationSelectionListGroupItem
                            coverImageFile={coverImage}
                            displayMenuActions={displayMenuActions}
                            favorites={favorites}
                            key={publication.id}
                            onChangeFavorites={onChangeFavorites}
                            publication={publication}
                            onResultClick={onResultClick}
                        />
                    );
                })}
            </div>
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationSelectionListGroup;

// #endregion Exports
