import { useCallback, useMemo, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook to handle deleting a user collection.
 * @param id
 */
export default function useDeleteUserCollection(id?: number) {
    const { globalState } = useGlobalState();
    const userId = useMemo(
        () => globalState.currentIdentity?.userId(),
        [globalState]
    );
    const [loading, setLoading] = useState(false);
    const { delete: deleteCollectionApi } = UserCollectionService.useDelete();

    const deleteUserCollection = useCallback(async () => {
        if (id == null || userId == null) {
            ToastManager.error("There was an issue deleting the collection.");
            return;
        }

        setLoading(true);

        try {
            await deleteCollectionApi(id, { id, userId });
        } catch (e) {
            ToastManager.error("There was an issue deleting the collection.");
        }

        setLoading(false);
    }, [id, userId, deleteCollectionApi]);

    return {
        deleteUserCollection,
        loading,
    };
}
