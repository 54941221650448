import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineBooksBasePathParams {
    offlineDeviceId: number;
}

export interface OfflineBooksResourcePathParams
    extends OfflineBooksBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Part resources.
 * These get translated to QueryString parameters
 */
export interface OfflineBooksQueryParams {
    bookId?: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "offlinedevices/:offlineDeviceId/books";
const resourceType = OfflineBookRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "offlinedevices-books";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const OfflineBooksService = {
    useGetQuery: QueryHookFactory.useGetQuery<
        OfflineBookRecord,
        OfflineBooksResourcePathParams
    >(key, resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useNestedList<
        OfflineBookRecord,
        OfflineBooksBasePathParams,
        OfflineBooksQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        OfflineBookRecord,
        OfflineBooksBasePathParams,
        OfflineBooksQueryParams
    >(key, resourceType, baseEndpoint),

    create: ServiceFactory.nestedCreate<
        OfflineBookRecord,
        OfflineBooksBasePathParams
    >(resourceType, baseEndpoint),

    useCreate: ServiceHookFactory.useNestedCreate<
        OfflineBookRecord,
        OfflineBooksBasePathParams
    >(resourceType, baseEndpoint),

    delete: ServiceFactory.delete<OfflineBooksBasePathParams>(resourceEndpoint),

    useDelete:
        ServiceHookFactory.useDelete<OfflineBooksResourcePathParams>(
            resourceEndpoint
        ),

    useUpdate: ServiceHookFactory.useUpdate<
        OfflineBookRecord,
        OfflineBooksResourcePathParams,
        OfflineBooksQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBooksService;

// #endregion Exports
