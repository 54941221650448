import { CollectionUtils } from "utilities/collection-utils";
import PublicationSidebarNavAnchor from "atoms/anchors/publication-sidebar-nav-anchor";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import React, { Fragment, useMemo } from "react";
import { match } from "react-router-dom";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import LinkResources from "molecules/linkResources/link-resources";
import useLinkResourcesChapterProvider from "molecules/linkResources/link-resources-chapter-provider";
import LinkResourceRecord from "molecules/linkResources/link-resource-record";
import { LinkResourceStyle } from "molecules/linkResources/link-resource-styles";
import useMetaNav from "utilities/atoms/metanav-visible-atom";
import PublicationTypes from "constants/publication-types";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import ChapterTableOfContentsRecord from "models/view-models/table-of-contents/chapter-table-of-contents-record";
import { ArticleParams, SectionParams } from "interfaces/routing/route-params";
import NavItem from "molecules/sidebar-chapter-navigation/nav-item";
import { SectionParentType } from "utilities/enumerations/section-parent-type";

interface ChapterMetaNavProps {
    match: match<NestedPublicationParams & NfpaLabelParams>;
}

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ChapterMetaNav: React.FunctionComponent<ChapterMetaNavProps> = (
    props: ChapterMetaNavProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const { match } = props;

    const { code, edition, nfpaLabel, publicationId, id } = match.params;

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization<CultureResources>();
    const { toggle } = useMetaNav();

    const {
        resultObject: publication,
        loading: publicationLoading,
        errors: publicationErrors,
    } = usePublication({
        code,
        edition,
        publicationId,
    });

    const { tableOfContents, loading: TOCLoading } =
        useBookviewTableOfContents();
    const { chapters } = tableOfContents;

    const chapter = useMemo(
        () =>
            chapters?.find(
                (c) =>
                    c.nfpaLabel === nfpaLabel ||
                    (id != null && c.id === parseInt(id))
            ) ?? new ChapterTableOfContentsRecord(),
        [chapters, id, nfpaLabel]
    );

    /**
     * NEC style publications (i.e. NFPA 70) are Chapter -> Articles -> Sections
     * NFC style publications are Chapter -> Sections
     * However, NFPA 70 chapter 9 is Chapter -> Sections
     */
    const isChapter9 =
        chapter.label === "Chapter 9" || chapter.nfpaLabel === "9";
    const useArticles =
        publication.type === PublicationTypes.NEC && !isChapter9;

    const { articles, sections } = chapter;

    const articleNavItems = useMemo(() => {
        if (!useArticles) return null;

        const articleParams: ArticleParams = {
            ...match.params,
            chapterId: chapter.id?.toString(),
            chapterNfpaLabel: chapter.nfpaLabel ?? "",
        };

        return articles?.map((article) => (
            <NavItem
                key={article.externalId}
                hasBookmark={article.hasBookmark}
                externalId={article.externalId}
                id={article.id}
                label={article.getLabel(publication.locale)}
                route={article.getRoute(
                    articleParams,
                    nfpaLabel,
                    isAdminPreview
                )}
                shouldScroll={false}
                title={article.getDisplayTitle(publication.locale)}
            />
        ));
    }, [
        articles,
        chapter.id,
        chapter.nfpaLabel,
        isAdminPreview,
        match.params,
        nfpaLabel,
        publication.locale,
        useArticles,
    ]);

    const sectionNavItems = useMemo(() => {
        if (useArticles) return null;

        const sectionParams: SectionParams = {
            ...match.params,
            chapterId: chapter.id?.toString(),
            chapterNfpaLabel: chapter.nfpaLabel ?? nfpaLabel,
            externalId: "",
        };

        return sections
            ?.filter((section) => !section.isDeleted)
            ?.map((section) => (
                <NavItem
                    key={section.externalId}
                    hasBookmark={section.hasBookmark}
                    externalId={section.externalId}
                    id={section.id}
                    label={section.getLabel()}
                    route={section.getRoute(
                        sectionParams,
                        isAdminPreview,
                        SectionParentType.Chapter
                    )}
                    shouldScroll={_shouldSearchForLinkOnPage(publication.type)}
                    title={section.getTitleWithoutLabel()}
                />
            ));
    }, [
        chapter.id,
        chapter.nfpaLabel,
        isAdminPreview,
        match.params,
        nfpaLabel,
        publication.type,
        sections,
        useArticles,
    ]);

    const loading = useLoading(publicationLoading, TOCLoading);

    const errors = useErrors(publicationErrors);

    useErrorBoundary(errors);

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Computations & Transformations
    // -----------------------------------------------------------------------------------------

    const LinkResourcesProvider = useLinkResourcesChapterProvider();
    let linkResources: LinkResourceRecord[] = [];

    if (isAdminPreview) {
        linkResources = LinkResourcesProvider.getChapterPreviewLinkResources(
            chapter?.id ?? 0,
            chapter.nfpaLabel ?? "",
            publication?.code ?? "",
            publication?.id ?? 0,
            chapter?.hasTables ?? false
        );
    } else {
        linkResources = LinkResourcesProvider.getChapterLinkResources(
            chapter.nfpaLabel ?? "",
            publication?.code ?? "",
            publication?.edition ?? "",
            chapter?.nfpaLabel ?? "",
            chapter?.hasTables ?? false
        );
    }

    const anchorTo = publication.getRoute(isAdminPreview);
    const text = t("tableOfContents");

    // #endregion Computations & Transformations

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <Fragment>
            <PublicationSidebarNavAnchor
                accessibleText={text}
                linkText={text}
                to={anchorTo}
            />
            {chapter != null && !loading && (
                <PublicationSidebarNav
                    title={chapter.getDisplayTitle(publication.locale)}>
                    {useArticles ? articleNavItems : sectionNavItems}

                    {CollectionUtils.hasValues(linkResources) && (
                        <LinkResources
                            linkResourceRecords={linkResources}
                            linkResourceStyles={LinkResourceStyle.MetaNav}
                            onNavItemClick={toggle}
                        />
                    )}
                </PublicationSidebarNav>
            )}
            {loading && <Loader type={LoaderStyles.LinkGlyphGray} />}
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList listItems={errors} />
            )}
        </Fragment>
    );

    // #endregion Returns
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

const _shouldSearchForLinkOnPage = (
    publicationType?: PublicationTypes
): boolean => {
    if (publicationType === PublicationTypes.NEC) {
        return false;
    }
    return true;
};

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ChapterMetaNav;

// #endregion Exports
