import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface EnhancedContentBasePathParams {}

export interface EnhancedContentResourcePathParams
    extends EnhancedContentBasePathParams {
    id: number;
}

/**
 * Search parameters when listing EnhancedContent resources.
 * These get translated to QueryString parameters
 */
export interface EnhancedContentListQueryParams {
    code: string;
    edition: string;
    externalIds?: string[];
    rootEntityId?: number;
    rootEntityType?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "sections/enhancedcontent";
const resourceType = EnhancedContentRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const EnhancedContentService = {
    /**
     * When successful a new EnhancedContent is created and returned.
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Soft-deletes the EnhancedContent
     */
    delete: ServiceFactory.delete(resourceEndpoint),

    /**
     * Retrieve a specific EnhancedContent resource
     */
    get: ServiceFactory.get<
        EnhancedContentRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Retrieves a list of EnhancedContent resources based upon supplied search criteria
     */
    list: ServiceFactory.list<
        EnhancedContentRecord,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Update a specific EnhancedContent resource
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        EnhancedContentRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        EnhancedContentRecord,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service update calls in react components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        EnhancedContentRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentService;

// #region Exports
