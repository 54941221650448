import { atom, useAtom } from "jotai";
import PublicationRecord from "models/view-models/publication-record";

interface CurrentPublicationAtomContext {
    publication: PublicationRecord;
    loaded: boolean;
    errors: string[];
}

const defaultParams = {
    publication: new PublicationRecord(),
    loaded: false,
    errors: [],
};

const CurrentPublicationAtom =
    atom<CurrentPublicationAtomContext>(defaultParams);

const useCurrentPublication = () => {
    const [currentPublication, setCurrentPublication] = useAtom(
        CurrentPublicationAtom
    );
    const { publication, loaded, errors } = currentPublication;
    return { publication, loaded, errors, setCurrentPublication };
};
export default useCurrentPublication;
