import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import RouteTabs from "molecules/route-tabs/route-tabs";
import EnhancedContentAdminControls from "organisms/admin/publications/enhanced-content/enhanced-content-admin-controls";
import EnhancedContentAdminTable from "organisms/admin/publications/enhanced-content/enhanced-content-admin-table";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import { PublicationsAdminUtils } from "pages/admin/publications/publications-admin-utils";
import React, { useEffect, useState } from "react";
import AdminEditorPageContext from "utilities/contexts/admin/use-admin-editor-page-context";
import usePagedResults from "utilities/hooks/use-paged-results";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import useEnhancedContentWithComponentAdmin from "utilities/hooks/domain/admin/enhanced-content/use-enhanced-content-with-component-admin";
import { SkipNavContent } from "@reach/skip-nav";
import useAdminControls from "utilities/hooks/use-admin-controls";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EnhancedContentAdminDashboardProps extends NestedRoutesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-admin-dashboard";
const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];
const DEFAULT_TAKE_SIZE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnhancedContentAdminDashboard: React.FC<any> = (
    props: EnhancedContentAdminDashboardProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const tabs = PublicationsAdminUtils.getTabs();
    const [context, setContext] = useState(new AdminEditorPageContextRecord());
    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const {
        publicationCode,
        publicationEdition,
        publications,
        publicationSelectOptions,
        handleSelectedPublication,
        hasUnpublishedChanges,
        publishStatus,
        searchText,
        setHasUnpublishedChanges,
        setPublishStatusFilter,
        setSearchText,
        debouncedText,
    } = useAdminControls();

    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        resetPagination,
        onSelectPage,
        rowCount,
        setRowCount,
        skip,
        take,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const {
        resultObject: enhancedContentResult,
        loading: enhancedContentLoading,
        refresh: refreshEnhancedContent,
        rowCount: rowCountFromAPI,
    } = useEnhancedContentWithComponentAdmin({
        code: publicationCode,
        edition: publicationEdition,
        hasUnpublishedChanges,
        publishStatus,
        searchText,
        skip,
        take: take,
    });

    const enhancedContentRecords = enhancedContentResult.map((ec) =>
        ec.with({
            publication: publications.find(
                (p) => p.code === ec.code && p.edition === ec.edition
            ),
        })
    );

    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        if (rowCountFromAPI == null) {
            return;
        }

        setRowCount(rowCountFromAPI);
    }, [rowCountFromAPI, setRowCount]);

    useEffect(() => {
        resetPagination();
    }, [
        debouncedText,
        hasUnpublishedChanges,
        publicationCode,
        publicationEdition,
        publishStatus,
        resetPagination,
    ]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <AdminEditorPageContext.Provider value={[context, setContext]}>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <Heading priority={HeadingPriority.Five}>
                            Publications Management
                        </Heading>
                        <RouteTabs routes={props.routes} tabs={tabs} />
                    </div>
                    <div
                        className={`${CSS_CLASS_NAME}__content c-enhanced-content-admin-tab-content`}>
                        <div
                            className={`${CSS_CLASS_NAME}__content__controls__container`}>
                            <EnhancedContentAdminControls
                                onChangeHasUnpublishedChanges={
                                    setHasUnpublishedChanges
                                }
                                onChangePublishStatus={setPublishStatusFilter}
                                options={{
                                    publicationSelectOptions,
                                    publishFilters: {
                                        publishStatus,
                                        hasUnpublishedChanges,
                                    },
                                }}
                                onChangeSelectedPublication={
                                    handleSelectedPublication
                                }
                                refreshEnhancedContent={refreshEnhancedContent!}
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__content__data`}>
                            <div
                                className={`${CSS_CLASS_NAME}__content__data__table`}>
                                <EnhancedContentAdminTable
                                    loading={enhancedContentLoading}
                                    enhancedContentRecords={
                                        enhancedContentRecords
                                    }
                                    refreshRecords={refreshEnhancedContent!}
                                />
                            </div>
                            <PageNavigationMenu
                                currentPage={currentPage}
                                numberOfPages={numberOfPages}
                                onNavigateBack={onPageLast}
                                onNavigateForward={onPageNext}
                                onPageSizeChange={onPageSizeChange}
                                onSelectPage={onSelectPage}
                                pageSize={take}
                                pageSizes={DEFAULT_PAGE_SIZES}
                                resultTotal={rowCount}
                            />
                        </div>
                    </div>
                </div>
            </SkipNavContent>
        </AdminEditorPageContext.Provider>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

// #endregion Private Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EnhancedContentAdminDashboard;

// #endregion Exports
