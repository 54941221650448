import CollapsePanel from "molecules/collapse-panel/collapse-panel";
import TableOfContentsArticle from "organisms/table-of-contents/table-of-contents-article";
import TableOfContentsSection from "organisms/table-of-contents/table-of-contents-section";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import TableOfContentsCollapsePanelHeader from "organisms/table-of-contents/table-of-contents-collapse-panel-header";
import StringUtils from "utilities/string-utils";
import LinkResources from "molecules/linkResources/link-resources";
import LinkResourceRecord from "molecules/linkResources/link-resource-record";
import useLinkResourcesChapterProvider from "molecules/linkResources/link-resources-chapter-provider";
import ChapterTableOfContentsRecord from "models/view-models/table-of-contents/chapter-table-of-contents-record";
import { useParams } from "react-router-dom";
import { ChapterParams } from "interfaces/routing/route-params";
import { SectionParentType } from "utilities/enumerations/section-parent-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TableOfContentsChapterProps
    extends Pick<DataTestAttributes, "dataTestId"> {
    allCollapsed: boolean;
    chapter: ChapterTableOfContentsRecord;
    locale?: string;
    isPublic?: boolean;
    publicationId: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableOfContentsChapter: React.FunctionComponent<
    TableOfContentsChapterProps
> = (props: TableOfContentsChapterProps) => {
    const {
        allCollapsed,
        chapter,
        isPublic = false,
        locale,
        publicationId,
    } = props;

    const { isAdminPreview } = useAdminPreview();
    const params = useParams<ChapterParams>();
    const { articles, sections } = chapter;
    const displaySections = sections?.filter((s) => !s.isDeleted);

    const cssBaseClass = "c-table-of-contents-chapter";
    const cssEmptyModifier = "-empty";
    const cssPublicModifier = "-public";
    const isEmpty =
        CollectionUtils.isEmpty(articles) &&
        CollectionUtils.isEmpty(displaySections);
    const cssClassName = StringUtils.joinClassNames([
        cssBaseClass,
        isPublic ? cssPublicModifier : "",
        isEmpty ? cssEmptyModifier : "",
    ]);
    const LinkResourcesProvider = useLinkResourcesChapterProvider();
    let linkResources: LinkResourceRecord[] = [];

    if (isAdminPreview) {
        linkResources = LinkResourcesProvider.getChapterPreviewLinkResources(
            chapter.id,
            chapter.label ?? "",
            params.code,
            publicationId,
            chapter?.hasTables
        );
    } else {
        linkResources = LinkResourcesProvider.getChapterLinkResources(
            chapter.nfpaLabel ?? "",
            params.code,
            params.edition,
            chapter.nfpaLabel ?? "",
            chapter?.hasTables ?? false
        );
    }

    return (
        <div className={cssClassName}>
            <CollapsePanel
                data-test-id="chapter-collapse-panel"
                panelTop={
                    <TableOfContentsCollapsePanelHeader
                        dataTestId={props.dataTestId}
                        dataTestDisplaySequence={chapter.displaySequence}
                        displayTitle={chapter.getDisplayTitle(locale)}
                        isPublic={isPublic}
                        to={chapter.getRoute(params, isAdminPreview)}
                    />
                }
                collapse={allCollapsed}
                buttonAriaText={chapter.getDisplayTitle(locale)}>
                {CollectionUtils.hasValues(articles) && (
                    <TableOfContentsArticle
                        articles={articles}
                        chapterNfpaLabel={chapter.nfpaLabel ?? ""}
                        isPublic={isPublic}
                    />
                )}
                {CollectionUtils.isEmpty(articles) &&
                    CollectionUtils.hasValues(displaySections) && (
                        <TableOfContentsSection
                            isPublic={isPublic}
                            chapterNfpaLabel={chapter.nfpaLabel}
                            sections={displaySections}
                            useHashLinks={false}
                            parentType={SectionParentType.Chapter}
                        />
                    )}
                {CollectionUtils.hasValues(linkResources) && (
                    <LinkResources
                        linkResourceRecords={linkResources}
                        isPublic={isPublic}
                    />
                )}
            </CollapsePanel>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TableOfContentsChapter;

// #endregion Export
