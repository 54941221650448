import { Record } from "immutable";
import type SolutionCategory from "models/interfaces/situational-navigation/solutions/solution-category";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SolutionCategory =
    RecordUtils.auditableDefaultValuesFactory<SolutionCategory>({
        categoryId: 0,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        solutionId: 0,
    });

export default class SolutionCategoryRecord
    extends Record(defaultValues)
    implements SolutionCategory
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SolutionCategory) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SolutionCategory>} values
     */
    public with(values: Partial<SolutionCategory>): SolutionCategoryRecord {
        return new SolutionCategoryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
