import Anchor from "atoms/anchors/anchor";
import { SupportConstants } from "constants/phone-number-constants";
import React, { PropsWithChildren } from "react";

interface SupportPhoneLinkProps {
    international?: boolean;
}

const SupportPhoneLink: React.FunctionComponent<
    PropsWithChildren<SupportPhoneLinkProps>
> = (props: PropsWithChildren<SupportPhoneLinkProps>) => {
    if (props.international === true) {
        return (
            <Anchor
                cssClassName="-anchor"
                external
                to={`tel:${SupportConstants.InternationalPhoneNumber}`}>
                {SupportConstants.InternationalPhoneNumber}
            </Anchor>
        );
    }
    return (
        <Anchor
            cssClassName="-anchor"
            external
            to={`tel:${SupportConstants.UsPhoneNumber}`}>
            {SupportConstants.UsPhoneNumber}
        </Anchor>
    );
};

export default SupportPhoneLink;
