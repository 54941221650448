import { Do } from "andculturecode-javascript-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import UserPublicationFavoriteService from "utilities/services/user-publication-favorites/user-publication-favorite-service";
import { ToastManager } from "utilities/toast/toast-manager";
import FavoritePublicationsAtom from "utilities/atoms/favorite-publications-atom";
import { useAtom } from "jotai";

/**
 * Utility hook to load current publication favorites
 */
export default function usePublicationFavorites() {
    const [favorites, setFavorites] = useAtom(FavoritePublicationsAtom);
    const [loading, setLoading] = useState(false);
    const { t } = useLocalization<CultureResources>();
    const { globalState } = useGlobalState();

    const currentUserId = useMemo(
        () => globalState.currentIdentity?.userId(),
        [globalState]
    );

    const currentUserRoleId = useMemo(
        () => globalState.currentIdentity?.getCurrentUserRole()?.id,
        [globalState]
    );

    const { list: listPublicationFavoritesApi } =
        UserPublicationFavoriteService.useList();

    const loadFavorites = useCallback(
        (isInitialLoad: boolean = true) => {
            if (currentUserRoleId == null || currentUserId == null) {
                return;
            }

            Do.try(async () => {
                if (isInitialLoad) {
                    setLoading(true);
                }
                const result = await listPublicationFavoritesApi({
                    userId: currentUserId,
                });
                setFavorites(result.resultObjects);
            })
                .catch(() =>
                    ToastManager.error(
                        t("errors-actionResource", {
                            action: t("loading"),
                            resource: t("favorite_plural"),
                        })
                    )
                )
                .finally(() => setLoading(false));
        },
        [
            currentUserId,
            currentUserRoleId, // reload on profile change
            listPublicationFavoritesApi,
            setFavorites,
            t,
        ]
    );

    useEffect(() => loadFavorites(), [loadFavorites]);

    return {
        favorites,
        setFavorites,
        loading,
        refresh: () => loadFavorites(false),
    };
}
