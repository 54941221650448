import { createContext, Dispatch, SetStateAction, useContext } from "react";
import SignupContextRecord from "models/view-models/signup-context-record";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type SignupContextUpdater = Dispatch<
    SetStateAction<SignupContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: SignupContextRecord = new SignupContextRecord();
const defaultUpdater: SignupContextUpdater = () => {};
const SignupContext = createContext([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useSignupContext() {
    const [signupContext, setSignupContext] = useContext(SignupContext);
    return {
        signupContext: signupContext as SignupContextRecord,
        setSignupContext: setSignupContext as SignupContextUpdater,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SignupContext;

// #endregion Exports
