import { PageNavigationProps } from "molecules/page-navigation/page-navigation";
import { useCallback } from "react";
import UserReferenceRecord from "models/view-models/user-reference-record";
import { List } from "immutable";

export function useReferencePanelPagination(
    currentUserReferenceId: number | undefined,
    handleSelect: (userReferenceId?: number) => Promise<void>,
    userReferences: List<UserReferenceRecord>
): PageNavigationProps {
    const currentIndex = userReferences.findIndex(
        (e) => e.id === currentUserReferenceId
    );

    const numberOfPages = userReferences.count();
    const currentPage = currentIndex + 1;

    const endIndex = numberOfPages - 1;
    const nextIndex = currentIndex < endIndex ? currentIndex + 1 : currentIndex;
    const previousIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;

    const hasNext = nextIndex <= endIndex && currentIndex !== endIndex;
    const hasPrevious = previousIndex >= 0 && currentIndex !== 0;

    const onNavigateBack = useCallback(() => {
        const userReferenceId = userReferences.get(previousIndex)?.id;
        if (userReferenceId == null || !hasPrevious) {
            return;
        }
        handleSelect(userReferenceId);
    }, [handleSelect, hasPrevious, previousIndex, userReferences]);

    const onNavigateForward = useCallback(() => {
        const userReferenceId = userReferences.get(nextIndex)?.id;
        if (userReferenceId == null || !hasNext) {
            return;
        }
        handleSelect(userReferenceId);
    }, [handleSelect, hasNext, nextIndex, userReferences]);

    return {
        currentPage,
        displayNumbers: false,
        disableNext: !hasNext,
        disablePrevious: !hasPrevious,
        numberOfPages,
        onNavigateForward,
        onNavigateBack,
    };
}

export default useReferencePanelPagination;
