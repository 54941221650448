import { Record } from "immutable";
import type EntityLabels from "utilities/interfaces/entity-labels";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: EntityLabels =
    RecordUtils.defaultValuesFactory<EntityLabels>({
        article: "",
        plural: "",
        singular: "",
    });

export default class EntityLabelsRecord
    extends Record(defaultValues)
    implements EntityLabels
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: EntityLabels) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<EntityLabels>} values
     */
    public with(values: Partial<EntityLabels>): EntityLabelsRecord {
        return new EntityLabelsRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
