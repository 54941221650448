import { Icons } from "atoms/constants/icons";
import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import { UseModalActionsHookResult } from "utilities/hooks/use-modal-actions";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-offline-alert-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineAlertModalProps
    extends Pick<UseModalActionsHookResult, "handleClose" | "isOpen"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineAlertModal: React.FC<OfflineAlertModalProps> = (
    props: OfflineAlertModalProps
) => {
    const { isOpen, handleClose } = props;
    return (
        <Modal
            cssClassName={BASE_CLASS}
            closeDialog={handleClose}
            isVisible={isOpen}
            label={t("offline-fallback-title")}
            type={ModalTypes.Base}>
            <AlertNotice
                buttonText={t("close")}
                description={t("offline-fallback-description")}
                icon={Icons.WifiOffline}
                level={AlertLevels.Info}
                onClick={handleClose}
                title={t("offline-fallback-title")}
            />
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineAlertModal;

// #endregion Exports
