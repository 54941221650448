import { SkipNavContent } from "@reach/skip-nav";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Loader from "atoms/loaders/loader";
import { Redirect } from "react-router-dom";
import Heading from "atoms/typography/heading";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import RouteTabs from "molecules/route-tabs/route-tabs";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React, { useEffect, useState } from "react";
import { siteMap } from "internal-sitemap";
import AdminEditorPageContext from "utilities/contexts/admin/use-admin-editor-page-context";
import useLoadResourceStatus from "utilities/hooks/use-load-resource-status";
import { RouteUtils } from "utilities/route-utils";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import AdminCategoryService from "utilities/services/admin/situational-navigation/categories/admin-category-service";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CategoriesPageProps extends NestedRoutesProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-categories-page";
const LOADING_CATEGORIES_ERROR = "There was an issue loading categories.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategoriesPage: React.FC<any> = (props: CategoriesPageProps) => {
    const { generateCultureCodedRoute } = useCultureCodeRoute();
    const { list: listCategoriesApi } = AdminCategoryService.useList();
    const [context, setContext] = useState(new AdminEditorPageContextRecord());
    const { loading, loaded, setLoading, setLoaded } = useLoadResourceStatus(
        false,
        false
    );

    const shouldFocusTabOnRouteChange = (
        oldRoute: string,
        newRoute: string
    ) => {
        // only focus the tab when the actual tab changes, not, for example,
        // when going from /occupancies to /occupancies/create

        const oldType = CategoryUtils.getTypeForRoute(oldRoute);
        const newType = CategoryUtils.getTypeForRoute(newRoute);

        return oldType !== newType;
    };

    useEffect(() => {
        if (loaded || loading) {
            return;
        }

        const loadCategories = async () => {
            setLoading(true);

            try {
                const result = await listCategoriesApi();
                setContext((context) =>
                    context.with({
                        categories: CategoryCollectionRecord.fromArray(
                            result.resultObjects
                        ),
                    })
                );
            } catch (e) {
                ToastManager.error(LOADING_CATEGORIES_ERROR);
            }

            setLoaded(true);
            setLoading(false);
        };

        loadCategories();
    }, [listCategoriesApi, loaded, loading, setLoaded, setLoading]);

    const categoryTypes = [
        CategoryType.Occupancies,
        CategoryType.Systems,
        CategoryType.Spaces,
        CategoryType.Equipment,
    ];

    const tabs = categoryTypes.map((type) => ({
        label: CategoryType[type],
        route: generateCultureCodedRoute(
            CategoryUtils.getDashboardRouteForType(type)
        ),
    }));

    if (
        RouteUtils.assertCurrentUrl(
            siteMap.admin.situationalNavigation.categories.dashboard
        )
    ) {
        return (
            <Redirect
                to={
                    siteMap.admin.situationalNavigation.categories.occupancies
                        .dashboard
                }
            />
        );
    }

    return (
        <AdminEditorPageContext.Provider value={[context, setContext]}>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={`${CSS_CLASS_NAME} c-dashboard__panel`}>
                    <Heading priority={HeadingPriority.Five}>
                        DiRECT Categories
                    </Heading>
                    <section className={`${CSS_CLASS_NAME}__content`}>
                        {(loading || !loaded) && (
                            <Loader accessibleText={"Loading categories..."} />
                        )}
                        {!loading && loaded && (
                            <RouteTabs
                                focusOnRouteChange={shouldFocusTabOnRouteChange}
                                routes={props.routes}
                                tabs={tabs}
                            />
                        )}
                    </section>
                </div>
            </SkipNavContent>
        </AdminEditorPageContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoriesPage;

// #endregion Exports
