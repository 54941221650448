import React from "react";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import FreeAccessPublicationCreate from "./free-access-publication-create";
import BaseModalHeader from "molecules/modals/headers/base-modal-header";

const CSS_CLASS_NAME = "c-add-free-access-publication-modal";
export interface FreeAccessPublicationsAdminAddPublicationModalProps {
    closeDialog: () => void;
}

const FreeAccessPublicationsAdminAddPublicationModal: React.FC<FreeAccessPublicationsAdminAddPublicationModalProps> =
    (props: FreeAccessPublicationsAdminAddPublicationModalProps) => {
        const { closeDialog } = props;
        return (
            <Modal
                closeDialog={closeDialog}
                isVisible={true}
                label={"Add Free Access Publication"}
                type={ModalTypes.Base}>
                <BaseModalHeader
                    heading={"Add a Free Access Publication"}
                    closeDialog={closeDialog}
                />
                <div className={`${CSS_CLASS_NAME}__body`}>
                    <FreeAccessPublicationCreate closeDialog={closeDialog} />
                </div>
            </Modal>
        );
    };

export default FreeAccessPublicationsAdminAddPublicationModal;
