import Button, { ButtonProps } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import UserReferenceRecord from "models/view-models/user-reference-record";
import React, { Ref } from "react";

interface ReferencePanelDropdownMenuItemProps extends ButtonProps {
    userReference: UserReferenceRecord;
    selected: boolean;
}

const BASE_CLASS_NAME = "c-menu-button__item";

const ReferencePanelDropdownMenuItem: React.FunctionComponent<ReferencePanelDropdownMenuItemProps> =
    React.forwardRef(
        (
            props: ReferencePanelDropdownMenuItemProps,
            ref: Ref<HTMLButtonElement>
        ) => {
            const { userReference, onClick, selected } = props;

            const sectionTitle =
                userReference.section?.getFullyQualifiedDisplayTitle();
            const publicationTitle =
                userReference.publication?.getDisplayCodeAndEdition();

            const className = selected ? "-active" : "";

            return (
                <Button
                    cssClassName={className}
                    key={userReference.id}
                    onClick={onClick}
                    ref={ref}
                    style={ButtonStyles.None}>
                    <div className={`${BASE_CLASS_NAME}__section-title`}>
                        {sectionTitle}
                    </div>
                    <div className={`${BASE_CLASS_NAME}__publication-title`}>
                        {publicationTitle}
                    </div>
                </Button>
            );
        }
    );

export default ReferencePanelDropdownMenuItem;
