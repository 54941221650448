import { SectionDividerType } from "models/enumerations/section-divider-type";
import React from "react";
import StringUtils from "utilities/string-utils";

interface SectionSpacerProps {
    type: SectionDividerType;
}

const SectionSpacer: React.FunctionComponent<SectionSpacerProps> = (
    props: SectionSpacerProps
) => {
    const CSS_BASE_CLASS = "c-section-spacer";
    const cssClassNames = [CSS_BASE_CLASS];

    if (props.type === SectionDividerType.None) {
        return null;
    }
    if (props.type === SectionDividerType.Medium) {
        cssClassNames.push("-medium");
    }
    if (props.type === SectionDividerType.Small) {
        cssClassNames.push("-small");
    }

    return (
        <div className={StringUtils.joinClassNames(cssClassNames)}>
            <hr />
        </div>
    );
};

export default SectionSpacer;
