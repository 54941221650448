export enum ButtonStyles {
    Anchor = "-anchor",
    Destructive = "-destructive",
    Primary = "-primary",
    Secondary = "-secondary",
    Tertiary = "-tertiary",
    TertiaryAlt = "-tertiary-alt",
    Text = "-text",
    None = "",
}
