import FileRecord from "models/view-models/file-record";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { siteMap } from "internal-sitemap";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import usePageErrors from "utilities/hooks/use-page-errors";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";
import AdminSituationService from "utilities/services/admin/situational-navigation/situations/admin-situation-service";
import FileService from "utilities/services/file-service";
import { ToastManager } from "utilities/toast/toast-manager";

const SITUATION_CREATED_SUCCESSFUL = "Situation successfully created.";
const SITUATION_DELETE_SUCCESSFUL = "Situation successfully deleted.";
const SITUATION_SAVE_SUCCESSFUL = "Situation successfully saved.";

/**
 * Custom hook wrapper to handle state and api interactions for Situations in the administrative editors
 *
 * @param  {string} id?
 */
export default function useAdminSituation(id?: string) {
    // -----------------------------------------------------------------------------------------
    // #region Context
    // -----------------------------------------------------------------------------------------

    const { context, setContext } = useAdminEditorPageContext();

    // #endregion Context

    // -----------------------------------------------------------------------------------------
    // #region State Hooks
    // -----------------------------------------------------------------------------------------

    const [loadingSituation, setLoadingSituation] = useState(false);
    const [loadingHotSpotImage, setLoadingHotSpotImage] = useState(false);

    const [initialSituation, setInitialSituation] = useState(
        new SituationRecord()
    );
    const [situation, setSituation] = useState(new SituationRecord());
    const [redirectIgnoreIsDirty, setRedirectIgnoreIsDirty] = useState("");
    const [hotSpotImage, setHotSpotImage] = useState<FileRecord>();

    const { handlePageLoadError } = usePageErrors();

    // #endregion State Hooks

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { get: getSituationApi } = AdminSituationService.useGet();
    const { get: getFileApi } = FileService.useGet();
    const { create: createSituationApi } = AdminSituationService.useCreate();
    const { update: updateSituationApi } = AdminSituationService.useUpdate();
    const { delete: deleteSituationApi } = AdminSituationService.useDelete();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    const initializeSituation = useCallback(
        (situation?: SituationRecord, hotSpotImage?: FileRecord) => {
            setInitialSituation(situation ?? new SituationRecord());
            setSituation(situation ?? new SituationRecord());
            setHotSpotImage(hotSpotImage);

            setContext((context: AdminEditorPageContextRecord) =>
                context.with({ currentValue: situation })
            );
        },
        [setContext]
    );

    const createSituation = useCallback(async (): Promise<
        SituationRecord | undefined
    > => {
        setLoadingSituation(true);

        try {
            const result = await createSituationApi(situation);
            ToastManager.success(SITUATION_CREATED_SUCCESSFUL);
            return result.resultObject;
        } catch (e) {
            ToastManager.error("There was an issue creating the situation.");
        }

        setLoadingSituation(false);
    }, [createSituationApi, situation]);

    const updateSituation = useCallback(async () => {
        setLoadingSituation(true);

        try {
            const updateResult = await updateSituationApi(situation, {
                id: situation.id!,
            });

            if (updateResult == null) {
                return;
            }

            initializeSituation(updateResult.resultObject!, hotSpotImage);
            ToastManager.success(SITUATION_SAVE_SUCCESSFUL);
        } catch (e) {
            handlePageLoadError(e);
            ToastManager.error("There was an issue creating the situation.");
        }

        setLoadingSituation(false);
    }, [
        handlePageLoadError,
        hotSpotImage,
        initializeSituation,
        situation,
        updateSituationApi,
    ]);

    const deleteSituation = useCallback(
        async (id: number) => {
            setLoadingSituation(true);
            try {
                const response = await deleteSituationApi(id);

                if (response.result!.hasErrors()) {
                    return;
                }

                // date format doesn't matter here, it's not saved to database; setting it just
                // to tell sidebar to remove it from the list
                setContext(
                    context.with({
                        currentValue: context.currentValue?.with({
                            deletedOn: moment().format(),
                        }),
                    })
                );

                ToastManager.success(SITUATION_DELETE_SUCCESSFUL);
                setRedirectIgnoreIsDirty(
                    RouteUtils.getUrl(
                        siteMap.admin.situationalNavigation.situations.dashboard
                    )
                );
            } catch (e) {
                ToastManager.error("There was an issue deleting situation.");
            }
            setLoadingSituation(false);
        },
        [deleteSituationApi, setContext, context]
    );

    useEffect(() => {
        const situationId = NumberUtils.parseInt(id);
        if (situationId == null) {
            return;
        }

        const loadFile = async (id: number) => {
            setLoadingHotSpotImage(true);
            try {
                const fileResult = await getFileApi({ id });
                setLoadingHotSpotImage(false);
                return fileResult.resultObject;
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading hot spot image."
                );
                setLoadingHotSpotImage(false);
            }
        };

        const loadSituation = async (situationId: number) => {
            setLoadingSituation(true);

            try {
                const result = await getSituationApi({ id: situationId });
                let hotSpotImageFile: FileRecord | undefined = undefined;
                let hotSpotImageFileDraft: FileRecord | undefined = undefined;
                if (result.resultObject?.hotSpotImageFileId != null) {
                    hotSpotImageFile = await loadFile(
                        result.resultObject.hotSpotImageFileId
                    );
                }
                if (result.resultObject?.hotSpotImageFileDraftId != null) {
                    hotSpotImageFileDraft = await loadFile(
                        result.resultObject.hotSpotImageFileDraftId
                    );
                }
                const situationResult = result.resultObject?.with({
                    hotSpotImageFile,
                    hotSpotImageFileDraft,
                });

                initializeSituation(situationResult, hotSpotImageFileDraft);
            } catch (e) {
                ToastManager.error("There was an issue loading the situation.");
            }

            setLoadingSituation(false);
        };

        loadSituation(situationId);
    }, [id, getSituationApi, getFileApi, initializeSituation]);

    // #endregion Public Methods

    return {
        createSituation,
        deleteSituation,
        hotSpotImage,
        initializeSituation,
        initialSituation,
        loadingHotSpotImage,
        loadingSituation,
        redirectIgnoreIsDirty,
        setHotSpotImage,
        setLoadingSituation,
        setRedirectIgnoreIsDirty,
        setSituation,
        situation,
        updateSituation,
    };
}
