import Button from "atoms/buttons/button";
import useAzure from "pages/azure-login/use-azure";
import React from "react";
import { t } from "utilities/localization-utils";
import Tooltip from "molecules/tooltips/tooltip";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";

interface CreateAccountAnchorProps {
    cssClassName?: string;
    disabled?: boolean;
    redirectStartPage?: string;
}

const CreateAccountAnchor = (props: CreateAccountAnchorProps) => {
    const { signUp } = useAzure();
    const { cssClassName = "c-button -anchor" } = props;
    const { subscriptionFreezeActive, alertMessageContents } =
        useConfigurableAlertMessages();

    const isDisabled = props.disabled || subscriptionFreezeActive;

    return (
        <Tooltip
            visible={subscriptionFreezeActive}
            content={alertMessageContents?.message}
            disabled={!subscriptionFreezeActive}>
            <Button
                onClick={() => signUp(props.redirectStartPage)}
                cssClassName={cssClassName}
                disabled={isDisabled}>
                {t("createAnAccount")}
            </Button>
        </Tooltip>
    );
};

export default CreateAccountAnchor;
