import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import RemoteImage from "atoms/images/remote-image";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { PublicationColor } from "utilities/enumerations/publication-color";
import NumberUtils from "utilities/number-utils";
import FileService from "utilities/services/file-service";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublicationCardStaticProps {
    publication: PublicationRecord;
    favorites?: Array<UserPublicationFavoriteRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationCardStatic: React.FC<PublicationCardStaticProps> = (
    props: PublicationCardStaticProps
) => {
    const { publication, favorites } = props;

    const CSS_CLASS_NAME = "c-publication-card";

    const anchorId = `publication-card-${publication.id!}`;

    const { get: getFileApi } = FileService.useGetQuery();
    const getFileResult = getFileApi(
        { id: publication.coverImageFileId! },
        undefined,
        { enabled: !NumberUtils.isDefault(publication.coverImageFileId) }
    );

    const imageFile = getFileResult.data?.resultObject;

    const classNames = [CSS_CLASS_NAME, "-static"];
    if (publication.coverImageFileId == null) {
        classNames.push("-no-image");
    }

    const colorModifier = CustomColorUtils.getClassModifier(
        PublicationColor,
        publication.getColorOrDefault(),
        "publication"
    );

    const showIcon =
        CollectionUtils.hasValues(favorites) &&
        favorites.some(
            (f: UserPublicationFavoriteRecord) =>
                f.publicationCode === publication.code &&
                f.publicationEdition === publication.edition
        );

    return (
        <div className={classNames.join(" ")}>
            <Anchor
                id={anchorId}
                dataTestCode={publication.code}
                dataTestEdition={publication.edition}
                to={publication.getRoute()}>
                <div
                    className={`${CSS_CLASS_NAME}__color-bar ${colorModifier}`}
                />
                <div className={`${CSS_CLASS_NAME}__detail`}>
                    <div className={`${CSS_CLASS_NAME}__detail__top`}>
                        <label className="-edition">
                            {publication.edition}
                        </label>
                        <Paragraph>
                            {StringUtils.hasValue(publication.code)
                                ? `NFPA ${publication.code}`
                                : publication.getDisplayTitle()}
                        </Paragraph>
                        <label className="-title">
                            {StringUtils.hasValue(publication.code)
                                ? publication.title
                                : ""}
                        </label>
                    </div>
                </div>
                <div
                    className={`${CSS_CLASS_NAME}__image-container ${colorModifier}`}>
                    {imageFile != null && <RemoteImage file={imageFile} />}
                </div>
            </Anchor>

            {showIcon && (
                <div className={`${CSS_CLASS_NAME}__favorite-button`}>
                    <Icon type={Icons.StarFilled} />
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationCardStatic;

// #endregion Exports
