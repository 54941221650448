import React, { PropsWithChildren } from "react";
import {
    ErrorBoundary as ReactErrorBoundary,
    ErrorBoundaryPropsWithRender,
} from "react-error-boundary";
import ErrorFallback, {
    ErrorFallbackProps,
} from "molecules/errors/error-fallback";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocation } from "react-router-dom";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ErrorBoundaryProps
    extends Omit<ErrorBoundaryPropsWithRender, "fallbackRender"> {
    fallbackRender?: (
        props: ErrorFallbackProps
    ) => React.ReactElement<any, any> | null;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ErrorBoundary: React.FunctionComponent<
    PropsWithChildren<ErrorBoundaryProps>
> = (props: PropsWithChildren<ErrorBoundaryProps>) => {
    const { children } = props;
    const { useErrorBoundary } = useFeatureFlags();
    const location = useLocation();

    const defaultFallbackRender = (props: ErrorFallbackProps) => (
        <ErrorFallback {...props} />
    );
    const fallbackRender = props.fallbackRender ?? defaultFallbackRender;

    if (!useErrorBoundary) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <ReactErrorBoundary
            {...props}
            fallbackRender={fallbackRender}
            resetKeys={[location]}>
            {children}
        </ReactErrorBoundary>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default ErrorBoundary;

// #endregion Export
