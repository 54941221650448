import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ReadonlyPublicationInfoModalProps {
    closeDialog: () => void;
    isVisible: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-readonly-publication-info-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReadonlyPublicationInfoModal: React.FunctionComponent<ReadonlyPublicationInfoModalProps> =
    (props) => {
        const { closeDialog, isVisible } = props;

        const { t } = useLocalization<CultureResources>();

        const label = t("readonlyPublicationAboutTitle");
        const closeLabel = t("close");

        const isMobile = window.innerWidth < Breakpoints.Phone;
        const transitionEffect = isMobile
            ? ModalTransitions.SlideUp
            : ModalTransitions.Fade;

        return (
            <Modal
                closeDialog={closeDialog}
                cssClassName={CSS_BASE_CLASS_NAME}
                isVisible={isVisible}
                label={label}
                transition={transitionEffect}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <h2>{label}</h2>
                    <Button
                        accessibleText={closeLabel}
                        cssClassName={"-modal-close -icon"}
                        onClick={closeDialog}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Tertiary}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    <Paragraph size={ParagraphSizes.Small}>
                        {t("readonlyPublicationAbout")}
                    </Paragraph>
                    <div className="c-button-row">
                        <Button
                            accessibleText={closeLabel}
                            cssClassName={""}
                            onClick={closeDialog}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Primary}>
                            {t("gotIt")}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default ReadonlyPublicationInfoModal;

// #endregion Export
