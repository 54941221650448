import { ResultRecord } from "andculturecode-javascript-core";
import AdminChangeSummaryService from "utilities/services/admin/change-summaries/change-summary-service";
import { ToastManager } from "utilities/toast/toast-manager";

const useChangeSummaryDelete = () => {
    const { delete: deleteApi } = AdminChangeSummaryService.useDelete();

    const deleteChangeSummary = async (summaryId: number) => {
        if (summaryId == null) return;
        try {
            await deleteApi(summaryId);
            ToastManager.success("Change Summary successfully deleted!");
        } catch (error) {
            if (error instanceof ResultRecord) {
                ToastManager.error(error.listErrorMessages());
            } else {
                ToastManager.error("An unknown error occurred.");
            }
        }
    };
    return { deleteChangeSummary };
};

export default useChangeSummaryDelete;
