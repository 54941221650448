import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useFreeAccessPublication from "utilities/hooks/domain/publications/free-access/use-free-access-publication";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import FreeAccessSidebar from "organisms/sidebar-navigation/free-access-sidebar";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import BannerNavigation from "organisms/banner-navigation/banner-navigation";
import useFreeAccessCtaToast from "pages/free-access/use-free-access-cta-toast";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import ImageViewer from "organisms/image-viewer/image-viewer";
import useTermsAndConditions from "./use-terms-and-conditions";
import FreeAccessTermsModal from "./free-access-terms-modal";
import FreeAccessPublicationExistsModal from "./free-access-publication-exists-modal";
import useTableOfContents from "utilities/hooks/domain/publications/use-table-of-contents";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import useTableOfContentsAtom from "./use-table-of-contents-atom";

const CSS_BASE_CLASS = "c-free-access-page";

interface FreeAccessParams {
    code: string;
    edition: string;
}

interface FreeAccessPageProps {}

const FreeAccessPage: React.FC<FreeAccessPageProps> = () => {
    const { code, edition } = useParams<FreeAccessParams>();
    const { t } = useLocalization<CultureResources>();
    const { globalState } = useGlobalState();
    const isSubscriber =
        globalState.currentIdentity?.hasActiveSubscription() ?? false;
    const publication = useMemo(
        () => `NFPA ${code} ${edition}`,
        [code, edition]
    );

    const { hasCookie, setCookie } = useTermsAndConditions(publication);

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

    const { setInteractionCount } = useFreeAccessCtaToast({
        isSubscriber,
    });
    const { setPageTitle } = useHeaderData();

    const { pages, tableOfContents, title } = useFreeAccessPublication({
        code: code,
        edition: edition,
    });

    const { handleActiveIdChange, onFooterPageChange, activeId } =
        useTableOfContents({
            tableOfContentsEntries: tableOfContents,
            currentPageNumber,
            pageEntries: pages,
        });

    const imageViewerPages =
        pages?.map((e) => {
            return { imageUri: e.imageUri, pageNumber: e.pageNumber };
        }) ?? [];

    const handlePageNumberChange = (value: number) => {
        setInteractionCount((count) => count + 1);

        setCurrentPageNumber(value);
    };

    const { drawerIsOpen } = useTableOfContentsAtom();

    const cssModifier = drawerIsOpen ? "" : "-fullscreen";

    useEffect(() => {
        setPageTitle(
            t("freeAccessPrintTitle", {
                code: code,
                title: title,
            })
        );
    }, [code, setPageTitle, t, title]);

    return (
        <div
            className={`${CSS_BASE_CLASS} ${
                !isSubscriber ? "" : "-noSidebar"
            }`}>
            <BannerNavigation />
            {!isSubscriber && <FreeAccessSidebar />}
            {hasCookie ? (
                <>
                    <ImageViewer
                        tableOfContents={tableOfContents ?? []}
                        pages={imageViewerPages}
                        currentPageNumber={currentPageNumber}
                        setCurrentPageNumber={handlePageNumberChange}
                        activeId={activeId}
                        setActiveId={handleActiveIdChange}
                        onFooterPageChange={onFooterPageChange}
                        banner={
                            <Banner cssModifier={cssModifier} code={code} />
                        }
                    />

                    <FreeAccessPublicationExistsModal
                        code={code}
                        edition={edition}
                        isSubscriber={isSubscriber}
                    />
                </>
            ) : (
                <FreeAccessTermsModal
                    publication={publication}
                    setCookie={setCookie}
                />
            )}
        </div>
    );
};

export default FreeAccessPage;

interface BannerProps {
    cssModifier: string;
    code: string;
}

const Banner = ({ cssModifier, code }: BannerProps) => {
    const buttonText = useMemo(() => `Buy NFPA ${code}`, [code]);
    const handleClick = () =>
        window.open(`https://nfpa.org/${code}`, "_blank")?.focus();

    return (
        <div className={`${CSS_BASE_CLASS}__banner ${cssModifier}`}>
            <Paragraph cssClassName="text" size={ParagraphSizes.XSmall}>
                Own the entire print publication
            </Paragraph>
            <Button
                onClick={handleClick}
                accessibleText={buttonText}
                size={ButtonSizes.Small}
                style={ButtonStyles.Secondary}>
                {buttonText}
            </Button>
        </div>
    );
};
