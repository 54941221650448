import { AnchorTargetTypes } from "andculturecode-javascript-core";
import Anchor from "atoms/anchors/anchor";
import SupportEmailLink from "atoms/anchors/support/support-email-link";
import SupportPhoneLink from "atoms/anchors/support/support-phone-link";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";

import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { siteMap } from "internal-sitemap";
import React from "react";
import Trans from "atoms/i18n/trans";
import { t } from "utilities/localization-utils";
import OfflineFaqPanels from "./offline-faq-panels";
import SupportFaqPanel from "./support-faq-panel";
import { RouteUtils } from "utilities/route-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import RemoteAccessDetailsService from "utilities/services/remote-access-details-service";
import FileUtils from "utilities/file-utils";
import { FileConstants } from "constants/file-constants";
import StringUtils from "utilities/string-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import ForgotPasswordButton from "molecules/forgot-password-button/forgot-password-button";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";

interface FAQsProps {
    limit?: number;
}

const BASE_CLASS_NAME = "c-support-page__faqs";
const CHANGE_INDICATOR_CLASS_NAME = "c-icon -change-indicator";
const ANCHOR_CLASS_NAME = "-anchor";
const ANSWER_CLASS_NAME = "-answer";
const U_LIST_CLASS_NAME = "-u-list";
const O_LIST_CLASS_NAME = "-o-list";

const FAQs = (props: FAQsProps) => {
    const { showVideoDemoFaq } = useFeatureFlags();
    const { globalState } = useGlobalState();
    const { get: getPdf } = RemoteAccessDetailsService.useGetQuery();
    const { isOnline } = useNetworkInformation();
    const { useAzureB2CForSSO } = useFeatureFlags();

    const whatIsLiNK = t("support-whatIsAppName", { appName: AppNameTm });
    const whatsInApp = t("support-whatsInApp", { appName: AppNameTm });
    const changeIndicators = t("support-changeIndicators");
    const howDoIAccess = t("support-howDoIAccess", { appName: AppNameTm });
    const howDoIAccess_answer = t("support-howDoIAccess_answer", {
        appName: AppNameTm,
    });
    const howDoILogIn = t("support-howDoILogIn", { appName: AppNameTm });
    const howDoILogInButtonText = useAzureB2CForSSO ? t("logIn") : t("signIn");
    const forgottenPassword = t("support-forgottenPassword");
    const plans = t("support-plans");
    const freeTrial = t("support-freeTrial", { appName: AppNameTm });
    const accountSettings = t("support-accountSettings");
    const billing = t("support-billing");
    const updateSubscription = t("support-updateSubscription");
    const renewSubscription = t("support-renewSubscription");
    const cancelSubscription = t("support-cancelSubscription");
    const refundPolicy = t("support-refundPolicy");
    const browserRequirements = t("support-browserRequirements", {
        appName: AppNameTm,
    });
    const licenseAgreement = t("support-licenseAgreement", {
        appName: AppNameTm,
    });
    const supportHours = t("support-hours");
    const otherFormsOfContact = t("support-otherFormsOfContact");
    const videoDemos = t("support-videoDemos");
    const { termsUrl, nfpaMyProfileUrl, customerSupportUrl } =
        useNfpaUrlPaths();

    const relativeProviderPath = FileUtils.getLocalizedPath(
        FileConstants.GettingStarted,
        globalState.getPreferredOrCurrentCulture()
    );
    const getPdfEnabled =
        StringUtils.hasValue(relativeProviderPath) && isOnline;
    const { data: gettingStartedGuideResponse } = getPdf(
        undefined,
        { relativeProviderPath },
        { enabled: getPdfEnabled }
    );

    const gettingStartedGuide = gettingStartedGuideResponse?.resultObject;

    const FAQs = [
        <SupportFaqPanel buttonText={whatIsLiNK}>
            <Trans i18nKey="support-whatIsAppName_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {{ appName: AppNameTm }} is a subscription-based application
                    that delivers NFPA codes and standards and supporting
                    content. This new tool allows users to easily search and
                    navigate a variety of different content types based on
                    real-life scenarios. {{ appName: AppNameTm }} allows you to
                    curate and share information relevant to your work and
                    educate yourself and your team to better prepare for your
                    day-to-day work. Users will have access to:
                </Paragraph>
                <ul className={U_LIST_CLASS_NAME}>
                    <li>
                        Online and offline access to NFPA Codes &amp; Standards
                    </li>
                    <li>Expert Commentary &amp; Visual Aids</li>
                    <li>Situational Navigation</li>
                    <li>Custom Collections</li>
                    <li>Personal Notations</li>
                    <li>Reference Panel</li>
                    <li>Bookmarking</li>
                    <li>Sharing</li>
                    <li>Team Collaboration</li>
                </ul>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={whatsInApp}>
            <Trans i18nKey="support-whatsInApp_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <span>
                        {{ appName: AppNameTm }}, a breakthrough digital
                        platform providing instant access to NFPA® codes and
                        standards, plus exclusive expert commentary, visual
                        aids, and more. NFPA LiNK® subscribers now have access
                        to over 1,500 NFPA codes and standards. This includes
                        all current editions, as well as a library of legacy
                        titles going back five editions. Subscribers also get
                        early access to newly released editions before the
                        printed book is available for purchase.
                    </span>
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        to={siteMap.publications.public.all}>
                        <b>Browse available publications.</b>
                    </Anchor>
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={changeIndicators}>
            <Trans i18nKey="support-changeIndicators_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    NFPA codes and standards include revision symbols
                    identifying changes from the previous edition. The following
                    indicators are meant to be a guide. [Note: Rearrangement of
                    sections may not be captured in the revision symbols.]
                </Paragraph>
                <ul className={U_LIST_CLASS_NAME}>
                    <li>
                        Revisions to text are{" "}
                        <span
                            className={`c-code-change -${XmlChangeNotationConstants.NEW_MATERIAL}`}>
                            <strong>shaded</strong>
                        </span>
                        .
                    </li>
                    <li>
                        <ChangeIndicatorIcon
                            changes={XmlChangeNotationConstants.HAS_CHANGES}
                            cssClassName={CHANGE_INDICATOR_CLASS_NAME}
                        />{" "}
                        before a section number indicates that words within that
                        section were deleted.
                    </li>
                    <li>
                        <ChangeIndicatorIcon
                            changes={XmlChangeNotationConstants.HAS_CHANGES}
                            cssClassName={CHANGE_INDICATOR_CLASS_NAME}
                        />{" "}
                        to the left of a table or figure number indicates a
                        revision to an existing table or figure.
                    </li>
                    <li>
                        When a chapter was heavily revised, the entire chapter
                        is marked throughout with the{" "}
                        <ChangeIndicatorIcon
                            changes={XmlChangeNotationConstants.HAS_CHANGES}
                            cssClassName={CHANGE_INDICATOR_CLASS_NAME}
                        />{" "}
                        symbol.
                    </li>
                    <li>
                        Where one or more sections were deleted, a
                        <ChangeIndicatorIcon
                            changes={XmlChangeNotationConstants.DELETION}
                            cssClassName={CHANGE_INDICATOR_CLASS_NAME}
                        />{" "}
                        is placed between the remaining sections.
                    </li>
                    <li>
                        Chapters, annexes, sections, figures, and tables that
                        are new are indicated with an{" "}
                        <span>
                            <ChangeIndicatorIcon
                                changes={
                                    XmlChangeNotationConstants.NEW_MATERIAL
                                }
                                cssClassName={CHANGE_INDICATOR_CLASS_NAME}
                            />
                            .
                        </span>
                    </li>
                </ul>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={howDoIAccess}>
            <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                {howDoIAccess_answer}
            </Paragraph>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={howDoILogIn}>
            <Trans i18nKey="support-howDoILogIn_answer">
                <ul className={U_LIST_CLASS_NAME}>
                    <li>
                        <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                            <strong>
                                If you already have an existing NFPA account
                            </strong>
                            ,{" "}
                            <LogInAnchor
                                buttonText={howDoILogInButtonText.toLowerCase()}
                            />{" "}
                            using your NFPA account credentials and select the{" "}
                            {{ appName: AppNameTm }} plan of your choice. Once
                            you’ve selected the plan of your choice, you will be
                            brought to NFPA to complete your transaction. Once
                            you’ve completed your purchase, you will be returned
                            to NFPA LiNK to begin using your subscription. Your
                            NFPA account credentials are the same credentials
                            you will use to log in to your NFPA LiNK
                            subscriptions. If you have forgotten your NFPA
                            password, you can{" "}
                            <ForgotPasswordButton
                                cssClassName={ANCHOR_CLASS_NAME}
                                buttonText={t("resetItHere").toLowerCase()}
                            />
                            .
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                            <strong>
                                If you need to create an NFPA account
                            </strong>
                            ,
                            <PlansAnchor
                                cssClassName={ANCHOR_CLASS_NAME}
                                target={AnchorTargetTypes.Blank}>
                                select the {{ appName: AppNameTm }} plan of your
                                choice.
                            </PlansAnchor>
                            You will then be brought to NFPA to complete your
                            transaction. During the checkout process you will be
                            prompted to create an account. Once you’ve completed
                            your purchase, you will be returned to NFPA LiNK to
                            begin using your subscription. Your NFPA account
                            credentials are the same credentials you will use to
                            log in to your NFPA LiNK subscriptions.
                        </Paragraph>
                    </li>
                </ul>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={forgottenPassword}>
            <Trans i18nKey="support-forgottenPassword_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    If you have forgotten your password you may
                    <ForgotPasswordButton
                        cssClassName={ANCHOR_CLASS_NAME}
                        buttonText={t("resetItHere").toLowerCase()}
                    />
                    . Enter your e-mail address and you will receive an e-mail
                    that will provide a link for you to reset your password. If
                    you do not receive an e-mail within 24 hours, please email
                    us at
                    <SupportEmailLink />
                    , or call us at
                    <SupportPhoneLink />
                    ; if you are calling from outside of the U.S.
                    <SupportPhoneLink international={true} />. Our customer
                    support team is available to assist you{" "}
                    {{ supportHours: supportHours }}.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <OfflineFaqPanels />,
        <SupportFaqPanel buttonText={plans}>
            <Trans i18nKey="support-plans_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    We understand that not every professional and team needs the
                    same solution.
                    {{ appName: AppNameTm }}
                    offers a
                    <PlansAnchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}>
                        variety of plans and billing options
                    </PlansAnchor>
                    . We offer monthly and annual billing options, as well as
                    individual, team and enterprise plans. Take advantage of the
                    auto-renewal option for ease of payment and cost savings.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={freeTrial}>
            <Trans i18nKey="support-freeTrial_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {{ appName: AppNameTm }} offers a 14-day free trial. This
                    option can be found below the
                    <PlansAnchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}>
                        plan options
                    </PlansAnchor>
                    on the log in page, or you may
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}
                        to={RouteUtils.getUrl(siteMap.signup.freeTrial)}>
                        enroll here
                    </Anchor>
                    . In order to participate in the free trial, you must have
                    an NFPA account and agree to the terms of the trial.
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    If you have an existing NFPA account, please choose the 'Log
                    In' option on
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}
                        to={RouteUtils.getUrl(siteMap.signup.freeTrial)}>
                        the enrollment page
                    </Anchor>
                    and enter your NFPA credentials to proceed with registering
                    for the trial. If you do not have an NFPA account, select
                    the 'Create an Account' option on
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}
                        to={RouteUtils.getUrl(siteMap.signup.freeTrial)}>
                        the enrollment page
                    </Anchor>
                    to register.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={accountSettings}>
            <Trans i18nKey="support-accountSettings_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    You may update your account information, notification
                    preferences, payment details, and change your password in
                    your
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        external
                        to={nfpaMyProfileUrl}
                        target={AnchorTargetTypes.Blank}>
                        NFPA account
                    </Anchor>
                    .
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel id="payment-info" buttonText={billing}>
            <Trans i18nKey="support-billing_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    To update your payment information for auto-renewals, please
                    email us at
                    <SupportEmailLink />, or call us at
                    <SupportPhoneLink />
                    ; if you are calling from outside of the U.S. or Canada,
                    dial
                    <SupportPhoneLink international={true} />. Our customer
                    support team is available to assist you
                    {{ supportHours: supportHours }}.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={updateSubscription}>
            <Trans i18nKey="support-updateSubscription_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    To update or modify your subscription plan, please email us
                    at
                    <SupportEmailLink />, or call us at
                    <SupportPhoneLink />; if you are calling from outside of the
                    U.S. or Canada, dial
                    <SupportPhoneLink international={true} />. Our customer
                    support team is available to assist you
                    {{ supportHours: supportHours }}.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={renewSubscription}>
            <Trans i18nKey="support-renewSubscription_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    If you selected auto-renewal at point of purchase, you don’t
                    have to do anything! You will receive the benefit of never
                    having to worry about loss of access and will automatically
                    be charged on your renewal date.
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    If you did not elect to auto-renewal upon purchase, you will
                    need to go to our
                    <PlansAnchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        target={AnchorTargetTypes.Blank}>
                        plans selection page
                    </PlansAnchor>
                    to renew your subscription.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={cancelSubscription}>
            <Trans i18nKey="support-cancelSubscription_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    Cancellation steps can differ depending on your subscription
                    tier. Please note the following cancellation terms before
                    proceeding to cancel
                </Paragraph>
                <ul className={U_LIST_CLASS_NAME}>
                    <li>
                        If you opt to cancel today, it will take 3-5 business
                        days for the cancellation of your subscription to take
                        effect.
                    </li>
                    <li>
                        If you cancel during a trial period, you may lose access
                        to content immediately.
                    </li>
                    <li>
                        If you cancel a monthly subscription, you can continue
                        using the subscription until the next billing date.
                    </li>
                    <li>
                        If you’ve prepaid for an annual subscription, we cannot
                        refund you for the unused portion of your subscription.
                    </li>
                    <li>
                        <strong>Cancelling Individual Subscriptions:</strong>
                        <ol className={O_LIST_CLASS_NAME}>
                            <li>
                                <LogInAnchor
                                    cssClassName={ANCHOR_CLASS_NAME}
                                    target={AnchorTargetTypes.Blank}
                                />
                                to your account from any browser.
                            </li>
                            <li>
                                Visit Account Settings and select "SUBSCRIPTION
                                DETAILS".
                            </li>
                            <li>
                                Click on the “CANCEL MY SUBSCRIPTION” button.
                            </li>
                            <li>Fill out the form and submit.</li>
                            <li>
                                If you have any issues, please send an email to
                                <SupportEmailLink />.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Cancelling Team Subscriptions:</strong>
                        <ol className={O_LIST_CLASS_NAME}>
                            <li>
                                As the Team account administrator,{" "}
                                <LogInAnchor
                                    cssClassName={ANCHOR_CLASS_NAME}
                                    target={AnchorTargetTypes.Blank}
                                />{" "}
                                to your account from any browser.
                            </li>
                            <li>
                                Visit Account Settings and select "SUBSCRIPTION
                                DETAILS".
                            </li>
                            <li>Click on “CANCEL SUBSCRIPTION” button.</li>
                            <li>Fill out the form and submit.</li>
                            <li>
                                If you have any issues, please send an email to
                                <SupportEmailLink />.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Cancelling Enterprise Subscriptions:</strong>
                        <ol className={O_LIST_CLASS_NAME}>
                            <li>Please contact your account manager.</li>
                            <li>
                                If you have any issues, please send an email to
                                <SupportEmailLink />.
                            </li>
                        </ol>
                    </li>
                </ul>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={refundPolicy}>
            <Trans i18nKey="support-refundPolicy_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    Digital products are non-returnable and non-refundable, but
                    NFPA takes pride in the quality of the products and services
                    we offer. If you are not completely satisfied with your
                    order, we would like to hear from you. Please{" "}
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        external
                        target={AnchorTargetTypes.Blank}
                        to={customerSupportUrl}>
                        contact us
                    </Anchor>{" "}
                    to provide feedback.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={browserRequirements}>
            <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                {t("support-browserRequirements_answer")}
            </Paragraph>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={licenseAgreement}>
            <Trans i18nKey="support-licenseAgreement_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    In order to use {{ appName: AppNameTm }}, you must read and
                    agree to the{" "}
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        external
                        target={AnchorTargetTypes.Blank}
                        to={termsUrl}>
                        End User License Agreement
                    </Anchor>
                    . Please read the entire agreement carefully. If you do not
                    agree with the terms, you will not be able to access the
                    site.
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
        <SupportFaqPanel buttonText={otherFormsOfContact}>
            <Trans i18nKey="support-otherFormsOfContact_answer">
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    Our customer support team is available to assist you{" "}
                    {{ supportHours: supportHours }}. If it is outside our
                    business hours, please email us at <SupportEmailLink />
                    &nbsp;and we will respond to you on our next business day.
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <strong>Telephone</strong>
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <SupportPhoneLink /> (U.S. &amp; Canada)
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        external
                        to={`tel: +1 855 274-6525`}>
                        {{ altPhone: "+1 855 274-6525" }}
                    </Anchor>
                    (U.S. &amp; Canada)
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <SupportPhoneLink international={true} />
                    (International)
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    <Anchor
                        cssClassName={ANCHOR_CLASS_NAME}
                        external
                        to={`tel: +95 800-844-6058`}>
                        {{ mxPhone: "95 800-844-6058" }}
                    </Anchor>
                    (Mexico)
                </Paragraph>
            </Trans>
        </SupportFaqPanel>,
    ];

    if (showVideoDemoFaq)
        FAQs.push(
            <SupportFaqPanel buttonText={videoDemos}>
                <Trans i18nKey="support-videoDemos_answer">
                    <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                        Yes! Not only have we developed a{" "}
                        <Anchor
                            cssClassName={ANCHOR_CLASS_NAME}
                            external={true}
                            target={AnchorTargetTypes.Blank}
                            to={gettingStartedGuide?.url!}>
                            Quick Start Guide
                        </Anchor>{" "}
                        to help you familiarize yourself with NFPA LiNK®, but we
                        have also developed these short companion videos:
                        <ul className={U_LIST_CLASS_NAME}>
                            <li>Dashboard</li>
                            <li>Book View</li>
                            <li>DiRECT</li>
                            <li>MyLiNK</li>
                            <li>Offline Access</li>
                            <li>
                                <Anchor
                                    cssClassName={ANCHOR_CLASS_NAME}
                                    external={true}
                                    target={AnchorTargetTypes.Blank}
                                    to="https://youtu.be/1BF4LOy-DL4">
                                    Reference Panel
                                </Anchor>
                            </li>
                            <li>Search</li>
                        </ul>
                    </Paragraph>
                </Trans>
            </SupportFaqPanel>
        );

    const limit = props.limit ?? FAQs.length;

    const FAQsToRender = FAQs.slice(0, limit);

    return (
        <div className={BASE_CLASS_NAME}>
            {FAQsToRender.map((x, index) => {
                return { ...x, key: index };
            })}
        </div>
    );
};

export default FAQs;
