import React, { Fragment } from "react";
import PublicationSidebarNavLink, {
    PublicationSidebarNavItem,
} from "./publication-sidebar-nav-link";
import PublicationTypes from "constants/publication-types";

interface PublicationSidebarNavLinksProps {
    navItems: PublicationSidebarNavItem[];
    publicationType?: PublicationTypes;
}

const PublicationSidebarNavLinks: React.FunctionComponent<PublicationSidebarNavLinksProps> =
    (props: PublicationSidebarNavLinksProps) => {
        const { navItems, publicationType } = props;

        // -----------------------------------------------------------------------------------------
        // #region Handlers
        // -----------------------------------------------------------------------------------------

        // #endregion Handlers

        return (
            <Fragment>
                {navItems.map((item) => {
                    item = {
                        ...item,
                        useRelativePath:
                            publicationType === PublicationTypes.NFC,
                    };
                    return (
                        <PublicationSidebarNavLink key={item.id} item={item} />
                    );
                })}
            </Fragment>
        );
    };

export default PublicationSidebarNavLinks;
