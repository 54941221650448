import { AnchorTargetTypes } from "andculturecode-javascript-core";
import logo from "assets/images/logo-blue.png";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { AppNameWithHtml } from "constants/app-name-tm";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import moment from "moment";
import React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { useLocalization } from "utilities/hooks/use-localization";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AboutModalProps {
    closeDialog: () => void;
    isVisible: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-about-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AboutModal: React.FunctionComponent<AboutModalProps> = (props) => {
    const { closeDialog, isVisible } = props;

    const { privacyUrl, termsUrl } = useNfpaUrlPaths();
    const { t } = useLocalization<CultureResources>();

    const aboutLabel = t("aboutApp", { appName: AppNameWithHtml });
    const closeLabel = t("close");
    const termsLabel = t("termsOfUse");
    const privacyLabel = t("privacyPolicy");
    const version = process.env.REACT_APP_VERSION;

    const isMobile = window.innerWidth < Breakpoints.Phone;
    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    return (
        <Modal
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={aboutLabel}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: aboutLabel,
                        }}
                    />
                </h2>
                <Button
                    accessibleText={closeLabel}
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <Paragraph
                    cssClassName={`${CSS_BASE_CLASS_NAME}__content__title`}
                    size={ParagraphSizes.Small}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: AppNameWithHtml,
                        }}
                    />
                    &nbsp;
                    {version}
                </Paragraph>
                <img src={logo} alt="Home" />
                <Anchor
                    to={termsUrl}
                    external={true}
                    target={AnchorTargetTypes.Blank}>
                    {termsLabel}
                </Anchor>
                <Anchor
                    to={privacyUrl}
                    external={true}
                    target={AnchorTargetTypes.Blank}>
                    {privacyLabel}
                </Anchor>
                <Paragraph size={ParagraphSizes.Small}>
                    {UnicodeCharacterConstants.Copyright} National Fire
                    Protection Association (NFPA) {moment().year()}
                </Paragraph>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AboutModal;

// #endregion Export
