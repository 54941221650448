import { useCallback, useMemo } from "react";
import { siteMap } from "internal-sitemap";
import useCurrentRouteDefinition from "utilities/hooks/routing/use-current-route-definition";
import { CULTURE_PARAM } from "utilities/route-utils";

/**
 * React hook returning a boolean indicating
 * whether the current route is nested under the specified route.
 */
export default function useIsNestedRoute() {
    const currentRoute = useCurrentRouteDefinition();
    const isNestedRoute = useCallback(
        (route: string): boolean =>
            currentRoute?.path?.replace(CULTURE_PARAM, "").startsWith(route) ??
            false,
        [currentRoute]
    );
    const routeIsAdmin = useMemo(
        () => isNestedRoute(siteMap.admin.dashboard),
        [isNestedRoute]
    );
    const routeIsBookView = useMemo(
        () =>
            isNestedRoute(
                siteMap.publications.deprecated.publication.replace("/:id", "")
            ) ||
            isNestedRoute(
                siteMap.publications.adminPreview.publication.replace(
                    "/:id",
                    ""
                )
            ),
        [isNestedRoute]
    );
    const routeIsMyLink = useMemo(
        () => isNestedRoute(siteMap.myLink.dashboard),
        [isNestedRoute]
    );
    const routeIsSitNav = useMemo(
        () => isNestedRoute(siteMap.situationalNavigation.dashboard),
        [isNestedRoute]
    );

    const routeIsFreeAccess = useMemo(
        () => isNestedRoute(siteMap.freeAccess.publications),
        [isNestedRoute]
    );

    const routeIsPreview = useMemo(
        () =>
            isNestedRoute(
                siteMap.publications.adminPreview.publication.replace(
                    "/:id",
                    ""
                )
            ),
        [isNestedRoute]
    );

    return {
        routeIsAdmin,
        routeIsBookView,
        routeIsMyLink,
        routeIsSitNav,
        routeIsFreeAccess,
        routeIsPreview,
    };
}
