import { useLocation } from "react-router-dom";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const ADMIN_PREVIEW_ROUTE_PREFIX = "preview";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Custom Hook
// -----------------------------------------------------------------------------------------

function useAdminPreview() {
    const location = useLocation();

    return {
        isAdminPreview: location.pathname.includes(ADMIN_PREVIEW_ROUTE_PREFIX),
    };
}

// #endregion Custom Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useAdminPreview;

// #endregion Exports
