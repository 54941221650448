import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import BookService from "utilities/services/books/book-service";
import { useCallback } from "react";
import OfflineBookSyncStatusService from "utilities/services/offline/offline-book-sync-status-service";

export default function useOfflineBookNavigationProperties() {
    const { get: getBook } = BookService.useGet();
    const { get: getOfflineBookSyncStatuses } =
        OfflineBookSyncStatusService.useGet();

    const addBook = useCallback(
        async (offlineBookRecord: OfflineBookRecord) => {
            if (
                offlineBookRecord.book != null ||
                offlineBookRecord.bookId == null
            ) {
                return offlineBookRecord;
            }

            const { resultObject: book } = await getBook({
                id: offlineBookRecord.bookId,
            });

            return offlineBookRecord.with({ book });
        },
        [getBook]
    );

    const addSyncStatus = useCallback(
        async (offlineBook: OfflineBookRecord) => {
            try {
                const { resultObject: syncStatus } =
                    await getOfflineBookSyncStatuses({
                        offlineBookId: offlineBook.id!,
                        offlineDeviceId: offlineBook.offlineDeviceId,
                    });

                return offlineBook.with({ syncStatus });
            } catch {
                return offlineBook;
            }
        },
        [getOfflineBookSyncStatuses]
    );

    return {
        addBook,
        addSyncStatus,
    };
}
