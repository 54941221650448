import { useAtom } from "jotai";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import WorkGroupsAtom from "utilities/atoms/work-group/work-groups-atom";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

const baseEndpoint = "workgroups/:workGroupId";

interface UpdateWorkGroupPathParams {
    workGroupId: number;
}

interface UpdateWorkGroupQueryParams {}

const useWorkGroupUpdateService = () => {
    const [workGroupRecords, setWorkGroupRecords] = useAtom(WorkGroupsAtom);

    const { result, setLoading, setSuccess } = useServiceHookResultState(
        new WorkGroupRecord()
    );

    const useUpdate = ServiceHookFactory.useUpdate<
        WorkGroupRecord,
        UpdateWorkGroupPathParams,
        UpdateWorkGroupQueryParams
    >(WorkGroupRecord, baseEndpoint);

    const { update } = useUpdate();

    const updateWorkGroup = async (workGroup: WorkGroupRecord) => {
        if (workGroup.id == null || StringUtils.isEmpty(workGroup.title)) {
            // toast message
            return;
        }

        setLoading();

        try {
            const { resultObject: updatedWorkGroup } = await update(workGroup, {
                workGroupId: workGroup.id,
            });

            if (updatedWorkGroup != null) {
                setSuccess(updatedWorkGroup);
                const newWorkGroups = workGroupRecords.map((workGroup) => {
                    if (workGroup.id === updatedWorkGroup.id) {
                        return updatedWorkGroup;
                    }
                    return workGroup;
                });
                setWorkGroupRecords(newWorkGroups);
                // localization
                ToastManager.success("Successfully updated WorkGroup");
            }
        } catch (error) {
            // localization
            ToastManager.error("Error updating WorkGroup");
        }
    };

    return { loading: result.loading, updateWorkGroup };
};

export default useWorkGroupUpdateService;
