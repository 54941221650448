import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import { UseModalActionsHookResult } from "utilities/hooks/use-modal-actions";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface VideoModalProps
    extends Pick<UseModalActionsHookResult, "isOpen" | "handleClose"> {
    autoplay?: boolean;
    title: string;
    videoId?: string;
    fullVideoSrc?: string;
    poster?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-video-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const VideoModal: React.FC<VideoModalProps> = (props: VideoModalProps) => {
    const {
        autoplay,
        handleClose,
        isOpen,
        title,
        videoId,
        fullVideoSrc = null,
        poster,
    } = props;

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const autoplayQueryParamValue = autoplay ? 1 : 0;
    const className = `${BASE_CLASS_NAME} ${isMobile ? "-mobile" : ""}`;

    return (
        <React.Fragment>
            <Modal
                closeButtonStyle={ModalCloseButtonStyle.OutsideDialog}
                closeDialog={handleClose}
                cssClassName={className}
                isVisible={isOpen}
                label={title}
                type={ModalTypes.Fullscreen}
                useDialogOverlay={true}>
                {fullVideoSrc ? (
                    <video
                        className={`${BASE_CLASS_NAME}__video`}
                        src={fullVideoSrc}
                        autoPlay={autoplay}
                        controls
                        poster={poster}></video>
                ) : (
                    <iframe
                        allowFullScreen={true}
                        allow={
                            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        }
                        frameBorder="0"
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplayQueryParamValue}`}
                        tabIndex={-1}
                        title={title}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default VideoModal;

// #endregion Exports
