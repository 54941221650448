import SituationCategoryRecord from "models/view-models/situational-navigation/situations/situation-category-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSituationCategoryQueryParams extends PagedQuery {}

export interface AdminSituationCategoryPathParams {
    situationId: number;
    id?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint =
    "admin/situational-navigation/situations/:situationId/categories";
const resourceType = SituationCategoryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSituationCategoryService = {
    list: ServiceFactory.nestedList<
        SituationCategoryRecord,
        AdminSituationCategoryPathParams,
        AdminSituationCategoryQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SituationCategoryRecord,
        AdminSituationCategoryPathParams,
        AdminSituationCategoryQueryParams
    >(resourceType, baseEndpoint),
    update: ServiceFactory.bulkUpdate<
        SituationCategoryRecord,
        AdminSituationCategoryPathParams
    >(resourceType, baseEndpoint),
    useUpdate: ServiceHookFactory.useBulkUpdate<
        SituationCategoryRecord,
        AdminSituationCategoryPathParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSituationCategoryService;

// #endregion Exports
