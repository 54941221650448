import React from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import TermsAndConditionsPage from "./terms-and-conditions-page";
import TermsAndConditionsPageV2 from "./terms-and-conditions-page-v2";

const TermsWrapper = () => {
    const { internalizeEula } = useFeatureFlags();

    return internalizeEula ? (
        <TermsAndConditionsPageV2 />
    ) : (
        <TermsAndConditionsPage />
    );
};

export default TermsWrapper;
