import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EmptySectionConverterProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EmptySectionConverter: React.FunctionComponent<EmptySectionConverterProps> =
    (props: EmptySectionConverterProps) => {
        const { externalId, children } = props;

        return externalId ? (
            <div id={externalId}>
                {children && typeof children !== "string" ? children : <></>}
            </div>
        ) : children && typeof children !== "string" ? (
            <>{children}</>
        ) : (
            <></>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EmptySectionConverter;

// #endregion Exports
