import { useEffect, useState } from "react";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { OfflineSearchIndexStatus } from "models/enumerations/offline/offline-search-index-status";

export default function useOfflineSearchIndexStatus() {
    const { sendCommand } = useServiceWorker();
    const [status, setStatus] = useState<OfflineSearchIndexStatus>();

    useEffect(
        function checkOfflineSearchIndexStatus() {
            sendCommand({
                type: ServiceWorkerCommandTypes.CheckSearchIndexStatus,
            });
        },
        [sendCommand]
    );

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.SearchIndexStatus,
        (event) => {
            setStatus(event.data.status);
        }
    );

    return status;
}
