import { PropsWithChildren, useState, useEffect } from "react";
import ReferencePanelContextRecord from "models/view-models/reference-panel-context-record";
import ReferencePanelContext from "utilities/contexts/reference-panel/use-reference-panel-context";
import React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useUserReferences from "utilities/hooks/domain/user-references/use-user-references";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ReferencePanelContextProviderProps {}

// -----------------------------------------------------------------------------------------
// #region Provider
// -----------------------------------------------------------------------------------------

export const ReferencePanelContextProvider: React.FC<
    PropsWithChildren<ReferencePanelContextProviderProps>
> = (props: PropsWithChildren<ReferencePanelContextProviderProps>) => {
    const [referencePanel, setReferencePanel] = useState(
        new ReferencePanelContextRecord()
    );

    const {
        globalState: { currentIdentity },
    } = useGlobalState();

    const currentUserId = currentIdentity?.userId();
    const currentUserRoleId = currentIdentity?.getCurrentUserRole()?.id;
    const referencesEnabled = currentIdentity?.hasActiveSubscription() ?? false;

    const result = useUserReferences(
        currentUserId,
        currentUserRoleId,
        referencesEnabled
    );

    const {
        errors,
        loaded,
        loading,
        resultObject: {
            currentSection,
            currentSectionId,
            currentUserReferenceId,
            userReferences,
        },
    } = result;

    useEffect(() => {
        setReferencePanel((prevContext) =>
            prevContext.with({
                currentSection,
                currentSectionId,
                currentUserReferenceId,
                errors,
                loaded,
                loading,
                userReferences,
            })
        );
    }, [
        currentSection,
        currentSectionId,
        currentUserReferenceId,
        errors,
        loaded,
        loading,
        userReferences,
    ]);

    return (
        <ReferencePanelContext.Provider
            value={[referencePanel, setReferencePanel]}>
            {props.children}
        </ReferencePanelContext.Provider>
    );
};

// #endregion Provider
