import AlertLevels from "constants/alert-levels";
import AlertBanner from "molecules/alerts/alert-banner";
import React from "react";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineUnsupportedBannerProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineUnsupportedBanner: React.FC<OfflineUnsupportedBannerProps> = (
    props: OfflineUnsupportedBannerProps
) => {
    const { isSupported } = useServiceWorker();
    const { t } = useLocalization<CultureResources>();

    if (isSupported) {
        return null;
    }

    return (
        <AlertBanner alertLevel={AlertLevels.Error}>
            {t("offline-unsupported-browser")}
        </AlertBanner>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineUnsupportedBanner;

// #endregion Exports
