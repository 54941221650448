import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserConfigurationRecord from "models/view-models/user-configuration-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserConfigurationBasePathParams {}

export interface UserConfigurationResourcePathParams
    extends UserConfigurationBasePathParams {}

/**
 * Search parameters when getting a UserConfiguration resource
 * These get translated to QueryString parameters
 */
export interface UserConfigurationGetQueryParams {
    returnForbiddenIfErrored?: boolean;
    setNewestUserRole?: boolean;
    startFreeTrial?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "userconfigurations";
const key = "userconfigurations";
const resourceType = UserConfigurationRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserConfigurationService = {
    key,

    get: ServiceFactory.get<
        UserConfigurationRecord,
        UserConfigurationResourcePathParams,
        UserConfigurationGetQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        UserConfigurationRecord,
        UserConfigurationResourcePathParams,
        UserConfigurationGetQueryParams
    >(resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        UserConfigurationRecord,
        UserConfigurationResourcePathParams,
        UserConfigurationGetQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default UserConfigurationService;

// #endregion Exports
