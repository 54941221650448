import React from "react";
import FileUtils from "utilities/file-utils";
import { useOfflineStorageUsage } from "utilities/hooks/use-offline-storage-usage";
import { useLocalization } from "utilities/hooks/use-localization";
import { FileSizeUnit } from "utilities/enumerations/file-size-unit";
import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import useServiceWorker, {
    ServiceWorkerStatus,
} from "utilities/contexts/service-worker/use-service-worker";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineTabHeaderProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-offline-tab-header";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineTabHeader: React.FC<OfflineTabHeaderProps> = (
    props: OfflineTabHeaderProps
) => {
    const { sizeInBytes } = useOfflineStorageUsage();
    const { status: serviceWorkerStatus } = useServiceWorker();
    const { t } = useLocalization();
    const deviceStorageMessage = t("deviceStorageUsed", {
        amount: FileUtils.formatBytes(sizeInBytes, FileSizeUnit.MB),
    });
    const statusClassName = () => {
        if (serviceWorkerStatus === ServiceWorkerStatus.Enabled) {
            return "-running";
        }

        if (serviceWorkerStatus === ServiceWorkerStatus.Waiting) {
            return "-waiting";
        }

        return "-disabled";
    };

    const offlineFeatures = t(
        `serviceWorkerStatus-${serviceWorkerStatus}` as keyof CultureResources
    );

    return (
        <div className={CLASS_NAME}>
            <AlertBanner alertLevel={AlertLevels.Info}>
                <Paragraph size={ParagraphSizes.XSmall}>
                    {t("offline-data-informational-message")}
                </Paragraph>
            </AlertBanner>
            <div className={`${CLASS_NAME}__status`}>
                {`Offline features: `}
                <span className={statusClassName()}>{offlineFeatures}</span>
            </div>
            <label className={`${CLASS_NAME}__usage`}>
                {deviceStorageMessage}
            </label>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineTabHeader;

// #endregion Exports
