import SectionShareLinkRecord from "models/view-models/share-links/section-share-link-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import ServiceFactory from "utilities/services/service-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SectionShareLinkPathParams {
    guid: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const resourceType = SectionShareLinkRecord;
const baseEndpoint = "sharelinks/section";
const resourceEndpoint = `${baseEndpoint}/:guid`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SectionShareLinkService = {
    create: ServiceFactory.create<SectionShareLinkRecord>(
        resourceType,
        baseEndpoint
    ),
    useCreate: ServiceHookFactory.useCreate<SectionShareLinkRecord>(
        resourceType,
        baseEndpoint
    ),
    get: ServiceFactory.get<SectionShareLinkRecord, SectionShareLinkPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGet: ServiceHookFactory.useGet<
        SectionShareLinkRecord,
        SectionShareLinkPathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SectionShareLinkService;

// #endregion Exports
