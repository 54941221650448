import * as React from "react";
import { useEffect } from "react";
import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { match } from "react-router-dom";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import useChapterQuery from "utilities/hooks/queries/publications/chapters/use-chapter-query";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";

import useCurrentPublication from "utilities/contexts/use-current-publication";

interface ChapterBreadcrumbProps {
    match: match<NestedPublicationParams & NfpaLabelParams>;
}

const ChapterBreadcrumb: React.FunctionComponent<ChapterBreadcrumbProps> = (
    props: ChapterBreadcrumbProps
) => {
    const { match } = props;
    const { code, edition, id, nfpaLabel, publicationId } = match.params;
    const { setPageTitle } = useHeaderData({ match });

    const { resultObject: chapter, loading: articleLoading } = useChapterQuery({
        code,
        edition,
        id,
        nfpaLabel,
        publicationId,
    });

    const { publication, loaded } = useCurrentPublication();
    const chapterWithPublication = useDeepCompareMemo(
        () => chapter.with({ publication }),
        [chapter, publication]
    );

    useEffect(() => {
        setPageTitle(chapterWithPublication.getPageTitle());
    }, [chapterWithPublication, setPageTitle]);

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">
                {articleLoading || !loaded
                    ? "..."
                    : chapterWithPublication.getDisplayTitle()}
            </span>
        </Breadcrumb>
    );
};

export default ChapterBreadcrumb;
