import React, { PropsWithChildren } from "react";
import { ToastManager } from "utilities/toast/toast-manager";

export interface UserDetail {
    heading: string;
    data: string;
}

export interface Details {
    cssClassName: string;
    details: UserDetail[];
}

const BASE_CLASS = `c-support-user-detail-page__user-details__data`;
const USER_DETAIL_ITEM = `${BASE_CLASS}__item`;
const USER_DETAIL_HEADING = `${BASE_CLASS}__item__heading`;
const USER_DETAIL_VALUE = `${BASE_CLASS}__item__value`;

const Detail = ({ heading, data }: UserDetail) => (
    <div className={USER_DETAIL_ITEM}>
        <span className={USER_DETAIL_HEADING}>{heading}</span>
        <span
            onDoubleClick={() => {
                copy(data);
            }}
            className={USER_DETAIL_VALUE}>
            {data}
        </span>
    </div>
);

const UserDetails = ({
    children,
    cssClassName,
    details,
}: PropsWithChildren<Details>) => {
    return (
        <>
            <div className={cssClassName}>
                {details.map((detail, i) => (
                    <Detail
                        key={i}
                        heading={detail.heading}
                        data={detail.data}
                    />
                ))}
                {children}
            </div>
        </>
    );
};

export default UserDetails;

const copy = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        ToastManager.success(`Copied '${text}' to clipboard`);
    } catch (error) {
        ToastManager.error("Not copied");
    }
};
