import * as React from "react";

export interface CommitteesCommitteeProps {
    children?: JSX.Element[];
}

const CommitteesCommittee = (props: CommitteesCommitteeProps) => {
    return <div className="c-committee">{props.children}</div>;
};

export default CommitteesCommittee;
