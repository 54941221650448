import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import UserCollectionRecord from "models/view-models/user-collection-record";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useWorkGroupReadService from "utilities/hooks/domain/work-groups/use-work-group-read-service";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

interface WorkGroupMultiSelectProps {
    errorMessages?: string[];
    setUserCollection: React.Dispatch<
        React.SetStateAction<UserCollectionRecord>
    >;
}

const WorkGroupMultiSelect = (props: WorkGroupMultiSelectProps) => {
    const { errorMessages, setUserCollection } = props;

    const isMobile = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MaxWidth
    );

    const { t } = useLocalization<CultureResources>();
    const label = t("addUserCollectionModalWorkGroupMessage");
    const placeholder = t("addWorkGroupsShare");

    const { workGroupRecords: workGroups } = useWorkGroupReadService();

    const workGroupOptions = createSelectOptions(workGroups);

    const handleChange = (
        selectedWorkGroups: Array<SelectOption<WorkGroupRecord, number>>
    ) => {
        const newWorkGroups = selectedWorkGroups.map(
            (selected) => selected.data!
        );

        setUserCollection((prev) => prev.with({ workGroups: newWorkGroups }));
    };

    return (
        <MultiSelect
            dropUp={isMobile}
            errorMessages={errorMessages}
            isValid={CollectionUtils.isEmpty(errorMessages)}
            label={label}
            menuPosition="fixed"
            onChange={handleChange}
            options={workGroupOptions}
            placeholder={placeholder}
        />
    );
};

export default WorkGroupMultiSelect;

const createSelectOptions = (
    workGroups: WorkGroupRecord[]
): Array<SelectOption<WorkGroupRecord, number>> =>
    workGroups
        .map((workGroup) => ({
            data: workGroup,
            label: workGroup.title!,
            value: workGroup.id!,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
