import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import React from "react";
import { t } from "utilities/localization-utils";

const BASE_CLASSNAME = "c-tia-changes-panel";
interface TiaChangesHeaderProps {
    handleClose: () => void;
}
const TiaChangesHeader = (props: TiaChangesHeaderProps) => {
    const { handleClose } = props;

    return (
        <header className={`${BASE_CLASSNAME}__header`}>
            <Heading
                priority={HeadingPriority.Three}
                cssClassName={`${BASE_CLASSNAME}__heading`}>
                {t("priorText")}
            </Heading>
            <Button
                accessibleText={t("closeItem", {
                    item: t("viewChanges"),
                })}
                ariaControls={"diff-panel"}
                cssClassName={`-modal-close -icon -modal-close-tertiary ${BASE_CLASSNAME}__closeButton`}
                onClick={handleClose}
                size={ButtonSizes.Medium}
                style={ButtonStyles.None}
                type={ButtonTypes.Button}>
                <Icon type={Icons.Close} size={IconSizes.Medium} />
            </Button>
        </header>
    );
};

export default TiaChangesHeader;
