import React, { useState, useEffect } from "react";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import Heading from "atoms/typography/heading";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useUserSettings from "utilities/hooks/domain/user-settings/use-user-settings";
import useModalTransition from "utilities/hooks/use-modal-transition";
import CultureRadioList from "molecules/culture-radio-list/culture-radio-list";
import LocalizationUtils, { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface LanguagePreferencesModalProps {
    isVisible: boolean;
    onHeaderClose: () => void;
    onCancel: () => void;
    onSave: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-language-preferences-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Modal
// -----------------------------------------------------------------------------------------

const LanguagePreferencesModal: React.FunctionComponent<LanguagePreferencesModalProps> =
    (props: LanguagePreferencesModalProps) => {
        // -----------------------------------------------------------------------------------------
        // #region Constructor
        // -----------------------------------------------------------------------------------------

        const { isVisible, onCancel, onHeaderClose, onSave } = props;

        // #endregion Constructor

        // -----------------------------------------------------------------------------------------
        // #region Hooks
        // -----------------------------------------------------------------------------------------

        const { globalState, setGlobalState } = useGlobalState();
        const { createOrUpdate: createOrUpdateUserSettings } =
            useUserSettings();
        const transitionEffect = useModalTransition();

        // #endregion Hooks

        // -----------------------------------------------------------------------------------------
        // #region State
        // -----------------------------------------------------------------------------------------

        const currentUserId = globalState.currentIdentity?.userId();
        const preferredLanguageSetting =
            globalState.getUserSetting("PreferredLanguage");
        const [preferredLanguage, setPreferredLanguage] = useState<
            string | undefined
        >(preferredLanguageSetting?.value);

        // #endregion State

        // -----------------------------------------------------------------------------------------
        // #region Side Effects
        // -----------------------------------------------------------------------------------------

        useEffect(
            function handlePreferredLanguage() {
                if (preferredLanguageSetting == null) {
                    return;
                }

                setPreferredLanguage(preferredLanguageSetting.value);
            },
            [preferredLanguageSetting]
        );

        // #endregion Side Effects

        // -----------------------------------------------------------------------------------------
        // #region Handlers
        // -----------------------------------------------------------------------------------------

        const handleSave = async () => {
            if (currentUserId == null) {
                return;
            }

            const result = await createOrUpdateUserSettings(
                preferredLanguageSetting?.with({
                    value: preferredLanguage,
                }),
                currentUserId
            );

            if (result == null) {
                return;
            }

            const updatedGlobalState = globalState.updateUserSetting(result);

            setGlobalState(updatedGlobalState);
            LocalizationUtils.changeCultureCode(preferredLanguage!);

            updatedGlobalState.currentIdentity?.refreshLocalStorage();

            onSave();
        };

        // #endregion Handlers

        // -----------------------------------------------------------------------------------------
        // #region Returns
        // -----------------------------------------------------------------------------------------

        return (
            <Modal
                cssClassName={`${CSS_BASE_CLASS}`}
                closeDialog={() => {}}
                isVisible={isVisible}
                label={t("preferredLanguage")}
                transition={transitionEffect}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS}__header`}>
                    <Heading priority={HeadingPriority.Two}>
                        {t("languagePreferences")}
                    </Heading>
                    <Button
                        accessibleText="Close Dialog"
                        cssClassName={"-modal-close -icon"}
                        onClick={onHeaderClose}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Tertiary}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>
                <div className={`${CSS_BASE_CLASS}__body`}>
                    <CultureRadioList
                        onCheck={setPreferredLanguage}
                        value={preferredLanguage}
                    />
                </div>
                <div className={`${CSS_BASE_CLASS}__footer`}>
                    <Button
                        dataTestId={"cancel"}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Secondary}
                        onClick={onCancel}>
                        {t("cancel")}
                    </Button>
                    <Button
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Primary}
                        onClick={handleSave}>
                        {t("saveChanges")}
                    </Button>
                </div>
            </Modal>
        );

        // #endregion Returns
    };

// #endregion Modal

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default LanguagePreferencesModal;

// #endregion Exports
