import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import EnhancedContentComponentRecord from "models/view-models/enhanced-content-component-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface AdminEnhancedContentComponentBasePathParams {}

export interface AdminEnhancedContentComponentResourcePathParams
    extends AdminEnhancedContentComponentBasePathParams {}

/**
 * Search parameters when listing EnhancedContentComponent resources.
 * These get translated to QueryString parameters
 */
export interface AdminEnhancedContentComponentListQueryParams {
    enhancedContentIds?: number[];
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const resourceType = EnhancedContentComponentRecord;
const baseEndpoint = "admin/enhancedcontentcomponents";
const key = "admin-enhancedcontentcomponents";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AdminEnhancedContentComponentService = {
    /**
     * Retrieves a list of EnhancedContent resources based upon supplied search criteria
     */
    list: ServiceFactory.list<
        EnhancedContentComponentRecord,
        AdminEnhancedContentComponentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        EnhancedContentComponentRecord,
        AdminEnhancedContentComponentListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useListQuery<
        EnhancedContentComponentRecord,
        AdminEnhancedContentComponentListQueryParams
    >(key, resourceType, baseEndpoint),

    useBatchedList: ServiceHookFactory.useBatchedList<
        EnhancedContentComponentRecord,
        AdminEnhancedContentComponentListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AdminEnhancedContentComponentService;

// #region Exports
