import React, { useRef, useState } from "react";
import { IconSizes } from "../../atoms/constants/icon-sizes";
import { Icons } from "../../atoms/constants/icons";
import Icon from "../../atoms/icons/icon";
import Paragraph from "../../atoms/typography/paragraph";
import { AppNameWithHtml, NfpaWithHtml } from "constants/app-name-tm";

const AzureGoLiveBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    const onBannerClick = () => {
        const height = divRef.current?.offsetHeight;
        divRef.current?.style.setProperty("--marginOffset", `-${height}px`);

        setShowBanner(false);
    };

    const divRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={`c-azure-go-live-banner ${!showBanner && "hide"}`}
            ref={divRef}>
            <button
                onClick={onBannerClick}
                className="c-azure-go-live-banner__content">
                <Paragraph cssClassName="description">
                    <span dangerouslySetInnerHTML={{ __html: NfpaWithHtml }} />{" "}
                    is upgrading their single sign on. Starting in November your
                    login screens for{" "}
                    <span
                        dangerouslySetInnerHTML={{ __html: AppNameWithHtml }}
                    />{" "}
                    will look a little different. You will be prompted to reset
                    your password the first time you log in following the
                    change. Once reset, you will continue using{" "}
                    <span
                        dangerouslySetInnerHTML={{ __html: AppNameWithHtml }}
                    />{" "}
                    exactly as you do today!
                </Paragraph>
                <span className="close-button">
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </span>
            </button>
        </div>
    );
};

export default AzureGoLiveBanner;
