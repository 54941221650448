import React from "react";
import TableDisplayRecord from "models/view-models/table-display-record";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import useRemoveWhitespaceChildren from "utilities/hooks/use-remove-whitespace-children";

interface TableHeadingProps extends XmlConvertedComponentProps {
    colspan: number;
    rowspan: number;
    width: string;
}

const TableHeading: React.FunctionComponent<TableHeadingProps> = (props) => {
    const { children, colspan, rowspan, codStyle, width } = props;
    const filteredChildren = useRemoveWhitespaceChildren(children);
    const table = new TableDisplayRecord({ codStyle, width: width });

    return (
        <th colSpan={colspan} rowSpan={rowspan} style={table.getStyle()}>
            {filteredChildren}
        </th>
    );
};

export default TableHeading;
