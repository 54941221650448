export enum NfpaUrlPaths {
    CustomerSupport = "customer-support/customer-service-inquiry",
    ForgottenPassword = "forgottenpassword",
    LicenseAgreement = "linklicense",
    LiNK = "LiNK",
    MyProfile = "my-profile?personalinfo",
    PrivacyPolicy = "privacypolicy",
    TermsOfUse = "termsofuse",
    Login = "Login",
}
//https://stage-www.nfpa.org/user/my-account/account%20information
