import React, { useState } from "react";
import PublicationRecord from "models/view-models/publication-record";
import PublicationSelectionListGroupItem, {
    PublicationSelectionListGroupIteStyle,
} from "organisms/modals/publication-selection-modal/publication-selection-list-group-item";
import useFiles from "utilities/hooks/domain/files/use-files";
import FileRecord from "models/view-models/file-record";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { useLocalization } from "utilities/hooks/use-localization";
import Heading from "atoms/typography/heading";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface DashboardFeaturedPublicationsProps {
    featuredPublications: PublicationRecord[];
    onFavoriteSelection: (
        favorites: Array<UserPublicationFavoriteRecord>
    ) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-dashboard-featured-publications";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DashboardFeaturedPublications = (
    props: DashboardFeaturedPublicationsProps
) => {
    const { featuredPublications: publications, onFavoriteSelection } = props;

    const coverImageFileIds = getCoverImageFileIds(publications);

    const { resultObject: coverImageFiles } = useFiles({
        ids: coverImageFileIds,
    });

    const [showFeaturedPublications, setShowFeaturedPublications] =
        useState(true);
    const { t } = useLocalization();

    const featuredPublications = publications.map(
        withCoverImages(coverImageFiles)
    );

    const featuredPublicationsText = t("featuredPublications");
    const showHideKey = showFeaturedPublications ? "hide" : "show";

    const handleShowHidePublications = () =>
        setShowFeaturedPublications((prev) => !prev);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading>{featuredPublicationsText}</Heading>
                <Button
                    accessibleText={t("actionItem", {
                        action: t(showHideKey),
                        item: featuredPublicationsText,
                    })}
                    onClick={handleShowHidePublications}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.Anchor}
                    type={ButtonTypes.Button}>
                    {t(showHideKey)}
                </Button>
            </div>

            {showFeaturedPublications && (
                <div className={`${CSS_CLASS_NAME}__list`}>
                    {featuredPublications.map((publication) => (
                        <PublicationSelectionListGroupItem
                            coverImageFile={publication.coverImageFile}
                            key={publication.id}
                            onChangeFavorites={onFavoriteSelection}
                            publication={publication}
                            style={
                                PublicationSelectionListGroupIteStyle.TitleFirst
                            }
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getCoverImageFileIds = (publications: PublicationRecord[]): number[] => {
    const coverImageFileIds = publications
        .filter(
            (publication) =>
                publication.coverImageFileId != null &&
                publication.coverImageFile == null
        )
        .map((publication) => publication.coverImageFileId!);

    return coverImageFileIds;
};

const withCoverImages = (files: FileRecord[]) => {
    return (publication: PublicationRecord): PublicationRecord => {
        const coverImageFile = files.find(
            (file) => file.id === publication.coverImageFileId
        );

        if (coverImageFile == null) {
            return publication;
        }

        return publication.with({ coverImageFile });
    };
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default DashboardFeaturedPublications;

// #endregion Exports
