import { Workbox, messageSW } from "workbox-window";
import { useRef, useEffect, useState, useCallback } from "react";
import {
    WorkboxLifecycleEvent,
    WorkboxLifecycleWaitingEvent,
} from "workbox-window/utils/WorkboxEvent";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface WorkboxOptions {
    serviceWorkerPath: string;
}

interface WorkboxHookState {
    isReadyToReload: boolean;
    hasUpdate: boolean;
    serviceWorker?: ServiceWorker;
    serviceWorkerState?: ServiceWorkerState;
}

interface UseWorkboxHook extends WorkboxHookState {
    isEnabled: boolean;
    sendServiceWorkerMessage: <T = any>(data: T) => void;
}

interface ServiceWorkerConfiguration {
    enabled: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultState: WorkboxHookState = {
    isReadyToReload: false,
    hasUpdate: false,
    serviceWorker: undefined,
    serviceWorkerState: undefined,
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export function useWorkbox(options: WorkboxOptions): UseWorkboxHook {
    const serviceworkerConfiguration = useRef<ServiceWorkerConfiguration>(
        getServiceWorkerConfiguration()
    );
    const serviceWorkerPathRef = useRef(options.serviceWorkerPath);

    const [
        { hasUpdate, isReadyToReload, serviceWorker, serviceWorkerState },
        setState,
    ] = useState(defaultState);

    const isServiceWorkerDisabled = () =>
        !serviceworkerConfiguration.current.enabled;

    const sendServiceWorkerMessage = useCallback(
        async <T = any>(data: T): Promise<any> => {
            if (serviceWorker != null) {
                await messageSW(serviceWorker, data);
            }
        },
        [serviceWorker]
    );

    useEffect(() => {
        if (isServiceWorkerDisabled()) {
            unregisterServiceWorker();
            return;
        }

        const currentWorkbox = new Workbox(serviceWorkerPathRef.current, {
            scope: "/",
        });

        if (currentWorkbox == null) {
            setState((prev) => ({
                ...prev,
                isLoading: false,
            }));
            return;
        }

        currentWorkbox.getSW().then((sw) => {
            if (sw == null) {
                return;
            }

            setState((prev) => ({
                ...prev,
                serviceWorker: sw,
                serviceWorkerState: sw.state,
            }));
        });

        const handleControlling = (event: WorkboxLifecycleEvent) => {
            if (event.isUpdate) {
                setState((prev) => ({
                    ...prev,
                    isReadyToReload: true,
                    serviceWorkerState: "installed",
                }));
            }
        };

        const handleWaiting = (event: WorkboxLifecycleWaitingEvent) => {
            setState((prev) => ({
                ...prev,
                hasUpdate: true,
                serviceWorker: event.sw ?? prev.serviceWorker,
                serviceWorkerState:
                    event.sw?.state ?? prev.serviceWorker?.state,
            }));
        };

        const handleStateChange = (event: WorkboxLifecycleEvent) => {
            setState((prev) => ({
                ...prev,
                serviceWorker: event.sw ?? prev.serviceWorker,
                serviceWorkerState:
                    event.sw?.state ?? prev.serviceWorker?.state,
            }));
        };

        currentWorkbox.addEventListener("controlling", handleControlling);

        // Add an event listener to detect when the registered
        // service worker has installed but is waiting to activate.
        // @ts-ignore
        currentWorkbox.addEventListener("externalwaiting", handleWaiting);
        currentWorkbox.addEventListener("waiting", handleWaiting);
        currentWorkbox.addEventListener("activated", handleStateChange);
        currentWorkbox.addEventListener("redundant", handleStateChange);

        currentWorkbox.register();

        return function cleanup() {
            currentWorkbox.removeEventListener(
                "controlling",
                handleControlling
            );

            currentWorkbox.removeEventListener(
                // @ts-ignore
                "externalwaiting",
                handleWaiting
            );
            currentWorkbox.removeEventListener("waiting", handleWaiting);
            currentWorkbox.removeEventListener("activated", handleStateChange);
            currentWorkbox.removeEventListener("redundant", handleStateChange);
        };
    }, []);

    return {
        hasUpdate,
        isEnabled: serviceworkerConfiguration.current.enabled,
        isReadyToReload,
        sendServiceWorkerMessage,
        serviceWorker,
        serviceWorkerState,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Provides a way to override and disable the service worker. Especially useful for Cypress tests
 * where the service worker needs to be disabled.
 */
function getServiceWorkerConfiguration(): ServiceWorkerConfiguration {
    if (!("serviceWorker" in navigator)) {
        return { enabled: false };
    }

    const enabled =
        process.env.REACT_APP_ENABLE_SERVICE_WORKER === "true" &&
        window.navigator.userAgent !== "CypressAutomation";

    return { enabled };
}

function unregisterServiceWorker() {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
    }
}

// #endregion Functions
