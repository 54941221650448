import { useState } from "react";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { ServiceWorkerMessageMap } from "utilities/service-worker/interfaces/messages/service-worker-message-map";

export default function useServiceWorkerMessage<
    T extends keyof ServiceWorkerMessageMap
>(messageType: T) {
    const [events, setEvents] = useState<Array<ServiceWorkerMessageMap[T]>>([]);

    useOnServiceWorkerMessageEvent(
        messageType,
        (event: ServiceWorkerMessageMap[T]) => {
            setEvents((prev) => [...prev, event]);
        }
    );

    return {
        events,
    };
}
