import {
    DependencyList,
    RefObject,
    useCallback,
    useEffect,
    useState,
} from "react";
import useWindowContext from "utilities/contexts/window/use-window-context";

/**
 * A custom hook for determining if elements overflow their containers.
 * Useful for when you have text-overflow: ellipsis; and you want to
 * detect whether the text is actually long enough to trigger the ellipsis
 * to appear.
 * @param ref
 * @param dependencies optionally add extra dependencies to update the value
 */
export default function useTextOverflow<T extends HTMLElement>(
    ref: RefObject<T>,
    dependencies?: DependencyList
) {
    const getIsOverflowed = useCallback((): boolean => {
        if (ref.current == null) {
            return false;
        }

        return ref.current.offsetWidth < ref.current.scrollWidth;
    }, [ref]);

    const { width, height } = useWindowContext();
    const [isOverflowed, setIsOverflowed] = useState(getIsOverflowed());

    useEffect(
        () => setIsOverflowed(getIsOverflowed()),
        [getIsOverflowed, width, height, dependencies]
    );

    return isOverflowed;
}
