import { siteMap } from "internal-sitemap";
import LandingPageFAQsSection from "organisms/landing-page-sections/landing-page-faqs-section";
import LandingPageHero from "organisms/landing-page-sections/landing-page-hero";
import LandingPagePlansSection from "organisms/landing-page-sections/landing-page-plans-section";
import LandingPageSpotlightsSection from "organisms/landing-page-sections/landing-page-spotlights-section";
import React from "react";
import { Redirect } from "react-router-dom";
import OnboardingLayoutV2 from "templates/onboarding-layout-v2";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

const LandingPage = () => {
    const { globalState } = useGlobalState();
    const { useAzureB2CForSSO } = useFeatureFlags();

    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    if (globalState.isAuthenticated())
        return <Redirect to={siteMap.dashboards.user} />;

    if (!globalState.isAuthenticated() && !useAzureB2CForSSO)
        return <Redirect to={siteMap.userlogins.new} />;

    return (
        <OnboardingLayoutV2>
            <LandingPageHero />
            {!subscriptionFreezeActive && <LandingPagePlansSection />}
            <LandingPageSpotlightsSection />
            <LandingPageFAQsSection />
        </OnboardingLayoutV2>
    );
};

export default LandingPage;
