import { useLocalization } from "utilities/hooks/use-localization";
import React, { useState } from "react";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Modal, { ModalProps } from "molecules/modals/modal";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHistory } from "react-router-dom";
import EcommerceRedirectionModal from "organisms/subscription-card/ecommerce-redirection-modal";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import { BillingCycleOption } from "constants/billing-cycle-options";
import SubscriptionType from "organisms/enums/subscription-type";
import TeamSizeSelect from "organisms/subscription-card/team-size-select";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import SubscriptionTypeLabel from "molecules/subscription-type-label/subscription-type-label";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import BillingCycleCards from "./billing-cycle-cards";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SelectBillingCycleModalV2Props
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    onCheck: (option?: BillingCycleOption) => void;
    selectedBillingCycle?: BillingCycleOption;
    selectedSubscriptionType?: SubscriptionType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-select-billing-cycle-modal-v2";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectBillingCycleModalV2: React.FunctionComponent<SelectBillingCycleModalV2Props> =
    (props) => {
        const {
            closeDialog,
            isVisible,
            onCheck: handleCheck,
            selectedBillingCycle,
            selectedSubscriptionType,
        } = props;

        const { t } = useLocalization<CultureResources>();

        const [teamSize, setTeamSize] = useState<SubscriptionTeamSize>();
        const [hasBillingCycleError, setHasBillingCycleError] =
            useState<boolean>(false);
        const [hasTeamSizeError, setHasTeamSizeError] =
            useState<boolean>(false);
        const [redirecting, setRedirecting] = useState<boolean>(false);
        const { globalState } = useGlobalState();
        const history = useHistory();

        const { useAzureB2CForSSO } = useFeatureFlags();

        const [confirmButtonText] = useState<string>(
            globalState.isAuthenticated()
                ? t("billingCycle-goToNfpaToPurchase")
                : t("continueToPurchase")
        );

        const onClose = () => {
            if (closeDialog != null) {
                closeDialog();
            }

            // Wipe out their selected plan if they are closing the modal.
            handleCheck(undefined);
        };

        const handleBillingCycleCheck = (option: BillingCycleOption) => {
            setHasBillingCycleError(false);
            handleCheck(option);
        };

        const handleTeamSizeChange = (teamSize: SubscriptionTeamSize) => {
            setHasTeamSizeError(false);
            setHasBillingCycleError(false);
            setTeamSize(teamSize);
            handleCheck(undefined);
        };

        const handleConfirm = () => {
            if (
                selectedSubscriptionType === SubscriptionType.Team &&
                teamSize == null
            ) {
                setHasTeamSizeError(true);
                return;
            }

            if (
                selectedBillingCycle == null ||
                selectedSubscriptionType == null
            ) {
                setHasBillingCycleError(true);
                return;
            }
            const autoRenew =
                selectedBillingCycle.autoRenew === "Off" ? "No" : "Yes";

            if (useAzureB2CForSSO) {
                // Change on path, all options are known
                const route = globalState?.systemSettings?.getNewEcommerceUrl(
                    selectedSubscriptionType,
                    {
                        "Number of Licenses": teamSize,
                        "Subscription Term": selectedBillingCycle.cycle,
                        "Automatically renew?": autoRenew,
                    }
                );
                window.location.href = route!;
            } else {
                if (globalState.isAuthenticated()) {
                    setRedirecting(true);
                    return;
                }

                // If we are unauthenticated, we need to redirect to the registration page. No need for the
                // redirection timeout.
                history.push(
                    RouteUtils.getRegistrationPageRoute(
                        selectedSubscriptionType,
                        selectedBillingCycle?.pin
                    )
                );
                return;
            }

            if (redirecting) {
                return (
                    <EcommerceRedirectionModal
                        pin={selectedBillingCycle!.pin}
                        transition={ModalTransitions.None}
                    />
                );
            }
        };

        const subscriptionType =
            selectedSubscriptionType != null
                ? t(
                      `subscriptionType-${selectedSubscriptionType.toString()}` as keyof CultureResources
                  )
                : "";

        return (
            <Modal
                closeDialog={onClose}
                cssClassName={CSS_BASE_CLASS_NAME}
                isVisible={isVisible}
                label={t("billingCycle-selectABillingCycle", {
                    subscriptionType,
                })}
                transition={ModalTransitions.Fade}
                type={ModalTypes.Base}>
                <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                    <div className={`${CSS_BASE_CLASS_NAME}__heading`}>
                        <SubscriptionTypeLabel
                            textCssClassName={`${CSS_BASE_CLASS_NAME}__heading-text`}
                            type={selectedSubscriptionType!}
                            showSize={false}
                        />
                    </div>
                    <ModalCloseButton onClick={onClose} />
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    {selectedSubscriptionType === SubscriptionType.Team && (
                        <TeamSizeSelect
                            labelText={t("selectYourTeamSize")}
                            labelCssClassName={`${CSS_BASE_CLASS_NAME}__team-select-label`}
                            errored={hasTeamSizeError}
                            onChange={handleTeamSizeChange}
                            value={teamSize}
                        />
                    )}
                    <BillingCycleCards
                        type={selectedSubscriptionType}
                        teamSize={teamSize}
                        handleSelect={handleBillingCycleCheck}
                        selectedBillingCycleOption={selectedBillingCycle}
                        errored={hasBillingCycleError}
                    />
                    <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                        <Button
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Primary}
                            onClick={handleConfirm}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SelectBillingCycleModalV2;

// #endregion Export
