import SolutionsPageContextRecord from "models/view-models/situational-navigation/solutions/solutions-page-context-record";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type SolutionsPageContextUpdater = Dispatch<
    SetStateAction<SolutionsPageContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: SolutionsPageContextRecord =
    new SolutionsPageContextRecord();
const defaultUpdater: SolutionsPageContextUpdater = () => {};
const SolutionsPageContext = createContext([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useSolutionsPageContext() {
    const [solutionsPageContext, setSolutionsPageContext] =
        useContext(SolutionsPageContext);
    return {
        solutionsPageContext:
            solutionsPageContext as SolutionsPageContextRecord,
        setSolutionsPageContext:
            setSolutionsPageContext as SolutionsPageContextUpdater,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionsPageContext;

// #endregion Exports
