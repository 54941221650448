import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Trans from "atoms/i18n/trans";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import FAQs from "pages/support/faqs";
import React from "react";
import { t } from "utilities/localization-utils";
import LandingPageSectionWrapper, {
    LandingPageSectionIds,
} from "./landing-page-section-wrapper";

const HEADING_CLASS = "c-landing-page-faq__heading";
const ADDITIONAL_CLASS = "c-landing-page-faq__additional";

const LandingPageFAQsSection = () => {
    const supportPageAnchor = (
        <Anchor cssClassName={ADDITIONAL_CLASS} to={siteMap.support.faq}>
            {{ appName: AppNameTm }} support page
        </Anchor>
    );

    return (
        <LandingPageSectionWrapper id={LandingPageSectionIds.FAQ}>
            <Heading
                cssClassName={HEADING_CLASS}
                priority={HeadingPriority.Two}>
                {t("faq")}
            </Heading>
            <FAQs limit={5} />
            <div>
                <Trans i18nKey="moreQuestions">
                    <Paragraph cssClassName={ADDITIONAL_CLASS}>
                        Have more questions? Visit the
                        {supportPageAnchor}
                        to view more detailed FAQs.
                    </Paragraph>
                </Trans>
            </div>
        </LandingPageSectionWrapper>
    );
};

export default LandingPageFAQsSection;
