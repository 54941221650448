import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import ButtonGroup from "organisms/button-groups/button-group";
import React from "react";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publish-status-filters-bar";
export interface ContentFormatFiltersBarProps {
    value: PublicationContentFormatType | undefined;
    onChange: (contentType: PublicationContentFormatType | undefined) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContentFormatFiltersBar: React.FC<ContentFormatFiltersBarProps> = (
    props: ContentFormatFiltersBarProps
) => {
    const getSelectedCssClass = (
        allPublications: boolean = true,
        contentType?: PublicationContentFormatType
    ): string => {
        if (allPublications === true && props.value === undefined) {
            return "-selected";
        }
        if (props.value === contentType) {
            return "-selected";
        }
        return "";
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <ButtonGroup cssClassName={`${CSS_CLASS_NAME}__button-group`}>
                <Button
                    accessibleText={`show PDF content files`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                        false,
                        PublicationContentFormatType.Xml
                    )}`}
                    type={ButtonTypes.Button}
                    onClick={() =>
                        props.onChange(PublicationContentFormatType.Xml)
                    }
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Paragraph size={ParagraphSizes.XSmall}>XML</Paragraph>
                </Button>
                <Button
                    accessibleText={`show PDF content files`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                        false,
                        PublicationContentFormatType.Image
                    )}`}
                    type={ButtonTypes.Button}
                    onClick={() =>
                        props.onChange(PublicationContentFormatType.Image)
                    }
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Paragraph size={ParagraphSizes.XSmall}>PDF</Paragraph>
                </Button>
                <Button
                    accessibleText={`show all content files`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item -all ${getSelectedCssClass(
                        true
                    )}`}
                    type={ButtonTypes.Button}
                    onClick={() => props.onChange(undefined)}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Paragraph size={ParagraphSizes.XSmall}>All</Paragraph>
                </Button>
            </ButtonGroup>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ContentFormatFiltersBar;

// #endregion Exports
