import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import Icon from "atoms/icons/icon";
import Title, { TitleConverterId } from "atoms/titles/title-converter";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import TableDisplayRecord from "models/view-models/table-display-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import MenuButton from "molecules/menu-button/menu-button";
import Modal from "molecules/modals/modal";
import TableHeader from "molecules/tables/table-header-converter";
import BookmarkSettings from "organisms/section-detail/bookmark-settings";
import BookmarkSlider from "organisms/section-detail/bookmark-slider";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import { useLinkPreviewContext } from "utilities/contexts/link-preview/use-link-preview-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { tForCulture } from "utilities/localization-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import MobileSimpleCard from "organisms/section-detail-components/table-section-simple-mobile-card";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import useBookmarkActions from "utilities/hooks/domain/user-bookmarks/use-bookmark-actions";
import useRenderBookmarks from "utilities/hooks/domain/user-bookmarks/use-render-bookmarks";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import ChangeIndicatorDiffPanel from "organisms/panels/change-indicator-diff/change-indicator-diff-panel";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useModalActions from "utilities/hooks/use-modal-actions";
import AddEditChangeSummariesModal from "organisms/panels/change-indicator-diff/change-summaries/add-edit-change-summaries-modal";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import { DiffPanelContext } from "organisms/panels/change-indicator-diff/change-indicator-diff-content";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { RefPanelSectionContext } from "molecules/preview/section-content-preview";
import useTias from "utilities/hooks/domain/publications/use-tias";

const BASE_CLASS_NAME = "c-table-section";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TableSectionProps extends XmlConvertedComponentProps {
    nfpaLabel?: string;
    pageWide?: string;
    tableWidth?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableSection: React.FunctionComponent<TableSectionProps> = (
    props: TableSectionProps
) => {
    const {
        externalId,
        diffchanged,
        codStyle,
        nfpaLabel: originalNfpaLabel,
        tableWidth,
    } = props;

    const nfpaLabel = originalNfpaLabel ?? "";

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();
    const expandTable = t("expandItem", { item: t("table") });
    const tableView = t("itemView", { item: t("table") });

    const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
    const [isPinned, setIsPinned] = useState<boolean>(false);
    const [isTableView, setIsTableView] = useState<boolean>(false);
    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );
    const { isOpen: isReferencePanelOpen, handleClose: closeReferencePanel } =
        useReferencePanelContext();
    const { isModalOpen: isLinkPreviewModalOpen } = useLinkPreviewContext();
    const { pathname } = useLocation();
    const {
        addTableBookmark,
        useSimpleTableCards,
        useSimpleTableStyle,
        useEnhancedChangeIndicators,
    } = useFeatureFlags();
    const { isOnline } = useNetworkInformation();

    const { handleOpen, content } = useChangeIndicatorDiffPanel();
    const { isTiaPanelOpen, closePanels } = useTias();
    const titleClassName: string = `${BASE_CLASS_NAME}`;
    const distanceToTopOfTable = 55;

    const handleDiffPanelOpen = () => {
        if (isReferencePanelOpen) closeReferencePanel();
        if (isTiaPanelOpen) closePanels();

        handleOpen({
            externalId: externalId,
            titleClassName: titleClassName,
            changes: diffchanged,
            additionalTopPadding: distanceToTopOfTable,
        });
    };

    // #endregion Hooks

    const [currentBookmark, setCurrentBookmark] = useState(
        new UserBookmarkRecord()
    );
    const { globalState } = useGlobalState();

    const {
        handleBookmarksReadOnlyDialogClose,
        handleBookmarkSettingsClose,
        handleBookmarksReadOnlyDialogOpen,
        bookviewLayoutContext,
    } = useBookviewLayoutContext();

    const { publication } = useCurrentPublication();

    const currentIdentity = globalState.currentIdentity;

    const userId = currentIdentity?.userId() ?? 0;

    const isAdminOrPublisher =
        currentIdentity?.isAdmin() || currentIdentity?.isAuthorOrPublisher();

    const { useChangeSummaries } = useFeatureFlags();

    const createdOnPublicationId = publication?.id;

    const groupId =
        globalState.currentIdentity?.getCurrentTeam()?.id ?? undefined;

    const { bookmarks } = useBookmarks();
    const tableBookmarks = bookmarks
        .filter((b) => b.externalId === externalId)
        .sort(getCurrentUserBookmarkComparator(userId));

    const { handleAddBookmark, handleEditBookmark, handleViewBookmarks } =
        useBookmarkActions({
            userBookmarkType: UserBookmarkType.Table,
            createdOnPublicationId: createdOnPublicationId,
            color: UserBookmarkColors.Red,
            externalId: externalId,
            userId: userId,
            groupId: groupId,
            setCurrentBookmark: setCurrentBookmark,
            bookmarks: tableBookmarks,
        });

    const { renderPrimaryBookmark, renderSecondaryBookmark } =
        useRenderBookmarks({ baseClass: BASE_CLASS_NAME });

    const bookmarksDistinctByColor = CollectionUtils.distinctBy(
        tableBookmarks,
        (b: UserBookmarkRecord) => b.color
    );

    const bookmarkCount = CollectionUtils.length(tableBookmarks);
    const addBookmarkButtonText = t("addItem", { item: t("bookmark") });

    const bookmarkButtonAccessibleText = t("itemCount", {
        count: bookmarkCount,
        item: t("bookmark", { count: bookmarkCount }),
    });

    const {
        isOpen: isChangeSummaryModalOpen,
        handleClose: onAddChangeSummaryClose,
        handleOpen: onAddChangeSummaryClick,
    } = useModalActions();

    const { routeIsMyLink: isMyLink } = useIsNestedRoute();
    const isInDiffPanel = useContext(DiffPanelContext);
    const isInReferencePanel = useContext(RefPanelSectionContext);
    const isShareLink = !globalState.isAuthenticated();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    // #endregion Handlers
    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    if (diffchanged === XmlChangeNotationConstants.DELETION) {
        return null;
    }

    const modalHeaderClass = `${BASE_CLASS_NAME}-modal-header`;
    let cssClassName = `${BASE_CLASS_NAME}__body`;

    const table = new TableDisplayRecord({ codStyle });

    if (tableWidth != null) {
        cssClassName += ` -${tableWidth}`;
    }

    const showChangeSummariesModal =
        useChangeSummaries &&
        isChangeSummaryModalOpen &&
        publication?.code != null &&
        publication?.edition != null;

    // if there is a <desc> tag, we need to put it inside the <caption> HTML element,
    // where the XML <title> element goes.
    const childrenArray = React.Children.toArray(props.children);
    const descriptionChild = childrenArray.find((e) => e?.type === "desc");

    const tableTitle = childrenArray.find(
        (child) => child?.props?.converterId === TitleConverterId
    );

    let title = (
        <Title
            description={descriptionChild?.props?.children}
            nfpaLabel={nfpaLabel}
            tagName={"title"}
            {...tableTitle?.props}></Title>
    );

    const headerChildren = childrenArray.filter(
        (child) =>
            child?.props?.converterId === "tr" && React.isValidElement(child)
    );

    const children = childrenArray.filter(
        (child) =>
            child?.props?.converterId !== TitleConverterId &&
            child?.props?.converterId !== "tr"
    );

    const bodyChildren = children.filter(
        (child) => child?.props?.converterId !== "tfoot"
    );

    const isSimple =
        useSimpleTableStyle && isSimpleTable(headerChildren, bodyChildren);

    cssClassName = isSimple ? `${cssClassName} -simple` : cssClassName;
    const style = isSimple ? table.getStyleWithoutBorders() : table.getStyle();

    const hasNoTitle = title.props.children === undefined ? "-no-title" : "";

    if (descriptionChild?.props?.children != null && title.type !== Title) {
        // if a description exists but not a title, we need to manually add the description
        title = (
            <Title
                description={descriptionChild.props.children}
                key={childrenArray.length}
                tagName={"title"}
                parentNode={"table"}
            />
        );
    }

    const useSticky = isPinned ? "-sticky" : "";

    const tHead = (
        <TableHeader contents={headerChildren} key={0} tagName="tHead" />
    );

    const modalChildren = [tHead, ...children];
    const bookviewChildren = [title, ...modalChildren];

    const toggleHeaderPin = () => {
        setIsPinned(!isPinned);
    };
    const toggleTableViewPin = () => {
        setIsTableView(!isTableView);
    };
    const closeModal = () => {
        setIsPinned(false);
        setShowFullScreen(false);
    };
    const pin = `${t("pin")} ${t("header")}`;
    const unpin = `${t("unpin")} ${t("header")}`;
    const toggleText = isPinned ? unpin : pin;

    const openForExternalId =
        bookviewLayoutContext.bookmarkSettingsOpenForExternalId;
    const readonlyOpenForExternalId =
        bookviewLayoutContext.bookmarkReadonlyOpenForExternalId;

    const renderReadonlySlider =
        readonlyOpenForExternalId != null &&
        readonlyOpenForExternalId === externalId;
    const renderSettings =
        openForExternalId != null && openForExternalId === externalId;

    const hideMenuButton =
        isMyLink ||
        pathname.includes("situational-navigation") ||
        isLinkPreviewModalOpen ||
        isInDiffPanel ||
        isInReferencePanel ||
        isShareLink;

    const showViewPriorVersionsButton =
        !isInDiffPanel &&
        useEnhancedChangeIndicators &&
        (diffchanged === XmlChangeNotationConstants.CHANGED ||
            diffchanged === XmlChangeNotationConstants.HAS_CHANGES ||
            diffchanged === XmlChangeNotationConstants.NEW_MATERIAL) &&
        !isMyLink;

    const tableAndLabel = `${tForCulture(
        publication?.locale,
        "table"
    )} ${nfpaLabel} `;

    const displayTitle =
        diffchanged != null
            ? `${tableAndLabel} ${diffchangedTitleBuilder(title)}`
            : `${tableAndLabel} ${title.props.children}`;

    const addChangeSummaryLabel = t("add-change-summary");

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------
    if (useSimpleTableCards && isMobile && isSimple && showFullScreen) {
        cssClassName =
            isTableView !== true
                ? `${cssClassName} -mobile`
                : `${cssClassName}`;
        return (
            <Modal
                cssClassName={`${BASE_CLASS_NAME}-modal`}
                closeButtonStyle={ModalCloseButtonStyle.Hidden}
                closeDialog={closeModal}
                isVisible={showFullScreen}
                label={tableView}
                transition={ModalTransitions.Fade}
                type={ModalTypes.Fullscreen}>
                <div className={`${modalHeaderClass} modal-header-center`}>
                    <div
                        className={`${modalHeaderClass}-toggle modal-toggle-header-center`}>
                        <Button
                            accessibleText={toggleText}
                            onClick={toggleTableViewPin}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Tertiary}>
                            {!isTableView && (
                                <Icon
                                    type={Icons.ToggleTable}
                                    size={IconSizes.Large}
                                />
                            )}
                            {isTableView && (
                                <Icon
                                    type={Icons.ToggleCard}
                                    size={IconSizes.Large}
                                />
                            )}
                        </Button>
                    </div>
                    <div className="title-container">
                        {title}
                        <Button
                            accessibleText={toggleText}
                            onClick={closeModal}
                            size={ButtonSizes.Medium}
                            style={ButtonStyles.Tertiary}>
                            <Icon type={Icons.Close} size={IconSizes.Large} />
                        </Button>
                    </div>
                </div>
                <div id={props.externalId} className={BASE_CLASS_NAME}>
                    <div className={`${BASE_CLASS_NAME} -container`}>
                        {!isTableView && (
                            <div
                                className={cssClassName
                                    .replace("-three-quarters", "")
                                    .replace("-full", "")
                                    .replace("-half", "")}>
                                <MobileSimpleCard
                                    tTitle={title}
                                    tHead={headerChildren}
                                    children={children}></MobileSimpleCard>
                            </div>
                        )}
                        {isTableView && (
                            <div
                                className={cssClassName
                                    .replace("-simple", "")
                                    .replace("-three-quarters", "")
                                    .replace("-full", "")
                                    .replace("-half", "")
                                    .replace("-mobile", "")}>
                                <div className={`${cssClassName} -no-margin`}>
                                    <table
                                        className={useSticky}
                                        id={externalId}
                                        style={style}>
                                        {modalChildren}
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    } else {
        return (
            <div id={props.externalId} className={`${BASE_CLASS_NAME}`}>
                <div className={`${BASE_CLASS_NAME} -container`}>
                    {CollectionUtils.hasValues(tableBookmarks) &&
                        !hideMenuButton && (
                            <div className={`${BASE_CLASS_NAME}__notes`}>
                                <Button
                                    cssClassName={`${
                                        bookmarkCount > 1 && "multi"
                                    }`}
                                    accessibleText={
                                        bookmarkButtonAccessibleText
                                    }
                                    style={ButtonStyles.TertiaryAlt}
                                    onClick={() =>
                                        handleBookmarksReadOnlyDialogOpen(
                                            externalId!
                                        )
                                    }>
                                    {renderPrimaryBookmark(
                                        bookmarksDistinctByColor[0],
                                        bookmarkCount
                                    )}
                                    {CollectionUtils.length(
                                        bookmarksDistinctByColor
                                    ) > 1 &&
                                        renderSecondaryBookmark(
                                            bookmarksDistinctByColor.slice(1)
                                        )}
                                </Button>
                            </div>
                        )}
                    <MenuButton
                        buttonAccessibleText={t("actionsForItem", {
                            item: t("table"),
                        })}
                        dataTestId={"table-detail-actions-menu-button"}
                        icon={Icons.MoreHorizontal}
                        iconSize={IconSizes.Base}
                        ignoreOffline={true}
                        modalClassName={`${BASE_CLASS_NAME}__modal`}
                        triggerButtonStyle={ButtonStyles.Tertiary}
                        useOverlay={true}
                        trackOutsideClicksToClose={true}>
                        <Button
                            accessibleText={expandTable}
                            onClick={() => setShowFullScreen(true)}>
                            <Icon type={Icons.Enlarge} />
                            {expandTable}
                        </Button>
                        {addTableBookmark && !hideMenuButton && isOnline && (
                            <Button
                                dataTestId={"bookmark"}
                                onClick={handleAddBookmark}
                                disabled={!isOnline}>
                                <Icon type={Icons.Bookmark} />
                                {addBookmarkButtonText}
                            </Button>
                        )}
                        {!hideMenuButton && bookmarkCount > 0 && isOnline && (
                            <Button
                                onClick={handleViewBookmarks}
                                disabled={!isOnline}>
                                <Icon type={Icons.Eye} />
                                {t("view-bookmarks")}
                            </Button>
                        )}
                        {showViewPriorVersionsButton &&
                            !isInDiffPanel &&
                            !isInReferencePanel &&
                            !isShareLink && (
                                <Button
                                    accessibleText={t("viewChanges")}
                                    ariaControls={"diff-panel"}
                                    onClick={handleDiffPanelOpen}>
                                    <ChangeIndicatorIcon
                                        changes={diffchanged}
                                        cssClassName=""
                                    />
                                    {t("viewChanges")}
                                </Button>
                            )}
                        {showViewPriorVersionsButton &&
                            isAdminOrPublisher &&
                            useChangeSummaries && (
                                <Button onClick={onAddChangeSummaryClick}>
                                    {addChangeSummaryLabel}
                                </Button>
                            )}
                    </MenuButton>
                    {renderReadonlySlider &&
                        !showFullScreen &&
                        !isInReferencePanel && (
                            <BookmarkSlider
                                bookmarks={tableBookmarks}
                                onClose={handleBookmarksReadOnlyDialogClose}
                                onDeleted={handleBookmarksReadOnlyDialogClose}
                                onEdit={handleEditBookmark}
                                title={displayTitle}
                                handleAddBookmark={handleAddBookmark}
                            />
                        )}
                    {renderSettings &&
                        !showFullScreen &&
                        !isInDiffPanel &&
                        !isInReferencePanel && (
                            <BookmarkSettings
                                onCancel={handleBookmarkSettingsClose}
                                onDelete={handleBookmarkSettingsClose}
                                bookmark={currentBookmark}
                                externalId={externalId}
                                title={displayTitle}
                                userBookmarkType={UserBookmarkType.Table}
                            />
                        )}
                    {diffchanged != null && (
                        <React.Fragment>
                            {useEnhancedChangeIndicators &&
                            !isMyLink &&
                            !isInReferencePanel ? (
                                <Button
                                    cssClassName="-change-indicator"
                                    accessibleText={t("viewChanges")}
                                    onClick={handleDiffPanelOpen}
                                    style={ButtonStyles.None}
                                    type={ButtonTypes.Button}>
                                    <ChangeIndicatorIcon
                                        cssClassName=""
                                        changes={diffchanged}
                                    />
                                </Button>
                            ) : (
                                <ChangeIndicatorIcon
                                    changes={diffchanged}
                                    cssClassName="-change-indicator"
                                />
                            )}
                        </React.Fragment>
                    )}
                    <div className={cssClassName}>
                        <table style={style} className={hasNoTitle}>
                            {bookviewChildren}
                        </table>
                    </div>
                    {content.externalId === externalId && (
                        <ChangeIndicatorDiffPanel isTable={true} />
                    )}
                </div>

                {showFullScreen && (
                    <Modal
                        cssClassName={`${BASE_CLASS_NAME}-modal`}
                        closeButtonStyle={ModalCloseButtonStyle.Hidden}
                        closeDialog={closeModal}
                        isVisible={showFullScreen}
                        label={tableView}
                        transition={ModalTransitions.Fade}
                        type={ModalTypes.Fullscreen}>
                        <div className={modalHeaderClass}>
                            <div className={`${modalHeaderClass}-toggle`}>
                                <Button
                                    accessibleText={toggleText}
                                    onClick={toggleHeaderPin}
                                    size={ButtonSizes.Medium}
                                    style={ButtonStyles.Tertiary}>
                                    <Icon
                                        type={Icons.Pin}
                                        size={IconSizes.Base}
                                    />
                                    {toggleText}
                                </Button>
                            </div>
                            <div className="title-container">
                                {title}
                                {CollectionUtils.hasValues(tableBookmarks) && (
                                    <Button
                                        accessibleText={
                                            bookmarkButtonAccessibleText
                                        }
                                        dataTestId="table-bookmark"
                                        style={ButtonStyles.TertiaryAlt}
                                        onClick={() =>
                                            handleBookmarksReadOnlyDialogOpen(
                                                externalId!
                                            )
                                        }>
                                        {renderPrimaryBookmark(
                                            bookmarksDistinctByColor[0],
                                            bookmarkCount
                                        )}
                                        {CollectionUtils.length(
                                            bookmarksDistinctByColor
                                        ) > 1 &&
                                            renderSecondaryBookmark(
                                                bookmarksDistinctByColor.slice(
                                                    1
                                                )
                                            )}
                                    </Button>
                                )}
                            </div>
                            {renderReadonlySlider && showFullScreen && (
                                <BookmarkSlider
                                    bookmarks={tableBookmarks}
                                    onClose={handleBookmarksReadOnlyDialogClose}
                                    onDeleted={
                                        handleBookmarksReadOnlyDialogClose
                                    }
                                    onEdit={handleEditBookmark}
                                    handleAddBookmark={handleAddBookmark}
                                    title={displayTitle}
                                />
                            )}
                            {renderSettings && showFullScreen && (
                                <BookmarkSettings
                                    onCancel={handleBookmarkSettingsClose}
                                    onDelete={handleBookmarkSettingsClose}
                                    bookmark={currentBookmark}
                                    externalId={externalId}
                                    title={displayTitle}
                                    userBookmarkType={UserBookmarkType.Table}
                                />
                            )}
                            <div className={`${modalHeaderClass}-modal-close`}>
                                <Button
                                    accessibleText={t("closeItem", {
                                        item: t("dialog"),
                                    })}
                                    cssClassName={"-icon -modal-close-tertiary"}
                                    onClick={closeModal}
                                    size={ButtonSizes.Medium}
                                    style={ButtonStyles.Tertiary}>
                                    <Icon
                                        type={Icons.Close}
                                        size={IconSizes.Large}
                                    />
                                </Button>
                            </div>
                        </div>
                        <div className={`${cssClassName} -no-margin`}>
                            <table
                                className={useSticky}
                                id={externalId}
                                style={style}>
                                {modalChildren}
                            </table>
                        </div>
                    </Modal>
                )}
                {showChangeSummariesModal && (
                    <AddEditChangeSummariesModal
                        externalId={externalId!}
                        title={displayTitle}
                        code={publication.code}
                        edition={publication.edition}
                        publicationComponentType={
                            PublicationComponentType.Table
                        }
                        onClose={onAddChangeSummaryClose}
                    />
                )}
            </div>
        );
    }
    // #endregion Render
};

// #endregion Component

export default TableSection;

/**
 * Test a table to find if it meets criteria for simple table
 * one-to-one grid
 * on row in the header (thead has one tr)
 * each cell has rowspan and colspan of one
 * @param headerChildren Array of children in the thead
 * @param bodyChildren Array of children in the tbody
 * @returns boolean
 */
const isSimpleTable = (
    headerChildren: Array<JSX.Element | undefined>,
    bodyChildren: Array<JSX.Element | undefined>
): boolean => {
    let isSimple = headerChildren.length === 1;
    if (!isSimple) {
        return isSimple;
    }

    const checkRowAndCol = (child: JSX.Element): void => {
        if (
            child.props.converterId !== null &&
            (child.props.converterId === "td" ||
                child.props.converterId === "th")
        ) {
            isSimple =
                child.props.rowspan === "1" && child.props.colspan === "1";

            return;
        }

        const children = React.Children.toArray(child.props.children);

        children.forEach((child: JSX.Element | undefined) => {
            if (isSimple && React.isValidElement(child)) {
                checkRowAndCol(child);
            }
        });
    };

    const tableChildren = [...headerChildren, ...bodyChildren];

    for (let child of tableChildren) {
        if (!isSimple) {
            return isSimple;
        }

        if (React.isValidElement(child)) {
            checkRowAndCol(child);
        }
    }

    return isSimple;
};

const getCurrentUserBookmarkComparator = (currentUserId: number) => {
    return (a: UserBookmarkRecord, b: UserBookmarkRecord) => {
        if (a.userId === currentUserId) {
            return -1;
        }

        if (b.userId === currentUserId) {
            return 1;
        }

        return 0;
    };
};

const diffchangedTitleBuilder = (title: JSX.Element): string => {
    let displayTitle: string = "";

    React.Children.forEach(title.props.children, (item) => {
        typeof item === "string"
            ? (displayTitle += item)
            : (displayTitle += item.props.children);
    });
    return displayTitle;
};
