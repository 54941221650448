import { SelectOption } from "atoms/forms/select";
import _ from "lodash";

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

const SelectOptionUtils = {
    getOptionsByValue<TData = any, TValue extends string | number = string>(
        options: Array<SelectOption<TData, TValue>>,
        values: Array<TValue>
    ): Array<SelectOption<TData, TValue>> {
        return _.intersectionWith(
            options,
            values,
            (option: SelectOption<TData, TValue>, value: TValue) =>
                option.value === value
        );
    },
    getValues<TData = any, TValue extends string | number = string>(
        options: Array<SelectOption<TData, TValue>>
    ): Array<TValue> {
        return options.map((option) => option.value);
    },
    isSelected<TData = any, TValue extends string | number = string>(
        option: SelectOption<TData, TValue>,
        values: Array<TValue>
    ) {
        return values.some((value: TValue) => option.value === value);
    },
    map<TSourceValue, TData = any, TValue extends string | number = string>(
        values: Array<TSourceValue>,
        mappingFn: (value: TSourceValue) => SelectOption<TData, TValue>
    ): Array<SelectOption<TData, TValue>> {
        return values.map(mappingFn);
    },
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SelectOptionUtils;

// #endregion Exports
