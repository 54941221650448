import { useEffect, useCallback, useState } from "react";
import UserReferenceRecord from "models/view-models/user-reference-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import { ToastManager } from "utilities/toast/toast-manager";
import { tErrorLoading } from "utilities/localization-utils";
import useSectionIncludeSubSectionsApi from "utilities/hooks/domain/publications/chapters/sections/use-section-include-sub-sections-api";
import useUserReferencesApi from "utilities/hooks/domain/user-references/use-user-references-api";
import { CollectionUtils } from "utilities/collection-utils";
import ReferencePanelContext from "models/interfaces/reference-panel-context";

export default function useUserReferences(
    currentUserId?: number,
    currentUserRoleId?: number,
    enabled: boolean = false
): ServiceHookResult<Partial<ReferencePanelContext>> {
    const { getSectionIncludeSubSections } = useSectionIncludeSubSectionsApi();
    const { getUserReferences } = useUserReferencesApi();

    const [serviceHookResult, setServiceHookResult] = useState<
        ServiceHookResult<Partial<ReferencePanelContext>>
    >(ServiceHookResultFactory.loadingResult({}));

    const initialize = useCallback(
        async (currentUserId?: number) => {
            if (currentUserId == null || !enabled) {
                return;
            }

            try {
                const userReferences = await getUserReferences(currentUserId);

                const lastUserReference =
                    userReferences.last<UserReferenceRecord>();
                const currentUserReferenceId = lastUserReference?.id;
                const currentSectionId = lastUserReference?.section?.id;

                if (
                    CollectionUtils.isEmpty(userReferences) ||
                    currentSectionId == null ||
                    currentUserReferenceId == null
                ) {
                    setServiceHookResult(
                        ServiceHookResultFactory.successResult({})
                    );
                    return;
                }

                const currentSection = await getSectionIncludeSubSections(
                    currentSectionId
                );

                setServiceHookResult(
                    ServiceHookResultFactory.successResult({
                        currentSection,
                        currentSectionId,
                        currentUserReferenceId,
                        userReferences,
                    })
                );
            } catch (error) {
                ToastManager.error(tErrorLoading("reference_plural"));

                setServiceHookResult(
                    ServiceHookResultFactory.errorResult({}, error)
                );
            }
        },
        [enabled, getSectionIncludeSubSections, getUserReferences]
    );

    // currentUserRoleId is a dependency to ensure that references update when UserRole changes.
    useEffect(() => {
        initialize(currentUserId);
    }, [currentUserId, currentUserRoleId, initialize]);

    return {
        ...serviceHookResult,
    };
}
