import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface EnhancedContentBasePathParams {
    enhancedContentId: number;
}

export interface EnhancedContentResourcePathParams
    extends EnhancedContentBasePathParams {
    id: number;
}

/**
 * Search parameters when listing EnhancedContentResource records.
 * These get translated to QueryString parameters
 */
export interface EnhancedContentListQueryParams {}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "enhancedcontent/:enhancedContentId/resources";
const resourceType = EnhancedContentResourceRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "enhancedcontent-resources";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const EnhancedContentResourceService = {
    /**
     * Retrieve a specific EnhancedContent resource
     */
    get: ServiceFactory.get<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Retrieves a list of EnhancedContent resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        EnhancedContentResourceRecord,
        EnhancedContentBasePathParams,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        EnhancedContentResourceRecord,
        EnhancedContentBasePathParams,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    useListQuery: QueryHookFactory.useNestedListQuery<
        EnhancedContentResourceRecord,
        EnhancedContentBasePathParams,
        EnhancedContentListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentResourceService;

// #region Exports
