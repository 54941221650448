import React, { useEffect, useState } from "react";
import useMounted from "utilities/hooks/use-mounted";

/**
 * Custom hook providing utility to take some action when a mouse event is fired outside of an element.
 * @param ref
 * @param handler
 * @param deps
 * @param inModal attach the event listener to .c-modal-overlay instead of #root
 * @param shouldCheck you can improve performance by skipping the check in certain cases
 */
export default function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: () => void,
    deps: React.DependencyList | undefined = undefined,
    inModal: boolean = false,
    shouldCheck: boolean = true
) {
    const isMounted = useMounted();

    // Ensure we only attach one event
    const [hasEvent, setHasEvent] = useState<boolean>(false);
    useEffect(() => {
        if (!isMounted.current) {
            return;
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (!shouldCheck) {
                return false;
            }

            if (
                ref != null &&
                ref.current != null &&
                !ref.current.contains(event.target as Node)
            ) {
                handler();
                return;
            }
            return true;
        };
        const layout = inModal
            ? document.querySelector<HTMLElement>(".c-modal-overlay")
            : document.getElementById("root");

        if (!hasEvent && layout != null) {
            const event = (e: MouseEvent) => {
                handleClickOutside(e);
                layout.removeEventListener("mousedown", event);
                if (isMounted.current) {
                    setHasEvent(false);
                }
            };

            layout.addEventListener("mousedown", event);
            if (isMounted.current) {
                setHasEvent(true);
            }
        }

        return () =>
            layout?.removeEventListener("mousedown", handleClickOutside);
    }, [ref, handler, deps, hasEvent, inModal, shouldCheck, isMounted]);
}
