import * as React from "react";

import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CommitteesParagraphConverterProps
    extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CommitteesParagraphConverter: React.FC<CommitteesParagraphConverterProps> =
    (props: CommitteesParagraphConverterProps) => {
        if (props.children == null) {
            return null;
        }

        return (
            <Paragraph size={ParagraphSizes.Large}>{props.children}</Paragraph>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default CommitteesParagraphConverter;

// #endregion Export
