import Button, { ButtonProps } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ModalProps } from "molecules/modals/modal";
import React from "react";
import StringUtils from "utilities/string-utils";

interface BaseModalFooterProps extends Pick<ModalProps, "closeDialog"> {
    closeButtonProps?: ButtonProps;
    closeButtonText?: string;
    primaryButtonProps: ButtonProps;
    primaryText: string;
}

const BaseModalFooter: React.FunctionComponent<BaseModalFooterProps> = (
    props: BaseModalFooterProps
) => {
    const {
        primaryButtonProps,
        closeButtonProps,
        primaryText,
        closeButtonText,
    } = props;

    const hasPrimaryButton = StringUtils.hasValue(props.primaryText);
    const hasCloseButton = StringUtils.hasValue(props.closeButtonText);

    return (
        <div className={"c-modal__footer"}>
            {hasCloseButton && (
                <Button {...closeButtonProps} style={ButtonStyles.Secondary}>
                    {closeButtonText}
                </Button>
            )}
            {hasPrimaryButton && (
                <Button {...primaryButtonProps} style={ButtonStyles.Primary}>
                    {primaryText}
                </Button>
            )}
        </div>
    );
};

export default BaseModalFooter;
