import Anchor from "atoms/anchors/anchor";
import Tooltip, {
    TooltipOffset,
    TooltipPlacement,
} from "molecules/tooltips/tooltip";
import * as React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface FreeAccessSidebarNavigationTooltipProps {
    children: React.ReactElement;
    description: string;
    disabled?: boolean;
    offsetInPx?: TooltipOffset;
    title: string;
    buttonText?: string;
    buttonUrl?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export default function FreeAccessSidebarNavigationTooltip(
    props: FreeAccessSidebarNavigationTooltipProps
) {
    const cssBaseClassName = "c-sidebar-navigation";
    const BUTTON_CLASSNAME = "c-button -secondary";
    const cssToolTipClassName = `${cssBaseClassName}__tooltip`;

    const { children, description, title, buttonText, buttonUrl } = props;

    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const tooltipContent = (
        <div>
            <div className={`${cssToolTipClassName}__title`}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}></span>
            </div>
            <div className={`${cssToolTipClassName}__description`}>
                {description}
            </div>
            {buttonText && buttonUrl && (
                <div className={`${cssToolTipClassName}__button`}>
                    <Anchor cssClassName={BUTTON_CLASSNAME} to={buttonUrl}>
                        <span>{buttonText}</span>
                    </Anchor>
                </div>
            )}
        </div>
    );

    return (
        <Tooltip
            content={tooltipContent}
            cssClassName={`${cssBaseClassName}__tooltip`}
            durationInMs={0}
            hideOnClick={isMobile ? true : false}
            offsetInPx={props.offsetInPx}
            placement={
                isMobile ? TooltipPlacement.AutoStart : TooltipPlacement.Right
            }
            interactive={true}>
            {children}
        </Tooltip>
    );

    // #endregion Render
}

// #endregion Component
