import { BrowserUtils } from "andculturecode-javascript-core";
import StickyNotification from "atoms/sticky-notification/sticky-notification";
import { AnimateSharedLayout, motion, MotionProps } from "framer-motion";
import PublicationRecord from "models/view-models/publication-record";
import ErrorBoundary from "molecules/errors/error-boundary";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import ReferencePanel from "organisms/panels/reference-panel";
import LegacyPublicationContent from "organisms/publication-content/legacy-publication-content";
import React from "react";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useLegacyPublication from "utilities/hooks/domain/publications/legacy/use-legacy-publication";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";

const CSS_BASE_CLASS = "c-legacy-publication-page";
const CSS_PAGE_CLASS = "c-image-viewer";
const SLIDE_LEFT: MotionProps = {
    initial: { x: "100%" },
    animate: { x: 0 },
    exit: { x: "90%" },
    transition: { type: "tween", duration: 0.2 },
};

interface LegacyPublicationPageProps {
    publication: PublicationRecord;
}

const LegacyPublicationPage: React.FC<LegacyPublicationPageProps> = (
    props: LegacyPublicationPageProps
) => {
    const { publication } = props;
    const { isOpen: isReferencePanelOpen } = useReferencePanelContext();
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;

    const {
        resultObject: legacyPublication,
        loading,
        loaded,
        errors,
    } = useLegacyPublication({
        publicationId: publication.id!,
    });

    const renderAnimatedReferencePanel =
        isReferencePanelOpen && !BrowserUtils.isIE();
    const renderStaticReferencePanel =
        isReferencePanelOpen && BrowserUtils.isIE();
    const classNames = `${CSS_BASE_CLASS} ${
        isReferencePanelOpen ? "-reference-panel-open" : ""
    }`;
    const renderUnpublishedSticky =
        currentIdentity?.isAdmin() &&
        !loading &&
        loaded &&
        StringUtils.isEmpty(publication.publishedOn);

    return (
        <div className={classNames}>
            <ErrorBoundary>
                <AnimateSharedLayout>
                    <BreadcrumbsMenu fullWidth={true} />
                    {BrowserUtils.isIE() ? (
                        <div className={`${CSS_PAGE_CLASS} -legacy`}>
                            <LegacyPublicationContent
                                legacyPublication={legacyPublication}
                                errors={errors}
                                loading={loading}
                            />
                        </div>
                    ) : (
                        <motion.div
                            className={`${CSS_PAGE_CLASS} -legacy`}
                            layout>
                            <LegacyPublicationContent
                                legacyPublication={legacyPublication}
                                errors={errors}
                                loading={loading}
                            />
                        </motion.div>
                    )}
                    {renderStaticReferencePanel && (
                        <div>
                            <ReferencePanel />
                        </div>
                    )}
                    {renderAnimatedReferencePanel && (
                        <motion.div
                            {...SLIDE_LEFT}
                            layoutId={"reference-panel"}>
                            <ReferencePanel />
                        </motion.div>
                    )}
                </AnimateSharedLayout>
            </ErrorBoundary>
            {renderUnpublishedSticky && (
                <StickyNotification>{t("unpublished")}</StickyNotification>
            )}
        </div>
    );
};

export default LegacyPublicationPage;
