import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import Hit from "models/interfaces/search/hit";
import SearchResult from "models/interfaces/search/search-result";
import HitRecord from "models/view-models/search/hit-record";
import { CollectionUtils } from "utilities/collection-utils";

const defaultValues: SearchResult =
    RecordUtils.defaultValuesFactory<SearchResult>({
        hits: [],
        found: undefined,
    });

export default class SearchResultRecord
    extends Record(defaultValues)
    implements SearchResult
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SearchResult) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.hits)) {
            params.hits = params.hits!.map((hit: Hit) =>
                RecordUtils.ensureRecord(hit, HitRecord)
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns number of pages available based on a page size.
     *
     * @param {number} [pageSize=10]
     */
    public getPageCount(pageSize: number = 10): number {
        if (this.found == null || this.found < 1) {
            return 0;
        }

        return Math.ceil(this.found / pageSize);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SearchResult>} values
     */
    public with(values: Partial<SearchResult>): SearchResultRecord {
        return new SearchResultRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
