import ReferencePanelContextRecord from "models/view-models/reference-panel-context-record";
import UserReferenceRecord from "models/view-models/user-reference-record";
import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { CollectionUtils } from "utilities/collection-utils";
import UserReferenceService from "utilities/services/user-references/user-references-service";
import useSectionIncludeSubSectionsApi from "utilities/hooks/domain/publications/chapters/sections/use-section-include-sub-sections-api";
import useUserReferencesApi from "utilities/hooks/domain/user-references/use-user-references-api";
import { ToastManager } from "utilities/toast/toast-manager";
import { t, tErrorLoading } from "utilities/localization-utils";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import { List } from "immutable";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import useTias from "utilities/hooks/domain/publications/use-tias";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type ReferencePanelContextUpdater = Dispatch<
    SetStateAction<ReferencePanelContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: ReferencePanelContextRecord =
    new ReferencePanelContextRecord();
const defaultUpdater: ReferencePanelContextUpdater = () => {};
const ReferencePanelContext = createContext<
    [ReferencePanelContextRecord, ReferencePanelContextUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useReferencePanelContext() {
    const { create: createUserReferenceApi } = UserReferenceService.useCreate();
    const { delete: deleteUserReferenceApi } = UserReferenceService.useDelete();
    const { delete: deleteAllUserReferencesApi } =
        UserReferenceService.useDeleteAll();
    const { getSectionIncludeSubSections } = useSectionIncludeSubSectionsApi();
    const { getUserReferences } = useUserReferencesApi();
    const { showDiffPanel, handleClose: closeChangeIndicatorPanel } =
        useChangeIndicatorDiffPanel();

    const [referencePanelContext, setReferencePanelContext] = useContext(
        ReferencePanelContext
    );

    const {
        currentSection,
        currentUserReferenceId,
        isOpen,
        loaded,
        loading,
        userReferences,
    } = referencePanelContext;

    const { globalState } = useGlobalState();
    const { isTiaPanelOpen, closePanels } = useTias();
    const currentUserId = globalState.currentIdentity?.userId();

    const isEmpty = currentSection == null;

    const errorSavingReference = t("errors-actionResource", {
        action: t("saving").toLowerCase(),
        resource: t("reference").toLowerCase(),
    });

    const errorRemovingReference = t("errors-actionResource", {
        action: t("removing").toLowerCase(),
        resource: t("reference").toLowerCase(),
    });

    const findReferenceBySectionId = (sectionId?: number) =>
        userReferences.find(
            (e: UserReferenceRecord) =>
                e.sectionId === sectionId || e.section?.id === sectionId
        );

    const handleAdd = async (sectionId?: number) => {
        if (currentUserId == null || sectionId == null) {
            return;
        }

        if (showDiffPanel) closeChangeIndicatorPanel();
        if (isTiaPanelOpen) closePanels();

        if (referenceForSectionExists(sectionId)) {
            openPanelBySectionId(sectionId);
            return;
        }

        const userReferenceDto = new UserReferenceRecord({
            sectionId: sectionId,
        });

        setReferencePanelContext((previous) =>
            previous.fromServiceHookResult(
                ServiceHookResultFactory.loadingResult({})
            )
        );

        try {
            const { resultObject: userReference } =
                await createUserReferenceApi(userReferenceDto, {
                    userId: currentUserId,
                });

            const [getUserReferencesResult, getSectionsResult] =
                await Promise.all([
                    getUserReferences(currentUserId),
                    getSectionIncludeSubSections(sectionId),
                ]);

            const result = ServiceHookResultFactory.successResult({
                currentSection: getSectionsResult,
                currentSectionId: getSectionsResult?.id,
                currentUserReferenceId: userReference?.id,
                userReferences: getUserReferencesResult,
            });

            setReferencePanelContext((previous) => {
                return previous
                    .fromServiceHookResult(result)
                    .with({ isOpen: true });
            });
        } catch (error) {
            ToastManager.error(errorSavingReference);

            setReferencePanelContext((previous) => {
                return previous.fromServiceHookResult(
                    ServiceHookResultFactory.errorResult({}, error)
                );
            });
        }
    };

    const handleSelect = async (userReferenceId?: number) => {
        if (
            userReferenceId == null ||
            currentUserReferenceId === userReferenceId
        ) {
            return;
        }

        if (showDiffPanel) closeChangeIndicatorPanel();
        if (isTiaPanelOpen) closePanels();

        openPanelById(userReferenceId);
    };

    const handleToggle = () => {
        if (!isOpen) {
            if (showDiffPanel) closeChangeIndicatorPanel();
            if (isTiaPanelOpen) closePanels();
        }

        setReferencePanelContext((prevContext: ReferencePanelContextRecord) => {
            return prevContext.with({ isOpen: !prevContext.isOpen });
        });
    };

    const handleClose = () => {
        setReferencePanelContext((prevContext: ReferencePanelContextRecord) => {
            return prevContext.with({ isOpen: false });
        });
    };

    const handleRemove = async (userReferenceId?: number) => {
        if (currentUserId == null || userReferenceId == null) {
            return;
        }

        const userReferenceToSelectAfterDeletion =
            CollectionUtils.getPreviousElement(
                userReferences,
                (e) => e.id === userReferenceId
            );

        setReferencePanelContext((previous) =>
            previous.fromServiceHookResult(
                ServiceHookResultFactory.loadingResult({})
            )
        );

        try {
            await deleteUserReferenceApi(userReferenceId, {
                userId: currentUserId,
            });

            const [getUserReferencesResult, getSectionsResult] =
                await Promise.all([
                    getUserReferences(currentUserId),
                    getSectionIncludeSubSections(
                        userReferenceToSelectAfterDeletion?.section?.id
                    ),
                ]);

            const result = ServiceHookResultFactory.successResult({
                currentSection: getSectionsResult,
                currentSectionId: getSectionsResult?.id,
                currentUserReferenceId: userReferenceToSelectAfterDeletion?.id,
                userReferences: getUserReferencesResult,
            });

            setReferencePanelContext((previous) =>
                previous.fromServiceHookResult(result)
            );
        } catch (error) {
            ToastManager.error(errorRemovingReference);

            setReferencePanelContext((previous) => {
                return previous.fromServiceHookResult(
                    ServiceHookResultFactory.errorResult({}, error)
                );
            });
        }
    };

    const handleRemoveAll = async () => {
        if (currentUserId == null) {
            return;
        }

        setReferencePanelContext((previous) =>
            previous.fromServiceHookResult(
                ServiceHookResultFactory.loadingResult({})
            )
        );

        try {
            await deleteAllUserReferencesApi(undefined as any, {
                userId: currentUserId,
            });

            const result = ServiceHookResultFactory.successResult({
                currentSection: undefined,
                currentSectionId: undefined,
                currentUserReferenceId: undefined,
                userReferences: List<UserReferenceRecord>(),
            });

            setReferencePanelContext((previous) =>
                previous.fromServiceHookResult(result)
            );
        } catch (error) {
            ToastManager.error(errorRemovingReference);
        }
    };

    const openPanelById = async (userReferenceId: number) => {
        if (CollectionUtils.isEmpty(userReferences)) {
            return;
        }

        setReferencePanelContext((previous) =>
            previous.fromServiceHookResult(
                ServiceHookResultFactory.loadingResult({})
            )
        );

        const userReference = userReferences.find(
            (e) => e.id === userReferenceId
        );

        const currentSectionId = userReference?.section?.id;

        try {
            const currentSection = await getSectionIncludeSubSections(
                currentSectionId
            );

            const result = ServiceHookResultFactory.successResult({
                currentSection,
                currentSectionId,
                currentUserReferenceId: userReferenceId,
            });

            setReferencePanelContext((previous) => {
                return previous
                    .fromServiceHookResult(result)
                    .with({ isOpen: true });
            });
        } catch (error) {
            ToastManager.error(tErrorLoading("reference_plural"));

            setReferencePanelContext((previous) => {
                return previous.fromServiceHookResult(
                    ServiceHookResultFactory.errorResult({}, error)
                );
            });
        }
    };

    const openPanelBySectionId = (sectionId: number) => {
        const existingReference = findReferenceBySectionId(sectionId);
        if (existingReference == null || existingReference.id == null) {
            return;
        }

        openPanelById(existingReference.id);
    };

    const referenceForSectionExists = (sectionId?: number) =>
        findReferenceBySectionId(sectionId) != null;

    return {
        currentSection,
        currentUserReferenceId,
        handleAdd,
        handleSelect,
        handleToggle,
        handleRemove,
        handleRemoveAll,
        handleClose,
        isEmpty,
        isOpen,
        loaded,
        loading,
        referenceForSectionExists,
        userReferences,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReferencePanelContext;

// #endregion Exports
