import EnhancedContentMigrationRecord from "models/view-models/enhanced-content-migration-record";
import Modal from "molecules/modals/modal";
import React from "react";
import { ModalTypes } from "molecules/constants/modal-types";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import RichTextArea from "molecules/rich-text-area/rich-text-area";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-enhanced-content-migration-report-modal";
const modalTitle = "Enhanced Content Migration Report";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentMigrationReportModalProps {
    closeDialog?: () => void;
    cssClassName?: string;
    migration?: EnhancedContentMigrationRecord;
    isVisible: boolean;
}

// #endregion Interfaces

export default function EnhancedContentMigrationReportModal(
    props: EnhancedContentMigrationReportModalProps
) {
    const { closeDialog, cssClassName, migration, isVisible = false } = props;

    const log = migration?.getLogDisplay() ?? "";

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleCloseModal = () => {
        closeDialog?.();
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    if (!isVisible || migration == null) {
        return null;
    }

    return (
        <Modal
            closeDialog={handleCloseModal}
            cssClassName={`${CSS_BASE_CLASS_NAME} ${cssClassName}`}
            isVisible={isVisible}
            label={modalTitle}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{modalTitle}</Heading>
                <ModalCloseButton onClick={handleCloseModal} />
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__body`}>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    <RichTextArea content={log} />
                </div>
            </div>
        </Modal>
    );

    // #endregion Render
}
