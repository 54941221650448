import React from "react";
import { Icons } from "atoms/constants/icons";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { t } from "utilities/localization-utils";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import Button from "atoms/buttons/button";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import CultureResources from "utilities/interfaces/culture-resources";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import useModalActions from "utilities/hooks/use-modal-actions";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ReferencePanelButtonProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-reference-panel-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReferencePanelButton: React.FC<ReferencePanelButtonProps> = (
    props: ReferencePanelButtonProps
) => {
    const {
        handleToggle: onToggle,
        isOpen,
        userReferences,
    } = useReferencePanelContext();

    const { isOnline } = useNetworkInformation();
    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );
    const {
        isOpen: isOfflineAlertModalOpen,
        handleClose: handleOfflineAlertModalClose,
        handleOpen: handleFeatureUnavailableModalOpen,
    } = useModalActions();

    const action: keyof CultureResources = isOpen ? "close" : "open";
    const title = t("reference-panel-menu", { action: t(action) });

    const cssModifiers: string[] = [];
    if (isOpen) {
        cssModifiers.push("-panel-open");
    }

    if (!isOnline) {
        cssModifiers.push("-offline");
    }

    const userReferencesCountClassName = [`${CSS_BASE_CLASS_NAME}__counter`];
    const userReferencesCount = userReferences.count();
    if (userReferencesCount > 0) {
        userReferencesCountClassName.push("-has-references");
    }

    const offlineTooltipContent = (
        <div>
            <div className={`${CSS_BASE_CLASS_NAME}__tooltip__title`}>
                {t("reference_plural")}
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__tooltip__description`}>
                {t("offline-fallback-description")}
            </div>
        </div>
    );

    const handleToggleClick = () => {
        if (isOnline) {
            onToggle();
            return;
        }

        if (!isMobile) {
            return;
        }

        handleFeatureUnavailableModalOpen();
    };

    return (
        <div className={CSS_BASE_CLASS_NAME}>
            <Tooltip
                content={offlineTooltipContent}
                cssClassName={`${CSS_BASE_CLASS_NAME}__tooltip`}
                disabled={isMobile || isOnline}
                durationInMs={0}
                hideOnClick={true}
                placement={TooltipPlacement.Bottom}>
                <Button
                    accessibleText={title}
                    ariaDisabled={!isOnline}
                    cssClassName={`${CSS_BASE_CLASS_NAME}__icon ${cssModifiers.join(
                        " "
                    )}`}
                    onClick={handleToggleClick}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.TertiaryAlt}>
                    <div className={userReferencesCountClassName.join(" ")}>
                        <span>{userReferencesCount}</span>
                    </div>
                    <Icon size={IconSizes.Large} type={Icons.ReferencePanel} />
                </Button>
            </Tooltip>
            {isOfflineAlertModalOpen && (
                <OfflineAlertModal
                    isOpen={isOfflineAlertModalOpen}
                    handleClose={handleOfflineAlertModalClose}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ReferencePanelButton;

// #endregion Exports
