import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import React from "react";

interface useRenderBookmarkOptions {
    baseClass: string;
}

const useRenderBookmarks = ({ baseClass }: useRenderBookmarkOptions) => {
    const renderPrimaryBookmark = (
        bookmark: UserBookmarkRecord,
        bookmarkCount: number
    ) => {
        const bookmarkCountModifier = bookmarkCount > 1 ? "-has-count" : "";
        const bookmarkColorModifier = bookmark.getColorClassModifier();
        return (
            <div
                className={`${baseClass}__icon-container ${bookmarkColorModifier} ${bookmarkCountModifier}`}>
                {bookmarkCount > 1 && (
                    <span aria-hidden={true}>{bookmarkCount}</span>
                )}
                <Icon
                    cssClassName={bookmarkColorModifier}
                    key={bookmark.id}
                    type={Icons.Bookmark}
                />
            </div>
        );
    };

    const renderSecondaryBookmark = (bookmarks: Array<UserBookmarkRecord>) =>
        bookmarks.map((bookmark) => {
            const bookmarkColorModifier = bookmark.getColorClassModifier();

            return (
                <div
                    className={`${baseClass}__icon-container -secondary-bookmark
                    ${bookmarkColorModifier}`}>
                    <Icon
                        cssClassName={bookmarkColorModifier}
                        key={bookmark.id}
                        type={Icons.Bookmark}
                    />
                </div>
            );
        });

    return {
        renderPrimaryBookmark,
        renderSecondaryBookmark,
    };
};

export default useRenderBookmarks;
