import Loader from "atoms/loaders/loader";
import CurrentPublicationEntityRecord from "models/view-models/atoms/current-publication-entity-record";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationContentArea from "organisms/publication-content/publication-content-area";
import React from "react";
import { useParams } from "react-router-dom";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import { CollectionUtils } from "utilities/collection-utils";
import useUpdateAtomEffect from "utilities/hooks/atoms/use-update-atom-value-effect";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";
import useAnnexGroupedSections from "utilities/hooks/domain/publications/annexes/use-annex-grouped-sections";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import useErrors from "utilities/hooks/use-errors";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import { AnnexGroupParams } from "interfaces/routing/route-params";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import HiddenContentAlertBanner from "organisms/alerts/hidden-content-alert-banner";
import PublicationComponentType from "utilities/enumerations/publication-component-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AnnexGroupContentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-page-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AnnexGroupContent: React.FunctionComponent<AnnexGroupContentProps> = (
    props: AnnexGroupContentProps
) => {
    const { publication } = useCurrentPublication();
    const { nfpaLabel, annexId, id: groupBy } = useParams<AnnexGroupParams>();

    const { t } = useLocalization<CultureResources>();

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        errors: annexErrors,
        loading: annexLoading,
        loaded: annexLoaded,
        resultObject: annex,
    } = useAnnex(publication?.id, annexId, nfpaLabel);

    const {
        errors: annexSectionsErrors,
        loading: annexSectionsLoading,
        resultObject: annexSections,
    } = useAnnexGroupedSections(publication?.id, annex?.id, groupBy);

    const errors = useErrors(annexErrors, annexSectionsErrors);
    useErrorBoundary(errors);
    const loading = useLoading(annexLoading, annexSectionsLoading);

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useUpdateAtomEffect({
        atom: CurrentPublicationEntityAtom,
        enabled: annex != null && annexLoaded,
        value: new CurrentPublicationEntityRecord({
            entityId: annex.id!,
            entityTitle: annex.getDisplayLabel(),
            selected: true,
            type: "Annex",
        }),
    });

    // #endregion Side-Effects

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            {
                // if
                CollectionUtils.hasValues(errors) && (
                    <UnorderedList
                        cssClassName={`${BASE_CLASS_NAME}__errors`}
                        listItems={errors}
                    />
                )
            }
            {
                // if
                loading ? (
                    <Loader
                        accessibleText={t("loadingItem", {
                            item: t("annex"),
                        })}
                    />
                ) : (
                    <>
                        <HiddenContentAlertBanner
                            id={annex.id}
                            sections={annexSections}
                            type={PublicationComponentType.AnnexGroup}
                        />

                        <PublicationContentArea sections={annexSections} />
                    </>
                )
            }
        </React.Fragment>
    );
    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AnnexGroupContent;

// #endregion Exports
