import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import breadcrumbRoutes from "breadcrumb-routes";
import React from "react";
import { Link } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import useBreadcrumbs, {
    BreadcrumbData,
    Options,
} from "use-react-router-breadcrumbs";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BreadcrumbNavigationProps {
    options: Options;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const MIN_BREADCRUMB_ELLIPSIS_LENGTH = 4;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = (
    props: BreadcrumbNavigationProps
) => {
    const { options } = props;
    const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, options);

    const breadcrumbLinks = breadcrumbs.map(
        (breadcrumbData: BreadcrumbData, index: number) => {
            const { match, breadcrumb } = breadcrumbData;
            const isLastBreadcrumb = index === breadcrumbs.length - 1;
            const isSecondBreadcrumb = index === 1;
            const showEllipsis =
                breadcrumbs.length >= MIN_BREADCRUMB_ELLIPSIS_LENGTH &&
                isLastBreadcrumb;

            const className = buildCssClassname(
                match.url,
                isLastBreadcrumb,
                isSecondBreadcrumb
            );

            return (
                <React.Fragment key={match.url}>
                    {
                        // if
                        showEllipsis && (
                            <li>
                                <span className="c-breadcrumb -mobile-separator">
                                    <Icon
                                        type={Icons.ChevronRight}
                                        size={IconSizes.Base}
                                    />
                                    <span>...</span>
                                </span>
                            </li>
                        )
                    }
                    <li key={match.url}>
                        <Link className={className} to={match.url}>
                            {breadcrumb}
                        </Link>
                    </li>
                </React.Fragment>
            );
        }
    );
    return (
        <nav className="c-breadcrumb-navigation">
            <ol>{breadcrumbLinks}</ol>
        </nav>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function buildCssClassname(
    matchUrl: string,
    lastBreadcrumb: boolean,
    secondBreadcrumb: boolean
): string {
    const classNames: string[] = ["c-breadcrumb"];

    if (matchUrl === siteMap.home) {
        classNames.push("-home");
    }

    if (lastBreadcrumb) {
        classNames.push("-last");
    }

    if (secondBreadcrumb) {
        classNames.push("-second");
    }

    return classNames.join(" ");
}

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BreadcrumbNavigation;

// #endregion Exports
