import Anchor from "atoms/anchors/anchor";
import Loader from "atoms/loaders/loader";
import PublicationTypes from "constants/publication-types";
import { SectionDividerType } from "models/enumerations/section-divider-type";
import TableRecord from "models/view-models/table-record";
import UnorderedList from "molecules/lists/unordered-list";
import SectionSpacer from "molecules/spacers/section-spacer";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import Heading from "atoms/typography/heading";
import TableOfContentsTables from "organisms/table-of-contents/table-of-contents-tables";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useAdminPreview from "utilities/hooks/use-admin-preview";

interface TablesListProps {
    errors: Array<string>;
    loaded: boolean;
    loading: boolean;
    publicationType: PublicationTypes;
    tables: Array<TableRecord>;
    title: string;
}

const BASE_CLASS_NAME = "c-section-wrapper -level-zero";
const PUBLICATION_LAYOUT_CLASS_NAME = "c-publication-page-layout";

const TablesList: React.FunctionComponent<TablesListProps> = (
    props: TablesListProps
) => {
    const { errors, loading, loaded, tables, title, publicationType } = props;
    const { t } = useLocalization<CultureResources>();
    const isTabletOrLarger = useBreakpoint(Breakpoints.Tablet);
    const { isAdminPreview } = useAdminPreview();
    return (
        <React.Fragment>
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList
                    cssClassName={`${PUBLICATION_LAYOUT_CLASS_NAME}__errors`}
                    listItems={errors}
                />
            )}
            {loading && (
                <Loader
                    accessibleText={t("loadingItem", { item: t("table") })}
                />
            )}
            {loaded && (
                <React.Fragment>
                    <section
                        className={`${PUBLICATION_LAYOUT_CLASS_NAME}__content__header`}
                        tabIndex={-1}>
                        <Heading
                            cssClassName={`${PUBLICATION_LAYOUT_CLASS_NAME}__content__title`}>
                            {`Tables in ${title}`}
                        </Heading>
                        {!isTabletOrLarger && (
                            <TableOfContentsTables tables={tables} />
                        )}
                    </section>
                    <section className={BASE_CLASS_NAME}>
                        {tables.map(
                            (table: TableRecord) =>
                                !table.isDeleted() && (
                                    <React.Fragment key={table.id}>
                                        {table.getBody()}
                                        <Anchor
                                            to={table.getRoute(
                                                publicationType,
                                                isAdminPreview
                                            )}>
                                            <>{t("viewInPublication")}</>
                                        </Anchor>
                                        <SectionSpacer
                                            type={SectionDividerType.Medium}
                                        />
                                    </React.Fragment>
                                )
                        )}
                    </section>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default TablesList;
