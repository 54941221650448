import NumberUtils from "utilities/number-utils";
import PathUtils from "utilities/path-utils";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import { Translator } from "utilities/types/translator-type";
import CultureResources from "utilities/interfaces/culture-resources";

export interface YouTubeQueryStringParams {
    v?: string; // video ID
}

const getYoutubeThumbnailUrl = (videoLink: string): string | undefined => {
    const videoId = getYoutubeVideoId(videoLink);

    if (videoId == null) {
        return undefined;
    }

    return `https://img.youtube.com/vi/${videoId}/default.jpg`;
};

const isYoutubeLink = (link?: string): boolean => {
    if (!StringUtils.hasValue(link)) {
        return false;
    }

    try {
        const url = new URL(link!);
        return (
            url.hostname.toLowerCase().includes("youtube") ||
            url.hostname.toLowerCase().includes("youtu.be")
        ); // youtube share links
    } catch (e) {
        return false;
    }
};

const isBrightcoveLink = (link?: string): boolean => {
    if (!StringUtils.hasValue(link)) {
        return false;
    }

    try {
        const url = new URL(link!);
        return url.hostname.toLowerCase().includes("brightcove");
    } catch (e) {
        return false;
    }
};

const getYoutubeVideoId = (link: string): string | undefined => {
    if (!isYoutubeLink(link)) {
        return undefined;
    }

    const url = new URL(link);
    if (
        url.hostname.toLowerCase().includes("youtube.com") &&
        link.includes("watch?v=")
    ) {
        const queryString =
            RouteUtils.queryStringToObject<YouTubeQueryStringParams>(
                link.substr(link.indexOf("?"))
            );

        if (StringUtils.isEmpty(queryString.v)) {
            return undefined;
        }

        return queryString.v;
    }

    if (url.hostname.toLowerCase().includes("youtu.be")) {
        // YouTube share links are formatted differently
        // https://youtu.be/dQw4w9WgXcQ
        return PathUtils.relativePathToFilename(link);
    }

    return undefined;
};

// Assumes link structure similar to https://players.brightcove.net/1640544031001/gO7ZpIewC_default/index.html?videoId=6172299257001.
// Change if not or if additional structures.
const getBrightcoveVideoId = (link: string): string | undefined => {
    if (!isBrightcoveLink(link)) {
        return undefined;
    }

    const url = new URL(link);

    if (
        url.hostname.toLowerCase().includes("brightcove.net") &&
        url.searchParams.get("videoId")
    ) {
        return url.searchParams.get("videoId") ?? undefined;
    }

    return undefined;
};

/**
 * Takes a youtube duration string from our api of the format "15m 32s"
 * rounds to the nearest minute, and returns the string "{minutes value} Minutes".
 * If the duration is less than 1 minute, returns "{seconds value} Seconds"
 * @param durationString
 */
const roundVideoDuration = (
    durationString: string,
    t: Translator<CultureResources>
): string | undefined => {
    if (durationString.includes("m")) {
        const minuteVal = durationString.substr(0, durationString.indexOf("m"));
        const secondVal = durationString
            .substr(minuteVal.length + 1)
            .replace("s", "");
        try {
            const minutes = NumberUtils.parseInt(minuteVal);
            const seconds = NumberUtils.parseInt(secondVal);
            if (minutes == null || seconds == null) {
                return undefined;
            }
            if (seconds >= 30) {
                return t("minuteWithCount_plural", { count: minutes + 1 });
            }
            return t("minuteWithCount_plural", { count: minutes });
        } catch (e) {
            return undefined;
        }
    }
    try {
        const secondVal = NumberUtils.parseInt(
            durationString.replace("s", "").trim()
        );
        if (secondVal == null) {
            return undefined;
        }
        return t("secondWithCount_plural", { count: secondVal });
    } catch (e) {
        return undefined;
    }
};

const ExternalResourceUtils = {
    isYoutubeLink,
    isBrightcoveLink,
    getYoutubeThumbnailUrl,
    getYoutubeVideoId,
    getBrightcoveVideoId,
    roundVideoDuration,
};

export default ExternalResourceUtils;
