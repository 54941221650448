import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import LinkResourceRecord from "./link-resource-record";

const useLinkResourcesChapterProvider = () => {
    function getChapterTablesLinkResource(
        chapterNumber: string,
        chapterCode: string,
        chapterEdition: string,
        chapterNfpaLabel: string
    ): LinkResourceRecord {
        let url = RouteUtils.getUrl(
            siteMap.publications.permalinks.chapterTables,
            {
                code: chapterCode,
                edition: chapterEdition,
                chapterNfpaLabel: chapterNfpaLabel,
            }
        );
        return new LinkResourceRecord({
            title: `Tables From Chapter ${chapterNumber}`,
            link: url,
        });
    }

    function getChapterPreviewTablesLinkResource(
        chapterId: string,
        chapterNumber: string,
        publicationId: string
    ): LinkResourceRecord {
        let url = RouteUtils.getUrl(
            siteMap.publications.adminPreview.chapterTables,
            {
                chapterId: chapterId,
                publicationId: publicationId,
            }
        );
        return new LinkResourceRecord({
            title: `Tables From Chapter ${chapterNumber}`,
            link: url,
        });
    }

    // https://app.clickup.com/t/2219993/NFPA-5007
    function isNfpa70Chapter9(
        chapterCode: string,
        chapterNumber: string
    ): boolean {
        return +chapterCode === 70 && +chapterNumber === 9;
    }

    function getChapterPreviewLinkResources(
        chapterId: number,
        chapterNumber: string,
        chapterCode: string,
        publicationId: number,
        chapterHasTables: boolean
    ): LinkResourceRecord[] {
        let linkResourceRecords: LinkResourceRecord[] = [];
        if (chapterHasTables && !isNfpa70Chapter9(chapterCode, chapterNumber)) {
            linkResourceRecords.push(
                getChapterPreviewTablesLinkResource(
                    chapterId.toString(),
                    chapterNumber,
                    publicationId.toString()
                )
            );
        }
        return linkResourceRecords;
    }

    function getChapterLinkResources(
        chapterNumber: string,
        chapterCode: string,
        chapterEdition: string,
        chapterNfpaLabel: string,
        chapterHasTables: boolean
    ): LinkResourceRecord[] {
        let linkResourceRecords: LinkResourceRecord[] = [];
        if (chapterHasTables && !isNfpa70Chapter9(chapterCode, chapterNumber)) {
            linkResourceRecords.push(
                getChapterTablesLinkResource(
                    chapterNumber,
                    chapterCode,
                    chapterEdition,
                    chapterNfpaLabel
                )
            );
        }
        return linkResourceRecords;
    }

    return { getChapterLinkResources, getChapterPreviewLinkResources };
};

export default useLinkResourcesChapterProvider;
