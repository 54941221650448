import PublicationRecord from "models/view-models/publication-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import ServiceFactory from "utilities/services/service-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PublicationBaseParams {
    guid: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "sharelinks/publications";
const resourceEndpoint = `${baseEndpoint}/:guid`;
const resourceType = PublicationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Services
// -------------------------------------------------------------------------------------------------

const PublicationService = {
    get: ServiceFactory.get<PublicationRecord, PublicationBaseParams>(
        resourceType,
        resourceEndpoint
    ),
    useGet: ServiceHookFactory.useGet<PublicationRecord, PublicationBaseParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Services

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default PublicationService;

// #endregion Exports
