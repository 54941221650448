import { atom, useAtom } from "jotai";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import { useCallback } from "react";

const CurrentEnhancedContentSearchHit = atom<EnhancedContentRecord | null>(
    null
);

function useEnhancedContentSearchHit() {
    const [enhancedContent, setEnhancedContent] = useAtom(
        CurrentEnhancedContentSearchHit
    );

    const isCurrentSearchHit = (record: EnhancedContentRecord) =>
        record.id === enhancedContent?.id;

    const setRecord = (record: EnhancedContentRecord | undefined) => {
        if (record == null) return;
        setEnhancedContent(record);
    };

    return {
        disableApiCall: enhancedContent == null,
        isCurrentSearchHit: useCallback(isCurrentSearchHit, [enhancedContent]),
        setRecord,
    };
}

export default useEnhancedContentSearchHit;
