import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import UnsavedChangesPrompt from "molecules/unsaved-changes-prompt/unsaved-changes-prompt";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import DateUtils from "utilities/date-utils";
import useUser from "utilities/hooks/domain/users/use-user";
import AdminCategoryService from "utilities/services/admin/situational-navigation/categories/admin-category-service";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-category-editor";
const LAYOUT_CLASS_NAME = "c-admin-editor-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategoryEditor: React.FC<any> = () => {
    const { create: createCategoryApi } = AdminCategoryService.useCreate();
    const { update: updateCategoryApi } = AdminCategoryService.useUpdate();
    const { delete: deleteCategoryApi } = AdminCategoryService.useDelete();

    const { id } = useParams();
    const location = useLocation();
    const categoryType = useMemo(
        () => CategoryUtils.getTypeForRoute(location.pathname),
        [location]
    );
    const { context, setContext } = useAdminEditorPageContext();
    /**
     * Used to set the isDirty flag.
     */
    const [initialCategory, setInitialCategory] = useState(
        new CategoryRecord().with({ type: categoryType })
    );
    const [category, setCategory] = useState(
        new CategoryRecord().with({ type: categoryType })
    );
    /**
     * After successful creation or deletion, redirect ignoring isDirty
     */
    const [createdId, setCreatedId] = useState<number>();
    const [deletedId, setDeletedId] = useState<number>();
    const [titleError, setTitleError] = useState<string>();
    const [saving, setSaving] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const typeLabels = useMemo(
        () => CategoryUtils.toLabel(categoryType),
        [categoryType]
    );

    const headerLabel = useMemo(() => {
        let typeLabel = typeLabels.singular;
        return `${category.id == null ? "New" : "Edit"} ${typeLabel}`;
    }, [category.id, typeLabels.singular]);

    const initializeCategory = useCallback(
        (newCategory?: CategoryRecord) => {
            setInitialCategory(
                newCategory ?? new CategoryRecord().with({ type: categoryType })
            );
            setCategory(
                newCategory ?? new CategoryRecord().with({ type: categoryType })
            );
        },
        [categoryType]
    );

    const initializeCategoryFromId = useCallback(() => {
        if (category.id != null && category.id.toString() === id) {
            return;
        }

        if (category.id == null && StringUtils.isEmpty(id)) {
            return;
        }

        if (StringUtils.isEmpty(id)) {
            initializeCategory();
            return;
        }

        initializeCategory(context.categories.findById(id));
    }, [id, category, context.categories, initializeCategory]);

    const lastUpdatedByUserId = useMemo(
        () => category.updatedById ?? category.createdById,
        [category]
    );
    const { user } = useUser(lastUpdatedByUserId);

    const isDirty = useMemo(() => {
        return (
            category.title !== initialCategory.title ||
            category.description !== initialCategory.description
        );
    }, [category, initialCategory]);

    const deleteConfirmationText = useMemo(
        () =>
            `Deleting this ${typeLabels.singular.toLowerCase()} will remove it from all DiRECT results. Are you sure you want to delete this ${typeLabels.singular.toLowerCase()}?`,
        [typeLabels]
    );

    useEffect(() => initializeCategoryFromId(), [id, initializeCategoryFromId]);
    useEffect(
        () => setContext((ctx) => ctx.with({ currentValue: category })),
        [category, setContext]
    );

    const createCategory = async () => {
        setSaving(true);

        try {
            const result = await createCategoryApi(category);
            setContext((ctx) =>
                ctx.with({
                    categories: ctx.categories.addOrUpdate(
                        result.resultObject!
                    ),
                })
            );
            setCategory(result.resultObject!);
            setCreatedId(result.resultObject!.id!);
            ToastManager.success(`${typeLabels.singular} created!`);
        } catch (e) {
            ToastManager.error(
                `There was an issue creating ${typeLabels.singular.toLowerCase()}!`
            );
        }

        setSaving(false);
    };

    const updateCategory = async () => {
        setSaving(true);

        try {
            const result = await updateCategoryApi(category, {
                id: category.id!,
            });
            setContext((ctx) =>
                ctx.with({
                    categories: ctx.categories.addOrUpdate(
                        result.resultObject!
                    ),
                })
            );
            setCategory(result.resultObject!);
            ToastManager.success(`${typeLabels.singular} updated!`);
        } catch (e) {
            ToastManager.error(
                `There was an issue updating ${typeLabels.singular.toLowerCase()}!`
            );
        }

        setSaving(false);
    };

    const deleteCategory = async () => {
        setShowDeleteConfirmModal(false);
        setSaving(true);

        try {
            await deleteCategoryApi(category.id!, { id: category.id! });
            setContext((ctx) =>
                ctx.with({
                    categories: ctx.categories.filter(
                        (c) => c.id !== category.id!
                    ),
                })
            );
            setDeletedId(category.id!);
            ToastManager.success(
                `Deleted ${typeLabels.singular.toLowerCase()}!`
            );
        } catch (e) {
            ToastManager.error(
                `There was an issue deleting ${typeLabels.singular.toLowerCase()}!`
            );
        }

        setSaving(false);
    };

    const validate = (): boolean => {
        if (StringUtils.isEmpty(category.title)) {
            setTitleError("Title is required");
            return false;
        }

        const otherCategoriesOfSameType = context.categories[
            CategoryUtils.toCategoryCollectionProperty(categoryType)
        ].filter((c: CategoryRecord) => c.id !== category.id);

        if (
            otherCategoriesOfSameType.some((c: CategoryRecord) =>
                StringUtils.isEqual(c.title, category.title)
            )
        ) {
            setTitleError(
                `Another ${typeLabels.singular.toLowerCase()} with this title already exists.`
            );
            return false;
        }

        setTitleError(undefined);
        return true;
    };

    const handleFormSubmit = () => {
        if (!validate()) {
            return;
        }

        if (category.id == null) {
            createCategory();
            return;
        }

        updateCategory();
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setCategory(category.with({ title: e.target.value }));

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setCategory(category.with({ description: e.target.value }));

    if (createdId != null) {
        return (
            <Redirect
                to={category.with({ id: createdId }).getEditPageRoute()!}
            />
        );
    }

    if (deletedId != null) {
        return (
            <Redirect
                to={CategoryUtils.getDashboardRouteForType(categoryType)}
            />
        );
    }

    return (
        <div className={`${CSS_CLASS_NAME} ${LAYOUT_CLASS_NAME}`}>
            <div className={`${LAYOUT_CLASS_NAME}__content`}>
                <Paragraph
                    size={ParagraphSizes.Large}
                    cssClassName={`${LAYOUT_CLASS_NAME}__content__heading`}>
                    {headerLabel}
                </Paragraph>
                <Form onSubmit={handleFormSubmit}>
                    <InputFormField
                        disabled={saving}
                        errorMessage={titleError}
                        label="Title"
                        onChange={handleTitleChange}
                        required={true}
                        value={category.title}
                        maxLength={150}
                        isValid={StringUtils.isEmpty(titleError)}
                        placeholder="Category title..."
                    />
                    <InputFormField
                        disabled={saving}
                        label="Description"
                        onChange={handleDescriptionChange}
                        required={false}
                        value={category.description}
                        maxLength={150}
                        isValid={true}
                        placeholder="Category description..."
                    />
                </Form>
            </div>
            <div className={`${LAYOUT_CLASS_NAME}__footer`}>
                {saving && (
                    <Loader
                        accessibleText={`Saving ${typeLabels.singular.toLowerCase()}...`}
                    />
                )}
                {!saving && (
                    <React.Fragment>
                        <div className={`${LAYOUT_CLASS_NAME}__footer__left`}>
                            {category.id == null && (
                                <Anchor
                                    cssClassName="c-button -secondary"
                                    to={CategoryUtils.getDashboardRouteForType(
                                        categoryType
                                    )}>
                                    Cancel
                                </Anchor>
                            )}
                            <Button onClick={handleFormSubmit}>
                                {category.id == null ? "Create " : "Save "}
                                {typeLabels.singular}
                            </Button>
                        </div>
                        <div className={`${LAYOUT_CLASS_NAME}__footer__right`}>
                            <div
                                className={`${LAYOUT_CLASS_NAME}__footer__right__left`}>
                                {(category.updatedOn != null ||
                                    category.createdOn != null) && (
                                    <React.Fragment>
                                        <Paragraph>
                                            Last edited
                                            {user != null &&
                                                ` by ${user.getFirstAndLastName()}`}
                                        </Paragraph>
                                        <Paragraph>
                                            {DateUtils.formatLastEditedDate(
                                                category
                                            )}
                                        </Paragraph>
                                    </React.Fragment>
                                )}
                            </div>
                            <div
                                className={`${LAYOUT_CLASS_NAME}__footer__right__right`}>
                                {category.id != null && (
                                    <MenuButton
                                        buttonAccessibleText={`${typeLabels.singular} Options`}>
                                        <Button
                                            accessibleText={`Delete ${typeLabels.singular}`}
                                            key="delete-category-button"
                                            onClick={() =>
                                                setShowDeleteConfirmModal(true)
                                            }>
                                            Delete {typeLabels.singular}
                                        </Button>
                                    </MenuButton>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <ConfirmationModal
                label={`Confirm ${typeLabels.singular} Deletion`}
                confirmButtonStyle={ButtonStyles.Destructive}
                confirmButtonText={"Yes, Delete"}
                isVisible={showDeleteConfirmModal}
                message={deleteConfirmationText}
                onCancel={() => setShowDeleteConfirmModal(false)}
                onConfirm={deleteCategory}
            />
            <UnsavedChangesPrompt
                isDirty={isDirty}
                message="Any unsaved changes will be lost."
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoryEditor;

// #endregion Exports
