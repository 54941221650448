import { SkipNavLink } from "@reach/skip-nav";
import { OfflineSessionConstants } from "constants/offline-session-constants";
import moment from "moment";
import AuthenticatedOverlay from "organisms/overlays/authenticated-overlay";
import SidebarNavigation from "organisms/sidebar-navigation/sidebar-navigation";
import * as React from "react";
import { useEffect, useState } from "react";
import { CookieUtils } from "utilities/cookie-utils";
import { SessionStatus } from "utilities/enumerations/session-status";
import { useLocalization } from "utilities/hooks/use-localization";
import useSessionStatus from "utilities/hooks/use-session-status";
import CultureResources from "utilities/interfaces/culture-resources";
import { NestedRoutes } from "utilities/routing/nested-route";
import ErrorBoundary from "molecules/errors/error-boundary";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";

/*
---------------------------------------------------------------------------------------------
Component
---------------------------------------------------------------------------------------------
*/

const BASE_CLASS = "c-application-layout";

const ApplicationLayout: React.FC<any> = (props: any) => {
    const { t } = useLocalization<CultureResources>();
    const sessionStatus = useSessionStatus();
    const [showAlert, setShowAlert] = useState(false);
    const handleClose = (): void => {
        setShowAlert(false);
        CookieUtils.set(
            OfflineSessionConstants.sessionExpirationCookieName,
            moment().add(24, "hours").toISOString()
        );
    };

    const { isInEmulationMode } = useEmulationMode();

    const className = isInEmulationMode
        ? `${BASE_CLASS} -emulation-mode`
        : BASE_CLASS;

    useEffect(
        function initializeTimeoutWarning() {
            if (
                sessionStatus === SessionStatus.Stale &&
                CookieUtils.get(
                    OfflineSessionConstants.sessionExpirationCookieName
                ) == null
            ) {
                setShowAlert(true);
            }
        },
        [sessionStatus]
    );

    return (
        <React.Fragment>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <div className={className}>
                <SidebarNavigation />
                <div className={`${BASE_CLASS}__panel`}>
                    <AuthenticatedOverlay
                        onDismiss={handleClose}
                        visible={showAlert}
                    />

                    <ErrorBoundary>
                        {!showAlert && (
                            <NestedRoutes
                                redirectIfNotFound={true}
                                routes={props.routes}
                            />
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        </React.Fragment>
    );
};

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default ApplicationLayout;
