import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import { useState, useCallback } from "react";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";

interface ServiceHookLoadingOptions {
    resetResultObject?: boolean;
    resetRowCount?: boolean;
}

interface ServiceHookResultState<T> {
    result: ServiceHookResult<T>;
    setError: (error: any) => void;
    setInitial: () => void;
    setLoading: (resetOptions?: ServiceHookLoadingOptions) => void;
    setSuccess: (resultObject: T, rowCount?: number) => void;
}

export default function useServiceHookResultState<T>(
    initialValue: T
): ServiceHookResultState<T> {
    const defaultValue = useDeepCompareMemo(() => initialValue, [initialValue]);

    const [result, setResult] = useState<ServiceHookResult<T>>(
        ServiceHookResultFactory.initialResult(initialValue)
    );

    const setError = useCallback(
        (error: any) =>
            setResult(
                ServiceHookResultFactory.errorResult(defaultValue, error)
            ),
        [defaultValue]
    );

    const setInitial = useCallback(
        () => setResult(ServiceHookResultFactory.initialResult(defaultValue)),
        [defaultValue]
    );

    const setLoading = useCallback(
        (resetOptions?: ServiceHookLoadingOptions) => {
            setResult((prev) =>
                ServiceHookResultFactory.loadingResult(
                    resetOptions?.resetResultObject
                        ? defaultValue
                        : prev.resultObject,
                    resetOptions?.resetRowCount ? undefined : prev.rowCount
                )
            );
        },
        [defaultValue]
    );

    const setSuccess = useCallback(
        (resultObject: T, rowCount?: number) =>
            setResult(
                ServiceHookResultFactory.successResult(resultObject, rowCount)
            ),
        []
    );

    return {
        result,
        setError,
        setInitial,
        setLoading,
        setSuccess,
    };
}
