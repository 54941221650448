import { useLocalization } from "utilities/hooks/use-localization";
import { BillingCycleOption } from "constants/billing-cycle-options";
import React from "react";
import SubscriptionType from "organisms/enums/subscription-type";
import RadioButton, { RadioButtonProps } from "atoms/forms/radio-button-input";
import Paragraph from "atoms/typography/paragraph";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface BillingCycleRadioButtonsProps
    extends Pick<RadioButtonProps<string>, "disabled"> {
    /**
     * Set to true if the form should display in an errored state
     */
    errored?: boolean;

    inline?: boolean;

    /**
     * Callback to be executed when an option is selected
     */
    onCheck: (option: BillingCycleOption) => void;

    /**
     * Optional title to display above the fieldset
     */
    title?: string;

    /**
     * When the subscription type is a "Team", this is for determining the size of the team
     */
    teamSize?: SubscriptionTeamSize;

    /**
     * Type of subscription to derive billing cycle options from
     */
    type?: SubscriptionType;

    /**
     * Checked value to display
     */
    value?: BillingCycleOption;

    includePrice?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-billing-cycle-radio-buttons";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BillingCycleRadioButtons = (props: BillingCycleRadioButtonsProps) => {
    const {
        disabled = false,
        errored,
        inline = false,
        onCheck,
        teamSize,
        type,
        title,
        value,
        includePrice = true,
    } = props;
    const { t } = useLocalization<CultureResources>();
    const { getBillingCycleType } = useEcommercePrices();
    const erroredModifier = errored === true ? "-error" : "";
    const inlineModifier = inline === true ? "-inline" : "";
    const billingCycleOptions = getBillingCycleType(type, teamSize);

    return (
        <fieldset
            className={`${CSS_BASE_CLASS_NAME} ${erroredModifier} ${inlineModifier}`}>
            {StringUtils.hasValue(title) && <legend>{title}</legend>}
            {billingCycleOptions.map((option: BillingCycleOption) => {
                const isChecked = value?.pin === option.pin;
                const handleCheck = () => onCheck(option);
                const bestValueText = option.isBestValue
                    ? `(${t("bestValue")})`
                    : "";
                return (
                    <RadioButton
                        autofocus={isChecked}
                        checked={isChecked}
                        dataTestId={option.pin}
                        disabled={disabled}
                        id={option.pin}
                        key={option.pin}
                        label={""}
                        name={CSS_BASE_CLASS_NAME}
                        onCheck={handleCheck}>
                        <Paragraph>
                            {option.description}
                            {includePrice && (
                                <React.Fragment>
                                    {` ${UnicodeCharacterConstants.EnDash}`}
                                    <span>{` ${option.price} ${bestValueText}`}</span>
                                </React.Fragment>
                            )}
                        </Paragraph>
                    </RadioButton>
                );
            })}
            {errored === true && (
                <div className={`${CSS_BASE_CLASS_NAME}__errors`}>
                    <Icon type={Icons.Information} />
                    <span>{t("subscriptionCard-mustSelectBillingCycle")}</span>
                </div>
            )}
        </fieldset>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default BillingCycleRadioButtons;

// #endregion Export
