import StringUtils from "utilities/string-utils";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import NumberUtils from "utilities/number-utils";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import { ToastManager } from "utilities/toast/toast-manager";
import { useAtom } from "jotai";
import WorkGroupsAtom from "utilities/atoms/work-group/work-groups-atom";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

const baseEndpoint = "workgroups";

const useWorkGroupCreateService = () => {
    const [workGroupRecords, setWorkGroupRecords] = useAtom(WorkGroupsAtom);

    const { t } = useLocalization<CultureResources>();

    const SUCCESS = t("workgroup-create-success");
    const FAILURE = t("workgroup-create-failure");

    const { result, setLoading, setSuccess } = useServiceHookResultState(
        new WorkGroupRecord()
    );

    const useCreate = ServiceHookFactory.useCreate(
        WorkGroupRecord,
        baseEndpoint
    );
    const { create } = useCreate();

    const createWorkGroup = async (newWorkGroup: WorkGroupRecord) => {
        const isWorkGroupValid =
            !NumberUtils.isDefault(newWorkGroup.groupId) &&
            !StringUtils.isEmpty(newWorkGroup.title);

        if (!isWorkGroupValid) {
            ToastManager.error(FAILURE);
            return;
        }

        setLoading();
        try {
            const { resultObject: createResult } = await create(newWorkGroup);
            if (createResult != null) {
                setSuccess(createResult);
                const newWorkGroups = [...workGroupRecords, createResult];
                setWorkGroupRecords(newWorkGroups);
                ToastManager.success(SUCCESS);
            }
        } catch (error) {
            ToastManager.error(FAILURE);
        }
    };

    return { createWorkGroup, loading: result.loading };
};

export default useWorkGroupCreateService;
