const B2C_CONFIG = {
    DOMAIN: process.env.REACT_APP_B2C_DOMAIN ?? "",
    TENANT: process.env.REACT_APP_B2C_TENANT ?? "",
    POLICY: process.env.REACT_APP_B2C_POLICY ?? "",
    CLIENT_ID: process.env.REACT_APP_B2C_CLIENT_ID ?? "",
    REDIRECT_URI: `${window.location.origin}/${process.env.REACT_APP_B2C_LOGIN_REDIRECT_URI}`,
    POST_LOGOUT_REDIRECT_URI: `${window.location.origin}/${process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI}`,
    FORGOT_PASSWORD_POLICY:
        process.env.REACT_APP_B2C_FORGOT_PASSWORD_POLICY ?? "",
};

export default B2C_CONFIG;
