import Loader from "atoms/loaders/loader";
import DataTable from "organisms/data-tables/data-table";
import React from "react";

export interface ChangeSummariesAdminTableProps {
    loading: boolean;
    children?: React.ReactNode;
}

const CSS_CLASS_NAME = "c-enhanced-content-admin-table";
const CSS_CLASS_NAME_HEADER = `${CSS_CLASS_NAME}__header`;

const ChangeSummariesAdminTable: React.FC<ChangeSummariesAdminTableProps> = (
    props: ChangeSummariesAdminTableProps
) => {
    const { loading, children } = props;

    if (loading) {
        return <Loader accessibleText={"Loading Enhanced Content..."} />;
    }

    return (
        <DataTable cssClassName={`${CSS_CLASS_NAME}`}>
            <thead className={`${CSS_CLASS_NAME_HEADER}`}>
                <tr>
                    <th className={`${CSS_CLASS_NAME_HEADER}-content`}>
                        Content
                    </th>
                    <th className={`${CSS_CLASS_NAME_HEADER}-publication`}>
                        Publication
                    </th>
                    <th>Note</th>
                    <th
                        className={`${CSS_CLASS_NAME_HEADER}-last-change -right-col`}>
                        Last Change
                    </th>
                    <th
                        className={`${CSS_CLASS_NAME_HEADER}-status -right-col`}>
                        Status
                    </th>
                    <th className={"-collapse"}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {loading ? (
                    <Loader accessibleText={"Loading Change Summaries..."} />
                ) : children ? (
                    children
                ) : (
                    <tr>
                        <td colSpan={5} className="-no-results-row">
                            No Results
                        </td>
                    </tr>
                )}
            </tbody>
        </DataTable>
    );
};

export default ChangeSummariesAdminTable;
