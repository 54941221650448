import Button from "atoms/buttons/button";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React, { useEffect, useMemo } from "react";
import useOrganizationManifests from "utilities/hooks/domain/organizations/use-organization-manifests";
import { t } from "utilities/localization-utils";
import { useParams } from "react-router-dom";

interface OrganizationLoginPageProps {}

interface OrganizationLoginPageParams {
    loginSlug: string;
}

export const ORGANIZATION_BASE_CLASS = "c-organization-login-page";

const OrganizationLoginPage = (props: OrganizationLoginPageProps) => {
    const { loginSlug } = useParams<OrganizationLoginPageParams>();

    const {
        list: listManifests,
        isLoading: isOrganizationLoading,
        organizationManifests,
    } = useOrganizationManifests();

    const isLoading = isOrganizationLoading;

    const manifestForSlug = useMemo(
        () =>
            organizationManifests?.find(
                (manifest) => manifest.loginSlug === loginSlug
            ),
        [loginSlug, organizationManifests]
    );

    const isNotFound = manifestForSlug == null;

    useEffect(() => {
        listManifests();
    }, [listManifests]);

    if (isLoading) {
        return <FullScreenTransition transitionText="" />;
    }

    return (
        <>
            {isNotFound && (
                <p>{`Organization Login for ${loginSlug} was not found.`}</p>
            )}
            {manifestForSlug && (
                <>
                    <h1>{manifestForSlug.name}</h1>
                    <Button>{t("logIn")}</Button>
                </>
            )}
        </>
    );
};

export default OrganizationLoginPage;
