import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { PublicationParams } from "interfaces/routing/publication-params";
import React, { useMemo } from "react";
import { match } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBreadcrumbsContext } from "utilities/contexts/use-breadcrumbs-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useCurrentPublication from "utilities/contexts/use-current-publication";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationBreadcrumbProps {
    match: match<PublicationParams>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

export const PublicationBreadcrumb: React.FC<PublicationBreadcrumbProps> = (
    props: PublicationBreadcrumbProps
) => {
    const { match } = props;
    const { setPageTitle } = useHeaderData({ match });
    const { inModal } = useBreadcrumbsContext();
    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );
    const { publication } = useCurrentPublication();

    const breadcrumbLabel = useMemo(() => {
        if (!publication.isPersisted()) {
            return "...";
        }

        return !isMobile || inModal
            ? `${publication.edition} NFPA-${publication.code}`
            : `${publication.code} (${publication.edition})`;
    }, [inModal, isMobile, publication]);

    useDeepCompareEffect(() => {
        if (publication.isPersisted()) {
            setPageTitle(publication.getPageTitle());
        }
    }, [publication, setPageTitle]);

    return (
        <React.Fragment>
            <Breadcrumb>
                <span className="c-breadcrumb__label">{breadcrumbLabel}</span>
            </Breadcrumb>
        </React.Fragment>
    );
};

// #endregion Components
