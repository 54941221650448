import { atom, SetStateAction } from "jotai";
import OfflineBookViewAnalyticsRecord, {
    OfflineBookViewAnalyticsState,
} from "models/view-models/atoms/offline-bookview-analytics-record/offline-bookview-analytics-record";
import { LocalStorageUtils } from "utilities/local-storage-utils";

const key = "offlineBookViewAnalytics";

const baseAtom = atom(
    LocalStorageUtils.get<OfflineBookViewAnalyticsRecord>(
        key,
        OfflineBookViewAnalyticsRecord
    ) ?? new OfflineBookViewAnalyticsRecord()
);

export const OfflineBookViewAnalyticsAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: SetStateAction<OfflineBookViewAnalyticsRecord>) => {
        const newValue =
            typeof update === "function" ? update(get(baseAtom)) : update;
        set(baseAtom, newValue);
        LocalStorageUtils.set<OfflineBookViewAnalyticsState>(key, newValue);
    }
);
