import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import RichTextReferenceLink from "molecules/rich-text-area/rich-text-reference-link";
import React, { useEffect, useRef, useState } from "react";
import NumberUtils from "utilities/number-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextLinkConverterProps extends XmlConvertedComponentProps {
    entityId?: string;
    entityType?: string;
    href?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-reference-link";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextLinkConverter: React.FC<RichTextLinkConverterProps> = (
    props: RichTextLinkConverterProps
) => {
    const classNames = [CSS_CLASS_NAME];
    if (props.className != null) {
        classNames.push(props.className);
    }

    const { entityType } = props;
    const entityId = NumberUtils.parseInt(props.entityId);

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [isInModal, setIsInModal] = useState<boolean>(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const anchorRef = useRef<HTMLAnchorElement>(null);

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    const inModal = anchorRef.current?.offsetParent?.classList.contains(
        "c-modal"
    )
        ? true
        : false;

    const isSection =
        entityType?.toLowerCase() ===
        PublicationEntityTypeConstants.Section.toLowerCase();

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        if (inModal) {
            setIsInModal(true);
        }
    }, [inModal]);

    // #endregion Effects

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    // if it's not a reference link or is missing required info
    // to be a reference link, or it's not to a section component,
    // or the feature flag is disabled
    if (
        isInModal ||
        !isSection ||
        !StringUtils.hasValue(entityType) ||
        entityId == null
    ) {
        return (
            <a {...props} className={classNames.join(" ")}>
                {props.children}
            </a>
        );
    }

    return (
        <RichTextReferenceLink
            className={classNames.join(" ")}
            href={props.href}
            ref={anchorRef}
            sectionId={entityId}>
            {props.children}
        </RichTextReferenceLink>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextLinkConverter;

// #endregion Exports
