import Button from "atoms/buttons/button";
import PublishStatus from "models/enumerations/publish-status";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import React, { useState } from "react";
import AdminChangeSummaryService from "utilities/services/admin/change-summaries/change-summary-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface PublishChangeSummaryButtonProps {
    changeSummary: ChangeSummaryRecord;
    updateChangeSummary: (changeSummary: ChangeSummaryRecord) => void;
}

const PublishChangeSummaryButton = ({
    updateChangeSummary,
    changeSummary,
}: PublishChangeSummaryButtonProps) => {
    const [loading, setLoading] = useState(false);

    const handlePublish = async (
        newPublishStatus: PublishStatus.Published | PublishStatus.Unpublished
    ) => {
        setLoading(true);
        try {
            const payload = new ChangeSummaryRecord({
                id: changeSummary.id!,
                publishStatus: newPublishStatus,
            });

            const result = await AdminChangeSummaryService.publish(payload);
            if (result.resultObject == null) throw new Error();

            updateChangeSummary(
                changeSummary.with({
                    publishStatus: result.resultObject.publishStatus,
                })
            );
            ToastManager.success(
                `Successfully ${
                    newPublishStatus === PublishStatus.Published
                        ? "published"
                        : "unpublished"
                } change summary!`
            );
        } catch {
            ToastManager.error("Oops! Something went wrong, please try again.");
        } finally {
            setLoading(false);
        }
    };

    return changeSummary.publishStatus === PublishStatus.Published ? (
        <Button
            disabled={loading}
            onClick={() => handlePublish(PublishStatus.Unpublished)}>
            Unpublish
        </Button>
    ) : changeSummary.publishStatus ===
      PublishStatus.PublishedWithUnpublishedChanges ? (
        <Button
            disabled={loading}
            onClick={() => handlePublish(PublishStatus.Published)}>
            Publish Changes
        </Button>
    ) : changeSummary.publishStatus === PublishStatus.Unpublished ? (
        <Button
            disabled={loading}
            onClick={() => handlePublish(PublishStatus.Published)}>
            Publish
        </Button>
    ) : null;
};

export default PublishChangeSummaryButton;
