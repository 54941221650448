import { Record } from "immutable";
import type UserCollectionBookmark from "models/interfaces/user-collection-bookmark";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: UserCollectionBookmark =
    RecordUtils.auditableDefaultValuesFactory({
        bookmark: undefined,
        bookmarkId: 0,
        collection: undefined,
        collectionId: 0,
    });

export default class UserCollectionBookmarkRecord
    extends Record(defaultValues)
    implements UserCollectionBookmark
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: UserCollectionBookmark) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.bookmark != null) {
            params.bookmark = RecordUtils.ensureRecord(
                params.bookmark,
                UserBookmarkRecord
            );
        }

        if (params.collection != null) {
            params.collection = RecordUtils.ensureRecord(
                params.collection,
                UserCollectionRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(
        values: Partial<UserCollectionBookmark>
    ): UserCollectionBookmarkRecord {
        return new UserCollectionBookmarkRecord(
            Object.assign(this.toJS(), values)
        );
    }

    public withBookmark(
        bookmark?: UserBookmarkRecord
    ): UserCollectionBookmarkRecord {
        return this.with({ bookmark });
    }

    public withCollection(
        collection?: UserCollectionRecord
    ): UserCollectionBookmarkRecord {
        return this.with({ collection });
    }

    // #endregion Public Methods
}
