import React, { useRef } from "react";
import uuid from "uuid";
import TextArea from "atoms/forms/text-area";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TextAreaFormFieldProps {
    disabled?: boolean;
    errorMessage?: string;
    errorMessages?: string[];
    fieldId?: string;
    /**
     * Unique identifier used select the underlying <input> for functional/e2e testing
     */
    helperText?: string;
    inputTestId?: string;
    isValid?: boolean;
    label: string;
    maxLength?: number;
    name?: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    required?: boolean;
    rows?: number;
    value?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CSS_CLASS = "c-form-field";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TextAreaFormField: React.FC<TextAreaFormFieldProps> = (
    props: TextAreaFormFieldProps
) => {
    const {
        disabled,
        errorMessage,
        errorMessages,
        helperText,
        inputTestId,
        isValid,
        label,
        maxLength,
        name,
        onChange,
        placeholder,
        required,
        rows,
        value,
    } = props;

    const fieldId = props.fieldId ?? uuid.v4();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleOnInput = () => {
        const textAreaRefCurrent = textAreaRef?.current;
        if (textAreaRef == null || textAreaRefCurrent == null) {
            return;
        }

        // There are no native ways to auto resize a "<textarea></textarea>" element.
        // https://css-tricks.com/auto-growing-inputs-textareas
        // This solution sets the height of the element based on it's scrollheight when the user
        // adds input.
        textAreaRefCurrent.style.height = "auto";
        textAreaRefCurrent.style.height = `${textAreaRefCurrent.scrollHeight}px`;
    };

    return (
        <div className={`${BASE_CSS_CLASS} ${isValid ? "" : "-invalid"}`}>
            <label htmlFor={fieldId}>
                {label}
                {required ? "*" : ""}
            </label>
            <TextArea
                disabled={disabled}
                id={fieldId}
                testId={inputTestId}
                maxLength={maxLength}
                name={name}
                onChange={onChange}
                onInput={handleOnInput}
                placeholder={placeholder}
                ref={textAreaRef}
                rows={rows}
                value={value}
            />
            <div className={`${BASE_CSS_CLASS}__errors`}>
                {
                    // if
                    errorMessage != null && errorMessage.length > 0 && (
                        <label>{errorMessage}</label>
                    )
                }
                {
                    // if
                    errorMessages != null &&
                        errorMessages.map((s: string) => (
                            <label key={s}>{s}</label>
                        ))
                }
            </div>
            <div className={`${BASE_CSS_CLASS}__bottom__helper-text`}>
                {helperText}
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TextAreaFormField;

// #endregion Exports
