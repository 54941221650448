import type UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useMemo, useState } from "react";
import UserBookmarkService from "utilities/services/user-bookmarks/user-bookmark-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultErrorHandler = () =>
    ToastManager.error("There was an issue deleting this bookmark.");

/**
 * Custom hook to wrap up the API calls to delete bookmarks.
 */
export default function useDeleteBookmark(
    bookmark: UserBookmarkRecord,
    onSuccess?: (bookmark: UserBookmarkRecord) => void,
    onError?: () => void
) {
    const { delete: deleteBookmarkApi } = UserBookmarkService.useDelete();

    const [loading, setLoading] = useState(false);

    const handleError = useMemo(
        () => onError ?? defaultErrorHandler,
        [onError]
    );

    const deleteBookmark = async () => {
        setLoading(true);

        try {
            await deleteBookmarkApi(bookmark.id!);
            onSuccess?.(bookmark);
        } catch (e) {
            handleError();
        }

        setLoading(false);
    };

    return { deleteBookmark, loading };
}
