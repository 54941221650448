import PublicationSidebarNavAnchor from "atoms/anchors/publication-sidebar-nav-anchor";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import { ChapterNfpaLabelParams } from "interfaces/routing/chapter-nfpa-label-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { TablePageParams } from "interfaces/routing/table-page-params";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import PublicationSidebarNavLinks from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-links";
import React from "react";
import { match } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import useChapterTables from "utilities/hooks/domain/publications/chapters/use-chapter-tables";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import { useLocationHash } from "utilities/hooks/use-location-hash";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";

interface ChapterTablesMetanavProps {
    match: match<
        NestedPublicationParams & ChapterNfpaLabelParams & TablePageParams
    >;
}

const ChapterTablesMetanav: React.FunctionComponent<ChapterTablesMetanavProps> =
    (props: ChapterTablesMetanavProps) => {
        const { match } = props;
        const { chapterNfpaLabel, chapterId, code, edition, publicationId } =
            match.params;
        const activeExternalId = useLocationHash();
        const { isAdminPreview } = useAdminPreview();
        const { t } = useLocalization<CultureResources>();

        const {
            errors: publicationErrors,
            loaded: publicationLoaded,
            loading: publicationLoading,
            resultObject: publication,
        } = usePublication({
            code,
            edition,
            publicationId,
        });

        const {
            errors: chapterErrors,
            loaded: chapterLoaded,
            loading: chapterLoading,
            resultObject: chapter,
        } = useChapter({
            publicationId: publication.id,
            chapterId,
            nfpaLabel: chapterNfpaLabel,
        });

        const {
            errors: tablesErrors,
            loaded: tablesLoaded,
            loading: tablesLoading,
            resultObject: tables,
        } = useChapterTables(isAdminPreview, chapter.id);

        const navItems: Array<PublicationSidebarNavItem> =
            useDeepCompareMemo(() => {
                return tables.map((table) => {
                    const active =
                        StringUtils.hasValue(activeExternalId) &&
                        table.externalId === activeExternalId;

                    return table.toNavItem({ active });
                });
            }, [activeExternalId, tables]);

        const errors = useErrors(
            chapterErrors,
            publicationErrors,
            tablesErrors
        );
        useErrorBoundary(errors);

        const loaded = chapterLoaded && publicationLoaded && tablesLoaded;
        const loading = useLoading(
            chapterLoading,
            publicationLoading,
            tablesLoading
        );
        const loaderText = t("loadingItem", { item: t("table") });

        const backButtonText = chapter.getDisplayTitle();
        const backButtonAccessibleText = t("backToLocation", {
            location: chapter?.getDisplayTitle(),
        });
        const backButtonRoute =
            chapter?.with({ publication }).getRoute(isAdminPreview) ?? "#";

        const title = `Tables in ${chapter.getDisplayTitle()}`;
        const publicationType = publication?.type;

        return (
            <React.Fragment>
                <PublicationSidebarNavAnchor
                    accessibleText={backButtonAccessibleText}
                    linkText={backButtonText}
                    to={backButtonRoute}
                />
                {CollectionUtils.hasValues(errors) && (
                    <UnorderedList listItems={errors} />
                )}
                {loading && (
                    <Loader
                        accessibleText={loaderText}
                        type={LoaderStyles.LinkGlyphGray}
                    />
                )}
                {chapter != null && loaded && (
                    <PublicationSidebarNav title={title}>
                        <PublicationSidebarNavLinks
                            navItems={navItems}
                            publicationType={publicationType}
                        />
                    </PublicationSidebarNav>
                )}
            </React.Fragment>
        );
    };

export default ChapterTablesMetanav;
