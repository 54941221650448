/**
 * Data configurations to mirror backend data validation configurations.
 */
export const DataConfiguration = {
    EmailLength: 250,
    IpAddressLength: 39,
    ExtraShortStringLength: 100,
    LongDescriptionLength: 1000,
    ShortDescriptionLength: 500,
    ShortNoteLength: 160,
    ShortStringLength: 150,
    ShortTitleLength: 60,
    LongTitleLength: 300,
    UrlLength: 2083,
};
