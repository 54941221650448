import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
interface SymbolProps extends XmlConvertedComponentProps {
    includeEqualsSign?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const SYMBOL_CONVERTER_ID = "symbol";
const CSS_BASE_CLASS = "c-symbol";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

// #endregion Component

const Symbol: React.FunctionComponent<SymbolProps> = (props: SymbolProps) => {
    if (props.includeEqualsSign === true) {
        return (
            <div className={CSS_BASE_CLASS}>
                {props.children}
                <span className={`${CSS_BASE_CLASS}__equals-sign`}>=</span>
            </div>
        );
    }
    return <div className="c-symbol">{props.children}</div>;
};

export default Symbol;
