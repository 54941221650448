import { Quill } from "react-quill";

const Block = Quill.import("blots/block");

/**
 * Custom format to render headers as <p> tags with
 * `-header` CSS class and `data-header="1/2/3" instead of using H1/2/3 elements.
 *
 * The Quill documentation on custom formats is somewhat lacking,
 * I had to look at the Quill source code to get this to work.
 *
 * @see https://github.com/quilljs/quill/blob/eda1472fb0813455cec38af296a24057b254c29d/modules/toolbar.js#L110
 * @see https://github.com/quilljs/quill/blob/eda1472fb0813455cec38af296a24057b254c29d/blots/block.js
 * @see https://github.com/quilljs/quill/blob/eda1472fb0813455cec38af296a24057b254c29d/formats/header.js
 */
export default class CustomHeader extends Block {
    public static blotName: string = "header";
    public static tagName: string = "P";
    public static className: string = "-header";
    public static DATA_HEADER_ATTR: string = "data-header";

    /**
     * Create the HTML element representing the header format.
     * @param value the `value` attribute corresponding to the clicked toolbar button and the current format
     */
    public static create(
        value: any
    ): ReturnType<typeof document.createElement> {
        if (
            value?.toString() === "1" ||
            value?.toString() === "2" ||
            value?.toString() === "3"
        ) {
            const domNode = document.createElement(CustomHeader.tagName);
            domNode.classList.add(CustomHeader.className);
            domNode.setAttribute(CustomHeader.DATA_HEADER_ATTR, value);
            return domNode;
        }

        return document.createElement(value);
    }

    /**
     * Returns the value of the formatted DOM node.
     * No special behavior needed here, just need to return the value.
     * @param domNode
     */
    public static value(domNode: HTMLElement): HTMLElement {
        return domNode;
    }

    /**
     * The value of the currently applied formats. This should
     * match the `value` attribute of the <option> elements within
     * the Heading dropdown in the toolbar, so that the <option>
     * gets selected when the line is selected in the editor.
     * @param domNode
     */
    public static formats(domNode: HTMLElement): string {
        if (
            domNode.classList.contains(CustomHeader.className) &&
            domNode.hasAttribute(CustomHeader.DATA_HEADER_ATTR)
        ) {
            return domNode.getAttribute(CustomHeader.DATA_HEADER_ATTR)!;
        }

        return "0";
    }
}
