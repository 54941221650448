import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import * as React from "react";
import { useEffect } from "react";
import { match } from "react-router-dom";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";

interface AnnexBreadcrumbProps {
    match: match<NestedPublicationParams & NfpaLabelParams>;
}

const AnnexBreadcrumb: React.FunctionComponent<AnnexBreadcrumbProps> = (
    props: AnnexBreadcrumbProps
) => {
    const { match } = props;
    const { setPageTitle } = useHeaderData({ match });
    const { publication, loaded: publicationLoaded } = useCurrentPublication();

    const { resultObject: annex, loaded } = useAnnex(
        publication.id,
        match.params.id,
        match.params.nfpaLabel
    );

    useEffect(
        () => setPageTitle(annex.getDisplayTitle()),
        [annex, setPageTitle]
    );

    const label =
        loaded && publicationLoaded ? annex.getDisplayTitleWithNumber() : "...";

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{label}</span>
        </Breadcrumb>
    );
};

export default AnnexBreadcrumb;
