import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface OriginsBodyConverterProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OriginsBodyConverter: React.FC<OriginsBodyConverterProps> = (
    props: OriginsBodyConverterProps
) => {
    const CSS_CLASS_NAME = "c-origins-body";

    if (props.children == null) {
        return null;
    }

    return <div className={CSS_CLASS_NAME}>{props.children}</div>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default OriginsBodyConverter;

// #endregion Exports
