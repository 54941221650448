// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export enum StorageErrorKeys {
    STORAGE_QUOTA_EXCEEDED = "STORAGE_QUOTA_EXCEEDED",
    STORAGE_READ = "STORAGE_READ",
    STORAGE_REMOVE = "STORAGE_REMOVE",
    STORAGE_WRITE = "STORAGE_WRITE",
}

// #endregion Constants

export default class StorageError extends Error {
    constructor(
        public key: keyof typeof StorageErrorKeys,
        public message: string,
        ...options: any[]
    ) {
        super(...options);
    }
}
