import { useUpdateAtom } from "jotai/utils";
import { useEffect } from "react";
import { IsClickedAtom, ActiveIdAtom } from "./use-section-scroll-spy";

const HAS_HANDLER_ATTRIBUTE = "hasHandler";

export default function useSectionScrollSpyHandlers() {
    const setIsClicked = useUpdateAtom(IsClickedAtom);
    const setActiveId = useUpdateAtom(ActiveIdAtom);

    const handleClick = (id: number) => {
        setIsClicked(true);
        setActiveId(id);
    };

    let scrollTimer: NodeJS.Timeout | null = null;
    let listenerTimer: NodeJS.Timeout | null = null;

    /**
     * the root property of the Intersection Observer
     */
    const root = document.getElementsByClassName(
        "c-publication-page-layout__content__main"
    )[0] as HTMLElement;

    /**
     * sets isClicked to false and removes the url hash
     */
    const handleScroll = () => {
        scrollTimer = setTimeout(() => {
            root.removeAttribute(HAS_HANDLER_ATTRIBUTE);
            setIsClicked(false);

            if (window.location.hash) {
                window.history.replaceState(
                    "",
                    document.title,
                    window.location.pathname
                );
            }
        }, 100);
    };

    /**
     * waits 500 miliseconds, then adds the scroll handler to the root element
     */
    const addScrollListener = () => {
        if (root == null || root.hasAttribute(HAS_HANDLER_ATTRIBUTE)) return;
        listenerTimer = setTimeout(() => {
            root.setAttribute(HAS_HANDLER_ATTRIBUTE, "true");
            root.addEventListener("scroll", handleScroll, { once: true });
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (scrollTimer != null) clearTimeout(scrollTimer);

            if (listenerTimer != null) clearTimeout(listenerTimer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { addScrollListener, handleClick };
}
