export enum ServiceWorkerCommandTypes {
    BuildSearchIndex = "BUILD_SEARCH_INDEX",
    CheckOfflineDevice = "CHECK_OFFLINE_DEVICE",
    CheckSearchIndexStatus = "CHECK_OFFLINE_SEARCH_INDEX_STATUS",
    CheckStorageUsage = "CHECK_STORAGE_USAGE",
    KeepAlive = "KEEP_ALIVE",
    PreloadUrls = "PRELOAD_URLS",
    RemoveUrls = "REMOVE_URLS",
    Search = "SEARCH",
    SkipWaiting = "SKIP_WAITING",
}
