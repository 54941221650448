import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameWithHtml } from "constants/app-name-tm";
import { siteMap } from "internal-sitemap";
import React from "react";
import { Link } from "react-router-dom";
import { t } from "utilities/localization-utils";

const AlreadyOnTeam = () => {
    return (
        <div>
            <Heading priority={HeadingPriority.Two}>
                {t("on-demand-already-on-team")}
            </Heading>
            <Paragraph>
                {t("on-demand-already-on-team-1")}&nbsp;
                <span
                    dangerouslySetInnerHTML={{
                        __html: AppNameWithHtml,
                    }}
                />
                &nbsp;{t("on-demand-already-on-team-2")}
            </Paragraph>
            <Link className="-block" to={siteMap.userlogins.new}>
                {t("on-demand-login")}
            </Link>
            <Paragraph>{t("on-demand-contact-admin-1")}</Paragraph>
        </div>
    );
};

export default AlreadyOnTeam;
