import { RecordUtils } from "andculturecode-javascript-core";
import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import { Record } from "immutable";
import type Hit from "models/interfaces/search/hit";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import { PartRecord } from "internal";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { nameof } from "ts-simple-nameof";
import { CollectionUtils } from "utilities/collection-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import NumberUtils from "utilities/number-utils";
import ReferenceLink, {
    ReferenceLinkValue,
} from "utilities/quill/formats/reference-link";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";
import EnhancedContentRecord from "../enhanced-content-record";

const defaultValues: Hit = RecordUtils.defaultValuesFactory<Hit>({
    annex: undefined,
    annexId: undefined,
    article: undefined,
    articleId: undefined,
    body: undefined,
    categories: [],
    categoryTypes: [],
    chapter: undefined,
    chapterId: undefined,
    enhancedContent: undefined,
    entityId: undefined,
    entityType: undefined,
    id: undefined,
    label: undefined,
    parentId: undefined,
    part: undefined,
    partId: undefined,
    publication: undefined,
    publicationId: undefined,
    section: undefined,
    sectionId: undefined,
    situation: undefined,
    solution: undefined,
    subtitle: undefined,
    title: undefined,
    uploadId: undefined,
});

export default class HitRecord extends Record(defaultValues) implements Hit {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Hit) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.annex != null) {
            params.annex = RecordUtils.ensureRecord(params.annex, AnnexRecord);
        }

        if (params.article != null) {
            params.article = RecordUtils.ensureRecord(
                params.article,
                ArticleRecord
            );
        }

        if (params.chapter != null) {
            params.chapter = RecordUtils.ensureRecord(
                params.chapter,
                ChapterRecord
            );
        }

        if (params.part != null) {
            params.part = RecordUtils.ensureRecord(params.part, PartRecord);
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (params.section != null) {
            params.section = RecordUtils.ensureRecord(
                params.section,
                SectionRecord
            );
        }

        if (params.situation != null) {
            params.situation = RecordUtils.ensureRecord(
                params.situation,
                SituationRecord
            );
        }

        if (params.solution != null) {
            params.solution = RecordUtils.ensureRecord(
                params.solution,
                SolutionRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Static Members
    // -----------------------------------------------------------------------------------------

    /**
     * Character limit to show in the body of the hit on desktop
     */
    public static DESKTOP_BODY_CHAR_LIMIT: number = 215;

    /**
     * Character limit to show in the body of the hit on mobile
     */
    public static MOBILE_BODY_CHAR_LIMIT: number = 122;

    public static TABLE: string = "Table";

    // #endregion Static Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns whether or not the hit contains all necessary data to be displayed properly
     */
    public isConfigured(): boolean {
        return !(
            StringUtils.isEmpty(this.getRoute()) ||
            (this.isPartOfPublication() &&
                StringUtils.isEmpty(this.getBreadcrumbText()))
        );
    }

    /**
     * Returns the annexId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getAnnexId(): number | undefined {
        if (this.isAnnex()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return NumberUtils.parseInt(this.annexId);
    }

    /**
     * Returns the articleId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getArticleId(): number | undefined {
        if (this.isArticle()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return NumberUtils.parseInt(this.articleId);
    }

    /**
     * Returns the body as a string (defaults to empty string if body is undefined/null)
     */
    public getBody(): string {
        return this.hasBody() ? this.body! : "";
    }

    /**
     * Returns an excerpt of the body based on the window width.
     * If the body contains an 'em' tag, it means the hit body contains a highlighted keyword and
     * should be cut surrounding that tag. Otherwise, it will truncate anything after the first
     * set of characters (dependent on screen width)
     */
    public getBodyExcerpt(width: number): string {
        if (!this.hasBody() || (this.isTable() && !this.isEnhancedContent())) {
            return "";
        }

        let characterLimit = HitRecord.DESKTOP_BODY_CHAR_LIMIT;
        if (width <= Breakpoints.Phone) {
            characterLimit = HitRecord.MOBILE_BODY_CHAR_LIMIT;
        }

        let body = this.getTextOnly(this.body!);

        const matchExpr = new RegExp(
            /(\b[^>\s]+\s?){0,10}<em>.*?<\/em>(\s?(<em>.*?<\/em>|([^<\s]+\s?))){0,10}/,
            "mi"
        );
        const highlightMatch = body.match(matchExpr);

        // Case 1: Body is already the appropriate length, whether or not the <em> tag is found.
        // Return the unmodified body.
        if (body.length <= characterLimit) {
            return body;
        }

        // Case 2: Body does not contain the <em> tag but is longer than the character limit.
        // Truncate the body from the start of the string until the character limit.
        if (highlightMatch == null) {
            return StringUtils.truncateRight(body, characterLimit);
        }

        // Case 3: Body contains the highlighted excerpt match
        return `...${highlightMatch.shift()!}...`;
    }

    /**
     * Builds a string of breadcrumbs based on the related entities that are attached, such as:
     *
     * NFPA 70 — National Electrical Code (2020) / Chapter 4 — Equipment for General Use / Article 425 Fixed Resistance and Electrode Industrial Process Heating Equipment
     */
    public getBreadcrumbText(): string {
        const breadcrumbs: string[] = [];

        if (this.hasPublication()) {
            breadcrumbs.push(this.publication!.getDisplayTitle());
        }

        if (this.hasChapter()) {
            breadcrumbs.push(this.chapter!.getDisplayTitle());
        }

        if (this.hasAnnex()) {
            breadcrumbs.push(this.annex!.getDisplayTitleWithNumber());
        }

        if (this.hasArticle()) {
            breadcrumbs.push(this.article!.getDisplayTitle());
        }

        if (this.hasPart()) {
            breadcrumbs.push(this.part!.getDisplayTitle());
        }

        if (CollectionUtils.hasValues(breadcrumbs)) {
            return breadcrumbs.join(" / ");
        }

        return "";
    }

    /**
     * Returns the chapterId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getChapterId(): number | undefined {
        if (this.isChapter()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return NumberUtils.parseInt(this.chapterId);
    }

    public getExternalId(): string | undefined {
        return this.isSection()
            ? this.section?.externalId
            : this.part?.externalId;
    }

    /**
     * Get a key to use in react when loop-rendering items.
     */
    public getKey(): string {
        return `${this.entityType ?? uuid.v4()}-${this.entityId ?? uuid.v4()}`;
    }

    /**
     * Returns the label as a string (defaults to empty string if label is undefined/null)
     */
    public getLabel(): string {
        return this.hasLabel() ? this.label! : "";
    }

    /**
     * Returns the partId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getPartId(): number | undefined {
        if (this.isPart()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return NumberUtils.parseInt(this.partId);
    }

    /**
     * Returns the publicationId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getPublicationId(): number | undefined {
        return NumberUtils.parseInt(this.publicationId);
    }

    /**
     * Returns the publicationId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getSituationId(): number | undefined {
        if (this.entityType === PublicationEntityTypeConstants.Situation) {
            return NumberUtils.parseInt(this.entityId);
        }
        return undefined;
    }

    /**
     * Returns the publicationId as a number (if it can be parsed)
     *
     * @default undefined
     */
    public getSolutionId(): number | undefined {
        if (this.entityType === PublicationEntityTypeConstants.Solution) {
            return NumberUtils.parseInt(this.entityId);
        }
        return undefined;
    }

    /**
     * Returns the enhancedContentId as a number if this hit is for an enhanced content
     *
     * @default undefined
     */
    public getEnhancedContentId(): number | undefined {
        if (this.isEnhancedContent()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return undefined;
    }

    /**
     * Returns the sectionId as a number if this hit is for a section
     *
     * @default undefined
     */
    public getSectionId(): number | undefined {
        if (this.isSection()) {
            return NumberUtils.parseInt(this.entityId);
        }

        return undefined;
    }

    /**
     * Returns the title as a string (defaults to empty string if title is undefined/null)
     */
    public getTitle(): string {
        return this.hasTitle() ? this.title! : "";
    }

    /**
     * Returns display plaintext for use in
     * <option> elements or Typeahead results
     */
    public getOptionDisplayText(): string {
        if (!this.hasTitle()) {
            return "";
        }

        switch (this.entityType) {
            case PublicationEntityTypeConstants.Situation:
                return this.situation?.title ?? "";
            case PublicationEntityTypeConstants.Solution:
                return this.solution?.title ?? "";
            case PublicationEntityTypeConstants.Chapter:
                return this.chapter?.getDisplayTitle() ?? "";
            case PublicationEntityTypeConstants.Annex:
                return this.annex?.getDisplayTitleWithNumber() ?? "";
            case PublicationEntityTypeConstants.Article:
                return this.article?.getTitleWithLabel() ?? "";
            case PublicationEntityTypeConstants.Part:
                return this.part?.getDisplayTitle() ?? "";
            case PublicationEntityTypeConstants.Section:
                return this.section?.getFullyQualifiedDisplayTitle() ?? "";
            default:
                return "";
        }
    }

    /**
     * Builds a link to the Publication or DiRECT resource based on the ids/nested records.
     *
     * @returns {string} String URL to the resource that this hit relates to - which could be
     * any member of the Publication hierarchy or DiRECT entities
     */
    public getRoute(): string {
        if (this.isSituation() && this.hasSituation()) {
            return this.situation!.getRoute();
        }

        if (this.isSolution() && this.hasSolution()) {
            return this.solution!.getRoute();
        }

        if (this.isEnhancedContent()) {
            return this.enhancedContent?.getRoute() ?? "";
        }

        const model = PublicationAnchorRecord.fromHitRecord(this);

        return model.getRoute() ?? "";
    }

    /**
     * Returns whether or not this hit has an attached AnnexRecord
     */
    public hasAnnex(): boolean {
        return RecordUtils.isRecord(this.annex, AnnexRecord);
    }

    /**
     * Returns whether or not this hit has an attached ArticleRecord
     */
    public hasArticle(): boolean {
        return RecordUtils.isRecord(this.article, ArticleRecord);
    }

    /**
     * Returns whether or not the hit has a non-null, non-whitespace body value
     */
    public hasBody(): boolean {
        if (StringUtils.isEmpty(this.body)) {
            return false;
        }

        return true;
    }

    /**
     * Returns whether or not this hit has an attached ChapterRecord
     */
    public hasChapter(): boolean {
        return RecordUtils.isRecord(this.chapter, ChapterRecord);
    }

    /**
     * Returns whether or not the hit has a non-null, non-whitespace label value
     */
    public hasLabel(): boolean {
        if (StringUtils.isEmpty(this.label)) {
            return false;
        }

        return true;
    }

    /**
     * Returns whether or not this hit has an attached PartRecord
     */
    public hasPart(): boolean {
        return RecordUtils.isRecord(this.part, PartRecord);
    }

    /**
     * Returns whether or not this hit has an attached PublicationRecord
     */
    public hasPublication(): boolean {
        return RecordUtils.isRecord(this.publication, PublicationRecord);
    }

    /**
     * Returns whether or not this hit has an attached SectionRecord
     */
    public hasSection(): boolean {
        return RecordUtils.isRecord(this.section, SectionRecord);
    }

    /**
     * Returns whether or not this hit has an attached SituationRecord
     */
    public hasSituation(): boolean {
        return RecordUtils.isRecord(this.situation, SituationRecord);
    }

    /**
     * Returns whether or not this hit has an attached SolutionRecord
     */
    public hasSolution(): boolean {
        return RecordUtils.isRecord(this.solution, SolutionRecord);
    }

    /**
     * Returns whether or not the hit has a non-null, non-whitespace title value
     */
    public hasTitle(): boolean {
        return !StringUtils.isEmpty(this.title);
    }

    /**
     * Returns whether or not the hit is related to an Annex entity
     */
    public isAnnex(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType,
            nameof<HitRecord>((e) => e.annex)
        );
    }

    /**
     * Returns whether or not the hit is related to an Article entity
     */
    public isArticle(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.article)
        );
    }

    /**
     * Returns whether or not the hit is related to a Chapter entity
     */
    public isChapter(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.chapter)
        );
    }

    /**
     * Returns whether or not the hit is related to a Enhanced Content entity
     */
    public isEnhancedContent(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.enhancedContent)
        );
    }

    /**
     * Returns whether or not the hit is related to a Part entity
     */
    public isPart(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.part)
        );
    }

    /**
     * Returns whether or not the hit is related to a part of a publication.
     *
     * Includes (CAAPS): Chapters, Annexes, Articles, Parts, Sections
     */
    public isPartOfPublication(): boolean {
        return (
            this.isAnnex() ||
            this.isArticle() ||
            this.isChapter() ||
            this.isPart() ||
            this.isSection()
        );
    }

    /**
     * Returns whether or not the hit is related to a Section entity
     */
    public isSection(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.section)
        );
    }

    /**
     * Returns whether or not the hit is related to a Situation entity
     */
    public isSituation(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.situation)
        );
    }

    /**
     * Returns whether or not the hit is related to a Solution entity
     */
    public isSolution(): boolean {
        if (StringUtils.isEmpty(this.entityType)) {
            return false;
        }

        return StringUtils.isEqual(
            this.entityType!,
            nameof<HitRecord>((e) => e.solution)
        );
    }

    /**
     * Returns whether or not this hit represents a table (based on title or label, may need to be
     * updated once tables are persisted entities)
     */
    public isTable(): boolean {
        return (
            (this.title?.includes(HitRecord.TABLE) ||
                this.label?.includes(HitRecord.TABLE)) ??
            false
        );
    }

    /**
     * Generate a ReferenceLinkValue to be inserted in the RTE.
     * @param text the link display text
     */
    public toReferenceLinkValue(text: string): ReferenceLinkValue {
        return {
            text: text.trim(),
            href: this.getRoute(),
            [ReferenceLink.DATA_ENTITY_ID_ATTR]: this.entityId ?? "",
            [ReferenceLink.DATA_ENTITY_TYPE_ATTR]: this.entityType ?? "",
        };
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Hit>} values
     */
    public with(values: Partial<Hit>): HitRecord {
        return new HitRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Adds the associated AnnexRecord relationship based on id when given an array of AnnexRecords.
     *
     * If this HitRecord has no AnnexId, or no matching AnnexId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {AnnexRecord[]} annexes
     */
    public withAnnex(annexes: AnnexRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(annexes)) {
            return this.with({});
        }

        const annex = annexes.find(
            (annex: AnnexRecord) => annex.id === this.getAnnexId()
        );

        return this.with({ annex });
    }

    /**
     * Adds the associated ArticleRecord relationship based on id when given an array of ArticleRecords.
     *
     * If this HitRecord has no ArticleId, or no matching ArticleId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {ArticleRecord[]} articles
     */
    public withArticle(articles: ArticleRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(articles)) {
            return this.with({});
        }

        const article = articles.find(
            (article: ArticleRecord) => article.id === this.getArticleId()
        );

        return this.with({ article });
    }

    /**
     * Adds the associated ChapterRecord relationship based on id when given an array of ChapterRecords.
     *
     * If this HitRecord has no ChapterId, or no matching ChapterId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {ChapterRecord[]} chapters
     */
    public withChapter(chapters: ChapterRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(chapters)) {
            return this.with({});
        }

        const chapter = chapters.find(
            (chapter: ChapterRecord) => chapter.id === this.getChapterId()
        );

        return this.with({ chapter });
    }

    /**
     * Adds the associated PartRecord relationship based on id when given an array of PartRecords.
     *
     * If this HitRecord has no PartId, or no matching PartId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {PartRecord[]} parts
     */
    public withPart(parts: PartRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(parts)) {
            return this.with({});
        }

        const part = parts.find(
            (part: PartRecord) => part.id === this.getPartId()
        );

        return this.with({ part });
    }

    /**
     * Adds the associated PublicationRecord relationship based on id when given an array of PublicationRecords.
     *
     * If this HitRecord has no PublicationId, or no matching PublicationId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {PublicationRecord[]} publications
     */
    public withPublication(publications: PublicationRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(publications)) {
            return this.with({});
        }

        const publication = publications.find(
            (publication: PublicationRecord) =>
                publication.id === this.getPublicationId()
        );

        return this.with({
            annex: this.annex?.withPublication(publications),
            publication,
        });
    }

    /**
     * Adds the associated EnhancedContentRecord relationship based on id when given an array of EnhancedContentRecords.
     *
     * If this HitRecord has no EnhancedContentId, or no matching EnhancedContentId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param enhancedContents
     */
    public withEnhancedContent(
        enhancedContents: EnhancedContentRecord[]
    ): HitRecord {
        if (CollectionUtils.isEmpty(enhancedContents)) {
            return this.with({});
        }

        const enhancedContent = enhancedContents.find(
            (enhancedContent: EnhancedContentRecord) =>
                enhancedContent.id === this.getEnhancedContentId()
        );

        const withComponents = enhancedContent?.with({
            annex: enhancedContent.annex?.with({
                publication: enhancedContent.publication,
            }),
            article: enhancedContent.article?.with({
                chapter: enhancedContent.chapter,
                publication: enhancedContent.publication,
            }),
            chapter: enhancedContent.chapter?.with({
                publication: enhancedContent.publication,
            }),
            section: enhancedContent.section?.with({
                annex: enhancedContent.annex,
                article: enhancedContent.article,
                chapter: enhancedContent.chapter,
                publication: enhancedContent.publication,
            }),
        });

        return this.with({ enhancedContent: withComponents });
    }

    /**
     * Adds the associated SectionRecord relationship based on id when given an array of SectionRecords.
     *
     * If this HitRecord has no SectionId, or no matching SectionId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param sections
     */
    public withSection(sections: SectionRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(sections)) {
            return this.with({});
        }

        const section = sections.find(
            (section: SectionRecord) => section.id === this.getSectionId()
        );

        return this.with({ section });
    }

    /**
     * Adds the associated SituationRecord relationship based on id when given an array of SituationRecords.
     *
     * If this HitRecord has no EntityId with EntityType of situation, or no matching SituationId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {SituationRecord[]} situations
     */
    public withSituations(situations: SituationRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(situations)) {
            return this.with({});
        }

        const situation = situations.find(
            (situation: SituationRecord) =>
                situation.id === this.getSituationId()
        );

        return this.with({ situation });
    }

    /**
     * Adds the associated SolutionRecord relationship based on id when given an array of SolutionRecords.
     *
     * If this HitRecord has no EntityId with EntityType of solution, or no matching SolutionId is found, a new instance is returned
     * but the data should remain unchanged.
     *
     * @param {SolutionRecord[]} solutions
     */
    public withSolutions(solutions: SolutionRecord[]): HitRecord {
        if (CollectionUtils.isEmpty(solutions)) {
            return this.with({});
        }

        const solution = solutions.find(
            (solution: SolutionRecord) => solution.id === this.getSolutionId()
        );

        return this.with({ solution: solution });
    }

    // #endregion Public Methods

    /**
     * Removes non-text elements from body to prevent their rendering in the search modal
     *
     * @param {string} body
     * @returns {string}
     */
    private getTextOnly(body: string): string {
        // Chain more replace() methods or update regex to remove other elements
        // Removes image tags from search results
        return body.replace(/<img[^>]*>/g, "");
    }
}
