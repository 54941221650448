import React from "react";
import Modal from "molecules/modals/modal";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
// import { List } from "immutable";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import Slider from "molecules/slider";
import BookmarkReadOnlyDialog from "./bookmark-readonly-dialog";
import BookmarkSliderHeader from "./bookmark-slider-header";

interface BookmarkReadOnlyMobileModalProps {
    bookmarks: Array<UserBookmarkRecord>;
    index: number;
    isOnFirstBookmark: boolean;
    isOnLastBookmark: boolean;
    isVisible: boolean;
    onClose: () => void;
    onDelete: () => void;
    onEdit: (bookmarkId: number) => void;
    title: string;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    sliderClassName?: string;
    handleAddBookmark: () => void;
    canAddBookmark: boolean;
}

const CSS_CLASS_NAME = "c-bookmark-read-only-mobile-modal";

const BookmarkReadOnlyMobileModal: React.FC<
    BookmarkReadOnlyMobileModalProps
> = (props) => {
    const {
        onClose,
        onEdit,
        onDelete,
        setIndex,
        sliderClassName,
        index,
        isOnFirstBookmark,
        isOnLastBookmark,
        isVisible,
        bookmarks,
        title,
        handleAddBookmark,
        canAddBookmark,
    } = props;

    const bookmarkModifier = bookmarks.length > 1 ? "-team-bookmark" : "";

    return (
        <Modal
            cssClassName={`${CSS_CLASS_NAME} -bottom`}
            closeDialog={onClose}
            isVisible={isVisible}
            label={"Readonly Bookmark"}
            transition={ModalTransitions.SlideUp}
            type={ModalTypes.Base}>
            <div className={`${sliderClassName}__content`}>
                <BookmarkSliderHeader
                    index={index}
                    isOnFirstBookmark={isOnFirstBookmark}
                    isOnLastBookmark={isOnLastBookmark}
                    bookmarks={bookmarks}
                    setIndex={setIndex}
                    handleAddBookmark={handleAddBookmark}
                    onClose={onClose}
                    canAddBookmark={canAddBookmark}
                />
                <Slider index={index}>
                    {bookmarks.map((bookmark: UserBookmarkRecord) => (
                        <BookmarkReadOnlyDialog
                            onClose={onClose}
                            onDeleted={onDelete}
                            onEdit={onEdit}
                            bookmark={bookmark}
                            title={title}
                            key={bookmark.id}
                            modifierClass={bookmarkModifier}
                            isMobile={true}
                            handleAddBookmark={handleAddBookmark}
                        />
                    ))}
                </Slider>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BookmarkReadOnlyMobileModal;

// #endregion Exports
