import Checkbox from "atoms/forms/checkbox";
import InputFormField from "molecules/form-fields/input-form-field";
import React from "react";

export interface PdfPublication {
    code: string;
    contentSourceFileId?: number;
    edition: string;
    title: string;
    usePdfBookmarkTitles: boolean;
}

interface PdfPublicationImportFormProps {
    publication: PdfPublication;
    setPublication: (publication: PdfPublication) => void;
}

const CSS_CLASS_NAME = "c-publication-create";

const PdfPublicationImportForm = (props: PdfPublicationImportFormProps) => {
    const { publication, setPublication } = props;

    const setCodeNumber = (codeNumber: string = "") => {
        setPublication({ ...publication, code: codeNumber });
    };
    const setEditionYear = (editionYear: string = "") => {
        setPublication({ ...publication, edition: editionYear });
    };
    const setCodeTitle = (codeTitle: string = "") => {
        setPublication({ ...publication, title: codeTitle });
    };
    const handleCheckboxChange = (newValue: boolean) => {
        setPublication({ ...publication, usePdfBookmarkTitles: newValue });
    };

    return (
        <div className={`${CSS_CLASS_NAME}_legacy_forms_container`}>
            <InputFormField
                isValid={true}
                disabled={true}
                label="Publication Title"
                required={true}
                maxLength={200}
                onChange={() => {}}
                showCharacterCount={false}
                value={`NFPA ${publication.code} - ${publication.title} (${publication.edition})`}
            />
            <div className={`${CSS_CLASS_NAME}_code_edition_container`}>
                <InputFormField
                    isValid={true}
                    label="Code Number"
                    value={publication.code}
                    onChange={(e) => setCodeNumber(e.target.value)}
                    required={true}
                    maxLength={7}
                />
                <InputFormField
                    isValid={true}
                    label="Edition Year"
                    value={publication.edition}
                    onChange={(e) => setEditionYear(e.target.value)}
                    required={true}
                    maxLength={4}
                />
            </div>
            <InputFormField
                isValid={true}
                label="Code Title"
                onChange={(e) => setCodeTitle(e.target.value)}
                required={true}
                maxLength={200}
            />
            <Checkbox
                checked={publication.usePdfBookmarkTitles}
                label="Use PDF Bookmark Titles"
                onChange={handleCheckboxChange}
            />
        </div>
    );
};

export default PdfPublicationImportForm;
