import React, { forwardRef, PropsWithChildren } from "react";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ParagraphProps extends Pick<DataTestAttributes, "dataTestId"> {
    cssClassName?: string;
    dangerouslySetInnerHTML?: { __html: string };
    id?: string;
    ref?: React.RefObject<HTMLParagraphElement> | null;
    size?: ParagraphSizes;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Paragraph: React.RefForwardingComponent<
    HTMLParagraphElement,
    ParagraphProps
> = forwardRef(
    (
        props: PropsWithChildren<ParagraphProps>,
        ref: React.Ref<HTMLParagraphElement>
    ) => {
        const {
            children,
            cssClassName,
            dangerouslySetInnerHTML,
            dataTestId,
            id,
            size,
        } = props;
        let cssClassNames: Array<string> = [];

        if (StringUtils.hasValue(cssClassName)) {
            cssClassNames.push(cssClassName);
        }

        if (size != null) {
            cssClassNames.push(size);
        }

        return (
            <p
                className={cssClassNames.join(" ")}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                data-test-id={dataTestId}
                id={id}
                ref={ref}>
                {children}
            </p>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default Paragraph;

// #endregion Exports
