/**
 * Error keys from NfpaExternalAuthenticationProvider.cs on the backend. Use to check for specific
 * errors on the frontend to handle behavior such as descriptive error messaging on a failed login attempt
 */
const AuthenticationProviderErrorKeys = {
    ERROR_CREATE_USER_EMAIL_IN_USE:
        "NfpaExternalAuthenticationProvider.CreateUser.EmailInUse",
    ERROR_GENERAL: "NfpaExternalAuthenticationProvider.Error.General",
    ERROR_IS_REQUIRED: "NfpaExternalAuthenticationProvider.PropertyIsRequired",
    ERROR_LOGIN_RESOURCE_NOT_FOUND:
        "NfpaExternalAuthenticationProvider.Login.ResourceNotFound",
    ERROR_NO_SUBSCRIPTIONS_FOUND:
        "NfpaExternalAuthenticationProvider.NoSubscriptionsFound",
    ERROR_PROVIDER_UNRESPONSIVE:
        "NfpaExternalAuthenticationProvider.Unresponsive",
};

export default AuthenticationProviderErrorKeys;
