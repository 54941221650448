import Anchor from "atoms/anchors/anchor";
import { SupportConstants } from "constants/phone-number-constants";
import React, { PropsWithChildren } from "react";

interface SupportEmailLinkProps {}

const SupportEmailLink: React.FunctionComponent<
    PropsWithChildren<SupportEmailLinkProps>
> = (props: PropsWithChildren<SupportEmailLinkProps>) => {
    return (
        <Anchor
            cssClassName="-anchor"
            external
            to={`mailto: ${SupportConstants.CustomerServiceEmail}`}>
            {SupportConstants.CustomerServiceEmail}
        </Anchor>
    );
};

export default SupportEmailLink;
