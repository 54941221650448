import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { RichTextEditorProps } from "molecules/rich-text/rich-text-editor";
import React, { ChangeEvent } from "react";
import QuillConfig, {
    ToolbarButtonConfig,
    ToolbarDropdownOptionConfig,
} from "utilities/quill/quill-config";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CustomQuillToolbarProps
    extends Required<
        Pick<RichTextEditorProps, "allowImages" | "allowReferenceLinks">
    > {
    disabled?: boolean;
    id: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

/**
 * Custom HTML Toolbar for quill. Quill automatically
 * attaches events based on classnames.
 * @param props {CustomQuillToolbarProps}
 */
const CustomQuillToolbar: React.FC<CustomQuillToolbarProps> = (
    props: CustomQuillToolbarProps
) => {
    const { allowImages, allowReferenceLinks, disabled, id } = props;

    const persistSelectChangeEvent = (e: ChangeEvent<HTMLSelectElement>) =>
        e.persist();

    const disabledModifier = disabled ? "-disabled" : "";

    return (
        <div
            className={`ql-toolbar ql-${QuillConfig.theme} ${disabledModifier}`}
            id={id}>
            <span className={QuillConfig.ToolbarButtonClasses.Section}>
                {QuillConfig.inlineFormats.map((cfg: ToolbarButtonConfig) => (
                    <button
                        aria-label={cfg.accessibleText}
                        className={`ql-${cfg.format}`}
                        key={`${cfg.format}-${cfg.value}`}
                        value={cfg.value}
                        type="button"
                    />
                ))}
            </span>
            <span className={QuillConfig.ToolbarButtonClasses.Section}>
                <select
                    aria-label={"Align text"}
                    className={QuillConfig.ToolbarButtonClasses.Align}
                    defaultValue={""}
                    onChange={persistSelectChangeEvent}>
                    <option />
                    {QuillConfig.alignmentFormats.map(
                        (cfg: ToolbarDropdownOptionConfig) => (
                            <option value={cfg.value} key={cfg.value} />
                        )
                    )}
                </select>
            </span>
            <span className={QuillConfig.ToolbarButtonClasses.Section}>
                <select
                    aria-label={"Apply heading"}
                    className={QuillConfig.ToolbarButtonClasses.Header}
                    defaultValue={""}
                    onChange={persistSelectChangeEvent}>
                    {QuillConfig.headerFormats.map(
                        (cfg: ToolbarDropdownOptionConfig) => (
                            <option value={cfg.value} key={cfg.value} />
                        )
                    )}
                    <option />
                </select>
            </span>
            <span className={QuillConfig.ToolbarButtonClasses.Section}>
                <button
                    aria-label={"Toggle bulleted list"}
                    className={QuillConfig.ToolbarButtonClasses.List}
                    type={"button"}
                    value={"bullet"}
                />
                <button
                    aria-label={"Toggle numbered list"}
                    className={QuillConfig.ToolbarButtonClasses.List}
                    type={"button"}
                    value={"ordered"}
                />
            </span>
            <span className={QuillConfig.ToolbarButtonClasses.Section}>
                <button
                    aria-label={"Link selected text"}
                    className={QuillConfig.ToolbarButtonClasses.Link}
                    type={"button"}
                />
                {allowImages && (
                    <button
                        aria-label={"Insert image"}
                        className={QuillConfig.ToolbarButtonClasses.Image}
                        type={"button"}
                    />
                )}
                {allowReferenceLinks && (
                    <button
                        aria-label={"Add reference link"}
                        className={
                            QuillConfig.ToolbarButtonClasses.ReferenceLink
                        }
                        type={"button"}>
                        <Icon
                            cssClassName={"-add-reference-icon"}
                            type={Icons.ReferenceLink}
                        />
                    </button>
                )}
            </span>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CustomQuillToolbar;

// #endregion Exports
