import ImagePage from "models/interfaces/image-page";
import PdfTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import { useState } from "react";

interface UseTableOfContentsOptions {
    tableOfContentsEntries?: PdfTableOfContentsEntry[];
    pageEntries?: ImagePage[];
    currentPageNumber: number;
}

export default function useTableOfContents(options: UseTableOfContentsOptions) {
    const [activeId, setActiveId] = useState<number | undefined>(undefined);

    const { tableOfContentsEntries = [] } = options;

    const getActiveParentId = (id: number) => {
        let parent = tableOfContentsEntries.find((t) => t.id === id);

        if (parent !== undefined) return parent.id;

        parent = tableOfContentsEntries.find((p) =>
            p.sections.some((s) => s.id === id)
        );

        return parent?.id;
    };

    // Used for selecting active index based on pagination rather than ToC click events
    const getActiveParentIdByPage = (pageNumber: number) => {
        if (tableOfContentsEntries.length) {
            const numberOfEntriesOnPage = tableOfContentsEntries.filter(
                (entry) => entry.pageNumber === pageNumber
            );

            let newActiveIndex: number = tableOfContentsEntries.findIndex(
                (element) => {
                    return element.pageNumber === pageNumber;
                }
            );

            // TODO: Change this section if lower display order should be highlighted
            if (numberOfEntriesOnPage.length > 0) {
                return numberOfEntriesOnPage[numberOfEntriesOnPage.length - 1]
                    .id;
            }

            if (newActiveIndex === -1) {
                const index: number = tableOfContentsEntries.findIndex(
                    (element) => {
                        return element.pageNumber > pageNumber;
                    }
                );
                newActiveIndex =
                    index === -1
                        ? tableOfContentsEntries.length - 1
                        : index === 0
                        ? index
                        : index - 1;
            }
            return tableOfContentsEntries[newActiveIndex].id;
        } else {
            return undefined;
        }
    };

    // Changes open panel in Table of Contents based on click on table of contents
    const handleActiveIdChange = (value: number | undefined) => {
        setActiveId(value);
    };

    const onFooterPageChange = (value: number) => {
        const newActiveId: number | undefined = getActiveParentIdByPage(value);
        setActiveId(newActiveId);
    };

    return {
        getActiveParentId,
        getActiveParentIdByPage,
        onFooterPageChange,
        handleActiveIdChange,
        activeId,
    };
}
