import type UserSettingRecord from "models/view-models/user-setting-record";
import UserSettingService, {
    UserSettingServiceErrorMessages,
} from "utilities/services/user-settings/user-setting-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook providing utility functions for persisting overridden user settings.
 */
export default function useUserSettings() {
    const { create: createUserSettingApi } = UserSettingService.useCreate();
    const { update: updateUserSettingApi } = UserSettingService.useUpdate();

    const callApi = async (
        userSetting: UserSettingRecord,
        service: any,
        params: any,
        error: string
    ): Promise<UserSettingRecord | undefined> => {
        try {
            const response = await service(userSetting, params);
            if (response.result?.hasErrors()) {
                ToastManager.error(error);
                return;
            }

            return response.resultObject;
        } catch (error) {
            ToastManager.error(error);
        }
    };

    const create = async (
        userSetting: UserSettingRecord
    ): Promise<UserSettingRecord | undefined> => {
        return callApi(
            userSetting,
            createUserSettingApi,
            {
                userId: userSetting.userId!,
            },
            UserSettingServiceErrorMessages.Create
        );
    };

    const update = async (
        userSetting: UserSettingRecord
    ): Promise<UserSettingRecord | undefined> => {
        return callApi(
            userSetting,
            updateUserSettingApi,
            {
                userId: userSetting.userId!,
                id: userSetting.id!,
            },
            UserSettingServiceErrorMessages.Create
        );
    };

    /**
     * Create or update user setting based on whether or not a default setting is being
     * overridden or an existing setting is being updated.
     *
     * @param  {UserSettingRecord|undefined} (userSetting)
     * @param  {number} (currentUserId)
     * @returns Promise
     */
    const createOrUpdate = async (
        userSetting: UserSettingRecord | undefined,
        currentUserId: number
    ): Promise<UserSettingRecord | undefined> => {
        if (userSetting == null) {
            return;
        }

        if (userSetting!.isDefault()) {
            var newUserSettingForUser = userSetting?.with({
                id: undefined,
                userId: currentUserId,
            });
            return create(newUserSettingForUser);
        }

        return update(userSetting);
    };

    return { createOrUpdate };
}
