import { NestedRoutes } from "andculturecode-javascript-react";
import PublicationRecord from "models/view-models/publication-record";
import TableOfContentsRecord from "models/view-models/table-of-contents/table-of-contents-record";
import { publicationRoutes } from "publication-routes";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { CoreUtils } from "utilities/core-utils";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import { RouteUtils } from "utilities/route-utils";

interface PublicationWrapperRouteParams {
    id?: string;
    code?: string;
    edition?: string;
}

const PublicationWrapper: React.FC<any> = () => {
    const { globalState } = useGlobalState();

    const routesWithCulture = useMemo(() => {
        const bookviewRoutes = CoreUtils.objectToArray(
            publicationRoutes.base.routes
        );
        const previewRoutes = CoreUtils.objectToArray(
            publicationRoutes.previewBase.routes
        );
        const routes = RouteUtils.getFlattenedRoutes([
            ...bookviewRoutes,
            ...previewRoutes,
        ]);

        return routes.map((route) => RouteUtils.withCulture(route));
    }, []);

    const { getTableOfContents, setTableOfContents } =
        useBookviewTableOfContents();

    const { setCurrentPublication } = useCurrentPublication();

    const {
        code,
        edition,
        id: publicationId,
    } = useParams<PublicationWrapperRouteParams>();

    const {
        resultObject: publication,
        loading,
        loaded,
        errors,
    } = usePublication({ code, edition, publicationId });

    useEffect(() => {
        if (loading || !loaded) {
            return;
        }

        const currentPublication = {
            publication: publication,
            loaded: !loading && loaded,
            errors: errors,
        };

        setCurrentPublication(currentPublication);
        return () => {
            setCurrentPublication({
                publication: new PublicationRecord(),
                loaded: false,
                errors: [],
            });
        };
    }, [publication, loading, loaded, errors, setCurrentPublication]);

    useEffect(() => {
        if (publication.id != null) getTableOfContents(publication.id);

        return () => setTableOfContents(new TableOfContentsRecord());
    }, [getTableOfContents, publication.id, setTableOfContents]);

    const memoRoutes = useMemo(
        () => (
            <NestedRoutes
                routes={routesWithCulture}
                isAuthenticated={globalState.isAuthenticated()}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalState.currentIdentity?.userLogin?.id, routesWithCulture]
    );

    return (
        // <NestedRoutes
        //     routes={routesWithCulture}
        //     isAuthenticated={globalState.isAuthenticated()}
        // />
        <>{memoRoutes}</>
    );
};

export default PublicationWrapper;
