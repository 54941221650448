import type CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import type SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { useEffect, useState } from "react";
import SolutionService from "utilities/services/situational-navigation/solutions/solution-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Solutions index wrapper based on related categories
 *
 * @param  {CategoryCollectionRecord} relatedCategories
 */

export default function useSolutions(
    relatedCategories: CategoryCollectionRecord
) {
    const [loadingSolutions, setLoadingSolutions] = useState(false);
    const [solutions, setSolutions] = useState<Array<SolutionRecord>>([]);

    const { list: listSolutionsApi } = SolutionService.useList();

    useEffect(() => {
        const loadSolutions = async () => {
            if (!relatedCategories.hasValues()) {
                setSolutions([]);
                return;
            }
            setLoadingSolutions(true);

            try {
                const result = await listSolutionsApi({
                    relatedCategoryIds: relatedCategories.mapToArray(
                        (c) => c.id!
                    ),
                });
                setSolutions(result.resultObjects ?? []);
            } catch (e) {
                ToastManager.error("There was an issue loading solutions.");
            }

            setLoadingSolutions(false);
        };

        loadSolutions();
    }, [listSolutionsApi, relatedCategories]);

    return {
        solutions,
        loadingSolutions,
    };
}
