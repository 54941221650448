import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { KeyboardConstants } from "constants/keyboard-constants";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import * as React from "react";
import { PropsWithChildren, useEffect, useRef, useCallback } from "react";
import { BrowserUtils } from "utilities/browser-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useMounted from "utilities/hooks/use-mounted";
import useModalTransition from "utilities/hooks/use-modal-transition";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useModalActions from "utilities/hooks/use-modal-actions";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useOutsideClick from "utilities/hooks/use-outside-click";
import { SetStateAction } from "jotai";

export interface MenuButtonOfflineAlertOptions {
    enabled: boolean;
    tooltipPlacement?: TooltipPlacement;
    tooltipDescription: string;
    tooltipHeader: string;
}

interface MenuButtonProps extends Pick<DataTestAttributes, "dataTestId"> {
    buttonAccessibleText?: string;
    cssClassName?: string;
    useOverlay?: boolean;
    trackOutsideClicksToClose?: boolean;
    /**
     * Disables/enables the button
     */
    disabled?: boolean;

    externalIsOpen?: boolean;

    externalSetIsOpen?: (update: SetStateAction<boolean>) => void;
    /**
     * Optionally render a menu footer element after all children
     * className: c-menu-button__menu__footer
     */
    footer?: React.ReactNode;

    /**
     * Display a label or informative text.
     */
    labelOrText?: number | string;

    /**
     * Icon to display as the underlying Button.
     *
     * @default {Icons.MoreVertical}
     */
    icon?: Icons;

    /**
     * Size of icon to display
     * @default {IconSizes.Large}
     */
    iconSize?: IconSizes;

    /**
     * Ignore any actions that occur while offline
     * @default {false}
     */
    ignoreOffline?: boolean;

    /**
     * Set initial opening state.
     */
    isOpen?: boolean;

    /**
     * Class name to apply to the modal on mobile layout.
     */
    modalClassName?: string;

    offlineAlertOptions?: MenuButtonOfflineAlertOptions;

    /**
     * Optionally render a menu title
     * className: c-menu-button__menu__title
     */
    title?: React.ReactNode;

    /**
     * Size of the button to trigger the menu
     *
     * @default {ButtonSizes.Medium}
     */
    triggerButtonSize?: ButtonSizes;

    /**
     * Style of the button to trigger the menu
     *
     * @default ButtonStyles.None
     */
    triggerButtonStyle?: ButtonStyles;

    /**
     * Style of the button to trigger the menu
     *
     * @default ""
     */
    triggerButtonCssClassName?: string;
}

const MenuButton: React.FunctionComponent<PropsWithChildren<MenuButtonProps>> =
    (props: PropsWithChildren<MenuButtonProps>) => {
        const [localIsOpen, localSetIsOpen] = React.useState<boolean>(
            props.isOpen ?? false
        );
        const isOpen = props.externalIsOpen ?? localIsOpen;
        const setIsOpen = props.externalSetIsOpen ?? localSetIsOpen;
        const { useOverlay = true, trackOutsideClicksToClose = false } = props;
        const [current, setCurrent] = React.useState<number>(0);
        const [focusFirstItem, setFocusFirstItem] =
            React.useState<boolean>(false);
        const cssBaseClass = "c-menu-button";
        const refArray = useRef<HTMLElement[]>([]);
        const buttonRef = useRef<HTMLButtonElement>(null);
        const menuContainerRef = useRef<HTMLDivElement>(null);
        const desktopMenu = useRef<HTMLDivElement>(null);
        const isMounted = useMounted();
        const transitionEffect = useModalTransition();
        // SlideUp modals handle their own click outside events
        const showClickOutsideOverlay =
            useOverlay &&
            isOpen &&
            transitionEffect !== ModalTransitions.SlideUp;

        const isMobile = useBreakpoint(
            Breakpoints.Phone,
            BreakpointComparer.MaxWidth
        );
        const isTablet = useBreakpoint(
            Breakpoints.Tablet,
            BreakpointComparer.MaxWidth
        );
        const { isOnline } = useNetworkInformation();

        const {
            isOpen: isOfflineAlertModalOpen,
            handleClose: handleOfflineAlertModalClose,
            handleOpen: handleFeatureUnavailableModalOpen,
        } = useModalActions();

        const {
            dataTestId,
            footer,
            icon,
            iconSize,
            ignoreOffline = false,
            labelOrText,
            offlineAlertOptions: offlineTooltipOptions,
            title,
            triggerButtonSize,
            triggerButtonStyle,
            triggerButtonCssClassName,
        } = props;
        const disableButton = props.disabled ?? false;

        const isOfflineAlertEnabled =
            offlineTooltipOptions != null && offlineTooltipOptions?.enabled;

        useEffect(() => {
            const element = refArray.current[current];
            if (!isMounted.current || element == null || !focusFirstItem) {
                return;
            }

            element.focus();
        }, [refArray, current, isOpen, focusFirstItem, isMounted]);

        const handleMenuBlur = useCallback(
            (event: React.FocusEvent<HTMLDivElement>) => {
                if (isMobile) {
                    return;
                }

                const relatedTarget =
                    (event.relatedTarget as Node) ?? document.activeElement;

                //  When a scrollbar inside a menu button is clicked, the event relatedTarget is
                // registered as `div#root`.  In this case, we don't want to close the menu button.
                if (relatedTarget === document.getElementById("root")) {
                    return;
                }

                if (event.currentTarget.contains(relatedTarget)) {
                    return;
                }

                setCurrent(0);
                setIsOpen(false);
            },
            [isMobile, setIsOpen]
        );

        const handleKeyDown = (e: KeyboardEvent) => {
            if (!isMounted.current) {
                e.preventDefault();
                return;
            }

            if (
                e.key === KeyboardConstants.DownArrow &&
                current !== refArray.current.length - 1
            ) {
                e.preventDefault();
                setCurrent(current + 1);
                return;
            }

            if (e.key === KeyboardConstants.UpArrow && current !== 0) {
                e.preventDefault();

                setCurrent(current - 1);
                return;
            }

            if (e.key === KeyboardConstants.Escape) {
                e.preventDefault();
                setCurrent(0);
                setIsOpen(false);
                buttonRef.current!.focus();
                return;
            }
        };

        const toggleModal = (
            focusFirst: boolean,
            e:
                | React.MouseEvent<HTMLButtonElement>
                | React.KeyboardEvent<HTMLButtonElement>
                | React.MouseEvent<HTMLDivElement>
        ) => {
            e.preventDefault();
            e.stopPropagation();
            if (!isMounted.current) {
                return;
            }
            setFocusFirstItem(focusFirst);
            setIsOpen(!isOpen);
        };

        const toggleModalKeyDown = (
            e: React.KeyboardEvent<HTMLButtonElement>
        ) => {
            if (!isOnline && isOfflineAlertEnabled && !ignoreOffline) {
                return;
            }

            if (
                e.key === KeyboardConstants.Enter ||
                e.key === KeyboardConstants.Space
            ) {
                e.preventDefault();
                toggleModal(true, e);
            }
        };

        const renderChildren = () => {
            if (!isMounted.current) {
                return;
            }

            return React.Children.map(
                props.children,
                (child: React.ReactNode, index: number) => {
                    if (!React.isValidElement(child)) {
                        return child;
                    }

                    return (
                        <span key={index} className={`${cssBaseClass}__item`}>
                            {React.cloneElement(child, {
                                ...child.props,
                                onClick: (e: React.MouseEvent) => {
                                    // Retain default functionality if there's a link/anchor prop
                                    if (child.props.to == null) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }

                                    if (BrowserUtils.isIE()) {
                                        // setTimeout with a 0ms timeout just pushes it to
                                        // the end of the current event queue. This fixes
                                        // a null-reference error on IE
                                        setTimeout(() => setIsOpen(false), 0);
                                    } else {
                                        setIsOpen(false);
                                    }
                                    if (child.props.onClick != null) {
                                        child.props.onClick();
                                    }
                                },
                                onKeyDown: handleKeyDown,
                                ref: (el: HTMLElement) =>
                                    (refArray.current[index] = el),
                            })}
                        </span>
                    );
                }
            );
        };

        const disableOfflineTooltip =
            isTablet || isOnline || !isOfflineAlertEnabled || ignoreOffline;
        const offlineTriggerModifier =
            !isOnline && isOfflineAlertEnabled ? "-offline" : "";

        const handleToggleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isOnline || ignoreOffline) {
                toggleModal(false, e);
                return;
            }

            if (!isTablet || !isOfflineAlertEnabled) {
                return;
            }

            handleFeatureUnavailableModalOpen();
        };

        useOutsideClick(desktopMenu, () => {
            if (trackOutsideClicksToClose) {
                setIsOpen(false);
            }
        });

        const offlineTooltipContent = (
            <div>
                <div className={`${cssBaseClass}__tooltip__title`}>
                    {offlineTooltipOptions?.tooltipHeader}
                </div>
                <div className={`${cssBaseClass}__tooltip__description`}>
                    {offlineTooltipOptions?.tooltipDescription}
                </div>
            </div>
        );

        return (
            <div
                className={`${cssBaseClass} ${props.cssClassName}`}
                onBlur={handleMenuBlur}
                ref={menuContainerRef}>
                {showClickOutsideOverlay && (
                    <div
                        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                            toggleModal(false, e)
                        }
                        className={`${cssBaseClass}__outside-click-overlay`}
                    />
                )}
                <Tooltip
                    content={offlineTooltipContent}
                    cssClassName={`${cssBaseClass}__tooltip`}
                    disabled={disableOfflineTooltip}
                    durationInMs={0}
                    hideOnClick={true}
                    placement={
                        offlineTooltipOptions?.tooltipPlacement ??
                        TooltipPlacement.Bottom
                    }>
                    <Button
                        accessibleText={props.buttonAccessibleText}
                        cssClassName={`${cssBaseClass}__icon ${offlineTriggerModifier} ${triggerButtonCssClassName}}`}
                        dataTestId={dataTestId}
                        disabled={disableButton}
                        onClick={handleToggleClick}
                        onKeyDown={toggleModalKeyDown}
                        ref={buttonRef}
                        size={triggerButtonSize ?? ButtonSizes.Medium}
                        style={triggerButtonStyle ?? ButtonStyles.None}>
                        {labelOrText && (
                            <span className={`${cssBaseClass}__label-or-text`}>
                                {labelOrText}
                            </span>
                        )}
                        <Icon
                            size={iconSize ?? IconSizes.Large}
                            type={icon ?? Icons.MoreVertical}
                        />
                    </Button>
                </Tooltip>
                {isOfflineAlertModalOpen && (
                    <OfflineAlertModal
                        isOpen={isOfflineAlertModalOpen}
                        handleClose={handleOfflineAlertModalClose}
                    />
                )}
                {isMobile ? (
                    <Modal
                        closeDialog={() => setIsOpen(false)}
                        cssClassName={`${cssBaseClass} ${
                            props.modalClassName ?? ""
                        }`}
                        isVisible={isOpen}
                        label="Change Edition"
                        transition={transitionEffect}
                        type={ModalTypes.Bottom}>
                        {title != null && (
                            <div className={`${cssBaseClass}__menu__title`}>
                                {title}
                            </div>
                        )}
                        {renderChildren()}
                        {footer != null && (
                            <div className={`${cssBaseClass}__menu__footer`}>
                                {footer}
                            </div>
                        )}
                    </Modal>
                ) : (
                    <React.Fragment>
                        {isOpen && (
                            <div
                                ref={desktopMenu}
                                className={`${cssBaseClass}__menu`}>
                                {title != null && (
                                    <div
                                        className={`${cssBaseClass}__menu__title`}>
                                        {title}
                                    </div>
                                )}
                                {renderChildren()}
                                {footer != null && (
                                    <div
                                        className={`${cssBaseClass}__menu__footer`}>
                                        {footer}
                                    </div>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    };

export default MenuButton;
