import { Record } from "immutable";
import type CurrentPublicationEntity from "interfaces/atoms/current-publication-entity";
import { SearchListQueryParams } from "utilities/services/search/search-service";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: CurrentPublicationEntity =
    RecordUtils.defaultValuesFactory<CurrentPublicationEntity>({
        entityId: 0,
        entityTitle: "",
        selected: false,
        type: undefined,
    });

type EntitySearchParams = Pick<
    SearchListQueryParams,
    "annexIds" | "articleIds" | "chapterIds"
>;

const SupportedSearchEntityParams: Array<keyof EntitySearchParams> = [
    "annexIds",
    "articleIds",
    "chapterIds",
];

export default class CurrentPublicationEntityRecord
    extends Record(defaultValues)
    implements CurrentPublicationEntity
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: CurrentPublicationEntity) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to return the `SearchListQueryParams` object properties for the current `type`
     * Example: given a current `type` of Annex -> {annexIds: [`this.entityId`]}
     */
    public getEntityIdParams() {
        if (this.type == null) {
            return undefined;
        }

        let ids: EntitySearchParams = {};

        const typeProperty = `${this.type.toLowerCase()}Ids`;
        if (!this._isKeyOfSupported(typeProperty)) {
            return undefined;
        }

        ids[typeProperty] = [this.entityId];
        return ids;
    }

    /**
     * Convenience method to update the selected property to the given value
     * @param selected
     */
    public updateSelection(selected: boolean) {
        return this.with({ selected: selected });
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<CurrentPublicationEntity>} values
     */
    public with(
        values: Partial<CurrentPublicationEntity>
    ): CurrentPublicationEntityRecord {
        return new CurrentPublicationEntityRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private _isKeyOfSupported(key: string): key is keyof EntitySearchParams {
        const supportedParams: string[] = SupportedSearchEntityParams;
        return Object.values(supportedParams).includes(key);
    }

    // #endregion Private Methods
}
