import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UnsavedChangesPromptProps {
    isDirty: boolean;
    message: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = (
    props: UnsavedChangesPromptProps
) => {
    useEffect(() => {
        const handleUnload = (e: BeforeUnloadEvent) => {
            if (!props.isDirty) {
                return undefined;
            }

            // on IE it assigns window.event instead of sending the event into the handler
            (e ?? window.event).returnValue = props.message;
            return props.message;
        };

        window.addEventListener("beforeunload", handleUnload);

        return () => window.removeEventListener("beforeunload", handleUnload);
    });

    return <Prompt when={props.isDirty} message={props.message} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UnsavedChangesPrompt;

// #endregion Exports
