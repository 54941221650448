import {
    toast,
    ToastContent,
    ToastOptions,
    UpdateOptions,
} from "react-toastify";
import { ToastTemplates } from "utilities/toast/toast-templates";
import { ReactText } from "react";
import { Icons } from "atoms/constants/icons";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export type ToastId = string | number;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ToastErrorOptions extends ToastOptions {
    hideWhenOffline?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const IGNORED_TOAST_ID = -1;

const ToastDefaultPosition = "bottom-right";

const defaultToastOptions: ToastOptions = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: false,
    hideProgressBar: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    position: ToastDefaultPosition,
};

const mergeDefaults = (...options: ToastOptions[]): ToastOptions =>
    Object.assign({}, defaultToastOptions, ...options);

const shouldIgnoreToast = (options?: ToastErrorOptions) => {
    const { hideWhenOffline = false } = options ?? {};

    return hideWhenOffline && !window.navigator.onLine;
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Class
// -----------------------------------------------------------------------------------------

class ToastManager {
    /**
     * Dismiss an existing toast programmatically.
     * @param toastId the ID returned by the method which created the toast
     */
    static dismiss(toastId?: ToastId): void {
        toast.dismiss(toastId);
    }

    /**
     * Dismiss all toasts programmatically.
     */
    static dismissAll(): void {
        toast.dismiss();
    }

    /**
     * Mark a toast as complete, commonly used when explicitly controlling the progress bar
     * @param toastId
     */
    static done(toastId: ToastId): void {
        return toast.done(toastId);
    }

    /**
     * Show an error style toast
     * @param content either a string or a TSX element
     * @param options optionally override default toast options
     */
    static error(
        content: string | ToastContent,
        options: ToastErrorOptions = defaultToastOptions
    ): ReactText {
        if (shouldIgnoreToast({ hideWhenOffline: true, ...options })) {
            return IGNORED_TOAST_ID;
        }

        return toast.error(
            ToastTemplates.error(content),
            // Do not auto close error toasts. The user may walk away and not see that something
            // failed to load or save.
            mergeDefaults(options, { autoClose: false })
        );
    }

    /**
     * Show an info style toast
     * @param content either a string or a TSX element
     * @param options optionally override default toast options
     */
    static info(
        content: string | ToastContent,
        options: ToastOptions = defaultToastOptions
    ): ReactText {
        return toast.info(ToastTemplates.info(content), mergeDefaults(options));
    }

    /**
     * Show a progress style toast
     * @param content either a string or a TSX element
     * @param options optionally override default toast options
     */
    static progress(
        content: string | ToastContent,
        icon: Icons,
        options: ToastOptions = defaultToastOptions
    ): ReactText {
        return toast.info(
            ToastTemplates.progress(content, icon),
            mergeDefaults(options)
        );
    }

    /**
     * Show a success style toast
     * @param content either a string or a TSX element
     * @param options optionally override default toast options
     */
    static success(
        content: string | ToastContent,
        options: ToastOptions = defaultToastOptions
    ): ToastId {
        return toast.success(
            ToastTemplates.success(content),
            mergeDefaults(options)
        );
    }

    /**
     * Update an existing toast with new content; this could be useful for
     * progress indicators, network state indicators, etc.
     * @param toastId the ID of the toast to update (returned from the method that created it)
     * @param options the new content to render into the toast
     */
    static update(toastId: ToastId, options: string | UpdateOptions): void {
        if (typeof options === "string") {
            toast.update(toastId, { render: options });
            return;
        }

        toast.update(toastId, options);
    }

    /**
     * Show a warning style toast
     * @param content either a string or a TSX element
     * @param options optionally override default toast options
     */
    static warn(
        content: string | ToastContent,
        options: ToastOptions = defaultToastOptions
    ): ReactText {
        return toast.warn(
            ToastTemplates.warning(content),
            mergeDefaults(options)
        );
    }
}

// #endregion Class

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ToastDefaultPosition, ToastManager };

// #endregion Exports
