import OfflineDeviceRecord from "models/view-models/offline/offline-device-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineDevicesBasePathParams {}

export interface OfflineDevicesResourcePathParams
    extends OfflineDevicesBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Part resources.
 * These get translated to QueryString parameters
 */
export interface OfflineDeviceQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "offlinedevices";
const resourceType = OfflineDeviceRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "offlinedevices";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const OfflineDevicesService = {
    useGetQuery: QueryHookFactory.useGetQuery<
        OfflineDeviceRecord,
        OfflineDevicesResourcePathParams
    >(key, resourceType, resourceEndpoint),

    create: ServiceFactory.create<OfflineDeviceRecord>(
        resourceType,
        baseEndpoint
    ),

    useCreate: ServiceHookFactory.useCreate<OfflineDeviceRecord>(
        resourceType,
        baseEndpoint
    ),

    delete: ServiceFactory.delete<OfflineDevicesBasePathParams>(baseEndpoint),

    useDelete:
        ServiceHookFactory.useDelete<OfflineDevicesBasePathParams>(
            baseEndpoint
        ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineDevicesService;

// #endregion Exports
