export enum ServiceWorkerMessageTypes {
    OfflineDevice = "OFFLINE_DEVICE",
    PreloadProgress = "PRELOAD_PROGRESS",
    RemoveUrlsProgress = "REMOVE_URLS_PROGRESS",
    SearchIndexStart = "SEARCH_INDEX_START",
    SearchIndexStatus = "SEARCH_INDEX_STATUS",
    SearchIndexEnd = "SEARCH_INDEX_END",
    SearchResults = "SEARCH_RESULTS",
    StorageUsage = "STORAGE_USAGE",
}
