import { OfflineSessionConstants } from "constants/offline-session-constants";
import moment from "moment";
import { useMemo } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { CookieUtils } from "utilities/cookie-utils";
import { SessionStatus } from "utilities/enumerations/session-status";

export default function useSessionStatus(): SessionStatus {
    const networkState = useNetworkInformation();

    const sessionStatus = useMemo((): SessionStatus => {
        if (networkState.offlineAt == null) {
            return SessionStatus.Valid;
        }
        const expirationDate = CookieUtils.get(
            OfflineSessionConstants.sessionExpirationCookieName
        );

        if (
            expirationDate != null &&
            moment(expirationDate).diff(moment(), "hours") < 0
        ) {
            return SessionStatus.Expired;
        }
        const today = moment();
        const updatedOn = moment(networkState.offlineAt);
        const sessionAge = today.diff(updatedOn, "days");

        return sessionAge >= 21 ? SessionStatus.Stale : SessionStatus.Valid;
    }, [networkState.offlineAt]);

    return sessionStatus;
}
