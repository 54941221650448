import { ResultRecord, ServiceResponse } from "andculturecode-javascript-core";
import { ButtonProps } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { Icons } from "atoms/constants/icons";
import CheckboxInput from "atoms/forms/checkbox-input";
import Icon from "atoms/icons/icon";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import type PublicationRecord from "models/view-models/publication-record";
import CallToActionToggle from "molecules/call-to-action/call-to-action-toggle";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import BaseModalFooter from "molecules/modals/footers/base-modal-footer";
import BaseModalHeader from "molecules/modals/headers/base-modal-header";
import MobileModalHeader from "molecules/modals/headers/mobile-modal-header";
import Modal, { ModalProps } from "molecules/modals/modal";
import PublicationListItem from "molecules/publication-list-item/publication-list-item";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import useOfflineSettings from "organisms/modals/offline-settings-modal/use-offline-settings";
import React, { useEffect, useCallback, useState } from "react";
import { useMutation } from "react-query";
import { CollectionUtils } from "utilities/collection-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useOfflineBooksMutation from "utilities/hooks/domain/offline/use-offline-book-create-or-update-mutation";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalTransition from "utilities/hooks/use-modal-transition";
import CultureResources from "utilities/interfaces/culture-resources";
import NumberUtils from "utilities/number-utils";
import BookService from "utilities/services/books/book-service";
import OfflineBooksService, {
    OfflineBooksResourcePathParams,
} from "utilities/services/offline/offline-books-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { RemoveUrlsCommand } from "utilities/service-worker/interfaces/commands/remove-urls-command";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import useOfflineBooks from "utilities/hooks/domain/offline/use-offline-books";
import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import OfflineUnsupportedBanner from "organisms/banners/offline-unsupported-banner";
import _ from "lodash";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface OfflineSettingsModalProps
    extends Omit<ModalProps, "closeDialog" | "type"> {
    closeDialog: (
        offlineBook?: OfflineBookRecord,
        sync?: boolean,
        includeOptionsChanged?: boolean
    ) => void;
    publication: PublicationRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-offline-settings-modal";
const DATA_TEST_ID = "offline-settings-modal";
const DATA_TEST_ID_SAVE = `${DATA_TEST_ID}-save`;
const DATA_TEST_ID_TOGGLE = `${DATA_TEST_ID}-toggle`;
const DATA_TEST_ID_BOOKMARKS = `${DATA_TEST_ID}-bookmarks`;
const DATA_TEST_ID_ENHANCED_CONTENT = `${DATA_TEST_ID}-enhanced-content`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineSettingsModal: React.FunctionComponent<OfflineSettingsModalProps> =
    (props: OfflineSettingsModalProps) => {
        const { publication, closeDialog } = props;
        const { list: listBooks } = BookService.useListQuery();
        const { isSupported: serviceWorkerSupported, sendCommand } =
            useServiceWorker();
        const offlineBookMutation = useOfflineBooksMutation();
        const offlineBookDeleteMutation = useMutation<
            ServiceResponse<Boolean>,
            ResultRecord<Boolean>,
            OfflineBookRecord,
            OfflineBooksResourcePathParams
        >(deleteOfflineBook);

        const { t } = useLocalization<CultureResources>();
        const saveButtonText = t("save");

        const transition = useModalTransition();
        const isDesktop = useBreakpoint(Breakpoints.Phone);
        const modalType = isDesktop ? ModalTypes.Base : ModalTypes.Bottom;

        const label = t("offline-settings-store-label");
        const description = t("offline-settings-store-description");

        const includeBookmarksLabel = t("offline-settings-bookmarks-label");
        const includeEnhancedContentLabel = t(
            "offline-settings-enhanced-content-label"
        );
        const heading = t("offline-settings-header");

        const bookmarksTooltip = t("offline-settings-bookmarks-tooltip");
        const enhancedContentTooltip = t(
            "offline-settings-enhanced-content-tooltip"
        );

        const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
            useState(false);

        const { data: books } = listBooks({
            code: publication.code,
            edition: publication.edition,
        });

        const { resultObject: currentDevice } = useOfflineDevice();
        const { resultObject: offlineBooks, refresh } = useOfflineBooks({
            offlineDeviceId: currentDevice?.id,
        });

        const currentBook = CollectionUtils.first(books?.resultObjects);
        const offlineBook = offlineBooks.find(
            (b) =>
                b.bookId === currentBook?.id &&
                b.offlineDeviceId === currentDevice?.id
        );

        const {
            resultObject: settings,
            handleToggleBookmarks,
            handleToggleEnhancedContent,
            handleToggleStoreForOffline,
            storeOfflineChecked,
        } = useOfflineSettings({ currentBook, currentDevice, offlineBook });

        const [initialSettings, setInitialSettings] = useState<
            OfflineBookRecord | undefined
        >(undefined);
        const [initialStoreOffline, setInitialStoreOffline] =
            useState(storeOfflineChecked);

        const isDirty =
            !_.isEqual(settings, initialSettings) ||
            storeOfflineChecked !== initialStoreOffline;

        const includeOptionsChanged =
            storeOfflineChecked &&
            offlineBook != null &&
            (offlineBook.includeBookmarks !== settings.includeBookmarks ||
                offlineBook.includeEnhancedContent !==
                    settings.includeEnhancedContent);

        const handleDelete = useCallback(async () => {
            if (!NumberUtils.isDefault(settings.id)) {
                await offlineBookDeleteMutation.mutateAsync(settings);

                sendCommand<RemoveUrlsCommand>({
                    bookId: currentBook!.id!,
                    code: currentBook!.code!,
                    edition: currentBook!.edition!,
                    type: ServiceWorkerCommandTypes.RemoveUrls,
                });
            }

            closeDialog(new OfflineBookRecord(), true);
        }, [
            closeDialog,
            currentBook,
            offlineBookDeleteMutation,
            sendCommand,
            settings,
        ]);

        const handleSave = useCallback(async () => {
            if (storeOfflineChecked === false) {
                await handleDelete();
                return;
            }

            const { resultObject: offlineBook } =
                await offlineBookMutation.mutateAsync(settings);
            refresh();
            closeDialog(offlineBook, true, includeOptionsChanged);
        }, [
            closeDialog,
            handleDelete,
            includeOptionsChanged,
            offlineBookMutation,
            refresh,
            settings,
            storeOfflineChecked,
        ]);

        const handleCloseDialogDefault = () => {
            if (isDirty) {
                setShowCancelConfirmationModal(true);
                return;
            }

            props.closeDialog();
        };

        useEffect(() => {
            if (settings?.book == null || initialSettings != null) {
                return;
            }

            setInitialSettings(settings);
            setInitialStoreOffline(!NumberUtils.isDefault(settings.id));
        }, [settings, initialSettings]);

        useEffect(
            function handleErrors() {
                if (offlineBookDeleteMutation.isError) {
                    ToastManager.error(
                        offlineBookDeleteMutation.error?.listErrorMessages()
                    );
                    return;
                }
                if (offlineBookMutation.isError) {
                    ToastManager.error(
                        offlineBookMutation.error?.listErrorMessages()
                    );
                }
            },
            [offlineBookMutation, offlineBookDeleteMutation]
        );

        const enhancedContentLabel = (
            <React.Fragment>
                {`${includeEnhancedContentLabel} `}
                <Tooltip
                    content={enhancedContentTooltip}
                    placement={TooltipPlacement.AutoStart}>
                    <span>
                        <Icon type={Icons.Information} />
                    </span>
                </Tooltip>
            </React.Fragment>
        );
        const bookmarksLabel = (
            <React.Fragment>
                {`${includeBookmarksLabel} `}
                <Tooltip
                    content={bookmarksTooltip}
                    placement={TooltipPlacement.AutoStart}>
                    <span>
                        <Icon type={Icons.Information} />
                    </span>
                </Tooltip>
            </React.Fragment>
        );

        const header = isDesktop ? (
            <BaseModalHeader
                closeDialog={handleCloseDialogDefault}
                heading={heading}
            />
        ) : (
            <MobileModalHeader
                closeDialog={handleCloseDialogDefault}
                heading={heading}
            />
        );

        const modalFooterCloseButtonProps: ButtonProps = {
            size: isDesktop ? ButtonSizes.Medium : ButtonSizes.Small,
            onClick: handleCloseDialogDefault,
        };

        const modalFooterPrimaryButtonProps: ButtonProps = {
            dataTestId: DATA_TEST_ID_SAVE,
            disabled:
                !isDirty ||
                !serviceWorkerSupported ||
                NumberUtils.isDefault(currentDevice?.id),
            size: isDesktop ? ButtonSizes.Medium : ButtonSizes.Small,
            onClick: handleSave,
        };
        const disableCheckOption =
            !storeOfflineChecked || !serviceWorkerSupported;

        return (
            <Modal
                {...props}
                closeButtonStyle={ModalCloseButtonStyle.Hidden}
                closeDialog={handleCloseDialogDefault}
                cssClassName={BASE_CLASS_NAME}
                transition={transition}
                type={modalType}>
                {header}
                <div className="c-modal__body">
                    <AlertBanner alertLevel={AlertLevels.Info}>
                        <Paragraph size={ParagraphSizes.XSmall}>
                            {t("offline-data-informational-message")}
                        </Paragraph>
                    </AlertBanner>
                    <PublicationListItem publication={publication} />
                    <fieldset>
                        <OfflineUnsupportedBanner />
                        <CallToActionToggle
                            checked={storeOfflineChecked}
                            dataTestId={DATA_TEST_ID_TOGGLE}
                            disabled={!serviceWorkerSupported}
                            description={description}
                            label={label}
                            onChange={handleToggleStoreForOffline}
                        />
                        <CheckboxInput
                            checked={
                                storeOfflineChecked && settings.includeBookmarks
                            }
                            dataTestId={DATA_TEST_ID_BOOKMARKS}
                            disabled={disableCheckOption}
                            label={bookmarksLabel}
                            onChange={handleToggleBookmarks}
                        />
                        <CheckboxInput
                            checked={
                                storeOfflineChecked &&
                                settings.includeEnhancedContent
                            }
                            dataTestId={DATA_TEST_ID_ENHANCED_CONTENT}
                            disabled={disableCheckOption}
                            label={enhancedContentLabel}
                            onChange={handleToggleEnhancedContent}
                        />
                    </fieldset>
                </div>
                <BaseModalFooter
                    closeButtonProps={modalFooterCloseButtonProps}
                    closeButtonText={isDesktop ? t("cancel") : ""}
                    closeDialog={handleCloseDialogDefault}
                    primaryButtonProps={modalFooterPrimaryButtonProps}
                    primaryText={saveButtonText}
                />
                <ConfirmationModal
                    confirmButtonText={t("yesAction", {
                        action: t("continue"),
                    })}
                    isVisible={showCancelConfirmationModal}
                    message={t("cancelOrConfirmWithoutSaving")}
                    onCancel={() => setShowCancelConfirmationModal(false)}
                    onConfirm={props.closeDialog}
                />
            </Modal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineSettingsModal;

// #endregion Exports

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function deleteOfflineBook(
    variables: OfflineBookRecord
): Promise<ServiceResponse<Boolean>> {
    return OfflineBooksService.delete(variables.id!, {
        offlineDeviceId: variables.offlineDeviceId,
        id: variables.id,
    });
}

// #endregion Private Functions
