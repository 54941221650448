export enum PublicationColor {
    Yellow = 1,
    Green = 2,
    Teal = 3,
    Blue = 4,
    Orange = 5,
    Red = 6,
    Grey = 7,
    Beige = 8,
}
