import { ServiceResponse } from "andculturecode-javascript-core";
import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import { List } from "immutable";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import EnhancedContentDetail, {
    EnhancedContentDetailProps,
} from "organisms/enhanced-content/enhanced-content-detail";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { v4 } from "uuid";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import useEnhancedContentSearchHit from "utilities/hooks/domain/enhanced-content/use-enhanced-content-search-hit";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EnhancedContentPanelProps {
    collapse?: boolean;
    enhancedContent: EnhancedContentRecord;
    isPreview?: boolean;
    /**
     * Optional callback to be executed when the 'Delete' action modal is confirmed. If not provided,
     * uses the enhanced content service for sections to delete.
     */
    onDeleteConfirm?: (
        enhancedContent: EnhancedContentRecord
    ) => Promise<ServiceResponse<Boolean>>;

    /**
     * Optional callback to be executed when the 'Edit' button is clicked. If not provided,
     * uses the event handler from context.
     */
    onEditClick?: () => void;
    onSuccess?: (enhancedContent?: EnhancedContentRecord) => void;
    onToggle?: (isCollapsed: boolean) => void;
    resources?: List<EnhancedContentResourceRecord>;
    resourcesLoading?: boolean;
    resourcesLoaded?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-enhanced-content-panel";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentPanel: React.FC<EnhancedContentPanelProps> = (
    props: EnhancedContentPanelProps
) => {
    const {
        collapse,
        enhancedContent,
        isPreview,
        onDeleteConfirm,
        onEditClick,
        onSuccess,
        onToggle,
        resources,
        resourcesLoading,
    } = props;

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const hasAdminAccess =
        currentIdentity?.isAdmin() || currentIdentity?.isAuthorOrPublisher();
    const { isCurrentSearchHit } = useEnhancedContentSearchHit();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(
        collapse ?? !isCurrentSearchHit(enhancedContent)
    );
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Private Properties
    // -----------------------------------------------------------------------------------------

    const accordionContentId = useMemo(() => v4(), []);
    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const collapsedStatusText = isMobile
        ? ""
        : isCollapsed
        ? t("expand")
        : t("collapse");

    const iconType = isMobile
        ? Icons.Enlarge
        : isCollapsed
        ? Icons.Expand
        : Icons.Collapse;

    const enhancedContentDetailProps: EnhancedContentDetailProps = {
        accordionContentId: accordionContentId,
        cssBaseClassName: BASE_CLASS_NAME,
        enhancedContent: enhancedContent,
        isPreview: isPreview,
        hasAdminAccess: hasAdminAccess,
        onDeleteConfirm: onDeleteConfirm,
        onEditClick: onEditClick,
        onSuccess: onSuccess,
        panelIsCollapsed: isCollapsed,
        resources: resources,
    };

    // User settings

    // This is memoized so that content panels are only auto-collapsed when the
    // EnhancedContentExpand setting changes and not whenever any of the global settings change
    const isAutoCollapsed: boolean | undefined = useMemo(() => {
        if (isMobile) {
            return;
        }

        const autoExpand = globalState.getUserSettingValue<boolean>(
            "EnhancedContentExpand"
        );
        if (autoExpand == null) {
            return;
        }

        return !autoExpand;
    }, [globalState, isMobile]);

    const showEnhancedContentUserSetting = globalState.getUserSetting(
        "EnhancedContentShow"
    );

    const expandedCSSModifier = !isCollapsed || isPreview ? "-expanded" : "";

    // #endregion Private Properties

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    const handleToggle = () => {
        if (isMobile) {
            setIsModalOpen((prevOpenState) => {
                onToggle?.(prevOpenState);
                return !prevOpenState;
            });
            return;
        }
        setIsCollapsed((prevCollapsedState) => {
            onToggle?.(!prevCollapsedState);
            return !prevCollapsedState;
        });
    };

    /**
     * Toggle panel without also firing of the parent onClick event when clicking the button
     * @param e
     */
    const handleToggleButtonClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        handleToggle();
    };

    // #endregion Handlers

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(
        function initializePanelState() {
            if (collapse == null || isMobile) {
                return;
            }

            setIsCollapsed(collapse);
        },
        [collapse, isMobile]
    );

    useEffect(
        function setupUserPreferences() {
            if (
                isAutoCollapsed == null ||
                isCurrentSearchHit(enhancedContent) // Ignore user settings when navigating from a search result
            ) {
                return;
            }

            setIsCollapsed(isAutoCollapsed);
            onToggle?.(isAutoCollapsed);
        },
        [enhancedContent, isAutoCollapsed, isCurrentSearchHit, onToggle]
    );

    // #endregion Side Effects

    if (
        enhancedContent == null ||
        (!showEnhancedContentUserSetting?.getValue<boolean>() &&
            !isCurrentSearchHit(enhancedContent)) // Ignore user settings when navigating from a search result
    ) {
        return null;
    }

    const enhancedContentHeaderTitleAndPublishInfo = (
        <React.Fragment>
            <div className={`${BASE_CLASS_NAME}__header__title`}>
                {t("enhancedContent")}
            </div>
            {hasAdminAccess && !isPreview && (
                <PublishStatusBadge
                    hasUnpublishedChanges={enhancedContent.hasUnpublishedChanges()}
                    small={true}
                    status={enhancedContent.getPublishStatus()}
                />
            )}
        </React.Fragment>
    );

    return (
        <div
            className={`${BASE_CLASS_NAME} ${expandedCSSModifier}`}
            id={enhancedContent.getHtmlId()}>
            <div
                className={`${BASE_CLASS_NAME}__header`}
                onClick={handleToggle}>
                {enhancedContentHeaderTitleAndPublishInfo}
                {resourcesLoading && (
                    <Loader type={LoaderStyles.LinkGlyphBlue} />
                )}
                {!isPreview && (
                    <Button
                        accessibleText={collapsedStatusText}
                        ariaControls={accordionContentId}
                        ariaExpanded={!isCollapsed}
                        cssClassName={`${BASE_CLASS_NAME}__header__toggle`}
                        onClick={handleToggleButtonClick}
                        style={ButtonStyles.None}>
                        {collapsedStatusText}
                        <Icon type={iconType} />
                    </Button>
                )}
            </div>
            {isMobile ? (
                <Modal
                    cssClassName={`${BASE_CLASS_NAME} -mobile-modal`}
                    closeDialog={handleToggle}
                    isVisible={isModalOpen}
                    label={t("enhancedContent")}
                    type={ModalTypes.Base}
                    transition={ModalTransitions.SlideUp}>
                    <div className={`${BASE_CLASS_NAME}__header`}>
                        {enhancedContentHeaderTitleAndPublishInfo}
                        <ModalCloseButton onClick={handleToggleButtonClick} />
                    </div>
                    <EnhancedContentDetail {...enhancedContentDetailProps} />
                </Modal>
            ) : (
                <React.Fragment>
                    <EnhancedContentDetail {...enhancedContentDetailProps} />
                </React.Fragment>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EnhancedContentPanel;

// #endregion Export
