import type UserCollectionRecord from "models/view-models/user-collection-record";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import { useAtom } from "jotai";
import UserCollectionAtom from "utilities/atoms/user-collections/user-collections-atom";
import { useEffect } from "react";

export interface ListUserCollectionsHookResult
    extends ServiceHookResult<Array<UserCollectionRecord>> {
    refresh: () => void;
}
export default function useUserCollections(
    userId?: number,
    groupId?: number,
    searchText?: string
): ListUserCollectionsHookResult {
    const [userCollections, setUserCollections] = useAtom(UserCollectionAtom);

    const { list } = UserCollectionService.useListQuery();

    const result = list(
        { userId: userId! },
        { searchText },
        { enabled: !NumberUtils.isDefault(userId) }
    );
    useEffect(() => {
        setUserCollections(result.data?.resultObjects ?? []);
    }, [result.data?.resultObjects, setUserCollections]);

    return {
        resultObject: userCollections,
        errors: result.error?.listErrorMessages() ?? [],
        loaded: result.isFetched,
        loading: result.isLoading,
        refresh: result.refetch,
    };
}
