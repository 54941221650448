import StringUtils from "utilities/string-utils";
import { CookieUtils, SetCookieOptions } from "utilities/cookie-utils";
import moment from "moment";
import AnnouncementRecord from "models/view-models/announcement-record";
import NumberIndexedObject from "utilities/types/number-indexed-object";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ACKNOWLEDGEMENT_COOKIE_KEY = "alertAnnouncementCookies";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface IAnnouncementUtils {
    /**
     * Acknowldege, update cookies, update and return announcement record
     *
     * @param {number} userId
     * @param {AnnouncementRecord} announcement
     * @return {*}  {boolean}
     */
    acknowledge(userId: number, announcement: AnnouncementRecord): boolean;

    /**
     * Based on the stored cookie value of viewed announcements, adds the acknowldeged boolean value
     *
     * @param {number} userId
     * @param {AnnouncementRecord} announcement
     * @return {*}  {AnnouncementRecord}
     */
    buildAnnouncementFromCookie(
        userId: number,
        announcement: AnnouncementRecord
    ): AnnouncementRecord;

    /**
     * Based on the stored cookie value of viewed announcements, this adds the acknowldeged boolean
     * value as well as removes any announcement versions that are no longer active
     *
     * @param {number} userId
     * @param {AnnouncementRecord[]} activeAnnouncements
     * @return {*}  {AnnouncementRecord[]}
     */
    buildAnnouncementsFromCookie(
        userId: number,
        activeAnnouncements: AnnouncementRecord[]
    ): AnnouncementRecord[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function _acknowledge(
    userId: number,
    announcement: AnnouncementRecord
): boolean {
    const { version } = announcement;

    if (StringUtils.isEmpty(version)) {
        return false;
    }

    const acknowledgedAnnouncements = _getAnnouncementsCookie();

    const userAcknowledgmentNotFound =
        _getUserAnnouncements(userId, acknowledgedAnnouncements).find(
            (s) => s === version
        ) == null;

    if (userAcknowledgmentNotFound) {
        const nextAnnouncementCookieValue = _updateUserAnnouncements(
            acknowledgedAnnouncements,
            userId,
            version
        );

        _setAnnouncementCookie(nextAnnouncementCookieValue);
    }

    return true;
}

function _updateUserAnnouncements(
    acknowledgedAnnouncements: NumberIndexedObject<Array<string>>,
    userId: number,
    acknowledgedVersion: string
): NumberIndexedObject<Array<string>> {
    const userAcknowledgements = _getUserAnnouncements(
        userId,
        acknowledgedAnnouncements
    );

    if (userAcknowledgements == null) {
        return {
            ...acknowledgedAnnouncements,
            [userId]: [acknowledgedVersion],
        };
    }

    return {
        ...acknowledgedAnnouncements,
        [userId]: [...userAcknowledgements, acknowledgedVersion],
    };
}

function _buildAnnouncementFromCookie(
    userId: number,
    announcement: AnnouncementRecord,
    announcementsCookie?: NumberIndexedObject<Array<string>>
): AnnouncementRecord {
    const { version } = announcement;

    if (StringUtils.isEmpty(version)) {
        return announcement.with({ acknowledged: false });
    }

    if (announcementsCookie == null) {
        announcementsCookie = _getAnnouncementsCookie();
    }

    const acknowledged =
        _getUserAnnouncements(userId, announcementsCookie).findIndex(
            (c) => c === version
        ) >= 0;

    return announcement.with({ acknowledged });
}

function _buildAnnouncementsFromCookie(
    userId: number,
    activeAnnouncements: AnnouncementRecord[]
): AnnouncementRecord[] {
    const announcementsCookie = _getAnnouncementsCookie();

    const cleanedAnnouncementCookie = _cookieCleanup(
        userId,
        activeAnnouncements,
        announcementsCookie
    );

    return activeAnnouncements?.map((a) =>
        _buildAnnouncementFromCookie(userId, a, cleanedAnnouncementCookie)
    );
}

function _cookieCleanup(
    userId: number,
    activeAnnouncements: AnnouncementRecord[],
    announcementsCookie: NumberIndexedObject<Array<string>>
): NumberIndexedObject<Array<string>> {
    const activeUserAnnouncements = _getUserAnnouncements(
        userId,
        announcementsCookie
    ).filter((u) => activeAnnouncements.find((a) => a.version === u));

    const cleanedAnnouncementCookie: NumberIndexedObject<Array<string>> = {
        ...announcementsCookie,
        [userId]: activeUserAnnouncements,
    };

    _setAnnouncementCookie(cleanedAnnouncementCookie);

    return cleanedAnnouncementCookie;
}

function _getAnnouncementsCookie(): NumberIndexedObject<Array<string>> {
    const announcementCookie =
        CookieUtils.get<NumberIndexedObject<Array<string>>>(
            ACKNOWLEDGEMENT_COOKIE_KEY
        ) ?? {};

    return announcementCookie;
}

function _getUserAnnouncements(
    userId: number,
    announcementsCookie: NumberIndexedObject<Array<string>>
): Array<string> {
    const userAcknowledgedAnnouncements = announcementsCookie[userId];

    if (!Array.isArray(userAcknowledgedAnnouncements)) {
        return [];
    }

    return userAcknowledgedAnnouncements;
}

function _setAnnouncementCookie(
    value: NumberIndexedObject<Array<string>>
): void {
    const cookieOptions: SetCookieOptions = {
        expires: moment().add(1, "year").toDate(),
        path: "/",
    };

    CookieUtils.set(ACKNOWLEDGEMENT_COOKIE_KEY, value, cookieOptions);
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const AnnouncementUtils: IAnnouncementUtils = {
    acknowledge: _acknowledge,
    buildAnnouncementFromCookie: _buildAnnouncementFromCookie,
    buildAnnouncementsFromCookie: _buildAnnouncementsFromCookie,
};

// #endregion Exports
