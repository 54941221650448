import type PublicationRecord from "models/view-models/publication-record";
import type UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import { useMemo } from "react";

/**
 * Utility hook to group publications into a list of favorited publications
 * and a list of unfavorited publications.
 * @param publications
 * @param favorites
 */
export default function useGroupByFavoritePublications(
    publications: Array<PublicationRecord>,
    favorites: Array<UserPublicationFavoriteRecord>
) {
    const additionalPublications = useMemo(
        () =>
            publications.filter(
                (p: PublicationRecord) =>
                    !favorites.some(
                        (f: UserPublicationFavoriteRecord) =>
                            p.code === f.publicationCode &&
                            p.edition === f.publicationEdition
                    )
            ),
        [publications, favorites]
    );

    const favoritePublications = useMemo(
        () =>
            favorites.map((f: UserPublicationFavoriteRecord) => f.publication),
        [favorites]
    );

    return {
        additionalPublications,
        favoritePublications,
    };
}
