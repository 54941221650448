// A custom hook that builds on useLocation to parse

import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";

// the query string for you.
export default function useQueryString<T>() {
    const { pathname, search } = useLocation();
    const queryParams = RouteUtils.queryStringToObject<T>(search);

    const updateQueryParams = useCallback(
        (pageNumber: number) => {
            const params = new URLSearchParams(search);

            params.set("page", pageNumber.toString());

            const newRelativePath = `${pathname}?${params.toString()}`;

            window.history.pushState(
                { path: newRelativePath },
                "",
                newRelativePath
            );
        },
        [pathname, search]
    );
    return { queryParams, updateQueryParams } as const;
}
