import { Breakpoints } from "utilities/enumerations/breakpoints";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { t } from "utilities/localization-utils";
import Modal from "molecules/modals/modal";
import React, { ReactNode } from "react";
import TiaChangesPanelContent from "./tia-changes-panel-content";
import TiaRecord from "models/view-models/tia-record";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { CaapsTypes } from "constants/caaps-types";

interface TiaChangesPanelProps {
    tias: TiaRecord[];
    handleClose: () => void;
    title: ReactNode;
    body: ReactNode;
}

const cssBaseClass: string = "c-tia-changes-panel";

const TiaChangesPanel = (props: TiaChangesPanelProps) => {
    const { tias, handleClose, title, body } = props;
    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);

    const isTitle = tias.some(
        (tia) =>
            tia.caapsType === CaapsTypes.Annex ||
            tia.caapsType === CaapsTypes.Article ||
            tia.caapsType === CaapsTypes.Chapter
    );

    const isPart = tias.some((tia) => tia.caapsType === CaapsTypes.Part);

    let className = cssBaseClass;

    if (isTitle) className = `${cssBaseClass} -title`;
    if (isPart) className = `${cssBaseClass} -part`;

    return (
        <React.Fragment>
            {isDesktop ? (
                <div className={className} id={"diff-panel"}>
                    <div className={`${cssBaseClass}__container`}>
                        <TiaChangesPanelContent
                            tias={tias}
                            handleClose={handleClose}
                            title={title}
                            body={body}
                        />
                    </div>
                </div>
            ) : (
                <Modal
                    useDialogOverlay={true}
                    cssClassName={"c-codes-modal"}
                    closeDialog={handleClose}
                    isVisible={true}
                    label={t("viewChanges")}
                    transition={ModalTransitions.SlideUp}
                    type={ModalTypes.Fullscreen}>
                    <TiaChangesPanelContent
                        tias={tias}
                        handleClose={handleClose}
                        title={title}
                        body={body}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default TiaChangesPanel;
