import { createContext } from "react";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export interface ServiceWorkerContextState {
    hasUpdate: boolean;
    isReadyToReload: boolean;
    serviceWorker?: ServiceWorker;
    serviceWorkerState?: ServiceWorkerState;
    sendServiceWorkerMessage: <T = any>(command: T) => void;
}

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Context
// -----------------------------------------------------------------------------------------

const defaultState: ServiceWorkerContextState = {
    hasUpdate: false,
    isReadyToReload: false,
    serviceWorker: undefined,
    serviceWorkerState: undefined,
    sendServiceWorkerMessage: () => {},
};

export const ServiceWorkerContext =
    createContext<ServiceWorkerContextState>(defaultState);

// #endregion Context
