import { useCallback } from "react";
import HitRecord from "models/view-models/search/hit-record";
import SectionRecord from "models/view-models/section-record";
import AnnexService from "utilities/services/annexes/annex-service";
import ArticleService from "utilities/services/articles/article-service";
import ChapterService from "utilities/services/chapters/chapter-service";
import { PartService } from "internal";
import PublicationService from "utilities/services/publications/publication-service";
import SectionService from "utilities/services/sections/section-service";
import SituationService from "utilities/services/situational-navigation/situations/situation-service";
import SolutionService from "utilities/services/situational-navigation/solutions/solution-service";
import { chain } from "lodash";
import { CollectionUtils } from "utilities/collection-utils";
import {
    BatchedListService,
    ListService,
} from "utilities/services/service-factory";
import EnhancedContentByIdService from "utilities/services/enhanced-content/enhanced-content-by-id-service";

export default function useRelatedRecords() {
    const { list: listAnnexesApi } = AnnexService.useBatchList();
    const { list: listPublicationsApi } = PublicationService.useBatchList();
    const { list: listPartsApi } = PartService.useBatchList();
    const { list: listChaptersApi } = ChapterService.useBatchList();
    const { list: listArticlesApi } = ArticleService.useBatchList();
    const { list: listSectionsApi } = SectionService.useBatchList();
    const { list: listSituationsApi } = SituationService.useList();
    const { list: listSolutionsApi } = SolutionService.useList();
    const { list: listEnhancedContentsApi } =
        EnhancedContentByIdService.useList();

    const getRelatedRecords = useCallback(
        async (hits: HitRecord[]): Promise<HitRecord[]> => {
            const {
                sectionIds,
                situationIds,
                solutionIds,
                enhancedContentIds,
                ...relatedIds
            } = getRelatedRecordIds(hits);

            const sections = await load(
                listSectionsApi,
                "sectionIds",
                sectionIds
            );

            const {
                annexIds,
                articleIds,
                chapterIds,
                partIds,
                publicationIds,
            } = coalesceRelatedIdsWithIdsFromSections(relatedIds, sections);

            const [
                annexes,
                articles,
                chapters,
                parts,
                publications,
                situations,
                solutions,
                enhancedContents,
            ] = await Promise.all([
                load(listAnnexesApi, "annexIds", annexIds),
                load(listArticlesApi, "articleIds", articleIds),
                load(listChaptersApi, "chapterIds", chapterIds),
                load(listPartsApi, "partIds", partIds),
                load(listPublicationsApi, "publicationIds", publicationIds),
                load(listSituationsApi, "situationIds", situationIds),
                load(listSolutionsApi, "solutionIds", solutionIds),
                load(listEnhancedContentsApi, "ids", enhancedContentIds),
            ]);

            return hits?.map((hit: HitRecord) =>
                hit
                    .withAnnex(annexes)
                    .withArticle(articles)
                    .withChapter(chapters)
                    .withPart(parts)
                    .withPublication(publications)
                    .withSituations(situations)
                    .withSolutions(solutions)
                    .withSection(
                        sections.map((section: SectionRecord) =>
                            section
                                .withAnnex(annexes)
                                .withArticle(articles)
                                .withChapter(chapters)
                                .withPart(parts)
                                .withPublication(publications)
                        )
                    )
                    .withEnhancedContent(enhancedContents)
            );
        },
        [
            listAnnexesApi,
            listArticlesApi,
            listChaptersApi,
            listPartsApi,
            listPublicationsApi,
            listSectionsApi,
            listSituationsApi,
            listSolutionsApi,
            listEnhancedContentsApi,
        ]
    );

    return {
        getRelatedRecords,
    };
}

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const coalesceIds = (...args: number[][]): number[] =>
    chain(args).flattenDeep().uniq().value();

const coalesceRelatedIdsWithIdsFromSections = (
    relatedIds: any,
    sections: SectionRecord[]
) => {
    return {
        annexIds: coalesceIds(
            relatedIds.annexIds,
            CollectionUtils.uniqueValuesByProperty(sections, "annexId")
        ),
        articleIds: coalesceIds(
            relatedIds.articleIds,
            CollectionUtils.uniqueValuesByProperty(sections, "articleId")
        ),
        chapterIds: coalesceIds(
            relatedIds.chapterIds,
            CollectionUtils.uniqueValuesByProperty(sections, "chapterId")
        ),
        partIds: coalesceIds(
            relatedIds.partIds,
            CollectionUtils.uniqueValuesByProperty(sections, "partId")
        ),
        publicationIds: coalesceIds(
            relatedIds.publicationIds,
            CollectionUtils.uniqueValuesByProperty(sections, "publicationId")
        ),
    };
};

const getRelatedRecordIds = (hits: HitRecord[] = []) => {
    const getUniqueIdsFromHits = getUniqueIds(hits);

    return {
        annexIds: getUniqueIdsFromHits((hit) => hit.getAnnexId()),
        articleIds: getUniqueIdsFromHits((hit) => hit.getArticleId()),
        chapterIds: getUniqueIdsFromHits((hit) => hit.getChapterId()),
        partIds: getUniqueIdsFromHits((hit) => hit.getPartId()),
        publicationIds: getUniqueIdsFromHits((hit) => hit.getPublicationId()),
        sectionIds: getUniqueIdsFromHits((hit) => hit.getSectionId()),
        situationIds: getUniqueIdsFromHits((hit) => hit.getSituationId()),
        solutionIds: getUniqueIdsFromHits((hit) => hit.getSolutionId()),
        enhancedContentIds: getUniqueIdsFromHits((hit) =>
            hit.getEnhancedContentId()
        ),
    };
};

const getUniqueIds =
    (hits: HitRecord[]) =>
    (getIdFunction: (hit: HitRecord) => number | undefined): number[] =>
        chain(hits)
            .map(getIdFunction)
            .filter((id): id is number => id != null)
            .uniq()
            .value();

const load = async <TRecord, TQueryParams>(
    service:
        | ListService<TRecord, TQueryParams>
        | BatchedListService<TRecord, TQueryParams>,
    idsParamKey: keyof TQueryParams,
    ids: Array<number>
): Promise<Array<TRecord>> => {
    if (CollectionUtils.isEmpty(ids)) {
        return [];
    }

    // @ts-ignore compiler is complaining that TQueryParams could be a subclass of TQueryParams
    const params: TQueryParams = { [idsParamKey]: ids };
    return (await service(params, idsParamKey, 15)).resultObjects;
};

// #endregion Functions
