import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionBookmarkPathParams {
    /**
     * Id of the User for which the UserCollections relate to
     */
    userId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/usercollectionbookmarks";
const resourceType = UserCollectionBookmarkRecord;
const key = "usercollectionbookmarks";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserCollectionBookmarkService = {
    list: ServiceFactory.nestedList<
        UserCollectionBookmarkRecord,
        UserCollectionBookmarkPathParams,
        PagedQuery
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        UserCollectionBookmarkRecord,
        UserCollectionBookmarkPathParams,
        PagedQuery
    >(resourceType, baseEndpoint),
    useNestedListQuery: QueryHookFactory.useNestedListQuery<
        UserCollectionBookmarkRecord,
        UserCollectionBookmarkPathParams,
        PagedQuery
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionBookmarkService;

// #endregion Exports
