/* eslint-disable no-loop-func */
import * as React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TableSectionSimpleMobileCardProps {
    tTitle: JSX.Element;
    tHead: (JSX.Element | undefined)[];
    children: (JSX.Element | undefined)[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableSectionMobileSimple: React.FunctionComponent<TableSectionSimpleMobileCardProps> =
    (props: TableSectionSimpleMobileCardProps) => {
        // -----------------------------------------------------------------------------------------
        // #region Utilities
        // -----------------------------------------------------------------------------------------
        const cardHeader = props.tHead[0]?.props.children.filter(
            (child: JSX.Element) => child?.key !== undefined
        );
        var items: JSX.Element[] = [];
        var cardItems: JSX.Element[] = [];
        const cardBody = props.children
            .filter((child) => child?.type === "tbody")[0]
            ?.props?.children.filter(
                (child: JSX.Element) => child?.key !== undefined
            );

        for (var i = 0; i < cardBody.length; i++) {
            React.Children.map(
                cardBody[i].props.children.filter(
                    (cardBodyChild: JSX.Element) =>
                        cardBodyChild?.key !== undefined
                ),
                (child) => {
                    var headerText = cardHeader.filter(
                        (header: JSX.Element) => header?.key === child.key
                    );
                    var headerTextValue = "";
                    headerText.forEach((header: JSX.Element) => {
                        if (
                            header.props.children !== undefined &&
                            typeof header.props.children === "string"
                        ) {
                            headerTextValue = header.props.children;
                        } else {
                            for (var i = 0; i < cardBody.length; i++) {
                                if (
                                    header.props.children !== undefined &&
                                    typeof header.props.children[i] === "string"
                                ) {
                                    var headerChildrenText =
                                        header.props.children[i];
                                    headerTextValue += headerChildrenText + " ";
                                }
                            }
                        }
                    });
                    items.push(
                        <tr>
                            {React.cloneElement(child, {
                                dataLabel: headerTextValue, //headerText[0].props.children,
                            })}
                        </tr>
                    );
                    if (items.length === cardHeader.length) {
                        cardItems.push(<tbody className="card">{items}</tbody>);
                        items = [];
                    }
                }
            );
        }

        // #endregion Utilities

        // -----------------------------------------------------------------------------------------
        // #region Render
        // -----------------------------------------------------------------------------------------
        return (
            <table>
                {/* {props.tTitle} */}
                {cardItems}
            </table>
        );
        // #endregion Render
    };

// #endregion Component

export default TableSectionMobileSimple;
