import { RouteTab } from "molecules/route-tabs/route-tabs";
import { siteMap } from "internal-sitemap";

const getTabs = (): RouteTab[] => {
    return [
        {
            route: siteMap.admin.publications.dashboard,
            label: "Publications",
        },
        {
            route: siteMap.admin.enhancedContent.dashboard,
            label: "Enhanced Content",
        },
        {
            route: siteMap.admin.changeSummaries.dashboard,
            label: "Change Summaries",
        },
    ];
};

export const PublicationsAdminUtils = {
    getTabs,
};
