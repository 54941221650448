import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import RouteTabs from "molecules/route-tabs/route-tabs";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import { PublicationsAdminUtils } from "pages/admin/publications/publications-admin-utils";
import React, { useState, useEffect, useCallback } from "react";
import usePagedResults from "utilities/hooks/use-paged-results";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import { SkipNavContent } from "@reach/skip-nav";
import ChangeSummariesAdminTable from "organisms/admin/publications/change-summaries/change-summaries-admin-table";
import ChangeSummariesAdminTableRow from "organisms/admin/publications/change-summaries/change-summaries-admin-table-row";
import { ToastManager } from "utilities/toast/toast-manager";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import AdminChangeSummaryService, {
    AdminChangeSummaryQueryParams,
} from "utilities/services/admin/change-summaries/change-summary-service";
import ChangeSummariesAdminControls from "organisms/admin/publications/change-summaries/change-summaries-admin-controls";
import useAdminControls from "utilities/hooks/use-admin-controls";

export interface ChangeSummariesAdminDashboardProps extends NestedRoutesProps {}

const CSS_CLASS_NAME = "c-admin-page-with-table";
const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];
const DEFAULT_TAKE_SIZE = 10;

const ChangeSummariesAdminDashboard: React.FC<any> = (
    props: ChangeSummariesAdminDashboardProps
) => {
    const tabs = PublicationsAdminUtils.getTabs();

    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        rowCount,
        take,
        skip,
        setRowCount,
        resetPagination,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const [changeSummaries, setChangeSummaries] = useState<
        ChangeSummaryRecord[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);

    const {
        publicationCode,
        publicationEdition,
        publicationSelectOptions,
        handleSelectedPublication,
        hasUnpublishedChanges,
        publishStatus,
        searchText,
        setPublishStatusFilter,
        setSearchText,
        debouncedText,
    } = useAdminControls();

    const getAdminChangeSummaries = useCallback(
        async (options: AdminChangeSummaryQueryParams) => {
            setIsLoading(true);
            try {
                const results = await AdminChangeSummaryService.list({
                    take: options.take,
                    skip: options.skip,
                    code: options.code,
                    edition: options.edition,
                    hasUnpublishedChanges: options.hasUnpublishedChanges,
                    publishStatus: options.publishStatus,
                    searchText: options.searchText,
                });
                setRowCount(results.rowCount);
                setChangeSummaries(results.resultObjects);
            } catch (error) {
                ToastManager.error("There was an error");
            }
            setIsLoading(false);
        },
        [setRowCount]
    );

    const refresh = () => {
        getAdminChangeSummaries({
            skip,
            take,
            code: publicationCode,
            edition: publicationEdition,
            hasUnpublishedChanges,
            publishStatus,
            searchText,
        });
    };

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        skip,
        take,
        publicationCode,
        publicationEdition,
        hasUnpublishedChanges,
        searchText,
        publishStatus,
    ]);

    useEffect(() => {
        resetPagination();
    }, [
        debouncedText,
        hasUnpublishedChanges,
        publicationCode,
        publicationEdition,
        publishStatus,
        resetPagination,
    ]);
    return (
        <>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <Heading
                            priority={HeadingPriority.Five}
                            cssClassName={`${CSS_CLASS_NAME}__heading`}>
                            Publications Management
                        </Heading>
                        <RouteTabs routes={props.routes} tabs={tabs} />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div className={`${CSS_CLASS_NAME}__controlsWrapper`}>
                            <ChangeSummariesAdminControls
                                onChangePublishStatus={setPublishStatusFilter}
                                options={{
                                    publicationSelectOptions,
                                    publishFilters: publishStatus,
                                }}
                                onChangeSelectedPublication={
                                    handleSelectedPublication
                                }
                                refresh={refresh}
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__tableWrapper`}>
                            <ChangeSummariesAdminTable loading={isLoading}>
                                {changeSummaries.length &&
                                    changeSummaries.map((changeSummary) => (
                                        <ChangeSummariesAdminTableRow
                                            key={changeSummary.id}
                                            changeSummary={changeSummary}
                                            setChangeSummaries={
                                                setChangeSummaries
                                            }
                                            refresh={refresh}
                                        />
                                    ))}
                            </ChangeSummariesAdminTable>
                        </div>
                        <PageNavigationMenu
                            currentPage={currentPage}
                            numberOfPages={numberOfPages}
                            onNavigateBack={onPageLast}
                            onNavigateForward={onPageNext}
                            onPageSizeChange={onPageSizeChange}
                            onSelectPage={onSelectPage}
                            pageSize={take}
                            pageSizes={DEFAULT_PAGE_SIZES}
                            resultTotal={rowCount}
                        />
                    </div>
                </div>
            </SkipNavContent>
        </>
    );
};

export default ChangeSummariesAdminDashboard;
