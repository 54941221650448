import { Record } from "immutable";
import type Group from "models/interfaces/group";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: Group = RecordUtils.auditableDefaultValuesFactory<Group>({
    externalIdentifier: undefined,
    name: "",
    parentGroupId: undefined,
    totalLicenses: 0,
});

export default class GroupRecord
    extends Record(defaultValues)
    implements Group
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Group) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Group>} values
     */
    public with(values: Partial<Group>): GroupRecord {
        return new GroupRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
