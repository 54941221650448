import type UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import type UserCollectionRecord from "models/view-models/user-collection-record";
import { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useLoading from "utilities/hooks/use-loading";
import UserCollectionBookmarkService from "utilities/services/user-collection-bookmarks/user-collection-bookmark-service";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultErrorHandler = () =>
    ToastManager.error(
        "There was an issue loading collections for this bookmark."
    );

/**
 * Custom hook to load {UserCollection}s and {UserCollectionBookmark}s for a given {bookmarkId},
 * and, optionally, ALL collections the user has access to so they can be used as select options.
 * @param bookmarkId
 * @param loadOptions loads all UserCollection options and returns it as a separate list
 * @param onError
 */
export default function useBookmarkCollections(
    bookmarkId: number | undefined,
    loadOptions: boolean = true,
    onError?: () => void
) {
    // Set Up API Hooks
    const { list: listCollectionBookmarksApi } =
        UserCollectionBookmarkService.useList();
    const { list: listCollectionsApi } = UserCollectionService.useList();

    // State
    const { globalState } = useGlobalState();
    const [collectionBookmarks, setCollectionBookmarks] = useState<
        Array<UserCollectionBookmarkRecord>
    >([]);
    const [collections, setCollections] = useState<Array<UserCollectionRecord>>(
        []
    );
    const [allCollections, setAllCollections] = useState<
        Array<UserCollectionRecord>
    >([]);

    // Loading State
    const [loadingCollections, setLoadingCollections] = useState(true);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const loading = useLoading(loadingCollections, loadingOptions);

    const [optionsLoaded, setOptionsLoaded] = useState(false);
    const [collectionsLoaded, setCollectionsLoaded] = useState(false);

    // Handlers
    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler();
    }, [onError]);

    // Utility Functions
    const currentUserId = globalState.currentIdentity?.userId();
    const currentTeamId = globalState.currentIdentity?.getCurrentTeam()?.id;

    const shouldLoadOptions = useCallback(() => loadOptions, [loadOptions]);
    const getCollectionOptions = useCallback(
        async (
            userId: number,
            groupId?: number
        ): Promise<Array<UserCollectionRecord>> => {
            if (optionsLoaded) {
                return allCollections;
            }
            const results = await listCollectionsApi({ userId });
            return results.resultObjects ?? [];
        },
        [listCollectionsApi, allCollections, optionsLoaded]
    );

    // Side effects/API Calls
    useEffect(() => {
        if (collectionsLoaded) {
            return;
        }

        // load the collections for the specified bookmark ID

        if (bookmarkId == null || currentUserId == null) {
            setLoadingCollections(false);
            return;
        }

        const loadCollectionBookmarks = async (userId: number) => {
            setLoadingCollections(true);

            try {
                const [collectionBookmarkResult, collectionResult] =
                    await Promise.all([
                        listCollectionBookmarksApi({ bookmarkId }),
                        listCollectionsApi({ userId }, { bookmarkId }),
                    ]);

                setCollectionBookmarks(
                    collectionBookmarkResult.resultObjects ?? []
                );
                setCollections(collectionResult.resultObjects ?? []);
            } catch (e) {
                handleError();
            }

            setLoadingCollections(false);
        };

        loadCollectionBookmarks(currentUserId);
        setCollectionsLoaded(true);
    }, [
        bookmarkId,
        collectionsLoaded,
        currentUserId,
        handleError,
        listCollectionBookmarksApi,
        listCollectionsApi,
    ]);

    useEffect(() => {
        // load ALL collections which a user has access to, to be used as
        // options for a <Select> or a <MultiSelect>

        const loadOptions = async (userId: number, teamId?: number) => {
            setLoadingOptions(true);

            try {
                const result = await getCollectionOptions(userId, teamId);
                setAllCollections(result);
            } catch (e) {
                handleError();
            }

            setLoadingOptions(false);
        };

        if (currentUserId == null || !shouldLoadOptions()) {
            setLoadingOptions(false);
            return;
        }

        loadOptions(currentUserId, currentTeamId);
        setOptionsLoaded(true);
    }, [
        currentUserId,
        currentTeamId,
        handleError,
        listCollectionsApi,
        getCollectionOptions,
        shouldLoadOptions,
    ]);

    return {
        collectionBookmarks,
        setCollectionBookmarks,
        collections,
        setCollections,
        allCollections,
        setAllCollections,
        loading,
    };
}
