import EndEmulationSessionButton from "pages/admin/support/end-emulation-session-button";
import React from "react";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import DateUtils from "utilities/date-utils";

const BASE_CLASS = "c-emulation-mode-banner";
const USER_CLASS = `${BASE_CLASS}__user`;
const EXPIRES_CLASS = `${BASE_CLASS}__expires`;

const EmulationModeBanner = () => {
    const { isInEmulationMode, token, userEmail } = useEmulationMode();

    if (!isInEmulationMode) return null;

    const formattedExpiration = DateUtils.formatCustomDate(
        token?.expiresOn,
        "h:mm A"
    );

    return (
        <div className={BASE_CLASS}>
            <div className={USER_CLASS}>
                <span>Emulating:</span>
                <span className={`${USER_CLASS}__email`}>
                    <strong>{userEmail}</strong>
                </span>
            </div>
            <EndEmulationSessionButton />
            <div className={EXPIRES_CLASS}>
                <span>Session expiration:</span>
                <span>
                    <strong>{formattedExpiration}</strong>
                </span>
            </div>
        </div>
    );
};

export default EmulationModeBanner;
