import Loader from "atoms/loaders/loader";
import HitRecord from "models/view-models/search/hit-record";
import SearchResultCard from "molecules/search-result-card/search-result-card";
import React, { useCallback } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import FilterButton from "organisms/filter-button/filter-button";
import { useLocalization } from "utilities/hooks/use-localization";
import NoResultsBanner from "molecules/banners/no-results-banner";
import { AriaRole } from "utilities/enumerations/aria-role";
import { GoogleAnalyticsIds } from "constants/google-analytics-ids";
import Button from "atoms/buttons/button";
import PublicationSelectionModal from "organisms/modals/publication-selection-modal/publication-selection-modal";
import useModalActions from "utilities/hooks/use-modal-actions";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalResultsProps {
    filterCount: number;
    loading: boolean;
    onFilterModalOpen: () => void;
    onResultClick: () => void;
    results?: Array<HitRecord>;
    resultTotal: number;
    searchText: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-search-modal-results";
const HEADER_CLASS = `${BASE_CLASS}__header`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalResults: React.FC<SearchModalResultsProps> = React.memo(
    (props: SearchModalResultsProps) => {
        const {
            filterCount,
            results,
            resultTotal,
            loading,
            onFilterModalOpen,
            onResultClick,
            searchText,
        } = props;
        const { t } = useLocalization();
        const isMobile = useBreakpoint(
            Breakpoints.Phone,
            BreakpointComparer.MaxWidth
        );
        const hasSearchResults = (results?.length ?? 0) > 0;

        const {
            isOpen: isPublicationSelectionModalOpen,
            handleOpen: handlePublicationSelectionModalOpen,
            handleClose: handlePublicationSelectionModalClose,
        } = useModalActions();
        const {
            favorites,
            setFavorites,
            refresh: refreshFavorites,
        } = usePublicationFavorites();
        const setAndRefreshFavorites = useCallback(
            (favorites: Array<UserPublicationFavoriteRecord>): void => {
                setFavorites(favorites);
                refreshFavorites();
            },
            [refreshFavorites, setFavorites]
        );

        return (
            <div className={BASE_CLASS}>
                {isMobile && (
                    <div className={HEADER_CLASS}>
                        <span className={`${HEADER_CLASS}__count`}>
                            {t("result", { count: resultTotal })}
                        </span>
                        <FilterButton
                            count={filterCount}
                            onClick={onFilterModalOpen}>
                            {t("filter", { count: filterCount })}
                        </FilterButton>
                    </div>
                )}
                <div
                    aria-label={t("searchModal-SearchResults")}
                    className={`${BASE_CLASS}__list`}
                    role={AriaRole.List}>
                    {loading && <Loader />}
                    {!loading && searchText && (
                        <>
                            <div
                                className={`${BASE_CLASS}__matching-code-and-standards`}>
                                <span
                                    className={`${BASE_CLASS}__matching-code-and-standards__label`}>
                                    {t("searchModal-MatchingCodesAndStandards")}
                                </span>
                                <span
                                    className={`${BASE_CLASS}__matching-code-and-standards__button`}>
                                    <Button
                                        size={ButtonSizes.Small}
                                        style={ButtonStyles.Tertiary}
                                        onClick={
                                            handlePublicationSelectionModalOpen
                                        }>
                                        {t(
                                            "searchModal-FindMatchingPublications"
                                        )}
                                    </Button>
                                </span>
                            </div>
                        </>
                    )}
                    {!loading && !hasSearchResults && (
                        <NoResultsBanner
                            id={GoogleAnalyticsIds.SEARCH_MODAL_NO_RESULTS}
                            subtitle={t("noSearchResultsBanner-subTitle")}
                            title={t("noSearchResultsBanner-title")}
                        />
                    )}
                    {isPublicationSelectionModalOpen && (
                        <PublicationSelectionModal
                            closeDialog={handlePublicationSelectionModalClose}
                            favorites={favorites}
                            isVisible={isPublicationSelectionModalOpen}
                            onChangeFavorites={setAndRefreshFavorites}
                            initialSearchText={searchText}
                            onResultClick={onResultClick}
                        />
                    )}
                    {!loading &&
                        results?.map((hit: HitRecord, index: number) => (
                            <SearchResultCard
                                closeDialog={onResultClick}
                                hit={hit}
                                id={GoogleAnalyticsIds.SEARCH_RESULT_CARD}
                                index={index}
                                key={hit.getKey()}
                                onClick={() => null}
                            />
                        ))}
                </div>
            </div>
        );
    }
);
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalResults;

// #endregion Exports
