import React from "react";
import Breadcrumb from "atoms/breadcrumbs/breadcrumb";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AdminBreadcrumbProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AdminBreadcrumb: React.FC<AdminBreadcrumbProps> = () => {
    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">Admin</span>
        </Breadcrumb>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AdminBreadcrumb;

// #endregion Exports
