import type PublicationRecord from "models/view-models/publication-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import PublicationService from "utilities/services/publications/publication-service";
import { ReactQueryUtils } from "utilities/react-query-utils";
import StringUtils from "utilities/string-utils";
import PublicPublicationService from "utilities/services/public/publications/publication-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UsePublicationsOptions {
    isPublic?: boolean;
    /**
     * (Option) Search text to search on code, edition and title.
     */
    searchText?: string;
    skip?: number;
    topicIds?: number[];
    take?: number;
}

interface UsePublicationsResult
    extends ServiceHookResult<Array<PublicationRecord>> {
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultOptionValues: UsePublicationsOptions = {
    isPublic: false,
    searchText: undefined,
    skip: undefined,
    take: undefined,
    topicIds: [],
};
const defaultResultObject: Array<PublicationRecord> = [];

// #endregion Constants

/**
 * Custom hook to load publications
 */
export default function usePublications(
    options?: UsePublicationsOptions
): UsePublicationsResult {
    const { isPublic, searchText, skip, take, topicIds } = Object.assign(
        {},
        defaultOptionValues,
        options
    );
    const publicationService = isPublic
        ? PublicPublicationService
        : PublicationService;
    let params = StringUtils.hasValue(searchText) ? { searchText } : {};
    if (isPublic) {
        params = Object.assign(params, { skip, take, topicIds });
    }

    const { list: listPublicationApi } = publicationService.useListQuery();
    const publicationsResult = listPublicationApi(params, { cacheTime: 0 });

    return {
        ...ReactQueryUtils.getResult(publicationsResult),
        refresh: publicationsResult.refetch,
        resultObject:
            publicationsResult.data?.resultObjects ?? defaultResultObject,
        rowCount: publicationsResult.data?.rowCount,
    };
}
