import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import AdminBreadcrumb from "organisms/breadcrumbs/admin/admin-breadcrumb";
import AnnouncementsAdminBreadcrumb from "organisms/breadcrumbs/admin/announcements-admin-breadcrumb";
import CategoriesBreadcrumb from "organisms/breadcrumbs/admin/categories-breadcrumb";
import CategoryTypeBreadcrumbFactory from "organisms/breadcrumbs/admin/category-type-breadcrumb-factory";
import EnhancedContentAdminBreadcrumb from "organisms/breadcrumbs/admin/enhanced-content-admin-breadcrumb";
import FeaturedPublicationsAdminBreadcrumb from "organisms/breadcrumbs/admin/featured-publications-admin-breadcrumb";
import PublicationsAdminBreadcrumb from "organisms/breadcrumbs/admin/publications-admin-breadcrumb";
import SituationsBreadcrumb from "organisms/breadcrumbs/admin/situations-breadcrumb";
import SolutionsBreadcrumb from "organisms/breadcrumbs/admin/solutions-breadcrumb";
import UserManagementBreadcrumb from "organisms/breadcrumbs/admin/user-management-breadcrumb";
import AnnexBreadcrumb from "organisms/breadcrumbs/annex-breadcrumb";
import AnnexGroupBreadcrumb from "organisms/breadcrumbs/annex-group-breadcrumb";
import ArticleBreadcrumb from "organisms/breadcrumbs/article-breadcrumb";
import ChapterBreadcrumb from "organisms/breadcrumbs/chapter-breadcrumb";
import CollectionPageBreadcrumb from "organisms/breadcrumbs/collection-page-breadcrumb";
import HomeBreadcrumb from "organisms/breadcrumbs/home-breadcrumb";
import MyBookmarksBreadcrumb from "organisms/breadcrumbs/my-bookmarks-breadcrumb";
import MyLinkBreadcrumb from "organisms/breadcrumbs/my-link-breadcrumb";
import MyLinkCollectionsBreadcrumb from "organisms/breadcrumbs/my-link-collections-breadcrumb";
import { PublicationBreadcrumb } from "organisms/breadcrumbs/publication-breadcrumb";
import PublicationMetadataBreadcrumb from "organisms/breadcrumbs/publication-metadata-breadcrumb";
import SectionBreadcrumb from "organisms/breadcrumbs/section-breadcrumb";
import SituationalNavigationBreadcrumb from "organisms/breadcrumbs/situational-navigation-breadcrumb";
import { siteMap } from "internal-sitemap";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { RouteUtils } from "utilities/route-utils";
import TablesBreadcrumb from "organisms/breadcrumbs/tables-breadcrumb";
import FreeAccessPublicationAdminBreadcrumb from "organisms/breadcrumbs/admin/free-access-publication-breadcrumb";
import FreeAccessJobAdminBreadcrumb from "organisms/breadcrumbs/admin/free-access-job-breadcrumb";
import ChangeSummariesAdminBreadcrumb from "organisms/breadcrumbs/admin/change-summaries-admin-breadcrumb";
import SupportUsersBreadcrumb from "organisms/breadcrumbs/admin/support-users-breadcrumb";
import SupportUserDetailBreadcrumb from "organisms/breadcrumbs/admin/support-user-detail-breadcrumb";

const breadcrumbRoutes: BreadcrumbsRoute[] = [
    {
        path: siteMap.admin.dashboard,
        breadcrumb: AdminBreadcrumb,
    },
    {
        path: siteMap.admin.announcements,
        breadcrumb: AnnouncementsAdminBreadcrumb,
    },
    {
        path: siteMap.admin.enhancedContent.dashboard,
        breadcrumb: EnhancedContentAdminBreadcrumb,
    },
    {
        path: siteMap.admin.featuredPublications.dashboard,
        breadcrumb: FeaturedPublicationsAdminBreadcrumb,
    },
    {
        path: siteMap.admin.publications.dashboard,
        breadcrumb: PublicationsAdminBreadcrumb,
    },
    {
        path: siteMap.admin.situationalNavigation.categories.dashboard,
        breadcrumb: CategoriesBreadcrumb,
    },
    {
        path: siteMap.admin.situationalNavigation.categories.occupancies
            .dashboard,
        breadcrumb: CategoryTypeBreadcrumbFactory(CategoryType.Occupancies),
    },
    {
        path: siteMap.admin.situationalNavigation.categories.systems.dashboard,
        breadcrumb: CategoryTypeBreadcrumbFactory(CategoryType.Systems),
    },
    {
        path: siteMap.admin.situationalNavigation.categories.equipment
            .dashboard,
        breadcrumb: CategoryTypeBreadcrumbFactory(CategoryType.Equipment),
    },
    {
        path: siteMap.admin.situationalNavigation.categories.spaces.dashboard,
        breadcrumb: CategoryTypeBreadcrumbFactory(CategoryType.Spaces),
    },
    {
        path: siteMap.admin.situationalNavigation.dashboard,
        breadcrumb: SituationalNavigationBreadcrumb,
    },
    {
        path: siteMap.admin.situationalNavigation.solutions.dashboard,
        breadcrumb: SolutionsBreadcrumb,
    },
    {
        path: siteMap.admin.situationalNavigation.situations.dashboard,
        breadcrumb: SituationsBreadcrumb,
    },
    {
        path: siteMap.admin.support.users,
        breadcrumb: SupportUsersBreadcrumb,
    },
    {
        path: siteMap.admin.support.userDetail,
        breadcrumb: SupportUserDetailBreadcrumb,
    },
    {
        path: siteMap.admin.userManagement.dashboard,
        breadcrumb: UserManagementBreadcrumb,
    },
    {
        path: siteMap.home,
        breadcrumb: HomeBreadcrumb,
    },
    {
        path: siteMap.myLink.dashboard,
        breadcrumb: MyLinkBreadcrumb,
    },
    {
        path: siteMap.myLink.myBookmarks,
        breadcrumb: MyBookmarksBreadcrumb,
    },
    {
        path: siteMap.myLink.collections,
        breadcrumb: MyLinkCollectionsBreadcrumb,
    },
    {
        path: siteMap.myLink.collection,
        breadcrumb: CollectionPageBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.publication,
        breadcrumb: PublicationBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.publication,
        breadcrumb: PublicationBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.publication,
        breadcrumb: PublicationBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.chapter,
        breadcrumb: ChapterBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.chapter,
        breadcrumb: ChapterBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.chapter,
        breadcrumb: ChapterBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.article,
        breadcrumb: ArticleBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.article,
        breadcrumb: ArticleBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.article,
        breadcrumb: ArticleBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.annex,
        breadcrumb: AnnexBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.annex,
        breadcrumb: AnnexBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.annex,
        breadcrumb: AnnexBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.annexGroup,
        breadcrumb: AnnexGroupBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.annexGroup,
        breadcrumb: AnnexGroupBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.annexGroup,
        breadcrumb: AnnexGroupBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.publicationMetadata,
        breadcrumb: PublicationMetadataBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.publicationMetadata,
        breadcrumb: PublicationMetadataBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.publicationMetadata,
        breadcrumb: PublicationMetadataBreadcrumb,
    },
    {
        path: siteMap.situationalNavigation.dashboard,
        breadcrumb: SituationalNavigationBreadcrumb,
    },
    {
        path: siteMap.publications.deprecated.section,
        breadcrumb: SectionBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.chapterTables,
        breadcrumb: TablesBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.chapterTables,
        breadcrumb: TablesBreadcrumb,
    },
    {
        path: siteMap.publications.adminPreview.annexTables,
        breadcrumb: TablesBreadcrumb,
    },
    {
        path: siteMap.publications.permalinks.annexTables,
        breadcrumb: TablesBreadcrumb,
    },
    {
        path: siteMap.admin.freeAccessPublications.dashboard,
        breadcrumb: FreeAccessPublicationAdminBreadcrumb,
    },
    {
        path: siteMap.admin.freeAccessPublications.jobs,
        breadcrumb: FreeAccessJobAdminBreadcrumb,
    },
    {
        path: siteMap.admin.changeSummaries.dashboard,
        breadcrumb: ChangeSummariesAdminBreadcrumb,
    },
];

export default breadcrumbRoutes.map((route: BreadcrumbsRoute) => {
    if (route.path === siteMap.home) {
        return route;
    }
    return RouteUtils.withCulture<BreadcrumbsRoute>(route);
});
