import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { Resource } from "models/interfaces/resource";
import { ResourceRecord } from "models/interfaces/resource-record";
import ResourceValidator from "models/validation/admin/resources/resource-validator";
import FileRecord from "models/view-models/file-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import FileUploadField from "molecules/form-fields/file-upload-field";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal from "molecules/modals/modal";
import React, { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import { ObjectValidationResult } from "utilities/validation/object-validator/object-validator";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminResourceModalProps<T extends ResourceRecord> {
    external: boolean;
    initialFile?: FileRecord;
    initialResource?: T;
    isVisible: boolean;
    onModalClose: () => void;
    onResourceAdded: (resource: Resource, file?: FileRecord) => void;
    loading: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const Validator = new ResourceValidator();

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminResourceModal = <T extends ResourceRecord>(
    props: AdminResourceModalProps<T>
) => {
    const CSS_BASE_CLASS = "c-resource-modal";
    const cssClassNames = [CSS_BASE_CLASS];

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [file, setFile] = useState<FileRecord | undefined>(undefined);
    const [validation, setValidation] = useState<ObjectValidationResult<T>>({});
    const [fileUploadErrored, setFileUploadErrored] = useState(false);

    useEffect(() => {
        setTitle(props.initialResource?.titleDraft ?? "");
        setDescription(props.initialResource?.descriptionDraft ?? "");
        setVideoUrl(props.initialResource?.absoluteUrlDraft ?? "");
        setFile(props.initialFile);
    }, [props.initialResource, props.initialFile, setTitle, setDescription]);

    const reset = useCallback(() => {
        setTitle(props.initialResource?.titleDraft ?? "");
        setDescription(props.initialResource?.descriptionDraft ?? "");
        setVideoUrl(props.initialResource?.absoluteUrlDraft ?? "");
        setFile(props.initialFile);
        setFileUploadErrored(false);
        setValidation({});
    }, [props.initialResource, props.initialFile]);

    useEffect(() => {
        if (!props.isVisible) {
            reset();
        }
    }, [props.isVisible, reset]);

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setTitle(e.target.value);
    const handleDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setDescription(e.target.value);
    const handleUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setVideoUrl(e.target.value);

    const handleCancel = () => props.onModalClose?.();

    const addResource = () => {
        const resource: Resource = {
            id: props.initialResource?.id,
            title: props.initialResource?.title ?? "",
            titleDraft: title,
            description: props.initialResource?.description ?? "",
            descriptionDraft: description,
            fileId: props.initialResource?.fileId, // add old fileId so we can delete it on publish
            fileDraftId: props.external ? undefined : file?.id,
            absoluteUrlDraft: props.external ? videoUrl : undefined,
        };
        const validationResult = Validator.validate(resource, props.external);
        setValidation(validationResult);
        if (ResourceValidator.hasErrors(validationResult)) {
            return;
        }

        props.onResourceAdded(resource, file);
    };

    const resetFileUploadErrors = () => {
        setValidation({ ...validation, fileDraftId: undefined });
        setFileUploadErrored(false);
    };

    return (
        <Modal
            cssClassName={cssClassNames.join(" ")}
            closeDialog={props.onModalClose}
            isVisible={props.isVisible}
            label={
                props.initialResource != null ? "Edit Resource" : "Add Resource"
            }
            transition={ModalTransitions.Fade}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS}__header`}>
                <h2>
                    {props.initialResource != null ? "Edit" : "Add"}{" "}
                    {props.external ? "Video" : "File"}
                </h2>
                <Button
                    disabled={props.loading}
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.Medium}
                    onClick={props.onModalClose}
                    cssClassName={"-modal-close -icon"}
                    accessibleText="Close Dialog">
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS}__content`}>
                <InputFormField
                    disabled={props.loading}
                    errorMessages={validation.titleDraft}
                    label={`${props.external ? "Video" : "File"} Title`}
                    onChange={handleTitleChanged}
                    maxLength={60}
                    placeholder={`Enter a ${
                        props.external ? "link" : "file"
                    } title`}
                    isValid={CollectionUtils.isEmpty(validation.titleDraft)}
                    value={title}
                    required={true}
                />
                <InputFormField
                    disabled={props.loading}
                    label={`${props.external ? "Video" : "File"} Description`}
                    onChange={handleDescriptionChanged}
                    maxLength={100}
                    placeholder={`Enter a ${
                        props.external ? "video" : "file"
                    } description`}
                    isValid={true}
                    value={description}
                />
                {
                    // if
                    !props.external && (
                        <FileUploadField
                            disabled={props.loading}
                            errorMessages={validation.fileDraftId}
                            fileUploadDestination={
                                FileUploadDestination.SolutionResources
                            }
                            isErrored={
                                fileUploadErrored ||
                                CollectionUtils.hasValues(
                                    validation.fileDraftId
                                )
                            }
                            onFileChanged={setFile}
                            onRetryUpload={resetFileUploadErrors}
                            onUploadError={() => setFileUploadErrored(true)}
                            value={file}
                            required={true}
                        />
                    )
                }
                {
                    // if
                    props.external && (
                        <InputFormField
                            disabled={props.loading}
                            label="Enter Youtube or Brightcove Video URL"
                            onChange={handleUrlChanged}
                            errorMessages={validation.absoluteUrlDraft}
                            maxLength={2083}
                            placeholder="Enter external video URL..."
                            isValid={CollectionUtils.isEmpty(
                                validation.absoluteUrlDraft
                            )}
                            value={videoUrl}
                            required={true}
                        />
                    )
                }
            </div>
            <div className={`${CSS_BASE_CLASS}__footer`}>
                <Button
                    disabled={props.loading}
                    onClick={handleCancel}
                    style={ButtonStyles.Secondary}>
                    Cancel
                </Button>
                <Button
                    disabled={
                        props.loading || (!props.external && file == null)
                    }
                    onClick={addResource}
                    style={ButtonStyles.Primary}>
                    {props.initialResource != null ? "Save" : "Add"}
                </Button>
            </div>
        </Modal>
    );
};

export default AdminResourceModal;
