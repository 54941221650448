import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import BookRecord from "models/view-models/book-record";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import { OfflineBookDownloadStatus } from "molecules/enums/offline-book-download-status";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import React, { useState } from "react";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import { t } from "utilities/localization-utils";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { PreloadUrlsProgressMessage } from "utilities/service-worker/interfaces/messages/preload-urls-progress-message";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface OfflineBookStatusIconProps {
    book?: BookRecord;
    offlineBook?: OfflineBookRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-offline-book-status-icon";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineBookStatusIcon: React.FC<OfflineBookStatusIconProps> = (
    props: OfflineBookStatusIconProps
) => {
    const { book = new BookRecord(), offlineBook } = props;

    const [syncInProgress, setSyncInprogress] = useState(false);
    const tooltipClass = `${BASE_CLASS}__tooltip`;

    const handleProgressMessage = (
        event: TypedMessageEvent<PreloadUrlsProgressMessage>
    ) => {
        const { code, edition, current, total } = event.data;
        if (
            code !== book.code ||
            edition !== book.edition ||
            (syncInProgress && current < total)
        ) {
            return;
        }

        if (!syncInProgress) {
            setSyncInprogress(true);
            return;
        }

        setSyncInprogress(false);
    };

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.PreloadProgress,
        handleProgressMessage
    );

    let icon = Icons.DownloadSync;
    let tooltipText = "";
    let modifierClass = "";

    const status = syncInProgress
        ? OfflineBookDownloadStatus.InProgress
        : offlineBook?.getDownloadStatus();

    switch (status) {
        case OfflineBookDownloadStatus.Downloaded:
            icon = Icons.DownloadComplete;
            modifierClass = "-downloaded";
            tooltipText = t("offlineBookDownloadStatus-downloaded");
            break;
        case OfflineBookDownloadStatus.InProgress:
            icon = Icons.Download;
            tooltipText = `${t("downloading")}...`;
            break;
        case OfflineBookDownloadStatus.UpdateAvailable:
            icon = Icons.DownloadSync;
            tooltipText = t("offlineBookDownloadStatus-updateAvailable");
            break;
        default:
            return null;
    }

    return (
        <div className={`${BASE_CLASS} ${modifierClass}`}>
            <Tooltip
                content={
                    <div className={`${tooltipClass}__content`}>
                        {tooltipText}
                    </div>
                }
                cssClassName={tooltipClass}
                durationInMs={0}
                hideOnClick={true}
                placement={TooltipPlacement.Top}>
                <span tabIndex={0}>
                    <Icon type={icon} />
                </span>
            </Tooltip>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookStatusIcon;

// #endregion Exports
