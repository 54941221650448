import * as React from "react";

export interface CommitteesMemberOrganizationProps {
    children?: JSX.Element[];
}

const CommitteesMemberOrganization = (
    props: CommitteesMemberOrganizationProps
) => <span className="c-committee-member__organization">{props.children}</span>;

export default CommitteesMemberOrganization;
