import SubscriptionType from "organisms/enums/subscription-type";
import StringUtils from "utilities/string-utils";
import { EcommercePins } from "constants/ecommerce-pins";
import { CoreUtils } from "utilities/core-utils";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SubscriptionTypeAndSize {
    teamSize?: SubscriptionTeamSize;
    type: SubscriptionType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const FREE_TRIAL_ROUTE_PARAM = "trial";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Attempts to map an ecommerce pin value to a `SubscriptionType`.
 *
 * Will NOT return `SubscriptionType.Enterprise`
 *
 * @param pin Pin from ecommerce to be mapped to a `SubscriptionType`
 */
const fromPin = (pin?: string): SubscriptionTypeAndSize | undefined => {
    if (StringUtils.isEmpty(pin)) {
        return undefined;
    }

    // Intentionally forcing a toString here incase a number is sent in as a query param. It will
    // throw a runtime error instead of gracefully returning `undefined`
    switch (pin.toString().toUpperCase()) {
        case EcommercePins.FreeTrial:
            return { type: SubscriptionType.FreeTrial };
        case EcommercePins.IndividualAnnual:
        case EcommercePins.IndividualAnnualAutoRenew:
        case EcommercePins.IndividualMonthlyAutoRenew:
            return { type: SubscriptionType.Individual };
        case EcommercePins.TeamTenAnnual:
        case EcommercePins.TeamTenAnnualAutoRenew:
            return {
                teamSize: SubscriptionTeamSize.UpToTen,
                type: SubscriptionType.Team,
            };
        case EcommercePins.TeamFiveAnnual:
        case EcommercePins.TeamFiveAnnualAutoRenew:
            return {
                teamSize: SubscriptionTeamSize.UpToFive,
                type: SubscriptionType.Team,
            };
        // Enterprise pins are not funneled through the registration page, so ignore them
        case EcommercePins.EnterpriseAnnual:
        case EcommercePins.EnterpriseAnnualAutoRenew:
        default:
            return undefined;
    }
};

/**
 * Returns the appropriate `SubscriptionType` based on the current route param, or `undefined` if
 * the route param does not match any recognized plan types.
 *
 * Will NOT return `SubscriptionType.Enterprise`
 *
 * @param subscriptionTypeRouteParam Route parameter for subscriptionType
 */
const fromRouteParam = (
    subscriptionTypeRouteParam?: string
): SubscriptionType | undefined => {
    if (StringUtils.isEmpty(subscriptionTypeRouteParam)) {
        return;
    }

    // also accept "trial" to indicate "Free Trial"
    if (subscriptionTypeRouteParam.toLowerCase() === FREE_TRIAL_ROUTE_PARAM) {
        return SubscriptionType.FreeTrial;
    }

    return CoreUtils.getEnumValues(SubscriptionType)
        .filter((t: SubscriptionType) => t !== SubscriptionType.Enterprise)
        .find(
            (t: SubscriptionType) =>
                t.toLowerCase() === subscriptionTypeRouteParam.toLowerCase()
        );
};

/**
 * Maps a `SubscriptionType` to a lowercase route param.
 *
 * Will NOT return `SubscriptionType.Enterprise`
 *
 * @param subscriptionType SubscriptionType to be mapped to a route param string
 */
const toRouteParam = (subscriptionType: SubscriptionType): string => {
    if (subscriptionType === SubscriptionType.FreeTrial) {
        return FREE_TRIAL_ROUTE_PARAM;
    }

    if (subscriptionType === SubscriptionType.Enterprise) {
        return "";
    }

    return subscriptionType.toString().toLowerCase();
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const SubscriptionTypeUtils = {
    fromPin,
    fromRouteParam,
    toRouteParam,
};

export default SubscriptionTypeUtils;

// #endregion Exports
