import TiaRecord from "models/view-models/tia-record";
import React from "react";
import { t } from "utilities/localization-utils";

interface TiaEffectiveDateLineProps {
    currentTiaIndex: number;
    tias: TiaRecord[];
}

const cssBaseClass: string = "c-tia-changes-panel";

const TiaEffectiveDateLine = (props: TiaEffectiveDateLineProps) => {
    const { currentTiaIndex, tias } = props;

    const currentTia = tias[currentTiaIndex];
    const nextTia =
        currentTiaIndex === tias.length - 1 ? null : tias[currentTiaIndex + 1];

    const currentTiaEffectiveDate = currentTia.getFormattedEffectiveDate();
    const currentTiaEndDate = currentTia.getFormattedLastDateEffective();
    const nextTiaEffectiveDate = nextTia?.getFormattedEffectiveDate();

    return (
        <div className={`${cssBaseClass}__subheader`}>
            {nextTia
                ? `${t(
                      "effective"
                  )} ${nextTiaEffectiveDate} - ${currentTiaEndDate} (${
                      nextTia.name
                  })`
                : `${t("effectiveBefore")} ${currentTiaEffectiveDate}:`}
        </div>
    );
};

export default TiaEffectiveDateLine;
