import { publicationRoutes } from "publication-routes";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "routes";
import { siteMap } from "internal-sitemap";
import { CollectionUtils } from "utilities/collection-utils";
import { CoreUtils } from "utilities/core-utils";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";

let _cachedFlattenedRoutes: Array<RouteDefinition> | undefined = undefined;

/**
 * React hook to keep the {RouteDefinition} for the current route in state.
 */
export default function useCurrentRouteDefinition() {
    const location = useLocation();

    const getCurrentRouteDefinition = useCallback(
        (route?: string): RouteDefinition | undefined => {
            const appRoutes = RouteUtils.join(
                routes,
                publicationRoutes,
                "home"
            );
            let currentRoute = route ?? location.pathname;

            if (currentRoute === "/" || StringUtils.isEmpty(currentRoute)) {
                return routes.home;
            }

            if (currentRoute.endsWith("/")) {
                currentRoute = currentRoute.substr(0, currentRoute.length - 1);
            }

            if (StringUtils.isEmpty(currentRoute)) {
                return undefined;
            }

            if (CollectionUtils.isEmpty(_cachedFlattenedRoutes)) {
                _cachedFlattenedRoutes = RouteUtils.getFlattenedRoutes(
                    CoreUtils.objectToArray(appRoutes)
                );
            }

            // we already matched the home route above, so filter it out
            // and sort by length of path so that nested routes appear after their parents
            let allRoutes: Array<RouteDefinition> = _cachedFlattenedRoutes!
                .filter((r: RouteDefinition) => r.path !== siteMap.home)
                .sort(
                    (a: RouteDefinition, b: RouteDefinition) =>
                        a.path.length - b.path.length
                );

            if (RouteUtils.containsCultureCode(currentRoute)) {
                allRoutes = allRoutes.map((route: RouteDefinition) =>
                    RouteUtils.withCulture<RouteDefinition>(route)
                );
            }

            let matchedRoute: RouteDefinition | undefined = undefined;
            for (const r of allRoutes) {
                let path = r.path;
                // regex replace params with regex to match any param value
                // and replace / with escaped version, \/
                path = path
                    .replace(/:[a-zA-Z-]*/gi, ".*")
                    .split("/")
                    .join("\\/");

                if (path.includes(".*")) {
                    // if route params exist in the route, regex match
                    if (new RegExp(path, "gi").test(currentRoute)) {
                        matchedRoute = r;
                    }
                    continue;
                }

                if (r.path === currentRoute) {
                    // else, do an exact match
                    matchedRoute = r;
                }
            }

            return matchedRoute;
        },
        [location.pathname]
    );

    const routeDefinition = useMemo(() => {
        return getCurrentRouteDefinition();
    }, [getCurrentRouteDefinition]);

    return routeDefinition;
}
