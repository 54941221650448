import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ChapterService from "utilities/services/chapters/chapter-service";
import NestedChapterService from "utilities/services/publications/chapters/chapter-service";
import { QueryHookOptions } from "utilities/interfaces/query-hook-options";
import PublicationRecord from "models/view-models/publication-record";
import NumberUtils from "utilities/number-utils";
import ChapterRecord from "models/view-models/chapter-record";
import StringUtils from "utilities/string-utils";
import { ReactQueryUtils } from "utilities/react-query-utils";

export interface ChapterQueryOptions extends QueryHookOptions {
    code?: string;
    edition?: string;
    nfpaLabel?: string;
    publication?: PublicationRecord;
    publicationId?: string | number;
    id?: string | number;
}

export default function useChapterQuery(
    options: ChapterQueryOptions
): ServiceHookResult<ChapterRecord> {
    // -----------------------------------------------------------------------------------------
    // #region Private Members
    // -----------------------------------------------------------------------------------------

    const { code, edition, nfpaLabel, publication } = options;
    const publicationId = NumberUtils.parseInt(options.publicationId);
    const id = NumberUtils.parseInt(options.id);

    const { list } = ChapterService.useListQuery();
    const { get } = ChapterService.useGetQuery();
    const { list: nestedList } = NestedChapterService.useListQuery();
    const { get: nestedGet } = NestedChapterService.useGetQuery();

    // #endregion Private Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    const listEnabled = code != null && edition != null && nfpaLabel != null;
    const listResult = list(
        { code, edition, nfpaLabel },
        { enabled: listEnabled }
    );

    const getEnabled = !listEnabled && !NumberUtils.isDefault(id);
    const getResult = get({ id: id! }, undefined, { enabled: getEnabled });

    const nestedListEnabled =
        !listEnabled &&
        !getEnabled &&
        !NumberUtils.isDefault(publicationId) &&
        !StringUtils.isEmpty(nfpaLabel);

    const nestedListResult = nestedList(
        { publicationId: publicationId! },
        { nfpaLabel },
        { enabled: nestedListEnabled }
    );

    const nestedGetEnabled =
        !nestedListEnabled &&
        !getEnabled &&
        !listEnabled &&
        !NumberUtils.isDefault(publicationId);
    const nestedGetResult = nestedGet(
        { publicationId: publicationId!, id: id! },
        undefined,
        { enabled: nestedGetEnabled }
    );

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const chapter: ChapterRecord | undefined = [
        listResult.data?.resultObjects[0],
        getResult.data?.resultObject,
        nestedGetResult.data?.resultObject,
        nestedListResult.data?.resultObjects[0],
    ].find((c) => c != null);

    const { loading, loaded, errors } = ReactQueryUtils.getResult(
        listResult,
        getResult,
        nestedGetResult,
        nestedListResult
    );

    const resultObject = chapter?.with({ publication }) ?? new ChapterRecord();

    return { loading, loaded, errors, resultObject };

    // #endregion Public Properties
}
