export enum AriaRole {
    Checkbox = "checkbox",
    List = "list",
    ListItem = "listitem",
    Search = "search",
    Status = "status",
    TabList = "tablist",
    Tab = "tab",
    TabPanel = "tabpanel",
}
