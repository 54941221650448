import { useCallback, useState } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
export interface UseModalActionsHookResult {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    handleToggle: () => void;
}

// #endregion Interfaces

export default function useModalActions(): UseModalActionsHookResult {
    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const [isOpen, setIsOpen] = useState(false);

    // #endregion Public Properties

    // -----------------------------------------------------------------------------------------
    // #region Public Functions
    // -----------------------------------------------------------------------------------------

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleToggle = () => setIsOpen((isOpen: boolean) => !isOpen);

    // #endregion Public Functions

    return {
        handleClose: useCallback(handleClose, [setIsOpen]),
        handleOpen: useCallback(handleOpen, []),
        handleToggle: useCallback(handleToggle, []),
        isOpen,
    };
}
