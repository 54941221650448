import PublishStatus from "models/enumerations/publish-status";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { List } from "immutable";
import useDeepCompareEffect from "use-deep-compare-effect";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import { useState, useCallback } from "react";
import AdminEnhancedContentService from "utilities/services/admin/enhanced-content/enhanced-content-service";
import AdminEnhancedContentComponentService from "utilities/services/admin/enhanced-content/enhanced-content-component-service";
import SectionService from "utilities/services/sections/section-service";
import AnnexService from "utilities/services/annexes/annex-service";
import ArticleService from "utilities/services/articles/article-service";
import ChapterService from "utilities/services/chapters/chapter-service";
import PublicationService from "utilities/services/publications/publication-service";
import UserService from "utilities/services/users/user-service";
import UserRecord from "models/view-models/user-record";
import { ToastManager } from "utilities/toast/toast-manager";
import EnhancedContentComponentRecord from "models/view-models/enhanced-content-component-record";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import SectionRecord from "models/view-models/section-record";
import PublicationRecord from "models/view-models/publication-record";
import { CollectionUtils } from "utilities/collection-utils";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BATCH_SIZE = 25;

const ERROR_LOADING_ENHANCED_CONTENT =
    "There was an issue loading enhanced content.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseEnhancedContentAdminOptions {
    code?: string;
    edition?: string;
    hasUnpublishedChanges?: boolean;
    publishStatus?: PublishStatus;
    searchText?: string;
    skip?: number;
    take?: number;
}

interface GetNavigationPropertiesResult {
    annexes: Array<AnnexRecord>;
    articles: Array<ArticleRecord>;
    chapters: Array<ChapterRecord>;
    publications: Array<PublicationRecord>;
    sections: Array<SectionRecord>;
    users: Array<UserRecord>;
}

// #endregion Interfaces

/**
 * Custom hook to gather EnhancedContent from the api.
 *
 * @export
 * @param {UseEnhancedContentAdminOptions} options
 * @return {*}  {ServiceHookResult<List<EnhancedContentRecord>>}
 */
export default function useEnhancedContentWithComponentAdmin(
    options: UseEnhancedContentAdminOptions
): ServiceHookResult<List<EnhancedContentRecord>> {
    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list } = AdminEnhancedContentService.useList();
    const { list: listComponents } =
        AdminEnhancedContentComponentService.useBatchedList();
    const { list: listAnnexes } = AnnexService.useBatchList();
    const { list: listArticles } = ArticleService.useBatchList();
    const { list: listChapters } = ChapterService.useBatchList();
    const { list: listPublications } = PublicationService.useBatchList();
    const { list: listSections } = SectionService.useBatchList();
    const { list: listUsers } = UserService.useBatchList();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [serviceHookResult, setServiceHookResult] = useState<
        ServiceHookResult<List<EnhancedContentRecord>>
    >(ServiceHookResultFactory.loadingResult(List<EnhancedContentRecord>()));

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Callbacks
    // -----------------------------------------------------------------------------------------

    const getComponents = useCallback(
        async (
            enhancedContents: Array<EnhancedContentRecord>
        ): Promise<Array<EnhancedContentComponentRecord>> => {
            const enhancedContentIds = CollectionUtils.uniqueValuesByProperty(
                enhancedContents,
                "id"
            );

            const getComponentsResult = await listComponents(
                {
                    enhancedContentIds,
                },
                "enhancedContentIds",
                BATCH_SIZE
            );

            return getComponentsResult.resultObjects ?? [];
        },
        [listComponents]
    );

    const getNavigationProperties = useCallback(
        async (
            records: Array<EnhancedContentRecord>,
            components: Array<EnhancedContentComponentRecord>
        ): Promise<GetNavigationPropertiesResult> => {
            const annexIds = CollectionUtils.uniqueValuesByProperty(
                components,
                "annexId"
            );
            const articleIds = CollectionUtils.uniqueValuesByProperty(
                components,
                "articleId"
            );
            const chapterIds = CollectionUtils.uniqueValuesByProperty(
                components,
                "chapterId"
            );
            const publicationIds = CollectionUtils.uniqueValuesByProperty(
                components,
                "publicationId"
            );
            const sectionIds = CollectionUtils.uniqueValuesByProperty(
                components,
                "sectionId"
            );
            const userIds = _uniqueCreatedByAndUpdatedByIds(records);

            const [
                getAnnexResult,
                getArticleResult,
                getChapterResult,
                getPublicationResult,
                getSectionsResult,
                getUsersResult,
            ] = await Promise.all([
                listAnnexes({ annexIds }, "annexIds", BATCH_SIZE),
                listArticles({ articleIds }, "articleIds", BATCH_SIZE),
                listChapters({ chapterIds }, "chapterIds", BATCH_SIZE),
                listPublications(
                    { publicationIds },
                    "publicationIds",
                    BATCH_SIZE
                ),
                listSections({ sectionIds }, "sectionIds", BATCH_SIZE),
                listUsers({ ids: userIds }, "ids", BATCH_SIZE),
            ]);

            return {
                annexes: getAnnexResult.resultObjects ?? [],
                articles: getArticleResult.resultObjects ?? [],
                chapters: getChapterResult.resultObjects ?? [],
                publications: getPublicationResult.resultObjects ?? [],
                sections: getSectionsResult.resultObjects ?? [],
                users: getUsersResult.resultObjects ?? [],
            };
        },
        [
            listAnnexes,
            listArticles,
            listChapters,
            listPublications,
            listSections,
            listUsers,
        ]
    );

    const getRecordsAndBuildNavigationProperties = useCallback(
        async (options: UseEnhancedContentAdminOptions) => {
            const enhancedContentsGetResult = await list(options);
            const enhancedContents =
                enhancedContentsGetResult.resultObjects ?? [];
            const rowCount = enhancedContentsGetResult.rowCount;

            const components = await getComponents(enhancedContents);
            const navigationProperties = await getNavigationProperties(
                enhancedContents,
                components
            );

            const withComponents = enhancedContents.map((enhancedContent) =>
                enhancedContent
                    .withComponent(components) // Components must be added first to have ids for mapping.
                    .withAnnex(navigationProperties.annexes)
                    .withArticle(navigationProperties.articles)
                    .withChapter(navigationProperties.chapters)
                    .withPublication(navigationProperties.publications)
                    .withSection(navigationProperties.sections)
                    .withUpdatedBy(navigationProperties.users)
            );

            return ServiceHookResultFactory.successResult(
                List(withComponents),
                rowCount
            );
        },
        [list, getComponents, getNavigationProperties]
    );

    const execute = useCallback(
        async (options: UseEnhancedContentAdminOptions) => {
            setServiceHookResult((prevResult) =>
                ServiceHookResultFactory.loadingResult(
                    List<EnhancedContentRecord>(),
                    prevResult.rowCount
                )
            );

            try {
                const result = await getRecordsAndBuildNavigationProperties(
                    options
                );
                setServiceHookResult(result);
            } catch (result) {
                ToastManager.error(ERROR_LOADING_ENHANCED_CONTENT);
                setServiceHookResult(
                    ServiceHookResultFactory.errorResult(
                        List<EnhancedContentRecord>(),
                        result
                    )
                );
            }
        },
        [getRecordsAndBuildNavigationProperties, setServiceHookResult]
    );

    const refresh = useCallback(() => execute(options), [options, execute]);

    // #endregion Callbacks

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useDeepCompareEffect(() => {
        execute(options);
    }, [options]);

    // #endregion Side-Effects

    return {
        ...serviceHookResult,
        refresh: refresh,
    };
}

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const _uniqueCreatedByAndUpdatedByIds = (
    records: Array<EnhancedContentRecord>
): Array<number> => {
    return List(records)
        .map((e) => e.getUpdatedOrCreatedById())
        .filter((e): e is number => e != null)
        .toSet() // Unique property values.
        .toArray();
};

// #endregion Private Functions
