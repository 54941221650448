import SectionRecord from "models/view-models/section-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import SectionService from "utilities/services/publications/chapters/sections/section-service";

/**
 * Custom hook for getting Section data based on a Chapter for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param {string | number} publicationId
 * @param {string | number} chapterId
 */
export default function useSections(
    publicationId?: string | number,
    chapterId?: string | number,
    excludeBody?: boolean
): ServiceHookResult<SectionRecord[]> {
    const [sections, setSections] = useState<SectionRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { list: listSectionsApi } = SectionService.useList();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const listSections = async (
            publicationId?: number,
            chapterId?: number
        ) => {
            if (
                NumberUtils.isDefault(publicationId) ||
                NumberUtils.isDefault(chapterId)
            ) {
                return;
            }

            setLoading(true);

            try {
                const response = await listSectionsApi(
                    {
                        publicationId: publicationId,
                        chapterId: chapterId,
                    },
                    { excludeBody: excludeBody ?? false }
                );

                setSections(response.resultObjects ?? []);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        listSections(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(chapterId)
        );
    }, [
        chapterId,
        excludeBody,
        handlePageLoadError,
        listSectionsApi,
        publicationId,
        resetPageErrors,
    ]);

    return {
        errors,
        loaded,
        loading,
        resultObject: sections,
    };
}
