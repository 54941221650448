import { EnvironmentUtils } from "andculturecode-javascript-core";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import FeatureFlag from "molecules/feature-flags/feature-flag";
import React, { useEffect } from "react";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UnhandledXmlConverterProps
    extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-unhandled-xml";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnhandledXmlConverter: React.FC<UnhandledXmlConverterProps> = (
    props: UnhandledXmlConverterProps
) => {
    useEffect(() => {
        EnvironmentUtils.runIfDevelopment(() =>
            console.warn(
                `xml-to-react: Default converter rendered. Unhandled XML tag: <${props.tagName}>`
            )
        );
    }, [props.tagName]);

    const classNames = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(props.className)) {
        classNames.push(props.className!);
    }

    if (StringUtils.hasValue(props.diffchanged)) {
        classNames.push(`-${props.diffchanged}`);
    }

    return (
        <FeatureFlag feature={"showUnhandledXml"}>
            <FeatureFlag.Boolean value={true}>
                <div className={classNames.join(" ")}>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__tag-name`}
                        size={ParagraphSizes.Small}>
                        <label>Warning:</label>
                        Unhandled XML Node
                    </Paragraph>
                    <code className={`${CSS_CLASS_NAME}__xml-tag-preview`}>
                        {renderXmlOpeningTag(props)}
                    </code>
                    <div className={`${CSS_CLASS_NAME}__xml-children`}>
                        {props.children}
                    </div>
                    <code className={`${CSS_CLASS_NAME}__xml-tag-preview`}>
                        <div>{`</${props.tagName}>`}</div>
                    </code>
                </div>
            </FeatureFlag.Boolean>
        </FeatureFlag>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const renderXmlOpeningTag = (props: UnhandledXmlConverterProps) => {
    const propsAsAny: any = { ...props };
    const items: React.ReactNodeArray = [`<${props.tagName}`];

    for (const key of Object.keys(propsAsAny)) {
        const value = propsAsAny[key];
        if (value == null || key === "children" || key === "tagName") {
            // props.tagName will be the same text as the actual tag rendered
            // no need to render it as a prop definition as well
            continue;
        }

        items.push(<br />);
        items.push(
            <span className={`${CSS_CLASS_NAME}__xml-tag-preview__prop`}>
                {key}={`"${value?.toString()?.trim() ?? ""}"`}
            </span>
        );
    }
    items.push(">");

    let classModifier = "";
    if (items.length === 4) {
        // if only one prop (items is "<tagName", <br/>, property, ">"),
        // strip line breaks
        items.splice(1, 1);
        classModifier = "-no-indent-props";
    }

    return <div className={classModifier}>{items}</div>;
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UnhandledXmlConverter;

// #endregion Exports
