import { useState } from "react";

/**
 * Bundling loading state logic used across other hooks
 */
export default function useLoadResourceStatus(
    initialLoading: boolean = false,
    initialLoaded: boolean = true
) {
    const [loading, setLoading] = useState<boolean>(initialLoading);
    const [loaded, setLoaded] = useState<boolean>(initialLoaded);

    return {
        loading,
        loaded,
        setLoading,
        setLoaded,
    };
}
