import React from "react";
import Modal, { ModalProps } from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import Heading from "atoms/typography/heading";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface MessageModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog" | "label"> {
    children: any;
    heading: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-message-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const MessageModal: React.FunctionComponent<MessageModalProps> = (
    props: MessageModalProps
) => {
    const { children, closeDialog, heading, isVisible, label } = props;

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={label}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <Heading priority={2}>{heading}</Heading>
                <Button
                    accessibleText="Close Dialog"
                    cssClassName="-modal-close -icon"
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <div className={`${CSS_BASE_CLASS_NAME}__container`}>
                    {children}
                </div>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <div className={`${CSS_BASE_CLASS_NAME}__container`}>
                    <Button
                        onClick={closeDialog}
                        style={ButtonStyles.Secondary}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default MessageModal;

// #endregion Export
