import { Record } from "immutable";
import type { SolutionResource } from "models/interfaces/situational-navigation/solutions/solution-resource";
import BaseResourceRecord from "models/view-models/base-resource-record";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SolutionResource =
    RecordUtils.auditableDefaultValuesFactory<SolutionResource>({
        absoluteUrl: undefined,
        absoluteUrlDraft: undefined,
        deletedDraftById: undefined,
        deletedDraftOn: undefined,
        description: "",
        descriptionDraft: "",
        fileDraftId: undefined,
        fileId: undefined,
        publishedById: undefined,
        publishedOn: undefined,
        solutionId: 0,
        title: "",
        titleDraft: "",

        file: undefined,
        fileDraft: undefined,
    });

export default class SolutionResourceRecord
    extends BaseResourceRecord(Record(defaultValues))
    implements SolutionResource
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: SolutionResource) {
        if (params == null) {
            params = Object.assign(defaultValues, params);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SolutionResource>} values
     */
    public with(values: Partial<SolutionResource>): SolutionResourceRecord {
        return new SolutionResourceRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
