import React, { ReactNode } from "react";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import AlertLevels from "constants/alert-levels";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AlertBannerProps {
    alertLevel: AlertLevels;
    children?: ReactNode;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-alert-banner";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AlertBanner: React.FC<AlertBannerProps> = (props: AlertBannerProps) => {
    const className = `${BASE_CLASS} ${BASE_CLASS}__${props.alertLevel}`;

    return (
        <div className={className}>
            <Icon type={Icons.Information} />
            {props.children}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AlertBanner;

// #endregion Exports
