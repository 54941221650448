import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import RadioButton from "atoms/forms/radio-button-input";
import Trans from "atoms/i18n/trans";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { List } from "immutable";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import EntityLabelsRecord from "models/view-models/entity-labels-record";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import RadioList, { RadioListStyles } from "molecules/radio-lists/radio-list";
import NoCategoriesAvailableModal from "organisms/situational-navigation/categories/no-categories-available-modal";
import React, { useEffect, useRef, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useOnClickOutside from "utilities/hooks/use-onclick-outside";
import CultureResources from "utilities/interfaces/culture-resources";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CategorySelectionModalProps {
    allCategories: List<CategoryRecord>;
    allSelectedOptions: CategoryCollectionRecord;
    categoryType: CategoryType;
    filteredCategories: List<CategoryRecord>;
    isVisible: boolean;
    loadingCategories?: boolean;
    onChange: (newValue?: CategoryRecord) => void;
    onCloseDialog: () => void;
    value: CategoryRecord | undefined;
}

// #endregion Interfaces

const CategorySelectionModal: React.FC<CategorySelectionModalProps> = (
    props: CategorySelectionModalProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------

    const baseClassName = "c-category-selection-modal";

    const {
        allCategories,
        allSelectedOptions,
        categoryType,
        filteredCategories,
        isVisible,
        loadingCategories,
        onChange,
        onCloseDialog,
        value,
    } = props;

    // #endregion Constants

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------
    const [labels, setLabels] = useState(
        new EntityLabelsRecord({
            article: "a",
            singular: "Solution",
            plural: "Solutions",
        })
    );
    const [showSelectedLabel, setShowSelectedLabel] = useState(false);
    const isDesktop = useBreakpoint(Breakpoints.LargeLaptop);

    const modalContentRef = useRef<HTMLDivElement | null>(null);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Localization
    // -----------------------------------------------------------------------------------------
    const { t } = useLocalization<CultureResources>();

    const actionClose = t("close");
    const filteredCategoriesSize = filteredCategories.size;
    const allCategoriesSize = allCategories.size;
    const labelsPlural = labels.plural.toLocaleLowerCase();

    // #endregion Localization

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useOnClickOutside(modalContentRef, props.onCloseDialog, []);

    useEffect(() => {
        if (allSelectedOptions.isEmpty()) {
            setShowSelectedLabel(false);
            return;
        }

        if (
            allSelectedOptions.size === 1 &&
            allSelectedOptions.contains(
                (c: CategoryRecord) => c.type === value?.type
            )
        ) {
            setShowSelectedLabel(false);
            return;
        }

        setShowSelectedLabel(true);
    }, [allSelectedOptions, allCategories, value]);

    useEffect(
        () => setLabels(CategoryUtils.toLabel(categoryType, false, true)),
        [categoryType]
    );

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Private Functions
    // -----------------------------------------------------------------------------------------

    const getSelectedOptionsLabel = (): string =>
        allSelectedOptions
            .excludingType(categoryType)
            .mapToList((c: CategoryRecord) => c.title)
            .join(", ");

    const isCategorySelected = (category: CategoryRecord) =>
        value != null && value.id === category.id;

    const getItemId = (categoryId?: number) =>
        `category-selection-item-${categoryId!}`;

    const handleCategorySelection = (category: CategoryRecord) => {
        if (isCategorySelected(category)) {
            onChange();
            onCloseDialog();
            return;
        }

        onChange(category);
        onCloseDialog();
    };

    // #endregion Private Functions

    // -------------------------------------------------------------------------------------------------
    // #region Component
    // -------------------------------------------------------------------------------------------------

    const selectedOptionsLabel = getSelectedOptionsLabel();

    if (filteredCategories.isEmpty()) {
        return (
            <NoCategoriesAvailableModal
                onClose={onCloseDialog}
                isVisible={isVisible}
            />
        );
    }

    return (
        <Modal
            cssClassName={baseClassName}
            closeDialog={onCloseDialog}
            isVisible={isVisible}
            label={labels.singular}
            transition={
                isDesktop
                    ? ModalTransitions.SlideRight // Not supporting animations for desktop version yet
                    : ModalTransitions.SlideUp
            }
            type={isDesktop ? ModalTypes.Base : ModalTypes.Bottom}
            ref={modalContentRef}
            useDialogOverlay={!isDesktop}>
            <div className={`${baseClassName}__top`}>
                <Heading
                    priority={HeadingPriority.Four}
                    cssClassName={`${baseClassName}__top__label`}>
                    {labels.singular}
                </Heading>
                {
                    // if
                    showSelectedLabel && (
                        <Paragraph
                            cssClassName={`${baseClassName}__top__help-text`}>
                            <Trans i18nKey="sitnav-categorySelectModal-filter-label">
                                Showing&nbsp;
                                <span className="-bold">
                                    {{ filteredCategoriesSize }} of&nbsp;
                                    {{ allCategoriesSize }}
                                    &nbsp;
                                </span>
                                {{ labelsPlural }} filtered by&nbsp;
                                <span className="-bold">
                                    {{ selectedOptionsLabel }}
                                </span>
                                .
                            </Trans>
                        </Paragraph>
                    )
                }
                {
                    // if
                    !showSelectedLabel && (
                        <Paragraph
                            cssClassName={`${baseClassName}__top__help-text`}>
                            <Trans i18nKey="sitnav-categorySelectModal-helptext">
                                Select <strong>one</strong> of the filters below
                                that best describes your situation
                            </Trans>
                        </Paragraph>
                    )
                }
                <Button
                    cssClassName={`${baseClassName}__top__close-btn -small`}
                    onClick={onCloseDialog}
                    style={ButtonStyles.Secondary}>
                    {actionClose}
                </Button>
            </div>
            <div className={`${baseClassName}__bottom`}>
                {!loadingCategories && (
                    <RadioList
                        items={(showSelectedLabel
                            ? filteredCategories
                            : allCategories
                        )
                            .map((c: CategoryRecord) => (
                                <RadioButton
                                    key={c.id}
                                    id={getItemId(c.id)}
                                    cssClassName={`${baseClassName}__bottom__item `}
                                    checked={isCategorySelected(c)}
                                    name={c.title}
                                    label={c.title}
                                    onClick={() => handleCategorySelection(c)}>
                                    {
                                        // if
                                        c.hasDescription() && (
                                            <Paragraph
                                                cssClassName={`${baseClassName}__option-description`}>
                                                {c.description}
                                            </Paragraph>
                                        )
                                    }
                                </RadioButton>
                            ))
                            .toArray()}
                        style={RadioListStyles.Button}
                    />
                )}
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategorySelectionModal;

// #endregion Exports
