import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import MenuButton from "molecules/menu-button/menu-button";
import ReferencePanelDropdownMenuItem from "molecules/reference-panel/reference-panel-dropdown-menu-item";
import React from "react";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import Button from "atoms/buttons/button";
import useModalActions from "utilities/hooks/use-modal-actions";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import { GoogleAnalyticsIds } from "constants/google-analytics-ids";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ReferencePanelDropdownMenuProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-reference-panel-dropdown-menu";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReferencePanelDropdownMenu: React.FC<ReferencePanelDropdownMenuProps> =
    () => {
        const {
            currentUserReferenceId,
            handleSelect,
            handleRemoveAll,
            userReferences,
        } = useReferencePanelContext();

        const { t } = useLocalization<CultureResources>();
        const {
            isOpen: isConfirmModalOpen,
            handleOpen,
            handleClose,
        } = useModalActions();

        const count = userReferences.count();
        // View N References
        const buttonSrOnly = t("viewItem", {
            item: `${userReferences.count()} ${t("reference_plural", {
                count: userReferences.count(),
            })}`,
        });

        const clearAllReferences = t("clearAllReferences");
        const clearAllConfirmButtonText = t("yesAction", {
            action: t("clearAll"),
        });

        const handleConfirmDelete = () => {
            handleRemoveAll();
            handleClose();
        };

        const confirmationModalMessage = t(
            "clearAllReferences-confirmationMessage"
        );

        return (
            <div className={BASE_CLASS_NAME}>
                <MenuButton
                    buttonAccessibleText={buttonSrOnly}
                    icon={Icons.Menu}
                    iconSize={IconSizes.Medium}
                    labelOrText={count}
                    modalClassName={`${BASE_CLASS_NAME}__modal`}
                    triggerButtonStyle={ButtonStyles.Secondary}>
                    {userReferences.map((e) => (
                        <ReferencePanelDropdownMenuItem
                            onClick={() => handleSelect(e.id)}
                            selected={currentUserReferenceId === e.id}
                            userReference={e}
                        />
                    ))}
                    <Button
                        accessibleText={clearAllReferences}
                        id={GoogleAnalyticsIds.REFERENCE_PANEL_CLEAR_ALL_BUTTON}
                        onClick={handleOpen}
                        style={ButtonStyles.None}>
                        {clearAllReferences}
                    </Button>
                </MenuButton>
                {isConfirmModalOpen && (
                    <ConfirmationModal
                        confirmButtonText={clearAllConfirmButtonText}
                        isVisible={true}
                        label={t("clearAllReferences_question")}
                        message={confirmationModalMessage}
                        onCancel={handleClose}
                        onConfirm={handleConfirmDelete}
                    />
                )}
            </div>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ReferencePanelDropdownMenu;

// #endregion Exports
