import { KeyboardConstants } from "constants/keyboard-constants";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { NestedRoutes } from "utilities/routing/nested-route";
import { v4 } from "uuid";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import useTabArrows from "utilities/hooks/use-tab-arrows";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface RouteTab {
    route: string;
    label: string;
}

interface RouteTabsProps {
    /**
     * When the route changes, if true, the tab will be focused.
     * @default true
     */
    focusOnRouteChange?: (oldRoute: string, newRoute: string) => boolean;

    /**
     * Passed to <NestedRoutes/>
     * @default true
     */
    redirectIfNotFound?: boolean;

    /**
     * Passed to <NestedRoutes /> in order to render the corresponding tab panel content for the given route
     */
    routes: RouteDefinition[];

    /**
     * Values to render as tabs. Note: This must be memoized to prevent RouteTabs from stealing focus
     * @example const tabs = useMemo(() => [route: "/mypage", label:"My Page"],[])
     */
    tabs: RouteTab[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-route-tabs";
const PANEL_ID = `panel-${v4()}`;

// #endregion Constants

/**
 * Displays an accessible list of horizontal tabs that correspond to routes. The content
 * rendered in the active tab panel corresponds to the nested route that it matches.
 * @param props
 */
const RouteTabs: React.FunctionComponent<RouteTabsProps> = (props) => {
    const { focusOnRouteChange, routes, tabs } = props;
    const location = useLocation();
    const history = useHistory();

    const [current, setCurrent] = useState(
        tabs.findIndex((t) => location.pathname.startsWith(t.route)) ?? 0
    );

    const [previousRoute, setPreviousRoute] = useState(location.pathname);
    const refArray = useMemo(() => {
        return tabs.map(() => createRef<HTMLAnchorElement>());
    }, [tabs]);

    const {
        setArrowVisibility,
        scrollLeft,
        scrollRight,
        tabListRef,
        leftButtonRef: leftArrowRef,
        rightButtonRef: rightArrowRef,
        showLeftArrow,
        showRightArrow,
    } = useTabArrows();

    // Update the route based on which tab is "current" from keyboard or click input
    useEffect(() => {
        if (
            refArray[current] == null ||
            location.pathname.startsWith(tabs[current].route)
        ) {
            return;
        }

        history.push(tabs[current].route);
    }, [current, tabs, history, location.pathname, refArray]);

    // Update focus of the current tab when the route changes
    useEffect(() => {
        if (location.pathname === previousRoute) {
            return;
        }

        const shouldFocus =
            focusOnRouteChange?.(previousRoute, location.pathname) ?? true;
        setPreviousRoute(location.pathname);
        if (
            !shouldFocus ||
            !location.pathname.startsWith(tabs[current]?.route)
        ) {
            return;
        }

        refArray[current].current?.focus();
    }, [
        location.pathname,
        current,
        refArray,
        tabs,
        focusOnRouteChange,
        previousRoute,
    ]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (
            e.key === KeyboardConstants.RightArrow &&
            current === refArray.length - 1
        ) {
            e.preventDefault();
            setCurrent(0);
            return;
        }

        if (e.key === KeyboardConstants.LeftArrow && current === 0) {
            e.preventDefault();
            setCurrent(refArray.length - 1);
            return;
        }

        if (
            e.key === KeyboardConstants.RightArrow &&
            current !== refArray.length - 1
        ) {
            e.preventDefault();

            setCurrent(current + 1);
            return;
        }

        if (e.key === KeyboardConstants.LeftArrow && current !== 0) {
            e.preventDefault();

            setCurrent(current - 1);
            return;
        }
    };

    const redirectIfNotFound = props.redirectIfNotFound ?? true;

    return (
        <React.Fragment>
            <div className={CSS_BASE_CLASS}>
                <div
                    className={`${CSS_BASE_CLASS}__list-wrapper`}
                    onScroll={() => setArrowVisibility()}>
                    <Button
                        onClick={scrollLeft}
                        style={ButtonStyles.None}
                        cssClassName={`${CSS_BASE_CLASS}__list-wrapper__scroll`}
                        hidden={!showLeftArrow}
                        ref={leftArrowRef}>
                        <Icon size={IconSizes.Base} type={Icons.ChevronLeft} />
                    </Button>
                    <Button
                        onClick={scrollRight}
                        style={ButtonStyles.None}
                        cssClassName={`${CSS_BASE_CLASS}__list-wrapper__scroll -right`}
                        hidden={!showRightArrow}
                        ref={rightArrowRef}>
                        <Icon size={IconSizes.Base} type={Icons.ChevronRight} />
                    </Button>
                    <div
                        aria-orientation="horizontal"
                        className={`${CSS_BASE_CLASS}__list`}
                        ref={tabListRef}
                        role="tablist">
                        {tabs.map((t: RouteTab, index: number) => {
                            const isActive = location.pathname.startsWith(
                                t.route
                            );
                            return (
                                <Link
                                    aria-selected={isActive}
                                    aria-controls={PANEL_ID}
                                    className={`${CSS_BASE_CLASS}__tab ${
                                        isActive ? "-active" : ""
                                    }`}
                                    id={t.route}
                                    key={t.route}
                                    onClick={(e) => setCurrent(index)}
                                    onKeyDown={handleKeyDown}
                                    ref={refArray[index]}
                                    role="tab"
                                    tabIndex={isActive ? 0 : -1}
                                    to={t.route}>
                                    {t.label}
                                    <div className={"-active-border"} />
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div
                aria-labelledby={tabs[current]?.route}
                className={`${CSS_BASE_CLASS}__panels`}
                id={PANEL_ID}
                role="tabpanel">
                <NestedRoutes
                    redirectIfNotFound={redirectIfNotFound}
                    routes={routes}
                />
            </div>
        </React.Fragment>
    );
};

export default RouteTabs;
