import "assets/scss/app.scss";
import "lazysizes";
import PrivacyBanner from "organisms/banners/privacy-banner";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AnnouncementContextProvider from "utilities/contexts/announcement-context-provider";
import { ToastDefaultPosition } from "utilities/toast/toast-manager";
import AppRoutes from "./app-routes";
import useAuthentication from "./use-authentication";
import UserConfigurationRedirects from "./utilities/routing/user-configuration-redirects";
import SystemIssuesBanner from "organisms/banners/system-issues-banner";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

// At this point the settings for the app have been loaded (featureFlags, etc.)
// Let's try to log them in
const ConfiguredApp = () => {
    const { loginAttemptCompleted } = useAuthentication();
    const { useOneTrustPrivacyPolicyBanner } = useFeatureFlags();

    if (!loginAttemptCompleted)
        return <FullScreenTransition transitionText={""} />;

    return (
        <AnnouncementContextProvider>
            <SystemIssuesBanner />
            {!useOneTrustPrivacyPolicyBanner && <PrivacyBanner />}
            <UserConfigurationRedirects />
            <AppRoutes />
            <ToastContainer
                draggable={false}
                position={ToastDefaultPosition}
                autoClose={5000}
                closeOnClick={true}
                closeButton={false}
                transition={Zoom}
                toastClassName="c-toast"
            />
        </AnnouncementContextProvider>
    );
};

export default ConfiguredApp;
