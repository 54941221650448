import { DependencyList, Ref, RefObject, useEffect } from "react";

export default function useComponentHeightChange(
    ref: Ref<HTMLElement>,
    index?: number,
    onHeightChanged?: ((index: number, height: number) => void) | undefined,
    deps?: DependencyList
) {
    useEffect(
        function handleComponentHeightChange() {
            const height = (ref as RefObject<HTMLElement>)?.current
                ?.clientHeight;
            if (index == null || height == null) {
                return;
            }

            onHeightChanged?.(index, height);
        },
        [onHeightChanged, ref, index, deps]
    );
}
