import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import React from "react";
import { useLocation } from "react-router-dom";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import {
    getAnnexIdFromRoute,
    getAnnexNfpaLabelFromRoute,
    getChapterIdFromRoute,
    getChapterNfpaLabelFromRoute,
} from "utilities/bookview-utils";
import ExpandableMetaNavChapter from "organisms/expandable-meta-nav/expandable-meta-nav-chapter";
import ExpandableMetaNavAnnexes from "organisms/expandable-meta-nav/expandable-meta-nav-annexes";
import useAdminPreview from "utilities/hooks/use-admin-preview";

interface ExpandableMetaNavProps {}

const ExpandableMetaNav: React.FunctionComponent<ExpandableMetaNavProps> = (
    props: ExpandableMetaNavProps
) => {
    const { tableOfContents, loading: tocIsLoading } =
        useBookviewTableOfContents();

    const { chapters, annexes } = tableOfContents;

    const { publication, loaded: publicationIsLoaded } =
        useCurrentPublication();

    const { pathname } = useLocation();

    const { isAdminPreview } = useAdminPreview();

    const currentChapterOrAnnexInRoute = isAdminPreview
        ? getChapterIdFromRoute(pathname) ?? getAnnexIdFromRoute(pathname)
        : getChapterNfpaLabelFromRoute(pathname) ??
          getAnnexNfpaLabelFromRoute(pathname);

    return (
        <>
            {tocIsLoading && !publicationIsLoaded ? (
                <Loader type={LoaderStyles.LinkFull} />
            ) : (
                <>
                    {chapters?.map((chapter) => (
                        <ExpandableMetaNavChapter
                            key={chapter.id}
                            isCurrentActivePanel={
                                chapter.nfpaLabel ===
                                    currentChapterOrAnnexInRoute ||
                                chapter.id === currentChapterOrAnnexInRoute
                            }
                            chapter={chapter}
                            locale={publication.locale}
                            publicationId={publication.id!}
                        />
                    ))}
                    {annexes && (
                        <ExpandableMetaNavAnnexes
                            annexes={annexes}
                            locale={publication.locale}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ExpandableMetaNav;
