import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import RemoteImage from "atoms/images/remote-image";
import Paragraph from "atoms/typography/paragraph";
import FileRecord from "models/view-models/file-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import React, { useEffect, useState } from "react";
import FileService from "utilities/services/file-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { LoaderStyles } from "atoms/constants/loader-styles";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationRelatedSituationCardProps {
    accessibleText: string;
    children?: any;
    situation: SituationRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const LOAD_IMAGE_ERROR = "There was an issue loading this image.";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationRelatedSituationCard: React.FC<SituationRelatedSituationCardProps> =
    (props: SituationRelatedSituationCardProps) => {
        const CSS_BASE_CLASS = "c-situation-related-situation-card";

        const { get: getFileApi } = FileService.useGet();

        const [imageFile, setImageFile] = useState<FileRecord>();

        useEffect(() => {
            if (props.situation.hotSpotImageFileId == null) {
                return;
            }

            const loadImage = async () => {
                try {
                    const result = await getFileApi({
                        id: props.situation.hotSpotImageFileId!,
                    });
                    setImageFile(result.resultObject);
                } catch (e) {
                    ToastManager.error(LOAD_IMAGE_ERROR);
                }
            };

            loadImage();
        }, [props.situation, getFileApi]);

        return (
            <Anchor
                cssClassName={CSS_BASE_CLASS}
                to={props.situation.getRoute()}>
                <div className={`${CSS_BASE_CLASS}__image`}>
                    {props.children}
                    {imageFile == null && <Icon type={Icons.List} />}
                    {imageFile != null && (
                        <RemoteImage
                            file={imageFile}
                            loaderType={LoaderStyles.LinkGlyphGray}
                        />
                    )}
                </div>
                <div className={`${CSS_BASE_CLASS}__content`}>
                    <Icon type={Icons.Sitnav} />
                    <Paragraph size={ParagraphSizes.Small}>
                        {props.situation.title}
                    </Paragraph>
                </div>
            </Anchor>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationRelatedSituationCard;

// #endregion Exports
