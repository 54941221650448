import { Record } from "immutable";
import EmailValidation from "models/interfaces/email-validation";
import { t } from "utilities/localization-utils";

const defaultValues: EmailValidation = {
    errorMessage: "",
    isValid: true,
};

export default class EmailValidationRecord
    extends Record(defaultValues)
    implements EmailValidation
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<EmailValidation>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Set EmailValidationRecord to Invalid with "Email required" error message.
     */
    public setToInvalid(): EmailValidationRecord {
        return this.with({
            errorMessage: t("email-required-field"),
            isValid: false,
        });
    }

    /**
     * Set EmailValidationRecord to valid.
     */
    public setToValid(): EmailValidationRecord {
        return this.with(defaultValues);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<EmailValidationRecord>} values
     */
    public with(values: Partial<EmailValidationRecord>): EmailValidationRecord {
        return new EmailValidationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
