import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import HitRecord from "models/view-models/search/hit-record";
import * as React from "react";
import { useCallback } from "react";
import StringUtils from "utilities/string-utils";
import { AriaRole } from "utilities/enumerations/aria-role";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";
import { useLocalization } from "utilities/hooks/use-localization";
import EnhancedContentBadge from "molecules/badges/enhanced-content-badge";
import useEnhancedContentSearchHit from "utilities/hooks/domain/enhanced-content/use-enhanced-content-search-hit";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchResultCardProps {
    closeDialog?: () => void;
    onClick: (url: string, index: number) => void;
    hit: HitRecord;
    id?: string;
    index: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-search-result-card";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchResultCard: React.FunctionComponent<SearchResultCardProps> = (
    props: SearchResultCardProps
) => {
    const { hit, closeDialog, onClick, index, id } = props;
    const { t } = useLocalization();
    const { setRecord } = useEnhancedContentSearchHit();

    // Pull out all of the ids & relevant data from the HitRecord
    const title = hit.getTitle();
    const body = hit.getBodyExcerpt(window.innerWidth);
    const breadcrumbText = hit.isEnhancedContent()
        ? hit.enhancedContent?.getBreadcrumbText()
        : hit.getBreadcrumbText();
    const route = hit.getRoute();
    const isDirectResult = hit.isSituation() || hit.isSolution();
    const icon = hit.isSituation() ? Icons.Sitnav : Icons.Lightbulb;
    const entityLabel = hit.isSituation() ? t("situation") : t("solution");
    const className = isDirectResult
        ? `${BASE_CLASS_NAME} -direct-result`
        : BASE_CLASS_NAME;

    const handleClick = useCallback(() => {
        if (hit.isEnhancedContent()) {
            setRecord(hit.enhancedContent);
        }
        onClick(route, index);
        closeDialog?.();
    }, [closeDialog, hit, index, onClick, route, setRecord]);

    // If we cannot properly generate the route or breadcrumbs, something must not have come back
    // correctly from the API. Don't display this result card.
    if (
        StringUtils.isEmpty(route) ||
        (hit.isPartOfPublication() && StringUtils.isEmpty(breadcrumbText))
    ) {
        return null;
    }

    return (
        <div className={className} id={id} role={AriaRole.ListItem}>
            <Anchor onClick={handleClick} to={route}>
                {isDirectResult && (
                    <Paragraph
                        cssClassName={`${BASE_CLASS_NAME}__entity-type -${hit.entityType?.toLowerCase()}`}>
                        <Icon size={IconSizes.Base} type={icon} /> {entityLabel}
                    </Paragraph>
                )}
                <Paragraph
                    cssClassName={`${BASE_CLASS_NAME}__title`}
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                    size={ParagraphSizes.Base}
                />
                <Paragraph
                    cssClassName={`${BASE_CLASS_NAME}__body`}
                    dangerouslySetInnerHTML={{
                        __html: body,
                    }}
                    size={ParagraphSizes.Small}
                />
                <Paragraph
                    cssClassName={`${BASE_CLASS_NAME}__breadcrumbs`}
                    size={ParagraphSizes.XSmall}>
                    {hit.isEnhancedContent() && <EnhancedContentBadge />}
                    {breadcrumbText}
                </Paragraph>
            </Anchor>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchResultCard;

// #endregion Exports
