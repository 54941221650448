import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import NumberUtils from "utilities/number-utils";
import AdminFreeAccessService from "utilities/services/admin/free-access/admin-free-access-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useAdminFreeAccessContext from "./use-admin-free-access-context";

const useAdminToggleFreeAccessPublications = (refresh: () => void) => {
    const { setIsLoading } = useAdminFreeAccessContext();
    const { update: updateIsPublished } = AdminFreeAccessService.usePublish();

    const toggleIsPublished = async (
        publication: FreeAccessPublicationRecord
    ) => {
        if (NumberUtils.isDefault(publication.id)) {
            return;
        }

        setIsLoading(true);

        const status = publication.isPublished ? "unpublished" : "published";
        const successMessage = `Publication successfully ${status}`;

        try {
            const { resultObject: updatedPublication } =
                await updateIsPublished(publication, {
                    id: publication.id,
                });

            if (!updatedPublication) throw new Error();
            refresh();
            ToastManager.success(successMessage);
        } catch (error) {
            ToastManager.error("There was an error");
        } finally {
            setIsLoading(false);
        }
    };

    return {
        toggleIsPublished,
    };
};

export default useAdminToggleFreeAccessPublications;
