import Button, { ButtonProps } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { ModalTypes } from "molecules/constants/modal-types";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

interface ModalCloseButtonProps extends ButtonProps {
    modalType?: ModalTypes;
}

const defaultProps: Partial<ModalCloseButtonProps> = {
    cssClassName: "-modal-close",
    size: ButtonSizes.Medium,
    style: ButtonStyles.Tertiary,
};

const ModalCloseButton: React.FunctionComponent<ModalCloseButtonProps> = (
    props: ModalCloseButtonProps
) => {
    const { cssClassName, modalType, onClick, size, style } = props;
    const { t } = useLocalization<CultureResources>();
    const accessibleText =
        props.accessibleText ?? t("closeItem", { item: t("dialog") });

    // Return the standard X button style if no modal type is specified
    if (modalType == null) {
        return (
            <Button
                accessibleText={accessibleText}
                cssClassName={`${cssClassName} -icon`}
                onClick={onClick}
                size={size}
                style={style}>
                <Icon type={Icons.Close} size={IconSizes.Large} />
            </Button>
        );
    }
    // Check the modal type requested and use the default styling.
    switch (modalType) {
        case ModalTypes.Bottom:
            return (
                <Button
                    accessibleText={accessibleText}
                    cssClassName={cssClassName}
                    onClick={onClick}
                    size={size}
                    style={style}>
                    {t("close")}
                </Button>
            );

        case ModalTypes.Left:
            return (
                <Button
                    accessibleText={accessibleText}
                    cssClassName={`${cssClassName} -icon`}
                    onClick={onClick}
                    size={size}
                    style={style}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            );

        case ModalTypes.Fullscreen:
        case ModalTypes.Search:
            return (
                <Button
                    accessibleText={accessibleText}
                    cssClassName={`${cssClassName} -icon`}
                    onClick={onClick}
                    size={size}
                    style={style}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            );
    }

    return null;
};

ModalCloseButton.defaultProps = defaultProps;

export default ModalCloseButton;
