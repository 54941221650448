import type { PartRecord } from "internal";
import type ServiceHookResult from "utilities/interfaces/service-hook-result";
import usePageErrors from "utilities/hooks/use-page-errors";
import NumberUtils from "utilities/number-utils";
import { ArticlePartsService } from "internal";

export interface PartsHookOptions {
    publicationId?: string | number;
    chapterId?: string | number;
    articleId?: string | number;
    pending?: boolean;
}
/**
 * Custom hook for getting Part data based on a Chapter and Article for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param {string | number} publicationId
 * @param {string | number} chapterId
 * @param {string | number} articleId
 */
export default function useParts(
    options: PartsHookOptions
): ServiceHookResult<PartRecord[]> {
    const { publicationId, chapterId, articleId } = options;
    const pending = options.pending ?? false;
    const disabled =
        NumberUtils.isDefault(publicationId) ||
        NumberUtils.isDefault(chapterId) ||
        NumberUtils.isDefault(articleId);

    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    const { list: partsQuery } = ArticlePartsService.default.useListQuery();

    const partsResult = partsQuery(
        {
            articleId: Number(articleId),
            chapterId: Number(chapterId),
            publicationId: Number(publicationId),
        },
        {}, // no query params
        {
            enabled: !pending && !disabled,
            onError: (error) => {
                handlePageLoadError(error);
            },
            onSuccess: () => {
                resetPageErrors();
            },
        }
    );

    return {
        errors,
        loaded: partsResult.isSuccess || partsResult.isError,
        loading: partsResult.isFetching,
        resultObject: partsResult.data?.resultObjects ?? [],
    };
}
