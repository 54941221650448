import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import { t } from "utilities/localization-utils";

interface CaptchaErrorsProps {
    showCaptchaErrors: boolean;
}
const CaptchaErrors = (props: CaptchaErrorsProps) => {
    const { showCaptchaErrors } = props;
    return (
        <div className="c-captcha-errors">
            {showCaptchaErrors && (
                <label>
                    <Icon type={Icons.Information} />
                    {t("propertyIsRequired", {
                        name: "reCAPTCHA",
                    })}
                </label>
            )}
        </div>
    );
};

export default CaptchaErrors;
