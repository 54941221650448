import React from "react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Anchor from "atoms/anchors/anchor";
import Heading from "atoms/typography/heading";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import StringUtils from "utilities/string-utils";

interface TableOfContentsCollapsePanelHeaderProps
    extends Pick<DataTestAttributes, "dataTestId" | "dataTestDisplaySequence"> {
    displayTitle: string;
    isPublic?: boolean;
    to: string;
    headerCssClass?: string;
}

const TableOfContentsCollapsePanelHeader: React.FunctionComponent<TableOfContentsCollapsePanelHeaderProps> =
    (props) => {
        const {
            dataTestDisplaySequence,
            dataTestId,
            displayTitle,
            isPublic = false,
            to,
            headerCssClass = "",
        } = props;

        const cssBaseClass = "c-table-of-contents-chapter__heading";
        const cssPublicModifier = "-public";
        const cssClassName = isPublic
            ? StringUtils.joinClassNames([cssBaseClass, cssPublicModifier])
            : cssBaseClass;

        if (isPublic) {
            return (
                <Heading
                    cssClassName={cssClassName}
                    dataTestDisplaySequence={dataTestDisplaySequence}
                    dataTestId={dataTestId}
                    priority={HeadingPriority.Two}>
                    {displayTitle}
                </Heading>
            );
        }

        return (
            <Anchor
                cssClassName={cssClassName}
                dataTestDisplaySequence={dataTestDisplaySequence}
                dataTestId={dataTestId}
                to={to}>
                <Heading
                    cssClassName={headerCssClass}
                    priority={HeadingPriority.Two}>
                    {displayTitle}
                </Heading>
            </Anchor>
        );
    };

export default TableOfContentsCollapsePanelHeader;
