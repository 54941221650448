import { useContext } from "react";
import {
    NetworkState,
    NetworkInformationContext,
} from "utilities/contexts/network-information/network-information-context";

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

const useNetworkInformation = (): NetworkState => {
    const networkState = useContext(NetworkInformationContext);

    return networkState;
};

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useNetworkInformation;

// #endregion Exports
