import React, { useState } from "react";
import videoScreenshot from "assets/images/link-video-screenshot.png";
import publicationsGroup from "assets/images/link-publications-group.png";
import Image from "atoms/images/image";
import LandingPageSectionWrapper, {
    LandingPageSectionIds,
} from "./landing-page-section-wrapper";
import Anchor from "atoms/anchors/anchor";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import VideoModal from "organisms/modals/video-modal/video-modal";
import Icon from "atoms/icons/icon";
import Button from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { siteMap } from "internal-sitemap";
import { PublicAssets } from "constants/public-assets";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { t } from "utilities/localization-utils";
import { AppNameTm } from "constants/app-name-tm";
import Trans from "atoms/i18n/trans";

interface LandingPageSpotlightsSectionProps {}

const BASE_CSS_CLASSNAME = "c-landing-page-spotlights-section";
const CSS_TEXT_CLASSNAME = "__card__text-panel";

const LandingPageSpotlightsSection = (
    props: LandingPageSpotlightsSectionProps
) => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const OFFLINE_VIDEO_ID = "mLUbW1I5FxU";

    const spotlightVideo = {
        title: "Welcome to LiNK",
        src: PublicAssets.LinkSizzleVideo,
    };
    const handleVideoOpen = () => {
        setIsVideoOpen(true);
    };
    const handleVideoClose = () => {
        setIsVideoOpen(false);
    };

    return (
        <LandingPageSectionWrapper id={LandingPageSectionIds.Spotlights}>
            <div className={`${BASE_CSS_CLASSNAME}`}>
                <div className={`${BASE_CSS_CLASSNAME}__card`}>
                    <div className={`${BASE_CSS_CLASSNAME}__card__container`}>
                        <Image
                            cssClassName={`${BASE_CSS_CLASSNAME}__card__image`}
                            src={publicationsGroup}
                        />
                    </div>
                    <div
                        className={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}`}>
                        <Heading
                            priority={HeadingPriority.Three}
                            cssClassName={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}__heading`}>
                            {t("spotlightsNewPublicationsTitle")}
                        </Heading>
                        <Trans i18nKey="spotlightsNewPublicationsBody">
                            <Paragraph
                                cssClassName={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}__subtext`}>
                                <span>
                                    {AppNameTm} subscribers have access to over
                                    1,500 NFPA codes and standards. This
                                    includes all current editions, select titles
                                    in Spanish, as well as a library of legacy
                                    titles going back five editions. Subscribers
                                    also get early access to newly released
                                    editions before the printed book is
                                    available for purchase.
                                </span>
                            </Paragraph>
                        </Trans>
                    </div>
                    <Anchor
                        cssClassName={`${BASE_CSS_CLASSNAME}__button-cta c-button`}
                        to={siteMap.publications.public.all}>
                        {t("browsePublications")}
                    </Anchor>
                </div>
                <div className={`${BASE_CSS_CLASSNAME}__card`}>
                    <div className={`${BASE_CSS_CLASSNAME}__card__container`}>
                        <Image
                            cssClassName={`${BASE_CSS_CLASSNAME}__card__image`}
                            src={videoScreenshot}
                        />
                        <div
                            className={`${BASE_CSS_CLASSNAME}__card__container__box`}
                        />
                        <Button
                            style={ButtonStyles.Text}
                            cssClassName={`${BASE_CSS_CLASSNAME}__card__container__icon-wrapper`}
                            onClick={handleVideoOpen}
                            accessibleText={t("playVideo")}>
                            <Icon
                                cssClassName={`${BASE_CSS_CLASSNAME}__card__container__icon`}
                                type={Icons.MediaPlayCircle}
                            />
                        </Button>
                    </div>
                    <div
                        className={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}`}>
                        <Heading
                            priority={HeadingPriority.Three}
                            cssClassName={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}__heading`}>
                            {t("spotlightsOfflineModeTitle")}
                        </Heading>
                        <Trans i18nKey="spotlightsOfflineModeBody">
                            <Paragraph
                                cssClassName={`${BASE_CSS_CLASSNAME}${CSS_TEXT_CLASSNAME}__subtext`}>
                                <span>
                                    Now you can get the job done even if you
                                    don't have internet access. The Offline Mode
                                    feature in {AppNameTm} lets you access your
                                    content wherever you are. Simply store
                                    relevant publications to your device so
                                    they're available when you need them,
                                    anytime, anywhere. This new feature allows
                                    you to store your most used publications to
                                    your device, including your bookmarks and
                                    notes.
                                </span>
                            </Paragraph>
                        </Trans>
                    </div>
                    <Button
                        cssClassName={`${BASE_CSS_CLASSNAME}__button-cta c-button`}
                        onClick={handleVideoOpen}>
                        {t("watchVideo")}
                    </Button>
                </div>
                <VideoModal
                    autoplay={true}
                    handleClose={handleVideoClose}
                    isOpen={isVideoOpen}
                    title={spotlightVideo.title}
                    videoId={OFFLINE_VIDEO_ID}
                />
            </div>
        </LandingPageSectionWrapper>
    );
};

export default LandingPageSpotlightsSection;
