import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import CompactPageNavigationMenu from "molecules/page-navigation/compact-page-navigation-menu";
import React from "react";
import { t } from "utilities/localization-utils";

interface ImageViewerFooterProps {
    currentPageNumber: number;
    setCurrentPageNumber: (value: number) => void;
    totalPages: number;
    onZoomIn: any;
    onZoomOut: any;
    onFooterPageChange: (value: number) => void;
}

const CSS_BASE_CLASS = "c-image-viewer__footer";

const ImageViewerFooter = (props: ImageViewerFooterProps) => {
    const {
        currentPageNumber,
        setCurrentPageNumber,
        totalPages,
        onZoomIn,
        onZoomOut,
        onFooterPageChange,
    } = props;

    return (
        <div className={CSS_BASE_CLASS}>
            <CompactPageNavigationMenu
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
                totalPages={totalPages}
                onFooterPageChange={onFooterPageChange}
            />
            <div>
                <Button
                    onClick={onZoomOut}
                    accessibleText={t("zoomOut")}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Icon size={IconSizes.Large} type={Icons.ZoomOut} />
                </Button>
                <Button
                    onClick={onZoomIn}
                    accessibleText={t("zoomIn")}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Icon size={IconSizes.Large} type={Icons.ZoomIn} />
                </Button>
            </div>
        </div>
    );
};

export default ImageViewerFooter;
