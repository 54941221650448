import React, { forwardRef } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Button from "atoms/buttons/button";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentEditActionProps {
    onClick?: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentEditAction = forwardRef<
    HTMLButtonElement,
    EnhancedContentEditActionProps
>((props, ref) => {
    const { onClick } = props;
    const { globalState } = useGlobalState();

    const canEdit =
        globalState.currentIdentity?.isAdmin() ||
        globalState.currentIdentity?.isAuthorOrPublisher();

    return (
        <Button disabled={!canEdit} onClick={onClick}>
            Edit Content
        </Button>
    );
});

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentEditAction;

// #endregion Exports
