import Publication from "models/interfaces/publication";
import PublicationRecord from "models/view-models/publication-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import { ReactQueryUtils } from "utilities/react-query-utils";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import FileService from "utilities/services/file-service";
import PublicationService from "utilities/services/publications/publication-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationQueryOptions {
    code?: string;
    edition?: string;
    id?: number;
    isAdmin?: boolean;
    includeProperties?: Array<keyof Publication>;
}

// #endregion Interfaces

export default function usePublicationQuery(
    options: PublicationQueryOptions
): ServiceHookResult<PublicationRecord> {
    // -----------------------------------------------------------------------------------------
    // #region Private Members
    // -----------------------------------------------------------------------------------------
    const { code, edition, id, includeProperties } = options;
    const isAdmin = options.isAdmin ?? false;

    const listPublicationsEnabled = !isAdmin && code != null && edition != null;
    const getPublicationEnabled = !isAdmin && !NumberUtils.isDefault(id);
    const getAdminEnabled = isAdmin && !NumberUtils.isDefault(id);

    const { get: getAdmin } = AdminPublicationService.useGetQuery();
    const { get: getFile } = FileService.useGetQuery();
    const { get: getPublication } = PublicationService.useGetQuery();

    const { list: listPublications } = PublicationService.useListQuery();

    // #endregion Private Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------
    // #region Publication
    // -----------------------------------------------------------------------------------------

    const publicationListResult = listPublications(
        { code, edition },
        { enabled: listPublicationsEnabled }
    );

    const publicationGetResult = getPublication({ id: id! }, undefined, {
        enabled: getPublicationEnabled,
    });

    const adminPublicationGetResult = getAdmin({ id: id! }, undefined, {
        enabled: getAdminEnabled,
    });

    let publication =
        publicationGetResult.data?.resultObject ??
        publicationListResult.data?.resultObjects[0];

    // console.log({
    //     code,
    //     edition,
    //     id,
    //     listPublicationsEnabled,
    //     getPublicationEnabled,
    //     getAdminEnabled,
    //     publication,
    // });

    if (isAdmin) {
        publication = adminPublicationGetResult.data?.resultObject;
    }

    // #endregion Publication

    // -----------------------------------------------------------------------------------------
    // #region Cover Image
    // -----------------------------------------------------------------------------------------

    const coverImageFileId = publication?.coverImageFileId;

    const coverImageEnabled =
        includeProperties?.includes("coverImageFile") === true &&
        !NumberUtils.isDefault(coverImageFileId);

    const coverImageFileResult = getFile({ id: coverImageFileId! }, undefined, {
        enabled: coverImageEnabled,
    });

    const coverImageFile = coverImageFileResult.data?.resultObject;

    // #endregion Cover Image

    // -----------------------------------------------------------------------------------------
    // #region ContentSourceFile
    // -----------------------------------------------------------------------------------------

    const contentSourceFileId = publication?.contentSourceFileId;

    const contentSourceFileEnabled =
        includeProperties?.includes("contentSourceFile") === true &&
        !NumberUtils.isDefault(contentSourceFileId);

    const contentSourceFileResult = getFile(
        { id: contentSourceFileId! },
        undefined,
        { enabled: contentSourceFileEnabled }
    );

    const contentSourceFile = contentSourceFileResult.data?.resultObject;

    // #endregion ContentSourceFile

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const resultObject =
        publication?.with({
            coverImageFile,
            contentSourceFile,
            color: publication.getColorOrDefault(),
        }) ?? new PublicationRecord();

    const allResults = [
        publicationListResult,
        publicationGetResult,
        adminPublicationGetResult,
        coverImageFileResult,
        contentSourceFileResult,
    ];

    const loading = ReactQueryUtils.isLoading(...allResults);
    const loaded = ReactQueryUtils.isLoaded(...allResults);
    const errors = ReactQueryUtils.getErrors(...allResults);

    return { resultObject, loading, loaded, errors };

    // #endregion Public Properties
}
