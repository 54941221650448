import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import VideoModal from "organisms/modals/video-modal/video-modal";
import React, { useState } from "react";
import { t } from "utilities/localization-utils";
import { AppNameTm } from "constants/app-name-tm";
import { PublicAssets } from "constants/public-assets";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import Trans from "atoms/i18n/trans";

interface LandingPageHeroProps {}

const BASE_CSS_CLASSNAME = "c-landing-page-hero";

const LandingPageHero = (props: LandingPageHeroProps) => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const spotlightVideo = {
        title: "Welcome to LiNK",
        src: PublicAssets.LinkSizzleVideo,
        poster: PublicAssets.LinkSizzleVideoLoadingImg,
    };

    const handleVideoOpen = () => {
        setIsVideoOpen(true);
    };
    const handleVideoClose = () => {
        setIsVideoOpen(false);
    };

    // Translate

    return (
        <section className={BASE_CSS_CLASSNAME}>
            <div className={`${BASE_CSS_CLASSNAME}__text-panel`}>
                <div className={`${BASE_CSS_CLASSNAME}__text-panel__content`}>
                    <Trans i18nKey="landingPageTitle">
                        <Paragraph
                            cssClassName={`${BASE_CSS_CLASSNAME}__heading`}>
                            <span
                                className={`${BASE_CSS_CLASSNAME}__heading-text`}>
                                {AppNameTm} puts our trusted knowledge base at
                                your fingertips.{" "}
                            </span>
                        </Paragraph>
                    </Trans>
                    <Trans i18nKey="landingPageContent">
                        <span className={`${BASE_CSS_CLASSNAME}__list`}>
                            Whether you’re an individual, a team, or a large
                            enterprise, {AppNameTm} provides instant access —
                            via your favorite device — to NFPA® codes and
                            standards, plus exclusive expert commentary, visual
                            aids, and more.
                        </span>
                    </Trans>
                    <div className={`${BASE_CSS_CLASSNAME}__cta-wrapper`}>
                        <PlansAnchor
                            cssClassName={`${BASE_CSS_CLASSNAME}__button-cta c-button -secondary`}
                        />
                    </div>
                    <span className={`${BASE_CSS_CLASSNAME}__link-login`}>
                        {t("registerOrLogin-signUpQuestion")}
                        <LogInAnchor
                            style={ButtonStyles.None}
                            cssClassName={`${BASE_CSS_CLASSNAME}__link-login-text`}
                        />
                    </span>
                </div>
            </div>
            <div className={`${BASE_CSS_CLASSNAME}__video-panel`}>
                <div className={`${BASE_CSS_CLASSNAME}__video-container`}>
                    <video
                        className={`${BASE_CSS_CLASSNAME}__video`}
                        src={spotlightVideo.src}
                        autoPlay
                        loop
                        muted
                        poster={spotlightVideo.poster}></video>
                </div>
                <Button
                    style={ButtonStyles.Text}
                    cssClassName={`${BASE_CSS_CLASSNAME}__icon-wrapper`}
                    onClick={handleVideoOpen}
                    accessibleText={t("playVideo")}>
                    <Icon
                        cssClassName={`${BASE_CSS_CLASSNAME}__icon`}
                        type={Icons.MediaPlayCircle}
                    />
                </Button>
            </div>
            <VideoModal
                autoplay={true}
                handleClose={handleVideoClose}
                isOpen={isVideoOpen}
                title={spotlightVideo.title}
                fullVideoSrc={spotlightVideo.src}
            />
        </section>
    );
};

export default LandingPageHero;
