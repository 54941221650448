import React, { useCallback } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import { chain } from "lodash";
import useServiceWorkerMessage from "utilities/contexts/service-worker/use-service-worker-messaging";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import useOfflineSync from "utilities/hooks/domain/offline/use-offline-sync";
import BookService from "utilities/services/books/book-service";
import OfflineBooksService from "utilities/services/offline/offline-books-service";
import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import PublicationRecord from "models/view-models/publication-record";
import BookRecord from "models/view-models/book-record";
import { CoreUtils } from "utilities/core-utils";
import OfflineBookListItem from "organisms/offline-book-list/offline-book-list-item";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";
import { PreloadUrlsProgressMessage } from "utilities/service-worker/interfaces/messages/preload-urls-progress-message";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import useOfflineBooks from "utilities/hooks/domain/offline/use-offline-books";
import { CollectionUtils } from "utilities/collection-utils";
import NoResultsBanner from "molecules/banners/no-results-banner";
import { t } from "utilities/localization-utils";
import Loader from "atoms/loaders/loader";
import { OfflineBookDownloadStatus } from "molecules/enums/offline-book-download-status";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-offline-book-list";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineBookList: React.FC = () => {
    const {
        isEnabled: serviceWorkerEnabled,
        isSupported: serviceWorkerSupported,
    } = useServiceWorker();
    const { isOnline } = useNetworkInformation();

    const { resultObject: currentDevice } = useOfflineDevice();

    const {
        refresh: refreshOfflineBooks,
        resultObject: offlineBooks,
        loaded: offlineBooksLoaded,
        loading: offlineBooksLoading,
    } = useOfflineBooks({ offlineDeviceId: currentDevice?.id });

    const { removeOfflineBook, syncOfflineBook } = useOfflineSync();

    const { list: listBooks } = BookService.useListQuery();
    const { data: books } = listBooks();
    const filteredBooks = books?.resultObjects.filter((b) =>
        offlineBooks.some((ob) => ob.bookId === b.id)
    );
    const { resultObject: publications } = usePublications({});

    const filterBookByPublication = (p: PublicationRecord) =>
        CoreUtils.filterByProperties<PublicationRecord, BookRecord>(p, [
            "code",
            "edition",
        ]);
    const findByBookId = (id?: number) => (book: OfflineBookRecord) =>
        book.bookId === id;

    const offlinePublications = publications.filter((p) =>
        filteredBooks?.some(filterBookByPublication(p))
    );
    const { create: createOfflineBook } = OfflineBooksService.useCreate();

    const { events: preloadEvents } = useServiceWorkerMessage(
        ServiceWorkerMessageTypes.PreloadProgress
    );

    const getOfflineBookByBookId = useCallback(
        async (bookId: number): Promise<OfflineBookRecord | undefined> => {
            const existingOfflineSync = offlineBooks.find(findByBookId(bookId));

            if (existingOfflineSync != null) {
                return existingOfflineSync;
            }

            if (currentDevice?.id == null) {
                return undefined;
            }

            const { resultObject: offlineBookRecord } = await createOfflineBook(
                new OfflineBookRecord().with({
                    bookId,
                    includeBookmarks: true,
                    includeEnhancedContent: true,
                    includeExternalReferences: true,
                    offlineDeviceId: currentDevice.id,
                }),
                { offlineDeviceId: currentDevice.id }
            );

            return offlineBookRecord;
        },
        [currentDevice, createOfflineBook, offlineBooks]
    );

    const handleDownloadClick = useCallback(
        async (book?: BookRecord) => {
            if (book?.id == null) {
                return;
            }

            const foundBook = await getOfflineBookByBookId(book.id);
            if (foundBook == null) {
                return;
            }

            await syncOfflineBook(foundBook);

            refreshOfflineBooks();
        },
        [getOfflineBookByBookId, refreshOfflineBooks, syncOfflineBook]
    );

    const handleDeleteClick = async (offlineBook?: OfflineBookRecord) => {
        if (offlineBook?.id != null) {
            await removeOfflineBook(offlineBook.id);
        }

        refreshOfflineBooks();
    };

    if (offlineBooksLoading && !offlineBooksLoaded) {
        return (
            <div className={`${BASE_CLASS}`}>
                <Loader accessibleText={t("loading")} />
            </div>
        );
    }

    if (CollectionUtils.isEmpty(offlinePublications) && offlineBooksLoaded) {
        return (
            <div className={`${BASE_CLASS}`}>
                <NoResultsBanner
                    subtitle={t("offline-tab-empty-banner-subtitle")}
                    title={t("offline-books-empty-banner-title")}
                />
            </div>
        );
    }

    return (
        <div className={`${BASE_CLASS}`}>
            {offlinePublications.map((publication) => {
                const book = filteredBooks?.find(
                    filterBookByPublication(publication)
                );
                const offlineBook = offlineBooks.find(findByBookId(book?.id));

                if (book == null || offlineBook == null) {
                    return null;
                }
                const isDownloading = isDownloadingOfflineBook(
                    publication,
                    preloadEvents
                );

                if (isIncompleteDownload(isDownloading, offlineBook)) {
                    return null;
                }

                const rowIsEnabled =
                    !isDownloading &&
                    serviceWorkerEnabled &&
                    isOnline &&
                    serviceWorkerSupported;

                return (
                    <OfflineBookListItem
                        book={book}
                        isDownloading={isDownloading}
                        isEnabled={rowIsEnabled}
                        key={book.id}
                        offlineBook={offlineBook}
                        onDownloadBook={handleDownloadClick}
                        onRemoveOfflineBook={handleDeleteClick}
                        publication={publication}
                    />
                );
            })}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const isDownloadingOfflineBook = (
    publication: PublicationRecord,
    preloadEvents: Array<TypedMessageEvent<PreloadUrlsProgressMessage>>
): boolean => {
    const { current = 0, total = 0 } =
        chain(preloadEvents)
            .filter(
                (p) =>
                    p.data.code === publication.code &&
                    p.data.edition === publication.edition
            )
            .last()
            .value()?.data ?? {};

    return current < total;
};

const isIncompleteDownload = (
    isDownloading: boolean,
    offlineBook: OfflineBookRecord
) => {
    return (
        !isDownloading &&
        offlineBook.getDownloadStatus() ===
            OfflineBookDownloadStatus.NotDownloaded
    );
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookList;

// #endregion Exports
