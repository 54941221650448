import { QueryObserverResult } from "react-query";
import {
    ServiceResponse,
    ResultRecord,
    RecordUtils,
} from "andculturecode-javascript-core";
import ServiceHookResult from "utilities/interfaces/service-hook-result";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------
const _isLoading = (...values: Array<QueryObserverResult>): boolean =>
    values.some((result: QueryObserverResult) => result.isLoading);
const _isLoaded = (...values: Array<QueryObserverResult>): boolean =>
    values.some(
        (result: QueryObserverResult) => !result.isIdle || result.isFetched
    );
const _getErrors = (
    ...results: Array<
        QueryObserverResult<ServiceResponse<any>, ResultRecord<any>>
    >
): Array<string> => {
    let errors: Array<string> = [];
    results.forEach((result) => {
        if (!result.isError || result.isIdle) {
            return;
        }

        const resultRecord = result?.error;
        if (
            !RecordUtils.isRecord(resultRecord, ResultRecord) ||
            !resultRecord.hasErrors()
        ) {
            return;
        }

        errors.push(...resultRecord.listErrorMessages());
    });

    return errors;
};

const _getResult = (
    ...values: Array<
        QueryObserverResult<ServiceResponse<any>, ResultRecord<any>>
    >
): Omit<ServiceHookResult<any>, "resultObject"> => {
    return {
        loading: _isLoading(...values),
        loaded: _isLoaded(...values),
        errors: _getErrors(...values),
    };
};

// #endregion Functions

export const ReactQueryUtils = {
    isLoading: _isLoading,
    isLoaded: _isLoaded,
    getErrors: _getErrors,
    getResult: _getResult,
};
