import { Resource } from "models/interfaces/resource";
import { ResourceRecord } from "models/interfaces/resource-record";
import { Constructor } from "utilities/types/constructor";

// -----------------------------------------------------------------------------------------
// #region Mixin
// -----------------------------------------------------------------------------------------

/**
 * Mixin class to hold base functionality of Immutable.Record classes that implement the Resource interface
 *
 * @see https://www.typescriptlang.org/docs/handbook/mixins.html
 * @template TBase
 * @param {TBase} Base Base Immutable.Record class to extend from
 * @returns
 */
function BaseResourceRecord<TBase extends Constructor<Resource>>(Base: TBase) {
    return class BaseResourceRecord extends Base implements ResourceRecord {
        // -----------------------------------------------------------------------------------------
        // #region Public Methods
        // -----------------------------------------------------------------------------------------

        /**
         * Returns the absolute url based on whether or not the consumer requests the unpublished
         * fields
         * @param draft
         */
        public getAbsoluteUrl(draft: boolean = false): string {
            if (draft) {
                return this.absoluteUrlDraft ?? "";
            }
            return this.absoluteUrl ?? "";
        }

        public isExternal(draft: boolean = false): boolean {
            if (draft) {
                return this.fileDraftId == null;
            }

            return this.fileId == null;
        }

        // #endregion Public Methods
    };
}

// #endregion Mixin

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BaseResourceRecord;

// #endregion Exports
