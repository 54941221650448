import LocalizationUtils from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getPricingText = (price: string) => {
    const basePricingText = `$${price}/${LocalizationUtils.t(
        "monthAbbreviation"
    )}`;

    if (
        LocalizationUtils.currentCultureCode() ===
        LocalizationUtils.defaultCultureCode()
    ) {
        return basePricingText;
    }

    return `${basePricingText} (USD)`;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const SubscriptionCardUtils = {
    getPricingText,
};

// #endregion Exports
