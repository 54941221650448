import ServiceFactory from "utilities/services/service-factory";
import SubscriptionRecord from "models/view-models/subscription-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SubscriptionBasePathParams {}

export interface SubscriptionResourcePathParams
    extends SubscriptionBasePathParams {
    id: number;
}
export interface SubscriptionResourceQueryParams
    extends SubscriptionBasePathParams {
    sku?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "subscriptions";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = SubscriptionRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SubscriptionService = {
    key: "subscriptions",
    /**
     * Retrieve a specific Subscription resource
     */
    get: ServiceFactory.get<SubscriptionRecord, SubscriptionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    /**
     * Retrieve a list of Subscription resources
     */
    list: ServiceFactory.list<
        SubscriptionRecord,
        SubscriptionResourceQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        SubscriptionRecord,
        SubscriptionResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        SubscriptionRecord,
        SubscriptionResourceQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubscriptionService;

// #endregion Exports
