import { useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";

interface DelightedSurveyProps {}

const DelightedSurvey: React.FunctionComponent<DelightedSurveyProps> = () => {
    const { globalState } = useGlobalState();
    const { routeIsFreeAccess } = useIsNestedRoute();
    const isSubscriber =
        globalState.currentIdentity?.hasActiveSubscription() ?? false;

    const DELIGHTED_SURVEY_CLOSE_BUTTON_CSS_CLASSNAME =
        "delighted-web-survey-close-btn";

    useEffect(() => {
        // If they are a subscriber and are not on any Free Access page, allow for surveying
        if (isSubscriber && !routeIsFreeAccess) {
            window.delightedCsat4.survey();
        } else {
            /* Clicks the survey close button programmatically and therefore solves the edge case
             * where a user is surveyed on a non-free access page and
             * then returns to the free access page with the survey stikll active
             *
             * Ticket: https://app.clickup.com/t/2219993/NFPA-6634
             */
            const closeButton = document.getElementsByClassName(
                DELIGHTED_SURVEY_CLOSE_BUTTON_CSS_CLASSNAME
            )[0] as HTMLElement;
            if (!!closeButton) {
                closeButton.click();
            }
        }
    }, [isSubscriber, routeIsFreeAccess]);

    return null;
};

export default DelightedSurvey;
