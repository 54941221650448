import { Icons } from "atoms/constants/icons";
import Checkbox from "atoms/forms/checkbox";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import * as React from "react";
import { useState, Fragment, useCallback } from "react";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import StringUtils from "utilities/string-utils";
import Anchor from "atoms/anchors/anchor";
import FileRecord from "models/view-models/file-record";
import FeatureFlag from "molecules/feature-flags/feature-flag";
import PublicationOptionsMenu from "organisms/modals/publication-selection-modal/publication-options-menu";
import { KeyboardConstants } from "constants/keyboard-constants";
import { PublicationSelectionListProps } from "organisms/modals/publication-selection-modal/publication-selection-list";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import publicationContentFormatType from "utilities/enumerations/publication-content-format-type";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import ReadonlyPublicationInfoModal from "organisms/modals/readonly-publication-info-modal";
import { useLocalization } from "andculturecode-javascript-react";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export enum PublicationSelectionListGroupIteStyle {
    EditionFirst = "-edition-first",
    TitleFirst = "-title-first",
}

interface PublicationSelectionListGroupItemProps
    extends Pick<PublicationSelectionListProps, "displayMenuActions"> {
    coverImageFile?: FileRecord;
    favorites?: Array<UserPublicationFavoriteRecord>;
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
    publication: PublicationRecord;
    style?: PublicationSelectionListGroupIteStyle;
    onResultClick?: Function;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-publication-selection-list-group-item";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationSelectionListGroupItem = (
    props: PublicationSelectionListGroupItemProps
) => {
    const {
        coverImageFile,
        displayMenuActions = true,
        favorites = [],
        publication,
        onChangeFavorites,
        style = PublicationSelectionListGroupIteStyle.EditionFirst,
        onResultClick = () => {},
    } = props;

    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const { showAllPublicationsPage } = useFeatureFlags();
    const { t } = useLocalization<CultureResources>();

    const coverImageSrc =
        coverImageFile?.presignedUrl ??
        publication.coverImageFile?.presignedUrl;

    const [isFocused, setIsFocused] = useState(false);

    const { addFavorite, unfavorite, isFavorited } =
        useUpdatePublicationFavorite(publication, favorites, onChangeFavorites);

    const handleFavoriteAction = useCallback(
        async (isToBeFavorited: boolean) => {
            if (isToBeFavorited) {
                await addFavorite();
                return;
            }

            await unfavorite();
        },
        [addFavorite, unfavorite]
    );

    const handleKeyDown = useCallback(
        async (event: React.KeyboardEvent<HTMLSpanElement>) => {
            if (event.key !== KeyboardConstants.Enter) {
                return;
            }

            await handleFavoriteAction(!isFavorited);
        },
        [handleFavoriteAction, isFavorited]
    );

    const coverStyle: React.CSSProperties = StringUtils.hasValue(coverImageSrc)
        ? {
              backgroundImage: `url(${coverImageSrc})`,
              backgroundSize: "cover",
          }
        : {};

    const modifierClasses = [];

    if (isFavorited) {
        modifierClasses.push(" -favorite");
    }

    if (isFocused) {
        modifierClasses.push(" -focused");
    }

    const isSubscriberOrInternalUser =
        currentIdentity?.isConfiguredWithActiveSubscription() ||
        currentIdentity?.isAdmin() ||
        currentIdentity?.isAuthorOrPublisher();
    const publicationRoute =
        isSubscriberOrInternalUser || !showAllPublicationsPage
            ? publication.getRoute()
            : RouteUtils.getUrl(siteMap.publications.public.preview, {
                  code: publication.code,
                  edition: publication.edition,
              });

    const [showLegacyInfoModal, setShowLegacyInfoModal] =
        useState<boolean>(false);

    return (
        <div
            className={`${BASE_CLASS} ${modifierClasses.join(" ")}`}
            onBlurCapture={() => setIsFocused(false)}
            onFocusCapture={() => setIsFocused(true)}>
            <div className={`${BASE_CLASS}__item_parent`}>
                <Anchor
                    onClick={(e) => onResultClick()}
                    cssClassName={`${BASE_CLASS}__item ${style.toString()}`}
                    to={publicationRoute}>
                    <div
                        className={`${BASE_CLASS}__cover`}
                        style={coverStyle}
                    />
                    <div>
                        <PublicationText
                            publication={publication}
                            style={style}
                        />
                    </div>
                </Anchor>
                {displayMenuActions && (
                    <>
                        <div className={`${BASE_CLASS}__actions`}>
                            <FeatureFlag feature={"useOfflineFeatures"}>
                                <FeatureFlag.Boolean value={true}>
                                    {publication.publicationContentFormatType ===
                                        publicationContentFormatType.Image && (
                                        <Button
                                            accessibleText={t(
                                                "readonlyPublicationAboutTitle"
                                            )}
                                            onClick={() =>
                                                setShowLegacyInfoModal(true)
                                            }
                                            cssClassName={"c-action-button"}
                                            size={ButtonSizes.Small}>
                                            <Icon
                                                cssClassName="c-legacy-icon"
                                                type={Icons.Scroll}
                                                size={IconSizes.Base}
                                            />
                                            <ReadonlyPublicationInfoModal
                                                isVisible={showLegacyInfoModal}
                                                closeDialog={() =>
                                                    setShowLegacyInfoModal(
                                                        false
                                                    )
                                                }
                                            />
                                        </Button>
                                    )}

                                    <PublicationOptionsMenu
                                        favorites={favorites}
                                        onChangeFavorites={onChangeFavorites}
                                        publication={publication}
                                    />
                                </FeatureFlag.Boolean>
                                <FeatureFlag.Boolean value={false}>
                                    <Checkbox
                                        ariaLabel={"Favorite"}
                                        checked={isFavorited}
                                        checkedIcon={Icons.StarFilled}
                                        uncheckedIcon={Icons.StarOutline}
                                        onChange={handleFavoriteAction}
                                        onKeyDown={handleKeyDown}
                                    />
                                </FeatureFlag.Boolean>
                            </FeatureFlag>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

function PublicationText(
    props: Pick<PublicationSelectionListGroupItemProps, "publication" | "style">
) {
    const { publication, style } = props;

    const edition = (
        <div className={`${BASE_CLASS}__item__edition`}>
            {publication.edition}
        </div>
    );

    const title = (
        <div className={`${BASE_CLASS}__item__title`}>
            {publication.getDisplayTitle(false)}
        </div>
    );

    if (style === PublicationSelectionListGroupIteStyle.EditionFirst) {
        return (
            <Fragment>
                {edition}
                {title}
            </Fragment>
        );
    }

    return (
        <Fragment>
            {title}
            {edition}
        </Fragment>
    );
}

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationSelectionListGroupItem;

// #endregion Exports
