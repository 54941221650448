import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import React, { useState } from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import Form from "molecules/forms/form";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import useUpdateEnhancedContentAdminNoteResources from "utilities/hooks/domain/admin/enhanced-content/use-update-enhanced-content-admin-note-resources";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import InputCharacterCount from "atoms/forms/input-character-count";
import FormFieldErrors from "molecules/form-fields/form-field-errors";
import { DataConfiguration } from "constants/data-configuration";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentAdminNoteActionModalProps {
    onRefresh: () => void;
    onClose: () => void;
    record: EnhancedContentRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-admin-note-modal";
const MODAL_LABEL = "Edit Note";
const NOTE_FORM_FIELD_LABEL = "Note Text";

// -----------------------------------------------------------------------------------------
// #region Components
// -----------------------------------------------------------------------------------------

const EnhancedContentAdminNoteActionModal = (
    props: EnhancedContentAdminNoteActionModalProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Props
    // -----------------------------------------------------------------------------------------

    const { onRefresh, onClose, record } = props;

    // #endregion Props

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [errors, setErrors] = useState(Array<string>());
    const [loading, setLoading] = useState(false);
    const [noteText, setNoteText] = useState(
        StringUtils.hasValue(record.adminNote) ? record.adminNote : ""
    );

    const hasErrors = CollectionUtils.hasValues(errors);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Private Properties
    // -----------------------------------------------------------------------------------------

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const modalTransition = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    // #endregion Private Properties

    // -----------------------------------------------------------------------------------------
    // #region Services
    // -----------------------------------------------------------------------------------------

    const { update } = useUpdateEnhancedContentAdminNoteResources();

    // #endregion Services

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();

        setErrors([]);
        setNoteText(event.target.value);
    };

    const handleSave = async () => {
        setErrors([]);
        setLoading(true);

        const dto = record.with({ adminNote: noteText });

        const result = await update(dto);

        if (CollectionUtils.hasValues(result.errors)) {
            setErrors(result.errors);
            setLoading(false);
            return;
        }

        onRefresh(); // This triggers a close of the modal.
    };

    // #endregion Handles

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            closeDialog={onClose}
            isVisible={true}
            label={MODAL_LABEL}
            type={ModalTypes.Base}
            transition={modalTransition}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{MODAL_LABEL}</Heading>
                <ModalCloseButton onClick={onClose} />
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <Form>
                        <TextAreaFormField
                            isValid={!hasErrors}
                            label={NOTE_FORM_FIELD_LABEL}
                            maxLength={DataConfiguration.ShortNoteLength}
                            onChange={handleChange}
                            rows={4}
                            value={noteText}
                        />
                        <InputCharacterCount
                            currentLength={noteText.length}
                            maxLength={DataConfiguration.ShortNoteLength}
                        />
                        {
                            //if
                            hasErrors && (
                                <FormFieldErrors errorMessages={errors} />
                            )
                        }
                    </Form>
                </div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    <Button onClick={onClose} style={ButtonStyles.Secondary}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={handleSave}
                        style={ButtonStyles.Primary}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );

    // #endregion Render
};

// #endregion Components

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentAdminNoteActionModal;

// #endregion Exports
