import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type AdminEditorPageContextUpdater = Dispatch<
    SetStateAction<AdminEditorPageContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState = new AdminEditorPageContextRecord();
const defaultUpdater: AdminEditorPageContextUpdater = () => {};
const AdminEditorPageContext = createContext([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useAdminEditorPageContext() {
    const [context, setContext] = useContext(AdminEditorPageContext);

    return {
        context: context as AdminEditorPageContextRecord,
        setContext: setContext as AdminEditorPageContextUpdater,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminEditorPageContext;

// #endregion Exports
