import Decryption from "constants/decryption";
import StringUtils from "./string-utils";
import CryptoJS from "crypto-js";

function decipher<T>(text: string): T | undefined {
    if (
        StringUtils.isEmpty(text) ||
        Decryption.key === undefined ||
        Decryption.iv === undefined
    )
        return;

    const key = CryptoJS.enc.Base64.parse(Decryption.key);
    const iv = CryptoJS.enc.Base64.parse(Decryption.iv);

    const ciphertext = CryptoJS.enc.Base64.parse(text);
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext,
        formatter: CryptoJS.format.OpenSSL,
    });

    const decryptedText = CryptoJS.AES.decrypt(cipherParams, key, { iv });
    const encodedText = decryptedText.toString(CryptoJS.enc.Utf8);
    const jsonText: T = JSON.parse(encodedText);

    return jsonText;
}

export default decipher;
