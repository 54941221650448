import type UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useCallback, useState } from "react";
import UserBookmarkService from "utilities/services/user-bookmarks/user-bookmark-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultErrorHandler = () =>
    ToastManager.error("There was an issue updating bookmark.");

/**
 * Custom hook to wrap up the API calls to update bookmarks.
 */
export default function useUpdateBookmark(
    onSuccess?: (bookmark: UserBookmarkRecord) => void,
    onError?: () => void
) {
    const { update: updateBookmarkApi } = UserBookmarkService.useUpdate();

    const [loading, setLoading] = useState(false);

    const handleError = useCallback(
        () => (onError ?? defaultErrorHandler)(),
        [onError]
    );

    const updateBookmark = async (
        bookmark: UserBookmarkRecord
    ): Promise<boolean> => {
        setLoading(true);

        let success: boolean;
        try {
            const result = await updateBookmarkApi(bookmark, {
                id: bookmark.id!,
            });
            onSuccess?.(result.resultObject!);
            success = true;
        } catch (e) {
            handleError();
            success = false;
        }

        setLoading(false);
        return success;
    };

    return { updateBookmark, loading };
}
