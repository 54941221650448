import TableOfContentsRecord from "models/view-models/table-of-contents/table-of-contents-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TableOfContentsBasePathParams {
    publicationId: number;
}

export interface TableOfContentResourcePathParams
    extends TableOfContentsBasePathParams {}

/**
 * Search parameters when listing Chapter resources.
 * These get translated to QueryString parameters
 */
export interface TableOfContentsListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "public/publications/:publicationId/tableofcontents";
const resourceType = TableOfContentsRecord;
const resourceEndpoint = baseEndpoint;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const TableOfContentsService = {
    /**
     * Retrieves a specific Section resource
     */
    get: ServiceFactory.get<
        TableOfContentsRecord,
        TableOfContentResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for retrieving a specific Section resource in a react component
     */
    useGet: ServiceHookFactory.useGet<
        TableOfContentsRecord,
        TableOfContentResourcePathParams,
        TableOfContentsListQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TableOfContentsService;

// #endregion Exports
