export enum UserBookmarkColors {
    Yellow = 1,
    Green = 2,
    Teal = 3,
    Blue = 4,
    Purple = 5,
    Pink = 6,
    Red = 7,
    Black = 8,
}
