import Tab from "atoms/tabs/tab";
import TabPanel from "atoms/tabs/tab-panel";
import React from "react";
import * as Reach from "@reach/tabs";
import { EnvironmentUtils } from "andculturecode-javascript-core";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TabsProps extends Reach.TabsProps {}

// #endregion Interfaces

/**
 * Extension of the ReachUI Tabs component
 *
 * https://reacttraining.com/reach-ui/tabs/
 *
 * @param props
 * @see /documentation/code-conventions-react.md#ReachUI Components##Tabs
 * for a note about usage
 */

const Tabs: React.FunctionComponent<TabsProps> = (props: TabsProps) => {
    // -----------------------------------------------------------------------------------------
    // #region Constants
    // -----------------------------------------------------------------------------------------
    const CSS_BASE_CLASS = "c-tabs";

    // #endregion Constants

    // -------------------------------------------------------------------------------------------------
    // #region Methods
    // -------------------------------------------------------------------------------------------------

    const validateChildren = () => {
        let tabCount = 0;
        let tabPanelCount = 0;
        React.Children.forEach(props.children, (tabListOrTabPanels: any) => {
            // looking for Tab inside of a TabList or TabPanel inside of a TabPanels
            if (tabListOrTabPanels?.props?.children != null) {
                React.Children.forEach(
                    tabListOrTabPanels?.props?.children,
                    (c) => {
                        if (c?.type === Tab) {
                            tabCount++;
                        }

                        if (c?.type === TabPanel) {
                            tabPanelCount++;
                        }
                    }
                );
            }
        });

        if (tabCount !== tabPanelCount) {
            console.warn(
                "tabs.tsx: The number of Tabs should match the number of TabPanels. If you are conditionally" +
                    " rendering a Tab, you should conditionally render the TabPanel, and vice versa."
            );
        }
    };

    EnvironmentUtils.runIfDevelopment(() => validateChildren());

    // #endregion Methods

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    return (
        <Reach.Tabs className={CSS_BASE_CLASS} {...props}>
            {props.children}
        </Reach.Tabs>
    );

    // #endregion Component
};

export default Tabs;
