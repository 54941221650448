import Anchor from "atoms/anchors/anchor";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import CategoryEditorPageSidebar from "organisms/admin/situational-navigation/categories/category-editor-page-sidebar";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import {
    NestedRoutes,
    NestedRoutesProps,
} from "utilities/routing/nested-route";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const LAYOUT_CLASS_NAME = "c-admin-editor-page-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CategoryEditorLayoutProps extends NestedRoutesProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategoryEditorLayout: React.FC<any> = (
    props: CategoryEditorLayoutProps
) => {
    const location = useLocation();
    const { generateCultureCodedRoute } = useCultureCodeRoute();
    const [type, setType] = useState(
        CategoryUtils.getTypeForRoute(location.pathname)
    );
    const { setContext } = useAdminEditorPageContext();

    const isCategoryDashboard = useMemo(
        () =>
            location.pathname ===
            generateCultureCodedRoute(
                CategoryUtils.getDashboardRouteForType(type)
            ),
        [location, type, generateCultureCodedRoute]
    );

    const typeLabels = useMemo(() => CategoryUtils.toLabel(type, true), [type]);

    useEffect(() => {
        setType(CategoryUtils.getTypeForRoute(location.pathname));
        if (isCategoryDashboard) {
            setContext((ctx) => ctx.with({ currentValue: undefined }));
        }
    }, [location, isCategoryDashboard, setContext]);

    const nothingSelectedMessage = (
        <Paragraph size={ParagraphSizes.Large}>
            Select {typeLabels.article} {typeLabels.singular} on the left to
            edit or&nbsp;
            <Anchor to={CategoryUtils.getCreateRouteForType(type)}>
                create a new {typeLabels.singular}.
            </Anchor>
        </Paragraph>
    );

    return (
        <div className={LAYOUT_CLASS_NAME}>
            <div className={`${LAYOUT_CLASS_NAME}__content`}>
                <CategoryEditorPageSidebar type={type} />
                <NestedRoutes
                    routes={props.routes.map((route: RouteDefinition) =>
                        RouteUtils.withCulture<RouteDefinition>(route)
                    )}
                />
                {isCategoryDashboard && (
                    <div className={`${LAYOUT_CLASS_NAME}__content__right`}>
                        <div
                            className={`${LAYOUT_CLASS_NAME}__content__right__none-selected`}>
                            {nothingSelectedMessage}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoryEditorLayout;

// #endregion Exports
