import PublicationRecord from "models/view-models/publication-record";
import React from "react";
import Select, { SelectOption } from "atoms/forms/select";
import SelectSizes from "atoms/constants/select-sizes";
import PublishStatus from "models/enumerations/publish-status";
import SearchForm from "molecules/forms/search-form";
import BasicPublishStatusFiltersBar from "organisms/admin/situational-navigation/basic-publish-status-filter-bar";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ChangeSummariesAdminControlsOptions {
    publicationSelectOptions: Array<SelectOption<PublicationRecord>>;
    publishFilters?: PublishStatus;
}

export interface ChangeSummariesAdminControlsProps {
    onChangePublishStatus: (publishStatus?: PublishStatus) => void;
    onChangeSelectedPublication: () => void;
    options: ChangeSummariesAdminControlsOptions;
    refresh: () => void;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-change-summaries-admin-controls";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ChangeSummariesAdminControls: React.FC<ChangeSummariesAdminControlsProps> =
    (props: ChangeSummariesAdminControlsProps) => {
        const { refresh, searchText, setSearchText } = props;
        const { publishFilters } = props.options;

        // -----------------------------------------------------------------------------------------
        // #region Event Handlers
        // -----------------------------------------------------------------------------------------

        const handlePublishStatusFiltersChanged = (
            publishStatusFilter?: PublishStatus
        ) => {
            props.onChangePublishStatus(publishStatusFilter);
        };

        // #endregion Event Handlers

        // -----------------------------------------------------------------------------------------
        // #region Render
        // -----------------------------------------------------------------------------------------

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__left`}>
                    <SearchForm
                        onClear={() => setSearchText("")}
                        onSearchTextChange={(e) =>
                            setSearchText(e.target.value)
                        }
                        placeholder="Filter by content item title..."
                        searchText={searchText}
                        onSearchClick={() => refresh?.()}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__right`}>
                    <Select
                        label="Filter by Publication"
                        onChange={props.onChangeSelectedPublication}
                        options={props.options.publicationSelectOptions}
                        size={SelectSizes.Small}
                    />
                    <BasicPublishStatusFiltersBar
                        onChange={handlePublishStatusFiltersChanged}
                        value={publishFilters}
                    />
                </div>
            </div>
        );

        // #endregion Render
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ChangeSummariesAdminControls;

// #endregion Exports
