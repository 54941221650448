import { CollectionUtils } from "utilities/collection-utils";

/**
 * Custom hook to generate all the CSS classes that
 * need to be applied to the editor based on props
 * that modify the styles of the editor (for example,
 * if the editor has errors, it has error styles).
 * @param baseClassName the default class name (e.g. c-rich-text-editor)
 * @param disabled if true, apply the "-disabled" class
 * @param errors if non-null and non-empty, apply the "-error" class
 * @param maxLength if non-null and plainTextValue is longer than maxLength, apply the "-error" class
 * @param plainTextValue the editor's plaintext value to check against maxLength, if non-null
 */
export default function useQuillClassName(
    baseClassName: string,
    disabled: boolean | undefined,
    errors: Array<string> | undefined,
    maxLength: number | undefined,
    plainTextValue: string
) {
    const classNames = [baseClassName, "c-form-field"];

    if (maxLength != null && plainTextValue.length > maxLength) {
        classNames.push("-error");
    }

    if (CollectionUtils.hasValues(errors) || maxLength != null) {
        classNames.push("-with-footer");
    }

    if (disabled === true) {
        classNames.push("-disabled");
    }

    return classNames.join(" ");
}
