import * as React from "react";

import { NestedRoutes } from "utilities/routing/nested-route";
import UnauthenticatedFooter from "molecules/footer/unauthenticated-footer";

const UserLoginLayout: React.FC<any> = (props: any) => {
    return (
        <div className="c-login-layout">
            <div className="c-login-layout__panels">
                <NestedRoutes redirectIfNotFound={true} routes={props.routes} />
            </div>
            <UnauthenticatedFooter />
        </div>
    );
};

export default UserLoginLayout;
