import React from "react";
import RadioList, {
    RadioMarkerPosition,
} from "molecules/radio-lists/radio-list";
import RadioButton, { RadioButtonProps } from "atoms/forms/radio-button-input";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface RadioListInputFormFieldProps<TValue extends string | number> {
    label: string;
    markerPosition?: RadioMarkerPosition;
    onValueChange: (value?: TValue) => void;
    options: RadioButtonProps<TValue>[];
    selectedValue: TValue;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

/**
 * Form Input Field for a radio list. `markerPosition` prop is defaulted to `RadioMarkerPosition.Left`
 *
 * @template TValue
 * @param {RadioListInputFormFieldProps<TValue>} props
 * @return {*}
 */
export const RadioListInputFormField = <TValue extends string | number>(
    props: RadioListInputFormFieldProps<TValue>
) => {
    const {
        label,
        markerPosition = RadioMarkerPosition.Left,
        onValueChange,
        options,
        selectedValue,
    } = props;

    const radioListItems = options.map((opt) => {
        const radioButtonProps: RadioButtonProps<TValue> = {
            ...opt,
            checked: selectedValue === opt.value,
            onClick: () => onValueChange(opt.value),
        };

        return <RadioButton {...radioButtonProps} />;
    });

    return (
        <div className={`c-form-field -radio-list`}>
            <label>{label}</label>
            <RadioList items={radioListItems} markerPosition={markerPosition} />
        </div>
    );
};

// #endregion Component
