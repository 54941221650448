import React from "react";
import useTias from "utilities/hooks/domain/publications/use-tias";
import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import { t } from "utilities/localization-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import type SectionRecord from "models/view-models/section-record";
import { isOpenAtom } from "organisms/user-preferences/book-view/book-view-user-preferences";
import { useUpdateAtom } from "jotai/utils";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { UserSettingsKeys } from "constants/user-settings-keys";

interface HiddenContentAlertBannerProps {
    id: number | undefined;
    type:
        | PublicationComponentType.Annex
        | PublicationComponentType.Article
        | PublicationComponentType.Chapter
        | PublicationComponentType.Section
        | PublicationComponentType.AnnexGroup;

    /**
     * Passing the sections for now only for Annex Groups.
     * Until the groupBy attribute is populated for deleted sections in the XML
     * this is the only way to check if an Annex Group has deleted content
     */
    sections?: SectionRecord[];

    /**
     * Only needed for SectionContent (NFPA 70 Chapter 9).
     */
    externalId?: string;
}

const CSS_BASE_CLASS = "c-hidden-content-alert-banner";

const HiddenContentAlertBanner = (props: HiddenContentAlertBannerProps) => {
    const { externalId, id, sections, type } = props;

    const { hasDeletedTiasForCaapsType } = useTias();

    const { globalState } = useGlobalState();

    const showDeletedTias =
        useGlobalState()
            .globalState.getUserSetting("TiasDeletedShow")
            ?.getValue<boolean>() ?? false;

    const hasDeletedTias = hasDeletedTiasForCaapsType({
        externalId,
        id,
        type,
        sections,
    });

    const userHasEnhancedContentHidden = !globalState.getUserSettingValue(
        UserSettingsKeys.EnhancedContentShow as keyof typeof UserSettingsKeys
    );

    const setIsOpen = useUpdateAtom(isOpenAtom);

    const handleClick = () => setIsOpen((isOpen) => !isOpen);

    const warnAboutDeletedTias = hasDeletedTias && !showDeletedTias;
    const hideBanner = !warnAboutDeletedTias && !userHasEnhancedContentHidden;

    if (hideBanner) return null;

    const caapsNames = {
        [PublicationComponentType.Chapter]: t("chapter"),
        [PublicationComponentType.Annex]: t("annex"),
        [PublicationComponentType.Article]: t("article"),
        [PublicationComponentType.AnnexGroup]: `${t("annex")} ${t("group")}`,
        [PublicationComponentType.Section]: t("section"),
    };

    const typeName = caapsNames[type].toLowerCase();

    const textToShow =
        warnAboutDeletedTias && !userHasEnhancedContentHidden
            ? "hiddenTiaContent"
            : warnAboutDeletedTias && userHasEnhancedContentHidden
            ? "hiddenTiaAndEnhancedContent"
            : "hiddenEnhancedContent";

    return (
        <div className={CSS_BASE_CLASS} id="hidden-content">
            <AlertBanner alertLevel={AlertLevels.Info}>
                <span className={`${CSS_BASE_CLASS}__text`}>
                    {t(textToShow, { item: typeName })}
                </span>
                &nbsp;
                <Button style={ButtonStyles.Anchor} onClick={handleClick}>
                    <span className={`${CSS_BASE_CLASS}__button`}>
                        {t("updateViewingPreferences")}
                    </span>
                </Button>
            </AlertBanner>
        </div>
    );
};

export default HiddenContentAlertBanner;
