import React from "react";
import SubscriptionCardFeature from "organisms/subscription-card/subscription-card-feature";
import useSubscriptionFeatures, {
    SubscriptionFeaturesType,
} from "utilities/hooks/domain/subscriptions/use-subscription-features";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SubscriptionFeatureListProps {
    type: SubscriptionFeaturesType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionFeatureList: React.FunctionComponent<SubscriptionFeatureListProps> =
    (props: SubscriptionFeatureListProps) => {
        const { type } = props;
        const { features } = useSubscriptionFeatures({ type });

        return (
            <React.Fragment>
                {features.map((feature: string, index: number) => (
                    <SubscriptionCardFeature
                        featureText={feature}
                        key={index}
                    />
                ))}
            </React.Fragment>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SubscriptionFeatureList;

// #endregion Export
