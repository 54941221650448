import { RouteDefinition } from "andculturecode-javascript-react";
import { useLocalization } from "utilities/hooks/use-localization";
import equipmentImage from "assets/images/sitnav_equipment.png";
import occupancyImage from "assets/images/sitnav_occupancy.png";
import spaceImage from "assets/images/sitnav_space.png";
import systemImage from "assets/images/sitnav_system.png";
import Button, { ButtonTypes } from "atoms/buttons/button";
import SituationalNavigationCategoryLink from "atoms/buttons/situational-navigation-category-link";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { List } from "immutable";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SolutionsPageContextRecord from "models/view-models/situational-navigation/solutions/solutions-page-context-record";
import AlertModal from "molecules/modals/alert-modal";
import SituationalNavigationToolbar from "molecules/situational-navigation/toolbars/situational-navigation-results-toolbar";
import SituationalNavigationResultsView from "organisms/situational-navigation/situational-navigation-results-view";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "routes";
import { siteMap } from "internal-sitemap";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import SolutionsPageContext from "utilities/contexts/use-solutions-page-context";
import { CoreUtils } from "utilities/core-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useCategories from "utilities/hooks/domain/situational-navigation/categories/use-categories";
import useSituations from "utilities/hooks/domain/situational-navigation/situations/use-situations";
import useSolutions from "utilities/hooks/domain/situational-navigation/solutions/use-solutions";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import useLoading from "utilities/hooks/use-loading";
import CategoryCollection from "utilities/interfaces/categories/category-collection";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import { NestedRoutes } from "utilities/routing/nested-route";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import StringIndexedObject from "utilities/types/string-indexed-object";
import { SkipNavContent } from "@reach/skip-nav";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SituationalNavigationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const SituationalNavigationPageTitle = `NFPA DiRECT${UnicodeCharacterConstants.TM}`;

const CATEGORY_TYPE_ICONS: StringIndexedObject<string> = {
    equipment: equipmentImage,
    occupancies: occupancyImage,
    spaces: spaceImage,
    systems: systemImage,
};

const CATEGORY_TYPES = [
    CategoryType.Occupancies,
    CategoryType.Systems,
    CategoryType.Spaces,
    CategoryType.Equipment,
];

const CSS_CLASS_NAME = "c-situational-navigation-page";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SituationalNavigationPage: React.FC<SituationalNavigationPageProps> =
    () => {
        // -----------------------------------------------------------------------------------------
        // #region Context
        // -----------------------------------------------------------------------------------------

        const location = useLocation();
        const [solutionsPageContext, setSolutionsPageContext] = useState(
            new SolutionsPageContextRecord()
        );

        // #endregion Context

        // -----------------------------------------------------------------------------------------
        // #region Localization
        // -----------------------------------------------------------------------------------------

        const { t } = useLocalization<CultureResources>();

        const placeholderText = t("sitnav-results-placeholder");

        const aboutButtonText = t("sitnav-about-buttonText");
        const aboutModalCloseText = t("continue");

        const about = t("sitnav-about", {
            sitnavTitle: SituationalNavigationPageTitle,
        });
        const helpText = t("sitnav-categories-helpText");
        const additionalHelpText = t("sitnav-categories-additionalHelpText");
        const aboutModalHeading = t("sitnav-about-modalHeading", {
            sitnavTitle: SituationalNavigationPageTitle,
        });

        // #endregion Localization

        // -----------------------------------------------------------------------------------------
        // #region State
        // -----------------------------------------------------------------------------------------

        const [hasMadeAllPossibleSelections, setHasMadeAllPossibleSelections] =
            useState(false);
        const isDesktop = useBreakpoint(Breakpoints.LargeLaptop);
        const [shouldRefreshCategories, setShouldRefreshCategories] =
            useState(true);
        const [aboutModalOpen, setAboutModalOpen] = useState(false);
        const { categories, initialCategories, loadingCategories } =
            useCategories(
                solutionsPageContext.categories,
                shouldRefreshCategories
            );
        const { situations, loadingSituations } = useSituations(
            solutionsPageContext.categories
        );
        const { solutions, loadingSolutions } = useSolutions(
            solutionsPageContext.categories
        );
        const loading = useLoading(
            loadingCategories,
            loadingCategories,
            loadingSituations,
            loadingSolutions
        );
        const total = solutions.length + situations.length;
        const showCount = total > 0;
        const hasCategoriesSelected =
            solutionsPageContext.categories.equipment.count() > 0 ||
            solutionsPageContext.categories.occupancies.count() > 0 ||
            solutionsPageContext.categories.spaces.count() > 0 ||
            solutionsPageContext.categories.systems.count() > 0;

        const relatedIdArray = solutionsPageContext.categories.mapToArray(
            (c: CategoryRecord) => c.id
        );

        useHeaderData({ title: SituationalNavigationPageTitle });

        // #endregion State

        // -----------------------------------------------------------------------------------------
        // #region Side Effects
        // -----------------------------------------------------------------------------------------

        useEffect(() => {
            let allPossibleSelectionsMade = true;

            for (const type of CATEGORY_TYPES) {
                if (
                    !categories.toList(type).isEmpty() &&
                    solutionsPageContext.categories.toList(type).isEmpty()
                ) {
                    // if the list of options is empty, but there is no selection,
                    // we haven't selected all possible options.
                    allPossibleSelectionsMade = false;
                    break;
                }
            }

            setHasMadeAllPossibleSelections(allPossibleSelectionsMade);
        }, [solutionsPageContext.categories, categories]);

        const getCategoriesChangeHandler = (
            propName: keyof CategoryCollection
        ) => {
            return (newValue?: CategoryRecord) => {
                setShouldRefreshCategories(true);
                setSolutionsPageContext(
                    solutionsPageContext.with({
                        categories: solutionsPageContext.categories.with({
                            [propName]: List.of(newValue),
                        }),
                    })
                );
            };
        };

        const handleCategoryClear = (categoryToClear: CategoryType) => {
            const category =
                CategoryUtils.toCategoryCollectionProperty(categoryToClear);
            setSolutionsPageContext(
                solutionsPageContext.with({
                    categories: solutionsPageContext.categories.with({
                        [category]: List(),
                    }),
                })
            );
            setShouldRefreshCategories(true);
        };

        // #endregion Side Effects

        const classNames = [CSS_CLASS_NAME];
        if (showCount) {
            classNames.push("-toolbar-active");
        }

        const { globalState } = useGlobalState();
        const shouldRenderDashboard =
            location.pathname === siteMap.situationalNavigation.dashboard ||
            location.pathname ===
                `/${globalState.getPreferredOrCurrentCulture().toLowerCase()}${
                    siteMap.situationalNavigation.dashboard
                }`;

        return (
            <SolutionsPageContext.Provider
                value={[solutionsPageContext, setSolutionsPageContext]}>
                {
                    // if
                    shouldRenderDashboard && (
                        <SkipNavContent>
                            <div className={classNames.join(" ")} tabIndex={-1}>
                                <div className={`${CSS_CLASS_NAME}__container`}>
                                    <Button
                                        cssClassName={`-icon-left ${CSS_CLASS_NAME}__about`}
                                        size={ButtonSizes.Small}
                                        type={ButtonTypes.Button}
                                        style={ButtonStyles.TertiaryAlt}
                                        onClick={() => setAboutModalOpen(true)}>
                                        <Icon type={Icons.Information} />{" "}
                                        {aboutButtonText}
                                    </Button>
                                    <Heading priority={HeadingPriority.Three}>
                                        {SituationalNavigationPageTitle}
                                    </Heading>
                                    {
                                        // if
                                        solutionsPageContext.categories.isEmpty() && (
                                            <Paragraph
                                                cssClassName={`${CSS_CLASS_NAME}__help-text`}>
                                                {helpText}
                                            </Paragraph>
                                        )
                                    }
                                    {
                                        // if
                                        !solutionsPageContext.categories.isEmpty() && (
                                            <Paragraph
                                                cssClassName={`${CSS_CLASS_NAME}__help-text`}>
                                                {additionalHelpText}
                                            </Paragraph>
                                        )
                                    }
                                    <div
                                        className={`${CSS_CLASS_NAME}__categories-container`}>
                                        {CATEGORY_TYPES.map(
                                            (type: CategoryType) => (
                                                <SituationalNavigationCategoryLink
                                                    key={type}
                                                    allSelectedOptions={
                                                        solutionsPageContext.categories
                                                    }
                                                    allCategories={
                                                        initialCategories?.toList(
                                                            type
                                                        ) ?? List()
                                                    }
                                                    categoryType={type}
                                                    filteredCategories={categories.toList(
                                                        type
                                                    )}
                                                    loadingCategories={loading}
                                                    value={solutionsPageContext.categories
                                                        .toList(type)
                                                        .get(0)}
                                                    onCategorySelected={getCategoriesChangeHandler(
                                                        CategoryUtils.toCategoryCollectionProperty(
                                                            type
                                                        )
                                                    )}
                                                    onCategoryClear={() =>
                                                        handleCategoryClear(
                                                            type
                                                        )
                                                    }>
                                                    <img
                                                        src={
                                                            CATEGORY_TYPE_ICONS[
                                                                CategoryUtils.toCategoryCollectionProperty(
                                                                    type
                                                                )
                                                            ]
                                                        }
                                                        alt={`${
                                                            CategoryUtils.toLabel(
                                                                type
                                                            ).singular
                                                        } Icon`}
                                                    />
                                                    {
                                                        CategoryUtils.toLabel(
                                                            type
                                                        ).singular
                                                    }
                                                </SituationalNavigationCategoryLink>
                                            )
                                        )}
                                    </div>
                                    {
                                        // if
                                        showCount &&
                                            !isDesktop &&
                                            hasCategoriesSelected && (
                                                <SituationalNavigationToolbar
                                                    cssClassName={`${CSS_CLASS_NAME}__toolbar`}
                                                    isFilled={
                                                        hasMadeAllPossibleSelections
                                                    }
                                                    numberOfResults={total}
                                                    viewResultsRoute={RouteUtils.appendQueryParams(
                                                        siteMap
                                                            .situationalNavigation
                                                            .results,
                                                        {
                                                            relatedCategoryIds:
                                                                relatedIdArray,
                                                        }
                                                    )}
                                                />
                                            )
                                    }
                                </div>
                                {isDesktop && (
                                    <div
                                        className={`${CSS_CLASS_NAME}__results`}>
                                        {
                                            // if
                                            loading && (
                                                <div
                                                    className={`${CSS_CLASS_NAME}__results__placeholder`}>
                                                    <Loader />
                                                </div>
                                            )
                                        }
                                        {
                                            // if
                                            showCount && !loading && (
                                                <SituationalNavigationResultsView
                                                    categoriesFromContext={
                                                        solutionsPageContext.categories
                                                    }
                                                    situations={situations}
                                                    solutions={solutions}
                                                />
                                            )
                                        }
                                        {
                                            // if
                                            !showCount && !loading && (
                                                <div
                                                    className={`${CSS_CLASS_NAME}__results__placeholder`}>
                                                    <Icon
                                                        size={IconSizes.Large}
                                                        type={Icons.Information}
                                                    />
                                                    <Paragraph
                                                        size={
                                                            ParagraphSizes.Large
                                                        }>
                                                        {placeholderText}
                                                    </Paragraph>
                                                </div>
                                            )
                                        }
                                    </div>
                                )}
                                <AlertModal
                                    buttonText={aboutModalCloseText}
                                    closeDialog={() => setAboutModalOpen(false)}
                                    heading={aboutModalHeading}
                                    isVisible={aboutModalOpen}
                                    label={aboutModalHeading}>
                                    <Paragraph size={ParagraphSizes.Base}>
                                        {about}
                                    </Paragraph>
                                </AlertModal>
                            </div>
                        </SkipNavContent>
                    )
                }
                {
                    // if
                    location.pathname !==
                        siteMap.situationalNavigation.dashboard && (
                        <NestedRoutes
                            routes={CoreUtils.objectToArray(
                                routes.home.routes.situationalNavigation.routes
                            ).map((route: RouteDefinition) =>
                                RouteUtils.withCulture<RouteDefinition>(route)
                            )}
                        />
                    )
                }
            </SolutionsPageContext.Provider>
        );
    };
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SituationalNavigationPage;

// #endregion Exports
