import AnnexGroupMetaNav from "molecules/metanavs/annex-group-metanav";
import AnnexMetaNav from "molecules/metanavs/annex-metanav";
import ArticleMetaNav from "molecules/metanavs/article-metanav";
import ChapterMetaNav from "molecules/metanavs/chapter-metanav";
import MetadataMetanav from "molecules/metanavs/metadata-metanav";
import SectionMetaNav from "molecules/metanavs/section-metanav";
import { RouteProps } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import AnnexTablesMetanav from "molecules/metanavs/annex-tables-metanav";
import ChapterTablesMetanav from "molecules/metanavs/chapter-tables-metanav";

export interface MetaNavRoute extends Omit<RouteProps, "path"> {
    path: string;
}

const metanavRoutes: MetaNavRoute[] = [
    {
        component: ArticleMetaNav,
        path: siteMap.publications.permalinks.article,
    },
    {
        component: SectionMetaNav,
        path: siteMap.publications.permalinks.section,
    },
    {
        component: ChapterMetaNav,
        path: siteMap.publications.permalinks.chapter,
    },
    {
        component: ChapterTablesMetanav,
        path: siteMap.publications.permalinks.chapterTables,
    },
    {
        component: MetadataMetanav,
        path: siteMap.publications.permalinks.publicationMetadata,
    },
    {
        component: AnnexMetaNav,
        path: siteMap.publications.permalinks.annex,
    },
    {
        component: AnnexGroupMetaNav,
        path: siteMap.publications.permalinks.annexGroup,
    },
    {
        component: AnnexTablesMetanav,
        path: siteMap.publications.permalinks.annexTables,
    },
    {
        component: AnnexMetaNav,
        path: siteMap.publications.adminPreview.annex,
    },
    {
        component: ArticleMetaNav,
        path: siteMap.publications.adminPreview.article,
    },
    {
        component: SectionMetaNav,
        path: siteMap.publications.adminPreview.section,
    },
    {
        component: ChapterMetaNav,
        path: siteMap.publications.adminPreview.chapter,
    },
    {
        component: ChapterTablesMetanav,
        path: siteMap.publications.adminPreview.chapterTables,
    },
    {
        component: MetadataMetanav,
        path: siteMap.publications.adminPreview.publicationMetadata,
    },
    {
        component: AnnexGroupMetaNav,
        path: siteMap.publications.adminPreview.annexGroup,
    },
];

export default metanavRoutes.map((route: MetaNavRoute) =>
    RouteUtils.withCulture<MetaNavRoute>(route)
);
