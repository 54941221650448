import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import TableOfContentsRecord from "models/view-models/table-of-contents/table-of-contents-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TableOfContentsBasePathParams {
    publicationId: number;
}

export interface TableOfContentResourcePathParams
    extends TableOfContentsBasePathParams {}

export interface AnnexTableOfContentsParams
    extends TableOfContentsBasePathParams {
    annexId: number;
}

export interface ArticleTableOfContentsParams
    extends TableOfContentsBasePathParams {
    articleId: number;
}
/**
 * Search parameters when listing Chapter resources.
 * These get translated to QueryString parameters
 */
export interface TableOfContentsListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/tableofcontents";
const resourceType = TableOfContentsRecord;
const resourceEndpoint = baseEndpoint;
const annexEndpoint = `${baseEndpoint}/annex/:annexId`;
const articleEndpoint = `${baseEndpoint}/article/:articleId`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const TableOfContentsService = {
    /**
     * Retrieves a specific Section resource
     */
    get: ServiceFactory.get<
        TableOfContentsRecord,
        TableOfContentResourcePathParams
    >(resourceType, resourceEndpoint),

    getArticleSections: ServiceFactory.nestedList<
        SectionTableOfContentsRecord,
        ArticleTableOfContentsParams,
        TableOfContentsListQueryParams
    >(SectionTableOfContentsRecord, articleEndpoint),

    /**
     * Custom hook for retrieving a specific Section resource in a react component
     */
    useGet: ServiceHookFactory.useGet<
        TableOfContentsRecord,
        TableOfContentResourcePathParams,
        TableOfContentsListQueryParams
    >(resourceType, resourceEndpoint),

    useGetArticle: ServiceHookFactory.useNestedList<
        SectionTableOfContentsRecord,
        ArticleTableOfContentsParams,
        TableOfContentsListQueryParams
    >(SectionTableOfContentsRecord, articleEndpoint),

    useGetAnnex: ServiceHookFactory.useNestedList<
        SectionTableOfContentsRecord,
        AnnexTableOfContentsParams,
        TableOfContentsListQueryParams
    >(SectionTableOfContentsRecord, annexEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TableOfContentsService;

// #endregion Exports
