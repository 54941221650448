import { useLocalization } from "utilities/hooks/use-localization";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import React from "react";
import CultureResources from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ConfirmationModalProps {
    /**
     * ButtonStyles to use for cancel button
     *
     * @default ButtonStyles.Secondary
     */
    cancelButtonStyle?: ButtonStyles;

    /**
     * text or TSX element to render inside the cancel button
     */
    cancelButtonText?: string | React.ReactNode;

    /**
     * ButtonStyles to use for confirm button
     *
     * @default ButtonStyles.Destructive
     */
    confirmButtonStyle?: ButtonStyles;
    /**
     * text or TSX element to render inside the confirm button
     */
    confirmButtonText?: string | React.ReactNode;

    /**
     * Optional className to attach to the modal
     */
    cssClassName?: string;

    /**
     * controls modal visibility
     */
    isVisible: boolean;

    /**
     * the label/title of the modal, rendered at the top
     *
     * @default Confirm
     */
    label?: string;

    /**
     * the text or component to render in the body of the modal
     */
    message: string | React.ReactNode;

    /**
     * callback to execute when cancel button is clicked
     */
    onCancel: () => void;

    /**
     * callback to execute when onConfirm is clicked
     */
    onConfirm: () => void;

    /**
     * CSS transition to use
     *
     * @default ModalTransitions.Fade
     */
    transition?: ModalTransitions;

    /**
     * Modal type to use
     *
     * @default ModalTypes.Base
     */
    type?: ModalTypes;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (
    props: ConfirmationModalProps
) => {
    const { t } = useLocalization<CultureResources>();
    const cssBaseClassName = "c-confirmation-modal";
    const cancelButtonText = props.cancelButtonText ?? t("cancel");
    const confirmButtonText = props.confirmButtonText ?? t("confirm");

    const {
        cancelButtonStyle,
        confirmButtonStyle,
        cssClassName,
        label,
        message,
        onCancel,
        onConfirm,
        transition,
        type,
    } = props;

    const modalPropsWithDefaults: ModalProps = Object.assign({}, props, {
        label: label ?? t("confirm"),
        transition: transition ?? ModalTransitions.Fade,
        type: type ?? ModalTypes.Base,
        closeDialog: onCancel,
    });

    return (
        <Modal
            {...modalPropsWithDefaults}
            cssClassName={`${cssBaseClassName} ${cssClassName}`}>
            <div className={`${cssBaseClassName}__header`}>
                <h2>{modalPropsWithDefaults.label}</h2>
                <Button
                    accessibleText={t("cancel")}
                    cssClassName={"-modal-close -icon"}
                    onClick={onCancel}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.TertiaryAlt}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${cssBaseClassName}__content`}>
                <div className={`${cssBaseClassName}__content__message`}>
                    {message}
                </div>
                <div className={`${cssBaseClassName}__footer`}>
                    <Button
                        onClick={onCancel}
                        style={cancelButtonStyle ?? ButtonStyles.Secondary}
                        type={ButtonTypes.Button}>
                        {cancelButtonText}
                    </Button>
                    <Button
                        dataTestId="confirm"
                        onClick={onConfirm}
                        style={confirmButtonStyle ?? ButtonStyles.Destructive}
                        type={ButtonTypes.Button}>
                        {confirmButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component
