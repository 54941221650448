import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ENTERPRISE_FEATURES: Array<keyof CultureResources> = [
    "subscriptionFeatures-allIndividualPlanFeatures",
    "subscriptionFeatures-userManagement",
    "subscriptionFeatures-enterpriseCollaboration",
    "subscriptionFeatures-enterpriseTeamNotesSharing",
];

const INDIVIDUAL_FEATURES: Array<keyof CultureResources> = [
    "subscriptionFeatures-digitalAccess",
    "subscriptionFeatures-expertCommentaryVisualAids",
    "subscriptionFeatures-situationalNavigation",
    "subscriptionFeatures-customCollections",
    "subscriptionFeatures-personalNotations",
    "subscriptionFeatures-referencePanel",
    "subscriptionFeatures-bookmarking",
    "subscriptionFeatures-sharing",
    "subscriptionFeatures-search",
];

const TEAM_FEATURES: Array<keyof CultureResources> = [
    "subscriptionFeatures-allIndividualPlanFeatures",
    "subscriptionFeatures-userManagement",
    "subscriptionFeatures-teamCollaboration",
    "subscriptionFeatures-teamNotesSharing",
];

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

enum SubscriptionFeaturesType {
    Individual,
    Team,
    Enterprise,
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseSubscriptionFeaturesHookProps {
    type: SubscriptionFeaturesType;
}

interface UseSubscriptionFeaturesHook {
    features: string[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Custom hook for returning a list of features based on the plan type
 *
 * @export
 * @param {UseSubscriptionFeaturesHookProps} props
 * @return {*}  {UseSubscriptionFeaturesHook}
 */
export default function useSubscriptionFeatures(
    props: UseSubscriptionFeaturesHookProps
): UseSubscriptionFeaturesHook {
    const { type } = props;

    const getFeatures = (): string[] => {
        if (type === SubscriptionFeaturesType.Enterprise) {
            return getSubscriptionFeatureDescriptions(ENTERPRISE_FEATURES);
        }

        if (type === SubscriptionFeaturesType.Team) {
            return getSubscriptionFeatureDescriptions(TEAM_FEATURES);
        }

        return getSubscriptionFeatureDescriptions(INDIVIDUAL_FEATURES);
    };

    return {
        features: getFeatures(),
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getSubscriptionFeatureDescriptions = (
    features: Array<keyof CultureResources>
): string[] => features.map((feature) => LocalizationUtils.t(feature));

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { SubscriptionFeaturesType };

// #endregion Exports
