import { useMemo } from "react";
import { CoreUtils } from "utilities/core-utils";
import QuillConfig from "utilities/quill/quill-config";

/**
 * Custom hook to build the Quill Modules configuration
 * object based on the specified configuration parameters.
 * @param allowImages indicates whether or not to configure the image handler
 * @param allowReferenceLinks indicates whether or not to configure the reference link handler
 * @param openImageModal a callback triggering the image modal to open, configured as the image handler if allowImages is true
 * @param openReferenceLinkModal a callback triggering the reference link to open, configured as the reference link handler if allowReferenceLinks is true
 * @param toolbarId the HTML ID of the toolbar element, configured as the toolbar container that Quill binds to
 */
export default function useQuillModules(
    allowImages: boolean,
    allowReferenceLinks: boolean,
    openImageModal: () => void,
    openReferenceLinkModal: () => void,
    toolbarId: string
) {
    return useMemo(
        () =>
            CoreUtils.merge(QuillConfig.defaultQuillModules, {
                toolbar: {
                    container: `#${toolbarId}`,
                    handlers: {
                        image: allowImages ? openImageModal : undefined,
                        addReference: allowReferenceLinks
                            ? openReferenceLinkModal
                            : undefined,
                    },
                },
            }),
        [
            allowImages,
            allowReferenceLinks,
            toolbarId,
            openImageModal,
            openReferenceLinkModal,
        ]
    );
}
