import React, { Dispatch, SetStateAction } from "react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import FreeAccessPublicationTableOfContentsEntry from "models/interfaces/pdf-table-of-contents-entry";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";

interface TableOfContentsImageViewerCollapsePanelHeaderProps
    extends Pick<DataTestAttributes, "dataTestId" | "dataTestDisplaySequence"> {
    tableOfContentsEntry: FreeAccessPublicationTableOfContentsEntry;
    isActive: boolean;
    onEntryClick: Dispatch<
        SetStateAction<FreeAccessPublicationTableOfContentsEntry>
    >;
    cssClassName?: string;
}

const TableOfContentsCollapsePanelHeader: React.FunctionComponent<TableOfContentsImageViewerCollapsePanelHeaderProps> =
    (props) => {
        const {
            tableOfContentsEntry,
            isActive,
            onEntryClick,
            cssClassName = "",
        } = props;

        const BASE_CSS_CLASS_NAME =
            "c-table-of-contents-chapter__heading -small";

        return (
            <Button
                style={ButtonStyles.Text}
                onClick={() => onEntryClick(tableOfContentsEntry)}>
                <Heading
                    cssClassName={`${BASE_CSS_CLASS_NAME} ${
                        isActive ? "-active" : ""
                    } ${cssClassName}`}
                    priority={HeadingPriority.Two}>
                    {tableOfContentsEntry.heading}
                    {tableOfContentsEntry.description && <>&mdash; </>}
                    {tableOfContentsEntry.description}
                </Heading>
            </Button>
        );
    };

export default TableOfContentsCollapsePanelHeader;
