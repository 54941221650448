import type TopicRecord from "models/view-models/topic-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import PublicTopicService from "utilities/services/public/topics/topic-service";
import { ReactQueryUtils } from "utilities/react-query-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseTopicsResult extends ServiceHookResult<TopicRecord[]> {
    /**
     * Function for the consumer to call to trigger a refresh of the data gathered by this hook
     */
    refresh: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultResultObject: Array<TopicRecord> = [];

// #endregion Constants

/**
 * Custom hook for retrieving Topics
 */
export default function useTopics(): UseTopicsResult {
    const { list: listTopicsApi } = PublicTopicService.useListQuery();
    const topicsResult = listTopicsApi();

    return {
        ...ReactQueryUtils.getResult(topicsResult),
        refresh: topicsResult.refetch,
        resultObject: topicsResult.data?.resultObjects ?? defaultResultObject,
        rowCount: topicsResult.data?.rowCount,
    };
}
