import axios from "axios";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PrivacyPolicyBasePathParams {}
export interface PrivacyPolicyPutDto {
    PrivacyPolicyIdentifiers: number[];
}

export interface PrivacyPolicyResourcePathParams
    extends PrivacyPolicyBasePathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "privacypolicy";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const PrivacyPolicyService = {
    accept: async (): Promise<boolean> => {
        return await axios.put(baseEndpoint);
    },
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PrivacyPolicyService;

// #endregion Exports
