import Shareable from "models/interfaces/shareable";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";

export default class ShareableValidator<
    T extends Shareable
> extends ObjectValidator<T> {
    public validate(object: T): ObjectValidationResult<T> {
        const result: ObjectValidationResult<T> = {};
        const emailAddress = t("field-email");
        const emailAddress_plural = t("field-email_plural");
        const emailRequired = t("validation-atLeastOne", {
            type: emailAddress,
        });
        const invalidEmail = t("validation-multipleInvalid", {
            type: emailAddress_plural,
        });
        const subjectRequired = t("propertyIsRequired", {
            name: t("subject"),
        });
        const templateRequired = t("propertyIsRequired", {
            name: t("email-template"),
        });
        if (CollectionUtils.isEmpty(object.emailAddresses)) {
            result.emailAddresses = [emailRequired];
        }

        if (
            object.emailAddresses?.some(
                (e: string) => !StringUtils.isValidEmail(e)
            )
        ) {
            result.emailAddresses = [invalidEmail];
        }

        if (StringUtils.isEmpty(object.subject)) {
            result.subject = [subjectRequired];
        }

        if (StringUtils.isEmpty(object.magicLinkUrlTemplate)) {
            result.magicLinkUrlTemplate = [templateRequired];
        }

        return result;
    }
}
