import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React, {
    forwardRef,
    HTMLProps,
    PropsWithChildren,
    Ref,
    RefObject,
} from "react";
import { useLinkPreviewContext } from "utilities/contexts/link-preview/use-link-preview-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextReferenceLinkProps
    extends Omit<XmlConvertedComponentProps, "children" | "key" | "tagName">,
        HTMLProps<HTMLAnchorElement> {
    className?: string;
    href?: string;
    ref?: RefObject<HTMLAnchorElement>;
    sectionId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextReferenceLink: React.RefForwardingComponent<
    HTMLElement,
    PropsWithChildren<RichTextReferenceLinkProps>
> = forwardRef(
    (
        props: PropsWithChildren<RichTextReferenceLinkProps>,
        ref: Ref<HTMLAnchorElement>
    ) => {
        const { sectionId, ...rest } = props;
        const { onAnchorClick } = useLinkPreviewContext();

        // Transform sectionId to sectionid to elim React Unknown Prop Warning
        const propsWithSectionId = { sectionid: sectionId, ...rest };

        return (
            <React.Fragment>
                <a
                    {...propsWithSectionId}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        onAnchorClick(e, sectionId, props.href!);
                    }}
                    ref={ref}>
                    {props.children}
                </a>
            </React.Fragment>
        );
    }
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextReferenceLink;

// #endregion Exports
