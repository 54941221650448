import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React, { useLayoutEffect, useRef } from "react";
import AnnouncementRecord from "models/view-models/announcement-record";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import RichTextUtils from "utilities/rich-text-utils";
import { ScrollUtils } from "utilities/scroll-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AnnouncementModalProps {
    announcement: AnnouncementRecord;
    canDismiss?: boolean;
    isVisible: boolean;
    onClose: (acknowledged: boolean) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-announcement-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AnnouncementModal: React.FC<AnnouncementModalProps> = (
    props: AnnouncementModalProps
) => {
    const { announcement, canDismiss = false, isVisible, onClose } = props;

    const headerRef = useRef<HTMLDivElement>(null);

    const modalTitle = announcement.title ?? "Announcement";

    useLayoutEffect(() => {
        if (headerRef.current != null) {
            ScrollUtils.scrollToElement(headerRef.current, {
                block: "start",
            });
        }
    }, []);

    const handleDismissModal = () => {
        if (canDismiss) {
            onClose(false);
        }
    };

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            closeDialog={handleDismissModal}
            isVisible={isVisible}
            label={modalTitle}
            type={ModalTypes.Base}
            transition={ModalTransitions.Fade}>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__header`} ref={headerRef}>
                    <Heading priority={HeadingPriority.Two}>
                        {modalTitle}
                    </Heading>
                    {canDismiss && (
                        <ModalCloseButton onClick={() => onClose(false)} />
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    {RichTextUtils.hasValue(announcement.body) && (
                        <RichTextArea
                            content={announcement.body}
                            cssClassName={`${CSS_CLASS_NAME}__content__title`}
                        />
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <Button
                    onClick={() => onClose(true)}
                    style={ButtonStyles.Primary}>
                    Got It
                </Button>
            </div>
        </Modal>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AnnouncementModal;

// #endregion Exports
