import BlotFormatter, { Action } from "quill-blot-formatter";
import StringUtils from "utilities/string-utils";

const CONTAINER_OFFSET_BOTTOM = -48;

export default class SetAltTextAction extends Action {
    private readonly applyCallback: (e: Event) => void;
    private readonly blotFormatter: BlotFormatter;
    private readonly containerEl: HTMLElement;
    private readonly inputEl: HTMLInputElement;
    private readonly overlayRepositionObserver: MutationObserver;
    private readonly scrollCallback: () => void;

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(formatter: BlotFormatter) {
        super(formatter);
        this.blotFormatter = formatter;
        const elements = SetAltTextAction.buildAltTextInput(
            this.getImgAltText()
        );
        this.containerEl = elements.container;
        this.inputEl = elements.input;
        this.applyCallback = this.onApply.bind(this);
        this.scrollCallback = this.getDebouncedFixOverlayPosition();

        // In onCreate we set up an observer to recompute the
        // container position when the overlay position changes
        this.overlayRepositionObserver = new MutationObserver(
            this.fixInputContainerPosition.bind(this)
        );
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Action Overrides
    // -------------------------------------------------------------------------------------------------

    public onCreate() {
        super.onCreate();
        this.setInputValue(this.getImgAltText());
        this.formatter.overlay.appendChild(this.containerEl);
        this.inputEl.addEventListener("change", this.applyCallback);
        this.blotFormatter.quill.root.addEventListener(
            "scroll",
            this.scrollCallback,
            true
        );
        this.fixInputContainerPosition();

        // recompute container position when overlay position changes
        this.overlayRepositionObserver.observe(this.formatter.overlay, {
            attributes: true,
            attributeFilter: ["style"],
        });
    }

    public onDestroy() {
        super.onDestroy();
        this.formatter.overlay.removeChild(this.containerEl);
        this.inputEl.removeEventListener("change", this.applyCallback);
        this.blotFormatter.quill.root.removeEventListener(
            "scroll",
            this.scrollCallback
        );

        // it's not nullable in TypeScript, but it may have already
        // been garbage-collected by the browser at runtime, so keep the ?.
        this.overlayRepositionObserver?.disconnect();
    }

    // #endregion Action Overrides

    // -------------------------------------------------------------------------------------------------
    // #region Private Methods
    // -------------------------------------------------------------------------------------------------

    private fixInputContainerPosition() {
        const rootPosition =
            this.blotFormatter.quill.root.getBoundingClientRect();
        let containerPosition = this.containerEl.getBoundingClientRect();

        // ensure all edges of container are inside of quill bounds
        if (
            rootPosition.bottom <= containerPosition.bottom ||
            rootPosition.bottom <=
                containerPosition.bottom - CONTAINER_OFFSET_BOTTOM
        ) {
            this.containerEl.style.bottom = "0";
        } else {
            this.containerEl.style.bottom = `${CONTAINER_OFFSET_BOTTOM}px`;
        }

        // set left and right values to initial
        this.containerEl.style.left = "50%";
        this.containerEl.style.right = "unset";

        // get updated container position
        containerPosition = this.containerEl.getBoundingClientRect();

        if (rootPosition.left >= containerPosition.left) {
            this.containerEl.style.left = "80%";
        } else if (rootPosition.right <= containerPosition.right) {
            this.containerEl.style.left = "unset";
            this.containerEl.style.right = "0";
        }
    }

    private getDebouncedFixOverlayPosition() {
        let timeout: number | undefined;
        const callback = this.fixInputContainerPosition.bind(this);
        const handler = function () {
            const callLater = function () {
                timeout = undefined;
                callback();
            };

            if (timeout != null) {
                clearTimeout(timeout);
            }

            // if we don't use window.setTimeout, TS tries
            // to use NodeJS.setTimeout which has the wrong param type
            timeout = window.setTimeout(callLater, 250);
        };

        return handler.bind(this);
    }

    private getImgAltText(): string | undefined {
        const img =
            this.formatter.currentSpec?.getTargetElement() as HTMLImageElement;
        if (img == null) {
            return undefined;
        }

        return img.alt;
    }

    private setImgAltText(altText: string) {
        const img =
            this.formatter.currentSpec?.getTargetElement() as HTMLImageElement;
        if (img == null) {
            return;
        }

        img.alt = altText;
    }

    private setInputValue(inputValue?: string) {
        if (StringUtils.isEmpty(inputValue)) {
            return;
        }

        this.inputEl.value = inputValue!;
    }

    private onApply(e: Event) {
        const input = e.target as HTMLInputElement;
        this.setImgAltText(input.value);
    }

    private static buildAltTextInput(inputValue?: string) {
        const container = document.createElement("div");
        container.style.display = "flex";
        container.style.backgroundColor = "white";
        container.style.padding = "4px";
        container.style.boxShadow = "0px 7px 20px #646a82";
        container.style.borderRadius = "4px";
        container.style.width = "300px";
        container.style.marginLeft = "-150px";
        container.style.position = "absolute";
        container.style.left = "50%";
        container.style.bottom = `${CONTAINER_OFFSET_BOTTOM}px`;

        const input = document.createElement("input");
        input.setAttribute("data-quill-alt-text-input", "true");
        input.placeholder = "Alt text...";
        input.style.padding = "8px";
        input.style.fontSize = "12px";
        if (StringUtils.hasValue(inputValue)) {
            input.value = inputValue;
        }

        container.appendChild(input);

        return { container, input };
    }

    // #endregion Private Methods
}
