import UserRecord from "models/view-models/user-record";
import SearchQuery from "utilities/interfaces/search-query";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import RoleType from "utilities/enumerations/role-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface AdminUserListQueryParams extends SearchQuery {
    /**
     * Filter out users that have a role matching the specified RoleType(s)
     */
    excludeRoleTypes?: RoleType[];

    /**
     * Filter down to only users that have a role matching the specified RoleType(s)
     */
    includeRoleTypes?: RoleType[];
}

export interface AdminUserListPathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "admin/users";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const AdminUserService = {
    get: ServiceFactory.get<UserRecord, AdminUserListPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGet: ServiceHookFactory.useGet<UserRecord, AdminUserListPathParams>(
        resourceType,
        resourceEndpoint
    ),
    list: ServiceFactory.list<UserRecord, AdminUserListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<UserRecord, AdminUserListQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AdminUserService;

// #endregion Exports
