import SectionRecord from "models/view-models/section-record";
import TiaRecord from "models/view-models/tia-record";
import EnhancedContentPanel from "organisms/enhanced-content/enhanced-content-panel";
import IndicatorIcons from "organisms/indicator-buttons/indicator-icons";
import React from "react";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import useModalActions from "utilities/hooks/use-modal-actions";
import SectionDetailActions from "./section-detail-actions";

interface SectionDetailContentProps {
    allowSectionDetailActions?: boolean;
    sectionLabel: JSX.Element | undefined;
    cssBaseClass: string;
    handleDiffPanelOpen: () => void;
    handleTiaPanelOpen: (tiaExternalId: string) => void;
    section: SectionRecord;
    tias: TiaRecord[];
    titleClassName: string;
}
const SectionDetailContent = (props: SectionDetailContentProps) => {
    const {
        allowSectionDetailActions = true,
        cssBaseClass,
        sectionLabel,
        handleDiffPanelOpen,
        handleTiaPanelOpen,
        section,
        tias,
        titleClassName,
    } = props;

    const {
        enhancedContentForCurrentCaapsItem,
        enhancedContentResourcesForCurrentCaapsItem,
    } = useEnhancedContent({
        externalId: section.externalId,
    });

    const {
        isOpen: enhancedContentPanelOpen,
        handleOpen: handleEnhancedContentModalOpen,
        handleClose: handleEnhancedContentModalClose,
    } = useModalActions();

    return (
        <React.Fragment>
            <span className={titleClassName}>
                <IndicatorIcons
                    tias={tias}
                    changes={section.changes}
                    handleDiffPanelOpen={handleDiffPanelOpen}
                    handleTiaClick={handleTiaPanelOpen}
                    notClickable={!allowSectionDetailActions}
                />
                <span>
                    {sectionLabel}
                    {section.getTitle()}
                </span>
                {allowSectionDetailActions && (
                    <SectionDetailActions
                        resources={enhancedContentResourcesForCurrentCaapsItem}
                        section={section}
                        onEditEnhancedContentClick={
                            handleEnhancedContentModalOpen
                        }
                        onEditEnhancedContentClose={
                            handleEnhancedContentModalClose
                        }
                        showEnhancedContentModal={enhancedContentPanelOpen}
                        handleDiffPanelOpen={handleDiffPanelOpen}
                    />
                )}
            </span>
            <div className={`${cssBaseClass}__body`}>{section.getBody()}</div>
            {enhancedContentForCurrentCaapsItem != null && (
                <div className={`${cssBaseClass}__enhanced-content`}>
                    <EnhancedContentPanel
                        enhancedContent={enhancedContentForCurrentCaapsItem}
                        onEditClick={handleEnhancedContentModalOpen}
                        resources={enhancedContentResourcesForCurrentCaapsItem}
                        resourcesLoading={false}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default SectionDetailContent;
