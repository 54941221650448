/**
 *   Corresponds to matching alert banner styling.
 */
enum AlertLevels {
    Error = "error",
    Info = "info",
    Success = "success",
    Warning = "warning",
}

export default AlertLevels;
