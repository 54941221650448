import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import JobRecord from "models/view-models/job-record";
import React, { useEffect, useState } from "react";
import { WorkerNames } from "utilities/enumerations/worker-names";
import FreeAccessPublicationAdminJobStatusesTable from "./free-access-publications-admin-job-statuses-table";
import { FreeAccessPublicationAdminJobStatusesTableRowProps } from "./free-access-publications-admin-job-statuses-row";
import useJobsByName from "utilities/hooks/domain/jobs/use-jobs-by-names";
import AdminFreeAccessService from "utilities/services/admin/free-access/admin-free-access-service";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";

const CSS_CLASS_NAME = "c-publication-batch-reprocess-statuses";

const FreeAccessPublicationAdminJobStatuses: React.FC = () => {
    const workerNames = [
        WorkerNames.FreeAccessPublicationExtractPages,
        WorkerNames.FreeAccessPublicationExtractTableOfContents,
    ];

    const { refresh: refreshJobs, resultObject: jobs } = useJobsByName({
        workerNames,
        reduceScope: true,
    });

    const publicationIds = jobs.map((j) => getPublicationId(j));

    const disinctPublicationIds = publicationIds.filter(
        (id, idx) => id != null && publicationIds.indexOf(id) === idx
    ) as number[];

    const { publications } = usePublicationIds(disinctPublicationIds);
    const jobRows = buildRows(jobs, publications);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <h2>Batch Actions From The Past 7 Days</h2>
                <Button
                    style={ButtonStyles.Secondary}
                    onClick={refreshJobs}
                    cssClassName={`${CSS_CLASS_NAME}__bulk-function-button`}>
                    Refresh
                </Button>
            </div>
            <FreeAccessPublicationAdminJobStatusesTable rows={jobRows} />
        </div>
    );
};

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

const usePublicationIds = (ids: number[]) => {
    const { list } = AdminFreeAccessService.useListByIds();
    const BATCH_SIZE = 25;
    const [publications, setPublications] = useState<
        FreeAccessPublicationRecord[]
    >([]);

    useEffect(() => {
        if (ids.length === 0) {
            return;
        }

        const getPublications = async () => {
            try {
                const publications = await list({ ids }, "ids", BATCH_SIZE);

                setPublications(publications.resultObjects);
            } catch (error) {
                console.error(error);
            }
        };

        getPublications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ids.length, list]);

    return { publications };
};

const buildRow = (
    job: JobRecord,
    publications: FreeAccessPublicationRecord[]
): FreeAccessPublicationAdminJobStatusesTableRowProps => {
    const publicationId = getPublicationId(job);
    const publication = publications.find((p) => p.id === publicationId);

    const row = {
        workerName:
            job?.workerName?.replace("Free Access Publication ", "") ?? "",
        fileName: getPublicationFileName(job),
        status: job.status,
        createdOn: job.createdOn,
    };

    if (job.workerName === WorkerNames.FreeAccessPublicationExtractPages) {
        return {
            ...row,
            totalPages: publication?.totalPages,
            isPublished: publication?.isPublished,
        };
    }

    return row;
};

const buildRows = (
    jobs: JobRecord[],
    publications: FreeAccessPublicationRecord[]
): FreeAccessPublicationAdminJobStatusesTableRowProps[] => {
    const jobRows = jobs.map((job) => buildRow(job, publications));
    return sortJobRows(jobRows);
};

const getPublicationFileName = (jobRecord: JobRecord): string => {
    let workerArgs = jobRecord?.workerArgs ?? "";
    const fileNameSplit = JSON.parse(workerArgs)[0].Data?.toString().split("/");
    return fileNameSplit.pop();
};

const getPublicationId = (job: JobRecord) => {
    const index =
        job.workerName === WorkerNames.FreeAccessPublicationExtractPages
            ? 2
            : 1;
    const workerArgs = JSON.parse(job?.workerArgs ?? "");

    if (!Array.isArray(workerArgs)) {
        return null;
    }

    const publicationId = workerArgs.at(index)?.Data;

    if (typeof publicationId !== "number" || isNaN(publicationId)) {
        return null;
    }

    return publicationId;
};

const sortJobRows = (
    jobRows: FreeAccessPublicationAdminJobStatusesTableRowProps[]
) => {
    return [...jobRows].sort((a, b) => {
        const aDate = new Date(a?.createdOn || "").valueOf();
        const bDate = new Date(b?.createdOn || "").valueOf();

        return bDate - aDate;
    });
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FreeAccessPublicationAdminJobStatuses;

// #endregion Exports
