import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { useState, useCallback, useEffect } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import UserConfigurationRecord from "models/view-models/user-configuration-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseUserConfigurationResult
    extends ServiceHookResult<UserConfigurationRecord> {
    /**
     * Function for the consumer to call to trigger a refresh of the data gathered by this hook
     */
    refresh: () => void;
}

// #endregion Interfaces

/**
 * Custom hook for getting the current user's configuration status to check manually
 * Note: will not cause a redirect and will not update GlobalState
 */
export default function useUserConfiguration(): UseUserConfigurationResult {
    const { globalState, setGlobalState } = useGlobalState();
    const { get } = UserConfigurationService.useGet();
    const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
    const [userConfiguration, setUserConfiguration] =
        useState<UserConfigurationRecord>(new UserConfigurationRecord());
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { handlePageLoadError, pageErrors: errors } = usePageErrors();
    const refresh = useCallback(() => {
        setLoaded(false);
        setLoading(false);
        setRefreshFlag((refreshFlag: boolean) => !refreshFlag);
    }, [setRefreshFlag]);

    useEffect(() => {
        if (loading || loaded) {
            return;
        }

        const listUserConfigurations = async () => {
            // If the user isn't logged in there is nothing to load.
            if (!globalState.isAuthenticated()) {
                setLoaded(true);
                return;
            }

            try {
                setLoading(true);
                const response = await get(
                    {},
                    {
                        returnForbiddenIfErrored: false,
                    }
                );
                setUserConfiguration(
                    new UserConfigurationRecord(response.resultObject)
                );
                setLoaded(true);
                setLoading(false);
            } catch (error) {
                setLoaded(true);
                setLoading(false);
            }
        };

        listUserConfigurations();
    }, [
        get,
        globalState,
        handlePageLoadError,
        loaded,
        loading,
        refreshFlag,
        setGlobalState,
    ]);

    return {
        errors,
        loaded,
        loading,
        refresh,
        resultObject: userConfiguration,
    };
}
