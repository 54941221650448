import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SuperscriptProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------
const className = "c-code-change -deletion";

const Superscript: React.FunctionComponent<SuperscriptProps> = (
    props: SuperscriptProps
) => {
    const isDeleted = props.diffchanged === XmlChangeNotationConstants.DELETION;

    return (
        <span className={isDeleted ? className : ""}>
            <sup>{props.children}</sup>
        </span>
    );
};

// #endregion Component

export default Superscript;
