import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSolutionPublishPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const resourceType = SolutionRecord;
const resourceEndpoint = "admin/situational-navigation/solutions/:id/publish";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminSolutionPublishService = {
    publish: ServiceFactory.update<
        SolutionRecord,
        AdminSolutionPublishPathParams
    >(resourceType, resourceEndpoint),
    usePublish: ServiceHookFactory.useUpdate<
        SolutionRecord,
        AdminSolutionPublishPathParams
    >(resourceType, resourceEndpoint),
    unpublish:
        ServiceFactory.delete<AdminSolutionPublishPathParams>(resourceEndpoint),
    useUnpublish:
        ServiceHookFactory.useDelete<AdminSolutionPublishPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminSolutionPublishService;

// #endregion Exports
