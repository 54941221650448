import { PropsWithChildren, useState } from "react";
import LinkPreviewContextRecord from "models/view-models/link-preview-context-record";
import LinkPreviewContext from "utilities/contexts/link-preview/use-link-preview-context";
import React from "react";
import LinkPreviewModal from "organisms/link-preview/link-preview-modal";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface LinkPreviewContextProviderProps {}

// -----------------------------------------------------------------------------------------
// #region Provider
// -----------------------------------------------------------------------------------------

export const LinkPreviewContextProvider: React.FC<
    PropsWithChildren<LinkPreviewContextProviderProps>
> = (props: PropsWithChildren<LinkPreviewContextProviderProps>) => {
    const [linkPreview, setLinkPreview] = useState(
        new LinkPreviewContextRecord()
    );

    return (
        <LinkPreviewContext.Provider value={[linkPreview, setLinkPreview]}>
            <LinkPreviewModal />
            {props.children}
        </LinkPreviewContext.Provider>
    );
};

// #endregion Provider
