import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import React from "react";

const BASE_CLASS = "c-invite-content-header";

interface InviteContentHeaderProps {
    groupName: string;
    groupInfoTitle: string;
    children: any;
}

const InviteContentHeader = ({
    groupName,
    groupInfoTitle,
    children,
}: InviteContentHeaderProps) => {
    return (
        <div className={`${BASE_CLASS}`}>
            <Heading
                cssClassName={`${BASE_CLASS}__title`}
                priority={HeadingPriority.One}>
                {children}
            </Heading>
            <div className={`${BASE_CLASS}__team`}>
                <span>{groupInfoTitle}:</span>
                <span>{groupName}</span>
            </div>
        </div>
    );
};

export default InviteContentHeader;
