import { useLocalization } from "utilities/hooks/use-localization";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import FilterLabel from "molecules/filter-label/filter-label";
import SearchForm from "molecules/forms/search-form";
import AddUserCollectionModal from "organisms/my-link/user-collections/add-user-collection-modal";
import UserCollectionList from "organisms/my-link/user-collections/user-collection-list";
import React, { useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useUserCollections from "utilities/hooks/domain/users/user-collections/use-user-collections";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import { SkipNavContent } from "@reach/skip-nav";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import RouteTabs from "molecules/route-tabs/route-tabs";
import { MyLinkUtils } from "pages/my-link/my-link-utils";
import { NestedRoutesProps } from "utilities/routing/nested-route";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionsTabProps extends NestedRoutesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const META_TAGS: Array<MetaTag> = [
    {
        name: MetaTagTypes.Title,
        content: AppNameTm,
    },
    {
        name: MetaTagTypes.Description,
        content: `${AppNameTm}: User Collections`,
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserCollectionsTab: React.FC<UserCollectionsTabProps> = (
    props: UserCollectionsTabProps
) => {
    const BASE_CLASS_NAME = "c-user-collections";

    useHeaderData({ metaTags: META_TAGS });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchText, setSearchText] = useState(searchInputValue);
    const { t } = useLocalization<CultureResources>();

    const { globalState } = useGlobalState();
    const currentUserId = globalState.currentIdentity?.userId();
    const currentGroupId = globalState.currentIdentity?.getCurrentTeam()?.id;

    const { resultObject, refresh, loading } = useUserCollections(
        currentUserId,
        currentGroupId,
        searchText
    );

    const isEmpty = CollectionUtils.isEmpty(resultObject);

    const showEmptyState =
        !loading && isEmpty && StringUtils.isEmpty(searchText);

    const showZeroSearchResults =
        !loading && isEmpty && StringUtils.hasValue(searchText);

    const isTabletOrSmaller = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    const emptyStateMessage = showEmptyState ? t("collection_plural") : "";
    const addButtonText = isTabletOrSmaller
        ? t("newItem").trimEnd()
        : t("newItem", { item: t("collection") });

    const noResultsLabel = showZeroSearchResults
        ? t("noSearchResultsBanner-title")
        : "";
    const tryAgainMessage = showZeroSearchResults
        ? t("noSearchResultsBanner-subTitle")
        : "";

    const loaderText = t("loadingItem", {
        item: t("collection_plural"),
    });

    const searchPlaceholder = t("action-searchByFields", {
        fields: t("field-title").toLowerCase(),
    });

    const handleClearFilters = () => {
        setSearchInputValue("");
        setSearchText("");
    };

    const handleModalClose = () => {
        refresh();
        setIsModalOpen(false);
    };

    const handleModalOpen = () => setIsModalOpen(true);

    const handleSearchFieldChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchInputValue(e.target.value);
        return;
    };

    const handleWithSearch = (e: React.FormEvent | React.MouseEvent) => {
        e.preventDefault();

        setSearchText(searchInputValue);
        refresh();
    };

    return (
        <>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={BASE_CLASS_NAME}>
                    <div className={`${BASE_CLASS_NAME}__headerWrapper`}>
                        <Heading
                            cssClassName={`${BASE_CLASS_NAME}__heading`}
                            priority={HeadingPriority.Five}>
                            MyLiNK
                        </Heading>
                        <RouteTabs
                            redirectIfNotFound={false}
                            routes={props.routes}
                            tabs={MyLinkUtils.getTabs(t)}
                        />
                    </div>
                    <div className={`${BASE_CLASS_NAME}__header`}>
                        {!showEmptyState && (
                            <SearchForm
                                accessibleText={searchPlaceholder}
                                buttonIcon={Icons.Search}
                                id="collection-search"
                                onClear={handleClearFilters}
                                onSearchClick={handleWithSearch}
                                onSearchTextChange={handleSearchFieldChange}
                                onSubmit={handleWithSearch}
                                placeholder={searchPlaceholder}
                                searchText={searchInputValue}
                                size={SearchFormSizes.Small}
                                style={SearchFormStyles.Tertiary}
                            />
                        )}
                        {
                            //if
                            CollectionUtils.hasValues(resultObject) && (
                                <Button
                                    cssClassName="-icon-left"
                                    onClick={handleModalOpen}
                                    size={ButtonSizes.Small}
                                    style={ButtonStyles.Tertiary}>
                                    <Icon type={Icons.Plus} />
                                    {addButtonText}
                                </Button>
                            )
                        }
                    </div>
                    <div className={`${BASE_CLASS_NAME}__body`}>
                        {loading && <Loader accessibleText={loaderText} />}
                        {StringUtils.hasValue(searchText) && (
                            <FilterLabel
                                filters={[searchText]}
                                itemName={t("collection")}
                                numberOfResults={CollectionUtils.length(
                                    resultObject ?? []
                                )}
                                onClear={handleClearFilters}
                            />
                        )}
                        {!loading && (
                            <UserCollectionList
                                userCollections={resultObject ?? []}
                            />
                        )}
                        {showEmptyState && (
                            <div className={`c-no-results-banner`}>
                                <Paragraph size={ParagraphSizes.Large}>
                                    {emptyStateMessage}
                                </Paragraph>
                                <Button
                                    cssClassName="-icon-left"
                                    size={ButtonSizes.Small}
                                    style={ButtonStyles.TertiaryAlt}
                                    onClick={handleModalOpen}>
                                    <Icon type={Icons.Plus} />
                                    {addButtonText}
                                </Button>
                            </div>
                        )}
                        {showZeroSearchResults && (
                            <div className={`c-no-results-banner -secondary`}>
                                <Paragraph size={ParagraphSizes.Large}>
                                    {noResultsLabel}
                                </Paragraph>
                                <Paragraph size={ParagraphSizes.Small}>
                                    {tryAgainMessage}
                                </Paragraph>
                            </div>
                        )}
                        <AddUserCollectionModal
                            closeDialog={handleModalClose}
                            isVisible={isModalOpen}
                        />
                    </div>
                </div>
            </SkipNavContent>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionsTab;

// #endregion Exports
