import PublicationRecord from "models/view-models/publication-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminPublicationPublishPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const resourceType = PublicationRecord;
const resourceEndpoint = "admin/publications/:id/publish";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminPublicationPublishService = {
    publish: ServiceFactory.update<
        PublicationRecord,
        AdminPublicationPublishPathParams
    >(resourceType, resourceEndpoint),
    usePublish: ServiceHookFactory.useUpdate<
        PublicationRecord,
        AdminPublicationPublishPathParams
    >(resourceType, resourceEndpoint),
    delete: ServiceFactory.delete<AdminPublicationPublishPathParams>(
        resourceEndpoint
    ),
    useDelete:
        ServiceHookFactory.useDelete<AdminPublicationPublishPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminPublicationPublishService;

// #endregion Exports
