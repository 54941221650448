import PublicationRecord from "models/view-models/publication-record";
import { useCallback, useState } from "react";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook to wrap logic for updating a publication.
 * NOTE: wrap callbacks in useCallback
 * @param publication
 * @param setPublication
 * @param onSuccess
 * @param onError
 */
export default function useUpdatePublication(
    publication: PublicationRecord | undefined,
    onSuccess?: (publication: PublicationRecord) => void,
    onError?: () => void
) {
    const { update: updatePublicationApi } =
        AdminPublicationService.useUpdate();

    const [saving, setSaving] = useState(false);

    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        ToastManager.error("There was an issue updating the publication.");
    }, [onError]);

    const handleSuccess = useCallback(
        (publication: PublicationRecord) => onSuccess?.(publication),
        [onSuccess]
    );

    const updatePublication = useCallback(
        async (pub?: PublicationRecord) => {
            setSaving(true);

            const publicationToUpdate = pub ?? publication;

            try {
                const result = await updatePublicationApi(
                    publicationToUpdate!,
                    {
                        id: publicationToUpdate!.id!,
                    }
                );
                if (result.resultObject == null) {
                    setSaving(false);
                    return;
                }
                handleSuccess(result.resultObject);
            } catch (e) {
                handleError();
            }

            setSaving(false);
        },
        [handleError, handleSuccess, publication, updatePublicationApi]
    );

    return {
        saving,
        updatePublication,
    };
}
