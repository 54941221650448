import Loader from "atoms/loaders/loader";
import LegacyPublicationPageRecord from "models/view-models/legacy-publication-page-record";
import LegacyPublicationRecord from "models/view-models/legacy-publication-record";
import UnorderedList from "molecules/lists/unordered-list";
import ImageViewer from "organisms/image-viewer/image-viewer";
import React, { useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useTableOfContents from "utilities/hooks/domain/publications/use-table-of-contents";
import usePreventRightClick from "utilities/hooks/use-prevent-right-click";
import { t } from "utilities/localization-utils";
interface LegacyPublicationContentProps {
    legacyPublication: LegacyPublicationRecord;
    errors: string[];
    loading: boolean;
}

const LegacyPublicationContent: React.FC<LegacyPublicationContentProps> = (
    props: LegacyPublicationContentProps
) => {
    const { legacyPublication, errors, loading } = props;
    const [currentPage, setCurrentPage] =
        useState<LegacyPublicationPageRecord>();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    usePreventRightClick();

    const { handleActiveIdChange, onFooterPageChange, activeId } =
        useTableOfContents({
            tableOfContentsEntries: legacyPublication.tableOfContents,
            currentPageNumber,
            pageEntries: legacyPublication.legacyPublicationPages,
        });

    useEffect(() => {
        setCurrentPage(
            legacyPublication.legacyPublicationPages?.find(
                (page) => page.pageNumber === currentPageNumber
            )
        );
    }, [currentPageNumber, legacyPublication.legacyPublicationPages]);

    useEffect(() => {
        if (
            CollectionUtils.hasValues(legacyPublication.legacyPublicationPages)
        ) {
            setCurrentPage(
                legacyPublication.legacyPublicationPages.find(
                    (page) => page.pageNumber === 1
                )
            );
        }
    }, [legacyPublication, loading]);

    const showPage =
        !CollectionUtils.hasValues(errors) &&
        !loading &&
        currentPage !== undefined;

    return (
        <React.Fragment>
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList listItems={errors} />
            )}
            {loading && <Loader accessibleText={t("loading")} />}
            {showPage && (
                <div className="c-image-viewer__wrapper">
                    <ImageViewer
                        tableOfContents={legacyPublication.tableOfContents}
                        pages={legacyPublication.legacyPublicationPages ?? []}
                        currentPageNumber={currentPageNumber}
                        setCurrentPageNumber={setCurrentPageNumber}
                        activeId={activeId}
                        setActiveId={handleActiveIdChange}
                        onFooterPageChange={onFooterPageChange}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default LegacyPublicationContent;
