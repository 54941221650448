import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import StringUtils from "utilities/string-utils";

interface ShowMoreProps {
    condensed?: boolean;
    showLessButtonText?: string;
    showMoreButtonText?: string;
    cssClassName?: string;
    children?: React.ReactNode;
}

const defaultProps: ShowMoreProps = {
    condensed: true,
    showLessButtonText: "Read less",
    showMoreButtonText: "Read more",
};

const ShowMore: React.FC<ShowMoreProps> = (props: ShowMoreProps) => {
    const { condensed, showLessButtonText, showMoreButtonText, children } =
        props;
    const [isCondensed, setCondensed] = useState<boolean>(condensed!);
    const [buttonText, setButtonText] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const showMoreContainer = useRef<HTMLDivElement>(null);
    const innerContentContainer = useRef<HTMLDivElement>(null);
    const CSS_BASE_CLASS = "c-show-more";
    const cssClassNames = [CSS_BASE_CLASS];

    if (!condensed || !isCondensed) {
        cssClassNames.push("-expanded");
    }

    if (!StringUtils.isEmpty(props.cssClassName)) {
        cssClassNames.push(props.cssClassName!);
    }

    const handleShowMore = () => {
        setCondensed(!isCondensed);
    };

    /**
     * In order to perform measurements, useLayoutEffect runs after React performs all DOM mutations
     */
    useLayoutEffect(() => {
        if (
            innerContentContainer.current == null ||
            showMoreContainer.current == null
        ) {
            return;
        }
        setShowMore(
            innerContentContainer.current.offsetHeight >
                showMoreContainer.current.offsetHeight
        );
    }, [children]);

    useEffect(() => {
        setButtonText(isCondensed ? showMoreButtonText : showLessButtonText);
    }, [showLessButtonText, showMoreButtonText, isCondensed]);

    return (
        <section>
            <div ref={showMoreContainer} className={cssClassNames.join(" ")}>
                <div
                    ref={innerContentContainer}
                    className={`${CSS_BASE_CLASS}__inner-content`}>
                    {props.children}
                </div>
            </div>

            <div
                className={`${CSS_BASE_CLASS}__button-container`}
                id={"show-more-button-container"}>
                {showMore && (
                    <Button onClick={handleShowMore} size={ButtonSizes.Small}>
                        {buttonText}
                    </Button>
                )}
            </div>
        </section>
    );
};

ShowMore.defaultProps = defaultProps;

export default ShowMore;
