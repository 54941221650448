import AnnexRecord from "models/view-models/annex-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import SectionService from "utilities/services/publications/annexes/sections/section-service";
import useServiceQuery from "utilities/hooks/queries/use-service-query";

export default function useSectionsQuery(
    annexId?: number,
    publicationId?: number,
    annex?: AnnexRecord,
    publication?: PublicationRecord
): ServiceHookResult<Array<SectionRecord> | undefined> {
    // -----------------------------------------------------------------------------------------
    // #region Private Members
    // -----------------------------------------------------------------------------------------
    const { list } = SectionService.useList();
    const enabled =
        !NumberUtils.isDefault(annexId) &&
        !NumberUtils.isDefault(publicationId);

    // #endregion Private Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------
    const sectionResult = useServiceQuery<SectionRecord>(
        [SectionService.key, annexId, publicationId],
        () => list({ annexId: annexId!, publicationId: publicationId! }),
        { enabled }
    );

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const sections = sectionResult.data?.resultObjects ?? [];

    const resultObject = sections.map((section: SectionRecord) =>
        section.with({ annex, publication })
    );

    const errors = sectionResult.data?.result?.listErrors() ?? [];

    // #endregion Public Properties

    return {
        resultObject,
        loading: sectionResult.isFetching,
        loaded: sectionResult.isFetched,
        errors: errors,
    };
}
