import type CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import type SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import { useEffect, useState } from "react";
import SituationService from "utilities/services/situational-navigation/situations/situation-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Situations Index wrapper based on related categories
 *
 * @param  {CategoryCollectionRecord} relatedCategories
 */
export default function useSituations(
    relatedCategories: CategoryCollectionRecord
) {
    const [loadingSituations, setLoadingSituations] = useState(false);
    const [situations, setSituations] = useState<Array<SituationRecord>>([]);

    const { list: listSituationsApi } = SituationService.useList();

    useEffect(() => {
        const loadSituations = async () => {
            if (!relatedCategories.hasValues()) {
                setSituations([]);
                return;
            }
            setLoadingSituations(true);

            try {
                const result = await listSituationsApi({
                    relatedCategoryIds: relatedCategories.mapToArray(
                        (c) => c.id!
                    ),
                });
                setSituations(result.resultObjects ?? []);
            } catch (e) {
                ToastManager.error("There was an issue loading solutions.");
            }

            setLoadingSituations(false);
        };
        loadSituations();
    }, [listSituationsApi, relatedCategories]);

    return { situations, loadingSituations };
}
