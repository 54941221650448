import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { ChapterNfpaLabelParams } from "interfaces/routing/chapter-nfpa-label-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import * as React from "react";
import { useMemo, useEffect } from "react";
import { match } from "react-router-dom";
import { useBreadcrumbsContext } from "utilities/contexts/use-breadcrumbs-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import useArticleQuery from "utilities/hooks/domain/publications/chapters/articles/use-article-query";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";
import useCurrentPublication from "utilities/contexts/use-current-publication";

interface ArticleBreadcrumbProps {
    match: match<
        NestedPublicationParams & ChapterNfpaLabelParams & NfpaLabelParams
    >;
}

const ArticleBreadcrumb: React.FunctionComponent<ArticleBreadcrumbProps> = (
    props: ArticleBreadcrumbProps
) => {
    const { match } = props;
    const { code, edition, nfpaLabel, id } = match.params;
    const { setPageTitle } = useHeaderData({ match });
    const { inModal } = useBreadcrumbsContext();

    const isMobile = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MaxWidth
    );

    const { resultObject: article, loading: articleLoading } = useArticleQuery({
        code,
        edition,
        nfpaLabel,
        id,
    });

    const { publication, loaded } = useCurrentPublication();

    const articleWithPublication = useDeepCompareMemo(
        () => article.with({ publication }),
        [article, publication]
    );

    const breadcrumbLabel = useMemo(() => {
        if (articleLoading || !loaded) {
            return "...";
        }

        if (!isMobile || inModal) {
            return articleWithPublication.getDisplayTitle();
        }

        return articleWithPublication.getTitleWithNfpaLabel();
    }, [articleLoading, articleWithPublication, inModal, isMobile, loaded]);

    useEffect(() => {
        setPageTitle(articleWithPublication.getDisplayTitle());
    }, [articleWithPublication, setPageTitle]);

    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">{breadcrumbLabel}</span>
        </Breadcrumb>
    );
};

export default ArticleBreadcrumb;
