import { ReactComponent as Book16 } from "assets/icons/16px/Book.svg";
import { ReactComponent as Bookmark } from "assets/icons/16px/Bookmark.svg";
import { ReactComponent as BookmarkOutline } from "assets/icons/16px/BookmarkOutlined.svg";
import { ReactComponent as Campaign } from "assets/icons/16px/Campaign.svg";
import { ReactComponent as Checkmark } from "assets/icons/16px/Checkmark.svg";
import { ReactComponent as ChevronDown16 } from "assets/icons/16px/Chevron-Down.svg";
import { ReactComponent as ChevronFirst } from "assets/icons/16px/Chevron-First.svg";
import { ReactComponent as ChevronLast } from "assets/icons/16px/Chevron-Last.svg";
import { ReactComponent as ChevronLeft16 } from "assets/icons/16px/Chevron-Left.svg";
import { ReactComponent as ChevronRight16 } from "assets/icons/16px/Chevron-Right.svg";
import { ReactComponent as ChevronUp16 } from "assets/icons/16px/Chevron-Up.svg";
import { ReactComponent as Close16 } from "assets/icons/16px/Close.svg";
import { ReactComponent as Collapse16 } from "assets/icons/16px/Collapse.svg";
import { ReactComponent as Delta16 } from "assets/icons/16px/Delta.svg";
import { ReactComponent as Document } from "assets/icons/16px/Document.svg";
import { ReactComponent as Dot16 } from "assets/icons/16px/Dot.svg";
import { ReactComponent as DragAndDrop } from "assets/icons/16px/DragAndDrop.svg";
import { ReactComponent as Edit } from "assets/icons/16px/Edit.svg";
import { ReactComponent as Enlarge } from "assets/icons/16px/Enlarge.svg";
import { ReactComponent as Expand16 } from "assets/icons/16px/Expand.svg";
import { ReactComponent as Eye } from "assets/icons/16px/Eye.svg";
import { ReactComponent as Home16 } from "assets/icons/16px/Home.svg";
import { ReactComponent as Information16 } from "assets/icons/16px/Information.svg";
import { ReactComponent as Lightbulb } from "assets/icons/16px/Lightbulb.svg";
import { ReactComponent as MediaPlay } from "assets/icons/16px/MediaPlay.svg";
import { ReactComponent as MediaPlay24 } from "assets/icons/24px/MediaPlay.svg";
import { ReactComponent as MediaPlayCircle } from "assets/icons/24px/MediaPlayCircle.svg";
import { ReactComponent as Menu } from "assets/icons/16px/Menu.svg";
import { ReactComponent as MetaPanel16 } from "assets/icons/16px/Meta-Panel.svg";
import { ReactComponent as MoreHorizontal16 } from "assets/icons/16px/More-Horizontal.svg";
import { ReactComponent as MoreVertical16 } from "assets/icons/16px/More-Vertical.svg";
import { ReactComponent as NewMaterial16 } from "assets/icons/16px/New-Material.svg";
import { ReactComponent as Note16 } from "assets/icons/16px/Note.svg";
import { ReactComponent as NoteColorable16 } from "assets/icons/16px/NoteColorable.svg";
import { ReactComponent as Pin16 } from "assets/icons/16px/Pin.svg";
import { ReactComponent as Placeholder16 } from "assets/icons/16px/Placeholder.svg";
import { ReactComponent as Plus16 } from "assets/icons/16px/Plus.svg";
import { ReactComponent as ReferenceLink } from "assets/icons/16px/ReferenceLink.svg";
import { ReactComponent as ReferencePanel16 } from "assets/icons/16px/ReferencePanel.svg";
import { ReactComponent as Share } from "assets/icons/16px/Share.svg";
import { ReactComponent as StarFilled } from "assets/icons/16px/StarFilled.svg";
import { ReactComponent as StarOutline } from "assets/icons/16px/StarOutline.svg";
import { ReactComponent as Trashcan } from "assets/icons/16px/Trashcan.svg";
import { ReactComponent as WifiOffline } from "assets/icons/16px/WifiOffline.svg";
import { ReactComponent as Book24 } from "assets/icons/24px/Book.svg";
import { ReactComponent as CheckmarkOvalFilled } from "assets/icons/24px/Checkmark-Oval-Filled.svg";
import { ReactComponent as CheckmarkOvalOutline } from "assets/icons/24px/Checkmark-Oval-Outline.svg";
import { ReactComponent as ChevronDown24 } from "assets/icons/24px/Chevron-Down.svg";
import { ReactComponent as ChevronLeft24 } from "assets/icons/24px/Chevron-Left.svg";
import { ReactComponent as ChevronRight24 } from "assets/icons/24px/Chevron-Right.svg";
import { ReactComponent as ChevronUp24 } from "assets/icons/24px/Chevron-Up.svg";
import { ReactComponent as Close24 } from "assets/icons/24px/Close.svg";
import { ReactComponent as DefaultAvatar24 } from "assets/icons/24px/Default-Avatar.svg";
import { ReactComponent as Folder } from "assets/icons/24px/Folder.svg";
import { ReactComponent as Home24 } from "assets/icons/24px/Home.svg";
import { ReactComponent as Information24 } from "assets/icons/24px/Information.svg";
import { ReactComponent as List } from "assets/icons/24px/List.svg";
import { ReactComponent as MetaPanel24 } from "assets/icons/24px/Meta-Panel.svg";
import { ReactComponent as MoreHorizontal24 } from "assets/icons/24px/More-Horizontal.svg";
import { ReactComponent as MoreVertical24 } from "assets/icons/24px/More-Vertical.svg";
import { ReactComponent as Placeholder24 } from "assets/icons/24px/Placeholder.svg";
import { ReactComponent as Plus24 } from "assets/icons/24px/Plus.svg";
import { ReactComponent as Preferences } from "assets/icons/24px/Preferences.svg";
import { ReactComponent as Question } from "assets/icons/24px/Question.svg";
import { ReactComponent as ReferencePanel24 } from "assets/icons/24px/ReferencePanel.svg";
import { ReactComponent as Scroll16 } from "assets/icons/16px/Scroll.svg";
import { ReactComponent as Scroll24 } from "assets/icons/24px/Scroll.svg";
import { ReactComponent as Search } from "assets/icons/24px/Search.svg";
import { ReactComponent as Settings } from "assets/icons/24px/Settings.svg";
import { ReactComponent as Sitnav } from "assets/icons/24px/Sitnav.svg";
import { ReactComponent as Stopwatch } from "assets/icons/24px/Stopwatch.svg";
import { ReactComponent as Trashcan24 } from "assets/icons/24px/Trashcan.svg";
import { ReactComponent as Warning } from "assets/icons/24px/Warning.svg";
import { ReactComponent as Enterprise } from "assets/icons/40px/Enterprise.svg";
import { ReactComponent as Individual } from "assets/icons/40px/Individual.svg";
import { ReactComponent as Logo } from "assets/icons/40px/Logo.svg";
import { ReactComponent as Team } from "assets/icons/40px/Team.svg";
import { ReactComponent as DownloadComplete } from "assets/icons/16px/Download-Complete.svg";
import { ReactComponent as DownloadRemove } from "assets/icons/16px/Download-Remove.svg";
import { ReactComponent as DownloadSync } from "assets/icons/16px/Download-Sync.svg";
import { ReactComponent as Download16 } from "assets/icons/16px/Download.svg";
import { ReactComponent as Download24 } from "assets/icons/16px/Download.svg";
import { ReactComponent as ToggleCard } from "assets/icons/16px/Toggle-Card.svg";
import { ReactComponent as TiaAdd } from "assets/icons/16px/TiaAdd.svg";
import { ReactComponent as TiaDelete } from "assets/icons/16px/TiaDelete.svg";
import { ReactComponent as TiaChange } from "assets/icons/16px/TiaChange.svg";
import { ReactComponent as ToggleTable } from "assets/icons/16px/Toggle-Table.svg";
import { ReactComponent as ToggleCard24 } from "assets/icons/24px/Toggle-Card.svg";
import { ReactComponent as ToggleTable24 } from "assets/icons/24px/Toggle-Table.svg";
import { ReactComponent as ZoomIn } from "assets/icons/24px/ZoomIn.svg";
import { ReactComponent as ZoomOut } from "assets/icons/24px/ZoomOut.svg";
import { Icons } from "atoms/constants/icons";
import { FunctionComponent, SVGProps } from "react";

export interface SvgIcon {
    key: keyof typeof Icons;
    base: FunctionComponent<SVGProps<SVGSVGElement>>;
    large: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const SvgIcons: SvgIcon[] = [
    { key: Icons.Book, base: Book16, large: Book24 },
    { key: Icons.Bookmark, base: Bookmark, large: Bookmark },
    { key: Icons.Campaign, base: Campaign, large: Campaign },

    {
        key: Icons.BookmarkOutline,
        base: BookmarkOutline,
        large: BookmarkOutline,
    },
    { key: Icons.Checkmark, base: Checkmark, large: Checkmark },
    {
        key: Icons.CheckmarkOvalFilled,
        base: CheckmarkOvalFilled,
        large: CheckmarkOvalFilled,
    },
    {
        key: Icons.CheckmarkOvalOutline,
        base: CheckmarkOvalOutline,
        large: CheckmarkOvalOutline,
    },
    { key: Icons.ChevronDown, base: ChevronDown16, large: ChevronDown24 },
    { key: Icons.ChevronLeft, base: ChevronLeft16, large: ChevronLeft24 },
    { key: Icons.ChevronFirst, base: ChevronFirst, large: ChevronFirst },
    { key: Icons.ChevronLast, base: ChevronLast, large: ChevronLast },
    { key: Icons.ChevronRight, base: ChevronRight16, large: ChevronRight24 },
    { key: Icons.ChevronUp, base: ChevronUp16, large: ChevronUp24 },
    { key: Icons.Close, base: Close16, large: Close24 },
    { key: Icons.Collapse, base: Collapse16, large: Collapse16 },
    { key: Icons.DefaultAvatar, base: DefaultAvatar24, large: DefaultAvatar24 },
    { key: Icons.Delta, base: Delta16, large: Delta16 },
    { key: Icons.Document, base: Document, large: Document },
    {
        key: Icons.Download,
        base: Download16,
        large: Download24,
    },
    {
        key: Icons.DownloadComplete,
        base: DownloadComplete,
        large: DownloadComplete,
    },
    { key: Icons.DownloadRemove, base: DownloadRemove, large: DownloadRemove },
    { key: Icons.DownloadSync, base: DownloadSync, large: DownloadSync },
    { key: Icons.Dot, base: Dot16, large: Dot16 },
    { key: Icons.DragAndDrop, base: DragAndDrop, large: DragAndDrop },
    { key: Icons.Edit, base: Edit, large: Edit },
    { key: Icons.Enlarge, base: Enlarge, large: Enlarge },
    { key: Icons.Enterprise, base: Enterprise, large: Enterprise },
    { key: Icons.Expand, base: Expand16, large: Expand16 },
    { key: Icons.Eye, base: Eye, large: Eye },
    { key: Icons.Folder, base: Folder, large: Folder },
    { key: Icons.Home, base: Home16, large: Home24 },
    { key: Icons.Individual, base: Individual, large: Individual },
    { key: Icons.Information, base: Information16, large: Information24 },
    { key: Icons.Lightbulb, base: Lightbulb, large: Lightbulb },
    { key: Icons.List, base: List, large: List },
    { key: Icons.Logo, base: Logo, large: Logo },
    { key: Icons.MediaPlay, base: MediaPlay, large: MediaPlay24 },
    { key: Icons.MetaPanel, base: MetaPanel16, large: MetaPanel24 },
    {
        key: Icons.MediaPlayCircle,
        base: MediaPlayCircle,
        large: MediaPlayCircle,
    },
    { key: Icons.Menu, base: Menu, large: Menu },
    {
        key: Icons.MoreHorizontal,
        base: MoreHorizontal16,
        large: MoreHorizontal24,
    },
    { key: Icons.MoreVertical, base: MoreVertical16, large: MoreVertical24 },
    { key: Icons.NewMaterial, base: NewMaterial16, large: NewMaterial16 },
    { key: Icons.Note, base: Note16, large: Note16 },
    { key: Icons.NoteColorable, base: NoteColorable16, large: NoteColorable16 },

    { key: Icons.Pin, base: Pin16, large: Pin16 },
    { key: Icons.Placeholder, base: Placeholder16, large: Placeholder24 },
    { key: Icons.Plus, base: Plus16, large: Plus24 },
    { key: Icons.Preferences, base: Preferences, large: Preferences },
    { key: Icons.Question, base: Question, large: Question },
    { key: Icons.ReferenceLink, base: ReferenceLink, large: ReferenceLink },
    {
        key: Icons.ReferencePanel,
        base: ReferencePanel16,
        large: ReferencePanel24,
    },
    { key: Icons.Scroll, base: Scroll16, large: Scroll24 },
    { key: Icons.Search, base: Search, large: Search },
    { key: Icons.Settings, base: Settings, large: Settings },
    { key: Icons.Share, base: Share, large: Share },
    { key: Icons.Sitnav, base: Sitnav, large: Sitnav },
    { key: Icons.StarFilled, base: StarFilled, large: StarFilled },
    { key: Icons.StarOutline, base: StarOutline, large: StarOutline },
    { key: Icons.Stopwatch, base: Stopwatch, large: Stopwatch },
    { key: Icons.Team, base: Team, large: Team },
    { key: Icons.Trashcan, base: Trashcan, large: Trashcan24 },
    { key: Icons.Warning, base: Warning, large: Warning },
    { key: Icons.WifiOffline, base: WifiOffline, large: WifiOffline },
    { key: Icons.TiaAdd, base: TiaAdd, large: TiaAdd },
    { key: Icons.TiaDelete, base: TiaDelete, large: TiaDelete },
    { key: Icons.TiaChange, base: TiaChange, large: TiaChange },
    { key: Icons.ToggleCard, base: ToggleCard, large: ToggleCard24 },
    { key: Icons.ToggleTable, base: ToggleTable, large: ToggleTable24 },
    { key: Icons.ZoomIn, base: ZoomIn, large: ZoomIn },
    { key: Icons.ZoomOut, base: ZoomOut, large: ZoomOut },
];
