import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import { useCallback } from "react";
import { useAtom, WritableAtom, SetStateAction } from "jotai";
import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------
interface ServiceHookResultAtom<T> {
    result: ServiceHookResult<T>;
    setError: (error: any) => void;
    setLoading: () => void;
    setSuccess: (resultObject: T, rowCount?: number) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

export default function useServiceHookResultAtom<T>(
    atom: WritableAtom<
        ServiceHookResult<T>,
        SetStateAction<ServiceHookResult<T>>
    >
): ServiceHookResultAtom<T> {
    const [result, setResult] = useAtom(atom);

    const setError = useCallback(
        (error: any) =>
            setResult((result) => ({
                ...result,
                errors: [...result.errors, ...getErrors(error)],
            })),
        [setResult]
    );

    const setLoading = useCallback(() => {
        setResult((prev) => ({
            ...prev,
            loading: true,
        }));
    }, [setResult]);

    const setSuccess = useCallback(
        (resultObject: T, rowCount?: number) =>
            setResult(
                ServiceHookResultFactory.successResult(resultObject, rowCount)
            ),
        [setResult]
    );

    return {
        result,
        setError,
        setLoading,
        setSuccess,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getErrors = (error: any): Array<string> => {
    if (typeof error === "string") {
        return [error];
    }

    if (RecordUtils.isRecord(error, ResultRecord)) {
        return [...error.listErrorMessages()];
    }

    if (error.message != null) {
        return [error.message];
    }

    return [];
};

// #endregion Functions
