import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import { useAsyncEffect } from "andculturecode-javascript-react";
import { List } from "immutable";
import AnnouncementContextRecord from "models/view-models/announcement-context-record";
import AnnouncementRecord from "models/view-models/announcement-record";
import AnnouncementModal from "organisms/announcements/announcement-modal";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import AnnouncementContext from "utilities/contexts/use-announcement-context";
import useAnnouncements from "utilities/hooks/domain/announcements/use-announcements";
import { useGlobalState } from "./use-global-state-context";
import { useLocation } from "react-router-dom";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AnnouncementContextProviderProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementContextProvider: React.FC<
    PropsWithChildren<AnnouncementContextProviderProps>
> = (props: PropsWithChildren<AnnouncementContextProviderProps>) => {
    const { children } = props;
    const [context, setContext] = useState(new AnnouncementContextRecord());
    const [announcementForUrl, setAnnouncementForUrl] = useState(
        new AnnouncementRecord()
    );
    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const { globalState } = useGlobalState();
    const hideAnnouncements =
        !globalState.currentIdentity?.isConfiguredWithActiveSubscription();

    const { acknowledge, list } = useAnnouncements();

    const location = useLocation();
    let currentLocation = useRef("");

    useAsyncEffect(
        async function loadAnnouncements() {
            if (loading || loaded || hideAnnouncements) {
                return;
            }

            setLoading(true);
            const result = await list();

            setLoaded(true);
            setLoading(false);
            if (CollectionUtils.isEmpty(result)) {
                return;
            }

            setContext((context: AnnouncementContextRecord) =>
                context.with({
                    announcements: List(result ?? []),
                })
            );
        },
        [setContext, loaded, loading, hideAnnouncements]
    );

    useEffect(
        function checkAnnouncementsForMatchingUrl() {
            if (CollectionUtils.isEmpty(context.announcements)) {
                return;
            }

            const matchingAnnouncement = context.announcements.find(
                (a) =>
                    StringUtils.hasValue(a.url) &&
                    location.pathname.startsWith(a.url)
            );
            if (
                matchingAnnouncement == null ||
                !matchingAnnouncement.shouldAlertUser()
            ) {
                return;
            }

            setAnnouncementForUrl(matchingAnnouncement);
            setShowModal(true);
        },
        [context, location, setAnnouncementForUrl]
    );

    useEffect(
        function reloadAnnouncementsOnPageChange() {
            if (currentLocation.current === location.pathname) {
                return;
            }

            currentLocation.current = location.pathname;
            setLoaded(false);
        },
        [currentLocation, location, setLoaded]
    );

    const handleCloseAnnouncementModal = (acknowledged: boolean) => {
        if (!acknowledged) {
            return;
        }

        const announcmentIndex = context.announcements.findIndex(
            (a) => a.id === announcementForUrl.id
        );
        const updatedRecord = announcementForUrl.with({
            acknowledged: acknowledge(announcementForUrl),
        });
        setContext((prev: AnnouncementContextRecord) =>
            prev.with({
                announcements: prev.announcements.update(
                    announcmentIndex,
                    () => updatedRecord
                ),
            })
        );

        setShowModal(false);
    };

    if (hideAnnouncements) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <AnnouncementContext.Provider value={[context, setContext]}>
            {children}
            {showModal && (
                <AnnouncementModal
                    announcement={announcementForUrl}
                    isVisible={showModal}
                    onClose={handleCloseAnnouncementModal}
                />
            )}
        </AnnouncementContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AnnouncementContextProvider;

// #endregion Exports
