import { useEffect, useCallback, useState } from "react";
import useIdentity from "utilities/hooks/use-identity";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import GlobalStateRecord from "models/view-models/global-state-record";
import { atom, useAtom } from "jotai";

/**
 * Custom hook to refresh the identity in globalState, with callback to trigger additional refreshes
 * on command.
 */
const loadingAtom = atom(false);

export default function useRefreshIdentity() {
    const { globalState, setGlobalState } = useGlobalState();
    const { buildCurrentIdentity } = useIdentity();

    const [loading, setLoading] = useAtom(loadingAtom);
    const [loaded, setLoaded] = useState(false);

    const updateIdentity = useCallback(async () => {
        try {
            setLoading(true);
            const identity = await buildCurrentIdentity(
                globalState.currentIdentity?.userLogin
            );

            if (identity == null) {
                return;
            }

            setGlobalState((globalState: GlobalStateRecord) =>
                globalState.setIdentity(identity)
            );
        } finally {
            setLoaded(true);
            setLoading(false);
        }
    }, [buildCurrentIdentity, globalState, setGlobalState, setLoading]);

    useEffect(() => {
        if (loaded || loading) {
            return;
        }

        updateIdentity();
    }, [loaded, loading, updateIdentity]);

    return { loading, refresh: updateIdentity };
}
