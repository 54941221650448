import RemoteImage from "atoms/images/remote-image";
import Loader from "atoms/loaders/loader";
import PublicationRecord from "models/view-models/publication-record";
import SectionCollectionRecord from "models/view-models/section-collection-record";
import SectionRecord from "models/view-models/section-record";
import SectionDetailWrapper from "organisms/section-detail/section-detail-wrapper";
import React from "react";
import useFile from "utilities/hooks/domain/files/use-file";
import { t } from "utilities/localization-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SharedSectionViewProps {
    publication: PublicationRecord;
    section: SectionRecord;
    sectionCollection: SectionCollectionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-shared-section-view";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SharedSectionView: React.FC<SharedSectionViewProps> = (
    props: SharedSectionViewProps
) => {
    const { publication, section, sectionCollection } = props;

    const { file, loadingFile } = useFile(publication.coverImageFileId);

    // For style purposes, share links will start no deeper than level 1
    let level = section.hasParentId() ? 1 : 0;
    let displaySections: Array<SectionRecord> = [];

    sectionCollection.sections.forEach((r: SectionRecord) => {
        sectionCollection.flattenRecursive(displaySections, r, level);
    });

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__publication-details`}>
                {(loadingFile || file != null) && (
                    <div
                        className={`${CSS_CLASS_NAME}__publication-details__cover-image-container`}>
                        {loadingFile && (
                            <Loader
                                accessibleText={t("loadingItem", {
                                    item: t("publicationCover"),
                                })}
                            />
                        )}
                        {!loadingFile && file != null && (
                            <RemoteImage file={file} />
                        )}
                    </div>
                )}
                <div className={`${CSS_CLASS_NAME}__publication-details__text`}>
                    <div
                        className={`${CSS_CLASS_NAME}__publication-details__text__edition`}>
                        {publication.getDisplayEdition()}
                    </div>
                    <div
                        className={`${CSS_CLASS_NAME}__publication-details__text__code-title`}>
                        {publication.getDisplayCodeAndTitle()}
                    </div>
                </div>
            </div>

            {displaySections.map((section: SectionRecord) => (
                <SectionDetailWrapper
                    key={section.id}
                    section={section}
                    sectionCollection={sectionCollection.sections}
                    allowSectionDetailActions={false}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SharedSectionView;

// #endregion Exports
