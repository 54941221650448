import React, { PropsWithChildren, useState } from "react";
import StickyBanner from "molecules/banners/sticky-banner";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StringUtils from "utilities/string-utils";
import PrivacyPolicyService from "utilities/services/privacy-policy-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PrivacyBannerProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-privacy-banner";
const ERROR_PRIVACY_ACCEPTANCE =
    "There was a problem accepting the privacy policy.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PrivacyBanner: React.FunctionComponent<
    PropsWithChildren<PrivacyBannerProps>
> = (props: PropsWithChildren<PrivacyBannerProps>) => {
    const { globalState } = useGlobalState();
    const { privacyUrl } = useNfpaUrlPaths();
    const [isAccepted, setIsAccepted] = useState(
        cookieExists(globalState.systemSettings?.privacyPolicyCookieName!)
    );

    if (isAccepted) {
        return null;
    }

    const handleClick = async () => {
        try {
            await PrivacyPolicyService.accept();
        } catch (error) {
            ToastManager.error(ERROR_PRIVACY_ACCEPTANCE);
        }

        setIsAccepted(true);
    };

    return (
        <StickyBanner cssClassName={CLASS_NAME}>
            <span>
                We have updated our <a href={privacyUrl}>privacy policy</a>,
                which includes information about how we collect, use and share
                information about you. By using this site, you consent to this
                policy and use of cookies.
            </span>
            <Button
                onClick={handleClick}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}>
                I Agree
            </Button>
        </StickyBanner>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const cookieExists = (name: string): boolean => {
    if (StringUtils.isEmpty(name)) {
        return false;
    }

    const privacyCookie = document.cookie
        .split(";")
        .find((e) => e.includes(name));

    return StringUtils.hasValue(privacyCookie);
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PrivacyBanner;

// #endregion Export
