import LocalizationUtils from "utilities/localization-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import MaintenanceModeMessage from "models/interfaces/maintenance-mode-message";

export default function useMaintenanceModeMessage() {
    const { globalState } = useGlobalState();
    const currentCultureCode =
        LocalizationUtils.currentCultureCode() ?? "en-US";

    const isActive =
        globalState.systemSettings?.maintenanceMode?.isActive ?? false;

    const hasAutoRefresh =
        globalState.systemSettings?.maintenanceMode?.hasAutoRefresh ?? false;

    const timeBetweenRefreshes =
        globalState.systemSettings?.maintenanceMode?.timeBetweenRefresh ?? 30;

    const maintenanceModeMessage: MaintenanceModeMessage | undefined =
        currentCultureCode === "en-US"
            ? globalState.systemSettings?.maintenanceMode?.message.enUs
            : globalState.systemSettings?.maintenanceMode?.message.esEs;

    return {
        maintenanceModeMessage,
        hasAutoRefresh,
        timeBetweenRefreshes,
        isActive,
    };
}
