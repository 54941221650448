import { Record } from "immutable";
import type Part from "models/interfaces/part";
import XmlUtils from "utilities/xml-utils";
import ExceptionSection from "organisms/section-detail-components/exception-section";
import InformationalNoteSection from "organisms/section-detail-components/informational-note-section";
import FigureSection from "organisms/section-detail-components/figure-section";
import StringUtils from "utilities/string-utils";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import { RecordUtils } from "andculturecode-javascript-core";
import CaapsRecordUtils from "utilities/caaps-record-utils";

const defaultValues: Part = RecordUtils.auditableDefaultValuesFactory<Part>({
    articleId: 0,
    body: "",
    chapterId: 0,
    displaySequence: 0,
    externalId: "",
    changes: "",
    label: "",
    publicationId: 0,
    title: "",
    titleAsPlainText: "",
});

export class PartRecord extends Record(defaultValues) implements Part {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Part) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no body is present, returns an empty string.
     *
     * @returns {(JSX.Element | string)}
     */
    public getBody(debug?: boolean): JSX.Element | string {
        // If we have no body, just return an empty string.
        if (StringUtils.isEmpty(this.body)) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            ...XmlUtils.publicationTableConverters,
            div: XmlUtils.xmlConverter(XmlUtils.FragmentWrapper),
            exception: XmlUtils.xmlConverter(ExceptionSection),
            fig: XmlUtils.xmlConverter(FigureSection),
            note: XmlUtils.xmlConverter(InformationalNoteSection),
        };

        const body = XmlUtils.convert(this.body!, xmlConverters, debug);
        return body;
    }

    /**
     * Gets the full 'display' title of the Part in the format of: {Label} {Title}
     * @example Part III. Portable Cables Over 600 Volts, Nominal
     */
    public getDisplayTitle(): string {
        if (!this.hasLabel()) {
            return this.getTitleAsPlainText();
        }

        return `${this.getLabel()} ${this.getTitleAsPlainText()}`;
    }

    /**
     * Returns an anchor href string to an element in the DOM representing this Part.
     */
    public getHashLink(): string {
        return `#${this.externalId}`;
    }

    /**
     * Returns roman numeral portion of the label field, or an empty string if no label is present
     *
     * @returns
     */
    public getLabel(): string {
        return this.hasLabel() ? this.label : "";
    }

    /**
     * Get the application route to view the current part in Book View
     */
    public getRoute(): string {
        return RouteUtils.getUrl(
            siteMap.publications.deprecated.article,
            {
                chapterId: this.chapterId,
                id: this.articleId,
                publicationId: this.publicationId,
            },
            null,
            this.getHashLink()
        );
    }

    /**
     * Returns the title stripped of XML.
     *
     * @returns
     */
    public getTitleAsPlainText(): string {
        return this.hasTitle() ? this.titleAsPlainText : "";
    }

    /**
     * Returns the title (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no title is present, returns an empty string.
     *
     * @returns {(JSX.Element | string)}
     */
    public getTitle(): string {
        // If we have no title, just return an empty string.
        if (StringUtils.isEmpty(this.title)) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        const title = XmlUtils.convert(this.title!, xmlConverters);
        return title;
    }

    /**
     * @returns Whether or not the record title or body XML has changes.
     * Uses XmlUtils.hasAttributeDeep to recursively check every element in the XML tree.
     */
    public doesTitleOrBodyHaveChanges(): boolean {
        const hasChanges = CaapsRecordUtils().doesTitleOrBodyHaveChanges(
            this.title,
            this.body,
            this.changes
        );

        return hasChanges;
    }

    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    /**
     * Returns whether or not the hit has a non-null, non-whitespace label value
     */
    public hasLabel(): boolean {
        if (this.label == null || this.label.trim().length === 0) {
            return false;
        }

        return true;
    }

    /**
     * Returns whether or not the hit has a non-null, non-whitespace title value
     */
    public hasTitle(): boolean {
        if (
            this.titleAsPlainText == null ||
            this.titleAsPlainText.trim().length === 0
        ) {
            return false;
        }

        return true;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Part>} values
     */
    public with(values: Partial<Part>): PartRecord {
        return new PartRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
