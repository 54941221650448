import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React, { useEffect } from "react";

const AzureLoginPage = () => {
    useEffect(() => {
        console.log("Azure redirect received", window.location.href);
    }, []);
    return <FullScreenTransition transitionText={""} />;
};

export default AzureLoginPage;
