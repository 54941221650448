import * as React from "react";
import Button, { ButtonTypes } from "atoms/buttons/button";
import TiaIndicatorIcon from "atoms/icons/tia-indicator-icon";
import TiaRecord from "models/view-models/tia-record";
import { ButtonStyles } from "atoms/constants/button-styles";
import TiaType from "models/enumerations/tia-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TiaIndicatorButtonProps {
    tias: TiaRecord[];
    handleTiaClick: (tiaExternalId: string) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const BASE_CSS_CLASS = "c-tia-indicator";

const TiaIndicatorButton: React.FunctionComponent<TiaIndicatorButtonProps> = (
    props: TiaIndicatorButtonProps
) => {
    const { tias = [], handleTiaClick } = props;

    const hasMultipleTias = tias.length > 1;

    return (
        <div
            className={`${BASE_CSS_CLASS} ${
                hasMultipleTias ? "-multipleTias" : ""
            } `}>
            <Button
                cssClassName={`${BASE_CSS_CLASS}__button`}
                style={ButtonStyles.None}
                type={ButtonTypes.Button}
                onClick={() => handleTiaClick(tias[0].externalId!)}>
                <TiaIndicatorIcon
                    type={
                        hasMultipleTias
                            ? TiaType.Change
                            : tias[0].type ?? TiaType.Change
                    }
                />
                {hasMultipleTias && (
                    <span className={`${BASE_CSS_CLASS}__count`}>
                        {tias.length}
                    </span>
                )}
            </Button>
        </div>
    );
};

// #endregion Component

export default TiaIndicatorButton;
