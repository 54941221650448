import React from "react";
import Button from "atoms/buttons/button";
import AnnouncementRecord from "models/view-models/announcement-record";
import AnnouncementService, {
    AnnouncementServiceErrorMessages,
} from "utilities/services/announcements/announcements-service";
import { ToastManager } from "utilities/toast/toast-manager";
import AnnouncementPublishService, {
    AnnouncementPublishServiceErrorMessages,
} from "utilities/services/admin/announcements/announcement-publish-service";
import PublishStatus from "models/enumerations/publish-status";
import Paragraph from "atoms/typography/paragraph";
import DateUtils from "utilities/date-utils";
import StringUtils from "utilities/string-utils";
import AnnouncementPublishStatusMenu from "organisms/admin/annoucements/announcement-publish-status-menu";
import UnsavedChangesPrompt from "molecules/unsaved-changes-prompt/unsaved-changes-prompt";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AnnouncementEditorFooterProps {
    announcement: AnnouncementRecord;
    isDirty: boolean;
    onAnnouncementUpdated?: () => void;
    validate: () => boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-announcement-editor-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementEditorFooter: React.FC<any> = (
    props: AnnouncementEditorFooterProps
) => {
    const { announcement, isDirty, onAnnouncementUpdated, validate } = props;

    const { create: AnnouncementCreateApi } = AnnouncementService.useCreate();
    const { update: AnnouncementUpdateApi } = AnnouncementService.useUpdate();
    const { update: AnnouncementPublishApi } =
        AnnouncementPublishService.usePublish();
    const { delete: AnnouncementUnpublishApi } =
        AnnouncementPublishService.useUnpublish();

    const handleSave = async () => {
        if (!validate()) {
            return false;
        }

        try {
            if (!announcement.isPersisted()) {
                const createResult = await AnnouncementCreateApi(announcement);
                if (createResult.result?.hasErrors()) {
                    ToastManager.error(AnnouncementServiceErrorMessages.Create);
                    return;
                }

                onAnnouncementUpdated?.();
                ToastManager.success("Announcement created successfully");
                return;
            }
        } catch (error) {
            ToastManager.error(
                "You cannot create an announcement because one already exists. Please clear cache and refresh the page."
            );
            return;
        }

        const updateResult = await AnnouncementUpdateApi(announcement, {
            id: announcement.id!,
        });

        if (updateResult.result?.hasErrors()) {
            ToastManager.error(AnnouncementServiceErrorMessages.Update);
            return;
        }

        onAnnouncementUpdated?.();
        ToastManager.success("Announcement saved successfully");
    };

    const handlePublishClick = async (withAlert: boolean = false) => {
        try {
            const publishReponse = await AnnouncementPublishApi(
                announcement,
                { id: announcement.id! },
                { withAlert: withAlert }
            );
            if (publishReponse.result?.hasErrors()) {
                ToastManager.error(
                    AnnouncementPublishServiceErrorMessages.Publish
                );
                return;
            }

            onAnnouncementUpdated?.();
            ToastManager.success("Announcement published successfully");
        } catch (error) {
            ToastManager.error(AnnouncementPublishServiceErrorMessages.Publish);
        }
    };

    const handleUnpublishClick = async () => {
        try {
            const unpublishReponse = await AnnouncementUnpublishApi(
                announcement.id!
            );
            if (unpublishReponse.result?.hasErrors()) {
                ToastManager.error(
                    AnnouncementPublishServiceErrorMessages.Unpublish
                );
                return;
            }

            onAnnouncementUpdated?.();
            ToastManager.success("Announcement unpublished successfully");
        } catch (error) {
            ToastManager.error(
                AnnouncementPublishServiceErrorMessages.Unpublish
            );
        }
    };

    const getStatusText = (): JSX.Element => {
        if (announcement.getPublishStatus() === PublishStatus.Published) {
            if (announcement.hasUnpublishedChanges()) {
                return (
                    <Paragraph cssClassName="-bold">
                        Unpublished Changes Saved
                    </Paragraph>
                );
            }

            return <Paragraph>Published</Paragraph>;
        }

        return <Paragraph>Changes Saved</Paragraph>;
    };

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const lastEditedDate = DateUtils.formatLastEditedDate(announcement);
    return (
        <div className={BASE_CLASS}>
            <div className={`${BASE_CLASS}__left`}>
                <Button onClick={handleSave}>
                    {announcement?.isPersisted()
                        ? "Save Changes"
                        : "Create Announcement"}{" "}
                </Button>
                {StringUtils.hasValue(lastEditedDate) && (
                    <div className={`${BASE_CLASS}__left__status-text`}>
                        {getStatusText()}
                        <Paragraph>{lastEditedDate}</Paragraph>
                    </div>
                )}
            </div>
            <div className={`${BASE_CLASS}__right`}>
                <AnnouncementPublishStatusMenu
                    announcement={announcement}
                    isDirty={isDirty}
                    onPublishClick={handlePublishClick}
                    onUnpublishClick={handleUnpublishClick}
                    recordLabel="announcement"
                />
                <UnsavedChangesPrompt
                    isDirty={isDirty}
                    message="Any unsaved changes will be lost."
                />
            </div>
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AnnouncementEditorFooter;

// #endregion Exports
