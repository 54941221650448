import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import { BillingCycleOption } from "constants/billing-cycle-options";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import SubscriptionType from "organisms/enums/subscription-type";
import React from "react";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";
import { t } from "utilities/localization-utils";

interface BillingCycleCardsProps {
    type: SubscriptionType | undefined;
    teamSize: SubscriptionTeamSize | undefined;
    handleSelect: (option: BillingCycleOption) => void;
    selectedBillingCycleOption: BillingCycleOption | undefined;
    errored: boolean;
}

const BASE_CSS_CLASSNAME = "c-billing-cycle-cards";

const BillingCycleCards = (props: BillingCycleCardsProps) => {
    const {
        type,
        teamSize,
        handleSelect,
        selectedBillingCycleOption,
        errored,
    } = props;

    const { getBillingCycleType } = useEcommercePrices();

    const billingCycleType = getBillingCycleType(type, teamSize);

    return (
        <>
            {billingCycleType.length > 0 && (
                <Heading cssClassName={`${BASE_CSS_CLASSNAME}__group-heading`}>
                    {t("selectYourBillingCycle")}
                </Heading>
            )}
            <div className={`${BASE_CSS_CLASSNAME}__card-wrapper`}>
                {billingCycleType.map((billingCycleOption) => (
                    <div
                        className={`${BASE_CSS_CLASSNAME}__card ${
                            billingCycleOption.isBestValue ? "-bestValue" : ""
                        }`}>
                        {billingCycleOption.isBestValue && (
                            <Heading
                                cssClassName={`${BASE_CSS_CLASSNAME}__heading`}>
                                {t("bestValue")}
                            </Heading>
                        )}
                        <div className={`${BASE_CSS_CLASSNAME}__content`}>
                            <span className={`${BASE_CSS_CLASSNAME}__price`}>
                                {billingCycleOption.price}
                            </span>
                            <span className={`${BASE_CSS_CLASSNAME}__cycle`}>
                                {billingCycleOption.cycle}
                            </span>
                            {billingCycleOption.autoRenew === "On" && (
                                <span
                                    className={`${BASE_CSS_CLASSNAME}__autorenew`}
                                    dangerouslySetInnerHTML={{
                                        __html: t("withAutoRenewal"),
                                    }}
                                />
                            )}
                        </div>
                        <Button
                            style={
                                selectedBillingCycleOption?.pin ===
                                billingCycleOption.pin
                                    ? ButtonStyles.Primary
                                    : ButtonStyles.Secondary
                            }
                            cssClassName={`${BASE_CSS_CLASSNAME}__button`}
                            onClick={() => handleSelect(billingCycleOption)}>
                            {selectedBillingCycleOption?.pin ===
                            billingCycleOption.pin
                                ? t("selected")
                                : t("select")}
                        </Button>
                    </div>
                ))}
            </div>
            {errored && (
                <div className={`${BASE_CSS_CLASSNAME}__errors`}>
                    <Icon type={Icons.Information} />
                    <span>{t("subscriptionCard-mustSelectBillingCycle")}</span>
                </div>
            )}
        </>
    );
};

export default BillingCycleCards;
