import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import { useCallback, useState } from "react";
import { siteMap } from "internal-sitemap";
import useDeepCompareEffect from "use-deep-compare-effect";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import ArticleService from "utilities/services/articles/article-service";
import ChapterService from "utilities/services/chapters/chapter-service";
import AnnexService from "utilities/services/publications/annexes/annex-service";
import PublicationService from "utilities/services/publications/publication-service";
import SectionService from "utilities/services/sections/section-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationRouteParams {
    publicationId?: number;
    chapterId?: number;
    annexId?: number;
    articleId?: number;
    sectionId?: number;
    groupBy?: string;
    isAdminPreview?: boolean;
    isDisabled?: boolean;
}

interface IncludeProperties {
    annex: boolean;
    article: boolean;
    chapter: boolean;
    section: boolean;
}
// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const NOT_FOUND = siteMap.errors.notFound;

// #endregion Constants

export default function usePublicationRoute(
    routeParams: PublicationRouteParams
): ServiceHookResult<string> {
    // -----------------------------------------------------------------------------------------
    // #region Private Properties
    // -----------------------------------------------------------------------------------------

    const includeProperties = getIncludeProperties(routeParams);

    // #endregion Private Properties

    // -----------------------------------------------------------------------------------------
    // #region Services
    // -----------------------------------------------------------------------------------------

    const { get: getPublication } = PublicationService.useGet();
    const { get: getChapter } = ChapterService.useGet();
    const { get: getAnnex } = AnnexService.useGet();
    const { get: getArticle } = ArticleService.useGet();
    const { get: getSection } = SectionService.useGet();

    // #endregion Services

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [result, setResult] = useState<ServiceHookResult<string>>(
        ServiceHookResultFactory.initialResult("")
    );

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    const getIncludes = useCallback(async () => {
        return await Promise.all([
            getPublication({ id: routeParams.publicationId }),

            includeProperties.annex
                ? getAnnex({
                      publicationId: routeParams.publicationId!,
                      id: routeParams.annexId!,
                  })
                : undefined,

            includeProperties.chapter
                ? getChapter({ id: routeParams.chapterId! })
                : undefined,

            includeProperties.article
                ? getArticle({ id: routeParams.articleId! })
                : undefined,

            includeProperties.section
                ? getSection({ id: routeParams.sectionId! })
                : undefined,
        ]);
    }, [
        getAnnex,
        getArticle,
        getChapter,
        getPublication,
        getSection,
        includeProperties,
        routeParams,
    ]);

    const getRouteAsync = useCallback(
        async (routeParams: PublicationRouteParams) => {
            if (routeParams.publicationId == null) {
                return NOT_FOUND;
            }

            setResult(ServiceHookResultFactory.loadingResult(""));

            try {
                const [
                    publicationResult,
                    annexResult,
                    chapterResult,
                    articleResult,
                    sectionResult,
                ] = await getIncludes();

                const anchor = new PublicationAnchorRecord({
                    ...routeParams,
                    annexNfpaLabel: annexResult?.resultObject?.nfpaLabel,
                    annexNumber: annexResult?.resultObject?.number,
                    articleNfpaLabel: articleResult?.resultObject?.nfpaLabel,
                    chapterNfpaLabel: chapterResult?.resultObject?.nfpaLabel,
                    externalId: sectionResult?.resultObject?.externalId,
                    publicationCode: publicationResult.resultObject?.code,
                    publicationEdition: publicationResult.resultObject?.edition,
                    publicationType: publicationResult.resultObject?.type,
                });

                const route = anchor.getRoute() ?? NOT_FOUND;

                setResult(ServiceHookResultFactory.successResult(route));
            } catch (error) {
                setResult(ServiceHookResultFactory.errorResult("", error));
            }
        },
        [getIncludes]
    );

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useDeepCompareEffect(() => {
        if (routeParams.isDisabled) {
            return;
        }

        getRouteAsync(routeParams);
    }, [routeParams]);

    // #endregion Side-Effects

    return { ...result };
}

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

function getIncludeProperties(
    params: PublicationRouteParams
): IncludeProperties {
    return {
        chapter: params.chapterId != null,
        annex: params.annexId != null,
        article: params.articleId != null,
        section: params.sectionId != null,
    };
}

// #endregion Private Functions
