import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import TrainingContractUserRole from "models/interfaces/training-contract-user-role";
import UserRoleRecord from "./user-role-record";
import TrainingContractRecord from "./training-contract-record";

const defautValues: TrainingContractUserRole =
    RecordUtils.auditableDefaultValuesFactory<TrainingContractUserRole>({
        trainingContractId: undefined,
        trainingContract: undefined,
        userRole: undefined,
        userRoleId: undefined,
    });

export default class TrainingContractUserRoleRecord
    extends Record(defautValues)
    implements TrainingContractUserRole
{
    constructor(params?: TrainingContractUserRole) {
        if (params == null) params = Object.assign({}, defautValues);

        if (params.trainingContract != null) {
            params.trainingContract = RecordUtils.ensureRecord(
                params.trainingContract,
                TrainingContractRecord
            );
        }

        if (params.userRole != null) {
            params.userRole = RecordUtils.ensureRecord(
                params.userRole,
                UserRoleRecord
            );
        }

        super(params);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<TrainingContractUserRole>} values
     */
    public with(
        values: Partial<TrainingContractUserRole>
    ): TrainingContractUserRole {
        return new TrainingContractUserRoleRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
