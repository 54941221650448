import UserRoleRecord from "models/view-models/user-role-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminUserRoleListQueryParams {
    userIds?: Array<number>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "admin/userroles";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserRoleRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AdminUserRoleService = {
    create: ServiceFactory.create<UserRoleRecord>(resourceType, baseEndpoint),
    delete: ServiceFactory.delete(resourceEndpoint),
    useCreate: ServiceHookFactory.useCreate<UserRoleRecord>(
        resourceType,
        baseEndpoint
    ),
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
    list: ServiceFactory.list<UserRoleRecord, AdminUserRoleListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useList: ServiceHookFactory.useList<
        UserRoleRecord,
        AdminUserRoleListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminUserRoleService;

// #endregion Exports
