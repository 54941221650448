import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";

const TableOfContentsAtom = atom<boolean>(false);

const useTableOfContentsAtom = () => {
    const [drawerIsOpen, setDrawerIsOpen] = useAtom(TableOfContentsAtom);

    const isTablet = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    useEffect(() => {
        setDrawerIsOpen(!isTablet);
    }, [isTablet, setDrawerIsOpen]);

    return {
        drawerIsOpen,
        setDrawerIsOpen,
    };
};

export default useTableOfContentsAtom;
