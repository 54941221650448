import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import TextInput, { TextInputProps } from "atoms/forms/text-input";
import Icon from "atoms/icons/icon";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import Form, { FormProps } from "molecules/forms/form";
import React, { useMemo, useState } from "react";
import { BrowserUtils } from "utilities/browser-utils";
import StringUtils from "utilities/string-utils";
import uuid from "uuid";
import { AriaRole } from "utilities/enumerations/aria-role";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-search-form";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SearchFormProps extends FormProps, Pick<TextInputProps, "disabled"> {
    accessibleText?: string;
    ariaRole?: AriaRole;
    buttonIcon?: Icons;
    clearButtonAccessibleText?: string;
    cssClearButtonClassName?: string;
    hintText?: string;
    id?: string;
    onClear?: () => void;
    onSearchClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSearchTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    searchText: string;
    style?: SearchFormStyles;
    size?: SearchFormSizes;
}

// #endregion Interfaces

const defaultProps: Partial<SearchFormProps> = {
    accessibleText: "Search",
    clearButtonAccessibleText: "Clear search",
    cssClearButtonClassName: "",
    buttonIcon: Icons.Search,
    style: SearchFormStyles.Primary,
    size: SearchFormSizes.Large,
};

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchForm: React.FunctionComponent<SearchFormProps> = (
    props: SearchFormProps
) => {
    const { dataTestId, disabled = false, size, style } = props;
    // stateful so it doesn't recompute on every render
    const [defaultId] = useState(`search-form-${uuid.v4()}`);

    const buttonStyle = useMemo(() => {
        switch (style) {
            case SearchFormStyles.Primary:
                return ButtonStyles.Primary;
            case SearchFormStyles.Secondary:
                return ButtonStyles.Secondary;
            case SearchFormStyles.Tertiary:
                return ButtonStyles.Tertiary;
            case SearchFormStyles.TertiaryAlt:
                return ButtonStyles.TertiaryAlt;
            default:
                return ButtonStyles.Primary;
        }
    }, [style]);
    const clearButtonStyle =
        buttonStyle === ButtonStyles.Primary
            ? ButtonStyles.Secondary
            : buttonStyle;

    const showClearButton = useMemo(
        () =>
            props.onClear != null &&
            !BrowserUtils.isIE() && // IE has a native clear button
            StringUtils.hasValue(props.searchText),
        [props.onClear, props.searchText]
    );

    const handleMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
        if (!BrowserUtils.isIE()) {
            return;
        }

        // on IE, we use a native clear button instead of our custom one
        // but since it doesn't fire a specific event, we check the mouseup
        // event and check the current value against the value that is set
        // after the event fires
        const input = e.target as HTMLInputElement;
        const oldValue = input.value;
        if (oldValue === "") {
            return;
        }

        setTimeout(() => {
            const newValue = input.value;
            if (newValue === "") {
                props.onClear?.();
            }
        }, 0);
    };

    return (
        <Form
            ariaRole={props.ariaRole}
            cssClassName={`${BASE_CLASS_NAME} ${style} ${size}`}
            dataTestId={dataTestId}
            onSubmit={props.onSubmit}>
            <TextInput
                disabled={disabled}
                hintText={props.hintText}
                id={props.id ?? defaultId}
                onChange={props.onSearchTextChange}
                onMouseUp={handleMouseUp}
                placeholder={props.placeholder}
                value={props.searchText}
            />
            <Button
                accessibleText={props.accessibleText}
                cssClassName={`${BASE_CLASS_NAME}__submit`}
                disabled={disabled}
                onClick={props.onSearchClick}
                size={ButtonSizes.Medium}
                style={buttonStyle}
                type={ButtonTypes.Submit}>
                <Icon type={props.buttonIcon!} size={IconSizes.Large} />
            </Button>
            {showClearButton && (
                <Button
                    accessibleText={props.clearButtonAccessibleText}
                    cssClassName={`${BASE_CLASS_NAME}__clear ${props.cssClearButtonClassName}`}
                    disabled={disabled}
                    onClick={props.onClear}
                    size={ButtonSizes.Medium}
                    style={clearButtonStyle}>
                    <Icon
                        cssClassName={"-close-icon"}
                        size={IconSizes.Large}
                        type={Icons.Close}
                    />
                </Button>
            )}
        </Form>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

SearchForm.defaultProps = defaultProps;
export default SearchForm;

// #endregion Exports
