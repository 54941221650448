import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import useTeams from "utilities/hooks/use-teams";
import { CollectionUtils } from "utilities/collection-utils";
import UnorderedList from "molecules/lists/unordered-list";
import Anchor from "atoms/anchors/anchor";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-subscription-detail-card";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionDetailCardTeamAdmins: React.FC = () => {
    const { t } = useLocalization<CultureResources>();

    const { teamAdmins } = useTeams();

    return (
        <>
            {CollectionUtils.hasValues(teamAdmins) && (
                <div className={`${BASE_CLASS}__admins`}>
                    <div className={`${BASE_CLASS}__admins__icon`}>
                        <Icon
                            size={IconSizes.Large}
                            type={Icons.NoteColorable}
                            cssClassName={`${BASE_CLASS}__admins__svg`}
                        />
                        <span className={`${BASE_CLASS}__admins__title`}>
                            {t("teamAdministrators")}
                        </span>
                    </div>
                    <Paragraph size={ParagraphSizes.Small}>
                        {t("reachOutToAccountAdministratorsMessage")}
                    </Paragraph>
                    <UnorderedList
                        listItems={teamAdmins.map((admin) => (
                            <Paragraph
                                size={ParagraphSizes.Small}
                                cssClassName={`${BASE_CLASS}__admins__entry`}>
                                <span className={`${BASE_CLASS}__admins__name`}>
                                    {admin.getFirstAndLastName()}
                                </span>
                                <br />
                                <span
                                    className={`${BASE_CLASS}__admins__email`}>
                                    <Anchor
                                        external
                                        to={`mailto:${admin.email}`}>
                                        {admin.email}
                                    </Anchor>
                                </span>
                            </Paragraph>
                        ))}
                    />
                </div>
            )}
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubscriptionDetailCardTeamAdmins;

// #endregion Exports
