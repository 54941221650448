import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import React from "react";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuPopover,
} from "@reach/menu-button";
import uuid from "uuid";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import MobileDropdownButton from "molecules/dropdown-button/mobile-dropdown-button";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DropdownItem {
    component: React.ReactNode | React.ReactNodeArray;
    onSelect: () => void;
}

export interface DropdownButtonProps {
    buttonClassName?: string;
    buttonContents: React.ReactNode | React.ReactNodeArray;
    items: Array<DropdownItem>;
    label: string;
    size?: ButtonSizes;
    style?: ButtonStyles;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const DropdownButtonClassName = "c-dropdown-button";
export const DropdownButtonListClassName = `${DropdownButtonClassName}__list`;
export const DropdownButtonListItemClassName = `${DropdownButtonListClassName}__item`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const DropdownButton: React.FC<DropdownButtonProps> = (
    props: DropdownButtonProps
) => {
    const { buttonClassName, items, buttonContents, size, style } = props;

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const classNames = ["c-button", DropdownButtonClassName];

    if (buttonClassName != null) {
        classNames.push(buttonClassName);
    }

    if (size != null) {
        classNames.push(size);
    }

    if (style != null) {
        classNames.push(style);
    }

    if (isMobile) {
        return <MobileDropdownButton {...props} />;
    }

    return (
        <Menu>
            <MenuButton className={classNames.join(" ")}>
                {buttonContents}
            </MenuButton>
            <MenuPopover className={DropdownButtonListClassName}>
                <MenuItems>
                    {items.map((item: DropdownItem) => (
                        <MenuItem
                            className={DropdownButtonListItemClassName}
                            key={uuid.v4()}
                            onSelect={item.onSelect}>
                            {item.component}
                        </MenuItem>
                    ))}
                </MenuItems>
            </MenuPopover>
        </Menu>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default DropdownButton;

// #endregion Exports
