import { useContext } from "react";
import { AdminFreeAccessContext } from "./admin-free-access-context";

const useAdminFreeAccessContext = () => {
    const { publications, setPublications, isLoading, setIsLoading } =
        useContext(AdminFreeAccessContext);
    return {
        publications,
        setPublications,
        isLoading,
        setIsLoading,
    };
};

export default useAdminFreeAccessContext;
