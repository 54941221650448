import { RecordUtils, ResultRecord } from "andculturecode-javascript-core";
import { LoaderStyles } from "atoms/constants/loader-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import SubmitButton from "atoms/forms/submit-button";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import AuthenticationProviderErrorKeys from "constants/authentication-provider-error-keys";
import CreateUserDto from "models/interfaces/create-user-dto";
import { CreateUserDtoValidator } from "models/validation/create-user-dto-validator";
import CreateUserDtoRecord from "models/view-models/create-user-dto-record";
import GlobalStateRecord from "models/view-models/global-state-record";
import SignupContextRecord from "models/view-models/signup-context-record";
import UserLoginRecord from "models/view-models/user-login-record";
import InputFormField from "molecules/form-fields/input-form-field";
import PasswordFormField from "molecules/form-fields/password-form-field";
import Form from "molecules/forms/form";
import FreeTrialTermsBox from "molecules/free-trial-terms-box/free-trial-terms-box";
import SubscriptionType from "organisms/enums/subscription-type";
import * as React from "react";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSignupContext } from "utilities/contexts/signup/use-signup-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useIdentity from "utilities/hooks/use-identity";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { ScrollUtils } from "utilities/scroll-utils";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import UserService from "utilities/services/users/user-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import { v4 } from "uuid";
import useEmailAddressValidation from "utilities/hooks/domain/validation/use-email-validation";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useRecaptcha from "utilities/hooks/domain/user-settings/use-recaptcha";
import CreateAccountAnchor from "molecules/create-account-anchor/create-account-anchor";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import useStartFreeTrial from "utilities/hooks/domain/users/use-start-free-trial";

export interface NewUserFormProps {
    /**
     * If present the email address will be prefilled and disabled
     */
    defaultEmail?: string;

    /**
     * id of the <Form> element
     */
    formId?: string;

    /**
     * A value is required if showing the free trial terms box.
     * Indicates user agreement to the free trial agreement.
     */
    freeTrialTermsAccepted?: boolean;

    /**
     * If the free trial agreement is showing, add the error message to it.
     */
    freeTrialAgreementError?: string;

    /**
     * Optional prop used to conditionally extend the loading indicator. By default it will stop after successful
     * creation and login of the new user.
     */
    loading?: boolean;

    /**
     * Optional prop to be called whenever there is an error within the sign-up or sign-in process
     */
    onError?: (message?: string) => void;

    /**
     * Called before creating/logging in the user. Can be used for additional validation before the form
     * submits.
     */
    onSubmit?: (user?: CreateUserDtoRecord) => boolean;

    /**
     * Called when the accepted state of free trial terms changes.
     * @param accepted
     */
    onFreeTrialTermsAccepted?: (accepted: boolean) => void;

    /**
     * Called after successfully creating and logging in the user.
     */
    onSuccess?: () => void;
    selectedSubscriptionType?: SubscriptionType;
    submitButtonText?: string;
}

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-new-user-form";
const { ERROR_CREATE_USER_EMAIL_IN_USE } = AuthenticationProviderErrorKeys;

// #endregion Constants

const NewUserForm: React.FunctionComponent<NewUserFormProps> = (
    props: NewUserFormProps
) => {
    const { useAzureB2CForSSO, useInternalFreeTrial, validateEmailAddresses } =
        useFeatureFlags();

    const { onError, onSubmit, selectedSubscriptionType } = props;
    const formId = props.formId ?? v4();
    const { signupContext, setSignupContext } = useSignupContext();
    const { t } = useLocalization<CultureResources>();
    const { startFreeTrialForSignedInUser, setCallbackLink } =
        useStartFreeTrial();

    // State
    const [creatingUser, setCreatingUser] = useState<boolean>(false);
    const [errorResult, setErrorResult] = useState<
        ObjectValidationResult<CreateUserDto>
    >({});
    const [disabled, setDisabled] = useState<boolean>(true);
    const hasDefaultEmail = StringUtils.hasValue(props.defaultEmail);
    const isFreeTrial = selectedSubscriptionType === SubscriptionType.FreeTrial;

    const { setGlobalState } = useGlobalState();
    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    const { siteKey, culture, captchaSettings, handleRecaptchaChange } =
        useRecaptcha();
    const { buildCurrentIdentity } = useIdentity();
    const { create: userCreateApi } = UserService.useCreate();
    const { create: userLoginCreateApi } = UserLoginService.useCreate();
    const [emailValidationError, setEmailValidationError] = useState<string>();
    const { validateEmail } = useEmailAddressValidation();

    const errorCreatingAccount = t("errors-actionResource_possessive", {
        action: t("creating"),
        resource: t("account"),
    });
    const firstNameLabel = t("field-firstName");
    const lastNameLabel = t("field-lastName");
    const emailLabel = t("yourItem", { item: t("field-email") });
    const passwordLabel = t("password");
    const passwordPlaceholder = `${StringUtils.capitalize(
        t("enterAField", { fieldName: passwordLabel })
    )}...`;
    const confirmPasswordLabel = StringUtils.capitalize(
        t("confirmItem", { item: passwordLabel })
    );
    const confirmPasswordPlaceholder = `${StringUtils.capitalize(
        t("confirmItem", { item: passwordLabel })
    )}...`;
    const firstNamePlaceholder = `${StringUtils.capitalize(
        t("enterAField_possessive", { fieldName: firstNameLabel })
    )}...`;
    const lastNamePlaceholder = `${StringUtils.capitalize(
        t("enterAField_possessive", { fieldName: lastNameLabel })
    )}...`;
    const emailAddressPlaceholder = `${StringUtils.capitalize(
        t("enterAField_possessive", { fieldName: t("field-email") })
    )}...`;
    useEffect(() => {
        if (
            StringUtils.hasValue(props.defaultEmail) &&
            StringUtils.isEmpty(signupContext.newUser.email)
        ) {
            setSignupContext(
                signupContext.with({
                    newUser: signupContext.newUser?.with({
                        email: props.defaultEmail,
                    }),
                })
            );
        }
    }, [props.defaultEmail, signupContext, setSignupContext]);

    const handleAccountCreationError = () => {
        ToastManager.error(errorCreatingAccount);
        setCreatingUser(false);
        onError?.();
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const parentIsValid = onSubmit?.(signupContext.newUser) ?? true;
        const formHasErrors = validate(signupContext.newUser, setErrorResult);

        if (formHasErrors || StringUtils.hasValue(emailValidationError)) {
            // Only scrolling to the top if this form has errors - if errors came from the parent,
            // let them decide whether to scroll to the top of the form.
            ScrollUtils.scrollToTop();
            onError?.();
            return;
        }

        if (!parentIsValid) {
            return;
        }

        async function createUser() {
            try {
                setCreatingUser(true);
                const dto = signupContext.newUser.with({
                    // the startFreeTrial flag is used by NFPA, if we are creating an internal
                    // free trial we do not need it
                    startFreeTrial: isFreeTrial && !useInternalFreeTrial,
                });
                const userCreateResponse = await userCreateApi(dto);
                if (userCreateResponse.result?.hasErrors()) {
                    handleAccountCreationError();
                    return;
                }

                const userLoginCreateResponse = await userLoginCreateApi(
                    new UserLoginRecord({
                        password: signupContext.newUser.password,
                        userName: signupContext.newUser.email,
                    })
                );
                if (userLoginCreateResponse.result?.hasErrors()) {
                    handleAccountCreationError();
                    return;
                }

                const identity = await buildCurrentIdentity(
                    userLoginCreateResponse.resultObject
                );

                if (identity == null) {
                    handleAccountCreationError();
                    return;
                }

                setGlobalState((globalState: GlobalStateRecord) =>
                    globalState.setIdentity(identity)
                );

                if (isFreeTrial) {
                    await startFreeTrialForSignedInUser();
                    return;
                }

                setCreatingUser(false);

                props.onSuccess?.();
            } catch (result) {
                setCreatingUser(false);
                const validationErrors = getFormFieldErrors(result);
                if (validationErrors != null) {
                    setErrorResult(validationErrors);
                    onError?.();
                    return;
                }

                onError?.();
                ToastManager.error(errorCreatingAccount);
            }
        }
        createUser();
    };

    const handleEmailFormFieldBlur = async () => {
        if (!validateEmailAddresses) {
            return;
        }

        const emailAddress = signupContext.newUser.email;

        var validationResult = await validateEmail(emailAddress);

        if (validationResult.isValid) {
            setEmailValidationError(undefined);
            setErrorResult((previous) => {
                return { ...previous, email: [] };
            });

            return;
        }

        setEmailValidationError(validationResult.errorMessage ?? "");
    };

    const handleFormFieldChange = (key: keyof CreateUserDto, value: any) => {
        setSignupContext((previousValue: SignupContextRecord) =>
            previousValue.with({
                newUser: previousValue.newUser?.set(key, value),
            })
        );
    };

    const handleFreeTrialTermsAccepted = (accepted: boolean) => {
        if (useAzureB2CForSSO) setDisabled(!disabled);
        props.onFreeTrialTermsAccepted?.(accepted);
    };

    const getEmailFormFieldErrors = (): string => {
        if (StringUtils.hasValue(emailValidationError)) {
            return emailValidationError;
        }

        return ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
            "email",
            errorResult
        );
    };
    const checkCaptchaChange = () => {
        handleRecaptchaChange();
        handleFormFieldChange("captchaComplete", true);
    };

    return (
        <div className={BASE_CLASS}>
            {useAzureB2CForSSO ? (
                <>
                    {isFreeTrial && (
                        <FreeTrialTermsBox
                            accepted={props.freeTrialTermsAccepted ?? false}
                            error={props.freeTrialAgreementError}
                            onAcceptChange={handleFreeTrialTermsAccepted}
                        />
                    )}
                    <CreateAccountAnchor
                        redirectStartPage={setCallbackLink()}
                        disabled={disabled}
                        cssClassName="c-button"
                    />
                </>
            ) : (
                <Form
                    buttonText={props.submitButtonText}
                    id={formId}
                    onSubmit={handleSubmit}>
                    <InputFormField
                        errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                            "firstName",
                            errorResult
                        )}
                        inputTestId="firstName"
                        isValid={
                            !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                "firstName",
                                errorResult
                            )
                        }
                        label={firstNameLabel}
                        maxLength={100}
                        placeholder={firstNamePlaceholder}
                        onChange={(e) =>
                            handleFormFieldChange("firstName", e.target.value)
                        }
                        required={true}
                        showCharacterCount={false}
                        value={signupContext.newUser?.firstName}
                    />
                    <InputFormField
                        errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                            "lastName",
                            errorResult
                        )}
                        inputTestId="lastName"
                        isValid={
                            !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                "lastName",
                                errorResult
                            )
                        }
                        label={lastNameLabel}
                        maxLength={100}
                        onChange={(e) =>
                            handleFormFieldChange("lastName", e.target.value)
                        }
                        placeholder={lastNamePlaceholder}
                        required={true}
                        showCharacterCount={false}
                        value={signupContext.newUser?.lastName}
                    />
                    <InputFormField
                        disabled={hasDefaultEmail}
                        errorMessage={getEmailFormFieldErrors()}
                        inputTestId="email"
                        isValid={
                            !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                "email",
                                errorResult
                            )
                        }
                        label={emailLabel}
                        maxLength={100}
                        onBlur={handleEmailFormFieldBlur}
                        onChange={(e) =>
                            handleFormFieldChange("email", e.target.value)
                        }
                        placeholder={emailAddressPlaceholder}
                        required={!hasDefaultEmail}
                        showCharacterCount={false}
                        value={signupContext.newUser?.email}
                    />
                    <div className={`${BASE_CLASS}__password`}>
                        <PasswordFormField
                            errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                                "password",
                                errorResult
                            )}
                            inputTestId="password"
                            isValid={
                                !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                    "password",
                                    errorResult
                                )
                            }
                            label={passwordLabel}
                            onChange={(e) =>
                                handleFormFieldChange(
                                    "password",
                                    e.target.value
                                )
                            }
                            placeholder={passwordPlaceholder}
                            required={true}
                            value={signupContext.newUser?.password}
                        />
                        <Paragraph size={ParagraphSizes.XSmall}>
                            {t("passwordRequirements")}
                        </Paragraph>
                    </div>
                    <PasswordFormField
                        errorMessage={ObjectValidator.getConcatenatedErrorsFor<CreateUserDto>(
                            "confirmPassword",
                            errorResult
                        )}
                        inputTestId="confirmPassword"
                        isValid={
                            !ObjectValidator.hasErrorsFor<CreateUserDto>(
                                "confirmPassword",
                                errorResult
                            )
                        }
                        label={confirmPasswordLabel}
                        onChange={(e) =>
                            handleFormFieldChange(
                                "confirmPassword",
                                e.target.value
                            )
                        }
                        placeholder={confirmPasswordPlaceholder}
                        required={true}
                        value={signupContext.newUser?.confirmPassword}
                    />
                    {isFreeTrial && (
                        <FreeTrialTermsBox
                            accepted={props.freeTrialTermsAccepted ?? false}
                            error={props.freeTrialAgreementError}
                            onAcceptChange={handleFreeTrialTermsAccepted}
                        />
                    )}

                    <input
                        type="hidden"
                        name="captcha_settings"
                        value={JSON.stringify(captchaSettings)}
                    />
                    <input
                        type="hidden"
                        name="orgid"
                        value={captchaSettings.orgId}
                    />

                    <ReCAPTCHA
                        hl={culture}
                        sitekey={siteKey}
                        onChange={checkCaptchaChange}
                    />
                    <div className={`${BASE_CLASS}__captcha-errors`}>
                        {ObjectValidator.hasErrorsFor<CreateUserDto>(
                            "captchaComplete",
                            errorResult
                        ) && (
                            <Paragraph size={ParagraphSizes.XSmall}>
                                {t("propertyIsRequired", {
                                    name: "reCAPTCHA",
                                })}
                            </Paragraph>
                        )}
                    </div>

                    {props.loading ?? creatingUser ? (
                        <Loader
                            accessibleText={
                                props.loading ? "Loading" : "Creating account"
                            }
                            type={LoaderStyles.LinkGlyphGray}
                        />
                    ) : (
                        <SubmitButton
                            //shouldn't render if subscription freeze alerts are active true - disabled prop is safeguard
                            disabled={subscriptionFreezeActive}
                            buttonText={props.submitButtonText}
                            cssClassName="c-button"
                            dataTestId="new-user-form-submit"
                            formId={formId}
                        />
                    )}
                </Form>
            )}
        </div>
    );
};

// -----------------------------------------------------------------------------------------
// #region Helper Methods
// -----------------------------------------------------------------------------------------

const getFormFieldErrors = (
    result: any
): ObjectValidationResult<CreateUserDto> | undefined => {
    if (!RecordUtils.isRecord(result, ResultRecord)) {
        return;
    }

    if (
        result.doesNotHaveErrors() ||
        !result.hasErrorFor(ERROR_CREATE_USER_EMAIL_IN_USE)
    ) {
        return;
    }

    const emailError = result.getErrorMessageFor(
        ERROR_CREATE_USER_EMAIL_IN_USE
    )!;

    const validationResult: ObjectValidationResult<CreateUserDto> = {
        email: [emailError!],
    };

    return validationResult;
};

const validate = (
    user: CreateUserDtoRecord,
    setErrorResult: React.Dispatch<
        React.SetStateAction<ObjectValidationResult<CreateUserDto>>
    >
) => {
    const validateResult = new CreateUserDtoValidator().validate(user);
    setErrorResult(validateResult);
    return ObjectValidator.hasErrors(validateResult);
};

// #endregion Helper Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default NewUserForm;

// #endregion Exports
