import Button, { ButtonTypes } from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { SelectOption } from "atoms/forms/select";
import PublicationRecord from "models/view-models/publication-record";
import HitRecord from "models/view-models/search/hit-record";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import SelectFormField from "molecules/form-fields/select-form-field";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import RichTextReferenceSelect, {
    RichTextReferenceLinkType,
} from "molecules/rich-text/rich-text-reference-select";
import React, { useEffect, useMemo, useState } from "react";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { ReferenceLinkValue } from "utilities/quill/formats/reference-link";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextReferenceLinkModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    baseClassName: string;
    onConfirm: (link: ReferenceLinkValue) => void;
}

type PublicationSelectOption = SelectOption<PublicationRecord, number>;

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextReferenceLinkModal: React.FC<RichTextReferenceLinkModalProps> = (
    props: RichTextReferenceLinkModalProps
) => {
    // -------------------------------------------------------------------------------------------------
    // #region Props
    // -------------------------------------------------------------------------------------------------

    const { baseClassName, closeDialog, isVisible, onConfirm } = props;

    // #endregion Props

    // -------------------------------------------------------------------------------------------------
    // #region State
    // -------------------------------------------------------------------------------------------------

    const [selectedPublication, setSelectedPublication] = useState(
        new PublicationRecord()
    );
    const [contentItem, setContentItem] = useState<HitRecord>();
    const [displayText, setDisplayText] = useState("");
    const [referenceType, setReferenceType] = useState(
        RichTextReferenceLinkType.Other
    );

    const [contentItemError, setContentItemError] = useState("");
    const [displayTextError, setDisplayTextError] = useState("");

    // #endregion State

    // -------------------------------------------------------------------------------------------------
    // #region Hooks
    // -------------------------------------------------------------------------------------------------

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const { resultObject: publications, loading } = usePublications({});

    const { publication } = useCurrentPublication();

    const defaultPublication = useMemo((): PublicationRecord => {
        const fallback = publications[0] ?? new PublicationRecord();
        if (publication == null) {
            return fallback;
        }

        return (
            publications.find(
                (p: PublicationRecord) =>
                    p.id === publication.id ||
                    (p.code === publication.code &&
                        p.edition === publication.edition)
            ) ?? fallback
        );
    }, [publication, publications]);

    // #endregion Hooks

    // -------------------------------------------------------------------------------------------------
    // #region Effects
    // -------------------------------------------------------------------------------------------------

    useEffect(() => {
        // reset search on close
        if (!isVisible) {
            setContentItem(undefined);
            setDisplayText("");
            setContentItemError("");
            setDisplayTextError("");
        }

        // set to default publication on visibility change
        setSelectedPublication(defaultPublication);
    }, [isVisible, publications, defaultPublication]);

    // #endregion Effects

    // -------------------------------------------------------------------------------------------------
    // #region Functions
    // -------------------------------------------------------------------------------------------------

    const validate = (): boolean => {
        let hasErrors = false;
        setContentItemError("");
        setDisplayTextError("");

        if (
            referenceType === RichTextReferenceLinkType.Other &&
            contentItem == null
        ) {
            setContentItemError("Content item reference is required.");
            hasErrors = true;
        }

        if (StringUtils.isEmpty(displayText)) {
            setDisplayTextError("Display text is required.");
            hasErrors = true;
        }

        return !hasErrors;
    };

    // #endregion Functions

    // -------------------------------------------------------------------------------------------------
    // #region Handlers
    // -------------------------------------------------------------------------------------------------

    const handleContentItemSelected = (hit?: HitRecord) => {
        setContentItem(hit);

        if (hit != null) {
            setDisplayText(hit?.getOptionDisplayText()?.trim() ?? "");
            return;
        }

        setContentItemError("");
    };

    const handleDisplayTextChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setDisplayText(e.target.value);

    const handlePublicationSelected = (option?: PublicationSelectOption) => {
        const publication = option?.data ?? defaultPublication;
        setSelectedPublication(publication);
        setContentItem(undefined);
        if (referenceType === RichTextReferenceLinkType.Publication) {
            setDisplayText(publication.getDisplayCodeEditionAndTitle().trim());
            return;
        }

        setDisplayText("");
    };

    const handleReferenceTypeChanged = (type: RichTextReferenceLinkType) => {
        setReferenceType(type);

        if (type === RichTextReferenceLinkType.Publication) {
            setDisplayText(
                selectedPublication.getDisplayCodeEditionAndTitle().trim()
            );
            return;
        }

        setDisplayText("");
    };

    const handleSubmit = () => {
        if (!validate()) {
            return;
        }

        onConfirm(
            (contentItem ?? selectedPublication).toReferenceLinkValue(
                displayText
            )
        );
    };

    // #endregion Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Render
    // -------------------------------------------------------------------------------------------------

    return (
        <Modal
            closeDialog={closeDialog}
            cssClassName={baseClassName}
            isVisible={isVisible}
            label={"Add reference link"}
            type={isMobile ? ModalTypes.Fullscreen : ModalTypes.Base}>
            <ModalCloseButton onClick={closeDialog} />
            <Form>
                <SelectFormField<PublicationRecord, number>
                    disabled={loading}
                    label={"Publication"}
                    onChange={handlePublicationSelected}
                    options={publications.map((p: PublicationRecord) =>
                        p.toSelectOption()
                    )}
                    required={true}
                    value={selectedPublication.id}
                />
                <RichTextReferenceSelect
                    baseClassName={baseClassName}
                    contentItemError={contentItemError}
                    onChange={handleContentItemSelected}
                    onReferenceTypeChanged={handleReferenceTypeChanged}
                    publicationId={selectedPublication.id!}
                    value={contentItem}
                />
                <InputFormField
                    disabled={loading}
                    errorMessage={displayTextError}
                    isValid={StringUtils.isEmpty(displayTextError)}
                    label={"Display Text"}
                    onChange={handleDisplayTextChanged}
                    maxLength={Infinity}
                    placeholder={"Link text..."}
                    required={true}
                    showCharacterCount={false}
                    value={displayText}
                />
            </Form>
            <div className={`${baseClassName}__footer`}>
                <Button
                    onClick={closeDialog}
                    style={ButtonStyles.Secondary}
                    type={ButtonTypes.Button}>
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    onClick={handleSubmit}
                    type={ButtonTypes.Button}>
                    Add Reference Link
                </Button>
            </div>
        </Modal>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextReferenceLinkModal;

// #endregion Exports
