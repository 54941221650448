import { List } from "immutable";
import { useAtom } from "jotai";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import EnhancedContentByAnnexService from "utilities/services/enhanced-content/enhanced-content-by-annex-service";
import EnhancedContentByChapterService from "utilities/services/enhanced-content/enhanced-content-by-chapter-service";
import { ToastManager } from "utilities/toast/toast-manager";
import CurrentPublicationEnhancedContent from "./enhanced-content-atom";
import EnhancedContentService from "utilities/services/sections/enhanced-content/enhanced-content-service";
import EnhancedContentResourceService from "utilities/services/enhanced-content-resources/enhanced-content-resource-service";
import FileService from "utilities/services/file-service";
import { CollectionUtils } from "utilities/collection-utils";

interface UseEnhancedContentOptions {
    chapterNfpaLabel?: string;
    annexNfpaLabel?: string;
    code?: string;
    edition?: string;
    isAdmin?: boolean;
    externalId?: string;
    chapterId?: number;
    annexId?: number;
    disabled?: boolean;
}

const BATCH_FILE_SIZE = 25;

const useEnhancedContent = (options: UseEnhancedContentOptions) => {
    const {
        chapterNfpaLabel,
        annexNfpaLabel,
        code,
        edition,
        externalId,
        chapterId,
        annexId,
        isAdmin = false,
        disabled = false,
    } = options;

    const [enhancedContent, setEnhancedContent] = useAtom(
        CurrentPublicationEnhancedContent
    );

    const [loadingEnhancedContent, setLoadingEnhancedContent] = useState(false);
    const { list: listResources } = EnhancedContentResourceService.useList();
    const { list: listFiles } = FileService.useBatchedList();

    const { pathname } = useLocation();
    const isChapter = pathname.toString().includes("chapters");

    const getEnhancedContentForAnnex = async () => {
        try {
            if (
                code === undefined ||
                edition === undefined ||
                (annexNfpaLabel === undefined && !isAdmin) ||
                (annexId === undefined && isAdmin)
            ) {
                return [];
            }
            setLoadingEnhancedContent(true);
            const response = isAdmin
                ? await EnhancedContentByAnnexService.adminList({
                      annexId: annexId?.toString() ?? "",
                  })
                : await EnhancedContentByAnnexService.list({
                      code,
                      edition,
                      annexNfpaLabel: annexNfpaLabel ?? "",
                  });
            return response.resultObjects;
        } catch (error) {
            ToastManager.error(`Error getting enhanced content: ${error}`);
            return [];
        } finally {
            setLoadingEnhancedContent(false);
        }
    };

    // Given a chaper, gets enhanced content for that chapter and returns it
    const getEnhancedContentForChapter = async () => {
        try {
            if (
                code === undefined ||
                edition === undefined ||
                (chapterNfpaLabel === undefined && !isAdmin) ||
                (chapterId === undefined && isAdmin)
            ) {
                return [];
            }
            setLoadingEnhancedContent(true);
            const response = isAdmin
                ? await EnhancedContentByChapterService.adminList({
                      chapterId: chapterId?.toString() ?? "",
                  })
                : await EnhancedContentByChapterService.list({
                      code,
                      edition,
                      chapterNfpaLabel: chapterNfpaLabel ?? "",
                  });
            return response.resultObjects;
        } catch (error) {
            ToastManager.error(`Error getting enhanced content: ${error}`);
            return [];
        } finally {
            setLoadingEnhancedContent(false);
        }
    };

    const getEnhancedContentForReferencePanelSection = async (
        code: string,
        edition: string,
        externalIds: string[]
    ) => {
        try {
            // Grab all enhanced content matching the section external id(s)
            const enhancedContentResponse = await EnhancedContentService.list({
                code,
                edition,
                externalIds,
            });

            // Grab resources matching all enhanced content in panel
            const enhancedContentIds =
                enhancedContentResponse.resultObjects.map((element) => {
                    return element.id!;
                });
            const resourcesResponse = await listResources({
                enhancedContentIds,
            });

            // Query for files related to enhanced content resources
            const fileIds = CollectionUtils.uniqueValuesByProperty(
                resourcesResponse.resultObjects,
                "fileId"
            );
            const { resultObjects: files } = await listFiles(
                { ids: fileIds },
                "ids",
                BATCH_FILE_SIZE
            );

            // Merge in files to resources
            const resourcesWithFiles = resourcesResponse.resultObjects.map(
                (resource) => {
                    return resource.withFile(
                        files.filter((file) => file.id === resource.fileId)
                    );
                }
            );

            // Merge in resources to enhanced content
            return enhancedContentResponse.resultObjects.map((content) => {
                return content.with({
                    resources: resourcesWithFiles.filter(
                        (resource) => resource.enhancedContentId === content.id
                    ),
                });
            });
        } catch (error) {
            ToastManager.error(
                `Error getting enhanced content in reference panel: ${error}`
            );
        }
    };
    // Checks to see if enhanced content is needed on chapter change
    const updateEnhancedContentOnChapterChange = async () => {
        if (disabled) return;

        // If enhanced content is not available for that chapter,
        // and if we're not already calling for enhanced content, make the call
        if (
            code !== undefined &&
            edition !== undefined &&
            (chapterNfpaLabel !== undefined ||
                annexNfpaLabel !== undefined ||
                isAdmin) &&
            !loadingEnhancedContent
        ) {
            const results = isChapter
                ? await getEnhancedContentForChapter()
                : await getEnhancedContentForAnnex();
            // Add the enhanced content to that chapter id
            setEnhancedContent(results);
        }
    };

    const updateEnhancedContentOnEdit = (
        updatedEnhancedContent: EnhancedContentRecord
    ) => {
        const newEnhancedContentAtom = enhancedContent.map((element) => {
            return element.id === updatedEnhancedContent.id
                ? updatedEnhancedContent
                : element;
        });

        setEnhancedContent(newEnhancedContentAtom);
    };

    const updateEnhancedContentOnDelete = (idToDelete: number | undefined) => {
        const newEnhancedContentAtom = enhancedContent.filter(
            (element) => element.id !== idToDelete
        );
        setEnhancedContent(newEnhancedContentAtom);
    };

    const updateEnhancedContentOnAdd = (
        newEnhancedContent: EnhancedContentRecord
    ) => {
        setEnhancedContent((prev) => [...prev, newEnhancedContent]);
    };

    const enhancedContentForCurrentCaapsItem =
        enhancedContent?.find((element) => element.externalId === externalId) ??
        null;

    // Enhanced content resources are typed as Lists, not arrays. Not sure why, but adding this here is easier than changing it everywhere
    const enhancedContentResourcesForCurrentCaapsItem = List(
        enhancedContentForCurrentCaapsItem?.resources ?? []
    );

    return {
        enhancedContent,
        enhancedContentForCurrentCaapsItem,
        enhancedContentResourcesForCurrentCaapsItem,
        updateEnhancedContentOnChapterChange,
        updateEnhancedContentOnAdd,
        updateEnhancedContentOnEdit,
        updateEnhancedContentOnDelete,
        getEnhancedContentForReferencePanelSection,
    };
};

export default useEnhancedContent;
