import * as React from "react";
import { useEffect } from "react";
import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { t } from "utilities/localization-utils";

interface AnnexGroupBreadcrumbProps {
    match: any;
}

const AnnexGroupBreadcrumb: React.FunctionComponent<AnnexGroupBreadcrumbProps> =
    (props: AnnexGroupBreadcrumbProps) => {
        const { match } = props;
        const { setPageTitle } = useHeaderData({ match });
        const title = t("chapterNumber", { number: match.params.id });

        useEffect(() => {
            setPageTitle(title);
        }, [setPageTitle, title]);

        return (
            <Breadcrumb>
                <span className="c-breadcrumb__label">{title}</span>
            </Breadcrumb>
        );
    };

export default AnnexGroupBreadcrumb;
