import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserManagementBreadcrumb: React.FC = () => {
    return (
        <Breadcrumb>
            <span className="c-breadcrumb__label">Admin Users</span>
        </Breadcrumb>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserManagementBreadcrumb;

// #endregion Exports
