import { PrimitiveAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { useEffect } from "react";
import useDeepCompareMemo from "utilities/hooks/use-deep-compare-memo";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface useUpdateAtomEffectOptions<TValue> {
    atom: PrimitiveAtom<TValue | undefined>;
    clearOnCancel?: boolean;
    enabled: boolean;
    value: TValue;
}

// #endregion Interfaces

/**
 * Utility useEffect hook to set an atom value dependent on a condition.
 *
 * @param {useUpdateAtomEffectOptions} options
 */
export default function useUpdateAtomEffect<TValue>(
    options: useUpdateAtomEffectOptions<TValue>
) {
    const { atom, clearOnCancel = true, enabled, value } = options;
    const memoValue = useDeepCompareMemo(() => value, [value]);
    const setAtom = useUpdateAtom(atom);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        setAtom(memoValue);
        return function cancel() {
            if (clearOnCancel) {
                setAtom(undefined);
            }
        };
    }, [enabled, setAtom, memoValue, clearOnCancel]);
}
