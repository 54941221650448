import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import DashboardVideo from "models/interfaces/dashboard-video";
import DashboardVideoRecord from "models/view-models/dashboard-video-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal, { ModalProps } from "molecules/modals/modal";
import Tooltip from "molecules/tooltips/tooltip";
import React, { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { t } from "utilities/localization-utils";
import YoutubeMetadataService from "utilities/services/external/youtube/youtube-metadata-service";
import SystemSettingsService from "utilities/services/system-settings/system-settings-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface DashboardVideoUpdateModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {}

interface DashboardVideoMetadata
    extends Pick<
        DashboardVideo,
        "thumbnailUrl" | "videoTitle" | "videoDescription"
    > {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const INVALID_METADATA_ERROR = "Video Metadata is invalid.";
const ERROR_RETRIEVING_METADATA = "Video Metadata could not be retrieved.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const DashboardVideoUpdateModal: React.FC<DashboardVideoUpdateModalProps> = (
    props: DashboardVideoUpdateModalProps
) => {
    const { isVisible, closeDialog } = props;
    const { globalState, setGlobalState } = useGlobalState();
    const { dashboardVideo } = globalState?.getSystemSettings();
    const [record, setRecord] = useState(
        dashboardVideo ?? new DashboardVideoRecord()
    );
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isLoadingYouTubeMetaData, setIsLoadingYouTubeMetaData] =
        useState(false);
    const { update } = SystemSettingsService.useUpdateDashboardVideo();
    const isMobile = window.innerWidth < Breakpoints.Phone;
    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const updateVideoError = t("errors-actionResource", {
        action: t("updating"),
        resource: "DashboardVideo",
    });

    useEffect(() => {
        if (dashboardVideo == null) {
            return;
        }

        setRecord(dashboardVideo);
    }, [dashboardVideo]);

    const resetErrors = useCallback(() => {
        setErrorMessage("");
    }, []);

    const metadataIsInvalid = useCallback((record: DashboardVideoMetadata) => {
        return (
            !StringUtils.hasValue(record.thumbnailUrl) ||
            !StringUtils.hasValue(record.videoTitle) ||
            !StringUtils.hasValue(record.videoDescription)
        );
    }, []);

    useEffect(() => {
        if (
            StringUtils.hasValue(record?.videoIdentifier) &&
            metadataIsInvalid(record)
        ) {
            setErrorMessage(INVALID_METADATA_ERROR);
        }
    }, [record?.videoIdentifier, metadataIsInvalid, record]);

    const { get: getYoutubeMetadataApi } = YoutubeMetadataService.useGet();

    const handleVideoIdentifierBlur = useCallback(() => {
        const loadYoutubeMetadata = async () => {
            try {
                if (StringUtils.isEmpty(record.videoIdentifier)) {
                    resetErrors();
                    return;
                }

                setIsLoadingYouTubeMetaData(true);

                const result = await getYoutubeMetadataApi({
                    videoId: record.videoIdentifier!,
                });

                const videoMetadata: DashboardVideoMetadata = {
                    thumbnailUrl: result?.resultObject?.thumbnailUrl ?? "",
                    videoTitle: result?.resultObject?.title ?? "",
                    videoDescription: result?.resultObject?.description ?? "",
                };

                resetErrors();
                setRecord((prev) => prev.with(videoMetadata));

                setIsLoadingYouTubeMetaData(false);
            } catch (e) {
                setErrorMessage(ERROR_RETRIEVING_METADATA);
            }
        };

        loadYoutubeMetadata();
    }, [record.videoIdentifier, getYoutubeMetadataApi, resetErrors]);

    // Create
    const handleSave = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        try {
            const updateResult = await update(record);
            setGlobalState((globalState) =>
                globalState.setSystemSettings(
                    globalState.systemSettings?.with({
                        dashboardVideo: updateResult.resultObject,
                    })
                )
            );
            closeDialog();
            ToastManager.success("Successfully updated DashboardVideo");
        } catch (error) {
            ToastManager.error(updateVideoError);
        }
    };

    return (
        <Modal
            closeDialog={closeDialog}
            isVisible={isVisible}
            label={"Update Dashboard Video"}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={"c-modal__header"}>
                <h2>Update Dashboard Video</h2>
                <Button
                    cssClassName={"-modal-close -icon"}
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={"c-modal__content"} style={{ padding: 24 }}>
                <form>
                    <InputFormField
                        label={"Video Card Title"}
                        isValid={true}
                        maxLength={100}
                        onChange={(e) =>
                            setRecord(
                                record.with({ videoCardTitle: e.target.value })
                            )
                        }
                        showCharacterCount={false}
                        value={record.videoCardTitle}
                    />
                    <InputFormField
                        label={"Youtube Video Identifier"}
                        isValid={true}
                        maxLength={100}
                        errorMessage={errorMessage}
                        onBlur={handleVideoIdentifierBlur}
                        onChange={(e) =>
                            setRecord(
                                record.with({ videoIdentifier: e.target.value })
                            )
                        }
                        showCharacterCount={false}
                        value={record.videoIdentifier}
                    />
                    <Tooltip
                        content={"Loading YouTube Metadata..."}
                        visible={isLoadingYouTubeMetaData}>
                        <Button
                            type={ButtonTypes.Submit}
                            onClick={handleSave}
                            disabled={
                                isLoadingYouTubeMetaData ||
                                metadataIsInvalid(record) ||
                                StringUtils.hasValue(errorMessage)
                            }>
                            {"Save"}
                        </Button>
                    </Tooltip>
                </form>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default DashboardVideoUpdateModal;

// #endregion Exports
