import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React from "react";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import { SkipNavContent } from "@reach/skip-nav";
import AnnouncementRecord from "models/view-models/announcement-record";
import AnnouncementEditor from "organisms/admin/annoucements/announcement-editor";
import { useAnnouncementContext } from "utilities/contexts/use-announcement-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EnhancedContentAdminDashboardProps extends NestedRoutesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-announcements-admin-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementsAdminPage: React.FC<any> = () => {
    const { announcementContext } = useAnnouncementContext();
    const announcement =
        announcementContext.announcements.get(0) ?? new AnnouncementRecord();

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div className={`${CSS_CLASS_NAME}__content__header`}>
                            <Heading priority={HeadingPriority.Five}>
                                Feature Announcement
                            </Heading>
                        </div>
                        <AnnouncementEditor announcementId={announcement.id!} />
                    </div>
                </div>
            </SkipNavContent>
        </React.Fragment>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AnnouncementsAdminPage;

// #endregion Exports
