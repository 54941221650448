import { SelectOption } from "atoms/forms/select";
import { useState, useCallback } from "react";
import NumberUtils from "utilities/number-utils";

const DEFAULT_TAKE_SIZE = 10;

export interface UsePagedResultsData {
    currentPage: number;
    numberOfPages: number;
    onPageLast: VoidFunction;
    onPageNext: VoidFunction;
    onPageSizeChange: (selectedOption?: SelectOption<number, number>) => void;
    onSelectPage: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    resetPagination: VoidFunction;
    rowCount: number;
    setRowCount: (count: number) => void;
    skip: number;
    take: number;
    setSkip: (count: number) => void;
}

export default function usePagedResults(
    defaultTake = DEFAULT_TAKE_SIZE
): UsePagedResultsData {
    const [take, setTake] = useState(defaultTake);
    const [skip, setSkip] = useState<number>(0);
    const [rowCount, setRowCount] = useState(0);

    const currentPage = (skip + take) / take;
    const numberOfPages = Math.ceil(rowCount / take);

    const onPageLast = () => {
        if (currentPage === 1) {
            return;
        }

        setSkip((skip: number) => skip - take);
    };

    const onPageNext = () => {
        if (currentPage === numberOfPages) {
            return;
        }

        setSkip((skip: number) => skip + take);
    };

    const onPageSizeChange = (
        selectedOption?: SelectOption<number, number>
    ) => {
        const value = selectedOption?.value;

        if (value == null) {
            return;
        }

        setTake(value);
        resetPagination();
    };

    const onSelectPage = (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const selectedPage = NumberUtils.parseInt(e!.currentTarget.value);

        if (selectedPage == null) {
            return;
        }

        if (selectedPage <= 1 && selectedPage >= numberOfPages) {
            return;
        }

        setSkip((selectedPage - 1) * take);
    };

    const resetPagination = useCallback(() => {
        setSkip(0);
    }, []);

    return {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        resetPagination,
        rowCount,
        setRowCount,
        skip,
        take,
        setSkip,
    };
}
