/**
 * the string values should be the mime-type of
 * the desired file type. You can accept a generic type
 * (i.e. any image type) by using a wildcard in the type/subtype
 * pattern, for example, to allow any image type, "image/*",
 * or you can limit to only png images by using "image/png".
 *
 * However, Firefox only recognizes the `accept` attribute based on
 * file extensions, not MIME types, so for any MIME types you add,
 * you must also add the corresponding file extension(s) with a leading dot,
 * for example:
 * "image/png" -> ".png"
 * "image/svg+xml" -> ".svg"
 * "image/jpeg" -> ".jpeg" AND ".jpg"
 *
 * @link https://www.iana.org/assignments/media-types/media-types.xhtml
 * @link https://bugzilla.mozilla.org/show_bug.cgi?id=826176
 */
const MimeTypes = {
    Image: [
        // these formats are supported by all browsers
        "image/bmp",
        "image/gif",
        "image/ico",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        ".bmp",
        ".gif",
        ".ico",
        ".jpeg",
        ".jpg",
        ".png",
        ".svg",
    ],
    Pdf: ["application/pdf", ".pdf"],
    Publication: ["application/pdf", "application/zip", ".pdf", ".zip"],
    Zip: ["application/zip", ".zip"],
};

export default MimeTypes;
