import React from "react";
import WorkGroupMember from "models/interfaces/work-groups/work-group-member";
import { Icons } from "atoms/constants/icons";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import Icon from "atoms/icons/icon";
import { ButtonSizes } from "atoms/constants/button-sizes";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import { useLocalization } from "andculturecode-javascript-react";
import CultureResources from "utilities/interfaces/culture-resources";
import AddUserDropdown from "./add-user-dropdown";

interface WorkGroupManageUsersProps {
    workGroupMembers: WorkGroupMember[];
    updateWorkGroup: (values: Partial<WorkGroupRecord>) => void;
}

const WorkGroupManageUsers: React.FC<WorkGroupManageUsersProps> = (props) => {
    const { workGroupMembers, updateWorkGroup } = props;
    const { t } = useLocalization<CultureResources>();
    const BASE_CLASS_TABLE = "c-account-dashboard__modal__table";
    const deleteButton = t("workgroup-delete-member-button");

    const handleDelete = (id: number | undefined): void | undefined => {
        const filteredWorkGroupMembers = workGroupMembers.filter(
            (members) => members.userId !== id
        );
        updateWorkGroup({ workGroupMembers: filteredWorkGroupMembers });
    };
    return (
        <table className={BASE_CLASS_TABLE}>
            <thead>
                <tr>
                    <th colSpan={2}>
                        <AddUserDropdown
                            currentWorkGroupMembers={workGroupMembers ?? []}
                            updateWorkGroup={updateWorkGroup}
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {workGroupMembers?.map((user: WorkGroupMember) => (
                    <tr key={user.userId}>
                        <td>{user.name}</td>
                        <td>
                            <Button
                                accessibleText={deleteButton}
                                onClick={() => {
                                    handleDelete(user.userId);
                                }}
                                size={ButtonSizes.XSmall}
                                style={ButtonStyles.Destructive}
                                type={ButtonTypes.Button}>
                                <Icon type={Icons.Trashcan} />
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default WorkGroupManageUsers;
