import ServiceHookResult from "utilities/interfaces/service-hook-result";
import PublicationRecord from "models/view-models/publication-record";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import { useEffect, useCallback } from "react";
import PublicationService from "utilities/services/public/publications/publication-service";
import { first } from "lodash";
import { ToastManager } from "utilities/toast/toast-manager";
import { tErrorLoading } from "utilities/localization-utils";
import FileService from "utilities/services/file-service";
import NumberUtils from "utilities/number-utils";

export interface UsePublicationOptions {
    /**
     * Search by Publication Code instead of ID; if ID is null, code and edition are required
     */
    code?: string;
    /**
     * Search by Publication Edition instead of ID; if ID is null, code and edition are required
     */
    edition?: string;
}

interface UsePublicationHook extends ServiceHookResult<PublicationRecord> {
    refresh: () => void;
}

const usePublication = (options: UsePublicationOptions): UsePublicationHook => {
    const { get: getFile } = FileService.useGet();
    const { list: listPublications } = PublicationService.useList();

    const { code, edition } = options;

    const { result, setError, setLoading, setSuccess } =
        useServiceHookResultState<PublicationRecord>(new PublicationRecord());

    const loadPublication = useCallback(async () => {
        if (code == null || edition == null) {
            return;
        }

        try {
            setLoading();

            const { resultObjects: publications } = await listPublications({
                code,
                edition,
            });

            const publication = first(publications);
            if (publication == null) {
                throw new Error();
            }

            const fileId = NumberUtils.parseInt(publication.coverImageFileId);
            if (fileId == null) {
                setSuccess(publication);
                return;
            }

            const { resultObject: file } = await getFile({ id: fileId });

            const publicationWithCoverImage = publication.with({
                color: publication.getColorOrDefault(),
                coverImageFile: file,
            });

            setSuccess(publicationWithCoverImage);
        } catch (error) {
            ToastManager.error(tErrorLoading("book_plural"));
            setError(error);
        }
    }, [
        code,
        edition,
        getFile,
        listPublications,
        setError,
        setLoading,
        setSuccess,
    ]);

    const refresh = useCallback(() => {
        loadPublication();
    }, [loadPublication]);

    useEffect(() => {
        loadPublication();
    }, [loadPublication]);

    return {
        ...result,
        refresh,
    };
};

export default usePublication;
