import React from "react";
import { ServiceWorkerMessageTypes } from "utilities/service-worker/constants/service-worker-message-types";
import { TypedMessageEvent } from "utilities/service-worker/interfaces/messages/typed-message-event";
import { PreloadUrlsProgressMessage } from "utilities/service-worker/interfaces/messages/preload-urls-progress-message";
import useOnServiceWorkerMessageEvent from "utilities/contexts/service-worker/use-on-service-worker-message-event";
import OfflineBooksService from "utilities/services/offline/offline-books-service";
import useOfflineBooks from "utilities/hooks/domain/offline/use-offline-books";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import moment from "moment";

const ServiceWorkerOfflineBookSync: React.FC = () => {
    const { resultObject: currentOfflineDevice } = useOfflineDevice();
    const { resultObject: offlineBooks, refresh } = useOfflineBooks({
        offlineDeviceId: currentOfflineDevice?.id,
    });
    const { update: updateOfflineBook } = OfflineBooksService.useUpdate();

    const handleProgressMessage = (
        event: TypedMessageEvent<PreloadUrlsProgressMessage>
    ) => {
        const { code, current, edition, total } = event.data;

        if (current < total) {
            return;
        }

        const offlineBook = offlineBooks.find(
            (book) => book.book?.edition === edition && book.book?.code === code
        );

        if (offlineBook?.id == null) {
            return;
        }

        updateOfflineBook(
            offlineBook.with({ syncedOn: moment().toISOString() }),
            {
                id: offlineBook.id,
                offlineDeviceId: offlineBook.offlineDeviceId,
            }
        ).then(() => refresh());
    };

    useOnServiceWorkerMessageEvent(
        ServiceWorkerMessageTypes.PreloadProgress,
        handleProgressMessage
    );

    return null;
};

export default ServiceWorkerOfflineBookSync;
