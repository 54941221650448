import { useCallback, useEffect, useRef, useState } from "react";
import useDelayedSearch from "utilities/hooks/use-delayed-search";
import usePagedResults from "utilities/hooks/use-paged-results";
import AdminFreeAccessService from "utilities/services/admin/free-access/admin-free-access-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useAdminFreeAccessContext from "./use-admin-free-access-context";

const DEFAULT_TAKE_SIZE = 10;

const useAdminFreeAccessPublications = () => {
    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        rowCount,
        setRowCount,
        skip,
        take,
        resetPagination,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const { isLoading, setIsLoading, publications, setPublications } =
        useAdminFreeAccessContext();

    const hasMountedRef = useRef(false);
    const [code, setCode] = useState("");
    const { delayedSearch } = useDelayedSearch({
        callback: () => getAdminFreeAccessPublications(0, take, code),
    });

    const getAdminFreeAccessPublications = useCallback(
        async (skip: number, take: number, code: string) => {
            // resetPagination();
            setIsLoading(true);
            try {
                const results = await AdminFreeAccessService.list({
                    take,
                    skip,
                    code,
                });
                setRowCount(results.rowCount);
                setPublications(results.resultObjects);
            } catch (error) {
                ToastManager.error("There was an error");
            }
            setIsLoading(false);
        },
        [setIsLoading, setRowCount, setPublications]
    );

    useEffect(() => {
        if (!hasMountedRef.current) return;
        setIsLoading(true);
        delayedSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    useEffect(() => {
        hasMountedRef.current = true;
        getAdminFreeAccessPublications(skip, take, code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, take]);

    return {
        isLoading,
        publications,
        skip,
        take,
        code,
        currentPage,
        numberOfPages,
        rowCount,
        setCode,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        getAdminFreeAccessPublications,
        resetPagination,
    };
};

export default useAdminFreeAccessPublications;
