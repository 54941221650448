import { useLocalization } from "utilities/hooks/use-localization";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import UserCollection from "models/interfaces/user-collection";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal from "molecules/modals/modal";
import React, { useEffect, useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useCreateUserCollection from "utilities/hooks/domain/users/user-collections/use-create-user-collection";
import CultureResources from "utilities/interfaces/culture-resources";
import { ObjectValidationResult } from "utilities/validation/object-validator/object-validator";
import UserCollectionShareTypeForm from "./user-collection-share-type-form";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------;

const BASE_CLASS_NAME = "c-user-collection-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AddUserCollectionModalProps {
    closeDialog: () => void;
    isVisible: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddUserCollectionModal: React.FC<AddUserCollectionModalProps> = (
    props: AddUserCollectionModalProps
) => {
    // Constants
    const { globalState } = useGlobalState();
    const { t } = useLocalization<CultureResources>();
    const { closeDialog, isVisible } = props;
    const userTeam = useMemo(
        () => globalState.currentIdentity?.getCurrentTeam(),
        [globalState]
    );

    // State
    const [validationErrors, setValidationErrors] = useState<
        ObjectValidationResult<UserCollection>
    >({});
    const [currentUserId] = useState<number | undefined>(
        globalState.currentIdentity?.userId()
    );
    const [userCollection, setUserCollection] = useState(
        new UserCollectionRecord().with({
            userId: currentUserId,
            groupId: userTeam?.id,
        })
    );

    const { createUserCollection, loading: creating } =
        useCreateUserCollection();

    const resetState = () => {
        setValidationErrors({});
        setUserCollection(
            new UserCollectionRecord().with({
                userId: currentUserId,
                groupId: userTeam?.id,
            })
        );
    };

    useEffect(() => {
        setUserCollection(
            new UserCollectionRecord().with({
                userId: currentUserId,
                groupId: userTeam?.id,
            })
        );
    }, [currentUserId, userTeam?.id]);

    const handleClose = () => {
        resetState();
        closeDialog();
    };

    // Event Handlers
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setUserCollection((userCollection) =>
            userCollection.with({
                name: e.target.value,
            })
        );

    const handleSaveForm = async () => {
        const { result, validationResult } = await createUserCollection(
            userCollection,
            currentUserId
        );

        setValidationErrors(validationResult);

        // Errors found see pageErrors
        if (result == null) {
            return;
        }
        closeDialog();
        resetState();
    };

    const newItem = t("newItem", { item: t("collection") });

    return (
        <Modal
            closeDialog={handleClose}
            cssClassName={BASE_CLASS_NAME}
            isVisible={isVisible}
            label={newItem}
            type={ModalTypes.Base}>
            <div className={`${BASE_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{newItem}</Heading>
                <Button
                    accessibleText="Close Dialog"
                    cssClassName={"-modal-close -icon"}
                    onClick={handleClose}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${BASE_CLASS_NAME}__content`}>
                <div className={`${BASE_CLASS_NAME}__container`}>
                    <InputFormField
                        disabled={creating}
                        errorMessages={validationErrors.name}
                        fieldId="collection_title"
                        isValid={CollectionUtils.isEmpty(validationErrors.name)}
                        label={t("collectionTitle")}
                        maxLength={60}
                        name="collection_title"
                        onChange={handleNameChange}
                        placeholder={t("collectionTitlePlaceholder")}
                        required={true}
                        showCharacterCount={true}
                        type={InputTypes.Text}
                        value={userCollection.name}
                    />
                    {userTeam != null && (
                        <UserCollectionShareTypeForm
                            errorMessages={validationErrors.workGroups}
                            userCollection={userCollection}
                            setUserCollection={setUserCollection}
                        />
                    )}
                </div>
            </div>
            <div className={`${BASE_CLASS_NAME}__footer`}>
                <div className={`${BASE_CLASS_NAME}__container`}>
                    <Button
                        disabled={creating}
                        onClick={handleClose}
                        style={ButtonStyles.Secondary}>
                        {t("cancel")}
                    </Button>
                    <Button
                        dataTestId="save-collection-button"
                        disabled={creating}
                        onClick={handleSaveForm}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AddUserCollectionModal;

// #endregion Exports
