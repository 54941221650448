import { SetStateAction } from "jotai";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { Dispatch, useCallback } from "react";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";

interface useBookmarkActionsOptions {
    userBookmarkType: UserBookmarkType;
    createdOnPublicationId?: number;
    color?: UserBookmarkColors;
    externalId?: string;
    userId?: number;
    groupId?: number;
    setCurrentBookmark: Dispatch<SetStateAction<UserBookmarkRecord>>;
    bookmarks: Array<UserBookmarkRecord>;
}

const useBookmarkActions = ({
    userBookmarkType,
    createdOnPublicationId,
    color,
    externalId,
    userId,
    groupId,
    setCurrentBookmark,
    bookmarks,
}: useBookmarkActionsOptions) => {
    const { handleBookmarkSettingsOpen, handleBookmarksReadOnlyDialogOpen } =
        useBookviewLayoutContext();

    const createNewBookmark = useCallback(() => {
        return new UserBookmarkRecord().with({
            userBookmarkType,
            createdOnPublicationId,
            color,
            externalId,
            userId,
            groupId,
        });
    }, [
        color,
        createdOnPublicationId,
        externalId,
        groupId,
        userBookmarkType,
        userId,
    ]);

    const handleAddBookmark = useCallback(() => {
        const newBookmark = createNewBookmark();
        setCurrentBookmark(newBookmark);
        handleBookmarkSettingsOpen(externalId!);
    }, [
        createNewBookmark,
        externalId,
        handleBookmarkSettingsOpen,
        setCurrentBookmark,
    ]);

    const handleEditBookmark = (bookmarkId: number) => {
        const findBookmark = bookmarks.find(
            (bookmark) => bookmark.id === bookmarkId
        );
        if (!findBookmark) return;
        setCurrentBookmark(findBookmark);
        handleBookmarkSettingsOpen(externalId!);
    };

    const handleViewBookmarks = () => {
        handleBookmarksReadOnlyDialogOpen(externalId!);
    };

    return {
        handleAddBookmark,
        handleEditBookmark,
        handleViewBookmarks,
    };
};

export default useBookmarkActions;
