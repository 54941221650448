import * as React from "react";

import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";

export interface CommitteesH1ConverterProps {
    children?: JSX.Element[];
    className?: string;
    datalabel?: string;
}

const CommitteesH1Converter = (props: CommitteesH1ConverterProps) => {
    switch (props.className) {
        case "nfpa-fm-html_heading":
            return (
                <Heading
                    cssClassName="c-committee-member-group__title"
                    priority={HeadingPriority.Three}>
                    {props.children}
                </Heading>
            );
        default:
            // There are times where props.children is undefined in paragraph elements.
            if (props.children === undefined) {
                return null;
            }

            return props.children;
    }
};

export default CommitteesH1Converter;
