import {
    BillingCycleOption,
    BillingCycleOptionTypes,
} from "constants/billing-cycle-options";
import { EcommercePins } from "constants/ecommerce-pins";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import SubscriptionType from "organisms/enums/subscription-type";
import { useCallback } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";

export function useEcommercePrices() {
    const ecommercePrices =
        useGlobalState().globalState.systemSettings?.ecommercePrices;

    const billingCycleOptions: BillingCycleOptionTypes = {
        individual: [
            {
                cycle: t("monthly"),
                description: t("billingCycle-individual-monthly-autorenew"),
                autoRenew: t("on"),
                pin: EcommercePins.IndividualMonthlyAutoRenew,
                price: `$${ecommercePrices?.IndividualMonthlyAutoRenew}`,
                isBestValue: false,
            },
            {
                cycle: t("annually"),
                description: t("billingCycle-individual-annual-autorenew"),
                autoRenew: t("on"),
                pin: EcommercePins.IndividualAnnualAutoRenew,
                price: `$${ecommercePrices?.IndividualAnnualAutoRenew}`,
                isBestValue: true,
            },
            {
                cycle: t("annually"),
                description: t("billingCycle-individual-annual"),
                autoRenew: t("off"),
                pin: EcommercePins.IndividualAnnual,
                price: `$${ecommercePrices?.IndividualAnnual}`,
                isBestValue: false,
            },
        ],
        teamOfFive: [
            {
                cycle: t("annually"),
                description: t("billingCycle-team-annual-autorenew"),
                autoRenew: t("on"),
                pin: EcommercePins.TeamFiveAnnualAutoRenew,
                price: `$${ecommercePrices?.TeamFiveAnnualAutoRenew}`,
                isBestValue: true,
            },
            {
                cycle: t("annually"),
                description: t("billingCycle-team-annual"),
                autoRenew: t("off"),
                pin: EcommercePins.TeamFiveAnnual,
                price: `$${ecommercePrices?.TeamFiveAnnual}`,
                isBestValue: false,
            },
        ],
        teamOfTen: [
            {
                cycle: t("annually"),
                description: t("billingCycle-team-annual-autorenew"),
                autoRenew: t("on"),
                pin: EcommercePins.TeamTenAnnualAutoRenew,
                price: `$${ecommercePrices?.TeamTenAnnualAutoRenew}`,
                isBestValue: true,
            },
            {
                cycle: t("annually"),
                description: t("billingCycle-team-annual"),
                autoRenew: t("off"),
                pin: EcommercePins.TeamTenAnnual,
                price: `$${ecommercePrices?.TeamTenAnnual}`,
                isBestValue: false,
            },
        ],
    };

    /**
     * Attempts to map an ecommerce pin value to a `BillingCycleOption`.
     *
     * @param pin Pin from ecommerce to be mapped to a `BillingCycleOption`
     */
    const fromPin = useCallback(
        (pin: string | undefined): BillingCycleOption | undefined => {
            if (StringUtils.isEmpty(pin)) {
                return undefined;
            }

            return [
                ...billingCycleOptions.individual,
                ...billingCycleOptions.teamOfFive,
                ...billingCycleOptions.teamOfTen,
            ].find((o: BillingCycleOption) =>
                // Forcing incoming pin as a string here incase it is parsed as a number automatically
                o.pin.toLowerCase().endsWith(pin.toString().toLowerCase())
            );
        },
        [
            billingCycleOptions.individual,
            billingCycleOptions.teamOfFive,
            billingCycleOptions.teamOfTen,
        ]
    );

    const getBillingCycleType = useCallback(
        (
            type: SubscriptionType | undefined,
            teamsize?: SubscriptionTeamSize
        ) => {
            if (type === SubscriptionType.Individual)
                return billingCycleOptions.individual;

            if (type === SubscriptionType.Team) {
                if (teamsize === SubscriptionTeamSize.UpToFive)
                    return billingCycleOptions.teamOfFive;

                if (teamsize === SubscriptionTeamSize.UpToTen)
                    return billingCycleOptions.teamOfTen;
            }

            return [];
        },
        [
            billingCycleOptions.individual,
            billingCycleOptions.teamOfFive,
            billingCycleOptions.teamOfTen,
        ]
    );

    return {
        billingCycleOptions,
        ecommercePrices,
        fromPin,
        getBillingCycleType,
    };
}
