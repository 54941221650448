import StringUtils from "utilities/string-utils";
import Loader from "atoms/loaders/loader";
import AnnexRecord from "models/view-models/annex-record";
import CurrentPublicationEntityRecord from "models/view-models/atoms/current-publication-entity-record";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationContentArea from "organisms/publication-content/publication-content-area";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CurrentPublicationEntityAtom from "utilities/atoms/current-publication-entity-atom";
import { CollectionUtils } from "utilities/collection-utils";
import useUpdateAtomEffect from "utilities/hooks/atoms/use-update-atom-value-effect";
import useAnnexesQuery from "utilities/hooks/domain/publications/annexes/use-annexes-query";
import useSectionsQuery from "utilities/hooks/domain/publications/annexes/use-sections-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useErrors from "utilities/hooks/use-errors";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import NumberUtils from "utilities/number-utils";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import AnnexGroupTableOfContentsRecord from "models/view-models/table-of-contents/annex-group-table-of-contents-record";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import { AnnexParams } from "interfaces/routing/route-params";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import CaapsItemTitleArea from "organisms/caaps-items/caaps-item-title-area";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import TableOfContentsAnnexGroups from "organisms/table-of-contents/table-of-contents-annex-groups";
import TableOfContentsSection from "organisms/table-of-contents/table-of-contents-section";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import HiddenContentAlertBanner from "organisms/alerts/hidden-content-alert-banner";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AnnexContentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const cssBaseClassName = "c-publication-page-layout";

// #endregion Constants

const AnnexContent: React.FunctionComponent<AnnexContentProps> = (
    props: AnnexContentProps
) => {
    const { publication } = useCurrentPublication();
    const { nfpaLabel, code, edition, id: annexId } = useParams<AnnexParams>();
    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization<CultureResources>();
    const annexContentRef = useRef<HTMLDivElement>(null);
    const [resetFocus, setResetFocus] = useState<boolean>(false);
    const { tableOfContents, loading: tocLoading } =
        useBookviewTableOfContents();

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const {
        loaded: annexesLoaded,
        resultObject: annexes,
        errors: annexesError,
    } = useAnnexesQuery({
        code,
        edition,
        publicationId: publication?.id,
        publication: publication,
    });

    // Find the active annex.
    const parsedAnnexId = NumberUtils.parseInt(annexId);

    const annex = useMemo(() => {
        if (
            (parsedAnnexId == null && StringUtils.isEmpty(nfpaLabel)) ||
            CollectionUtils.isEmpty(annexes)
        ) {
            return;
        }

        const byLabel = (annex: AnnexRecord) => annex.nfpaLabel === nfpaLabel;
        const byId = (annex: AnnexRecord) => annex.id === parsedAnnexId;
        const filter = parsedAnnexId == null ? byLabel : byId;
        const annex = annexes.find(filter);

        if (annex == null) {
            return;
        }

        return annex;
    }, [parsedAnnexId, nfpaLabel, annexes]);

    const annexTOC =
        tableOfContents.annexes?.find((a) => a.id === annex?.id) ??
        new AnnexTableOfContentsRecord();
    const hasGroupedAnnexes = CollectionUtils.hasValues(annexTOC?.annexGroups);
    const annexGroups = useMemo(
        () => annexTOC?.annexGroups ?? [new AnnexGroupTableOfContentsRecord()],
        [annexTOC?.annexGroups]
    );

    const isDesktop = useBreakpoint(Breakpoints.Tablet);

    const {
        resultObject: sections,
        loaded: sectionsLoaded,
        errors: sectionErrors,
    } = useSectionsQuery(annex?.id, annex?.publicationId);

    // #endregion Service Hooks

    const contentLoaded = sectionsLoaded && !tocLoading && annex != null;
    const loaderText = t("loadingItem", { item: t("annex") });

    // If annex groups exist, sections and sub-sections should not be displayed
    const filteredSections = useMemo(() => {
        let hasSectionsConditional =
            hasGroupedAnnexes || sections == null || annexId == null;
        if (!isAdminPreview) {
            hasSectionsConditional =
                hasGroupedAnnexes || sections == null || nfpaLabel == null;
        }
        return hasSectionsConditional ? [] : sections;
    }, [annexId, nfpaLabel, hasGroupedAnnexes, isAdminPreview, sections]);

    useEffect(
        function focusAnnex() {
            if (
                parsedAnnexId != null &&
                parsedAnnexId === annex?.id &&
                resetFocus &&
                annexContentRef &&
                annexContentRef.current
            ) {
                setResetFocus(false);
                annexContentRef.current.focus({ preventScroll: true });
            }
        },
        [resetFocus, annex, annexesLoaded, parsedAnnexId]
    );

    useUpdateAtomEffect({
        atom: CurrentPublicationEntityAtom,
        enabled: annex != null && annexesLoaded,
        value: new CurrentPublicationEntityRecord({
            entityId: annex?.id!,
            entityTitle: annex?.getDisplayLabel() ?? "",
            selected: true,
            type: "Annex",
        }),
    });

    const errors = useErrors(annexesError, sectionErrors);
    useErrorBoundary(errors);

    const shouldGetTableOfContentsSections = useMemo(
        () => !isDesktop && !hasGroupedAnnexes,
        [hasGroupedAnnexes, isDesktop]
    );

    const showTableOfContentsSections =
        shouldGetTableOfContentsSections &&
        CollectionUtils.hasValues(annexTOC.sections);

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const memoizedChildren = useMemo(() => {
        if (contentLoaded)
            return (
                <CaapsItemTitleArea
                    ref={annexContentRef}
                    externalId={annex.externalId}
                    publication={publication}
                    titleClassName={""}
                    record={annex}
                    publicationComponentType={PublicationComponentType.Annex}
                    doesHaveTitleOrBodyChanges={annex.doesTitleOrBodyHaveChanges()}>
                    <div className={`${cssBaseClassName}__body`}>
                        {annex?.getBody()}
                    </div>
                    {(hasGroupedAnnexes || showTableOfContentsSections) && (
                        <nav
                            className={`${cssBaseClassName}__table-of-contents`}>
                            {hasGroupedAnnexes && (
                                <TableOfContentsAnnexGroups
                                    annexGroups={annexGroups}
                                    annexNfpaLabel={annex.nfpaLabel ?? ""}
                                />
                            )}
                            {showTableOfContentsSections && (
                                <TableOfContentsSection
                                    sections={annexTOC.sections}
                                    parentType={SectionParentType.Annex}
                                />
                            )}
                        </nav>
                    )}
                </CaapsItemTitleArea>
            );
    }, [
        annex,
        annexGroups,
        annexTOC.sections,
        contentLoaded,
        hasGroupedAnnexes,
        publication,
        showTableOfContentsSections,
    ]);

    return (
        <React.Fragment>
            {
                // if
                CollectionUtils.hasValues(errors) && (
                    <UnorderedList
                        cssClassName={`${cssBaseClassName}__errors`}
                        listItems={errors}
                    />
                )
            }

            {!contentLoaded ? (
                <Loader accessibleText={loaderText} />
            ) : (
                <>
                    {/* Don't display the banner on the Annex Group TOC page */}
                    {!hasGroupedAnnexes && (
                        <HiddenContentAlertBanner
                            id={annex.id}
                            type={PublicationComponentType.Annex}
                        />
                    )}
                    <PublicationContentArea
                        memoizedChildren={memoizedChildren}
                        sections={filteredSections!}
                        externalId={annex.externalId}
                    />
                </>
            )}
        </React.Fragment>
    );

    // #endregion Render
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AnnexContent;

// #endregion Exports
