import React from "react";
import ToggleButton from "atoms/forms/toggle-button";
import { CheckboxButtonProperties } from "atoms/forms/checkbox-button";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import { AriaRole } from "utilities/enumerations/aria-role";

interface CallToActionToggleProps
    extends Omit<CheckboxButtonProperties, "onChange">,
        Pick<DataTestAttributes, "dataTestId"> {
    description: string;
    label: string;
    onChange: () => void;
}

const BASE_CLASS = "c-cta-toggle";

const CallToActionToggle: React.FunctionComponent<CallToActionToggleProps> = (
    props: CallToActionToggleProps
) => {
    const {
        checked,
        cssClassName,
        dataTestId,
        description,
        disabled = false,
        label,
        onChange,
    } = props;
    const checkedClass = checked ? "-checked" : "";
    const className = [BASE_CLASS, cssClassName, checkedClass].join(" ");
    const ref = React.createRef<HTMLInputElement>();

    return (
        <Button
            ariaRole={AriaRole.Checkbox}
            ariaChecked={checked}
            cssClassName={className}
            dataTestId={dataTestId}
            disabled={disabled}
            onClick={onChange}
            style={ButtonStyles.None}>
            <label>{label}</label>
            <p>{description}</p>
            <ToggleButton
                checked={checked}
                disabled={disabled}
                label={label}
                onChange={onChange}
                ref={ref}
                tabIndex={-1}
            />
        </Button>
    );
};

export default CallToActionToggle;
