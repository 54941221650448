import { Do } from "andculturecode-javascript-core";
import type PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import { useMemo, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import UserPublicationFavoriteService from "utilities/services/user-publication-favorites/user-publication-favorite-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Utility hook to manage favoriting/unfavoriting publications.
 * @param publication
 * @param favorites
 * @param setFavorites
 * @param onSuccess
 */
export default function useUpdatePublicationFavorite(
    publication: PublicationRecord,
    favorites: Array<UserPublicationFavoriteRecord>,
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void,
    onSuccess?: (isFavorited: boolean) => void
) {
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;

    const { t } = useLocalization<CultureResources>();

    const [loading, setLoading] = useState(false);
    const favorite = useMemo(
        () =>
            favorites.find(
                (f: UserPublicationFavoriteRecord) =>
                    f.publicationCode === publication.code &&
                    f.publicationEdition === publication.edition
            ),
        [favorites, publication]
    );
    const isFavorited = useMemo(() => favorite != null, [favorite]);
    const currentUserId = useMemo(
        () => currentIdentity?.userId() ?? 0,
        [currentIdentity]
    );
    const currentUserRoleId = useMemo(
        () => currentIdentity?.getCurrentUserRole()?.id ?? 0,
        [currentIdentity]
    );

    const { delete: deleteFavoriteApi } =
        UserPublicationFavoriteService.useDelete();
    const { create: createFavoriteApi } =
        UserPublicationFavoriteService.useCreate();

    const addFavorite = async () => {
        // already favorited or publication not loaded yet
        if (favorite != null || !publication.isPersisted()) {
            return;
        }

        await Do.try(async () => {
            setLoading(true);
            const model = new UserPublicationFavoriteRecord({
                userRoleId: currentUserRoleId,
                publicationCode: publication.code ?? "",
                publicationEdition: publication.edition ?? "",
                publication: publication,
            });
            const result = await createFavoriteApi(model, {
                userId: currentUserId,
            });
            onChangeFavorites([result.resultObject!, ...favorites]);
            onSuccess?.(true);
        })
            .catch(() =>
                ToastManager.error(
                    t("publicationFavorites-errors-addingToFavorites", {
                        codeAndEdition: publication.getDisplayCodeAndEdition(),
                    })
                )
            )
            .finally(() => setLoading(false))
            .getAwaiter();
    };

    const unfavorite = async () => {
        // already not favorited or publication not loaded yet
        if (favorite == null || !publication.isPersisted()) {
            return;
        }

        await Do.try(async () => {
            setLoading(true);
            const id = favorite.id!;
            await deleteFavoriteApi(id, { userId: currentUserId, id });
            onChangeFavorites(
                favorites.filter(
                    (f: UserPublicationFavoriteRecord) => f.id !== id
                )
            );
            onSuccess?.(false);
        })
            .catch(() =>
                ToastManager.error(
                    t("publicationFavorites-errors-removingFromFavorites", {
                        codeAndEdition: publication.getDisplayCodeAndEdition(),
                    })
                )
            )
            .finally(() => setLoading(false))
            .getAwaiter();
    };

    return {
        addFavorite,
        unfavorite,
        isFavorited,
        loading,
    };
}
