import Button from "atoms/buttons/button";
import AlertLevels from "constants/alert-levels";
import { AppNameTm } from "constants/app-name-tm";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import AlertBanner from "molecules/alerts/alert-banner";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { NewUserLoginFormType } from "organisms/userlogins/userlogins-new-form/userlogins-new-form";
import RegisterOrLoginForm from "organisms/users/register-or-login-form";
import { AcceptGroupInvitationPageQueryParams } from "pages/groups/accept-group-invitation-page";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import GroupInvitationService from "utilities/services/group-invitations/group-invitation-service";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import LogOutButton from "../../molecules/log-out-button.tsx/log-out-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { t } from "utilities/localization-utils";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AcceptGroupInvitationFormProps
    extends AcceptGroupInvitationPageQueryParams {
    invitation: GroupInvitationRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-accept-group-invitation-form";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AcceptGroupInvitationForm: React.FC<AcceptGroupInvitationFormProps> = (
    props: AcceptGroupInvitationFormProps
) => {
    const { invitation, invitedBy, teamName } = props;
    const history = useHistory();
    const { globalState, setGlobalState } = useGlobalState();
    const { currentIdentity, isLoggedIn } = useCurrentIdentity();
    const { useGroupInvitationSignoutFlow } = useFeatureFlags();
    const { loadingGroupInvitation } = globalState;
    const { update: acceptGroupInvitationApi } =
        GroupInvitationService.useAccept();
    const { get: getUserConfigurationApi } = UserConfigurationService.useGet();
    const { t } = useLocalization<CultureResources>();

    const { refresh: updateIdentity } = useRefreshIdentity();

    const acceptInvitationError = t("errors-actionResource", {
        action: t("accepting"),
        resource: t("invitation"),
    });
    const joinTeam = t("acceptGroupInvitation-join");
    const toJoinTeam = t("acceptGroupInvitation-toJoin", {
        appNameTm: AppNameTm,
    });
    const inviteText = StringUtils.hasValue(invitedBy)
        ? t("acceptGroupInvitation-invitedByNameToJoin", {
              byName: invitedBy,
              toJoin: toJoinTeam,
          })
        : t("acceptGroupInvitation-invitedToJoin", {
              toJoin: toJoinTeam,
          });
    const mismatchedEmail =
        StringUtils.hasValue(invitation.email) &&
        currentIdentity?.user?.email?.toLowerCase() !==
            invitation.email.toLowerCase();

    const setLoading = useCallback(
        (loadingGroupInvitation: boolean) => {
            setGlobalState((prev) => prev.with({ loadingGroupInvitation }));
        },
        [setGlobalState]
    );

    const acceptGroupInvitation = useCallback(async () => {
        try {
            setLoading(true);
            const response = await acceptGroupInvitationApi(invitation, {
                id: invitation.id,
                groupId: invitation.groupId,
            });
            if (response.result?.hasErrors()) {
                setLoading(false);
                ToastManager.error(acceptInvitationError);
                return;
            }
            ToastManager.success(
                t("acceptGroupInvitation-switchProfiles", {
                    appNameTm: AppNameTm,
                    teamName,
                }),
                { autoClose: false }
            );
            await getUserConfigurationApi(
                {},
                {
                    setNewestUserRole: true,
                }
            );
            await updateIdentity();
            history.push(siteMap.dashboards.user);
        } catch (error) {
            ToastManager.error(acceptInvitationError);
        }
        setLoading(false);
    }, [
        acceptGroupInvitationApi,
        acceptInvitationError,
        getUserConfigurationApi,
        history,
        invitation,
        setLoading,
        t,
        teamName,
        updateIdentity,
    ]);

    const handleUserLogin = () => {
        setLoading(true);
        return true;
    };

    const handleUserLoginError = () => {
        setLoading(false);
    };

    useEffect(() => {
        setGlobalState((prev) =>
            prev.with({
                callbackLink: window.location.pathname + window.location.search,
            })
        );
    }, [setGlobalState]);

    return (
        <React.Fragment>
            {(invitation.id == null ||
                StringUtils.hasValue(invitation.deletedOn) ||
                loadingGroupInvitation) && (
                <FullScreenTransition transitionText={t("loading")} />
            )}
            <div className={`${CLASS_NAME} ${isLoggedIn ? "-logged-in" : ""}`}>
                <div className={CLASS_NAME + "__invited"}>
                    <div>{inviteText}</div>
                    <div className={CLASS_NAME + "__invited__team"}>
                        <span>{`${t("team-name")}`}:</span>
                        <span>{teamName}</span>
                    </div>

                    {isLoggedIn && (
                        <React.Fragment>
                            {mismatchedEmail && (
                                <React.Fragment>
                                    <AlertBanner alertLevel={AlertLevels.Error}>
                                        {t("acceptGroupInvitation-mismatch")}
                                    </AlertBanner>
                                    {useGroupInvitationSignoutFlow && (
                                        <LogOutButton />
                                    )}
                                </React.Fragment>
                            )}
                            {(!mismatchedEmail ||
                                !useGroupInvitationSignoutFlow) && (
                                <Button
                                    disabled={mismatchedEmail}
                                    onClick={acceptGroupInvitation}>
                                    {joinTeam}
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </div>

                {!isLoggedIn && (
                    <div className={CLASS_NAME + "__form"}>
                        <RegisterOrLoginForm
                            defaultEmail={invitation.email}
                            newUserLoginFormType={
                                NewUserLoginFormType.GroupInvitation
                            }
                            onError={handleUserLoginError}
                            onNewUserFormSuccess={acceptGroupInvitation}
                            onSubmit={handleUserLogin}
                            onUserLoginFormSuccess={acceptGroupInvitation}
                            showLogo={false}
                            showRegisterForm={false}
                            showSignInTitle={false}
                            submitButtonText={joinTeam}>
                            <RegistrationEmail email={invitation.email} />
                        </RegisterOrLoginForm>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AcceptGroupInvitationForm;

// #endregion Exports

const RegistrationEmail = ({ email }: { email: string }) => (
    <div className={CLASS_NAME + "__form__email"}>
        <span>{t("registrationEmail")} </span>
        <div className={CLASS_NAME + "__form__email__controls"}>
            <strong>{email}</strong>
            <Button
                accessibleText={t("copy")}
                cssClassName={CLASS_NAME + "__form__email__controls__button"}
                onClick={() => copyToClipboard(email)}
                style={ButtonStyles.Anchor}>
                {t("copy")}
            </Button>
        </div>
    </div>
);

const copyToClipboard = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        ToastManager.success(
            <span>
                Copied <strong>{text}</strong> to clipboard
            </span>
        );
    } catch {
        ToastManager.error("There was an error copying email");
    }
};
