// import { List } from "immutable";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import Slider from "molecules/slider";
import React, { useState } from "react";
import BookmarkReadOnlyDialog from "./bookmark-readonly-dialog";
import BookmarkReadOnlyMobileModal from "./bookmark-readonly-mobile-modal";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import BookmarkSliderHeader from "./bookmark-slider-header";
import SectionRecord from "models/view-models/section-record";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";

export interface BookmarkSliderProps {
    onClose: () => void;
    onDeleted: () => void;
    onEdit: (bookmarkId: number) => void;
    bookmarks: Array<UserBookmarkRecord>;
    title: string;
    section?: SectionRecord;
    handleAddBookmark: () => void;
}

const CSS_CLASS_NAME = "c-bookmark-slider";

const BookmarkSlider: React.FC<BookmarkSliderProps> = (
    props: BookmarkSliderProps
) => {
    const {
        onClose,
        onDeleted,
        onEdit,
        bookmarks,
        title,
        section,
        handleAddBookmark,
    } = props;
    const [index, setIndex] = useState(0);

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const isOnLastBookmark = index === bookmarks.length - 1;
    const isOnFirstBookmark = index === 0;

    const canAddBookmark =
        section?.changes !== XmlChangeNotationConstants.DELETION;

    if (isMobile) {
        return (
            <>
                <BookmarkReadOnlyMobileModal
                    sliderClassName={CSS_CLASS_NAME}
                    onClose={onClose}
                    onEdit={onEdit}
                    onDelete={onDeleted}
                    index={index}
                    isOnFirstBookmark={isOnFirstBookmark}
                    isOnLastBookmark={isOnLastBookmark}
                    setIndex={setIndex}
                    isVisible={true}
                    bookmarks={bookmarks}
                    title={title}
                    handleAddBookmark={handleAddBookmark}
                    canAddBookmark={canAddBookmark}
                />
            </>
        );
    }

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <BookmarkSliderHeader
                    index={index}
                    isOnFirstBookmark={isOnFirstBookmark}
                    isOnLastBookmark={isOnLastBookmark}
                    bookmarks={bookmarks}
                    setIndex={setIndex}
                    handleAddBookmark={handleAddBookmark}
                    onClose={onClose}
                    canAddBookmark={canAddBookmark}
                />
                <Slider index={index}>
                    {bookmarks.map((bookmark: UserBookmarkRecord) => (
                        <BookmarkReadOnlyDialog
                            onClose={onClose}
                            onDeleted={onDeleted}
                            onEdit={onEdit}
                            bookmark={bookmark}
                            title={title}
                            section={section}
                            key={bookmark.id}
                            modifierClass={"-team-bookmark"}
                            handleAddBookmark={handleAddBookmark}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BookmarkSlider;

// #endregion Exports
