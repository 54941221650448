import { RecordUtils } from "andculturecode-javascript-core";
import { List, Record } from "immutable";
import type AnnouncementContext from "models/interfaces/announcement-context";
import AnnouncementRecord from "models/view-models/announcement-record";

const defaultValues: AnnouncementContext =
    RecordUtils.defaultValuesFactory<AnnouncementContext>({
        announcements: List(),
    });

export default class AnnouncementContextRecord
    extends Record(defaultValues)
    implements AnnouncementContext
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: AnnouncementContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.announcements != null) {
            params.announcements = List(
                params.announcements.map((e: AnnouncementRecord) =>
                    RecordUtils.ensureRecord(e, AnnouncementRecord)
                )
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<AnnouncementContext>} values
     */
    public with(
        values: Partial<AnnouncementContext>
    ): AnnouncementContextRecord {
        return new AnnouncementContextRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
