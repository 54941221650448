import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";
import React, { useEffect } from "react";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { useLocation } from "react-router-dom";
import usePrevious from "utilities/hooks/use-previous";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ServiceWorkerUpdate: React.FC = () => {
    const { isReadyToReload } = useServiceWorker();
    const { pathname } = useLocation();
    const previousPathname = usePrevious(pathname);

    const reloadTransition = isReadyToReload && pathname !== previousPathname;

    useEffect(() => {
        if (reloadTransition) {
            window.location.reload();
        }
    }, [reloadTransition]);

    if (!reloadTransition) {
        return null;
    }

    return <FullScreenTransition transitionText="Loading..." />;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ServiceWorkerUpdate;

// #endregion Exports
