import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import ServiceFactory from "../service-factory";
import ServiceHookFactory from "../service-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "enhanced-contents/components";

interface EnhancedContentBasePathParams {}

interface EnhancedContentQueryParams {
    ids: [];
}

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const EnhancedContentByIdService = {
    list: ServiceFactory.nestedList<
        EnhancedContentRecord,
        EnhancedContentBasePathParams,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseEndpoint),

    useList: ServiceHookFactory.useList<
        EnhancedContentRecord,
        EnhancedContentQueryParams
    >(EnhancedContentRecord, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentByIdService;

// #endregion Exports
