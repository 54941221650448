import logo from "assets/images/nfpa-link-color-reversed.png";
import { ButtonSizes } from "atoms/constants/button-sizes";
import Trans from "atoms/i18n/trans";
import Image from "atoms/images/image";
import Paragraph from "atoms/typography/paragraph";
import GlobalStateRecord from "models/view-models/global-state-record";
import SectionShareLinkRecord from "models/view-models/share-links/section-share-link-record";
import RootPortal from "molecules/portals/root-portal";
import React, { useEffect } from "react";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ShareLinkCallToActionBannerProps {
    shareLink?: SectionShareLinkRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ShareLinkCallToActionBanner: React.FC<
    ShareLinkCallToActionBannerProps
> = (props: ShareLinkCallToActionBannerProps) => {
    const CSS_BASE_CLASS_NAME = "c-share-link-cta-banner";

    const { globalState, setGlobalState } = useGlobalState();
    const { t } = useLocalization<CultureResources>();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const buttonSize = isMobile ? ButtonSizes.Small : undefined;

    useEffect(() => {
        if (StringUtils.hasValue(props.shareLink?.guid)) {
            setGlobalState((context: GlobalStateRecord) =>
                context.with({
                    callbackLink: RouteUtils.getUrl(siteMap.share.section, {
                        guid: props.shareLink?.guid,
                    }),
                })
            );
            return;
        }

        setGlobalState((context: GlobalStateRecord) =>
            context.with({ callbackLink: undefined })
        );
    }, [props.shareLink, setGlobalState]);

    return (
        <RootPortal className={CSS_BASE_CLASS_NAME}>
            <div className={`${CSS_BASE_CLASS_NAME}__left-container`}>
                <div className={`${CSS_BASE_CLASS_NAME}__left-container__logo`}>
                    <Image src={logo} />
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__left-container__text`}>
                    {props.shareLink != null && (
                        <label>
                            {t("linkWillExpireWarning", {
                                expiration:
                                    props.shareLink.timeTillExpiration(),
                            })}
                        </label>
                    )}
                    <Paragraph>
                        <Trans i18nKey="signUpCTAMessage">
                            Sign up for a free trial or log in to
                            <strong> NFPA LiNK&reg; </strong>
                            to continue viewing the code.
                        </Trans>
                    </Paragraph>
                </div>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__buttons`}>
                {/* this is hidden in the case where a user is logged in but has no subscriptions */}
                {!globalState.isAuthenticated() && (
                    <LogInAnchor
                        cssClassName={`c-button -secondary ${buttonSize ?? ""}`}
                    />
                )}
                {/*
                 * If they are seeing this, they don't have a subscription, whether they
                 * are logged in or not, so we can go right to the plans page. If we go to
                 * the register page, it redirects to the plans page, and then you can't
                 * use the browser back button because the register page keeps redirecting
                 * you to the plans page.
                 */}
                <PlansAnchor
                    cssClassName={`c-button -secondary ${buttonSize ?? ""}`}
                />
            </div>
        </RootPortal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ShareLinkCallToActionBanner;

// #endregion Exports

