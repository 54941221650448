import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserPublicationFavoritePathParams {
    userId: number;
    id?: number;
}

export interface UserPublicationFavoriteQueryParams {
    userRoleId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const key = "users-favorites-publications";
const baseEndpoint = "users/:userId/favorites/publications";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserPublicationFavoriteRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserPublicationFavoriteService = {
    list: ServiceFactory.nestedList<
        UserPublicationFavoriteRecord,
        UserPublicationFavoritePathParams,
        UserPublicationFavoriteQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        UserPublicationFavoriteRecord,
        UserPublicationFavoritePathParams,
        UserPublicationFavoriteQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        UserPublicationFavoriteRecord,
        UserPublicationFavoritePathParams,
        UserPublicationFavoriteQueryParams
    >(key, resourceType, baseEndpoint),
    create: ServiceFactory.nestedCreate<
        UserPublicationFavoriteRecord,
        UserPublicationFavoritePathParams
    >(resourceType, baseEndpoint),
    useCreate: ServiceHookFactory.useNestedCreate<
        UserPublicationFavoriteRecord,
        UserPublicationFavoritePathParams
    >(resourceType, baseEndpoint),
    delete: ServiceFactory.delete<UserPublicationFavoritePathParams>(
        resourceEndpoint
    ),
    useDelete:
        ServiceHookFactory.useDelete<UserPublicationFavoritePathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserPublicationFavoriteService;

// #endregion Exports
