import { useRef, useEffect, useState } from "react";
import useWindowContext from "utilities/contexts/window/use-window-context";

/**
 * Custom hook to handle the visibility of scroll arrows in a tab list
 */
export default function useTabArrows() {
    const tabListRef = useRef<HTMLDivElement>(null);
    const leftButtonRef = useRef<HTMLButtonElement>(null);
    const rightButtonRef = useRef<HTMLButtonElement>(null);
    const { width: windowWidth } = useWindowContext();

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        setArrowVisibility();
    }, []); // Run once on render

    useEffect(() => {
        setArrowVisibility();
    }, [windowWidth]);

    const setArrowVisibility = (): void => {
        if (
            tabListRef.current == null ||
            leftButtonRef.current == null ||
            rightButtonRef.current == null
        ) {
            return;
        }

        // Value of scrollLeft when the scrollbar is scrolled full right
        const scrollRight =
            tabListRef.current.scrollWidth - tabListRef.current.clientWidth;

        if (scrollRight === 0) {
            setShowLeftArrow(false);
            setShowRightArrow(false);
            return;
        }

        // scrollRight - scrollLeft never exactly 0.
        // Remove the arrows when the difference is less than 5.
        const buffer = 5;

        // Hide left arrow when scrollbar is fully scrolled left
        setShowLeftArrow(tabListRef.current.scrollLeft !== 0);

        // Hide right arrow when scrollbar is fully scrolled right.
        setShowRightArrow(scrollRight - tabListRef.current.scrollLeft > buffer);
    };

    const scrollLeft = (): void => {
        if (tabListRef == null || tabListRef.current == null) {
            return;
        }
        tabListRef.current.scrollLeft -= 20;
    };
    const scrollRight = (): void => {
        if (tabListRef == null || tabListRef.current == null) {
            return;
        }
        tabListRef.current.scrollLeft += 20;
    };

    return {
        tabListRef,
        leftButtonRef,
        rightButtonRef,
        setArrowVisibility,
        scrollLeft,
        scrollRight,
        showLeftArrow,
        showRightArrow,
    };
}
