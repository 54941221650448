import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { RouteUtils } from "utilities/route-utils";
import { isEqual, isEmpty, pick } from "lodash";

export interface MarketingQueryParams {
    icid?: string;
    order_src?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
}

export const MarketingQueryParamsKeys: Array<keyof MarketingQueryParams> = [
    "icid",
    "order_src",
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
];

/**
 * When added to a component, will look for MarketingQueryParams and set those query params
 * in GlobalState.
 */
export default function useMarketingQueryParams() {
    const { globalState, setGlobalState } = useGlobalState();
    const { search } = useLocation();

    const getMarketingQueryParams = globalState.marketingQueryParams ?? {};

    const setMarketingQueryParams = useCallback(() => {
        const allQueryParams =
            RouteUtils.queryStringToObject<MarketingQueryParams>(search);

        const marketingQueryParams = pick(
            allQueryParams,
            MarketingQueryParamsKeys
        );

        if (
            isEmpty(marketingQueryParams) ||
            isEqual(globalState.marketingQueryParams, marketingQueryParams)
        ) {
            return;
        }

        setGlobalState((prevGlobalState) =>
            prevGlobalState.with({ marketingQueryParams })
        );
    }, [globalState.marketingQueryParams, search, setGlobalState]);

    return {
        getMarketingQueryParams,
        setMarketingQueryParams,
    };
}
