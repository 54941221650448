import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { List } from "immutable";
import { Resource } from "models/interfaces/resource";
import { ResourceRecord } from "models/interfaces/resource-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import FileRecord from "models/view-models/file-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import SolutionResourceRecord from "models/view-models/situational-navigation/solutions/solution-resource-record";
import DropdownButton, {
    DropdownItem,
} from "molecules/dropdown-button/dropdown-button";
import DragAndDropListBox from "molecules/lists/drag-and-drop-list-box";
import ListBox, {
    ListBoxItem,
    ListBoxItemClassName,
} from "molecules/lists/list-box";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import AdminResourceListboxItem from "organisms/resources/admin-resource-listbox-item";
import AdminResourceModal from "organisms/resources/admin-resource-modal";
import React, { useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import ResourceType from "utilities/enumerations/resource-type";
import { Constructor } from "utilities/types/constructor";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminResourcesSectionProps<T extends ResourceRecord> {
    allowDragAndDrop?: boolean;
    loading: boolean;
    onReordered?: (startIndex: number, endIndex: number) => void;
    onResourcesChanged: (newResources: ResourceCollectionRecord<T>) => void;
    recordType: Constructor<
        SolutionResourceRecord | EnhancedContentResourceRecord
    >;
    resources: ResourceCollectionRecord<T>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "c-admin-resources-section";
const TRIGGER_BUTTON_TEXT = "Add Resource";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AdminResourcesSection = <T extends ResourceRecord>(
    props: AdminResourcesSectionProps<T>
) => {
    const cssClassNames = [CSS_BASE_CLASS];

    const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
    const [resourceModalExternalMode, setResourceModalExternalMode] =
        useState(false);
    const [editingResource, setEditingResource] = useState<T>();
    const [deletingResource, setDeletingResource] = useState<T>();
    const [currentResourceIndex, setCurrentResourceIndex] = useState<number>();

    useEffect(() => {
        if (!isResourceModalOpen) {
            setEditingResource(undefined);
            setResourceModalExternalMode(false);
        }
    }, [isResourceModalOpen, setEditingResource]);

    useEffect(() => {
        if (editingResource != null) {
            setIsResourceModalOpen(true);
        }
    }, [editingResource, setIsResourceModalOpen]);

    const handleResourceAdded = (
        resource: Resource,
        file: FileRecord | undefined
    ) => {
        let newResourcesArray = [...props.resources.resources.toArray()];
        const index = currentResourceIndex ?? newResourcesArray.length;
        if (index >= newResourcesArray.length) {
            newResourcesArray = [
                ...newResourcesArray,
                new props.recordType().with(resource),
            ];
        } else {
            newResourcesArray = CollectionUtils.replaceElementAt(
                newResourcesArray,
                index,
                new props.recordType().with(resource)
            );
        }

        if (file != null) {
            props.onResourcesChanged(
                ResourceCollectionRecord.fromArrays(
                    props.recordType,
                    newResourcesArray,
                    CollectionUtils.distinctBy(
                        [...props.resources.files.toArray(), file],
                        (f: FileRecord) => f.id
                    )
                )
            );
            setIsResourceModalOpen(false);
            setCurrentResourceIndex(undefined);
            return;
        }

        props.onResourcesChanged(
            ResourceCollectionRecord.fromArrays(
                props.recordType,
                newResourcesArray,
                props.resources.files.toArray()
            )
        );
        setIsResourceModalOpen(false);
        setCurrentResourceIndex(undefined);
    };

    const handleRemoveClick = (resource: T, index: number) => {
        setDeletingResource(resource);
        setCurrentResourceIndex(index);
    };

    const handleDeleteCancel = () => setDeletingResource(undefined);

    const handleResourceRemoved = () => {
        let newResources = props.resources.with({
            resources: List(
                CollectionUtils.removeElementAt(
                    props.resources.resources.toArray(),
                    currentResourceIndex!
                )
            ),
        });
        newResources = newResources.filterByFile((f: FileRecord) =>
            newResources.anyResources((r: T) => r.fileDraftId === f.id)
        );
        setDeletingResource(undefined);
        setCurrentResourceIndex(undefined);
        props.onResourcesChanged(newResources);
    };

    const handleEditClick = (resource: T, index: number) => {
        setEditingResource(resource);
        setResourceModalExternalMode(resource.isExternal(true));
        setCurrentResourceIndex(index);
    };

    const listBoxItems = (): Array<ListBoxItem<string>> =>
        props.resources
            .map(
                (resource: T, file: FileRecord | undefined, index: number) => ({
                    id: index.toString(),
                    label: resource.isExternal(true)
                        ? ResourceType.Link
                        : file?.resourceType() ?? "File",
                    text: (
                        <AdminResourceListboxItem
                            resource={resource}
                            file={file}
                        />
                    ),
                    customAction: (
                        <div
                            className={`${ListBoxItemClassName}__custom-actions`}>
                            <Button
                                disabled={props.loading}
                                onClick={() => handleEditClick(resource, index)}
                                size={ButtonSizes.Small}
                                style={ButtonStyles.Primary}
                                type={ButtonTypes.Button}>
                                Edit
                            </Button>
                            <Button
                                disabled={props.loading}
                                onClick={() =>
                                    handleRemoveClick(resource, index)
                                }
                                size={ButtonSizes.Small}
                                style={ButtonStyles.TertiaryAlt}
                                type={ButtonTypes.Button}>
                                Remove
                            </Button>
                        </div>
                    ),
                }),
                true
            )
            .toArray();

    const resourceDropdownItems: Array<DropdownItem> = [
        {
            component: "Add File",
            onSelect: () => {
                setResourceModalExternalMode(false);
                setIsResourceModalOpen(true);
            },
        },
        {
            component: "Add External Video",
            onSelect: () => {
                setResourceModalExternalMode(true);
                setIsResourceModalOpen(true);
            },
        },
    ];

    const allowDragAndDrop =
        props.allowDragAndDrop === true && props.onReordered != null;

    const resourceDropdownTrigger = (
        <React.Fragment>
            {TRIGGER_BUTTON_TEXT}
            <Icon type={Icons.ChevronDown} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className={cssClassNames.join(" ")}>
                <Paragraph
                    cssClassName={`${CSS_BASE_CLASS}__heading`}
                    size={ParagraphSizes.Large}>
                    Resources
                </Paragraph>
                <div className={`${CSS_BASE_CLASS}__resources`}>
                    {props.resources.hasValues() && !allowDragAndDrop && (
                        <ListBox items={listBoxItems()} />
                    )}
                    {props.resources.hasValues() && allowDragAndDrop && (
                        <DragAndDropListBox
                            items={listBoxItems()}
                            onReordered={props.onReordered!}
                        />
                    )}
                    {props.resources.isEmpty() && <ListBox items={[]} />}
                </div>
                <DropdownButton
                    buttonClassName={`${CSS_BASE_CLASS}__dropdown-btn`}
                    buttonContents={resourceDropdownTrigger}
                    items={resourceDropdownItems}
                    label={TRIGGER_BUTTON_TEXT}
                    size={ButtonSizes.Small}
                />
            </div>
            <AdminResourceModal
                external={resourceModalExternalMode}
                loading={props.loading}
                initialResource={editingResource}
                initialFile={props.resources.getFile(
                    editingResource?.fileDraftId ?? -1
                )}
                onResourceAdded={handleResourceAdded}
                isVisible={isResourceModalOpen}
                onModalClose={() => setIsResourceModalOpen(false)}
            />
            <ConfirmationModal
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                isVisible={deletingResource != null}
                message={`Are you sure you want to delete the resource "${deletingResource?.titleDraft}"?`}
                onCancel={handleDeleteCancel}
                onConfirm={handleResourceRemoved}
            />
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AdminResourcesSection;

// #endregion Exports
