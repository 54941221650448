import { useLocalization } from "utilities/hooks/use-localization";
import Button from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm, AppNameWithHtml } from "constants/app-name-tm";
import { SupportConstants } from "constants/phone-number-constants";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import ContactModal from "pages/support/contact-modal";
import React, { useMemo, useState } from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import CultureResources from "utilities/interfaces/culture-resources";
import { Translator } from "utilities/types/translator-type";
import { SkipNavContent } from "@reach/skip-nav";
import SalesforceConfirmationModal from "pages/support/salesforce-confirmation-modal";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import Trans from "atoms/i18n/trans";
import FAQs from "./faqs";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SupportPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-support-page";

const translateMetaTags = (t: Translator<CultureResources>): Array<MetaTag> => [
    {
        name: MetaTagTypes.Title,
        content: t("supportPage-metaTitle", { appName: AppNameTm }),
    },
    {
        name: MetaTagTypes.Description,
        content: t("supportPage-metaDescription", {
            appName: AppNameTm,
            supportPhone: SupportConstants.UsPhoneNumber,
            supportHours: t("support-hours"),
            supportEmail: SupportConstants.CustomerServiceEmail,
        }),
    },
    {
        name: MetaTagTypes.Keywords,
        content: t("supportPage-metaKeywords"),
    },
];
const translatePageTitle = (t: Translator<CultureResources>): string =>
    t("supportPage-title", { appName: AppNameTm });

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SupportPage: React.FunctionComponent<SupportPageProps> = (
    props: SupportPageProps
) => {
    const { t } = useLocalization<CultureResources>();
    const { isOnline } = useNetworkInformation();
    const headerData = useMemo(
        () => ({
            title: translatePageTitle(t),
            metaTags: translateMetaTags(t),
        }),
        [t]
    );
    useHeaderData(headerData);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const title = t("supportPage-title", { appName: AppNameWithHtml });
    const faq = t("faq");

    const { isInEmulationMode } = useEmulationMode();
    const EMULATION_MODE_RIGHT_CLASS_NAME = isInEmulationMode
        ? "__emulation-mode-right"
        : "__right";

    return (
        <div className={BASE_CLASS_NAME}>
            <SkipNavContent tabIndex={-1}>
                <SalesforceConfirmationModal />
                <div className={`${BASE_CLASS_NAME}__container`}>
                    <Heading priority={HeadingPriority.Four}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        />
                    </Heading>
                    <div className={`${BASE_CLASS_NAME}__left`}>
                        <Heading priority={HeadingPriority.Five}>{faq}</Heading>
                        <FAQs />
                    </div>
                    <div
                        className={`${BASE_CLASS_NAME}${EMULATION_MODE_RIGHT_CLASS_NAME}`}>
                        <Trans i18nKey="supportPage-furtherAssistance">
                            <div
                                className={`${BASE_CLASS_NAME}__contact-container`}>
                                <Paragraph size={ParagraphSizes.Large}>
                                    <strong>Need Further Assistance?</strong>
                                </Paragraph>
                                <Paragraph
                                    cssClassName={`${BASE_CLASS_NAME}__contact-description`}
                                    size={ParagraphSizes.Small}>
                                    Send us a message through the contact
                                    support option below.
                                </Paragraph>
                                <div className={`${BASE_CLASS_NAME}__contact`}>
                                    <Button
                                        onClick={() => setIsModalOpen(true)}>
                                        Contact Support
                                    </Button>
                                </div>
                            </div>
                        </Trans>
                    </div>
                </div>
            </SkipNavContent>
            <ContactModal
                closeDialog={handleModalClose}
                isVisible={isModalOpen && isOnline}>
                <p>Let us know how we can help.</p>
            </ContactModal>
            <OfflineAlertModal
                isOpen={isModalOpen && !isOnline}
                handleClose={handleModalClose}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SupportPage;

// #endregion Export
