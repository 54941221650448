import type ArticleRecord from "models/view-models/article-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import ArticleService from "utilities/services/publications/chapters/articles/article-service";

/**
 * Custom hook for getting Article data based on a Chapter for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param {string | number} publicationId
 * @param {string | number} chapterId
 */
export default function useArticles(
    publicationId?: string | number,
    chapterId?: string | number
): ServiceHookResult<ArticleRecord[]> {
    const [articles, setArticles] = useState<ArticleRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { list: listArticlesApi } = ArticleService.useList();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const listArticles = async (
            publicationId?: number,
            chapterId?: number
        ) => {
            if (publicationId == null || chapterId == null || chapterId === 0) {
                return;
            }

            setLoading(true);

            try {
                const response = await listArticlesApi({
                    publicationId,
                    chapterId,
                });

                setArticles(response.resultObjects ?? []);
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        listArticles(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(chapterId)
        );
    }, [
        chapterId,
        handlePageLoadError,
        listArticlesApi,
        publicationId,
        resetPageErrors,
    ]);

    return {
        errors,
        loaded,
        loading,
        resultObject: articles,
    };
}
