import BlotFormatter from "quill-blot-formatter";
import { Quill } from "react-quill";
import CustomImageSpec from "utilities/quill/blot-formatters/specs/custom-image-spec";
import AbsoluteLink from "utilities/quill/formats/absolute-link";
import CustomHeader from "utilities/quill/formats/custom-header";
import ReferenceLink from "utilities/quill/formats/reference-link";
import ResizableImage from "utilities/quill/formats/resizable-image";
import QuillToolbarAccessibilityModule from "utilities/quill/modules/quill-toolbar-accessibility-module";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ToolbarDropdownOptionConfig {
    text: string;
    value: string | number;
}

export interface ToolbarButtonConfig {
    format: string;
    accessibleText: string;
    value?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const defaultQuillModules = {
    // the options item just has to exist for the module to run
    accessibility: {},
    blotFormatter: {
        align: {
            aligner: {
                // we're applying alignment styles ourselves
                // based on the data-align="left/right/center"
                applyStyle: false,
            },
        },
        specs: [CustomImageSpec],
    },
};

const quillFormats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "header",
    "list",
    "bullet",
    "ordered",
    "link",
    "image",
    "align",
    "script",
    ReferenceLink.blotName,
];

const inlineFormats: Array<ToolbarButtonConfig> = [
    {
        format: "bold",
        accessibleText: "Toggle bold text",
    },
    {
        format: "italic",
        accessibleText: "Toggle italic text",
    },
    {
        format: "underline",
        accessibleText: "Toggle underlined text",
    },
    {
        format: "strike",
        accessibleText: "Toggle strikethrough text",
    },
    {
        format: "blockquote",
        accessibleText: "Toggle blockquote",
    },
    {
        format: "script",
        accessibleText: "Superscript",
        value: "super",
    },
    {
        format: "script",
        accessibleText: "Subscript",
        value: "sub",
    },
];

const alignmentFormats: Array<ToolbarDropdownOptionConfig> = [
    {
        text: "",
        value: "center",
    },
    {
        text: "",
        value: "right",
    },
    {
        text: "",
        value: "justify",
    },
];

const headerFormats: Array<ToolbarDropdownOptionConfig> = [
    {
        text: "Heading 1",
        value: 1,
    },
    {
        text: "Heading 2",
        value: 2,
    },
    {
        text: "Heading 3",
        value: 3,
    },
];

const theme = "snow";

enum ToolbarButtonClasses {
    Align = "ql-align",
    Header = "ql-header",
    Image = "ql-image",
    Link = "ql-link",
    List = "ql-list",
    ReferenceLink = "ql-addReference",
    Section = "ql-formats",
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

/**
 * Registers our custom modules and formats with Quill.
 * To use any of these modules, they MUST be registered
 * before the Quill instance is created.
 */
const registerCustomModules = () => {
    // `Quill.Register` takes an optional third parameter that will suppress warnings when set to 'true'.
    Quill.register("modules/accessibility", QuillToolbarAccessibilityModule);
    Quill.register("modules/blotFormatter", BlotFormatter);
    Quill.register("formats/image", ResizableImage, true);
    Quill.register("formats/link", AbsoluteLink, true);
    Quill.register("formats/referenceLink", ReferenceLink);
    Quill.register("formats/header", CustomHeader, true);
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const QuillConfig = {
    alignmentFormats,
    defaultQuillModules,
    headerFormats,
    inlineFormats,
    quillFormats,
    registerCustomModules,
    theme,
    ToolbarButtonClasses,
};

export default QuillConfig;

// #endregion Exports
