import React from "react";
import useModalActions from "utilities/hooks/use-modal-actions";
import Button from "atoms/buttons/button";
import {
    DropdownButtonProps,
    DropdownItem,
    DropdownButtonClassName,
    DropdownButtonListClassName,
    DropdownButtonListItemClassName,
} from "molecules/dropdown-button/dropdown-button";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import { ModalTransitions } from "molecules/constants/modal-transitions";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface MobileDropdownButtonProps
    extends Omit<DropdownButtonProps, "buttonClassName"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const MobileDropdownButton: React.FunctionComponent<MobileDropdownButtonProps> =
    (props: MobileDropdownButtonProps) => {
        const { isOpen, handleOpen, handleClose } = useModalActions();
        const { buttonContents, items, label, size, style } = props;

        const handleItemClick = (item: DropdownItem) => {
            item.onSelect();
            handleClose();
        };

        return (
            <React.Fragment>
                <Button
                    cssClassName={DropdownButtonClassName}
                    onClick={handleOpen}
                    size={size}
                    style={style}>
                    {buttonContents}
                </Button>
                {isOpen && (
                    <Modal
                        closeDialog={handleClose}
                        label={label}
                        isVisible={true}
                        transition={ModalTransitions.SlideUp}
                        type={ModalTypes.Bottom}>
                        <div className={DropdownButtonListClassName}>
                            {items.map((item: DropdownItem, index: number) => (
                                <button
                                    className={DropdownButtonListItemClassName}
                                    key={index}
                                    onClick={() => handleItemClick(item)}>
                                    {item.component}
                                </button>
                            ))}
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default MobileDropdownButton;

// #endregion Export
