import ServiceHookResult from "utilities/interfaces/service-hook-result";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import { useCallback } from "react";
import { ToastManager } from "utilities/toast/toast-manager";
import { tErrorLoading } from "utilities/localization-utils";
import { CollectionUtils } from "utilities/collection-utils";
import PublicationRecord from "models/view-models/publication-record";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import useDeepCompareEffect from "use-deep-compare-effect";

export interface UsePublicationsByIdsOptions {
    /**
     * Ids for requested publications.
     */
    publicationIds: Array<number>;
}

interface UsePublicationsByIdsHook
    extends ServiceHookResult<Array<PublicationRecord>> {
    refresh: () => void;
}

const BATCH_SIZE = 25;

const usePublicationsByIds = (
    options: UsePublicationsByIdsOptions
): UsePublicationsByIdsHook => {
    const { list } = AdminPublicationService.useBatchedListByPublicationId();

    const { publicationIds } = options;

    const { result, setError, setLoading, setSuccess } =
        useServiceHookResultState<Array<PublicationRecord>>([]);

    const loadPublications = useCallback(
        async (publicationIds: Array<number>) => {
            if (CollectionUtils.isEmpty(publicationIds)) {
                return;
            }

            try {
                setLoading();

                const { resultObjects: publications } = await list(
                    { publicationIds },
                    "publicationIds",
                    BATCH_SIZE
                );

                setSuccess(publications);
            } catch (error) {
                ToastManager.error(tErrorLoading("publication_plural"));
                setError(error);
            }
        },
        [list, setError, setLoading, setSuccess]
    );

    const refresh = useCallback(() => {
        loadPublications(publicationIds);
    }, [loadPublications, publicationIds]);

    useDeepCompareEffect(() => {
        loadPublications(publicationIds);
    }, [loadPublications, publicationIds]);

    return {
        ...result,
        refresh,
    };
};

export default usePublicationsByIds;
