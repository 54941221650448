import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { ModalProps } from "molecules/modals/modal";
import React from "react";
import { t } from "utilities/localization-utils";

interface MobileModalHeaderProps extends Pick<ModalProps, "closeDialog"> {
    heading?: string;
}

const MobileModalHeader: React.FunctionComponent<MobileModalHeaderProps> = (
    props: MobileModalHeaderProps
) => {
    const closeButtonText = t("close");

    return (
        <div className="c-modal__header -mobile">
            <Heading priority={HeadingPriority.Two}>{props.heading}</Heading>
            <Button
                accessibleText={closeButtonText}
                cssClassName={"-modal-close"}
                onClick={props.closeDialog}
                size={ButtonSizes.Small}
                style={ButtonStyles.Secondary}>
                {closeButtonText}
            </Button>
        </div>
    );
};

export default MobileModalHeader;
