import Anchor from "atoms/anchors/anchor";
import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import PublicationTypes from "constants/publication-types";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import * as React from "react";
import { MouseEventHandler } from "react";
import { useLinkPreviewContext } from "utilities/contexts/link-preview/use-link-preview-context";
import { CoreUtils } from "utilities/core-utils";
import useIsNestedRoute from "utilities/hooks/routing/use-is-nested-route";
import useAdminPreview from "utilities/hooks/use-admin-preview";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationAnchorProps extends XmlConvertedComponentProps {
    annexId?: number;
    annexNfpaLabel?: string;
    annexNumber?: string;
    articleId?: number;
    articleNfpaLabel?: string;
    chapterId?: number;
    chapterNfpaLabel?: string;
    chapterNumber?: string;
    groupBy?: string;
    publicationCode?: string;
    publicationEdition?: string;
    publicationType?: string;
    publicationId: number;
    rootSectionId?: number;
    sectionId?: number;
    type: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationAnchor: React.FunctionComponent<PublicationAnchorProps> = (
    props: PublicationAnchorProps
) => {
    const { routeIsBookView } = useIsNestedRoute();

    const { isAdminPreview } = useAdminPreview();
    const {
        isModalOpen,
        onAnchorClick: onPreviewLinkClick,
        onGoToBookView,
    } = useLinkPreviewContext();

    const types = CoreUtils.getEnumValues<PublicationTypes>(PublicationTypes);
    const publicationType = types.find((t) => props.publicationType === t);

    const viewModel = new PublicationAnchorRecord({
        ...props,
        isAdminPreview,
        publicationType,
    });

    const classNames = ["c-publication-anchor"];
    if (props.diffchanged) {
        classNames.push(`c-code-change -${props.diffchanged}`);
    }

    const route = viewModel.getRoute();
    if (route == null) {
        classNames.push("-not-found");
        return <span className={classNames.join(" ")}>{props.children}</span>;
    }

    const onClick = buildClickHandler(
        routeIsBookView,
        props.type,
        isModalOpen,
        (e: React.MouseEvent<HTMLAnchorElement>) => onGoToBookView(e, route),
        (e: React.MouseEvent<HTMLAnchorElement>) =>
            onPreviewLinkClick(e, props.sectionId!, route)
    );

    return (
        <Anchor
            cssClassName={classNames.join(" ")}
            onClick={onClick}
            to={route}>
            {props.children}
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const buildClickHandler = (
    isInBookView: boolean,
    entityType: string,
    isModalOpen: boolean,
    onGoToBookView: MouseEventHandler<HTMLAnchorElement>,
    onPreviewLinkClick: MouseEventHandler<HTMLAnchorElement>
): MouseEventHandler<HTMLAnchorElement> | undefined => {
    if (!isInBookView) {
        return undefined;
    }

    if (isModalOpen) {
        return onGoToBookView;
    }

    if (entityType !== PublicationEntityTypeConstants.Section) {
        return undefined;
    }

    return onPreviewLinkClick;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PublicationAnchor;

// #endregion Export
