import React from "react";
import TableOfContentsAnnexGroup from "organisms/table-of-contents/table-of-contents-annex-group";
import AnnexGroupTableOfContentsRecord from "models/view-models/table-of-contents/annex-group-table-of-contents-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TableOfContentsAnnexGroupsProps {
    annexGroups: AnnexGroupTableOfContentsRecord[];
    annexNfpaLabel: string;
    isPublic?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TableOfContentsAnnexGroups: React.FunctionComponent<TableOfContentsAnnexGroupsProps> =
    (props: TableOfContentsAnnexGroupsProps) => {
        const className = "c-table-of-contents-section";
        const { annexGroups, annexNfpaLabel, isPublic = false } = props;

        return (
            <nav className={className}>
                <ul>
                    {annexGroups.map((annexGroup) => (
                        <TableOfContentsAnnexGroup
                            annexGroup={annexGroup}
                            annexNfpaLabel={annexNfpaLabel}
                            isPublic={isPublic}
                            key={annexGroup.groupBy}
                        />
                    ))}
                </ul>
            </nav>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TableOfContentsAnnexGroups;

// #endregion Export
