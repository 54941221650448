import SectionRecord from "models/view-models/section-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface SectionBasePathParams {
    articleId: number;
    chapterId: number;
    publicationId: number;
}

export interface SectionResourcePathParams extends SectionBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Chapter resources.
 * These get translated to QueryString parameters
 */
export interface SectionListQueryParams {}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint =
    "publications/:publicationId/chapters/:chapterId/articles/:articleId/sections";
const resourceType = SectionRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "publications-chapters-articles-sections";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const SectionService = {
    /**
     * Retrieve a specific Section resource
     */
    get: ServiceFactory.get<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of Section resources based upon supplied search criteria
     */
    list: ServiceFactory.nestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<SectionRecord, SectionResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<
        SectionRecord,
        SectionResourcePathParams
    >(key, resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default SectionService;

// #endregion Exports
