import TableRecord from "models/view-models/table-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

export interface TableBasePathParams {
    chapterId: number;
}

export interface TableQueryParams {}

const baseEndpoint = "publications/chapters/:chapterId/tables";
const adminPreviewEndpoint = "admin/publications/chapters/:chapterId/tables";
const resourceType = TableRecord;

const TableService = {
    list: ServiceFactory.nestedList<
        TableRecord,
        TableBasePathParams,
        TableQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        TableRecord,
        TableBasePathParams,
        TableQueryParams
    >(resourceType, baseEndpoint),
    usePreviewList: ServiceHookFactory.useNestedList<
        TableRecord,
        TableBasePathParams,
        TableQueryParams
    >(resourceType, adminPreviewEndpoint),
};

export default TableService;
