import ServiceHookFactory from "utilities/services/service-hook-factory";
import BrightcoveMetadataRecord from "models/view-models/external/brightcove/brightcove-metadata-record";
import ServiceFactory from "utilities/services/service-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BrightcoveMetadataPathParams {
    videoId: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "external/brightcove/metadata/:videoId";
const resourceType = BrightcoveMetadataRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const BrightcoveMetadataService = {
    get: ServiceFactory.get<
        BrightcoveMetadataRecord,
        BrightcoveMetadataPathParams
    >(resourceType, baseEndpoint),
    useGet: ServiceHookFactory.useGet<
        BrightcoveMetadataRecord,
        BrightcoveMetadataPathParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BrightcoveMetadataService;

// #endregion Exports
