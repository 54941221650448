import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import RouteTabs from "molecules/route-tabs/route-tabs";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React from "react";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import FreeAccessPublicationAdminJobStatuses from "./free-access-publications-admin-job-statuses";
import { FreeAccessPublicationsAdminUtils } from "./free-access-publications-admin-utils";

export interface FreeAccessPublicationsAdminJobsProps
    extends NestedRoutesProps {}

const CSS_CLASS_NAME = "c-enhanced-content-admin-dashboard";
const FreeAccessPublicationsAdminJobManagement: React.FC<any> = (
    props: FreeAccessPublicationsAdminJobsProps
) => {
    return (
        <>
            <BreadcrumbsMenu fullWidth={true} />
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.Five}>
                        Free Access Jobs Management
                    </Heading>
                    <RouteTabs
                        redirectIfNotFound={false}
                        routes={props.routes}
                        tabs={FreeAccessPublicationsAdminUtils.getTabs()}
                    />
                </div>
                <div
                    className={`${CSS_CLASS_NAME}__content c-enhanced-content-admin-tab-content`}>
                    <FreeAccessPublicationAdminJobStatuses />
                </div>
            </div>
        </>
    );
};

export default FreeAccessPublicationsAdminJobManagement;
