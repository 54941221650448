import MultiSelect from "atoms/forms/multi-select";
import { SelectOption } from "atoms/forms/select";
import WorkGroupMember from "models/interfaces/work-groups/work-group-member";
import UserRecord from "models/view-models/user-record";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import React from "react";
import { StylesConfig } from "react-select";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useReadCurrentGroupMembers from "utilities/hooks/domain/work-groups/use-read-current-group-members";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

const MODAL_CONTENT_ID = "workgroup-modal-content";
const MULTI_SELECT_ID = "workgroup-multi-select";

interface AddUserDropdownProps {
    currentWorkGroupMembers: WorkGroupMember[];
    updateWorkGroup: (values: Partial<WorkGroupRecord>) => void;
}

const AddUserDropdown: React.FC<AddUserDropdownProps> = (props) => {
    const { currentWorkGroupMembers, updateWorkGroup } = props;

    const { loaded, resultObject: currentUsers } = useReadCurrentGroupMembers();

    let userOptions;
    if (loaded) {
        const filteredUsers = filterCurrentMembers(
            currentWorkGroupMembers,
            currentUsers
        );
        userOptions = createSelectOptions(filteredUsers);
    }

    const handleChange = (selectedUser: SelectOption<UserRecord, number>) => {
        const newWorkGroupMember = {
            name: selectedUser.label,
            userId: selectedUser.value,
        };

        updateWorkGroup({
            workGroupMembers: [newWorkGroupMember, ...currentWorkGroupMembers],
        });
    };

    const closeMenuOnScroll = (e: Event) =>
        e.target != null && (e.target as HTMLElement).id === MODAL_CONTENT_ID;

    const { t } = useLocalization<CultureResources>();
    const groupMembers = t("workGroup-groupMembers");
    const placeholder = t("searchUsers");
    const mobilePlaceholder = t("addItem", { item: t("user_plural") });

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const customStyles: Partial<StylesConfig> = {
        menuList: (provided) => ({
            ...provided,
            height: "40vh",
            maxHeight: "40vh",
        }),
    };

    return (
        <MultiSelect
            closeMenuOnScroll={closeMenuOnScroll}
            customStyles={isMobile ? customStyles : undefined}
            id={MULTI_SELECT_ID}
            isValid={true}
            menuPosition={"fixed"}
            label={groupMembers}
            options={userOptions}
            onChange={handleChange}
            placeholder={isMobile ? mobilePlaceholder : placeholder}
            isMulti={false}
            searchable={!isMobile}
            showSelection={false}
        />
    );
};

export default AddUserDropdown;

const createSelectOptions = (
    users: UserRecord[]
): Array<SelectOption<UserRecord, number>> =>
    users
        .map((user) => ({
            data: user,
            label: `${user.firstName} ${user.lastName}`,
            value: user.id!,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

const filterCurrentMembers = (
    currentMembers: WorkGroupMember[],
    userOptions: UserRecord[]
) =>
    userOptions.filter(
        (user) =>
            currentMembers.findIndex((member) => user.id === member.userId) ===
            -1
    );
