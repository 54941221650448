import PublishStatus from "models/enumerations/publish-status";
import Publishable from "models/interfaces/publishable";
import moment from "moment";
import StringUtils from "utilities/string-utils";

export interface PublishableRecord extends Publishable {
    hasUnpublishedChanges(): boolean;
    getPublishStatus(): PublishStatus;
}

const hasUnpublishedChanges = (publishable: PublishableRecord): boolean => {
    if (publishable.getPublishStatus() !== PublishStatus.Published) {
        return false;
    }

    const secondsBetweenPublishAndUpdate = Math.abs(
        moment(publishable.updatedOn ?? publishable.createdOn).diff(
            moment(publishable.publishedOn),
            "seconds"
        )
    );

    // Compensate for slight discrepencies between the published and updated dates
    return secondsBetweenPublishAndUpdate > 0.5;
};

const getPublishStatus = (publishable: PublishableRecord): PublishStatus => {
    if (StringUtils.isEmpty(publishable.publishedOn)) {
        return PublishStatus.Unpublished;
    }

    return PublishStatus.Published;
};

const PublishableUtils = {
    hasUnpublishedChanges,
    getPublishStatus,
};

export default PublishableUtils;
