import React from "react";
import { t } from "utilities/localization-utils";

const EnhancedContentBadge: React.FC = () => {
    const className = "c-tag -enhancedContent";

    return <span className={className}>{t("enhancedContent")}</span>;
};

export default EnhancedContentBadge;
