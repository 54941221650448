import React from "react";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useLocalization } from "utilities/hooks/use-localization";
import PublicationRecord from "models/view-models/publication-record";
import { PublicationComponentRecord } from "utilities/service-worker/search/publication-component-record-store";

// -----------------------------------------------------------------------------------------
// #region types
// -----------------------------------------------------------------------------------------

type entityTypes = PublicationComponentRecord | PublicationRecord;

// #endregion types

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationContentRedirectProps {
    entities: Array<entityTypes>;
    loaded: boolean;
    loading: boolean;
    missingParam?: boolean;
    route: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationContentRedirect: React.FC<PublicationContentRedirectProps> = (
    props: PublicationContentRedirectProps
) => {
    const { entities, loaded, loading, missingParam = false, route } = props;

    const { t } = useLocalization();

    if (loading || !loaded) {
        return <FullScreenTransition transitionText={t("redirecting")} />;
    }

    const missingResource = entities.some((e) => !e.isPersisted());
    if (missingResource || missingParam) {
        return <Redirect to={siteMap.errors.notFound} />;
    }

    return <Redirect to={route} />;
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationContentRedirect;

// #endregion Exports
