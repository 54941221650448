import Tooltip, {
    TooltipOffset,
    TooltipPlacement,
} from "molecules/tooltips/tooltip";
import * as React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SidebarNavigationTooltipProps {
    children: React.ReactElement;
    description: string;
    disabled?: boolean;
    offsetInPx?: TooltipOffset;
    title: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export default function SidebarNavigationTooltip(
    props: SidebarNavigationTooltipProps
) {
    const cssBaseClassName = "c-sidebar-navigation";
    const cssToolTipClassName = `${cssBaseClassName}__tooltip`;

    const { children, description, disabled = false, title } = props;

    const isMobile = useBreakpoint(
        Breakpoints.Tablet,
        BreakpointComparer.MaxWidth
    );

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const tooltipContent = (
        <div>
            <div className={`${cssToolTipClassName}__title`}>{title}</div>
            <div className={`${cssToolTipClassName}__description`}>
                {description}
            </div>
        </div>
    );

    return (
        <Tooltip
            content={tooltipContent}
            cssClassName={`${cssBaseClassName}__tooltip`}
            disabled={isMobile || disabled}
            durationInMs={0}
            hideOnClick={true}
            offsetInPx={props.offsetInPx}
            placement={TooltipPlacement.Right}>
            {children}
        </Tooltip>
    );

    // #endregion Render
}

// #endregion Component
