import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { QueryHookOptions } from "utilities/interfaces/query-hook-options";
import NumberUtils from "utilities/number-utils";
import StringUtils from "utilities/string-utils";
import { ReactQueryUtils } from "utilities/react-query-utils";
import NestedArticleService from "utilities/services/publications/chapters/articles/article-service";
import ArticleService from "utilities/services/articles/article-service";
import ArticleRecord from "models/view-models/article-record";
import _ from "lodash";

export interface UseArticleQueryOptions extends QueryHookOptions {
    code?: string;
    chapterId?: string | number;
    edition?: string;
    id?: string | number;
    nfpaLabel?: string;
    publicationId?: string | number;
}

const defaultResultObject: ArticleRecord = new ArticleRecord();

export default function useArticleQuery(
    options: UseArticleQueryOptions
): ServiceHookResult<ArticleRecord> {
    const { code, edition, nfpaLabel } = options;
    const publicationId = NumberUtils.parseInt(options.publicationId);
    const chapterId = NumberUtils.parseInt(options.chapterId);
    const id = NumberUtils.parseInt(options.id);

    const { list } = ArticleService.useListQuery();
    const { get } = ArticleService.useGetQuery();
    const { list: nestedList } = NestedArticleService.useListQuery();
    const { get: nestedGet } = NestedArticleService.useGetQuery();

    const listEnabled = code != null && edition != null && nfpaLabel != null;
    const listResult = list(
        { code, edition, nfpaLabel },
        { enabled: listEnabled }
    );

    const getEnabled = !listEnabled && !NumberUtils.isDefault(id);
    const getResult = get({ id: id! }, undefined, { enabled: getEnabled });

    const nestedListEnabled =
        !listEnabled &&
        !getEnabled &&
        !NumberUtils.isDefault(publicationId) &&
        !NumberUtils.isDefault(chapterId) &&
        !StringUtils.isEmpty(nfpaLabel);

    const nestedListResult = nestedList(
        { publicationId: publicationId!, chapterId: chapterId! },
        { nfpaLabel },
        { enabled: nestedListEnabled }
    );

    const nestedGetEnabled =
        !nestedListEnabled &&
        !getEnabled &&
        !listEnabled &&
        !NumberUtils.isDefault(publicationId) &&
        !NumberUtils.isDefault(chapterId) &&
        !NumberUtils.isDefault(id);

    const nestedGetResult = nestedGet(
        { publicationId: publicationId!, chapterId: chapterId!, id: id! },
        undefined,
        { enabled: nestedGetEnabled }
    );

    const article: ArticleRecord | undefined = _.chain([
        listResult.data?.resultObjects[0],
        getResult.data?.resultObject,
        nestedGetResult.data?.resultObject,
        nestedListResult.data?.resultObjects[0],
    ])
        .compact()
        .first()
        .value();

    const { loading, loaded, errors } = ReactQueryUtils.getResult(
        listResult,
        getResult,
        nestedGetResult,
        nestedListResult
    );

    const resultObject = article ?? defaultResultObject;

    return { loading, loaded, errors, resultObject };
}
