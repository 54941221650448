import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import ChangeIndicatorDiffContent from "./change-indicator-diff-content";

export interface ChangeIndicatorDiffModalProps {
    isTable?: boolean;
    isPart?: boolean;
}

const cssBaseClass: string = "c-change-indicator-diff-panel";

const ChangeIndicatorDiffPanel: React.FC<ChangeIndicatorDiffModalProps> = (
    props
) => {
    const { isTable, isPart } = props;
    const { showDiffPanel, content, handleClose, setChangeSummaries } =
        useChangeIndicatorDiffPanel();
    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
    const { t } = useLocalization<CultureResources>();

    const CHANGED_SUBHEADER = t("diffPanelSubHeader");
    const NEW_SUBHEADER = t("diffPanelSubHeaderNew");
    const PREVIOUS_VERSION_SUBHEADER = t("diffPanelSubHeaderPriorEdition");

    const showPriorEdition =
        content.body == null ||
        content.changes === XmlChangeNotationConstants.DELETION;
    const isANewItem =
        content.changes === XmlChangeNotationConstants.NEW_MATERIAL;
    const showBody = content.body != null && !(isANewItem || showPriorEdition);

    const getSubHeader = () => {
        if (isANewItem) return NEW_SUBHEADER;
        if (showPriorEdition) return PREVIOUS_VERSION_SUBHEADER;
        return CHANGED_SUBHEADER;
    };

    const showDesktopView = isDesktop && showDiffPanel;
    const showMobileView = !isDesktop && showDiffPanel;

    return (
        <React.Fragment>
            {showDesktopView && (
                <div
                    className={`${cssBaseClass} ${isPart ? "-part" : ""} ${
                        isTable ? "-is-table" : ""
                    }`}
                    id={"diff-panel"}>
                    <div className={`${cssBaseClass}__container`}>
                        <ChangeIndicatorDiffContent
                            content={content}
                            showBody={showBody}
                            subHeader={getSubHeader()}
                            setChangeSummaries={setChangeSummaries}
                        />
                    </div>
                </div>
            )}
            {showMobileView && (
                <Modal
                    useDialogOverlay={true}
                    cssClassName={"c-codes-modal"}
                    closeDialog={handleClose}
                    isVisible={true}
                    label={t("viewChanges")}
                    transition={ModalTransitions.SlideUp}
                    type={ModalTypes.Fullscreen}>
                    <ChangeIndicatorDiffContent
                        content={content}
                        showBody={showBody}
                        subHeader={getSubHeader()}
                        setChangeSummaries={setChangeSummaries}
                    />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default ChangeIndicatorDiffPanel;
