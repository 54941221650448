import { useEffect, useState } from "react";

/**
 * Custom hook to aggregate multiple loading flags into one.
 * Useful for conditionally rendering things while data is loading.
 * If ANY of the ...values are true, loading will be true. If ALL
 * ...values are false, then loading will be false.
 * Essentially creates a variable which is an aggregate of all ...values.
 * @param values
 */
export default function useLoading(...values: Array<boolean>) {
    const [loading, setLoading] = useState(
        values.some((boolValue: boolean) => boolValue)
    );

    useEffect(
        () => setLoading(values.some((boolValue: boolean) => boolValue)),
        [values]
    );

    return loading;
}
