import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import { t } from "utilities/localization-utils";

interface UnrecognizedEmailProps {
    resetForm: () => void;
}

const UnrecognizedEmail = ({ resetForm }: UnrecognizedEmailProps) => {
    const handleTryAgainBtnClick = () => {
        resetForm();
    };

    return (
        <div>
            <Heading priority={HeadingPriority.Two}>
                {t("on-demand-unrecognized-email-1")}
            </Heading>
            <Paragraph>
                {t("on-demand-unrecognized-email")}&nbsp;
                <button
                    aria-label={t("tryAgain")}
                    onClick={handleTryAgainBtnClick}>
                    {t("tryAgain")}
                </button>
                .
            </Paragraph>
            <Paragraph>{t("on-demand-contact-admin-1")}</Paragraph>
        </div>
    );
};
export default UnrecognizedEmail;
