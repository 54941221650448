import AnnexRecord from "models/view-models/annex-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import AnnexService from "utilities/services/publications/annexes/annex-service";
import PublicationService from "utilities/services/publications/publication-service";
import StringUtils from "utilities/string-utils";
import { tErrorLoading } from "utilities/localization-utils";

/**
 * Custom hook for getting Annex data for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param  {string | number} publicationId
 * @param  {string | number} annexId
 * @param {string} nfpaLabel
 */
export default function useAnnex(
    publicationId?: string | number,
    annexId?: string | number,
    nfpaLabel?: string
): ServiceHookResult<AnnexRecord> {
    const [annex, setAnnex] = useState<AnnexRecord>(new AnnexRecord());
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { get: getAnnexApi } = AnnexService.useGet();
    const { list: listAnnexesApi } = AnnexService.useList();
    const { get: getPublicationApi } = PublicationService.useGet();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    const loadPublication = useCallback(
        async (publicationId: number) => {
            const result = await getPublicationApi({ id: publicationId });
            return result.resultObject!;
        },
        [getPublicationApi]
    );

    const loadByNfpaLabel = useCallback(
        async (publicationId: number, nfpaLabel: string) => {
            setLoading(true);

            try {
                const [annexResponse, publication] = await Promise.all([
                    listAnnexesApi(
                        { publicationId },
                        { nfpaLabel, skip: 0, take: 1 }
                    ),
                    loadPublication(publicationId),
                ]);
                if (CollectionUtils.isEmpty(annexResponse.resultObjects)) {
                    handlePageLoadError(tErrorLoading("annex"));
                    setLoading(false);
                    setLoaded(true);
                    return;
                }
                const annex = annexResponse.resultObjects[0]!.withPublication([
                    publication,
                ]);
                setAnnex(annex);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoading(false);
            setLoaded(true);
        },
        [listAnnexesApi, loadPublication, handlePageLoadError, resetPageErrors]
    );

    const loadById = useCallback(
        async (publicationId: number, annexId: number) => {
            setLoading(true);

            try {
                const [annexResponse, publication] = await Promise.all([
                    getAnnexApi({
                        publicationId: publicationId,
                        id: annexId,
                    }),
                    loadPublication(publicationId),
                ]);

                const annex = annexResponse.resultObject!.withPublication([
                    publication,
                ]);
                setAnnex(annex);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoading(false);
            setLoaded(true);
        },
        [getAnnexApi, loadPublication, handlePageLoadError, resetPageErrors]
    );

    const load = useCallback(
        async (
            publicationId?: number,
            annexId?: number,
            nfpaLabel?: string
        ) => {
            if (publicationId == null || publicationId === 0) {
                return;
            }

            if (annexId != null) {
                return await loadById(publicationId, annexId);
            }

            if (StringUtils.hasValue(nfpaLabel)) {
                return await loadByNfpaLabel(publicationId, nfpaLabel!);
            }
        },
        [loadById, loadByNfpaLabel]
    );

    useEffect(() => {
        load(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(annexId),
            nfpaLabel
        );
    }, [publicationId, annexId, nfpaLabel, load]);

    return {
        errors,
        loaded,
        loading,
        resultObject: annex,
    };
}
