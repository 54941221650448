import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import { ChapterNfpaLabelParams } from "interfaces/routing/chapter-nfpa-label-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import ChapterRecord from "models/view-models/chapter-record";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import PublicationSidebarNavLinks from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-links";
import React, { useMemo } from "react";
import { match } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import useChapter from "utilities/hooks/domain/chapters/use-chapter";
import useSections from "utilities/hooks/domain/publications/chapters/sections/use-sections";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import NumberUtils from "utilities/number-utils";
import StringUtils from "utilities/string-utils";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import PublicationTypes from "constants/publication-types";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SectionPageParams {
    externalId: string;
    id: string;
}

interface SectionMetaNavProps {
    match: match<
        NestedPublicationParams & ChapterNfpaLabelParams & SectionPageParams
    >;
}
// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionMetaNav: React.FunctionComponent<SectionMetaNavProps> = (
    props: SectionMetaNavProps
) => {
    const {
        publicationId,
        externalId,
        code,
        edition,
        chapterId,
        id: sectionId,
        chapterNfpaLabel: nfpaLabel,
    } = props.match.params;

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: publication,
        loading: publicationLoading,
        errors: publicationErrors,
    } = usePublication({
        code,
        edition,
        publicationId,
    });

    const {
        chapter,
        loading: chapterLoading,
        errors: chapterErrors,
    } = useChapter(code, edition, nfpaLabel, NumberUtils.parseInt(chapterId));

    const {
        resultObject: sections,
        loading: sectionsLoading,
        errors: sectionErrors,
    } = useSections(publication.id, chapter?.id);

    const loading = useLoading(
        chapterLoading,
        publicationLoading,
        sectionsLoading
    );

    const errors = useErrors(chapterErrors, publicationErrors, sectionErrors);
    useErrorBoundary(errors);

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Computations & Transformations
    // -----------------------------------------------------------------------------------------

    const navItems = useMemo(() => {
        if (chapter == null) {
            return [];
        }

        return _getSectionNavItems(
            sections,
            publication,
            chapter,
            externalId,
            NumberUtils.parseInt(sectionId),
            isAdminPreview
        );
    }, [chapter, sections, publication, externalId, isAdminPreview, sectionId]);

    const backButtonText = t("chapterNumber", { number: chapter?.number });
    const backButtonRoute =
        chapter?.with({ publication }).getRoute(isAdminPreview) ?? "#";

    const backButtonAccessibleText = t("backToLocation", {
        location: chapter?.getDisplayTitle(),
    });
    const type = publication?.type;

    // #endregion Computations & Transformations

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <AnchorWithIcon
                accessibleText={backButtonAccessibleText}
                icon={Icons.ChevronLeft}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                to={backButtonRoute}>
                {backButtonText}
            </AnchorWithIcon>
            {chapter != null && !loading && (
                <PublicationSidebarNav title={chapter.getDisplayTitle()}>
                    <PublicationSidebarNavLinks
                        navItems={navItems}
                        publicationType={type}
                    />
                </PublicationSidebarNav>
            )}
            {loading && <Loader type={LoaderStyles.LinkGlyphGray} />}
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList listItems={errors} />
            )}
        </React.Fragment>
    );

    // #endregion Returns
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

const _getSectionNavItems = (
    sections: SectionRecord[],
    publication: PublicationRecord,
    chapter: ChapterRecord,
    activeExternalId: string,
    sectionId: number | undefined,
    isAdminPreview?: boolean
): PublicationSidebarNavItem[] =>
    sections
        .filter((s) => s.parentId == null)
        .map((section) => {
            let active =
                StringUtils.hasValue(activeExternalId) &&
                section.externalId === activeExternalId;

            if (!NumberUtils.isDefault(sectionId)) {
                active = section.id === sectionId;
            }
            return section.with({ publication, chapter }).toNavItem(
                {
                    active,
                    searchForLinkOnPage: _shouldSearchForLinkOnPage(
                        publication.type
                    ),
                },
                publication.type,
                isAdminPreview
            );
        });

const _shouldSearchForLinkOnPage = (
    publicationType?: PublicationTypes
): boolean => {
    if (publicationType === PublicationTypes.NEC) {
        return false;
    }
    return true;
};
// #endregion Private Methods

export default SectionMetaNav;
