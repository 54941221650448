import React from "react";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import TableOfContentsCollapsePanelLink from "organisms/table-of-contents/table-of-contents-collapse-panel-link";
import ArticleTableOfContentsRecord from "models/view-models/table-of-contents/article-table-of-contents-record";
import { useParams } from "react-router-dom";
import { ArticleParams } from "interfaces/routing/route-params";

interface TableOfContentsArticleProps {
    articles: ArticleTableOfContentsRecord[];
    chapterNfpaLabel: string;
    isPublic?: boolean;
    locale?: string;
}

const className = "c-table-of-contents-section";

const TableOfContentsArticle: React.FunctionComponent<TableOfContentsArticleProps> =
    (props: TableOfContentsArticleProps) => {
        const { articles, chapterNfpaLabel, isPublic = false, locale } = props;
        const { isAdminPreview } = useAdminPreview();

        const params = useParams<ArticleParams>();

        const sortedArticles = articles.sort(
            (a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0)
        );

        return (
            <nav className={className}>
                <ul>
                    {sortedArticles.map((article) => (
                        <TableOfContentsCollapsePanelLink
                            key={article.id}
                            dataTestDisplaySequence={article.displaySequence}
                            dataTestId={"article-link"}
                            hasBookmark={article.hasBookmark}
                            index={article.id}
                            isPublic={isPublic}
                            to={article.getRoute(
                                params,
                                chapterNfpaLabel,
                                isAdminPreview
                            )}>
                            <span>{article.getDisplayTitle(locale)}</span>
                        </TableOfContentsCollapsePanelLink>
                    ))}
                </ul>
            </nav>
        );
    };

export default TableOfContentsArticle;
