import { CollectionUtils } from "utilities/collection-utils";
import { Icons } from "atoms/constants/icons";
import TextInputIcon from "atoms/forms/text-input-icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import PublishStatus from "models/enumerations/publish-status";
import PublicationRecord from "models/view-models/publication-record";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import PublishStatusFiltersBar from "organisms/admin/situational-navigation/publish-status-filters-bar";
import React, { useEffect, useState } from "react";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import usePublications from "utilities/hooks/domain/admin/publications/use-publications";
import useDebounce from "utilities/hooks/use-debounce";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import PublicationAdminSideBarItem from "./publication-admin-sidebar-item";
import ContentFormatFiltersBar from "../situational-navigation/content-format-filters-bar";
import PublicationContentFormatType from "utilities/enumerations/publication-content-format-type";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useHistory } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------
const CSS_CLASS_NAME = "c-publications-admin-dashboard__content__sidebar";
// #endregion Constants

interface PublicationsAdminSidebarProps {}

const PublicationsAdminSidebar: React.FunctionComponent<PublicationsAdminSidebarProps> =
    (props: PublicationsAdminSidebarProps) => {
        const { context, setContext } = useAdminEditorPageContext();
        const [inputText, setInputText] = useState<string>();
        const searchText = useDebounce(inputText);
        const [publishStatus, setPublishStatusFilter] =
            useState<PublishStatus>();
        const [publicationContentFormatType, setPublicationContentFormatType] =
            useState<PublicationContentFormatType | undefined>(
                PublicationContentFormatType.Xml
            );
        const [shouldSelectAll, setShouldSelectAll] = useState(false);
        const { globalState } = useGlobalState();

        const history = useHistory();

        const {
            resultObject: publications,
            loading,
            refresh,
        } = usePublications(
            true,
            publishStatus,
            publicationContentFormatType,
            searchText
        );

        useEffect(() => {
            if (!context.shouldFetchNavItems) {
                return;
            }
            refresh?.(); // Refresh Publications
            setContext((prevState: AdminEditorPageContextRecord) =>
                prevState.with({ shouldFetchNavItems: false })
            );
        }, [refresh, context, setContext]);

        const batchRoute = RouteUtils.getUrl(siteMap.admin.publications.batch);
        useEffect(() => {
            if (shouldSelectAll) {
                setContext((prevState) =>
                    prevState.with({ publicationBatch: publications })
                );
                history.push(batchRoute);
            } else {
                setContext((prevState) =>
                    prevState.with({ publicationBatch: [] })
                );
            }
        }, [batchRoute, history, publications, setContext, shouldSelectAll]);

        const handleSelectAll = () => setShouldSelectAll((current) => !current);

        const handleSearchTextChanged = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => setInputText(e.target.value);

        const handlePublishStatusFiltersChanged = (
            newFilters: PublishableFilters
        ) => setPublishStatusFilter(newFilters.publishStatus);

        const handleContentFormatFilterChanged = (
            contentFormat: PublicationContentFormatType | undefined
        ) => {
            setPublicationContentFormatType(contentFormat);
        };
        // Since we're ignoring unpublished changes, this will add the selected state styles to the
        // publish status buttons
        const hasUnpublishedChanges =
            publishStatus === PublishStatus.Published ? false : undefined;

        const selectAllText = shouldSelectAll ? "Deselect All" : "Select All";

        return (
            <div className={CSS_CLASS_NAME}>
                <TextInputIcon
                    icon={Icons.Search}
                    id="publication-sidebar-search"
                    onChange={handleSearchTextChanged}
                    value={inputText}
                    placeholder={`Filter by code, edition or title...`}
                />
                <div className={`${CSS_CLASS_NAME}__filters`}>
                    <PublishStatusFiltersBar
                        value={{
                            publishStatus: publishStatus,
                            hasUnpublishedChanges: hasUnpublishedChanges,
                            searchText: inputText,
                        }}
                        onChange={handlePublishStatusFiltersChanged}
                        includePublishedWithChanges={false}
                    />
                    <ContentFormatFiltersBar
                        value={publicationContentFormatType}
                        onChange={handleContentFormatFilterChanged}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__select`}>
                    <Button
                        onClick={handleSelectAll}
                        size={ButtonSizes.Small}
                        style={ButtonStyles.Secondary}>
                        {selectAllText}
                    </Button>
                </div>
                <div className={`${CSS_CLASS_NAME}__items`}>
                    {loading && (
                        <Loader accessibleText={"Loading publications..."} />
                    )}
                    {!loading && CollectionUtils.isEmpty(publications) && (
                        <Paragraph
                            cssClassName={`${CSS_CLASS_NAME}__no-publications`}>
                            No Publications
                        </Paragraph>
                    )}
                    {!loading && CollectionUtils.hasValues(publications) && (
                        <React.Fragment>
                            {publications.map(
                                (pub: PublicationRecord, index: number) => (
                                    <PublicationAdminSideBarItem
                                        publication={pub}
                                        key={index}
                                    />
                                )
                            )}
                        </React.Fragment>
                    )}
                </div>

                {globalState.currentIdentity?.isAdmin() && (
                    <div className={`${CSS_CLASS_NAME}__footer`}>
                        <Anchor
                            to={siteMap.admin.publications.create}
                            cssClassName="c-button">
                            New Publication
                        </Anchor>
                    </div>
                )}
            </div>
        );
    };

export default PublicationsAdminSidebar;
