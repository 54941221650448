import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import { t } from "utilities/localization-utils";

interface TiaChangesPanelNavigationProps {
    activeTia: number;
    numberOfTias: number;
    onSetActiveTia: (newTiaIndex: number) => void;
    cssBaseClass?: string;
}

const TiaChangesPanelNavigation = (props: TiaChangesPanelNavigationProps) => {
    const {
        activeTia,
        numberOfTias,
        onSetActiveTia,
        cssBaseClass = "c-tia-changes-panel",
    } = props;
    return (
        <div className={`${cssBaseClass}__controls`}>
            <Button
                cssClassName={`${cssBaseClass}__control`}
                style={ButtonStyles.None}
                disabled={activeTia >= numberOfTias - 1}
                onClick={() => onSetActiveTia(activeTia + 1)}>
                <Icon
                    cssClassName={`${cssBaseClass}__controlIcon -back`}
                    type={Icons.ChevronLeft}
                    size={IconSizes.Base}
                />
                {t("earlierVersion")}
            </Button>
            <Button
                style={ButtonStyles.None}
                cssClassName={`${cssBaseClass}__control`}
                disabled={activeTia === 0}
                onClick={() => onSetActiveTia(activeTia - 1)}>
                {t("laterVersion")}
                <Icon
                    cssClassName={`${cssBaseClass}__controlIcon -next`}
                    type={Icons.ChevronRight}
                    size={IconSizes.Base}
                />
            </Button>
        </div>
    );
};

export default TiaChangesPanelNavigation;
