enum FileSizeUnit {
    B = "B",
    Bytes = "bytes",
    KB = "KB",
    Kilobytes = "kilobytes",
    MB = "MB",
    Megabytes = "megabytes",
    GB = "GB",
    Gigabytes = "gigabytes",
    TB = "TB",
    Terabytes = "terabytes",
}

export { FileSizeUnit };
