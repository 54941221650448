import UserRecord from "models/view-models/user-record";
import { useCallback, useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import NumberUtils from "utilities/number-utils";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import { ToastManager } from "utilities/toast/toast-manager";

const baseEndpoint = "groups/:groupId/users";

interface GroupsPathParams {
    groupId: number;
}

interface GroupsQueryParams {}

const useReadCurrentGroupMembers = () => {
    const { currentIdentity } = useGlobalState().globalState;
    const groupId = currentIdentity?.getCurrentTeamId();

    const { result, setError, setLoading, setSuccess } =
        useServiceHookResultState([new UserRecord()]);

    const useList = ServiceHookFactory.useNestedList<
        UserRecord,
        GroupsPathParams,
        GroupsQueryParams
    >(UserRecord, baseEndpoint);
    const { list: getUsers } = useList();

    const getCurrentGroupMembers = useCallback(async () => {
        if (NumberUtils.isDefault(groupId)) {
            return;
        }

        setLoading();

        try {
            const { resultObjects: currentUsers } = await getUsers({ groupId });
            setSuccess(currentUsers);
        } catch (error) {
            setError(error);
            ToastManager.error("There was an error getting users");
        }
    }, [getUsers, groupId, setError, setLoading, setSuccess]);

    useEffect(() => {
        getCurrentGroupMembers();
    }, [getCurrentGroupMembers]);

    return { loaded: result.loaded, resultObject: result.resultObject };
};

export default useReadCurrentGroupMembers;
