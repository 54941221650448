import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { PartRecord } from "internal";
import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import * as React from "react";
import { Link } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface PartsListProps {
    /**
     * className that gets applied to the paragraph inside each ul
     */
    paragraphClassName?: string;
    parts: PartRecord[];

    sections: SectionTableOfContentsRecord[];

    renderSections: (partId?: number) => JSX.Element[];

    /**
     * className that gets applied to each parts <ul>.
     */
    ulClassName: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PartsList: React.FunctionComponent<PartsListProps> = (
    props: PartsListProps
) => {
    const {
        ulClassName: cssClassName,
        parts,
        renderSections,
        sections,
        paragraphClassName,
    } = props;

    const sortedParts = parts.sort(
        (a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0)
    );

    return (
        <React.Fragment>
            {sortedParts.map((part: PartRecord) => {
                const sectionsForPart = sections.filter(
                    (section) =>
                        section.partId != null && section.partId === part.id
                );
                const hasBodyContentWithNoSections =
                    StringUtils.hasValue(part.body) &&
                    CollectionUtils.isEmpty(sectionsForPart);
                return (
                    <ul key={part.id} className={cssClassName}>
                        <Paragraph
                            cssClassName={`${paragraphClassName ?? ""} ${
                                hasBodyContentWithNoSections ? "-link" : ""
                            }`}
                            size={ParagraphSizes.Small}>
                            {hasBodyContentWithNoSections ? (
                                <Link to={{ hash: part.externalId }}>
                                    {part.label + " "}
                                    {part.getTitle()}
                                </Link>
                            ) : (
                                <React.Fragment>
                                    {part.label + " "}
                                    {part.getTitle()}
                                </React.Fragment>
                            )}
                        </Paragraph>
                        {renderSections(part.id)}
                    </ul>
                );
            })}
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PartsList;

// #endregion Export
