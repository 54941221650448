import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import ServiceHookFactory from "../service-hook-factory";

const baseEndpoint = "organizationmanifests";
const resourceType = OrganizationManifestRecord;

const OrganizationManifestsService = {
    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<OrganizationManifestRecord, {}>(
        resourceType,
        baseEndpoint
    ),
};

export default OrganizationManifestsService;
