import { RefObject, useEffect } from "react";

export default function useOutsideClick(
    element: RefObject<HTMLElement>,
    callback: (...args: any[]) => void
) {
    const eventListenerCallback = (event: MouseEvent) => {
        if (event.target instanceof HTMLElement) {
            const isClickInside: boolean =
                element?.current?.contains(event.target) ?? false;

            if (!isClickInside) {
                callback();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", eventListenerCallback);

        return () =>
            document.removeEventListener("click", eventListenerCallback);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
