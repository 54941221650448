import { PropsWithChildren, useState } from "react";
import React from "react";
import { HeaderData } from "organisms/header-data/header-data";
import { HeaderDataContext } from "utilities/contexts/use-header-data-context";
import HeaderDataRecord from "models/view-models/header-data-record";

// -----------------------------------------------------------------------------------------
// #region Provider
// -----------------------------------------------------------------------------------------

export const HeaderDataProvider = ({
    children,
}: PropsWithChildren<unknown>) => {
    const [headerDataState, setHeaderDataState] = useState(
        new HeaderDataRecord()
    );

    return (
        <HeaderDataContext.Provider
            value={[headerDataState, setHeaderDataState]}>
            <HeaderData />
            {children}
        </HeaderDataContext.Provider>
    );
};

// #endregion Provider
