import UserBookmarkSortOption from "models/enumerations/sorting/user-bookmarks/user-bookmark-sort-option";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useEffect, useState } from "react";
import UserBookmarkService from "utilities/services/user-bookmarks/user-bookmark-service";

const useDashboardBookmarks = () => {
    const [loading, setLoading] = useState(true);
    const [bookmarks, setBookmarks] = useState<UserBookmarkRecord[]>([]);

    useEffect(() => {
        const loadBookmarks = async () => {
            try {
                const result = await UserBookmarkService.listV2({
                    currentUserOnly: true,
                    skip: 0,
                    take: 5,
                    orderBy: UserBookmarkSortOption.CreatedOnDescending,
                });

                setBookmarks(result.resultObjects);
            } catch {
            } finally {
                setLoading(false);
            }
        };

        loadBookmarks();
    }, []);

    return {
        loading,
        bookmarks,
    };
};

export default useDashboardBookmarks;
