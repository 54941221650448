import { BillingCycleOption } from "constants/billing-cycle-options";
import SubscriptionType from "organisms/enums/subscription-type";
import SelectBillingCycleModal from "organisms/subscription-card/select-billing-cycle-modal";
import SelectBillingCycleModalV2 from "organisms/subscription-card/select-billing-cycle-modal-v2";
import React from "react";
import EnterpriseSubscriptionCard from "./enterprise-subscription-card";
import IndividualSubscriptionCard from "./individual-subscription-card";
import TeamSubscriptionCard from "./team-subscription-card";

interface SubscriptionCardsProps {
    handleEnterpriseCardSelect: () => void;
    handleIndividualCardSelect: () => void;
    handleTeamCardSelect: () => void;
    resetModal: () => void;
    handleBillingCycleCheck: () => void;
    selectedBillingCycle: BillingCycleOption | undefined;
    selectedSubscriptionType: SubscriptionType | undefined;
    showBillingModal: boolean;
    cssClassName?: string;
    cardCssClassNameModifier?: string;
    showNewBillingModal?: boolean;
}

const SubscriptionCards = (props: SubscriptionCardsProps) => {
    const {
        handleEnterpriseCardSelect,
        handleIndividualCardSelect,
        handleTeamCardSelect,
        resetModal,
        handleBillingCycleCheck,
        selectedBillingCycle,
        selectedSubscriptionType,
        showBillingModal,
        cssClassName,
        cardCssClassNameModifier = "",
        showNewBillingModal = false,
    } = props;

    return (
        <div className={`${cssClassName}__cards`}>
            <IndividualSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleIndividualCardSelect}
            />
            <TeamSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleTeamCardSelect}
            />
            <EnterpriseSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleEnterpriseCardSelect}
            />

            {showBillingModal &&
                (showNewBillingModal ? (
                    <SelectBillingCycleModalV2
                        closeDialog={resetModal}
                        onCheck={handleBillingCycleCheck}
                        isVisible={showBillingModal}
                        selectedBillingCycle={selectedBillingCycle}
                        selectedSubscriptionType={selectedSubscriptionType}
                    />
                ) : (
                    <SelectBillingCycleModal
                        closeDialog={resetModal}
                        onCheck={handleBillingCycleCheck}
                        isVisible={showBillingModal}
                        selectedBillingCycle={selectedBillingCycle}
                        selectedSubscriptionType={selectedSubscriptionType}
                    />
                ))}
        </div>
    );
};

export default SubscriptionCards;
