import React from "react";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EquationNumberProps extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-equation-number";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EquationNumber: React.FunctionComponent<EquationNumberProps> = (
    props
) => {
    return (
        <Paragraph size={ParagraphSizes.Small} cssClassName={BASE_CLASS_NAME}>
            {props.children}
        </Paragraph>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default EquationNumber;

// #endregion Export
