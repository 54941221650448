import PublicationRecord from "models/view-models/publication-record";
import SectionCollectionRecord from "models/view-models/section-collection-record";
import SectionShareLinkRecord from "models/view-models/share-links/section-share-link-record";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import SharedSectionView from "organisms/share-link-views/shared-section-view";
import InvalidLinkView from "organisms/share-links/invalid-link-view";
import ShareLinkCallToActionBanner from "organisms/share-links/share-link-call-to-action-banner";
import SidebarNavigation from "organisms/sidebar-navigation/sidebar-navigation";
import NotFoundPage from "pages/errors/not-found";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import usePublicationRoute from "utilities/hooks/domain/publications/use-publication-route";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import CultureResources from "utilities/interfaces/culture-resources";
import PublicationService from "utilities/services/share-links/publications/publication-service";
import SectionShareLinkService from "utilities/services/share-links/section-share-link-service";
import ShareLinkSectionService from "utilities/services/share-links/sections/section-service";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------
interface SectionShareLinkPageProps {}

interface Params {
    guid: string;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-section-share-link-page";

// #endregion Constants

const SectionShareLinkPage: React.FunctionComponent<
    SectionShareLinkPageProps
> = (props: SectionShareLinkPageProps) => {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization<CultureResources>();
    const [loadingPublication, setLoadingPublication] = useState(true);
    const [loadingShareLink, setLoadingShareLink] = useState(true);
    const [loadingSection, setLoadingSection] = useState(true);

    const [publication, setPublication] = useState<PublicationRecord>(
        new PublicationRecord()
    );
    const [sectionCollection, setSectionCollection] =
        useState<SectionCollectionRecord>(new SectionCollectionRecord());
    const [shareLink, setShareLink] = useState<SectionShareLinkRecord>();

    const [shareLinkInvalid, setShareLinkInvalid] = useState(false);

    // #endregion State

    const { globalState } = useGlobalState();
    const { guid } = useParams<Params>();

    const { list: listShareLinkSectionApi } = ShareLinkSectionService.useList();
    const { get: getShareLinkApi } = SectionShareLinkService.useGet();
    const { get: getPublicationApi } = PublicationService.useGet();

    const { handlePageLoadError } = usePageErrors();

    const firstSection = sectionCollection.firstSection();

    useEffect(() => {
        const loadSection = async (guid: string) => {
            setLoadingSection(true);

            try {
                const sectionResult = await listShareLinkSectionApi({
                    guid,
                });
                setSectionCollection(
                    new SectionCollectionRecord({
                        parts: [],
                        sections: sectionResult.resultObjects ?? [],
                    })
                );
                if (CollectionUtils.isEmpty(sectionResult.resultObjects)) {
                    setShareLinkInvalid(true);
                }
            } catch (e) {
                setShareLinkInvalid(true);
                handlePageLoadError(e);
            }

            setLoadingSection(false);
        };

        if (StringUtils.isEmpty(guid)) {
            setLoadingSection(false);
            setShareLinkInvalid(true);
            return;
        }

        loadSection(guid!);
    }, [guid, listShareLinkSectionApi, handlePageLoadError]);

    useEffect(() => {
        const loadPublication = async (guid: string) => {
            setLoadingPublication(true);

            try {
                const publicationGetResult = await getPublicationApi({
                    guid,
                });
                setPublication(
                    publicationGetResult.resultObject ?? new PublicationRecord()
                );
            } catch (e) {
                handlePageLoadError(e);
            }

            setLoadingPublication(false);
        };

        if (StringUtils.isEmpty(guid)) {
            return;
        }

        loadPublication(guid!);
    }, [guid, getPublicationApi, handlePageLoadError]);

    useEffect(() => {
        const loadShareLink = async (guid: string) => {
            setLoadingShareLink(true);

            try {
                const result = await getShareLinkApi({ guid });
                if (result.resultObject == null) {
                    setShareLinkInvalid(true);
                    setLoadingShareLink(false);
                    return;
                }

                setShareLink(result.resultObject);
            } catch (e) {
                setShareLinkInvalid(true);
            }

            setLoadingShareLink(false);
        };

        if (StringUtils.isEmpty(guid)) {
            setLoadingShareLink(false);
            return;
        }

        loadShareLink(guid!);
    }, [guid, getShareLinkApi]);

    const { loading: loadingRoute, resultObject: route } = usePublicationRoute({
        annexId: firstSection?.annexId,
        articleId: firstSection?.articleId,
        chapterId: firstSection?.chapterId,
        isDisabled: !globalState.isAuthenticated(),
        publicationId: firstSection?.publicationId,
        sectionId: firstSection?.id,
    });

    const loading = useLoading(
        loadingPublication,
        loadingShareLink,
        loadingSection,
        loadingRoute
    );

    if (loading) {
        return (
            <FullScreenTransition
                transitionText={t("loadingItem", { item: t("section") })}
            />
        );
    }

    if (
        route !== siteMap.errors.notFound &&
        StringUtils.hasValue(route) &&
        globalState.isAuthenticated() &&
        CollectionUtils.hasValues(globalState.currentIdentity?.getRoles())
    ) {
        return <Redirect to={route} />;
    }

    if (shareLinkInvalid) {
        const subtext = t("invalidShareLink");

        if (
            globalState.isAuthenticated() &&
            CollectionUtils.hasValues(globalState.currentIdentity?.getRoles())
        ) {
            return (
                <div className="c-application-layout">
                    <SidebarNavigation />
                    <div className="c-application-layout__panel">
                        <InvalidLinkView
                            text={t("expiredLink")}
                            subtext={subtext}
                        />
                    </div>
                </div>
            );
        }

        return <InvalidLinkView text="Expired Link" subtext={subtext} />;
    }

    if (!sectionCollection.hasSections()) {
        // This shouldn't normally be reached
        return <NotFoundPage />;
    }

    return (
        <div className={CSS_CLASS_NAME}>
            <SharedSectionView
                publication={publication}
                section={firstSection!}
                sectionCollection={sectionCollection}
            />
            <ShareLinkCallToActionBanner shareLink={shareLink!} />
        </div>
    );
};

export default SectionShareLinkPage;
