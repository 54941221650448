import { useAtom } from "jotai";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import React, { Dispatch, SetStateAction } from "react";
import ChangesSummaryAtom from "utilities/atoms/change-indicator-diff-panel/change-summary-atom";
import ChangeSummaryContent from "./change-summaries/change-summary-content";

interface ChangeIndicatorDiffPanelFooterProps {
    setChangeSummaries: Dispatch<SetStateAction<ChangeSummaryRecord[]>>;
}
const CSS_CLASS_NAME = "c-change-indicator-diff-panel__footer";
const ChangeIndicatorDiffPanelFooter = ({
    setChangeSummaries,
}: ChangeIndicatorDiffPanelFooterProps) => {
    const [changeSummaries] = useAtom(ChangesSummaryAtom);
    const changeSummary = changeSummaries.at(0);

    return (
        <>
            {changeSummary != null && (
                <div className={CSS_CLASS_NAME}>
                    <ChangeSummaryContent
                        changeSummary={changeSummary}
                        setChangeSummaries={setChangeSummaries}
                    />
                </div>
            )}
        </>
    );
};

export default ChangeIndicatorDiffPanelFooter;
