import "assets/scss/app.scss";
import "lazysizes";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import UnauthenticatedOverlay from "organisms/overlays/unauthenticated-overlay";
import React from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { routeArray } from "routes";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { SessionStatus } from "utilities/enumerations/session-status";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useSessionStatus from "utilities/hooks/use-session-status";
import { RouteUtils } from "utilities/route-utils";
import { NestedRoutes } from "utilities/routing/nested-route";
import { Location } from "history";
import { siteMap } from "internal-sitemap";
import LandingPage from "pages/public/landing-page";
import useInitializeOfflineDevice from "./utilities/hooks/domain/offline/use-initialize-offline-device";
import EmulationModeContextProvider from "utilities/contexts/emulation/emulation-mode-context-provider";
import EmulationModeBanner from "molecules/banners/emulation-mode-banner";

// At this point the user is either logged in, or not
const AppRoutes = () => {
    const location = useLocation();
    const { globalState } = useGlobalState();
    const { i18N: usei18n } = useFeatureFlags();
    const sessionStatus = useSessionStatus();
    const currentCultureCode = globalState
        .getPreferredOrCurrentCulture()
        .toLowerCase();
    useInitializeOfflineDevice(
        globalState?.currentIdentity?.userId(),
        globalState?.currentIdentity?.userLogin?.hasRole()
    );

    if (sessionStatus === SessionStatus.Expired) {
        return <UnauthenticatedOverlay />;
    }

    if (
        isReturningFromNfpa(location) &&
        !globalState.hasLoadedUserConfigurationsFromNfpa &&
        globalState.currentIdentity != null
    ) {
        return <FullScreenTransition transitionText="Loading..." />;
    }

    if (usei18n) {
        // Verify the current url path contains the culture code if it isn't the default
        const cultureRedirectPath = RouteUtils.getCultureRedirectPath(
            location.pathname,
            globalState.getPreferredOrCurrentCulture()
        );
        if (cultureRedirectPath != null) {
            return <Redirect to={`${cultureRedirectPath}${location.search}`} />;
        }
    }

    const isHomePage =
        location.pathname === siteMap.home ||
        location.pathname === `/${currentCultureCode}/`;

    return isHomePage ? (
        <LandingPage />
    ) : (
        <EmulationModeContextProvider>
            <EmulationModeBanner />
            <Switch>
                <NestedRoutes redirectIfNotFound={true} routes={routeArray} />
            </Switch>
        </EmulationModeContextProvider>
    );
};

const isReturningFromNfpa = (location: Location<unknown>): boolean =>
    location.search.indexOf("fromNfpa") > 0;

export default AppRoutes;
