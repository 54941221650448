import { SkipNavLink } from "@reach/skip-nav";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import React, { PropsWithChildren, useState } from "react";
import { t } from "utilities/localization-utils";
import logo from "assets/images/nfpa-link-color.png";
import UnauthenticatedFooter from "molecules/footer/unauthenticated-footer";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ButtonSizes } from "atoms/constants/button-sizes";
import OnboardingLayoutBannerModal from "molecules/modals/onboarding-layout-banner-modal";
import { AppNameWithHtml } from "constants/app-name-tm";
import useFeatureFlags from "utilities/hooks/use-feature-flags";

interface Props {}

const BASE_CLASS = "c-onboarding-layout-v2";
const BANNER_CLASS = "c-onboarding-layout-v2__banner";
const CONTENT_CLASS = "c-onboarding-layout-v2__content";
const HOME_BUTTON_CLASS = "c-onboarding-layout-v2__banner__home";
const LOGO_CLASS = "c-onboarding-layout-v2__banner__home__logo";

const OnboardingLayoutV2 = (props: PropsWithChildren<Props>) => {
    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const isTablet = useBreakpoint(
        Breakpoints.Desktop,
        BreakpointComparer.MaxWidth
    );

    const { useAzurePostGoLiveBanner } = useFeatureFlags();

    const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);
    const postGoLiveBannerText = t("postGoLiveBanner", {
        appName: AppNameWithHtml,
    });

    const handleOpenLearnMoreModal = () => setIsLearnMoreModalOpen(true);
    return (
        <>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <div className={BASE_CLASS}>
                <header className={BANNER_CLASS}>
                    <Anchor
                        to={siteMap.home}
                        ariaLabel={t("home")}
                        cssClassName={HOME_BUTTON_CLASS}>
                        <img
                            className={LOGO_CLASS}
                            src={logo}
                            alt={t("home")}
                        />
                    </Anchor>
                    <div className={`${BASE_CLASS}__headerRightSide`}>
                        {!isMobile && !isTablet && useAzurePostGoLiveBanner && (
                            <div className={`${BASE_CLASS}__postGoLiveBanner`}>
                                <Icon
                                    cssClassName={`${BASE_CLASS}__postGoLiveBanner__icon`}
                                    type={Icons.Campaign}
                                    size={IconSizes.Medium}
                                />
                                <div
                                    className={`${BASE_CLASS}__postGoLiveBanner__text`}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: postGoLiveBannerText,
                                        }}
                                    />
                                </div>
                                <Button
                                    cssClassName={`${BASE_CLASS}__postGoLiveBanner__text`}
                                    style={ButtonStyles.Anchor}
                                    size={ButtonSizes.Medium}
                                    onClick={handleOpenLearnMoreModal}>
                                    {t("learnMore")}
                                </Button>
                            </div>
                        )}
                        <LogInAnchor cssClassName={"c-button -medium"} />
                    </div>
                </header>
                <OnboardingLayoutBannerModal
                    isVisible={isLearnMoreModalOpen}
                    closeDialog={() => setIsLearnMoreModalOpen(false)}
                />
                <main className={CONTENT_CLASS}>{props.children}</main>
                <UnauthenticatedFooter />
            </div>
        </>
    );
};

export default OnboardingLayoutV2;
