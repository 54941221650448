import { Record } from "immutable";
import type SearchState from "models/interfaces/search-state";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SearchState =
    RecordUtils.defaultValuesFactory<SearchState>({
        currentPublicationPage: 1,
        currentDirectPage: 1,
        searchText: "",
    });

export default class SearchStateRecord
    extends Record(defaultValues)
    implements SearchState
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SearchState) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SearchState>} values
     */
    public with(values: Partial<SearchState>): SearchStateRecord {
        return new SearchStateRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
