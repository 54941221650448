import { Record } from "immutable";
import type BrightcoveMetadata from "models/interfaces/external/brightcove/brightcove-metadata";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: BrightcoveMetadata =
    RecordUtils.defaultValuesFactory<BrightcoveMetadata>({
        id: "",
        duration: "",
        description: "",
        thumbnailUrl: "",
        title: "",
    });

export default class BrightcoveMetadataRecord
    extends Record(defaultValues)
    implements BrightcoveMetadata
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: BrightcoveMetadata) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<BrightcoveMetadata>): BrightcoveMetadataRecord {
        return new BrightcoveMetadataRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
