import { SkipNavContent } from "@reach/skip-nav";
import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import UpdateUserCollectionModal from "organisms/my-link/user-collections/update-user-collection-modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useDeleteUserCollection from "utilities/hooks/domain/users/user-collections/use-delete-user-collection";
import useUserCollection from "utilities/hooks/domain/users/user-collections/use-user-collection";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import NumberUtils from "utilities/number-utils";
import UserService from "utilities/services/users/user-service";
import { ToastManager } from "utilities/toast/toast-manager";
import useUserCollectionInfo from "utilities/hooks/domain/users/user-collections/use-user-collection-info";
import useModalActions from "utilities/hooks/use-modal-actions";
import UserCollectionVisibilityModal from "organisms/my-link/user-collections/user-collection-visibility-modal";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";
import MyBookmarksList from "organisms/my-link/my-bookmarks/my-bookmarks-list";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionPageProps {}
interface UserCollectionPageParams {
    id: any;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserCollectionPage: React.FC<any> = (props: UserCollectionPageProps) => {
    const CSS_CLASS_NAME = "c-user-collection-page";
    const { globalState } = useGlobalState();
    const { t } = useLocalization<CultureResources>();
    const { id } = useParams<UserCollectionPageParams>();
    const history = useHistory();
    const currentUserId = globalState.currentIdentity?.userId();
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        useState(false);
    const { get: getUser } = UserService.useGetQuery();
    const collectionLoadErrorHandler = useCallback(
        () =>
            ToastManager.error(t("userCollectionPage-error-loadingCollection")),
        [t]
    );

    const { isOpen, handleOpen, handleClose } = useModalActions();

    const currentCollectionId = NumberUtils.parseInt(id);
    const {
        resultObject: userCollection,
        loading: loadingUserCollection,
        errors: pageErrors,
        refresh,
    } = useUserCollection(currentUserId, currentCollectionId);

    const { deleteUserCollection, loading: deleting } = useDeleteUserCollection(
        userCollection?.id
    );

    const loading = useLoading(loadingUserCollection, deleting);

    const userResult = getUser({ id: userCollection?.userId! }, undefined, {
        enabled: !NumberUtils.isDefault(userCollection?.userId),
    });

    const user = userResult.data?.resultObject;

    const handleModalSave = () => {
        refresh();
        setIsUpdateModalOpen(false);
    };

    const handleDeleteConfirmed = async () => {
        setIsDeleteConfirmationOpen(false);
        await deleteUserCollection();
        history.push(siteMap.myLink.collections);
    };

    useEffect(
        function pageLoad() {
            if (CollectionUtils.isEmpty(pageErrors)) {
                return;
            }
            collectionLoadErrorHandler();
        },
        [pageErrors, collectionLoadErrorHandler]
    );

    const { pageLabel, collectionIconType } = useUserCollectionInfo(
        userCollection!
    );

    const canWriteCollection = useMemo(() => {
        if (userCollection?.groupId == null) {
            return userCollection?.userId === currentUserId;
        }

        return (
            userCollection.userId === currentUserId ||
            globalState.currentIdentity?.isAdminOfCurrentGroup()
        );
    }, [globalState, currentUserId, userCollection]);

    const renameCollectionAction = t("renameItem", { item: t("collection") });
    const deleteCollectionAction = t("deleteItem", { item: t("collection") });

    const auditLabel =
        userCollection?.isGroupCollection() ||
        userCollection?.isWorkGroupCollection()
            ? t("byUser", { user: user?.getFirstAndLastName() ?? "..." })
            : "";

    const collectionLabel = useMemo(() => {
        const shareType = userCollection?.getCollectionShareType();

        if (shareType === UserCollectionShareType.User) {
            return t("collection");
        }

        if (shareType === UserCollectionShareType.WorkGroup) {
            return `${t("sharedWith")} `;
        }

        if (shareType === UserCollectionShareType.Team) {
            return `${t("sharedWith")} ${t("entireTeam")}`;
        }
    }, [t, userCollection]);

    const isWorkGroupCollection = userCollection?.isWorkGroupCollection();

    const numberOfWorkGroups = userCollection?.workGroups?.length ?? 0;
    const workGroupLableLink = `${numberOfWorkGroups} ${t("workgroups")}`;

    const workGroupTitles =
        userCollection?.workGroups
            ?.map((workGroup) => workGroup?.title ?? "")
            .sort((a, b) => a.localeCompare(b)) ?? [];

    return (
        <React.Fragment>
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <div className={`${CSS_CLASS_NAME}__header__top`}>
                                <AnchorWithIcon
                                    size={ButtonSizes.Small}
                                    style={ButtonStyles.Tertiary}
                                    icon={Icons.ChevronLeft}
                                    to={siteMap.myLink.collections}>
                                    {t("collection_plural")}
                                </AnchorWithIcon>
                                {canWriteCollection && (
                                    <MenuButton
                                        cssClassName="-icon"
                                        buttonAccessibleText="Bookmark actions"
                                        triggerButtonStyle={
                                            ButtonStyles.Tertiary
                                        }
                                        triggerButtonSize={ButtonSizes.XSmall}
                                        iconSize={IconSizes.Medium}>
                                        <Button
                                            onClick={() =>
                                                setIsUpdateModalOpen(true)
                                            }>
                                            {renameCollectionAction}
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                setIsDeleteConfirmationOpen(
                                                    true
                                                )
                                            }>
                                            {deleteCollectionAction}
                                        </Button>
                                    </MenuButton>
                                )}
                            </div>
                            <div
                                className={`${CSS_CLASS_NAME}__page-label ${pageLabel}`}>
                                <Icon type={collectionIconType} />
                                {isWorkGroupCollection ? (
                                    <span className="c-label -small">
                                        {collectionLabel}
                                        <Button
                                            onClick={handleOpen}
                                            style={ButtonStyles.Anchor}>
                                            <span className="c-label -small">
                                                {workGroupLableLink}
                                            </span>
                                        </Button>
                                    </span>
                                ) : (
                                    <span className="c-label -small">
                                        {collectionLabel}
                                    </span>
                                )}
                                <UserCollectionVisibilityModal
                                    closeDialog={handleClose}
                                    isVisible={isOpen}
                                    title={userCollection?.name ?? ""}
                                    workGroupTitles={workGroupTitles}
                                />
                            </div>

                            {!loading && (
                                <div
                                    className={`${CSS_CLASS_NAME}__header__meta`}>
                                    <Heading priority={HeadingPriority.Four}>
                                        {userCollection?.name}
                                    </Heading>
                                    {userCollection?.isGroupCollection() && (
                                        <label>{auditLabel}</label>
                                    )}
                                </div>
                            )}
                        </div>
                        <MyBookmarksList
                            collectionId={currentCollectionId}
                            showPagination={true}
                        />
                        {
                            //if
                            userCollection != null && (
                                <React.Fragment>
                                    <UpdateUserCollectionModal
                                        closeDialog={() =>
                                            setIsUpdateModalOpen(false)
                                        }
                                        isVisible={isUpdateModalOpen}
                                        onConfirm={handleModalSave}
                                        userCollection={userCollection}
                                    />
                                    <ConfirmationModal
                                        isVisible={isDeleteConfirmationOpen}
                                        label={t(
                                            "userCollectionPage-deleteConformationModal-title"
                                        )}
                                        message={t(
                                            "userCollectionPage-deleteConformationModal-text"
                                        )}
                                        confirmButtonText={t(
                                            "userCollectionPage-deleteConformationModal-confirmButtonText"
                                        )}
                                        onCancel={() =>
                                            setIsDeleteConfirmationOpen(false)
                                        }
                                        onConfirm={handleDeleteConfirmed}
                                    />
                                </React.Fragment>
                            )
                        }
                    </>
                </div>
            </SkipNavContent>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export default UserCollectionPage;

// #endregion Exports
