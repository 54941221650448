import { CollectionUtils } from "utilities/collection-utils";
import { useAsyncEffect } from "andculturecode-javascript-react";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import AlertLevels from "constants/alert-levels";
import { List } from "immutable";
import { uniqBy } from "lodash";
import BookRecord from "models/view-models/book-record";
import PublicationRecord from "models/view-models/publication-record";
import AlertBanner from "molecules/alerts/alert-banner";
import PublicationCardSimple from "molecules/cards/publication-card-simple";
import React, { useCallback, useState } from "react";
import { siteMap } from "internal-sitemap";
import OnboardingLayout from "templates/onboarding-layout";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBook from "utilities/hooks/domain/books/use-book";
import usePublicationFavorites from "utilities/hooks/domain/user-publication-favorites/use-publication-favorites";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import usePageErrors from "utilities/hooks/use-page-errors";
import CultureResources from "utilities/interfaces/culture-resources";
import PublicationService from "utilities/services/publications/publication-service";
import ExternalTopicService from "utilities/services/topics/external-topic-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SelectFavoritesPageProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-select-favorites";
const PUBLICATIONS_CLASS_NAME = `${CSS_CLASS_NAME}__publications`;
const TAKE = 5;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectFavoritesPage: React.FC<SelectFavoritesPageProps> = (
    props: SelectFavoritesPageProps
) => {
    const { t } = useLocalization<CultureResources>();
    const anchorDashboardText = t("selectFavoritesPage-nextButton");
    const bannerText = t("selectFavoritesPage-banner");
    const description = t("selectFavoritesPage-description");
    const descriptionFeaturedPublications = t(
        "selectFavoritesPage-description-featuredPublications"
    );
    const heading = t("selectFavoritesPage-header");
    const showLessText = t("showLess");
    const showMoreText = t("showMore");

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const [descriptionParagraph, setDescriptionParagraph] =
        useState<string>("");
    const [publications, setPublications] = useState<Array<PublicationRecord>>(
        []
    );
    const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
    const [showAll, setShowAll] = useState(true);
    const { handlePageLoadError, pageErrors } = usePageErrors();
    const { favorites, setFavorites } = usePublicationFavorites();

    const getExternalTopicsApi = ExternalTopicService.list;
    const { list: listPublicationApi } = PublicationService.useList();
    const { resultObject: featuredBooks } = useBook({ isFeatured: true });

    useHeaderData({
        title: heading,
    });

    useAsyncEffect(
        async function getUserTopics() {
            try {
                const response = await getExternalTopicsApi();
                const externalTopics = response.resultObjects ?? [];
                setSelectedTopics(externalTopics.map((u) => u.id));
            } catch (result) {
                handlePageLoadError(result);
            }
        },
        [getExternalTopicsApi, handlePageLoadError, pageErrors]
    );

    const findPublicationsByTopics = useCallback(
        async (selectedTopics: number[]): Promise<Array<PublicationRecord>> => {
            try {
                const publicationTopicsResponse = await listPublicationApi({
                    topicIds: selectedTopics,
                });
                const publications = publicationTopicsResponse.resultObjects;

                return publications;
            } catch (result) {
                handlePageLoadError(result);
                return [];
            }
        },
        [listPublicationApi, handlePageLoadError]
    );

    const findFeaturedPublications = useCallback(
        async (
            featuredBooks: List<BookRecord>
        ): Promise<Array<PublicationRecord>> => {
            try {
                const response = await listPublicationApi();
                const allPublications = response.resultObjects ?? [];
                const featuredPublications = allPublications.filter(
                    (p: PublicationRecord) => {
                        return featuredBooks.some(
                            (b: BookRecord) =>
                                b.code === p.code && b.edition === p.edition
                        );
                    }
                );
                return featuredPublications;
            } catch (result) {
                handlePageLoadError(result);
                return [];
            }
        },
        [handlePageLoadError, listPublicationApi]
    );

    useAsyncEffect(
        async function getPublications() {
            if (CollectionUtils.isEmpty(selectedTopics)) {
                return;
            }

            let publications = await findPublicationsByTopics(selectedTopics);
            if (CollectionUtils.hasValues(publications)) {
                setPublications(publications);
                setDescriptionParagraph(description);
                return;
            }

            publications = await findFeaturedPublications(featuredBooks);
            setPublications(publications);
            setDescriptionParagraph(descriptionFeaturedPublications);
        },
        [selectedTopics]
    );
    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------

    const handleShowAllToggle = () => setShowAll((prevState) => !prevState);

    // #endregion Handlers

    // -----------------------------------------------------------------------------------------
    // #region Transforms
    // ----------------------------------------------------------------------------------------

    const findFeaturedTopicBooks = publications.filter((p) =>
        featuredBooks.some((f) => p.code === f.code && p.edition === f.edition)
    );
    const publicationsWithFeaturedFirst = uniqBy(
        [...findFeaturedTopicBooks, ...publications],
        "id"
    );
    const availablePublications =
        showAll && !isMobile
            ? publicationsWithFeaturedFirst.slice(0, TAKE)
            : publicationsWithFeaturedFirst;

    const buttonText = showAll ? showMoreText : showLessText;
    const displayShowAllText = publicationsWithFeaturedFirst.length > TAKE;

    // #endregion Transforms

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <OnboardingLayout>
            <div className={CSS_CLASS_NAME}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__title`}
                    priority={HeadingPriority.One}>
                    {heading}
                </Heading>
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__description`}>
                    {descriptionParagraph}
                </Paragraph>
                <div className={PUBLICATIONS_CLASS_NAME}>
                    <div className={`${PUBLICATIONS_CLASS_NAME}__list`}>
                        {availablePublications.map(
                            (publication: PublicationRecord) => {
                                return (
                                    <PublicationCardSimple
                                        favorites={favorites}
                                        key={publication.id}
                                        onChangeFavorites={setFavorites}
                                        publication={publication}
                                    />
                                );
                            }
                        )}
                    </div>
                    {displayShowAllText && (
                        <Button
                            cssClassName={`${PUBLICATIONS_CLASS_NAME}__show-more-button`}
                            style={ButtonStyles.Anchor}
                            onClick={handleShowAllToggle}>
                            {buttonText}
                        </Button>
                    )}
                </div>
                <AlertBanner alertLevel={AlertLevels.Info}>
                    {bannerText}
                </AlertBanner>
                <div className={`${CSS_CLASS_NAME}__divider`}></div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    <Anchor
                        dataTestId="go-to-dashboard"
                        ariaLabel="Dashboard"
                        onClick={() => setShowAll(false)}
                        to={siteMap.dashboards.user}
                        title={anchorDashboardText}>
                        {anchorDashboardText}
                    </Anchor>
                </div>
            </div>
        </OnboardingLayout>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SelectFavoritesPage;

// #endregion Exports
