import React from "react";

export interface OriginsListDesigConverterProps {
    children?: React.ReactNodeArray;
    className?: string;
    datalabel?: string;
}

const OriginsListDesigConverter = (props: OriginsListDesigConverterProps) => {
    // There are times where props.children is undefined
    if (props.children == null) {
        return null;
    }

    return <div className="c-origins-list__desig">{props.children}</div>;
};

export default OriginsListDesigConverter;
