import { Record } from "immutable";
import type { EnhancedContentMigration } from "models/interfaces/enhanced-content-migration";
import { RecordUtils } from "andculturecode-javascript-core";
import StringUtils from "utilities/string-utils";
import PublicationRecord from "models/view-models/publication-record";
import JobRecord from "models/view-models/job-record";
import UserRecord from "models/view-models/user-record";
import DateUtils from "utilities/date-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const ERROR_LOADING_ENHANCED_CONTENT_MIGRATION =
    "There was an error when running an enhanced content migration";

// #endregion Constants

const defaultValues: EnhancedContentMigration =
    RecordUtils.auditableDefaultValuesFactory({
        code: "",
        destinationEdition: "",
        job: undefined,
        jobId: 0,
        log: undefined,
        overwriteExisting: false,
        sourcePublication: undefined,
        sourceEdition: "",
        updatedBy: undefined,
    });

export default class EnhancedContentMigrationRecord
    extends Record(defaultValues)
    implements EnhancedContentMigration
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EnhancedContentMigration>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.job != null) {
            params.job = RecordUtils.ensureRecord(params.job, JobRecord);
        }

        if (params.sourcePublication != null) {
            params.sourcePublication = RecordUtils.ensureRecord(
                params.sourcePublication,
                PublicationRecord
            );
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(
                params.updatedBy,
                UserRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Gets the log message to display within the migration report
     *
     * @return {*}  {string}
     */
    public getLogDisplay(): string {
        if (StringUtils.hasValue(this.log)) {
            return this.log;
        }

        if (this.job?.endedOn != null && StringUtils.hasValue(this.job.error)) {
            return `<p>${ERROR_LOADING_ENHANCED_CONTENT_MIGRATION}</p>`;
        }

        return "<h6>Nothing to display</h6>";
    }

    /**
     * Get the formatted source publication name
     *
     * @return {*}  {string}
     */
    public getMigratedSourceText(): string {
        if (this.sourcePublication == null) {
            return `NFPA ${this.code} — (${this.sourceEdition})`;
        }

        return this.sourcePublication.getDisplayTitle();
    }

    /**
     * Get the formatted display text for an enhanced content migration record.
     *
     * @return {*}  {string}
     */
    public getMigratedStatusText(): string {
        const formattedDate = DateUtils.formatLastEditedDate(this);

        if (this.updatedBy == null) {
            return formattedDate;
        }

        return `${formattedDate} by ${this.updatedBy.getFirstAndLastName()}`;
    }

    /**
     * Determines if a migration is currently running
     *
     * @return {*}  {boolean}
     */
    public isMigrating(): boolean {
        if (this.job != null) {
            return this.job.endedOn == null;
        }

        return StringUtils.isEmpty(this.log);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<EnhancedContentMigration>} values
     */
    public with(
        values: Partial<EnhancedContentMigration>
    ): EnhancedContentMigrationRecord {
        return new EnhancedContentMigrationRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
