import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { CountedColorSelectOption } from "organisms/my-link/bookmark-color-filter-select";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import StringUtils from "utilities/string-utils";
import { CustomColor } from "utilities/types/custom-color";
import { CoreUtils } from "utilities/core-utils";
import { ColorSelectOption } from "organisms/my-link/my-bookmarks/color-select";
import { CollectionUtils } from "utilities/collection-utils";
import { t } from "utilities/localization-utils";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Build select options for bookmark colors, with the label having the number of results based on the
 * allBookmarks param, i.e. "Yellow (3)". If the result count is 0, the item is removed from the option list.
 *
 * @param allBookmarks the list of all possible results from which to calculate the result count for each color
 */
const _buildBookmarkColorSelectOptions = (
    allBookmarks: Array<UserBookmarkRecord>
): Array<CountedColorSelectOption> => {
    const optionsWithoutCount = _buildSelectOptions(UserBookmarkColors);
    return optionsWithoutCount
        .map((o: ColorSelectOption) => ({
            ...o,
            data: allBookmarks.filter(
                (b: UserBookmarkRecord) => b.color === o.value
            ).length,
        }))
        .filter((o: CountedColorSelectOption) => o.data! > 0);
};

const _buildSelectOptions = <TEnum>(enumObject: TEnum): ColorSelectOption[] => {
    const enumValues = CoreUtils.getEnumValues<number>(enumObject);
    const enumKeys = CoreUtils.getEnumKeys(enumObject);

    const options = enumKeys
        .map((key: string, index: number): ColorSelectOption => {
            key = key.toLowerCase(); // Color keys in the culture-resources interface are lowercase
            const label = StringUtils.capitalize(
                t(key as keyof CultureResources)
            );
            const value = enumValues[index];

            return {
                label,
                value,
            };
        })
        // Just in case an option comes back without a label value, we don't want to display it to the user.
        .filter((o) => StringUtils.hasValue(o.label));

    if (CollectionUtils.isEmpty(options)) {
        return [
            {
                label: t("noOptionsAvailable"),
                value: 0,
            },
        ];
    }

    return options;
};

/**
 * Returns the CSS classname modifier for applying a custom color
 *
 * @example
 * CustomColorUtils.getClassModifier(UserBookmarkColors, 1, "bookmark");
 * // returns " -bookmark-color-yellow"
 * @param {UserBookmarkColor | PublicationColor} object
 * @param {number} key
 * @param {string} type
 */
const _getClassModifier = (
    enumObject: CustomColor,
    key: number,
    type: string,
    defaultValue: string = ""
): string => {
    const value = enumObject[key];

    // If no color can be determined from the defined constant, there's likely no modifier that needs
    // to be applied for styling. Return the defaultValue in case the caller wants to apply a
    // default class name.
    if (StringUtils.isEmpty(value)) {
        return defaultValue;
    }

    return ` -${type.toLowerCase()}-color-${value.toLowerCase()}`;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export const CustomColorUtils = {
    buildBookmarkColorSelectOptions: _buildBookmarkColorSelectOptions,
    buildSelectOptions: _buildSelectOptions,
    getClassModifier: _getClassModifier,
};

// #endregion Exports
