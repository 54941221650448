import AnnouncementRecord from "models/view-models/announcement-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminAnnouncementPublishPathParams {
    id: number;
}
export interface AdminAnnouncementPublishQueryParams {
    withAlert?: boolean;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const resourceType = AnnouncementRecord;
const resourceEndpoint = "admin/announcements/:id/publish";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const AnnouncementPublishService = {
    publish: ServiceFactory.update<
        AnnouncementRecord,
        AdminAnnouncementPublishPathParams
    >(resourceType, resourceEndpoint),

    usePublish: ServiceHookFactory.useUpdate<
        AnnouncementRecord,
        AdminAnnouncementPublishPathParams,
        AdminAnnouncementPublishQueryParams
    >(resourceType, resourceEndpoint),

    unpublish:
        ServiceFactory.delete<AdminAnnouncementPublishPathParams>(
            resourceEndpoint
        ),

    useUnpublish:
        ServiceHookFactory.useDelete<AdminAnnouncementPublishPathParams>(
            resourceEndpoint
        ),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AnnouncementPublishService;

export const AnnouncementPublishServiceErrorMessages = {
    Publish: "There was an issue publishing this announcement",
    Unpublish: "There was an issue unpublishing an announcement",
};

// #region Exports
