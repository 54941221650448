import { LoaderSizes } from "atoms/constants/loader-sizes";
import { LoaderStyles } from "atoms/constants/loader-styles";
import * as React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

interface LoaderProps {
    /**
     * Optional Text to describe what is loading for users utilizing a screen reader. Not visibly rendered.
     * Default value: "Loading..."
     */
    accessibleText?: string;

    /**
     * Size of Loader to display
     * @default LoaderSizes.Default
     */
    size?: LoaderSizes;

    /**
     * Style of the spinner to display
     *
     * @default LoaderStyles.LinkGlyph
     */
    type?: LoaderStyles;
}

const Loader: React.FunctionComponent<LoaderProps> = (props) => {
    const { t } = useLocalization<CultureResources>();
    const { accessibleText } = props;
    const type = props.type ?? LoaderStyles.LinkGlyph;
    let size = "";

    switch (props.size) {
        case LoaderSizes.Small:
            size = "-small";
            break;
        case LoaderSizes.XSmall:
            size = "-xsmall";
            break;
        case LoaderSizes.Default:
            break;
        default:
            break;
    }

    return (
        <div className={`c-loader ${type} ${size}`}>
            <span className="sr-only">
                {accessibleText ?? t("loadingItem")}
            </span>
        </div>
    );
};

export default Loader;
