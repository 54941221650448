import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import BookviewLayoutContextProvider from "utilities/contexts/ui-state-context/bookview-layout-context-provider";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import { ReferencePanelContextProvider } from "utilities/contexts/reference-panel/reference-panel-context-provider";
import { LinkPreviewContextProvider } from "utilities/contexts/link-preview/link-preview-context-provider";
import BookViewLayoutContent from "./bookview-layout-content";

interface BookViewQueryParams {
    code: string;
    edition: string;
    id: string;
}

const BookViewLayout: React.FunctionComponent<any> = (props: any) => {
    const { code } = useParams<BookViewQueryParams>();
    const { loadBookmarks } = useBookmarks();

    useEffect(() => {
        loadBookmarks({ code });
    }, [code, loadBookmarks]);

    return (
        <ReferencePanelContextProvider>
            <BookviewLayoutContextProvider>
                <LinkPreviewContextProvider>
                    <BookViewLayoutContent {...props} />
                </LinkPreviewContextProvider>
            </BookviewLayoutContextProvider>
        </ReferencePanelContextProvider>
    );
};

export default BookViewLayout;
