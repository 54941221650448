import UserSettingRecord from "models/view-models/user-setting-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserSettingBasePathParams {
    userId: number;
}

export interface UserSettingResourcePathParams
    extends UserSettingBasePathParams {
    /**
     * Id of the UserSetting resource
     */
    id: number;
}

/**
 * Search parameters when listing UserSetting resources
 * These get translated to QueryString parameters
 */
export interface UserSettingListQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/settings";
const resourceType = UserSettingRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserSettingService = {
    /**
     * Retrieve a single UserSetting by id
     */
    get: ServiceFactory.get<UserSettingRecord, UserSettingResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Retrieves a list of UserSettings by userId
     */
    list: ServiceFactory.list<UserSettingRecord, UserSettingListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Update a UserSetting
     */
    update: ServiceFactory.update(resourceType, resourceEndpoint),

    /**
     * Custom hook Creating a UserSetting
     */
    useCreate: ServiceHookFactory.useNestedCreate<
        UserSettingRecord,
        UserSettingBasePathParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for Getting a UserSetting
     */
    useGet: ServiceHookFactory.useGet<
        UserSettingRecord,
        UserSettingResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for Listing UserSettings
     */
    useList: ServiceHookFactory.useNestedList<
        UserSettingRecord,
        UserSettingBasePathParams,
        UserSettingListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for Updating a UserSetting
     */
    useUpdate: ServiceHookFactory.useUpdate<
        UserSettingRecord,
        UserSettingResourcePathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserSettingService;

export const UserSettingServiceErrorMessages = {
    Create: "There was an issue creating user settings",
    List: "There was an issue retrieving user settings",
    Update: "There was an issue updating user settings",
};

// #endregion Exports
