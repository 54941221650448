import {
    ObjectValidator,
    ObjectValidationResult,
} from "utilities/validation/object-validator/object-validator";
import UserBookmark from "models/interfaces/user-bookmark";
import { t } from "utilities/localization-utils";

class UserBookmarkValidator extends ObjectValidator<UserBookmark> {
    public validate(
        userBookmark: UserBookmark,
        maxLength?: number
    ): ObjectValidationResult<UserBookmark> {
        const validationResult: ObjectValidationResult<UserBookmark> = {};
        // If we aren't validating on a max length, just short-circuit here. No further validation
        // is necessary.
        if (maxLength == null) {
            return validationResult;
        }

        if (userBookmark.descriptionAsPlainText.length > maxLength) {
            validationResult.description = [t("noteTextTooLong")];
            return validationResult;
        }

        return validationResult;
    }
}

export { UserBookmarkValidator };
