import {
    ObjectValidator,
    ObjectValidationResult,
} from "utilities/validation/object-validator/object-validator";
import StringUtils from "utilities/string-utils";
import { CancelSubscriptionFormSubmission } from "models/interfaces/cancel-subscription-form-submission";
import { t } from "utilities/localization-utils";

class CancelSubscriptionFormSubmissionValidator extends ObjectValidator<CancelSubscriptionFormSubmission> {
    public validate(
        form: CancelSubscriptionFormSubmission
    ): ObjectValidationResult<CancelSubscriptionFormSubmission> {
        const validationResult: ObjectValidationResult<CancelSubscriptionFormSubmission> =
            {};

        if (StringUtils.isEmpty(form.reason)) {
            validationResult.reason = [
                t("propertyIsRequired", { name: t("reason") }),
            ];
        }

        if (
            StringUtils.hasValue(form.description) &&
            form.description!.length > 255
        ) {
            validationResult.description = [
                t("exceedsCharacterLimit", { limit: 255 }),
            ];
        }

        return validationResult;
    }
}

export { CancelSubscriptionFormSubmissionValidator };
