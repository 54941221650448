import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import { PagedQuery } from "andculturecode-javascript-core";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SituationRelationshipPathParams {
    situationId: number;
}

export interface SituationRelationshipQueryParams extends PagedQuery {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const key = "situational-navigation-situations-relationships";
const baseEndpoint =
    "situational-navigation/situations/:situationId/relationships";
const resourceType = SituationRelationshipRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SituationRelationshipService = {
    list: ServiceFactory.nestedList<
        SituationRelationshipRecord,
        SituationRelationshipPathParams,
        SituationRelationshipQueryParams
    >(resourceType, baseEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SituationRelationshipRecord,
        SituationRelationshipPathParams,
        SituationRelationshipQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        SituationRelationshipRecord,
        SituationRelationshipPathParams,
        SituationRelationshipQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationRelationshipService;

// #endregion Exports
