import { List, Record } from "immutable";
import type PublicationMetadata from "models/interfaces/publication-metadata";
import PublicationMetadataTypeEnum from "models/interfaces/publication-metadata-types";
import CommitteesAlternatesMemberGroup from "organisms/frontmatter/committees/committees-alternates-member-group";
import CommitteesChapters from "organisms/frontmatter/committees/committees-chapters";
import CommitteesCommittee from "organisms/frontmatter/committees/committees-committee";
import CommitteeLists from "organisms/frontmatter/committees/committees-committee-lists";
import CommitteesDivConverter from "organisms/frontmatter/committees/committees-div-converter";
import CommitteesH1Converter from "organisms/frontmatter/committees/committees-h1-converter";
import CommitteesIntroduction from "organisms/frontmatter/committees/committees-introduction";
import CommitteesMember from "organisms/frontmatter/committees/committees-member";
import CommitteesMemberClassification from "organisms/frontmatter/committees/committees-member-classification";
import CommitteesMemberGroup from "organisms/frontmatter/committees/committees-member-group";
import CommitteesMemberInfo from "organisms/frontmatter/committees/committees-member-info";
import CommitteesMemberName from "organisms/frontmatter/committees/committees-member-name";
import CommitteesMemberOrganization from "organisms/frontmatter/committees/committees-member-organization";
import CommitteesNonVotersMemberGroup from "organisms/frontmatter/committees/committees-nonvoters-member-group";
import CommitteesOfficers from "organisms/frontmatter/committees/committees-officers";
import CommitteesParagraphConverter from "organisms/frontmatter/committees/committees-paragraph-converter";
import CommitteesSpanConverter from "organisms/frontmatter/committees/committees-span-converter";
import OriginsBodyConverter from "organisms/frontmatter/origins/origins-body-converter";
import OriginsConverter from "organisms/frontmatter/origins/origins-converter";
import OriginsH1Converter from "organisms/frontmatter/origins/origins-h1-converter";
import OriginsH2Converter from "organisms/frontmatter/origins/origins-h2-converter";
import OriginsListConverter from "organisms/frontmatter/origins/origins-list-converter";
import OriginsListDesigConverter from "organisms/frontmatter/origins/origins-list-desig-converter";
import OriginsListItemConverter from "organisms/frontmatter/origins/origins-list-item-converter";
import OriginsListItemContentConverter from "organisms/frontmatter/origins/origins-list-itemcontent-converter";
import OriginsSectionConverter from "organisms/frontmatter/origins/origins-section-converter";
import ListItem from "organisms/section-detail-components/list-item";
import { siteMap } from "internal-sitemap";
import { tForCulture } from "utilities/localization-utils";
import { RouteUtils } from "utilities/route-utils";
import XmlUtils from "utilities/xml-utils";
import { RecordUtils } from "andculturecode-javascript-core";
import TranslationsSectionConverter from "organisms/frontmatter/translations/translations-section-converter";

const defaultValues: PublicationMetadata =
    RecordUtils.auditableDefaultValuesFactory({
        publication: undefined,
        publicationId: 0,
        type: undefined,
        xmlContent: "",
    });

export default class PublicationMetadataRecord
    extends Record(defaultValues)
    implements PublicationMetadata
{
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: PublicationMetadata) {
        if (params == null) {
            params = Object.assign(defaultValues, params);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public committeeConverter(debug?: boolean): React.ReactNode {
        // Converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            a: XmlUtils.xmlConverter("a"),
            administrativeSecretary: XmlUtils.xmlConverter("div"),
            alternates: XmlUtils.xmlConverter(CommitteesAlternatesMemberGroup),
            alternateTo: XmlUtils.xmlConverter(CommitteesMemberInfo),
            b: XmlUtils.xmlConverter("b"),
            br: XmlUtils.xmlConverter("br"),
            chair: XmlUtils.xmlConverter("div"),
            chapters: XmlUtils.xmlConverter(CommitteesChapters),
            classification: XmlUtils.xmlConverter(
                CommitteesMemberClassification
            ),
            committee: XmlUtils.xmlConverter(CommitteesCommittee),
            committeeLists: XmlUtils.xmlConverter(CommitteeLists),
            committeeScope: XmlUtils.xmlConverter(
                XmlUtils.DivWrapper("c-committee-scope")
            ),
            div: XmlUtils.xmlConverter(CommitteesDivConverter),
            frontmatter: XmlUtils.xmlConverter(OriginsConverter),
            h1: XmlUtils.xmlConverter(CommitteesH1Converter),
            i: XmlUtils.xmlConverter("em"),
            info: XmlUtils.xmlConverter(CommitteesMemberInfo),
            introduction: XmlUtils.xmlConverter(CommitteesIntroduction),
            li: XmlUtils.xmlConverter(ListItem),
            name: XmlUtils.xmlConverter(CommitteesMemberName),
            nonVoters: XmlUtils.xmlConverter(CommitteesNonVotersMemberGroup),
            nonvotingsecretary: XmlUtils.xmlConverter(CommitteesMember),
            officers: XmlUtils.xmlConverter(CommitteesOfficers),
            ol: XmlUtils.xmlConverter(List),
            organization: XmlUtils.xmlConverter(CommitteesMemberOrganization),
            p: XmlUtils.xmlConverter(CommitteesParagraphConverter),
            person: XmlUtils.xmlConverter(CommitteesMember),
            principals: XmlUtils.xmlConverter(CommitteesMemberGroup),
            recordingSecretary: XmlUtils.xmlConverter("div"),
            secretary: XmlUtils.xmlConverter("div"),
            span: XmlUtils.xmlConverter(CommitteesSpanConverter),
            staffLiaison: XmlUtils.xmlConverter(CommitteesMember),
            title: XmlUtils.xmlConverter("span"),
        };

        return XmlUtils.convert(this.xmlContent!, xmlConverters, debug);
    }

    /**
     * Get the application route to view the current Metadata in Book View
     */
    public getRoute(isAdminPreview: boolean = false): string {
        if (isAdminPreview) {
            return RouteUtils.getUrl(
                siteMap.publications.adminPreview.publicationMetadata,
                {
                    publicationId: this.publicationId,
                    type: this.type,
                }
            );
        }

        if (this.publication?.code == null || this.publication?.edition == null)
            return "#";

        return RouteUtils.getUrl(
            siteMap.publications.permalinks.publicationMetadata,
            {
                code: this.publication?.code,
                edition: this.publication?.edition,
                type: this.type,
            }
        );
    }

    /**
     * Returns custom display name based on the type
     */
    public getTypeDisplayName(): string {
        switch (this.type) {
            case PublicationMetadataTypeEnum.COMMITTEES:
                return tForCulture(
                    this.publication?.locale,
                    "committeePersonnel"
                );
            case PublicationMetadataTypeEnum.ORIGINS:
                return tForCulture(this.publication?.locale, "origins");
            case PublicationMetadataTypeEnum.TRANSLATIONS:
                return tForCulture(this.publication?.locale, "translationInfo");
            default:
                return "";
        }
    }

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no body is present, returns an empty string.
     *
     * @returns {(React.ReactNode)}
     */
    public getXmlContent(debug?: boolean): React.ReactNode {
        // If we have no body, just return an empty string.
        if (this.xmlContent == null) {
            return "";
        }

        let body: React.ReactNode = "";
        switch (this.type) {
            case PublicationMetadataTypeEnum.COMMITTEES:
                body = this.committeeConverter(debug);
                break;
            case PublicationMetadataTypeEnum.ORIGINS:
                body = this.originsConverter(debug);
                break;
            case PublicationMetadataTypeEnum.TRANSLATIONS:
                body = this.translationConverter(debug);
                break;
            default:
                break;
        }

        return body;
    }

    public originsConverter(debug?: boolean): React.ReactNode {
        // Converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            a: XmlUtils.xmlConverter("a"),
            desig: XmlUtils.xmlConverter(OriginsListDesigConverter),
            div: XmlUtils.xmlConverter("div"),
            frontmatter: XmlUtils.xmlConverter(OriginsConverter),
            h2: XmlUtils.xmlConverter(OriginsH2Converter),
            history: XmlUtils.xmlConverter(OriginsSectionConverter),
            i: XmlUtils.xmlConverter("em"),
            item: XmlUtils.xmlConverter(OriginsListItemConverter),
            itemcontent: XmlUtils.xmlConverter(OriginsListItemContentConverter),
            li: XmlUtils.xmlConverter(ListItem),
            list: XmlUtils.xmlConverter(OriginsListConverter),
            meetingInformation: XmlUtils.xmlConverter(OriginsSectionConverter),
            ol: XmlUtils.xmlConverter(List),
            originAndDevelopment: XmlUtils.xmlConverter(OriginsBodyConverter),
            originanddevelopment: XmlUtils.xmlConverter(
                OriginsSectionConverter
            ),
            origins: XmlUtils.xmlConverter(OriginsConverter),
            p: XmlUtils.xmlConverter("p"),
            span: XmlUtils.xmlConverter("span"),
            title: XmlUtils.xmlConverter(OriginsH1Converter),
        };

        return XmlUtils.convert(this.xmlContent!, xmlConverters, debug);
    }

    public translationConverter(debug?: boolean): React.ReactNode {
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            // fig: XmlUtils.xmlConverter(FigureSection),
            frontmatter: XmlUtils.xmlConverter(OriginsConverter),
            translationinfo: XmlUtils.xmlConverter(
                TranslationsSectionConverter
            ),
        };

        return XmlUtils.convert(this.xmlContent!, xmlConverters, debug);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<PublicationMetadata>} values
     */
    public with(
        values: Partial<PublicationMetadata>
    ): PublicationMetadataRecord {
        return new PublicationMetadataRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
