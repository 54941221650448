import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CollectionUtils } from "utilities/collection-utils";

export default function useErrors(...values: Array<Array<string>>) {
    const [errors, setErrors] = useState<Array<string>>([]);
    useDeepCompareEffect(() => {
        const errors = values.flat();

        if (CollectionUtils.hasValues(errors)) {
            setErrors(errors);
            return;
        }
        setErrors((prevState) => {
            if (CollectionUtils.isEmpty(prevState)) {
                return prevState;
            }
            return [];
        });
    }, [values]);

    return errors;
}
