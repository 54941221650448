// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface LanguageCodeDisplayMap {
    full: string;
    short: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const LanguageCodeDisplayLabels: Record<string, LanguageCodeDisplayMap> = {
    EN_US: {
        full: "English - United States",
        short: "English - EN",
    },
    ES_ES: {
        full: "Español - España",
        short: "Español - ES",
    },
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default LanguageCodeDisplayLabels;

// #endregion Export
