import { useContext, useCallback, useMemo } from "react";
import { ServiceWorkerContext } from "utilities/contexts/service-worker/service-worker-context";
import { ServiceWorkerCommandData } from "utilities/service-worker/interfaces/commands/service-worker-command-data";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum ServiceWorkerStatus {
    Enabled = "enabled",
    Waiting = "waiting",
    Disabled = "disabled",
    Unsupported = "unsupported",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

function useServiceWorker() {
    const {
        hasUpdate,
        isReadyToReload,
        sendServiceWorkerMessage,
        serviceWorker,
        serviceWorkerState,
    } = useContext(ServiceWorkerContext);

    const isSupported = "serviceWorker" in navigator;
    const isEnabled = serviceWorker != null;
    const isWaiting =
        isEnabled && (navigator.serviceWorker.controller == null || hasUpdate);

    const status = useMemo(() => {
        if (!isSupported) {
            return ServiceWorkerStatus.Unsupported;
        }

        if (!isEnabled || serviceWorkerState == null) {
            return ServiceWorkerStatus.Disabled;
        }

        if (isWaiting) {
            return ServiceWorkerStatus.Waiting;
        }

        return ServiceWorkerStatus.Enabled;
    }, [isEnabled, isSupported, isWaiting, serviceWorkerState]);

    const sendCommand = useCallback(
        <T extends ServiceWorkerCommandData>(command: T) => {
            sendServiceWorkerMessage({ ...command });
        },
        [sendServiceWorkerMessage]
    );

    return {
        hasUpdate,
        isEnabled,
        isReadyToReload,
        isSupported,
        sendCommand,
        serviceWorkerState,
        status,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useServiceWorker;

// #endregion Exports
