import { useCallback, useState } from "react";
import UserCollectionBookmarkService from "utilities/services/user-collection-bookmarks/user-collection-bookmark-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultErrorHandler = () =>
    ToastManager.error(
        "There was an issue removing the bookmark from the collection."
    );

/**
 * Custom hook to handle deletion of UserCollectionBookmarks.
 * This is essentially "Removing a bookmark from a collection"
 */
export default function useDeleteUserCollectionBookmark(
    userCollectionBookmarkId?: number,
    onSuccess?: () => void,
    onError?: () => void
) {
    const { delete: deleteUserCollectionBookmarkApi } =
        UserCollectionBookmarkService.useDelete();

    const [deletingUserCollectionBookmark, setDeletingUserCollectionBookmark] =
        useState(false);

    const handleSuccess = useCallback(() => onSuccess?.(), [onSuccess]);
    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler();
    }, [onError]);

    const deleteUserCollectionBookmark = useCallback(async () => {
        if (userCollectionBookmarkId == null) {
            setDeletingUserCollectionBookmark(false);
            return;
        }

        setDeletingUserCollectionBookmark(true);

        try {
            const result = await deleteUserCollectionBookmarkApi(
                userCollectionBookmarkId
            );
            if (!result.resultObject) {
                handleError();
                setDeletingUserCollectionBookmark(false);
                return;
            }

            handleSuccess();
        } catch (e) {
            handleError();
        }

        setDeletingUserCollectionBookmark(false);
    }, [
        deleteUserCollectionBookmarkApi,
        handleError,
        handleSuccess,
        userCollectionBookmarkId,
    ]);

    return {
        deletingUserCollectionBookmark,
        deleteUserCollectionBookmark,
    };
}
