import * as React from "react";
import AnnouncementContextRecord from "models/view-models/announcement-context-record";
import AnnouncementModal from "organisms/announcements/announcement-modal";
import AnnouncementRecord from "models/view-models/announcement-record";
import CultureResources from "utilities/interfaces/culture-resources";
import Icon from "atoms/icons/icon";
import Modal from "molecules/modals/modal";
import NotificationDotIcon from "atoms/icons/notification-dot-icon";
import OrganizationMemberProfileMenuDetail from "organisms/profile/organization-member/profile-menu-detail";
import ProfileMenuDetail from "organisms/profile/profile-menu-detail";
import RoleType from "utilities/enumerations/role-type";
import SidebarNavigationTooltip from "organisms/sidebar-navigation/sidebar-navigation-tooltip";
import StringUtils from "utilities/string-utils";
import SwitchProfileModal from "organisms/profile/switch-profile-modal";
import useAnnouncements from "utilities/hooks/domain/announcements/use-announcements";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import useWindowContext from "utilities/contexts/window/use-window-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { BrowserUtils } from "utilities/browser-utils";
import { FramerMotionTransitions } from "constants/framer-motion-transitions";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { KeyboardConstants } from "constants/keyboard-constants";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import { motion } from "framer-motion";
import { useAnnouncementContext } from "utilities/contexts/use-announcement-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import { useMemo, useRef, useState } from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ProfileMenuProps {
    hideActions?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-profile-menu";
const TOOLTIP_DISTANCE = 20;
const TOOLTIP_SKIDDING = 0;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = (
    props: ProfileMenuProps
) => {
    const { hideActions } = props;

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [switchProfileModalIsOpen, setSwitchProfileModalIsOpen] =
        useState<boolean>(false);
    const [focusFirstItem, setFocusFirstItem] = useState<boolean>(false);
    const [showAnnouncementModal, setShowAnnouncementModal] =
        useState<boolean>(false);
    const { isOnline } = useNetworkInformation();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const triggerRef = useRef<HTMLButtonElement>(null);
    const { width: windowWidth } = useWindowContext();

    const { globalState } = useGlobalState();
    const initials = globalState.currentIdentity?.user?.getInitials();

    const { announcementContext, setAnnouncementContext } =
        useAnnouncementContext();

    const { acknowledge } = useAnnouncements();

    const currentIdentity = globalState.currentIdentity;
    const isConfiguredUser =
        currentIdentity?.isConfiguredWithActiveSubscription();

    const { t } = useLocalization<CultureResources>();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Utilities
    // -----------------------------------------------------------------------------------------

    const handleCloseAnnouncementModal = () => {
        setShowAnnouncementModal(false);
    };

    const handleOpenAnnouncementModal = (value: boolean) => {
        if (!value) {
            return;
        }

        const announcmentIndex = announcementContext.announcements.findIndex(
            (a: AnnouncementRecord) => a.id === announcement.id
        );
        const updatedRecord = announcement.with({
            acknowledged: acknowledge(announcement),
        });
        setAnnouncementContext((prev: AnnouncementContextRecord) =>
            prev.with({
                announcements: prev.announcements.update(
                    announcmentIndex,
                    () => updatedRecord
                ),
            })
        );

        setIsOpen(false);
        setShowAnnouncementModal(value);
    };

    const toggleMenu = (focusFirst: boolean) => {
        setFocusFirstItem(focusFirst);
        setIsOpen(!isOpen);
    };

    const announcement = useMemo(
        () =>
            announcementContext.announcements.get(0) ??
            new AnnouncementRecord(),
        [announcementContext.announcements]
    );

    const alertUserOfAnnouncement = useMemo(
        () =>
            isConfiguredUser &&
            announcement.shouldAlertUser() &&
            announcement.publishedOn != null,
        [isConfiguredUser, announcement]
    );

    const showAnnouncement = useMemo(
        () => isConfiguredUser && showAnnouncementModal,
        [isConfiguredUser, showAnnouncementModal]
    );

    const showWhatsNew = useMemo(
        () => isConfiguredUser && announcement.publishedOn != null,
        [isConfiguredUser, announcement]
    );

    const transitionEffect =
        window.innerWidth < Breakpoints.Phone
            ? ModalTransitions.SlideUp
            : ModalTransitions.Fade;

    const userIcon = StringUtils.isEmpty(initials) ? (
        <Icon type={Icons.DefaultAvatar} />
    ) : (
        <React.Fragment>{initials}</React.Fragment>
    );

    const teamName = globalState.currentIdentity?.getCurrentRoleDisplayName();

    const fullName = globalState.currentIdentity?.user?.getFirstAndLastName();
    const fullNameText = StringUtils.hasValue(fullName)
        ? fullName
        : t("itemNotAvailable", { item: t("name") });

    const switchProfileMenuLabel = t("switchProfile");

    const isOrganizationMember = globalState.currentIdentity?.isCurrentRole(
        RoleType.ORGANIZATION_MEMBER
    );

    // #endregion Utilities

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const profileMenuDetail = () =>
        isOrganizationMember ? (
            <OrganizationMemberProfileMenuDetail
                focusFirstItem={focusFirstItem}
                setIsOpen={setIsOpen}
                triggerRef={triggerRef}
                userIcon={userIcon}
            />
        ) : (
            <ProfileMenuDetail
                focusFirstItem={focusFirstItem}
                handleOpenAnnouncementModal={handleOpenAnnouncementModal}
                hideActions={hideActions}
                setIsOpen={setIsOpen}
                setSwitchProfileModalIsOpen={setSwitchProfileModalIsOpen}
                showWhatsNew={showWhatsNew}
                triggerRef={triggerRef}
                unreadAnnouncement={alertUserOfAnnouncement}
                userIcon={userIcon}
            />
        );

    const renderProfileMenuDetailAnimation = () => {
        if (BrowserUtils.isIE()) {
            return <div>{profileMenuDetail()}</div>;
        }

        if (windowWidth > Breakpoints.Phone) {
            return (
                <motion.div {...FramerMotionTransitions.FADE_IN}>
                    {profileMenuDetail()}
                </motion.div>
            );
        }

        return (
            <Modal
                closeDialog={() => setTimeout(() => setIsOpen(false), 0)}
                cssClassName={`${BASE_CLASS_NAME}__mobile -modal`}
                isVisible={isOpen}
                label={switchProfileMenuLabel}
                transition={transitionEffect}
                type={ModalTypes.Bottom}>
                {profileMenuDetail()}
            </Modal>
        );
    };

    return (
        <div className={BASE_CLASS_NAME}>
            {!isOnline && (
                <div className={`${BASE_CLASS_NAME}__offline-indicator -icon`}>
                    <Icon type={Icons.WifiOffline} size={IconSizes.Large} />
                </div>
            )}
            {alertUserOfAnnouncement && <NotificationDotIcon pulse={true} />}
            <SidebarNavigationTooltip
                description={teamName ?? ""}
                offsetInPx={{
                    skidding: TOOLTIP_SKIDDING,
                    distance: TOOLTIP_DISTANCE,
                }}
                title={fullNameText}>
                <button
                    aria-label={t("nav-aria-user-menu-link")}
                    className={`${BASE_CLASS_NAME}__trigger`}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleMenu(false);
                    }}
                    onKeyDown={(e) =>
                        handleTriggerKeyDown(
                            e,
                            toggleMenu,
                            setFocusFirstItem,
                            setIsOpen
                        )
                    }
                    ref={triggerRef}>
                    {userIcon}
                </button>
            </SidebarNavigationTooltip>
            {isOpen && renderProfileMenuDetailAnimation()}
            {switchProfileModalIsOpen && (
                <SwitchProfileModal
                    isVisible={switchProfileModalIsOpen}
                    closeDialog={() => setSwitchProfileModalIsOpen(false)}
                    onSwitchProfile={() => setIsOpen(false)}
                />
            )}
            {showAnnouncement && (
                <AnnouncementModal
                    announcement={announcement}
                    isVisible={showAnnouncement}
                    onClose={handleCloseAnnouncementModal}
                />
            )}
        </div>
    );
    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Event Handlers
// -----------------------------------------------------------------------------------------

/**
 * Handle keyboard presses for accessibility. For example, pressing Escape
 * and closing the menu should return the user's focus to the element that opened
 * it.
 * @param e
 * @param toggleMenu
 * @param setFocusFirstItem
 * @param setIsOpen
 */
const handleTriggerKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    toggleMenu: (focusFirst: boolean) => void,
    setFocusFirstItem: (value: React.SetStateAction<boolean>) => void,
    setIsOpen: (value: React.SetStateAction<boolean>) => void
) => {
    if (
        e.key === KeyboardConstants.Enter ||
        e.key === KeyboardConstants.Space
    ) {
        e.preventDefault();
        toggleMenu(true);
        return;
    }

    if (e.key === KeyboardConstants.Tab) {
        setFocusFirstItem(true);
        return;
    }

    if (e.key === KeyboardConstants.Escape) {
        e.preventDefault();
        setIsOpen(false);
        return;
    }
};

// #endregion Event Handlers

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ProfileMenu;

// #endregion Exports
