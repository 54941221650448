import React from "react";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import Button from "atoms/buttons/button";

interface EmulateUserButtonProps {
    guestId: number | undefined;
    isAdmin: boolean | undefined;
}

const BASE_CLASS = "c-support-user-detail-page__user-details__emulate";

const EmulateUserButton: React.FC<EmulateUserButtonProps> = (
    props: EmulateUserButtonProps
) => {
    const { guestId, isAdmin } = props;
    const { createToken, isInEmulationMode } = useEmulationMode();

    const onClick = () => {
        createToken(guestId);
    };

    const buttonText = isAdmin
        ? "Emulation not available for Administrators"
        : "Emulate User";

    return (
        <div className={BASE_CLASS}>
            <Button
                disabled={isInEmulationMode || isAdmin}
                size={ButtonSizes.Small}
                style={ButtonStyles.Primary}
                onClick={onClick}>
                {buttonText}
            </Button>
        </div>
    );
};

export default EmulateUserButton;
