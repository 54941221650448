import {
    CatchResultHandler,
    Do,
    ResultRecord,
} from "andculturecode-javascript-core";
import { Set } from "immutable";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import { PartRecord } from "internal";
import PublicationRecord from "models/view-models/publication-record";
import HitRecord from "models/view-models/search/hit-record";
import SearchResultRecord from "models/view-models/search/search-result-record";
import SectionRecord from "models/view-models/section-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { useCallback, useState, useEffect } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useLoadRecords from "utilities/hooks/domain/use-load-records";
import AnnexService from "utilities/services/annexes/annex-service";
import ArticleService from "utilities/services/articles/article-service";
import ChapterService from "utilities/services/chapters/chapter-service";
import { PartService } from "internal";
import PublicationService from "utilities/services/publications/publication-service";
import SearchService, {
    SearchListQueryParams,
} from "utilities/services/search/search-service";
import SectionService from "utilities/services/sections/section-service";
import { ListService } from "utilities/services/service-factory";
import SituationService from "utilities/services/situational-navigation/situations/situation-service";
import SolutionService from "utilities/services/situational-navigation/solutions/solution-service";
import {
    UseSearchHookOptions,
    UseSearchHookProvider,
} from "utilities/hooks/use-search";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CloudSearchHookResult {
    loading: boolean;
    search: (
        params: SearchListQueryParams
    ) => Promise<SearchResultRecord | undefined>;
    searchResults?: SearchResultRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

/**
 * Hook to use cloud search
 * @param options
 */
export default function useCloudSearch(
    options: UseSearchHookOptions
): CloudSearchHookResult {
    // -------------------------------------------------------------------------------------------------
    // #region Options
    // -------------------------------------------------------------------------------------------------

    const { onError, params, provider = UseSearchHookProvider.Cloud } = options;

    // #endregion Options

    // -------------------------------------------------------------------------------------------------
    // #region Private Properties
    // -------------------------------------------------------------------------------------------------

    const { list: listAnnexesApi } = AnnexService.useList();
    const { list: listPartsApi } = PartService.useList();
    const { list: listPublicationsApi } = PublicationService.useList();
    const { list: listChaptersApi } = ChapterService.useList();
    const { list: listArticlesApi } = ArticleService.useList();
    const { list: listSectionsApi } = SectionService.useList();
    const { list: listSituationsApi } = SituationService.useList();
    const { list: listSolutionsApi } = SolutionService.useList();

    const { get } = SearchService.useGet();
    // endpoint has no route params, simplify function for convenience
    const searchApi = useCallback(
        (params: SearchListQueryParams) => get({}, params),
        [get]
    );

    const { loadRecords: loadAnnexes } = useLoadRecords<AnnexRecord>();
    const { loadRecords: loadParts } = useLoadRecords<PartRecord>();
    const { loadRecords: loadPublications } =
        useLoadRecords<PublicationRecord>();
    const { loadRecords: loadChapters } = useLoadRecords<ChapterRecord>();
    const { loadRecords: loadArticles } = useLoadRecords<ArticleRecord>();
    const { loadRecords: loadSections } = useLoadRecords<SectionRecord>();
    const { loadRecords: loadSituations } = useLoadRecords<SituationRecord>();
    const { loadRecords: loadSolutions } = useLoadRecords<SolutionRecord>();

    // #endregion Private Properties

    // -------------------------------------------------------------------------------------------------
    // #region Public Properties
    // -------------------------------------------------------------------------------------------------

    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<SearchResultRecord>();

    // #endregion Public Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    const handleError: CatchResultHandler<Array<SearchResultRecord>> =
        useCallback(
            (
                errorResult?: ResultRecord<Array<SearchResultRecord>>,
                error?: any
            ) => onError?.(errorResult, error),
            [onError]
        );

    const loadRelatedRecords = useCallback(
        async (searchResults: SearchResultRecord) => {
            let {
                annexIds,
                partIds,
                publicationIds,
                chapterIds,
                articleIds,
                sectionIds,
                situationIds,
                solutionIds,
            } = getRelatedRecordIds(searchResults);

            const sections =
                (await loadSections(
                    load(listSectionsApi, "sectionIds", sectionIds)
                )) ?? [];
            annexIds = [
                ...annexIds,
                ...sections
                    .filter((s: SectionRecord) => s.annexId != null)
                    .map((s: SectionRecord) => s.annexId!),
            ];
            partIds = [
                ...partIds,
                ...sections
                    .filter((s: SectionRecord) => s.partId != null)
                    .map((s: SectionRecord) => s.partId!),
            ];
            publicationIds = [
                ...publicationIds,
                ...sections
                    .filter((s: SectionRecord) => s.publicationId != null)
                    .map((s: SectionRecord) => s.publicationId!),
            ];
            chapterIds = [
                ...chapterIds,
                ...sections
                    .filter((s: SectionRecord) => s.chapterId != null)
                    .map((s: SectionRecord) => s.chapterId!),
            ];
            articleIds = [
                ...articleIds,
                ...sections
                    .filter((s: SectionRecord) => s.articleId != null)
                    .map((s: SectionRecord) => s.articleId!),
            ];

            const [
                annexes,
                parts,
                publications,
                chapters,
                articles,
                situations,
                solutions,
            ] = await Promise.all([
                loadAnnexes(load(listAnnexesApi, "annexIds", annexIds)),
                loadParts(load(listPartsApi, "partIds", partIds)),
                loadPublications(
                    load(listPublicationsApi, "publicationIds", publicationIds)
                ),
                loadChapters(load(listChaptersApi, "chapterIds", chapterIds)),
                loadArticles(load(listArticlesApi, "articleIds", articleIds)),
                loadSituations(
                    load(listSituationsApi, "situationIds", situationIds)
                ),
                loadSolutions(
                    load(listSolutionsApi, "solutionIds", solutionIds)
                ),
            ]);

            return searchResults.with({
                hits: searchResults.hits?.map((hit: HitRecord) =>
                    hit
                        .withAnnex(annexes ?? [])
                        .withArticle(articles ?? [])
                        .withChapter(chapters ?? [])
                        .withPart(parts ?? [])
                        .withPublication(publications ?? [])
                        .withSituations(situations ?? [])
                        .withSolutions(solutions ?? [])
                        .withSection(
                            (sections ?? []).map((s: SectionRecord) =>
                                s
                                    .withAnnex(annexes ?? [])
                                    .withArticle(articles ?? [])
                                    .withChapter(chapters ?? [])
                                    .withPart(parts ?? [])
                                    .withPublication(publications ?? [])
                            )
                        )
                ),
            });
        },
        [
            listArticlesApi,
            listAnnexesApi,
            listChaptersApi,
            listPartsApi,
            listPublicationsApi,
            listSectionsApi,
            listSituationsApi,
            listSolutionsApi,
            loadArticles,
            loadAnnexes,
            loadChapters,
            loadParts,
            loadPublications,
            loadSections,
            loadSituations,
            loadSolutions,
        ]
    );

    const search = useCallback(
        async (
            params?: SearchListQueryParams
        ): Promise<SearchResultRecord | undefined> => {
            if (params == null || provider !== UseSearchHookProvider.Cloud) {
                setSearchResults(undefined);
                setLoading(false);
                return undefined;
            }

            return await Do.try(async () => {
                setLoading(true);
                const result = await searchApi(params);
                const resultWithRelationships = await loadRelatedRecords(
                    result.resultObject!
                );
                setSearchResults(resultWithRelationships);
                return resultWithRelationships;
            })
                .catch(handleError)
                .finally(() => setLoading(false))
                .getAwaiter();
        },
        [provider, handleError, searchApi, loadRelatedRecords]
    );

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Effects
    // -------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (params == null || provider !== UseSearchHookProvider.Cloud) {
            return;
        }

        search(params);
    }, [search, params, provider]);

    // #endregion Effects

    // -------------------------------------------------------------------------------------------------
    // #region Result
    // -------------------------------------------------------------------------------------------------

    return {
        loading,
        search,
        searchResults,
    };

    // #endregion Result
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getUniqueNonNullIds = (
    hits: Array<HitRecord>,
    getIdFunction: (hit: HitRecord) => number | undefined
): Array<number> => {
    return Set(
        hits
            .filter((hit: HitRecord) => getIdFunction(hit) != null)
            .map((hit: HitRecord) => getIdFunction(hit)!)
    ).toArray();
};

const load = <TRecord, TQueryParams>(
    service: ListService<TRecord, TQueryParams>,
    idsParamKey: keyof TQueryParams,
    ids: Array<number>
): (() => Promise<Array<TRecord> | undefined>) => {
    return async () => {
        if (CollectionUtils.isEmpty(ids)) {
            return [];
        }

        // @ts-ignore compiler is complaining that TQueryParams could be a subclass of TQueryParams
        const params: TQueryParams = { [idsParamKey]: ids };
        return (await service(params)).resultObjects;
    };
};

const getRelatedRecordIds = (searchResults: SearchResultRecord) => {
    const annexIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getAnnexId()
    );
    const partIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getPartId()
    );
    const publicationIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getPublicationId()
    );
    const chapterIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getChapterId()
    );
    const articleIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getArticleId()
    );
    const sectionIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getSectionId()
    );
    const situationIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getSituationId()
    );
    const solutionIds = getUniqueNonNullIds(
        searchResults.hits ?? [],
        (hit: HitRecord) => hit.getSolutionId()
    );

    return {
        annexIds,
        partIds,
        publicationIds,
        chapterIds,
        articleIds,
        sectionIds,
        situationIds,
        solutionIds,
    };
};

// #endregion Functions
