import LegacyPublicationRecord from "models/view-models/legacy-publication-record";
import { useCallback, useEffect } from "react";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import LegacyService from "utilities/services/publications/legacy/legacy-service";

interface UseLegacyPublicationOptions {
    publicationId: number;
}

export default function useLegacyPublication(
    options: UseLegacyPublicationOptions
): ServiceHookResult<LegacyPublicationRecord> {
    const { publicationId } = options;
    const { get: getLegacyPublication } = LegacyService.useGet();

    const { result, setError, setInitial, setLoading, setSuccess } =
        useServiceHookResultState<LegacyPublicationRecord>(
            new LegacyPublicationRecord()
        );

    const loadLegacyPublication = useCallback(async () => {
        setLoading();

        try {
            const { resultObject: legacyPublication } =
                await getLegacyPublication({ id: publicationId });

            if (legacyPublication != null) {
                setSuccess(legacyPublication);
            } else {
                setInitial();
            }
        } catch (error) {
            setError(error);
        }
    }, [
        publicationId,
        getLegacyPublication,
        setError,
        setInitial,
        setLoading,
        setSuccess,
    ]);

    useEffect(() => {
        loadLegacyPublication();
    }, [loadLegacyPublication]);

    return { ...result };
}
