import {
    SetStateAction,
    Dispatch,
    createContext,
    useContext,
    useCallback,
} from "react";
import LinkPreviewContextRecord from "models/view-models/link-preview-context-record";
import { useHistory } from "react-router-dom";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type LinkPreviewContextUpdater = Dispatch<
    SetStateAction<LinkPreviewContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: LinkPreviewContextRecord = new LinkPreviewContextRecord();
const defaultUpdater: LinkPreviewContextUpdater = () => {};
const LinkPreviewContext = createContext<
    [LinkPreviewContextRecord, LinkPreviewContextUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useLinkPreviewContext() {
    const [linkPreviewContext, setLinkPreviewContext] =
        useContext(LinkPreviewContext);

    const resetLinkPreviewContext = useCallback(
        () => setLinkPreviewContext(new LinkPreviewContextRecord()),
        [setLinkPreviewContext]
    );

    const history = useHistory();

    const onAnchorClick = useCallback(
        (
            event: React.MouseEvent<HTMLElement, MouseEvent>,
            sectionId: number,
            route: string
        ) => {
            if (event == null) {
                return;
            }

            event.preventDefault();

            setLinkPreviewContext((linkPreviewContext) =>
                linkPreviewContext.with({
                    isModalOpen: true,
                    route: route,
                    sectionId: sectionId,
                })
            );
        },
        [setLinkPreviewContext]
    );

    const onModalClose = resetLinkPreviewContext;

    const onGoToBookView = useCallback(
        (event?: React.MouseEvent<HTMLElement, MouseEvent>, route?: string) => {
            if (event == null || route == null) {
                return;
            }

            event.preventDefault();

            resetLinkPreviewContext();

            setTimeout(() => {
                history.push(route);
            }, 0);
        },
        [history, resetLinkPreviewContext]
    );

    return {
        ...linkPreviewContext.toJS(),
        onAnchorClick,
        onGoToBookView,
        onModalClose,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default LinkPreviewContext;

// #endregion Exports
