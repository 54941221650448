import { Record } from "immutable";
import OrganizationManifest from "models/interfaces/organization-manifest";

const defautValues: OrganizationManifest = { name: "", loginSlug: "" };

export default class OrganizationManifestRecord
    extends Record(defautValues)
    implements OrganizationManifest
{
    constructor(params?: OrganizationManifest) {
        if (params == null) params = Object.assign({}, defautValues);
        super(params);
    }
}
