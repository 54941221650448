import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import * as React from "react";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { tForCulture } from "utilities/localization-utils";

export interface InformationalNoteSectionProps
    extends XmlConvertedComponentProps {
    label: string;
}

const InformationalNoteSection: React.FunctionComponent<InformationalNoteSectionProps> =
    (props: InformationalNoteSectionProps) => {
        const { publication } = useCurrentPublication();
        const changedModifier =
            props.diffchanged != null
                ? ` c-code-change -${props.diffchanged}`
                : "";
        const cssClassName = "c-informational-note-section" + changedModifier;
        const label =
            props.label == null
                ? tForCulture(publication?.locale, "informationalNote")
                : props.label;

        return (
            <div className={cssClassName}>
                {label + ": "}
                {props.children}
            </div>
        );
    };

export default InformationalNoteSection;
