import { RecordUtils } from "andculturecode-javascript-core/dist/utilities/record-utils";
import { Record } from "immutable";
import ChapterTableOfContents from "models/interfaces/table-of-contents/chapter-table-of-contents";
import ArticleTableOfContentsRecord from "./article-table-of-contents-record";
import SectionTableOfContentsRecord from "./section-table-of-contents-record";
import { tForCulture } from "utilities/localization-utils";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";
import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import {
    PublicationParams,
    PublicationPermalinkParams,
    PublicationPreviewParams,
} from "interfaces/routing/route-params";
import { CollectionUtils } from "utilities/collection-utils";

const defaultValues: ChapterTableOfContents =
    RecordUtils.defaultValuesFactory<ChapterTableOfContents>({
        id: undefined,
        articles: [],
        displaySequence: undefined,
        hasTables: false,
        isDeleted: false,
        label: "",
        nfpaLabel: "",
        sections: [],
        title: "",
    });

export default class ChapterTableOfContentsRecord
    extends Record(defaultValues)
    implements ChapterTableOfContents
{
    constructor(params?: ChapterTableOfContents) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (CollectionUtils.hasValues(params.articles)) {
            params.articles = RecordUtils.ensureRecords(
                params.articles,
                ArticleTableOfContentsRecord
            );
        }

        if (CollectionUtils.hasValues(params.sections)) {
            params.sections = RecordUtils.ensureRecords(
                params.sections,
                SectionTableOfContentsRecord
            );
        }

        super(params);
    }

    public getDisplayTitle(locale?: string) {
        const number = parseInt(this?.nfpaLabel ?? "");

        if (Number.isNaN(number)) return this.title ?? "";

        // The intro chapter is "Chapter 0"
        // <title> for introduction in the XML is empty
        if (number === 0) return tForCulture(locale, "introduction");

        const titleWithNumber = tForCulture(locale, "chapterNumber", {
            number,
        });

        const title = `${titleWithNumber} ${
            UnicodeCharacterConstants.EmDash
        } ${this.title?.replace(titleWithNumber, "")}`;

        return title;
    }

    public getRoute(params: PublicationParams, isAdmin?: boolean) {
        return isAdmin
            ? this.getAdminRoute(params)
            : this.getPermalinkRoute(params);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<ChapterTableOfContentsRecord>} values
     */
    public with(
        values: Partial<ChapterTableOfContents>
    ): ChapterTableOfContentsRecord {
        return new ChapterTableOfContentsRecord(
            Object.assign(this.toJS(), values)
        );
    }

    private getAdminRoute(params: PublicationPreviewParams) {
        return RouteUtils.getUrl(siteMap.publications.adminPreview.chapter, {
            publicationId: params?.id,
            id: this.id,
        });
    }

    private getPermalinkRoute(params: PublicationPermalinkParams) {
        if (params.code == null || params.edition == null) return "#";

        return RouteUtils.getUrl(siteMap.publications.permalinks.chapter, {
            code: params.code,
            edition: params.edition,
            nfpaLabel: this.nfpaLabel,
        });
    }
}
