import React from "react";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { RouteUtils } from "utilities/route-utils";
import {
    NestedRoutes,
    NestedRoutesProps,
} from "utilities/routing/nested-route";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyLinkPageProps extends NestedRoutesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyLinkPage: React.FC<any> = (props: MyLinkPageProps) => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { generateCultureCodedRoute } = useCultureCodeRoute();

    // #endregion Hooks

    if (
        RouteUtils.assertCurrentUrl(
            generateCultureCodedRoute(siteMap.myLink.dashboard)
        )
    ) {
        // default to My Bookmarks tab
        return (
            <Redirect
                to={generateCultureCodedRoute(siteMap.myLink.myBookmarks)}
            />
        );
    }

    return (
        <NestedRoutes
            routes={props.routes.map((route: RouteDefinition) =>
                RouteUtils.withCulture<RouteDefinition>(route)
            )}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MyLinkPage;

// #endregion Exports
