import React from "react";
import AnchorWithIcon from "./anchor-with-icon";
import { Icons } from "atoms/constants/icons";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicationSidebarNavAnchorProps {
    accessibleText: string;
    linkText: string;
    to: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationSidebarNavAnchor: React.FunctionComponent<PublicationSidebarNavAnchorProps> =
    (props: PublicationSidebarNavAnchorProps) => {
        // -----------------------------------------------------------------------------------------
        // #region Constructor
        // -----------------------------------------------------------------------------------------

        const { accessibleText, linkText, to } = props;

        // #endregion Constructor

        // -----------------------------------------------------------------------------------------
        // #region Hooks
        // -----------------------------------------------------------------------------------------

        // #endregion Hooks

        // -----------------------------------------------------------------------------------------
        // #region Returns
        // -----------------------------------------------------------------------------------------

        return (
            <AnchorWithIcon
                accessibleText={accessibleText}
                icon={Icons.ChevronLeft}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                to={to}>
                {linkText}
            </AnchorWithIcon>
        );

        // #endregion Returns
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationSidebarNavAnchor;

// #endregion Exports
