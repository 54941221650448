import LegacyPublication from "models/interfaces/legacy-publication";
import { Record as ImmutableRecord } from "immutable";

const defaultValues: LegacyPublication = {
    totalPages: undefined,
    legacyPublicationPages: undefined,
    tableOfContents: [],
};

export default class LegacyPublicationRecord
    extends ImmutableRecord(defaultValues)
    implements LegacyPublication
{
    constructor(params?: LegacyPublication) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
}
