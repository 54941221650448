import { HeadingPriority } from "atoms/constants/heading-priority";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import React, { createContext } from "react";
import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SectionPreviewProps extends Pick<DataTestAttributes, "dataTestId"> {
    isLoading: boolean;
    publication: PublicationRecord;
    section: SectionRecord;
    subSections?: SectionRecord[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-section-content-preview";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export const RefPanelSectionContext = createContext(false);

const SectionContentPreview: React.FC<SectionPreviewProps> = (
    props: SectionPreviewProps
) => {
    const { dataTestId, isLoading, publication, section, subSections } = props;
    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div
            className={`${CSS_BASE_CLASS_NAME}__body`}
            data-test-id={dataTestId}
            tabIndex={0}>
            <Heading priority={HeadingPriority.Six}>
                {publication?.getDisplayTitle()}
            </Heading>
            <Heading
                cssClassName={`${CSS_BASE_CLASS_NAME}__body__section-title`}
                priority={HeadingPriority.Five}>
                <ChangeIndicatorIcon
                    changes={section.changes}
                    cssClassName={"-change-indicator"}
                />
                {section?.getFullyQualifiedDisplayTitle()}
            </Heading>

            {
                // if
                isLoading && <Loader />
            }
            <RefPanelSectionContext.Provider value={true}>
                {
                    // if
                    StringUtils.hasValue(section?.body) && !isLoading && (
                        <div>{section?.getBody()}</div>
                    )
                }
                {
                    // if
                    CollectionUtils.hasValues(subSections) &&
                        !isLoading &&
                        subSections.map(
                            (subSection: SectionRecord, index: number) => (
                                <React.Fragment key={index}>
                                    {subSection.isDeleted() ? (
                                        <ChangeIndicatorIcon
                                            changes={subSection.changes}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <Heading
                                                cssClassName={`${CSS_BASE_CLASS_NAME}__body__sub-section-title`}
                                                priority={HeadingPriority.Five}>
                                                <ChangeIndicatorIcon
                                                    cssClassName={
                                                        "-change-indicator"
                                                    }
                                                    changes={subSection.changes}
                                                />
                                                {subSection?.getDisplayTitle()}
                                            </Heading>
                                            {
                                                // if
                                                StringUtils.hasValue(
                                                    subSection?.body
                                                ) && (
                                                    <div>
                                                        {subSection?.getBody()}
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )
                        )
                }
            </RefPanelSectionContext.Provider>
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionContentPreview;

// #endregion Exports
