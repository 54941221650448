import TopicRecord from "models/view-models/topic-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PublicTopicBasePathParams {}

export interface PublicTopicResourcePathParams
    extends PublicTopicBasePathParams {
    id: number;
}

/**
 * Search parameters when listing Topic resources
 * These get translated to QueryString parameters
 */
export interface PublicTopicListQueryParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "public/topics";
const resourceType = TopicRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const PublicTopicService = {
    /**
     * Retrieve a a list of all Topics matching given parameters
     */
    list: ServiceFactory.list<TopicRecord, PublicTopicListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useList<
        TopicRecord,
        PublicTopicListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls utilizing react-query
     */
    useListQuery: QueryHookFactory.useListQuery<
        TopicRecord,
        PublicTopicListQueryParams
    >(baseEndpoint, resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicTopicService;

// #endregion Exports
