import * as React from "react";

import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";

export interface OriginsH2ConverterProps {
    children?: JSX.Element[];
    className?: string;
    datalabel?: string;
}

const OriginsH2Converter = (props: OriginsH2ConverterProps) => {
    // There are times where props.children is undefined
    if (props.children === undefined) {
        return null;
    }

    return <Heading priority={HeadingPriority.Three}>{props.children}</Heading>;
};

export default OriginsH2Converter;
