import * as React from "react";

export interface OriginsConverterProps {
    children?: React.ReactNodeArray;
    className?: string;
    datalabel?: string;
}

const OriginsConverter = (props: OriginsConverterProps) => {
    // There are times where props.children is undefined
    if (props.children === undefined) {
        return null;
    }

    return <div className="c-publication-content">{props.children}</div>;
};

export default OriginsConverter;
