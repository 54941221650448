import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

const useWorkGroupModalActions = (
    existingWorkGroup?: WorkGroupRecord | undefined
) => {
    const { currentIdentity } = useGlobalState().globalState;
    const currentGroupId = currentIdentity?.getCurrentTeamId();

    const [workGroup, setWorkGroup] = useState<WorkGroupRecord>(() =>
        existingWorkGroup != null
            ? existingWorkGroup
            : new WorkGroupRecord({
                  groupId: currentGroupId,
                  title: "",
                  workGroupMembers: [],
              })
    );

    useEffect(() => {
        if (existingWorkGroup != null) setWorkGroup(existingWorkGroup);
    }, [existingWorkGroup]);

    const updateWorkGroup = useCallback((values: Partial<WorkGroupRecord>) => {
        setWorkGroup((prev) => prev.with({ ...values }));
    }, []);

    // Will there be a minimum required length?
    const isWorkGroupNameValid =
        workGroup.title != null && workGroup.title.trim().length > 0;

    return {
        isWorkGroupNameValid,
        setWorkGroup,
        updateWorkGroup,
        workGroup,
    };
};

export default useWorkGroupModalActions;
