import { AnnexGroupParams, AnnexParams } from "interfaces/routing/route-params";
import AnnexGroupTableOfContentsRecord from "models/view-models/table-of-contents/annex-group-table-of-contents-record";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import ExpandableMetaNavCollapsePanel, {
    ExpandableMetaNavLevels,
} from "./expandable-meta-nav-collapse-panel";
import ExpandableMetaNavSections from "./expandable-meta-nav-sections";
import { SectionParentType } from "utilities/enumerations/section-parent-type";
import useAdminPreview from "utilities/hooks/use-admin-preview";

interface ExpandableMetaNavAnnexGroupProps {
    annexId: string;
    annexGroup: AnnexGroupTableOfContentsRecord;
    annexParams: AnnexParams;
    currentGroupById: string | null;
}

const ExpandableMetaNavAnnexGroup = (
    props: ExpandableMetaNavAnnexGroupProps
) => {
    const { annexId, annexGroup, annexParams, currentGroupById } = props;
    const { isAdminPreview } = useAdminPreview();

    const groupParams: AnnexGroupParams = {
        ...annexParams,
        annexId,
        id: annexGroup.groupBy,
    };

    const isCurrentActiveGroupPanel = annexGroup.groupBy === currentGroupById;

    const sections = annexGroup.sections.filter((s) => !s.isDeleted);

    const hasSections = CollectionUtils.hasValues(sections);

    return (
        <ExpandableMetaNavCollapsePanel
            key={annexGroup.groupBy}
            isCurrentActivePanel={isCurrentActiveGroupPanel}
            level={ExpandableMetaNavLevels.Child}
            headingText={annexGroup.getTitle() ?? ""}
            headingRoute={annexGroup.getRoute(groupParams, isAdminPreview)}>
            {hasSections && (
                <ExpandableMetaNavSections
                    sections={sections}
                    isAdminPreview={isAdminPreview}
                    isCurrentActivePanel={isCurrentActiveGroupPanel}
                    parentType={SectionParentType.AnnexGroup}
                    routeParams={groupParams}
                />
            )}
        </ExpandableMetaNavCollapsePanel>
    );
};

export default ExpandableMetaNavAnnexGroup;
