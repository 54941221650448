import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import LinkResources from "molecules/linkResources/link-resources";
import LinkResourceRecord from "molecules/linkResources/link-resource-record";
import useLinkResourcesChapterProvider from "molecules/linkResources/link-resources-chapter-provider";
import ChapterTableOfContentsRecord from "models/view-models/table-of-contents/chapter-table-of-contents-record";
import { useParams } from "react-router-dom";
import { PublicationParams } from "interfaces/routing/route-params";
import ExpandableMetaNavSections from "./expandable-meta-nav-sections";
import ExpandableMetaNavCollapsePanel, {
    ExpandableMetaNavLevels,
} from "./expandable-meta-nav-collapse-panel";
import ExpandableMetaNavArticle from "./expandable-meta-nav-article";
import { SectionParentType } from "utilities/enumerations/section-parent-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ExpandableMetaNavChapterProps {
    isCurrentActivePanel: boolean;
    chapter: ChapterTableOfContentsRecord;
    locale?: string;
    publicationId: number;
}

const BASE_CSS_CLASSNAME = "c-expandable-meta-nav-chapter";

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ExpandableMetaNavChapter: React.FunctionComponent<
    ExpandableMetaNavChapterProps
> = (props: ExpandableMetaNavChapterProps) => {
    const { isCurrentActivePanel, chapter, locale, publicationId } = props;

    const { isAdminPreview } = useAdminPreview();
    const params = useParams<PublicationParams>();

    const { articles, sections } = chapter;
    const displaySections = sections?.filter((s) => !s.isDeleted);

    const LinkResourcesProvider = useLinkResourcesChapterProvider();
    let linkResources: LinkResourceRecord[] = [];

    if (isAdminPreview && chapter) {
        linkResources = LinkResourcesProvider.getChapterPreviewLinkResources(
            chapter.id ?? "",
            chapter.label ?? "",
            params.code ?? "",
            publicationId,
            chapter?.hasTables
        );
    } else if (chapter) {
        linkResources = LinkResourcesProvider.getChapterLinkResources(
            chapter.nfpaLabel ?? "",
            params.code,
            params.edition,
            chapter.nfpaLabel ?? "",
            chapter?.hasTables ?? false
        );
    }
    const routeParams = {
        code: params.code,
        edition: params.edition,
        publicationId: params.id,
        nfpaLabel: chapter.nfpaLabel,
        id: chapter.id,
    };

    return (
        <div className={BASE_CSS_CLASSNAME}>
            <ExpandableMetaNavCollapsePanel
                isCurrentActivePanel={isCurrentActivePanel}
                level={ExpandableMetaNavLevels.Parent}
                headingText={chapter.getDisplayTitle(locale)}
                headingRoute={chapter.getRoute(params, isAdminPreview)}>
                {CollectionUtils.hasValues(articles) &&
                    articles.map((article) => (
                        <ExpandableMetaNavArticle
                            key={article.id}
                            article={article}
                            publicationId={publicationId}
                            isCurrentActivePanel={isCurrentActivePanel}
                            isAdminPreview={isAdminPreview}
                            routeParams={routeParams}
                        />
                    ))}

                {CollectionUtils.isEmpty(articles) &&
                    CollectionUtils.hasValues(displaySections) && (
                        <ExpandableMetaNavSections
                            sections={displaySections}
                            isAdminPreview={isAdminPreview}
                            isCurrentActivePanel={isCurrentActivePanel}
                            parentType={SectionParentType.Chapter}
                            routeParams={routeParams}
                        />
                    )}

                {CollectionUtils.hasValues(linkResources) && (
                    <LinkResources linkResourceRecords={linkResources} />
                )}
            </ExpandableMetaNavCollapsePanel>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default ExpandableMetaNavChapter;

// #endregion Export
