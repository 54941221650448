import { useLocalization } from "utilities/hooks/use-localization";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import SectionShareLink from "models/interfaces/share-links/section-share-link";
import ShareableValidator from "models/validation/shareable-validator";
import SectionRecord from "models/view-models/section-record";
import SectionShareLinkRecord from "models/view-models/share-links/section-share-link-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import EmailMultiInput from "molecules/form-fields/email-multi-input";
import InputFormField from "molecules/form-fields/input-form-field";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import Form from "molecules/forms/form";
import Modal from "molecules/modals/modal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import useLoading from "utilities/hooks/use-loading";
import CultureResources from "utilities/interfaces/culture-resources";
import PublicationService from "utilities/services/publications/publication-service";
import SectionShareLinkService from "utilities/services/share-links/section-share-link-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import EmailConstants from "utilities/validation/email-constants";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ShareModalProps {
    onClose: () => void;
    section: SectionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-share-modal";
const Validator = new ShareableValidator();

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ShareModal: React.FC<ShareModalProps> = (props: ShareModalProps) => {
    const { onClose, section } = props;
    const { t } = useLocalization<CultureResources>();
    const isDesktop = useBreakpoint(Breakpoints.SmallTablet);

    const modalType = isDesktop ? ModalTypes.Base : ModalTypes.Bottom;

    const modalTransition = isDesktop
        ? ModalTransitions.Fade
        : ModalTransitions.SlideUp;

    const { get: getPublicationApi } = PublicationService.useGet();
    const { create: createShareLinkApi } = SectionShareLinkService.useCreate();

    const subjectInputRef = useRef<HTMLInputElement>(null);

    const [shareLink, setShareLink] = useState(
        new SectionShareLinkRecord().with({ sectionId: section.id! })
    );

    const [loadingPublication, setLoadingPublication] = useState(false);
    const [loadingShareLink, setLoadingShareLink] = useState(false);
    const loading = useLoading(loadingPublication, loadingShareLink);

    const [publicationLabel, setPublicationLabel] = useState("");
    const [validationErrors, setValidationErrors] = useState<
        ObjectValidationResult<SectionShareLink>
    >({});

    const handleEmailAddressesChanged = (newValues: Array<string>) =>
        setShareLink(shareLink.with({ emailAddresses: newValues }));
    const handleSubjectChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setShareLink(shareLink.with({ subject: e.target.value }));
    const handleMessageChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setShareLink(shareLink.with({ message: e.target.value }));

    const handleFormSubmit = async () => {
        const validationResult = Validator.validate(shareLink);
        setValidationErrors(validationResult);
        if (ObjectValidator.hasErrors(validationResult)) {
            return;
        }

        setLoadingShareLink(true);

        try {
            await createShareLinkApi(shareLink);
            onClose();
            reset();
            ToastManager.success(t("sectionShared"));
        } catch (e) {
            ToastManager.error(
                t("errors-actionResource", {
                    action: t("sharing"),
                    resource: t("section"),
                })
            );
        }

        setLoadingShareLink(false);
    };

    const handleCancel = () => {
        onClose();
    };

    const reset = useCallback(() => {
        setShareLink(
            new SectionShareLinkRecord().with({
                sectionId: section.id!,
                message: "",
                subject: publicationLabel,
            })
        );
        setValidationErrors({});
    }, [publicationLabel, section]);

    const isPublicationLabelSet = useCallback(
        () => StringUtils.hasValue(publicationLabel),
        [publicationLabel]
    );

    useEffect(() => {
        if (isPublicationLabelSet()) {
            // already loaded
            return;
        }

        if (section.publication != null) {
            setPublicationLabel(section.publication.getDisplayTitle(true));
            setShareLink((link: SectionShareLinkRecord) =>
                link.with({
                    subject: section.publication!.getDisplayTitle(true),
                })
            );
            return;
        }

        if (section.publicationId == null) {
            setPublicationLabel("");
            return;
        }

        const loadPublicationLabel = async () => {
            setLoadingPublication(true);

            try {
                const result = await getPublicationApi({
                    id: section.publicationId!,
                });
                setPublicationLabel(result.resultObject!.getDisplayTitle(true));
                setShareLink((link: SectionShareLinkRecord) =>
                    link.with({
                        subject: result.resultObject!.getDisplayTitle(true),
                    })
                );
            } catch (e) {
                setPublicationLabel("");
                ToastManager.error(
                    t("errors-actionResource", {
                        action: t("loading"),
                        resource: t("publication"),
                    })
                );
            }

            setLoadingPublication(false);
        };

        loadPublicationLabel();
    }, [getPublicationApi, isPublicationLabelSet, section, t]);

    useEffect(() => {
        if (StringUtils.hasValue(publicationLabel)) {
            // default subject to publication label
            setShareLink((link: SectionShareLinkRecord) =>
                link.with({ subject: publicationLabel })
            );
        }
    }, [publicationLabel]);

    const sendEmailText = t("sendEmail");
    const cancelButtonText = t("cancel");
    const buttonStyle =
        sendEmailText.concat(cancelButtonText).length > 16 ? "-wide" : "";

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={onClose}
            cssClassName={CSS_CLASS_NAME}
            isVisible={true}
            label={`${t("share")} ${t("section")}`}
            transition={modalTransition}
            type={modalType}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header__left`}>
                    <Icon type={Icons.Share} />
                    <Heading priority={HeadingPriority.Two}>
                        {t("share")}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__header__right`}>
                    <Button
                        accessibleText={t("closeItem", { item: t("dialog") })}
                        cssClassName={"-modal-close -icon"}
                        onClick={onClose}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.TertiaryAlt}>
                        <Icon type={Icons.Close} size={IconSizes.Large} />
                    </Button>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div
                    className={`${CSS_CLASS_NAME}__body__section-preview`}
                    data-test-id={section.externalId}>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__body__section-preview__title`}>
                        {section.getFullyQualifiedDisplayTitle()}
                    </Paragraph>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__body__section-preview__publication-title`}>
                        {loadingPublication && (
                            <Loader
                                accessibleText={t("loadingItem", {
                                    item: t("publication"),
                                })}
                            />
                        )}
                        {!loadingPublication && publicationLabel}
                    </Paragraph>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__body__section-preview__body`}>
                        {StringUtils.truncateRight(
                            section.bodyAsPlainText ?? "",
                            350
                        )}
                    </Paragraph>
                </div>
                <Form onSubmit={handleFormSubmit}>
                    <EmailMultiInput
                        disabled={loadingShareLink}
                        emails={shareLink.emailAddresses}
                        errorMessages={validationErrors.emailAddresses}
                        inModal={true}
                        isValid={CollectionUtils.isEmpty(
                            validationErrors.emailAddresses
                        )}
                        label={t("shareTo")}
                        onEmailsChanged={handleEmailAddressesChanged}
                        required={true}
                        suggestTeamMembers={true}
                    />
                    <InputFormField
                        disabled={loading}
                        errorMessages={validationErrors.subject}
                        isValid={CollectionUtils.isEmpty(
                            validationErrors.subject
                        )}
                        label={t("subject")}
                        maxLength={EmailConstants.subjectMaxLength}
                        onChange={handleSubjectChanged}
                        placeholder={t("subject")}
                        ref={subjectInputRef}
                        required={true}
                        value={shareLink.subject}
                    />
                    <TextAreaFormField
                        disabled={loadingShareLink}
                        isValid={true}
                        label={t("message")}
                        onChange={handleMessageChanged}
                        placeholder={`${t("enterAMessage")} ${t(
                            "field-optional"
                        )}`}
                        value={shareLink.message}
                    />
                </Form>
                <div
                    className={`${CSS_CLASS_NAME}__body__footer ${
                        !isDesktop ? "-mobile" : ""
                    }`}>
                    {!loadingShareLink && (
                        <React.Fragment>
                            <Button
                                onClick={handleCancel}
                                style={ButtonStyles.Secondary}
                                cssClassName={buttonStyle}>
                                {cancelButtonText}
                            </Button>
                            <Button
                                onClick={handleFormSubmit}
                                cssClassName={buttonStyle}>
                                {sendEmailText}
                            </Button>
                        </React.Fragment>
                    )}
                    {loadingShareLink && (
                        <Loader
                            accessibleText={`${t("sharing")} ${t("section")}`}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ShareModal;

// #endregion Exports
