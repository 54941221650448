import Tooltip, { FollowCursorPlacement } from "molecules/tooltips/tooltip";
import React, { useRef } from "react";
import useTextOverflow from "utilities/hooks/use-text-overflow";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ResourceSubtextLabelProps {
    content: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const TOOLTIP_DELAY_IN_MS = 500;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResourceSubtextLabel: React.FC<ResourceSubtextLabelProps> = (
    props: ResourceSubtextLabelProps
) => {
    const { content } = props;
    const labelRef = useRef<HTMLLabelElement>(null);
    const isOverflowed = useTextOverflow(labelRef);

    return (
        <Tooltip
            content={content}
            delayInMs={TOOLTIP_DELAY_IN_MS}
            disabled={!isOverflowed}
            followCursor={FollowCursorPlacement.Initial}>
            <label ref={labelRef}>{content}</label>
        </Tooltip>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ResourceSubtextLabel;

// #endregion Exports
