import UserCollectionBookmarkRecord from "models/view-models/user-collection-bookmark-record";
import { useCallback, useState } from "react";
import UserCollectionBookmarkService from "utilities/services/user-collection-bookmarks/user-collection-bookmark-service";
import { ToastManager } from "utilities/toast/toast-manager";

const defaultSuccessHandler = () => {};
const defaultErrorHandler = () =>
    ToastManager.error("There was an issue updating bookmark collections.");

/**
 * Custom hook to handle figuring out which records need to be deleted, and which need to be created when
 * updating collections applied to a bookmark.
 * When the {updateUserCollectionBookmarks} method from the return value is called, it basically performs
 * a diff based on the collection IDs with {initialCollectionBookmarks}, and uses that to create/delete
 * the appropriate records.
 * @param initialCollectionBookmarks
 * @param onSuccess
 * @param onError
 */
export default function useUpdateBookmarkCollections(
    initialCollectionBookmarks: Array<UserCollectionBookmarkRecord>,
    onSuccess?: () => void,
    onError?: () => void
) {
    const { create: createCollectionBookmarkApi } =
        UserCollectionBookmarkService.useCreate();
    const { delete: deleteCollectionBookmarkApi } =
        UserCollectionBookmarkService.useDelete();

    const [saving, setSaving] = useState(false);

    const handleSuccess = useCallback(() => {
        if (onSuccess != null) {
            onSuccess();
            return;
        }

        defaultSuccessHandler();
    }, [onSuccess]);

    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler();
    }, [onError]);

    const getRecordsToCreateAndDelete = useCallback(
        (
            bookmarkId: number,
            collectionIds: Array<number>
        ): [Array<UserCollectionBookmarkRecord>, Array<number>] => {
            const recordsToCreate = collectionIds
                .filter(
                    (collectionId: number) =>
                        // collectionIds that do NOT exist in the initialCollectionBookmarks
                        !initialCollectionBookmarks.some(
                            (cb: UserCollectionBookmarkRecord) =>
                                cb.collectionId === collectionId
                        )
                )
                .map(
                    (collectionId: number) =>
                        // map to UserCollectionBookmarkRecords to be passed to create API
                        new UserCollectionBookmarkRecord({
                            bookmarkId,
                            collectionId,
                        })
                );
            const idsToDelete = initialCollectionBookmarks
                .filter(
                    (cb: UserCollectionBookmarkRecord) =>
                        // records that DO exist in initialCollectionBookmarks but NOT in collectionIds
                        !collectionIds.some(
                            (collectionId: number) =>
                                cb.collectionId === collectionId
                        )
                )
                .map((cb: UserCollectionBookmarkRecord) => cb.id!); // map to IDs to be passed to delete API

            return [recordsToCreate, idsToDelete];
        },
        [initialCollectionBookmarks]
    );

    const updateUserCollectionBookmarks = async (
        bookmarkId: number,
        collectionIds: Array<number>
    ) => {
        setSaving(true);

        let success: boolean;

        try {
            const [recordsToCreate, idsToDelete] = getRecordsToCreateAndDelete(
                bookmarkId,
                collectionIds
            );
            await Promise.all<any>([
                ...recordsToCreate.map((cb: UserCollectionBookmarkRecord) =>
                    createCollectionBookmarkApi(cb)
                ),
                ...idsToDelete.map((id: number) =>
                    deleteCollectionBookmarkApi(id)
                ),
            ]);
            handleSuccess();
            success = true;
        } catch (e) {
            handleError();
            success = false;
        }

        setSaving(false);
        return success;
    };

    return {
        updateUserCollectionBookmarks,
        saving,
    };
}
