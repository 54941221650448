import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { SkipNavContent } from "@reach/skip-nav";
import FreeAccessPublicationsAdminDashboardTable from "./free-access-publications-admin-dashboard-table";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import SearchForm from "molecules/forms/search-form";
import useAdminFreeAccessPublications from "./use-admin-free-access-publications";
import FreeAccessPublicationsEditModal from "./free-access-publications-edit-modal";
import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { AdminFreeAccessProvider } from "./admin-free-access-context";
import useAdminEditFreeAccessPublications from "./use-admin-edit-free-access-publications";
import useAdminToggleFreeAccessPublications from "./use-admin-toggle-free-access-publications";
import FreeAccessPublicationsAdminAddPublicationModal from "./free-access-publications-admin-add-publication-modal";
import { NestedRoutesProps } from "utilities/routing/nested-route";
import { FreeAccessPublicationsAdminUtils } from "pages/admin/free-access/free-access-publications-admin-utils";
import RouteTabs from "molecules/route-tabs/route-tabs";
export interface FreeAccessPublicationsAdminDashboardProps
    extends NestedRoutesProps {}

const CSS_CLASS_NAME = "c-enhanced-content-admin-dashboard";
const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

const FreeAccessPublicationsAdminDashboard: React.FC<any> = () => {
    return (
        <AdminFreeAccessProvider>
            <FreeAccessPublicationsAdminDashboardContent />
        </AdminFreeAccessProvider>
    );
};

const FreeAccessPublicationsAdminDashboardContent: React.FC<any> = (
    props: FreeAccessPublicationsAdminDashboardProps
) => {
    const {
        isLoading,
        publications,
        code,
        take,
        skip,
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        rowCount,
        getAdminFreeAccessPublications,
        setCode,
        resetPagination,
    } = useAdminFreeAccessPublications();

    const {
        onUpdateFreeAccessPublication,
        isEditModalOpen,
        onEditModalClose,
        onEditPublication,
        publicationToEdit,
    } = useAdminEditFreeAccessPublications();

    const refresh = () => {
        getAdminFreeAccessPublications(skip, take, code);
    };

    const { toggleIsPublished } = useAdminToggleFreeAccessPublications(refresh);

    const [showAddPublicationModal, setShowPublicationModal] = useState(false);

    const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        resetPagination();
        setCode(e.target.value);
    };
    const handleSearchTextClear = () => {
        resetPagination();
        setCode("");
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        resetPagination();
        getAdminFreeAccessPublications(0, take, code);
    };
    const openAddPublicationModal = () => {
        setShowPublicationModal(true);
    };
    const closeAddPublicationModal = () => {
        setShowPublicationModal(false);
    };

    return (
        <>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <Heading priority={HeadingPriority.Five}>
                            Free Access Publications Management
                        </Heading>
                        <RouteTabs
                            redirectIfNotFound={false}
                            routes={props.routes}
                            tabs={FreeAccessPublicationsAdminUtils.getTabs()}
                        />
                    </div>
                    <div
                        className={`${CSS_CLASS_NAME}__content c-enhanced-content-admin-tab-content`}>
                        <div
                            className={`${CSS_CLASS_NAME}__content__controls__container c-enhanced-content-admin-controls`}>
                            <SearchForm
                                onSubmit={handleSubmit}
                                onSearchClick={handleSubmit}
                                onSearchTextChange={handleSearchTextChange}
                                onClear={handleSearchTextClear}
                                placeholder={"Filter by publication number..."}
                                searchText={code}
                                cssClearButtonClassName={"-noDivider"}
                            />
                            <Button
                                accessibleText={"Add Admin"}
                                onClick={() => openAddPublicationModal()}
                                size={ButtonSizes.Small}
                                type={ButtonTypes.Button}>
                                Add Publication
                            </Button>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__content__data`}>
                            <div
                                className={`${CSS_CLASS_NAME}__content__data__table`}>
                                <FreeAccessPublicationsAdminDashboardTable
                                    loading={isLoading}
                                    freeAccessPublicationRecords={publications}
                                    onEditPublication={onEditPublication}
                                    toggleIsPublished={toggleIsPublished}
                                />
                                {isEditModalOpen && (
                                    <FreeAccessPublicationsEditModal
                                        publicationToEdit={publicationToEdit}
                                        isModalOpen={isEditModalOpen}
                                        freeAccessPublicationIsSaving={
                                            isLoading
                                        }
                                        onEditModalClose={onEditModalClose}
                                        onSavePublication={
                                            onUpdateFreeAccessPublication
                                        }
                                    />
                                )}
                            </div>
                            <PageNavigationMenu
                                currentPage={currentPage}
                                numberOfPages={numberOfPages}
                                onNavigateBack={onPageLast}
                                onNavigateForward={onPageNext}
                                onPageSizeChange={onPageSizeChange}
                                onSelectPage={onSelectPage}
                                pageSize={take}
                                pageSizes={DEFAULT_PAGE_SIZES}
                                resultTotal={rowCount}
                            />
                        </div>
                    </div>
                </div>
            </SkipNavContent>
            {showAddPublicationModal && (
                <FreeAccessPublicationsAdminAddPublicationModal
                    closeDialog={closeAddPublicationModal}
                />
            )}
        </>
    );
};

export default FreeAccessPublicationsAdminDashboard;
