import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";

interface AdminFreeAccess {
    publications: FreeAccessPublicationRecord[];
    setPublications: Dispatch<SetStateAction<FreeAccessPublicationRecord[]>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface AdminFreeAccessProviderProps {
    children: JSX.Element;
}

const defaultValues: AdminFreeAccess = {
    publications: [],
    setPublications: () => {},
    isLoading: false,
    setIsLoading: () => {},
};

export const AdminFreeAccessContext =
    createContext<AdminFreeAccess>(defaultValues);
export const AdminFreeAccessProvider = ({
    children,
}: AdminFreeAccessProviderProps) => {
    const [publications, setPublications] = useState(
        defaultValues.publications
    );
    const [isLoading, setIsLoading] = useState(false);
    return (
        <AdminFreeAccessContext.Provider
            value={{ publications, setPublications, isLoading, setIsLoading }}>
            {children}
        </AdminFreeAccessContext.Provider>
    );
};
