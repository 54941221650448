import React, { PropsWithChildren } from "react";

export enum LandingPageSectionColor {
    White = "-white",
    Blue = "-blue",
}

export enum LandingPageSectionIds {
    Plans = "plans",
    FAQ = "faq",
    Spotlights = "spotlights",
}

interface LandingPageSectionWrapperProps {
    id: LandingPageSectionIds;
    backgroundColor?: LandingPageSectionColor;
}

const BASE_CSS_CLASSNAME = "c-landing-page-section-wrapper";

const LandingPageSectionWrapper = (
    props: PropsWithChildren<LandingPageSectionWrapperProps>
) => {
    const {
        backgroundColor = LandingPageSectionColor.White,
        id,
        children,
    } = props;
    return (
        <section className={`${BASE_CSS_CLASSNAME} ${backgroundColor}`} id={id}>
            <div className={`${BASE_CSS_CLASSNAME}__content`}>{children}</div>
        </section>
    );
};

export default LandingPageSectionWrapper;
