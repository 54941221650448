/* eslint-disable-next-line no-restricted-imports */
import { LocalizationUtils as AndcultureCodeLocalizationUtils } from "andculturecode-javascript-core";
import CultureResources from "utilities/interfaces/culture-resources";
import LanguageCodeDisplayLabels, {
    LanguageCodeDisplayMap,
} from "utilities/enumerations/language-code-display-labels";
import StringUtils from "utilities/string-utils";
import { SupportedCultures } from "constants/supported-cultures";
import i18next from "i18next";

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

const LocalizationUtils = {
    ...AndcultureCodeLocalizationUtils,

    /**
     * Wrapper around the function from core to sanitize the value coming back and ensure it is
     * one of our supported cultures.
     */
    currentCultureCode(): string {
        const currentCulture =
            AndcultureCodeLocalizationUtils.currentCultureCode();

        const invalidCultureFormat =
            !StringUtils.isValidCultureCode(currentCulture);
        const unsupportedCulture = SupportedCultures.every(
            (supportedCulture: string) =>
                !StringUtils.isEqual(supportedCulture, currentCulture)
        );

        if (invalidCultureFormat || unsupportedCulture) {
            return LocalizationUtils.defaultCultureCode();
        }

        return currentCulture;
    },

    getLanguageCodeFullLabel(culture?: string): string {
        return getLanguageCodeToDisplayMap(culture)?.full ?? "";
    },

    getLanguageCodeShortLabel(culture?: string): string {
        return getLanguageCodeToDisplayMap(culture)?.short ?? "";
    },
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const getLanguageCodeToDisplayMap = (
    culture?: string
): LanguageCodeDisplayMap | undefined => {
    if (StringUtils.isEmpty(culture)) {
        return undefined;
    }

    const sanitizedCode = culture?.toUpperCase().replace("-", "_");

    return LanguageCodeDisplayLabels[
        sanitizedCode as keyof typeof LanguageCodeDisplayLabels
    ];
};

/**
 * Typed version of the `t` function from `andculturecode-javascript-core`
 */
const t = (key: keyof CultureResources, options?: object | undefined): string =>
    AndcultureCodeLocalizationUtils.t(key as string, options);

/**
 * Typed version of the fixed `t` function which returns translations for a specific culture
 * Defaults to english.
 */
const tForCulture = (
    culture: string | undefined,
    key: keyof CultureResources,
    options?: object | undefined
) => {
    const cultureOrDefault = StringUtils.hasValue(culture)
        ? culture
        : AndcultureCodeLocalizationUtils.defaultCultureCode();
    const cultureT = i18next.getFixedT(cultureOrDefault.toLowerCase());

    return cultureT(key, options);
};

/**
 * Helper for building strings with a container and action.
 *
 * @example
 * // Returns: "Add to favorites"
 * tActionContainer("favorite_plural")("addTo");
 */
const tActionForContainer =
    (container: keyof CultureResources) =>
    (key: keyof Pick<CultureResources, "addTo" | "removeFrom">) =>
        t(key, { container: t(container).toLowerCase() });

const tErrorLoading = (resource: keyof CultureResources): string => {
    const key: keyof CultureResources = resource.endsWith("_plural")
        ? "errors-actionResource_plural"
        : "errors-actionResource";

    return t(key, { action: t("loading"), resource: t(resource) });
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default LocalizationUtils;

export { t, tActionForContainer, tErrorLoading, tForCulture };

// #endregion Exports
