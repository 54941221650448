import Button, { ButtonTypes } from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import Tab from "atoms/tabs/tab";
import TabPanel from "atoms/tabs/tab-panel";
import FileRecord from "models/view-models/file-record";
import { ModalTypes } from "molecules/constants/modal-types";
import FileUploadField from "molecules/form-fields/file-upload-field";
import InputFormField from "molecules/form-fields/input-form-field";
import Form from "molecules/forms/form";
import Modal, { ModalProps } from "molecules/modals/modal";
import TabList from "molecules/tabs/tab-list";
import TabPanels from "molecules/tabs/tab-panels";
import Tabs from "molecules/tabs/tabs";
import React, { useEffect, useMemo, useState } from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import MimeTypes from "utilities/enumerations/mime-type";
import FileUtils from "utilities/file-utils";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextImageUploadModalProps
    extends Pick<ModalProps, "closeDialog" | "isVisible"> {
    baseClassName: string;
    onConfirm: (src: string, alt: string) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

enum ImageModalTabs {
    Upload = 0,
    Url = 1,
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextImageUploadModal: React.FC<RichTextImageUploadModalProps> = (
    props: RichTextImageUploadModalProps
) => {
    const { baseClassName, closeDialog, isVisible, onConfirm } = props;

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );
    const modalType = useMemo(
        () => (isMobile ? ModalTypes.Fullscreen : ModalTypes.Base),
        [isMobile]
    );

    const [altText, setAltText] = useState("");
    const [altTextError, setAltTextError] = useState("");
    const [file, setFile] = useState<FileRecord>();
    const [fileError, setFileError] = useState("");
    const [url, setUrl] = useState("");
    const [urlError, setUrlError] = useState("");

    const [currentTab, setCurrentTab] = useState(ImageModalTabs.Upload);
    const absoluteUrl = file?.presignedUrl ?? "";

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setUrl(e.target.value);

    const handleAltTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setAltText(e.target.value);

    const handleConfirm = () => {
        if (!validate()) {
            return;
        }

        if (currentTab === ImageModalTabs.Upload) {
            // Removing query string because it has an expiration
            // we're currently storing these images in a public bucket
            // so we don't need a signed URL
            onConfirm(RouteUtils.removeQueryString(absoluteUrl), altText);
            return;
        }

        onConfirm(url, altText);
    };

    const validate = () => {
        if (currentTab === ImageModalTabs.Upload) {
            const uploadValid = validateUpload();
            return uploadValid && validateAltText();
        }

        return validateUrl() && validateAltText();
    };

    const validateUrl = () => {
        if (StringUtils.isEmpty(url)) {
            setUrlError("URL is required.");
            return false;
        }

        if (!url.startsWith("http") && !url.startsWith("https")) {
            setUrlError(
                "URL must be absolute (must start with http:// or https://)."
            );
            return false;
        }

        if (!StringUtils.isValidUrl(url)) {
            setUrlError("Must be a valid URL.");
            return false;
        }

        return true;
    };

    const validateUpload = () => {
        if (file == null) {
            setFileError("File is required");
            return false;
        }

        return true;
    };

    const validateAltText = () => {
        if (StringUtils.isEmpty(altText)) {
            setAltTextError("Accessible text is required.");
            return false;
        }

        return true;
    };

    // reset state on modal close
    useEffect(() => {
        if (!isVisible) {
            setAltText("");
            setAltTextError("");
            setFile(undefined);
            setFileError("");
            setUrl("");
            setUrlError("");
            setCurrentTab(ImageModalTabs.Upload);
        }
    }, [isVisible]);

    const altTextInput = (
        <InputFormField
            errorMessage={altTextError}
            isValid={StringUtils.isEmpty(altTextError)}
            label="Accessible Text"
            maxLength={100}
            onChange={handleAltTextChange}
            placeholder="Image description..."
            required={true}
            value={altText}
        />
    );

    return (
        <Modal
            cssClassName={baseClassName}
            closeDialog={closeDialog}
            isVisible={isVisible}
            label={"Insert Image"}
            type={modalType}>
            <ModalCloseButton onClick={closeDialog} />
            <Tabs
                index={currentTab}
                onChange={(index: number) => setCurrentTab(index)}>
                <TabList>
                    <Tab>Upload</Tab>
                    <Tab>URL</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Form>
                            <FileUploadField
                                accept={MimeTypes.Image}
                                errorMessage={fileError}
                                fileUploadDestination={
                                    FileUploadDestination.RichText
                                }
                                helpText={"Max file size is 2 MB"}
                                isErrored={StringUtils.hasValue(fileError)}
                                label="Upload Image"
                                maxSize={FileUtils.megabytesToBytes(2)}
                                onFileChanged={setFile}
                                onUploadStart={() => setFileError("")}
                                required={true}
                                value={file}
                            />
                            {altTextInput}
                        </Form>
                    </TabPanel>
                    <TabPanel>
                        <Form>
                            <InputFormField
                                errorMessage={urlError}
                                isValid={StringUtils.isEmpty(urlError)}
                                label="Image URL"
                                maxLength={Infinity}
                                onChange={handleUrlChange}
                                placeholder={"https://link.nfpa.org"}
                                required={true}
                                showCharacterCount={false}
                                value={url}
                            />
                            {altTextInput}
                        </Form>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <div className={`${baseClassName}__footer`}>
                <Button
                    onClick={closeDialog}
                    style={ButtonStyles.Secondary}
                    type={ButtonTypes.Button}>
                    Cancel
                </Button>
                <Button onClick={handleConfirm} type={ButtonTypes.Button}>
                    Insert Image
                </Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextImageUploadModal;

// #endregion Exports
