import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import Icon from "atoms/icons/icon";
import { ContactFormSubmission } from "models/interfaces/contact-form-submission";
import { ContactFormSubmissionValidator } from "models/validation/contact-form-submission-validator";
import ContactFormSubmissionRecord from "models/view-models/contact-form-submission-record";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import SelectFormField from "molecules/form-fields/select-form-field";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import Form from "molecules/forms/form";
import Modal from "molecules/modals/modal";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { siteMap } from "internal-sitemap";
import { nameof } from "ts-simple-nameof";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import {
    ObjectValidationResult,
    ObjectValidator,
} from "utilities/validation/object-validator/object-validator";
import { v4 } from "uuid";
import useRecaptcha from "utilities/hooks/domain/user-settings/use-recaptcha";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContactModalProps {
    closeDialog: () => void;
    isVisible: boolean;
    returnURL?: string;
    selectedReasonOption?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-contact-modal";
const FORM_ID = `contact-form-${v4()}`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContactModal: React.FunctionComponent<ContactModalProps> = (props) => {
    const { closeDialog, isVisible, selectedReasonOption, returnURL } = props;
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const { t } = useLocalization<CultureResources>();
    const [formRecord, setFormRecord] = useState<ContactFormSubmissionRecord>(
        new ContactFormSubmissionRecord({
            email: currentIdentity?.user?.email ?? "",
            name: currentIdentity?.user?.getFirstAndLastName() ?? "",
            phone: currentIdentity?.user?.getPhoneOrMobileNumber() ?? "",
        })
    );
    const [errorResult, setErrorResult] = useState<
        ObjectValidationResult<ContactFormSubmission>
    >({});

    const formRef = useRef<HTMLFormElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);
    const relativeRetUrl = returnURL ?? siteMap.support.faq;
    const {
        siteKey,
        culture,
        captchaSettings,
        captchaComplete,
        handleRecaptchaChange,
    } = useRecaptcha();

    const handleFormSubmit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const isValid = handleSubmit(e);
        if (isValid) {
            formRef.current?.submit();
        }
    };
    const handleSubmit = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => {
        const formHasErrors = validate(formRecord, setErrorResult);
        if (formHasErrors) {
            e.preventDefault();
            modalContentRef.current?.scrollTo(0, 0);
            return false;
        }
        return true;
    };
    const onClose = () => {
        if (closeDialog != null) {
            closeDialog();
        }
    };

    const nameLabel = t("field-fullName");
    const namePlaceholder = StringUtils.capitalize(
        `${t("enterAField", {
            context: "possessive",
            fieldName: nameLabel,
        })}...`
    );
    const emailLabel = t("email");
    const emailPlaceholder = `${StringUtils.capitalize(
        t("enterAField", { context: "possessive", fieldName: t("field-email") })
    )}...`;

    const phoneLabel = t("phone");
    const phonePlaceholder = `${StringUtils.capitalize(
        t("enterAField", {
            context: "possessive",
            fieldName: t("field-phoneNumber"),
        })
    )}...`;

    const bothLabel = t("both");
    const noneLabel = t("none");
    const descriptionLabel = t("field-description");
    const descriptionPlaceholder = `${t("enterAField", {
        fieldName: descriptionLabel,
    })} ${t("field-optional")}...`;

    const billingLabel = t("contactReasons-billing");
    const feedbackLabel = t("contactReasons-feedback");
    const helpLabel = t("contactReasons-accountHelp");
    const modalHeading = t("contactModal-heading");
    const modifySubscriptionLabel = t("contactReasons-modifySubscription");
    const renewEnterpriseSubscriptionLabel = t(
        "contactReasons-renewEnterpriseSubscription"
    );
    const otherLabel = t("contactReasons-other");
    const suggestionLabel = t("contactReasons-suggestions");
    const technicalLabel = t("contactReasons-technical");
    const methodLabel = t("contactModal-preferredContact");
    const reasonLabel = t("contactModal-reasonLabel");

    const selectContactMethodOptions = [
        {
            data: "none",
            label: `--${noneLabel}--`,
            value: "none",
        },
        {
            data: "email",
            label: emailLabel,
            value: "email",
        },
        {
            data: "phone",
            label: phoneLabel,
            value: "phone",
        },
        {
            data: "both",
            label: bothLabel,
            value: "both",
        },
    ];

    const selectReasonOptions = [
        {
            data: "General feedback",
            label: feedbackLabel,
            value: "General feedback",
        },
        {
            data: "I need help with my account",
            label: helpLabel,
            value: "I need help with my account",
        },
        {
            data: "Pricing, billing, & licensing help",
            label: billingLabel,
            value: "Pricing, billing, & licensing help",
        },
        {
            data: "Modify my subscription",
            label: modifySubscriptionLabel,
            value: "Modify my subscription",
        },
        {
            data: "Renew Enterprise Subscription",
            label: renewEnterpriseSubscriptionLabel,
            value: "Renew Enterprise Subscription",
        },
        {
            data: "Technical issue",
            label: technicalLabel,
            value: "Technical issue",
        },
        {
            data: "Feature request or suggestion",
            label: suggestionLabel,
            value: "Feature request or suggestion",
        },
        {
            data: "Something else",
            label: otherLabel,
            value: "Something else",
        },
    ];

    const handleSelectOptionChange = (key: string, value: string | boolean) => {
        setFormRecord(formRecord.with({ [key]: value }));
    };

    const handleInputChange =
        (key: keyof ContactFormSubmission) =>
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setFormRecord(formRecord.with({ [key]: event.target.value }));

    const retUrl = RouteUtils.getUrl(
        `${
            globalState.systemSettings!.groupInvitiationsBaseUrl
        }${relativeRetUrl}`,
        undefined,
        {
            fromSalesforce: true,
            fromContactForm: true,
        }
    );

    if (selectedReasonOption) {
        const option = selectReasonOptions.filter(
            (o) => o.label === selectedReasonOption
        );
        const optionIndex = selectReasonOptions.indexOf(option[0]);
        selectReasonOptions.splice(optionIndex, 1);
        selectReasonOptions.unshift(...option);
    }

    useEffect(() => {
        if (captchaComplete) {
            setFormRecord(
                formRecord.with({ captchaComplete: captchaComplete })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [captchaComplete]);

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={modalHeading}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>{modalHeading}</h2>
                <Button
                    accessibleText={t("close")}
                    cssClassName={"-modal-close -icon"}
                    onClick={onClose}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.Tertiary}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div
                className={`${CSS_BASE_CLASS_NAME}__content`}
                ref={modalContentRef}>
                {/* Salesforce debugging information in salesforce.md */}
                <Form
                    ref={formRef}
                    action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                    id={FORM_ID}
                    method="POST"
                    onSubmit={(e) => handleSubmit(e)}>
                    <div
                        className={`${CSS_BASE_CLASS_NAME}__container -condensed-bottom`}>
                        {/* <input
                            type="hidden"
                            name="captcha_settings"
                            value={JSON.stringify(captchaSettings)}
                        /> */}
                        <input
                            type="hidden"
                            name="orgid"
                            value={captchaSettings.orgId}
                        />
                        <input type="hidden" name="retURL" value={retUrl} />
                        <div className="-hidden">
                            <label htmlFor="subject">Subject</label>
                            <input
                                onChange={function () {}}
                                id="subject"
                                maxLength={80}
                                name="subject"
                                type="text"
                                value="COD Support Request"
                            />
                            <label htmlFor="recordType">Case Record Type</label>
                            <select id="recordType" name="recordType">
                                <option value="01250000000QxNB">
                                    CSS Record Type
                                </option>
                            </select>
                            UseWebToCaseTrigger:
                            <input
                                id="00N50000002y48J"
                                name="00N50000002y48J"
                                type="checkbox"
                                value="1"
                                onChange={function () {}}
                                checked
                            />
                        </div>
                        <div
                            className={CSS_BASE_CLASS_NAME + "__content__name"}>
                            <InputFormField
                                errorMessage={getErrors(errorResult, "name")}
                                fieldId="name"
                                isValid={getErrors(errorResult, "name") == null}
                                label={nameLabel}
                                maxLength={40}
                                name="name"
                                placeholder={namePlaceholder}
                                onChange={handleInputChange("name")}
                                required={true}
                                showCharacterCount={false}
                                value={formRecord.name}
                            />
                        </div>
                        <InputFormField
                            errorMessage={getErrors(errorResult, "email")}
                            fieldId="email"
                            inputTestId="email"
                            isValid={getErrors(errorResult, "email") == null}
                            label={t("field-email")}
                            maxLength={80}
                            name="email"
                            placeholder={emailPlaceholder}
                            onChange={handleInputChange("email")}
                            required={true}
                            showCharacterCount={false}
                            type={InputTypes.Email}
                            value={formRecord.email}
                        />
                        <InputFormField
                            errorMessage={getErrors(errorResult, "phone")}
                            inputTestId="phone"
                            isValid={getErrors(errorResult, "phone") == null}
                            label={phoneLabel}
                            fieldId="phone"
                            maxLength={40}
                            name="phone"
                            placeholder={phonePlaceholder}
                            onChange={handleInputChange("phone")}
                            required={true}
                            showCharacterCount={false}
                            type={InputTypes.Phone}
                            value={formRecord.phone}
                        />
                        <SelectFormField
                            id="00N50000002uJ0R"
                            isValid={true}
                            fieldId="preferred_contact_method"
                            label={methodLabel}
                            name="00N50000002uJ0R"
                            onChange={(e) =>
                                handleSelectOptionChange(
                                    nameof<ContactFormSubmission>(
                                        (e) => e.contactMethod
                                    ),
                                    e!.value
                                )
                            }
                            options={selectContactMethodOptions}
                            value={formRecord.contactMethod}
                        />
                        <SelectFormField
                            id="00N1T00000AK8zZ"
                            isValid={true}
                            fieldId="reason_for_reaching_out"
                            name="00N1T00000AK8zZ"
                            label={reasonLabel}
                            onChange={(e) =>
                                handleSelectOptionChange(
                                    nameof<ContactFormSubmission>(
                                        (e) => e.issue
                                    ),
                                    e!.value
                                )
                            }
                            options={selectReasonOptions}
                            value={formRecord.issue}
                        />
                        <TextAreaFormField
                            errorMessage={""}
                            inputTestId="description"
                            isValid={true}
                            label={descriptionLabel}
                            fieldId="description"
                            placeholder={descriptionPlaceholder}
                            name="description"
                            onChange={handleInputChange("description")}
                            required={false}
                            rows={6}
                            value={formRecord.description}
                        />
                    </div>
                </Form>
                <div className={`${CSS_BASE_CLASS_NAME}__container -condensed`}>
                    <ReCAPTCHA
                        hl={culture}
                        sitekey={siteKey}
                        onChange={handleRecaptchaChange}
                    />
                    <div className={`${CSS_BASE_CLASS_NAME}__captcha-errors`}>
                        {getErrors(errorResult, "captchaComplete") != null && (
                            <React.Fragment>
                                <label>
                                    <Icon type={Icons.Information} />
                                    {t("propertyIsRequired", {
                                        name: "reCAPTCHA",
                                    })}
                                </label>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                    <div className={`${CSS_BASE_CLASS_NAME}__container`}>
                        <Button onClick={handleFormSubmit}>
                            {t("submit")}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Validation
// -----------------------------------------------------------------------------------------

const getErrors = (
    errorResult: ObjectValidationResult<ContactFormSubmission>,
    key: keyof ContactFormSubmission
): string | undefined => {
    const hasErrors = ObjectValidator.hasErrorsFor(key, errorResult);
    return hasErrors ? errorResult[key]?.join(",") : undefined;
};

const validate = (
    form: ContactFormSubmissionRecord,
    setErrorResult: React.Dispatch<
        React.SetStateAction<ObjectValidationResult<ContactFormSubmission>>
    >
) => {
    const validateResult = new ContactFormSubmissionValidator().validate(form);
    setErrorResult(validateResult);
    return ObjectValidator.hasErrors(validateResult);
};

// #endregion Validation

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default ContactModal;

// #endregion Export
