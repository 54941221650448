import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import FileRecord from "models/view-models/file-record";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import HotSpotImage from "molecules/situational-navigation/hotspot-image";
import AdminHotSpotSection from "organisms/admin/situational-navigation/admin-hot-spot-section";
import AdminHotSpotUploadModal from "organisms/admin/situational-navigation/situations/admin-hotspot-upload-modal";
import React, { useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminSituationHotSpotImageProps {
    hotSpotImageFile?: FileRecord;
    loadingFile: boolean;
    onImageFileSelected: (file?: FileRecord) => void;
    hotSpots: Array<SituationRelationshipRecord>;
    onHotSpotsChanged: (
        newHotSpots: Array<SituationRelationshipRecord>
    ) => void;
    situations: Array<SituationRecord>;
    solutions: Array<SolutionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminSituationHotSpotImage: React.FC<AdminSituationHotSpotImageProps> = (
    props: AdminSituationHotSpotImageProps
) => {
    const CSS_CLASS_NAME = "c-admin-situation-hotspot-image";

    const [modalFile, setModalFile] = useState<FileRecord>();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

    const handleFileModalConfirm = () => {
        props.onImageFileSelected(modalFile);
        setModalFile(undefined);
        setUploadModalOpen(false);
    };

    const handleFileModalCancel = () => {
        setModalFile(undefined);
        setUploadModalOpen(false);
    };

    const handleImageDeleted = () => {
        setDeleteConfirmModalOpen(false);

        if (props.hotSpotImageFile == null) {
            return;
        }

        props.onHotSpotsChanged(
            props.hotSpots.map((s: SituationRelationshipRecord) =>
                s.with({
                    hotSpotXDraft: undefined,
                    hotSpotYDraft: undefined,
                })
            )
        );

        props.onImageFileSelected(undefined);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            {props.hotSpotImageFile != null && !props.loadingFile && (
                <div className={`${CSS_CLASS_NAME}__image-container`}>
                    <div className={`${CSS_CLASS_NAME}__image-container__top`}>
                        <label>Hot Spot Diagram</label>
                        <Button
                            accessibleText="Remove Image"
                            type={ButtonTypes.Button}
                            cssClassName={`${CSS_CLASS_NAME}__image-container__top__delete-btn`}
                            onClick={() => setDeleteConfirmModalOpen(true)}
                            size={ButtonSizes.XSmall}>
                            <Icon type={Icons.Trashcan} />
                        </Button>
                    </div>
                    <div
                        className={`${CSS_CLASS_NAME}__image-container__hotspot`}>
                        <AdminHotSpotSection
                            cssClassName={`${CSS_CLASS_NAME}__image-container__hotspot__map`}
                            hotSpots={props.hotSpots}
                            onChange={props.onHotSpotsChanged}
                            situations={props.situations}
                            solutions={props.solutions}>
                            <HotSpotImage
                                hotSpotImageFile={props.hotSpotImageFile}
                            />
                        </AdminHotSpotSection>
                    </div>
                </div>
            )}
            {props.hotSpotImageFile == null && !props.loadingFile && (
                <div className={`${CSS_CLASS_NAME}__placeholder-container`}>
                    <label>Hot Spot Diagram</label>
                    <div
                        className={`${CSS_CLASS_NAME}__placeholder-container__placeholder`}
                        onClick={() => setUploadModalOpen(true)}>
                        <div
                            className={`${CSS_CLASS_NAME}__placeholder-container__placeholder__contents`}>
                            <Icon
                                type={Icons.Document}
                                size={IconSizes.Large}
                            />
                            <Paragraph>Click to Upload an Image</Paragraph>
                        </div>
                    </div>
                </div>
            )}
            {props.loadingFile && (
                <Loader accessibleText="Loading hot spot image..." />
            )}
            <AdminHotSpotUploadModal
                baseClassName={CSS_CLASS_NAME}
                closeDialog={() => setUploadModalOpen(false)}
                isVisible={uploadModalOpen}
                onCancel={handleFileModalCancel}
                onConfirm={handleFileModalConfirm}
                onFileChanged={setModalFile}
                value={modalFile}
            />
            <ConfirmationModal
                isVisible={deleteConfirmModalOpen}
                message="Are you sure you want to delete the hot spot image? This will also remove any hot spots."
                onCancel={() => setDeleteConfirmModalOpen(false)}
                onConfirm={handleImageDeleted}
                confirmButtonText="Yes, Delete"
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Export
// -------------------------------------------------------------------------------------------------

export default AdminSituationHotSpotImage;

// #endregion Export
