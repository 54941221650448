import { ChapterParams } from "interfaces/routing/chapter-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { PublicationParams } from "interfaces/routing/publication-params";
import PublicationContentRedirect from "organisms/publication-content/legacy-redirects/publication-content-redirect";
import React from "react";
import { useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import usePublicationQuery from "utilities/hooks/domain/publications/use-publication-query";
import useSection from "utilities/hooks/domain/publications/use-section";
import useChapterQuery from "utilities/hooks/queries/publications/chapters/use-chapter-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useLoading from "utilities/hooks/use-loading";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SectionRedirectProps {}

interface RouteParams
    extends Pick<NestedPublicationParams, "publicationId">,
        Pick<PublicationParams, "id">,
        Pick<ChapterParams, "chapterId"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionRedirect: React.FC<SectionRedirectProps> = (
    props: SectionRedirectProps
) => {
    const {
        publicationId,
        chapterId,
        id: sectionId,
    } = useParams<RouteParams>();

    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: chapter,
        loaded: chapterLoaded,
        loading: chapterLoading,
    } = useChapterQuery({
        id: chapterId,
    });
    const {
        resultObject: section,
        loaded: sectionLoaded,
        loading: sectionLoading,
    } = useSection(publicationId, sectionId);
    const {
        resultObject: publication,
        loaded: publicationLoaded,
        loading: publicationLoading,
    } = usePublicationQuery({
        id: NumberUtils.parseInt(publicationId),
        isAdmin: isAdminPreview,
    });

    const loading = useLoading(
        chapterLoading,
        sectionLoading,
        publicationLoading
    );
    const loaded = chapterLoaded && sectionLoaded && publicationLoaded;
    const route = RouteUtils.getUrl(siteMap.publications.permalinks.section, {
        code: publication.code,
        edition: publication.edition,
        chapterNfpaLabel: chapter.nfpaLabel,
        externalId: section.externalId,
    });

    return (
        <PublicationContentRedirect
            entities={[publication, chapter, section]}
            loaded={loaded}
            loading={loading}
            route={route}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SectionRedirect;

// #endregion Exports
