import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import OfflineBookUrlsRecord from "models/view-models/offline/offline-book-urls-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineBookUrlsBasePathParams {
    offlineBookId: number;
    offlineDeviceId: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint =
    "offlinedevices/:offlineDeviceId/books/:offlineBookId/urls";
const resourceType = OfflineBookUrlsRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const OfflineBookUrlsService = {
    useGet: ServiceHookFactory.useGet<
        OfflineBookUrlsRecord,
        OfflineBookUrlsBasePathParams
    >(resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        OfflineBookUrlsRecord,
        OfflineBookUrlsBasePathParams
    >(baseEndpoint, resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default OfflineBookUrlsService;

// #endregion Exports
