import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Loader from "atoms/loaders/loader";
import Heading from "atoms/typography/heading";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";
import Bookmarkable from "models/interfaces/bookmarkable";
import IndicatorIcons from "organisms/indicator-buttons/indicator-icons";
import TiaRecord from "models/view-models/tia-record";
import PublicationRecord from "models/view-models/publication-record";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentPanel from "organisms/enhanced-content/enhanced-content-panel";
import { List } from "immutable";
import SectionRecord from "models/view-models/section-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContentPreviewProps<T>
    extends Pick<DataTestAttributes, "dataTestId"> {
    isLoading: boolean;
    publicationDisplayTitle: string;
    content: T;
    subContent?: T[];
    tias: TiaRecord[];
    publication: PublicationRecord | undefined;
    code?: string;
    edition?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-content-preview";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContentPreview = <T extends Bookmarkable>(
    props: PropsWithChildren<ContentPreviewProps<T>>
): ReactElement | null => {
    const {
        dataTestId,
        isLoading,
        publicationDisplayTitle,
        content,
        subContent,
        tias,
        code,
        edition,
    } = props;

    const [sectionEnhancedContent, setSectionEnhancedContent] =
        useState<EnhancedContentRecord[]>();

    const { getEnhancedContentForReferencePanelSection } = useEnhancedContent(
        {}
    );

    // Convert content to sections to be typed properly
    const section = content as unknown as SectionRecord;
    const subSections = subContent as unknown as SectionRecord[];

    const updateEnhancedContent = async () => {
        if (code && edition && section.externalId) {
            let allExternalIds = [section.externalId];
            if (subSections) {
                subSections.forEach((subsection) =>
                    allExternalIds.push(subsection.externalId)
                );
            }
            const response = await getEnhancedContentForReferencePanelSection(
                code,
                edition,
                allExternalIds
            );
            if (response && response.length) {
                setSectionEnhancedContent(response);
            }
        }
    };

    const mainSectionEnhancedContent = sectionEnhancedContent?.find(
        (enhancedContent) => enhancedContent.externalId === section.externalId
    );
    const findSubsectionEnhancedContent = (subContentToMatch: T) => {
        const subSectionToMatch = subContentToMatch as unknown as SectionRecord;
        return sectionEnhancedContent?.find(
            (enhancedContent) =>
                enhancedContent.externalId === subSectionToMatch.externalId
        );
    };

    useEffect(() => {
        if (isLoading) return;
        updateEnhancedContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, edition, content, isLoading]);

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <div
            className={`${CSS_BASE_CLASS_NAME}__body`}
            data-test-id={dataTestId}
            tabIndex={0}>
            <Heading priority={HeadingPriority.Six}>
                {publicationDisplayTitle}
            </Heading>
            <Heading
                cssClassName={`${CSS_BASE_CLASS_NAME}__body__content-title`}
                priority={HeadingPriority.Five}>
                <IndicatorIcons
                    changes={content.changes}
                    tias={tias}
                    handleDiffPanelOpen={() => {}}
                    handleTiaClick={() => {}}
                    notClickable={true}
                />
                {content?.getFullyQualifiedDisplayTitle()}
            </Heading>

            {
                // if
                isLoading && <Loader />
            }

            {
                // if
                StringUtils.hasValue(content?.body) && !isLoading && (
                    <div>{content?.getBody()}</div>
                )
            }

            {mainSectionEnhancedContent != null && (
                <EnhancedContentPanel
                    enhancedContent={mainSectionEnhancedContent}
                    resources={
                        mainSectionEnhancedContent?.resources
                            ? List(mainSectionEnhancedContent.resources)
                            : List([])
                    }
                    resourcesLoading={false}
                />
            )}

            {
                // if
                CollectionUtils.hasValues(subContent) &&
                    !isLoading &&
                    subContent.map((subContent: T, index: number) => (
                        <React.Fragment key={index}>
                            {subContent.isDeleted() ? (
                                <IndicatorIcons
                                    changes={subContent.changes}
                                    tias={[]}
                                    handleDiffPanelOpen={() => {}}
                                    handleTiaClick={() => {}}
                                    notClickable={true}
                                />
                            ) : (
                                <React.Fragment>
                                    <Heading
                                        cssClassName={`${CSS_BASE_CLASS_NAME}__body__sub-content-title`}
                                        priority={HeadingPriority.Five}>
                                        <IndicatorIcons
                                            changes={subContent.changes}
                                            tias={[]}
                                            handleDiffPanelOpen={() => {}}
                                            handleTiaClick={() => {}}
                                            notClickable={true}
                                        />
                                        {subContent?.getDisplayTitle()}
                                    </Heading>
                                    {
                                        // if
                                        StringUtils.hasValue(
                                            subContent?.body
                                        ) && <div>{subContent?.getBody()}</div>
                                    }
                                    {findSubsectionEnhancedContent(
                                        subContent
                                    ) != null && (
                                        <EnhancedContentPanel
                                            enhancedContent={
                                                findSubsectionEnhancedContent(
                                                    subContent
                                                )!
                                            }
                                            resources={
                                                findSubsectionEnhancedContent(
                                                    subContent
                                                )?.resources
                                                    ? List(
                                                          findSubsectionEnhancedContent(
                                                              subContent
                                                          )!.resources!
                                                      )
                                                    : List([])
                                            }
                                            resourcesLoaded={true}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ))
            }
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ContentPreview;

// #endregion Exports
