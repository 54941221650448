/* eslint-disable-next-line no-restricted-imports */
import { useLocalization as useAndcultureCodeLocalization } from "andculturecode-javascript-react";
import CultureResources from "utilities/interfaces/culture-resources";
import { tErrorLoading } from "utilities/localization-utils";

export const useLocalization = <
    TKeys extends CultureResources = CultureResources
>() => {
    const { t } = useAndcultureCodeLocalization<TKeys>();
    return { t, tErrorLoading };
};
