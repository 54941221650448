import StringUtils from "utilities/string-utils";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal, { ModalProps } from "molecules/modals/modal";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AlertModalProps
    extends Pick<ModalProps, "isVisible" | "closeDialog" | "label"> {
    children: any;
    heading: string;
    buttonText?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-alert-modal";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AlertModal: React.FunctionComponent<AlertModalProps> = (
    props: AlertModalProps
) => {
    const { children, closeDialog, heading, isVisible, label, buttonText } =
        props;
    const closeButtonText = StringUtils.isEmpty(buttonText)
        ? "Close"
        : buttonText;

    return (
        <Modal
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={label}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <Heading priority={2}>{heading}</Heading>
                <Button
                    accessibleText={closeButtonText}
                    cssClassName="-modal-close -icon"
                    onClick={closeDialog}
                    size={ButtonSizes.Medium}
                    style={ButtonStyles.TertiaryAlt}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>{children}</div>
            <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                <Button onClick={closeDialog} style={ButtonStyles.Primary}>
                    {closeButtonText}
                </Button>
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default AlertModal;

// #endregion Export
