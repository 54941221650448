import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameWithHtml } from "constants/app-name-tm";
import React from "react";
import { t } from "utilities/localization-utils";

const ThankYouSignUp = () => {
    return (
        <div>
            <Heading priority={HeadingPriority.Two}>
                {t("on-demand-thank-you")}
            </Heading>
            <Paragraph>
                {t("on-demand-sign-up-1")}&nbsp;
                <span
                    dangerouslySetInnerHTML={{
                        __html: AppNameWithHtml,
                    }}
                />
                &nbsp;{t("on-demand-receive-email-2")}
            </Paragraph>
            <Paragraph>{t("on-demand-contact-admin")}</Paragraph>
        </div>
    );
};

export default ThankYouSignUp;
