import { ServiceResponse } from "andculturecode-javascript-core";
import { List } from "immutable";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import RichTextArea from "molecules/rich-text-area/rich-text-area";
import EnhancedContentPanelFooter from "organisms/enhanced-content/enhanced-content-panel-footer";
import EnhancedContentPanelResources from "organisms/enhanced-content/enhanced-content-panel-resources";
import React, { useMemo } from "react";
import { BrowserUtils } from "utilities/browser-utils";
import { v4 } from "uuid";
import { CollectionUtils } from "utilities/collection-utils";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EnhancedContentDetailProps {
    accordionContentId: string;
    cssBaseClassName: string;
    enhancedContent: EnhancedContentRecord;
    hasAdminAccess?: boolean;
    isPreview?: boolean;
    panelIsCollapsed: boolean;
    resources?: List<EnhancedContentResourceRecord>;
    resourcesLoading?: boolean;
    onDeleteConfirm?: (
        enhancedContent: EnhancedContentRecord
    ) => Promise<ServiceResponse<Boolean>>;
    onEditClick?: () => void;
    onSuccess?: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentDetail: React.FunctionComponent<
    EnhancedContentDetailProps
> = (props: EnhancedContentDetailProps) => {
    const {
        accordionContentId,
        enhancedContent,
        isPreview,
        hasAdminAccess,
        onDeleteConfirm,
        onEditClick,
        onSuccess,
        panelIsCollapsed,
        resources,
    } = props;
    const buttonId = useMemo(() => v4(), []);
    const BASE_CLASS_NAME = props.cssBaseClassName;

    const { isOnline } = useNetworkInformation();

    return (
        <div
            aria-hidden={panelIsCollapsed}
            aria-labelledby={buttonId}
            className={`${BASE_CLASS_NAME}__accordion`}
            role="region"
            id={accordionContentId}>
            <div className={`${BASE_CLASS_NAME}__content`}>
                <RichTextArea
                    content={enhancedContent.getBodyContentBasedOnRole(
                        hasAdminAccess!
                    )}
                />
            </div>

            {CollectionUtils.hasValues(resources) && isOnline && (
                <EnhancedContentPanelResources
                    cssClassName={`${BASE_CLASS_NAME}__resources`}
                    disableTooltip={BrowserUtils.isTouchscreenDevice()}
                    hasAdminAccess={hasAdminAccess}
                    resources={resources?.toArray() ?? []}
                />
            )}

            {hasAdminAccess && (
                <EnhancedContentPanelFooter
                    enhancedContent={enhancedContent}
                    isPreview={isPreview}
                    onDeleteConfirm={onDeleteConfirm}
                    onEditClick={onEditClick}
                    onSuccess={onSuccess}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default React.memo(EnhancedContentDetail);

// #endregion Exports
