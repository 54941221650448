import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { SelectOption } from "atoms/forms/select";
import _ from "lodash";
import PublicationRecord from "models/view-models/publication-record";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import PublicationFilterModal from "organisms/modals/search-modal/filters/publication-filter-modal";
import SearchModalFilterSection from "organisms/modals/search-modal/filters/search-modal-filter-section";
import { SearchModalFiltersContainerProps } from "organisms/modals/search-modal/filters/search-modal-filters-container";
import { SearchScope } from "organisms/modals/search-modal/filters/search-modal-scope-filter";
import { SearchModalResultType } from "organisms/modals/search-modal/utils/search-modal-reducer";
import React, { useMemo } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import SelectOptionUtils from "utilities/select-option-utils";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalPublicationFilterProps
    extends Pick<
        SearchModalFiltersContainerProps,
        | "favoritePublications"
        | "onSelectedPublicationsUpdated"
        | "publications"
        | "searchResultType"
        | "selectedPublications"
    > {
    searchScope: SearchScope;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-search-modal-filters";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalPublicationFilter: React.FC<SearchModalPublicationFilterProps> =
    React.memo((props: SearchModalPublicationFilterProps) => {
        const {
            favoritePublications = [],
            onSelectedPublicationsUpdated,
            publications = [],
            searchResultType,
            searchScope,
            selectedPublications = [],
        } = props;

        const {
            handleClose: handleClosePublicationFilterModal,
            handleOpen: handleOpenPublicationFilterModal,
            isOpen: isPublicationFilterModalOpen,
        } = useModalActions();

        const { t } = useLocalization();

        const publicationToSelectOption = (publication: PublicationRecord) => ({
            data: publication,
            label: publication.getDisplayCodeEditionAndTitle(),
            value: publication.id!,
        });

        const selectOptions = useMemo(
            () =>
                SelectOptionUtils.map(publications, publicationToSelectOption),
            [publications]
        );

        const defaultPublications = useMemo(
            () =>
                CollectionUtils.hasValues(favoritePublications)
                    ? favoritePublications
                    : _.take(publications, 5),
            [favoritePublications, publications]
        );

        const defaultOptionsFilter = (
            option: SelectOption<PublicationRecord, number>
        ) => defaultPublications?.some((fv) => fv.id === option.value) ?? false;

        const nonDefaultOptionsFilter = (
            option: SelectOption<PublicationRecord, number>
        ) =>
            (!defaultPublications?.some((fv) => fv.id === option.value) ??
                false) &&
            (selectedPublications?.some((sp) => sp === option.value) ?? false);

        const selectedNonDefaults = _.differenceWith(
            selectedPublications,
            defaultPublications,
            (selectedPublicationId, favoritedPublication) =>
                favoritedPublication.id === selectedPublicationId
        );

        const handleFavoritesUpdated = (
            selectedOptions: Array<SelectOption<PublicationRecord, number>>
        ) =>
            onSelectedPublicationsUpdated(
                SelectOptionUtils.getValues(selectedOptions)
            );

        const publicationText = StringUtils.appendCountLabel(
            "publication",
            selectedPublications
        );
        const yourFavoritesText = t("yourItem", { item: t("favorite_plural") });
        const viewAllText = t("viewItem", {
            item: StringUtils.capitalize(t("all")),
        });

        return (
            <SearchModalFilterSection
                isVisible={
                    searchResultType === SearchModalResultType.Codes &&
                    searchScope === SearchScope.SelectPublications
                }
                panelText={publicationText}
                panelTriggerAriaText={publicationText}>
                {CollectionUtils.hasValues(favoritePublications) && (
                    <span className="-favorites-label">
                        {yourFavoritesText}
                    </span>
                )}
                <CheckboxList<PublicationRecord, number>
                    filter={defaultOptionsFilter}
                    onChange={handleFavoritesUpdated}
                    options={selectOptions}
                    values={selectedPublications}
                />
                {CollectionUtils.hasValues(selectedNonDefaults) && (
                    <CheckboxList<PublicationRecord, number>
                        filter={nonDefaultOptionsFilter}
                        onChange={handleFavoritesUpdated}
                        options={selectOptions}
                        values={selectedPublications}
                    />
                )}
                <div className={`${BASE_CLASS}__filter-action`}>
                    <Button
                        cssClassName="-link"
                        onClick={handleOpenPublicationFilterModal}
                        size={ButtonSizes.Small}>
                        {viewAllText}
                    </Button>
                </div>
                {isPublicationFilterModalOpen && (
                    <PublicationFilterModal
                        closeDialog={handleClosePublicationFilterModal}
                        isVisible={isPublicationFilterModalOpen}
                        onSelection={onSelectedPublicationsUpdated}
                        selected={selectedPublications}
                    />
                )}
            </SearchModalFilterSection>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalPublicationFilter;

// #endregion Exports
