import { PagedQuery } from "andculturecode-javascript-core";
import { ServiceFactory } from "andculturecode-javascript-react";
import SupportUserRecord from "models/view-models/support-user-record";
import SupportUserSubscriptionRecord from "models/view-models/support-user-subscription-record";
import SearchQuery from "utilities/interfaces/search-query";

export interface SupportUserListQueryParams extends SearchQuery, PagedQuery {}

export interface SupportUserResourcePathParams {
    id: number;
}

const baseEndpoint = "admin/support/users";
const resourceEndpoint = `${baseEndpoint}/:id`;

const resourceType = SupportUserRecord;
const resourceTypeTwo = SupportUserSubscriptionRecord;

const SupportUserService = {
    get: ServiceFactory.get<SupportUserRecord, SupportUserResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    list: ServiceFactory.list<SupportUserRecord, SupportUserListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    update: ServiceFactory.update<
        SupportUserSubscriptionRecord,
        SupportUserResourcePathParams
    >(resourceTypeTwo, resourceEndpoint),
};

export default SupportUserService;
