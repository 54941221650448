import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import { RouteUtils } from "utilities/route-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -----------------------------------------------------------------------------------------
// #region Custom Hook
// -----------------------------------------------------------------------------------------

function useCultureCodeRoute() {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { pathname } = useLocation();
    const { globalState } = useGlobalState();

    // #endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Functions
    // -----------------------------------------------------------------------------------------

    const generateCultureCodedRoute = useCallback(
        (route: string) => {
            return RouteUtils.generateCultureCodedRoute(
                route,
                pathname,
                globalState.getPreferredOrCurrentCulture()
            );
        },
        [globalState, pathname]
    );

    // #endregion Functions

    return {
        generateCultureCodedRoute,
    };
}

// #endregion Custom Hook

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default useCultureCodeRoute;

// #endregion Exports
