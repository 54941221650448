import localforage from "localforage";
import Hit from "models/interfaces/search/hit";
import { CacheStorageNames } from "utilities/service-worker/constants/cache-storage-names";
import StorageError, {
    StorageErrorKeys,
} from "utilities/service-worker/storage/storage-error";
import { SearchStatus } from "utilities/service-worker/interfaces/search-status";
import { isImmutable } from "immutable";
import { CacheStorageKeys } from "utilities/service-worker/constants/cache-storage-keys";
import OfflineBook from "models/interfaces/offline/offline-book";
import OfflineDevice from "models/interfaces/offline/offline-device";
import { Metadata } from "utilities/service-worker/interfaces/metadata";
import StorageValue from "models/interfaces/storage-value";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type Iteratee<TStorageValue, TMappedValue> = (
    value: TStorageValue,
    key: string,
    iterationNumber: number
) => TMappedValue | Array<TMappedValue>;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface StorageProvider<T> {
    /**
     * Reads from a cache storage implementation by providing the key
     */
    read(key: string): Promise<T | null>;

    /**
     * Removes a value from the storage provider by key
     */
    remove(key: string): Promise<void>;

    /**
     * Writes a value to the cache storage implementation based on the provided key
     */
    write(key: string, storageValue: T): Promise<T>;

    /**
     * Iterates through each record in the cache storage, apply the provided transform and act as a
     * flatMap for transforms that already return an array
     */
    iterate<TStorageValue, TMappedValue>(
        iteratee: Iteratee<TStorageValue, TMappedValue>
    ): Promise<Array<TMappedValue>>;
}

export interface StorageProviderForKey<T>
    extends Pick<StorageProvider<T>, "iterate"> {
    /**
     * Reads from a cache storage implementation
     */
    read(): Promise<T | null>;

    /**
     * Removes value from storage provider
     */
    remove(): Promise<void>;

    /**
     * Writes a value to the cache storage implementation
     */
    write(storageValue: T): Promise<T>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Factory
// -----------------------------------------------------------------------------------------

const create = <T>(name: string): StorageProvider<T> => {
    const localForageInstance = localforage.createInstance({
        name,
    });

    return {
        iterate<T, U>(iteratee: Iteratee<T, U>) {
            return iterateLocalForage(localForageInstance, iteratee);
        },

        read(key: string) {
            return readLocalForage(localForageInstance, key);
        },

        remove(key: string) {
            return removeLocalForage(localForageInstance, key);
        },

        write<T>(key: string, storageValue: T) {
            return writeLocalForage(localForageInstance, key, storageValue);
        },
    };
};

const createForKey = <T>(
    name: string,
    key: string
): StorageProviderForKey<T> => {
    const base = create<T>(name);

    return {
        iterate: base.iterate,
        read: () => base.read(key),
        remove: () => base.remove(key),
        write: (storageValue: T) => base.write(key, storageValue),
    };
};

const StorageProviderFactory = {
    api: () => create<Array<StorageValue>>(CacheStorageNames.Api),
    assets: () => create<Array<StorageValue>>(CacheStorageNames.Assets),
    create: create,
    createForKey: createForKey,
    metadata: () => create<Array<Metadata>>(CacheStorageNames.Metadata),
    offlineBooks: () =>
        createForKey<Array<OfflineBook>>(
            CacheStorageNames.Offline,
            CacheStorageKeys.OfflineBooks
        ),
    offlineDevice: () =>
        createForKey<OfflineDevice>(
            CacheStorageNames.Offline,
            CacheStorageKeys.OfflineDevice
        ),
    offlineDevices: () =>
        createForKey<Array<OfflineDevice>>(
            CacheStorageNames.Offline,
            CacheStorageKeys.OfflineDevices
        ),
    searchDocuments: () =>
        createForKey<Array<Hit>>(
            CacheStorageNames.Search,
            CacheStorageKeys.SearchIndexDocuments
        ),
    searchStatus: () =>
        createForKey<SearchStatus>(
            CacheStorageNames.Search,
            CacheStorageKeys.SearchIndexStatus
        ),
};

// #endregion Factory

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const iterateLocalForage = async <T, U>(
    localForageInstance: LocalForage,
    iteratee: (value: T, key: string, iterationNumber: number) => U | Array<U>
): Promise<Array<U>> => {
    let items: Array<U> = [];

    await localForageInstance.iterate(
        (value: T, key: string, iterationNumber: number) => {
            const result = iteratee(value, key, iterationNumber);
            const results = Array.isArray(result) ? result : [result];

            items.push(...results);
        }
    );

    return items;
};

const readLocalForage = async <T>(
    localForageInstance: LocalForage,
    key: string
): Promise<T | null> => {
    try {
        return await localForageInstance.getItem<T>(key);
    } catch (error) {
        console.error("storage-provider-factory#readLocalForage", error);

        throw new StorageError(
            StorageErrorKeys.STORAGE_READ,
            "There was an error reading from the local cache instance",
            error
        );
    }
};

const removeLocalForage = async (
    localForageInstance: LocalForage,
    key: string
): Promise<void> => {
    try {
        return await localForageInstance.removeItem(key);
    } catch (error) {
        console.error("storage-provider-factory#removeLocalForage", error);

        throw new StorageError(
            StorageErrorKeys.STORAGE_REMOVE,
            "There was an error removing item from the local cache instance",
            error
        );
    }
};

const writeLocalForage = async <T>(
    localForageInstance: LocalForage,
    key: string,
    storageValue: T
): Promise<T> => {
    try {
        let value = storageValue;
        if (isImmutable(value)) {
            value = value.toJS() as T;
        }
        return await localForageInstance.setItem<T>(key, value);
    } catch (error) {
        console.error("storage-provider-factory#writeLocalForage", error);

        const isQuotaError =
            error instanceof DOMException &&
            error.name === "QuotaExceededError";
        const errorKey = isQuotaError
            ? StorageErrorKeys.STORAGE_QUOTA_EXCEEDED
            : StorageErrorKeys.STORAGE_WRITE;

        throw new StorageError(
            errorKey,
            "There was an error writing a value to the local cache instance",
            error
        );
    }
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default StorageProviderFactory;

// #endregion Exports
