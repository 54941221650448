import TableRecord from "models/view-models/table-record";
import ServiceFactory from "../service-factory";
import ServiceHookFactory from "../service-hook-factory";

export interface TableBasePathParams {
    publicationId: number;
}

export interface TableExternalIdPathParams extends TableBasePathParams {
    externalId: string;
}

export interface TableQueryParams {
    code: string;
    edition: string;
}

const baseEndpoint = "publications/:publicationId/tables";
const resourceEndpoint = `${baseEndpoint}/externalId/:externalId`;
const resourceType = TableRecord;

const TableService = {
    key: "table",
    get: ServiceFactory.get<TableRecord, TableExternalIdPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetByExternalId: ServiceHookFactory.useGet<
        TableRecord,
        TableExternalIdPathParams,
        TableQueryParams
    >(resourceType, resourceEndpoint),
};

export default TableService;
