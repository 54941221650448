import { Record } from "immutable";
import type Book from "models/interfaces/book";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: Book = RecordUtils.auditableDefaultValuesFactory<Book>({
    code: "",
    edition: "",
    isFeatured: false,
    locale: undefined,
});

export default class BookRecord extends Record(defaultValues) implements Book {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Book) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Book>} values
     */
    public with(values: Partial<Book>): BookRecord {
        return new BookRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
