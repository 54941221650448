import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";
import UserCollectionRecord from "models/view-models/user-collection-record";
import UserCollectionListItem from "organisms/my-link/user-collections/user-collection-list-item";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserCollectionListProps {
    userCollections: UserCollectionRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------
const BASE_CLASS_NAME = "c-user-collection-list";

const UserCollectionList: React.FC<UserCollectionListProps> = (
    props: UserCollectionListProps
) => {
    const { userCollections } = props;

    const { t } = useLocalization<CultureResources>();

    const privateHeading = t("my-collections");
    const sharedHeading = t("shared-collections");

    const privateCollections = userCollections
        .filter((u) => u.shareType === UserCollectionShareType.User)
        .sort((a, b) => a.name.localeCompare(b.name));

    const sharedCollections = userCollections
        .filter(
            (u) =>
                u.shareType === UserCollectionShareType.Team ||
                u.shareType === UserCollectionShareType.WorkGroup
        )
        .sort((a, b) => a.name.localeCompare(b.name));

    const showPrivateCollections = privateCollections.length > 0;
    const showSharedCollections = sharedCollections.length > 0;

    return (
        <div className={BASE_CLASS_NAME}>
            {showPrivateCollections && (
                <>
                    <Heading priority={HeadingPriority.Six}>
                        {privateHeading}
                    </Heading>
                    <div className={`${BASE_CLASS_NAME}__rows`}>
                        {privateCollections.map((u: UserCollectionRecord) => (
                            <UserCollectionListItem
                                key={u.id}
                                userCollectionRecord={u}
                            />
                        ))}
                    </div>
                </>
            )}

            {showSharedCollections && (
                <>
                    <Heading priority={HeadingPriority.Six}>
                        {sharedHeading}
                    </Heading>
                    <div className={`${BASE_CLASS_NAME}__rows`}>
                        {sharedCollections.map((u: UserCollectionRecord) => (
                            <UserCollectionListItem
                                key={u.id}
                                userCollectionRecord={u}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserCollectionList;

// #endregion Exports
