import { RecordUtils } from "andculturecode-javascript-core";
import PublicationTypes from "constants/publication-types";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { Record } from "immutable";
import type Section from "models/interfaces/section";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import { PartRecord } from "internal";
import PublicationAnchorRecord from "models/view-models/publication-anchor-record";
import PublicationRecord from "models/view-models/publication-record";
import { PublicationSidebarNavItem } from "molecules/sidebar-chapter-navigation/publication-sidebar-nav-link";
import ExceptionSection from "organisms/section-detail-components/exception-section";
import FigureSection from "organisms/section-detail-components/figure-section";
import InformationalNoteSection from "organisms/section-detail-components/informational-note-section";
import type { ReactNode } from "react";
import { siteMap } from "internal-sitemap";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";
import XmlUtils from "utilities/xml-utils";
import Bookmarkable from "models/interfaces/bookmarkable";
import CaapsRecordUtils from "../../utilities/caaps-record-utils";
import EmptySectionConverter from "atoms/containers/empty-section-converter";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultValues: Section =
    RecordUtils.auditableDefaultValuesFactory<Section>({
        annex: undefined,
        annexId: undefined,
        article: undefined,
        articleId: undefined,
        body: undefined,
        bodyAsPlainText: "",
        changes: undefined,
        chapter: undefined,
        chapterId: undefined,
        displaySequence: undefined,
        externalId: "",
        groupBy: "",
        label: undefined,
        nfpaLabel: undefined,
        parentId: undefined,
        part: undefined,
        partId: undefined,
        publication: undefined,
        publicationId: undefined,
        rootSectionId: undefined,
        subSections: undefined,
        tiaChanges: undefined,
        title: undefined,
        titleAsPlainText: "",
        type: undefined,
    });

// #endregion Constants

export default class SectionRecord
    extends Record(defaultValues)
    implements Section, Bookmarkable
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Section) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.publication != null) {
            params.publication = RecordUtils.ensureRecord(
                params.publication,
                PublicationRecord
            );
        }

        if (params.article != null) {
            params.article = RecordUtils.ensureRecord(
                params.article,
                ArticleRecord
            );
        }

        if (params.annex != null) {
            params.annex = RecordUtils.ensureRecord(params.annex, AnnexRecord);
        }

        if (params.chapter != null) {
            params.chapter = RecordUtils.ensureRecord(
                params.chapter,
                ChapterRecord
            );
        }

        if (params.part != null) {
            params.part = RecordUtils.ensureRecord(params.part, PartRecord);
        }

        if (params.subSections != null) {
            params.subSections = params.subSections?.map(
                (subSection: SectionRecord) =>
                    RecordUtils.ensureRecord(subSection, SectionRecord)
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getChangesClassModifier(): string {
        if (StringUtils.hasValue(this.changes)) {
            return `-changes-${this.changes}`;
        }

        return "";
    }

    /**
     * Get the application route to view the current section in Book View
     */
    public getRoute(
        publicationType?: PublicationTypes,
        useAdminPreview: boolean = false,
        includeHash: boolean = true
    ): string {
        if (this.annexId == null && this.chapterId == null) {
            return this.getHashLink();
        }

        if (this.publicationId == null) {
            return siteMap.errors.notFound;
        }

        const model = PublicationAnchorRecord.fromSectionRecord(
            this,
            useAdminPreview,
            publicationType
        );

        return model.getRoute(includeHash) ?? "#";
    }

    // Returns the body (if it exists) as empty sections with externalIds (for lazy-rendering bookview)
    public getEmptySections(debug?: boolean) {
        if (this.body == null) {
            return "";
        }
        return XmlUtils.convert(
            this.body,
            { "*": XmlUtils.xmlConverter(EmptySectionConverter) },
            debug
        );
    }

    /**
     * Returns the body (if it exists) as React components. See `xml-utils.ts` for more info.
     *
     * If no body is present, returns an empty string.
     */
    public getBody(debug?: boolean): ReactNode {
        // If we have no body, just return an empty string.
        if (this.body == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
            ...XmlUtils.publicationTableConverters,
            div: XmlUtils.xmlConverter(XmlUtils.FragmentWrapper),
            exception: XmlUtils.xmlConverter(ExceptionSection),
            note: XmlUtils.xmlConverter(InformationalNoteSection),
            fig: XmlUtils.xmlConverter(FigureSection),
        };

        return XmlUtils.convert(this.body, xmlConverters, debug);
    }

    /**
     * Gets the 'display' title of the Section in the format of: {Label} {Title}
     * For use in context of parent and child sections,(i.e Book View)
     * @example (B) Raceways over 100mm (4 in.) Wide But Not over 200 mm (8 in.) Wide
     */
    public getDisplayTitle(): string {
        if (StringUtils.isEmpty(this.label)) {
            return this.getTitleAsPlainText();
        }

        const title = this.getTitleAsPlainText();
        if (title.includes(this.getLabel())) {
            return title;
        }

        return `${this.label} ${this.getTitleAsPlainText()}`;
    }

    /**
     * Get the title of the section including the fully qualified Section Label.
     * @example 390.15(B) Raceways over 100mm (4 in.) Wide But Not over 200 mm (8 in.) Wide
     */
    public getFullyQualifiedDisplayTitle(): string {
        if (StringUtils.isEmpty(this.nfpaLabel)) {
            return this.getDisplayTitle();
        }

        // If the label is already in the title, strip it out
        const title = this.getTitleAsPlainText().replace(this.getLabel(), "");
        return `${this.nfpaLabel} ${title}`;
    }

    /**
     * Returns an anchor href string to an element in the DOM representing this Section.
     */
    public getHashLink(): string {
        return `#${this.externalId}`;
    }

    /**
     * Returns the label as a string (defaults to empty string if label is undefined/null)
     */
    public getLabel(): string {
        return this.hasLabel() ? this.label! : "";
    }

    /**
     * Returns the partId as a number (defaults to 0 if partId is undefined/null)
     */
    public getPartId(): number {
        return this.hasPartId() ? this.partId! : 0;
    }

    /**
     * Returns the title stripped of XML.
     *
     * @returns
     */
    public getTitleAsPlainText(): string {
        if (StringUtils.isEmpty(this.titleAsPlainText)) {
            return "";
        }

        return StringUtils.translateDomainTerms(this.titleAsPlainText!);
    }

    /**
     * Returns the title as React components (Usually only text, but <inline> elements can exist for changes).
     */
    public getTitle(debug?: boolean): ReactNode {
        // If we have no title, just return an empty string.
        if (this.title == null) {
            return "";
        }

        // Otherwise, converts the XML to React components using default and custom converters.
        const xmlConverters = {
            ...XmlUtils.defaultConverters,
            ...XmlUtils.publicationInlineConverters,
        };

        return XmlUtils.convert(this.title!, xmlConverters, debug);
    }

    public isPersisted(): boolean {
        return (this.id ?? 0) > 0;
    }

    public isDeleted(): boolean {
        return this.changes === XmlChangeNotationConstants.DELETION;
    }

    /**
     * Returns whether or not the record has a non-null, non-whitespace label value
     */
    public hasLabel(): boolean {
        return StringUtils.hasValue(this.label);
    }

    /**
     * Returns whether or not the record has a non-null, valid parentId value
     */
    public hasParentId(): boolean {
        return this.parentId != null && this.parentId > 0;
    }

    /**
     * Returns whether or not the record has a non-null, valid partId value
     */
    public hasPartId(): boolean {
        return this.partId != null && this.partId > 0;
    }

    /**
     * @returns Whether or not the record title or body XML has changes.
     * Uses XmlUtils.hasAttributeDeep to recursively check every element in the XML tree.
     */
    public doesTitleOrBodyHaveChanges(): boolean {
        const hasChanges = CaapsRecordUtils().doesTitleOrBodyHaveChanges(
            this.title,
            this.body,
            this.changes
        );

        return hasChanges;
    }

    /**
     * Transforms this Section into an object representing the PublicationSidebarNavItem interface
     *
     * Useful when mapping out a set of SectionRecords to nav items.
     *
     * @param {Partial<PublicationSidebarNavItem>} [values] (Optional) Values to override the default transformation
     * @param {PublicationComponentType} publicationType
     */
    public toNavItem(
        values?: Partial<PublicationSidebarNavItem>,
        publicationType?: PublicationTypes,
        isAdminPreview: boolean = false
    ): PublicationSidebarNavItem {
        const label = this.getLabel();
        const title = StringUtils.translateDomainTerms(
            this.getTitleAsPlainText().replace(label, "")
        );

        const defaultNavItem: PublicationSidebarNavItem = {
            dataTestDisplaySequence: this.displaySequence,
            id: this.id!,
            isDeleted: this.changes === XmlChangeNotationConstants.DELETION,
            label: `${label} `,
            route: this.getNavItemRoute(publicationType, isAdminPreview),
            singleLine: true,
            title: title,
            type: PublicationComponentType.Section,
            useRelativePath: false,
            searchForLinkOnPage: true,
        };

        return Object.assign(defaultNavItem, values);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Section>} values
     */
    public with(values: Partial<Section>): SectionRecord {
        return new SectionRecord(Object.assign(this.toJS(), values));
    }

    public withAnnex(annexes: Array<AnnexRecord>): SectionRecord {
        const annex = annexes.find(
            (a: AnnexRecord) => this.annexId != null && this.annexId === a.id
        );

        return this.with({ annex });
    }

    public withArticle(articles: Array<ArticleRecord>): SectionRecord {
        const article = articles.find(
            (a: ArticleRecord) =>
                this.articleId != null && this.articleId === a.id
        );

        return this.with({ article });
    }

    public withChapter(chapters: Array<ChapterRecord>): SectionRecord {
        const chapter = chapters.find(
            (c: ChapterRecord) =>
                this.chapterId != null && this.chapterId === c.id
        );

        return this.with({ chapter });
    }

    public withPart(parts: Array<PartRecord>): SectionRecord {
        const part = parts.find(
            (p: PartRecord) => this.partId != null && this.partId === p.id
        );

        return this.with({ part });
    }

    public withPublication(
        publications: Array<PublicationRecord>
    ): SectionRecord {
        const publication = publications.find(
            (p: PublicationRecord) =>
                this.publicationId != null && this.publicationId === p.id
        );

        return this.with({ publication });
    }

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Private Methods
    // -----------------------------------------------------------------------------------------

    private getAnnexGroupUrl() {
        return RouteUtils.getUrl(
            siteMap.publications.deprecated.annexGroup,
            {
                publicationId: this.publicationId,
                annexId: this.annexId,
                id: this.groupBy,
            },
            null,
            this.getHashLink()
        );
    }

    private getAnnexUrl() {
        return RouteUtils.getUrl(
            siteMap.publications.deprecated.annex,
            {
                publicationId: this.publicationId,
                id: this.annexId,
            },
            null,
            this.getHashLink()
        );
    }

    private getChapterUrl() {
        return RouteUtils.getUrl(
            siteMap.publications.deprecated.chapter,
            {
                publicationId: this.publicationId,
                id: this.chapterId,
            },
            null,
            this.getHashLink()
        );
    }

    private getChapterArticleUrl() {
        return RouteUtils.getUrl(
            siteMap.publications.deprecated.article,
            {
                publicationId: this.publicationId,
                chapterId: this.chapterId,
                id: this.articleId,
            },
            null,
            this.getHashLink()
        );
    }

    private getChapterSectionUrl(hashLink?: string) {
        const sectionId = this.rootSectionId ?? this.id;

        return RouteUtils.getUrl(
            siteMap.publications.deprecated.section,
            {
                publicationId: this.publicationId,
                chapterId: this.chapterId,
                id: sectionId,
            },
            null,
            hashLink
        );
    }

    private getNavItemRoute(
        publicationType?: PublicationTypes,
        isAdminPreview: boolean = false
    ) {
        if (publicationType === PublicationTypes.NFC) {
            return this.getHashLink();
        }

        return this.getRoute(publicationType, isAdminPreview);
    }
    // #endregion Private Methods
}
