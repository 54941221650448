import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import TableDisplayRecord from "models/view-models/table-display-record";
import React from "react";
import useRemoveWhitespaceChildren from "utilities/hooks/use-remove-whitespace-children";

interface TableCellProps extends XmlConvertedComponentProps {
    colspan: number;
    rowspan: number;
    width: string;
    dataLabel: string;
}

const TableCell: React.FunctionComponent<TableCellProps> = (props) => {
    const {
        children,
        colspan,
        rowspan,
        codStyle,
        width,
        diffchanged,
        dataLabel,
    } = props;

    const filteredChildren = useRemoveWhitespaceChildren(children);
    const table = new TableDisplayRecord({ codStyle, width });
    if (diffchanged === XmlChangeNotationConstants.DELETION) {
        return null;
    }

    return (
        <td
            style={table.getStyle()}
            colSpan={colspan}
            rowSpan={rowspan}
            data-label={dataLabel}>
            {filteredChildren}
        </td>
    );
};

export default TableCell;
