import Loader from "atoms/loaders/loader";
import SubscriptionDetailCard from "molecules/subscription-detail-card/subscription-detail-card";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useSubscription from "utilities/hooks/domain/subscriptions/use-subscription";
import SubscriptionChoiceCard from "organisms/subscription-card/subscription-choice-card";
import SubscriptionType from "organisms/enums/subscription-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountSubscriptionTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__subscription";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountSubscriptionTabPanel: React.FC<AccountSubscriptionTabPanelProps> =
    () => {
        const { globalState } = useGlobalState();
        const currentUserRole =
            globalState?.currentIdentity?.getCurrentUserRole();
        const teamName = currentUserRole?.userRoleGroup?.group?.name;
        const isIndividual = currentUserRole?.isIndividualRole() ?? false;
        const isFreeTrial = currentUserRole?.isFreeTrialRole() ?? false;
        const isEnterprise = currentUserRole?.isEnterpriseRole() ?? false;
        const isTeam = currentUserRole?.isTeamRole() ?? false;
        const [isLoading, setIsLoading] = useState(true);

        const location = useLocation();
        const isFromContactForm =
            location.search.indexOf("fromContactForm") > 0;

        const { resultObject: subscription, loading: subscriptionLoading } =
            useSubscription(currentUserRole?.subscriptionId);

        const canCancelSubscription = !(
            isFreeTrial ||
            isEnterprise ||
            (isTeam && !currentUserRole?.userRoleGroup?.isAdmin)
        );

        const canModifySubscription =
            isIndividual ||
            ((isEnterprise || isTeam) &&
                currentUserRole?.userRoleGroup?.isAdmin === true);

        useEffect(() => {
            if (currentUserRole?.role == null || subscriptionLoading) {
                return;
            }

            setIsLoading(false);
        }, [currentUserRole, globalState, subscriptionLoading]);

        if (
            globalState.currentIdentity != null &&
            !globalState.currentIdentity.getCurrentUserRole()?.isSubscription()
        ) {
            return <Redirect to={siteMap.account.information} />;
        }

        if (isLoading) {
            return <Loader />;
        }

        return (
            <div className={BASE_CLASS}>
                <SubscriptionDetailCard
                    canCancelSubscription={canCancelSubscription}
                    canModifySubscription={canModifySubscription}
                    isFreeTrial={isFreeTrial}
                    isFromContactForm={isFromContactForm}
                    subscription={subscription}
                    teamName={teamName}
                />
                {isFreeTrial && (
                    <SubscriptionChoiceCard
                        subscriptionType={SubscriptionType.Individual}
                    />
                )}
            </div>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountSubscriptionTabPanel;

// #endregion Exports
