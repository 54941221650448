import React from "react";
import RadioList, {
    RadioMarkerPosition,
    RadioListStyles,
} from "molecules/radio-lists/radio-list";
import RadioButton from "atoms/forms/radio-button-input";
import LocalizationUtils from "utilities/localization-utils";
import { SupportedCultures } from "constants/supported-cultures";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface CultureRadioListProps {
    onCheck: (language: string) => void;
    value?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CultureRadioList: React.FunctionComponent<CultureRadioListProps> = (
    props: CultureRadioListProps
) => {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    const { onCheck, value } = props;

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Returns
    // -----------------------------------------------------------------------------------------

    return (
        <RadioList
            markerPosition={RadioMarkerPosition.Left}
            style={RadioListStyles.None}
            items={SupportedCultures.map((culture: string) => (
                <RadioButton
                    checked={value === culture}
                    label={_getLabel(culture)}
                    name="culture-options"
                    onCheck={() => onCheck(culture)}
                    value={culture}
                />
            ))}
        />
    );

    // #endregion Returns
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const _getLabel = (language: string) =>
    LocalizationUtils.getLanguageCodeFullLabel(language);

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default CultureRadioList;

// #endregion Exports
