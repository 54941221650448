import { useContext } from "react";
import { WindowContext } from "./window-context-provider";

const useWindowContext = () => {
    const { height, width } = useContext(WindowContext);

    return { height, width };
};

export default useWindowContext;
