import publicationRecord from "models/view-models/publication-record";
import { siteMap } from "internal-sitemap";
import { CreativeWork, WithContext } from "schema-dts";
import { useParams } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import useOffersStructuredData from "./use-offers-structured-data";

type PublicationStructuredData = WithContext<CreativeWork>;

export default function usePublicationStructuredData(
    publication: publicationRecord
): string {
    const { code, edition } = useParams<any>();

    const publicationStructuredData: PublicationStructuredData = {
        "@context": "https://schema.org",
        "@type": "CreativeWork",
        "@id": `${window.location.origin}${RouteUtils.getUrl(
            siteMap.publications.permalinks.publication,
            { code, edition }
        )}`,
        url: `${window.location.origin}${RouteUtils.getUrl(
            siteMap.publications.permalinks.publication,
            { code, edition }
        )}`,
        name: publication.getDisplayTitle(),
        author: {
            "@type": "Organization",
            name: "NFPA",
        },
        potentialAction: {
            "@type": "ReadAction",
            target: {
                "@type": "EntryPoint",
                url: `${window.location.origin}${RouteUtils.getUrl(
                    siteMap.publications.permalinks.publication,
                    { code, edition }
                )}`,
                actionPlatform: [
                    "https://schema.org/DesktopWebPlatform",
                    "https://schema.org/AndroidPlatform",
                    "https://schema.org/IOSPlatform",
                ],
            },
            expectsAcceptanceOf: useOffersStructuredData(),
        },
    };

    return JSON.stringify(publicationStructuredData);
}
