import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface RecentBookmarkAnchorProps {
    bookmark: UserBookmarkRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-recent-bookmark-anchor";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RecentBookmarkAnchor: React.FunctionComponent<RecentBookmarkAnchorProps> =
    (props: RecentBookmarkAnchorProps) => {
        const { bookmark } = props;

        return (
            <div className={BASE_CLASS_NAME}>
                <Anchor to={bookmark.getSectionRoute()}>
                    <Icon
                        cssClassName={bookmark.getColorClassModifier()}
                        type={Icons.Bookmark}
                    />
                    <div className={`${BASE_CLASS_NAME}__content`}>
                        <span className={`${BASE_CLASS_NAME}__content__title`}>
                            {bookmark.getTruncatedTitle()}
                        </span>
                        <span className={`${BASE_CLASS_NAME}__content__date`}>
                            {bookmark.getModifiedDateFromNowText()}
                        </span>
                    </div>
                </Anchor>
            </div>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default RecentBookmarkAnchor;

// #endregion Export
