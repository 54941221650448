import type TopicRecord from "models/view-models/topic-record";
import { useCallback, useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import TopicService from "utilities/services/topics/topic-service";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseTopicsResult extends ServiceHookResult<TopicRecord[]> {
    /**
     * Function for the consumer to call to trigger a refresh of the data gathered by this hook
     */
    refresh: () => void;
}

// #endregion Interfaces

/**
 * Custom hook for retrieving Topics
 */
export default function useTopics(): UseTopicsResult {
    const [topics, setTopics] = useState<TopicRecord[]>([]);
    const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { list: listTopicsApi } = TopicService.useList();
    const { handlePageLoadError, pageErrors: errors } = usePageErrors();
    const refresh = useCallback(() => {
        setLoaded(false);
        setLoading(false);
        setRefreshFlag((refreshFlag: boolean) => !refreshFlag);
    }, [setRefreshFlag]);

    useEffect(() => {
        if (loading || loaded) {
            return;
        }

        const listTopics = async () => {
            try {
                setLoading(true);
                const response = await listTopicsApi();
                setTopics(response.resultObjects!);
            } catch (error) {
                handlePageLoadError(error);
            }

            setLoaded(true);
            setLoading(false);
        };

        listTopics();
    }, [handlePageLoadError, listTopicsApi, loaded, loading, refreshFlag]);

    return {
        errors,
        loaded,
        loading,
        refresh,
        resultObject: topics,
    };
}
