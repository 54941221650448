import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React, { useEffect } from "react";
import useAzure from "./use-azure";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

const AzureRedirectPage = () => {
    const { login } = useAzure();
    const { globalState } = useGlobalState();

    useEffect(() => {
        if (globalState.callbackLink) {
            console.info("Callback link", globalState.callbackLink);
            login();
        }
    }, [globalState.callbackLink, login]);
    return <FullScreenTransition transitionText={""} />;
};

export default AzureRedirectPage;
