import React from "react";
import {
    /* eslint-disable-next-line no-restricted-imports */
    Trans as I18NextTrans,
    TransProps as I18NextTransProps,
} from "react-i18next";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TransProps extends I18NextTransProps {
    i18nKey: keyof CultureResources;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

/**
 * Typed wrapper around `Trans` component from react-i18next
 */
const Trans: React.FunctionComponent<TransProps> = (props: TransProps) => (
    <I18NextTrans {...props} />
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default Trans;

// #endregion Export
