import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import SectionContentPreview from "molecules/preview/section-content-preview";
import Anchor from "atoms/anchors/anchor";
import React from "react";
import { useHistory } from "react-router-dom";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import usePublicationRoute from "utilities/hooks/domain/publications/use-publication-route";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import ReferencePanelDropdownMenu from "molecules/reference-panel/reference-panel-dropdown-menu";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import PageNavigation from "molecules/page-navigation/page-navigation";
import useReferencePanelPagination from "utilities/hooks/domain/reference-panel/use-reference-panel-pagination";
import { GoogleAnalyticsIds } from "constants/google-analytics-ids";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ReferencePanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-reference-panel";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReferencePanel: React.FC<ReferencePanelProps> = (
    props: ReferencePanelProps
) => {
    const removeText = t("remove");
    const referencePanelEmptyReferences = t("reference-panel-empty-references");
    const referencePanelEmptyActionText = t(
        "reference-panel-empty-action-text",
        {
            action: t("reference-panel-add-to"),
        }
    );

    const closeButtonText = t("reference-panel-menu", { action: t("close") });

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    const history = useHistory();
    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const {
        currentSection,
        currentUserReferenceId,
        isEmpty,
        loaded,
        loading,
        handleRemove,
        handleSelect,
        handleToggle,
        userReferences,
    } = useReferencePanelContext();

    const pageNavigationProps = useReferencePanelPagination(
        currentUserReferenceId,
        handleSelect,
        userReferences
    );

    const { isAdminPreview } = useAdminPreview();

    const { resultObject: route } = usePublicationRoute({
        annexId: currentSection?.annexId,
        articleId: currentSection?.articleId,
        chapterId: currentSection?.chapterId,
        publicationId: currentSection?.publicationId,
        sectionId: currentSection?.id,
        isAdminPreview,
    });

    const goToItemAnchorClassNames = ["c-button", "-small", "-secondary"];

    const routeNotFound = StringUtils.isEmpty(route);
    if (routeNotFound) {
        goToItemAnchorClassNames.push("-disabled");
    }

    const handleDelete = () => {
        handleRemove(currentUserReferenceId);
    };

    const handleGoToReferenceLink = async (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (event == null) {
            return;
        }

        event.preventDefault();

        if (routeNotFound) {
            return;
        }

        setTimeout(() => {
            history.push(route);
        }, 0);

        // When viewing on a mobile device, "Go to Item" and close the reference panel.
        if (isMobile) {
            handleToggle();
        }
    };

    const { currentPage, numberOfPages } = pageNavigationProps;
    const getTitleCounter = () =>
        numberOfPages > 0 ? `${currentPage}/${numberOfPages}` : "";
    const titleText =
        numberOfPages === 0 || numberOfPages > 1
            ? t("reference_plural")
            : t("reference");
    const headerText = `${titleText} ${getTitleCounter()}`;

    return (
        <div className={BASE_CLASS_NAME} data-test-id={"reference-panel"}>
            <div className={`${BASE_CLASS_NAME}__header`}>
                <span>{headerText}</span>
                <Button
                    accessibleText={closeButtonText}
                    onClick={handleToggle}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.Tertiary}>
                    <Icon size={IconSizes.Medium} type={Icons.Close} />
                </Button>
            </div>
            {loading && (
                <div className={`${BASE_CLASS_NAME}__container -empty`}>
                    <Loader />
                </div>
            )}
            {loaded && isEmpty && (
                <div className={`${BASE_CLASS_NAME}__container -empty`}>
                    <React.Fragment>
                        <div>
                            <Icon
                                type={Icons.ReferencePanel}
                                size={IconSizes.XLarge}></Icon>
                        </div>
                        <Paragraph>{referencePanelEmptyReferences}</Paragraph>
                        <Paragraph>{referencePanelEmptyActionText}</Paragraph>
                    </React.Fragment>
                </div>
            )}
            {loaded && !isEmpty && (
                <div className={`${BASE_CLASS_NAME}__container`}>
                    <SectionContentPreview
                        dataTestId={"reference-panel-section-content-preview"}
                        isLoading={false} // We only show the preview if we have the data.  No loading state is needed here.
                        publication={currentSection?.publication!}
                        section={currentSection!}
                        subSections={currentSection?.subSections}
                    />
                </div>
            )}
            {(loading || !isEmpty) && (
                <div className={`${BASE_CLASS_NAME}__footer`}>
                    <div className={`${BASE_CLASS_NAME}__footer-actions`}>
                        <div
                            className={`${BASE_CLASS_NAME}__footer-actions__left`}>
                            <Button
                                accessibleText={removeText}
                                disabled={isEmpty || loading}
                                id={
                                    GoogleAnalyticsIds.REFERENCE_PANEL_DELETE_BUTTON
                                }
                                onClick={handleDelete}
                                size={ButtonSizes.XSmall}
                                style={ButtonStyles.Tertiary}>
                                <Icon type={Icons.Trashcan} />
                            </Button>
                            <Anchor
                                cssClassName={goToItemAnchorClassNames.join(
                                    " "
                                )}
                                id={
                                    GoogleAnalyticsIds.REFERENCE_PANEL_GO_TO_LINK
                                }
                                onClick={handleGoToReferenceLink}
                                to={route}>
                                {t("goToItem")}
                            </Anchor>
                        </div>
                        <div
                            className={`${BASE_CLASS_NAME}__footer-actions__right`}>
                            <ReferencePanelDropdownMenu />
                            <PageNavigation {...pageNavigationProps} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ReferencePanel;

// #endregion Exports
