import { StyleConfigList, ToolbarConfig } from "react-rte";

/**
 * this file is based on https://github.com/sstur/react-rte/blob/master/src/lib/EditorToolbarConfig.js
 * so that we can have a typed version of the default config
 */

const INLINE_STYLE_BUTTONS: StyleConfigList = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
];

const BLOCK_TYPE_DROPDOWN: StyleConfigList = [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
    { label: "Code Block", style: "code-block" },
];
const BLOCK_TYPE_BUTTONS: StyleConfigList = [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
];

const DefaultEditorToolbarConfig: ToolbarConfig = {
    display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "IMAGE_BUTTON",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS,
    BLOCK_TYPE_DROPDOWN,
    BLOCK_TYPE_BUTTONS,
};

export default DefaultEditorToolbarConfig;
