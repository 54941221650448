import CheckboxInput from "atoms/forms/checkbox-input";
import Paragraph from "atoms/typography/paragraph";
import React from "react";
import StringUtils from "utilities/string-utils";
import { t } from "utilities/localization-utils";
import { AppNameWithHtml } from "constants/app-name-tm";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FreeTrialTermsBoxProps {
    accepted: boolean;
    error?: string;
    onAcceptChange?: (accepted: boolean) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-free-trial-terms";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FreeTrialTermsBox: React.FC<FreeTrialTermsBoxProps> = (
    props: FreeTrialTermsBoxProps
) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onAcceptChange?.(e.target.checked);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <label>{t("freeTrialAgreementLabel")}</label>
            <Paragraph
                dangerouslySetInnerHTML={{
                    __html: t("freeTrialTerms", {
                        appNameWithHtml: AppNameWithHtml,
                    }),
                }}
            />
            <CheckboxInput
                checked={props.accepted}
                label={t("freeTrialAgreementCheckBoxLabel")}
                onChange={handleChange}
            />
            {StringUtils.hasValue(props.error) && (
                <div className={`${CSS_CLASS_NAME}__error`}>
                    <span>{props.error}</span>
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default FreeTrialTermsBox;

// #endregion Exports
