import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import TrainingContract from "models/interfaces/training-contract";

const defautValues: TrainingContract =
    RecordUtils.auditableDefaultValuesFactory<TrainingContract>({
        accessCode: undefined,
        subscriptionDurationInDays: undefined,
        organizationName: undefined,
        hasRegistrationPage: false,
    });

export default class TrainingContractRecord
    extends Record(defautValues)
    implements TrainingContract
{
    constructor(params?: TrainingContract) {
        if (params == null) params = Object.assign({}, defautValues);
        super(params);
    }
}
