import EmailValidationRecord from "models/view-models/email-validation-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface EmailValidationPathParams {}

export interface EmailValidationQueryParams {
    /**
     * Email address to validate.
     */
    emailAddress: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "validation/email";
const resourceType = EmailValidationRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const EmailValidationService = {
    useGet: ServiceHookFactory.useGet<
        EmailValidationRecord,
        EmailValidationPathParams,
        EmailValidationQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EmailValidationService;

// #endregion Exports
