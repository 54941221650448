import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserReferenceRecord from "models/view-models/user-reference-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserReferenceBasePathParams {
    userId: number;
}

export interface UserReferenceResourcePathParams
    extends UserReferenceBasePathParams {
    /**
     * Id of the UserReference resource
     */
    id: number;
}

/**
 * Search parameters when listing UserReference resources
 * These get translated to QueryString parameters
 */
export interface UserReferenceListQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users/:userId/references";
const resourceType = UserReferenceRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const key = "userreferences";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserReferenceService = {
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
    useDeleteAll: ServiceHookFactory.useDelete(baseEndpoint),

    useCreate: ServiceHookFactory.useNestedCreate<
        UserReferenceRecord,
        UserReferenceBasePathParams
    >(resourceType, baseEndpoint),

    useList: ServiceHookFactory.useNestedList<
        UserReferenceRecord,
        UserReferenceBasePathParams,
        UserReferenceListQueryParams
    >(resourceType, baseEndpoint),
    useListQuery: QueryHookFactory.useNestedListQuery<
        UserReferenceRecord,
        UserReferenceBasePathParams,
        UserReferenceListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserReferenceService;

// #endregion Exports
