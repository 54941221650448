import { RecordUtils } from "andculturecode-javascript-core";
import { Record as ImmutableRecord } from "immutable";
import PublicationCodeGroup from "models/interfaces/publication-code-group";
import PublicationRecord from "./publication-record";

const defaultValues: PublicationCodeGroup = {
    code: "",
    publications: [],
};

export default class PublicationCodeGroupRecord
    extends ImmutableRecord(defaultValues)
    implements PublicationCodeGroup
{
    constructor(params?: PublicationCodeGroup) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.publications != null) {
            params.publications = RecordUtils.ensureRecords(
                params.publications,
                PublicationRecord
            );
        }

        super(params);
    }

    public with(
        values: Partial<PublicationCodeGroup>
    ): PublicationCodeGroupRecord {
        return new PublicationCodeGroupRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
