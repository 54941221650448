import FileRecord from "models/view-models/file-record";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import { PdfPublication } from "organisms/admin/publications/pdf-publication-import-form";
import { useEffect, useState } from "react";
import useFile from "utilities/hooks/domain/files/use-file";
import usePageErrors from "utilities/hooks/use-page-errors";
import AdminFreeAccessService from "utilities/services/admin/free-access/admin-free-access-service";

const useAdminFreeAccessPublicationCreate = () => {
    const [pdfPublication, setPdfPublication] = useState<PdfPublication>({
        title: "",
        edition: "",
        code: "",
        contentSourceFileId: 0,
        usePdfBookmarkTitles: false,
    });

    const { create: createFreeAccessPublicationApi } =
        AdminFreeAccessService.useCreate();
    const { file: contentSourceFile, loadingFile } = useFile(
        pdfPublication.contentSourceFileId
    );
    const { resetPageErrors, handlePageLoadError } = usePageErrors();
    const handlePublicationFileChange = (file?: FileRecord) => {
        setPdfPublication({ ...pdfPublication, contentSourceFileId: file?.id });
    };
    const handlePublicationCreate = async () => {
        if (pdfPublication.contentSourceFileId == null) {
            return false;
        }
        try {
            resetPageErrors();
            const freeAccessPublication =
                FreeAccessPublicationRecord.fromPdfPublication(pdfPublication);
            const response = await createFreeAccessPublicationApi(
                freeAccessPublication
            );
            return response.resultObject != null;
        } catch (response) {
            handlePageLoadError(response);
            return false;
        }
    };

    useEffect(() => {
        const parseFileName = () => {
            const fileName = contentSourceFile?.fileName();
            if (
                fileName == null ||
                pdfPublication.code !== "" ||
                pdfPublication.edition !== ""
            )
                return;

            // input fileNames are in the format: "code-edition.pdf"
            // the file is updated with a timestamp: "timestamp-code-edition.pdf"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, code = "", edition = ""] = fileName.split("-");

            setPdfPublication({ ...pdfPublication, code, edition });
        };

        parseFileName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSourceFile]);

    return {
        handlePublicationCreate,
        pdfPublication,
        contentSourceFile,
        loadingFile,
        setPdfPublication,
        handlePublicationFileChange,
    };
};

export default useAdminFreeAccessPublicationCreate;
