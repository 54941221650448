import React, { useState } from "react";
import uuid from "uuid";
import Select, { SelectOption } from "atoms/forms/select";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SelectFormFieldProps<
    TData = any,
    TValue extends string | number = string
> {
    cssClassName?: string;
    disabled?: boolean;
    errorMessage?: string;
    errorMessages?: string[];
    fieldId?: string;
    id?: string;
    isValid?: boolean;
    name?: string;
    label: string;
    onChange: (e: SelectOption<TData, TValue> | undefined) => void;
    required?: boolean;
    options: SelectOption<TData, TValue>[];
    value?: TValue;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-form-field";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectFormField = <
    TData extends any = any,
    TValue extends string | number = string
>(
    props: SelectFormFieldProps<TData, TValue>
) => {
    const {
        cssClassName,
        disabled,
        errorMessage,
        errorMessages,
        isValid,
        name,
        label,
        onChange,
        required,
        options,
        value,
    } = props;

    // stateful so they don't recompute on every render
    const [fieldId] = useState(props.fieldId ?? uuid.v4());
    const [id] = useState(props.id ?? uuid.v4());

    const cssClassNames = [CSS_BASE_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        cssClassNames.push(cssClassName!);
    }

    if (isValid === false) {
        cssClassNames.push("-invalid");
    }

    return (
        <div className={StringUtils.joinClassNames(cssClassNames)}>
            <label htmlFor={fieldId}>
                {label}
                {required && (
                    <span className="c-form-field__required">{" *"}</span>
                )}
            </label>
            <Select<TData, TValue>
                disabled={disabled}
                options={options}
                id={id}
                onChange={onChange}
                name={name}
                label={label}
                value={value}
            />
            <div className={`${CSS_BASE_CLASS_NAME}__errors`}>
                {
                    // if
                    errorMessage != null && errorMessage.length > 0 && (
                        <label>{errorMessage}</label>
                    )
                }
                {
                    // if
                    errorMessages != null &&
                        errorMessages.map((s: string) => (
                            <label key={s}>{s}</label>
                        ))
                }
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SelectFormField;

// #endregion Exports
