import React from "react";
import Breadcrumb from "atoms/breadcrumbs/breadcrumb";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SituationalNavigationBreadcrumbProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SituationalNavigationBreadcrumb: React.FC<SituationalNavigationBreadcrumbProps> =
    () => {
        return (
            <React.Fragment>
                <Breadcrumb>
                    <span className="c-breadcrumb__label">
                        Situational Navigation
                    </span>
                </Breadcrumb>
            </React.Fragment>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SituationalNavigationBreadcrumb;

// #endregion Exports
