import React from "react";
import { RouteDefinition } from "utilities/interfaces/route-definition";
import { NestedRoutes } from "utilities/routing/nested-route";

interface ShareLayoutProps {
    routes?: RouteDefinition[];
}

const ShareLayout: React.FunctionComponent<ShareLayoutProps> = (
    props: ShareLayoutProps
) => {
    return (
        <div>
            <NestedRoutes routes={props.routes!} />
        </div>
    );
};

export default ShareLayout;
