import AboutModal from "organisms/modals/about-modal/about-modal";
import AccessibleList from "molecules/accessible-list/accessible-list";
import Button from "atoms/buttons/button";
import CultureResources from "utilities/interfaces/culture-resources";
import LogOutButton from "molecules/log-out-button.tsx/log-out-button";
import ProfileMenuTeamName from "organisms/profile/profile-menu-team-name";
import React, { useState } from "react";
import useOutsideClick from "utilities/hooks/use-outside-click";
import { AppNameTm } from "constants/app-name-tm";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocalization } from "andculturecode-javascript-react";
import { useRef } from "react";

interface OrganizationMemberProfileMenuDetailProps {
    focusFirstItem: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
    triggerRef: React.RefObject<HTMLButtonElement>;
    userIcon: React.ReactNode;
}

const BASE_CLASS_NAME = "c-profile-menu";

const OrganizationMemberProfileMenuDetail: React.FunctionComponent<OrganizationMemberProfileMenuDetailProps> =
    ({ focusFirstItem, setIsOpen, triggerRef, userIcon }) => {
        const [showAboutModal, setShowAboutModal] = useState<boolean>(false);
        const profileMenuRef = useRef<HTMLDivElement>(null);
        const { globalState } = useGlobalState();
        const { t } = useLocalization<CultureResources>();

        useOutsideClick(profileMenuRef, () => {
            setIsOpen(false);
        });

        const fullName =
            globalState.currentIdentity?.user?.getFirstAndLastNameOrReturnDefault();
        const aboutApp = t("aboutApp", { appName: AppNameTm });

        return (
            <div className={`${BASE_CLASS_NAME}__menu`}>
                <div className={`${BASE_CLASS_NAME}__menu__user-info`}>
                    <div
                        className={`${BASE_CLASS_NAME}__menu__user-info__initials`}>
                        {userIcon}
                    </div>
                    <div>
                        <div
                            className={`${BASE_CLASS_NAME}__menu__user-info__name`}>
                            {fullName}
                        </div>
                        <ProfileMenuTeamName />
                    </div>
                </div>
                <div
                    ref={profileMenuRef}
                    className={`${BASE_CLASS_NAME}__menu__actions`}>
                    <AccessibleList
                        focusFirstItem={focusFirstItem}
                        onEsc={() => {
                            triggerRef.current?.focus();
                            setIsOpen(false);
                        }}>
                        {globalState.currentIdentity != null && (
                            <Button onClick={() => setShowAboutModal(true)}>
                                {aboutApp}
                            </Button>
                        )}
                        <LogOutButton />
                    </AccessibleList>
                    {showAboutModal && (
                        <AboutModal
                            isVisible={showAboutModal}
                            closeDialog={() => setShowAboutModal(false)}
                        />
                    )}
                </div>
            </div>
        );
    };

export default OrganizationMemberProfileMenuDetail;
