import UserReferenceSectionService from "utilities/services/user-references/user-reference-section-service";
import PublicationService from "utilities/services/publications/publication-service";
import UserReferenceService from "utilities/services/user-references/user-references-service";
import { useCallback } from "react";
import UserReferenceRecord from "models/view-models/user-reference-record";
import BookRecord from "models/view-models/book-record";
import { CollectionUtils } from "utilities/collection-utils";
import SectionRecord from "models/view-models/section-record";
import PublicationRecord from "models/view-models/publication-record";
import { List } from "immutable";
import BookService from "utilities/services/books/book-service";

const BATCH_SIZE = 25;

/**
 * Custom hook to get user reference.
 *
 * @export
 */
export default function useUserReferencesApi() {
    const { list: listUserReferences } = UserReferenceService.useList();
    const { list: listSectionsBatched } =
        UserReferenceSectionService.useNestedBatchedList();
    const { list: listBooks } = BookService.useList();
    const { list: listPublications } = PublicationService.useList();

    const getBooks = useCallback(
        async (
            userReferences: UserReferenceRecord[]
        ): Promise<BookRecord[]> => {
            const bookIds = CollectionUtils.uniqueValuesByProperty(
                userReferences,
                "bookId"
            );

            const { resultObjects: books } = await listBooks({
                bookIds: bookIds,
            });

            return books;
        },
        [listBooks]
    );

    const getPublications = useCallback(
        async (sections: SectionRecord[]): Promise<PublicationRecord[]> => {
            const publicationIds = CollectionUtils.uniqueValuesByProperty(
                sections,
                "publicationId"
            );

            const { resultObjects: publications } = await listPublications({
                publicationIds: publicationIds,
            });

            return publications;
        },
        [listPublications]
    );

    const getSections = useCallback(
        async (
            currentUserId: number,
            userReferences: UserReferenceRecord[]
        ): Promise<SectionRecord[]> => {
            const userReferenceIds = CollectionUtils.uniqueValuesByProperty(
                userReferences,
                "id"
            );

            const { resultObjects: sections } = await listSectionsBatched(
                { userId: currentUserId },
                {
                    userReferenceIds: userReferenceIds,
                },
                "userReferenceIds",
                BATCH_SIZE
            );

            return sections;
        },
        [listSectionsBatched]
    );

    const getUserReferences = useCallback(
        async (currentUserId: number): Promise<List<UserReferenceRecord>> => {
            const { resultObjects: userReferences } = await listUserReferences({
                userId: currentUserId,
            });

            const books = await getBooks(userReferences);
            const sections = await getSections(currentUserId, userReferences);
            const publications = await getPublications(sections);

            const userReferencesWithRelated = userReferences.map(
                (userReference) => {
                    return userReference
                        .withBook(books)
                        .withPublication(publications)
                        .withSection(sections);
                }
            );

            return List(userReferencesWithRelated);
        },
        [getBooks, getPublications, getSections, listUserReferences]
    );

    return {
        getUserReferences,
    };
}
