import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import PublishStatus from "models/enumerations/publish-status";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import ButtonGroup from "organisms/button-groups/button-group";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BasicPublishStatusFiltersBarProps {
    value?: PublishStatus;
    onChange: (newValues?: PublishStatus) => void;
}

const CSS_CLASS_NAME = "c-publish-status-filters-bar";

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BasicPublishStatusFiltersBar: React.FC<BasicPublishStatusFiltersBarProps> =
    (props: BasicPublishStatusFiltersBarProps) => {
        const { value, onChange } = props;

        const getSelectedCssClass = (publishStatus?: PublishStatus): string => {
            if (value === publishStatus) {
                return "-selected";
            }

            return "";
        };

        return (
            <div className={CSS_CLASS_NAME}>
                <ButtonGroup cssClassName={`${CSS_CLASS_NAME}__button-group`}>
                    <Button
                        accessibleText={`All enhanced content`}
                        cssClassName={`${CSS_CLASS_NAME}__button-group__item -all ${getSelectedCssClass()}`}
                        type={ButtonTypes.Button}
                        onClick={() => onChange()}
                        size={ButtonSizes.XSmall}
                        style={ButtonStyles.TertiaryAlt}>
                        <Paragraph size={ParagraphSizes.XSmall}>All</Paragraph>
                    </Button>
                    <Button
                        accessibleText={`Published enhanced content`}
                        cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                            PublishStatus.PublishedWithUnpublishedChanges
                        )}`}
                        type={ButtonTypes.Button}
                        onClick={() =>
                            onChange(
                                PublishStatus.PublishedWithUnpublishedChanges
                            )
                        }
                        size={ButtonSizes.XSmall}
                        style={ButtonStyles.TertiaryAlt}>
                        <PublishStatusBadge
                            status={
                                PublishStatus.PublishedWithUnpublishedChanges
                            }
                            hasUnpublishedChanges={true}
                            small={true}
                        />
                    </Button>
                    <Button
                        accessibleText={`Published enhanced content`}
                        cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                            PublishStatus.Published
                        )}`}
                        type={ButtonTypes.Button}
                        onClick={() => onChange(PublishStatus.Published)}
                        size={ButtonSizes.XSmall}
                        style={ButtonStyles.TertiaryAlt}>
                        <PublishStatusBadge
                            status={PublishStatus.Published}
                            hasUnpublishedChanges={false}
                            small={true}
                        />
                    </Button>
                    <Button
                        accessibleText={`Unpublished enhanced content`}
                        cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                            PublishStatus.Unpublished
                        )}`}
                        type={ButtonTypes.Button}
                        onClick={() => onChange(PublishStatus.Unpublished)}
                        size={ButtonSizes.XSmall}
                        style={ButtonStyles.TertiaryAlt}>
                        <PublishStatusBadge
                            status={PublishStatus.Unpublished}
                            hasUnpublishedChanges={false}
                            small={true}
                        />
                    </Button>
                </ButtonGroup>
            </div>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default BasicPublishStatusFiltersBar;

// #endregion Exports
