import TiaRecord from "models/view-models/tia-record";
import ServiceFactory from "utilities/services/service-factory";

interface TiaPathParams {
    publicationId: number;
}

interface TiaQueryParams {}

const resourceType = TiaRecord;
const baseEndpoint = "publications/:publicationId/tias";

const TiaService = {
    list: ServiceFactory.nestedList<TiaRecord, TiaPathParams, TiaQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

export default TiaService;
