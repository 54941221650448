export const EcommercePins = {
    EnterpriseAnnual: "COD30ANN",
    EnterpriseAnnualAutoRenew: "COD30AUT",
    FreeTrial: "COD00TRL",
    IndividualAnnual: "LINKSINGLE:COD11ANN",
    IndividualAnnualAutoRenew: "LINKSINGLE:COD11AUT",
    IndividualMonthlyAutoRenew: "LINKSINGLE:COD11MOS",
    TeamTenAnnual: "LINKTEAM:COD20ANN",
    TeamTenAnnualAutoRenew: "LINKTEAM:COD20AUT",
    TeamFiveAnnual: "LINKTEAM:COD21ANN",
    TeamFiveAnnualAutoRenew: "LINKTEAM:COD21AUT",
};
