import type { Auditable } from "andculturecode-javascript-core";
import moment from "moment";
import StringUtils from "utilities/string-utils";

const formatCustomDate = (value: string | undefined, format = "YYYY/M/D") => {
    const date = moment(value);

    if (date == null || !date.isValid()) return "";

    return date.format(format);
};

const formatDate = (value?: string): string => {
    if (value === undefined) {
        return "";
    }
    const date = moment(value);
    if (date == null || !date.isValid()) {
        return "";
    }

    return `${date.format("YYYY/M/D")} at ${date.format("h:mm A")}`;
};

const formatIsoDate = (value?: string) => {
    if (value === undefined) return "";

    const date = moment(value);
    if (date == null || !date.isValid()) return "";

    return `${date.format("YYYY/MM/DD")}`;
};

/**
 * Given an Auditable, returns a string representing the last date and time
 * the record was updated. If the record has never been saved
 * (i.e. updatedOn and createdOn are both null or empty),
 * returns an empty string.
 * @param record
 */
const formatLastEditedDate = (record: Auditable): string => {
    if (
        StringUtils.isEmpty(record.createdOn) &&
        StringUtils.isEmpty(record.updatedOn)
    ) {
        return "";
    }

    return formatDate(
        StringUtils.hasValue(record.updatedOn)
            ? record.updatedOn
            : record.createdOn
    );
};

const sortByDate = <T>(arrayToSort: T[], propertyToSort: keyof T) => {
    return arrayToSort.sort(
        (a, b) =>
            new Date((b[propertyToSort] as string) ?? "").getTime() -
            new Date((a[propertyToSort] as string) ?? "").getTime()
    );
};

const DateUtils = {
    formatCustomDate,
    formatDate,
    formatIsoDate,
    formatLastEditedDate,
    sortByDate,
};

export default DateUtils;
