import React from "react";

export interface OriginsListConverterProps {
    children?: React.ReactNodeArray;
    className?: string;
    datalabel?: string;
}

const OriginsListConverter = (props: OriginsListConverterProps) => {
    // There are times where props.children is undefined
    if (props.children == null) {
        return null;
    }

    return <ul className="c-origins-list">{props.children}</ul>;
};

export default OriginsListConverter;
