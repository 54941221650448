import ChapterRecord from "models/view-models/chapter-record";
import { useCallback, useEffect } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import useLoadRecord from "utilities/hooks/domain/use-load-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import ChapterService from "utilities/services/publications/chapters/chapter-service";

export interface ChapterHookOptions {
    publicationId?: string | number;
    chapterId?: string | number;
    nfpaLabel: string;
}
/**
 * Custom hook for getting Chapter data for pages
 *
 * Ids can be passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param  {string | number} publicationId
 * @param  {string | number} chapterId
 */
export default function useChapter(
    options: ChapterHookOptions
): ServiceHookResult<ChapterRecord> {
    const { publicationId, nfpaLabel, chapterId } = options;

    const { get: getApi } = ChapterService.useGet();
    const { list: getListApi } = ChapterService.useList();
    const { errors, loaded, loading, loadRecord, resultObject } =
        useLoadRecord(ChapterRecord);

    const loadByNfpaLabel = useCallback(
        async (publicationId: number, nfpaLabel: string) =>
            loadRecord(async () => {
                const response = await getListApi(
                    { publicationId: publicationId },
                    { nfpaLabel: nfpaLabel }
                );

                if (CollectionUtils.isEmpty(response.resultObjects)) {
                    return;
                }

                return response.resultObjects[0];
            }),
        [getListApi, loadRecord]
    );

    const loadById = useCallback(
        async (publicationId: number, chapterId: number) =>
            loadRecord(async () => {
                return (
                    await getApi({
                        publicationId: publicationId,
                        id: chapterId,
                    })
                ).resultObject;
            }),
        [getApi, loadRecord]
    );

    useEffect(() => {
        const parsedPublicationId = NumberUtils.parseInt(publicationId);
        const parsedChapterId = NumberUtils.parseInt(chapterId);
        if (NumberUtils.isDefault(parsedPublicationId)) {
            return;
        }

        if (NumberUtils.isDefault(parsedPublicationId) && nfpaLabel == null) {
            return;
        }

        parsedChapterId
            ? loadById(parsedPublicationId, parsedChapterId)
            : loadByNfpaLabel(parsedPublicationId, nfpaLabel!);
    }, [chapterId, nfpaLabel, loadById, loadByNfpaLabel, publicationId]);

    return {
        errors,
        loaded,
        loading,
        resultObject,
    };
}
