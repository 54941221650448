import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import OfflineBookSyncStatus from "models/interfaces/offline/offline-book-sync-status";

const defaultValues: OfflineBookSyncStatus =
    RecordUtils.defaultValuesFactory<OfflineBookSyncStatus>({
        lastUpdatedOn: undefined,
        offlineBookId: undefined,
    });

export default class OfflineBookSyncStatusRecord
    extends Record(defaultValues)
    implements OfflineBookSyncStatus
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: OfflineBookSyncStatus) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<OfflineBookSyncStatus>} values
     */
    public with(
        values: Partial<OfflineBookSyncStatus>
    ): OfflineBookSyncStatusRecord {
        return new OfflineBookSyncStatusRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
