import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Loader from "atoms/loaders/loader";
import GroupRecord from "models/view-models/group-record";
import { ResultRecord, RecordUtils } from "andculturecode-javascript-core";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import GroupInviteCard from "organisms/group-invite-card/group-invite-card";
import UserRoleGroupRow from "organisms/user-role-groups/user-role-group-row";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useUserRoleGroups from "utilities/hooks/domain/user-role-groups/use-user-role-groups";
import GroupService from "utilities/services/groups/group-service";
import { DeleteService } from "utilities/services/service-factory";
import UserRoleGroupService from "utilities/services/user-role-groups/user-role-group-service";
import { ToastManager } from "utilities/toast/toast-manager";
import TeamManagementTeamNameField from "pages/account/tabs/tab-panels/team-management-team-name-field";
import usePagedResults from "utilities/hooks/use-paged-results";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import SearchForm from "molecules/forms/search-form";
import useSearchText from "utilities/hooks/use-search-text";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import { t } from "utilities/localization-utils";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import DataTable from "organisms/data-tables/data-table";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TeamManagementTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__team-management";
const BASE_CLASS_TABLE = `${BASE_CLASS}__table`;
const DEFAULT_TAKE_SIZE = 10;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TeamManagementTabPanel: React.FC<TeamManagementTabPanelProps> = () => {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const { globalState, setGlobalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const [groupSynced, setGroupSynced] = useState(false);
    const [refreshGroupInvitesFlag, setRefreshGroupInvitesFlag] =
        useState(false);
    const [isGroupSyncing, setIsGroupSyncing] = useState(false);
    const { isOnline } = useNetworkInformation();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Computations
    // -----------------------------------------------------------------------------------------

    const currentUserRoleGroup = useMemo(
        () =>
            currentIdentity?.userRoles.find(
                (ur) => ur.id === currentIdentity?.userLogin?.userRoleId
            )?.userRoleGroup ?? new UserRoleGroupRecord(),
        [currentIdentity?.userLogin, currentIdentity?.userRoles]
    );

    const magicLinkUrlTemplate = `${
        globalState.systemSettings?.groupInvitiationsBaseUrl
    }/group/${
        currentUserRoleGroup?.group?.id
    }/invitation/:token?invitedBy=${globalState.currentIdentity?.user?.getFirstAndLastName()}&teamName=${
        currentUserRoleGroup?.group?.name
    }`;
    const isTablet = useBreakpoint(
        Breakpoints.SmallTablet,
        BreakpointComparer.MinWidth
    );

    // #endregion Computations

    // -----------------------------------------------------------------------------------------
    // #region Custom Hooks
    // -----------------------------------------------------------------------------------------

    const { delete: deleteApi } = UserRoleGroupService.useDelete();
    const { update: updateApi } = UserRoleGroupService.useUpdate();
    const { update: syncGroupApi } = GroupService.useSync();

    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        resetPagination,
        rowCount,
        setRowCount,
        skip,
        take,
    } = usePagedResults(DEFAULT_TAKE_SIZE);

    const {
        handleSearchClear,
        handleSearchTextChange,
        searchText,
        setSubmittedSearchText,
        submittedSearchText,
    } = useSearchText();

    const { t } = useLocalization<CultureResources>();

    // Page errors + API service hooks

    const { refresh: refreshIdentity } = useRefreshIdentity();
    const {
        loaded,
        loading,
        refresh: refreshUserRoleGroups,
        resultObject: userRoleGroups,
        rowCount: rowCountFromApi,
    } = useUserRoleGroups({
        groupId: currentUserRoleGroup.groupId,
        includeUserRoleAndUser: true,
        searchText: submittedSearchText,
        skip: skip,
        take: take,
    });

    const groupCount = rowCountFromApi ?? 0;
    const showPaging = groupCount > DEFAULT_TAKE_SIZE;

    const showZeroSearchResults =
        !loading &&
        CollectionUtils.isEmpty(userRoleGroups) &&
        StringUtils.hasValue(searchText);

    const noResultsLabel = showZeroSearchResults
        ? t("noSearchResultsBanner-title")
        : "";
    const tryAgainMessage = showZeroSearchResults
        ? t("noSearchResultsBanner-subTitle")
        : "";

    const { bypassHermes } = useFeatureFlags();
    // #endregion Custom Hooks

    // -----------------------------------------------------------------------------------------
    // #region Functions
    // -----------------------------------------------------------------------------------------

    const refreshUserRoleGroupData = () => {
        refreshUserRoleGroups();
    };

    const syncGroup = useCallback(
        async (force: boolean) => {
            const { group } = currentUserRoleGroup;
            if (group == null) {
                return;
            }

            try {
                setIsGroupSyncing(true);
                await syncGroupApi(
                    group,
                    {
                        id: group?.id!,
                    },
                    {
                        forceSync: force,
                    }
                );
            } catch (e) {
                const issueSyncingGroup = t("issueSyncingGroup");
                ToastManager.error(issueSyncingGroup);
            }
            refreshUserRoleGroups();
            setRefreshGroupInvitesFlag(!refreshGroupInvitesFlag);
            setIsGroupSyncing(false);
        },
        [
            currentUserRoleGroup,
            refreshGroupInvitesFlag,
            refreshUserRoleGroups,
            syncGroupApi,
            t,
        ]
    );

    // #endregion Functions

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        if (
            groupSynced ||
            currentUserRoleGroup == null ||
            currentUserRoleGroup.group == null
        ) {
            return;
        }

        setGroupSynced(true);
        syncGroup(false);
    }, [
        syncGroup,
        syncGroupApi,
        currentUserRoleGroup,
        groupSynced,
        refreshUserRoleGroups,
        refreshGroupInvitesFlag,
    ]);

    useEffect(() => {
        if (rowCountFromApi == null) {
            return;
        }

        setRowCount(rowCountFromApi);
    }, [rowCountFromApi, setRowCount]);

    useEffect(() => {
        resetPagination();
    }, [resetPagination, submittedSearchText]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleForceRefresh = async () => {
        await syncGroup(true);

        // Refresh identity to ensure underlying Group has current data (for example, totalLicenses or name may have changed)
        await refreshIdentity();
    };

    const handleTeamNameChange = (group: GroupRecord) =>
        setGlobalState((prev) => {
            if (group.id == null) {
                return prev;
            }

            return prev.updateTeamName(group.id, group.name);
        });

    const handleUpdate = async (userRoleGroup: UserRoleGroupRecord) => {
        try {
            await updateApi(
                userRoleGroup.with({ isAdmin: !userRoleGroup.isAdmin }),
                { id: userRoleGroup.id! }
            );
        } catch (error: any) {
            if (
                RecordUtils.isRecord(ResultRecord, error) &&
                error.hasErrors()
            ) {
                ToastManager.error(error.listErrorMessages());
                return;
            }

            const errorMessage = t("errors-actionResource", {
                action: t("updating"),
                resource: t("user"),
            });
            ToastManager.error(errorMessage);
            return;
        }

        refreshUserRoleGroups();
        ToastManager.success(t("updatedItem", { item: t("userRole") }));
    };

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    if (!globalState.currentIdentity?.isGroupMember()) {
        return <Redirect to={siteMap.account.information} />;
    }

    const membersLoading = loading || isGroupSyncing;
    const groupCountLabel = loaded ? `${groupCount} ` : "-- ";
    const searchPlaceholder = `${t("searchby-nameOrEmail")}...`;
    const refreshButtonStyle =
        isTablet && bypassHermes
            ? ButtonStyles.Tertiary
            : ButtonStyles.Secondary;

    return (
        <div className={BASE_CLASS}>
            <TeamManagementTeamNameField
                group={currentUserRoleGroup?.group}
                onChange={handleTeamNameChange}
            />
            {currentUserRoleGroup?.isAdmin && (
                <GroupInviteCard
                    existingUserRoleGroupCount={groupCount}
                    group={currentUserRoleGroup?.group!}
                    magicLinkUrlTemplate={encodeURI(magicLinkUrlTemplate)}
                    refreshInvites={refreshGroupInvitesFlag}
                />
            )}
            <div className={`${BASE_CLASS}__team-members`}>
                <div className={`${BASE_CLASS}__team-members__title`}>
                    {groupCountLabel}
                    {t("team-member", { count: groupCount })}
                    <Tooltip
                        placement={TooltipPlacement.Right}
                        content={t("bypassHermes-feature-unavailable-tooltip")}
                        disabled={!bypassHermes}
                        durationInMs={0}
                        hideOnClick={true}>
                        <div
                            className={`${BASE_CLASS}__team-members__title__button-wrapper-for-tooltip`}>
                            <Button
                                disabled={!isOnline || bypassHermes}
                                onClick={handleForceRefresh}
                                size={ButtonSizes.Small}
                                style={refreshButtonStyle}>
                                {t("refresh")}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
                <div className={`${BASE_CLASS}__team-members__search`}>
                    {currentUserRoleGroup.isAdmin && (
                        <SearchForm
                            disabled={!isOnline}
                            onClear={handleSearchClear}
                            onSearchClick={(e) =>
                                setSubmittedSearchText(searchText)
                            }
                            onSearchTextChange={handleSearchTextChange}
                            placeholder={searchPlaceholder}
                            searchText={searchText}
                            style={SearchFormStyles.Tertiary}
                        />
                    )}
                </div>
                {membersLoading && (
                    <Loader
                        accessibleText={t("loadingItem", {
                            item: t("team-member_plural"),
                        })}
                    />
                )}
                {!membersLoading && loaded && (
                    <React.Fragment>
                        <DataTable>
                            <thead className={`${BASE_CLASS_TABLE}`}>
                                <tr>
                                    <th
                                        className={`${BASE_CLASS_TABLE}__team-members`}>
                                        Team Members
                                    </th>
                                    <th
                                        className={`${BASE_CLASS_TABLE}__email`}>
                                        Email
                                    </th>
                                    <th
                                        className={`${BASE_CLASS_TABLE}__last-active`}>
                                        Last Active
                                    </th>
                                    <th className={`${BASE_CLASS_TABLE}__type`}>
                                        Type
                                    </th>
                                    <th className={"-collapse"}>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userRoleGroups?.map(
                                    (userRoleGroup: UserRoleGroupRecord) => (
                                        <UserRoleGroupRow
                                            currentUserRoleGroup={
                                                currentUserRoleGroup
                                            }
                                            key={userRoleGroup.id}
                                            onDelete={() => {
                                                handleDeleteUserRoleGroup(
                                                    deleteApi,
                                                    refreshUserRoleGroupData,
                                                    userRoleGroup
                                                );
                                            }}
                                            onUpdate={() =>
                                                handleUpdate(userRoleGroup)
                                            }
                                            userRoleGroup={userRoleGroup}
                                        />
                                    )
                                )}

                                {showZeroSearchResults && (
                                    <div
                                        className={`c-no-results-banner -secondary`}>
                                        <Paragraph size={ParagraphSizes.Large}>
                                            {noResultsLabel}
                                        </Paragraph>
                                        <Paragraph size={ParagraphSizes.Small}>
                                            {tryAgainMessage}
                                        </Paragraph>
                                    </div>
                                )}
                            </tbody>
                        </DataTable>

                        {showPaging && (
                            <PageNavigationMenu
                                currentPage={currentPage}
                                numberOfPages={numberOfPages}
                                onNavigateBack={onPageLast}
                                onNavigateForward={onPageNext}
                                onPageSizeChange={onPageSizeChange}
                                onSelectPage={onSelectPage}
                                pageSize={take}
                                resultTotal={rowCount}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );

    // #endregion Render
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getSuccessMessage = (userRoleGroup: UserRoleGroupRecord): string => {
    const user = userRoleGroup.userRole?.user;
    if (user == null) {
        return t("successfullyRemovedFromTeam", { user: t("user") });
    }

    return t("successfullyRemovedFromTeam", {
        user: user.getFirstAndLastName(),
    });
};

const handleDeleteUserRoleGroup = async (
    deleteApi: DeleteService,
    refreshUserRoleGroups: () => void,
    userRoleGroup: UserRoleGroupRecord
) => {
    try {
        await deleteApi(userRoleGroup.id ?? 0);
    } catch (error) {
        // If there are specific errors from the API, lets use those.
        if (error instanceof ResultRecord && error.hasErrors()) {
            ToastManager.error(error.listErrorMessages());
            return;
        }

        const errorMessage = t("errors-actionResource", {
            action: t("removing"),
            resource: t("user"),
        });
        ToastManager.error(errorMessage);
        return;
    }

    refreshUserRoleGroups();
    ToastManager.success(getSuccessMessage(userRoleGroup));
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default TeamManagementTabPanel;

// #endregion Exports
