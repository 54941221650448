import Button from "atoms/buttons/button";
import useAzure from "pages/azure-login/use-azure";
import React, { forwardRef, useCallback } from "react";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { t } from "utilities/localization-utils";
import GlobalStateRecord from "models/view-models/global-state-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHistory } from "react-router-dom";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import { siteMap } from "internal-sitemap";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import EndEmulationSessionButton from "pages/admin/support/end-emulation-session-button";

interface LogOutButtonProps {
    disabled?: boolean;
    cssClassName?: string;
}

const LogOutButton = forwardRef((props: LogOutButtonProps, _ref) => {
    const { disabled = false, cssClassName = "c-button" } = props;
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { logout: signOut } = useAzure();
    const { globalState, setGlobalState } = useGlobalState();
    const history = useHistory();
    const { delete: deleteUserLogin } = UserLoginService.useDelete();
    const { isInEmulationMode } = useEmulationMode();

    const handleSignOutOfLiNK = useCallback(() => {
        const userLoginId = globalState.currentIdentity?.userLogin?.id!;
        deleteUserLogin(userLoginId).finally(() => {
            setGlobalState(
                (globalState: GlobalStateRecord) =>
                    globalState.setIdentity(undefined) // Removes current identity.
            );
            history.push(siteMap.userlogins.new);
        });
    }, [
        deleteUserLogin,
        globalState.currentIdentity?.userLogin?.id,
        history,
        setGlobalState,
    ]);

    const handleSignOutOfAzure = useCallback(() => {
        const userLoginId = globalState.currentIdentity?.userLogin?.id!;
        try {
            deleteUserLogin(userLoginId);
        } catch {
        } finally {
            globalState.setUnauthenticated();
            signOut();
        }
    }, [deleteUserLogin, globalState, signOut]);

    return isInEmulationMode ? (
        <EndEmulationSessionButton disabled={disabled} />
    ) : useAzureB2CForSSO ? (
        <Button
            onClick={handleSignOutOfAzure}
            cssClassName={cssClassName}
            disabled={disabled}>
            {t("logOut")}
        </Button>
    ) : (
        <Button
            onClick={handleSignOutOfLiNK}
            cssClassName={cssClassName}
            disabled={disabled}>
            {t("signOut")}
        </Button>
    );
});

export default LogOutButton;
