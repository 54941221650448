/* eslint-disable-next-line no-restricted-imports */
import { useErrorHandler } from "react-error-boundary";
import { useCallback } from "react";
import { ErrorLike } from "utilities/types/error-like";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import ErrorUtils from "utilities/error-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseErrorBoundaryHookResult {
    handleErrors: (errors?: ErrorLike) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Custom hook to bubble errors of various shapes up to the nearest error boundary - wrapper around
 * `useErrorHandler` from `react-error-boundary`
 */
export const useErrorBoundary = (
    errors?: ErrorLike
): UseErrorBoundaryHookResult => {
    const { useErrorBoundary } = useFeatureFlags();
    const error = !useErrorBoundary
        ? undefined
        : ErrorUtils.errorLikeToError(errors);
    const defaultErrorHandler = useErrorHandler(error);

    const handleErrors = (errors?: ErrorLike) => {
        if (!useErrorBoundary) {
            return;
        }

        const error = ErrorUtils.errorLikeToError(errors);
        if (error == null) {
            return;
        }

        defaultErrorHandler(error);
    };

    return {
        handleErrors: useCallback(handleErrors, [
            defaultErrorHandler,
            useErrorBoundary,
        ]),
    };
};

// #endregion Hook
