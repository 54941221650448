import JobRecord from "models/view-models/job-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface JobQueryParams {
    publicationId?: number;
}

export interface JobPathParams {}

export interface JobResourcePathParams extends JobPathParams {
    id: number;
}

export interface JobByNameQueryParams {
    jobName: string;
    reduceScope: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "jobs";
const key = "jobs";
const resourceEndpoint = `${baseEndpoint}/:id`;
const jobNameEndPoint = `${baseEndpoint}/name`;
const resourceType = JobRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const JobService = {
    /**
     * Retrieve a specific Group resource
     */
    get: ServiceFactory.get<JobRecord, JobResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    list: ServiceFactory.list<JobRecord, JobQueryParams>(
        resourceType,
        baseEndpoint
    ),
    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<JobRecord, JobResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    useList: ServiceHookFactory.useList<JobRecord, JobQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useListByJob: ServiceHookFactory.useList<JobRecord, JobByNameQueryParams>(
        resourceType,
        jobNameEndPoint
    ),
    useListQuery: QueryHookFactory.useListQuery<JobRecord, JobQueryParams>(
        key,
        resourceType,
        baseEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<JobRecord, JobResourcePathParams>(
        key,
        resourceType,
        resourceEndpoint
    ),
    useBulkCreate: ServiceHookFactory.useBulkCreate<JobRecord>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default JobService;

// #endregion Exports
