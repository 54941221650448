import { List } from "immutable";
import type BookRecord from "models/view-models/book-record";
import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { t, tErrorLoading } from "utilities/localization-utils";
import BookService from "utilities/services/books/book-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseBookOptions {
    code?: string;
    edition?: string;
    isFeatured?: boolean;
}

interface UseBookServiceHookResult extends ServiceHookResult<List<BookRecord>> {
    update: (book?: BookRecord) => Promise<BookRecord | undefined>;
}

// #endregion Interfaces

export default function useBook(
    options: UseBookOptions
): UseBookServiceHookResult {
    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listApi } = BookService.useList();
    const { update: updateApi } = BookService.useUpdate();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [serviceHookResult, setServiceHookResult] = useState<
        ServiceHookResult<List<BookRecord>>
    >(ServiceHookResultFactory.loadingResult(List<BookRecord>()));

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Callbacks
    // -----------------------------------------------------------------------------------------

    const index = useCallback(
        async (options: UseBookOptions) => {
            if (
                options.code == null &&
                options.edition == null &&
                options.isFeatured == null
            ) {
                return;
            }

            setServiceHookResult((prevResult) =>
                ServiceHookResultFactory.loadingResult(
                    List<BookRecord>(),
                    prevResult.rowCount
                )
            );

            try {
                const response = await listApi(options);
                const result = ServiceHookResultFactory.successResult(
                    List(response.resultObjects),
                    response.rowCount
                );

                setServiceHookResult(result);
            } catch (result) {
                ToastManager.error(tErrorLoading("book_plural"));

                setServiceHookResult(
                    ServiceHookResultFactory.errorResult(
                        List<BookRecord>(),
                        result
                    )
                );
            }
        },
        [listApi]
    );

    const update = useCallback(
        async (book?: BookRecord): Promise<BookRecord | undefined> => {
            if (book == null) {
                return;
            }

            try {
                const response = await updateApi(book, { id: book.id });

                if (response.result?.hasErrors()) {
                    ToastManager.error(t("book_update_error"));
                    return;
                }

                return response.resultObject;
            } catch (error) {
                ToastManager.error(t("book_update_error"));
            }
        },
        [updateApi]
    );

    const refresh = useCallback(() => index(options), [options, index]);

    // #endregion Callbacks

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useDeepCompareEffect(() => {
        index(options);
    }, [options]);

    // #endregion Side-Effects

    return {
        ...serviceHookResult,
        refresh,
        update,
    };
}
