import AnnouncementRecord from "models/view-models/announcement-record";
import AnnouncementService, {
    AnnouncementListQueryParams,
    AnnouncementResourcePathParams,
    AnnouncementServiceErrorMessages,
} from "utilities/services/announcements/announcements-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { AnnouncementUtils } from "utilities/announcements/announcement-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useMemo } from "react";
import { useAnnouncementContext } from "utilities/contexts/use-announcement-context";
import AnnouncementContextRecord from "models/view-models/announcement-context-record";
import { List } from "immutable";

export default function useAnnouncements() {
    const { setAnnouncementContext } = useAnnouncementContext();
    const { get: getApi } = AnnouncementService.useGet();
    const { list: listApi } = AnnouncementService.useList();
    const { list: cachedListApi } = AnnouncementService.useCachedList();

    const { globalState } = useGlobalState();

    const currentUserId = useMemo(() => {
        const currentUserId = globalState.currentIdentity?.user?.id;

        if (currentUserId == null) {
            return 0;
        }

        return currentUserId;
    }, [globalState.currentIdentity]);

    function acknowledge(announcement: AnnouncementRecord): boolean {
        if (currentUserId < 1) {
            return false;
        }

        return AnnouncementUtils.acknowledge(currentUserId, announcement);
    }

    async function callApi<T>(
        params:
            | AnnouncementResourcePathParams
            | AnnouncementListQueryParams
            | undefined,
        service: any,
        errorMessage: string
    ): Promise<T | undefined> {
        try {
            const response = await service(params);
            if (response.result?.hasErrors()) {
                ToastManager.error(errorMessage);
                return undefined;
            }

            return response.resultObject ?? response.resultObjects;
        } catch (error) {
            ToastManager.error(errorMessage);
        }
    }

    const get = async (id: number): Promise<AnnouncementRecord | undefined> => {
        const announcement = await callApi<AnnouncementRecord>(
            {
                id: id,
            },
            getApi,
            AnnouncementServiceErrorMessages.Get
        );

        return announcement != null
            ? AnnouncementUtils.buildAnnouncementFromCookie(
                  currentUserId,
                  announcement
              )
            : undefined;
    };

    const list = async (
        useCache: boolean = true
    ): Promise<Array<AnnouncementRecord>> => {
        const announcements = await callApi<Array<AnnouncementRecord>>(
            undefined,
            useCache ? cachedListApi : listApi,
            AnnouncementServiceErrorMessages.List
        );

        return AnnouncementUtils.buildAnnouncementsFromCookie(
            currentUserId,
            announcements ?? []
        );
    };

    const refreshContext = async (): Promise<void> => {
        const records = await list(false);
        setAnnouncementContext((context: AnnouncementContextRecord) =>
            context.with({ announcements: List(records) })
        );
    };

    return { acknowledge, get, list, refreshContext };
}
