import React, { PropsWithChildren } from "react";
import RootPortal from "molecules/portals/root-portal";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface StickyBannerProps {
    cssClassName?: string;
    position?: StickyBannerPosition;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum StickyBannerPosition {
    Bottom = "-bottom",
    Top = "-top",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-sticky-banner";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const StickyBanner: React.FunctionComponent<
    PropsWithChildren<StickyBannerProps>
> = (props: PropsWithChildren<StickyBannerProps>) => {
    const { cssClassName, position } = props;
    const positionModifier = position ?? StickyBannerPosition.Bottom;
    const modifierClasses = `${positionModifier} ${
        StringUtils.hasValue(cssClassName) ? cssClassName : ""
    }`;
    return (
        <RootPortal className={`${CLASS_NAME} ${modifierClasses}`}>
            {props.children}
        </RootPortal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default StickyBanner;

// #endregion Export
