import CheckboxInput from "atoms/forms/checkbox-input";
import { SelectOption } from "atoms/forms/select";
import React from "react";
import SelectOptionUtils from "utilities/select-option-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CheckboxListProps<
    TData = any,
    TValue extends string | number = string
> {
    disabled?: boolean;
    /**
     * Optionally filter what values are rendered.
     */
    filter?: (value: SelectOption<TData, TValue>) => boolean;
    onChange: (selectedValues: Array<SelectOption<TData, TValue>>) => void;
    options: Array<SelectOption<TData, TValue>>;

    /**
     * Optionally customize the option rendering
     * @param option the option to be rendered
     * @default a function that just returns option.label
     */
    renderOption?: (option: SelectOption<TData, TValue>) => React.ReactNode;
    values: Array<TValue>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CheckboxList = <
    TData extends any = any,
    TValue extends string | number = string
>(
    props: CheckboxListProps<TData, TValue>
) => {
    const CSS_CLASS_NAME = "c-checkbox-list";
    const {
        disabled = false,
        options,
        filter,
        onChange,
        renderOption,
        values,
    } = props;

    const getChangeHandler = (option: SelectOption<TData, TValue>) => {
        return () => {
            const updatedValues = values.some(
                (value: TValue) => value === option.value
            )
                ? values.filter((value: TValue) => value !== option.value)
                : [...values, option.value];

            onChange(
                SelectOptionUtils.getOptionsByValue(options, updatedValues)
            );
        };
    };

    const OptionComponent = (option: SelectOption<TData, TValue>) =>
        renderOption?.(option) ?? option.label;

    const renderItems = filter != null ? options.filter(filter) : options;
    return (
        <div className={CSS_CLASS_NAME}>
            {renderItems.map(
                (option: SelectOption<TData, TValue>, index: number) => (
                    <CheckboxInput
                        key={index}
                        disabled={disabled}
                        checked={SelectOptionUtils.isSelected(option, values)}
                        label={OptionComponent(option)}
                        onChange={getChangeHandler(option)}
                    />
                )
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CheckboxList;

// #endregion Exports
