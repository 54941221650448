import { ServiceResponse } from "andculturecode-javascript-core";
import { List } from "immutable";
import _ from "lodash";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type PromiseLikeFunction<T> = () => Promise<T> | T;

// #endregion Types

/**
 * Returns the first non-null value from one of the supplied promise-like functions. They are
 * executed in sequential order as needed.
 */
const coalesce = async <T>(
    ...promises: Array<PromiseLikeFunction<T>>
): Promise<T | undefined> => {
    for (const promise of promises) {
        const result = await promise();

        if (result != null) {
            return result;
        }
    }

    return undefined;
};

const toArray = async <T>(
    requests:
        | List<Promise<ServiceResponse<T>>>
        | Array<Promise<ServiceResponse<T>>>
): Promise<Array<T>> => {
    const responses = await Promise.all(requests);

    // TODO: Swap for open source `flatten` method.
    // See https://github.com/AndcultureCode/AndcultureCode.JavaScript.Core/issues/86 for more information.
    return _.flatten(
        responses.map((response: ServiceResponse<T>) => response.resultObjects)
    );
};

const toList = async <T>(
    requests:
        | List<Promise<ServiceResponse<T>>>
        | Array<Promise<ServiceResponse<T>>>
): Promise<List<T>> => {
    const responses = await toArray(requests);
    return List(responses);
};

export const PromiseUtils = { coalesce, toList, toArray };
