import { ServiceWorkerContext } from "utilities/contexts/service-worker/service-worker-context";
import React, { PropsWithChildren, useEffect } from "react";
import NetworkInformationProvider from "utilities/contexts/network-information/network-information-provider";
import { useWorkbox } from "utilities/contexts/service-worker/use-workbox";
import { ServiceWorkerCommandTypes } from "utilities/service-worker/constants/service-worker-command-types";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import { NetworkState } from "utilities/contexts/network-information/network-information-context";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

function ServiceWorkerProvider(props: PropsWithChildren<unknown>) {
    const { children } = props;

    const {
        hasUpdate,
        isReadyToReload,
        sendServiceWorkerMessage,
        serviceWorker,
        serviceWorkerState,
    } = useWorkbox({
        serviceWorkerPath: `${window.location.origin}/service-worker.js`,
    });

    const networkState = LocalStorageUtils.get<NetworkState>(
        LocalStorageKey.NetworkState
    );

    useEffect(() => {
        if (hasUpdate) {
            sendServiceWorkerMessage({
                type: ServiceWorkerCommandTypes.SkipWaiting,
            });
        }
    }, [hasUpdate, sendServiceWorkerMessage]);

    return (
        <ServiceWorkerContext.Provider
            value={{
                hasUpdate,
                isReadyToReload,
                sendServiceWorkerMessage,
                serviceWorker,
                serviceWorkerState,
            }}>
            <NetworkInformationProvider networkState={networkState}>
                {children}
            </NetworkInformationProvider>
        </ServiceWorkerContext.Provider>
    );
}

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default ServiceWorkerProvider;

// #endregion Exports
