import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import PublicationMetadataTypeEnum from "models/interfaces/publication-metadata-types";
import PublicationMetadataRecord from "models/view-models/publication-metadata-record";
import * as React from "react";
import { useEffect } from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface PublicationMetadataBreadcrumbProps {
    match: any;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationMetadataBreadcrumb: React.FunctionComponent<PublicationMetadataBreadcrumbProps> =
    (props) => {
        const { match } = props;
        const { type } = match.params;
        const { setPageTitle } = useHeaderData({ match });

        const label = React.useMemo(
            () =>
                new PublicationMetadataRecord()
                    .with({ type: type as PublicationMetadataTypeEnum })
                    .getTypeDisplayName(),
            [type]
        );

        useEffect(() => setPageTitle(label), [label, setPageTitle]);

        return (
            <Breadcrumb>
                <span className="c-breadcrumb__label">{label}</span>
                <span className="sr-only">{label}</span>
            </Breadcrumb>
        );
    };

// #endregion Component

export default PublicationMetadataBreadcrumb;
