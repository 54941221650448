import { SkipNavContent } from "@reach/skip-nav";
import { NestedRoutesProps } from "andculturecode-javascript-react";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { AppNameTm } from "constants/app-name-tm";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import RouteTabs from "molecules/route-tabs/route-tabs";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import { MyLinkUtils } from "pages/my-link/my-link-utils";
import React, { useMemo } from "react";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import MyBookmarksList from "./my-bookmarks-list";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface MyBookmarksTabProps extends NestedRoutesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

export const MY_BOOKMARKS_CSS_CLASS_NAME = "c-my-bookmarks-tab";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyBookmarksTab: React.FC<MyBookmarksTabProps> = (
    props: MyBookmarksTabProps
) => {
    const { t } = useLocalization<CultureResources>();

    const META_TAGS = useMemo(
        function buildMetaTags(): Array<MetaTag> {
            return [
                {
                    name: MetaTagTypes.Title,
                    content: AppNameTm,
                },
                {
                    name: MetaTagTypes.Description,
                    content: t("myBookmarksTab-metaTags-description", {
                        appName: AppNameTm,
                    }),
                },
            ];
        },
        [t]
    );

    useHeaderData({ metaTags: META_TAGS });

    // -----------------------------------------------------------------------------------------
    // #region Render
    // -----------------------------------------------------------------------------------------

    return (
        <>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={MY_BOOKMARKS_CSS_CLASS_NAME}>
                    <div className={`${MY_BOOKMARKS_CSS_CLASS_NAME}__header`}>
                        <Heading
                            cssClassName={`${MY_BOOKMARKS_CSS_CLASS_NAME}__heading`}
                            priority={HeadingPriority.Five}>
                            MyLiNK
                        </Heading>
                        <RouteTabs
                            redirectIfNotFound={false}
                            routes={props.routes}
                            tabs={MyLinkUtils.getTabs(t)}
                        />
                    </div>
                    <MyBookmarksList showPagination={true} />
                </div>
            </SkipNavContent>
        </>
    );

    // #endregion Render
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export default MyBookmarksTab;

// #endregion Exports
