import { PublicationEntityTypeConstants } from "constants/publication-entity-type-constants";
import useSearchModalReducer, {
    SearchModalReducerOptions,
    SearchModalV2State,
} from "organisms/modals/search-modal/utils/search-modal-reducer";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UseSearchModalOptions
    extends Omit<SearchModalReducerOptions, "initialState"> {}

// #endregion Interfaces

export default function useSearchModal(options: UseSearchModalOptions) {
    const { resetPagination } = options;
    const reducerResult = useSearchModalReducer(options);

    const { handleSetReducerState } = reducerResult;

    const getFilterHandler =
        <T extends keyof SearchModalV2State>(key: T) =>
        (value: SearchModalV2State[typeof key]) => {
            resetPagination();
            handleSetReducerState({ [key]: value });
        };

    return {
        ...reducerResult,
        handleClearFilters: () => {
            resetPagination();
            handleSetReducerState({
                codesEntityTypes: [],
                directEntityTypes: [],
                enhancedContentTypes: [
                    PublicationEntityTypeConstants.EnhancedContent,
                ],
                selectedPublicationIds: [],
            });
        },
        handleCodesEntityTypesUpdated: getFilterHandler("codesEntityTypes"),
        handleDirectEntityTypesUpdated: getFilterHandler("directEntityTypes"),
        handleEnhancedContentTypesUpdated: getFilterHandler(
            "enhancedContentTypes"
        ),
        handleSetSelectedResultType: getFilterHandler("selectedResultType"),
        handleSelectedPublicationsUpdated: getFilterHandler(
            "selectedPublicationIds"
        ),
    };
}
