import { useLocalization } from "utilities/hooks/use-localization";
import React from "react";
import SubscriptionCard from "organisms/subscription-card/subscription-card";
import SubscriptionType from "organisms/enums/subscription-type";
import { Icons } from "atoms/constants/icons";
import { SubscriptionFeaturesType } from "utilities/hooks/domain/subscriptions/use-subscription-features";
import SubscriptionFeatureList from "organisms/subscription-card/subscription-feature-list";
import CultureResources from "utilities/interfaces/culture-resources";
import { SubscriptionCardUtils } from "organisms/subscription-cards/subscription-card-utils";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TeamSubscriptionCardProps {
    onSelect: () => void;
    cssClassNameModifier?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TeamSubscriptionCard: React.FunctionComponent<
    TeamSubscriptionCardProps
> = (props: TeamSubscriptionCardProps) => {
    const { onSelect, cssClassNameModifier = "" } = props;
    const { t } = useLocalization<CultureResources>();
    const { ecommercePrices } = useEcommercePrices();

    return (
        <SubscriptionCard
            billingText={t("subscriptionCard-smallTeam-billingText")}
            buttonText={t("subscriptionCard-team-buttonText")}
            headingIcon={Icons.Team}
            onSelect={onSelect}
            price={SubscriptionCardUtils.getPricingText(
                ecommercePrices?.TeamFiveAnnualAutoRenewAsMonthly ?? ""
            )}
            type={SubscriptionType.Team}
            cssClassNameModifier={cssClassNameModifier}>
            <SubscriptionFeatureList type={SubscriptionFeaturesType.Team} />
        </SubscriptionCard>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TeamSubscriptionCard;

// #endregion Export
