import { InputTypes } from "atoms/constants/input-types";
import { InputProperties } from "interfaces/forms/input-properties";
import React, {
    Ref,
    RefForwardingComponent,
    RefObject,
    forwardRef,
} from "react";
import StringUtils from "utilities/string-utils";
import { DataTestAttributes } from "interfaces/data-test-attributes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TextInputProps
    extends InputProperties,
        Pick<DataTestAttributes, "dataTestId"> {
    hintText?: string;
    id: string;

    /**
     * Max number of characters the input will accept. If not set, input has no character limit.
     */
    maxLength?: number;
    name?: string;
    onMouseUp?: (e: React.MouseEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    ref?: RefObject<HTMLInputElement>;
}

// #endregion Interfaces

const BASE_CSS_CLASS = "c-text-input";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TextInput: RefForwardingComponent<HTMLInputElement, TextInputProps> =
    forwardRef((props: TextInputProps, ref: Ref<HTMLInputElement>) => {
        const {
            ariaLabelledBy,
            dataTestId,
            disabled,
            hintText,
            id,
            maxLength,
            name,
            onChange,
            onMouseUp,
            onKeyDown,
            onFocus,
            placeholder,
            value,
            type,
        } = props;

        return (
            <React.Fragment>
                <input
                    aria-labelledby={ariaLabelledBy}
                    data-test-id={dataTestId}
                    disabled={disabled}
                    id={id}
                    maxLength={maxLength}
                    name={name}
                    onChange={onChange}
                    onMouseUp={onMouseUp}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    ref={ref}
                    type={type || InputTypes.Text}
                    value={value}
                />
                {StringUtils.hasValue(hintText) && (
                    <div className={`${BASE_CSS_CLASS}__hint-text`}>
                        {hintText}
                    </div>
                )}
            </React.Fragment>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default TextInput;

// #endregion Export
