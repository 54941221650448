import Image from "atoms/images/image";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import React from "react";
import NfpaLogo from "assets/images/logo-subdued.svg";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TranslationsSectionConverterProps
    extends XmlConvertedComponentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TranslationsSectionConverter: React.FC<TranslationsSectionConverterProps> =
    (props: TranslationsSectionConverterProps) => {
        const CSS_CLASS_NAME = "c-translations-section";

        // There are times where props.children is undefined
        if (props.children == null) {
            return null;
        }

        return (
            <div className={CSS_CLASS_NAME}>
                <Image
                    cssClassName={`${CSS_CLASS_NAME}__logo`}
                    altText="NFPA logo"
                    src={NfpaLogo}
                />
                {props.children}
            </div>
        );
    };

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default TranslationsSectionConverter;

// #endregion Exports
