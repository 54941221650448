import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import React from "react";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-enhanced-content-panel__footer__changes";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentChangesProps {
    enhancedContent: EnhancedContentRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentChanges = (props: EnhancedContentChangesProps) => {
    const { enhancedContent, status, statusClassName } = getProperties(props);

    return (
        <span className={BASE_CLASS_NAME}>
            <span className={statusClassName}>{status}</span>
            {enhancedContent.getLastUpdatedText()}
        </span>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Compute default properties for component
 *
 * @param {EnhancedContentChangesProps} props
 * @returns
 */
const getProperties = (props: EnhancedContentChangesProps) => {
    const { enhancedContent } = props;

    const defaultProperties = {
        enhancedContent,
        status: enhancedContent.getDisplayStatus(),
        statusClassName: `${BASE_CLASS_NAME}__status`,
    };

    if (enhancedContent.hasUnpublishedChanges()) {
        return {
            ...defaultProperties,
            statusClassName: `${defaultProperties.statusClassName} -bold`,
        };
    }

    return defaultProperties;
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentChanges;

// #endregion Exports
