import { SelectOption } from "atoms/forms/select";
import PublicationTypes from "constants/publication-types";
import _ from "lodash";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import SearchModalFilterSection from "organisms/modals/search-modal/filters/search-modal-filter-section";
import { SearchModalFiltersContainerProps } from "organisms/modals/search-modal/filters/search-modal-filters-container";
import { SearchScope } from "organisms/modals/search-modal/filters/search-modal-scope-filter";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";
import SelectOptionUtils from "utilities/select-option-utils";
import {
    CodesEntityTypes as DefaultCodesEntityTypes,
    DirectEntityTypes as DefaultDirectEntityTypes,
    PublicationEntityTypeConstants,
} from "constants/publication-entity-type-constants";
import StringUtils from "utilities/string-utils";
import { SearchModalResultType } from "organisms/modals/search-modal/utils/search-modal-reducer";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SearchModalContentFilterProps
    extends Pick<
        SearchModalFiltersContainerProps,
        | "codesEntityTypes"
        | "directEntityTypes"
        | "onCodesEntityTypesUpdated"
        | "onDirectEntityTypesUpdated"
        | "publications"
        | "searchResultType"
        | "selectedPublications"
    > {
    searchScope: SearchScope;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalContentFilter: React.FC<SearchModalContentFilterProps> =
    React.memo((props: SearchModalContentFilterProps) => {
        const {
            codesEntityTypes = [],
            directEntityTypes = [],
            onCodesEntityTypesUpdated,
            onDirectEntityTypesUpdated,
            publications = [],
            searchResultType,
            searchScope,
            selectedPublications = [],
        } = props;

        const entityTypeToSelectOption = (entityType: string) => ({
            value: entityType,
            label: t(
                `${entityType.toLowerCase()}_plural` as keyof CultureResources
            ),
        });

        const necPublicationIds =
            publications
                .filter((p) => p.type === PublicationTypes.NEC)
                .map((necPub) => necPub.id) ?? [];
        const isNecPublicationSelected = CollectionUtils.hasValues(
            _.intersection(necPublicationIds, selectedPublications)
        );

        const defaultAvailableCodeEntities = isNecPublicationSelected
            ? DefaultCodesEntityTypes
            : DefaultCodesEntityTypes.filter(
                  (d) => d !== PublicationEntityTypeConstants.Article
              );
        const availableEntityTypes =
            searchResultType === SearchModalResultType.Codes
                ? defaultAvailableCodeEntities
                : DefaultDirectEntityTypes;

        const selectedEntityTypes =
            searchResultType === SearchModalResultType.Codes
                ? codesEntityTypes
                : directEntityTypes;

        const entityTypeOptions = SelectOptionUtils.map(
            availableEntityTypes,
            entityTypeToSelectOption
        );

        const handleEntityTypesUpdated = (
            selectedOptions: Array<SelectOption<string, string>>
        ) => {
            const entityTypes = SelectOptionUtils.getValues(selectedOptions);

            if (searchResultType === SearchModalResultType.Codes) {
                onCodesEntityTypesUpdated(entityTypes);
                return;
            }

            onDirectEntityTypesUpdated(entityTypes);
        };

        const contentText = StringUtils.appendCountLabel(
            "content",
            selectedEntityTypes
        );

        return (
            <SearchModalFilterSection
                isVisible={searchScope !== SearchScope.CurrentLocation}
                panelText={contentText}
                panelTriggerAriaText={contentText}>
                <CheckboxList<string, string>
                    onChange={handleEntityTypesUpdated}
                    options={entityTypeOptions}
                    values={selectedEntityTypes}
                />
            </SearchModalFilterSection>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchModalContentFilter;

// #endregion Exports
