import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { AnnexParams } from "interfaces/routing/annex-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import React, { useMemo } from "react";
import { match } from "react-router-dom";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import AnnexTableOfContentsRecord from "models/view-models/table-of-contents/annex-table-of-contents-record";
import NavItem from "molecules/sidebar-chapter-navigation/nav-item";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";

interface AnnexGroupMetaNavProps {
    match: match<NestedPublicationParams & AnnexParams & NfpaLabelParams>;
}

const AnnexGroupMetaNav: React.FunctionComponent<AnnexGroupMetaNavProps> = (
    props: AnnexGroupMetaNavProps
) => {
    const { match } = props;
    const { annexId, id: groupBy, nfpaLabel } = match.params;
    const { isAdminPreview } = useAdminPreview();
    const { t } = useLocalization<CultureResources>();

    const { tableOfContents, loading } = useBookviewTableOfContents();

    const annex =
        tableOfContents.annexes?.find(
            (a) => a.nfpaLabel === nfpaLabel || a.id?.toString() === annexId
        ) ?? new AnnexTableOfContentsRecord();

    const backButtonText = t("backToLocation", {
        location: annex.getDisplayLabel(),
    });

    const navItems = useMemo(
        () =>
            annex.annexGroups.map((ag) => (
                <NavItem
                    key={ag.groupBy}
                    hasBookmark={ag.hasBookmark}
                    externalId={""}
                    id={undefined}
                    isActive={ag.groupBy === groupBy}
                    label={""}
                    route={ag.getRoute({ ...match.params }, isAdminPreview)}
                    shouldScroll={false}
                    title={ag.getTitle()}
                />
            )),
        [annex.annexGroups, groupBy, isAdminPreview, match.params]
    );

    return (
        <React.Fragment>
            <AnchorWithIcon
                accessibleText={backButtonText}
                icon={Icons.ChevronLeft}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                to={annex.getRoute(match.params, isAdminPreview)}>
                {backButtonText}
            </AnchorWithIcon>
            {annex != null && !loading ? (
                <PublicationSidebarNav title={annex.getDisplayTitle()}>
                    {navItems}
                </PublicationSidebarNav>
            ) : (
                <Loader type={LoaderStyles.LinkGlyphGray} />
            )}
        </React.Fragment>
    );
};

export default AnnexGroupMetaNav;
