import { SelectOption } from "atoms/forms/select";
import PublicationRecord from "models/view-models/publication-record";
import { useCallback, useEffect, useState } from "react";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import StringUtils from "utilities/string-utils";

interface PublicationSelectOptionsHookOptions {}

const ALL_PUBLICATIONS_OPTION = "current";

const usePublicationSelectOptions = (
    options: PublicationSelectOptionsHookOptions
) => {
    const [publicationSelectOptions, setPublicationSelectOptions] = useState<
        Array<SelectOption<PublicationRecord>>
    >([]);

    const [publicationCode, setPublicationCode] = useState<string>();
    const [publicationEdition, setPublicationEdition] = useState<string>();

    const { resultObject: publications } = usePublications({});
    useEffect(
        function updatePublicationSelectOptions() {
            const selectOptions =
                publications.sort().map((publication: PublicationRecord) => {
                    return {
                        data: publication,
                        label: publication.getDisplayTitle(true),
                        value: publication.id?.toString(),
                    } as SelectOption;
                }) ?? [];

            selectOptions.unshift({
                label: "All Publications",
                value: ALL_PUBLICATIONS_OPTION,
            });

            setPublicationSelectOptions(selectOptions);
        },
        [publications]
    );
    const handleSelectedPublication = useCallback(
        (selectedPublication?: SelectOption) => {
            const publication = getSelectedPublication(
                publications,
                publicationSelectOptions,
                selectedPublication
            );

            // If the selected publication is null, both the code and edition should be updated with
            // undefined so that all editions are shown
            setPublicationCode(publication?.code);
            setPublicationEdition(publication?.edition);
        },
        [publications, publicationSelectOptions]
    );

    function getSelectedPublication(
        publications: Array<PublicationRecord>,
        publicationSelectOptions: Array<SelectOption<PublicationRecord>>,
        selectedPublication?: SelectOption
    ) {
        const publicationID =
            selectedPublication?.value ?? publicationSelectOptions[0].value;

        if (!StringUtils.hasValue(publicationID)) {
            return;
        }

        return publications.find((p) => p.id?.toString() === publicationID);
    }

    return {
        getSelectedPublication,
        handleSelectedPublication,
        setPublicationSelectOptions,
        setPublicationCode,
        setPublicationEdition,
        publicationCode,
        publicationEdition,
        publicationSelectOptions,
        publications,
    };
};

export default usePublicationSelectOptions;
