import { Record } from "immutable";
import type SignupContext from "models/interfaces/signup-context";
import CreateUserDtoRecord from "models/view-models/create-user-dto-record";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: SignupContext =
    RecordUtils.defaultValuesFactory<SignupContext>({
        newUser: new CreateUserDtoRecord(),
        shouldResetPasswordField: false,
    });

export default class SignupContextRecord
    extends Record(defaultValues)
    implements SignupContext
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: SignupContext) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        params.newUser = RecordUtils.ensureRecord(
            params.newUser,
            CreateUserDtoRecord
        );

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<SignupContext>} values
     */
    public with(values: Partial<SignupContext>): SignupContextRecord {
        return new SignupContextRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
