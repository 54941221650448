import React from "react";
import { DataTestAttributes } from "interfaces/data-test-attributes";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SubmitButtonProps
    extends Pick<DataTestAttributes, "dataTestId"> {
    buttonText?: string;
    cssClassName?: string;
    disabled?: boolean;

    /**
     * Typically you do not need this, but using it can allow you to
     * place the button outside of the form for styling purposes.
     */
    formId?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubmitButton: React.FC<SubmitButtonProps> = (
    props: SubmitButtonProps
) => {
    return (
        <input
            className={props.cssClassName ?? "c-button"}
            data-test-id={props.dataTestId}
            disabled={props.disabled}
            form={props.formId}
            type="submit"
            value={props.buttonText ?? "Submit"}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubmitButton;

// #endregion Exports
