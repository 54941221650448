import SectionRecord from "models/view-models/section-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface SectionBasePathParams {
    chapterId: number;
    publicationId: number;
}

/**
 * Search parameters when listing Section resources.
 * These get translated to QueryString parameters
 */
export interface SectionListQueryParams {
    excludeBody?: boolean;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "publications/:publicationId/chapters/:chapterId/sections";
const resourceType = SectionRecord;

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const SectionService = {
    list: ServiceFactory.nestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service list calls in react components
     */
    useList: ServiceHookFactory.useNestedList<
        SectionRecord,
        SectionBasePathParams,
        SectionListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default SectionService;

// #endregion Exports
