import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import B2C_CONFIG from "./pages/azure-login/b2c-config";

const config: Configuration = {
    auth: {
        clientId: B2C_CONFIG.CLIENT_ID,
        redirectUri: B2C_CONFIG.REDIRECT_URI,
        authority: `https://${B2C_CONFIG.DOMAIN}/${B2C_CONFIG.TENANT}.onmicrosoft.com/${B2C_CONFIG.POLICY}`,
        knownAuthorities: [B2C_CONFIG.DOMAIN],
        navigateToLoginRequestUrl: true,
    },
};
const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(config);

export default PUBLIC_CLIENT_APPLICATION;
