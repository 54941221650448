import Button, { ButtonTypes } from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Select, { SelectOption } from "atoms/forms/select";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import ListBox, { ListBoxItem } from "molecules/lists/list-box";
import Typeahead from "molecules/typeahead/typeahead";
import React, { useCallback, useMemo, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import usePublications from "utilities/hooks/domain/publications/use-publications";
import AdminSectionService from "utilities/services/admin/situational-navigation/sections/admin-section-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const SEARCH_SECTIONS_ERROR = "There was an issue searching sections.";
const OPTION_LIST_SIZE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SolutionCodeSectionsProps {
    dropdownPortal?: HTMLElement;
    onRemoved: (removedValue: SectionRecord) => void;
    onSelected: (newValue: SectionRecord) => void;
    value: Array<SectionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SolutionCodeSections: React.FC<SolutionCodeSectionsProps> = (
    props: SolutionCodeSectionsProps
) => {
    const baseClassName = "c-solution-code-sections";

    const { list: searchSectionsApi } = AdminSectionService.useList();

    const [selectedSection, setSelectedSection] = useState<
        SectionRecord | undefined
    >();

    const [publicationId, setPublicationId] = useState(0);
    const { resultObject: publications, loading: loadingPublications } =
        usePublications({});

    const publicationOptions = useMemo(() => {
        const publicationList = CollectionUtils.isEmpty(publications)
            ? []
            : publications;
        const options = publicationList.map(
            (p: PublicationRecord): SelectOption<undefined, number> => ({
                label: p.getDisplayCodeAndEdition(),
                value: p.id!,
            })
        );
        options.unshift({
            label: "All Publications",
            value: 0,
        });
        return options;
    }, [publications]);

    const selectedSectionIds = useMemo(
        () => props.value.map((s: SectionRecord) => s.id!),
        [props.value]
    );

    const searchSections = useCallback(
        async (searchText: string) => {
            try {
                const result = await searchSectionsApi({
                    excludeSectionIds: selectedSectionIds,
                    searchText: searchText,
                    publicationId:
                        publicationId === 0 ? undefined : publicationId,
                    take: OPTION_LIST_SIZE,
                });
                return result.resultObjects;
            } catch (e) {
                ToastManager.error(SEARCH_SECTIONS_ERROR);
                return [];
            }
        },
        [searchSectionsApi, publicationId, selectedSectionIds]
    );

    const listBoxItems = (): Array<ListBoxItem<number>> => {
        const publicationList = CollectionUtils.isEmpty(publications)
            ? []
            : publications;
        return props.value.map((section: SectionRecord) => ({
            id: section.id!,
            label: publicationList
                .find((p: PublicationRecord) => p.id === section.publicationId)
                ?.getDisplayCodeAndEdition(),
            text: section.getFullyQualifiedDisplayTitle(),
        }));
    };

    const handlePublicationSelected = (
        selectedOption?: SelectOption<undefined, number>
    ) => {
        setSelectedSection(undefined);

        if (selectedOption == null) {
            setPublicationId(0);
            return;
        }

        setPublicationId(selectedOption.value);
    };

    const handleApply = () => {
        if (selectedSection == null) {
            return;
        }

        props.onSelected(selectedSection!);
        setSelectedSection(undefined);
    };

    const handleRemove = (id: number) => {
        const record = props.value.find(
            (section: SectionRecord) => section.id === id
        );
        if (record != null) {
            props.onRemoved(record);
        }
    };

    if (loadingPublications) {
        return <Loader accessibleText={"Loading publications..."} />;
    }

    return (
        <div className={baseClassName}>
            <div className={`${baseClassName}__select-container`}>
                <Paragraph size={ParagraphSizes.Large}>Code Sections</Paragraph>
                <div className={`${baseClassName}__select-container__controls`}>
                    <Select<undefined, number>
                        id="publication-select"
                        onChange={handlePublicationSelected}
                        options={publicationOptions}
                        value={publicationId}
                    />
                    <Typeahead<SectionRecord>
                        label={""}
                        getOptionText={getOptionText}
                        onChange={setSelectedSection}
                        search={searchSections}
                        value={selectedSection}
                    />
                    <Button onClick={handleApply} type={ButtonTypes.Button}>
                        Add
                    </Button>
                </div>
            </div>
            <ListBox
                items={listBoxItems()}
                onActionClick={handleRemove}
                actionText="Remove"
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getOptionText = (s: SectionRecord) => s.getFullyQualifiedDisplayTitle();

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default SolutionCodeSections;

// #endregion Exports
