import PublicationSidebarNavAnchor from "atoms/anchors/publication-sidebar-nav-anchor";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import React from "react";
import { match } from "react-router-dom";
import usePublication from "utilities/hooks/domain/publications/use-publication";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface MetadataMetanavProps {
    match: match<NestedPublicationParams>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const MetadataMetanav: React.FunctionComponent<MetadataMetanavProps> = (
    props: MetadataMetanavProps
) => {
    const { match } = props;
    const { code, edition, publicationId } = match.params;
    const { isAdminPreview } = useAdminPreview();

    const { resultObject: publication, errors } = usePublication({
        code,
        edition,
        publicationId,
    });
    useErrorBoundary(errors);

    const { t } = useLocalization<CultureResources>();

    if (publication == null) {
        return null;
    }

    const anchorTo = publication.getRoute(isAdminPreview);
    const text = t("tableOfContents");

    return (
        <React.Fragment>
            <PublicationSidebarNavAnchor
                to={anchorTo}
                accessibleText={text}
                linkText={text}
            />
        </React.Fragment>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default MetadataMetanav;

// #endregion Exports
