import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import InviteOnDemand from "models/interfaces/group-invitations/invite-on-demand";

const defaultValues: InviteOnDemand =
    RecordUtils.defaultValuesFactory<InviteOnDemand>({
        name: undefined,
        domains: [],
    });

export default class InviteOnDemandRecord
    extends Record(defaultValues)
    implements InviteOnDemand
{
    constructor(params?: InviteOnDemand) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
}
