import XMLToReact from "andculturecode.javascript.xml-to-react";
import UnhandledXmlConverter from "atoms/containers/unhandled-xml-converter";
import HtmlConverter from "atoms/converters/html-converter";
import Denominator from "atoms/fractions/denominator";
import Fraction from "atoms/fractions/fractions";
import Numerator from "atoms/fractions/numerator";
import Image from "atoms/images/image";
import Title, { TitleConverterId } from "atoms/titles/title-converter";
import ParagraphConverter, {
    NON_NFPA_REF_CONVERTER_ID,
} from "atoms/typography/paragraph-converter";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { XmlConvertedComponentProps } from "interfaces/forms/xml-converted-component-props";
import Equation from "molecules/equations/equation";
import EquationNumber from "molecules/equations/equation-number";
import Subscript from "molecules/equations/subscript";
import Superscript from "molecules/equations/superscript";
import Symbol, { SYMBOL_CONVERTER_ID } from "molecules/equations/symbol";
import LabelConverter, {
    LabelConverterId,
} from "molecules/labels/label-converter";
import WhereList, {
    DEFINITION_CONVERTER,
} from "molecules/lists/where-list-converter";
import RichTextLinkConverter, {
    RichTextLinkConverterProps,
} from "molecules/rich-text-area/converters/rich-text-link-converter";
import TableCell from "molecules/tables/table-cell-converter";
import TableHeader from "molecules/tables/table-header-converter";
import TableHeading from "molecules/tables/table-heading-converter";
import TableNotes from "molecules/tables/table-notes-converter";
import ExceptionSection from "organisms/section-detail-components/exception-section";
import List from "organisms/section-detail-components/list";
import ListItem from "organisms/section-detail-components/list-item";
import PublicationAnchor from "organisms/section-detail-components/publication-anchor";
import SectionBody from "organisms/section-detail-components/section-body";
import SpanConverter from "organisms/section-detail-components/span-converter";
import TableSection from "organisms/section-detail-components/table-section";
import React, { PropsWithChildren, ReactElement } from "react";
import { nameof } from "ts-simple-nameof";
import { CollectionUtils } from "utilities/collection-utils";
import TableWidth from "utilities/enumerations/table-widths";
import ReferenceLink from "utilities/quill/formats/reference-link";
import StringUtils from "utilities/string-utils";
import { DOMParser } from "xmldom";

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

/**
 * Convert xml string to react component tree
 * @param xml
 * @param converters Optional set of custom converters, overriding defaults
 * @param debug
 */
const convert = (xml: string, converters?: any, debug?: boolean): any => {
    const localXmlToReact =
        converters == null
            ? xmlToReact
            : new XMLToReact(converters, debug ? debug : false);
    return localXmlToReact.convert(xml);
};

/**
 * Check that the provided props contain any children that were created by a converter base on the converterId
 *
 * @param {XmlConvertedComponentProps} props
 * @param {string} converterId
 * @return {*}  {boolean}
 */
function childrenHaveConverter(
    props: XmlConvertedComponentProps,
    converterId: string
): boolean {
    const { children } = props;

    if (children == null) {
        return false;
    }

    const containsConverter = isConverter(converterId);

    if (!Array.isArray(children)) {
        return containsConverter(children);
    }

    return children.some(containsConverter);
}

/**
 * Run rich-text HTML through the XML converter.
 * @param html
 * @param debug
 */
const convertRichText = (html: string, debug: boolean = false) =>
    convert(html, richTextConverters, debug);

/**
 * Removes any xml nodes with the deletion specific attribute
 */
const removeDeletedXmlNodes = (xmlString: string): string => {
    var parser = new DOMParser();
    let result = undefined;
    try {
        const parsedXml = parser.parseFromString(xmlString.trim());
        const allNodes = parsedXml.getElementsByTagName("*");
        for (const node in allNodes) {
            const element = allNodes[node];
            if (hasDeletionAttribute(element)) {
                element.remove();
            }
        }
        result = parsedXml.documentElement.textContent;
    } catch (error) {}

    return result ?? "";
};

/**
 * Convenience function to simplify configuration of XMLToReact converters
 * @param type {string|object}
 * @param converterId {string}
 */
const xmlConverter = (type: any, converterId?: string) => {
    return (attrs: any) => ({
        type,
        props: preprocessAttrs(attrs, converterId),
    });
};

const toElement = (xmlString: string) => {
    var parser = new DOMParser();
    try {
        const parsedXml = parser.parseFromString(xmlString.trim());
        return parsedXml.documentElement;
    } catch (error) {
        return null;
    }
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

/**
 * Create a ReactJS friendly attribute name for every key with the matching namespace alias
 * for use as props in React components.
 * If http://xml.nfpa.org/diff is aliased as "p3", and the key "p3:changed" exists in the object,
 * create a new attribute for "diffChanged"
 *
 * @param attrs Object containing xml converted to JS Object
 * @param namespaceAlias Alias created by parser during import i.e p3:label
 * @param namespaceName Name to set in place of namespace alias
 */
const createFriendlyAttribute = (
    attrs: any,
    namespaceAlias: string,
    namespaceName: string
): void => {
    Object.keys(attrs).forEach((key: string) => {
        if (key.indexOf(namespaceAlias) === -1) {
            return;
        }
        const attributeName = key.split(":")[1];
        attrs[`${namespaceName}${StringUtils.capitalize(attributeName)}`] =
            attrs[key];
    });
};

/**
 * Wrapper component to allow XML converted components to render in a <div> without needing an explicit converter
 * @param className css className to be added to the wrapping <div>
 */
const DivWrapper = (className: string): React.ReactNode => {
    return (props: PropsWithChildren<XmlConvertedComponentProps>) => {
        if (props.diffchanged === XmlChangeNotationConstants.DELETION) {
            return null;
        }

        const changedModifier =
            props.diffchanged != null
                ? `c-code-change -${props.diffchanged}`
                : "";

        return (
            <div className={`${className} ${changedModifier}`}>
                {props.children}
            </div>
        );
    };
};

/**
 * Filter out a specific element from an array React elements by its converterId
 * @param converterId
 * @param children
 */
const filterChildrenByConverterId = (
    converterId: string,
    children?: JSX.Element[]
): JSX.Element[] => {
    if (CollectionUtils.isEmpty(children)) {
        return [];
    }

    const elementToFilter = XmlUtils.findElementByConverterId(
        converterId,
        children
    );

    if (elementToFilter != null) {
        children = children.filter(
            (element: JSX.Element) => element.type !== elementToFilter.type
        );
    }

    return children!;
};

/**
 * Locate a given element in an array of children by its converterId
 * @param converterId
 * @param children
 */
const findElementByConverterId = (
    converterId: string,
    children?: JSX.Element[]
): JSX.Element | undefined => {
    if (CollectionUtils.isEmpty(children)) {
        return undefined;
    }

    return children!.find(
        (element: JSX.Element) =>
            element != null &&
            element.props != null &&
            element.props.converterId != null &&
            element.props.converterId === converterId
    );
};

/**
 * Wrapper component to allow XML converted components to render without needing an explicit converter,
 * or passing invalid props directly to React.Fragment which causes console warnings
 *
 * `Warning: Invalid prop 'parentNode' supplied to 'React.Fragment'. React.Fragment can only have 'key' and 'children' props.`
 *
 * @param {PropsWithChildren<XmlConvertedComponentProps>} props
 * @returns {JSX.Element}
 */
const FragmentWrapper = (
    props: PropsWithChildren<XmlConvertedComponentProps>
): JSX.Element | null => {
    if (props.diffchanged === XmlChangeNotationConstants.DELETION) {
        return null;
    }
    return <React.Fragment>{props.children}</React.Fragment>;
};

/**
 * Returns true if there is a deletion specific attribute
 */
const hasDeletionAttribute = (element: Element): boolean => {
    for (const attr in element.attributes) {
        const a = element.attributes[attr];
        if (
            a.localName === XmlChangeNotationConstants.CHANGED &&
            a.value === XmlChangeNotationConstants.DELETION
        ) {
            return true;
        }
    }

    return false;
};

/**
 * Returns true if any node in the entire tree has the attribute
 */
const hasAttributeDeep = (element: Element, attribute: string): boolean => {
    if (element == null) return false;

    for (const attr in element.attributes) {
        const a = element.attributes[attr];
        if (
            a.localName === attribute &&
            a.value !== XmlChangeNotationConstants.NO_CHANGES
        ) {
            return true;
        }
    }

    if (!element.hasChildNodes()) return false;

    const childrenArr = Array.from(element.childNodes).filter(
        (e) => e.nodeType === e.ELEMENT_NODE
    ) as Element[];
    let hasAttribute = false;

    childrenArr.forEach((element) => {
        if (hasAttributeDeep(element, attribute)) hasAttribute = true;
    });

    return hasAttribute;
};

/**
 * Factory method to for callback function to check for the existence of a converterId prop and matching value
 *
 * @param {string} converterId
 * @return {*}  {(child: JSX.Element) => boolean}
 */
function isConverter(converterId: string): (child: JSX.Element) => boolean {
    return (child: ReactElement<XmlConvertedComponentProps>) =>
        StringUtils.hasValue(child?.props?.converterId) &&
        child.props.converterId === converterId;
}

/**
 * Takes a key and value from an xml element being converted into a React component, and conditionally
 * outputs a modified version of the key for special use cases.
 *
 * These use-cases can be semantic renaming, removing invalid characters to allow for mapping to
 * component props, removing unnecessary/superfluous naming context from the key,
 * renaming to avoid collision with base React props, etc.
 *
 * Use your best judgement, and add test coverage in xml-utils.test.ts
 *
 * @param {string} key
 * @param {string} value
 */
const modifyAttributeName = (key: string, value?: string): string => {
    if (key.split(":")[1] === "changed") {
        return "diffchanged";
    }

    // Check to see if a namespaced 'id' attribute matches the externalId pattern, ie
    // ID000700008783, ID000700008784, ID000700008785, ID000700009000
    if (
        key.split(":")[1] === "id" &&
        value != null &&
        value.match(/ID\w{12}/) != null
    ) {
        return "externalId";
    }

    // Check for any of the data-reference-* attributes that were processed on the backend
    // for anchor tags
    if (key.startsWith("data-reference-")) {
        let keyParts = key.replace("data-reference-", "").split("-");

        // ie section-id -> sectionId, or multi-word keys such as root-section-id
        // properly become rootSectionId
        const camelCasedKey =
            keyParts[0] +
            keyParts
                .slice(1)
                .map((s) => StringUtils.capitalize(s))
                .join("");

        return camelCasedKey;
    }

    if (
        key.split(":")[1] === "width" &&
        value != null &&
        value.match(
            `${TableWidth.Full}|${TableWidth.ThreeQuarters}|${TableWidth.Half}`
        )
    ) {
        return "tableWidth";
    }

    switch (key) {
        case "alt":
            key = "altText";
            break;
        case "class":
            // Otherwise, react will throw error trying to pass a prop called 'class'
            key = "className";
            break;
        case "cod-style":
            key = "codStyle";
            break;
        case "pgWide":
            key = "pageWide";
            break;
        case ReferenceLink.DATA_ENTITY_ID_ATTR: // RTE Reference Link props
            key = nameof<RichTextLinkConverterProps>((e) => e.entityId);
            break;
        case ReferenceLink.DATA_ENTITY_TYPE_ATTR: // RTE Reference Link props
            key = nameof<RichTextLinkConverterProps>((e) => e.entityType);
            break;
    }

    return key;
};

/**
 * Find attributes prefixed with "xmlns" and identify the namespace identified
 * Attributes named xmlns:p3 have a url value indicating xml namespace (i.e "http://someurl.org/namespaceId")
 * Identifies the namespace, the alias and the namespace id for pre-processing.
 *
 * @param attrs Object provided by xml-to-react conversion
 */
const processAliasedNamespaceAttributes = (attrs: any) => {
    const xmlns = "xmlns";

    Object.keys(attrs).forEach((key: string) => {
        if (key.indexOf(xmlns) >= 0) {
            const namespaceUrl = attrs[key];

            //Use the last segment of the namespace url as the namespace Id. http://someurl.org/namespaceId
            const namespaceId = namespaceUrl.substring(
                namespaceUrl.lastIndexOf("/") + 1
            );

            const alias = key.split(":")[1];
            createFriendlyAttribute(attrs, alias, namespaceId);
        }
    });
};

/**
 * All xmlConverters pass their attributes first through this function
 * before passing them along to the react component's props
 * @param attrs
 * @param converterId
 */
const preprocessAttrs = (attrs: any, converterId?: string) => {
    let result: any = {};
    if (converterId != null) {
        result.converterId = converterId;
    }

    processAliasedNamespaceAttributes(attrs);

    Object.keys(attrs).forEach((key: string) => {
        const value: string = attrs[key];

        key = modifyAttributeName(key, value);

        result[key] = value;
    });

    // Pre-process changes for elements that don't have a custom converter
    const diffChanges = result["diffchanged"];
    if (diffChanges == null) {
        return result;
    }

    const modifierClass = ` c-code-change -${diffChanges}`;
    if (result.className != null) {
        result.className += modifierClass;
        return result;
    }

    if (result.class == null) {
        result.class = modifierClass;
        return result;
    }

    result.class += modifierClass;
    return result;
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Init
// -----------------------------------------------------------------------------------------

/**
 * Configure single instance of XMLToReact with converters.
 *
 * #### Example of Custom Component
 * ```
 * const TitleComponent = (props: any) => {
 *   return <span>{props.children}</span>;
 * };
 * const xmlToReact = new XMLToReact({
 *   "np:title": (attrs: any) => ({
 *     type: TitleComponent,
 *     props: preprocessAttrs(attrs),
 *   })
 * }),
 * ```
 */

const defaultConverters = {
    "*": xmlConverter(UnhandledXmlConverter),
    body: xmlConverter(FragmentWrapper),
    label: xmlConverter(LabelConverter, LabelConverterId),
    li: xmlConverter("li"),
    lineBreak: xmlConverter("br"),
    ol: xmlConverter("ol"),
    p: xmlConverter(ParagraphConverter),
    sub: xmlConverter(Subscript),
    sup: xmlConverter(Superscript),
};

const publicationTableConverters = {
    caption: xmlConverter(Title, TitleConverterId),
    desc: xmlConverter("desc"),
    table: xmlConverter(TableSection),
    TableNotes: xmlConverter(TableNotes, "tfoot"),
    TableNotesBody: xmlConverter(FragmentWrapper),
    TableNotesEntry: xmlConverter(FragmentWrapper),
    TableNotesRow: xmlConverter(FragmentWrapper),
    tbody: xmlConverter("tbody"),
    td: xmlConverter(TableCell, "td"),
    th: xmlConverter(TableHeading, "th"),
    thead: xmlConverter(TableHeader),
    tr: xmlConverter("tr", "tr"),
};
const fractionConverter = {
    denominator: xmlConverter(Denominator),
    fraction: xmlConverter(Fraction),
    numerator: xmlConverter(Numerator),
};

const publicationInlineConverters = {
    a: xmlConverter(PublicationAnchor),
    b: xmlConverter("b"),
    body: xmlConverter(SectionBody),
    br: xmlConverter("br"),
    Definition: xmlConverter(DivWrapper("c-definition"), DEFINITION_CONVERTER),
    Equation: xmlConverter(FragmentWrapper),
    EquationBlock: xmlConverter(Equation),
    EquationInline: xmlConverter("span"),
    i: xmlConverter("em"),
    image: xmlConverter(Image),
    inline: xmlConverter("span"),
    li: xmlConverter(ListItem),
    NonNFPARef: xmlConverter(FragmentWrapper, NON_NFPA_REF_CONVERTER_ID),
    Number: xmlConverter(EquationNumber),
    NumberedEquation: xmlConverter(FragmentWrapper),
    ol: xmlConverter(List),
    sign: xmlConverter(ExceptionSection),
    span: xmlConverter(SpanConverter),
    Symbol: xmlConverter(Symbol, SYMBOL_CONVERTER_ID),
    title: xmlConverter(Title, TitleConverterId),
    warning: xmlConverter(ExceptionSection),
    WhereList: xmlConverter(WhereList),

    ...fractionConverter,
};

const richTextConverters = {
    "*": xmlConverter(HtmlConverter),
    a: xmlConverter(RichTextLinkConverter),
};

const xmlToReact = new XMLToReact(defaultConverters, true);

// #endregion Init

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const XmlUtils = {
    childrenHaveConverter,
    convert,
    convertRichText,
    createFriendlyAttribute,
    defaultConverters,
    DivWrapper,
    FragmentWrapper,
    filterChildrenByConverterId,
    findElementByConverterId,
    fractionConverter,
    modifyAttributeName,
    processAliasedNamespaceAttributes,
    publicationInlineConverters,
    publicationTableConverters,
    removeDeletedXmlNodes,
    richTextConverters,
    xmlConverter,
    hasAttributeDeep,
    toElement,
};

export default XmlUtils;

// #endregion Exports
