import EnglishUnitedStates from "cultures/english-united-states";
import SpanishSpain, { Spanish } from "cultures/spanish-spain";
import { i18n } from "i18next";
import GlobalStateRecord from "models/view-models/global-state-record";
import { useEffect, useMemo, useState } from "react";
import { initReactI18next } from "react-i18next";
import "react-toastify/dist/ReactToastify.min.css";
import CultureResources from "utilities/interfaces/culture-resources";
import LocalizationUtils from "utilities/localization-utils";
import ServiceUtils from "utilities/service-utils";
import useWindowContext from "utilities/contexts/window/use-window-context";
import SystemSettingsService from "./utilities/services/system-settings/system-settings-service";
import LOCALIZATION_OPTIONS from "./localization-options";

// This hook is used to setup the app before the first render
// It's main job is to load the SystemSettings, so we know which FeatureFlags to render.
const useSetupApp = () => {
    const { height: windowHeight } = useWindowContext();
    const [cachedI18n, setCachedI18n] = useState<i18n>();
    const [globalState, setGlobalState] = useState(
        GlobalStateRecord.getInitialGlobalState()
    );
    const { get: getSystemSettings } = SystemSettingsService.useGet();
    const cultureCode = useMemo(
        () => globalState.getPreferredOrCurrentCulture(),
        [globalState]
    );

    // Set the default culture
    ServiceUtils.configure({
        cultureCode: cultureCode,
        globalState,
        setGlobalState,
    });

    // Setup HTML helment
    const htmlAttributes = useMemo(
        () => ({ lang: cultureCode }),
        [cultureCode]
    );

    // Setup localization
    if (cachedI18n == null) {
        const i18n = LocalizationUtils.initialize<CultureResources>(
            initReactI18next,
            [EnglishUnitedStates, SpanishSpain, Spanish],
            LOCALIZATION_OPTIONS
        );

        setCachedI18n(i18n);
    }

    // Set the document vh values
    useEffect(() => {
        let vh = windowHeight * 0.01;
        if (vh === 0 || vh == null) return;

        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, [windowHeight]);

    // Load System Settings
    useEffect(() => {
        const loadSystemSettings = async () => {
            const response = await getSystemSettings({});
            setGlobalState((prev) => {
                return prev.with({
                    systemSettings: response.resultObject,
                    systemSettingsLoaded: true,
                });
            });
        };

        loadSystemSettings();
    }, [getSystemSettings]);

    return {
        htmlAttributes,
        globalState,
        setGlobalState,
    };
};

export default useSetupApp;
