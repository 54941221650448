import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import RouteTabs from "molecules/route-tabs/route-tabs";
import PublicationsAdminSidebar from "organisms/admin/publications/publications-admin-sidebar";
import BreadcrumbsMenu from "organisms/breadcrumbs/breadcrumbs-menu";
import { PublicationsAdminUtils } from "pages/admin/publications/publications-admin-utils";
import React, { useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import AdminEditorPageContext from "utilities/contexts/admin/use-admin-editor-page-context";
import {
    NestedRoutes,
    NestedRoutesProps,
} from "utilities/routing/nested-route";
import { SkipNavContent } from "@reach/skip-nav";
import { CollectionUtils } from "utilities/collection-utils";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublicationsAdminDashboardProps extends NestedRoutesProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationsAdminDashboard: React.FC<any> = (
    props: PublicationsAdminDashboardProps
) => {
    const CSS_CLASS_NAME = "c-publications-admin-dashboard";

    const location = useLocation();

    const [context, setContext] = useState(new AdminEditorPageContextRecord());

    const headerClassModifier = "-border-bottom";

    const batchRoute = RouteUtils.getUrl(siteMap.admin.publications.batch);
    const adminDashboardRoute = RouteUtils.getUrl(
        siteMap.admin.publications.dashboard
    );
    if (
        CollectionUtils.isEmpty(context.publicationBatch) &&
        RouteUtils.assertCurrentUrl(batchRoute)
    ) {
        return <Redirect to={adminDashboardRoute} />;
    }

    return (
        <AdminEditorPageContext.Provider value={[context, setContext]}>
            <BreadcrumbsMenu fullWidth={true} />
            <SkipNavContent tabIndex={-1}>
                <div className={CSS_CLASS_NAME}>
                    <div
                        className={`${CSS_CLASS_NAME}__header ${headerClassModifier}`}>
                        <Heading priority={HeadingPriority.Five}>
                            Publications Management
                        </Heading>
                        <RouteTabs
                            redirectIfNotFound={false}
                            routes={props.routes}
                            tabs={PublicationsAdminUtils.getTabs()}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <PublicationsAdminSidebar />
                        <div className={`${CSS_CLASS_NAME}__content__editor`}>
                            {location.pathname ===
                                siteMap.admin.publications.dashboard && (
                                <div className="-no-publication">
                                    <Paragraph>
                                        Select a Publication from the Sidebar to
                                        Edit
                                    </Paragraph>
                                </div>
                            )}
                            <NestedRoutes routes={props.routes} />
                        </div>
                    </div>
                </div>
            </SkipNavContent>
        </AdminEditorPageContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationsAdminDashboard;

// #endregion Exports
