/**
 * This is needed because the <code>new File()</code> constructor
 * is not supported on IE, Edge, or Safari: https://caniuse.com/#feat=fileapi
 * This implements the full standard File API interface excluding the property
 * <code>webkitRelativePath</code> because this API has not been standardized.
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/File
 */
/* tslint:disable */
declare global {
    // extend the global Window interface and add the missing File class property
    interface Window {
        File: any;
    }
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Browser_compatibility
 */
const registerClosestElementPolyfill = () => {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            // @ts-ignore
            Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s: any) {
            var el = this;

            do {
                if (Element.prototype.matches.call(el, s)) {
                    return el;
                }

                // @ts-ignore
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
};

const registerFileConstructorPolyfill = () => {
    try {
        new File([], "");
    } catch (e) {
        // @ts-ignore
        window.File = class WindowFile extends Blob {
            public lastModifiedDate: Date;
            public name: string;
            // @ts-ignore
            constructor(bits, filename, opts = {}) {
                super(bits, opts);
                this.lastModifiedDate = new Date();
                this.name = filename;
            }
            get lastModified(): Date {
                return this.lastModifiedDate;
            }
            set lastModified(date: Date) {
                this.lastModifiedDate = date;
            }
        };
    }
};
/* tslint:enable */

const registerGetBoundingClientRectPolyfill = () => {
    (function (global) {
        if (!("window" in global && "document" in global)) return;

        //----------------------------------------------------------------------
        //
        // CSSOM View Module
        // https://dev.w3.org/csswg/cssom-view/
        //
        //----------------------------------------------------------------------

        // Fix for IE8-'s Element.getBoundingClientRect()
        if (
            "TextRectangle" in global &&
            !("width" in (global as any).TextRectangle.prototype)
        ) {
            Object.defineProperties((global as any).TextRectangle.prototype, {
                width: {
                    get: function () {
                        return this.right - this.left;
                    },
                },
                height: {
                    get: function () {
                        return this.bottom - this.top;
                    },
                },
            });
        }
    })(window);
};

/**
 * Promise.finally is not natively supported in Internet Explorer.
 */
/* tslint:disable */
const registerPromiseFinallyPolyfill = () => {
    // eslint-disable-next-line
    Promise.prototype.finally =
        Promise.prototype.finally ??
        function (callback: () => any) {
            // @ts-ignore
            return this.then(
                (value: any) => Promise.resolve(callback()).then(() => value),
                (reason: any) =>
                    Promise.resolve(callback()).then(() => {
                        throw reason;
                    })
            );
        };
};
/* tslint:enable */

const registerStringPrototypeRepeatPolyfill = () => {
    if (String.prototype.repeat != null) {
        return;
    }

    // @ts-ignore
    // eslint-disable-next-line
    String.prototype.repeat = function repeat(count: number) {
        // ~~ is double bit-inversion; fastest way to truncate a float to int
        const n = ~~count;
        const stringToConcat = `${this}`;
        let result = "";
        for (let i = 0; i < n; i++) {
            result += stringToConcat;
        }

        return result;
    };
};

const registerAllPolyfills = () => {
    registerClosestElementPolyfill();
    registerFileConstructorPolyfill();
    registerGetBoundingClientRectPolyfill();
    registerPromiseFinallyPolyfill();
    registerStringPrototypeRepeatPolyfill();
};

const PolyfillUtils = {
    registerAllPolyfills,
    registerClosestElementPolyfill,
    registerFileConstructorPolyfill,
    registerGetBoundingClientRectPolyfill,
    registerPromiseFinallyPolyfill,
    registerStringPrototypeRepeatPolyfill,
};

export default PolyfillUtils;
