import { ServiceResponse, ResultRecord } from "andculturecode-javascript-core";
import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import InviteOnDemandRecord from "models/view-models/group-invitations/invite-on-demand.record";
import React, { useState } from "react";
import AlreadyOnTeam from "./already-on-team";
import JoinGroupOnDemandForm from "./join-group-on-demand-form";
import NoInvitesAvailable from "./no-invites-available";
import ThankYouSignIn from "./thank-you-sign-in";
import ThankYouSignUp from "./thank-you-sign-up";
import UnrecognizedEmail from "./unrecognized-email";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Paragraph from "atoms/typography/paragraph";
import InviteContentHeader from "organisms/content-headers/invite-content-header";
import { AppNameWithHtml } from "constants/app-name-tm";
import { t } from "utilities/localization-utils";

const BASE_CLASS = "c-join-group-on-demand-page";

interface JoinGroupOnDemandContentProps {
    isLoading: boolean;
    group: InviteOnDemandRecord;
    createInviteOnDemand: (email: string) => Promise<{
        validationErrors?: string[];
        result?:
            | ServiceResponse<GroupInvitationRecord>
            | ResultRecord<GroupInvitationRecord>;
    }>;
}

export enum JoinGroupOnDemandState {
    FORM,
    THANK_YOU_SIGN_UP,
    THANK_YOU_SIGN_IN,
    NO_INVITES_AVAILABLE,
    UNRECOGNIZED_EMAIL,
    ALREADY_ON_TEAM,
}

const JoinGroupOnDemandContent = ({
    isLoading,
    group,
    createInviteOnDemand,
}: JoinGroupOnDemandContentProps) => {
    const [state, setState] = useState<JoinGroupOnDemandState>(
        JoinGroupOnDemandState.FORM
    );
    const [email, setEmail] = useState("");
    const [emailErrors, setEmailErrors] = useState<string[]>([]);

    const { globalState } = useGlobalState();
    const isLoggedIn = globalState.isAuthenticated();

    const resetForm = () => {
        setEmail("");
        setEmailErrors([]);
        setState(JoinGroupOnDemandState.FORM);
    };
    return (
        <div className={`${BASE_CLASS}__content__form-wrapper`}>
            <div className={`${BASE_CLASS}__content__form-wrapper__header`}>
                <InviteContentHeader
                    groupName={group.name}
                    groupInfoTitle={`${t("team-name")}`}>
                    {t("on-demand-team-header-1")}&nbsp;
                    <span
                        className={`${BASE_CLASS}__content__form-wrapper__superscript`}
                        dangerouslySetInnerHTML={{
                            __html: AppNameWithHtml,
                        }}
                    />
                    &nbsp;{t("on-demand-team-header-2")}
                </InviteContentHeader>
                {!isLoggedIn && (
                    <div
                        className={`${BASE_CLASS}__content__form-wrapper__body`}>
                        <Paragraph>
                            Already a member of this team? <LogInAnchor />.
                        </Paragraph>
                    </div>
                )}
            </div>
            <div className={`${BASE_CLASS}__content__form-wrapper__form`}>
                {state === JoinGroupOnDemandState.FORM && (
                    <JoinGroupOnDemandForm
                        domains={group.domains}
                        disabled={isLoading}
                        setState={setState}
                        email={email}
                        setEmail={setEmail}
                        emailErrors={emailErrors}
                        setEmailErrors={setEmailErrors}
                        createInviteOnDemand={createInviteOnDemand}
                    />
                )}
                {state === JoinGroupOnDemandState.THANK_YOU_SIGN_UP && (
                    <ThankYouSignUp />
                )}
                {state === JoinGroupOnDemandState.THANK_YOU_SIGN_IN && (
                    <ThankYouSignIn />
                )}
                {state === JoinGroupOnDemandState.ALREADY_ON_TEAM && (
                    <AlreadyOnTeam />
                )}
                {state === JoinGroupOnDemandState.NO_INVITES_AVAILABLE && (
                    <NoInvitesAvailable />
                )}
                {state === JoinGroupOnDemandState.UNRECOGNIZED_EMAIL && (
                    <UnrecognizedEmail resetForm={resetForm} />
                )}
            </div>
        </div>
    );
};

export default JoinGroupOnDemandContent;
