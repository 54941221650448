import { useLocalization } from "andculturecode-javascript-react/dist/hooks/use-localization";
import Button from "atoms/buttons/button";
import React from "react";
import useWorkGroupCreateService from "utilities/hooks/domain/work-groups/use-work-group-create-service";
import useModalActions from "utilities/hooks/use-modal-actions";
import CultureResources from "utilities/interfaces/culture-resources";
import WorkgroupModal from "./work-group-modal";

interface AddNewWorkGroupProps {}

const AddNewWorkGroup: React.FC<AddNewWorkGroupProps> = (props) => {
    const { createWorkGroup, loading } = useWorkGroupCreateService();

    const { handleClose, handleOpen, isOpen } = useModalActions();

    const { t } = useLocalization<CultureResources>();
    const newWorkgroup = t("newItem", { item: t("workgroup") });

    return (
        <React.Fragment>
            <Button onClick={handleOpen}>{newWorkgroup}</Button>
            <WorkgroupModal
                closeDialog={handleClose}
                heading={newWorkgroup}
                loading={loading}
                isVisible={isOpen}
                onSave={createWorkGroup}
            />
        </React.Fragment>
    );
};

export default AddNewWorkGroup;
