import { ResultRecord } from "andculturecode-javascript-core/dist/view-models/result-record";
import { useAtom } from "jotai";
import UserCollectionShareType from "models/enumerations/user-collection-share-type";
import UserCollectionValidator from "models/validation/users/user-collections/user-collection-validator";
import UserCollectionRecord from "models/view-models/user-collection-record";
import { useCallback, useState } from "react";
import UserCollectionAtom from "utilities/atoms/user-collections/user-collections-atom";
import useLoading from "utilities/hooks/use-loading";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserCollectionService from "utilities/services/users/user-collections/user-collection-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { ObjectValidationResult } from "utilities/validation/object-validator/object-validator";

const userCollectionWorkGroupsEndpoint = "users/collections/workgroups";
const ERROR_DUPLICATE_NAME =
    "UserCollectionsController.Create.DuplicateUserCollectionExists";

export default function useCreateUserCollection() {
    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [userCollections, setUserCollections] = useAtom(UserCollectionAtom);

    const [userCollectionCreateLoading, setUserCollectionCreateLoading] =
        useState<boolean>(false);
    const loading = useLoading(userCollectionCreateLoading);
    const { create: userCollectionCreateApi } =
        UserCollectionService.useCreate();

    const { create: createUserCollectionWithWorkGroupApi } =
        ServiceHookFactory.useCreate(
            UserCollectionRecord,
            userCollectionWorkGroupsEndpoint
        )();

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Handlers
    // -----------------------------------------------------------------------------------------
    const { t } = useLocalization<CultureResources>();

    const handleSuccess = useCallback(
        () => ToastManager.success(t("newCollection")),
        [t]
    );

    // #endregion Handlers

    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const validator = new UserCollectionValidator();

    const createDefaultUserCollection = useCallback(
        async (record: UserCollectionRecord, userId?: number) => {
            if (userId == null) {
                return;
            }

            const userCollectionListResult = await userCollectionCreateApi(
                record,
                { userId }
            );
            const resultObject = userCollectionListResult.resultObject;

            return resultObject;
        },
        [userCollectionCreateApi]
    );

    const createUserCollectionWithWorkGroup = useCallback(
        async (userCollection: UserCollectionRecord) => {
            if (userCollection.groupId == null) {
                ToastManager.error(t("oopsErrorOccurred"));
                return;
            }

            const { resultObject } = await createUserCollectionWithWorkGroupApi(
                userCollection
            );

            return resultObject;
        },
        [createUserCollectionWithWorkGroupApi, t]
    );

    const createUserCollection = async (
        userCollection: UserCollectionRecord,
        userId?: number
    ) => {
        let validationResult = validator.validate(userCollection);
        let result: UserCollectionRecord | undefined;

        if (UserCollectionValidator.hasErrors(validationResult)) {
            return { result, validationResult };
        }

        setUserCollectionCreateLoading(true);
        try {
            if (
                userCollection.shareType === UserCollectionShareType.WorkGroup
            ) {
                result = await createUserCollectionWithWorkGroup(
                    userCollection
                );
            } else {
                result = await createDefaultUserCollection(
                    userCollection,
                    userId
                );
            }

            if (result != null) {
                setUserCollections([...userCollections, result]);
            }
            handleSuccess();
        } catch (error) {
            if (
                error instanceof ResultRecord &&
                error.listErrorMessages().includes(ERROR_DUPLICATE_NAME)
            ) {
                const duplicate: ObjectValidationResult<UserCollectionRecord> =
                    {
                        name: [t("duplicateUserCollectionError")],
                    };
                validationResult = duplicate;
            } else {
                ToastManager.error(t("oopsErrorOccurred"));
            }
        } finally {
            setUserCollectionCreateLoading(false);
            return { result, validationResult };
        }
    };

    // #endregion Hooks

    return {
        createUserCollection,
        loading,
    };
}
