import { DataTestAttributes } from "interfaces/data-test-attributes";
import React, { forwardRef } from "react";
import { AriaRole } from "utilities/enumerations/aria-role";

export interface FormProps extends Pick<DataTestAttributes, "dataTestId"> {
    action?: string;
    ariaRole?: AriaRole;
    buttonText?: string;
    children?: React.ReactNode;
    cssClassName?: string;
    id?: string;
    method?: string;
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    ref?: React.RefObject<HTMLFormElement> | null;
}

const Form: React.RefForwardingComponent<HTMLFormElement, FormProps> =
    forwardRef((props: FormProps, ref: React.Ref<HTMLFormElement>) => {
        const onSubmit =
            props.onSubmit ??
            ((e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });

        return (
            <form
                action={props.action}
                data-test-id={props.dataTestId}
                id={props.id}
                onSubmit={onSubmit}
                method={props.method}
                noValidate={true}
                ref={ref}
                role={props.ariaRole}
                className={props.cssClassName || "c-form"}>
                {props.children}
            </form>
        );
    });

export default Form;
