import * as React from "react";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import TiaType from "models/enumerations/tia-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TiaIndicatorIconProps {
    type: TiaType | undefined;
    cssClassName?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const TiaIndicatorIcon: React.FunctionComponent<TiaIndicatorIconProps> = (
    props: TiaIndicatorIconProps
) => {
    const { type, cssClassName = "c-icon -medium c-tia-indicator__icon" } =
        props;

    // const type = tias.at(-1)?.type;

    switch (type) {
        case TiaType.Deletion:
            return <Icon type={Icons.TiaDelete} cssClassName={cssClassName} />;
        case TiaType.Addition:
            return <Icon type={Icons.TiaAdd} cssClassName={cssClassName} />;
        case TiaType.Change:
            return <Icon type={Icons.TiaChange} cssClassName={cssClassName} />;
        default:
            return null;
    }
};

// #endregion Component

export default TiaIndicatorIcon;
