import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import SituationCategoryRecord from "models/view-models/situational-navigation/situations/situation-category-record";
import { useCallback, useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import NumberUtils from "utilities/number-utils";
import AdminCategoryService from "utilities/services/admin/situational-navigation/categories/admin-category-service";
import AdminSituationCategoryService from "utilities/services/admin/situational-navigation/situations/admin-situation-category-service";
import { ToastManager } from "utilities/toast/toast-manager";

/**
 * Custom hook wrapper to handle state and api interactions for SituationCategories in the
 * administrative editors
 *
 * @param  {string} situationId?
 */
export default function useAdminSituationCategories(situationId?: string) {
    // -----------------------------------------------------------------------------------------
    // #region State Hooks
    // -----------------------------------------------------------------------------------------

    const [initialCategories, setInitialCategories] =
        useState<CategoryCollectionRecord>(new CategoryCollectionRecord());
    const [categories, setCategories] = useState(
        new CategoryCollectionRecord()
    );
    const [loadingCategories, setLoadingCategories] = useState(false);

    // #endregion State Hooks

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { list: listCategoriesApi } = AdminCategoryService.useList();
    const { update: updateSituationCategoriesApi } =
        AdminSituationCategoryService.useUpdate();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    const initializeCategories = useCallback(
        (collection?: CategoryCollectionRecord) => {
            setInitialCategories(collection ?? new CategoryCollectionRecord());
            setCategories(collection ?? new CategoryCollectionRecord());
        },
        []
    );

    const updateCategories = useCallback(
        async (situationId?: number) => {
            if (situationId == null) {
                return;
            }

            const situationCategories = categories.toList().map(
                (c: CategoryRecord) =>
                    new SituationCategoryRecord({
                        situationId: situationId!,
                        categoryId: c.id!,
                    })
            );
            try {
                await updateSituationCategoriesApi(
                    situationCategories.toArray(),
                    {
                        situationId,
                    }
                );
                setInitialCategories(categories);
            } catch (e) {
                ToastManager.error(
                    "There was an issue updating situation categories."
                );
            }
        },
        [categories, updateSituationCategoriesApi]
    );

    useEffect(() => {
        const loadCategories = async (situationId: number) => {
            setLoadingCategories(true);

            try {
                const listCategoriesResult = await listCategoriesApi({
                    situationId,
                });
                if (
                    CollectionUtils.hasValues(
                        listCategoriesResult.resultObjects
                    )
                ) {
                    // even though the fromArray method does it's own null-check,
                    // it still breaks in IE11 without this conditional here for some reason...
                    const collection = CategoryCollectionRecord.fromArray(
                        listCategoriesResult.resultObjects
                    );
                    initializeCategories(collection);
                } else {
                    initializeCategories();
                }
            } catch (e) {
                ToastManager.error(
                    "There was an issue loading related categories."
                );
            }

            setLoadingCategories(false);
        };

        const id = NumberUtils.parseInt(situationId);
        if (id == null) return;

        loadCategories(id);
    }, [situationId, listCategoriesApi, initializeCategories]);

    // #endregion Public Methods

    return {
        categories,
        initialCategories,
        initializeCategories,
        loadingCategories,
        setCategories,
        updateCategories,
    };
}
