const chapters = "chapters";
const annexes = "annexes";
const preview = "preview";
const articles = "articles";
const groups = "groups";

const pathAsArray = (route: string) => route.split("#")[0].split("/") ?? [];

const getParentNfpaLabelFromRoute = (route: string) => {
    const labelType = pathAsArray(route).some((element) => element === annexes)
        ? annexes
        : chapters;
    const indexOfChapterLabel = pathAsArray(route).findIndex(
        (element) => element === labelType
    );
    return pathAsArray(route)[indexOfChapterLabel + 1] ?? undefined;
};

const getChapterNfpaLabelFromRoute = (route: string) => {
    return pathAsArray(route).some((element) => element === chapters)
        ? getParentNfpaLabelFromRoute(route)
        : undefined;
};

const getAnnexNfpaLabelFromRoute = (route: string) => {
    return pathAsArray(route).some((element) => element === annexes)
        ? getParentNfpaLabelFromRoute(route)
        : undefined;
};

const getAnnexIdFromRoute = (route: string) => {
    return pathAsArray(route).some((element) => element === preview) &&
        pathAsArray(route).some((element) => element === annexes)
        ? parseInt(getParentNfpaLabelFromRoute(route))
        : undefined;
};

const getArticleNfpaLabelFromRoute = (route: string) => {
    return (
        pathAsArray(route)[
            pathAsArray(route).findIndex((element) => element === articles) + 1
        ] ?? undefined
    );
};

const getArticleIdFromRoute = (route: string) => {
    return (
        parseInt(
            pathAsArray(route)[
                pathAsArray(route).findIndex(
                    (element) => element === articles
                ) + 1
            ]
        ) ?? undefined
    );
};

const getChapterIdFromRoute = (route: string) => {
    return pathAsArray(route).some((element) => element === preview) &&
        pathAsArray(route).some((element) => element === chapters)
        ? parseInt(getParentNfpaLabelFromRoute(route))
        : undefined;
};

const getGroupByIdFromRoute = (route: string) => {
    const segments = pathAsArray(route);
    const groupsIndex = segments.findIndex((element) => element === groups);

    return groupsIndex >= 0 ? segments[groupsIndex + 1] : null;
};

export {
    getAnnexNfpaLabelFromRoute,
    getChapterNfpaLabelFromRoute,
    getChapterIdFromRoute,
    getAnnexIdFromRoute,
    getArticleNfpaLabelFromRoute,
    getArticleIdFromRoute,
    getGroupByIdFromRoute,
};
