import Anchor from "atoms/anchors/anchor";
import PublicationRecord from "models/view-models/publication-record";
import React, { useState } from "react";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import { useLocalization } from "utilities/hooks/use-localization";
import StringUtils from "utilities/string-utils";
import PublicationListItemActions from "./publication-list-item-actions";
import { PublicationsListProps } from "./publications-list";
import CultureResources from "utilities/interfaces/culture-resources";

const BASE_CLASS = "c-publication-selection-list-group-item";

export enum PublicationTextStyle {
    EditionFirst = "-edition-first",
    TitleFirst = "-title-first",
}

type SharedProps = Pick<
    PublicationsListProps,
    "favorites" | "onChangeFavorites" | "onResultClick"
>;

interface PublicationListItemProps extends SharedProps {
    isNested?: boolean;
    publication: PublicationRecord;
    style: PublicationTextStyle;
}

const PublicationListItem: React.FunctionComponent<PublicationListItemProps> = (
    props
) => {
    const {
        children,
        favorites,
        isNested,
        onChangeFavorites,
        onResultClick = () => {},
        publication,
        style,
    } = props;

    const [isFocused, setIsFocused] = useState(false);

    const { isFavorited } = useUpdatePublicationFavorite(
        publication,
        favorites,
        onChangeFavorites
    );

    const coverImageSrc = publication.coverImageFile?.presignedUrl;

    const hasCoverImageSrc = StringUtils.hasValue(coverImageSrc);

    const modifierClasses = [];

    if (isFavorited) {
        modifierClasses.push(" -favorite");
    }

    if (isFocused) {
        modifierClasses.push(" -focused");
    }

    if (isNested) {
        modifierClasses.push(" -nested");
    }

    return (
        <div
            className={`${BASE_CLASS} ${modifierClasses.join(" ")}`}
            onBlurCapture={() => setIsFocused(false)}
            onFocusCapture={() => setIsFocused(true)}>
            <div className={`${BASE_CLASS}__item_parent`}>
                <Anchor
                    onClick={(e) => onResultClick()}
                    cssClassName={`${BASE_CLASS}__item ${style.toString()}`}
                    to={publication.getRoute()}>
                    {hasCoverImageSrc ? (
                        <img
                            className={`${BASE_CLASS}__cover`}
                            src={coverImageSrc}
                            alt={`${publication.getDisplayCodeEditionAndTitle()} cover`}
                            // @ts-ignore
                            loading="lazy"
                        />
                    ) : (
                        <div className={`${BASE_CLASS}__cover`} />
                    )}
                    <div>
                        <PublicationText
                            publication={publication}
                            style={style}>
                            {children}
                        </PublicationText>
                    </div>
                </Anchor>
                <PublicationListItemActions
                    publication={publication}
                    favorites={favorites}
                    onChangeFavorites={onChangeFavorites}
                />
            </div>
        </div>
    );
};

function PublicationText(
    props: React.PropsWithChildren<
        Pick<PublicationListItemProps, "publication" | "style">
    >
) {
    const { children, publication, style } = props;
    const { t } = useLocalization<CultureResources>();

    const edition = (
        <div className={`${BASE_CLASS}__item__edition`}>
            {publication.edition} {t("edition")}
        </div>
    );

    const isBold = style === PublicationTextStyle.TitleFirst;

    const title = (
        <div className={`${BASE_CLASS}__item__title${isBold ? " -bold" : ""}`}>
            {publication.getDisplayTitle(false)}
        </div>
    );

    if (style === PublicationTextStyle.EditionFirst) {
        return (
            <React.Fragment>
                {edition}
                {title}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {title}
            <div className={`${BASE_CLASS}__toggle`}>
                {edition}
                {children}
            </div>
        </React.Fragment>
    );
}

export default PublicationListItem;
