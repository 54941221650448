import ServiceHookFactory from "utilities/services/service-hook-factory";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface EnhancedContentBasePathParams {}

export interface EnhancedContentResourcePathParams
    extends EnhancedContentBasePathParams {
    id: number;
}

/**
 * Search parameters when listing EnhancedContentResource records.
 * These get translated to QueryString parameters
 */
export interface EnhancedContentListQueryParams {
    enhancedContentIds?: number[];
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "enhancedcontentresources";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = EnhancedContentResourceRecord;
const key = "enhanacedcontentresources";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------

const EnhancedContentResourceService = {
    useGet: ServiceHookFactory.useGet<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(resourceType, resourceEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        EnhancedContentResourceRecord,
        EnhancedContentResourcePathParams
    >(key, resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useList<
        EnhancedContentResourceRecord,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),

    useBatchList: ServiceHookFactory.useBatchedList<
        EnhancedContentResourceRecord,
        EnhancedContentListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default EnhancedContentResourceService;

// #region Exports
