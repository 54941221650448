import { Record } from "immutable";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import type Category from "models/interfaces/situational-navigation/categories/category";
import { siteMap } from "internal-sitemap";
import { RouteUtils } from "utilities/route-utils";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: Category =
    RecordUtils.auditableDefaultValuesFactory<Category>({
        type: CategoryType.Equipment,
        title: "",
        description: "",
    });

export default class CategoryRecord
    extends Record(defaultValues)
    implements Category
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Category) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.title == null) {
            params.title = "";
        }

        if (params.description == null) {
            params.description = "";
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public getEditPageRoute(): string | undefined {
        if (this.id == null) {
            return undefined;
        }

        let baseRoute: string;
        switch (this.type) {
            case CategoryType.Equipment:
                baseRoute =
                    siteMap.admin.situationalNavigation.categories.equipment
                        .edit;
                break;
            case CategoryType.Occupancies:
                baseRoute =
                    siteMap.admin.situationalNavigation.categories.occupancies
                        .edit;
                break;
            case CategoryType.Systems:
                baseRoute =
                    siteMap.admin.situationalNavigation.categories.systems.edit;
                break;
            case CategoryType.Spaces:
            default:
                baseRoute =
                    siteMap.admin.situationalNavigation.categories.spaces.edit;
                break;
        }

        return RouteUtils.getUrl(baseRoute, { id: this.id });
    }

    public hasDescription(): boolean {
        return this.description != null && this.description.length > 0;
    }

    public with(values: Partial<Category>): CategoryRecord {
        return new CategoryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
