import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

const SupportUsersBreadcrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">LiNK Subscribers</span>
    </Breadcrumb>
);

export default SupportUsersBreadcrumb;
