import Anchor from "atoms/anchors/anchor";
import { Icons } from "atoms/constants/icons";
import TextInputIcon from "atoms/forms/text-input-icon";
import Paragraph from "atoms/typography/paragraph";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import CategorySidebarListItems from "organisms/admin/situational-navigation/categories/category-sidebar-list-items";
import React, { useEffect, useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import useDebounce from "utilities/hooks/use-debounce";
import useSortAlphabeticallyReducer from "utilities/reducers/use-sort-alphabetically-reducer";
import { CategoryUtils } from "utilities/situational-navigation/categories/category-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CategoryEditorPageSidebarProps {
    type: CategoryType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const LAYOUT_CLASS_NAME = "c-admin-editor-page-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategoryEditorPageSidebar: React.FC<CategoryEditorPageSidebarProps> = (
    props: CategoryEditorPageSidebarProps
) => {
    const { context } = useAdminEditorPageContext();
    const [searchText, setSearchText] = useState("");
    const debouncedSearchText = useDebounce(searchText);
    const [allCategories, setAllCategories] = useState(
        context.categories.toArray(props.type)
    );
    const [values, setValues] = useSortAlphabeticallyReducer(
        allCategories,
        (c: CategoryRecord) => c.title
    );

    const handleSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchText(e.target.value);

    useEffect(
        () => setAllCategories(context.categories.toArray(props.type)),
        [context.categories, props.type]
    );

    useEffect(() => {
        if (StringUtils.isEmpty(debouncedSearchText)) {
            setValues(allCategories);
            return;
        }

        setValues(
            allCategories.filter(
                (c: CategoryRecord) =>
                    StringUtils.isEqual(c.title, debouncedSearchText) ||
                    StringUtils.isEqual(c.description, debouncedSearchText)
            )
        );
    }, [debouncedSearchText, allCategories, setValues]);

    return (
        <div className={`${LAYOUT_CLASS_NAME}__content__left`}>
            <TextInputIcon
                icon={Icons.Search}
                id="situation-sidebar-search"
                onChange={handleSearchTextChanged}
                value={searchText}
                placeholder="Filter by category title or description..."
            />
            <div
                className={`${LAYOUT_CLASS_NAME}__content__left__list`}
                tabIndex={-1}>
                {CollectionUtils.isEmpty(values) && (
                    <Paragraph cssClassName="-no-records-message">
                        No Categories
                    </Paragraph>
                )}
                {CollectionUtils.hasValues(values) && (
                    <CategorySidebarListItems
                        baseClassName={`${LAYOUT_CLASS_NAME}`}
                        items={values}
                        type={props.type}
                    />
                )}
            </div>
            <div className={`${LAYOUT_CLASS_NAME}__content__left__footer`}>
                <Anchor
                    cssClassName="c-button"
                    to={CategoryUtils.getCreateRouteForType(props.type)}>
                    New Category
                </Anchor>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoryEditorPageSidebar;

// #endregion Exports
