import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import ArticleRecord from "models/view-models/article-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface ArticleResourcePathParams {
    id: number;
}

export interface ArticleListQueryParams {
    /**
     * (Optional) Array of ArticleIds to filter results by.
     */
    articleIds?: number[];

    /**
     * (Optional) Publication Code to filter by
     */
    code?: string;

    /**
     * (Optional) Publication Edition to filter by
     */
    edition?: string;

    /**
     * (Optional) Article NfpaLabel to filter by
     */
    nfpaLabel?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "articles";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = ArticleRecord;
const key = "articles";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const ArticleService = {
    get: ServiceFactory.get<ArticleRecord, ArticleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),
    list: ServiceFactory.list<ArticleRecord, ArticleListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useBatchList: ServiceHookFactory.useBatchedList<
        ArticleRecord,
        ArticleListQueryParams
    >(resourceType, baseEndpoint),

    useGet: ServiceHookFactory.useGet<ArticleRecord, ArticleResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGetQuery: QueryHookFactory.useGetQuery<
        ArticleRecord,
        ArticleResourcePathParams
    >(key, resourceType, resourceEndpoint),

    useList: ServiceHookFactory.useList<ArticleRecord, ArticleListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useListQuery: QueryHookFactory.useListQuery<
        ArticleRecord,
        ArticleListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default ArticleService;

// #endregion Exports
