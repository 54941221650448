import { DependencyList, useRef } from "react";
import _ from "lodash";

/**
 * Similar to `React.useMemo`, with the addition of a deep comparison on the dependency list.
 * Dependencies with different references but with the same values will be equal and not require
 * additional memoization
 */
export default function useDeepCompareMemo<T>(
    valueCallback: () => T,
    deps: DependencyList
): T {
    const prevDeps = useRef<DependencyList>([]);
    const value = useRef<T>();

    if (
        value.current != null &&
        (deps === prevDeps.current || _.isEqual(deps, prevDeps.current))
    ) {
        return value.current;
    }

    prevDeps.current = deps;
    value.current = valueCallback();

    return value.current;
}
