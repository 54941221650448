import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import { LoaderStyles } from "atoms/constants/loader-styles";
import Loader from "atoms/loaders/loader";
import PublicationTypes from "constants/publication-types";
import { AdminPreviewParams } from "interfaces/routing/admin-preview-params";
import { ChapterNfpaLabelParams } from "interfaces/routing/chapter-nfpa-label-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { NfpaLabelParams } from "interfaces/routing/nfpa-label-params";
import UnorderedList from "molecules/lists/unordered-list";
import PublicationSidebarNav from "molecules/sidebar-chapter-navigation/publication-sidebar-nav";
import PartsList from "organisms/parts-list/parts-list";
import React from "react";
import { match } from "react-router-dom";
import { CollectionUtils } from "utilities/collection-utils";
// import { usePublicationPageContext } from "utilities/contexts/use-publication-page-context";
import useParts from "utilities/hooks/domain/publications/chapters/articles/parts/use-parts";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import { useLocationHash } from "utilities/hooks/use-location-hash";
import { t } from "utilities/localization-utils";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import ChapterTableOfContentsRecord from "models/view-models/table-of-contents/chapter-table-of-contents-record";
import ArticleTableOfContentsRecord from "models/view-models/table-of-contents/article-table-of-contents-record";
import SectionTableOfContentsRecord from "models/view-models/table-of-contents/section-table-of-contents-record";
import NavItem from "molecules/sidebar-chapter-navigation/nav-item";
import { SectionParams } from "interfaces/routing/route-params";
import useListArticleSections from "utilities/hooks/domain/publications/use-list-article-sections";
import useDebounce from "utilities/hooks/use-debounce";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { SectionParentType } from "utilities/enumerations/section-parent-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ArticleMetaNavProps {
    match: match<
        NestedPublicationParams &
            ChapterNfpaLabelParams &
            NfpaLabelParams &
            AdminPreviewParams
    >;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ArticleMetaNav: React.FunctionComponent<ArticleMetaNavProps> = (
    props: ArticleMetaNavProps
) => {
    const { match } = props;
    const {
        chapterNfpaLabel,
        nfpaLabel,
        id: articleId,
        chapterId,
    } = match.params;
    const { publication } = useCurrentPublication();
    const { isAdminPreview } = useAdminPreview();
    const hash = useLocationHash();

    const { tableOfContents } = useBookviewTableOfContents();
    const { chapters } = tableOfContents;
    const chapter =
        chapters?.find(
            (c) => c.id === +chapterId || c.nfpaLabel === chapterNfpaLabel
        ) ?? new ChapterTableOfContentsRecord();
    const article =
        chapter?.articles?.find(
            (a) => a.id === +articleId || a.nfpaLabel === nfpaLabel
        ) ?? new ArticleTableOfContentsRecord();

    useListArticleSections(article.id, publication?.id);

    const sections = article.sections ?? [];

    const sectionsWithPartId =
        sections?.filter((s) => s.partId != null && !s.isDeleted) ?? [];
    const sectionsWithoutPartId =
        sections?.filter((s) => s.partId == null && !s.isDeleted) ?? [];

    const {
        errors: partErrors,
        loading: partsLoading,
        resultObject: parts,
    } = useParts({
        articleId: article?.id,
        chapterId: chapter?.id,
        publicationId: publication?.id,
    });

    const loading = useDebounce(useLoading(partsLoading), 50);

    const errors = useErrors(partErrors);

    useErrorBoundary(errors);

    const backButtonAccessibleText = t("backToLocation", {
        location: chapter.getDisplayTitle(publication?.locale),
    });
    const backButtonRoute =
        chapter.getRoute(match.params, isAdminPreview) ?? "";

    const renderSidebarWithParts = CollectionUtils.hasValues(parts);

    const renderSections = (partId?: number) =>
        buildNavItemsWithPart(
            sectionsWithPartId,
            { ...match.params, externalId: hash },
            partId,
            publication?.type,
            isAdminPreview
        );

    return (
        <React.Fragment>
            <AnchorWithIcon
                accessibleText={backButtonAccessibleText}
                dataTestId={"back-button"}
                icon={Icons.ChevronLeft}
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                to={backButtonRoute}>
                {chapter.getDisplayTitle(publication?.locale)}
            </AnchorWithIcon>
            {article != null && !loading && (
                <PublicationSidebarNav
                    title={article.getDisplayTitle(publication?.locale)}>
                    {!renderSidebarWithParts &&
                        buildNavItems(
                            sections,
                            { ...match.params, externalId: hash },
                            publication?.type,
                            isAdminPreview
                        )}
                    {renderSidebarWithParts && (
                        <React.Fragment>
                            {CollectionUtils.hasValues(sectionsWithoutPartId) &&
                                buildNavItems(
                                    sectionsWithoutPartId,
                                    { ...match.params, externalId: hash },
                                    publication?.type,
                                    isAdminPreview
                                )}
                            <PartsList
                                paragraphClassName="c-part-title"
                                parts={parts}
                                renderSections={renderSections}
                                sections={
                                    sectionsWithPartId ?? [
                                        new SectionTableOfContentsRecord(),
                                    ]
                                }
                                ulClassName="c-publication-meta-nav-parts-list"
                            />
                        </React.Fragment>
                    )}
                </PublicationSidebarNav>
            )}
            {loading && <Loader type={LoaderStyles.LinkGlyphGray} />}
            {CollectionUtils.hasValues(errors) && (
                <UnorderedList listItems={errors} />
            )}
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------
const buildNavItemsWithPart = (
    sections: SectionTableOfContentsRecord[],
    params: SectionParams,
    partId?: number,
    publicationType: PublicationTypes = PublicationTypes.NEC,
    isAdminPreview: boolean = false
) => {
    return (
        sections
            .filter((s) => s.partId === partId && !s.isDeleted)
            .map((s) => (
                <NavItem
                    key={s.id}
                    hasBookmark={s.hasBookmark}
                    externalId={s.externalId}
                    id={s.id}
                    label={s.getLabel()}
                    route={s.getRoute(
                        params,
                        isAdminPreview,
                        SectionParentType.Article
                    )}
                    shouldScroll={publicationType === PublicationTypes.NEC}
                    title={s.getTitleWithoutLabel()}
                />
            )) ?? [<></>]
    );
};

const buildNavItems = (
    sections: SectionTableOfContentsRecord[],
    params: SectionParams,
    publicationType: PublicationTypes = PublicationTypes.NEC,
    isAdminPreview: boolean = false
) => {
    return (
        sections
            .filter((s) => !s.isDeleted)
            .map((s) => (
                <NavItem
                    key={s.id}
                    hasBookmark={s.hasBookmark}
                    externalId={s.externalId}
                    id={s.id}
                    label={s.getLabel()}
                    route={s.getRoute(
                        params,
                        isAdminPreview,
                        SectionParentType.Article
                    )}
                    shouldScroll={publicationType === PublicationTypes.NEC}
                    title={s.getTitleWithoutLabel()}
                />
            )) ?? [<></>]
    );
};

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default React.memo(ArticleMetaNav);

// #endregion Exports
