import { useEffect } from "react";
import { useLocationHash } from "./use-location-hash";
import useFeatureFlags from "./use-feature-flags";

const useSectionAdjustment = () => {
    const { useReducedRendersInBookview } = useFeatureFlags();
    const hash = useLocationHash();

    useEffect(() => {
        if (useReducedRendersInBookview) {
            let renderTimer: NodeJS.Timeout;
            if (hash.length) {
                renderTimer = setTimeout(() => {
                    const sectionWithHash = document.getElementById(`${hash}`);
                    sectionWithHash?.scrollIntoView();
                }, 500);
            }
            return () => {
                clearTimeout(renderTimer);
            };
        }
    }, [hash, useReducedRendersInBookview]);
};

export default useSectionAdjustment;
