// import "why-did-you-render"; // Must be first import per docs. https://github.com/welldone-software/why-did-you-render

import "babel-polyfill";
import "focus-within-polyfill";
import "proxy-polyfill/proxy.min.js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { Do, DoSync, EnvironmentUtils } from "andculturecode-javascript-core";
import React from "react";
import ReactDOM from "react-dom";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import ServiceWorkerProvider from "utilities/contexts/service-worker/service-worker-provider";
import DoTryDefaultCatchHandler from "utilities/do-try-default-catch-handler";
import PolyfillUtils from "utilities/polyfills/polyfill-utils";
import App from "./app";
import { QueryClient, QueryClientProvider } from "react-query";

// More options available if needed
// Package: https://www.npmjs.com/package/react-gtm-module
const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-W8NLW6L",
};

TagManager.initialize(tagManagerArgs);

/*
---------------------------------------------------------------------------------------------
Application setup
---------------------------------------------------------------------------------------------
*/

EnvironmentUtils.runIfDevelopment(() => {
    Do.configure({ defaultErrorHandler: DoTryDefaultCatchHandler });
    DoSync.configure({ defaultErrorHandler: DoTryDefaultCatchHandler });
});

PolyfillUtils.registerAllPolyfills();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Disabling auto-refetch on focus until there's a good case for it. Currently, causes a pretty big
            // performance hit in book view when the window loses focus, and the API calls we're using for react-query
            // do not change often enough to warrant this eager loading.
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ServiceWorkerProvider>
            <App />
        </ServiceWorkerProvider>
    </QueryClientProvider>,
    document.getElementById("root")
);
