import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import React, { useState } from "react";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import Form from "molecules/forms/form";
import TextAreaFormField from "molecules/form-fields/text-area-form-field";
import { CollectionUtils } from "utilities/collection-utils";
import StringUtils from "utilities/string-utils";
import InputCharacterCount from "atoms/forms/input-character-count";
import FormFieldErrors from "molecules/form-fields/form-field-errors";
import { DataConfiguration } from "constants/data-configuration";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import AdminChangeSummaryService from "utilities/services/admin/change-summaries/change-summary-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface ChangeSummariesAdminNoteModalProps {
    onRefresh: () => void;
    onClose: () => void;
    adminNote: string;
    id: number;
    updateChangeSummary: (changeSummary: ChangeSummaryRecord) => void;
}

const CSS_CLASS_NAME = "c-enhanced-content-admin-note-modal";
const MODAL_LABEL = "Edit Note";
const NOTE_FORM_FIELD_LABEL = "Note Text";

const ChangeSummariesAdminNoteModal = ({
    onRefresh,
    onClose,
    adminNote,
    id,
    updateChangeSummary,
}: ChangeSummariesAdminNoteModalProps) => {
    const [errors, setErrors] = useState(Array<string>());
    const [loading, setLoading] = useState(false);
    const [noteText, setNoteText] = useState(
        StringUtils.hasValue(adminNote) ? adminNote : ""
    );

    const hasErrors = CollectionUtils.hasValues(errors);

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const modalTransition = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();

        setErrors([]);
        setNoteText(event.target.value);
    };

    const handleSave = async () => {
        setErrors([]);
        setLoading(true);
        try {
            const updatedChangeSummary = new ChangeSummaryRecord({
                id,
                adminNote: noteText,
            });
            const result = await AdminChangeSummaryService.update(
                updatedChangeSummary,
                {
                    id: updatedChangeSummary.id,
                }
            );
            if (result?.resultObject == null) throw new Error();
            updateChangeSummary(result.resultObject);
            ToastManager.success("Note added to Change Summary!");
        } catch {
            ToastManager.error("Something went wrong.");
        } finally {
            setLoading(false);
            onRefresh();
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            closeDialog={onClose}
            isVisible={true}
            label={MODAL_LABEL}
            type={ModalTypes.Base}
            transition={modalTransition}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{MODAL_LABEL}</Heading>
                <ModalCloseButton onClick={onClose} />
            </div>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <Form>
                        <TextAreaFormField
                            isValid={!hasErrors}
                            label={NOTE_FORM_FIELD_LABEL}
                            maxLength={DataConfiguration.ShortStringLength}
                            onChange={handleChange}
                            rows={4}
                            value={noteText}
                        />
                        <InputCharacterCount
                            currentLength={noteText.length}
                            maxLength={DataConfiguration.ShortStringLength}
                        />
                        {hasErrors && (
                            <FormFieldErrors errorMessages={errors} />
                        )}
                    </Form>
                </div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    <Button onClick={onClose} style={ButtonStyles.Secondary}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={handleSave}
                        style={ButtonStyles.Primary}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeSummariesAdminNoteModal;
