import StringUtils from "utilities/string-utils";
import SectionRecord from "models/view-models/section-record";
import React, { useEffect, useRef } from "react";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { Content } from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import TiaRecord from "models/view-models/tia-record";
import SectionDetailContent from "./section-detail-content";
import TiaBookviewHeading from "organisms/panels/tia-changes/tia-bookview-heading";
import DeletedSection from "./deleted-section";
import { useLocation } from "react-router-dom";

interface SectionDetailProps {
    allowSectionDetailActions?: boolean;
    classNames: string[];
    cssBaseClass: string;
    diffPanelContent: Content;
    sectionLabel: JSX.Element | undefined;
    handleDiffPanelOpen: () => void;
    handleTiaPanelOpen: (tiaExternalId: string) => void;
    section: SectionRecord;
    tias: TiaRecord[];
    tiaExternalId: string | null | undefined;
    titleClassName: string;
    renderContent?: boolean;
}
const SectionDetail = (props: SectionDetailProps) => {
    const {
        allowSectionDetailActions = true,
        classNames,
        cssBaseClass,
        diffPanelContent,
        sectionLabel,
        handleDiffPanelOpen,
        handleTiaPanelOpen,
        section,
        tias,
        tiaExternalId,
        titleClassName,
        renderContent = true,
    } = props;

    const hasTiaOpen = section.externalId === tiaExternalId;

    const highlightSectionModifier =
        section.externalId === diffPanelContent.externalId || hasTiaOpen
            ? "-highlight-section"
            : "";

    const isDeleted =
        section.changes === XmlChangeNotationConstants.DELETION ||
        section.tiaChanges === XmlChangeNotationConstants.TIA_DELETION;

    const sectionRef = useRef<HTMLElement>(null);
    const { hash } = useLocation();
    useEffect(() => {
        // use includes to account for linking to section or enhanced content
        // enhanced content id - ec.id-ec.externalId
        if (hash.includes(section.externalId)) sectionRef.current?.focus();
    }, [hash, section.externalId]);

    return (
        <section
            data-section-level={section.type}
            data-section-label={section.nfpaLabel}
            className={`${StringUtils.joinClassNames(
                classNames
            )} ${highlightSectionModifier}`}
            id={section.externalId}
            ref={sectionRef}
            tabIndex={-1}>
            {renderContent ? (
                <>
                    {isDeleted ? (
                        <DeletedSection
                            handleDiffPanelOpen={handleDiffPanelOpen}
                            handleTiaClick={handleTiaPanelOpen}
                            hasTiaOpen={hasTiaOpen}
                            section={section}
                            tias={tias}
                            cssBaseClass={cssBaseClass}
                        />
                    ) : (
                        <>
                            {hasTiaOpen && <TiaBookviewHeading tias={tias} />}
                            <SectionDetailContent
                                allowSectionDetailActions={
                                    allowSectionDetailActions
                                }
                                sectionLabel={sectionLabel}
                                cssBaseClass={cssBaseClass}
                                handleDiffPanelOpen={handleDiffPanelOpen}
                                handleTiaPanelOpen={handleTiaPanelOpen}
                                section={section}
                                tias={tias}
                                titleClassName={titleClassName}
                            />
                        </>
                    )}
                </>
            ) : (
                section.getEmptySections()
            )}
        </section>
    );
};

export default SectionDetail;
