import Paragraph from "atoms/typography/paragraph";
import Tooltip, { FollowCursorPlacement } from "molecules/tooltips/tooltip";
import React, { useRef } from "react";
import useTextOverflow from "utilities/hooks/use-text-overflow";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RelatedSolutionTitleProps {
    children: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const TOOLTIP_DELAY_IN_MS = 500;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RelatedSolutionTitle: React.FC<RelatedSolutionTitleProps> = (
    props: RelatedSolutionTitleProps
) => {
    const { children } = props;
    const pRef = useRef<HTMLParagraphElement>(null);
    const isOverflowed = useTextOverflow(pRef);

    return (
        <Tooltip
            content={children}
            delayInMs={TOOLTIP_DELAY_IN_MS}
            disabled={!isOverflowed}
            followCursor={FollowCursorPlacement.Initial}>
            <Paragraph ref={pRef}>{children}</Paragraph>
        </Tooltip>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RelatedSolutionTitle;

// #endregion Exports
