import * as React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import ApplicationLayout from "./application-layout";
import OnboardingLayout from "./onboarding-layout";
import useUserConfiguration from "utilities/hooks/aspect/use-user-configuration";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import { NestedRoutes } from "utilities/routing/nested-route";

const SupportLayout: React.FC<any> = (props: any) => {
    const { globalState } = useGlobalState();
    const { loaded, resultObject: userConfiguration } = useUserConfiguration();

    if (!loaded) {
        return <FullScreenTransition transitionText="Loading" />;
    }

    if (globalState.isAuthenticated() && userConfiguration?.isValid) {
        return <ApplicationLayout routes={props.routes} />;
    }

    return (
        <OnboardingLayout>
            <NestedRoutes routes={props.routes} />
        </OnboardingLayout>
    );
};

export default SupportLayout;
