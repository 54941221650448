import SectionRecord from "models/view-models/section-record";
import { useEffect, useState } from "react";
import usePageErrors from "utilities/hooks/use-page-errors";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import SectionService from "utilities/services/publications/sections/section-service";

/**
 * Custom hook for getting Section data for pages
 *
 * Ids are being passed in as strings to allow for easier consumer use with `useParams()` hook
 *
 * Note: To conditionally prevent this hook from running, you can pass in any of the ids as `null`
 * or `undefined`.
 * @param {string | number} publicationId
 * @param {string | number} sectionId
 */
export default function useSection(
    publicationId?: string | number,
    sectionId?: string | number
): ServiceHookResult<SectionRecord> {
    const [section, setSection] = useState<SectionRecord>(new SectionRecord());
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const { get: getSectionApi } = SectionService.useGet();
    const {
        handlePageLoadError,
        pageErrors: errors,
        resetPageErrors,
    } = usePageErrors();

    useEffect(() => {
        const getSection = async (
            publicationId?: number,
            sectionId?: number
        ) => {
            if (publicationId == null || sectionId == null) {
                return;
            }

            setLoading(true);

            try {
                const response = await getSectionApi({
                    publicationId: publicationId,
                    id: sectionId,
                });

                setSection(response.resultObject ?? new SectionRecord());
                setLoading(false);
                resetPageErrors();
            } catch (result) {
                handlePageLoadError(result);
            }

            setLoaded(true);
        };

        getSection(
            NumberUtils.parseInt(publicationId),
            NumberUtils.parseInt(sectionId)
        );
    }, [
        getSectionApi,
        handlePageLoadError,
        publicationId,
        resetPageErrors,
        sectionId,
    ]);

    return {
        errors,
        loaded,
        loading,
        resultObject: section,
    };
}
