import * as React from "react";
import SignupContextRecord from "models/view-models/signup-context-record";
import { useState } from "react";
import SignupContext from "utilities/contexts/signup/use-signup-context";
import OnboardingLayout from "templates/onboarding-layout";
import useMarketingQueryParams from "utilities/hooks/use-marketing-query-params";

const SignupLayout: React.FC<any> = (props: any) => {
    const [signupContext, setSignupContext] = useState<SignupContextRecord>(
        new SignupContextRecord()
    );

    const { setMarketingQueryParams } = useMarketingQueryParams();
    setMarketingQueryParams();

    return (
        <SignupContext.Provider value={[signupContext, setSignupContext]}>
            <OnboardingLayout {...props}>{props.children}</OnboardingLayout>
        </SignupContext.Provider>
    );
};

export default SignupLayout;
