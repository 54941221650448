import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import { BillingCycleOption } from "constants/billing-cycle-options";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import SubscriptionType from "organisms/enums/subscription-type";
import BillingCycleRadioButtons from "organisms/subscription-card/billing-cycle-radio-buttons";
import TeamSizeSelect from "organisms/subscription-card/team-size-select";
import React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SelectedSubscriptionCardProps {
    erroredBillingCycle?: boolean;
    erroredTeamSize?: boolean;
    onCheckBillingCycle: (option: BillingCycleOption) => void;
    onChangeTeamSize: (teamSize: SubscriptionTeamSize) => void;
    selectedBillingCycle?: BillingCycleOption;
    selectedSubscriptionType?: SubscriptionType;
    selectedTeamSize?: SubscriptionTeamSize;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-selected-subscription-card";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SelectedSubscriptionCard: React.FunctionComponent<
    SelectedSubscriptionCardProps
> = (props: SelectedSubscriptionCardProps) => {
    const {
        erroredBillingCycle,
        erroredTeamSize,
        onCheckBillingCycle,
        onChangeTeamSize,
        selectedBillingCycle: billingCycle,
        selectedSubscriptionType: type,
        selectedTeamSize: teamSize,
    } = props;

    const { globalState } = useGlobalState();

    const freeTrialModifier =
        type === SubscriptionType.FreeTrial ? "-free-trial" : "";
    const signedInModifier =
        globalState.currentIdentity != null ? "-signed-in" : "";
    const classNames = StringUtils.joinClassNames([
        BASE_CLASS,
        freeTrialModifier,
        signedInModifier,
    ]);
    const iconType =
        type === SubscriptionType.Team ? Icons.Team : Icons.Individual;
    const showBillingCycles = type !== SubscriptionType.FreeTrial;
    const billingCycleTitle =
        billingCycle != null
            ? t("billingCycle-selectABillingCycle", {
                  subscriptionType: "",
              })
            : t("billingCycle-selectABillingCycle", {
                  subscriptionType: "a",
              });

    const subscriptionName = getSubscriptionName(type);

    if (type == null) {
        return null;
    }

    return (
        <div className={classNames}>
            <div className={`${BASE_CLASS}__subscription`}>
                <span>{t("subscriptionCard-selectedPlan").toUpperCase()}</span>
                <div>
                    <Icon size={IconSizes.Large} type={iconType} />
                    <span>{subscriptionName}</span>
                </div>
                <PlansAnchor cssClassName="c-button -tertiary">
                    {t("subscriptionCard-changePlan")}
                </PlansAnchor>
            </div>
            {showBillingCycles && (
                <div>
                    {type === SubscriptionType.Team && (
                        <TeamSizeSelect
                            errored={erroredTeamSize}
                            onChange={onChangeTeamSize}
                            requiredIndicator={false}
                            value={teamSize}
                        />
                    )}
                    {(type !== SubscriptionType.Team || teamSize != null) && (
                        <div className={`${BASE_CLASS}__radios`}>
                            <BillingCycleRadioButtons
                                errored={erroredBillingCycle}
                                onCheck={onCheckBillingCycle}
                                teamSize={teamSize}
                                title={billingCycleTitle}
                                type={type}
                                value={billingCycle}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getSubscriptionName = (type?: SubscriptionType): string | null => {
    if (type == null) {
        return null;
    }

    return t(`subscriptionType-${type.toString()}` as keyof CultureResources);
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SelectedSubscriptionCard;

// #endregion Export
