import { Record } from "immutable";
import type OfflineBookUrls from "models/interfaces/offline/offline-book-urls";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: OfflineBookUrls =
    RecordUtils.defaultValuesFactory<OfflineBookUrls>({
        urls: [],
    });

export default class OfflineBookUrlsRecord
    extends Record(defaultValues)
    implements OfflineBookUrls
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: OfflineBookUrls) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<OfflineBookUrls>} values
     */
    public with(values: Partial<OfflineBookUrls>): OfflineBookUrlsRecord {
        return new OfflineBookUrlsRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}
