import { SkipNavContent } from "@reach/skip-nav";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import AlertLevels from "constants/alert-levels";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import AlertBanner from "molecules/alerts/alert-banner";
import AccountExpirationAlerts from "organisms/alerts/account-expiration-alerts";
import DownloadedPublications from "organisms/downloads/downloaded-publications";
import React from "react";
import { t } from "utilities/localization-utils";
import uuid from "uuid";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import RecentBookmarksList from "organisms/recent-bookmarks-list/recent-bookmarks-list";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import GettingStartedDashboardPanel from "organisms/getting-started-dashboard-panel/getting-started-dashboard-panel";
import { Redirect } from "react-router-dom";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { siteMap } from "internal-sitemap";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface OfflineDashboardProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-dashboard";
const DATA_TEST_ID_OFFLINE_WARNING = "offline-warning";
const META_TAGS: Array<MetaTag> = [
    {
        name: MetaTagTypes.Title,
        content: "NFPA LiNK® Offline Dashboard",
    },
    {
        name: MetaTagTypes.Description,
        content: "NFPA LiNK®: Content & Activity",
    },
];

const TITLE_ID = uuid.v4();

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const OfflineDashboard: React.FunctionComponent<OfflineDashboardProps> = (
    props: OfflineDashboardProps
) => {
    useHeaderData({ metaTags: META_TAGS });
    const { useOfflineFeatures } = useFeatureFlags();
    const networkState = useNetworkInformation();

    // Leaving this empty until we implement recent downloaded bookmarks
    const bookmarks: Array<UserBookmarkRecord> = [];

    if (useOfflineFeatures && networkState.isOnline) {
        return <Redirect to={siteMap.dashboards.user} />;
    }

    return (
        <React.Fragment>
            <SkipNavContent>
                <div className={`${BASE_CLASS_NAME} -offline`}>
                    <div className={`${BASE_CLASS_NAME}__content`}>
                        <div
                            className={`${BASE_CLASS_NAME}__panel -left`}
                            tabIndex={-1}>
                            <AccountExpirationAlerts />
                            <div className={"c-account-expiration-alerts"}>
                                <AlertBanner alertLevel={AlertLevels.Warning}>
                                    <Paragraph
                                        dataTestId={
                                            DATA_TEST_ID_OFFLINE_WARNING
                                        }
                                        size={ParagraphSizes.Small}>
                                        {t("offline-dashboard-banner-message")}
                                    </Paragraph>
                                </AlertBanner>
                            </div>
                            <div
                                className={`${BASE_CLASS_NAME}__panel -left__title`}>
                                <Icon
                                    type={Icons.Book}
                                    size={IconSizes.Large}
                                />
                                <Paragraph
                                    id={TITLE_ID}
                                    size={ParagraphSizes.Large}>
                                    {t("download_plural")}
                                </Paragraph>
                            </div>

                            <nav
                                aria-labelledby={TITLE_ID}
                                className={`${BASE_CLASS_NAME}__publications`}>
                                <DownloadedPublications />
                            </nav>
                        </div>
                        <div className={`${BASE_CLASS_NAME}__panel -right`}>
                            <RecentBookmarksList
                                bookmarks={bookmarks}
                                disabled={true}
                                loaded={true}
                                loading={false}
                            />
                            <GettingStartedDashboardPanel
                                cssClassName={`${BASE_CLASS_NAME}__panel -right__getting-started`}
                            />
                        </div>
                    </div>
                </div>
            </SkipNavContent>
        </React.Fragment>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default OfflineDashboard;

// #endregion Export
