import AnnouncementContextRecord from "models/view-models/announcement-context-record";
import { Dispatch, SetStateAction, createContext, useContext } from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type AnnouncementContextUpdater = Dispatch<
    SetStateAction<AnnouncementContextRecord>
>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const defaultState: AnnouncementContextRecord = new AnnouncementContextRecord();
const defaultUpdater: AnnouncementContextUpdater = () => {};
const AnnouncementContext = createContext<
    [AnnouncementContextRecord, AnnouncementContextUpdater]
>([defaultState, defaultUpdater]);

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useAnnouncementContext() {
    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const [announcementContext, setAnnouncementContext] =
        useContext(AnnouncementContext);

    // #endregion Public Properties

    return {
        announcementContext,
        setAnnouncementContext,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AnnouncementContext;

// #endregion Exports
