import React, { FormEvent, useState } from "react";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import { TRAINING_CONTRACT_BASE_CLASS } from "./training-contract-page";
import { t } from "utilities/localization-utils";
import Paragraph from "atoms/typography/paragraph";
import { AppNameWithHtml } from "constants/app-name-tm";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import Form from "molecules/forms/form";
import InputFormField from "molecules/form-fields/input-form-field";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "utilities/hooks/domain/user-settings/use-recaptcha";
import Button, { ButtonTypes } from "atoms/buttons/button";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import CaptchaErrors from "molecules/forms/captcha-errors";
import { UrlParamNames } from "constants/url-param-names";
import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import useAzure from "pages/azure-login/use-azure";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import TrainingContractRecord from "models/view-models/training-contract-record";
import TrainingContractNewUserForm from "organisms/users/training-contract-new-user-form";
import useTrainingContractSignup from "utilities/hooks/domain/users/use-training-contract-signup";
import Loader from "atoms/loaders/loader";
import { LoaderStyles } from "atoms/constants/loader-styles";

interface TrainingContractPageFormProps {
    contractAccessCode: number;
    contractId: number;
    onSuccessfulAccessCode: ({
        id,
        accessCode,
    }: Partial<TrainingContractRecord>) => Promise<void>;
}

const TrainingContractPageForm = (props: TrainingContractPageFormProps) => {
    const { contractAccessCode, contractId, onSuccessfulAccessCode } = props;
    const { isLoggedIn } = useCurrentIdentity();
    const [accessCode, setAccessCode] = useState("");
    const [accessCodeErrors, setAccessCodeErrors] = useState<string[]>([]);

    const { useAzureB2CForSSO } = useFeatureFlags();

    const { signUp } = useAzure();

    const {
        siteKey,
        culture,
        captchaSettings,
        captchaComplete,
        handleRecaptchaChange,
        showCaptchaErrors,
        setShowCaptchaErrors,
    } = useRecaptcha();

    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    const {
        handleFormFieldChange,
        handleEmailFormFieldBlur,
        getEmailFormFieldErrors,
        createUser,
        signupContext,
        errorResult,
        creatingUser,
    } = useTrainingContractSignup();

    const handleSubmit = async (
        e: FormEvent<HTMLFormElement | HTMLButtonElement>
    ) => {
        e.preventDefault();

        if (!captchaComplete) {
            setShowCaptchaErrors(true);
            return;
        }

        if (!accessCode) {
            setAccessCodeErrors([t("accessCodeIsRequired")]);
            return;
        }

        if (!isValidAccessCode(contractAccessCode, accessCode)) {
            setAccessCodeErrors([t("accessCodeIsInvalid")]);
            return;
        }

        const validAccessCode = Number(accessCode);

        try {
            if (isLoggedIn) {
                onSuccessfulAccessCode({
                    accessCode: validAccessCode,
                    id: contractId,
                });
            } else {
                useAzureB2CForSSO
                    ? signUp(
                          `${window.location.pathname}?${UrlParamNames.TrainingContractAccessCode}=${accessCode}`
                      )
                    : await createUserAndRole(validAccessCode, contractId);
            }
        } catch (error) {
            setAccessCodeErrors([t("trainingContractAccessCodeError")]);
        }
    };

    const createUserAndRole = async (accessCode: number, id: number) => {
        await createUser();
        await onSuccessfulAccessCode({
            accessCode,
            id,
        });
    };

    const checkCaptchaChange = () => {
        handleRecaptchaChange();
        if (!useAzureB2CForSSO) handleFormFieldChange("captchaComplete", true);
    };

    const isDisabled =
        !captchaComplete || !accessCode.length || subscriptionFreezeActive;

    return (
        <div className={`${TRAINING_CONTRACT_BASE_CLASS}__formWrapper`}>
            {!isLoggedIn && (
                <>
                    <Heading
                        priority={HeadingPriority.Two}
                        cssClassName={`${TRAINING_CONTRACT_BASE_CLASS}__createAccountHeading`}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t("registerOrLogin-signUpHeader", {
                                    appNameWithHtml: AppNameWithHtml,
                                }),
                            }}
                        />
                    </Heading>
                    <Paragraph>
                        {t("registerOrLogin-signUpQuestion")} <LogInAnchor />.
                    </Paragraph>
                </>
            )}
            <Form
                onSubmit={handleSubmit}
                cssClassName={`${TRAINING_CONTRACT_BASE_CLASS}__form`}>
                {!isLoggedIn && !useAzureB2CForSSO && (
                    <TrainingContractNewUserForm
                        signupContext={signupContext}
                        handleFormFieldChange={handleFormFieldChange}
                        handleEmailFormFieldBlur={handleEmailFormFieldBlur}
                        getEmailFormFieldErrors={getEmailFormFieldErrors}
                        errorResult={errorResult}
                    />
                )}

                <InputFormField
                    label={t("enterYourAccessCode")}
                    required
                    onChange={(e) => {
                        setAccessCodeErrors([]);
                        setAccessCode(e.target.value);
                    }}
                    errorMessages={accessCodeErrors}
                    maxLength={15}
                    isValid={!accessCodeErrors.length}
                    value={accessCode}
                />
                <input
                    type="hidden"
                    name="captcha_settings"
                    value={JSON.stringify(captchaSettings)}
                />
                <input
                    type="hidden"
                    name="orgid"
                    value={captchaSettings.orgId}
                />
                <ReCAPTCHA
                    hl={culture}
                    sitekey={siteKey}
                    onChange={checkCaptchaChange}
                />
                <CaptchaErrors showCaptchaErrors={showCaptchaErrors} />

                {creatingUser ? (
                    <Loader
                        accessibleText="Creating account"
                        type={LoaderStyles.LinkGlyphGray}
                    />
                ) : (
                    <Button
                        cssClassName={`${TRAINING_CONTRACT_BASE_CLASS}__submitButton`}
                        accessibleText={t("submit")}
                        ariaDisabled={isDisabled}
                        disabled={isDisabled}
                        onClick={handleSubmit}
                        type={ButtonTypes.Submit}>
                        {t("completeRegistration")}
                    </Button>
                )}
            </Form>
        </div>
    );
};

export default TrainingContractPageForm;

const isValidAccessCode = (contractCode: number, inputCode: string) => {
    const userCode = Number(inputCode);

    if (Number.isNaN(userCode)) return false;

    return contractCode === userCode;
};
