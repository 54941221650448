import React from "react";
import Paragraph from "atoms/typography/paragraph";
import Button from "atoms/buttons/button";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import Tooltip from "molecules/tooltips/tooltip";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";

// -----------------------------------------------------------------------------------------
// #region enums
// -----------------------------------------------------------------------------------------

export enum CallToActionType {
    Anchor,
    Button,
}

// #endregion enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CallToActionProps {
    message?: string;
    onActionClick?: () => void;
    title: string;
    type?: CallToActionType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-call-to-action";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CallToAction: React.FC<CallToActionProps> = (
    props: CallToActionProps
) => {
    const {
        title,
        message,
        onActionClick,
        type = CallToActionType.Button,
    } = props;

    const { subscriptionFreezeActive, alertMessageContents } =
        useConfigurableAlertMessages();

    return (
        <div className={CSS_CLASS_NAME}>
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__message`}>
                {message}
            </Paragraph>
            {type === CallToActionType.Button ? (
                <Tooltip
                    content={alertMessageContents?.tooltip}
                    disabled={!subscriptionFreezeActive}>
                    <Button onClick={onActionClick}>{title}</Button>
                </Tooltip>
            ) : (
                <PlansAnchor cssClassName={"c-button"} onClick={onActionClick}>
                    {title}
                </PlansAnchor>
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default CallToAction;

// #endregion Exports
