import React from "react";
import { Icons } from "atoms/constants/icons";
import Anchor, { AnchorProps } from "atoms/anchors/anchor";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface GettingStartedLinkProps
    extends Pick<AnchorProps, "to" | "external" | "target"> {
    icon: keyof typeof Icons;
    label: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-getting-started-link";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const GettingStartedLink: React.FC<
    React.PropsWithChildren<GettingStartedLinkProps>
> = (props: React.PropsWithChildren<GettingStartedLinkProps>) => {
    const { children, external, icon, label, target, to } = props;
    const card = () => {
        return (
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <Paragraph>{children}</Paragraph>
                {
                    // if
                    StringUtils.hasValue(props.label) && (
                        <div className={`${CSS_BASE_CLASS_NAME}__label`}>
                            <Paragraph size={ParagraphSizes.XSmall}>
                                {label}
                            </Paragraph>
                        </div>
                    )
                }
            </div>
        );
    };

    return (
        <Anchor
            cssClassName={CSS_BASE_CLASS_NAME}
            external={external}
            target={target}
            to={to}>
            <div className={`${CSS_BASE_CLASS_NAME}__icon`}>
                <Icon type={icon} />
            </div>
            {card()}
        </Anchor>
    );
};

// #endregion Component

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default GettingStartedLink;
