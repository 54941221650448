import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import TextInput, { TextInputProps } from "atoms/forms/text-input";
import Icon from "atoms/icons/icon";
import React, { useRef } from "react";
import { BrowserUtils } from "utilities/browser-utils";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TextInputIconProps extends TextInputProps {
    /**
     * if true, adds a X button to clear the input
     * @default true
     */
    clearable?: boolean;
    cssClassName?: string;
    icon: Icons;
    iconSize?: IconSizes;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TextInputIcon: React.FC<TextInputIconProps> = (
    props: TextInputIconProps
) => {
    const CSS_CLASS_NAME = "c-text-input-icon";
    const classNames = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    if ((props.clearable ?? true) && !BrowserUtils.isIE()) {
        classNames.push("-clearable");
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const handleClearClicked = () => {
        if (inputRef.current == null) {
            return;
        }

        const nativeInputSetterFn = Object.getOwnPropertyDescriptor(
            HTMLInputElement.prototype,
            "value"
        )?.set;
        if (nativeInputSetterFn == null) {
            return;
        }

        const event = new Event("change", { bubbles: true });
        nativeInputSetterFn.call(inputRef.current, "");
        inputRef.current.dispatchEvent(event);
        inputRef.current.focus();
    };

    return (
        <div className={classNames.join(" ")}>
            <Icon type={props.icon} size={props.iconSize ?? IconSizes.Large} />
            <TextInput ref={inputRef} {...props} />
            {(props.clearable ?? true) && !BrowserUtils.isIE() && (
                <Button
                    accessibleText={"Clear value"}
                    cssClassName={`-icon ${
                        StringUtils.hasValue(props.value) ? "-has-value" : ""
                    }`}
                    onClick={handleClearClicked}
                    style={ButtonStyles.TertiaryAlt}>
                    <Icon
                        cssClassName={"-close-icon"}
                        size={props.iconSize ?? IconSizes.Large}
                        type={Icons.Close}
                    />
                </Button>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default TextInputIcon;

// #endregion Exports
