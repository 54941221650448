import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import * as Reach from "@reach/tabs";
import useTabArrows from "utilities/hooks/use-tab-arrows";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface TabListProps extends Reach.TabListProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const LIST_BASE_CLASS = "c-tabs__list";
const WRAPPER_BASE_CLASS = `${LIST_BASE_CLASS}-wrapper`;

// #endregion Constants

/**
 * Extension of the ReachUI TabList component
 * https://reacttraining.com/reach-ui/tabs/
 * @param props
 * @see /documentation/code-conventions-react.md#ReachUI Components##Tabs
 * for a note about usage
 */
const TabList: React.FunctionComponent<TabListProps> = (
    props: TabListProps
) => {
    const {
        setArrowVisibility,
        scrollLeft,
        scrollRight,
        tabListRef,
        leftButtonRef,
        rightButtonRef,
        showLeftArrow,
        showRightArrow,
    } = useTabArrows();

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    /**
     * TabList should only Render <Tab> components
     */
    return (
        <header className={WRAPPER_BASE_CLASS}>
            <Button
                onClick={scrollLeft}
                style={ButtonStyles.None}
                cssClassName={`${WRAPPER_BASE_CLASS}__scroll`}
                hidden={!showLeftArrow}
                ref={leftButtonRef}>
                <Icon size={IconSizes.Base} type={Icons.ChevronLeft} />
            </Button>

            <Button
                onClick={scrollRight}
                style={ButtonStyles.None}
                cssClassName={`${WRAPPER_BASE_CLASS}__scroll -right`}
                hidden={!showRightArrow}
                ref={rightButtonRef}>
                <Icon size={IconSizes.Base} type={Icons.ChevronRight} />
            </Button>

            <Reach.TabList
                className={`${LIST_BASE_CLASS}`}
                ref={tabListRef}
                onScroll={setArrowVisibility}>
                {props.children}
            </Reach.TabList>
        </header>
    );

    // #endregion Component
};

export default TabList;
