import { useState, useCallback, useEffect } from "react";

export interface UseCheckboxHookResult {
    checked: boolean;
    handleToggle: () => void;
}
export default function useCheckbox(
    defaultValue?: boolean
): UseCheckboxHookResult {
    const initialValue = defaultValue ?? false;
    const [checked, setChecked] = useState(initialValue);

    useEffect(() => {
        setChecked(initialValue);
    }, [initialValue]);

    const handleToggle = useCallback(
        () => setChecked((prevValue) => !prevValue),
        []
    );

    return { checked, handleToggle };
}
