import AnnexRecord from "models/view-models/annex-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";

// ---------------------------------------------------------------------------------------------
// #region Interfaces
// ---------------------------------------------------------------------------------------------

export interface AnnexResourcePathParams {
    id: number;
}

export interface AnnexListQueryParams {
    /**
     * (Optional) Array of AnnexIds to filter results by.
     */
    annexIds?: number[];
    code?: string;
    edition?: string;
}

// #endregion Interfaces

// ---------------------------------------------------------------------------------------------
// #region Constants
// ---------------------------------------------------------------------------------------------

const baseEndpoint = "annexes";
const resourceType = AnnexRecord;
const key = "annexes";

// #endregion Constants

// ---------------------------------------------------------------------------------------------
// #region Service
// ---------------------------------------------------------------------------------------------
const AnnexService = {
    key,
    list: ServiceFactory.list<AnnexRecord, AnnexListQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useBatchList: ServiceHookFactory.useBatchedList<
        AnnexRecord,
        AnnexListQueryParams
    >(resourceType, baseEndpoint),

    useList: ServiceHookFactory.useList<AnnexRecord, AnnexListQueryParams>(
        resourceType,
        baseEndpoint
    ),
    useListQuery: QueryHookFactory.useListQuery<
        AnnexRecord,
        AnnexListQueryParams
    >(key, resourceType, baseEndpoint),
};

// #endregion Service

// ---------------------------------------------------------------------------------------------
// #region Exports
// ---------------------------------------------------------------------------------------------

export default AnnexService;

// #endregion Exports
