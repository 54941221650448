import * as React from "react";

import Heading from "atoms/typography/heading";
import { HeadingPriority } from "atoms/constants/heading-priority";
import UnorderedList from "molecules/lists/unordered-list";
import Paragraph from "atoms/typography/paragraph";

export interface CommitteesDivConverterProps {
    children?: JSX.Element[];
    className?: string;
    datalabel?: string;
}

const CommitteesDivConverter = (props: CommitteesDivConverterProps) => {
    switch (props.className) {
        case "nfpa-fm-html_committeelist":
            return (
                <UnorderedList
                    listItems={props.children as JSX.Element[]}
                    cssClassName="c-committee-list"
                />
            );
        case "nfpa-fm-html_committeename":
            return (
                <Heading
                    priority={HeadingPriority.Two}
                    cssClassName="c-committee-list__name">
                    {props.children}
                </Heading>
            );
        case "nfpa-fm-html_committeechapters":
            return (
                <Paragraph cssClassName="c-committee-list__articles">
                    {props.children}
                </Paragraph>
            );
        case "nfpa-fm-html_officers":
            return (
                <div className="c-committee-member-group -officers">
                    {props.children}
                </div>
            );
        case "nfpa-fm-html_principalmembers":
        case "nfpa-fm-html_alternates":
        case "nfpa-fm-html_nonvotingmembers":
        case "nfpa-fm-html_staffingliaison":
            return (
                <div className="c-committee-member-group">{props.children}</div>
            );
        case "nfpa-fm-html_member":
        case "nfpa-fm-html_alternatemember":
            return <div className="c-committee-member">{props.children}</div>;

        default:
            // There are times where props.children is undefined in paragraph elements.
            if (props.children === undefined) {
                return null;
            }

            return props.children;
    }
};

export default CommitteesDivConverter;
