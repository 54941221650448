import Select, { SelectOption } from "atoms/forms/select";
import Paragraph from "atoms/typography/paragraph";
import SituationRecord from "models/view-models/situational-navigation/situations/situation-record";
import SituationRelationshipRecord from "models/view-models/situational-navigation/situations/situation-relationship-record";
import SolutionRecord from "models/view-models/situational-navigation/solutions/solution-record";
import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from "react";
import AdminSituationHotspotModal from "./situations/admin-situation-hotspot-modal";
import { CollectionUtils } from "utilities/collection-utils";
import EventUtils from "utilities/events/event-utils";
import SitNavUtils from "utilities/situational-navigation/sitnav-utils";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AdminHotspotSectionProps {
    cssClassName?: string;
    hotSpots: Array<SituationRelationshipRecord>;
    onChange: (newHotSpots: Array<SituationRelationshipRecord>) => void;
    situations: Array<SituationRecord>;
    solutions: Array<SolutionRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export const HOT_SPOT_ICON_SIZE = 24;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminHotSpotSection: React.FC<
    PropsWithChildren<AdminHotspotSectionProps>
> = (props: PropsWithChildren<AdminHotspotSectionProps>) => {
    const CSS_CLASS_NAME = "c-admin-hotspot-section";
    const [currentHotspot, setCurrentHotSpot] =
        useState<SituationRelationshipRecord>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const selectOptions: Array<SelectOption> = props.hotSpots
        .map((item: SituationRelationshipRecord) => {
            const solution = props.solutions.find(
                (s: SolutionRecord) => s.id === item.relatedSolutionId
            );
            const situation = props.situations.find(
                (s: SituationRecord) => s.id === item.relatedSituationId
            );

            if (solution == null && situation == null) {
                return undefined;
            }

            if (solution != null) {
                if (
                    props.hotSpots.some(
                        (s: SituationRelationshipRecord) =>
                            s.relatedSolutionId === solution.id &&
                            s.hotSpotXDraft != null &&
                            s.hotSpotYDraft != null
                    ) &&
                    solution.id !== currentHotspot?.relatedSolutionId
                ) {
                    return undefined;
                }
            }

            if (situation != null) {
                if (
                    props.hotSpots.some(
                        (s: SituationRelationshipRecord) =>
                            s.relatedSituationId === situation.id &&
                            s.hotSpotXDraft != null &&
                            s.hotSpotYDraft != null
                    ) &&
                    situation.id !== currentHotspot?.relatedSituationId
                ) {
                    return undefined;
                }
            }

            const record: SituationRecord | SolutionRecord = (solution ??
                situation)!;

            return {
                label: record.titleDraft,
                value: SitNavUtils.getComponentId(record),
            };
        })
        .filter(
            (option?: SelectOption) => option != null
        ) as Array<SelectOption>;

    const handleHotSpotDelete = () => {
        let newHotSpots = [...props.hotSpots];
        const index = props.hotSpots.findIndex(
            (r: SituationRelationshipRecord) => {
                if (currentHotspot == null) {
                    return false;
                }

                if (currentHotspot.relatedSituationId == null) {
                    return (
                        currentHotspot.relatedSolutionId === r.relatedSolutionId
                    );
                }

                return (
                    currentHotspot.relatedSituationId === r.relatedSituationId
                );
            }
        );
        if (index < 0) {
            return;
        }
        newHotSpots = CollectionUtils.replaceElementAt(
            newHotSpots,
            index,
            newHotSpots[index].with({
                hotSpotXDraft: undefined,
                hotSpotYDraft: undefined,
            })
        );
        props.onChange(newHotSpots);
        setIsModalOpen(false);
    };

    const calculateHotSpotPosition = (e: React.MouseEvent) =>
        EventUtils.getRelativeClickCoordinates(e, HOT_SPOT_ICON_SIZE);

    const getSelectedOption = useCallback(
        (
            currentHotSpot?: SituationRelationshipRecord
        ): SelectOption | undefined => {
            const option = selectOptions.find((option: SelectOption) => {
                if (currentHotSpot == null) {
                    return false;
                }

                if (currentHotSpot.relatedSituationId != null) {
                    return (
                        option.value ===
                        `situation-${currentHotSpot.relatedSituationId}`
                    );
                }

                return (
                    option.value ===
                    `solution-${currentHotSpot?.relatedSolutionId}`
                );
            });
            return option ?? selectOptions[0];
        },
        [selectOptions]
    );

    const handleSelectOptionChange = (selectOption?: SelectOption) => {
        if (selectOption == null) {
            return;
        }

        const component = SitNavUtils.parseComponentId(selectOption.value);
        if (component == null) {
            return;
        }
        setCurrentHotSpot(
            currentHotspot?.with({
                relatedSituationId:
                    component.type === SituationRecord
                        ? component.id
                        : undefined,
                relatedSolutionId:
                    component.type === SolutionRecord
                        ? component.id
                        : undefined,
            })
        );
    };

    const onHotSpotClick = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        hotspot: SituationRelationshipRecord
    ) => {
        e.stopPropagation();
        e.preventDefault();
        setIsModalOpen(true);
        setCurrentHotSpot(hotspot);
    };

    const onHotSpotMouseDown = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        hotspot: SituationRelationshipRecord
    ) => setCurrentHotSpot(hotspot);

    const onHotSpotDragOver = (e: React.DragEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        e.preventDefault();
        return;
    };

    const onHotSpotDragEnd = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const coordinates = calculateHotSpotPosition(e);
        const newHotSpot = (
            currentHotspot ?? new SituationRelationshipRecord()
        ).with({
            hotSpotXDraft: coordinates.percentageX * 100,
            hotSpotYDraft: coordinates.percentageY * 100,
        });
        const index = props.hotSpots.findIndex(
            (h) =>
                h.hotSpotXDraft === currentHotspot!.hotSpotXDraft &&
                h.hotSpotYDraft === currentHotspot!.hotSpotYDraft
        );
        const newHotspots = CollectionUtils.replaceElementAt(
            props.hotSpots,
            index,
            newHotSpot
        );
        props.onChange(newHotspots);
    };

    const onHotspotImageClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (
            CollectionUtils.isEmpty(props.situations) &&
            CollectionUtils.isEmpty(props.solutions)
        ) {
            ToastManager.warn(
                "There are no related results. Please add at least one."
            );
            return;
        }

        if (CollectionUtils.isEmpty(selectOptions)) {
            ToastManager.warn(
                "There are no remaining related results to add hotspots for. Add at least one more."
            );
            return;
        }

        const coordinates = calculateHotSpotPosition(e);
        const hotspot = new SituationRelationshipRecord().with({
            hotSpotXDraft: coordinates.percentageX * 100,
            hotSpotYDraft: coordinates.percentageY * 100,
        });
        setCurrentHotSpot(hotspot);
        setIsModalOpen(true);
    };

    const handleHotSpotClose = () => {
        setCurrentHotSpot(undefined);
        setIsModalOpen(false);
    };

    const handleHotSpotConfirm = () => {
        if (currentHotspot == null) {
            return;
        }

        const index = props.hotSpots.findIndex(
            (r: SituationRelationshipRecord) => {
                if (currentHotspot == null) {
                    return false;
                }

                if (currentHotspot.relatedSituationId == null) {
                    return (
                        currentHotspot.relatedSolutionId === r.relatedSolutionId
                    );
                }

                return (
                    currentHotspot.relatedSituationId === r.relatedSituationId
                );
            }
        );

        if (index < 0) {
            return;
        }

        const existingItem = props.hotSpots[index];
        const newItem = existingItem.with({
            hotSpotXDraft: currentHotspot.hotSpotXDraft,
            hotSpotYDraft: currentHotspot.hotSpotYDraft,
        });

        props.onChange(
            CollectionUtils.replaceElementAt(props.hotSpots, index, newItem)
        );
        setCurrentHotSpot(undefined);
        setIsModalOpen(false);
    };

    const hotSpots = props.hotSpots.filter(
        (h: SituationRelationshipRecord) =>
            h.hotSpotXDraft != null && h.hotSpotYDraft != null
    );

    const selectDefaultHotspot = useCallback(() => {
        // since no change event is fired if using the default value,
        // manually set it from the default option when the modal is first opened
        setCurrentHotSpot((currentHotSpot?: SituationRelationshipRecord) => {
            if (
                selectOptions.length === 0 ||
                currentHotSpot?.relatedSituationId != null ||
                currentHotSpot?.relatedSolutionId != null
            ) {
                return currentHotSpot;
            }

            const option = selectOptions[0];
            const component = SitNavUtils.parseComponentId(option.value);

            if (component == null) {
                return;
            }

            if (component?.type === SolutionRecord) {
                return currentHotSpot?.with({
                    relatedSolutionId: component.id,
                    relatedSituationId: undefined,
                });
            }

            return currentHotSpot?.with({
                relatedSituationId: component.id,
                relatedSolutionId: undefined,
            });
        });
    }, [selectOptions]);

    useEffect(() => {
        selectDefaultHotspot();
    }, [isModalOpen, selectDefaultHotspot]);

    return (
        <React.Fragment>
            <div
                onClick={onHotspotImageClick}
                className={`${CSS_CLASS_NAME} ${props.cssClassName}`}>
                <div
                    className={`${CSS_CLASS_NAME}__map`}
                    onDragOver={onHotSpotDragOver}
                    onDrop={onHotSpotDragEnd}>
                    {hotSpots.map(
                        (
                            hotspot: SituationRelationshipRecord,
                            index: number
                        ) => {
                            const hotspotModifier = hotspot.relatedSituationId
                                ? "-situation"
                                : "-solution";
                            return (
                                <button
                                    draggable={true}
                                    onClick={(e) => onHotSpotClick(e, hotspot)}
                                    onMouseDown={(e) =>
                                        onHotSpotMouseDown(e, hotspot)
                                    }
                                    style={hotspot.toDraftInlineStyles()}
                                    key={index}
                                    className={`${CSS_CLASS_NAME}__hotspot ${hotspotModifier}`}
                                    type="button">
                                    {hotspot.displaySequenceDraft + 1}
                                </button>
                            );
                        }
                    )}
                </div>
                {props.children}
            </div>
            <AdminSituationHotspotModal
                closeDialog={handleHotSpotClose}
                onConfirm={handleHotSpotConfirm}
                handleDelete={handleHotSpotDelete}
                currentHotspot={currentHotspot}
                hotspots={props.hotSpots}
                isVisible={isModalOpen}>
                <Paragraph>Please select a Related Resource.</Paragraph>
                <Select
                    value={getSelectedOption(currentHotspot)?.value}
                    options={selectOptions}
                    onChange={handleSelectOptionChange}
                    id={"related_content"}
                />
            </AdminSituationHotspotModal>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminHotSpotSection;

// #endregion Exports
