import OfflineBookRecord from "models/view-models/offline/offline-book-record";
import PublicationRecord from "models/view-models/publication-record";
import { useEffect, useMemo } from "react";
import useOfflineBooks from "utilities/hooks/domain/offline/use-offline-books";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import usePageErrors from "utilities/hooks/use-page-errors";
import SearchQuery from "utilities/interfaces/search-query";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import BookService from "utilities/services/books/book-service";
import PublicationService from "utilities/services/publications/publication-service";
import StringUtils from "utilities/string-utils";
import NumberUtils from "utilities/number-utils";
import useUpdateAtomEffect from "utilities/hooks/atoms/use-update-atom-value-effect";
import OfflinePublicationsAtom from "utilities/atoms/offline-publications-atom";
import { CollectionUtils } from "utilities/collection-utils";
import { OfflineBookDownloadStatus } from "molecules/enums/offline-book-download-status";

export interface UseOfflinePublicationsResult
    extends Omit<
        ServiceHookResult<PublicationRecord[]>,
        "resultObject" | "refresh"
    > {
    refresh: VoidFunction;
    searchResults: PublicationRecord[];
}

// -----------------------------------------------------------------------------------------
// #region Hook
// -----------------------------------------------------------------------------------------

/**
 * Custom hook to load publications that are only available offline
 */
export default function useOfflinePublications(
    options?: SearchQuery
): UseOfflinePublicationsResult {
    const { searchText } = options ?? {};

    const { list: listBookApi } = BookService.useListQuery();
    const { list: listPublicationApi } = PublicationService.useListQuery();

    const { resultObject: currentOfflineDevice } = useOfflineDevice();
    const {
        errors: offlineBooksErrors,
        loading: isLoadingOfflineBooks,
        refresh: refreshOfflineBooksWithoutBookRecord,
        resultObject: offlineBooksWithoutBookRecord,
    } = useOfflineBooks({ offlineDeviceId: currentOfflineDevice?.id });

    const { handlePageLoadError, pageErrors, resetPageErrors } =
        usePageErrors();

    const currentOfflineDeviceInitialized = !NumberUtils.isDefault(
        currentOfflineDevice?.id
    );

    const {
        data: publicationsData,
        error: publicationsError,
        isLoading: isLoadingPublications,
    } = listPublicationApi(undefined, {
        enabled: currentOfflineDeviceInitialized,
    });

    const {
        data: booksData,
        error: booksError,
        isLoading: isLoadingBooks,
    } = listBookApi(undefined, {
        enabled: currentOfflineDeviceInitialized,
    });

    const offlineBooks = useMemo(() => {
        return offlineBooksWithoutBookRecord.map((o) =>
            o.with({
                book: booksData?.resultObjects.find((b) => b.id === o.bookId),
            })
        );
    }, [booksData, offlineBooksWithoutBookRecord]);

    const offlinePublications = useMemo(
        () =>
            publicationsData?.resultObjects.filter(
                isValidOfflineBook(offlineBooks)
            ) ?? [],
        [offlineBooks, publicationsData]
    );

    const searchResults = useMemo(
        () => offlinePublications.filter(bySearchText(searchText)),
        [offlinePublications, searchText]
    );

    const loading =
        isLoadingBooks ||
        isLoadingOfflineBooks ||
        isLoadingPublications ||
        !currentOfflineDeviceInitialized;

    const loaded = !loading;

    useUpdateAtomEffect({
        atom: OfflinePublicationsAtom,
        clearOnCancel: false,
        enabled: !isLoadingBooks && !isLoadingPublications,
        value: offlinePublications,
    });

    useEffect(() => {
        if (booksError != null) {
            handlePageLoadError(booksError);
        }
    }, [booksError, handlePageLoadError]);

    useEffect(() => {
        if (publicationsError != null) {
            handlePageLoadError(publicationsError);
        }
    }, [handlePageLoadError, publicationsError]);

    useEffect(() => {
        if (CollectionUtils.hasValues(offlineBooksErrors)) {
            handlePageLoadError(
                offlineBooksErrors.filter((err) => StringUtils.hasValue(err))
            );
        }
    }, [handlePageLoadError, offlineBooksErrors]);

    useEffect(() => {
        if (
            booksError == null &&
            offlineBooksErrors == null &&
            publicationsError == null
        ) {
            resetPageErrors();
        }
    }, [booksError, offlineBooksErrors, publicationsError, resetPageErrors]);

    return {
        errors: pageErrors,
        loaded,
        loading,
        refresh: refreshOfflineBooksWithoutBookRecord,
        searchResults,
    };
}

// #endregion Hook

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const bySearchText =
    (searchText?: string) => (publication: PublicationRecord) => {
        if (StringUtils.isEmpty(searchText)) {
            return true;
        }

        const title = publication
            .getDisplayCodeEditionAndTitle()
            ?.toLowerCase();

        return title?.includes(searchText.toLowerCase()) ?? false;
    };

const isValidOfflineBook =
    (offlineBooks: Array<OfflineBookRecord>) =>
    (publication: PublicationRecord) =>
        offlineBooks.some(
            (offlineBook) =>
                offlineBook.getDownloadStatus() ===
                    OfflineBookDownloadStatus.Downloaded &&
                offlineBook.book?.code === publication.code &&
                offlineBook.book?.edition === publication.edition
        );

// #endregion Functions
