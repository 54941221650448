import AnnexRecord from "models/view-models/annex-record";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import AnnexService from "utilities/services/publications/annexes/annex-service";
import PublicationService from "utilities/services/publications/publication-service";
import PublicationRecord from "models/view-models/publication-record";
import Annex from "models/interfaces/annex";
import { ReactQueryUtils } from "utilities/react-query-utils";

export interface UseAnnexesQueryOptions {
    code?: string;
    edition?: string;
    publicationId?: number | string;
    publication?: PublicationRecord;
    includeProperties?: keyof Annex;
}

export default function useAnnexesQuery(
    options: UseAnnexesQueryOptions
): ServiceHookResult<Array<AnnexRecord>> {
    // -----------------------------------------------------------------------------------------
    // #region Private Members
    // -----------------------------------------------------------------------------------------
    const { code, edition, publicationId } = options;

    const { list: listAnnexes } = AnnexService.useListQuery();
    const { get: getPublication } = PublicationService.useGetQuery();
    const { list: listPublications } = PublicationService.useListQuery();

    const includePublication =
        options.includeProperties != null &&
        options.includeProperties.includes("publication") &&
        options.publication == null;

    const listPublicationsEnabled =
        includePublication && code != null && edition != null;
    const getPublicationEnabled =
        includePublication && !NumberUtils.isDefault(publicationId);

    // #endregion Private Members

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------
    // #region Publication
    // -----------------------------------------------------------------------------------------

    const publicationListResult = listPublications(
        { code, edition },
        { enabled: listPublicationsEnabled }
    );

    const publicationGetResult = getPublication(
        { id: NumberUtils.parseInt(publicationId!) },
        undefined,
        { enabled: getPublicationEnabled }
    );

    const publication =
        options.publication ??
        publicationListResult.data?.resultObjects[0] ??
        publicationGetResult.data?.resultObject;

    // #endregion Publication

    // -----------------------------------------------------------------------------------------
    // #region Annexes
    // -----------------------------------------------------------------------------------------

    const annexListEnabled = !NumberUtils.isDefault(publication?.id);

    const annexListResult = listAnnexes(
        { publicationId: publication?.id! },
        undefined,
        { enabled: annexListEnabled }
    );

    // #endregion Annexes

    // #endregion Public Methods

    // -----------------------------------------------------------------------------------------
    // #region Public Properties
    // -----------------------------------------------------------------------------------------

    const annexes = annexListResult.data?.resultObjects ?? [];

    const resultObject = annexes.map((a: AnnexRecord) =>
        a.with({ publication })
    );

    const allResults = [
        publicationGetResult,
        publicationListResult,
        annexListResult,
    ];

    const loading = ReactQueryUtils.isLoading(...allResults);
    const loaded = ReactQueryUtils.isLoaded(...allResults);
    const errors = annexListResult.isError
        ? annexListResult.error.listErrorMessages()
        : [];

    // #endregion Public Properties

    return { resultObject, loading, loaded, errors };
}
