import RootPortal from "molecules/portals/root-portal";
import * as React from "react";
import Loader from "atoms/loaders/loader";
import { LoaderStyles } from "atoms/constants/loader-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface FullScreenTranistionProps {
    transitionText: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-full-screen-transition";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const FullScreenTransition: React.FunctionComponent<FullScreenTranistionProps> =
    (props: FullScreenTranistionProps) => {
        return (
            <RootPortal className={CSS_BASE_CLASS_NAME}>
                <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                    <Loader
                        accessibleText={props.transitionText}
                        type={LoaderStyles.LinkFull}
                    />
                    <span
                        className={`${CSS_BASE_CLASS_NAME}__content__transition-text`}>
                        {props.transitionText}
                    </span>
                </div>
            </RootPortal>
        );
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default FullScreenTransition;

// #endregion Exports
