import { SkipNavLink } from "@reach/skip-nav";
import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import SidebarNavigation from "organisms/sidebar-navigation/sidebar-navigation";
import React from "react";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import OnboardingLayout from "templates/onboarding-layout";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization-utils";

/*
---------------------------------------------------------------------------------------------
Interfaces
---------------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------------
Component
---------------------------------------------------------------------------------------------
*/

const NotFoundPage: React.FC<any> = (props: any) => {
    const history = useHistory();

    const handleDismiss = () => {
        history.push(siteMap.home);
    };
    const { globalState } = useGlobalState();
    const userIsValid = globalState.currentIdentity?.hasActiveSubscription();

    const alert = (
        <div className="c-not-found-page">
            <AlertNotice
                buttonText={t("not-found-page-button-text")}
                description={t("not-found-page-description")}
                level={AlertLevels.Warning}
                onClick={handleDismiss}
                title={t("not-found-page-title")}
            />
        </div>
    );

    if (globalState.isAuthenticated() && userIsValid) {
        return (
            <React.Fragment>
                <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
                <div className="c-application-layout">
                    <SidebarNavigation />
                    <div className="c-application-layout__panel">{alert}</div>
                </div>
            </React.Fragment>
        );
    }

    return <OnboardingLayout>{alert}</OnboardingLayout>;
};

/*
---------------------------------------------------------------------------------------------
Exports
---------------------------------------------------------------------------------------------
*/

export default NotFoundPage;
