import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import SystemSettingsRecord from "models/view-models/system-settings-record";
import QueryHookFactory from "utilities/hooks/queries/query-hook-factory";
import DashboardVideoRecord from "models/view-models/dashboard-video-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SystemSettingsBasePathParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "systemsettings";
const key = "systemsettings";
const resourceType = SystemSettingsRecord;

const dashboardVideoEndpoint = `${baseEndpoint}/dashboard-video`;
const dashboardVideoResource = DashboardVideoRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SystemSettingsService = {
    key,

    /**
     * Retrieve the SystemSettings object
     */
    get: ServiceFactory.get<SystemSettingsRecord, SystemSettingsBasePathParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<
        SystemSettingsRecord,
        SystemSettingsBasePathParams
    >(resourceType, baseEndpoint),

    useGetQuery: QueryHookFactory.useGetQuery<
        SystemSettingsRecord,
        SystemSettingsBasePathParams
    >(key, resourceType, baseEndpoint),

    useUpdateDashboardVideo: ServiceHookFactory.useUpdate<
        DashboardVideoRecord,
        SystemSettingsBasePathParams
    >(dashboardVideoResource, dashboardVideoEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SystemSettingsService;

// #endregion Exports
