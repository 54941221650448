import RemoteImage from "atoms/images/remote-image";
import FileRecord from "models/view-models/file-record";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface HotSpotImageProps {
    hotSpotImageFile?: FileRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

/**
 * Presentational component to ensure that we have the exact same CSS
 * in admin side and user side for image scaling, etc. so that hotspots
 * line up correctly.
 */
const HotSpotImage: React.FC<HotSpotImageProps> = (
    props: HotSpotImageProps
) => {
    const CSS_CLASS_NAME = "c-hotspot-image";

    return (
        <RemoteImage
            cssClassName={CSS_CLASS_NAME}
            file={props.hotSpotImageFile}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default HotSpotImage;

// #endregion Exports
