import { AnchorTargetTypes } from "andculturecode-javascript-core";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import StringUtils from "utilities/string-utils";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import useCultureCodeRoute from "utilities/hooks/routing/use-culture-code-route";
import { siteMap } from "internal-sitemap";
import { ScrollUtils } from "utilities/scroll-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface AnchorProps
    extends Pick<
        DataTestAttributes,
        | "dataTestCode"
        | "dataTestDisplaySequence"
        | "dataTestEdition"
        | "dataTestExternalId"
        | "dataTestId"
    > {
    ariaLabel?: string;
    children?: any;
    cssClassName?: string;
    disabled?: boolean;
    external?: boolean;
    id?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
    ref?: React.Ref<HTMLAnchorElement>;
    /**
     * replace the current entry in the history stack instead of adding a new entry
     */
    replace?: boolean;
    target?: AnchorTargetTypes;
    title?: string;
    to: string;
    shouldScroll?: boolean;
    useRelativePath?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Anchor: React.RefForwardingComponent<Link, AnchorProps> = forwardRef(
    (props: AnchorProps, ref: React.Ref<HTMLAnchorElement>) => {
        const { generateCultureCodedRoute } = useCultureCodeRoute();
        const {
            disabled = false,
            cssClassName,
            shouldScroll = false,
            onClick,
            to,
        } = props;

        const cssClassNames: Array<string> = [];

        if (StringUtils.hasValue(cssClassName)) {
            cssClassNames.push(cssClassName!);
        }

        if (disabled) {
            cssClassNames.push("-disabled");
        }

        const history = useHistory();

        const [hash, setHash] = useState("");

        const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (disabled) {
                e.preventDefault();
                return;
            }

            if (shouldScroll) {
                e.preventDefault();
                e.stopPropagation();
                const id = to.replace("#", "");
                ScrollUtils.scrollToElementById(id);
                window.history.pushState({}, "", to);
                setHash(window.location.hash);
                return;
            }

            if (onClick != null) {
                onClick(e);
            }
        };

        useEffect(() => {
            if (hash !== "") {
                history.push(hash);
            }
        }, [hash, history]);

        let relAttribute: string | undefined = undefined;
        if (props.target != null) {
            // Using target="_blank" without rel="noopener noreferrer" is a security risk: see
            // https://mathiasbynens.github.io/rel-noopener  react/jsx-no-target-blank
            relAttribute = "noopener noreferrer";
        }

        const commonProps = {
            "aria-disabled": disabled,
            "aria-label": props.ariaLabel,
            className: cssClassNames.join(" "),
            "data-test-code": props.dataTestCode,
            "data-test-display-sequence": props.dataTestDisplaySequence,
            "data-test-edition": props.dataTestEdition,
            "data-test-external-id": props.dataTestExternalId,
            "data-test-id": props.dataTestId,
            id: props.id,
            onClick: useCallback(onClickHandler, [
                disabled,
                to,
                onClick,
                shouldScroll,
            ]),
            ref: ref,
            replace: props.replace,
            target: props.target,
            rel: relAttribute,
            title: props.title,
            onKeyDown: props.onKeyDown,
        };

        if (props.external === true) {
            return (
                <a href={props.to} {...commonProps}>
                    {props.children}
                </a>
            );
        }

        const localizedPath =
            props.to === siteMap.home || props.useRelativePath
                ? props.to
                : generateCultureCodedRoute(props.to);

        return (
            <>
                {shouldScroll ? (
                    <a
                        className={cssClassNames.join(" ")}
                        href={props.to}
                        onClick={onClickHandler}>
                        {props.children}
                    </a>
                ) : (
                    <Link to={localizedPath} {...commonProps}>
                        {props.children}
                    </Link>
                )}
            </>
        );
    }
);

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default Anchor;

// #endregion Exports
