import * as React from "react";
import UnorderedList from "molecules/lists/unordered-list";
import StringUtils from "utilities/string-utils";
import { CollectionUtils } from "utilities/collection-utils";

export interface CommitteeListsProps {
    children?: JSX.Element[];
}

const CommitteeLists = (props: CommitteeListsProps) => {
    const isNotStringElement = (child: JSX.Element) =>
        typeof child.props.children !== "string";

    const isNotWhitespaceElement = (child: JSX.Element) =>
        typeof child.props.children === "string" &&
        StringUtils.hasValue(child.props.children);

    const hasNoChildren = (child: JSX.Element) => child.props?.children == null;

    /**
     * A 'valid' child for the list is one that is a string element with a value, or a converted
     * React component (which does not necessarily need children).
     *
     * This is used to remove children that are converted from empty strings in
     * the XML, causing additional dividers on the page.
     * @param child
     */
    const validateChild = (child: JSX.Element) =>
        hasNoChildren(child) ||
        isNotStringElement(child) ||
        isNotWhitespaceElement(child);

    const filterOutWhitespaceChildren = (
        children: JSX.Element[]
    ): JSX.Element[] => {
        if (CollectionUtils.isEmpty(children)) {
            return [];
        }

        const filteredChildren = children.filter(
            (child: JSX.Element) =>
                // Filter out any top-level whitespace children followed by any nested children
                validateChild(child) && validateChild(child.props?.children)
        );

        return filteredChildren;
    };

    const filteredChildren = filterOutWhitespaceChildren(props.children ?? []);

    return (
        <UnorderedList
            cssClassName="c-committee-list"
            listItems={filteredChildren}
        />
    );
};

export default CommitteeLists;
