import React, { useEffect, useState } from "react";
import { ToastContent, toast } from "react-toastify";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import {
    EligibleFreeTrialToast,
    NotEligibleFreeTrialToast,
} from "./free-access-toast-variations";

interface Options {
    isSubscriber: boolean;
}
export interface ToastMessageRecordParams {
    type: number;
}

export default function useFreeAccessCtaToast(options: Options) {
    const { isSubscriber } = options;

    const { globalState } = useGlobalState();
    const hasHadFreeTrial = globalState.currentIdentity?.hasHadFreeTrial();

    const [interactionCount, setInteractionCount] = useState<number>(0);

    const toaster = (content: ToastContent) =>
        toast.info(content, {
            autoClose: false,
            closeButton: true,
        });

    useEffect(() => {
        const showToast = !isSubscriber && interactionCount === 1;
        if (showToast) {
            hasHadFreeTrial
                ? toaster(<NotEligibleFreeTrialToast />)
                : toaster(<EligibleFreeTrialToast />);

            setInteractionCount(2);
        }
    }, [hasHadFreeTrial, interactionCount, isSubscriber]);

    return { setInteractionCount };
}
