import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface FilterButtonProps extends Pick<ButtonProps, "onClick"> {
    count: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CLASS_NAME = "c-filter-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const FilterButton: React.FunctionComponent<
    PropsWithChildren<FilterButtonProps>
> = (props: PropsWithChildren<FilterButtonProps>) => {
    const { count, children, onClick } = props;
    const showFilterBadge = count > 0;
    const activeClassName = showFilterBadge ? "-active" : "";

    return (
        <Button
            cssClassName={`${CLASS_NAME} ${activeClassName}`}
            onClick={onClick}
            size={ButtonSizes.Small}
            style={ButtonStyles.Secondary}>
            {showFilterBadge && (
                <div className={`${CLASS_NAME}__badge`}>
                    <span>{count}</span>
                </div>
            )}
            {children}
        </Button>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default FilterButton;

// #endregion Export
