import React from "react";
import PublicationRecord from "models/view-models/publication-record";
import { CollectionUtils } from "utilities/collection-utils";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import Icon from "atoms/icons/icon";
import { Icons } from "atoms/constants/icons";
import { IconSizes } from "atoms/constants/icon-sizes";

const CSS_CLASS_NAME = "c-publication-batch-badge";

const PublicationBatchBadge: React.FC = () => {
    const {
        context: { publicationBatch },
        setContext,
    } = useAdminEditorPageContext();

    const handleRemoveBatchSelection = (id: number) => {
        setContext((prevContext) => {
            const { publicationBatch } = prevContext;

            const batch = publicationBatch?.filter((e) => e.id !== id);

            return prevContext.with({ publicationBatch: batch });
        });
    };

    if (!CollectionUtils.hasValues(publicationBatch)) {
        return <React.Fragment />;
    }

    return (
        <div className={CSS_CLASS_NAME}>
            {publicationBatch.map((pub: PublicationRecord) => (
                <div className={`${CSS_CLASS_NAME}__badge_button`} key={pub.id}>
                    <div className={`${CSS_CLASS_NAME}__badge_text`}>
                        {pub.getDisplayCodeAndEdition()}
                    </div>
                    <div
                        className={`${CSS_CLASS_NAME}__badge_icon`}
                        onClick={() => handleRemoveBatchSelection(pub.id!)}>
                        <Icon type={Icons.Close} size={IconSizes.Base} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PublicationBatchBadge;
