import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import ServiceFactory from "utilities/services/service-factory";
import ServiceHookFactory from "utilities/services/service-hook-factory";

export interface FreeAccessPublicationBasePathParams {}

export interface FreeAccessPublicationResourcePathParams {
    code: string;
    edition: string;
}

const baseEndpoint = "free-access/publications";
const resourceEndpoint = `${baseEndpoint}/:code/:edition`;
const resourceType = FreeAccessPublicationRecord;

const FreeAccessService = {
    get: ServiceFactory.get<
        FreeAccessPublicationRecord,
        FreeAccessPublicationResourcePathParams
    >(resourceType, resourceEndpoint),
    useGet: ServiceHookFactory.useGet<
        String,
        FreeAccessPublicationResourcePathParams
    >(String, resourceEndpoint),
};

export default FreeAccessService;
