import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import StickyBanner, {
    StickyBannerPosition,
} from "molecules/banners/sticky-banner";
import React, { useState } from "react";
import {
    CookieUtils,
    SameSiteCookie,
    SetCookieOptions,
} from "utilities/cookie-utils";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { t } from "utilities/localization-utils";

const CLASS_NAME = "c-system-issues-banner";

const SYSTEM_ISSUES_COOKIE_NAME = "systemIssues";
const SYSTEM_ISSUES_COOKIE_VALUE = "true";
const fourHours = 4 * 60 * 60; // 4 hours in seconds
const cookieOptions: SetCookieOptions = {
    maxAge: fourHours,
    path: "/",
    secure: true,
    sameSite: SameSiteCookie.Strict,
};

const SystemIssuesBanner = () => {
    const { bypassHermes } = useFeatureFlags();
    const [isAccepted, setIsAccepted] = useState(
        CookieUtils.get(SYSTEM_ISSUES_COOKIE_NAME) ===
            SYSTEM_ISSUES_COOKIE_VALUE
    );

    const handleClick = () => {
        CookieUtils.set(
            SYSTEM_ISSUES_COOKIE_NAME,
            SYSTEM_ISSUES_COOKIE_VALUE,
            cookieOptions
        );
        setIsAccepted(true);
    };

    if (isAccepted || !bypassHermes) return null;

    return (
        <StickyBanner
            cssClassName={CLASS_NAME}
            position={StickyBannerPosition.Top}>
            <Icon type={Icons.Warning} size={IconSizes.Large} />
            <span>{t("system-issues-banner-text")}</span>
            <Button
                accessibleText="Okay"
                onClick={handleClick}
                size={ButtonSizes.Small}
                style={ButtonStyles.TertiaryAlt}>
                {t("okay")}
            </Button>
        </StickyBanner>
    );
};

export default SystemIssuesBanner;
