import { useRef } from "react";

const DEFAULT_SEARCH_DELAY = 800; //ms

interface UseDelayedSearchOptions {
    callback: Function;
    searchDelay?: number;
}

const useDelayedSearch = ({
    callback,
    searchDelay = DEFAULT_SEARCH_DELAY,
}: UseDelayedSearchOptions) => {
    const searchIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const delayedSearch = () => {
        if (searchIntervalRef.current != null) {
            clearInterval(searchIntervalRef.current);
        }

        searchIntervalRef.current = setInterval(() => {
            callback();
            clearInterval(searchIntervalRef.current!);
        }, searchDelay);
    };

    return {
        delayedSearch,
    };
};

export default useDelayedSearch;
