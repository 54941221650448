import React, {
    Fragment,
    useCallback,
    useState,
    FC,
    ReactNode,
    useEffect,
} from "react";
import Modal from "molecules/modals/modal";
import { ModalTypes } from "molecules/constants/modal-types";
import Heading from "atoms/typography/heading";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import SelectFormField from "molecules/form-fields/select-form-field";
import { SelectOption } from "atoms/forms/select";
import PublicationRecord from "models/view-models/publication-record";
import { RadioListInputFormField } from "molecules/form-fields/radio-input-form-field";
import { RadioButtonProps } from "atoms/forms/radio-button-input";
import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import { CollectionUtils } from "utilities/collection-utils";
import EnhancedContentMigrationRecord from "models/view-models/enhanced-content-migration-record";
import useEnhancedContentPreMigrations from "utilities/hooks/domain/enhanced-content/use-enhanced-content-pre-migration";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentMigrationModallProps {
    closeDialog: (migration?: EnhancedContentMigrationRecord) => void;
    destination: PublicationRecord;
    sources: PublicationRecord[];
    isVisible: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum EnhancedContentMigrationAction {
    None,
    MigrateAndKeepExisting,
    MigrateAndOverwriteExisting,
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-enhanced-content-migration-modal";

const RADIO_BUTTON_NAME = "enhanced-content-migration-modal";

const defaultMigrationActionOptions: RadioButtonProps<number>[] = [
    {
        checked: false,
        label: "Keep any existing enhanced content",
        name: RADIO_BUTTON_NAME,
        value: EnhancedContentMigrationAction.MigrateAndKeepExisting,
    },
    {
        checked: false,
        label: "Overwrite any existing enhanced content",
        name: RADIO_BUTTON_NAME,
        value: EnhancedContentMigrationAction.MigrateAndOverwriteExisting,
    },
];

const label = "Migrate Enhanced Content";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

export const EnhancedContentMigrationModal: FC<EnhancedContentMigrationModallProps> =
    (props) => {
        const { closeDialog, destination, isVisible, sources } = props;

        // -----------------------------------------------------------------------------------------
        // #region State
        // -----------------------------------------------------------------------------------------

        const [migrationAction, setMigrationAction] = useState(
            EnhancedContentMigrationAction.MigrateAndKeepExisting
        );
        const [showConfirmationDialog, setShowConfirmationDialog] =
            useState<boolean>(false);
        const [source, setSource] = useState<PublicationRecord>();

        // #endregion State

        // -----------------------------------------------------------------------------------------
        // #region Service Hooks
        // -----------------------------------------------------------------------------------------

        const { errors: preMigrationErrors, overwriteCount } =
            useEnhancedContentPreMigrations({
                destination,
                migrationAction,
                source,
            });

        // #endregion Service Hooks

        // -----------------------------------------------------------------------------------------
        // #region Computations
        // -----------------------------------------------------------------------------------------

        const buildMigration = useCallback(
            (
                action: EnhancedContentMigrationAction
            ): EnhancedContentMigrationRecord | undefined => {
                if (action !== EnhancedContentMigrationAction.None) {
                    return new EnhancedContentMigrationRecord({
                        code: destination.code,
                        destinationEdition: destination.edition,
                        overwriteExisting:
                            action ===
                            EnhancedContentMigrationAction.MigrateAndOverwriteExisting,
                        sourceEdition: source?.edition,
                        sourcePublication: source,
                    });
                }
            },
            [destination, source]
        );

        const closeModal = useCallback(
            (action: EnhancedContentMigrationAction) => {
                const migration = buildMigration(action);

                closeDialog(migration);
                setShowConfirmationDialog(false);
            },
            [buildMigration, closeDialog]
        );

        const initializeMigrationSource = useCallback(() => {
            if (!isVisible) {
                setSource(undefined);
                return;
            }

            if (CollectionUtils.hasValues(sources)) {
                setSource(sources[0]);
                setMigrationAction(
                    EnhancedContentMigrationAction.MigrateAndKeepExisting
                );
            }
        }, [isVisible, sources]);

        const migrationSourceOptions =
            sources?.map((p) => {
                const option: SelectOption<PublicationRecord, number> = {
                    label: p.getDisplayTitle(true),
                    value: p.id!,
                    data: p,
                };

                return option;
            }) ?? [];

        // #endregion Computations

        // -----------------------------------------------------------------------------------------
        // #region Side-Effects
        // -----------------------------------------------------------------------------------------

        useEffect(() => {
            initializeMigrationSource();
        }, [initializeMigrationSource]);

        useEffect(() => {
            if (CollectionUtils.hasValues(preMigrationErrors)) {
                preMigrationErrors.forEach((err) => {
                    ToastManager.error(err);
                });
            }
        }, [preMigrationErrors]);

        // #endregion Side-Effects

        // -----------------------------------------------------------------------------------------
        // #region Event Handlers
        // -----------------------------------------------------------------------------------------

        const handleCloseModal = () =>
            closeModal(EnhancedContentMigrationAction.None);

        const handleCancelConfirmation = () => setShowConfirmationDialog(false);

        const handleConfirmation = useCallback(() => {
            closeModal(migrationAction);
        }, [closeModal, migrationAction]);

        const handleMigrateContent = () => setShowConfirmationDialog(true);

        const handleMigationActionChange = (
            value?: EnhancedContentMigrationAction
        ) => {
            if (value != null) {
                setMigrationAction(value);
            }
        };

        const handleMigrationSourceChange = (
            selectedPublication?: SelectOption<PublicationRecord, number>
        ) => {
            if (selectedPublication?.data != null) {
                setSource(selectedPublication.data);
            }
        };

        // #endregion Event Handlers

        // -----------------------------------------------------------------------------------------
        // #region Render
        // -----------------------------------------------------------------------------------------

        return (
            <Fragment>
                <Modal
                    closeDialog={handleCloseModal}
                    cssClassName={CSS_BASE_CLASS_NAME}
                    isVisible={isVisible}
                    label={label}
                    type={ModalTypes.Base}>
                    <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                        <Heading priority={HeadingPriority.Two}>
                            {label}
                        </Heading>
                        <ModalCloseButton onClick={handleCloseModal} />
                    </div>
                    <div className={`${CSS_BASE_CLASS_NAME}__body`}>
                        <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                            <SelectFormField
                                cssClassName={`${CSS_BASE_CLASS_NAME}__content__source`}
                                label={`Migration Source`}
                                onChange={handleMigrationSourceChange}
                                options={migrationSourceOptions}
                                value={source?.id}
                            />
                            <RadioListInputFormField
                                label={`Existing Enhanced Content Preference`}
                                onValueChange={handleMigationActionChange}
                                options={defaultMigrationActionOptions}
                                selectedValue={migrationAction}
                            />
                        </div>
                        <div className={`${CSS_BASE_CLASS_NAME}__footer`}>
                            <Button
                                onClick={handleCloseModal}
                                style={ButtonStyles.Secondary}>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleMigrateContent}
                                style={ButtonStyles.Primary}>
                                Migrate Content
                            </Button>
                        </div>
                    </div>
                </Modal>

                <ConfirmationModal
                    confirmButtonStyle={ButtonStyles.Primary}
                    confirmButtonText="Yes, migrate content"
                    cssClassName={`${CSS_BASE_CLASS_NAME}__confirmation-modal`}
                    isVisible={showConfirmationDialog}
                    label="Are you sure?"
                    message={getConfirmationMessage(overwriteCount)}
                    onCancel={handleCancelConfirmation}
                    onConfirm={handleConfirmation}
                />
            </Fragment>
        );

        // #endregion Render
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Pure Functions
// -----------------------------------------------------------------------------------------

function getConfirmationMessage(overwriteContentAreaCount: number): ReactNode {
    const warningMessage = "Are you sure you want to migrate enhanced content?";

    if (overwriteContentAreaCount > 0) {
        const alertMessage = [
            `Warning this action will overwrite enhanced content on ${overwriteContentAreaCount} content area`,
            overwriteContentAreaCount > 1 ? "s" : "",
            ".",
        ].join("");

        return (
            <Fragment>
                {warningMessage}
                <AlertBanner alertLevel={AlertLevels.Error}>
                    {alertMessage}
                </AlertBanner>
            </Fragment>
        );
    }

    return warningMessage;
}

// #endregion Pure Functions
