import SubscriptionRecord from "models/view-models/subscription-record";
import React from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import StringUtils from "utilities/string-utils";
import CultureResources from "utilities/interfaces/culture-resources";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";
import moment from "moment";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SubscriptionDetailContentCardProps {
    daysUntilExpiration: number;
    isFreeTrial: boolean;
    subscription: SubscriptionRecord;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-subscription-detail-card-content";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SubscriptionDetailCardContent: React.FC<
    SubscriptionDetailContentCardProps
> = (props) => {
    const { daysUntilExpiration, isFreeTrial, subscription } = props;

    const { t } = useLocalization();

    const { fromPin } = useEcommercePrices();
    const { cycle, autoRenew, price } = fromPin(subscription.sku) ?? {};

    const autoRenewClass = `-${autoRenew?.toLowerCase()}`;
    const dateDescription =
        autoRenew === t("off") ? t("expiration-date") : t("renewal-date");
    formatDate(daysUntilExpiration);
    if (isFreeTrial) {
        return renderFreeTrialMessage(t, daysUntilExpiration);
    }

    return (
        <table className={BASE_CLASS}>
            <tbody>
                <tr>
                    <td>{t("price")}</td>
                    <td>{price}</td>
                </tr>
                <tr>
                    <td>{t("billing")}</td>
                    <td>{cycle}</td>
                </tr>
                <tr>
                    <td>{t("auto-renew")}</td>
                    <td className={autoRenewClass}>{autoRenew}</td>
                </tr>
                <tr>
                    <td>{dateDescription}</td>
                    <td>{formatDate(daysUntilExpiration)}</td>
                </tr>
            </tbody>
        </table>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const renderFreeTrialMessage = (
    t: (key: keyof CultureResources, options?: object | undefined) => string,
    daysUntilExpiration: number
) => {
    const day = StringUtils.pluralize(daysUntilExpiration, t("day"));

    return (
        <AlertBanner alertLevel={AlertLevels.Warning}>
            <span>
                {`${t("your-free-trial")} ends in`}
                <span className={`${BASE_CLASS}__days-remaining`}>
                    {` ${daysUntilExpiration} ${day.toLowerCase()}. `}
                </span>
                {t("freeTrialUpgradeMessage")}
            </span>
        </AlertBanner>
    );
};

const formatDate = (daysUntilExpiration: number): string => {
    const parsedDate = moment().add(daysUntilExpiration, "d");

    if (!parsedDate.isValid()) {
        return "--";
    }
    return parsedDate.format("LL");
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SubscriptionDetailCardContent;

// #endregion Exports
