import { Record } from "immutable";
import type { ContactFormSubmission } from "models/interfaces/contact-form-submission";
import { RecordUtils } from "andculturecode-javascript-core";

const defaultValues: ContactFormSubmission =
    RecordUtils.defaultValuesFactory<ContactFormSubmission>({
        captchaComplete: false,
        contactMethod: "",
        description: "",
        email: "",
        issue: "",
        name: "",
        phone: "",
    });

export default class ContactFormSubmissionRecord
    extends Record(defaultValues)
    implements ContactFormSubmission
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: ContactFormSubmission) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<ContactFormSubmission>} values
     */
    public with(
        values: Partial<ContactFormSubmission>
    ): ContactFormSubmissionRecord {
        return new ContactFormSubmissionRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
