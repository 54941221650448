import { useCallback, useRef } from "react";
import uuid from "uuid";
import { ToastManager, ToastId } from "utilities/toast/toast-manager";
import { Icons } from "atoms/constants/icons";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseProgressToastOptions {
    /**
     * Closes progress toast automatically when `current` hits `total`. Toast can still be closed
     * manually with `done` in either case
     * @default true
     */
    autoCloseWhenComplete?: boolean;

    /**
     * Icon to display alongside progress toast.
     * @default {Icons.Download}
     */
    icon?: Icons;
}

interface UseProgressToastResult {
    done: (toastId: ToastId) => void;
    start: (message: string, total: number, customToastId?: ToastId) => ToastId;
    update: (toastId: ToastId, current: number) => void;
}

interface ToastState {
    id: ToastId;
    total: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultOptions: Required<UseProgressToastOptions> = {
    autoCloseWhenComplete: true,
    icon: Icons.Download,
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Public Functions
// -----------------------------------------------------------------------------------------

const useProgressToast = (
    options?: UseProgressToastOptions
): UseProgressToastResult => {
    const optionsWithDefaults = getOptionsOrDefault(options);
    const { autoCloseWhenComplete, icon } = optionsWithDefaults;
    const toastRefs = useRef<Array<ToastState>>([]);

    const addToast = useCallback((toast: ToastState) => {
        if (getToastById(toast.id) != null) {
            return;
        }

        toastRefs.current = [...toastRefs.current, toast];
    }, []);

    const getToastById = (id: ToastId): ToastState | undefined =>
        toastRefs.current.find((toast) => toast.id === id);

    const removeToastById = (id: ToastId) =>
        (toastRefs.current = toastRefs.current.filter(
            (toast) => toast.id !== id
        ));

    const start = (
        message: string,
        total: number,
        customToastId?: ToastId
    ): ToastId => {
        const toastId = customToastId ?? uuid.v4();
        ToastManager.progress(message, icon, {
            autoClose: false,
            progress: 0,
            toastId,
        });

        addToast({
            id: toastId,
            total,
        });

        return toastId;
    };

    const update = (toastId: ToastId, current: number) => {
        const toast = getToastById(toastId);
        if (toast == null) {
            return;
        }

        const { total } = toast;

        if (autoCloseWhenComplete && current === total) {
            done(toastId);
            return;
        }

        ToastManager.update(toastId, { progress: current / total });
    };

    const done = useCallback((toastId: ToastId) => {
        ToastManager.done(toastId);
        removeToastById(toastId);
    }, []);

    return {
        done,
        start: useCallback(start, [addToast, icon]),
        update: useCallback(update, [autoCloseWhenComplete, done]),
    };
};

// #endregion Public Functions

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const getOptionsOrDefault = (
    options?: UseProgressToastOptions
): Required<UseProgressToastOptions> =>
    Object.assign({}, defaultOptions, options);

// #endregion Private Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { useProgressToast };

// #endregion Exports
