import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Paragraph from "atoms/typography/paragraph";
import PublishStatus from "models/enumerations/publish-status";
import PublishStatusBadge from "organisms/admin/situational-navigation/publish-status-badge";
import ButtonGroup from "organisms/button-groups/button-group";
import React from "react";
import PublishableFilters from "utilities/interfaces/publishable-filters";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublishStatusFiltersBarProps {
    value: PublishableFilters;
    onChange: (newValues: PublishableFilters) => void;
    includePublishedWithChanges?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublishStatusFiltersBar: React.FC<PublishStatusFiltersBarProps> = (
    props: PublishStatusFiltersBarProps
) => {
    const CSS_CLASS_NAME = "c-publish-status-filters-bar";

    const includePublishedWithChanges =
        props.includePublishedWithChanges ?? true;

    const getSelectedCssClass = (
        publishStatus?: PublishStatus,
        hasUnpublishedChanges?: boolean
    ): string => {
        if (
            props.value.publishStatus === publishStatus &&
            props.value.hasUnpublishedChanges === hasUnpublishedChanges
        ) {
            return "-selected";
        }

        return "";
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <ButtonGroup cssClassName={`${CSS_CLASS_NAME}__button-group`}>
                <Button
                    accessibleText={`All enhanced content`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item -all ${getSelectedCssClass()}`}
                    type={ButtonTypes.Button}
                    onClick={() => props.onChange({})}
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <Paragraph size={ParagraphSizes.XSmall}>All</Paragraph>
                </Button>
                {
                    // if
                    includePublishedWithChanges && (
                        <Button
                            accessibleText={`Published enhanced content with changes`}
                            cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                                PublishStatus.Published,
                                true
                            )}`}
                            type={ButtonTypes.Button}
                            onClick={() =>
                                props.onChange({
                                    publishStatus: PublishStatus.Published,
                                    hasUnpublishedChanges: true,
                                })
                            }
                            size={ButtonSizes.XSmall}
                            style={ButtonStyles.TertiaryAlt}>
                            <PublishStatusBadge
                                status={PublishStatus.Published}
                                hasUnpublishedChanges={true}
                                small={true}
                            />
                        </Button>
                    )
                }
                <Button
                    accessibleText={`Published enhanced content`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                        PublishStatus.Published,
                        false
                    )}`}
                    type={ButtonTypes.Button}
                    onClick={() =>
                        props.onChange({
                            publishStatus: PublishStatus.Published,
                            hasUnpublishedChanges: false,
                        })
                    }
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <PublishStatusBadge
                        status={PublishStatus.Published}
                        hasUnpublishedChanges={false}
                        small={true}
                    />
                </Button>
                <Button
                    accessibleText={`Unpublished enhanced content`}
                    cssClassName={`${CSS_CLASS_NAME}__button-group__item ${getSelectedCssClass(
                        PublishStatus.Unpublished
                    )}`}
                    type={ButtonTypes.Button}
                    onClick={() =>
                        props.onChange({
                            publishStatus: PublishStatus.Unpublished,
                        })
                    }
                    size={ButtonSizes.XSmall}
                    style={ButtonStyles.TertiaryAlt}>
                    <PublishStatusBadge
                        status={PublishStatus.Unpublished}
                        hasUnpublishedChanges={false}
                        small={true}
                    />
                </Button>
            </ButtonGroup>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublishStatusFiltersBar;

// #endregion Exports
