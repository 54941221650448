import Breadcrumb from "atoms/breadcrumbs/breadcrumb";
import React from "react";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CategoriesBreadcrumb: React.FC = () => (
    <Breadcrumb>
        <span className="c-breadcrumb__label">Categories</span>
    </Breadcrumb>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default CategoriesBreadcrumb;

// #endregion Exports
