import Button from "atoms/buttons/button";
import ModalCloseButton from "atoms/buttons/modal-close-button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import FreeAccessPublicationRecord from "models/view-models/free-access-publication-record";
import { ModalTypes } from "molecules/constants/modal-types";
import InputFormField from "molecules/form-fields/input-form-field";
import Modal from "molecules/modals/modal";
import React from "react";
import { useState } from "react";

interface FreeAccessPublicationsEditModalProps {
    publicationToEdit: FreeAccessPublicationRecord | undefined;
    isModalOpen: boolean;
    freeAccessPublicationIsSaving: boolean;
    onEditModalClose: () => void;
    onSavePublication: (args0: FreeAccessPublicationRecord) => void;
}

const FreeAccessPublicationsEditModal = (
    props: FreeAccessPublicationsEditModalProps
) => {
    const {
        isModalOpen,
        onEditModalClose,
        freeAccessPublicationIsSaving,
        onSavePublication,
        publicationToEdit,
    } = props;
    const [code, setCode] = useState(publicationToEdit?.code);
    const [edition, setEdition] = useState(publicationToEdit?.edition);
    const [title, setTitle] = useState(publicationToEdit?.title);

    const updateCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
    };

    const updateEdition = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEdition(event.target.value);
    };

    const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const savePublication = () => {
        onSavePublication(
            new FreeAccessPublicationRecord({
                id: publicationToEdit?.id,
                code,
                edition,
                title,
            })
        );
    };
    return (
        <Modal
            cssClassName="c-modal"
            closeDialog={onEditModalClose}
            isVisible={isModalOpen}
            label={`Edit`}
            type={ModalTypes.Base}>
            <div className="c-modal__header">
                <Heading priority={HeadingPriority.Four}>
                    Edit Free Access Publication
                </Heading>
                <ModalCloseButton onClick={onEditModalClose} />
            </div>

            <div className="c-modal__content">
                <div className="c-modal__body">
                    <InputFormField
                        label="Title"
                        onChange={updateTitle}
                        required={true}
                        value={title}
                        maxLength={150}
                        placeholder="Title"
                        isValid={true}
                    />
                    <InputFormField
                        label="Edition"
                        onChange={updateEdition}
                        required={true}
                        value={edition}
                        maxLength={150}
                        placeholder="Edition"
                        isValid={true}
                    />
                    <InputFormField
                        label="Code"
                        onChange={updateCode}
                        required={true}
                        value={code}
                        maxLength={150}
                        placeholder="Code"
                        isValid={true}
                    />
                </div>
            </div>
            <div className="c-modal__footer">
                <Button
                    onClick={onEditModalClose}
                    style={ButtonStyles.Secondary}>
                    Cancel
                </Button>
                <Button
                    disabled={freeAccessPublicationIsSaving}
                    onClick={savePublication}
                    style={ButtonStyles.Primary}>
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default FreeAccessPublicationsEditModal;
