import SectionRecord from "models/view-models/section-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";
import ServiceFactory from "utilities/services/service-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ShareLinkSectionPathParams {
    guid: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "sharelinks/sections";
const resourceEndpoint = `${baseEndpoint}/:guid`;
const resourceType = SectionRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ShareLinkSectionService = {
    list: ServiceFactory.nestedList<
        SectionRecord,
        ShareLinkSectionPathParams,
        undefined
    >(resourceType, resourceEndpoint),
    useList: ServiceHookFactory.useNestedList<
        SectionRecord,
        ShareLinkSectionPathParams,
        undefined
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ShareLinkSectionService;

// #endregion Exports
