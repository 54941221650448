import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { InputTypes } from "atoms/constants/input-types";
import TextInput from "atoms/forms/text-input";
import Icon from "atoms/icons/icon";
import React, { useEffect, useState } from "react";
import { t } from "utilities/localization-utils";

export interface CompactPageNavigationMenuProps {
    currentPageNumber: number;
    setCurrentPageNumber: (value: number) => void;
    onFooterPageChange: (value: number) => void;
    totalPages: number;
}

const cssBaseClassName = "c-compact-page-navigation-menu";

const CompactPageNavigationMenu: React.FunctionComponent<CompactPageNavigationMenuProps> =
    (props: CompactPageNavigationMenuProps) => {
        const {
            currentPageNumber,
            setCurrentPageNumber,
            totalPages,
            onFooterPageChange,
        } = props;

        const [inputValue, setInputValue] = useState(
            currentPageNumber.toString()
        );

        const onNextPage = () => {
            if (currentPageNumber === totalPages) {
                return;
            }

            setCurrentPageNumber(currentPageNumber + 1);
            onFooterPageChange(currentPageNumber + 1);
        };

        const onPreviousPage = () => {
            if (currentPageNumber === 1) {
                return;
            }

            setCurrentPageNumber(currentPageNumber - 1);
            onFooterPageChange(currentPageNumber - 1);
        };

        const onFirstPage = () => {
            if (currentPageNumber === 1) {
                return;
            }

            setCurrentPageNumber(1);
            onFooterPageChange(1);
        };

        const onLastPage = () => {
            if (currentPageNumber === totalPages) {
                return;
            }

            setCurrentPageNumber(totalPages);
            onFooterPageChange(totalPages);
        };

        const onSelectPage = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const target = +e.currentTarget.value;
            if (e.key !== "Enter") {
                return;
            }

            if (target == null) {
                return;
            }

            if (target > totalPages) {
                onLastPage();
                return;
            }

            setCurrentPageNumber(target);
            onFooterPageChange(target);
        };

        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            let input = +e.target.value;
            if (Number.isNaN(input)) {
                return;
            }
            setInputValue(input.toString());
        };

        useEffect(() => {
            setInputValue(currentPageNumber.toString());
        }, [currentPageNumber]);

        useEffect(() => {
            const onArrowPress = (e: KeyboardEvent) => {
                if (e.key === "ArrowLeft") {
                    onPreviousPage();
                }
                if (e.key === "ArrowRight") {
                    onNextPage();
                }
            };
            document.body.addEventListener("keydown", onArrowPress);
            return () => {
                document.body.removeEventListener("keydown", onArrowPress);
            };
        });

        return (
            <div className={cssBaseClassName}>
                <Button
                    accessibleText={t("firstResultsPage")}
                    cssClassName={`${cssBaseClassName}__first`}
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.XSmall}
                    onClick={onFirstPage}>
                    <Icon type={Icons.ChevronFirst} size={IconSizes.Base} />
                </Button>
                <Button
                    accessibleText={t("previousResultsPage")}
                    cssClassName={`${cssBaseClassName}__prev`}
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.XSmall}
                    onClick={onPreviousPage}>
                    <Icon type={Icons.ChevronLeft} size={IconSizes.Base} />
                </Button>
                <div className={`${cssBaseClassName}__paging`}>
                    <TextInput
                        id={"page-jump"}
                        isValid={true}
                        value={inputValue}
                        onKeyDown={onSelectPage}
                        onChange={(e) => {
                            onChangeHandler(e);
                        }}
                        onFocus={(e) => {
                            e.target.select();
                        }}
                        maxLength={4}
                        type={InputTypes.Number}
                    />
                    <div className={`${cssBaseClassName}__total_pages`}>
                        <span>of {totalPages}</span>
                    </div>
                </div>
                <Button
                    accessibleText={t("nextResultsPage")}
                    cssClassName={`${cssBaseClassName}__next`}
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.XSmall}
                    onClick={onNextPage}>
                    <Icon type={Icons.ChevronRight} size={IconSizes.Base} />
                </Button>
                <Button
                    accessibleText={t("lastResultsPage")}
                    cssClassName={`${cssBaseClassName}__last`}
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.XSmall}
                    onClick={onLastPage}>
                    <Icon type={Icons.ChevronLast} size={IconSizes.Base} />
                </Button>
            </div>
        );
    };

export default CompactPageNavigationMenu;
