import SectionRecord from "models/view-models/section-record";
import { useCallback, useEffect, useState } from "react";
import ServiceHookResultFactory from "utilities/hooks/service-hook-result-factory";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import useSectionIncludeSubSectionsApi from "utilities/hooks/domain/publications/chapters/sections/use-section-include-sub-sections-api";
import NumberUtils from "utilities/number-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface UseSectionIncludeSubSectionsOptions {
    enabled?: boolean;
    sectionId?: number;
}

// #endregion Interfaces

/**
 * Custom hook for getting a section and all subSections.
 *
 * SubSections will not be nested.
 *
 * @export
 * @param {number} [sectionId]
 */
export default function useSectionIncludeSubSections(
    options: UseSectionIncludeSubSectionsOptions
): ServiceHookResult<SectionRecord> {
    const { enabled = true } = options;

    const sectionId = NumberUtils.parseInt(options.sectionId);

    // -----------------------------------------------------------------------------------------
    // #region Service Hooks
    // -----------------------------------------------------------------------------------------

    const { getSectionIncludeSubSections } = useSectionIncludeSubSectionsApi();

    // #endregion Service Hooks

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [result, setResult] = useState<ServiceHookResult<SectionRecord>>(
        ServiceHookResultFactory.initialResult(new SectionRecord())
    );

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Callbacks
    // -----------------------------------------------------------------------------------------

    const execute = useCallback(
        async (sectionId) => {
            if (sectionId == null || !enabled) {
                setResult(
                    ServiceHookResultFactory.initialResult(new SectionRecord())
                );

                return;
            }

            setResult(
                ServiceHookResultFactory.loadingResult(new SectionRecord())
            );

            try {
                const section = await getSectionIncludeSubSections(sectionId);

                if (section == null || section.publication == null) {
                    setResult(
                        ServiceHookResultFactory.errorResult(
                            new SectionRecord(),
                            t("sections-include-subsections-not-found")
                        )
                    );

                    return;
                }

                setResult(ServiceHookResultFactory.successResult(section));
            } catch (error) {
                ToastManager.error(
                    t("sections-include-subsection-loading-error")
                );

                setResult(
                    ServiceHookResultFactory.errorResult(
                        new SectionRecord(),
                        error
                    )
                );
            }
        },
        [getSectionIncludeSubSections, enabled]
    );

    const refresh = useCallback(() => execute(sectionId), [execute, sectionId]);

    // #endregion Callbacks

    // -----------------------------------------------------------------------------------------
    // #region Side-Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        execute(sectionId);
    }, [execute, sectionId]);

    // #endregion Side-Effects

    return {
        ...result,
        refresh,
    };
}
