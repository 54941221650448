import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import ResourceCollectionRecord from "models/view-models/resource-collection-record";
import { useReducer } from "react";

/**
 * Custom wrapper of the reducer used to map enhancedContentId to
 * a Collection of EnhancedContentResources
 */
export default function useEnhancedContentResourcesReducer(
    initialResources: ResourceCollectionRecord<EnhancedContentResourceRecord>,
    enhancedContentId?: number
) {
    const [resources, setResources] = useReducer(
        (
            currentValues: ResourceCollectionRecord<EnhancedContentResourceRecord>,
            newValues: ResourceCollectionRecord<EnhancedContentResourceRecord>
        ) => {
            const resourcesArray = newValues.resources
                .map((e: EnhancedContentResourceRecord) =>
                    e.with({ enhancedContentId })
                )
                .toList();
            return newValues.with({ resources: resourcesArray });
        },
        initialResources
    );

    return { resources, setResources };
}
