import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import ChangeSummaryRecord from "models/view-models/change-summary-record";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import React from "react";
import useChangeSummaryDelete from "utilities/hooks/domain/change-summaries/use-change-summary-delete";
import useModalActions from "utilities/hooks/use-modal-actions";
import ChangeSummariesAdminNoteModal from "./change-summaries-admin-note-modal";
import PublishChangeSummaryButton from "./publish-change-summary-button";

const CONFIRMATION_MESSAGE =
    "Are you sure you want to delete this Change Summary?";

interface ChangeSummariesAdminActionsProps {
    adminNote: string;
    bookviewRoute: string;
    removeChangeSummary: (chamgeSummaryId: number) => void;
    updateChangeSummary: (changeSummary: ChangeSummaryRecord) => void;
    changeSummary: ChangeSummaryRecord;
}

const ChangeSummariesAdminActions = ({
    adminNote,
    bookviewRoute,
    removeChangeSummary,
    updateChangeSummary,
    changeSummary,
}: ChangeSummariesAdminActionsProps) => {
    const { deleteChangeSummary } = useChangeSummaryDelete();
    const {
        handleClose: handleAdminNoteModalClose,
        handleOpen: handleAdminNoteModalOpen,
        isOpen: isAdminNoteModalOpen,
    } = useModalActions();
    const {
        isOpen: isConfirmationModalOpen,
        handleOpen: openConfirmationModal,
        handleClose: closeConfirmationModal,
    } = useModalActions();

    const handleOpenConfirmation = () => {
        openConfirmationModal();
    };
    const handleCancel = () => {
        closeConfirmationModal();
    };
    const handleConfirm = async () => {
        await deleteChangeSummary(changeSummary.id!);
        await removeChangeSummary(changeSummary.id!);
        closeConfirmationModal();
    };

    return (
        <>
            <MenuButton
                buttonAccessibleText={"Options"}
                triggerButtonStyle={ButtonStyles.TertiaryAlt}
                triggerButtonSize={ButtonSizes.XSmall}
                useOverlay={false}
                trackOutsideClicksToClose={true}>
                <PublishChangeSummaryButton
                    updateChangeSummary={updateChangeSummary}
                    changeSummary={changeSummary}
                />
                <Anchor cssClassName="c-button" to={bookviewRoute}>
                    Go to Bookview
                </Anchor>
                <Button onClick={handleOpenConfirmation}>Delete</Button>
                <Button
                    accessibleText="Edit Enhanced Content Note"
                    onClick={handleAdminNoteModalOpen}>
                    Edit Note
                </Button>
            </MenuButton>
            {isAdminNoteModalOpen && (
                <ChangeSummariesAdminNoteModal
                    onClose={handleAdminNoteModalClose}
                    onRefresh={handleAdminNoteModalClose}
                    id={changeSummary.id!}
                    adminNote={adminNote ?? ""}
                    updateChangeSummary={updateChangeSummary}
                />
            )}
            <ConfirmationModal
                isVisible={isConfirmationModalOpen}
                message={CONFIRMATION_MESSAGE}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </>
    );
};

export default ChangeSummariesAdminActions;
