import React from "react";
import { DataTestAttributes } from "interfaces/data-test-attributes";
import useServiceWorker from "utilities/contexts/service-worker/use-service-worker";

// -----------------------------------------------------------------------------------------
// #region Interface
// -----------------------------------------------------------------------------------------

interface ServiceWorkerStatusProps
    extends Pick<DataTestAttributes, "dataTestId"> {}

// #endregion Interface

const ServiceWorkerStatus: React.FC<ServiceWorkerStatusProps> = (props) => {
    const { dataTestId } = props;

    const { serviceWorkerState, isReadyToReload: shouldReloadServiceWorker } =
        useServiceWorker();

    return (
        <div className="c-service-worker-status" data-test-id={dataTestId}>
            <p data-test-id={"service-worker-state"}>
                State: {serviceWorkerState}
            </p>
            <p data-test-id={"service-worker-reload"}>
                Reload: {shouldReloadServiceWorker ? "Yes" : "No"}
            </p>
        </div>
    );
};

export default ServiceWorkerStatus;
