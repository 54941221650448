import MetadataParams from "interfaces/routing/metadata-params";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import PublicationContentRedirect from "organisms/publication-content/legacy-redirects/publication-content-redirect";
import React from "react";
import { useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import usePublicationQuery from "utilities/hooks/domain/publications/use-publication-query";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import NumberUtils from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface MetadataRedirectProps {}

interface RouteParams
    extends Pick<NestedPublicationParams, "publicationId">,
        Pick<MetadataParams, "type"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const MetadataRedirect: React.FC<MetadataRedirectProps> = (
    props: MetadataRedirectProps
) => {
    const { publicationId, type } = useParams<RouteParams>();
    const { isAdminPreview } = useAdminPreview();

    const {
        resultObject: publication,
        loaded,
        loading,
    } = usePublicationQuery({
        id: NumberUtils.parseInt(publicationId),
        isAdmin: isAdminPreview,
    });
    const route = RouteUtils.getUrl(siteMap.publications.permalinks.chapter, {
        code: publication.code,
        edition: publication.edition,
        type: type,
    });

    return (
        <PublicationContentRedirect
            entities={[publication]}
            loaded={loaded}
            loading={loading}
            missingParam={StringUtils.isEmpty(type)}
            route={route}
        />
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default MetadataRedirect;

// #endregion Exports
