import { siteMap } from "internal-sitemap";
import { RouteTab } from "molecules/route-tabs/route-tabs";
import CultureResources from "utilities/interfaces/culture-resources";

const getTabs = (
    translation: <K extends keyof CultureResources>(
        key: K,
        options?: object | undefined
    ) => string
): RouteTab[] => {
    return [
        {
            route: siteMap.myLink.myBookmarks,
            label: translation("mylinkLayout-tabs-label-myBookmarks"),
        },
        {
            route: siteMap.myLink.collections,
            label: translation("collection_plural"),
        },
    ];
};

export const MyLinkUtils = {
    getTabs,
};
