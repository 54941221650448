import SearchResultRecord from "models/view-models/search/search-result-record";
import ServiceHookFactory from "utilities/services/service-hook-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

/**
 * Search parameters when listing Search resources.
 * These get translated to QueryString parameters
 */
export interface SearchListQueryParams {
    annexIds?: Array<number>;
    articleIds?: Array<number>;
    chapterIds?: Array<number>;
    entityTypes?: string[];
    exact?: boolean;
    publicationIds?: Array<number>;
    search?: string;
    start?: number;
    take?: number;
}

/**
 * Search endpoint does not require any
 */
export interface SearchResourcePathParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "search";
const resourceType = SearchResultRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SearchService = {
    useGet: ServiceHookFactory.useGet<
        SearchResultRecord,
        SearchResourcePathParams,
        SearchListQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default SearchService;

// #endregion Exports
