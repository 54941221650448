import React, { PropsWithChildren } from "react";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DataTableProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-data-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const DataTable: React.FC<PropsWithChildren<DataTableProps>> = (
    props: PropsWithChildren<DataTableProps>
) => {
    const classNames = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }
    return <table className={classNames.join(" ")}>{props.children}</table>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default DataTable;

// #endregion Exports
