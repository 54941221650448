import { RichTextEditorProps } from "molecules/rich-text/rich-text-editor";
import { RefObject, useEffect } from "react";
import ReactQuill from "react-quill";
import useQuillClassName from "utilities/hooks/quill/use-quill-class-name";
import useQuillHandlers from "utilities/hooks/quill/use-quill-handlers";
import useQuillModules from "utilities/hooks/quill/use-quill-modules";
import useQuillState from "utilities/hooks/quill/use-quill-state";

/**
 * Custom hook to wrap up the logic and custom behavior we're adding
 * onto the base Quill editor.
 * @param baseClassName root CSS class name
 * @param props component props
 * @param quillRef ref to ReactQuill component to pull out plaintext value
 */
export default function useQuill(
    baseClassName: string,
    props: RichTextEditorProps,
    quillRef: RefObject<ReactQuill>
) {
    // -------------------------------------------------------------------------------------------------
    // #region Props
    // -------------------------------------------------------------------------------------------------

    const {
        allowImages,
        allowReferenceLinks,
        disabled,
        errors,
        maxLength,
        onChange,
        onFocus,
    } = props;

    // #endregion Props

    // -------------------------------------------------------------------------------------------------
    // #region Hooks
    // -------------------------------------------------------------------------------------------------

    const quillState = useQuillState();

    const quillModules = useQuillModules(
        allowImages!,
        allowReferenceLinks!,
        quillState.openImageModal,
        quillState.openReferenceLinkModal,
        quillState.toolbarId
    );

    const handlers = useQuillHandlers(
        quillState.closeImageModal,
        quillState.closeReferenceLinkModal,
        quillState.getCurrentIndex,
        onChange,
        onFocus,
        quillRef,
        quillState.setQuillSelectionIndex
    );

    const plainTextValue = quillRef.current?.getEditor()?.getText() ?? "";

    const className = useQuillClassName(
        baseClassName,
        disabled,
        errors,
        maxLength,
        plainTextValue
    );

    // #endregion Hooks

    // -------------------------------------------------------------------------------------------------
    // #region Effects
    // -------------------------------------------------------------------------------------------------

    /**
     * In IE, images and some other objects within `contenteditable` containers
     * have native image resize handles, which messes with our custom image
     * resize handles, so disable them via the IE-specific `mscontrolselect` event.
     */
    useEffect(() => {
        const eventName = "mscontrolselect";
        const handler = (event: Event) => event.preventDefault();
        document.body.addEventListener(eventName, handler);

        return () => document.body.removeEventListener(eventName, handler);
    }, []);

    // #endregion Effects

    // -------------------------------------------------------------------------------------------------
    // #region Result
    // -------------------------------------------------------------------------------------------------

    return {
        className,
        ...handlers,
        plainTextValue,
        quillModules,
        ...quillState,
    };

    // #endregion Result
}
