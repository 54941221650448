import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import { List } from "immutable";
import { CategoryType } from "models/enumerations/situational-navigation/categories/category-type";
import CategoryCollectionRecord from "models/view-models/situational-navigation/categories/category-collection-record";
import CategoryRecord from "models/view-models/situational-navigation/categories/category-record";
import CategorySelectionModal from "organisms/situational-navigation/categories/category-selection-modal";
import React, { useState } from "react";

export interface SituationalNavigationCategoryLinkProps {
    accessibleText?: string;
    allCategories: List<CategoryRecord>;
    allSelectedOptions: CategoryCollectionRecord;
    ariaControls?: string;
    ariaExpanded?: boolean;
    categoryType: CategoryType;
    children?: any;
    cssClassName?: string;
    filteredCategories: List<CategoryRecord>;
    id?: string;
    loadingCategories?: boolean;
    onCategorySelected: (newValue?: CategoryRecord) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    onCategoryClear: (categoryToClear: CategoryType) => void;
    ref?: React.RefObject<HTMLButtonElement>;
    size?: ButtonSizes;
    style?: ButtonStyles;
    value: CategoryRecord | undefined;
}

const SituationalNavigationCategoryLink: React.FC<SituationalNavigationCategoryLinkProps> =
    ({
        accessibleText,
        allCategories,
        allSelectedOptions,
        ariaControls,
        ariaExpanded,
        categoryType,
        children,
        cssClassName,
        loadingCategories,
        filteredCategories,
        id,
        onCategorySelected,
        onCategoryClear,
        size,
        style,
        value,
    }) => {
        const cssBaseClassName = "c-situational-navigation-category-link";
        const buttonClassNames = [`${cssBaseClassName}__main-button`];

        const [showModal, setShowModal] = useState(false);

        if (style === ButtonStyles.None) {
            buttonClassNames[0] = "";
        }

        if (size != null) {
            buttonClassNames.push(size);
        }

        if (style != null) {
            buttonClassNames.push(style);
        }

        if (value != null) {
            buttonClassNames.push("-selected");
        }

        if (cssClassName != null && cssClassName.length > 0) {
            buttonClassNames.push(cssClassName);
        }

        if (filteredCategories.isEmpty()) {
            buttonClassNames.push("-disabled");
        }

        const handleClick = () => setShowModal(true);
        const handleClearSelection = () => {
            onCategoryClear(categoryType);
        };
        const closeDialog = () => setShowModal(false);

        return (
            <React.Fragment>
                <div className={`${cssBaseClassName}`}>
                    <Button
                        aria-controls={ariaControls}
                        aria-expanded={ariaExpanded}
                        cssClassName={buttonClassNames.join(" ")}
                        style={ButtonStyles.TertiaryAlt}
                        id={id}
                        onClick={handleClick}>
                        <div>
                            {children}
                            {
                                // if
                                filteredCategories.isEmpty() && (
                                    <div className="-no-selections">
                                        No selections available.
                                    </div>
                                )
                            }
                            <div
                                className={`${cssBaseClassName}__main-button__selection`}>
                                {value && <Paragraph>{value.title}</Paragraph>}
                            </div>
                        </div>
                        {accessibleText !== null &&
                            accessibleText !== undefined && ( // if
                                <span className="sr-only">
                                    {accessibleText}
                                </span>
                            )}
                    </Button>
                    {
                        // if
                        value != null && (
                            <Button
                                accessibleText="Clear Selection"
                                cssClassName={`${cssBaseClassName}__close`}
                                disabled={filteredCategories.isEmpty()}
                                onClick={handleClearSelection}
                                style={ButtonStyles.None}>
                                <Icon type={Icons.Close} />
                            </Button>
                        )
                    }
                </div>
                <CategorySelectionModal
                    allCategories={allCategories}
                    allSelectedOptions={allSelectedOptions}
                    categoryType={categoryType}
                    loadingCategories={loadingCategories}
                    filteredCategories={filteredCategories}
                    isVisible={showModal}
                    onChange={onCategorySelected}
                    onCloseDialog={closeDialog}
                    value={value}
                />
            </React.Fragment>
        );
    };

export default SituationalNavigationCategoryLink;
