import GroupInvitationRecord from "models/view-models/group-invitations/group-invitation-record";
import InviteOnDemandRecord from "models/view-models/group-invitations/invite-on-demand.record";
import ServiceFactory from "utilities/services/service-factory";

const baseEndpoint = "invite-on-demand";
const resourceEndpoint = `${baseEndpoint}/:groupId`;

interface InviteOnDemandPathParams {
    groupId: number;
}
interface InviteOnDemandQueryParams {}

const InviteOnDemandService = {
    create: ServiceFactory.create<GroupInvitationRecord>(
        GroupInvitationRecord,
        baseEndpoint
    ),
    get: ServiceFactory.get<
        InviteOnDemandRecord,
        InviteOnDemandPathParams,
        InviteOnDemandQueryParams
    >(InviteOnDemandRecord, resourceEndpoint),
};

export default InviteOnDemandService;
