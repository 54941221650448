import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { Set } from "immutable";
import PublicationRecord from "models/view-models/publication-record";
import SectionRecord from "models/view-models/section-record";
import { LinkCardTypes } from "molecules/constants/link-card-types";
import LinkCard from "molecules/link-card/link-card";
import CodesModal from "molecules/modals/codes-modal";
import React, { useEffect, useState } from "react";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";
import useArticle from "utilities/hooks/domain/publications/chapters/articles/use-article";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import SectionService from "utilities/services/sections/section-service";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";

interface SectionLinkCardListProps {
    sections: SectionRecord[];
}

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-section-link-card-list";

// #endregion Constants

const SectionLinkCardList: React.FunctionComponent<SectionLinkCardListProps> = (
    props: SectionLinkCardListProps
) => {
    const { t } = useLocalization<CultureResources>();

    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    const [publications, setPublications] = useState<Set<PublicationRecord>>(
        Set(
            props.sections
                .filter((e: SectionRecord) => e.publication != null)
                .map((e: SectionRecord) => e.publication!)
        )
    );
    const [selectedSection, setSelectedSection] = useState<
        SectionRecord | undefined
    >();
    const [subSections, setSubSections] = useState<SectionRecord[]>();

    const { list: listSectionsApi } = SectionService.useList();
    const { resultObject: selectedSectionAnnex } = useAnnex(
        selectedSection?.publicationId,
        selectedSection?.annexId
    );
    const { resultObject: selectedSectionArticle } = useArticle({
        publicationId: selectedSection?.publicationId,
        chapterId: selectedSection?.chapterId,
        articleId: selectedSection?.articleId,
    });
    const { resultObject: selectedSectionChapter } = useChapter({
        publicationId: selectedSection?.publicationId,
        chapterId: selectedSection?.chapterId,
        nfpaLabel: selectedSection?.chapter?.nfpaLabel ?? "",
    });
    const selectedSectionPublication = publications.find(
        (p: PublicationRecord) => p.id === selectedSection?.publicationId
    );

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Event Handlers
    // -----------------------------------------------------------------------------------------

    const handleLinkCardClick = (section: SectionRecord) =>
        setSelectedSection(section);

    // #endregion Event Handlers

    // -----------------------------------------------------------------------------------------
    // #region Side Effects
    // -----------------------------------------------------------------------------------------

    useEffect(() => {
        const publications = Set(
            props.sections
                .filter((e: SectionRecord) => e.publication != null)
                .map((e: SectionRecord) => e.publication!)
        );
        setPublications(publications);
    }, [props.sections]);

    useEffect(() => {
        const getSubSections = async (parentId: number) => {
            try {
                const subSectionReadResult = await listSectionsApi({
                    parentId,
                });
                if (subSectionReadResult.result?.hasErrors()) {
                    return ToastManager.error(
                        t("sectionLinkCardList-error-loadingChildSections")
                    );
                }
                setSubSections(subSectionReadResult.resultObjects);
            } catch (error) {
                ToastManager.error(
                    t("sectionLinkCardList-error-loadingChildSections")
                );
                return;
            }
        };

        if (selectedSection == null) {
            return;
        }

        getSubSections(selectedSection.id!);
    }, [selectedSection, listSectionsApi, t]);

    // #endregion Side Effects

    // -----------------------------------------------------------------------------------------
    // #region Component
    // -----------------------------------------------------------------------------------------

    return (
        <div className={`${CSS_BASE_CLASS_NAME}`}>
            <React.Fragment>
                {publications.map((publication: PublicationRecord) => {
                    const sections = props.sections.filter(
                        (section: SectionRecord) =>
                            section.publicationId === publication.id
                    );
                    return (
                        <div
                            className={`${CSS_BASE_CLASS_NAME}__item`}
                            key={publication.id}>
                            <React.Fragment>
                                <Heading priority={HeadingPriority.Six}>
                                    {publication?.getDisplayTitle()}
                                </Heading>
                                {sections.map(
                                    (section: SectionRecord, index) => (
                                        <LinkCard
                                            type={LinkCardTypes.Button}
                                            onClick={() =>
                                                handleLinkCardClick(section)
                                            }
                                            key={index}
                                            label={StringUtils.truncateRight(
                                                section.bodyAsPlainText ?? "",
                                                110
                                            )}>
                                            {section.getFullyQualifiedDisplayTitle()}
                                        </LinkCard>
                                    )
                                )}
                            </React.Fragment>
                        </div>
                    );
                })}
                {selectedSection && selectedSectionPublication && (
                    <CodesModal
                        publicationDisplayTitle={selectedSectionPublication.getDisplayTitle()}
                        publicationType={selectedSectionPublication.type}
                        content={selectedSection.with({
                            annex: selectedSectionAnnex,
                            article: selectedSectionArticle,
                            chapter: selectedSectionChapter,
                            publication: selectedSectionPublication,
                        })}
                        subContent={subSections}
                        closeDialog={() => setSelectedSection(undefined)}
                        isVisible={selectedSection != null}
                    />
                )}
            </React.Fragment>
        </div>
    );

    // #endregion Component
};

export default SectionLinkCardList;
