import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import InputCharacterCount from "atoms/forms/input-character-count";
import Icon from "atoms/icons/icon";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextEditorFooterProps {
    baseClassName: string;
    currentLength: number;
    errors?: string[];
    maxLength?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextEditorFooter: React.FC<RichTextEditorFooterProps> = (
    props: RichTextEditorFooterProps
) => {
    const { baseClassName, currentLength, errors, maxLength } = props;

    if (CollectionUtils.hasValues(errors)) {
        return (
            <span className={`${baseClassName}__error`}>
                <Icon size={IconSizes.Base} type={Icons.Information} />
                <div className={`${baseClassName}__error-messages`}>
                    {errors?.map((e, index) => (
                        <span key={index}>{e}</span>
                    ))}
                </div>
                {maxLength != null && (
                    <InputCharacterCount
                        currentLength={currentLength}
                        maxLength={maxLength}
                    />
                )}
            </span>
        );
    }

    if (maxLength != null) {
        return (
            <InputCharacterCount
                currentLength={currentLength}
                maxLength={maxLength}
            />
        );
    }

    return null;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextEditorFooter;

// #endregion Exports
