import { Redirect, useParams } from "react-router";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useLoading from "utilities/hooks/use-loading";
import React from "react";
import useErrors from "utilities/hooks/use-errors";
import useAnnex from "utilities/hooks/domain/publications/annexes/use-annex";
import useAnnexTables from "utilities/hooks/domain/publications/annexes/use-annex-tables";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import { siteMap } from "internal-sitemap";
import TablesList from "./tables-list";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useCurrentPublication from "utilities/contexts/use-current-publication";

interface AnnexTablesContentProps {}
interface RouteParams {
    annexId: string;
    annexNfpaLabel: string;
}

const AnnexTablesContent: React.FunctionComponent<AnnexTablesContentProps> = (
    props: AnnexTablesContentProps
) => {
    const { extractTablesFromPublicationImport } = useFeatureFlags();
    const { publication } = useCurrentPublication();
    const { annexId, annexNfpaLabel } = useParams<RouteParams>();
    const { isAdminPreview } = useAdminPreview();

    const {
        errors: annexErrors,
        loaded: annexLoaded,
        loading: annexLoading,
        resultObject: annex,
    } = useAnnex(publication?.id, annexId, annexNfpaLabel);

    const {
        errors: tablesErrors,
        loaded: tablesLoaded,
        loading: tablesLoading,
        resultObject: tables,
    } = useAnnexTables(annex.id, isAdminPreview);

    const errors = useErrors(annexErrors, tablesErrors);
    useErrorBoundary(errors);

    const loaded = annexLoaded && tablesLoaded;
    const loading = useLoading(annexLoading, tablesLoading);

    const title = annex.getDisplayTitleWithNumber();

    if (!extractTablesFromPublicationImport) {
        return <Redirect to={siteMap.dashboards.user} />;
    }

    return (
        <TablesList
            errors={errors}
            loaded={loaded}
            loading={loading}
            publicationType={publication?.type!}
            tables={tables}
            title={title}
        />
    );
};

export default AnnexTablesContent;
