import TableRecord from "models/view-models/table-record";
import { useEffect, useCallback } from "react";
import useServiceHookResultState from "utilities/hooks/use-service-hook-result-state";
import ServiceHookResult from "utilities/interfaces/service-hook-result";
import NumberUtils from "utilities/number-utils";
import TableService from "utilities/services/publications/chapters/tables/table-service";

/**
 * Custom hook for getting Tables based on a Chapter
 *
 * @param {string | number} chapterId
 * @returns
 */
export default function useChapterTables(
    isAdmin: boolean,
    chapterId?: string | number
): ServiceHookResult<Array<TableRecord>> {
    chapterId = NumberUtils.parseInt(chapterId);

    const { list: listTablesApi } = TableService.useList();
    const { list: adminPreviewList } = TableService.usePreviewList();
    const { result, setInitial, setError, setLoading, setSuccess } =
        useServiceHookResultState<Array<TableRecord>>(new Array<TableRecord>());
    const getTables = isAdmin ? adminPreviewList : listTablesApi;
    const loadTables = useCallback(
        async (chapterId) => {
            if (NumberUtils.isDefault(chapterId)) {
                setInitial();
                return;
            }

            setLoading();

            try {
                const { resultObjects: tablesList } = await getTables({
                    chapterId,
                });

                setSuccess(tablesList);
            } catch (error) {
                setError(error);
            }
        },
        [getTables, setError, setInitial, setLoading, setSuccess]
    );

    const refresh = useCallback(
        () => loadTables(chapterId),
        [chapterId, loadTables]
    );

    useEffect(() => {
        loadTables(chapterId);
    }, [chapterId, loadTables]);

    return { ...result, refresh };
}
