import PublicationRecord from "models/view-models/publication-record";
import React from "react";
import Select, { SelectOption } from "atoms/forms/select";
import SelectSizes from "atoms/constants/select-sizes";
import PublishStatus from "models/enumerations/publish-status";
import PublishStatusFiltersBar from "organisms/admin/situational-navigation/publish-status-filters-bar";
import PublishableFilters from "utilities/interfaces/publishable-filters";
import SearchForm from "molecules/forms/search-form";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface EnhancedContentAdminControlsOptions {
    publicationSelectOptions: Array<SelectOption<PublicationRecord>>;
    publishFilters: PublishableFilters;
}

export interface EnhancedContentAdminControlsProps {
    onChangeHasUnpublishedChanges: (hasUnpublishedChanges?: boolean) => void;
    onChangePublishStatus: (publishStatus?: PublishStatus) => void;
    onChangeSelectedPublication: () => void;
    options: EnhancedContentAdminControlsOptions;
    refreshEnhancedContent: () => void;
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-enhanced-content-admin-controls";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EnhancedContentAdminControls: React.FC<EnhancedContentAdminControlsProps> =
    (props: EnhancedContentAdminControlsProps) => {
        const { refreshEnhancedContent, searchText, setSearchText } = props;
        const { publishFilters } = props.options;

        // -----------------------------------------------------------------------------------------
        // #region Event Handlers
        // -----------------------------------------------------------------------------------------

        const handlePublishStatusFiltersChanged = (
            publishStatusFilter: PublishableFilters
        ) => {
            props.onChangeHasUnpublishedChanges(
                publishStatusFilter.hasUnpublishedChanges
            );
            props.onChangePublishStatus(publishStatusFilter.publishStatus);
        };

        // #endregion Event Handlers

        // -----------------------------------------------------------------------------------------
        // #region Render
        // -----------------------------------------------------------------------------------------

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__left`}>
                    <SearchForm
                        onClear={() => setSearchText("")}
                        onSearchTextChange={(e) =>
                            setSearchText(e.target.value)
                        }
                        placeholder="Filter by content item title..."
                        searchText={searchText}
                        onSearchClick={() => refreshEnhancedContent?.()}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__right`}>
                    <Select
                        label="Filter by Publication"
                        onChange={props.onChangeSelectedPublication}
                        options={props.options.publicationSelectOptions}
                        size={SelectSizes.Small}
                    />
                    <PublishStatusFiltersBar
                        onChange={handlePublishStatusFiltersChanged}
                        value={publishFilters}
                    />
                </div>
            </div>
        );

        // #endregion Render
    };

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default EnhancedContentAdminControls;

// #endregion Exports
