import EmailValidationService from "utilities/services/validation/email-validation-service";
import EmailValidationRecord from "models/view-models/email-validation-record";
import StringUtils from "utilities/string-utils";

interface UseEmailValidationHook {
    validateEmail: (emailAddress: string) => Promise<EmailValidationRecord>;
}

const useEmailAddressValidation = (): UseEmailValidationHook => {
    const { get: validateEmailApi } = EmailValidationService.useGet();

    const validateEmail = async (
        emailAddress: string
    ): Promise<EmailValidationRecord> => {
        if (StringUtils.isEmpty(emailAddress)) {
            return new EmailValidationRecord().setToInvalid();
        }

        try {
            const { resultObject } = await validateEmailApi(
                {},
                { emailAddress }
            );

            if (resultObject == null) {
                throw new Error(
                    "No email validation result returned from API."
                );
            }

            return resultObject;
        } catch (error) {
            // If the API fails we're going to assume that the email is valid so that
            // we don't prevent users from signing up.
            return new EmailValidationRecord().setToValid();
        }
    };

    return {
        validateEmail,
    };
};

export default useEmailAddressValidation;
