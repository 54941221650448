import type SectionRecord from "models/view-models/section-record";
import type UserBookmarkRecord from "models/view-models/user-bookmark-record";
import { useEffect, useMemo, useState } from "react";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import SectionService from "utilities/services/sections/section-service";
import PublicationSectionService from "utilities/services/publications/sections/section-service";
import { ToastManager } from "utilities/toast/toast-manager";

const CODE_ERROR_MESSAGE = "There was an issue fetching related code sections.";

/**
 * Custom hook to wrap up the API calls for gathering additional data for bookmarks.
 */
export default function useSelectedSectionBookmark() {
    // Services
    const { get: getSectionApi } =
        PublicationSectionService.useGetByExternalId();
    const { list: listSectionsApi } = SectionService.useList();

    // State
    const [isLoadingBody, setIsLoadingBody] = useState<boolean>(false);

    const [isLoadingSubSections, setIsLoadingSubSections] =
        useState<boolean>(false);

    const [selectedBookmark, setSelectedBookmark] =
        useState<UserBookmarkRecord>();

    const [selectedSection, setSelectedSection] = useState<SectionRecord>();

    // Memoized Values
    const isLoading = useMemo(
        () => isLoadingBody || isLoadingSubSections,
        [isLoadingBody, isLoadingSubSections]
    );

    useEffect(() => {
        setIsLoadingBody(true);
        setIsLoadingSubSections(true);
        setSelectedSection(undefined);
    }, [selectedBookmark]);

    // Lazy load section body
    useEffect(() => {
        if (
            selectedSection != null ||
            selectedBookmark?.createdOnPublicationId == null ||
            selectedBookmark?.userBookmarkType !== UserBookmarkType.Section
        ) {
            setIsLoadingBody(false);
            return;
        }

        async function getSectionBody(
            publicationId: number,
            externalId: string
        ) {
            setIsLoadingBody(true);

            try {
                const response = await getSectionApi({
                    publicationId,
                    externalId,
                });

                if (response.resultObject != null) {
                    setSelectedSection(response.resultObject);
                }
            } catch (error) {
                ToastManager.error(CODE_ERROR_MESSAGE);
                return;
            }

            setIsLoadingBody(false);
        }

        getSectionBody(
            selectedBookmark.createdOnPublicationId,
            selectedBookmark.externalId
        );
    }, [getSectionApi, selectedBookmark, selectedSection]);

    // Lazy load sub sections
    useEffect(() => {
        if (
            selectedBookmark?.userBookmarkType !== UserBookmarkType.Section ||
            selectedSection?.id == null ||
            Array.isArray(selectedSection.subSections)
        ) {
            setIsLoadingSubSections(false);
            return;
        }

        const getSubSections = async (parentId: number) => {
            setIsLoadingSubSections(true);

            try {
                const subSectionReadResult = await listSectionsApi({
                    parentId,
                });
                setSelectedSection((previousSection) =>
                    previousSection?.with({
                        subSections: subSectionReadResult.resultObjects ?? [],
                    })
                );
            } catch (error) {
                ToastManager.error(CODE_ERROR_MESSAGE);
                return;
            }

            setIsLoadingSubSections(false);
        };

        getSubSections(selectedSection.id);
    }, [listSectionsApi, selectedBookmark?.userBookmarkType, selectedSection]);

    return {
        isLoading,
        selectedBookmark,
        selectedSection,
        setSelectedBookmark,
    };
}
